import { Col, Form, FormInstance, Row } from "antd";
import { useTranslation } from "react-i18next";
import {
  DASHBOARD_FACILITY_BOTTOM_LEFT,
  DASHBOARD_FACILITY_BOTTOM_RIGHT,
  DASHBOARD_FACILITY_TOP_LEFT,
  DASHBOARD_FACILITY_TOP_RIGHT,
  IDbFacilityBottomRight,
} from "../../../store/dashboard";
import { getKeyByLocale } from "../../../tools/translation";
import BarChart from "../bar-chart";
import DonutChart from "../donut-chart";
import {
  appendStartAndEndOfTime,
  DBHeader,
  ErrorPage,
  LoadingPage,
} from "../header";
import LineChart from "../line-chart";

const facilityTopLeft = "facilityTopLeft";
const facilityTopRight = "facilityTopRight";
const facilityBottomLeft = "facilityBottomLeft";
const facilityBottomRight = "facilityBottomRight";

const FacilitySection = () => {
  const { t } = useTranslation();

  return (
    <div className="bg-white p-4">
      <p className="f-24 mb-4 bold">{t(`menu.setting-facility`)}</p>
      <Row gutter={[12, 12]}>
        <Col span={8}>
          <div className="p-3 db-border h-100">
            <DBHeader
              label={t("top-3-most-booked-facility")}
              selectType="range"
              formItemName={facilityTopLeft}
            />
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev[facilityTopLeft] !== next[facilityTopLeft]
              }
            >
              {(form: FormInstance) => {
                const value = form.getFieldValue(facilityTopLeft);
                return <TopLeft value={value} />;
              }}
            </Form.Item>
          </div>
        </Col>
        <Col span={16}>
          <div className="p-3 db-border h-100">
            <DBHeader
              label={t("facility-reservations-time-year")}
              selectType="year"
              formItemName={facilityTopRight}
            />
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev[facilityTopRight] !== next[facilityTopRight]
              }
            >
              {(form: FormInstance) => {
                const value = form.getFieldValue(facilityTopRight);
                return <TopRight value={value} />;
              }}
            </Form.Item>
          </div>
        </Col>
        <Col span={8}>
          <div className="p-3 db-border h-100">
            <DBHeader
              label={t("top-3-room-with-the-most-frequently-booked-facility")}
              selectType="range"
              formItemName={facilityBottomLeft}
            />
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev[facilityBottomLeft] !== next[facilityBottomLeft]
              }
            >
              {(form: FormInstance) => {
                const value = form.getFieldValue(facilityBottomLeft);
                return <BottomLeft value={value} />;
              }}
            </Form.Item>
          </div>
        </Col>
        <Col span={16}>
          <div className="p-3 db-border h-100">
            <DBHeader
              label={t("the-times-when-the-facility-is-used-the-most")}
              selectType="range"
              formItemName={facilityBottomRight}
            />
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev[facilityBottomRight] !== next[facilityBottomRight]
              }
            >
              {(form: FormInstance) => {
                const value = form.getFieldValue(facilityBottomRight);
                return <BottomRight value={value} />;
              }}
            </Form.Item>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const TopLeft = ({ value }: { value: any }) => {
  const params = appendStartAndEndOfTime(value);
  const { data, isLoading, isError, error } =
    DASHBOARD_FACILITY_TOP_LEFT(params);
  const { i18n } = useTranslation();

  if (isLoading) {
    return (
      <div style={{ height: 300 }}>
        <LoadingPage />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div style={{ height: 300 }}>
        <ErrorPage error={error} />
      </div>
    );
  }

  return (
    <div style={{ height: 300 }}>
      <DonutChart
        type="donut"
        data={data.map((e) => {
          return {
            label: getKeyByLocale({
              data: e,
              key: "facilityName",
              locale: i18n.language,
            }),
            value: e.count,
          };
        })}
      />
    </div>
  );
};

const TopRight = ({ value }: { value: any }) => {
  // const params = appendStartAndEndOfTime(value);
  const { t } = useTranslation();
  const { data, isLoading, isError, error } = DASHBOARD_FACILITY_TOP_RIGHT({
    year: value,
  });

  if (isLoading) {
    return (
      <div style={{ height: 300 }}>
        <LoadingPage />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div style={{ height: 300 }}>
        <ErrorPage error={error} />
      </div>
    );
  }

  return (
    <div style={{ height: 300 }}>
      <LineChart
        data={data.map((e) => e.count)}
        lineName={t("number-of-times")}
      />
    </div>
  );
};

const BottomLeft = ({ value }: { value: any }) => {
  const params = appendStartAndEndOfTime(value);
  const { data, isLoading, isError, error } =
    DASHBOARD_FACILITY_BOTTOM_LEFT(params);

  if (isLoading) {
    return (
      <div style={{ height: 300 }}>
        <LoadingPage />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div style={{ height: 300 }}>
        <ErrorPage error={error} />
      </div>
    );
  }

  return (
    <div style={{ height: 300 }}>
      <DonutChart
        data={data.map((e) => {
          return { label: e.unitNumber, value: e.count };
        })}
      />
    </div>
  );
};

const BottomRight = ({ value }: { value: any }) => {
  const params = appendStartAndEndOfTime(value);
  const { data, isLoading, isError, error } =
    DASHBOARD_FACILITY_BOTTOM_RIGHT(params);

  if (isLoading) {
    return (
      <div style={{ height: 300 }}>
        <LoadingPage />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div style={{ height: 300 }}>
        <ErrorPage error={error} />
      </div>
    );
  }

  const getFirstAndLast = (
    v: IDbFacilityBottomRight[]
  ): IDbFacilityBottomRight => {
    const s = v[0].timeStart;
    const e = v[v.length - 1].timeEnd;
    const count = v.reduce((r, c) => {
      return r + c.count;
    }, 0);
    return { timeEnd: e, timeStart: s, count };
  };

  const groupTime = () => {
    let result: IDbFacilityBottomRight[] = [];
    let count = 0;
    for (let i = 1; i <= 6; i++) {
      const split = [...data].splice(count, 4);
      count += 4;
      const value = getFirstAndLast(split);
      result = [...result, value];
    }
    return result;
  };

  const group = groupTime();

  return (
    <div style={{ height: 300 }}>
      <BarChart
        data={group.map((item) => {
          const s = item.timeStart.substring(0, 5);
          const e = item.timeEnd.substring(0, 5);
          return { label: `${s} - ${e}`, value: item.count };
        })}
        isGold={true}
      />
    </div>
  );
};

export default FacilitySection;
