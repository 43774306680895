import axios from "axios";
import dayjs from "dayjs";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { IResponse } from ".";
import { exportFile, throwResponse } from "../config/axios";
import { getClientTimeZone } from "../tools/datetime";
import { POST_FILE_OBJECT } from "./file";
import { POST_MEDIA_OBJECT } from "./media";

interface INews {
  id: number;
  headerTh: string;
  headerEn: string;
  createdAt: string;
  status: string;
  reach: number;
  updatedAt: string;
  createdBy: string;
  category: string;
  startDate: string;
  endDate: string;
  contentEn: string;
  contentTh: string;
  accessibility: string;
  newsMediaObjects: IMedia[];
  newsFileObjects: {
    id: number;
    filename: string;
    fileUrl: string;
    originalName: string;
  };
}

interface IMedia {
  id: number;
  createdAt: string;
  updatedAt: string;
  filename: string;
  alternativeText: string;
  width: string;
  height: string;
  mimeType: string;
  size: number;
  path: string;
  url: string;
  imageUrl: string;
}

export const GET_NEWS = (params?: {}): UseQueryResult<IResponse<INews[]>> => {
  return useQuery(
    ["get-news", params],
    async () => {
      const res = await axios.get("/api/news", {
        params: params,
      });
      if (res.status === 200) {
        const { data, count, page, countData } = res.data;
        return { data: data, count, page, countData };
      }
      throwResponse(res);
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const GET_NEWS_ID = (id: string): UseQueryResult<INews> => {
  return useQuery(["get-news-id", id], async () => {
    if (!id) return;
    const res = await axios.get(`/api/news/${id}`);
    if (res.status === 200) {
      const { data } = res.data;
      const mapMedia = data.newsMediaObjects.map((media: any) => {
        const mediaObjects = {
          id: media.mediaObject.id,
          name: media.mediaObject.filename,
          uid: media.uid,
          url: media.mediaObject.imageUrl,
        };
        return mediaObjects;
      });
      const files = data.newsFileObjects.map((file: any) => {
        const fileObject = {
          id: file.fileObject.id,
          filename: file.fileObject.filename,
          fileUrl: file.fileObject.fileUrl,
          originalName: file.fileObject.originalName,
        };
        return fileObject;
      });
      const createdBy = data.createdBy?.fullName || "";
      const time = [dayjs(data?.startDate), dayjs(data?.endDate)];
      return {
        ...data,
        date: time,
        picture: mapMedia,
        file: files,
        createdBy: createdBy,
      };
    }
    throwResponse(res);
  });
};

export const POST_NEWS = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const { picture, file } = data;
    let imageId = undefined;
    if (picture)
      imageId = await Promise.all(
        picture.map(async (item: any) => {
          const idImage = await POST_MEDIA_OBJECT(item.originFileObj);
          return idImage;
        })
      );
    let fileObjects = undefined;
    if (file)
      fileObjects = await Promise.all(
        file.map(async (item: any) => {
          if (!item.id) {
            const idFile = await POST_FILE_OBJECT(item);
            return idFile;
          }
          return item.id;
        })
      );
    delete data.picture;
    delete data.file;
    data = { ...data, mediaObjects: imageId, fileObjects };
    const res = await axios.post("/api/news", data);
    if (res.status === 201) {
      return true;
    }
    throwResponse(res);
  });
};

export const PATCH_NEWS = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const { picture, file } = data;
    let imageId = undefined;
    if (picture)
      imageId = await Promise.all(
        picture.map(async (item: any) => {
          if (!item.id) {
            const idImage = await POST_MEDIA_OBJECT(item.originFileObj);
            return idImage;
          }
          return item.id;
        })
      );
    let fileObjects = undefined;
    if (file)
      fileObjects = await Promise.all(
        file.map(async (item: any) => {
          if (!item.id) {
            const idFile = await POST_FILE_OBJECT(item);
            return idFile;
          }
          return item.id;
        })
      );
    delete data.picture;
    delete data.file;
    data = { ...data, mediaObjects: imageId, fileObjects };
    const res = await axios.patch(`/api/news/${data.id}`, data);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const DELETE_NEWS = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/api/news/${data.id}`);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const DELETE_NEWS_BULK = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/api/news/bulk`, {
      data: data,
    });
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const EXPORT_NEWS = (): UseMutationResult<Blob> => {
  return useMutation(
    async (data: any) => {
      const timeZone = getClientTimeZone();
      const res = await exportFile.post("/api/export-excel/news", {
        ...data,
        timeZone,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { retry: false }
  );
};

export const LINE_NOTIFICATION_NEWS = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post(
      "/api/line-notification/send-message-news",
      data
    );
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};
