import { Button, Col, Modal, Row, Select, Upload } from "antd";
import { useEffect, useState } from "react";
import { PlusCircleFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { RcFile, UploadChangeParam } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { swalError } from "../tools/swal";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/src/ReactCrop.scss";
import { dataURLtoFile, fileToBase64 } from "../tools/convert";
import { fixed_hieght } from "./page-header";

const UploadImage = ({
  value,
  onChange,
  onPage,
  facility = false,
  isReceiptImage = false,
}: {
  value?: any;
  onChange?: any;
  onPage?: string;
  facility?: boolean;
  isReceiptImage?: boolean;
}) => {
  const [imageList, setImageList] = useState<any | undefined>(value || []);
  const [crop, setCrop] = useState<Partial<Crop>>({
    unit: "px",
    width: 30,
    aspect: 1,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [base64Image, setBase64Image] = useState<any>();
  const [cropObject, setCropObject] = useState<Crop | null>(null);
  const [imageRef, setImageRef] = useState<HTMLImageElement | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    initial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const initial = () => {
    if (typeof value !== "object") return;
    setImageList(value);
  };

  const onChangeImage = (info: UploadChangeParam<UploadFile<any>>) => {
    const { fileList, file } = info;

    if ((file?.size || 0) / Math.pow(1024, 2) > 10) {
      return swalError(t, t("validate.image-size"));
    }

    if (fileList.length > imageList.length) return;

    onChange(fileList);
    setImageList(fileList);
  };

  const beforeUpload = async (file: RcFile, _: RcFile[]) => {
    if ((file?.size || 0) / Math.pow(1024, 2) > 10) {
      return false;
    }
    const base64 = await fileToBase64(file);
    setBase64Image(base64);
    setIsModalVisible(true);
    return false;
  };

  const onCropChange = (crop: Crop, _: Crop) => {
    setCrop({ ...crop });
  };

  const onComplete = async (crop: Crop, _: Crop) => {
    if (!imageRef || !crop.width || !crop.height) return;
    setCropObject(crop);
  };

  const onImageLoaded = (e: HTMLImageElement) => {
    setImageRef(e);
  };

  const getCroppedImg = () => {
    if (!imageRef || !cropObject)
      return { originFileObj: undefined, url: undefined };
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = imageRef.naturalWidth / imageRef.width;
    const scaleY = imageRef.naturalHeight / imageRef.height;
    const ctx = canvas.getContext("2d");
    if (!ctx) return { originFileObj: undefined, url: undefined };

    canvas.width = cropObject.width * pixelRatio * scaleX;
    canvas.height = cropObject.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      imageRef,
      cropObject.x * scaleX,
      cropObject.y * scaleY,
      cropObject.width * scaleX,
      cropObject.height * scaleY,
      0,
      0,
      cropObject.width * scaleX,
      cropObject.height * scaleY
    );

    const url = canvas.toDataURL();
    const originFileObj = dataURLtoFile(url, "imageCrop.png");
    return { originFileObj, url };
  };

  const onSaveCrop = () => {
    const image = getCroppedImg();
    if (!image) return;
    if (isReceiptImage || facility) {
      onChange([image]);
      setImageList([image]);
      setIsModalVisible(false);
    } else {
      onChange([...imageList, image]);
      setImageList([...imageList, image]);
      setIsModalVisible(false);
    }
  };

  const customFooterModal = () => {
    return (
      <Row gutter={24} className="mt-3">
        <Col span={12}></Col>
        <Col span={6} className="d-flex justify-end">
          <Button
            className="button-cancel f-18"
            style={{ width: 180, height: fixed_hieght }}
            onClick={() => setIsModalVisible(false)}
          >
            {t("cancel")}
          </Button>
        </Col>
        <Col span={6} className="d-flex justify-end">
          <Button
            className="button-save f-18"
            style={{ width: 170, height: fixed_hieght }}
            onClick={onSaveCrop}
          >
            {t("save")}
          </Button>
        </Col>
      </Row>
    );
  };

  const onPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
    // imgWindow.document.write(image.outerHTML);
  };

  return (
    <Col>
      {!isReceiptImage && (
        <span className="color-gold">
          * {t("recommended-cover-image-size")}{" "}
          {onPage && <> ({t("banner")} </>}
          4:3 {t("or")} 1200 x 900 {onPage && <>)</>}
          {t("and-lower-than")} 10 MB <br />
        </span>
      )}
      {onPage === "news" && (
        <span className="color-gold">
          &ensp; - {t("news")} ({t("rules-and-regulations")} 1:1 {t("or")} 1080
          x 1080), ({t("news")} 1:1 {t("or")} 1080 x 1080) {t("and-lower-than")}{" "}
          10 MB
          <br />
        </span>
      )}
      {onPage === "privilege" && (
        <span className="color-gold">
          &ensp; - {t("menu.privilege")} ({t("exclusive-privileges")} 1:1{" "}
          {t("or")} 1080 x 1080) {t("and-lower-than")} 10 MB <br />
        </span>
      )}
      {!isReceiptImage && !facility && (
        <span className="color-gold">
          * {t("recommended-main-image-size")} 1:1 {t("or")} 1080 x 1080{" "}
          {t("and-lower-than")} 10 MB
        </span>
      )}
      {isReceiptImage && (
        <span className="color-gold">
          * {t("only-one-receipt-image-upload")}
        </span>
      )}
      <Row
        style={{ border: "1px solid #e4e9f2", height: "100%" }}
        className="px-4 pt-4 mt-3 pb-3"
      >
        <Upload
          listType="picture-card"
          fileList={imageList}
          onChange={onChangeImage}
          beforeUpload={beforeUpload}
          accept=".jpg,.jpeg,.png"
          // disabled={isReceiptImage}
          multiple={false}
          onPreview={onPreview}
        >
          {imageList === undefined ||
            (imageList.length < imageList.length + 1 && (
              <PlusCircleFilled style={{ color: "#AAAAA7", fontSize: 24 }} />
            ))}
        </Upload>
      </Row>
      {imageList && imageList[0] && (
        <div className="cover">{t("cover-image")}</div>
      )}
      {imageList && imageList[1] && (
        <div className="main">{t("main-image")}</div>
      )}
      <Modal
        title=""
        footer={null}
        visible={isModalVisible}
        closable={false}
        maskClosable={false}
        // onOk={onSaveCrop}
        // onCancel={() => setIsModalVisible(false)}
      >
        <div>
          <Select
            defaultValue={1 / 1}
            value={crop.aspect}
            onChange={(value: number) => setCrop({ ...crop, aspect: value })}
            placeholder={"aspect"}
            style={{ width: "80px" }}
            className="w-100 d-flex align-center mb-4"
          >
            <Select.Option value={1 / 1}>1/1</Select.Option>
            <Select.Option value={4 / 3}>4/3</Select.Option>
          </Select>
          <ReactCrop
            src={base64Image}
            crop={crop}
            onChange={onCropChange}
            onComplete={onComplete}
            onImageLoaded={onImageLoaded}
          />
          {customFooterModal()}
        </div>
      </Modal>
    </Col>
  );
};

export default UploadImage;
