import { Col, Form, FormInstance, Row } from "antd";
import dayjs from "dayjs";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DASHBOARD_HOMECARE_LEFT,
  DASHBOARD_HOMECARE_BOTTOM,
  DASHBOARD_HOMECARE_MIDDLE,
  DASHBOARD_HOMECARE_RIGHT,
  IDbHomeCareLeft,
} from "../../../store/dashboard";
import { getKeyByLocale } from "../../../tools/translation";
import BarChart from "../bar-chart";
import DonutChart from "../donut-chart";
import {
  appendStartAndEndOfTime,
  DBHeader,
  ErrorPage,
  LoadingPage,
} from "../header";

const homecareLeft = "homecareLeft";
const homecareMiddle = "homecareMiddle";
const homecareRight = "homecareRight";
const homecareBottom = "homecareBottom";

const HomeCareSection = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 bg-white">
      <p className="f-24 mb-4 bold">{t(`menu.homecare`)}</p>
      <Row gutter={[12, 12]}>
        <Col span={8}>
          <div className="h-100 db-border p-3">
            <DBHeader
              label={t("dashboard-most-homecare")}
              selectType="range"
              formItemName={homecareLeft}
            />
            <Form.Item
              shouldUpdate={(prev, next) =>
                prev[homecareLeft] !== next[homecareLeft]
              }
              noStyle
            >
              {(form: FormInstance) => {
                const value = form.getFieldValue(homecareLeft);
                return <HomeCareLeft value={value} />;
              }}
            </Form.Item>
          </div>
        </Col>

        <Col span={8}>
          <div className="h-100 db-border p-3">
            <DBHeader
              label={t("all-expired-warranty")}
              selectType="range"
              formItemName={homecareMiddle}
            />
            <Form.Item
              shouldUpdate={(prev, next) =>
                prev[homecareMiddle] !== next[homecareMiddle]
              }
              noStyle
            >
              {(form: FormInstance) => {
                const value = form.getFieldValue(homecareMiddle);
                return <HomeCareMiddle value={value} />;
              }}
            </Form.Item>
          </div>
        </Col>

        <Col span={8}>
          <div className="h-100 db-border p-3">
            <DBHeader
              label={t("all-overtime-sla")}
              selectType="range"
              formItemName={homecareRight}
            />
            <Form.Item
              shouldUpdate={(prev, next) =>
                prev[homecareRight] !== next[homecareRight]
              }
              noStyle
            >
              {(form: FormInstance) => {
                const value = form.getFieldValue(homecareRight);
                return <HomeCareRight value={value} />;
              }}
            </Form.Item>
          </div>
        </Col>

        <Col span={24}>
          <div className="p-3 db-border h-100">
            <DBHeader
              label={t("top-5-most-reported-repairs")}
              selectType="range"
              formItemName={homecareBottom}
            />
            <Form.Item
              shouldUpdate={(prev, next) =>
                prev[homecareBottom] !== next[homecareBottom]
              }
              noStyle
            >
              {(form: FormInstance) => {
                const value = form.getFieldValue(homecareBottom);
                return <HomeCareBottom value={value} />;
              }}
            </Form.Item>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const HomeCareLeft = ({ value }: { value: any }) => {
  const params = appendStartAndEndOfTime(value);
  const { data, isLoading, isError, error } = DASHBOARD_HOMECARE_LEFT(params);
  const [firstTime, setFirstTime] = useState<boolean>(true);
  const [defaultData, setDefaultData] = useState<IDbHomeCareLeft[]>([]);

  useEffect(() => {
    if (firstTime && data) {
      setFirstTime(false);
      setDefaultData(data || []);
    }
  }, [data, firstTime]);

  if (isLoading) {
    return (
      <div style={{ height: 300 }}>
        <LoadingPage />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div style={{ height: 300 }}>
        <ErrorPage error={error} />
      </div>
    );
  }

  const leftData = Array.isArray(data) ? data : defaultData;

  return (
    <div style={{ height: 300 }}>
      <DonutChart
        type="pie"
        data={leftData.map((e) => {
          return { label: e.unitNumber, value: e.count, room: e.unitId };
        })}
        historyRepairing
      />
    </div>
  );
};

const HomeCareBottom = ({ value }: { value: any }) => {
  const params = appendStartAndEndOfTime(value);
  const { data, isLoading, isError, error } = DASHBOARD_HOMECARE_BOTTOM(params);
  const { i18n } = useTranslation();

  if (isLoading) {
    return (
      <div style={{ height: 300 }}>
        <LoadingPage />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div style={{ height: 300 }}>
        <ErrorPage error={error} />
      </div>
    );
  }

  return (
    <div style={{ height: 300 }}>
      <BarChart
        data={data.map((e) => {
          return {
            label: getKeyByLocale({
              data: e,
              key: "categoryName",
              locale: i18n.language,
            }),
            value: e.count,
          };
        })}
      />
    </div>
  );
  // return <BarChart />;
};

const HomeCareMiddle = ({ value }: { value: any }) => {
  const { t } = useTranslation();
  const params = appendStartAndEndOfTime(
    value || [dayjs().startOf("month"), dayjs().endOf("month")]
  );
  const { data, isLoading, isError, error } = DASHBOARD_HOMECARE_MIDDLE(params);
  const [firstTime, setFirstTime] = useState<boolean>(true);
  const [defaultData, setDefaultData] = useState<{
    countRepairingExpiredWarranty: number;
  }>({ countRepairingExpiredWarranty: 0 });

  useEffect(() => {
    if (firstTime && data) {
      setFirstTime(false);
      setDefaultData(data || { countRepairingExpiredWarranty: 0 });
    }
  }, [data, firstTime]);

  if (isLoading) {
    return (
      <div style={{ height: 300 }}>
        <LoadingPage />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div style={{ height: 300 }}>
        <ErrorPage error={error} />
      </div>
    );
  }

  const middleData =
    data.countRepairingExpiredWarranty ||
    typeof data.countRepairingExpiredWarranty === "number"
      ? data
      : defaultData;

  return (
    <div style={{ height: 300 }} className="c-container flex-column">
      <span className="f-28 bold color-gold">
        {numeral(middleData.countRepairingExpiredWarranty).format("0,0")}
      </span>
      <span className="f-18">{t("items")}</span>
    </div>
  );
};

const HomeCareRight = ({ value }: { value: any }) => {
  const { t } = useTranslation();
  const params = appendStartAndEndOfTime(
    value || [dayjs().startOf("month"), dayjs().endOf("month")]
  );
  const { data, isLoading, isError, error } = DASHBOARD_HOMECARE_RIGHT(params);

  const [firstTime, setFirstTime] = useState<boolean>(true);
  const [defaultData, setDefaultData] = useState<{ countOverSla: number }>({
    countOverSla: 0,
  });

  useEffect(() => {
    if (firstTime && data) {
      setFirstTime(false);
      setDefaultData(data || { countOverSla: 0 });
    }
  }, [data, firstTime]);

  if (isLoading) {
    return (
      <div style={{ height: 300 }}>
        <LoadingPage />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div style={{ height: 300 }}>
        <ErrorPage error={error} />
      </div>
    );
  }

  const rightData =
    data.countOverSla || typeof data.countOverSla === "number"
      ? data
      : defaultData;

  return (
    <div style={{ height: 300 }} className="c-container flex-column">
      <span className="f-28 bold color-gold">
        {numeral(rightData.countOverSla).format("0,0")}
      </span>
      <span className="f-18">{t("items")}</span>
    </div>
  );
};

export default HomeCareSection;
