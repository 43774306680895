import {
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
} from "antd";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { useReducer } from "react";
import { useTranslation } from "react-i18next";
import CustomDatePicker from "../../../components/custom-date-picker";
import PageHeaderEdit from "../../../components/page-header-create";
import { GET_FACILITY_CATEGORY } from "../../../store";
import { GET_UNIT } from "../../../store/units";
import { getKeyByLocale } from "../../../tools/translation";
import { bookingReducer, TBookingReducer } from "./reducer";
import th from "antd/es/date-picker/locale/th_TH";
import en from "antd/es/date-picker/locale/en_US";
import {
  FIND_BOOKING_AVAILABLE,
  GET_FACILITY_BOOKING_BY_ID,
  PATCH_FACILITY_BOOKING,
  POST_FACILITY_BOOKING,
} from "../../../store/facility-booking";
import dayjs from "dayjs";
import { useHistory, useParams } from "react-router";
import { swalError, swalSuccess } from "../../../tools/swal";
import { useQueryClient } from "react-query";
import { useEffect } from "react";
import numeral from "numeral";

const EditFacilityBooking = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const client = useQueryClient();

  const { t, i18n } = useTranslation();
  const [form] = useForm();
  const get_units = GET_UNIT({ pagination: false });
  const get_category = GET_FACILITY_CATEGORY({ pagination: false });
  const get_booking = FIND_BOOKING_AVAILABLE();
  const post_booking = POST_FACILITY_BOOKING();
  const patch_booking = PATCH_FACILITY_BOOKING();
  const { data } = GET_FACILITY_BOOKING_BY_ID(id);

  const [state, dispatch] = useReducer<TBookingReducer>(bookingReducer, {
    available: [],
    maxBookingTime: 0,
  });

  useEffect(() => {
    initial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, get_units.data, get_category.data]);

  const initial = () => {
    if (!data || !get_units.data || !get_category.data) {
      return;
    }

    const { bookingPerson, facilityCategory, facility, unit, bookingDate } =
      data;

    const findUnit = get_units.data.data.find((e) => e.id === unit?.id);
    const findCategory = get_category.data.data.find(
      (e) => e.id === facilityCategory.id
    );
    const findFacility = findCategory?.facilities.find(
      (e) => e.id === facility?.id
    );

    const lineName = findUnit?.userUnits.find(
      (e) => e.user.id === bookingPerson?.id
    )?.user?.lineName;

    form.setFieldsValue({
      ...data,
      bookingPerson: bookingPerson?.id,
      facilityCategory: facilityCategory?.id,
      facility: facility?.id,
      unit: unit?.id || undefined,
      lineName,
      date: dayjs(bookingDate),
      additionalCost: findFacility?.additionalCost,
      isAdditionalCost: findFacility?.isAdditionalCost,
    });

    dispatch({ type: "unit", data: findUnit?.userUnits });
    dispatch({ type: "facilities", data: findCategory?.facilities });
    dispatch({
      type: "facility",
      data: findFacility ? findFacility : data.facility,
    });
    dispatch({ type: "date", data: dayjs(bookingDate) });
    dispatch({ type: "stateTime", data: data.bookingDate });
    dispatch({ type: "endTime", data: data.endOfBookingDate });
    dispatch({ type: "phoneNumber", data: data.phoneNumber });

    get_booking.mutate(
      { date: `${dayjs(bookingDate)}`, facility: findFacility?.id },
      {
        onSuccess: (available) => {
          dispatch({ type: "available", data: available });
          dispatch({ type: "maxBookingTime", data: 9999 });
        },
      }
    );
  };

  const onFinish = (values: any) => {
    if (id) {
      return patch_booking.mutate(
        { id, ...values },
        {
          onSuccess: () => {
            swalSuccess(t);
            client.invalidateQueries("/api/facility-booking");
            history.replace("/facility-list/booking");
          },
          onError: ({ message }: any) => {
            swalError(t, message);
          },
        }
      );
    }
    return post_booking.mutate(values, {
      onSuccess: () => {
        swalSuccess(t);
        client.invalidateQueries("/api/facility-booking");
        history.replace("/facility-list/booking");
      },
      onError: ({ message }: any) => {
        swalError(t, message);
      },
    });
  };

  const onValuesChange = (change: any) => {
    const {
      unit,
      facilityCategory,
      date,
      facility,
      bookingDate,
      bookingPerson,
      endOfBookingDate,
    } = change;

    if (unit) {
      const find = get_units.data?.data.find((e) => e.id === unit);
      dispatch({ type: "unit", data: find?.userUnits });
      form.setFieldsValue({
        bookingPerson: undefined,
        lineName: undefined,
        phoneNumber: undefined,
      });
    }

    if (bookingPerson) {
      const unit = form.getFieldValue("unit");
      const find = get_units.data?.data
        .find((e) => e.id === unit)
        ?.userUnits.find((e) => e.user.id === bookingPerson);
      const lineName = find?.user?.lineName;
      const phoneNumber = find?.user?.phoneNumber;
      form.setFieldsValue({ lineName, phoneNumber });
    }

    if (facilityCategory) {
      const find = get_category.data?.data.find(
        (e) => e.id === facilityCategory
      );
      dispatch({ type: "facilities", data: find?.facilities });

      form.setFieldsValue({
        facility: undefined,
        date: undefined,
        bookingDate: undefined,
        endOfBookingDate: undefined,
        numberOfParticipants: undefined,
      });
    }

    if (facility) {
      const { facilities } = state;
      const find = facilities?.find((e) => e.id === facility);
      dispatch({ type: "facility", data: find });
      form.setFieldsValue({
        date: undefined,
        bookingDate: undefined,
        endOfBookingDate: undefined,
        numberOfParticipants: undefined,
        additionalCost: find?.additionalCost,
        isAdditionalCost: find?.isAdditionalCost,
      });
    }

    if (date) {
      form.setFieldsValue({ bookingDate: undefined });
      get_booking.mutate(
        { date: `${date}`, facility: state.facility?.id },
        {
          onSuccess: (data) => {
            dispatch({ type: "available", data: data });
            dispatch({ type: "date", data: date });
            form.setFieldsValue({
              bookingDate: undefined,
              endOfBookingDate: undefined,
              numberOfParticipants: undefined,
            });
          },
          onError: ({ message }: any) => {
            dispatch({ type: "available", data: [] });
            swalError(t, message);
            form.setFieldsValue({
              date: undefined,
              bookingDate: undefined,
              endOfBookingDate: undefined,
              numberOfParticipants: undefined,
            });
          },
        }
      );
    }

    if (bookingDate) {
      form.setFieldsValue({
        endOfBookingDate: undefined,
        numberOfParticipants: undefined,
      });
      dispatch({ type: "stateTime", data: bookingDate });
    }

    if (endOfBookingDate) {
      const maxBookingTime = findMinAvailable(
        state.startTime!,
        endOfBookingDate
      );

      form.setFieldsValue({ numberOfParticipants: undefined });
      dispatch({ type: "endTime", data: endOfBookingDate });
      dispatch({ type: "maxBookingTime", data: maxBookingTime });
    }
  };

  const findMinAvailable = (start: string, end: string): number => {
    const s = dayjs(start);
    const e = dayjs(end);

    let min = -1;

    state.available.forEach((item) => {
      const t = dayjs(item.timeAvailable);
      if (s <= t && e >= t) {
        if (min <= -1) {
          min = item.availableForBooking;
        } else if (item.availableForBooking < min) {
          min = item.availableForBooking;
        }
      }
    });
    return min;
  };

  const FormEdit = () => {
    return (
      <Row gutter={[24, 24]} className="px-4 mb-4 pb-2">
        {!!id && (
          <Col span={12}>
            <Form.Item
              name="status"
              label={`${t("status")}`}
              className="custom-select-ant "
            >
              <Select
                placeholder={`${t("status")}`}
                getPopupContainer={(e) => e}
                disabled={
                  data?.status === "CANCEL" || data?.status === "COMPLETE"
                }
              >
                <Select.Option value="PENDING">{t("pending")}</Select.Option>
                <Select.Option value="COMPLETE">{t("complete")}</Select.Option>
                <Select.Option value="CANCEL">{t("cancel")}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        )}
        {!!id && (
          <Col span={12}>
            <Form.Item
              shouldUpdate={(prev, cur) => prev.status !== cur.status}
              className="w-100"
            >
              {(_form: FormInstance) => {
                const status = _form.getFieldValue("status");
                if (status !== "CANCEL") return null;

                return (
                  <Form.Item
                    name="cancelNote"
                    label={`${t("canceled-note")}`}
                    className="custom-input"
                  >
                    <Input
                      placeholder={`${t("canceled-note")}`}
                      disabled={
                        data?.status === "CANCEL" || data?.status === "COMPLETE"
                      }
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        )}
        {!!id && <Divider />}

        <Col span={12}>
          <Form.Item
            name="unit"
            label={`${t("house-number")}`}
            rules={[{ required: true }]}
            className="custom-select-ant"
          >
            <Select
              placeholder={`${t("house-number")}`}
              //unit number to use, house number to show
              disabled={!!id}
              showSearch
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {get_units.data?.data.map((item, key) => {
                return (
                  <Select.Option key={key} value={item.id}>
                    {item.unitNumber}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="bookingPerson"
            label={`${t("name")}`}
            rules={[{ required: true }]}
            className="custom-select-ant"
          >
            <Select
              placeholder={`${t("name")}`}
              disabled={!state.unitUser || !!id}
            >
              {state?.unitUser?.map((item, index) => {
                return (
                  <Select.Option key={index} value={item.user.id}>
                    {item.user.fullName}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="lineName"
            label={t("line-name")}
            className="custom-input"
          >
            <Input placeholder={t("line-name")} disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="phoneNumber"
            label={t("phone-number")}
            className="custom-input"
            rules={[{ required: true }]}
          >
            <Input
              placeholder={t("phone-number")}
              disabled={
                data?.status === "CANCEL" || data?.status === "COMPLETE"
              }
            />
          </Form.Item>
        </Col>
        <Divider className="mb-0" />
        <Col span={12}>
          <Form.Item
            name="facilityCategory"
            label={`${t("menu.setting-facility-category")}`}
            rules={[{ required: true }]}
            className="custom-select-ant"
          >
            <Select
              placeholder={`${t("menu.setting-facility-category")}`}
              disabled={!!id}
            >
              {get_category.data?.data.map((item, index) => {
                return (
                  <Select.Option
                    key={index}
                    value={item.id}
                    disabled={item.status === "INACTIVE"}
                  >
                    {getKeyByLocale({
                      key: "name",
                      data: item,
                      locale: i18n.language,
                    })}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="facility"
            label={`${t("menu.setting-facility")}`}
            rules={[{ required: true }]}
            className="custom-select-ant"
          >
            <Select
              disabled={!state.facilities || !!id}
              placeholder={`${t("menu.setting-facility")}`}
            >
              {state?.facilities?.map((item, index) => {
                return (
                  <Select.Option
                    key={index}
                    value={item.id}
                    disabled={item.status === "INACTIVE"}
                  >
                    {getKeyByLocale({
                      key: "name",
                      data: item,
                      locale: i18n.language,
                    })}
                    {item.status === "INACTIVE"
                      ? ` | ${getKeyByLocale({
                          data: item,
                          key: "inactiveNote",
                          locale: i18n.language,
                        })}`
                      : ""}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="date"
            label={`${t("date")}`}
            rules={[{ required: true }]}
            className="custom-ant-picker"
          >
            <CustomDatePicker
              locale={i18n.language === "en" ? en : th}
              format={i18n.language === "en" ? "DD-MM-YYYY" : "DD-MM-BBBB"}
              disabled={!state.facilities || !state.facility || !!id}
              allowClear={false}
            />
          </Form.Item>
        </Col>
        {/* <Col span={12}></Col> */}
        <Col span={12}>
          <Form.Item
            name="bookingDate"
            label={`${t("from")} ${t("time")}`}
            rules={[{ required: true }]}
            className="custom-select-ant"
          >
            <Select
              placeholder={`${t("from")} ${t("time")}`}
              disabled={state.available.length <= 0 || !!id}
              loading={get_booking.isLoading}
            >
              {state.available.map((item, index) => {
                const { bookInAdvance } = state.facility!;
                const { timeAvailable, isFull, availableForBooking } = item;
                const now = dayjs();
                const available = dayjs(timeAvailable);

                const advanceTime = now.clone().add(bookInAdvance || 0, "hour");
                const isAdvance = available.diff(advanceTime, "minute") < 0;
                const isOutOfTime = available.diff(now, "minute") < 0;

                return (
                  <Select.Option
                    key={index}
                    value={timeAvailable}
                    disabled={isFull || isAdvance || isOutOfTime}
                  >
                    {`${dayjs(timeAvailable).format("HH:mm")} | ${t(
                      "remaining"
                    )}: ${availableForBooking}`}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="endOfBookingDate"
            label={`${t("to")} ${t("time")}`}
            rules={[{ required: true }]}
            className="custom-select-ant"
          >
            <Select
              placeholder={`${t("to")} ${t("time")}`}
              disabled={!state.startTime || !!id}
              loading={get_booking.isLoading}
            >
              {state.available.map((item, index) => {
                const { startTime, facility } = state;
                const { timeAvailable, availableForBooking, isFull } = item;
                const available = dayjs(timeAvailable);
                const start = dayjs(startTime);
                const max = facility?.maximumBookingHour || 0;

                const isBeforeStartTime =
                  available.diff(start.clone().add(1, "hour"), "minute") < 0;

                const maxHours = available.diff(start, "hour");

                const isMaximumHour = max > 0 && max < maxHours;
                return (
                  <Select.Option
                    key={index}
                    value={timeAvailable}
                    disabled={isBeforeStartTime || isMaximumHour || isFull}
                  >
                    {`${dayjs(timeAvailable).format("HH:mm")} | ${t(
                      "remaining"
                    )}: ${availableForBooking}`}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="numberOfParticipants"
            label={`${t("number-of-users")}`}
            rules={[{ required: true }]}
            className="custom-input-number"
          >
            <InputNumber
              disabled={!!id || !state.endTime}
              min={1}
              placeholder={`${t("number-of-users")}`}
              max={state.maxBookingTime}
            />
          </Form.Item>
        </Col>
        <Col hidden>
          <Form.Item name="additionalCost">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t("additional-cost")}>
            <Row className="d-lfex align-center">
              <Col>
                <Form.Item
                  name="isAdditionalCost"
                  className="custom-ant-switch"
                  valuePropName="checked"
                >
                  <Switch disabled />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  shouldUpdate={(prev, cur) => prev.facility !== cur.facility}
                >
                  {(_form: FormInstance) => {
                    const additional = form.getFieldValue("additionalCost");
                    const isAdditionalCost =
                      form.getFieldValue("isAdditionalCost");

                    if (isAdditionalCost)
                      return (
                        <span className="px-2" style={{ paddingTop: "6px" }}>
                          {numeral(additional).format("0,0.00")} {t("baht")}
                        </span>
                      );
                  }}
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <div className="bg-white color-black pb-1">
        <Form
          onValuesChange={onValuesChange}
          layout="vertical"
          form={form}
          onFinish={onFinish}
          className="fixed-input-size"
        >
          <PageHeaderEdit
            label="menu.facility-list-booking"
            labelTo="menu.facility-list-booking"
            isLoading={post_booking.isLoading || patch_booking.isLoading}
          />
          <FormEdit />
        </Form>
      </div>
    </>
  );
};

// const TimeGenerater = ({
//   onChange,
//   value,
//   isLoading,
//   isError,
//   state,
//   disabled,
// }: {
//   onChange?: any;
//   value?: any;
//   state: IBookingState;
//   isLoading: boolean;
//   isError: boolean;
//   disabled?: boolean;
// }) => {
//   const { t } = useTranslation();
//   const { facility, available, date } = state;
//   const now = dayjs();

//   if (!available || !facility || !date) {
//     return (
//       <div
//         style={{ height: 100 }}
//         className="d-flex align-center justify-center"
//       >
//         <span>{t("please-select-facility-time")}</span>
//       </div>
//     );
//   }

//   if (isError) {
//     return <div>something wrong</div>;
//   }

//   if (isLoading) {
//     return (
//       <div
//         style={{ height: 100 }}
//         className="d-flex align-center justify-center"
//       >
//         <Loader />
//       </div>
//     );
//   }

//   if (
//     available.length <= 0 ||
//     !facility.openTime ||
//     !facility.closeTime ||
//     !facility.maximumBookingHour
//   ) {
//     return (
//       <div
//         style={{ height: 100 }}
//         className="d-flex align-center justify-center"
//       >
//         <span>{t("missing-facility-information")}</span>
//       </div>
//     );
//   }

//   const onSelect = (date: string) => {
//     onChange(date);
//   };

//   return (
//     <Row gutter={[24, 24]}>
//       {available.map((item, index) => {
//         const { timeAvailable, maximumBookingHour, isFull } = item;
//         const { bookInAdvance } = facility;
//         const s = dayjs(timeAvailable);
//         const e = dayjs(timeAvailable).add(maximumBookingHour, "hour");

//         const isOutOfTime = s.diff(now, "minute") < 0;

//         //เอาเวลา start ตั้ง diff ด้วยเวลาปัจจุบัน
//         const isEndOfTime =
//           s.diff(now.add(bookInAdvance || 0, "hour"), "minute") < 0;
//         const active = timeAvailable === value ? "active" : "";
//         const cannotSelect = isFull || isOutOfTime || isEndOfTime || disabled;
//         return (
//           <Col
//             span={4}
//             key={index}
//             className={`${active} ${cannotSelect ? "disabled" : ""}`}
//           >
//             <div
//               onClick={() => {
//                 if (!cannotSelect && !disabled) {
//                   onSelect(timeAvailable);
//                 }
//               }}
//               className="booking-time-btn"
//             >
//               {s.format("HH:mm")} - {e.format("HH:mm")}
//             </div>
//           </Col>
//         );
//       })}
//     </Row>
//   );
// };

export default EditFacilityBooking;
