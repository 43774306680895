import React from "react";

const NEWS = (props: React.SVGProps<SVGSVGElement> = {}) => {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141.73 141.73"
      {...props}
    >
      <path
        data-name="Path 20619"
        d="M3 3v135.78h135.78V3zm105.6 105.6H33.14V93.51h75.46zm0-30.19H33.14V63.33h75.46zm0-30.19H33.14V33.14h75.46z"
        fill="#b59d4e"
      />
    </svg>
  );
};

export default NEWS;
