import { ScanOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import MobileHeader from "../../../components/page-header-mobile";
import { GET_USER_PROFILE } from "../../../store/auth";
import { PARCEL_LIST_PATH } from "../../router-mobile/path";

const Parcel = () => {
  const user = GET_USER_PROFILE();
  const { t } = useTranslation();
  const { push } = useHistory();
  const onClick = () => {
    return push(PARCEL_LIST_PATH);
  };
  return (
    <div className="w-100 h-100">
      <MobileHeader isGoBack={user.role.title === "JURISTIC" && true} />
      <Row className="h-80" align="middle">
        <Col span={24} className="d-grid justify-center">
          <div className="f-20 font-bold" style={{ color: "#B79965" }}>
            {t("mobile.scan-to-receive-parcels")}
          </div>
          <div className="d-flex justify-center">
            <Button
              icon={<ScanOutlined className="f-18 mt-2" />}
              className="button-scan-mobile f-18 mt-4"
              style={{ width: "160px", height: 45 }}
              onClick={onClick}
            >
              {t("mobile.scan")}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Parcel;
