import { Col, Form, Row } from "antd";
import { FormInstance } from "rc-field-form";
import { useTranslation } from "react-i18next";
import {
  DASHBOARD_PRIVILEGE_MOST,
  DASHBOARD_PRIVILEGE_USE,
} from "../../../store/dashboard";
import { getKeyByLocale } from "../../../tools/translation";
import BarChart from "../bar-chart";
import DonutChart from "../donut-chart";
import {
  appendStartAndEndOfTime,
  DBHeader,
  ErrorPage,
  LoadingPage,
} from "../header";

const privilegeLeft = "privilegeLeft";
const privilegeRight = "privilegeRight";

const PrivilegeSection = () => {
  const { t } = useTranslation();

  return (
    <div className="p-4 bg-white">
      <p className="f-24 mb-4 bold">{t(`menu.privilege`)}</p>
      <Row gutter={12}>
        <Col span={8}>
          <div className="p-3 db-border h-100">
            <DBHeader
              label={t("top-3-people-who-use-privilege-the-most")}
              selectType="range"
              formItemName={privilegeLeft}
            />
            <Form.Item
              shouldUpdate={(prev, next) =>
                prev[privilegeLeft] !== next[privilegeLeft]
              }
              noStyle
            >
              {(form: FormInstance) => {
                const value = form.getFieldValue(privilegeLeft);
                return <PrivilegeLeft value={value} />;
              }}
            </Form.Item>
          </div>
        </Col>
        <Col span={16}>
          <div className="p-3 db-border h-100">
            <DBHeader
              label={t("dashboard-most-privilege")}
              selectType="range"
              formItemName={privilegeRight}
            />
            <Form.Item
              shouldUpdate={(prev, next) =>
                prev[privilegeRight] !== next[privilegeRight]
              }
              noStyle
            >
              {(form: FormInstance) => {
                const value = form.getFieldValue(privilegeRight);
                return <PrivilegeRight value={value} />;
              }}
            </Form.Item>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const PrivilegeLeft = ({ value }: { value: any }) => {
  const params = appendStartAndEndOfTime(value);
  const { data, isError, isLoading, error } = DASHBOARD_PRIVILEGE_USE(params);

  if (isLoading) {
    return (
      <div style={{ height: 300 }}>
        <LoadingPage />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div style={{ height: 300 }}>
        <ErrorPage error={error} />
      </div>
    );
  }

  return (
    <div style={{ height: 300 }}>
      <DonutChart
        type="donut"
        data={data.map((e) => {
          return { label: e.userFullName, value: e.count };
        })}
      />
    </div>
  );
};

const PrivilegeRight = ({ value }: { value: any }) => {
  const params = appendStartAndEndOfTime(value);
  const { data, isError, isLoading , error} = DASHBOARD_PRIVILEGE_MOST(params);
  const { i18n } = useTranslation();

  if (isLoading) {
    return (
      <div style={{ height: 300 }}>
        <LoadingPage />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div style={{ height: 300 }}>
        <ErrorPage error={error} />
      </div>
    );
  }

  return (
    <div style={{ height: 300 }}>
      <BarChart
        data={data.map((e) => {
          return {
            label: getKeyByLocale({
              data: e,
              key: "header",
              locale: i18n.language,
            }),
            value: e.reach,
          };
        })}
      />
    </div>
  );
};

export default PrivilegeSection;
