import { Form } from "antd";
import { useTranslation } from "react-i18next";
import PageHeaderEdit from "../../../components/page-header-create";
import { validateMessages } from "../../../config/validateMessage";
import { GET_SERVICE_CATEGORY } from "../../../store/service-category";
import { GET_UNIT } from "../../../store/units";
import { getKeyByLocale } from "../../../tools/translation";
import { useHistory, useParams } from "react-router-dom";
import {
  GET_GENERATE_QR_CODE_BOT_SERVICE_REQUEST,
  // GET_GENERATE_QR_CODE_SCB_SERVICE_REQUEST,
  GET_SERVICE_REQUEST_ID,
  PATCH_SERVICE_REQUEST,
  POST_SERVICE_REQUEST,
} from "../../../store/service-request";
import { useEffect, useState } from "react";
import { swalError, swalSuccess } from "../../../tools/swal";
import { SERVICE_ORDER_PATH } from "../../../router/path";
import { useQueryClient } from "react-query";
import dayjs from "dayjs";
import { GET_SERVICE_LIST, IService } from "../../../store/service";
import UploadImage from "../../../components/custom-upload-image";
import PriceInput from "./input/price-input";
import ServiceInput from "./input/service-input";
import UnitInput from "./input/unit-input";
import DetailInput from "./input/detail-input";
import PaymentMethode from "./input/payment-methode";

const ServiceOrderEditPage = () => {
  const { id } = useParams<{ id?: string }>();
  const [form] = Form.useForm();
  const client = useQueryClient();
  const { replace } = useHistory();
  const { t, i18n } = useTranslation();

  const { data, refetch } = GET_SERVICE_REQUEST_ID(id);
  const get_units = GET_UNIT({ pagination: false });
  const get_service_category = GET_SERVICE_CATEGORY({ pagination: false });
  const get_service_Item = GET_SERVICE_LIST({ pagination: false });
  // const generateQrSCB = GET_GENERATE_QR_CODE_SCB_SERVICE_REQUEST();
  const generateQrBOT = GET_GENERATE_QR_CODE_BOT_SERVICE_REQUEST();

  const create = POST_SERVICE_REQUEST();
  const update = PATCH_SERVICE_REQUEST();

  const [calculatePrice, setCalculatePrice] = useState<{
    price?: number;
    priceWithSvg?: number;
    priceWithVat?: number;
    totalPrice?: number;
  }>({
    price: undefined,
    priceWithSvg: undefined,
    priceWithVat: undefined,
    totalPrice: undefined,
  });

  useEffect(() => {
    initial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const taxId = get_service_Item.data?.data.find(
      (item) => item.id === data?.serviceItem.id
    )?.billType.taxId;
    const unitReference = get_units.data?.data.find(
      (item) => item.id === data?.unit?.id
    )?.unitReference;
    if (!form.getFieldValue("taxId")) {
      if (taxId && !data?.taxId) form.setFieldsValue({ taxId });
    }
    if (!form.getFieldValue("unitReference")) {
      if (unitReference && !data?.unitReference)
        form.setFieldsValue({ unitReference });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, get_units]);

  useEffect(() => {
    if (!data) return;
    const { serviceItem } = data;
    const surcharge = get_service_Item.data?.data.find(
      (item) => item.id === serviceItem.id
    )?.extraPrice;
    form.setFieldsValue({ surcharge });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, get_service_Item]);

  const initial = () => {
    if (!data) return;
    const {
      bookingDate,
      createdAt,
      detail,
      status,
      serviceCategory,
      serviceItem,
      unit,
      user,
      paymentType,
      bookingHour,
      adminDetail,
      requirePrivate,
      phoneNumber,
      receiptImage,
      totalPrice,
      priceWithVat,
      priceWithSvg,
      svc,
      vat,
      paymentDate,
      serviceRef,
      taxId,
      unitReference,
    } = data;

    const nameServiceCategory = getKeyByLocale({
      key: "name",
      data: serviceCategory,
      locale: i18n.language,
    });

    const nameServiceItem = getKeyByLocale({
      key: "name",
      data: serviceItem,
      locale: i18n.language,
    });

    const lineName = user?.lineName;

    const values = {
      bookingDate: bookingDate ? dayjs(bookingDate) : undefined,
      createdAt: dayjs(createdAt) || undefined,
      detail: detail,
      setPrice: Number(totalPrice).toFixed(2) || undefined,
      status: status,
      serviceCategory: nameServiceCategory || undefined,
      serviceItem: nameServiceItem || undefined,
      unit: unit?.unitNumber || undefined,
      user: user?.fullName || undefined,
      paymentType: paymentType || undefined,
      bookingHour: bookingHour,
      adminDetail: adminDetail,
      requirePrivate: requirePrivate,
      phoneNumber: phoneNumber || user.phoneNumber,
      lineName,
      picture: receiptImage,
      priceWithSvg,
      priceWithVat,
      svc,
      vat,
      paymentDate: paymentDate ? dayjs(paymentDate) : undefined,
      serviceRef,
      taxId,
      unitReference,
    };

    form.setFieldsValue(values);
  };

  const afterMutation = {
    onSuccess: (res: any) => {
      swalSuccess();
      if (!id) generateQrCode(res.id, false);
      client.invalidateQueries([
        "get-service-request",
        "get-service-request-id",
      ]);
      replace(SERVICE_ORDER_PATH.index);
    },
    onError: ({ message }: any) => {
      swalError(t, message);
    },
  };

  const onFinish = (values: any, goBack?: boolean) => {
    const {
      status,
      adminDetail,
      paymentType,
      bookingHour,
      bookingDate,
      phoneNumber,
      picture,
      serviceRef,
      unitReference,
      taxId,
    } = values;

    // console.log(typeof calculatePrice?.price, Number(calculatePrice.price));

    const value = {
      price:
        typeof calculatePrice?.price === "number"
          ? Number(calculatePrice?.price)
          : undefined,
      priceWithSvg:
        typeof calculatePrice?.priceWithSvg === "number"
          ? Number(calculatePrice?.priceWithSvg)
          : undefined,
      priceWithVat:
        typeof calculatePrice?.priceWithVat === "number"
          ? Number(calculatePrice?.priceWithVat)
          : undefined,
      totalPrice:
        typeof calculatePrice?.totalPrice === "number"
          ? Number(calculatePrice?.totalPrice)
          : undefined,
      status: status,
      adminDetail: adminDetail,
      paymentType: paymentType,
      phoneNumber: phoneNumber,
      picture,
      serviceRef,
      unitReference,
      taxId,
    };

    // console.log(value);

    const serviceItem = form.getFieldValue("serviceItem");
    const findServiceItem = get_service_Item.data?.data.find(
      (e) => e.id === serviceItem
    ) as IService;

    const reg = /^-?\d*(\.\d*)?$/;
    const newRef = getNewRef();

    if (values.serviceRef)
      if (isNaN(values.serviceRef) && !reg.test(values.serviceRef))
        return swalError(
          t,
          t("service-order-reference-number") + ": " + t("validate.number")
        );

    if (id) {
      return update.mutate(
        { ...value, ...newRef, id },
        {
          onSuccess: (res: any) => {
            if (goBack !== false) swalSuccess();
            if (!data?.qrImage || !data?.qrBotImage)
              generateQrCode(+id, goBack);
            client.invalidateQueries([
              "get-service-request",
              "get-service-request-id",
            ]);
            if (goBack !== false) replace(SERVICE_ORDER_PATH.index);
          },
          onError: ({ message }: any) => {
            swalError(t, message);
          },
        }
      );
    }
    return create.mutate(
      {
        ...values,
        price: Number(calculatePrice?.price || 0),
        priceWithSvg: Number(calculatePrice?.priceWithSvg || 0),
        priceWithVat: Number(calculatePrice?.priceWithVat || 0),
        totalPrice: Number(values.setPrice || 0),
        bookingDate: bookingDate
          ? dayjs(bookingDate).minute(0).second(0).toISOString()
          : undefined,
        bookingHour: bookingHour,
        svc: Number(findServiceItem.svg),
        vat: Number(findServiceItem.vat),
      },
      afterMutation
    );
  };

  const generateQrCode = (createId: number, goBack?: boolean) => {
    // generateQrSCB.mutate(createId, {
    //   onError: (err: any) => {
    //     swalError(t, err.message);
    //   },
    //   onSuccess: () => {
    //     if (goBack === false)
    //       swalSuccess(t, `${t("qr-code-scb")} ${t("success")}`);
    //   },
    // });
    generateQrBOT.mutate(createId, {
      onError: (err: any) => {
        swalError(t, err.message);
      },
      onSuccess: () => {
        if (goBack === false)
          swalSuccess(t, `${t("qr-code-bot")} ${t("success")}`);
      },
    });
  };

  const getNewRef = () => {
    const serviceId = form.getFieldValue("serviceItem");
    const taxId = get_service_Item.data?.data?.find(
      (item) => item.id === serviceId
    )?.billType?.taxId;
    const unitId = form.getFieldValue("unit");
    const unitReference = get_units.data?.data?.find(
      (item) => item.id === unitId
    )?.unitReference;
    return { taxId, unitReference };
  };

  return (
    <div className="bg-white color-black pb-1">
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        validateMessages={validateMessages}
        className="main-input-form"
      >
        <PageHeaderEdit
          label="menu.service-order"
          isLoading={create.isLoading || update.isLoading}
        />
        <UnitInput units={get_units.data?.data || []} id={id} form={form} />
        <ServiceInput
          units={get_units.data?.data || []}
          form={form}
          category={get_service_category.data?.data || []}
          id={id}
          serviceItems={get_service_Item.data?.data || []}
          setCalculatePrice={setCalculatePrice}
        />
        <PriceInput
          serviceItems={get_service_Item.data?.data || []}
          form={form}
          units={get_units.data?.data || []}
          setCalculatePrice={setCalculatePrice}
          data={data}
        />
        <DetailInput id={id} />
        <PaymentMethode
          id={id}
          data={data}
          units={get_units.data?.data || []}
          serviceItem={get_service_Item.data?.data || []}
          onFinish={(e, gb) => onFinish(e, gb)}
          // generateQrSCB={generateQrSCB}
          generateQrBOT={generateQrBOT}
          refetch={refetch}
        />
        {id && (
          <div className="px-4 pb-4">
            <Form.Item
              name="picture"
              label={t("attached-picture")}
              className="custom-ant-upload-image"
            >
              <UploadImage onPage="service-order" isReceiptImage />
            </Form.Item>
          </div>
        )}
      </Form>
    </div>
  );
};
export default ServiceOrderEditPage;
