import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import CustomStatusBackground from "../../../components/custom-status";
import CustomTable from "../../../components/custom-table";
import PageFilter, { IPageFilterChoice } from "../../../components/page-filter";
import PageHeader, { ISearchInput } from "../../../components/page-header";
import { SETTING_FACILITY_CATEGORY_CREATE_PATH } from "../../../router/path";
import { page_params } from "../../../store";
import {
  BULK_DELETE_FACILITY_CATEGORY,
  DELETE_FACILITY_CATEGORY,
  GET_FACILITY_CATEGORY,
} from "../../../store/facility-category";
import { parse } from "../../../tools/datetime";
import { swalDelete, swalError, swalSuccess } from "../../../tools/swal";

const FacilityCategoryPage = () => {
  const { t, i18n } = useTranslation();
  const [params, setParams] = useRecoilState(page_params);
  const { data, refetch, isLoading } = GET_FACILITY_CATEGORY(params);
  const { mutate } = DELETE_FACILITY_CATEGORY();
  const bulkDelete = BULK_DELETE_FACILITY_CATEGORY();

  const searchInput: ISearchInput[] = [
    {
      label: t("search"),
      key: "search",
      span: 10,
      type: "input",
      props: {
        placeholder: t("search-name-EN-TH-create-by"),
      },
    },
    {
      label: t("creation-date"),
      key: "date",
      span: 7,
      type: "range-picker",
      props: {
        placeholder: [t("start-date"), t("end-date")],
        getPopupContainer: (props: HTMLElement) => {
          return props;
        },
      },
    },
  ];

  const countData = data?.countData;

  const choices: IPageFilterChoice[] = [
    {
      label: t("all"),
      total: countData?.countAll,
    },
    {
      label: t("active"),
      key: "ACTIVE",
      total: countData?.countActive,
    },
    {
      label: t("inactive"),
      key: "INACTIVE",
      total: countData?.countInActive,
    },
  ];

  const columns: ColumnsType = [
    {
      dataIndex: i18n.language === "en" ? "nameEn" : "nameTh",
      title: t("name"),
      width: "20%",
      render: (text, __) => {
        return <div className="line-clamp">{text || "-"}</div>;
      },
    },
    {
      dataIndex: "updatedAt",
      title: t("update"),
      render: (text, record) => {
        return (
          <p style={{ whiteSpace: "pre", display: "contents" }}>
            {text
              ? parse(
                  text,
                  i18n.language,
                  `${i18n.language === "en" ? "DD MMM YYYY" : "DD MMM BBBB"}`
                )
              : "-"}
          </p>
        );
      },
    },
    {
      dataIndex: "createdBy",
      title: t("created-by"),
      render: (text, __) => {
        return (
          <p style={{ whiteSpace: "pre", display: "contents" }}>
            {text || "-"}
          </p>
        );
      },
    },
    {
      dataIndex: "status",
      title: t("status"),
      render: (text, record) => {
        return (
          <>
            {!text && "-"}
            {text === "INACTIVE" && (
              <CustomStatusBackground
                lable={t("inactive")}
                className={"frame-no-background"}
              />
            )}
            {text === "ACTIVE" && (
              <CustomStatusBackground
                lable={t("active")}
                className={"frame-background"}
              />
            )}
          </>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    if (values.date)
      values = {
        ...values,
        startDate: moment(values.date[0]).format("YYYY-MM-DD 00:00"),
        endDate: moment(values.date[1]).format("YYYY-MM-DD 23:59"),
      };
    delete values.date;
    setParams({ ...params, ...values });
  };

  const onFilter = (choice: IPageFilterChoice) => {
    setParams({ ...params, status: choice.key });
  };

  const onDelete = async (id: number) => {
    const confirm = await swalDelete(t);
    if (confirm) {
      mutate(
        { id },
        {
          onError: (err: any) => {
            swalError(t, t(err.message));
          },
          onSuccess: () => {
            swalSuccess(t);
            refetch();
          },
        }
      );
    }
  };

  const onReset = () => {
    const values = { startDate: undefined, endDate: undefined };
    setParams({
      ...params,
      ...values,
    });
  };

  const _getCurrentFilter = (): string | undefined => {
    const { status } = params;
    if (status) return status;
    return undefined;
  };

  return (
    <div>
      <PageHeader
        menu="setting-facility-category"
        onSearch={onSearch}
        searchInput={searchInput}
        label={"create"}
        path={SETTING_FACILITY_CATEGORY_CREATE_PATH}
        utility={["delete"]}
        bulkDelete={bulkDelete}
        invalidateQueries={["setting-facility-categories"]}
        onReset={onReset}
      />
      <PageFilter
        choices={choices}
        current={_getCurrentFilter()}
        onClick={onFilter}
      />
      <CustomTable
        rowKey="id"
        title={t("facility-category-lists")}
        dataSource={data?.data || []}
        columns={columns}
        pagination={{
          limit: params.limit,
          page: params.page,
          total: data?.count || 0,
        }}
        onDelete={onDelete}
        onPageChange={(page, limit) => setParams({ ...params, page, limit })}
        isLoadingTable={isLoading}
      />
    </div>
  );
};

export default FacilityCategoryPage;
