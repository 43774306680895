import { IResponse } from ".";
import axios from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { throwResponse } from "../config/axios";
import { IServiceCategory } from "./service-category";
import { IBillType } from "./bill";

export interface IService {
  id: number;
  createdAt: string;
  updatedAt: string;
  nameEn: string;
  nameTh: string;
  status: "ACTIVE" | "INACTIVE";
  openTime: string;
  closeTime: string;
  capacity: number;
  maximumBookingHour: number;
  descriptionEn: string;
  descriptionTh: string;
  isHaveCondition?: boolean;
  serviceCategory: IServiceCategory;
  billType: IBillType;
  bookInAdvance: number;
  duplexPrice: string;
  extraPrice: string;
  fourBedroomPrice: string;
  penthousePrice: string;
  pricePerHour: string;
  requireDetail: boolean;
  requireOpenCloseTime: boolean;
  requirePrice: boolean;
  requirePricePerHour: boolean;
  requirePrivate: boolean;
  requireSvg: boolean;
  requireVat: boolean;
  svg: string;
  threeBedroomPrice: string;
  twoBedroomPrice: string;
  vat: string;
  oneBedroomPrice: string;
}

export interface IServiceParams {
  page?: number;
  limit?: number;
  pagination?: boolean;
  search?: string;
  status?: string;
  createdAtStartDate?: string;
  createdAtEndDate?: string;
}

export const GET_SERVICE_LIST = (
  params?: IServiceParams
): UseQueryResult<IResponse<IService[]>> => {
  return useQuery(
    ["get-services", params],
    async () => {
      const res = await axios.get(`/api/service-item`, { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    {
      keepPreviousData: true,
    }
  );
};

export const GET_SERVICE_LIST_BY_ID = (
  id?: string
): UseQueryResult<IService> => {
  return useQuery(["get-services-id", id], async () => {
    if (!id) return;
    const res = await axios.get(`/api/service-item/${id}`);
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

export const POST_SERVICE_LIST = (): UseMutationResult => {
  return useMutation(async (values) => {
    const res = await axios.post("/api/service-item", values);
    if (res.status === 201) {
      return;
    }
    throwResponse(res);
  });
};

export const PATCH_SERVICE_LIST = (): UseMutationResult => {
  return useMutation(async (values: any) => {
    const id = values.id;
    delete values.id;

    const res = await axios.patch(`/api/service-item/${id}`, values);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const DELETE_SERVICE_LIST = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/api/service-item/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const BULK_DELETE_SERVICE_LIST = (): UseMutationResult => {
  return useMutation(async (values) => {
    const res = await axios.delete(`/api/service-item/bulk`, { data: values });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
