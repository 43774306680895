import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import CustomStatusBackground from "../../../components/custom-status";
// import { useTranslation } from "react-i18next";
import CustomTable from "../../../components/custom-table";
import PageHeader from "../../../components/page-header";
import { GET_CONTENT_ABOUT_US } from "../../../store";
import { parse } from "../../../tools/datetime";

const ContentAboutUsPage = () => {
  const { data, isLoading } = GET_CONTENT_ABOUT_US();
  const { t, i18n } = useTranslation();

  const columns: ColumnsType = [
    {
      dataIndex: i18n.language === "en" ? "nameEn" : "nameTh",
      title: t("name"),
      render: (text, __) => {
        return <div className="line-clamp">{text || "-"}</div>;
      },
    },
    {
      dataIndex: "createdAt",
      title: t("creation-date"),
      render: (text, record) => {
        return (
          <p style={{ whiteSpace: "pre", display: "contents" }}>
            {text
              ? parse(
                  text,
                  i18n.language,
                  `${i18n.language === "en" ? "DD MMM YYYY" : "DD MMM BBBB"}`
                )
              : "-"}
          </p>
        );
      },
    },
    {
      dataIndex: "updatedAt",
      title: t("update"),
      render: (text, record) => {
        return (
          <p style={{ whiteSpace: "pre", display: "contents" }}>
            {text
              ? parse(
                  text,
                  i18n.language,
                  `${i18n.language === "en" ? "DD MMM YYYY" : "DD MMM BBBB"}`
                )
              : "-"}
          </p>
        );
      },
    },
    {
      dataIndex: "status",
      title: t("status"),
      render: (text, record) => {
        return (
          <>
            {!text && "-"}
            {text === "INACTIVE" && (
              <CustomStatusBackground
                lable={t("inactive")}
                className={"frame-no-background"}
              />
            )}
            {text === "ACTIVE" && (
              <CustomStatusBackground
                lable={t("active")}
                className={"frame-background"}
              />
            )}
          </>
        );
      },
    },
  ];

  return (
    <div>
      <PageHeader
        menu="setting-content-about-us"
        onSearch={() => {}}
        searchInput={[]}
        utility={[]}
        hasCreateButton={false}
      />
      <CustomTable
        title={t("content-about-us")}
        dataSource={data || []}
        columns={columns}
        actions={["edit"]}
        hasIndex={false}
        pagination={{ limit: 10, page: 1, total: data?.length || 0 }}
        isLoadingTable={isLoading}
        disabledSelect={true}
        disablePagination={true}
      />
    </div>
  );
};

export default ContentAboutUsPage;
