import { Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { fixed_hieght } from "./page-header";

export interface IPageFilterChoice {
  label: string;
  key?: any;
  total?: number;
}

const PageFilter = ({
  choices,
  current,
  onClick,
  showTotal = true,
  isLoading,
  parcelAction,
  paymentAction,
}: {
  choices: IPageFilterChoice[];
  current?: any;
  onClick: (value: IPageFilterChoice) => void;
  showTotal?: boolean;
  isLoading?: boolean;
  parcelAction?: () => void;
  paymentAction?: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div className="mt-4 d-flex align-center">
      <Row gutter={[12, 12]} className="w-100">
        {choices.map((item, index) => {
          const active = item.key === current;
          return (
            <Col key={index} span={4} onClick={() => onClick(item)}>
              <div
                className={`page-filter-button ${
                  active ? "page-filter-button-active" : ""
                }`}
              >
                <span>{t(item.label)}</span>
                {showTotal && <span className="ml-1">({item.total})</span>}
              </div>
            </Col>
          );
        })}
      </Row>
      {parcelAction && (
        <div>
          <Button
            className="button-save f-18"
            style={{ width: 180, height: fixed_hieght }}
            onClick={parcelAction}
            loading={isLoading}
          >
            {t("checkout")}
          </Button>
        </div>
      )}
      {paymentAction && (
        <div>
          <Button
            className="button-save f-18 ml-3"
            style={{ width: 150, height: fixed_hieght }}
            onClick={paymentAction}
            loading={isLoading}
          >
            {t("complete")}
          </Button>
        </div>
      )}
    </div>
  );
};

export default PageFilter;
