import { ColumnsType } from "antd/lib/table";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import CustomStatusBackground from "../../../components/custom-status";
import CustomTable from "../../../components/custom-table";
import PageFilter, { IPageFilterChoice } from "../../../components/page-filter";
import PageHeader, { ISearchInput } from "../../../components/page-header";
import { FOOD_ORDER_PATH } from "../../../router/path";
import {
  BULK_DELETE_FOOD_ORDER,
  DELETE_FOOD_ORDER,
  EXPORT_FOOD_ORDER,
  GET_FOOD_ORDER,
  IFoodOrder,
  IFoodOrderParams,
  LINE_NOTIFICATION_FOOD_ORDER,
} from "../../../store/food-order";
import { useNotification } from "../../../store/notification";
import { findStartOrEndTime, parse } from "../../../tools/datetime";
import { swalDelete, swalError, swalSuccess } from "../../../tools/swal";

const FoodOrderPage = () => {
  const { t, i18n } = useTranslation();
  const client = useQueryClient();
  const [params, setParams] = useState<IFoodOrderParams>({
    page: 1,
    limit: 10,
  });

  const { data, isLoading, refetch } = GET_FOOD_ORDER(params);
  const delete_order = DELETE_FOOD_ORDER();
  const bulk_delete_order = BULK_DELETE_FOOD_ORDER();
  const exportExcel = EXPORT_FOOD_ORDER();
  const lineNotification = LINE_NOTIFICATION_FOOD_ORDER();

  const { tableNoti, dispatch } = useNotification();

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableNoti]);

  useEffect(() => {
    return () =>
      dispatch!({
        type: "table",
        action: "clear",
        key: ["food", "food-order"],
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchInput: ISearchInput[] = [
    {
      label: t("search"),
      key: "search",
      span: 10,
      type: "input",
      props: {
        placeholder: `${t("house-number")} / ${t("name")} / ${t(
          "order-number"
        )}`,
      },
    },
    {
      label: t("order-date"),
      key: "date",
      span: 7,
      type: "range-picker",
      props: {
        placeholder: [t("start-date"), t("end-date")],
        getPopupContainer: (props: HTMLElement) => {
          return props;
        },
      },
    },
  ];

  const columns: ColumnsType<IFoodOrder> = [
    {
      dataIndex: "foodCode",
      title: t("order-number"),
      render: (_, record) => (
        <span>{record.foodCode ? record?.foodCode : "-"}</span>
      ),
    },
    {
      title: t("house-number"), //unit number to use, house number to show
      render: (_, record) => <span>{record?.unit?.unitNumber}</span>,
    },
    {
      title: t("name"),
      render: (_, record) => (
        <div className="line-clamp">{record?.customer?.fullName || "-"}</div>
      ),
    },
    {
      dataIndex: "createdAt",
      title: t("order-date"),
      render: (text) => (
        <span>
          {parse(
            text,
            i18n.language,
            `${
              i18n.language === "en"
                ? "DD MMM YYYY / HH:mm a"
                : "DD MMM BBBB / HH:mm a"
            }`
          )}
        </span>
      ),
    },

    {
      title: t("total-food"),
      align: "center",
      render: (_, record) => {
        const total = record?.foodOrderList.reduce((acc, cur) => {
          return acc + cur.quantity;
        }, 0);
        // return <span>{record.foodOrderList.length}</span>;
        return <span>{numeral(total || 0).format("0,000")}</span>;
      },
    },
    {
      title: `${t("price")} (${t("baht")})`,
      align: "center",
      render: (_, record) => (
        <span>{numeral(record?.totalPrice || 0).format("0,000.00")}</span>
      ),
    },
    // {
    //   title: t("payment-status"),
    //   align: "center",
    //   render: (_, record) => {
    //     if (record.isPaid) {
    //       return (
    //         <CustomStatusBackground
    //           lable={t("paid")}
    //           className={"frame-background"}
    //         />
    //       );
    //     }
    //     return (
    //       <CustomStatusBackground
    //         lable={t("not-yet-paid")}
    //         className={"frame-no-background"}
    //       />
    //     );
    //   },
    // },
    {
      title: t("status"),
      dataIndex: "status",
      render: (_, record) => {
        let className = "frame-background-pending";
        if (record?.status === "SUCCESS") {
          className = "frame-background-complete ";
        }
        if (record?.status === "CANCEL") {
          className = "frame-background-cancel";
        }
        // if (record?.status === "PAID") {
        //   className = "frame-background-paid";
        // }

        return record?.status ? (
          <CustomStatusBackground
            lable={
              record?.status === "SUCCESS"
                ? t("complete")
                : t(record?.status.toLocaleLowerCase())
            }
            className={className}
          />
        ) : (
          "-"
        );
      },
    },
  ];

  const choices: IPageFilterChoice[] = [
    {
      label: t("all"),
      total: data?.countData.countAll || 0,
    },
    {
      label: t("pending"),
      key: "PENDING",
      total: data?.countData?.countPendingStatus || 0,
    },
    // {
    //   label: t("paid"),
    //   key: "PAID",
    //   total: data?.countData?.countPaidstatus || 0,
    // },
    {
      label: t("complete"),
      key: "SUCCESS",
      total: data?.countData?.countSuccessStatus || 0,
    },
    {
      label: t("cancel"),
      key: "CANCEL",
      total: data?.countData?.countCancelStatus || 0,
    },
  ];

  const onSearch = (values: any) => {
    if (!values.date) {
      values.date = [];
    }
    const { date } = values;

    const startDate = findStartOrEndTime(date[0], "start");
    const endDate = findStartOrEndTime(date[1], "end");
    values = { ...values, startDate, endDate };
    delete values.date;

    setParams({ ...params, ...values });
  };

  const onDelete = async (id: any) => {
    const confirm = await swalDelete();
    if (confirm) {
      delete_order.mutate(
        { id: id },
        {
          onSuccess: () => {
            swalSuccess();
            if (params.page > 1 && data?.data.length === 1) {
              return setParams({ ...params, page: params.page - 1 });
            }
            client.invalidateQueries("get-food-order");
          },
          onError: (error: any) => {
            swalError(t, error.message);
          },
        }
      );
    }
  };

  const onFilter = (choice: IPageFilterChoice) => {
    setParams({ ...params, status: choice.key });
  };

  const onBulkDeleteSuccess = (length: number) => {
    if (length === data?.data.length && params.page > 1) {
      setParams({ ...params, page: params.page - 1 });
    }
  };

  return (
    <div>
      <PageHeader
        menu="food-order"
        onSearch={onSearch}
        searchInput={searchInput}
        label="plus-btn.order"
        path={FOOD_ORDER_PATH.create}
        bulkDelete={bulk_delete_order}
        invalidateQueries={["get-food-order"]}
        onBulkDeleteSuccess={onBulkDeleteSuccess}
        topicExport="Food Order"
        exportExcel={exportExcel}
        lineNotification={lineNotification}
      />
      <PageFilter
        choices={choices}
        onClick={onFilter}
        current={params.status}
      />
      <CustomTable
        title={t("order-list")}
        dataSource={data?.data || []}
        isLoadingTable={isLoading}
        columns={columns}
        pagination={{
          limit: params.limit,
          page: params.page,
          total: data?.count || 0,
        }}
        onPageChange={(page, limit) => {
          setParams({ ...params, page, limit });
        }}
        onDelete={onDelete}
        rowClassName={(record, index) => {
          if (tableNoti["food"]?.some((e) => e === record?.id)) {
            return "table-row-pink";
          }
        }}
      />
    </div>
  );
};

export default FoodOrderPage;
