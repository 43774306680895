//dashboard
export const DASHBOARD_PATH = "/dashbord";
//new
export const NEWS_PATH = "/news";
export const NEWS_CREATE_PATH = `${NEWS_PATH}/create`;
//privilege
export const PRIVILEGE_PATH = "/privilege";
export const PRIVILEGE_CREATE_PATH = `${PRIVILEGE_PATH}/create`;
//all
export const PRIVILEGE_ALL_PATH = `${PRIVILEGE_PATH}/all`;
//user
export const PRIVILEGE_USER_PATH = `${PRIVILEGE_PATH}/user`;
//homecare
export const HOMECARE_PATH = "/homecare";
//private_area_repairing
export const HOMECARE_PRIVATE_AREA_REPAIRING_PATH = {
  index: `${HOMECARE_PATH}/repairing`,
  create: `${HOMECARE_PATH}/repairing/create`,
  edit: `${HOMECARE_PATH}/repairing/:id(\\d+)`,
};
//repair_setup
export const HOMECARE_REPAIRING_SETUP_PATH = {
  index: `${HOMECARE_PATH}/repair-setup`,
  create: `${HOMECARE_PATH}/repair-setup/create`,
  edit: `${HOMECARE_PATH}/repair-setup/:id(\\d+)`,
};
//payment
export const PAYMENT_PATH = "/payment";
export const PAYMENT_CREATE_PATH = "/payment/create";
export const PAYMENT_LIST_PATH = "/payment-list";
export const PAYMENT_LIST_CREATE_PATH = "/payment-list/create";
//parcel
export const PARCEL_PATH = "/parcel";
export const PARCEL_CREATE_PATH = `${PARCEL_PATH}/create`;
//facility
export const FACILITY_LIST_PATH = "/facility-list";
export const FACILITY_LIST_BOOKING_PATH = `${FACILITY_LIST_PATH}/booking`;
export const FACILITY_LIST_CREATE_BOOKING_PATH = `${FACILITY_LIST_BOOKING_PATH}/create`;
export const FACILITY_LIST_CALENDAR_PATH = `${FACILITY_LIST_PATH}/calendar`;
//service
export const SERVICE_PATH = "/service";
export const SERVICE_LIST_PATH = {
  index: `${SERVICE_PATH}/list`,
  edit: `${SERVICE_PATH}/list/:id(\\d+)`,
  create: `${SERVICE_PATH}/list/create`,
};
export const SERVICE_ORDER_PATH = {
  index: `${SERVICE_PATH}/order`,
  edit: `${SERVICE_PATH}/order/:id(\\d+)`,
  create: `${SERVICE_PATH}/order/create`,
};

//user-management
export const USER_MANAGEMENT_PATH = "/user-management";

//resident
export const USER_MANAGEMENT_RESIDENT_PATH = {
  index: `${USER_MANAGEMENT_PATH}/resident`,
  create: `${USER_MANAGEMENT_PATH}/resident/create`,
  edit: `${USER_MANAGEMENT_PATH}/resident/:id(\\d+)`,
};

//admin
export const USER_MANAGEMENT_ADMIN_PATH = {
  index: `${USER_MANAGEMENT_PATH}/admin`,
  create: `${USER_MANAGEMENT_PATH}/admin/create`,
  edit: `${USER_MANAGEMENT_PATH}/admin/:id(\\d+)`,
};
//setting
export const SETTING_PATH = "/setting";
//facility category
export const SETTING_FACILITY_CATEGORY_PATH = `${SETTING_PATH}/facility-category`;
export const SETTING_FACILITY_CATEGORY_CREATE_PATH = `${SETTING_FACILITY_CATEGORY_PATH}/create`;
//facility
export const SETTING_FACILITY_PATH = `${SETTING_PATH}/facility`;
export const SETTING_FACILITY_CREATE_PATH = `${SETTING_FACILITY_PATH}/create`;
//repairing
export const SETTING_REPAIRING_ZONE_PATH = `${SETTING_PATH}/repairing-zone`;
export const SETTING_REPAIRING_ZONE_CREATE_PATH = `${SETTING_REPAIRING_ZONE_PATH}/create`;
//repairing category
export const SETTING_REPAIRING_CATEGORY_PATH = `${SETTING_PATH}/repairing-category`;
export const SETTING_REPAIRING_CATEGORY_CREATE_PATH = `${SETTING_REPAIRING_CATEGORY_PATH}/create`;
//repairing-item
export const SETTING_REPAIRING_ITEM_PATH = `${SETTING_PATH}/repairing-item`;
export const SETTING_REPAIRING_ITEM_CREATE_PATH = `${SETTING_REPAIRING_ITEM_PATH}/create`;
//bill
export const SETTING_BILL_TYPE_PATH = `${SETTING_PATH}/bill-type`;
export const SETTING_BILL_TYPE_CREATE_PATH = `${SETTING_BILL_TYPE_PATH}/create`;
//service category
export const SETTING_SERVICE_CATEGORY_PATH = `${SETTING_PATH}/service-category`;
export const SETTING_SERVICE_CATEGORY_CREATE_PATH = `${SETTING_SERVICE_CATEGORY_PATH}/create`;
//service lists
// export const SETTING_SERVICE_LISTS_PATH = `${SETTING_PATH}/service-lists`;
// export const SETTING_SERVICE_LISTS_CREATE_PATH = `${SETTING_PATH}/service-lists/create`;

export const FOOD_PATH = "/food";
export const FOOD_LIST_PATH = {
  index: `${FOOD_PATH}/list`,
  create: `${FOOD_PATH}/list/create`,
  edit: `${FOOD_PATH}/list/:id(\\d+)`,
};
export const FOOD_ORDER_PATH = {
  index: `${FOOD_PATH}/order`,
  create: `${FOOD_PATH}/order/create`,
  edit: `${FOOD_PATH}/order/:id(\\d+)`,
};

//content
export const SETTING_CONTENT_ABOUT_US_PATH = `${SETTING_PATH}/content-about-us`;
//unit
export const UNITS_PATH = "/units";

//role
export const ROLE_PATH = {
  index: "/setting/role",
  create: "/setting/role/create",
  edit: "/setting/role/:id",
};
export const UNIT_PATH = "/units";
export const UNIT_CREATE_PATH = `${UNIT_PATH}/create`;

//NotAuthorized
export const NOT_AUTHORIZED = "/not-authorized";

//laundry
export const LAUNDRY_PATH = "/laundry";
export const LAUNDRY_LIST_PATH = {
  index: `${LAUNDRY_PATH}/list`,
  create: `${LAUNDRY_PATH}/list/create`,
  edit: `${LAUNDRY_PATH}/list/:id(\\d+)`,
};
export const LAUNDRY_ORDER_PATH = {
  index: `${LAUNDRY_PATH}/order`,
  create: `${LAUNDRY_PATH}/order/create`,
  edit: `${LAUNDRY_PATH}/order/:id(\\d+)`,
};
