import React from "react";
import { Select } from "antd";
import { useTranslation } from "react-i18next";

export interface IOptionValue {
  values: object[];
  key: string;
  [name: string]: any;
  text?: any;
}

const _isObj = (value: any): boolean => {
  return typeof value === "object";
};

const CustomSelect = ({
  optionValue = { values: [], key: "", name: "", text: "" },
  className,
  placeholder,
  showSearch = true,
  hasTranslation = false,
  disabled = false,
  bordered = true,
  allowClear = false,
  size,
  onChange,
  inactive = false,
  ...props
}: {
  optionValue: IOptionValue;
  className?: string;
  placeholder?: string;
  showSearch?: boolean;
  hasTranslation?: boolean;
  bordered?: boolean;
  onChange?: (value: any, option: any) => void;
  mode?: "multiple" | "tags";
  disabled?: boolean;
  allowClear?: boolean;
  size?: any;
  inactive?: boolean;
}) => {
  const { t } = useTranslation();
  const renderOption = (): any => {
    if (!hasTranslation) {
      return optionValue.values.map((item: any, index) => {
        return (
          <Select.Option
            key={index}
            value={_isObj(item) ? item[optionValue.key] : item}
          >
            {_isObj(item) ? t(item[optionValue.name]) : item}{" "}
            {item[optionValue.text] && `+${item[optionValue.text]}`}
          </Select.Option>
        );
      });
    }

    return optionValue.values.map((item: any, index) => {
      return (
        <Select.Option
          key={index}
          value={_isObj(item) ? item[optionValue.key] : item}
          disabled={item?.status === "INACTIVE" || false}
        >
          {_isObj(item) ? t(item[optionValue.name]) : t(item)}{" "}
          {item[optionValue.text] && `+${t(item[optionValue.text])}`}
          {inactive && item.status === "INACTIVE" && ` (${t("inactive")})`}
        </Select.Option>
      );
    });
  };
  return (
    <Select
      getPopupContainer={(value) => {
        return value;
      }}
      placeholder={placeholder}
      className={className}
      {...props}
      showSearch={showSearch}
      optionFilterProp="children"
      onChange={onChange}
      disabled={disabled}
      bordered={bordered}
      allowClear={allowClear}
      size={size}
    >
      {renderOption()}
    </Select>
  );
};

export default CustomSelect;
