import { Badge, Modal } from "antd";
import { CalendarMode } from "antd/lib/calendar/generateCalendar";
import Table, { ColumnsType } from "antd/lib/table";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CalendarDayJS from "../../../components/calendar-dayjs";
import CustomStatusBackground from "../../../components/custom-status";
import PageHeader from "../../../components/page-header";
import {
  GET_FACILITY_BOOKING,
  IFacilityBooking,
  IFacilityBookingParams,
} from "../../../store/facility-booking";
import { parse } from "../../../tools/datetime";
import { getKeyByLocale } from "../../../tools/translation";

const FacilityCalendarPage = () => {
  const { i18n, t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dateSelected, setDateSelected] = useState<Dayjs>();

  const [params, setParams] = useState<IFacilityBookingParams>({
    page: 1,
    limit: 10,
    pagination: false,
    bookingStartDate: dayjs().startOf("M").format(),
    bookingEndDate: dayjs().endOf("M").format(),
  });
  const { data } = GET_FACILITY_BOOKING(params);

  const dataTable = data?.data.filter((item) => {
    const booking = dayjs(item.bookingDate).format("YYYY-MM-DD");
    const selected = dayjs(dateSelected).format("YYYY-MM-DD");
    return booking === selected;
  });

  const columns: ColumnsType<IFacilityBooking> = [
    {
      dataIndex: "unit",
      title: t("house-number"), //unit number to use, house number to show
      render: (text, record) => {
        return <>{text?.unitNumber || "-"}</>;
      },
    },
    {
      dataIndex: "bookingPerson",
      title: t("booking-person"),
      render: (text) => {
        return <>{text?.fullName || "-"}</>;
      },
    },
    {
      dataIndex: "facility",
      title: t("menu.setting-facility"),
      render: (text) => {
        if (!text) return "-";
        return getKeyByLocale({
          key: "name",
          data: text,
          locale: i18n.language,
        });
      },
    },
    {
      dataIndex: "bookingDate",
      title: t("date-and-time-booking"),
      render: (text, record) => {
        const date = text
          ? parse(
              text,
              i18n.language,
              i18n.language === "en" ? "DD/MM/YYYY" : "DD/MM/BBBB"
            )
          : "-";

        const start = dayjs(record?.bookingDate).format("HH:mm") || "-";
        const end = dayjs(record?.endOfBookingDate).format("HH:mm") || "-";
        return `${date} | ${start} - ${end}`;
      },
    },
    {
      dataIndex: "numberOfParticipants",
      title: t("number-of-users"),
      render: (text, _) => {
        return text || 0;
      },
    },
    {
      dataIndex: "status",
      title: t("status"),
      render: (_, record) => {
        const colorStatus = record.status
          ? record?.status === "PENDING"
            ? "frame-no-background"
            : record?.status === "COMPLETE"
            ? "frame-background"
            : "frame-no-background-cancel"
          : "";
        return (
          <CustomStatusBackground
            lable={record.status ? t(record.status.toLocaleLowerCase()) : "-"}
            className={colorStatus}
          />
        );
      },
    },
  ];

  const onSelect = (date: Dayjs) => {
    const isHave = data?.data.find((item) => {
      const selected = dayjs(date).format("YYYY-MM-DD");
      const booking = dayjs(item?.bookingDate).format("YYYY-MM-DD");
      return selected === booking;
    });
    if (isHave) {
      setDateSelected(date);
      setIsModalVisible(true);
    }
  };

  const onPanelChange = (value: Dayjs, mode: CalendarMode) => {
    const start = dayjs(value).startOf("M").format();
    const end = dayjs(value).endOf("M").format();
    setParams({ ...params, bookingStartDate: start, bookingEndDate: end });
  };

  const dateCellRender = (date: Dayjs): React.ReactNode => {
    const booking = data?.data.filter((item) => {
      const bookingDate = dayjs(item.bookingDate).format("YYYY-MM-DD");
      const rederDate = dayjs(date).format("YYYY-MM-DD");
      return bookingDate === rederDate;
    });

    return (
      <ul className="holiday-events">
        {booking?.map((item) => {
          const language = i18n.language === "en";
          const facilityList = item?.facility;
          const facility = language
            ? facilityList?.nameEn
              ? facilityList?.nameEn
              : "-"
            : facilityList?.nameTh
            ? facilityList?.nameTh
            : "-";
          const unit = item?.unit;
          return (
            <li key={item.id}>
              <Badge color="gold" text={`${facility} (${unit?.unitNumber})`} />
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <>
      <PageHeader
        menu="facility-list-calendar"
        onSearch={() => {}}
        searchInput={[]}
        hasCreateButton={false}
        utility={[]}
      />
      <div className="p-3 bg-white custom-calendar relative">
        <span className="mt-3 f-16 font-bold absolute">
          {t("menu.facility-list-booking")}
        </span>
        <CalendarDayJS
          onSelect={onSelect}
          onPanelChange={onPanelChange}
          dateCellRender={dateCellRender}
        />
      </div>
      <Modal
        title=""
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
        width="67vw"
        footer={null}
        centered={true}
      >
        <Table
          rowKey="id"
          className="pt-4 custom-table"
          dataSource={dataTable || []}
          columns={columns}
          scroll={{ y: "80vh" }}
          pagination={false}
        />
      </Modal>
    </>
  );
};

export default FacilityCalendarPage;
