import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
import PageHeaderEdit from "../../components/page-header-create";
import TextEditor from "../../components/custom-text-editor";
import UploadImage from "../../components/custom-upload-image";
import {
  GET_NEWS_ID,
  LINE_NOTIFICATION_NEWS,
  PATCH_NEWS,
  POST_NEWS,
} from "../../store/news";
import { swalError, swalSuccess } from "../../tools/swal";
import { useQueryClient } from "react-query";
import { VALIDATE_MESSAGES } from "../../config/validateMessage";
import dayjs from "dayjs";
import PreviewContent from "../../components/preview-content";
import FileUpload from "../../components/upload-file";

const { Option } = Select;
const { RangePicker } = DatePicker;

export const fix_height = "45px";

const EditNews = () => {
  const { id } = useParams<{ id: string }>();
  const [form] = useForm();
  const history = useHistory();
  const { t } = useTranslation();
  const client = useQueryClient();
  const create = POST_NEWS();
  const update = PATCH_NEWS();
  const { data } = GET_NEWS_ID(id);
  const [previewEn, setPreviewEn] = useState(false);
  const [previewTh, setPreviewTh] = useState(false);
  const [previewData, setPreviewData] = useState();
  const lineMutation = LINE_NOTIFICATION_NEWS();

  useEffect(() => {
    form.setFieldsValue(data || {});
  }, [data, form]);

  const onFinish = (value: any) => {
    const startDate = dayjs(value.date[0]);
    const endDate = dayjs(value.date[1]);
    const data = { ...value, startDate, endDate, accessibility: "ALL" };
    if (!data.status) data.status = "DRAFT";
    if (id) {
      delete data.date;
      delete data.createdBy;
      const dataId = { ...data, id: +id };
      update.mutate(dataId, {
        onError: (err: any) => {
          swalError(t, t("swal.please-complete-the-information"));
        },
        onSuccess: () => {
          client.invalidateQueries("get-news");
          client.invalidateQueries(["get-news-id", id]);
          swalSuccess(t);
          history.goBack();
        },
      });
    } else {
      delete data.date;
      create.mutate(data, {
        onError: (err: any) => {
          swalError(t, t("swal.please-complete-the-information"));
        },
        onSuccess: () => {
          client.invalidateQueries("get-news");
          swalSuccess(t);
          history.goBack();
        },
      });
    }
  };

  const FormEdit = () => {
    return (
      <Row gutter={[24, 24]} className="px-4 mb-4 pb-2">
        <Col span={12}>
          <Form.Item
            name="headerEn"
            label={t("headingEn")}
            rules={[{ required: true }, { max: 255 }]}
            className="custom-input"
          >
            <Input placeholder={t("headingEn")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="headerTh"
            label={t("headingTh")}
            rules={[{ required: true }, { max: 255 }]}
            className="custom-input"
          >
            <Input placeholder={t("headingTh")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="category"
            label={t("category")}
            rules={[{ required: true }]}
            className="custom-select-ant "
          >
            <Select placeholder={t("category")}>
              <Option value="BANNER">{t("banner")}</Option>
              <Option value="PUBLIC_RELATIONS">
                {t("rules-and-regulations")}
              </Option>
              <Option value="NEWS">{t("news")}</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="date"
            label={t("date-and-time")}
            rules={[{ required: true }]}
            className="custom-ant-picker"
          >
            <RangePicker
              getPopupContainer={(props: HTMLElement) => {
                return props;
              }}
              className="w-100"
              style={{ height: `${fix_height}` }}
              format="YYYY-MM-DD HH:mm"
              showTime={true}
              placeholder={[t("start-date"), t("end-date")]}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="status"
            label={t("status")}
            className="custom-select-ant "
            rules={[{ required: true }]}
            initialValue="DRAFT"
          >
            <Select placeholder={t("status")} defaultValue={"DRAFT"}>
              <Option value="DRAFT">{t("draft")}</Option>
              <Option value="PUBLISHED">{t("published")}</Option>
            </Select>
          </Form.Item>
        </Col>
        {id && (
          <Col span={12}>
            <Form.Item
              name="createdBy"
              label={t("author")}
              className="custom-input"
            >
              <Input placeholder={t("author")} disabled />
            </Form.Item>
          </Col>
        )}
        <Col span={24}>
          <PreviewContent
            values={previewData}
            reach={data?.reach || 0}
            language="en"
            previewEn={previewEn}
            openPreview={() => {
              setPreviewData(form.getFieldsValue());
              setPreviewEn(true);
            }}
            closePreview={() => setPreviewEn(false)}
          />
          <Form.Item name="contentEn" label={t("contentEn")}>
            <TextEditor />
          </Form.Item>
        </Col>
        <Col span={24}>
          <PreviewContent
            values={previewData}
            reach={data?.reach || 0}
            language="th"
            previewTh={previewTh}
            openPreview={() => {
              setPreviewData(form.getFieldsValue());
              setPreviewTh(true);
            }}
            closePreview={() => setPreviewTh(false)}
          />
          <Form.Item name="contentTh" label={t("contentTh")}>
            <TextEditor />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            name="picture"
            label={t("attached-picture")}
            className="custom-ant-upload-image"
          >
            <UploadImage onPage="news" />
          </Form.Item>
        </Col>
        <Col span={24} style={{ marginTop: id ? "-50px" : 0 }}>
          <Form.Item name="file" label={t("file") + "(PDF)"}>
            <FileUpload />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <div className="bg-white color-black pb-1">
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          validateMessages={VALIDATE_MESSAGES(t)}
          className="fixed-input-size"
        >
          <PageHeaderEdit
            utility={id ? ["line"] : []}
            label="menu.news"
            labelTo="menu.news"
            lineMutation={lineMutation}
            isLoading={create.isLoading || update.isLoading}
          />
          <FormEdit />
        </Form>
      </div>
    </>
  );
};

export default EditNews;
