import axios from "axios";
import { throwResponse } from "../config/axios";

export const POST_MEDIA_OBJECT = async (file: any): Promise<number> => {
  let formData = new FormData();
  formData.append("file", file);
  const res = await axios.post("/api/media-object", formData);
  if (res.status === 201) {
    const { data } = res.data;
    return data.id;
  }
  return throwResponse(res);
};

export const POST_MEDIA_OBJECT_CONTENT = async (file: any): Promise<any> => {
  let formData = new FormData();
  formData.append("file", file);
  const res = await axios.post("/api/media-object", formData);
  if (res.status === 201) {
    return res;
  }
  return throwResponse(res);
};

export const splitMediaObject = async (medias: any[]): Promise<number[]> => {
  let mediaObjects: number[] = [];
  for (const file of medias) {
    if (file.originFileObj) {
      const id = await POST_MEDIA_OBJECT(file.originFileObj);
      mediaObjects = [...mediaObjects, id];
    } else {
      mediaObjects = [...mediaObjects, file.id];
    }
  }
  return mediaObjects;
};
