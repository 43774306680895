import { CommentOutlined, FolderOpenOutlined } from "@ant-design/icons";
import { Col, Form, Modal, Row, Button, Input, Empty } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import CustomSelect from "../../../components/custom-select";
import { fixed_hieght } from "../../../components/page-header";
import MobileHeader from "../../../components/page-header-mobile";
import ImageUpload from "../../../components/upload-image";
import ErrorPage from "../../../pages/page-error-mobile";
import { GET_CONTENT_ABOUT_US } from "../../../store";
import { GET_REPAIRING_ID } from "../../../store/repairing";
import { getKeyByLocale } from "../../../tools/translation";
import { repairingStatus } from "../../../views/home-care/repairing/repairing-index";
import RepairingComment from "./repairman-comment";
import { RepairmanCard } from "./repairman-index";

const RepairmanView = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = useForm();
  const { data, isError, isLoading } = GET_REPAIRING_ID(id);
  const content = GET_CONTENT_ABOUT_US({ slug: "REPAIRMAN" });
  const [modalComment, setModalComment] = useState(false);

  useEffect(() => {
    initail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data]);

  const initail = () => {
    if (!data) {
      return;
    }
    const {
      status,
      detail,
      beforeImage,
      afterImage,
      repairmanSignImage,
      verificationSignImage,
    } = data;

    form.setFieldsValue({
      status: status,
      detail: detail,
      beforeImage: beforeImage?.imageUrl || undefined,
      afterImage: afterImage?.imageUrl || undefined,
      repairmanSignImage: repairmanSignImage?.imageUrl || undefined,
      verificationSignImage: verificationSignImage?.imageUrl || undefined,
    });
  };

  if (isLoading) {
    return (
      <div className="w-100 background-mobile h-100">
        <MobileHeader />
        <Row className="px-3 pt-5-5 pb-3 w-100 h-100 display-flex justify-center align-center flex-column">
          <ClipLoader color="#b69e4f" />
        </Row>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="w-100 background-mobile h-100">
        <MobileHeader />
        <Row className="px-3 pt-5-5 pb-3 w-100 h-100 display-flex justify-center align-center flex-column">
          <ErrorPage />
        </Row>
      </div>
    );
  }

  if (!data) {
    return (
      <div className="w-100 background-mobile h-100">
        <MobileHeader />
        <Row className="px-3 pt-5-5 pb-3 w-100 h-100 display-flex justify-center align-center flex-column">
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            className="color-gold expty-color"
          />
        </Row>
      </div>
    );
  }

  const { repairmanSignImage, verificationSignImage, beforeImage, afterImage } =
    data;

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showCommentModal = () => {
    setModalComment(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setModalComment(false);
  };

  const MyComponent = () => {
    const dataContent = content?.data?.find((e: any) => e.slug === "REPAIRMAN");
    return (
      <p
        className="color-gold modal-color"
        dangerouslySetInnerHTML={{
          __html: getKeyByLocale({
            key: "content",
            data: dataContent,
            locale: i18n.language,
          }),
        }}
      />
    );
  };

  return (
    <div className="w-100 background-mobile">
      <MobileHeader />
      <div className="w-100">
        <Row className="px-3 pt-5-5 pb-3 ">
          <Form
            layout="vertical"
            form={form}
            className="fixed-input-size w-100"
          >
            <Col span={24}>
              <Form.Item className="pt-2">
                <RepairmanCard
                  values={data}
                  showStatus={false}
                  isClassName={false}
                  isClick={false}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="detail" label={t("details")} className="disabled-textarea">
                <Input.TextArea disabled />
              </Form.Item>
            </Col>
            {beforeImage && (
              <Col span={24}>
                <Form.Item name="beforeImage" label={t("image-before")}>
                  <ImageUpload disable={true} />
                </Form.Item>
              </Col>
            )}
            {afterImage && (
              <Col span={24}>
                <Form.Item name="afterImage" label={t("image-after")}>
                  <ImageUpload disable={true} />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item
                name="status"
                label={t("status")}
                className="repairman-disable"
              >
                <CustomSelect
                  hasTranslation={true}
                  showSearch={false}
                  placeholder={t("repairing-status")}
                  disabled={true}
                  optionValue={{
                    values: repairingStatus || [],
                    key: "key",
                    name: "name",
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label={t("comment")}>
                <Button
                  icon={<CommentOutlined className="f-24" />}
                  className="button-note f-18"
                  style={{ width: "100%", height: fixed_hieght }}
                  onClick={() => {
                    showCommentModal();
                  }}
                >
                  {t("comment")}
                </Button>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label={t("mobile.document")}>
                <Button
                  icon={<FolderOpenOutlined className="f-24" />}
                  className="button-document f-18"
                  style={{ width: "100%", height: fixed_hieght }}
                  onClick={() => {
                    showModal();
                  }}
                >
                  {t("mobile.document")}
                </Button>
              </Form.Item>
            </Col>
            {repairmanSignImage && (
              <Col span={24}>
                <Form.Item
                  name="repairmanSignImage"
                  label={t("repairman-sign")}
                >
                  <ImageUpload disable={true} />
                </Form.Item>
              </Col>
            )}
            {verificationSignImage && (
              <Col span={24}>
                <Form.Item
                  name="verificationSignImage"
                  label={t("verification-sign")}
                >
                  <ImageUpload disable={true} />
                </Form.Item>
              </Col>
            )}
          </Form>
        </Row>
      </div>
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
        width={800}
        centered
      >
        <span className="mt-3">
          <MyComponent />
        </span>
      </Modal>
      <Modal
        visible={modalComment}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
        width={800}
        centered
      >
        <span className="mt-3">
          <RepairingComment id={id} i18n={i18n} modalComment={modalComment} />
        </span>
      </Modal>
    </div>
  );
};

export default RepairmanView;
