import PageHeader, { ISearchInput } from "../../../components/page-header";
import { useTranslation } from "react-i18next";
import PageFilter, { IPageFilterChoice } from "../../../components/page-filter";
import { FACILITY_LIST_CREATE_BOOKING_PATH } from "../../../router/path";
import { GET_UNIT } from "../../../store/units";
import { ColumnsType } from "antd/lib/table";
import CustomStatusBackground from "../../../components/custom-status";
import { findStartOrEndTime, parse } from "../../../tools/datetime";
import CustomTable from "../../../components/custom-table";
import { useEffect, useState } from "react";
import {
  BULK_DELETE_FACILITY_BOOKING,
  DELETE_FACILITY_BOOKING,
  EXPORT_BOOKING,
  GET_FACILITY_BOOKING,
  IFacilityBooking,
  IFacilityBookingParams,
  NOTIFY_BOOKING,
} from "../../../store/facility-booking";
import { getKeyByLocale } from "../../../tools/translation";
import dayjs from "dayjs";
import { swalDelete, swalError, swalSuccess } from "../../../tools/swal";
import { useQueryClient } from "react-query";
import { useNotification } from "../../../store/notification";
import numeral from "numeral";

const FacilityBookingPage = () => {
  const { tableNoti, dispatch } = useNotification();

  const { i18n, t } = useTranslation();
  const client = useQueryClient();
  const [params, setParams] = useState<IFacilityBookingParams>({
    page: 1,
    limit: 10,
  });

  const notify_booking = NOTIFY_BOOKING();
  const export_excel = EXPORT_BOOKING();
  const get_unit = GET_UNIT({ pagination: false });
  const get_booking = GET_FACILITY_BOOKING(params);
  const delete_booking = DELETE_FACILITY_BOOKING();
  const bulk_delete_booking = BULK_DELETE_FACILITY_BOOKING();

  useEffect(() => {
    get_booking.refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableNoti]);

  useEffect(() => {
    return () =>
      dispatch!({
        type: "table",
        action: "clear",
        key: ["facility-list", "facility-list-booking"],
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchInput: ISearchInput[] = [
    {
      label: t("search"),
      key: "search",
      span: 10,
      type: "input",
      props: {
        placeholder: t("search-facility"),
      },
    },
    {
      label: t("house-number"), //unit number to use, house number to show
      key: "unit",
      span: 7,
      type: "select",
      props: {
        placeholder: t("all"),
        optionValue: {
          values: get_unit.data?.data || [],
          key: "id",
          name: "unitNumber",
        },
      },
    },
    {
      label: t("creation-date"),
      key: "date",
      span: 7,
      type: "range-picker",
      props: {
        placeholder: [t("start-date"), t("end-date")],
        getPopupContainer: (props: HTMLElement) => {
          return props;
        },
      },
    },
  ];

  const choices: IPageFilterChoice[] = [
    {
      label: t("all"),
      total: get_booking.data?.countData?.countAllStatus || 0,
    },
    {
      label: t("pending"),
      key: "PENDING",
      total: get_booking.data?.countData?.countPending || 0,
    },
    {
      label: t("complete"),
      key: "COMPLETE",
      total: get_booking.data?.countData?.countComplete || 0,
    },
    {
      label: t("cancel"),
      key: "CANCEL",
      total: get_booking.data?.countData?.countCancel || 0,
    },
  ];

  const columns: ColumnsType<IFacilityBooking> = [
    {
      dataIndex: "unit",
      title: t("house-number"), //unit number to use, house number to show
      render: (text, record) => {
        return <>{text?.unitNumber || "-"}</>;
      },
    },
    {
      dataIndex: "createdAt",
      title: t("creation-date"),
      render: (text, record) => {
        return (
          <p style={{ whiteSpace: "pre", display: "contents" }}>
            {text
              ? parse(
                  text,
                  i18n.language,
                  `${
                    i18n.language === "en"
                      ? "DD MMM YYYY HH:mm"
                      : "DD MMM BBBB HH:mm"
                  }`
                )
              : "-"}
          </p>
        );
      },
    },
    {
      dataIndex: "facility",
      title: t("menu.setting-facility"),
      render: (text) => {
        if (!text) return "-";
        return getKeyByLocale({
          key: "name",
          data: text,
          locale: i18n.language,
        });
      },
    },
    {
      dataIndex: "bookingDate",
      title: t("date-and-time-booking"),
      render: (text, record) => {
        const date = text
          ? parse(
              text,
              i18n.language,
              i18n.language === "en" ? "DD/MM/YYYY" : "DD/MM/BBBB"
            )
          : "-";

        const start = record.bookingDate
          ? dayjs(record.bookingDate).format("HH:mm")
          : "-";
        const end = record.endOfBookingDate
          ? dayjs(record.endOfBookingDate).format("HH:mm")
          : "-";
        return `${date} | ${start} - ${end}`;
      },
    },
    {
      dataIndex: "numberOfParticipants",
      title: t("number-of-users"),
      render: (text, _) => numeral(text).format("0,0") || "-",
    },
    {
      dataIndex: "status",
      title: t("status"),
      render: (_, record) => {
        const colorStatus =
          record.status === "PENDING"
            ? "frame-background-pending"
            : record.status === "COMPLETE"
            ? "frame-background-complete"
            : "frame-background-cancel";
        return (
          <>
            {record.status === null ? (
              "-"
            ) : (
              <CustomStatusBackground
                lable={t(record.status.toLocaleLowerCase())}
                className={colorStatus}
              />
            )}
          </>
        );
      },
    },
  ];

  const onDelete = async (id: any) => {
    const confirm = await swalDelete();
    if (confirm) {
      delete_booking.mutate(
        { id: id },
        {
          onSuccess: () => {
            swalSuccess();
            if (params.page > 1 && get_booking.data?.data.length === 1) {
              return setParams({ ...params, page: params.page - 1 });
            }
            client.invalidateQueries("get-facility-booking");
          },
          onError: (error: any) => {
            swalError(t, error.message);
          },
        }
      );
    }
  };

  const onBulkDeleteSuccess = (length: number) => {
    if (length === get_booking.data?.data.length && params.page > 1) {
      setParams({ ...params, page: params.page - 1 });
    }
  };

  const onSearch = (values: any) => {
    if (!values.date) {
      values.date = [];
    }
    const { date } = values;

    const startDate = findStartOrEndTime(date[0], "start");
    const endDate = findStartOrEndTime(date[1], "end");
    values = { ...values, startDate, endDate };
    delete values.date;

    setParams({ ...params, ...values });
  };

  const onFilter = (choice: IPageFilterChoice) => {
    setParams({ ...params, status: choice.key });
  };

  return (
    <div>
      <PageHeader
        menu="facility-list-booking"
        onSearch={onSearch}
        searchInput={searchInput}
        label={"create"}
        path={FACILITY_LIST_CREATE_BOOKING_PATH}
        bulkDelete={bulk_delete_booking}
        onBulkDeleteSuccess={onBulkDeleteSuccess}
        invalidateQueries={["get-facility-booking"]}
        exportExcel={export_excel}
        topicExport="facility-booking"
        lineNotification={notify_booking}
      />
      <PageFilter
        choices={choices}
        onClick={onFilter}
        current={params.status}
      />
      <CustomTable
        rowKey="id"
        title={t("booking-list")}
        dataSource={get_booking.data?.data || []}
        columns={columns}
        pagination={{
          limit: params.limit,
          page: params.page,
          total: get_booking.data?.count || 0,
        }}
        onDelete={onDelete}
        onPageChange={(page, limit) => setParams({ ...params, page, limit })}
        isLoadingTable={get_booking.isLoading}
        rowClassName={(record, index) => {
          if (tableNoti["facility-list"]?.some((e) => e === record.id)) {
            return "table-row-pink";
          }
        }}
      />
    </div>
  );
};

export default FacilityBookingPage;
