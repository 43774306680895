import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import { ColumnsType } from "antd/lib/table";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import CustomStatusBackground from "../../../components/custom-status";
import CustomTable from "../../../components/custom-table";
import PageFilter, { IPageFilterChoice } from "../../../components/page-filter";
import PageHeader, { ISearchInput } from "../../../components/page-header";
import { LAUNDRY_ORDER_PATH } from "../../../router/path";
import {
  BULK_DELETE_LAUNDRY_ORDER,
  DELETE_LAUNDRY_ORDER,
  EXPORT_LAUNDRY_ORDER,
  GET_LAUNDRY_ORDER,
  ILaundryOrder,
  ILaundryOrderParams,
  LINE_NOTIFICATION_LAUNDRY_ORDER,
} from "../../../store/laundry-order";
import { useNotification } from "../../../store/notification";
import { parse, findStartOrEndTime } from "../../../tools/datetime";
import { swalDelete, swalError, swalSuccess } from "../../../tools/swal";

const LaundryOrderPage = () => {
  const [params, setParams] = useState<ILaundryOrderParams>({
    page: 1,
    limit: 10,
  });
  const { t, i18n } = useTranslation();
  const { data, isLoading, refetch } = GET_LAUNDRY_ORDER(params);
  const delete_order = DELETE_LAUNDRY_ORDER();
  const bulk_delete_laundry_order = BULK_DELETE_LAUNDRY_ORDER();
  const exportExcel = EXPORT_LAUNDRY_ORDER();
  const lineNotification = LINE_NOTIFICATION_LAUNDRY_ORDER();
  const client = useQueryClient();

  const { tableNoti, dispatch } = useNotification();

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableNoti]);

  useEffect(() => {
    return () =>
      dispatch!({
        type: "table",
        action: "clear",
        key: ["laundry", "laundry-order"],
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchInput: ISearchInput[] = [
    {
      label: t("search"),
      key: "search",
      span: 10,
      type: "input",
      props: {
        placeholder: `${t("house-number")} / ${t("created-by")} / ${t(
          "order-number"
        )}`,
      },
    },
    {
      label: t("laundry-create-date"),
      key: "date",
      span: 7,
      type: "range-picker",
      props: {
        placeholder: [t("start-date"), t("end-date")],
        getPopupContainer: (props: HTMLElement) => {
          return props;
        },
      },
    },
  ];
  const choices: IPageFilterChoice[] = [
    {
      label: t("all"),
      total: data?.countData.total || 0,
    },
    {
      label: t("pending"),
      key: "PENDING",
      total: data?.countData.countPending || 0,
    },
    // {
    //   label: t("paid"),
    //   key: "PAID",
    //   total: data?.countData.countPaid || 0,
    // },
    {
      label: t("complete"),
      key: "SUCCESS",
      total: data?.countData.countComplete || 0,
    },
    {
      label: t("cancel"),
      key: "CANCEL",
      total: data?.countData.countCancel || 0,
    },
  ];

  const columns: ColumnsType<ILaundryOrder> = [
    {
      dataIndex: "laundryCode",
      title: t("order-number"),
      render: (text) => {
        return <>{text ? text : "-"}</>;
      },
    },
    {
      dataIndex: "unit",
      title: t("house-number"), //unit number to use, house number to show
      render: (text) => <>{text ? text.unitNumber : "-"}</>,
    },
    {
      dataIndex: "customer",
      title: t("name"),
      render: (text) => <>{text ? text.fullName : "-"}</>,
    },
    {
      dataIndex: "createdAt",
      title: t("laundry-create-date"),
      width: 150,
      render: (text) => {
        return (
          <span>{text ? parse(text, i18n.language, "DD MMM YYYY") : "-"}</span>
        );
      },
    },
    {
      dataIndex: "allList",
      title: t("all-list"),
      render: (text) => <>{text ? text : "-"}</>,
    },
    {
      dataIndex: "totalPrice",
      title: t("laundry-price"),
      width: 150,
      render: (text) => <>{text ? numeral(text).format("0,000.00") : "-"}</>,
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (_, record) => {
        let className = "frame-background-pending";
        if (record.status === "SUCCESS") {
          className = "frame-background-complete ";
        }
        if (record.status === "CANCEL") {
          className = "frame-background-cancel";
        }
        // if (record.status === "PAID") {
        //   className = "frame-background-paid";
        // }

        return record.status ? (
          <CustomStatusBackground
            lable={
              record.status === "SUCCESS"
                ? t("complete")
                : t(record.status.toLocaleLowerCase())
            }
            className={className}
          />
        ) : (
          "-"
        );
      },
    },
    {
      dataIndex: "isExpress",
      title: t("express"),
      render: (text, record) => {
        const isExpress = record.laundryOrdersList.some(
          (item) => item.isExpress
        );

        return (
          <div className="d-flex justify-center">
            {isExpress && (
              <Popover
                content={
                  <div
                    style={{ background: "black", color: "white" }}
                    className="p-1"
                  >
                    {t("express")}
                  </div>
                }
                title={null}
              >
                <ExclamationCircleOutlined style={{ color: "red" }} />
              </Popover>
            )}
          </div>
        );
      },
    },
    {
      dataIndex: "isComplimentary",
      title: t("complimentary"),
      render: (text, record) => {
        const isComplimentary = record.laundryOrdersList
          .map((item: any) => item?.quantityOfFree || 0)
          .reduce((a: number, b: number) => a + b, 0);

        return (
          <div className="d-flex justify-center">
            {!!isComplimentary && (
              <Popover
                content={
                  <div
                    style={{ background: "black", color: "white" }}
                    className="p-1"
                  >
                    {t("complimentary")}
                  </div>
                }
                title={null}
              >
                <ExclamationCircleOutlined style={{ color: "#AEB7FF" }} />
              </Popover>
            )}
          </div>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    if (!values.date) {
      values.date = [];
    }
    const { date } = values;

    const startDate = findStartOrEndTime(date[0], "start");
    const endDate = findStartOrEndTime(date[1], "end");
    values = { ...values, startDate, endDate };
    delete values.date;

    setParams({ ...params, ...values });
  };

  const onDelete = async (id: any) => {
    const status = data?.data.find((item) => item.id === id)?.status;
    if (status === "SUCCESS" || status === "PAID")
      return swalError(t, t("completed-orders-cannot-be-deleted"));
    const confirm = await swalDelete();
    if (confirm) {
      delete_order.mutate(
        { id: id },
        {
          onSuccess: () => {
            swalSuccess();
            if (params.page > 1 && data?.data.length === 1) {
              return setParams({ ...params, page: params.page - 1 });
            }
            client.invalidateQueries("get-laundry-orders");
          },
          onError: (error: any) => {
            swalError(t, error.message);
          },
        }
      );
    }
  };

  const onFilter = (choice: IPageFilterChoice) => {
    setParams({ ...params, status: choice.key });
  };

  const onBulkDeleteSuccess = (length: number) => {
    if (length === data?.data.length && params.page > 1) {
      setParams({ ...params, page: params.page - 1 });
    }
  };

  return (
    <div>
      <PageHeader
        menu="laundry-order"
        onSearch={onSearch}
        searchInput={searchInput}
        label="create"
        path={LAUNDRY_ORDER_PATH.create}
        bulkDelete={bulk_delete_laundry_order}
        onBulkDeleteSuccess={onBulkDeleteSuccess}
        invalidateQueries={["get-laundry-orders"]}
        topicExport="Laundry Order"
        exportExcel={exportExcel}
        lineNotification={lineNotification}
      />
      <PageFilter
        choices={choices}
        onClick={onFilter}
        current={params.status}
      />
      <CustomTable
        title={t("menu.laundry-order")}
        dataSource={data?.data || []}
        isLoadingTable={isLoading}
        columns={columns}
        pagination={{
          limit: params.limit,
          page: params.page,
          total: data?.count || 0,
        }}
        onPageChange={(page, limit) => {
          setParams({ ...params, page, limit });
        }}
        onDelete={onDelete}
        rowClassName={(record, index) => {
          if (tableNoti["laundry"]?.some((e) => e === record.id)) {
            return "table-row-pink";
          }
        }}
      />
    </div>
  );
};

export default LaundryOrderPage;
