import { Layout, Image } from "antd";
import { useReducer, Reducer, useState, useEffect } from "react";
import { IMenu, menu, TMenuKey } from "../router/menu";
import { NavLink } from "react-router-dom";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { WEB_LOGO } from "../assets/icons";
import { useTranslation } from "react-i18next";
import { GET_USER_PROFILE } from "../store/auth";
import { GET_ROLE_BY_ID } from "../store/role";
import { useNotification } from "../store/notification";

type TNavlinkType = {
  key: TMenuKey;
};

export type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

type TNavlink = PartialRecord<TMenuKey, boolean>;
type TNavlinkReducer = Reducer<TNavlink, TNavlinkType>;

const toggleNavlink = (state: TNavlink, action: TNavlinkType): TNavlink => {
  const { key } = action;
  return { ...state, [key]: !state[key] };
};

const initialNavLink: TNavlink = {
  privilege: false,
  homecare: false,
  "user-management": false,
  setting: false,
};

const SideBar = () => {
  const { dispatch, sidebarNoti, headerNoti } = useNotification();
  const { t } = useTranslation();
  const [state, sidebarDispatch] = useReducer<TNavlinkReducer>(
    toggleNavlink,
    initialNavLink
  );

  const [navMenu, setNavMenu] = useState<IMenu[]>([]);

  const { role } = GET_USER_PROFILE();
  const { id } = role;
  const { data } = GET_ROLE_BY_ID(`${id}`);

  // const [initialCount, setInitialCount] = useRecoilState(count_value);
  // const socketValue = useRecoilValue(socket_value);

  useEffect(() => {
    initital();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const initital = () => {
    if (!data) return;
    const side = menu.filter((item) => {
      const find = data?.roleMenus?.find((e: any) => e.menu.code === item.code);
      return find?.isActive;
    });
    setNavMenu(side);
  };

  const styleProps = {
    className: "f-10 absolute",
    style: { right: 30 },
  };

  const DownIcon = <DownOutlined {...styleProps} />;
  const UpIcon = <UpOutlined {...styleProps} />;

  const generateNavLink = (mn?: IMenu[], ic: boolean = false) => {
    if (!mn) return null;

    return mn.map((item, index) => {
      const { key, children, to, icon } = item;
      let findChildren: any | undefined;

      if (key === "setting") {
        findChildren = children?.filter((item) => {
          const find = data?.roleMenus?.find(
            (e: any) => e.menu.code === item.code
          );
          return find?.isActive;
        });
      }
      const hasChildren =
        key === "setting"
          ? findChildren && findChildren.length
          : children && children.length;

      const itemChildren = key === "setting" ? findChildren : children;
      const visible = state[key];
      const arrow = visible ? UpIcon : DownIcon;

      const count = sidebarNoti[key];
      const countUser = headerNoti[key];
      const countResident = headerNoti[key];

      return (
        <div key={index}>
          <NavLink
            activeClassName={`sider-navlink-active relative`}
            className={`sider-navlink px-5 py-3 ${
              ic ? "sider-navlink-children" : ""
            }`}
            to={to}
            onClick={(e) => {
              if (count && count.length > 0) {
                if (key === "facility-list-booking") {
                  dispatch!({
                    type: "sidebar",
                    action: "clear",
                    key: ["facility-list-booking", "facility-list"],
                  });
                }
                if (key === "homecare-repairing") {
                  dispatch!({
                    type: "sidebar",
                    action: "clear",
                    key: ["homecare", "homecare-repairing"],
                  });
                }
                if (key === "payment") {
                  dispatch!({
                    type: "sidebar",
                    action: "clear",
                    key: ["payment"],
                  });
                }
                if (key === "service-order") {
                  dispatch!({
                    type: "sidebar",
                    action: "clear",
                    key: ["service", "service-order"],
                  });
                }
                if (key === "food-order") {
                  dispatch!({
                    type: "sidebar",
                    action: "clear",
                    key: ["food", "food-order"],
                  });
                }
                if (key === "laundry-order") {
                  dispatch!({
                    type: "sidebar",
                    action: "clear",
                    key: ["laundry", "laundry-order"],
                  });
                }
              }

              if (countUser && countUser.length > 0) {
                if (key === "user-management-admin") {
                  dispatch!({
                    type: "header",
                    action: "clear",
                    key: ["user-management-admin"],
                  });
                }
              }

              if (countResident && countResident.length > 0) {
                if (key === "user-management-resident") {
                  dispatch!({
                    type: "header",
                    action: "clear",
                    key: ["user-management-resident"],
                  });
                }
              }

              if (hasChildren) {
                sidebarDispatch({ key: key });
                e.preventDefault();
              }
            }}
          >
            {icon && icon()}
            <p className="mb-0 ml-3">{t(`menu.${key}`)}</p>
            {hasChildren ? arrow : null}
            {count && count.length > 0 && (
              <span className="sider-new-count">{count.length}</span>
            )}
          </NavLink>
          {visible && <div>{generateNavLink(itemChildren, true)}</div>}
        </div>
      );
    });
  };

  return (
    <Layout.Sider className="layout-sider h-100">
      <div
        style={{ height: 80 }}
        className="mt-3 px-3 py-2 d-flex justify-center align-center"
      >
        <Image width="80%" src={WEB_LOGO} preview={false} />
      </div>
      <div className="mt-5">{generateNavLink(navMenu)}</div>
    </Layout.Sider>
  );
};

export default SideBar;
