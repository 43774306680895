import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  InputNumber,
  Button,
} from "antd";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
import PageHeaderEdit from "../../components/page-header-create";
import TextEditor from "../../components/custom-text-editor";
import UploadImage from "../../components/custom-upload-image";
import CustomTable from "../../components/custom-table";
import { ColumnsType } from "antd/lib/table";
import PageFilter, { IPageFilterChoice } from "../../components/page-filter";
import { useEffect, useState } from "react";
import { VALIDATE_MESSAGES } from "../../config/validateMessage";
import { swalError, swalSuccess } from "../../tools/swal";
import { useQueryClient } from "react-query";
import {
  POST_PRIVILEGES,
  PATCH_PRIVILEGES,
  GET_PRIVILEGES_ID,
  GET_PRIVILEGES_USERS_LIST,
  LINE_NOTIFICATION_PRIVILEGE,
} from "../../store/privilege";
import { fix_height } from "../news/news-edit";
import dayjs from "dayjs";
import { DownloadOutlined } from "@ant-design/icons";
import html2canvas from "html2canvas";
import PreviewContent from "../../components/preview-content";
import { parse } from "../../tools/datetime";
import { useRecoilState } from "recoil";
import { page_params } from "../../store";
import FileUpload from "../../components/upload-file";

const { Option } = Select;
const { RangePicker } = DatePicker;

const PrivilegeEdit = () => {
  const { id } = useParams<{ id: string }>();
  const [form] = useForm();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const client = useQueryClient();
  const [currentFilter, setCurrentFilter] = useState<string>("EDIT");
  const { data } = GET_PRIVILEGES_ID(id);
  const create = POST_PRIVILEGES();
  const update = PATCH_PRIVILEGES();
  const [previewEn, setPreviewEn] = useState(false);
  const [previewTh, setPreviewTh] = useState(false);
  const [previewData, setPreviewData] = useState();
  const [load, setLoad] = useState(false);
  const lineMutation = LINE_NOTIFICATION_PRIVILEGE();

  useEffect(() => {
    form.setFieldsValue(data || {});
  }, [data, form]);

  const onFinish = (value: any) => {
    const startDate = dayjs(value.date[0]);
    const endDate = dayjs(value.date[1]);

    const data = { ...value, startDate, endDate, accessibility: "ALL" };
    if (!data.status) data.status = "DRAFT";
    if (id) {
      delete data.date;
      delete data.createdBy;
      const dataId = { ...data, id: +id };
      update.mutate(dataId, {
        onError: (err: any) => {
          swalError(t, t("swal.please-complete-the-information"));
        },
        onSuccess: () => {
          client.invalidateQueries("get-privilege");
          client.invalidateQueries(["get-privilege-id", id]);
          swalSuccess(t);
          history.goBack();
        },
      });
    } else {
      delete data.date;
      create.mutate(data, {
        onError: (err: any) => {
          swalError(t, t("swal.please-complete-the-information"));
        },
        onSuccess: () => {
          client.invalidateQueries("get-privilege");
          swalSuccess(t);
          history.goBack();
        },
      });
    }
  };

  const downloadQRCode = async () => {
    const element: HTMLElement | null = document.querySelector(
      "#qr-code-privilege-download"
    );
    if (!element) return;
    const canvas = await html2canvas(element);
    const img = canvas.toDataURL("image/png");
    let qrCode = document.createElement("a");
    qrCode.download = `${
      i18n.language === "en" ? data?.headerEn : data?.headerTh
    }.png`;
    qrCode.href = img;
    qrCode.click();
    setLoad(false);
  };

  const FormEdit = () => {
    return (
      <Row gutter={[24, 24]} className="px-4 mb-4 pb-2">
        <Col span={12}>
          <Form.Item
            name="headerEn"
            label={t("headingEn")}
            rules={[{ required: true }, { max: 255 }]}
            className="custom-input"
          >
            <Input placeholder={t("headingEn")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="headerTh"
            label={t("headingTh")}
            rules={[{ required: true }, { max: 255 }]}
            className="custom-input"
          >
            <Input placeholder={t("headingTh")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="category"
            label={t("category")}
            rules={[{ required: true }]}
            className="custom-select-ant "
          >
            <Select placeholder={t("category")}>
              <Option value="BANNER">{t("banner")}</Option>
              <Option value="PRIVILEGES_FOR_YOU">
                {t("exclusive-privileges")}
              </Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="date"
            label={t("date-and-time")}
            rules={[{ required: true }]}
            className="custom-ant-picker"
          >
            <RangePicker
              getPopupContainer={(props: HTMLElement) => {
                return props;
              }}
              className="w-100"
              style={{ height: `${fix_height}` }}
              format="YYYY-MM-DD HH:mm"
              showTime={true}
              placeholder={[t("start-date"), t("end-date")]}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="status"
            label={t("status")}
            className="custom-select-ant "
            initialValue="DRAFT"
          >
            <Select placeholder={t("status")} defaultValue={"DRAFT"}>
              <Option value="DRAFT">{t("draft")}</Option>
              <Option value="PUBLISHED">{t("published")}</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="quantity"
            label={t("quantity")}
            className="custom-input-number"
          >
            <InputNumber placeholder={t("quantity")} min="-1" />
          </Form.Item>
        </Col>
        {id && (
          <Col span={12}>
            <Form.Item
              name="createdBy"
              label={t("author")}
              className="custom-input"
            >
              <Input placeholder={t("author")} disabled />
            </Form.Item>
          </Col>
        )}
        <Col span={24}>
          <PreviewContent
            values={previewData}
            reach={data?.reach || 0}
            language="en"
            previewEn={previewEn}
            openPreview={() => {
              setPreviewData(form.getFieldsValue());
              setPreviewEn(true);
            }}
            closePreview={() => setPreviewEn(false)}
          />
          <Form.Item name="contentEn" label={t("contentEn")}>
            <TextEditor />
          </Form.Item>
        </Col>
        <Col span={24}>
          <PreviewContent
            values={previewData}
            reach={data?.reach || 0}
            language="th"
            previewTh={previewTh}
            openPreview={() => {
              setPreviewData(form.getFieldsValue());
              setPreviewTh(true);
            }}
            closePreview={() => setPreviewTh(false)}
          />
          <Form.Item name="contentTh" label={t("contentTh")}>
            <TextEditor />
          </Form.Item>
        </Col>

        {/* {id && (
          <Col span={12}>
            <Form.Item
              name="remaining"
              label={t("remaining")}
              className="custom-input-number"
            >
              <InputNumber placeholder={t("remaining")} min="-1" disabled />
            </Form.Item>
          </Col>
        )} */}
        <Col span={24}>
          <Form.Item
            name="picture"
            label={t("attached-picture")}
            className="custom-ant-upload-image"
          >
            <UploadImage onPage="privilege" />
          </Form.Item>
        </Col>
        <Col span={24} style={{ marginTop: id ? "-50px" : 0 }}>
          <Form.Item name="file" label={t("file") + "(PDF)"}>
            <FileUpload />
          </Form.Item>
        </Col>
        {id && (
          <Col>
            <Form.Item>
              <Row className="mb-2">
                <div
                  className="mr-3 f-16"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  {t("qr-code")}
                </div>
                <Button
                  type="link"
                  block
                  icon={<DownloadOutlined className="f-20" />}
                  onClick={() => {
                    setLoad(true);
                    downloadQRCode();
                  }}
                  style={{ width: 30 }}
                  loading={load}
                ></Button>
              </Row>
              <Row
                style={{ border: "1px solid #e4e9f2" }}
                className="px-2 py-2"
                id="qr-code-privilege-download"
              >
                <QRCode value={id} id="qrCode" level="Q" />
              </Row>
            </Form.Item>
          </Col>
        )}
      </Row>
    );
  };

  const onFilter = (choice: IPageFilterChoice) => {
    const status = choice.key === "EDIT" ? undefined : choice.key;
    setCurrentFilter(status ? status : "EDIT");
  };

  const choices: IPageFilterChoice[] = [
    {
      label: t("menu.privilege"),
      key: "EDIT",
    },
    {
      label: t("privilege-user-lists"),
      key: "LISTS",
    },
  ];

  return (
    <>
      <div className="bg-white color-black pb-1">
        {!id && (
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            validateMessages={VALIDATE_MESSAGES(t)}
          >
            <PageHeaderEdit
              label="menu.privilege"
              labelTo="menu.privilege"
              isLoading={create.isLoading || update.isLoading}
            />
            <FormEdit />
          </Form>
        )}
      </div>
      {id && (
        <>
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            validateMessages={VALIDATE_MESSAGES(t)}
            className="fixed-input-size"
          >
            <div className="bg-white color-black pb-2">
              <PageHeaderEdit
                label="menu.privilege"
                labelTo="menu.privilege"
                isDivider={false}
                utility={id ? ["line"] : []}
                lineMutation={lineMutation}
                isLoading={create.isLoading || update.isLoading}
              />
            </div>
            <PageFilter
              choices={choices}
              current={currentFilter}
              onClick={onFilter}
              showTotal={false}
            />
            {currentFilter === "EDIT" && (
              <div className="bg-white color-black pb-1 pt-3 mt-4">
                <FormEdit />
              </div>
            )}
            {currentFilter !== "EDIT" && <PrivilegeUserList id={id} />}
          </Form>
        </>
      )}
    </>
  );
};

const PrivilegeUserList = ({ id }: { id: string }) => {
  const { t, i18n } = useTranslation();
  const [params, setParams] = useRecoilState(page_params);
  const { data, isLoading } = GET_PRIVILEGES_USERS_LIST(params, id);

  const columns: ColumnsType = [
    {
      dataIndex: "unit",
      title: t("house-number"),
      render: (text, __) => {
        return <div className="line-clamp">{text?.unitNumber || "-"}</div>;
      },
    },
    {
      dataIndex: "fullName",
      title: t("name"),
      render: (text, __) => {
        return <div className="line-clamp">{text || "-"}</div>;
      },
    },
    {
      dataIndex: "email",
      title: t("email"),
      render: (text, __) => {
        return <div className="line-clamp">{text || "-"}</div>;
      },
    },

    {
      dataIndex: "updatedAt",
      title: t("special-use-date"),
      render: (text, __) => {
        return (
          <p style={{ whiteSpace: "pre", display: "contents" }}>
            {parse(
              text,
              i18n.language,
              `${
                i18n.language === "en"
                  ? "DD MMM YYYY / HH:mm"
                  : "DD MMM BBBB / HH:mm"
              }`
            )}
          </p>
        );
      },
    },
    {
      dataIndex: "count",
      title: t("used"),
      render: (text, __) => {
        return <div className="line-clamp">{text || "0"}</div>;
      },
    },
  ];
  return (
    <>
      <CustomTable
        rowKey="id"
        title={t("privilege-user-lists")}
        dataSource={data?.data || []}
        columns={columns}
        disabledSelect={true} //ปิด selete
        actions={[]} //ปิด action
        pagination={{
          limit: params.limit,
          page: params.page,
          total: data?.count || 0,
        }}
        onPageChange={(page, limit) => setParams({ ...params, page, limit })}
        isLoadingTable={isLoading}
      />
    </>
  );
};

export default PrivilegeEdit;
