import { Col, Form, Input, Row, Select } from "antd";
import { useTranslation } from "react-i18next";

const DetailInput = ({ id }: { id: string | undefined }) => {
  const { t } = useTranslation();
  return (
    <Row className="px-4 mb-4 pb-2" gutter={[24, 24]}>
      {id && (
        <Col span={12}>
          <Form.Item
            name="detail"
            label={t("details")}
            className="custom-input"
          >
            <Input placeholder={t("details")} disabled />
          </Form.Item>
        </Col>
      )}

      <Col span={12}>
        <Form.Item
          name="adminDetail"
          label={t("details-officer")}
          className="custom-input"
        >
          <Input placeholder={t("details-officer")} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="status"
          label={t("status")}
          className="custom-select-ant "
          rules={[{ required: true }]}
          initialValue="PENDING"
        >
          <Select
            placeholder={t("status")}
            getPopupContainer={(value) => {
              return value;
            }}
            defaultValue="PENDING"
          >
            <Select.Option value="PENDING">{t("pending")}</Select.Option>
            {/* <Select.Option value="PAID">{t("paid")}</Select.Option> */}
            <Select.Option value="SUCCESS">{t("complete")}</Select.Option>
            <Select.Option value="CANCEL">{t("cancel")}</Select.Option>
          </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="paymentType"
          label={t("payment-type")}
          className="custom-select-ant "
          rules={[{ required: true }]}
        >
          <Select
            placeholder={t("payment-type")}
            getPopupContainer={(value) => {
              return value;
            }}
          >
            <Select.Option value="QR_CODE_BOT">
              {t("qr-code-bot")}
            </Select.Option>
            {/* <Select.Option value="QR_CODE_SCB">
              {t("qr-code-scb")}
            </Select.Option> */}
            <Select.Option value="CASH">{t("cash")}</Select.Option>
            <Select.Option value="CREDIT_CARD">
              {t("credit-card")}
            </Select.Option>
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default DetailInput;
