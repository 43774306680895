import { Col, Form, FormInstance, Row } from "antd";
import { useTranslation } from "react-i18next";
import numeral from "numeral";
import {
  appendStartAndEndOfTime,
  DBHeader,
  ErrorPage,
  LoadingPage,
} from "../header";
import {
  DASHBOARD_PAYMENT_LEFT,
  DASHBOARD_PAYMENT_RIGHT,
} from "../../../store/dashboard";

const leftKey = "paymentLeft";
const rightKey = "paymentRight";

const PaymentSection = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-white p-4">
      <p className="f-24 mb-4 bold">{t(`menu.payment`)}</p>
      <Row gutter={[12, 12]}>
        <Col span={24}>
          <div className="h-100 db-border p-3">
            <DBHeader
              formItemName={leftKey}
              label={t("this-month-bill")}
              selectType="range"
            />
            <Form.Item
              shouldUpdate={(prev, next) => prev[leftKey] !== next[leftKey]}
              noStyle
            >
              {(form: FormInstance) => {
                const value = form.getFieldValue(leftKey);
                return <Left value={value} />;
              }}
            </Form.Item>
          </div>
        </Col>
        <Col span={24}>
          <div className="h-100 db-border p-3">
            <DBHeader
              formItemName={rightKey}
              label={t("total-bill-amount")}
              selectType="range"
            />
            <Form.Item
              shouldUpdate={(prev, next) => prev[rightKey] !== next[rightKey]}
              noStyle
            >
              {(form: FormInstance) => {
                const value = form.getFieldValue(rightKey);
                return <Right value={value} />;
              }}
            </Form.Item>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const Left = ({ value }: { value: any }) => {
  const params = appendStartAndEndOfTime(value);
  const { data, isLoading, isError, error } = DASHBOARD_PAYMENT_LEFT(params);

  if (isLoading) {
    return (
      <div
        style={{
          height: 220,
        }}
      >
        <LoadingPage />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div
        style={{
          height: 220,
        }}
      >
        <ErrorPage error={error} />
      </div>
    );
  }

  return (
    <div
      style={{
        height: 110, // if overdue to 220
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
      }}
    >
      <Row gutter={[0, 6]}>
        <Col span={24}>
          <PaymentCard
            suffix="complete"
            prexfix="bill"
            value={data.countPaid}
          />
        </Col>
        {/* <Col span={24}>
          <PaymentCard
            suffix="overdue"
            prexfix="bill"
            value={data.countOverdue}
          />
        </Col> */}
      </Row>
    </div>
  );
};

const Right = ({ value }: { value: any }) => {
  const params = appendStartAndEndOfTime(value);
  const { data, isLoading, isError, error } = DASHBOARD_PAYMENT_RIGHT(params);

  if (isLoading) {
    return (
      <div
        style={{
          height: 220,
        }}
      >
        <LoadingPage />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div
        style={{
          height: 220,
        }}
      >
        <ErrorPage error={error} />
      </div>
    );
  }

  return (
    <div
      style={{
        height: 220, // if over to 440
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
      }}
    >
      <Row gutter={[6, 6]}>
        <Col span={14}>
          <PaymentCard
            format="0,0.00"
            suffix="complete"
            prexfix="baht"
            value={data.countPaid}
            fixedHeight={200}
          />
        </Col>
        <Col span={10}>
          <Row>
            {data.paidTypes.map((item) => {
              return (
                <Col span={24}>
                  <PaymentCard
                    format="0,0.00"
                    suffix={item.type}
                    prexfix="baht"
                    value={item.amount}
                    fixedHeight={50}
                  />
                </Col>
              );
            })}
          </Row>
        </Col>
        {/* <Col span={14}>
          <PaymentCard
            format="0,0.00"
            suffix="overdue"
            prexfix="baht"
            value={data.countOverdue}
            fixedHeight={200}
          />
        </Col> */}
        {/* <Col span={10}>
          <Row>
            {data.overdueTypes.map((item) => {
              return (
                <Col span={24}>
                  <PaymentCard
                    format="0,0.00"
                    suffix={item.type}
                    prexfix="baht"
                    value={item.amount}
                    fixedHeight={50}
                  />
                </Col>
              );
            })}
          </Row>
        </Col> */}
      </Row>
    </div>
  );
};

const PaymentCard = ({
  prexfix,
  value,
  suffix,
  format = "0,0",
  fixedHeight,
}: {
  prexfix: "bill" | "baht";
  suffix:
    | "complete"
    | "overdue"
    | "qr-code-bot"
    | "qr-code-scb"
    | "cash"
    | "credit-card";
  value: string | number;
  format?: "0,0" | "0,0.00";
  fixedHeight?: number;
}) => {
  const { t } = useTranslation();
  return (
    <Row
      style={{
        backgroundColor: "#f6f6f5",
        height: fixedHeight || 100,
      }}
      align="middle"
      className="px-5"
    >
      <Col span={8}>
        <span className="f-18">{t(suffix)}</span>
      </Col>
      <Col span={8} className="text-center">
        <span className="f-18 color-gold">{numeral(value).format(format)}</span>
      </Col>
      <Col span={8} className="text-end">
        <span className="f-18">{t(prexfix)}</span>
      </Col>
    </Row>
  );
};

export default PaymentSection;
