import { Col, Form, Input, InputNumber, Row, Select, Switch } from "antd";
import dayjs from "dayjs";
import { FormInstance } from "rc-field-form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import CustomTimePicker from "../../../components/custom-time-picker";
import PageHeaderEdit from "../../../components/page-header-create";
import { validateMessages } from "../../../config/validateMessage";
import { SERVICE_LIST_PATH } from "../../../router/path";
import { GET_BILL_TYPE, IBillType } from "../../../store/bill";
import {
  GET_SERVICE_LIST_BY_ID,
  PATCH_SERVICE_LIST,
  POST_SERVICE_LIST,
} from "../../../store/service";
import {
  GET_SERVICE_CATEGORY,
  IServiceCategory,
} from "../../../store/service-category";
import { swalError, swalSuccess } from "../../../tools/swal";
import { getKeyByLocale } from "../../../tools/translation";
import th from "antd/es/date-picker/locale/th_TH";
import en from "antd/es/date-picker/locale/en_US";
import CustomDatePicker from "../../../components/custom-date-picker";

const ServiceListEditPage = () => {
  const { id } = useParams<{ id?: string }>();
  const { replace } = useHistory();
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();

  const { data } = GET_SERVICE_LIST_BY_ID(id);
  const service_category = GET_SERVICE_CATEGORY();
  const post_service = POST_SERVICE_LIST();
  const patch_service = PATCH_SERVICE_LIST();
  const bill_type = GET_BILL_TYPE();

  const client = useQueryClient();

  useEffect(() => {
    initial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const initial = () => {
    if (!data) return;
    const {
      serviceCategory,
      closeTime,
      openTime,
      billType,
      createdAt,
      updatedAt,
    } = data;
    const serviceData = service_category.data?.data.find(
      (e) => e.id === serviceCategory?.id
    );
    const nameService = getKeyByLocale({
      key: "name",
      data: serviceCategory,
      locale: i18n.language,
    });
    const billTypeData = bill_type.data?.data.find(
      (e) => e.id === billType?.id
    );
    const nameBillType = getKeyByLocale({
      key: "name",
      data: billType,
      locale: i18n.language,
    });

    form.setFieldsValue({
      ...data,
      serviceCategory: !serviceData
        ? nameService
        : serviceCategory?.id || undefined,
      closeTime: closeTime ? dayjs(`2022-02-09T${closeTime}:00`) : "",
      openTime: openTime ? dayjs(`2022-02-09T${openTime}:00`) : "",
      billType: !billTypeData ? nameBillType : billType.id || undefined,
      createdAt: dayjs(createdAt),
      updatedAt: dayjs(updatedAt),
    });
  };

  const afterMutation = {
    onSuccess: () => {
      swalSuccess();
      client.invalidateQueries(["get-services", "get-services-id"]);
      replace(SERVICE_LIST_PATH.index);
    },
    onError: ({ message }: any) => {
      swalError(t, message);
    },
  };

  const onFinish = (values: any) => {
    const { closeTime, openTime, serviceCategory, billType, extraPrice } =
      values;
    if (typeof serviceCategory === "string") {
      values = {
        ...values,
        serviceCategory: undefined,
      };
    }

    if (typeof billType === "string") {
      values = {
        ...values,
        billType: undefined,
      };
    }
    if (closeTime && openTime) {
      values = {
        ...values,
        closeTime: dayjs(closeTime).format("HH:mm:00Z"),
        openTime: dayjs(openTime).format("HH:mm:00Z"),
      };
    }
    if (id) {
      return patch_service.mutate(
        { ...values, id, extraPrice: Number(extraPrice) },
        afterMutation
      );
    }
    return post_service.mutate(
      { ...values, extraPrice: Number(extraPrice) },
      afterMutation
    );
  };

  return (
    <div className="bg-white color-black pb-1">
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        validateMessages={validateMessages}
        className="main-input-form"
      >
        <PageHeaderEdit
          label="menu.service-list"
          isLoading={post_service.isLoading || patch_service.isLoading}
        />
        <GeneralInput
          data={service_category.data?.data}
          billType={bill_type.data?.data}
          id={id}
        />
        <TimeInput />
        <PriceInput />
        <VatInput />
      </Form>
    </div>
  );
};

const GeneralInput = ({
  data,
  billType,
  id,
}: {
  data?: IServiceCategory[];
  billType?: IBillType[];
  id?: string;
}) => {
  const { t, i18n } = useTranslation();
  return (
    <Row gutter={[24, 24]} className="px-4 mb-4 pb-2">
      <Col span={12}>
        <Form.Item
          name="nameEn"
          label={`${t("nameEn")}`}
          rules={[{ required: true }, { max: 100 }]}
          className="custom-input"
        >
          <Input placeholder={`${t("nameEn")}`} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="nameTh"
          label={`${t("nameTh")}`}
          rules={[{ required: true }, { max: 100 }]}
          className="custom-input"
        >
          <Input placeholder={`${t("nameTh")}`} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="billType"
          label={t("bill-type")}
          rules={[{ required: true }]}
          className="custom-select-ant "
        >
          <Select
            placeholder={t("bill-type")}
            getPopupContainer={(value) => {
              return value;
            }}
            showSearch
            optionFilterProp="children"
            allowClear
          >
            {billType?.map((item, index) => {
              return (
                <Select.Option
                  value={item.id}
                  key={index}
                  disabled={item.status === "INACTIVE"}
                >
                  {getKeyByLocale({
                    key: "name",
                    data: item,
                    locale: i18n.language,
                  })}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="serviceCategory"
          rules={[{ required: true }]}
          label={`${t("menu.setting-service-category")}`}
          className="custom-select-ant "
        >
          <Select
            placeholder={`${t("menu.setting-service-category")}`}
            getPopupContainer={(value) => {
              return value;
            }}
            showSearch
            optionFilterProp="children"
            allowClear
          >
            {data?.map((item, index) => {
              return (
                <Select.Option
                  key={index}
                  value={item.id}
                  disabled={item.status === "INACTIVE"}
                >
                  {getKeyByLocale({
                    key: "name",
                    data: item,
                    locale: i18n.language,
                  })}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="descriptionEn"
          label={`${t("description-en")}`}
          rules={[{ required: true }, { max: 255 }]}
          className="custom-input"
        >
          <Input.TextArea placeholder={`${t("description-en")}`} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="descriptionTh"
          label={`${t("description-th")}`}
          rules={[{ required: true }, { max: 255 }]}
          className="custom-input"
        >
          <Input.TextArea placeholder={`${t("description-th")}`} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="order"
          label={`${t("order")}`}
          className="custom-input-number"
        >
          <InputNumber
            placeholder={t("order")}
            step={1}
            min={0}
            formatter={(value) => `${value}`.replace(".", "")}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="status"
          rules={[{ required: true }]}
          label={`${t("status")}`}
          className="custom-select-ant "
        >
          <Select
            placeholder={`${t("status")}`}
            getPopupContainer={(value) => {
              return value;
            }}
          >
            <Select.Option value="ACTIVE">{t("active")}</Select.Option>
            <Select.Option value="INACTIVE">{t("inactive")}</Select.Option>
          </Select>
        </Form.Item>
      </Col>
      {id && (
        <>
          <Col span={12}>
            <Form.Item
              name="createdAt"
              label={t("creation-date")}
              className="custom-ant-picker"
            >
              <CustomDatePicker
                locale={i18n.language === "en" ? en : th}
                format={i18n.language === "en" ? "DD-MM-YYYY" : "DD-MM-BBBB"}
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="updatedAt"
              label={`${t("update")}`}
              className="custom-ant-picker"
            >
              <CustomDatePicker
                locale={i18n.language === "en" ? en : th}
                format={i18n.language === "en" ? "DD-MM-YYYY" : "DD-MM-BBBB"}
                disabled
              />
            </Form.Item>
          </Col>
        </>
      )}
    </Row>
  );
};

const disableTimePicker = (open: number) => {
  let time: number[] = [];
  for (let i = 0; i <= open; i++) {
    time = [...time, i];
  }
  return time;
};

const TimeInput = () => {
  const { t } = useTranslation();
  return (
    <Row gutter={[24, 24]} className="px-4 pb-2">
      <Col span={12}>
        <Form.Item name="requireDetail" label={t("details")}>
          <SwitchWithTextInput />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="requireOpenCloseTime"
          label={`${t("open-time")} - ${t("close-time")}`}
        >
          <SwitchWithTextInput />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item
          shouldUpdate={(prev, next) =>
            prev.requireOpenCloseTime !== next.requireOpenCloseTime
          }
          noStyle
        >
          {(form: FormInstance) => {
            const requireOpenCloseTime = form.getFieldValue(
              "requireOpenCloseTime"
            );
            if (!requireOpenCloseTime) return <></>;

            return (
              <Form.Item label={`${t("open-close")}`}>
                <Row
                  gutter={24}
                  className="px-2 pt-4 pb-3"
                  style={{
                    border: "1px solid #e4e9f2",
                    marginLeft: "0px",
                    marginRight: "0px",
                  }}
                >
                  <Col span={8}>
                    <Form.Item
                      name="openTime"
                      label={`${t("open-time")}`}
                      rules={[{ required: true }]}
                    >
                      <CustomTimePicker
                        secondStep={60}
                        minuteStep={60}
                        showNow={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      shouldUpdate={(acc, cur) => acc.openTime !== cur.openTime}
                      noStyle
                    >
                      {(form: FormInstance) => {
                        const openTime = form.getFieldValue("openTime");
                        const open = dayjs(openTime).hour();
                        return (
                          <Form.Item
                            name="closeTime"
                            label={`${t("close-time")}`}
                            rules={[{ required: true }]}
                          >
                            <CustomTimePicker
                              secondStep={60}
                              minuteStep={60}
                              showNow={false}
                              disabledHours={() => disableTimePicker(open)}
                            />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="bookInAdvance"
                      label={`${t("book-in-advance")}`}
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        min={0}
                        placeholder={t("book-in-advance")}
                        addonAfter={t("hours")}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
    </Row>
  );
};

const PriceInput = () => {
  const { t } = useTranslation();
  return (
    <Row gutter={[24, 24]} className="px-4 pb-4">
      <Col span={12}>
        <Form.Item shouldUpdate>
          {(form: FormInstance) => {
            const requirePricePerHour = form.getFieldValue(
              "requirePricePerHour"
            );
            return (
              <Form.Item name="requirePrice" label={t("price")}>
                <SwitchWithTextInput disabled={requirePricePerHour} />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={12}></Col>
      <Col span={24}>
        <Form.Item
          shouldUpdate={(prev, next) => prev.requirePrice !== next.requirePrice}
          noStyle
        >
          {(form: FormInstance) => {
            const requirePrice = form.getFieldValue("requirePrice");
            if (!requirePrice) return <></>;

            return (
              <Row
                gutter={[24, 24]}
                className="pt-3 pb-3 px-2 mb-4"
                style={{
                  border: "1px solid #e4e9f2",
                  marginLeft: "0px",
                  marginRight: "0px",
                }}
              >
                <Col span={12}>
                  <Form.Item
                    name="oneBedroomPrice"
                    label={t("1-bed-room")}
                    rules={[{ required: true }]}
                    className="custom-input-number custom-input"
                  >
                    <InputNumber
                      placeholder={t("1-bed-room")}
                      min={0}
                      addonAfter={t("baht")}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="twoBedroomPrice"
                    label={t("2-bed-room")}
                    rules={[{ required: true }]}
                    className="custom-input-number custom-input"
                  >
                    <InputNumber
                      placeholder={t("2-bed-room")}
                      min={0}
                      addonAfter={t("baht")}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="threeBedroomPrice"
                    label={t("3-bed-room")}
                    rules={[{ required: true }]}
                    className="custom-input-number custom-input"
                  >
                    <InputNumber
                      placeholder={t("3-bed-room")}
                      min={0}
                      addonAfter={t("baht")}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="fourBedroomPrice"
                    label={t("4-bed-room")}
                    rules={[{ required: true }]}
                    className="custom-input-number custom-input"
                  >
                    <InputNumber
                      placeholder={t("4-bed-room")}
                      min={0}
                      addonAfter={t("baht")}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="duplexPrice"
                    label={t("duplex-room")}
                    rules={[{ required: true }]}
                    className="custom-input-number custom-input"
                  >
                    <InputNumber
                      placeholder={t("duplex-room")}
                      min={0}
                      addonAfter={t("baht")}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="penthousePrice"
                    label={t("penthouse-room")}
                    rules={[{ required: true }]}
                    className="custom-input-number custom-input"
                  >
                    <InputNumber
                      placeholder={t("penthouse-room")}
                      min={0}
                      addonAfter={t("baht")}
                    />
                  </Form.Item>
                </Col>
              </Row>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item shouldUpdate>
          {(form: FormInstance) => {
            const requirePrice = form.getFieldValue("requirePrice");
            return (
              <Form.Item name="requirePricePerHour" label={t("price/hours")}>
                <SwitchWithTextInput disabled={requirePrice} />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item shouldUpdate>
          {(form: FormInstance) => {
            const requirePricePerHour = form.getFieldValue(
              "requirePricePerHour"
            );
            if (!requirePricePerHour) return <></>;
            return (
              <Form.Item
                name="pricePerHour"
                label={t("price/hours")}
                rules={[{ required: true }]}
                className="custom-input-number custom-input"
              >
                <InputNumber
                  placeholder={t("price/hours")}
                  min={0}
                  addonAfter={t("baht")}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="requirePrivate" label={t("surcharge")}>
          <SwitchWithTextInput />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item shouldUpdate>
          {(form: FormInstance) => {
            const requireVat = form.getFieldValue("requirePrivate");
            if (!requireVat) return <></>;
            return (
              <Form.Item
                name="extraPrice"
                label={t("surcharge")}
                rules={[{ required: true }]}
                className="custom-input-number custom-input"
              >
                <InputNumber
                  placeholder={t("surcharge")}
                  min={0}
                  addonAfter={t("baht")}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
    </Row>
  );
};

const VatInput = () => {
  const { t } = useTranslation();
  return (
    <Row gutter={[24, 24]} className="px-4 mb-4 pb-2">
      <Col span={12}>
        <Form.Item name="requireSvg" label={t("svc")}>
          <SwitchWithTextInput />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item shouldUpdate>
          {(form: FormInstance) => {
            const requireSvg = form.getFieldValue("requireSvg");
            if (!requireSvg) return <></>;
            return (
              <Form.Item
                name="svg"
                label={t("svc")}
                rules={[{ required: true }]}
                className="custom-input-number custom-input"
              >
                <InputNumber placeholder={t("svc")} min={0} addonAfter="%" />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="requireVat" label="VAT">
          <SwitchWithTextInput />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item shouldUpdate>
          {(form: FormInstance) => {
            const requireVat = form.getFieldValue("requireVat");
            if (!requireVat) return <></>;
            return (
              <Form.Item
                name="vat"
                label="VAT"
                rules={[{ required: true }]}
                className="custom-input-number custom-input"
              >
                <InputNumber placeholder="VAT" min={0} addonAfter="%" />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
    </Row>
  );
};

const SwitchWithTextInput = ({
  onChange,
  value,
  disabled = false,
}: {
  value?: boolean;
  onChange?: any;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();

  const active: string = t("active");
  const inactive: string = t("inactive");
  return (
    <Row align="middle" justify="center">
      <Col span={3}>
        <Switch onChange={onChange} checked={value} disabled={disabled} />
      </Col>
      <Col span={20}>
        <Input readOnly value={value ? active : inactive} disabled />
      </Col>
    </Row>
  );
};

export default ServiceListEditPage;
