import { Select } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

interface IYear {
  name: string;
  value: dayjs.Dayjs;
}

const YearSelect = ({
  onChange,
  value,
  from = 2020,
}: {
  value?: any;
  onChange?: any;
  from?: number;
}) => {
  const { i18n, t } = useTranslation();

  const generateYear = (): IYear[] => {
    const now = dayjs().year();
    let obj: IYear[] = [];

    for (let i = from; i <= now; i++) {
      const value = dayjs().year(i);
      obj = [...obj, { name: `${i}`, value }];
    }

    return obj;
  };

  const years = generateYear();
  const format = i18n.language === "en" ? "YYYY" : "BBBB";

  return (
    <Select
      allowClear
      onChange={onChange}
      value={value}
      placeholder={t("year")}
      style={{ width: 90 }}
    >
      {years.map((item, index) => {
        return (
          <Select.Option key={index} value={item.value.format('YYYY')}>
            {item.value.format(format)}
          </Select.Option>
        );
      })}
    </Select>
  );
};

export default YearSelect;
