import React from "react";

const BIN_ICON = (props: React.SVGProps<SVGSVGElement> = {}) => {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141.73 141.73"
      {...props}
    >
      <path
        data-name="Path 20627"
        d="M26.34 137.65h89.06V33.77H26.34zM122.82 11.5h-26l-7.4-7.42H52.31l-7.42 7.42h-26v14.85h103.93z"
        fill="#ababa8"
      />
    </svg>
  );
};

export default BIN_ICON;
