import React from "react";

const USER_MANAGE = (props: React.SVGProps<SVGSVGElement> = {}) => {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141.73 141.73"
      {...props}
    >
      <path
        data-name="Path 20611"
        d="M101.75 70.87A17.16 17.16 0 1084.6 53.7a17.11 17.11 0 0017.06 17.16zM50.27 64a20.6 20.6 0 10-20.6-20.59A20.5 20.5 0 0050.09 64h.18zm51.48 20.6C89.19 84.6 64 90.92 64 103.48v15.44h75.53v-15.44c-.01-12.56-25.23-18.88-37.78-18.88zm-51.49-6.86c-16 0-48 8-48 24v17.16h48v-15.42c0-5.84 2.25-16.07 16.26-23.82a84 84 0 00-16.26-1.92z"
        fill="#b59d4e"
      />
    </svg>
  );
};

export default USER_MANAGE;
