import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useRecoilState } from "recoil";
import CustomStatusBackground from "../../components/custom-status";
import CustomTable from "../../components/custom-table";
import PageFilter, { IPageFilterChoice } from "../../components/page-filter";
import PageHeader, { ISearchInput } from "../../components/page-header";
import { PARCEL_CREATE_PATH } from "../../router/path";
import { page_params, select_rows_atom } from "../../store/global";
import {
  BULK_DELETE_POST_PARCEL,
  CHECKOUT_POST_PARCEL,
  DELETE_POST_PARCEL,
  EXPORT_PARCEL,
  EXPORT_PARCEL_PDF,
  GET_POST_PARCEL,
  LINE_NOTIFICATION_POST_PARCEL,
} from "../../store/parcel";
import { GET_UNIT } from "../../store/units";
import { swalDelete, swalError, swalSuccess } from "../../tools/swal";
import moment from "moment";
import { parse } from "../../tools/datetime";
import { useForm } from "antd/lib/form/Form";
import { Col, Form, Input, Modal, Row } from "antd";
import { useState } from "react";

const ParcelPage = () => {
  const { i18n, t } = useTranslation();
  const [form] = useForm();
  const [params, setParams] = useRecoilState(page_params);
  const [gSelectRows, sSelectRows] = useRecoilState(select_rows_atom);
  const { data, refetch, isLoading } = GET_POST_PARCEL({
    ...params,
  });
  const client = useQueryClient();
  const units = GET_UNIT({ pagination: false });
  const checkout = CHECKOUT_POST_PARCEL();
  const { mutate } = DELETE_POST_PARCEL();
  const bulkDelete = BULK_DELETE_POST_PARCEL();
  const exportExcel = EXPORT_PARCEL();
  const lineNotification = LINE_NOTIFICATION_POST_PARCEL();
  const exportPdf = EXPORT_PARCEL_PDF();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [id, setId] = useState<number>();
  const [ids, setIds] = useState<number[]>([]);

  const searchInput: ISearchInput[] = [
    {
      label: t("search"),
      key: "search",
      span: 10,
      type: "input",
      props: {
        placeholder: t("search-parcel-number-name"),
      },
    },
    {
      label: t("house-number"), //unit number to use, house number to show
      key: "unit",
      span: 7,
      type: "select",
      props: {
        placeholder: t("all"),
        optionValue: {
          values: units.data?.data || [],
          key: "id",
          name: "unitNumber",
        },
      },
    },
    {
      label: t("delivery-date"),
      key: "deliveryDate",
      span: 7,
      type: "range-picker",
      props: {
        placeholder: [t("start-date"), t("end-date")],
        getPopupContainer: (props: HTMLElement) => {
          return props;
        },
      },
    },
    {
      label: t("received-date"),
      key: "receivedDate",
      span: 7,
      type: "range-picker",
      props: {
        placeholder: [t("start-date"), t("end-date")],
        getPopupContainer: (props: HTMLElement) => {
          return props;
        },
      },
    },
  ];

  const countData = data?.countData;

  const choices: IPageFilterChoice[] = [
    {
      label: t("all"),
      total: countData?.countAll || 0,
    },
    {
      label: t("not-received"),
      key: "false",
      total: countData?.countIsNotReceived || 0,
    },
    {
      label: t("received"),
      key: "true",
      total: countData?.countIsReceived || 0,
    },
    {
      label: t("cancel"),
      key: "cancel",
      total: countData?.countIsCancel || 0,
    },
  ];

  const columns: ColumnsType = [
    // {
    //   dataIndex: "parcelNumber",
    //   title: t("parcel-number"),
    // },
    {
      dataIndex: "unit",
      title: t("house-number"), //unit number to use, house number to show
      render: (text, record) => {
        return <>{text?.unitNumber || "-"}</>;
      },
    },
    {
      dataIndex: "consignee",
      title: t("name"),
      render: (text, record) => {
        return <>{text?.fullName || "-"}</>;
      },
    },
    {
      dataIndex: "type",
      title: t("type"),
      render: (text) => <>{text ? t(text) : "-"}</>,
    },
    {
      dataIndex: "deliveryDate",
      title: t("delivery-date"),
      render: (text) =>
        text ? parse(text, i18n.language, "DD MMM YYYY") : "-",
    },
    {
      dataIndex: "isReceived",
      title: t("status"),
      width: "12%",
      render: (text, record: any) => {
        return (
          <>
            {text === null && !record.isCancel && "-"}
            {record.isCancel === true && (
              <CustomStatusBackground
                lable={t("cancel")}
                className={"frame-background-cancel"}
              />
            )}
            {text === false && !record.isCancel && (
              <CustomStatusBackground
                lable={t("not-received")}
                className={"frame-no-background"}
              />
            )}
            {text === true && !record.isCancel && (
              <CustomStatusBackground
                lable={t("received")}
                className={"frame-background"}
              />
            )}
          </>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    if (values.deliveryDate) {
      const date = values.deliveryDate;
      const deliveryStartDate = moment(date[0]).format("YYYY-MM-DD 00:00");
      const deliveryEndDate = moment(date[1]).format("YYYY-MM-DD 23:59");
      values = { ...values, deliveryStartDate, deliveryEndDate };
    }
    if (values.receivedDate) {
      const date = values.receivedDate;
      const receivedStartDate = moment(date[0]).format("YYYY-MM-DD 00:00");
      const receivedEndDate = moment(date[1]).format("YYYY-MM-DD 23:59");
      values = { ...values, receivedStartDate, receivedEndDate };
    }
    delete values.deliveryDate;
    delete values.receivedDate;
    setParams({ ...params, ...values });
  };

  const onFilter = (choice: IPageFilterChoice) => {
    let isReceived = undefined;
    let isCancel = undefined;
    if (choice.key !== "cancel" && choice.key) {
      isReceived = choice.key === "true" ? true : false;
    }
    if (choice.key === "cancel") {
      isCancel = true;
    }
    return setParams({ ...params, isReceived, isCancel });
  };

  const onCheckout = () => {
    const ids = gSelectRows.flatMap((item) => item.data);
    checkout.mutate(
      { ids },
      {
        onError: (err: any) => {
          swalError(t, err);
        },
        onSuccess: () => {
          client.invalidateQueries("post-parcels");
          sSelectRows([]);
          swalSuccess(t);
        },
      }
    );
  };

  const onDelete = async (id: number) => {
    const isReceived = data?.data.find((item) => item.id === id)?.isReceived;
    if (isReceived) return swalError(t, t("this-parcel-has-received"));
    const confirm = await swalDelete(t);
    if (confirm) {
      setId(id);
      setIsModalVisible(true);
    }
  };

  const onFinish = (values: any) => {
    mutate(
      { id: id, cancelNote: values.cancelNote },
      {
        onError: (err: any) => {
          swalError(t, t(err.message));
        },
        onSuccess: () => {
          swalSuccess(t);
          setIsModalVisible(false);
          setId(undefined);
          form.setFieldsValue({ cancelNote: undefined });
          refetch();
        },
      }
    );
  };

  const onBulkDelete = () => {
    const cancelNote = form.getFieldValue("cancelNote");
    bulkDelete?.mutate(
      { ids, cancelNote },
      {
        onError: async (err: any) => {
          return swalError(t, t(err.message));
        },
        onSuccess: () => {
          swalSuccess(t);
          setIsModalVisible(false);
          form.setFieldsValue({ cancelNote: undefined });
          setIds([]);
          refetch();
        },
      }
    );
  };

  const onReset = () => {
    const values = {
      deliveryStartDate: undefined,
      deliveryEndDate: undefined,
      receivedStartDate: undefined,
      receivedEndDate: undefined,
    };
    setParams({ ...params, ...values });
  };

  const _getCurrentFilter = (): string | undefined => {
    const { isReceived, isCancel } = params;
    if (isReceived) return "true";
    if (isReceived === false) return "false";
    if (isCancel) return "cancel";
    return undefined;
  };

  return (
    <div>
      <PageHeader
        menu="parcel"
        onSearch={onSearch}
        searchInput={searchInput}
        label={"create"}
        path={PARCEL_CREATE_PATH}
        onBulkDelete={(id) => {
          setIds(id);
          setIsModalVisible(true);
        }}
        invalidateQueries={["post-parcels"]}
        onReset={onReset}
        topicExport="Parcel"
        exportExcel={exportExcel}
        lineNotification={lineNotification}
        hasExportPdf={true}
        exportPdf={exportPdf}
      />
      <PageFilter
        choices={choices}
        current={_getCurrentFilter()}
        onClick={onFilter}
        parcelAction={onCheckout}
        isLoading={checkout.isLoading}
      />
      <CustomTable
        rowKey="id"
        title={t("parcel-list")}
        dataSource={data?.data || []}
        columns={columns}
        pagination={{
          limit: params.limit,
          page: params.page,
          total: data?.count || 0,
        }}
        onDelete={onDelete}
        onPageChange={(page, limit) => setParams({ ...params, page, limit })}
        isLoadingTable={isLoading}
      />
      <Form
        layout="vertical"
        form={form}
        className="fixed-input-size"
        onFinish={onFinish}
      >
        <Modal
          title={t("note")}
          visible={isModalVisible}
          onOk={() => {
            const values = form.getFieldsValue();
            if (!ids.length) onFinish(values);
            if (ids.length) onBulkDelete();
          }}
          onCancel={() => setIsModalVisible(false)}
        >
          <Row gutter={[24, 6]}>
            <Col>
              <span className="f-16">{t("cancel-note")}</span>
            </Col>
            <Col span={24}>
              <Form.Item
                name="cancelNote"
                className="custom-input"
                rules={[{ required: true }, { max: 255 }]}
              >
                <Input.TextArea placeholder={t("cancel-note")} />
              </Form.Item>
            </Col>
          </Row>
        </Modal>
      </Form>
    </div>
  );
};

export default ParcelPage;
