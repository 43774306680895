export const genUniqueId = (uniques?: string[]): string => {
  if (uniques) {
    const random = `id_${Math.random().toString(16).slice(2)}`;
    if (uniques.some((e) => e === random)) {
      return genUniqueId(uniques);
    }
    return random;
  }
  return `id_${Math.random().toString(16).slice(2)}`;
};
