import { TFunction } from "react-i18next";
import { t } from "i18next";
import Swal from "sweetalert2";

export const swalDelete = async (
  _?: TFunction,
  text?: string
): Promise<boolean> => {
  return new Promise((resolve) => {
    Swal.fire({
      showCancelButton: true,
      confirmButtonText: t("swal.confirm"),
      cancelButtonText: t("swal.cancel"),
      confirmButtonColor: "#F27474",
      focusConfirm: false,
      title: t("swal.confirm-data-deletion"),
      text: t(text ? text : "swal.irreversible"),
      icon: "question",
      reverseButtons: true,
      iconColor: "#595959",
      background: "#e1ded9",
    }).then(async (result) => {
      if (result.isConfirmed) {
        resolve(true);
      }
      resolve(false);
    });
  });
};

export const swalError = (_?: TFunction, text?: string) => {
  return new Promise((resolve) => {
    Swal.fire({
      icon: "error",
      title: t("swal.an-error-occurred"),
      text: text,
      iconColor: "#595959",
      background: "#e1ded9",
      confirmButtonColor: "#b69e4f",
      customClass: {
        confirmButton: "button-submit-swal-error",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        resolve(true);
      }
      resolve(false);
    });
  });
};

export const swalSuccess = (_?: TFunction, text?: string) => {
  Swal.fire({
    icon: "success",
    iconColor: "#595959",
    title: t("swal.success"),
    text: text,
    timer: 1500,
    showConfirmButton: false,
    background: "#e1ded9",
  });
};

export const swalWarning = (_?: TFunction, text?: string) => {
  Swal.fire({
    icon: "warning",
    iconColor: "#9f8f1e",
    // title: t("swal.success"),
    text: text,
    showConfirmButton: true,
    background: "#e1ded9",
  });
};
