import ApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useTranslation } from "react-i18next";
import { Empty } from "antd";

const BarChart = ({
  isGold = false,
  data,
  horizontal,
}: {
  isGold?: boolean;
  data: { label: string; value: number }[];
  horizontal?: boolean;
}) => {
  const { t } = useTranslation();

  const options: ApexOptions = {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "20%",
        horizontal: horizontal,
        barHeight: "50%",
        borderRadius: 3,
      },
    },
    dataLabels: {
      enabled: false,
    },
    grid: {
      row: {
        // colors: horizontal ? undefined : ["#F9F9F9", "transparent"],
        // opacity: horizontal ? 0 : 0.5,
      },
      borderColor: "#F9F9F9",
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        formatter: (value) => {
          if (value.length > 15) {
            return `${value.substring(0, 15)}...`;
          }
          return value;
        },
      },
    },
  };

  if (data.length <= 0 || data.every((e) => e.value === 0)) {
    return (
      <div className="h-100 c-container">
        <Empty />
      </div>
    );
  }

  const colors = ["#B69E4F", "#54544D", "#AAAAA7", "#8F731B", "#64666B"];

  const series = data.map((item, index) => {
    const cnumber = +(index / 5).toFixed(2).split(".")[1] / 20;
    const c = colors.map((item) => {
      if (isGold) return colors[0];
      return item;
    });
    return { x: item.label, y: item.value, fillColor: c[cnumber] };
  });

  return (
    <div className="h-100">
      <ApexChart
        options={options}
        series={[{ data: series, name: t("amount") }]}
        type="bar"
        width="100%"
        height={300}
        className="px-5"
      />
    </div>
  );
};

export default BarChart;
