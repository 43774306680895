//scan
export const SCAN_QRCODE_PATH = "/scan";
export const PARCEL_LIST_PATH = "/list-parcel";
export const USER_PATH = "/user";
export const REPAIRMAN_INDEX_PATH = "/repairman";
export const REPAIRMAN_PATH = {
  index: `${REPAIRMAN_INDEX_PATH}`,
  view: `${REPAIRMAN_INDEX_PATH}/view/:id(\\d+)`,
  edit: `${REPAIRMAN_INDEX_PATH}/:id(\\d+)`,
};
export const ADMIN_PATH = "/admin";
