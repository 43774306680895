import { Col, Form, Input, Row, Select } from "antd";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { useForm } from "antd/lib/form/Form";
import PageHeaderEdit from "../../components/page-header-create";
import CustomTable from "../../components/custom-table";
import { ColumnsType } from "antd/lib/table";
import { swalDelete, swalError, swalSuccess } from "../../tools/swal";
import { useReducer, useState } from "react";
import { GET_UNIT } from "../../store/units";
import axios from "axios";
import { serverUrl } from "../../config/serverUrl";
import { reducerFunction, TParcelReducer } from "./parcel-reducer";
import moment from "moment";
import { IUser, parcelType } from "../../store";
import { LOCAL_TOKEN_KEY } from "../../config/axios";
import { VALIDATE_MESSAGES } from "../../config/validateMessage";

const postPostPacelApi = serverUrl + "/api/post-parcel";
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${localStorage.getItem(LOCAL_TOKEN_KEY)}`,
};

const CreateParcel = () => {
  const [form] = useForm();
  const { t } = useTranslation();
  const units = GET_UNIT({ pagination: false, status: "ACTIVE" });
  const [getMembers, setMembers] = useState<IUser[]>();
  const [parcels, dispatch] = useReducer<TParcelReducer>(reducerFunction, []);
  const [id, setId] = useState<number>(NaN);
  const [loading, setloading] = useState<boolean>(false);

  const columns: ColumnsType = [
    {
      dataIndex: "unit",
      title: t("house-number"), //unit number to use, house number to show
      render: (text, record) => {
        const unit = units.data?.data.find((item) => item.id === text);
        return <>{unit?.unitNumber}</>;
      },
    },
    {
      dataIndex: "recipientName",
      title: t("recipient-name"),
    },
    {
      dataIndex: "type",
      title: "Type",
    },
  ];

  const onFinish = async () => {
    setloading(true);
    try {
      for (const item of parcels) {
        axios.defaults.validateStatus = (_) => true;
        const res = await axios.post(postPostPacelApi, item, { headers });
        if (res.status !== 201) throw new Error(res.data.message);
        dispatch({ key: "delete", id: item.id });
        setloading(false);
        swalSuccess(t);
      }
    } catch (err) {
      setloading(false);
      swalError(t, `${err}`);
    } finally {
      //
    }
  };

  const onAddParcel = (values: any) => {
    const member = getMembers?.find((item) => item.id === values.consignee);
    const memberName = member?.firstName + " " + member?.lastName;
    if (id) setId(NaN);
    if (!id) values = { ...values, id: moment().format("YYYYMMDDHHmmss") };
    const prev = parcels.filter((item) => item.id !== id);
    const next = { ...values, recipientName: member ? memberName : "-" };
    dispatch({ key: "edit", data: [next, ...prev] });
    form.resetFields();
  };

  const onEditParcel = (id: number) => {
    setId(id);
    let next: any = parcels.find((item) => item.id === id);
    form.setFieldsValue(next);
  };

  const onDelete = async (id: number) => {
    const confirm = await swalDelete(t);
    if (confirm) {
      dispatch({ key: "delete", id: id });
    }
  };

  const onSelectUnit = (id: number) => {
    form.setFieldsValue({ consignee: undefined, phoneNumber: undefined });
    const members = units.data?.data
      .find((item) => item.id === id)
      ?.userUnits.map((item: any) => {
        return {
          ...item.user,
          fullName: item.user?.fullName + ` (${t(item.role.toLowerCase())})`,
        };
      })
      .filter((item: any) => item.isActive === true);
    setMembers(members);
  };

  const onSelectUser = (value: number) => {
    const member = getMembers?.find((item) => item.id === value);
    const phoneNumber = member?.phoneNumber;
    const lineName = member?.lineName;
    form.setFieldsValue({ phoneNumber, lineName });
  };

  const FormEdit = () => {
    return (
      <Row gutter={[24, 24]} className="px-4 mb-4 pb-2">
        <Col span={12} hidden>
          <Form.Item name="id">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="parcelCode"
            label={t("tracking-number")}
            className="custom-input "
          >
            <Input placeholder={t("tracking-number")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="unit"
            label={`${t("house-number")}`} //unit number to use, house number to show
            rules={[{ required: true }]}
            className="custom-select-ant "
          >
            <Select
              placeholder={`${t("house-number")}`} //unit number to use, house number to show
              onSelect={onSelectUnit}
              showSearch
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {units.data?.data.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.unitNumber}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="consignee"
            label={`${t("name")}`}
            rules={[{ required: true }]}
            className="custom-select-ant"
          >
            <Select placeholder={`${t("name")}`} onSelect={onSelectUser}>
              {getMembers?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item?.fullName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="phoneNumber"
            label={t("phone-number")}
            className="custom-input"
            rules={[{ required: true }]}
          >
            <Input placeholder={t("phone-number")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="lineName"
            label={t("line-name")}
            className="custom-input"
          >
            <Input disabled placeholder={t("line-name")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="type"
            label={`${t("type")}`}
            className="custom-select-ant "
            rules={[{ required: true }]}
          >
            <Select placeholder={`${t("type")}`}>
              {parcelType.map((item) => (
                <Select.Option key={item.label} value={item.key}>
                  {t(item.label)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}></Col>
        <Col span={12}>
          <Form.Item
            name="note"
            label={`${t("note")}`}
            rules={[{ max: 255 }]}
            className="custom-input"
          >
            <Input.TextArea rows={3} placeholder={`${t("note")}`} />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <div className="bg-white color-black pb-1">
        <Form
          layout="vertical"
          form={form}
          onFinish={onAddParcel}
          validateMessages={VALIDATE_MESSAGES(t)}
          className="fixed-input-size"
        >
          <PageHeaderEdit
            label="menu.parcel"
            labelTo="menu.parcel"
            parcelAction={id ? undefined : () => {}}
          />
          <FormEdit />
        </Form>
      </div>
      {parcels.length > 0 && (
        <CustomTable
          rowKey="parcelNumber"
          title="parcel"
          dataSource={parcels || []}
          columns={columns}
          onPageChange={() => {}}
          parcelAction={onFinish}
          editParcel={onEditParcel}
          onDelete={onDelete}
          pagination={{
            limit: 100,
            page: 1,
            total: parcels.length || 0,
          }}
          disabledSelect={true}
          isLoading={loading}
        />
      )}
    </>
  );
};

export default CreateParcel;
