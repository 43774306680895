import axios from "axios";
import dayjs from "dayjs";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { IResponse } from ".";
import { exportFile, throwResponse } from "../config/axios";
import { getClientTimeZone } from "../tools/datetime";
import { IBillType } from "./bill";
import { POST_MEDIA_OBJECT } from "./media";

interface IPayment {
  id: number;
  createdAt: string;
  updatedAt: string;
  billingDate: string;
  paymentDate: string;
  paymentDueDate: string;
  paymentReference: string;
  amount: number;
  paymentType: string;
  status: string;
  note: string;
  unit: number;
  users: number[];
  billType: IBillType;
  privilegeMediaObjects: IMedia[];
  qrImage: IQRImage;
  qrBotImage: IQRImage;
  approveBillDate: string;
  taxId: string;
  unitReference: string;
}

interface IMedia {
  id: number;
  createdAt: string;
  updatedAt: string;
  filename: string;
  alternativeText: string;
  width: string;
  height: string;
  mimeType: string;
  size: number;
  path: string;
  url: string;
  imageUrl: string;
}

export interface IQRImage {
  id: number;
  createdAt: string;
  updatedAt: string;
  imageUrl: string;
  filename: string;
  alternativeText: string;
}

export const GET_PAYMENTS = (params?: {}): UseQueryResult<
  IResponse<IPayment[]>
> => {
  return useQuery(
    ["get-payments", params],
    async () => {
      const res = await axios.get("/api/payment", {
        params: params,
      });
      if (res.status === 200) {
        const { data, count, page, countData } = res.data;
        return { data: data, count, page, countData };
      }
      throwResponse(res);
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const GET_PAYMENT_ID = (id: string): UseQueryResult<IPayment> => {
  return useQuery(["get-payment-id", id], async () => {
    if (!id) return;
    const res = await axios.get(`/api/payment/${id}`);
    if (res.status === 200) {
      const { data } = res.data;
      const mapMedia = data.paymentMediaObjects.map((media: any) => {
        const mediaObjects = {
          id: media.mediaObject.id,
          name: media.mediaObject.filename,
          uid: media.uid,
          url: media.mediaObject.imageUrl,
        };
        return mediaObjects;
      });
      data.unit = data.unit?.id;
      const users = data.userPayments.map((item: any) => item.user?.id);
      data.billType = data.billType?.id;
      if (data.createdAt) data.createdAt = dayjs(data.createdAt);
      if (data.paymentDate) data.paymentDate = dayjs(data.paymentDate);
      if (data.paymentDueDate) data.paymentDueDate = dayjs(data.paymentDueDate);
      if (data.approveBillDate)
        data.approveBillDate = dayjs(data.approveBillDate);
      data.billLists = data.billLists?.map((item: any) => {
        const list = item.paymentTypeList?.id;
        delete item.paymentTypeList;
        delete item.createdAt;
        delete item.updatedAt;
        return { ...item, list };
      });
      delete data.userPayments;
      return {
        ...data,
        picture: mapMedia,
        users,
      };
    }
    throwResponse(res);
  });
};

export const POST_PAYMENT = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const { picture } = data;
    let imageId = undefined;
    if (picture)
      imageId = await Promise.all(
        picture.map(async (item: any) => {
          const idImage = await POST_MEDIA_OBJECT(item.originFileObj);
          return idImage;
        })
      );
    delete data.picture;
    data = { ...data, mediaObjects: imageId };
    const res = await axios.post("/api/payment", data);
    if (res.status === 201) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

export const PATCH_PAYMENT = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const { picture } = data;
    let imageId = undefined;
    if (picture)
      imageId = await Promise.all(
        picture.map(async (item: any) => {
          if (!item.id) {
            const idImage = await POST_MEDIA_OBJECT(item.originFileObj);
            return idImage;
          }
          return item.id;
        })
      );
    delete data.picture;
    data = { ...data, mediaObjects: imageId };
    const res = await axios.patch(`/api/payment/${data.id}`, data);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const DELETE_PAYMENT = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/api/payment/${data.id}`);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const BULK_DELETE_PAYMENTS = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/api/payment/bulk`, {
      data: data,
    });
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const EXPORT_PAYMENT = (): UseMutationResult<Blob> => {
  return useMutation(
    async (data: any) => {
      const timeZone = getClientTimeZone();
      const res = await exportFile.post("/api/export-excel/payment", {
        ...data,
        timeZone,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { retry: false }
  );
};

export const EXPORT_PAYMENT_TEMPLATE = (): UseMutationResult<Blob> => {
  return useMutation(
    async (data: any) => {
      const timeZone = getClientTimeZone();
      const res = await exportFile.post("/api/export-excel/template-payment", {
        ...data,
        timeZone,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { retry: false }
  );
};

export const LINE_NOTIFICATION_PAYMENT = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post(
      "/api/line-notification/send-message-payment",
      data
    );
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const PAID_PAYMENT = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.patch(`/api/payment/change-status`, data);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const GET_GENERATE_QR_CODE_SCB_PAYMENT = (): UseMutationResult => {
  return useMutation(async (paymentId: any) => {
    const res = await axios.get(
      `/api/scb/${paymentId}/payment/generate-qrcode-qr30`
    );
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const GET_GENERATE_QR_CODE_BOT_PAYMENT = (): UseMutationResult => {
  return useMutation(async (paymentId: any) => {
    const res = await axios.get(`/api/payment/generate-qrcode/${paymentId}`);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const IMPORT_PAYMENT = (): UseMutationResult => {
  return useMutation(async (values) => {
    const res = await axios.post(`/api/payment/import-payment`, values);
    if (res.status === 201) {
      return;
    }
    throwResponse(res);
  });
};

export const EXPORT_INVOICE_PAYMENT = (): UseMutationResult<Blob> => {
  return useMutation(
    async (data: any) => {
      const timeZone = getClientTimeZone();
      const res = await exportFile.post("/api/export-pdf/payment-invoice", {
        ...data,
        timeZone,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { retry: false }
  );
};

export const EXPORT_RECEIPT_PAYMENT = (): UseMutationResult<Blob> => {
  return useMutation(
    async (data: any) => {
      const timeZone = getClientTimeZone();
      const res = await exportFile.post("/api/export-pdf/payment-receipt", {
        ...data,
        timeZone,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { retry: false }
  );
};
