import axios from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { IResponse } from ".";
import { exportFile, throwResponse } from "../config/axios";
import { getClientTimeZone } from "../tools/datetime";
import { IUser } from "./user";

interface IRole {
  id: number;
  title: string;
  isActive: boolean;
  menu: IMenu;
}

interface IMenu {
  id: number;
  title: string;
  code: string;
}

interface IRoleId {
  id: number;
  createdAt: string;
  updatedAt: string;
  title: string;
  isActive: boolean;
  roleMenus: IRole[];
}

export const GET_ROLE = (params?: {}): UseQueryResult<IResponse<IRole[]>> => {
  return useQuery(
    ["get-role", params],
    async () => {
      const res = await axios.get("/api/role", {
        params: params,
      });

      if (res.status === 200) {
        const { data, page, count, countData } = res.data;

        const roleMenu = data.map((roleItem: any) => {
          const { roleMenus, title, isActive, id } = roleItem;

          const roleMenu = roleMenus?.map((menuItem: any) => {
            const { menu, isActive } = menuItem;
            return {
              id: menu.id,
              isActive: isActive,
              code: menu.code,
              title: menu.title,
            };
          });

          return { id, title, isActive, menu: roleMenu };
        });
        return { data: roleMenu, page, count, countData };
      }
      throwResponse(res);
    },
    { retry: false, refetchOnWindowFocus: false }
  );
};

export const GET_ROLE_ONLY_MEMBER = (): UseQueryResult<IRole[]> => {
  return useQuery("role-only-member", async () => {
    const res = await axios.get("/api/role/member");
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

export const GET_ROLE_BY_ID = (
  id?: string | number
): UseQueryResult<IRoleId> => {
  return useQuery(["get-role-id", id], async () => {
    if (!id) return;
    const res = await axios.get(`/api/role/${id}`);
    if (res.status === 200) {
      const { data } = res.data;

      return { ...data };
    }
    throwResponse(res);
  });
};

export const POST_ROLE = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post("/api/role", data);
    if (res.status === 201) {
      return true;
    }
    throwResponse(res);
  });
};

export const PATCH_ROLE = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.patch(`/api/role/${data.id}`, data);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const DELETE_ROLE = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/api/role/${data.id}`);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const GET_USER_BY_ROLE = (
  id?: string | number
): UseQueryResult<IResponse<IUser[]>> => {
  return useQuery(["get-user-role", id], async () => {
    if (!id) return;
    const res = await axios.get(`/api/role/get-user-role/${id}`);
    if (res.status === 200) {
      const { data, count, page } = res.data;

      return { data, count, page };
    }
    throwResponse(res);
  });
};

export const EXPORT_ROLE = (): UseMutationResult<Blob> => {
  return useMutation(
    async (data: any) => {
      const timeZone = getClientTimeZone();
      const res = await exportFile.post("/api/export-excel/role", {...data, timeZone});
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { retry: false }
  );
};
