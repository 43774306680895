import React from "react";

const EXCEL_ICON = (props: React.SVGProps<SVGSVGElement> = {}) => {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141.73 141.73"
      {...props}
    >
      <path
        data-name="Path 21834"
        d="M79.26 117v11.55c0 4-2 5.7-5.87 5l-53.85-10.12-11.82-2.23a4.32 4.32 0 01-3.9-4.61V25.23a4.29 4.29 0 013.78-4.59L74 8.19a4.18 4.18 0 015.08 3 4 4 0 01.12 1.28v12.31h53.48c3.71 0 5.2 1.51 5.2 5.25v81.69c0 3.76-1.47 5.25-5.2 5.25H79.26zm.09-8.45h50.11V33.26H79.32c0 32.41.03 38.39.03 75.27zM42.61 63.29l-1-1.08c-3-3.48-6.08-7-9.14-10.44a4.21 4.21 0 00-6.93 4.78 5 5 0 00.6.69c2.35 2.71 4.71 5.39 7.07 8.09 1.36 1.56 2.73 3.13 4.09 4.71-.25.34-.41.59-.6.84L25.93 84.72a4.33 4.33 0 00-1.05 3.77A4 4 0 0028 91.66 4.05 4.05 0 0032.47 90c2.87-3.67 5.73-7.37 8.6-11 .61-.78 1.23-1.55 1.91-2.4l1.81 2.06q5 5.7 10 11.43a4.32 4.32 0 004.38 1.72 4.18 4.18 0 002.12-7C57.21 80 53.07 75.34 49 70.64c-.25-.29-.5-.6-.73-.92l5.1-6.57c2.73-3.51 5.52-7 8.18-10.54a4.15 4.15 0 00-2.44-6.77 4.27 4.27 0 00-4.41 1.93c-4 5.13-7.99 10.23-12.09 15.52z"
        fill="#ababa8"
      />
    </svg>
  );
};

export default EXCEL_ICON;
