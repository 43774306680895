import axios, { AxiosResponse } from "axios";
import { serverUrl } from "./serverUrl";

export const LOCAL_TOKEN_KEY = "intercon-token";

export const GET_LOCAL_TOKEN = (): string | null => {
  return localStorage.getItem(LOCAL_TOKEN_KEY);
};

export const SET_LOCAL_TOKEN = (token: string) => {
  localStorage.setItem(LOCAL_TOKEN_KEY, token);
};

export const GET_LOCAL_LANG = (): string => {
  return localStorage.getItem("lang") || "en";
};

axios.interceptors.request.use(
  (request) => {
    const token = GET_LOCAL_TOKEN();
    request.validateStatus = (_) => true;
    request.baseURL = serverUrl;
    request.responseType = "json" || "blob";
    request.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
    return Promise.resolve(request);
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;

export const exportFile = axios.create({
  responseType: "blob",
  baseURL: serverUrl,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${GET_LOCAL_TOKEN()}`,
  },
  validateStatus: (_) => true,
});

export const throwResponse = (res: AxiosResponse): never => {
  const { message } = res.data;
  if (typeof message === "object") {
    const err_message = message[GET_LOCAL_LANG()];
    if (Array.isArray(err_message) && typeof err_message[0] === "object") {
      const text =
        "error delete" +
        err_message
          .map((item) => {
            return `${item.email}/${item.fullname}`;
          })
          .join(",");
      throw new Error(text);
    }
    if (Array.isArray(err_message)) {
      throw new Error(err_message[0]);
    }
    throw new Error(err_message);
  }
  if (!Array.isArray(message)) {
    throw new Error(message);
  }
  if (Array.isArray(message) && typeof message[0] === "object") {
    const text =
      "error delete" +
      message
        .map((item) => {
          return `${item.email}/${item.fullname}`;
        })
        .join(",");
    throw new Error(text);
  }
  const text = message.reduce((result: string, item: string) => {
    return `${result}${item}\n`;
  }, "");
  throw new Error(text);
};
