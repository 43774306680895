import { DeleteOutlined, PlusCircleFilled } from "@ant-design/icons";
import React, { useRef, useState, useEffect } from "react";
import { Col, Image, Row } from "antd";
import PDF from "../assets/images/Pdf.svg";
import { swalError } from "../tools/swal";
import { useTranslation } from "react-i18next";

const FileUpload = ({ value, onChange }: { value?: any; onChange?: any }) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);
  const support = ["application/pdf"];
  const [getFile, setFile] = useState<undefined | any[]>(value);

  useEffect(() => {
    initial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const initial = () => {
    if (typeof value !== "object") return;
    setFile(value);
  };

  const onPicked = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files?.length > 0) {
      try {
        const file = new File([files[0]], files[0].name, {
          type: files[0].type,
        });
        const extension = file.type;
        if (!extension || !support.includes(extension)) {
          return swalError(t, t("validate.file-types"));
        }
        if ((file?.size || 0) / Math.pow(1024, 2) > 10) {
          return swalError(t, t("validate.file-size"));
        }

        const next = getFile ? [...getFile, file] : [file];

        setFile(next);
        onChange(next);
      } catch (err) {}
    }
  };

  const onDelete = (id: any) => {
    const files = getFile?.filter((item) => {
      if (item.id) return item.id !== id;
      return item.lastModified !== id;
    });
    setFile(files);
    onChange(files);
  };

  const openPickFileDialog = () => {
    ref.current?.click();
  };

  const openInNewTab = (href: string) => {
    Object.assign(document.createElement("a"), {
      target: "_blank",
      href: href,
    }).click();
  };

  return (
    <Row
      className="d-flex pt-4 pl-4 pr-4"
      style={{
        border: "1px solid rgb(228, 233, 242)",
        paddingBottom: "0.9rem",
      }}
    >
      <div
        style={{
          position: "absolute",
          left: 75,
          bottom: 225,
          fontSize: 16,
          zIndex: 1,
        }}
      >
        <span className="color-gold">{t("recommend-file-size")}</span>
      </div>
      {getFile?.map((item, index) => (
        <Col className="file-upload" key={index}>
          <Image
            src={PDF}
            preview={false}
            onClick={
              item.fileUrl ? () => openInNewTab(item.fileUrl) : undefined
            }
            style={{ cursor: item.fileUrl ? "pointer" : undefined }}
          />
          <div className="w-90 d-flex justify-center">
            <span className="text-truncate">
              {item.name || item.originalName || item.filename}
            </span>
            <span style={{ cursor: item.fileUrl ? "pointer" : undefined }}>
              <DeleteOutlined
                className="color-red"
                onClick={() => onDelete(item.id || item.lastModified)}
              />
            </span>
          </div>
        </Col>
      ))}
      <Col className="file-upload" onClick={openPickFileDialog}>
        <input
          accept=".pdf"
          multiple={true}
          type="file"
          ref={ref}
          style={{ display: "none" }}
          onChange={onPicked}
        />
        <div className="addition">
          <PlusCircleFilled style={{ color: "#AAAAA7", fontSize: 24 }} />
        </div>
      </Col>
    </Row>
  );
};

export default FileUpload;
