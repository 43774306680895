import { Col, Form, FormInstance, Input, Row, Select } from "antd";
import { useTranslation } from "react-i18next";
import { IService } from "../../../../store/service";
import { IServiceCategory } from "../../../../store/service-category";
import { POST_SERVICE_REQUEST_CALULATE_PRICE } from "../../../../store/service-request";
import { IUnit, TUnitType } from "../../../../store/units";
import { swalError } from "../../../../tools/swal";
import { getKeyByLocale } from "../../../../tools/translation";

const roomType: {
  [K in TUnitType]: string;
} = {
  ONE_BEDROOM: "oneBedroomPrice",
  TWO_BEDROOM: "twoBedroomPrice",
  THREE_BEDROOM: "threeBedroomPrice",
  FOUR_BEDROOM: "fourBedroomPrice",
  DUPLEX: "duplexPrice",
  PENTHOUSE: "penthousePrice",
};

const ServiceInput = ({
  category,
  id,
  serviceItems,
  form,
  units,
  setCalculatePrice,
}: {
  category: IServiceCategory[];
  id: string | undefined;
  serviceItems: IService[];
  form: FormInstance;
  units: IUnit[];
  setCalculatePrice: React.Dispatch<{
    price: number;
    priceWithSvg: number;
    priceWithVat: number;
    totalPrice: number;
  }>;
}) => {
  const { t, i18n } = useTranslation();
  const calculatePrice = POST_SERVICE_REQUEST_CALULATE_PRICE();

  const calculate = (values: any) => {
    calculatePrice.mutate(values, {
      onSuccess: (res) => {
        setCalculatePrice(res);
        form.setFieldsValue({
          setPrice: Number(res?.totalPrice || 0).toFixed(2),
          priceWithSvg: Number(res?.priceWithSvg || 0).toFixed(2),
          priceWithVat: Number(res?.priceWithVat || 0).toFixed(2),
        });
      },
      onError: ({ message }: any) => {
        swalError(t, message);
      },
    });
  };

  return (
    <Row className="px-4 mb-4 pb-2" gutter={[24, 24]}>
      <Col span={12}>
        <Form.Item
          name="unitReference"
          label={
            t("unit-reference-number") + t("this-information-comes-from-unit")
          }
          className="custom-input"
          rules={[{ required: true }]}
        >
          <Input placeholder={t("unit-reference-number")} disabled />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="serviceRef"
          label={t("service-order-reference-number")}
          className="custom-input"
          rules={[{ required: true }]}
        >
          <Input placeholder={t("service-order-reference-number")} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => prev.user !== next.user}
        >
          {(form: FormInstance) => {
            const user = form.getFieldValue("user");
            return (
              <Form.Item
                name="serviceCategory"
                label={t("menu.setting-service-category")}
                rules={[{ required: true }]}
                className="custom-select-ant "
                getValueFromEvent={(e) => {
                  form.setFieldsValue({
                    setPrice: undefined,
                    price: null,
                    serviceItem: undefined,
                    bookingHour: undefined,
                    requirePrivate: undefined,
                    svc: undefined,
                    vat: undefined,
                    priceWithSvg: undefined,
                    priceWithVat: undefined,
                  });
                  return e;
                }}
              >
                <Select
                  placeholder={t("menu.setting-service-category")}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  getPopupContainer={(value) => {
                    return value;
                  }}
                  disabled={!!id || !user}
                >
                  {category.map((item, key) => {
                    return (
                      <Select.Option
                        key={key}
                        value={item.id}
                        disabled={item.status === "INACTIVE"}
                      >
                        {getKeyByLocale({
                          key: "name",
                          data: item,
                          locale: i18n.language,
                        })}
                        {item.status === "INACTIVE" && ` (${t("inactive")})`}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          shouldUpdate={(prev, next) =>
            prev.serviceCategory !== next.serviceCategory
          }
          noStyle
        >
          {(form: FormInstance) => {
            const categoryId: number | undefined =
              form.getFieldValue("serviceCategory");
            const findItem = category.find((e) => e.id === categoryId);
            // const unitId = form.getFieldValue("unit");
            // const findUnit = units.find((e) => e.id === unitId);
            return (
              <Form.Item
                name="serviceItem"
                label={t("menu.service-list")}
                rules={[{ required: true }]}
                className="custom-select-ant"
                getValueFromEvent={(id) => {
                  const find = serviceItems.find(
                    (e) => e.id === id
                  ) as IService;
                  form.setFieldsValue({
                    price: null,
                    bookingHour: null,
                    setPrice: null,
                    requirePrivate: find.requirePrivate,
                    svc: undefined,
                    vat: undefined,
                    priceWithSvg: undefined,
                    priceWithVat: undefined,
                    taxId: find.billType.taxId,
                  });

                  if (find && find.pricePerHour && find.requirePricePerHour) {
                    // console.log("hour");
                    form.setFieldsValue({ bookingHour: 1 });
                    calculate({
                      price: Number(find?.pricePerHour || 0),
                      serviceItem: id,
                    });
                  }

                  if (find && find.requirePrice) {
                    // console.log("price");
                    const unitId = form.getFieldValue("unit");
                    const findUnit = units.find((e) => e.id === unitId);
                    if (!findUnit?.typeOfBedroom) {
                      form.setFieldsValue({ serviceItem: undefined });
                      swalError(t, t("please-add-room-type-information"));
                    }
                    if (findUnit && findUnit.typeOfBedroom) {
                      // console.log("findUnit", findUnit);
                      const findService = find as any;
                      // console.log("findService", findService);
                      const priceKey = roomType[findUnit.typeOfBedroom];
                      // console.log("priceKey", priceKey);
                      const roomPrice = findService[priceKey];
                      // console.log("roomPrice", roomPrice);
                      calculate({
                        serviceItem: id,
                        price: Number(roomPrice || 0),
                      });
                    }
                  }

                  if (find && find.svg) {
                    form.setFieldsValue({
                      svc: Number(find?.svg || 0).toFixed(2),
                    });
                  }
                  if (find && !find.requireSvg) {
                    form.setFieldsValue({ svc: 0 });
                  }
                  if (find && find.vat) {
                    form.setFieldsValue({
                      vat: Number(find?.vat || 0).toFixed(2),
                    });
                  }
                  if (find && !find.requireVat) {
                    form.setFieldsValue({ vat: 0 });
                  }

                  return id;
                }}
              >
                <Select
                  placeholder={t("menu.service-list")}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  getPopupContainer={(value) => {
                    return value;
                  }}
                  disabled={!categoryId || !!id}
                >
                  {findItem?.serviceItems.map((item, key) => {
                    return (
                      <Select.Option
                        key={key}
                        value={item.id}
                        disabled={
                          item.status === "INACTIVE"
                          // ||
                          // (!findUnit?.typeOfBedroom && item.requirePrice)
                        }
                      >
                        {getKeyByLocale({
                          key: "name",
                          data: item,
                          locale: i18n.language,
                        })}
                        {item.status === "INACTIVE" && ` (${t("inactive")})`}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="taxId"
          label={t("tax-id") + t("this-information-comes-from-bill-type")}
          className="custom-input"
          rules={[{ required: true }]}
        >
          <Input placeholder={t("tax-id")} disabled />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default ServiceInput;
