import {
  Row,
  Col,
  Button,
  Divider,
  Modal,
  Form,
  Input,
  Switch,
  FormInstance,
  DatePicker,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import EXCEL_ICON from "../../../assets/icons/page-header/excel";
import CustomSelect from "../../../components/custom-select";
import CustomStatusBackground from "../../../components/custom-status";
import CustomTable from "../../../components/custom-table";
import { fixed_hieght } from "../../../components/page-header";
import { VALIDATE_MESSAGES } from "../../../config/validateMessage";
import { PartialRecord } from "../../../layout/sidebar";
import { GET_MENUS } from "../../../store/menu";
import {
  DELETE_ROLE,
  EXPORT_ROLE,
  GET_ROLE,
  GET_USER_BY_ROLE,
  PATCH_ROLE,
  POST_ROLE,
} from "../../../store/role";
import { findStartOrEndTime } from "../../../tools/datetime";
import { downloadFile } from "../../../tools/files";
import { swalDelete, swalError, swalSuccess } from "../../../tools/swal";

const status = [
  {
    id: 1,
    key: true,
    name: "active",
  },
  {
    id: 2,
    key: false,
    name: "inactive",
  },
];

const menus: IMenu[] = [
  {
    key: "DASHBOARD",
    label: "dashboard",
    code: "001",
  },
  {
    key: "NEWS",
    label: "news",
    code: "002",
  },
  {
    key: "PRIVILEGE",
    label: "privilege",
    code: "003",
  },
  {
    key: "HOMECARE",
    label: "homecare",
    code: "005",
  },
  {
    key: "PAYMENT",
    label: "payment",
    code: "006",
  },
  {
    key: "PARCEL",
    label: "parcel",
    code: "007",
  },
  {
    key: "FACILITY",
    label: "facility-list",
    code: "008",
  },
  {
    key: "SERVICE",
    label: "service",
    code: "009",
  },
  {
    key: "FOOD",
    label: "food",
    code: "013",
  },
  {
    key: "LAUNDRY",
    label: "laundry",
    code: "014",
  },
  {
    key: "USER",
    label: "user-management",
    code: "010",
  },
  {
    key: "SETTING",
    label: "setting",
    code: "011",
  },
  {
    key: "FACILITY_CATEGORY",
    label: "setting-facility-category",
    code: "015",
  },
  {
    key: "FACILITY_TYPE",
    label: "setting-facility",
    code: "016",
  },
  {
    key: "REPAIRING_ZONE",
    label: "setting-repairing-zone",
    code: "017",
  },
  {
    key: "REPAIRING_CATEGORY",
    label: "setting-repairing-category",
    code: "018",
  },
  {
    key: "REPAIRING_ITEM",
    label: "setting-repairing-item",
    code: "019",
  },
  {
    key: "BILL_TYPE",
    label: "setting-bill-type",
    code: "020",
  },
  {
    key: "SERVICE_CATEGORY",
    label: "setting-service-category",
    code: "021",
  },
  {
    key: "CONTENT_ABOUT_US",
    label: "setting-content-about-us",
    code: "022",
  },
  {
    key: "ROLE",
    label: "setting-role",
    code: "023",
  },
  {
    key: "UNIT",
    label: "units",
    code: "012",
  },
];

const menuList: any = {
  DASHBOARD: false,
  NEWS: false,
  PRIVILEGE: false,
  HOMECARE: false,
  PAYMENT: false,
  PARCEL: false,
  FACILITY: false,
  SERVICE: false,
  USER: false,
  SETTING: false,
  UNIT: false,
  FOOD: false,
  LAUNDRY: false,
  FACILITY_CATEGORY: false,
  FACILITY_TYPE: false,
  REPAIRING_CATEGORY: false,
  REPAIRING_ZONE: false,
  REPAIRING_ITEM: false,
  BILL_TYPE: false,
  SERVICE_CATEGORY: false,
  CONTENT_ABOUT_US: false,
  ROLE: false,
};

const RolePage = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [id, setId] = useState<number>();
  const client = useQueryClient();
  const [params, setParams] = useState({ limit: 100, page: 1 });
  const [visible, setVisible] = useState<boolean>(false);
  const [userVisible, setUserVisible] = useState<boolean>(false);
  const [dataRole, setDataRole] = useState<any>();
  const { data, isLoading, refetch } = GET_ROLE(params);
  const menuData = GET_MENUS({ limit: 100, page: 1 });
  const create = POST_ROLE();
  const update = PATCH_ROLE();
  const { mutate } = DELETE_ROLE();
  const [paramsUSer, setParamsUser] = useState({ id, limit: 10, page: 1 });
  const user_by_role = GET_USER_BY_ROLE(id);
  const exportRole = EXPORT_ROLE();

  const columnsUser: ColumnsType = [
    {
      dataIndex: "title_th",
      title: t("name"),
      width: "40%",
      render: (_, record: any) => {
        return (
          <span>
            {record?.firstName} {record?.lastName}
          </span>
        );
      },
    },
    {
      dataIndex: "email",
      title: t("email"),
      width: "40%",
      render: (text, record: any) => {
        return <span>{text}</span>;
      },
    },
  ];

  useEffect(() => {
    if (dataRole?.id) {
      listData(dataRole);
    } else {
      form.setFieldsValue(dataRole);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataRole]);

  const listData = (data: any) => {
    const { isActive, menu, title } = data;
    if (menu !== []) {
      const menuList = menu.map((item: any) => {
        return { [item.title]: item.isActive };
      });
      const listMenu = menuList.reduce((acc: any, curr: any) => {
        return { ...acc, ...curr };
      }, {});
      let setData = { roleMenus: listMenu, title: title, isActive: isActive };
      return form.setFieldsValue(setData);
    }
  };
  const columns: ColumnsType = [
    {
      dataIndex: "title",
      title: t("name-the-role-group"),
      render: (text, __) => {
        return (
          <p style={{ whiteSpace: "pre", display: "contents" }}>
            {text || "-"}
          </p>
        );
      },
    },
    {
      dataIndex: "roleMenus",
      title: t("set-menu"),
      width: "600px",
      render: (text, record: any) => {
        const menuList: any[] = record?.menu?.map((item: any) => {
          const filterMenu = menus.filter(
            (itemMenus) =>
              item.code === itemMenus.code && item.isActive === true
          );
          if (!filterMenu[0]) return undefined;
          return filterMenu[0].label;
        });
        return (
          <>
            {menuList.every((e) => !e) && "-"}
            {menuList.every((e) => !e) === false && (
              <div>
                {menuList.map((item: any, index: any) => {
                  if (item !== undefined) return `${t(`menu.${item}`)}, `;
                  return "";
                })}
              </div>
            )}
          </>
        );
      },
    },
    {
      dataIndex: "isActive",
      title: t("status"),
      render: (text, record) => {
        return (
          <>
            {text === null && "-"}
            {text === false && (
              <CustomStatusBackground
                lable={t("inactive")}
                className={"frame-no-background"}
              />
            )}
            {text === true && (
              <CustomStatusBackground
                lable={t("active")}
                className={"frame-background"}
              />
            )}
          </>
        );
      },
    },
  ];

  const onCancel = () => {
    setDataRole({
      title: "",
      isActive: true,
      roleMenus: menuList,
    });
    setVisible(false);
  };

  const onSave = (values: any) => {
    let listMenu;
    if (values.roleMenus === undefined) {
      listMenu = menuData.data?.map((item: any) => {
        const itemMenu = {
          isActive: menuList[item.title],
          menu: item.id,
        };
        return itemMenu;
      });
    } else {
      listMenu = menuData.data?.map((item: any) => {
        const itemMenu = {
          isActive: values.roleMenus[item.title],
          menu: item.id,
        };
        return itemMenu;
      });
    }

    values = { ...values, roleMenus: listMenu };

    if (dataRole?.id) {
      const { id } = dataRole;
      update.mutate(
        { ...values, id: id },
        {
          onError: (err: any) => {
            setVisible(false);
            swalError(err.message);
            setDataRole({
              title: "",
              isActive: true,
              roleMenus: menuList,
            });
          },
          onSuccess: () => {
            client.invalidateQueries("get-role");
            client.invalidateQueries(["get-role-id", id]);
            swalSuccess(t);
            setVisible(false);
            refetch();
            setDataRole({
              title: "",
              isActive: true,
              roleMenus: menuList,
            });
          },
        }
      );
    } else {
      values = { ...values, isActive: true };
      create.mutate(values, {
        onError: (err: any) => {
          swalError(err.message);
          setVisible(false);
          setDataRole({
            title: "",
            isActive: true,
            roleMenus: menuList,
          });
        },
        onSuccess: () => {
          client.invalidateQueries("GET_ROLE");
          swalSuccess(t);
          setVisible(false);
          refetch();
          setDataRole({
            title: "",
            isActive: true,
            roleMenus: menuList,
          });
        },
      });
    }
  };

  const onEditRole = (value: any) => {
    setVisible(true);
    setDataRole(value);
  };

  const onDelete = async (id: number) => {
    const confirm = await swalDelete(t);
    if (confirm) {
      mutate(
        { id },
        {
          onError: async (err: any) => {
            let errMessage = undefined;
            if (
              err.message ===
              "ไม่สามารถลบบทบาทนี้ได้เนื่องจากมีผู้ใช้งานอยู่ในขณะนี้"
            ) {
              errMessage = t(
                "this-role-is-already-a-user-and-cannot-be-deleted"
              );
            } else {
              errMessage = err.message;
            }
            const error = await swalError(t, errMessage);
            if (
              err.message ===
                "ไม่สามารถลบบทบาทนี้ได้เนื่องจากมีผู้ใช้งานอยู่ในขณะนี้" &&
              error
            ) {
              setId(id);
              setUserVisible(true);
            }
          },
          onSuccess: () => {
            swalSuccess(t);
            refetch();
          },
        }
      );
    }
  };

  const onSearch = (values: any) => {
    if (!values.date) {
      values.date = [];
    }
    const { date } = values;

    const startDate = findStartOrEndTime(date[0], "start");
    const endDate = findStartOrEndTime(date[1], "end");
    values = { ...values, startDate, endDate };
    delete values.date;

    setParams({ ...params, ...values });
  };

  const onExportExcel = () => {
    const ids = data?.data.map((item) => item.id);
    exportRole.mutate(
      { id: ids },
      {
        onSuccess: (data: any) => {
          downloadFile(data, "xlsx", `Role(${dayjs().format("DD-MM-YYYY")})`);
        },
        onError: (error: any) => {
          swalError(t, error.message);
        },
      }
    );
  };

  return (
    <Form layout="vertical" onFinish={onSearch}>
      <Row
        justify="space-between"
        align="middle"
        className="px-4 pt-4 bg-white"
      >
        <Col>
          <p className="f-26 mb-1 font-bold">{t("role")}</p>
          <p className="f-12 mb-0 color-light-grey font-italic">{t("role")}</p>
        </Col>
        <Col>
          <Row gutter={12}>
            <Col>
              <Button
                onClick={onExportExcel}
                style={{
                  width: fixed_hieght,
                  height: fixed_hieght,
                  padding: 12,
                }}
                className="excel-icon"
                icon={EXCEL_ICON()}
                loading={exportRole.isLoading}
              />
            </Col>
            <Col>
              <Button
                // icon={<PlusOutlined className="f-12" />}
                className="button-primary-boederless f-18"
                style={{ width: 195, height: 50 }}
                onClick={() => {
                  setVisible(true);
                }}
              >
                {t("create-role")}
              </Button>
            </Col>
          </Row>
        </Col>
        <Divider />
        <Col span={18}>
          <Row gutter={[12, 28]}>
            <Col span={10}>
              <span
                className="font-bold"
                style={{ position: "absolute", marginTop: "-22px" }}
              >
                {t("date")}
              </span>
              <Form.Item className="custom-ant-picker" name="date">
                <DatePicker.RangePicker
                  getPopupContainer={(props: HTMLElement) => {
                    return props;
                  }}
                  style={{ height: 50 }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={6}>
          <Row gutter={[12, 12]} className="mb-4 mt-4">
            <Col span={12}>
              <Button
                className="button-cancel w-100"
                htmlType="reset"
                style={{ height: fixed_hieght }}
              >
                {t("clear")}
              </Button>
            </Col>
            <Col span={12}>
              <Button
                className="button-save w-100"
                htmlType="submit"
                style={{ height: fixed_hieght }}
              >
                {t("search")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <CustomTable
        rowKey="id"
        title={t("role-lists")}
        columns={columns}
        dataSource={data?.data.filter((item) => item.title !== "MEMBER") || []}
        pagination={{
          limit: params.limit,
          page: params.page,
          total: data?.count || 0,
        }}
        onDelete={onDelete}
        onPageChange={(page, limit) => setParams({ ...params, page, limit })}
        isLoadingTable={isLoading}
        disabledSelect={true}
        onEditRole={onEditRole}
      />
      <FormModal
        visible={visible}
        onCancel={onCancel}
        t={t}
        onSave={onSave}
        form={form}
        dataRole={dataRole}
        loading={create.isLoading || update.isLoading}
      />
      <Modal
        title={null}
        visible={userVisible}
        onOk={() => setUserVisible(false)}
        onCancel={() => setUserVisible(false)}
        footer={null}
        width="50vw"
      >
        <CustomTable
          rowKey="id"
          title={t("user-list")}
          columns={columnsUser}
          dataSource={user_by_role?.data?.data || []}
          pagination={{
            limit: paramsUSer.limit,
            page: paramsUSer.page,
            total: user_by_role.data?.count || 0,
          }}
          onPageChange={(page, limit) =>
            setParamsUser({ ...paramsUSer, page, limit })
          }
          isLoadingTable={user_by_role.isLoading}
          disabledSelect
          actions={[]}
        />
      </Modal>
    </Form>
  );
};

const FormModal = ({
  visible,
  onCancel,
  onSave,
  t,
  form,
  dataRole,
  loading,
}: {
  visible: boolean;
  onCancel: () => void;
  onSave: (value: any) => void;
  t: TFunction;
  form: FormInstance;
  dataRole: any;
  loading: boolean;
}) => {
  const staticsRole = [
    "SUPER ADMIN",
    "JURISTIC",
    "RESIDENT",
    "REPAIRMAN",
    "OWNER",
    "TENANT",
  ];

  let isStatics = false;
  const someRole = (title: string) => {
    return title === dataRole?.title;
  };

  if (dataRole?.id) {
    isStatics = staticsRole.some(someRole);
  }

  return (
    <Modal
      visible={visible}
      className="p-4"
      footer={null}
      closable={false}
      onCancel={onCancel}
      centered={true}
      width="50vw"
    >
      <Form
        layout="vertical"
        onFinish={onSave}
        form={form}
        validateMessages={VALIDATE_MESSAGES(t)}
        className="fixed-input-size"
      >
        <Row justify="space-between">
          <Col></Col>
          <Col>
            <Row gutter={[12, 12]}>
              <Col>
                <Button
                  style={{ height: 45, width: 120 }}
                  className="button-cancel"
                  onClick={onCancel}
                >
                  {t("cancel")}
                </Button>
              </Col>
              <Col>
                <Button
                  style={{ height: 45, width: 120 }}
                  htmlType="submit"
                  className="button-primary"
                  disabled={false}
                  loading={loading}
                >
                  {t("save")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Form.Item
          label={t("name-the-role-group")}
          name="title"
          rules={[{ required: true }, { max: 255 }]}
          className="custom-input"
        >
          <Input style={{ height: 50 }} placeholder={t("name")} />
        </Form.Item>
        {/* {dataRole?.id && ( */}
        <Form.Item
          label={t("status")}
          name="isActive"
          className="custom-select-ant "
        >
          <CustomSelect
            placeholder={t("status")}
            optionValue={{ values: status || [], key: "key", name: "name" }}
          />
        </Form.Item>
        {/* )} */}
        <Form.Item label={t("set-menu")} name="roleMenus">
          <Menu t={t} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

type TMenuKey =
  | "DASHBOARD"
  | "NEWS"
  | "PRIVILEGE"
  | "HOMECARE"
  | "PAYMENT"
  | "PARCEL"
  | "FACILITY"
  | "SERVICE"
  | "USER"
  | "SETTING"
  | "UNIT"
  | "FOOD"
  | "LAUNDRY"
  | "FACILITY_CATEGORY"
  | "FACILITY_TYPE"
  | "REPAIRING_CATEGORY"
  | "REPAIRING_ZONE"
  | "REPAIRING_ITEM"
  | "BILL_TYPE"
  | "SERVICE_CATEGORY"
  | "CONTENT_ABOUT_US"
  | "ROLE";

interface IMenu {
  key: TMenuKey;
  label: string;
  code: string;
}
const Menu = ({
  onChange,
  value,
  t,
}: {
  onChange?: any;
  value?: any;
  t: TFunction;
}) => {
  const [state, setState] = useState<PartialRecord<TMenuKey, boolean>>(
    value || {
      DASHBOARD: false,
      NEWS: false,
      PRIVILEGE: false,
      HOMECARE: false,
      PAYMENT: false,
      PARCEL: false,
      FACILITY: false,
      SERVICE: false,
      USER: false,
      SETTING: false,
      UNIT: false,
      FOOD: false,
      LAUNDRY: false,
      FACILITY_CATEGORY: false,
      FACILITY_TYPE: false,
      REPAIRING_CATEGORY: false,
      REPAIRING_ZONE: false,
      REPAIRING_ITEM: false,
      BILL_TYPE: false,
      SERVICE_CATEGORY: false,
      CONTENT_ABOUT_US: false,
      ROLE: false,
    }
  );

  useEffect(() => {
    initial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const initial = () => {
    if (typeof value !== "object") return;
    setState(value);
  };
  return (
    <div className="role-modal-switch">
      {menus.map((item, index) => {
        return (
          <Row
            justify="space-between"
            align="middle"
            style={{ border: "1px solid #e4e9f2", height: 50 }}
            className="px-3"
            key={index}
          >
            <Col>{t(`menu.${item.label}`)}</Col>
            <Col>
              <Switch
                checked={state[item.key]}
                onChange={(e) => {
                  const next = { ...state, [item.key]: e };
                  setState(next);
                  onChange(next);
                }}
              />
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default RolePage;
