import {
  Row,
  Col,
  Button,
  Divider,
  Input,
  Form,
  DatePicker,
  Popover,
} from "antd";
import { FieldTimeOutlined, PlusOutlined } from "@ant-design/icons";

import LINE_ICON from "../assets/icons/page-header/line";
import BIN_ICON from "../assets/icons/page-header/bin";
import EXCEL_ICON from "../assets/icons/page-header/excel";
import CustomSelect from "./custom-select";
import { JSXElementConstructor } from "react";
import { useTranslation } from "react-i18next";
import { TMenuKey } from "../router/menu";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { select_rows_atom } from "../store/global";
import { swalDelete, swalError, swalSuccess, swalWarning } from "../tools/swal";
import { UseMutationResult, useQueryClient } from "react-query";
import { ROLE_PATH } from "../router/path";
import { downloadFile } from "../tools/files";
import dayjs from "dayjs";
import PDF_ICON from "../assets/icons/page-header/pdf";
import ExcelJS from "exceljs";
import * as fs from "file-saver";
import PAYMENT_LIST_ICON from "../assets/icons/page-header/payment-list";
import LAUNDRY_ICON from "../assets/icons/page-header/laundry";

export const fixed_hieght = 50;

type TSearchInput = "input" | "select" | "date-picker" | "range-picker";
type TUtility = "line" | "delete" | "export" | "food" | "laundry" | "payment";

export interface ISearchInput {
  key: string;
  label: string;
  span: number;
  type: TSearchInput;
  props?: object;
}

interface IExcelOptions {
  import?: () => void;
  export?: UseMutationResult;
  template?: () => void;
}

export interface IExcelDate {
  showModalExport: () => void;
}

const PageHeader = ({
  searchInput,
  onSearch,
  menu,
  hasCreateButton = true,
  hasRoleButton = false,
  path,
  utility = ["line", "delete", "export"],
  bulkDelete,
  invalidateQueries,
  label = "",
  onReset,
  exportExcel,
  exportPdf,
  topicExport,
  lineNotification,
  hasExcelRepairItem = false,
  showModal,
  onBulkDelete,
  onBulkDeleteSuccess,
  showModalImport,
  showModalExport,
  hasExportPdf = false,
  excelOptions,
  pdfOptions,
  hasExportExcelDate = false,
  excelDate,
  onTimeButtonClick,
  onComplimentaryButtonClick,
  onPaymentListClick,
  textConfirmDelete,
}: {
  searchInput: ISearchInput[];
  onSearch: (values: any) => void;
  menu: TMenuKey;
  hasCreateButton?: boolean;
  path?: string;
  utility?: TUtility[];
  bulkDelete?: UseMutationResult;
  invalidateQueries?: any[];
  label?: string;
  hasRoleButton?: boolean;
  onReset?: () => void;
  exportExcel?: any;
  exportPdf?: any;
  pdfOptions?: any;
  topicExport?: string;
  lineNotification?: any;
  hasExcelRepairItem?: boolean;
  showModal?: () => void;
  onBulkDelete?: (ids: number[]) => void;
  onBulkDeleteSuccess?: (selectLength: number) => void;
  showModalImport?: () => void;
  showModalExport?: () => void;
  hasExportPdf?: boolean;
  excelOptions?: IExcelOptions;
  hasExportExcelDate?: boolean;
  excelDate?: IExcelDate;
  onTimeButtonClick?: () => void;
  onComplimentaryButtonClick?: () => void;
  onPaymentListClick?: () => void;
  textConfirmDelete?: string;
}) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const client = useQueryClient();
  const [gSelectRows, sSelectRows] = useRecoilState(select_rows_atom);

  const utilityProps: {
    [K in TUtility]: {
      className: string;
      func: () => void;
      icon: any;
      loading?: boolean;
      padding?: string;
    };
  } = {
    line: {
      func: () => onNotification(),
      className: "line-icon",
      icon: LINE_ICON(),
      loading: lineNotification?.isLoading,
    },
    delete: {
      func: () => onDelete(),
      className: "bin-icon",
      icon: BIN_ICON(),
      loading: bulkDelete?.isLoading,
    },
    export: {
      func: () => onExportExcel(),
      className: "excel-icon",
      icon: EXCEL_ICON(),
      loading: exportExcel?.isLoading,
    },
    food: {
      func: () => onTimeButtonClick?.(),
      className: "time-btn",
      icon: <FieldTimeOutlined style={{ fontSize: 24 }} />,
      loading: false,
    },
    laundry: {
      func: () => onComplimentaryButtonClick?.(),
      className: "time-btn",
      icon: LAUNDRY_ICON(),
      loading: false,
      padding: "0",
    },
    payment: {
      func: () => onPaymentListClick?.(),
      className: "time-btn",
      icon: PAYMENT_LIST_ICON(),
      loading: false,
    },
  };

  const onDelete = async () => {
    const ids = gSelectRows.flatMap((item) => item.data);
    const confirm = await swalDelete(
      t,
      textConfirmDelete ? textConfirmDelete : undefined
    );
    if (confirm) {
      if (onBulkDelete) return onBulkDelete(ids);
      bulkDelete?.mutate(
        { ids },
        {
          onError: (err: any) => {
            if (menu === "units" && err.message.slice(0, 11))
              return swalError(
                t,
                t("this-room-is-already-a-user-and-cannot-be-deleted") +
                  " " +
                  err.message.slice(12, err.message.length)
              );
            return swalError(t, err.message);
          },
          onSuccess: () => {
            if (onBulkDeleteSuccess) {
              onBulkDeleteSuccess(ids.length);
            }
            invalidateQueries?.map(async (item) => {
              const key =
                typeof item === "object" ? [item.key, item.value] : item.key;
              return await client.invalidateQueries(key);
            });
            sSelectRows([]);
            swalSuccess(t);
          },
        }
      );
    }
  };

  const onExportExcel = () => {
    const ids = gSelectRows.flatMap((item) => item.data);
    if (!ids.length)
      return swalWarning(t, t("swal.please-select-information-to-export"));
    (exportExcel || excelOptions?.export).mutate(
      { id: ids },
      {
        onSuccess: (data: any) => {
          downloadFile(
            data,
            "xlsx",
            topicExport + `(${dayjs().format("DD-MM-YYYY")})`
          );
        },
        onError: (error: any) => {
          swalError(t, t("swal.please-select-an-information"));
        },
      }
    );
  };

  const onExportPdf = () => {
    const ids = gSelectRows.flatMap((item) => item.data);
    if (!ids.length)
      return swalWarning(t, t("swal.please-select-information-to-export"));
    exportPdf.mutate(
      { id: ids },
      {
        onSuccess: (data: any) => {
          downloadFile(
            data,
            "pdf",
            topicExport + `(${dayjs().format("DD-MM-YYYY")})`
          );
        },
        onError: (error: any) => {
          swalError(t, t("swal.please-select-an-information"));
        },
      }
    );
  };

  const onExportInvoice = () => {
    const ids = gSelectRows.flatMap((item) => item.data);
    if (!ids.length) return;
    pdfOptions.exportInvoice.mutate(
      { id: ids },
      {
        onSuccess: (data: any) => {
          downloadFile(
            data,
            "pdf",
            `Payment invoice(${dayjs().format("DD-MM-YYYY")})`
          );
        },
        onError: (error: any) => {
          swalError(t, t("swal.please-select-an-information"));
        },
      }
    );
  };

  const onExportReceipt = () => {
    const ids = gSelectRows.flatMap((item) => item.data);
    if (!ids.length) return;
    pdfOptions.exportReceipt.mutate(
      { id: ids },
      {
        onSuccess: (data: any) => {
          downloadFile(
            data,
            "pdf",
            `Payment receipt(${dayjs().format("DD-MM-YYYY")})`
          );
        },
        onError: (error: any) => {
          swalError(t, t("swal.please-select-an-information"));
        },
      }
    );
  };

  const onNotification = () => {
    const ids = gSelectRows.flatMap((item) => item.data);
    if (!ids.length) return;
    lineNotification.mutate(
      { ids },
      {
        onSuccess: () => {
          swalSuccess();
        },
        onError: (error: any) => {
          swalError(t, error.message);
        },
      }
    );
  };

  const getInput = (type: TSearchInput): JSXElementConstructor<any> => {
    switch (type) {
      case "input":
        return Input;
      case "select":
        return CustomSelect;
      case "date-picker":
        return DatePicker;
      case "range-picker":
        return DatePicker.RangePicker;
    }
  };

  const generateSearchInput = (inputs: ISearchInput[]) => {
    return inputs.map((item, index) => {
      const INPUT = getInput(item.type);
      return (
        <Col span={item.span} key={index}>
          <span
            className="font-bold"
            style={{ position: "absolute", marginTop: "-22px" }}
          >
            {item.label}
          </span>
          <Form.Item
            className="custom-input custom-select-ant custom-ant-picker"
            name={item.key}
          >
            <INPUT {...item.props} style={{ height: 50 }} allowClear={false} />
          </Form.Item>
        </Col>
      );
    });
  };

  const onCreatePage = () => {
    if (path) {
      push(path);
    }
  };

  const getUtility = (_utility: TUtility[]) => {
    return _utility.map((item, index) => {
      const { className, icon, func, loading, padding } = utilityProps[item];
      return (
        <Col key={index}>
          <Button
            onClick={func}
            style={{
              width: fixed_hieght,
              height: fixed_hieght,
              padding: padding || 12,
            }}
            className={className}
            icon={icon}
            loading={loading}
          />
        </Col>
      );
    });
  };

  const onDownloadTemplate = async () => {
    let workbook = new ExcelJS.Workbook();
    let worksheet = workbook.addWorksheet();

    worksheet.columns = [
      { header: "Email_user", key: "email", width: 20 },
      { header: "RepairingCategory_EN", key: "category", width: 20 },
      { header: "Date", key: "date", width: 20 },
      { header: "Time", key: "time", width: 20 },
    ];

    worksheet.addRows([
      {
        email: "test@email.com",
        category: "Floor",
        date: "2022-01-06",
        time: "18:00 - 19:00",
      },
      {
        email: "test@email.com",
        category: "Floor",
        date: "2022-01-06",
        time: "18:00 - 19:00",
      },
    ]);
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fs.saveAs(blob, "repairing-setup.xlsx");
  };

  const contentRepairSetup = (
    <div>
      <Col className="mb-2">
        <Button
          className="button-primary f-18"
          style={{ width: 190, height: fixed_hieght }}
          onClick={showModalImport}
        >
          {t(`import`)}
        </Button>
      </Col>
      <Col className="mb-2">
        <Button
          className="button-primary f-18"
          style={{ width: 190, height: fixed_hieght }}
          onClick={showModalExport}
        >
          {t(`export`)}
        </Button>
      </Col>
      <Col>
        <Button
          className="button-primary f-18"
          style={{ width: 190, height: fixed_hieght }}
          onClick={onDownloadTemplate}
        >
          {t(`download-template`)}
        </Button>
      </Col>
    </div>
  );

  const excelOptionsContent = (
    <div>
      {excelOptions?.import && (
        <Col className="mb-2 px-0">
          <Button
            className="button-primary f-18"
            style={{ width: 190, height: fixed_hieght }}
            onClick={excelOptions.import}
          >
            {t(`import`)}
          </Button>
        </Col>
      )}
      {excelOptions?.export && (
        <Col className="mb-2 px-0">
          <Button
            className="button-primary f-18"
            style={{ width: 190, height: fixed_hieght }}
            onClick={onExportExcel}
          >
            {t(`export`)}
          </Button>
        </Col>
      )}
      {excelOptions?.template && (
        <Col className="px-0">
          <Button
            className="button-primary f-18"
            style={{ width: 190, height: fixed_hieght }}
            onClick={excelOptions.template}
          >
            {t(`download-template`)}
          </Button>
        </Col>
      )}
    </div>
  );

  const pdfOptionsContent = (
    <div>
      {pdfOptions?.exportInvoice && (
        <Col className="mb-2 px-0">
          <Button
            className="button-primary f-18"
            style={{ width: 190, height: fixed_hieght }}
            onClick={onExportInvoice}
          >
            {t(`export-invoice`)}
          </Button>
        </Col>
      )}
      {pdfOptions?.exportReceipt && (
        <Col className="mb-2 px-0">
          <Button
            className="button-primary f-18"
            style={{ width: 190, height: fixed_hieght }}
            onClick={onExportReceipt}
          >
            {t(`export-receipt`)}
          </Button>
        </Col>
      )}
    </div>
  );

  return (
    <Form className="bg-white" layout="vertical" onFinish={onSearch}>
      <Row justify="space-between" align="middle" className="px-4 pt-4">
        <Col>
          <p className="f-26 mb-1 font-bold">{t(`menu.${menu}`)}</p>
          <p className="f-12 mb-0 color-light-grey font-italic">
            {t(`menu.${menu}`)}
          </p>
        </Col>
        <Col className="d-flex">
          <Row gutter={[12, 0]}>
            {getUtility(utility)}
            {hasExportPdf && (
              <Col>
                <Button
                  onClick={onExportPdf}
                  style={{
                    width: fixed_hieght,
                    height: fixed_hieght,
                    padding: 12,
                  }}
                  className="pdf-icon"
                  icon={PDF_ICON()}
                  loading={exportPdf.isLoading}
                />
              </Col>
            )}
            {excelOptions ? (
              <Col>
                <Popover
                  placement="rightTop"
                  content={excelOptionsContent}
                  trigger="click"
                  getPopupContainer={(e) => e}
                >
                  <Button
                    style={{
                      width: fixed_hieght,
                      height: fixed_hieght,
                      padding: 12,
                    }}
                    className="excel-icon"
                    icon={EXCEL_ICON()}
                    loading={excelOptions.export?.isLoading}
                  />
                </Popover>
              </Col>
            ) : null}
            {pdfOptions ? (
              <Col>
                <Popover
                  placement="rightTop"
                  content={pdfOptionsContent}
                  trigger="click"
                  getPopupContainer={(e) => e}
                >
                  <Button
                    style={{
                      width: fixed_hieght,
                      height: fixed_hieght,
                      padding: 12,
                    }}
                    className="pdf-icon"
                    icon={PDF_ICON()}
                    loading={
                      pdfOptions?.exportInvoice?.isLoading ||
                      pdfOptions?.exportReceipt?.isLoading
                    }
                  />
                </Popover>
              </Col>
            ) : null}
            {hasExcelRepairItem && (
              <Col>
                <Popover
                  placement="rightTop"
                  content={contentRepairSetup}
                  trigger="click"
                >
                  <Button
                    style={{
                      width: fixed_hieght,
                      height: fixed_hieght,
                      padding: 12,
                    }}
                    className="excel-icon"
                    icon={EXCEL_ICON()}
                  />
                </Popover>
              </Col>
            )}
            {hasRoleButton && (
              <Col>
                <Button
                  icon={<PlusOutlined className="f-12" />}
                  className="button-primary-boederless f-18"
                  style={{ width: 195, height: fixed_hieght }}
                  onClick={() => {
                    push(ROLE_PATH.index);
                  }}
                >
                  {t("create-role")}
                </Button>
              </Col>
            )}
            {hasExportExcelDate && (
              <Col>
                <Button
                  onClick={excelDate?.showModalExport}
                  style={{
                    width: fixed_hieght,
                    height: fixed_hieght,
                    padding: 12,
                  }}
                  className="excel-icon"
                  icon={EXCEL_ICON()}
                />
              </Col>
            )}
            {hasCreateButton && (
              <Col>
                <Button
                  icon={
                    label === "create-user" ? undefined : (
                      <PlusOutlined className="f-12" />
                    )
                  }
                  className="button-primary f-18"
                  style={{ width: 170, height: fixed_hieght }}
                  onClick={hasExcelRepairItem ? showModal : onCreatePage}
                >
                  {t(`${label}`)}
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Divider />
      {searchInput.length > 0 && (
        <Row gutter={[12, 12]} className="p-4">
          <Col span={18}>
            <Row gutter={[12, 28]}>{generateSearchInput(searchInput)}</Row>
          </Col>
          <Col span={6}>
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <Button
                  className="button-cancel w-100"
                  htmlType="reset"
                  style={{ height: fixed_hieght }}
                  onClick={onReset}
                >
                  {t("clear")}
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  className="button-save w-100"
                  htmlType="submit"
                  style={{ height: fixed_hieght }}
                >
                  {t("search")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default PageHeader;
