import { CloseOutlined, EyeFilled, MobileOutlined } from "@ant-design/icons";
import { Modal, Image, Row, Col, Divider } from "antd";
import parse from "html-react-parser";
import mobile from "../assets/images/iphone-12-pro-max.png";
import time from "../assets/images/time-iphone.png";
import battery from "../assets/images/battery-iphone.png";
import ReactShadowScroll from "react-shadow-scroll";
import dayjs from "dayjs";
import logo from "../assets/images/logo_intercon.svg";
import { useTranslation } from "react-i18next";

const PreviewContent = ({
  values,
  reach,
  language,
  previewEn,
  previewTh,
  openPreview,
  closePreview,
}: {
  values: any;
  reach: number;
  language: string;
  previewEn?: boolean;
  previewTh?: boolean;
  openPreview: () => void;
  closePreview: () => void;
}) => {
  const { t } = useTranslation();

  const title = language === "en" ? values?.headerEn : values?.headerTh;
  const name = language === "en" ? values?.nameEn : values?.nameTh;
  const datetime = dayjs(values?.createdAt)
    .locale(language)
    .format("DD MMM YYYY");
  const content = language === "en" ? values?.contentEn : values?.contentTh;
  const image = values?.picture ? values?.picture[1]?.url : undefined;

  return (
    <>
      <div
        style={{
          position: "absolute",
          right: 12,
          bottom: 550,
          fontSize: 14,
          zIndex: 1,
        }}
      >
        <MobileOutlined onClick={openPreview} />
      </div>
      <Modal
        title={null}
        visible={previewEn || previewTh}
        onOk={closePreview}
        onCancel={closePreview}
        closeIcon={<></>}
        footer={null}
      >
        <div className="d-flex justify-center pb-2">
          <h3>{t("mobile-previews")} (iphone 12 pro max)</h3>
        </div>
        <div className="w-100 d-flex justify-center">
          <Image src={mobile} width={350} height={700} preview={false} />
          <Row
            className="absolute d-flex justify-center"
            style={{
              paddingTop: "4%",
              width: 280,
            }}
          >
            <Col span={24} className="d-flex justify-between">
              <Image
                src={time}
                width={"20%"}
                preview={false}
                style={{ borderRadius: "20px" }}
              />
              <Image
                src={battery}
                width={"20%"}
                preview={false}
                style={{ borderRadius: "20px" }}
              />
            </Col>
          </Row>
          <Row
            className="bg-white absolute"
            style={{
              marginTop: "10%",
              width: 300,
            }}
          >
            <Col span={2}></Col>
            <Col span={20} className="d-flex justify-center">
              Pround real estate
            </Col>
            <Col span={2}>
              <CloseOutlined
                style={{ fontSize: "16px" }}
                onClick={closePreview}
              />
            </Col>
          </Row>
          <div
            className="h-100 absolute"
            style={{ width: 300, marginTop: "16%" }}
          >
            <ReactShadowScroll isShadow={false} scrollWidth={0}>
              <div style={{ width: "100%", height: 580, fontSize: "13px" }}>
                <Image
                  src={image ? image : logo}
                  width="100%"
                  height={280}
                  preview={false}
                  className={image ? "" : "px-3 py-3"}
                />
                {!image && (
                  <div className="d-flex justify-center">
                    <span className="color-red">
                      {t("please-save-to-show")}
                    </span>
                  </div>
                )}

                <div className="m-3">
                  <h3>
                    {title}
                    {name}
                  </h3>
                  <div className="f-12 d-flex justify-between">
                    {datetime}
                    <div className="d-flex align-center">
                      <EyeFilled
                        style={{
                          fontSize: "16px",
                          color: "#B79965",
                          marginRight: 6,
                        }}
                      />
                      {numberWithCommas(reach)}
                    </div>
                  </div>
                  <Divider className="mt-2 mb-2" />
                  {content &&
                    parse(`${content}`, {
                      replace: (domNode: any) => {
                        if (domNode.name === "img")
                          return (
                            <Image
                              src={domNode.attribs.src}
                              width={"100%"}
                              preview={false}
                            />
                          );
                      },
                    })}
                </div>
              </div>
            </ReactShadowScroll>
          </div>
        </div>
      </Modal>
    </>
  );
};

const numberWithCommas = (number: number) => {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export default PreviewContent;
