import { MinusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Form,
  FormInstance,
  InputNumber,
  Row,
  Select,
  Switch,
} from "antd";
import numeral from "numeral";
import { useTranslation } from "react-i18next";
import { GET_LAUNDRY_TAX, ILaundryList } from "../store/laundry-list";
import { ILaundryOrder } from "../store/laundry-order";
import { vatOrSvc } from "../views/laundry/laundry-order/laundry-order-edit";

const InputArrayLaundryOrder = ({
  data,
  form,
  laundryList,
}: {
  data: ILaundryOrder | undefined;
  form: any;
  laundryList?: ILaundryList[];
}) => {
  const { i18n, t } = useTranslation();
  const tax = GET_LAUNDRY_TAX();

  const LabelInput = () => {
    return (
      <div className="w-100 py-2 d-flex justify-center">
        <Row gutter={[24, 24]} className="w-100 px-2">
          <Col span={6}>
            <div style={{ borderLeft: "1px solid #e4e9f2" }}>
              <span className="color-gold ml-2">* </span> {t("list")}
            </div>
          </Col>
          <Col span={5}>
            <div style={{ borderLeft: "1px solid #e4e9f2" }}>
              <span className="color-gold ml-2">* </span> {t("type")}
            </div>
          </Col>
          <Col span={6}>
            <Form.Item
              shouldUpdate={(prev, next) =>
                prev.isComplimentary !== next.isComplimentary
              }
            >
              {(form: FormInstance) => {
                const isComplimentary = form.getFieldValue("isComplimentary");

                return (
                  <Row gutter={24}>
                    <Col span={isComplimentary ? 12 : 24}>
                      <div style={{ borderLeft: "1px solid #e4e9f2" }}>
                        <span className="color-gold ml-2">* </span>{" "}
                        {t("quantity")}
                      </div>
                    </Col>
                    <Col span={12} hidden={!isComplimentary ? true : false}>
                      <div
                        style={{ borderLeft: "1px solid #e4e9f2" }}
                        className="text-truncate"
                      >
                        <span className="ml-2">{t("complimentary")}</span>
                      </div>
                    </Col>
                  </Row>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={4}>
            <div style={{ borderLeft: "1px solid #e4e9f2" }}>
              <span className="color-gold ml-2">* </span> {t("total-price")}
            </div>
          </Col>
          <Col span={2}>
            <div style={{ borderLeft: "1px solid #e4e9f2" }}>
              <span className="ml-2">{t("express")}</span>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const onSelectList = (name: number) => {
    let laundryOrdersList = undefined;
    const isComplimentary = form.getFieldValue("isComplimentary");
    const laundryOrders = form.getFieldValue("laundryOrdersList");

    if (isComplimentary) {
      laundryOrdersList = laundryOrders.map((item: any, index: number) => {
        if (index === name) {
          const list = laundryList?.find(
            (list) => list.id === item.laundryItem
          );
          const quantityOfFree = item.quantityOfFree || 1;
          const priceOfType = list?.laundryPrice;
          const type = "laundry";
          const quantity = item.quantity || 1;
          const totalPrice = Number(expressPrice(name)).toFixed(2);
          return {
            ...item,
            quantityOfFree,
            priceOfType,
            type,
            quantity,
            totalPrice,
          };
        }
        return item;
      });
    } else {
      laundryOrdersList = laundryOrders.map((item: any, index: number) => {
        if (index === name) {
          const quantityOfFree = undefined;
          const priceOfType = undefined;
          const type = undefined;
          const quantity = undefined;
          const totalPrice = undefined;
          return {
            ...item,
            quantityOfFree,
            priceOfType,
            type,
            quantity,
            totalPrice,
          };
        }
        return item;
      });
    }

    form.setFieldsValue({ laundryOrdersList });
    calculateTotalPrice();
  };

  const onSelectType = (name: number) => {
    const laundryOrders = form.getFieldValue("laundryOrdersList");

    const laundryOrdersList = laundryOrders.map((item: any, index: number) => {
      if (index === name) {
        const list = laundryList?.find((list) => list.id === item.laundryItem);
        let priceOfType = undefined;
        if (item.type === "dry-cleaning") priceOfType = list?.dryCleaningPrice;
        if (item.type === "laundry") priceOfType = list?.laundryPrice;
        if (item.type === "pressing-only")
          priceOfType = list?.pressingOnlyPrice;
        const quantity = item.quantity || 1;
        const totalPrice = Number(expressPrice(name, priceOfType)).toFixed(2);
        return {
          ...item,
          priceOfType,
          quantity,
          totalPrice,
        };
      }
      return item;
    });

    form.setFieldsValue({ laundryOrdersList });
    calculateTotalPrice();
  };

  const expressPrice = (name: number, price?: number) => {
    const laundryOrders = form.getFieldValue("laundryOrdersList");
    const order = laundryOrders[name];

    const quantityOfFree = order?.quantityOfFree || 0;
    const quantity = order?.quantity || 1;
    const priceOfType = price || order?.priceOfType;
    const isExpress = order?.isExpress || false;
    const normalQuantity =
      quantity - quantityOfFree < 0 ? 0 : quantity - quantityOfFree;

    if (isExpress)
      return normalQuantity * priceOfType * 2 + quantityOfFree * priceOfType;
    return normalQuantity * priceOfType;
  };

  const svc = data ? +data.svc : tax.data ? +tax.data?.svg : 0;
  const vat = data ? +data.vat : tax.data ? +tax.data?.vat : 0;

  const calculateTotalPrice = () => {
    const laundryOrders = form.getFieldValue("laundryOrdersList");
    const laundryOrdersList = laundryOrders.map((item: any, index: number) => {
      const totalPrice = expressPrice(index);
      return { ...item, totalPrice };
    });

    const totalPrice = laundryOrdersList
      .map((item: any) => Number(item.totalPrice) || 0)
      .reduce((a: number, b: number) => a + b, 0);

    const svgPrice = vatOrSvc(totalPrice, svc);
    const vatPrice = vatOrSvc(svgPrice, vat);

    form.setFieldsValue({
      laundryOrdersList,
      totalPrice: Math.ceil(Number(vatPrice)).toFixed(2),
      svgPrice: Number(svgPrice - totalPrice).toFixed(2),
      vatPrice: Number(vatPrice - svgPrice).toFixed(2),
    });
  };

  const getOrderQuantityComplimentary = (name: number) => {
    const conplimentary = form.getFieldValue("complimentary");
    const usedComplimentary = form
      .getFieldValue("laundryOrdersList")
      .filter((item: any, index: number) => index !== name)
      .map((item: any) => item?.quantityOfFree || 0)
      .reduce((a: number, b: number) => a + b, 0);
    return conplimentary - usedComplimentary;
  };

  const onChangeQuantityOfFree = (name: number) => {
    const laundryOrders = form.getFieldValue("laundryOrdersList");
    const laundryOrdersList = laundryOrders.map((item: any, index: number) => {
      if (index === name) {
        const quantityOfFree = item.quantity;
        return { ...item, quantityOfFree };
      }
      return item;
    });
    form.setFieldsValue({ laundryOrdersList });
  };

  const onChange = () => {
    if (!calculateTotalPrice) return;
    calculateTotalPrice();
  };

  const ValueInput = () => {
    return (
      <>
        <div className="w-100 px-2 py-2 d-flex align-center flex-column">
          <Form.List name="laundryOrdersList">
            {(fields, { add, remove }) => {
              if (!fields.length) add();

              return (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Row
                      gutter={[24, 24]}
                      key={key}
                      className="w-100 pb-2 pt-3"
                    >
                      <Col span={6}>
                        <Form.Item
                          shouldUpdate={(prev, next) =>
                            prev.isComplimentary !== next.isComplimentary
                          }
                        >
                          {(form: FormInstance) => {
                            const isComplimentary =
                              form.getFieldValue("isComplimentary");
                            const laundryOrdersList =
                              form.getFieldValue("laundryOrdersList");
                            const disabled = laundryOrdersList?.map(
                              (e: any) => e?.laundryItem
                            );

                            return (
                              <Form.Item
                                {...restField}
                                name={[name, "laundryItem"]}
                                className="custom-select-ant"
                                rules={[{ required: true }]}
                              >
                                <Select
                                  placeholder={t("list")}
                                  getPopupContainer={(e) => e}
                                  onChange={() => onSelectList(name)}
                                >
                                  {laundryList
                                    ?.filter((item) =>
                                      isComplimentary
                                        ? item?.isComplimentary &&
                                          item.requireLaundry
                                        : item
                                    )
                                    .filter(
                                      (item) =>
                                        item.requireDryCleaning ||
                                        item.requireLaundry ||
                                        item.requirePressingOnly
                                    )
                                    .map((item) => (
                                      <Select.Option
                                        value={item.id}
                                        key={item.id}
                                        disabled={disabled?.includes(item.id)}
                                      >
                                        {t(
                                          i18n.language === "en"
                                            ? item.nameEn
                                            : item.nameTh
                                        )}
                                      </Select.Option>
                                    ))}
                                </Select>
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item
                          shouldUpdate={(prev, next) =>
                            prev.isComplimentary !== next.isComplimentary
                          }
                        >
                          {(form: FormInstance) => {
                            const isComplimentary =
                              form.getFieldValue("isComplimentary");
                            const list =
                              form.getFieldValue("laundryOrdersList")[name];
                            const laundryTypes = laundryList?.find(
                              (item) => item.id === list?.laundryItem
                            );

                            return (
                              <Form.Item
                                {...restField}
                                name={[name, "type"]}
                                className="custom-select-ant"
                                rules={[{ required: !isComplimentary }]}
                              >
                                <Select
                                  placeholder={t("type")}
                                  getPopupContainer={(e) => e}
                                  disabled={isComplimentary}
                                  onChange={() =>
                                    isComplimentary
                                      ? undefined
                                      : onSelectType(name)
                                  }
                                >
                                  {laundryTypes?.requireDryCleaning && (
                                    <Select.Option value="dry-cleaning">
                                      {t("dry-cleaning")}
                                    </Select.Option>
                                  )}
                                  {laundryTypes?.requireLaundry && (
                                    <Select.Option value="laundry">
                                      {t("laundry")}
                                    </Select.Option>
                                  )}
                                  {laundryTypes?.requirePressingOnly && (
                                    <Select.Option value="pressing-only">
                                      {t("pressing-only")}
                                    </Select.Option>
                                  )}
                                </Select>
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                      <Form.Item
                        {...restField}
                        name={[name, "priceOfType"]}
                        hidden
                      >
                        <InputNumber />
                      </Form.Item>
                      <Col span={6}>
                        <Form.Item
                          shouldUpdate={(prev, next) =>
                            prev.isComplimentary !== next.isComplimentary
                          }
                        >
                          {(form: FormInstance) => {
                            const isComplimentary =
                              form.getFieldValue("isComplimentary");

                            const maxComplimentary =
                              getOrderQuantityComplimentary(name);

                            return (
                              <Row gutter={24}>
                                <Col span={isComplimentary ? 12 : 24}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "quantity"]}
                                    className="custom-input-number"
                                    rules={[{ required: true }]}
                                  >
                                    <InputNumber
                                      placeholder={t("quantity")}
                                      min={0}
                                      max={
                                        isComplimentary
                                          ? maxComplimentary || 0
                                          : undefined
                                      }
                                      onChange={() => {
                                        if (isComplimentary)
                                          onChangeQuantityOfFree(name);
                                        calculateTotalPrice();
                                      }}
                                      formatter={(value) =>
                                        numeral(value).format("0,000")
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                                <Col
                                  span={12}
                                  hidden={!isComplimentary ? true : false}
                                >
                                  <Form.Item
                                    {...restField}
                                    name={[name, "quantityOfFree"]}
                                    className="custom-input-number"
                                    hidden={!isComplimentary ? true : false}
                                  >
                                    <InputNumber
                                      placeholder={t("is-complimentary")}
                                      disabled
                                      min={0}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            );
                          }}
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          {...restField}
                          name={[name, "totalPrice"]}
                          className="custom-input-number"
                        >
                          <InputNumber
                            disabled
                            placeholder={t("total-price")}
                            min={0}
                            formatter={(value) =>
                              numeral(value).format("0,000 0.00")
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={2} className="d-flex align-center">
                        <Form.Item
                          shouldUpdate={(prev, next) =>
                            prev.laundryOrdersList[name]?.isExpress !==
                            next.laundryOrdersList[name]?.isExpress
                          }
                        >
                          {(form: FormInstance) => {
                            const isExpress =
                              form.getFieldValue("laundryOrdersList")[name]
                                ?.isExpress;

                            return (
                              <Form.Item
                                {...restField}
                                name={[name, "isExpress"]}
                              >
                                <Switch
                                  onChange={calculateTotalPrice}
                                  checked={isExpress}
                                />
                              </Form.Item>
                            );
                          }}
                        </Form.Item>
                      </Col>
                      <Col span={1} className="d-flex align-center">
                        {!!key && (
                          <MinusCircleOutlined
                            onClick={() => {
                              remove(name);
                              onChange();
                            }}
                          />
                        )}
                      </Col>
                      <Divider
                        style={{ margin: 0, minWidth: "98%", width: "98%" }}
                      />
                    </Row>
                  ))}
                  <div className="w-100 my-2 pt-2" style={{ paddingLeft: 12 }}>
                    <Form.Item>
                      <Button
                        className="button-save"
                        style={{ height: 45, width: 120 }}
                        onClick={() => add()}
                      >
                        {t("add-list")}
                      </Button>
                    </Form.Item>
                  </div>
                </>
              );
            }}
          </Form.List>
        </div>
        <div className="w-100 px-2 py-2">
          <Divider style={{ margin: 0, minWidth: "98%", width: "98%" }} />
        </div>
        <Row gutter={[12, 12]} className="w-100">
          <Col span={19} className="d-flex justify-end align-end mb-3">
            <div className="mr-4">
              <span className="color-gold mr-1">*</span> {t("if-you-edit-it")}
            </div>
            <Form.Item name="customToltal" className="custom-input-switch mr-4">
              <Switch checkedChildren="edit" unCheckedChildren="edit" />
            </Form.Item>
          </Col>
          <Col span={4} className="pb-2">
            <Form.Item
              shouldUpdate={(prev, cur) =>
                prev.customToltal !== cur.customToltal
              }
            >
              {(_form: FormInstance) => {
                const disabled = form.getFieldValue("customToltal");

                return (
                  <Form.Item
                    name="totalPrice"
                    label={`${t("total")}`}
                    rules={[{ required: true }]}
                    className="custom-input-number pt-3"
                  >
                    <InputNumber
                      disabled={!disabled}
                      placeholder={`${t("total")}`}
                      formatter={(value) => numeral(value).format("0,000 0.00")}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={17}></Col>
          <Col span={3} className="d-flex align-center">
            {t("svc")}
          </Col>
          <Col span={1} className="d-flex align-center">
            <span className="color-gold ml-2">{svc.toFixed(0)}%</span>
          </Col>
          <Col span={2} className="d-flex justify-end">
            <Form.Item
              shouldUpdate={(prev, cur) => prev.svgPrice !== cur.svgPrice}
            >
              {(_form: FormInstance) => {
                const svgPrice = form.getFieldValue("svgPrice");

                return (
                  <>
                    <span className="color-gold mr-2">
                      {svgPrice ? Number(svgPrice).toFixed(2) : 0}
                    </span>
                    {t("baht")}
                  </>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={17}></Col>
          <Col span={3} className="d-flex align-center">
            {t("vat")}
          </Col>
          <Col span={1}>
            <span className="color-gold ml-2">{vat.toFixed(0)}%</span>
          </Col>
          <Col span={2} className="d-flex justify-end">
            <Form.Item
              shouldUpdate={(prev, cur) => prev.vatPrice !== cur.vatPrice}
            >
              {(_form: FormInstance) => {
                const vatPrice = form.getFieldValue("vatPrice");

                return (
                  <>
                    <span className="color-gold mr-2">
                      {vatPrice ? Number(vatPrice).toFixed(2) : 0}
                    </span>
                    {t("baht")}
                  </>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div className="w-100 px-3 py-3">
      <LabelInput />
      <div className="d-flex justify-center pt-2">
        <Divider style={{ margin: 0, minWidth: "98%", width: "98%" }} />
      </div>
      <ValueInput />
    </div>
  );
};

export default InputArrayLaundryOrder;
