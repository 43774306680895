import { Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import CustomStatusBackground from "../../../components/custom-status";
import CustomTable from "../../../components/custom-table";
import ExportExcel from "../../../components/export-date";
import PageFilter, { IPageFilterChoice } from "../../../components/page-filter";
import PageHeader, {
  IExcelDate,
  ISearchInput,
} from "../../../components/page-header";
import { HOMECARE_PRIVATE_AREA_REPAIRING_PATH } from "../../../router/path";
import { page_params } from "../../../store";
import { useNotification } from "../../../store/notification";
import {
  BULK_DELETE_REPAIRING,
  DELETE_REPAIRING,
  EXPORT_REPAIRING,
  GET_REPAIRING,
  IRepairing,
  LINE_NOTIFICATION_REPAIRING,
} from "../../../store/repairing";
import { GET_UNIT } from "../../../store/units";
import { parse } from "../../../tools/datetime";
import { swalDelete, swalError, swalSuccess } from "../../../tools/swal";

export const repairingStatus = [
  { id: 1, name: "waiting", key: "WAITING" },
  { id: 2, name: "pending", key: "PENDING" },
  { id: 3, name: "processing", key: "PROCESSING" },
  { id: 4, name: "checking", key: "CHECKING" },
  { id: 5, name: "complete", key: "COMPLETE" },
  { id: 6, name: "cancel", key: "CANCEL" },
];

export const repairingType = [
  { id: 1, name: "private", key: "PRIVATE" },
  { id: 2, name: "common", key: "COMMON" },
];

const RepairingPage = () => {
  const [params, setParams] = useRecoilState(page_params);
  const { t, i18n } = useTranslation();
  const units = GET_UNIT({ pagination: false, status: "ACTIVE" });
  const { data, isLoading, refetch } = GET_REPAIRING(params);
  const { mutate } = DELETE_REPAIRING();
  const deleteBulk = BULK_DELETE_REPAIRING();
  const exportExcel = EXPORT_REPAIRING();
  const lineNotification = LINE_NOTIFICATION_REPAIRING();
  const [formData] = useForm();
  const [visible, setVisible] = useState<boolean>(false);

  const { tableNoti, dispatch } = useNotification();

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableNoti]);

  useEffect(() => {
    return () =>
      dispatch!({
        type: "table",
        action: "clear",
        key: ["homecare", "homecare-repairing"],
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showModalExport = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    formData.resetFields();
  };

  const excelDate: IExcelDate = {
    showModalExport: showModalExport,
  };

  const searchInput: ISearchInput[] = [
    {
      label: t("search"),
      key: "search",
      span: 10,
      type: "input",
      props: {
        placeholder: t("search-user-request"),
      },
    },
    {
      label: t("house-number"), //unit number to use, house number to show
      key: "unit",
      span: 7,
      type: "select",
      props: {
        placeholder: t("all"),
        optionValue: {
          values: units.data?.data || [],
          key: "id",
          name: "unitNumber",
        },
      },
    },
    {
      label: t("repairing-status"),
      key: "status",
      span: 7,
      type: "select",
      props: {
        placeholder: t("repairing-status"),
        optionValue: {
          values: repairingStatus || [],
          key: "key",
          name: "name",
        },
        hasTranslation: true,
      },
    },
    {
      label: t("repairing-type"),
      key: "repairingType",
      span: 7,
      type: "select",
      props: {
        placeholder: t("repairing-type"),
        optionValue: {
          values: repairingType || [],
          key: "key",
          name: "name",
        },
        hasTranslation: true,
      },
    },
    {
      label: t("creation-date"),
      key: "date",
      span: 7,
      type: "range-picker",
      props: {
        placeholder: [t("start-date"), t("end-date")],
        getPopupContainer: (props: HTMLElement) => {
          return props;
        },
      },
    },
  ];
  const choices: IPageFilterChoice[] = [
    {
      label: t("all"),
      total: data?.countData.countAll || 0,
    },
    {
      label: t("over-one-day"),
      key: "overOneDay",
      total: data?.countData.countWatingOverOneDay || 0,
    },
    {
      label: t("over-sla-date"),
      key: "overSLADate",
      total: data?.countData.countOverSLA || 0,
    },
  ];

  const columns: ColumnsType<IRepairing> = [
    {
      dataIndex: "repairingCode",
      title: t("job-number"),
      render: (text, record) => <>{text ? text : "-"}</>,
    },
    {
      dataIndex: "unitNumber",
      title: t("house-number"), //unit number to use, house number to show
      render: (text, record) => {
        return <>{record.unit ? record?.unit?.unitNumber : "-"}</>;
      },
    },
    {
      dataIndex: "createdAt",
      title: t("created-at"),
      render: (text, record) => {
        return (
          <>
            {record.createdAt
              ? parse(
                  record.createdAt,
                  i18n.language,
                  `${
                    i18n.language === "en"
                      ? "DD MMM YYYY / HH:mm a"
                      : "DD MMM BBBB / HH:mm a"
                  }`
                )
              : "-"}
          </>
        );
      },
    },
    {
      dataIndex: "startDate",
      title: t("appointment-date"),
      render: (text, record) => {
        const appointment = record?.repairingAppointments
          ? record?.repairingAppointments?.sort((a, b) => {
              return b.id - a.id;
            })
          : [];
        return (
          <>
            {record.repairingAppointments.length > 0
              ? parse(
                  appointment[0].appointmentFrom,
                  i18n.language,
                  `${
                    i18n.language === "en"
                      ? "DD MMM YYYY / HH:mm a"
                      : "DD MMM BBBB / HH:mm a"
                  }`
                )
              : "-"}
          </>
        );
      },
    },
    {
      dataIndex: "repairingType",
      title: t("repairing-type"),
      render: (text, record) => (
        <>
          {text === null && "-"}
          {text === "COMMON" && (
            <CustomStatusBackground
              lable={t("common")}
              className={"frame-background-common"}
            />
          )}
          {text === "PRIVATE" && (
            <CustomStatusBackground
              lable={t("private")}
              className={"frame-background-private"}
            />
          )}
        </>
      ),
    },
    {
      dataIndex: "status",
      title: t("repairing-status"),
      render: (text, record) => {
        return (
          <>
            {text === null && "-"}
            {text === "CHECKING" && (
              <CustomStatusBackground
                lable={t("checking")}
                className={"frame-background-checking"}
              />
            )}
            {text === "WAITING" && (
              <CustomStatusBackground
                lable={t("waiting")}
                className={"frame-background-waiting"}
              />
            )}
            {text === "PROCESSING" && (
              <CustomStatusBackground
                lable={t("processing")}
                className={"frame-background-processing"}
              />
            )}
            {text === "COMPLETE" && (
              <CustomStatusBackground
                lable={t("complete")}
                className={"frame-background-complete"}
              />
            )}
            {text === "CANCEL" && (
              <CustomStatusBackground
                lable={t("cancel")}
                className={"frame-background-cancel"}
              />
            )}
            {text === "PENDING" && (
              <CustomStatusBackground
                lable={t("pending")}
                className={"frame-background-pending"}
              />
            )}
          </>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    if (values.date) {
      const startDate = parse(values.date[0], "en", "YYYY-MM-DD 00:00");
      const endDate = parse(values.date[1], "en", "YYYY-MM-DD 23:59");
      values = { ...values, startDate, endDate };
    }
    delete values.date;
    setParams({ ...params, ...values });
  };

  const onFilter = (choice: IPageFilterChoice) => {
    if (choice.key === "overOneDay") {
      setParams({ ...params, overOneDay: true, overSLADate: undefined });
    } else if (choice.key === "overSLADate") {
      setParams({ ...params, overSLADate: true, overOneDay: undefined });
    } else {
      setParams({ ...params, overSLADate: undefined, overOneDay: undefined });
    }
  };
  const onDelete = async (id: number) => {
    const confirm = await swalDelete(t);
    if (confirm) {
      mutate(
        { id },
        {
          onError: (err: any) => {
            swalError(t, err.message);
          },
          onSuccess: () => {
            if (params.page > 1 && data?.data.length === 1) {
              setParams({ ...params, page: params.page - 1 });
            }
            swalSuccess(t);
            refetch();
          },
        }
      );
    }
  };

  const onReset = () => {
    const values = { startDate: undefined, endDate: undefined };
    setParams({
      ...params,
      ...values,
    });
  };

  const _getCurrentFilter = (): string | undefined => {
    const { overOneDay, overSLADate } = params;
    if (overOneDay) {
      return "overOneDay";
    }
    if (overSLADate) {
      return "overSLADate";
    }
    return undefined;
  };

  return (
    <div>
      <PageHeader
        menu="homecare-repairing"
        onSearch={onSearch}
        searchInput={searchInput}
        label={"create"}
        path={HOMECARE_PRIVATE_AREA_REPAIRING_PATH.create}
        onReset={onReset}
        bulkDelete={deleteBulk}
        invalidateQueries={["get-repairing"]}
        exportExcel={exportExcel}
        topicExport="Repairing"
        lineNotification={lineNotification}
        hasExportExcelDate={true}
        utility={["line", "delete"]}
        excelDate={excelDate}
      />
      <PageFilter
        choices={choices}
        onClick={onFilter}
        current={_getCurrentFilter()}
      />
      <CustomTable
        title={t("repairing-lists")}
        dataSource={data?.data || []}
        columns={columns}
        pagination={{
          limit: params.limit,
          page: params.page,
          total: data?.count || 0,
        }}
        onDelete={onDelete}
        onPageChange={(page, limit) => setParams({ ...params, page, limit })}
        isLoadingTable={isLoading}
        rowClassName={(record, index) => {
          if (tableNoti["homecare"]?.some((e) => e === record.id)) {
            return "table-row-pink";
          }
        }}
      />
      <Modal
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
        width="35vw"
        centered
      >
        <ExportExcel
          handleCancel={handleCancel}
          formDate={formData}
          exportExcel={exportExcel}
        />
      </Modal>
    </div>
  );
};

export default RepairingPage;
