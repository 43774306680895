import { ColumnsType } from "antd/lib/table";
import numeral from "numeral";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import CustomStatusBackground from "../../components/custom-status";
import CustomTable from "../../components/custom-table";
import PageFilter, { IPageFilterChoice } from "../../components/page-filter";
import PageHeader, { ISearchInput } from "../../components/page-header";
import { NEWS_CREATE_PATH } from "../../router/path";
import { page_params } from "../../store/global";
import {
  DELETE_NEWS,
  DELETE_NEWS_BULK,
  EXPORT_NEWS,
  GET_NEWS,
} from "../../store/news";
import { findStartOrEndTime, parse } from "../../tools/datetime";
import { swalDelete, swalError, swalSuccess } from "../../tools/swal";

const NewsPage = () => {
  const { t, i18n } = useTranslation();
  const [params, setParams] = useRecoilState(page_params);
  const { data, refetch, isLoading } = GET_NEWS(params);
  const { mutate } = DELETE_NEWS();
  const deleteBulk = DELETE_NEWS_BULK();
  const exportExcel = EXPORT_NEWS();

  const statusFilter = [
    { id: 1, name: "draft", key: "DRAFT" },
    { id: 2, name: "published", key: "PUBLISHED" },
  ];

  const searchInput: ISearchInput[] = [
    {
      label: t("search"),
      key: "search",
      span: 10,
      type: "input",
      props: {
        placeholder: t("search-heading-EN-TH"),
      },
    },
    {
      label: t("status"),
      key: "status",
      span: 7,
      type: "select",
      props: {
        hasTranslation: true,
        placeholder: t("all"),
        optionValue: {
          values: statusFilter || [],
          key: "key",
          name: "name",
        },
      },
    },
    {
      label: t("creation-date"),
      key: "date",
      span: 7,
      type: "range-picker",
      props: {
        placeholder: [t("start-date"), t("end-date")],
        getPopupContainer: (props: HTMLElement) => {
          return props;
        },
      },
    },
  ];
  const choices: IPageFilterChoice[] = [
    {
      label: t("all"),
      // key: "ALL",
      total: data?.countData.countAll || 0,
    },
    {
      label: t("banner"),
      key: "BANNER",
      total: data?.countData.countBanner || 0,
    },
    {
      label: t("rules-and-regulations"),
      key: "PUBLIC_RELATIONS",
      total: data?.countData.countPublicRelations || 0,
    },
    {
      label: t("news"),
      key: "NEWS",
      total: data?.countData.countNews || 0,
    },
  ];
  const columns: ColumnsType = [
    {
      dataIndex: i18n.language === "en" ? "headerEn" : "headerTh",
      title: t("title"),
      render: (text, record) => {
        return <div className="line-clamp">{text || "-"}</div>;
      },
    },
    {
      dataIndex: "startDate",
      title: t("start-date"),
      render: (text, record) => {
        return (
          <p style={{ whiteSpace: "pre", display: "contents" }}>
            {text
              ? parse(
                  text,
                  i18n.language,
                  `${i18n.language === "en" ? "DD MMM YYYY" : "DD MMM BBBB"}`
                )
              : "-"}
          </p>
        );
      },
    },
    {
      dataIndex: "endDate",
      title: t("end-date"),
      render: (text, record) => {
        return (
          <p style={{ whiteSpace: "pre", display: "contents" }}>
            {text
              ? parse(
                  text,
                  i18n.language,
                  `${i18n.language === "en" ? "DD MMM YYYY" : "DD MMM BBBB"}`
                )
              : "-"}
          </p>
        );
      },
    },
    {
      dataIndex: "category",
      title: t("category"),
      render: (text, record) => {
        return (
          <p style={{ whiteSpace: "pre", display: "contents" }}>
            {!text && "-"}
            {text === "BANNER" && `${t("banner")}`}
            {text === "PUBLIC_RELATIONS" && `${t("rules-and-regulations")}`}
            {text === "NEWS" && `${t("news")}`}
          </p>
        );
      },
    },
    {
      dataIndex: "reach",
      title: t("visitors"),
      render: (text, record) => {
        return <>{numeral(text || 0).format("0,0")}</>;
      },
    },
    {
      dataIndex: "status",
      title: t("status"),
      render: (text, record) => {
        return (
          <>
            {!text && "-"}
            {text === "DRAFT" && (
              <CustomStatusBackground
                lable={t("draft")}
                className={"frame-no-background"}
              />
            )}
            {text === "PUBLISHED" && (
              <CustomStatusBackground
                lable={t("published")}
                className={"frame-background"}
              />
            )}
          </>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    if (!values.date) {
      values.date = [];
    }
    const { date } = values;

    const createdAtStartDate = findStartOrEndTime(date[0], "start");
    const createdAtEndDate = findStartOrEndTime(date[1], "end");
    values = { ...values, createdAtStartDate, createdAtEndDate };
    delete values.date;

    setParams({ ...params, ...values });
  };

  const onFilter = (choice: IPageFilterChoice) => {
    const category = choice.key === "ALL" ? undefined : choice.key;
    setParams({ ...params, category: category });
  };

  const onDelete = async (id: number) => {
    const confirm = await swalDelete(t);
    if (confirm) {
      mutate(
        { id },
        {
          onError: (err: any) => {
            swalError(t, err.message);
          },
          onSuccess: () => {
            swalSuccess(t);
            refetch();
          },
        }
      );
    }
  };

  const _getCurrentFilter = (): string | undefined => {
    const { category } = params;
    if (category) return category;
    return undefined;
  };

  return (
    <div>
      <PageHeader
        menu="news"
        onSearch={onSearch}
        searchInput={searchInput}
        label={"create"}
        path={NEWS_CREATE_PATH}
        bulkDelete={deleteBulk}
        invalidateQueries={["get-news"]}
        exportExcel={exportExcel}
        topicExport="News"
        utility={["delete", "export"]}
        // lineNotification={lineNotification}
      />
      <PageFilter
        choices={choices}
        current={_getCurrentFilter()}
        onClick={onFilter}
      />
      <CustomTable
        rowKey="id"
        title={`${t("news-lists")}`}
        dataSource={data?.data || []}
        columns={columns}
        onDelete={onDelete}
        pagination={{
          limit: params.limit,
          page: params.page,
          total: data?.count || 0,
        }}
        onPageChange={(page, limit) => setParams({ ...params, page, limit })}
        isLoadingTable={isLoading}
      />
    </div>
  );
};

export default NewsPage;
