import React from "react";

const PDF_ICON = (props: React.SVGProps<SVGSVGElement> = {}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={59} height={59}  viewBox="0 0 141.73 141.73" {...props}>
      <defs>
        <clipPath id="a">
          <path d="M0 0h59v59H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)">
        <path
          data-name="Icon awesome-file-pdf"
          d="M28.376 29.511c-.557-1.781-.546-5.221-.223-5.221.935 0 .846 4.11.223 5.221Zm-.189 5.255a51.371 51.371 0 0 1-3.162 6.98 40.994 40.994 0 0 1 7-2.438 14.421 14.421 0 0 1-3.839-4.542ZM17.71 48.66c0 .089 1.47-.6 3.885-4.475-.745.701-3.239 2.727-3.885 4.475Zm18.024-29.847h15.141v36.515A2.665 2.665 0 0 1 48.203 58H10.797a2.665 2.665 0 0 1-2.672-2.672V3.672A2.665 2.665 0 0 1 10.797 1h22.266v15.141a2.68 2.68 0 0 0 2.671 2.672Zm-.891 19.126a11.174 11.174 0 0 1-4.754-5.989c.5-2.06 1.291-5.188.69-7.147-.523-3.273-4.72-2.95-5.321-.757-.557 2.037-.045 4.91.9 8.572a104.54 104.54 0 0 1-4.542 9.552c-.011 0-.011.011-.022.011-3.017 1.547-8.194 4.954-6.067 7.57a3.459 3.459 0 0 0 2.398 1.113c1.993 0 3.974-2 6.8-6.88a63.459 63.459 0 0 1 8.794-2.584 16.872 16.872 0 0 0 7.125 2.171c3.251 0 3.473-3.562 2.193-4.832-1.547-1.514-6.045-1.08-8.194-.8Zm15.253-25.25-10.91-10.91A2.67 2.67 0 0 0 37.293 1h-.668v14.25h14.25v-.679a2.663 2.663 0 0 0-.779-1.882Zm-8.25 28.423c.456-.3-.278-1.325-4.765-1 4.131 1.757 4.765 1 4.765 1Z"
          fill="#aaaaa7"
        />
      </g>
    </svg>
  );
};

export default PDF_ICON;
