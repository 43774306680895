import { atom } from "recoil";
import { PartialRecord } from "../layout/sidebar";
import { TMenuKey } from "../router/menu";

export interface IPageParams {
  limit: number;
  total: number;
  page: number;
  isActive?: boolean;
  isReceived?: boolean;
  isCancel?: boolean;
  category?: string;
  status?: string;
  pagination?: boolean;
  repairingType?: string;
  overOneDay?: boolean;
  overSLADate?: boolean;
}

interface IPaginationSelect {
  page: number;
  data: any[];
}

interface IFixValue {
  value: any;
  label: string;
}
export interface IResponse<T> {
  data: T;
  count: number;
  countData: any;
  page?: number;
  pageCount?: number;
  limit?: number;
}

export interface IUsersList<T> {
  data: T;
  count: number;
  page: number;
  limit: number;
}

// type TCount = PartialRecord<TMenuKey, number>;
export type TIdCount = PartialRecord<TMenuKey, (number | undefined)[]>;

export interface TSocketValue {
  facilityBookingId?: number;
}

export const select_rows_atom = atom<IPaginationSelect[]>({
  key: "select-rows-atom",
  default: [],
});

export const entireSelection = (data: IPaginationSelect[]): any[] => {
  return data.map((i) => i.data).flat();
};

export const page_params = atom<IPageParams>({
  key: "page-params",
  default: {
    limit: 10,
    page: 1,
    total: 0,
  },
});

// export const socket_value = atom<TSocketValue>({ key: "socket", default: {} });
// export const id_value = atom<TIdCount>({
//   key: "id",
//   default: { "facility-list-booking": [] },
// });

// export const count_value = atom<TCount>({
//   key: "count",
//   default: {
//     "facility-list-booking": 0,
//     "facility-list": 0,
//   },
// });

export const FIXED_STATUS: IFixValue[] = [
  {
    value: true,
    label: "active",
  },
  {
    value: false,
    label: "inactive",
  },
];

export const FIXED_GENDER: IFixValue[] = [
  {
    value: "FEMALE",
    label: "female",
  },
  {
    value: "MALE",
    label: "male",
  },
];

export const FIXED_LANGUAGE: IFixValue[] = [
  {
    value: "th",
    label: "thai",
  },
  {
    value: "en",
    label: "english",
  },
];
export const FIXED_ACCEPT: IFixValue[] = [
  {
    value: true,
    label: "accept",
  },
  {
    value: false,
    label: "decline",
  },
];
