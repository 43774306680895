import { Col, Form, Image, Row, Spin } from "antd";
import dayjs from "dayjs";
import LoadingGif from "../../assets/images/loding.gif";
import { useTranslation } from "react-i18next";
import CustomDatePicker from "../../components/custom-date-picker";
import localeTh from "antd/es/date-picker/locale/th_TH";
import YearSelect from "../../components/year-select";

export const DBHeader = ({
  selectType,
  formItemName,
  label,
}: {
  selectType: "year" | "range";
  formItemName: string;
  label: string;
}) => {
  const { i18n } = useTranslation();
  return (
    <Row gutter={6}>
      <Col span={10}>
        <p className="f-18 bold mb-0">{label}</p>
      </Col>
      <Col className='ml-auto'>
        {/* <span className="ml-auto"> */}
          <Form.Item name={formItemName} noStyle>
            {selectType === "range" ? (
              <CustomDatePicker.RangePicker
                className="antd-picker-db"
                locale={i18n.language === "en" ? undefined : localeTh}
                format="DD/MM/YYYY"
                size="small"
              />
            ) : (
              <YearSelect />
            )}
          </Form.Item>
        {/* </span> */}
      </Col>
    </Row>
  );
};

export const appendStartAndEndOfTime = (data?: dayjs.Dayjs[]) => {
  if (!data) return;
  const [s, e] = data;

  const startDate = s.hour(0).minute(0).second(0).format("YYYY-MM-DD");
  const endDate = e.hour(23).minute(59).second(59).format("YYYY-MM-DD");

  return { startDate: startDate, endDate: endDate };
};

export const getFirstAndLastDateOfMonth = (
  date?: string
): { startDate: string; endDate: string } | undefined => {
  if (!date) return;
  const parse = dayjs(date);
  const startDate = parse.startOf("M").format();
  const endDate = parse.endOf("M").format();

  return { startDate, endDate };
};

export const getFirstAndLastDateOfYear = (
  year?: number
): { startDate: string; endDate: string } | undefined => {
  if (!year) return;

  const parse = dayjs().year(year);
  const startDate = parse.startOf("y").format();
  const endDate = parse.endOf("y").format();

  return { startDate, endDate };
};

export const LoadingPage = () => {
  const indicator = (
    <Image src={LoadingGif} preview={false} style={{ fontSize: 50 }} />
  );
  return (
    <div className="h-100 w-100 c-container">
      <Spin indicator={indicator} />
    </div>
  );
};

export const ErrorPage = ({ error }: { error?: any }) => {
  const { t } = useTranslation();
  return (
    <div className="h-100 w-100 c-container">
      <span className="f-18 ml-2">
        {error?.message || t("an-error-occurred")}
      </span>
    </div>
  );
};
