import {
  createContext,
  Dispatch,
  Reducer,
  useContext,
  useEffect,
  useReducer,
} from "react";
import io from "socket.io-client";
import { serverUrl } from "../config/serverUrl";
import { PartialRecord } from "../layout/sidebar";
import { TMenuKey } from "../router/menu";

interface ISocketValue {
  facilityBookingId?: number;
}

interface ISocketValueUser {
  userId?: number;
  isMember?: boolean;
}

interface ISocketValueNoti {
  itemId: number;
  type: ITypeNoti;
}

type ITypeNoti = "PAYMENT" | "HOMECARE" | "SERVICE" | "FOOD" | "LAUNDRY";

type INotification = PartialRecord<TMenuKey, number[]>;

interface INotificationContext {
  sidebarNoti: INotification;
  tableNoti: INotification;
  headerNoti: INotification;
  dispatch?: Dispatch<TNotiAction>;
}

const NotificationContext = createContext<INotificationContext>({
  sidebarNoti: {
    "facility-list": [],
    "facility-list-booking": [],
    "user-management-resident": [],
    "user-management-admin": [],
    payment: [],
    homecare: [],
    "homecare-repairing": [],
    service: [],
    "service-order": [],
    food: [],
    "food-order": [],
    laundry: [],
    "laundry-order": [],
  },
  tableNoti: {
    "facility-list": [],
    "facility-list-booking": [],
    "user-management-resident": [],
    "user-management-admin": [],
    payment: [],
    homecare: [],
    "homecare-repairing": [],
    service: [],
    "service-order": [],
    food: [],
    "food-order": [],
    laundry: [],
    "laundry-order": [],
  },
  headerNoti: {
    "user-management-resident": [],
    "user-management-admin": [],
  },
});

const NotificationProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = useReducer<Reducer<INotiState, TNotiAction>>(
    notiReducer,
    {
      tableNoti: {
        "facility-list": [],
        "facility-list-booking": [],
        payment: [],
        homecare: [],
        "homecare-repairing": [],
        service: [],
        "service-order": [],
        food: [],
        "food-order": [],
        laundry: [],
        "laundry-order": [],
      },
      sidebarNoti: {
        "facility-list": [],
        "facility-list-booking": [],
        payment: [],
        homecare: [],
        "homecare-repairing": [],
        service: [],
        "service-order": [],
        food: [],
        "food-order": [],
        laundry: [],
        "laundry-order": [],
      },
      headerNoti: {
        "user-management-resident": [],
        "user-management-admin": [],
      },
    }
  );

  useEffect(() => {
    const socket = io(serverUrl.replace("http", "ws"), {
      transports: ["websocket"],
    });

    socket.on("new-message", setFromSocket);
    socket.on("new-user-create", setFromSocketNewUser);
    socket.on("on-create", setFromSocketNoti);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFromSocket = (value: ISocketValue) => {
    const { facilityBookingId } = value;

    if (facilityBookingId) {
      dispatch({
        type: "all",
        action: "add",
        id: facilityBookingId,
        key: ["facility-list", "facility-list-booking"],
      });
    }
  };

  const setFromSocketNewUser = (value: ISocketValueUser) => {
    const { userId, isMember } = value;

    if (isMember) {
      dispatch({
        type: "header",
        action: "add",
        id: userId,
        key: ["user-management-resident"],
      });
    }

    if (!isMember) {
      dispatch({
        type: "header",
        action: "add",
        id: userId,
        key: ["user-management-admin"],
      });
    }
  };

  const setFromSocketNoti = (value: ISocketValueNoti) => {
    // console.log(value);

    const { itemId, type } = value;

    if (type === "PAYMENT") {
      dispatch({
        type: "all",
        action: "add",
        id: itemId,
        key: ["payment"],
      });
    }

    if (type === "HOMECARE") {
      dispatch({
        type: "all",
        action: "add",
        id: itemId,
        key: ["homecare", "homecare-repairing"],
      });
    }

    if (type === "SERVICE") {
      dispatch({
        type: "all",
        action: "add",
        id: itemId,
        key: ["service", "service-order"],
      });
    }

    if (type === "FOOD") {
      dispatch({
        type: "all",
        action: "add",
        id: itemId,
        key: ["food", "food-order"],
      });
    }

    if (type === "LAUNDRY") {
      dispatch({
        type: "all",
        action: "add",
        id: itemId,
        key: ["laundry", "laundry-order"],
      });
    }
  };

  return (
    <NotificationContext.Provider
      value={{
        ...state,
        dispatch: dispatch,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
export default NotificationProvider;

interface INotiState {
  tableNoti: INotification;
  sidebarNoti: INotification;
  headerNoti: INotification;
}

type TNotiAction = {
  type: "sidebar" | "table" | "all" | "header";
  action: "clear" | "add";
  id?: number;
  key: TMenuKey[];
};

const notiReducer = (state: INotiState, payload: TNotiAction): INotiState => {
  const { action, type, id, key } = payload;

  for (const keyItem of key) {
    const { tableNoti, sidebarNoti, headerNoti } = state;

    if (type === "header") {
      if (action === "clear") {
        state = {
          ...state,
          headerNoti: {
            ...headerNoti,
            [keyItem]: [],
          },
        };
      } else {
        state = {
          ...state,
          headerNoti: {
            ...headerNoti,
            [keyItem]: [...headerNoti[keyItem]!, id],
          },
        };
      }
    } else {
      if (action === "clear") {
        switch (type) {
          case "all":
            state = {
              ...state,
              tableNoti: { ...tableNoti, [keyItem]: [] },
              sidebarNoti: { ...sidebarNoti, [keyItem]: [] },
            };
            break;
          case "sidebar":
            state = {
              ...state,
              sidebarNoti: { ...sidebarNoti, [keyItem]: [] },
            };
            break;
          case "table":
            state = {
              ...state,
              tableNoti: { ...tableNoti, [keyItem]: [] },
            };
            break;
        }
        continue;
      }

      switch (type) {
        case "all":
          state = {
            ...state,
            sidebarNoti: {
              ...sidebarNoti,
              [keyItem]: [...sidebarNoti[keyItem]!, id],
            },
            tableNoti: {
              ...tableNoti,
              [keyItem]: [...tableNoti[keyItem]!, id],
            },
          };
          break;
        case "sidebar":
          state = {
            ...state,
            sidebarNoti: {
              ...sidebarNoti,
              [keyItem]: [...sidebarNoti[keyItem]!, id],
            },
          };
          break;
        case "table":
          state = {
            ...state,
            tableNoti: {
              ...tableNoti,
              [keyItem]: [...tableNoti[keyItem]!, id],
            },
          };
          break;
      }
    }
  }

  return state;
};
