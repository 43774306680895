const ErrorPage = ({ error }: { error?: any }) => {
  return (
    <div className="d-flex flex-column justify-end align-center">
      <span>error page</span>
      <span className="text-center">{error?.message}</span>
    </div>
  );
};

export default ErrorPage;
