import { Col, Form, Input, InputNumber, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import CustomDatePicker from "../../../components/custom-date-picker";
import PageHeaderEdit from "../../../components/page-header-create";
import { VALIDATE_MESSAGES } from "../../../config/validateMessage";
import { GET_REPAIRING_ZONE } from "../../../store";
import {
  GET_REPAIRING_CATEGORY_ID,
  PATCH_REPAIRING_CATEGORY,
  POST_REPAIRING_CATEGORY,
} from "../../../store/repairing-category";
import { swalError, swalSuccess } from "../../../tools/swal";
import { getKeyByLocale } from "../../../tools/translation";
import th from "antd/es/date-picker/locale/th_TH";
import en from "antd/es/date-picker/locale/en_US";

const RepairingCategoryEdit = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = GET_REPAIRING_CATEGORY_ID(id);
  const create = POST_REPAIRING_CATEGORY();
  const update = PATCH_REPAIRING_CATEGORY();
  const client = useQueryClient();
  const [form] = useForm();
  const history = useHistory();
  const { i18n, t } = useTranslation();
  const repairingZone = GET_REPAIRING_ZONE({
    pagination: false,
  });

  useEffect(() => {
    initial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data]);

  const initial = () => {
    if (!data) return;
    form.setFieldsValue({
      ...data,
      repairingZone: data?.repairingZone?.id || undefined,
    });
  };

  const onFinish = (values: any) => {
    values.status = values.status === undefined ? "ACTIVE" : values.status;

    delete values.createdAt;
    delete values.updatedAt;
    delete values.createdBy;

    if (id) {
      const data = { ...values, id: +id };
      update.mutate(data, {
        onError: ({ message }: any) => {
          swalError(t, message);
        },
        onSuccess: () => {
          client.invalidateQueries("setting-facility-categories");
          client.invalidateQueries(["setting-facility-category", id]);
          swalSuccess(t);
          history.goBack();
        },
      });
    } else {
      create.mutate(values, {
        onError: ({ message }: any) => {
          swalError(t, message);
        },
        onSuccess: () => {
          client.invalidateQueries("setting-facility-category");
          swalSuccess(t);
          history.goBack();
        },
      });
    }
  };

  const FormInput = () => {
    return (
      <Row gutter={[24, 24]} className="px-4 mb-4 pb-2">
        <Col span={12}>
          <Form.Item
            name="nameEn"
            label={t("nameEn")}
            className="custom-input"
            rules={[{ required: true }, { max: 255 }]}
          >
            <Input placeholder={t("nameEn")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="nameTh"
            label={t("nameTh")}
            className="custom-input"
            rules={[{ required: true }, { max: 255 }]}
          >
            <Input placeholder={t("nameTh")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="repairingZone"
            label={t("menu.setting-repairing-zone")}
            className="custom-select-ant"
            rules={[{ required: true }]}
          >
            <Select
              placeholder={t("menu.setting-repairing-zone")}
              allowClear
              showSearch
              optionFilterProp="children"
              getPopupContainer={(value) => {
                return value;
              }}
            >
              {(repairingZone.data?.data || []).map((item, index) => {
                return (
                  <Select.Option
                    key={index}
                    value={item.id}
                    disabled={item.status === "INACTIVE"}
                  >
                    {getKeyByLocale({
                      key: "name",
                      data: item,
                      locale: i18n.language,
                    })}{" "}
                    {item.status === "INACTIVE" && `(${t("inactive")})`}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="status"
            label={t("status")}
            className="custom-select-ant "
          >
            <Select placeholder={t("status")} defaultValue="ACTIVE">
              <Select.Option value="ACTIVE">{t("active")}</Select.Option>
              <Select.Option value="INACTIVE">{t("inactive")}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <div className="d-flex align-end">
            <Form.Item
              name="period"
              label={t("period")}
              className="w-90 custom-input-number"
              rules={[{ required: true }]}
            >
              <InputNumber
                placeholder={t("period")}
                formatter={(value) => `${value}`.replace(".", "")}
                min={0}
              />
            </Form.Item>
            <span style={{ width: "10%", padding: "12px" }}>{t("month")}</span>
          </div>
          <span className="color-red">*</span>{" "}
          <span className="color-primary f-12">
            {t("example-repairing-period")}
          </span>
        </Col>
        <Col span={12}>
          <div className="d-flex align-end">
            <Form.Item
              name="slaDate"
              label={t("sla")}
              className="w-90 custom-input-number"
              rules={[{ required: true }]}
            >
              <InputNumber
                placeholder={t("sla")}
                formatter={(value) => `${value}`.replace(".", "")}
                min={0}
              />
            </Form.Item>
            <span style={{ width: "10%", padding: "12px" }}>{t("day")}</span>
          </div>
          <span className="color-red">*</span>{" "}
          <span className="color-primary f-12">
            {t("example-repairing-time")}
          </span>
        </Col>
        {/* <Col span={12} className="d-flex align-end">
          <Form.Item
            name="repairingPrice"
            label={t("repairing-price")}
            className="w-90 custom-input-number"
            rules={[{ required: true }]}
          >
            <InputNumber placeholder={t("repairing-price")} />
          </Form.Item>
          <span style={{ width: "10%", padding: "12px" }}>{t("baht")}</span>
        </Col> */}
        {id && (
          <>
            <Col span={12}>
              <Form.Item
                name="createdAt"
                label={t("creation-date")}
                className="custom-ant-picker"
              >
                <CustomDatePicker
                  locale={i18n.language === "en" ? en : th}
                  format={i18n.language === "en" ? "DD-MM-YYYY" : "DD-MM-BBBB"}
                  disabled
                  className="w-100"
                  style={{ height: "45px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="updatedAt"
                label={t("update")}
                className="custom-ant-picker"
              >
                <CustomDatePicker
                  locale={i18n.language === "en" ? en : th}
                  format={i18n.language === "en" ? "DD-MM-YYYY" : "DD-MM-BBBB"}
                  disabled
                  className="w-100"
                  style={{ height: "45px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="createdBy"
                label={t("created-by")}
                className="custom-input"
              >
                <Input disabled placeholder={t("created-by")} />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
    );
  };

  return (
    <div className="bg-white color-black pb-1">
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        validateMessages={VALIDATE_MESSAGES(t)}
      >
        <PageHeaderEdit
          label="menu.setting-repairing-category"
          isLoading={create.isLoading || update.isLoading}
        />
        <FormInput />
      </Form>
    </div>
  );
};

export default RepairingCategoryEdit;
