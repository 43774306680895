import { IRoute } from "../../layout/content";
import AdminPage from "../views/admin/admin-index";
import Parcel from "../views/parcel/parcel-index";
import ListParcel from "../views/parcel/parcel-list-scan";
import RepairmanEdit from "../views/repairman/repairman-edit";
import RepairmanPage from "../views/repairman/repairman-index";
import RepairmanView from "../views/repairman/repairman-view";
import UserParcel from "../views/user/user-index";
import {
  PARCEL_LIST_PATH,
  SCAN_QRCODE_PATH,
  USER_PATH,
  REPAIRMAN_PATH,
  ADMIN_PATH,
} from "./path";

const parcels: IRoute[] = [
  {
    name: "scan-qrcode",
    path: SCAN_QRCODE_PATH,
    component: Parcel,
    exact: true,
  },
  {
    name: "list-parcel",
    path: PARCEL_LIST_PATH,
    component: ListParcel,
    exact: true,
  },
];

const profile: IRoute[] = [
  { name: "user-mobile", path: USER_PATH, component: UserParcel },
];

const repairing: IRoute[] = [
  {
    name: "repairman-index",
    path: REPAIRMAN_PATH.index,
    component: RepairmanPage,
    exact: true,
  },
  {
    name: "repairman-view",
    path: REPAIRMAN_PATH.view,
    component: RepairmanView,
    // exact: true,
  },
  {
    name: "repairman-edit",
    path: REPAIRMAN_PATH.edit,
    component: RepairmanEdit,
    // exact: true,
  },
];

const menu: IRoute[] = [
  { name: "admin-mobile", path: ADMIN_PATH, component: AdminPage },
];

export const MOBILE_SUPER_ADMIN_ROUTE: IRoute[] = [
  ...profile,
  ...repairing,
  ...parcels,
  ...menu,
];
export const MOBILE_JURISTIC_ROUTE: IRoute[] = [...profile, ...parcels];
export const MOBILE_REPAIR_MAN_ROUTE: IRoute[] = [...profile, ...repairing];