import { Col, Form, FormInstance, Input, InputNumber, Row, Select } from "antd";
import { t } from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import UploadImage from "../../../components/custom-upload-image";
import PageHeaderEdit from "../../../components/page-header-create";
import { validateMessages } from "../../../config/validateMessage";
import { FOOD_LIST_PATH } from "../../../router/path";
import {
  GET_FOOD_BY_ID,
  PATCH_FOOD,
  POST_FOOD,
} from "../../../store/food-list";
import { swalError, swalSuccess } from "../../../tools/swal";
import InputArray, {
  IInputArray,
} from "../../../components/custom-input-array";
import {
  BULK_DELETE_FOOD_OPTIONAL_LIST,
  // DELETE_FOOD_OPTIONAL_LIST,
  IFoodOptional,
  PATCH_FOOD_OPTIONAL_LIST,
  POST_FOOD_OPTIONAL_LIST,
} from "../../../store/food-optional-list";

const FoodListEditPage = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();

  const post_food = POST_FOOD();
  const patch_food = PATCH_FOOD();
  const { data } = GET_FOOD_BY_ID(id);

  const post_optional = POST_FOOD_OPTIONAL_LIST();
  const patch_optional = PATCH_FOOD_OPTIONAL_LIST();
  // const delete_optional = DELETE_FOOD_OPTIONAL_LIST();
  const bulk_delete_optional = BULK_DELETE_FOOD_OPTIONAL_LIST();

  const client = useQueryClient();

  useEffect(() => {
    initial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data]);

  const initial = () => {
    if (!data) return;
    form.setFieldsValue(data);
  };

  const metate = {
    onSuccess: () => {
      swalSuccess();
      history.replace(FOOD_LIST_PATH.index);
      client.invalidateQueries("get-food-optional-list");
      client.invalidateQueries("get-foods");
    },
    onError: ({ message }: any) => {
      swalError(t, message);
    },
  };

  const onDeleteOptional = () => {
    //   //delete optional
    //   const value = form.getFieldValue("foodOptionalLists");
    //   console.log(data?.foodOptionalLists, value);
    //   const fill = data?.foodOptionalLists.filter(
    //     (item: IFoodOptional) =>
    //       !value.some((e: IFoodOptional) => e.id === item.id)
    //   );
    //   console.log(fill);
  };

  const onFinish = (values: any) => {
    const { foodOptionalLists } = values;
    const foodOptional = foodOptionalLists;
    // optional
    if (foodOptional && foodOptional.length > 0) {
      if (id) {
        const fill = data?.foodOptionalLists.filter(
          (item: IFoodOptional) =>
            !foodOptional.some((e: IFoodOptional) => e.id === item.id)
        );
        delete values.foodOptionalLists;
        patch_food.mutate(
          { id, ...values },
          {
            onSuccess: (data) => {
              client.invalidateQueries("get-foods");
              if (fill !== undefined && fill.length > 0) {
                const id = fill.map((e: any) => e.id);
                bulk_delete_optional.mutate(
                  { ids: id },
                  {
                    onSuccess: () => {
                      client.invalidateQueries("get-food-optional-list");
                      client.invalidateQueries("get-foods");
                    },
                    onError: ({ message }: any) => {
                      swalError(t, message);
                    },
                  }
                );
              }
              return foodOptional.map((item: IFoodOptional) => {
                const items = {
                  ...item,
                  foodService: Number(id),
                  price: Number(item.price),
                };

                if (item.id) {
                  patch_optional.mutate(
                    {
                      ...items,
                      id: item.id,
                    },
                    metate
                  );
                }

                post_optional.mutate({ ...items }, metate);

                return true;
              });
            },
            onError: ({ message }: any) => {
              console.log("message :: ", message);
              swalError(t, message);
            },
          }
        );
        return true;
      }

      post_food.mutate(values, {
        onSuccess: ({ data }: any) => {
          const id = data.id;
          client.invalidateQueries("get-foods");
          return foodOptionalLists.map((item: IFoodOptional) => {
            post_optional.mutate(
              { ...item, foodService: id, price: Number(item.price) },
              metate
            );
            return true;
          });
        },
        onError: ({ message }: any) => {
          swalError(t, message);
        },
      });
    } else {
      //no optional
      if (id) {
        return patch_food.mutate(
          { id, ...values },
          {
            onSuccess: () => {
              swalSuccess();
              history.replace(FOOD_LIST_PATH.index);
              client.invalidateQueries("get-foods");
            },
            onError: ({ message }: any) => {
              swalError(t, message);
            },
          }
        );
      }
      return post_food.mutate(values, {
        onSuccess: () => {
          swalSuccess();
          history.replace(FOOD_LIST_PATH.index);
          client.invalidateQueries("get-foods");
        },
        onError: ({ message }: any) => {
          swalError(t, message);
        },
      });
    }
  };

  return (
    <div className="bg-white color-black pb-1">
      <Form
        onFinish={onFinish}
        layout="vertical"
        form={form}
        validateMessages={validateMessages}
        className="main-input-form"
      >
        <PageHeaderEdit
          label="menu.food-list"
          isLoading={post_optional.isLoading || post_food.isLoading}
        />
        <FormInput form={form} onDeleteOptional={onDeleteOptional} />
      </Form>
    </div>
  );
};

const FormInput = ({
  form,
  onDeleteOptional,
}: {
  form: FormInstance;
  onDeleteOptional: () => void;
}) => {
  const { t } = useTranslation();
  const inputs: IInputArray[] = [
    {
      name: "nameEn",
      label: "nameEn",
      input: "input",
    },
    {
      name: "nameTh",
      label: "nameTh",
      input: "input",
    },
    {
      name: "price",
      label: "price",
      input: "number",
      props: {
        min: 0,
      },
    },
  ];

  return (
    <Row gutter={[24, 24]} className="px-4 mb-4 pb-2">
      <Col span={12}>
        <Form.Item
          name="nameEn"
          label={`${t("nameEn")}`}
          rules={[{ required: true }, { max: 100 }]}
        >
          <Input placeholder={`${t("nameEn")}`} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="nameTh"
          label={`${t("nameTh")}`}
          rules={[{ required: true, max: 100 }]}
        >
          <Input placeholder={`${t("nameTh")}`} />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          name="descriptionEn"
          label={`${t("descriptionEn")}`}
          rules={[{ required: true }, { max: 500 }]}
        >
          <Input.TextArea rows={5} placeholder={`${t("descriptionEn")}`} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="descriptionTh"
          label={`${t("descriptionTh")}`}
          rules={[{ required: true, max: 500 }]}
        >
          <Input.TextArea rows={5} placeholder={`${t("descriptionTh")}`} />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          name="price"
          label={`${t("price")}`}
          rules={[{ required: true }]}
        >
          <InputNumber min={0} placeholder={`${t("price")}`} />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          name="status"
          rules={[{ required: true }]}
          label={`${t("status")}`}
          initialValue={"ACTIVE"}
        >
          <Select
            placeholder={`${t("status")}`}
            getPopupContainer={(e) => e}
            defaultValue="ACTIVE"
          >
            <Select.Option value="ACTIVE">{t("active")}</Select.Option>
            <Select.Option value="INACTIVE">{t("inactive")}</Select.Option>
          </Select>
        </Form.Item>
      </Col>

      <Col span={24}>
        <span className="f-20 font-bold"> {t("extra")}</span>
        <InputArray
          formName="foodOptionalLists"
          form={form}
          inputs={inputs}
          req={false}
          totalPrice={false}
          span={7}
          // onDeleteOptional={onDeleteOptional}
        />
      </Col>

      <Col span={24}>
        <Form.Item
          name="foodMediaObjects"
          label={t("attached-picture")}
          rules={[{ required: true }]}
          className="custom-ant-upload-image"
        >
          <UploadImage />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default FoodListEditPage;
