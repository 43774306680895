import React from "react";

const PAYMENT_LIST_ICON = (props: React.SVGProps<SVGSVGElement> = {}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={56}
      height={56}
      viewBox="0 2 60 56"
      {...props}
    >
      <path
        xmlns="http://www.w3.org/2000/svg"
        id="Vector-2"
        data-name="Vector"
        d="M14.814,0H5.694C1.134,0,0,1.218,0,6.08V19.664c0,3.209,1.761,3.969,3.9,1.677l.012-.012a2.122,2.122,0,0,1,3.354.181l1.218,1.629a2.1,2.1,0,0,0,3.535,0l1.218-1.629a2.12,2.12,0,0,1,3.366-.181c2.147,2.292,3.9,1.532,3.9-1.677V6.08C20.508,1.218,19.374,0,14.814,0ZM13.68,9.639l-.6.615h-.012L9.41,13.909a1.417,1.417,0,0,1-.712.35L7.069,14.5a.769.769,0,0,1-.917-.917l.229-1.641a1.58,1.58,0,0,1,.35-.712L10.4,7.576l.6-.615a1.966,1.966,0,0,1,1.327-.688,1.94,1.94,0,0,1,1.351.688A1.647,1.647,0,0,1,13.68,9.639Z"
        transform="translate(4.222 2.413)"
        fill="#54544d"
      />
    </svg>
  );
};

export default PAYMENT_LIST_ICON;
