import { Button, Col, DatePicker, Form, Modal, Row, Select } from "antd";
import dayjs from "dayjs";
import { FormInstance } from "rc-field-form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EXCEL_ICON from "../../assets/icons/page-header/excel";
import { EXPORT_DASHBOARD } from "../../store/dashboard";
import { downloadFile } from "../../tools/files";
import { swalError } from "../../tools/swal";
import { fix_height } from "../news/news-edit";
import FacilitySection from "./facility";
import FoodSection from "./food";
import HomeCareSection from "./home-care";
import LaundrySection from "./laundry";
import NewsSection from "./news";
import ParcelSection from "./parcel";
import PaymentSection from "./payment";
import PrivilegeSection from "./privilege";
import ServiceSection from "./service";
import UserSection from "./user";

const menus = [
  "news",
  "privilege",
  "homecare",
  "payment",
  "parcel",
  "setting-facility",
  "service",
  "laundry",
  "food",
  "user",
];

const DashBoard = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    onReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAgree = () => {
    const fields = form.getFieldValue("currentTag");
    form.setFieldsValue({ hidden: fields });
  };

  const onReset = () => {
    form.setFieldsValue({ currentTag: menus, hidden: menus });
  };

  return (
    <Form form={form}>
      <Form.Item hidden noStyle name="hidden">
        <Select />
      </Form.Item>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Row className="bg-white p-4">
            <Col span={16} className="d-flex align-center px-1">
              <p className="f-16 font-bold">{t("arrangement-of-topic")}</p>
            </Col>
            <Col span={8} className="d-flex justify-end px-1 py-1">
              <Button
                onClick={() => {
                  setVisible(true);
                }}
                style={{
                  width: 45,
                  height: 45,
                  padding: 12,
                }}
                className="excel-icon"
                icon={EXCEL_ICON()}
              />
              <Modal
                title={null}
                visible={visible}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
                footer={null}
                centered={true}
                maskClosable={false}
              >
                <ExportExcel onClose={() => setVisible(false)} />
              </Modal>
            </Col>
            <Col span={16} className="px-1">
              <Form.Item
                name="currentTag"
                className="w-100 mr-3 custom-select-ant"
              >
                <Select
                  mode="multiple"
                  className="w-100"
                  placeholder={t("arrangement-of-topic")}
                >
                  {menus.map((item, index) => {
                    return (
                      <Select.Option key={index} value={item}>
                        {t(`menu.${item}`)}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4} className="px-1">
              <Button onClick={onReset} className="button-cancel w-100 h-100">
                {t("cancel")}
              </Button>
            </Col>
            <Col span={4} className="px-1">
              <Button onClick={onAgree} className="button-save w-100 h-100">
                {t("agree")}
              </Button>
            </Col>
          </Row>
        </Col>
        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => prev.hidden !== next.hidden}
        >
          {(form: FormInstance) => {
            const currentTag: string[] = form.getFieldValue("currentTag");
            if (currentTag && currentTag.includes("news")) {
              return (
                <Col span={24}>
                  <NewsSection />
                </Col>
              );
            }
          }}
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => prev.hidden !== next.hidden}
        >
          {(form: FormInstance) => {
            const currentTag: string[] = form.getFieldValue("currentTag");
            if (currentTag && currentTag.includes("privilege")) {
              return (
                <Col span={24}>
                  <PrivilegeSection />
                </Col>
              );
            }
          }}
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => prev.hidden !== next.hidden}
        >
          {(form: FormInstance) => {
            const currentTag: string[] = form.getFieldValue("currentTag");
            if (currentTag && currentTag.includes("homecare")) {
              return (
                <Col span={24}>
                  <HomeCareSection />
                </Col>
              );
            }
          }}
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => prev.hidden !== next.hidden}
        >
          {(form: FormInstance) => {
            const currentTag: string[] = form.getFieldValue("currentTag");
            if (currentTag && currentTag.includes("payment")) {
              return (
                <Col span={24}>
                  <PaymentSection />
                </Col>
              );
            }
          }}
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => prev.hidden !== next.hidden}
        >
          {(form: FormInstance) => {
            const currentTag: string[] = form.getFieldValue("currentTag");
            if (currentTag && currentTag.includes("parcel")) {
              return (
                <Col span={24}>
                  <ParcelSection />
                </Col>
              );
            }
          }}
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => prev.hidden !== next.hidden}
        >
          {(form: FormInstance) => {
            const currentTag: string[] = form.getFieldValue("currentTag");
            if (currentTag && currentTag.includes("setting-facility")) {
              return (
                <Col span={24}>
                  <FacilitySection />
                </Col>
              );
            }
          }}
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => prev.hidden !== next.hidden}
        >
          {(form: FormInstance) => {
            const currentTag: string[] = form.getFieldValue("currentTag");
            if (currentTag && currentTag.includes("service")) {
              return (
                <Col span={24}>
                  <ServiceSection />
                </Col>
              );
            }
          }}
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => prev.hidden !== next.hidden}
        >
          {(form: FormInstance) => {
            const currentTag: string[] = form.getFieldValue("currentTag");
            if (currentTag && currentTag.includes("laundry")) {
              return (
                <Col span={24}>
                  <LaundrySection />
                </Col>
              );
            }
          }}
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => prev.hidden !== next.hidden}
        >
          {(form: FormInstance) => {
            const currentTag: string[] = form.getFieldValue("currentTag");
            if (currentTag && currentTag.includes("food")) {
              return (
                <Col span={24}>
                  <FoodSection />
                </Col>
              );
            }
          }}
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => prev.hidden !== next.hidden}
        >
          {(form: FormInstance) => {
            const currentTag: string[] = form.getFieldValue("currentTag");
            if (currentTag && currentTag.includes("user")) {
              return (
                <Col span={24}>
                  <UserSection />
                </Col>
              );
            }
          }}
        </Form.Item>
      </Row>
    </Form>
  );
};

const ExportExcel = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const [formDate] = Form.useForm();
  const export_dashboard = EXPORT_DASHBOARD();

  const onFinish = (value: any) => {
    const { date } = value;
    const startDate = dayjs(date[0]).format("YYYY-MM-DD");
    const endDate = dayjs(date[1]).format("YYYY-MM-DD");
    const useDate = value ? true : undefined;

    export_dashboard.mutate(
      { startDate, endDate, useDate },
      {
        onSuccess: (data: any) => {
          downloadFile(
            data,
            "xlsx",
            `Dashboard (${dayjs().format("DD-MM-YYYY")})`
          );
        },
        onError: (error: any) => {
          swalError(t, t("swal.please-select-an-information"));
        },
      }
    );
  };

  return (
    <Form
      form={formDate}
      onFinish={onFinish}
      className="w-100 custom-ant-picker"
    >
      <Row justify="space-between" className="mt-4 mb-4">
        <Col className="d-flex flex-column justify-center f-18">
          {t("export-excel")}
        </Col>

        <Col>
          <Row gutter={[12, 12]}>
            <Col>
              <Button
                style={{ height: 45, width: 120 }}
                className="button-cancel-setup"
                onClick={onClose}
              >
                {t("cancel")}
              </Button>
            </Col>
            <Col>
              <Button
                style={{ height: 45, width: 120 }}
                htmlType="submit"
                className="button-submit-setup"
                loading={export_dashboard.isLoading}
              >
                {t("export")}
              </Button>
            </Col>
          </Row>
        </Col>

        <Col span={24} className="pt-4">
          <Form.Item className="w-100" name="date">
            <DatePicker.RangePicker
              getPopupContainer={(props: HTMLElement) => {
                return props;
              }}
              className="w-100"
              style={{ height: `${fix_height}` }}
              format="YYYY-MM-DD"
              showTime={false}
              placeholder={[t("start-date"), t("end-date")]}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default DashBoard;
