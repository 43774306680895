import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, notification, Result } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { TO_LOGIN_PAGE } from "../store/auth";

const NotAuthorized = () => {
    const {t} = useTranslation()
  const _logout = async () => {
    TO_LOGIN_PAGE();
  };
  const openNotificationWithIcon = () => {
    const btn = (
      <Button className="button-log-out" size="small" onClick={_logout}>
        {t("log-out")}
      </Button>
    );
    notification.warning({
      message: t("log-out"),
      description: t("swal.press-confirm-to-sign-out"),
      btn,
      duration: null,
      icon: <ExclamationCircleFilled style={{ color: "#deb961" }} />,
    });
  };
  return (
    <div
      className="d-flex align-center justify-center color-gold h-100"
      style={{ fontSize: 40 }}
    >
      <Result
      className="result"
        status="403"
        title={t("page-error.sorry")}
        subTitle={t("page-error.dont-have-access")}
        extra={
          <Button className="button-log-out" onClick={openNotificationWithIcon}>
            {t("log-out")}
          </Button>
        }
      />
    </div>
  );
};

export default NotAuthorized;
