import { Dayjs } from "dayjs";
import { PickerTimeProps } from "antd/es/date-picker/generatePicker";
import { forwardRef } from "react";
import CustomDatePicker from "./custom-date-picker";
import th from "antd/es/date-picker/locale/th_TH";
import en from "antd/es/date-picker/locale/en_US";
import { useTranslation } from "react-i18next";

export interface TimePickerProps
  extends Omit<PickerTimeProps<Dayjs>, "picker"> {}

const CustomTimePicker = forwardRef<any, TimePickerProps>((props, ref) => {
  const { i18n } = useTranslation();
  return (
    <CustomDatePicker
      {...props}
      picker="time"
      mode={undefined}
      ref={ref}
      locale={i18n.language === "en" ? en : th}
      getPopupContainer={(value) => {
        return value;
      }}
    />
  );
});

CustomTimePicker.displayName = "TimePicker";

export default CustomTimePicker;
