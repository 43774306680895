import { ColumnsType } from "antd/lib/table";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import CustomStatusBackground from "../../../components/custom-status";
import CustomTable from "../../../components/custom-table";
import PageFilter, { IPageFilterChoice } from "../../../components/page-filter";
import PageHeader, { ISearchInput } from "../../../components/page-header";
import { SERVICE_ORDER_PATH } from "../../../router/path";
import { useNotification } from "../../../store/notification";
import {
  BULK_DELETE_SERVICE_REQUEST,
  DELETE_SERVICE_REQUEST,
  EXPORT_SERVICE_ORDER,
  GET_SERVICE_REQUEST,
  IServiceRequest,
  IServiceRequestParams,
  LINE_NOTIFICATION_SERVICE_ORDER,
} from "../../../store/service-request";
import { parse, findStartOrEndTime } from "../../../tools/datetime";
import { swalDelete, swalSuccess, swalError } from "../../../tools/swal";
import { getKeyByLocale } from "../../../tools/translation";

const ServiceOrderPage = () => {
  const { t, i18n } = useTranslation();
  const [params, setParams] = useState<IServiceRequestParams>({
    page: 1,
    limit: 10,
  });

  const { data, isLoading, refetch } = GET_SERVICE_REQUEST(params);
  const delete_request = DELETE_SERVICE_REQUEST();
  const bulk_delete_request = BULK_DELETE_SERVICE_REQUEST();

  const client = useQueryClient();
  const exportExcel = EXPORT_SERVICE_ORDER();
  const lineNotification = LINE_NOTIFICATION_SERVICE_ORDER();

  const { tableNoti, dispatch } = useNotification();

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableNoti]);

  useEffect(() => {
    return () =>
      dispatch!({
        type: "table",
        action: "clear",
        key: ["service", "service-order"],
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchInput: ISearchInput[] = [
    {
      label: t("search"),
      key: "search",
      span: 10,
      type: "input",
      props: {
        placeholder: `${t("search-name")} / ${t("search-service-name")} / ${t(
          "house-number"
        )} / ${t("order-number")}`,
      },
    },
    {
      label: t("creation-date"),
      key: "date",
      span: 7,
      type: "range-picker",
      props: {
        placeholder: [t("start-date"), t("end-date")],
        getPopupContainer: (props: HTMLElement) => {
          return props;
        },
      },
    },
  ];

  const columns: ColumnsType<IServiceRequest> = [
    {
      dataIndex: "serviceCode",
      title: t("order-number"),
      render: (_, record) => (
        <span>{record.serviceCode ? record.serviceCode : "-"}</span>
      ),
    },
    {
      title: t("house-number"),
      render: (_, record) => <span>{record?.unit?.unitNumber || "-"}</span>,
    },
    {
      title: t("menu.service-list"),
      render: (_, record) => {
        return (
          <span className="line-clamp">
            {record.serviceItem
              ? getKeyByLocale({
                  key: "name",
                  data: record.serviceItem,
                  locale: i18n.language,
                })
              : "-"}
          </span>
        );
      },
    },
    {
      title: t("desired-date-time"),
      render: (_, record) => {
        return (
          <span>
            {record.bookingDate
              ? parse(
                  record.bookingDate,
                  i18n.language,
                  `${
                    i18n.language === "en"
                      ? "DD MMM YYYY / HH:mm a"
                      : "DD MMM BBBB / HH:mm a"
                  }`
                )
              : "-"}
          </span>
        );
      },
    },
    {
      title: `${t("price")} (${t("baht")})`,
      align: "center",
      render: (_, record) => {
        return (
          <span>
            {record.totalPrice
              ? numeral(record.totalPrice).format("0,0.00")
              : "-"}
          </span>
        );
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      render: (_, record) => {
        let className = "frame-background-pending";
        if (record.status === "SUCCESS") {
          className = "frame-background-complete ";
        }
        if (record.status === "CANCEL") {
          className = "frame-background-cancel";
        }
        // if (record.status === "PAID") {
        //   className = "frame-background-paid";
        // }

        return (
          <CustomStatusBackground
            lable={
              record?.status
                ? record?.status === "SUCCESS"
                  ? t("complete")
                  : t(record?.status.toLocaleLowerCase())
                : "-"
            }
            className={className}
          />
        );
      },
    },
  ];
  const choices: IPageFilterChoice[] = [
    {
      label: t("all"),
      total: data?.countStatus?.countAll || 0,
    },
    {
      label: t("pending"),
      key: "PENDING",
      total: data?.countStatus?.countPending || 0,
    },
    // {
    //   label: t("paid"),
    //   key: "PAID",
    //   total: data?.countStatus?.countPaid || 0,
    // },
    {
      label: t("complete"),
      key: "SUCCESS",
      total: data?.countStatus?.countSuccess || 0,
    },
    {
      label: t("cancel"),
      key: "CANCEL",
      total: data?.countStatus?.countCancel || 0,
    },
  ];

  const onSearch = (values: any) => {
    if (!values.date) {
      values.date = [];
    }
    const { date } = values;

    const createdAtStartDate = findStartOrEndTime(date[0], "start");
    const createdAtEndDate = findStartOrEndTime(date[1], "end");
    values = { ...values, createdAtStartDate, createdAtEndDate };
    delete values.date;

    setParams({ ...params, ...values });
  };

  const onDelete = async (id: any) => {
    const confirm = await swalDelete();
    if (confirm) {
      delete_request.mutate(
        { id: id },
        {
          onSuccess: () => {
            swalSuccess();
            if (params.page! > 1 && data?.data.length === 1) {
              return setParams({ ...params, page: params.page! - 1 });
            }
            client.invalidateQueries("get-service-request");
          },
          onError: (error: any) => {
            swalError(t, error.message);
          },
        }
      );
    }
  };

  const onFilter = (choice: IPageFilterChoice) => {
    setParams({ ...params, status: choice.key });
  };

  const onBulkDeleteSuccess = (length: number) => {
    if (length === data?.data.length && params.page! > 1) {
      setParams({ ...params, page: params.page! - 1 });
    }
  };

  return (
    <div>
      <PageHeader
        menu="service-order"
        onSearch={onSearch}
        searchInput={searchInput}
        label="plus-btn.request"
        path={SERVICE_ORDER_PATH.create}
        bulkDelete={bulk_delete_request}
        invalidateQueries={["get-service-request"]}
        onBulkDeleteSuccess={onBulkDeleteSuccess}
        exportExcel={exportExcel}
        topicExport="Service Order"
        lineNotification={lineNotification}
      />
      <PageFilter
        choices={choices}
        onClick={onFilter}
        current={params.status}
      />
      <CustomTable
        title={t("request-list")}
        dataSource={data?.data || []}
        isLoadingTable={isLoading}
        columns={columns}
        pagination={{
          limit: params?.limit || 0,
          page: params?.page || 0,
          total: data?.count || 0,
        }}
        onPageChange={(page, limit) => {
          setParams({ ...params, page, limit });
        }}
        onDelete={onDelete}
        rowClassName={(record, index) => {
          if (tableNoti["service"]?.some((e) => e === record.id)) {
            return "table-row-pink";
          }
        }}
      />
    </div>
  );
};

export default ServiceOrderPage;
