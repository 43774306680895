import React from "react";

const FACILITY = (props: React.SVGProps<SVGSVGElement> = {}) => {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141.73 141.73"
      {...props}
    >
      <g data-name="Group 20181">
        <path
          data-name="Path 20608"
          d="M97.44 56.73V16.49h40.26v40.24zm0 53.67h40.26V70.15H97.44zM84 16.49v93.9H3.53v-93.9z"
          fill="#b59d4e"
        />
      </g>
    </svg>
  );
};

export default FACILITY;
