import { Col, Form, FormInstance, InputNumber, Row, Switch } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import CustomDatePicker from "../../../../components/custom-date-picker";
import { CustomInputNumber } from "../../../../components/custom-input-number";
import { IService } from "../../../../store/service";
import th from "antd/es/date-picker/locale/th_TH";
import en from "antd/es/date-picker/locale/en_US";
import { useParams } from "react-router-dom";
import {
  IServiceRequest,
  POST_SERVICE_REQUEST_CALULATE_PRICE,
} from "../../../../store/service-request";
import { IUnit, TUnitType } from "../../../../store/units";
import { swalError } from "../../../../tools/swal";

const roomType: {
  [K in TUnitType]: string;
} = {
  ONE_BEDROOM: "oneBedroomPrice",
  TWO_BEDROOM: "twoBedroomPrice",
  THREE_BEDROOM: "threeBedroomPrice",
  FOUR_BEDROOM: "fourBedroomPrice",
  DUPLEX: "duplexPrice",
  PENTHOUSE: "penthousePrice",
};

const PriceInput = ({
  serviceItems,
  form,
  units,
  setCalculatePrice,
  data,
}: {
  serviceItems: IService[];
  form: FormInstance;
  units: IUnit[];
  setCalculatePrice: React.Dispatch<{
    price?: number;
    priceWithSvg?: number;
    priceWithVat?: number;
    totalPrice?: number;
  }>;
  data: IServiceRequest | undefined;
}) => {
  const { t, i18n } = useTranslation();
  const { id } = useParams<{ id?: string }>();
  const calculatePrice = POST_SERVICE_REQUEST_CALULATE_PRICE();

  const serviceItem = form.getFieldValue("serviceItem");
  const findServiceItem = serviceItems.find(
    (e) => e.id === serviceItem
  ) as IService;

  const st = Number(
    dayjs(`2022-02-09T${findServiceItem?.openTime}:00`).format("HH")
  );
  const et = Number(
    dayjs(`2022-02-09T${findServiceItem?.closeTime}:00`).format("HH")
  );

  const range = (start: any, end: any): number[] => {
    let result: number[] = [];
    if (start > end) {
      for (let i = start; i <= 24; i++) {
        result = [...result, i];
      }
      for (let i = 0; i <= end; i++) {
        result = [...result, i];
      }
    } else {
      for (let i = start; i <= end; i++) {
        result = [...result, i];
      }
    }

    return result;
  };

  const rangeTime = range(st, et);

  const getTime24Hours = (): number[] => {
    let time: number[] = [];

    for (let i = 0; i < 24; i++) {
      const find = rangeTime.find((e) => e === i);
      if (find !== i) {
        time = [...time, i];
      }
    }

    return time;
  };

  const showTime = getTime24Hours();

  const disableTimePicker = () => {
    return {
      disabledHours: () => {
        return showTime;
      },
    };
  };

  const calculate = (values: any) => {
    calculatePrice.mutate(values, {
      onSuccess: (res) => {
        setCalculatePrice(res);
        form.setFieldsValue({
          setPrice: Number(res.totalPrice).toFixed(2),
          priceWithSvg: Number(res.priceWithSvg).toFixed(2),
          priceWithVat: Number(res.priceWithVat).toFixed(2),
        });
      },
      onError: ({ message }: any) => {
        swalError(t, message);
      },
    });
  };

  return (
    <Row className="px-4 mb-4 pb-2" gutter={[24, 24]}>
      <Col span={12}>
        <Form.Item
          shouldUpdate={(prev, next) =>
            prev.serviceItem !== next.serviceItem ||
            prev.price !== next.price ||
            prev.requirePrivate !== next.requirePrivate
          }
        >
          {(form: FormInstance) => {
            const serviceItem = form.getFieldValue("serviceItem");
            const internalPrice = form.getFieldValue("price");
            const find = serviceItems.find(
              (e) => e.id === serviceItem
            ) as IService;
            const requirePrivate = form.getFieldValue("requirePrivate");

            return (
              <Form.Item
                label={t("hours")}
                name="bookingHour"
                className="custom-input-number custom-input"
                rules={[{ required: find?.requirePricePerHour }]}
                getValueFromEvent={(hour) => {
                  if (requirePrivate) {
                    calculate({
                      price:
                        hour *
                          Number(internalPrice || find?.pricePerHour || 0) +
                        Number(find?.extraPrice || 0),
                      serviceItem: find?.id,
                    });
                    return hour;
                  }

                  calculate({
                    price:
                      hour * Number(internalPrice || find?.pricePerHour || 0),
                    serviceItem: find?.id,
                  });
                  return hour;
                }}
              >
                <InputNumber
                  placeholder={t("hours")}
                  disabled={!serviceItem || !!id || !find?.requirePricePerHour}
                  min={1}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          shouldUpdate={(prev, next) =>
            prev.serviceItem !== next.serviceItem ||
            prev.bookingHour !== next.bookingHour
          }
        >
          {(form: FormInstance) => {
            const serviceItem = form.getFieldValue("serviceItem");
            const hour = form.getFieldValue("bookingHour");
            const findService = serviceItems.find(
              (e) => e.id === serviceItem
            ) as IService;
            const requirePrivate = form.getFieldValue("requirePrivate");

            return (
              <Form.Item
                name="price"
                label={t("price") + `(${t("this-input-is-net-price")})`}
                className="custom-input-number custom-input"
                getValueFromEvent={(price) => {
                  if (!id) {
                    if (
                      findService &&
                      !findService.requirePricePerHour &&
                      findService.pricePerHour
                    ) {
                      const unitId = form.getFieldValue("unit");
                      const findUnit = units.find((e) => e.id === unitId);
                      if (findUnit) {
                        const find = findService as any;
                        const priceKey = roomType[findUnit.typeOfBedroom];
                        const roomPrice = find[priceKey];

                        if (requirePrivate) {
                          calculate({
                            serviceItem: findService.id,
                            price:
                              Number(price || roomPrice) +
                              Number(findService.extraPrice || 0),
                          });
                          return price;
                        }
                        calculate({
                          serviceItem: findService.id,
                          price: Number(price || roomPrice),
                        });
                      }
                      return price;
                    }

                    calculate({
                      serviceItem: findService.id,
                      price:
                        Number(price) * Number(hour || 1) ||
                        Number(findService?.pricePerHour || 0) *
                          Number(hour || 1),
                    });
                  } else {
                    const findService = serviceItems.find(
                      (e) => e.id === data?.serviceItem.id
                    ) as IService;

                    if (!price && typeof price !== "number") {
                      setCalculatePrice({
                        totalPrice: Number(data?.totalPrice || 0),
                        priceWithVat: Number(data?.totalPrice || 0),
                      });
                      form.setFieldsValue({
                        setPrice: Number(data?.totalPrice).toFixed(2),
                        priceWithSvg: Number(data?.priceWithSvg).toFixed(2),
                        priceWithVat: Number(data?.priceWithVat).toFixed(2),
                      });
                      return price;
                    }

                    if (requirePrivate) {
                      calculate({
                        serviceItem: findService.id,
                        price:
                          Number(price || 0) * Number(hour || 1) +
                          Number(findService.extraPrice || 0),
                      });
                      return price;
                    }

                    calculate({
                      serviceItem: findService.id,
                      price: Number(price || 0) * Number(hour || 1),
                    });
                  }

                  return price;
                }}
              >
                <CustomInputNumber
                  placeholder={t("price")}
                  formatter={(value: number) =>
                    `${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  step="0.01"
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          shouldUpdate={(prev, next) => prev.serviceItem !== next.serviceItem}
        >
          {(form: FormInstance) => {
            const serviceItemId = form.getFieldValue("serviceItem");
            const find = serviceItems.find((e) => e.id === serviceItemId);
            return (
              <Form.Item
                name="bookingDate"
                label={t("date")}
                rules={[{ required: find?.requireOpenCloseTime }]}
                className="custom-ant-picker"
              >
                <CustomDatePicker
                  disabled={
                    !!id || !serviceItemId || !find?.requireOpenCloseTime
                  }
                  locale={i18n.language === "en" ? en : th}
                  format={
                    i18n.language === "en"
                      ? "DD-MM-YYYY / HH:00 a"
                      : "DD-MM-BBBB / HH:00 a"
                  }
                  allowClear
                  getPopupContainer={(e) => e}
                  showTime={{ format: "HH:00" }}
                  disabledTime={disableTimePicker}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="setPrice"
          label={
            t("service-price") + `(${t("this-input-is-calculated-price")})`
          }
          className="custom-input-number custom-input"
        >
          <CustomInputNumber
            placeholder={t("service-price")}
            readOnly
            loading={calculatePrice.isLoading}
            formatter={(value: number) =>
              `${Number(value).toFixed(2)}`.replace(
                /\B(?=(\d{3})+(?!\d))/g,
                ","
              )
            }
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label={t("surcharge")}
          name="requirePrivate"
          className="custom-ant-switch"
          valuePropName="checked"
        >
          <Switch disabled />
        </Form.Item>
      </Col>
      <Col span={3}></Col>
      <Col span={4} className="d-flex flex-column">
        <Form.Item>{t("surcharge")}</Form.Item>
        <Form.Item>{t("svc")}</Form.Item>
        <Form.Item>{t("vat")}</Form.Item>
      </Col>
      <Col span={1}>
        <Form.Item></Form.Item>
        <Form.Item shouldUpdate={(prev, cur) => prev.svc !== cur.svc}>
          {(_form: FormInstance) => {
            const svc = form.getFieldValue("svc");

            return (
              <span className="color-gold ml-2">
                {Number(svc || 0).toFixed(0)}%
              </span>
            );
          }}
        </Form.Item>
        <Form.Item shouldUpdate={(prev, cur) => prev.vat !== cur.vat}>
          {(_form: FormInstance) => {
            const vat = form.getFieldValue("vat");

            return (
              <span className="color-gold ml-2">
                {Number(vat || 0).toFixed(0)}%
              </span>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={4} className="d-flex flex-column justify-end">
        <Form.Item
          shouldUpdate={(prev, cur) => prev.serviceItem !== cur.serviceItem}
        >
          {(_form: FormInstance) => {
            let surcharge = form.getFieldValue("surcharge");
            const serviceItem = form.getFieldValue("serviceItem");
            const findService = serviceItems.find((e) => e.id === serviceItem);
            const requirePrivate = findService?.requirePrivate;
            if (requirePrivate)
              surcharge = Number(findService?.extraPrice || 0);

            return (
              <div className="text-end">
                <span className="color-gold mr-2">
                  {surcharge
                    ? Number(surcharge || 0)
                        ?.toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : 0}
                </span>
                {t("baht")}
              </div>
            );
          }}
        </Form.Item>
        <Form.Item
          shouldUpdate={(prev, cur) => prev.priceWithSvg !== cur.priceWithSvg}
        >
          {(_form: FormInstance) => {
            const svgPrice = form.getFieldValue("priceWithSvg");

            return (
              <div className="text-end">
                <span className="color-gold mr-2">
                  {svgPrice
                    ? Number(svgPrice || 0)
                        ?.toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : 0}
                </span>
                {t("baht")}
              </div>
            );
          }}
        </Form.Item>
        <Form.Item
          shouldUpdate={(prev, cur) => prev.priceWithVat !== cur.priceWithVat}
        >
          {(_form: FormInstance) => {
            const vatPrice = form.getFieldValue("priceWithVat");

            return (
              <div className="text-end">
                <span className="color-gold mr-2">
                  {vatPrice
                    ? Number(vatPrice || 0)
                        ?.toFixed(2)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    : 0}
                </span>
                {t("baht")}
              </div>
            );
          }}
        </Form.Item>
      </Col>
    </Row>
  );
};

export default PriceInput;
