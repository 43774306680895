import { Avatar } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import CustomStatusBackground from "../../../components/custom-status";
import CustomTable from "../../../components/custom-table";
import PageFilter, { IPageFilterChoice } from "../../../components/page-filter";
import PageHeader, { ISearchInput } from "../../../components/page-header";
import {
  DELETE_MULTI_USER,
  DELETE_USER,
  IUser,
  GET_USERS_ADMIN,
  IUserParams,
  EXPORT_ADMIN,
} from "../../../store/user";
import { USER_MANAGEMENT_ADMIN_PATH } from "../../../router/path";
import { swalDelete, swalError, swalSuccess } from "../../../tools/swal";
import { useQueryClient } from "react-query";
import { useState } from "react";
import { UserOutlined } from "@ant-design/icons";
import NO_PROFILE from "../../../assets/images/no_profile.png";
import { GET_ROLE } from "../../../store/role";

const UserAdminPage = () => {
  const [params, setParams] = useState<IUserParams>({
    limit: 10,
    page: 1,
  });
  const { isLoading, data } = GET_USERS_ADMIN(params);
  const delete_user = DELETE_USER();
  const delete_multi_user = DELETE_MULTI_USER();
  const client = useQueryClient();
  const { t } = useTranslation();
  const exportExcel = EXPORT_ADMIN();
  const roles = GET_ROLE({ pagination: false });

  const choices: IPageFilterChoice[] = [
    {
      label: t("all"),
      total: data?.countData?.countAll || 0,
    },
    {
      label: t("active"),
      key: "active",
      total: data?.countData?.countActive || 0,
    },
    {
      label: t("inactive"),
      key: "inactive",
      total: data?.countData?.countInActive || 0,
    },
  ];

  const columns: ColumnsType<IUser> = [
    {
      dataIndex: "avatar",
      title: t("profile"),
      render: (item: any, rc: any) => (
        <>
          <Avatar
            size="large"
            src={rc?.avatar?.imageUrl || NO_PROFILE}
            style={{ backgroundColor: "#F4F4F4" }}
            icon={<UserOutlined style={{ color: "#D1D1D1" }} />}
          />
        </>
      ),
    },
    {
      dataIndex: "title_th",
      title: `${t("first-name")} - ${t("last-name")}`,
      render: (_, record) => {
        return (
          <span>
            {record?.firstName || "-"} {record.lastName || "-"}
          </span>
        );
      },
    },
    {
      dataIndex: "email",
      title: t("email"),
      render: (item) => {
        return <span>{item || "-"}</span>;
      },
    },
    {
      dataIndex: "phoneNumber",
      title: t("phone-number"),
      render: (item) => {
        return <span>{item || "-"}</span>;
      },
    },
    {
      dataIndex: "reader_count",
      title: t("role"),
      render: (_, record) => {
        return <span>{record?.role?.title || "-"}</span>;
      },
    },
    {
      dataIndex: "isActive",
      title: t("status"),
      width: 150,
      render: (text, record) => {
        return (
          <>
            {text === null && "-"}
            {text === false && (
              <CustomStatusBackground
                lable={t("inactive")}
                className={"frame-no-background"}
              />
            )}
            {text === true && (
              <CustomStatusBackground
                lable={t("active")}
                className={"frame-background"}
              />
            )}
          </>
        );
      },
    },
  ];

  const searchInput: ISearchInput[] = [
    {
      label: t("search"),
      key: "search",
      span: 12,
      type: "input",
      props: {
        placeholder: `${t("search")} ${t("first-name")} - ${t("last-name")} / ${t("email")} / ${t(
          "phone-number"
        )} / ${t("id-card-number")}`,
      },
    },
    {
      label: t("role"),
      key: "role",
      span: 7,
      type: "select",
      props: {
        hasTranslation: true,
        placeholder: t("all"),
        optionValue: {
          values:
            roles.data?.data.map((item) => {
              return { id: item.id, name: item.title, key: item.id };
            }) || [],
          key: "key",
          name: "name",
        },
      },
    },
  ];

  const onSearch = (e: any) => {
    const { search, role } = e;
    const next = { ...params, search, role };
    setParams(next);
  };

  const onFilter = (e: any) => {
    const { key } = e;
    if (key === "active") {
      setParams({ ...params, isActive: true });
    } else if (key === "inactive") {
      setParams({ ...params, isActive: false });
    } else {
      setParams({ ...params, isActive: undefined });
    }
  };

  const onDelete = async (id: any) => {
    const confirm = await swalDelete();
    if (confirm) {
      delete_user.mutate(
        { id: id },
        {
          onSuccess: () => {
            swalSuccess();
            client.invalidateQueries(["get-users-admin"]);
          },
          onError: (error: any) => {
            swalError(t, error.message);
          },
        }
      );
    }
  };

  const _getCurrentFilter = (): string | undefined => {
    const { isActive } = params;
    if (isActive) return "active";
    if (isActive === false) return "inactive";
    return undefined;
  };

  const onReset = () => {
    const next = { ...params, search: undefined, role: undefined };
    setParams(next);
  };

  return (
    <div>
      <PageHeader
        menu="user-management-admin"
        onSearch={onSearch}
        onReset={onReset}
        searchInput={searchInput}
        // hasRoleButton={true}
        label={"create-user"}
        utility={["delete", "export"]}
        path={USER_MANAGEMENT_ADMIN_PATH.create}
        bulkDelete={delete_multi_user}
        invalidateQueries={["get-users"]}
        exportExcel={exportExcel}
        topicExport={t("menu.user-management-admin")}
      />
      <PageFilter
        choices={choices}
        onClick={onFilter}
        current={_getCurrentFilter()}
      />
      <CustomTable
        onDelete={onDelete}
        isLoadingTable={isLoading}
        rowKey="id"
        title={t("user-man")}
        dataSource={data?.data || []}
        columns={columns}
        pagination={{
          limit: params.limit,
          page: params.page,
          total: data?.count || 0,
        }}
        onPageChange={(page, size) => {
          setParams({ ...params, page: page, limit: size || params.limit });
        }}
        noti="user"
      />
    </div>
  );
};

export default UserAdminPage;
