import axios from "axios";
import { throwResponse } from "../config/axios";

export const POST_FILE_OBJECT = async (file: any): Promise<number> => {
  let formData = new FormData();
  formData.append("file", file);
  const res = await axios.post("/api/file-object", formData);
  if (res.status === 201) {
    const { data } = res.data;
    return data.id;
  }
  return throwResponse(res);
};
