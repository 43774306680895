import { ReloadOutlined } from "@ant-design/icons";
import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Image,
  InputNumber,
} from "antd";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import InputArray, { IInputArray } from "../../components/custom-input-array";
import UploadImage from "../../components/custom-upload-image";
import PageHeaderEdit from "../../components/page-header-create";
import { VALIDATE_MESSAGES } from "../../config/validateMessage";
import { IUser } from "../../store";
import { GET_BILL_TYPE } from "../../store/bill";
import {
  GET_GENERATE_QR_CODE_BOT_PAYMENT,
  // GET_GENERATE_QR_CODE_SCB_PAYMENT,
  GET_PAYMENT_ID,
  PATCH_PAYMENT,
  POST_PAYMENT,
} from "../../store/payment";
import { GET_PAYMENT_LISTS } from "../../store/payment-list";
import { GET_UNIT } from "../../store/units";
import { swalError, swalSuccess } from "../../tools/swal";
import { getKeyByLocale } from "../../tools/translation";
import { fix_height } from "../news/news-edit";

const EditPayment = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [form] = useForm();
  const history = useHistory();
  const client = useQueryClient();
  const create = POST_PAYMENT();
  const update = PATCH_PAYMENT();
  const { data } = GET_PAYMENT_ID(id);
  const units = GET_UNIT({ pagination: false, status: "ACTIVE" });
  const payment_list = GET_PAYMENT_LISTS({
    pagination: false,
    status: "ACTIVE",
  });
  const billType = GET_BILL_TYPE({ pagination: false, status: "ACTIVE" });
  const [getMembers, setMembers] = useState<IUser[]>();
  // const generateQrSCB = GET_GENERATE_QR_CODE_SCB_PAYMENT();
  const generateQrBOT = GET_GENERATE_QR_CODE_BOT_PAYMENT();

  const inputs: IInputArray[] = [
    {
      name: "list",
      label: "list",
      input: "select",
      props: {
        hasTranslation: true,
        placeholder: t("list"),
        optionValue: {
          values:
            payment_list.data?.data.map((item) => {
              const name = getKeyByLocale({
                key: "name",
                data: item,
                locale: i18n.language,
              });
              return { id: item.id, name, key: item.id };
            }) || [],
          key: "key",
          name: "name",
        },
      },
    },
    {
      name: "quantity",
      label: "quantity",
      input: "number",
      calculate: true,
      props: {
        min: 0,
      },
    },
    {
      name: "price",
      label: "price",
      input: "number",
      calculate: true,
      props: {
        min: 0,
      },
      demical: true,
    },
  ];

  useEffect(() => {
    if (data?.unit) onSelectUnit(+data?.unit);
    // if (data?.users) onSelectUser(+data?.users);
    form.setFieldsValue(data || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, form, units.data?.data]);

  const onFinish = (value: any, goBack?: boolean) => {
    const reg = /^-?\d*(\.\d*)?$/;
    const newRef = getNewRef();

    if (value.paymentReference)
      if (isNaN(value.paymentReference) && !reg.test(value.paymentReference))
        return swalError(
          t,
          t("payment-reference-number") + ": " + t("validate.number")
        );

    value.previousBalance = Number(value.previousBalance);
    if (id) {
      value.amount = Number(value.amount);
      const dataId = { ...value, ...newRef, id: +id };
      update.mutate(dataId, {
        onError: (err: any) => {
          swalError(t, t("swal.please-complete-the-information"));
        },
        onSuccess: () => {
          swalSuccess(t);
          client.invalidateQueries("get-payments");
          client.invalidateQueries(["get-payment-id", id]);
          generateQrCode(Number(id));
          if (goBack !== false) history.goBack();
        },
      });
    } else {
      value.status = value.status ? value.status : "PENDING";
      create.mutate(value, {
        onError: (err: any) => {
          swalError(t, t("swal.please-complete-the-information"));
        },
        onSuccess: (res: any) => {
          swalSuccess(t);
          generateQrCode(Number(res.id));
          client.invalidateQueries("get-payments");
          history.goBack();
        },
      });
    }
  };

  const generateQrCode = (createId: number) => {
    // generateQrSCB.mutate(createId, {
    //   onError: (err: any) => {
    //     swalError(t, err.message);
    //   },
    //   onSuccess: () => {
    //     swalSuccess(t);
    //   },
    // });
    generateQrBOT.mutate(createId, {
      onError: (err: any) => {
        swalError(t, err.message);
      },
      onSuccess: () => {
        swalSuccess(t);
      },
    });
  };

  const getNewRef = () => {
    const typeId = data?.billType;
    const taxId = billType.data?.data.find(
      (item) => item.id === Number(typeId)
    )?.taxId;
    const unitId = data?.unit;
    const unitReference = units.data?.data.find(
      (item) => item.id === unitId
    )?.unitReference;
    return { taxId, unitReference };
  };

  const onSelectUnit = (id: number) => {
    form.setFieldsValue({ users: undefined });
    const members = units.data?.data
      .find((item) => item.id === id)
      ?.userUnits.map((item: any) => item.user)
      .filter((item: any) => item?.isActive === true);
    setMembers(members);
    const unit = units.data?.data.find((item) => item.id === id);
    form.setFieldsValue({ unitReference: unit?.unitReference });
  };

  const onSelectUser = (id: number) => {
    const member = getMembers?.find((item) => item.id === id);
    const lineName = member?.lineName;
    form.setFieldsValue({ lineName });
  };

  const onSelectBillType = (id: number) => {
    const type = billType.data?.data.find((item) => item.id === id);
    form.setFieldsValue({ taxId: type?.taxId });
  };

  const FormEdit = () => {
    return (
      <Row gutter={[24, 24]} className="px-4 mb-4 pb-2">
        <Col span={12}>
          <Form.Item
            name="unit"
            label={t("house-number")} //unit number to use, house number to show
            rules={[{ required: true }]}
            className="custom-select-ant "
          >
            <Select
              placeholder={`${t("house-number")}`} //unit number to use, house number to show
              onSelect={onSelectUnit}
              showSearch
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              getPopupContainer={(value) => {
                return value;
              }}
              loading={units.isFetching}
            >
              {units.data?.data.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.unitNumber}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="users"
            label={t("name")}
            rules={[{ required: true }]}
            className="custom-select-ant "
          >
            <Select
              placeholder={`${t("name")}`}
              mode="multiple"
              getPopupContainer={(value) => {
                return value;
              }}
              onSelect={onSelectUser}
            >
              {getMembers?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.firstName + " " + item.lastName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="unitReference"
            label={
              t("unit-reference-number") + t("this-information-comes-from-unit")
            }
            className="custom-input"
            rules={[{ required: true }]}
          >
            <Input placeholder={t("unit-reference-number")} disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="paymentReference"
            label={t("payment-reference-number")}
            className="custom-input"
            rules={[{ required: true }]}
          >
            <Input placeholder={t("payment-reference-number")} />
          </Form.Item>
        </Col>
        {/* {id && (
          <Col span={12}>
            <Form.Item
              name="billNumber"
              label={t("invoice") + t("used-for-qr-code-scb")}
              rules={[{ required: true }, { max: 255 }]}
              className="custom-input"
            >
              <Input disabled placeholder={t("invoice")} />
            </Form.Item>
          </Col>
        )} */}
        <Col span={12}>
          <Form.Item
            name="billType"
            label={t("bill-type")}
            rules={[{ required: true }]}
            className="custom-select-ant "
          >
            <Select
              placeholder={t("bill-type")}
              getPopupContainer={(value) => {
                return value;
              }}
              showSearch
              optionFilterProp="children"
              allowClear
              onChange={onSelectBillType}
            >
              {billType.data?.data.map((item) => {
                const name = i18n.language === "en" ? item.nameEn : item.nameTh;
                return (
                  <Select.Option value={item.id} key={item.id}>
                    {name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="taxId"
            label={t("tax-id") + t("this-information-comes-from-bill-type")}
            className="custom-input"
            rules={[{ required: true }]}
          >
            <Input placeholder={t("tax-id")} disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="paymentDueDate"
            label={t("payment-due-date")}
            rules={[{ required: true }]}
            className="custom-ant-picker"
          >
            <DatePicker
              placeholder={`${t("payment-due-date")}`}
              style={{ width: "100%", height: `${fix_height}` }}
              format="DD-MM-YYYY"
            />
          </Form.Item>
        </Col>
        {id && (
          <>
            <Col span={12}>
              <Form.Item
                name="createdAt"
                label={t("billing-date")}
                className="custom-ant-picker"
              >
                <DatePicker
                  disabled
                  placeholder={`${t("billing-date")}`}
                  style={{ width: "100%", height: `${fix_height}` }}
                  format="DD-MM-YYYY"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="paymentDate"
                label={t("payment-date")}
                className="custom-ant-picker"
              >
                <DatePicker
                  disabled
                  placeholder={`${t("payment-date")}`}
                  style={{ width: "100%", height: `${fix_height}` }}
                  format="DD-MM-YYYY HH:mm"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="approveBillDate"
                label={t("approve-bill")}
                className="custom-ant-picker"
              >
                <DatePicker
                  disabled
                  placeholder={`${t("approve-bill")}`}
                  style={{ width: "100%", height: `${fix_height}` }}
                  format="DD-MM-YYYY HH:mm"
                />
              </Form.Item>
            </Col>
          </>
        )}
        <Col span={12}>
          <Form.Item
            name="paymentType"
            label={t("payment-type")}
            className="custom-select-ant "
            rules={[{ required: true }]}
          >
            <Select
              placeholder={t("payment-type")}
              getPopupContainer={(value) => {
                return value;
              }}
            >
              <Select.Option value="QR_CODE_BOT">
                {t("qr-code-bot")}
              </Select.Option>
              {/* <Select.Option value="QR_CODE_SCB">
                {t("qr-code-scb")}
              </Select.Option> */}
              <Select.Option value="CASH">{t("cash")}</Select.Option>
              <Select.Option value="CREDIT_CARD">
                {t("credit-card")}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="status"
            label={t("status")}
            // rules={[{ required: true }]}
            className="custom-select-ant "
          >
            <Select
              disabled={data?.status === "PAID" || data?.status === "CANCEL"}
              placeholder={t("status")}
              getPopupContainer={(value) => {
                return value;
              }}
              defaultValue="PENDING"
            >
              <Select.Option value="PENDING">{t("pending")}</Select.Option>
              <Select.Option value="PAID">{t("complete")}</Select.Option>
              {/* <Select.Option value="NOT_YET_PAID">{t("waiting")}</Select.Option> */}
              {/* <Select.Option value="OVERDUE">{t("overdue")}</Select.Option> */}
              <Select.Option value="CANCEL">{t("cancel")}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        {/* {data && (
          <Form.Item
            shouldUpdate={(prev, next) => prev.paymentType !== next.paymentType}
          >
            {(form: FormInstance) => {
              let isQr = true;
              const paymentType = form.getFieldValue("paymentType");
              if (paymentType?.split("_")[0] !== "QR") isQr = false;

              if (!isQr) return <></>;

              return (
                <Col span={12}>
                  <span style={{ color: "white" }}> - </span>
                </Col>
              );
            }}
          </Form.Item>
        )} */}
        <Col span={12}>
          <Form.Item name="note" label={t("note")} className="custom-input">
            <Input.TextArea rows={6} placeholder={t("note")} />
          </Form.Item>
        </Col>
        {id &&
          (data?.paymentType === "QR_CODE_SCB" ||
            data?.paymentType === "QR_CODE_BOT") && (
            <Col span={12}>
              <Form.Item
                shouldUpdate={(prev, next) =>
                  prev.paymentType !== next.paymentType ||
                  prev.paymentReference !== next.paymentReference
                }
              >
                {(form: FormInstance) => {
                  let generateQr: any = undefined;
                  let showQr = true;
                  const paymentType = form.getFieldValue("paymentType");
                  const qrImage =
                    paymentType === "QR_CODE_SCB" ? "qrImage" : "qrBotImage";
                  // if (paymentType === "QR_CODE_SCB") generateQr = generateQrSCB;
                  if (paymentType === "QR_CODE_BOT") generateQr = generateQrBOT;
                  if (paymentType?.split("_")[0] !== "QR") showQr = false;
                  if (!generateQr) return <></>;

                  const onClick = () => {
                    saveNewRef();
                  };

                  const saveNewRef = () => {
                    const typeId = data.billType;
                    const taxId = billType.data?.data.find(
                      (item) => item.id === Number(typeId)
                    )?.taxId;
                    const unitId = data.unit;
                    const unitReference = units.data?.data.find(
                      (item) => item.id === unitId
                    )?.unitReference;
                    const values = form.getFieldsValue();
                    onFinish({ ...values, taxId, unitReference }, false);
                  };

                  const reloadSCB =
                    paymentType === "QR_CODE_SCB" && !data.qrImage;
                  const reloadBOT = paymentType === "QR_CODE_BOT";

                  return (
                    <Row>
                      <Col>
                        <Form.Item label={t("qr-code")} hidden={!showQr}>
                          <Image
                            src={data[qrImage]?.imageUrl}
                            preview={false}
                            height={228}
                            width={228}
                          />
                        </Form.Item>
                      </Col>
                      {(reloadSCB || reloadBOT) && showQr && (
                        <Col className="d-flex align-center pl-4 color-white">
                          <div
                            style={{
                              marginTop: 41,
                              borderRadius: 50,
                            }}
                            className="p-1 d-flex align-center bg-grey pointer"
                            onClick={onClick}
                          >
                            <ReloadOutlined
                              style={{
                                fontSize: 24,
                                fontWeight: "bold",
                              }}
                            />
                          </div>
                        </Col>
                      )}
                    </Row>
                  );
                }}
              </Form.Item>
            </Col>
          )}
      </Row>
    );
  };

  const FormBringForward = () => {
    return (
      <Row gutter={[24, 24]} className="px-4 mb-4 pt-4 pb-2">
        <Col span={12}>
          <Form.Item
            name="balanceDetails"
            label={t("bring-forward-details")}
            className="custom-input"
          >
            <Input.TextArea rows={6} placeholder={t("bring-forward-details")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="previousBalance"
            label={t("bring-forward")}
            className="custom-input-number"
          >
            <InputNumber
              placeholder={t("bring-forward")}
              step="0.01"
              formatter={(value: any) => {
                return `${Number(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      validateMessages={VALIDATE_MESSAGES(t)}
    >
      <div className="bg-white color-black pb-1">
        <PageHeaderEdit
          label="menu.payment"
          labelTo="menu.payment"
          isLoading={create.isLoading || update.isLoading}
        />
        <FormEdit />
      </div>
      <div className="bg-white color-black mt-4 pb-1">
        <FormBringForward />
      </div>
      <div className="bg-white color-black mt-4 pb-1">
        <InputArray
          formName="billLists"
          form={form}
          inputs={inputs}
          calculateTotalPrice={() => {
            const billLists = form.getFieldValue("billLists");
            const next = billLists.map((item: any) => {
              const totalPrice = item?.price * item?.quantity;
              return { ...item, totalPrice };
            });
            return next;
          }}
        />
      </div>
      {id && (
        <div className="bg-white color-black mt-4 px-4 pb-4 pt-2">
          <Col span={24}>
            <Form.Item
              name="picture"
              label={t("attached-picture")}
              className="custom-ant-upload-image"
            >
              <UploadImage onPage="payment" isReceiptImage />
            </Form.Item>
          </Col>
        </div>
      )}
    </Form>
  );
};

export default EditPayment;
