import React from "react";

const PASSWORD = (props: React.SVGProps<SVGSVGElement> = {}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="32"
      viewBox="0 0 22 32"
    >
      <defs>
        <clipPath id="clip-Artboard_4">
          <rect width="22" height="32" />
        </clipPath>
      </defs>
      <g
        id="Artboard_4"
        data-name="Artboard – 4"
        clipPath="url(#clip-Artboard_4)"
      >
        <path
          id="Path_19926"
          data-name="Path 19926"
          d="M427.388,694.153c.662,0,1.272-.008,1.881,0a2.239,2.239,0,0,1,.67.092,1.09,1.09,0,0,1,.8,1.006,18.991,18.991,0,0,1,.012,1.956,1.223,1.223,0,0,1-1.31,1.2c-.57,0-1.139,0-1.709,0h-.3c-.041.448-.047.873-.125,1.285a1.958,1.958,0,0,1-.751,1.214,1.623,1.623,0,0,1-2.528-.692,3.5,3.5,0,0,1-.294-1.376c-.022-4.259-.013-8.518-.013-12.777v-.382a8.021,8.021,0,0,1-2.513-.9,7.94,7.94,0,0,1-4.139-6.007,7.856,7.856,0,0,1,2.862-7.443,7.752,7.752,0,0,1,6.426-1.795,7.975,7.975,0,0,1,5.9,4.051,7.721,7.721,0,0,1,1.04,3.471,8.014,8.014,0,0,1-1.949,5.88,7.727,7.727,0,0,1-3.522,2.395.535.535,0,0,0-.44.622c.017,2.591.009,5.181.009,7.772Zm-2.236-21.128a4.607,4.607,0,1,0,4.59,4.666A4.6,4.6,0,0,0,425.152,673.025Z"
          transform="translate(-639.035 -459.376) rotate(-22)"
          fill="#a9a9a5"
        />
      </g>
    </svg>
  );
};

export default PASSWORD;
