import { Avatar, Button, Col, Form, Input, Row, Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MobileHeader from "../../../components/page-header-mobile";
import { appendLangToHtmlTag, setLocalLanguage } from "../../../i18n";
import { GET_USER_BY_ID, IUser } from "../../../store";
import { GET_USER_PROFILE, TO_LOGIN_PAGE } from "../../../store/auth";
import DefaultAvatar from "../../../assets/images/no_profile.png";

const UserParcel = () => {
  const { i18n, t } = useTranslation();
  const { id } = GET_USER_PROFILE();
  const { data } = GET_USER_BY_ID(id);

  const [profile, setProfile] = useState<IUser | undefined>();

  useEffect(() => {
    inititalProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const inititalProfile = () => {
    setProfile(data?.data);
  };

  const onLangChange = (e: any) => {
    i18n.changeLanguage(e);
    setLocalLanguage(e);
    appendLangToHtmlTag(e);
  };

  const _logout = async () => {
    TO_LOGIN_PAGE();
  };

  return (
    <div className="w-100 background-mobile h-100">
      <MobileHeader />
      <div className="px-3 w-100 h-100 pt-5-5 pb-4 d-flex flex-column justify-between">
        <Form className="custom-input-mobile w-100" layout="vertical">
          <Col span={24} className="w-100 d-flex justify-center align-center ">
            <Avatar
              size={100}
              src={profile?.avatar?.imageUrl || DefaultAvatar}
              className="mb-2"
            />
          </Col>
          <Col span={24}>
            <Form.Item label={`${t("mobile.name")}`} className="custom-input">
              <Input
                placeholder={`${t("mobile.name")}`}
                value={profile?.fullName}
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={`${t("mobile.email")}`} className="custom-input">
              <Input
                placeholder={`${t("mobile.email")}`}
                value={profile?.email}
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={`${t("mobile.phone-number")}`}
              className="custom-input"
            >
              <Input
                placeholder={`${t("mobile.phone-number")}`}
                value={profile?.phoneNumber}
                disabled
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={`${t("mobile.change-language")}`}
              className="custom-select-ant "
            >
              <Select onChange={onLangChange} value={i18n.language}>
                <Select.Option value="en">{t("mobile.english")}</Select.Option>
                <Select.Option value="th">{t("mobile.thai")}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Form>
        <Row className="mt-2 w-100 " align="middle">
          <Col span={24} className="w-100 d-flex justify-end">
            <Button
              className="button-logout-mobile"
              style={{ width: "100vw", height: "5vh" }}
              onClick={_logout}
            >
              {t("log-out")}
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UserParcel;
