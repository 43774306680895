import { Col, Divider, Form, Input, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import dayjs from "dayjs";
import React, { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CustomDatePicker from "../../../components/custom-date-picker";
import CustomSelect from "../../../components/custom-select";
import PageHeaderEdit from "../../../components/page-header-create";
import { validateMessages } from "../../../config/validateMessage";
import {
  GET_REPAIRING_ZONE,
  GET_USERS_ADMIN,
  GET_USERS_MEMBER,
  IRepairingZone,
} from "../../../store";
import {
  GET_REPAIRING,
  GET_REPAIRING_ID,
  IRepairing,
  LINE_NOTIFICATION_REPAIRING_CHECKING,
  PATCH_REPAIRING,
  POST_REPAIRING_WARRANTY,
} from "../../../store/repairing";
import {
  GET_REPAIRING_CATEGORY,
  IRepairingCategory,
} from "../../../store/repairing-category";
import { GET_UNIT } from "../../../store/units";
import { swalError, swalSuccess } from "../../../tools/swal";
import { repairingStatus, repairingType } from "./repairing-index";
import { useHistory } from "react-router";
import { useQueryClient } from "react-query";
import {
  IRepairingState,
  repairingReducer,
  TRepairingReducer,
} from "./reducer";
import ImageUpload from "../../../components/upload-image";
import SignaturePad from "react-signature-canvas";
import th from "antd/es/date-picker/locale/th_TH";
import en from "antd/es/date-picker/locale/en_US";
import RepairingComment from "./repairing-comment";
import { FormInstance } from "rc-field-form";
import { dataURLtoFile } from "../../../tools/convert";
import AppointmentDate from "./appointment-date";
import Reference from "./reference";
import { IRepairingItem } from "../../../store/repairing-item";
import { CustomInputNumber } from "../../../components/custom-input-number";

const RepairingEdit = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [form] = useForm();
  const history = useHistory();
  const client = useQueryClient();
  const sigPadRepairMan = React.useRef<any>({});
  const sigPadVerification = React.useRef<any>({});

  const units = GET_UNIT({ pagination: false, status: "ACTIVE" });
  const member = GET_USERS_MEMBER({ pagination: false, status: "ACTIVE" });
  const getRepairingZone = GET_REPAIRING_ZONE({
    pagination: false,
  });
  const getRepairingCategory = GET_REPAIRING_CATEGORY({
    pagination: false,
  });
  const repairMan = GET_USERS_ADMIN({
    isActive: true,
    pagination: false,
    limit: 10,
    page: 1,
  });

  const [warrantyDate, setWarrantyDate] = useState<any>({});
  const [unitId, setUnitId] = useState("");

  const getRepairing = GET_REPAIRING({ pagination: false });
  const { data } = GET_REPAIRING_ID(id);
  const update = PATCH_REPAIRING();
  const warranty = POST_REPAIRING_WARRANTY();
  const lineMutation = LINE_NOTIFICATION_REPAIRING_CHECKING();

  const [state, dispatch] = useReducer<TRepairingReducer>(repairingReducer, {
    repairingZone: [],
  });

  useEffect(() => {
    initial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    getRepairingCategory.data,
    units.data,
    getRepairingZone.data,
    repairMan.data,
  ]);

  const initial = () => {
    if (
      !data ||
      !getRepairingZone.data ||
      !getRepairingCategory.data ||
      !units ||
      !repairMan.data
    ) {
      return;
    }
    const {
      repairingCategory,
      repairingItem,
      repairingZone,
      unit,
      user,
      warrantyExpiredDate,
      status,
      repairingType,
      createdAt,
      detail,
      note,
      repairmans,
      repairmanSignImage,
      verificationSignImage,
      beforeImage,
      afterImage,
      finishDate,
      reference,
      price,
    } = data;

    setUnitId(unit?.id?.toString());

    const findRepairMan = repairMan.data?.data.filter(
      (e) => e.role.title === "REPAIRMAN"
    );
    const findUnitUser = units.data?.data.find((e) => e.id === unit?.id);
    const dataUser = member.data?.data.find((e) => e.id === user?.id);
    let userRepairMan: number[] | undefined;
    if (repairmans) {
      const man = repairmans.map((item) => item.user);
      userRepairMan = man
        .filter((item) => findRepairMan.find((e) => e.id === item.id)?.id)
        .map((item) => item.id);
    }

    let findRepairingCategory: IRepairingCategory[] | undefined;
    let findRepairingItem: IRepairingItem[] | undefined;

    let UseRepairingZone: IRepairingZone | undefined;
    let UseRepairingCategory: IRepairingCategory | undefined;
    let UseRepairingItem: IRepairingItem | undefined;

    if (repairingZone) {
      const category = getRepairingZone.data.data.find(
        (e) => e.id === repairingZone.id
      );
      findRepairingCategory = category?.repairingCategories;
      UseRepairingZone = getRepairingZone.data.data.find(
        (e) => e.id === repairingZone?.id
      );
    }

    if (repairingCategory) {
      const item = getRepairingCategory.data.data.find(
        (e) => e.id === repairingCategory.id
      );
      findRepairingItem = item?.repairingItems;
      UseRepairingCategory = findRepairingCategory?.find(
        (e) => e.id === repairingCategory?.id
      );
    }

    if (repairingItem) {
      UseRepairingItem = findRepairingItem?.find(
        (e) => e.id === repairingItem.id
      );
    }

    form.setFieldsValue({
      repairingZone: UseRepairingZone?.id || undefined,
      repairingCategory: UseRepairingCategory?.id || undefined,
      repairingItem: UseRepairingItem?.id || undefined,
      unitNumber: unit?.unitNumber || undefined,
      user: dataUser ? dataUser?.fullName : undefined,
      phoneNumber: dataUser ? dataUser.phoneNumber : undefined,
      email: dataUser ? dataUser.email : undefined,
      lineName: dataUser ? dataUser.lineName : undefined,
      createDate: createdAt ? dayjs(createdAt) : undefined,
      warrantyExpiredDate: warrantyExpiredDate
        ? dayjs(warrantyExpiredDate)
        : undefined,
      repairingType,
      detail,
      status,
      note,
      repairmans: userRepairMan,
      repairmanSignImage: repairmanSignImage
        ? repairmanSignImage.imageUrl
        : undefined,
      verificationSignImage: verificationSignImage
        ? verificationSignImage.imageUrl
        : undefined,
      beforeImage: beforeImage ? beforeImage.imageUrl : undefined,
      afterImage: afterImage ? afterImage.imageUrl : undefined,
      finishDate: finishDate ? dayjs(finishDate) : undefined,
      reference: reference,
      price,
    });

    dispatch({ type: "repair-man", data: findRepairMan });
    dispatch({ type: "unit", data: findUnitUser?.userUnits });
    dispatch({
      type: "repairing-category",
      data: findRepairingCategory,
    });
    dispatch({
      type: "repairing-item",
      data: findRepairingItem,
    });
  };

  const onFinish = async (value: any) => {
    const {
      repairmanSignImage,
      verificationSignImage,
      beforeImage,
      afterImage,
      repairmans,
      repairingZone,
      repairingCategory,
      repairingItem,
      repairingType,
      status,
      startDate,
      endDate,
      finishDate,
      user,
      note,
      price,
      warrantyExpiredDate,
    } = value;

    let verification: File | undefined;
    let repairman: File | undefined;

    if (
      sigPadVerification?.current !== null &&
      typeof sigPadVerification?.current === "object"
    ) {
      if (Object.keys(sigPadVerification?.current).length !== 0) {
        if (
          !sigPadVerification?.current.isEmpty() &&
          sigPadVerification?.current.isEmpty() !== undefined
        ) {
          const canvas = sigPadVerification?.current.getCanvas().toDataURL();
          verification = dataURLtoFile(canvas, "singVerification.png");
        }
      }
    }

    if (
      sigPadRepairMan?.current !== null &&
      typeof sigPadRepairMan?.current === "object"
    ) {
      if (Object.keys(sigPadRepairMan?.current).length !== 0) {
        if (
          !sigPadRepairMan?.current.isEmpty() &&
          sigPadRepairMan?.current.isEmpty() !== undefined
        ) {
          const canvas = sigPadRepairMan?.current.getCanvas().toDataURL();
          repairman = dataURLtoFile(canvas, "singRepairman.png");
        }
      }
    }

    if (repairmanSignImage) {
      if (repairmanSignImage.name) {
        repairman = repairmanSignImage;
      }
    }

    if (verificationSignImage) {
      if (verificationSignImage.name) {
        verification = verificationSignImage;
      }
    }

    const dataValue = {
      repairmanSignImage: repairman,
      verificationSignImage: verification,
      beforeImage: beforeImage,
      afterImage: afterImage,
      repairmans: repairmans,
      repairingZone: repairingZone,
      repairingCategory: repairingCategory,
      repairingItem: repairingItem,
      repairingType: repairingType,
      status: status,
      startDate: startDate,
      endDate: endDate,
      finishDate: finishDate,
      user: typeof user === "number" ? user : undefined,
      note: note,
      warrantyExpiredDate: warrantyExpiredDate,
      isFree: warrantyDate?.isFree,
      isExpired: warrantyDate?.expired,
      price: price,
    };

    update.mutate(
      { id: id, ...dataValue },
      {
        onSuccess: () => {
          client.invalidateQueries("get-repairing");
          client.invalidateQueries(["get-repairing-id", id]);
          swalSuccess(t);
          history.goBack();
        },
        onError: (error: any) => {
          swalError(t, error.message);
        },
      }
    );
  };

  const onValuesChange = (change: any) => {
    const { user, repairingZone, repairingCategory } = change;

    if (user) {
      const findUser = member.data?.data.find((e) => e.id === user);
      form.setFieldsValue({
        email: findUser?.email,
        phoneNumber: findUser?.phoneNumber,
        lineName: findUser?.lineName,
      });
    }

    if (repairingZone) {
      const findRepairingCategory = getRepairingZone.data?.data.find(
        (e) => e.id === repairingZone
      );
      const repairingCategories =
        findRepairingCategory?.repairingCategories.filter(
          (e) => e.status === "ACTIVE"
        );

      dispatch({
        type: "repairing-category",
        data: repairingCategories,
      });
      const repairType =
        findRepairingCategory?.isPrivateArea === false ? "COMMON" : "PRIVATE";

      form.setFieldsValue({
        repairingCategory: undefined,
        repairingItem: undefined,
        repairingType: repairType,
      });
    }

    if (repairingCategory) {
      const findRepairingItem = getRepairingCategory.data?.data.find(
        (e) => e.id === repairingCategory
      );
      const repairingItem = findRepairingItem?.repairingItems.filter(
        (e) => e.status === "ACTIVE"
      );
      dispatch({
        type: "repairing-item",
        data: repairingItem,
      });
      form.setFieldsValue({
        repairingItem: undefined,
      });
      warranty.mutate(
        { repairingCategory: repairingCategory, unit: unitId },
        {
          onSuccess: (data: any) => {
            form.setFieldsValue({
              warrantyExpiredDate: dayjs(data.data.warrantyExpiredDate),
            });
            setWarrantyDate(data.data);
          },
          onError: (error: any) => {
            swalError(t, error.message);
          },
        }
      );
    }
  };

  return (
    <>
      <div className="bg-white color-black pb-1 w-100 mb-4-2">
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          validateMessages={validateMessages}
          className="fixed-input-size"
          onValuesChange={onValuesChange}
        >
          <PageHeaderEdit
            label="menu.homecare-repairing"
            labelTo="menu.homecare-repairing"
            isLoading={update.isLoading}
            utility={data?.status === "CHECKING" ? ["line"] : []}
            lineMutation={lineMutation}
          />
          <FormEdit
            state={state}
            getRepairingZone={getRepairingZone.data?.data || []}
            getRepairing={getRepairing.data?.data || []}
            sigPadRepairMan={sigPadRepairMan}
            sigPadVerification={sigPadVerification}
          />
        </Form>
        <Divider />
        <RepairingComment id={id} i18n={i18n} />
      </div>
      <AppointmentDate id={id} />
      <Reference referenceId={data?.reference} />
    </>
  );
};

const FormEdit = ({
  state,
  getRepairingZone,
  getRepairing,
  sigPadRepairMan,
  sigPadVerification,
}: {
  state: IRepairingState;
  getRepairingZone: IRepairingZone[] | [];
  getRepairing: IRepairing[] | [];
  sigPadRepairMan: React.MutableRefObject<any>;
  sigPadVerification: React.MutableRefObject<any>;
}) => {
  const { i18n, t } = useTranslation();
  const [signRepairMan, setSignRepairMan] = useState<boolean>(false);
  const [signVerification, setSignVerification] = useState<boolean>(false);

  const onClear = (text: string) => {
    if (text === "repairman") {
      sigPadRepairMan.current?.clear();
    } else {
      sigPadVerification.current?.clear();
    }
  };
  return (
    <Row gutter={[24, 24]} className="px-4 mb-4 pb-2">
      <Col span={12}>
        <Form.Item
          name="unitNumber"
          label={t("house-number")} //unit number to use, house number to show
          rules={[{ required: true }]}
          className="custom-select-ant "
        >
          <Input placeholder={t("house-number")} disabled />
          {/*unit number to use, house number to show */}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="user"
          label={t("name")}
          rules={[{ required: true }]}
          className="custom-input"
        >
          <Select
            placeholder={`${t("name")}`}
            allowClear
            showSearch
            optionFilterProp="children"
            getPopupContainer={(value) => {
              return value;
            }}
          >
            {state?.unitUser?.map((item, index) => {
              return (
                <Select.Option key={index} value={item.user.id}>
                  {item.user.fullName}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="email"
          label={
            <div>
              {t("email")}
              <span style={{ color: "#b69e4f" }}>
                ({t("can't-edit-information")})
              </span>
            </div>
          }
          className="custom-input"
        >
          <Input placeholder={t("email")} disabled />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="phoneNumber"
          label={
            <div>
              {t("phone-number")}{" "}
              <span style={{ color: "#b69e4f" }}>
                ({t("can't-edit-information")})
              </span>
            </div>
          }
          className="custom-input"
        >
          <Input placeholder={t("phone-number")} disabled />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="lineName"
          label={
            <div>
              {t("line-name")}{" "}
              <span style={{ color: "#b69e4f" }}>
                ({t("can't-edit-information")})
              </span>
            </div>
          }
          className="custom-input"
        >
          <Input placeholder={t("line-name")} disabled />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="repairingZone"
          label={t("repairing-zone")}
          className="custom-select-ant "
        >
          <CustomSelect
            allowClear={true}
            hasTranslation={true}
            showSearch={true}
            inactive={true}
            placeholder={t("repairing-zone")}
            optionValue={{
              values: getRepairingZone,
              key: "id",
              name: `${i18n.language === "en" ? "nameEn" : "nameTh"}`,
            }}
          />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          name="repairingType"
          label={t("repairing-type")}
          className="custom-select-ant"
        >
          <CustomSelect
            disabled
            hasTranslation={true}
            showSearch={false}
            placeholder={t("repairing-type")}
            optionValue={{
              values: repairingType || [],
              key: "key",
              name: "name",
            }}
          />
        </Form.Item>
      </Col>

      <Col span={12}>
        <Form.Item
          name="repairingCategory"
          label={t("repairing-category")}
          className="custom-select-ant "
        >
          <CustomSelect
            allowClear={true}
            hasTranslation={true}
            showSearch={true}
            inactive={true}
            placeholder={t("repairing-category")}
            optionValue={{
              values: state.repairingCategory || [],
              key: "id",
              name: `${i18n.language === "en" ? "nameEn" : "nameTh"}`,
            }}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="warrantyExpiredDate"
          label={t("warranty-expire-date")}
          className="date-picker-style"
        >
          <CustomDatePicker
            placeholder={t("warranty-expire-date")}
            locale={i18n.language === "en" ? en : th}
            format={i18n.language === "en" ? "DD-MM-YYYY" : "DD-MM-BBBB"}
            disabled
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="repairingItem"
          label={t("repairing-item")}
          className="custom-select-ant "
        >
          <CustomSelect
            allowClear={true}
            hasTranslation={true}
            showSearch={true}
            inactive={true}
            placeholder={t("repairing-item")}
            optionValue={{
              values: state.repairingItem || [],
              key: "id",
              name: `${i18n.language === "en" ? "nameEn" : "nameTh"}`,
            }}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="reference"
          label={t("reference")}
          className="custom-select-ant "
        >
          <Select placeholder={t("reference")} disabled>
            {getRepairing.map((item, index) => {
              return (
                <Select.Option key={index} value={item.reference}>
                  {item.repairingCode}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="price"
          label={t("repairing-price")}
          className="custom-input-number custom-input"
        >
          <CustomInputNumber placeholder={t("repairing-price")} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name="detail" label={t("details")} className="custom-input">
          <Input.TextArea placeholder={t("details")} rows={3} disabled />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="createDate"
          label={t("created-date")}
          className="date-picker-style"
        >
          <CustomDatePicker
            getPopupContainer={(value) => {
              return value;
            }}
            locale={i18n.language === "en" ? en : th}
            format={
              i18n.language === "en"
                ? "DD-MM-YYYY / HH:mm a"
                : "DD-MM-BBBB / HH:mm a"
            }
            disabled
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item shouldUpdate={(prev, next) => prev.status !== next.status}>
          {(form: FormInstance) => {
            const status = form.getFieldValue("status");
            let complete: boolean = true;
            if (status === "CANCEL" || status === "COMPLETE") {
              complete = false;
            }

            return (
              <Form.Item
                name="finishDate"
                label={t("finish-date")}
                className="date-picker-style date-picker-time-style"
              >
                <CustomDatePicker
                  getPopupContainer={(value) => {
                    return value;
                  }}
                  locale={i18n.language === "en" ? en : th}
                  format={
                    i18n.language === "en"
                      ? "DD-MM-YYYY / HH:mm a"
                      : "DD-MM-BBBB / HH:mm a"
                  }
                  disabled={complete}
                  showTime={{ format: "HH:mm" }}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="repairmans"
          label={t("repairmans")}
          className="custom-select-ant"
        >
          <CustomSelect
            allowClear={true}
            size="large "
            mode="multiple"
            hasTranslation={true}
            showSearch={true}
            placeholder={t("repairmans")}
            optionValue={{
              values: state.repairMan || [],
              key: "id",
              name: "fullName",
            }}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="status"
          label={t("repairing-status")}
          className="custom-select-ant "
        >
          <CustomSelect
            allowClear={true}
            hasTranslation={true}
            showSearch={false}
            placeholder={t("repairing-status")}
            optionValue={{
              values: repairingStatus || [],
              key: "key",
              name: "name",
            }}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="beforeImage" label={t("image-before")}>
          <ImageUpload />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="afterImage" label={t("image-after")}>
          <ImageUpload />
        </Form.Item>
      </Col>
      <Col span={12}>
        {
          <Form.Item shouldUpdate={(prev, next) => prev.status !== next.status}>
            {(form: FormInstance) => {
              const status = form.getFieldValue("status");
              if (
                status === "WAITING" ||
                status === "PENDING" ||
                status === "PROCESSING"
              )
                return <></>;

              return (
                <Form.Item
                  className="custom-select-ant "
                  label={
                    <Row className="w-100">
                      {t("repairman-sign")}
                      <>
                        <Col
                          onClick={() => {
                            setSignRepairMan(false);
                            form.setFieldsValue({
                              repairmanSignImage: undefined,
                            });
                          }}
                          className="px-2"
                        >
                          <div
                            className={`button-upload ${
                              !signRepairMan ? "button-upload-active" : ""
                            }`}
                          >
                            <span>{t("upload-picture")}</span>
                          </div>
                        </Col>
                        <Col
                          onClick={() => {
                            setSignRepairMan(true);
                            form.setFieldsValue({
                              repairmanSignImage: undefined,
                            });
                          }}
                          className="pr-2"
                        >
                          <div
                            className={`button-signature ${
                              signRepairMan ? "button-signature-active" : ""
                            }`}
                          >
                            <span>{t("sign")}</span>
                          </div>
                        </Col>
                        {signRepairMan && (
                          <Col
                            onClick={() => {
                              onClear("repairman");
                            }}
                          >
                            <div className="button-clear-web">
                              <span>{t("clear")}</span>
                            </div>
                          </Col>
                        )}
                      </>
                    </Row>
                  }
                >
                  {signRepairMan && (
                    <Form.Item name="repairmanSignImage">
                      <SignaturePad
                        canvasProps={{
                          className: "sigContainer-web",
                        }}
                        ref={sigPadRepairMan}
                      />
                    </Form.Item>
                  )}
                  {!signRepairMan && (
                    <Form.Item name="repairmanSignImage">
                      <ImageUpload />
                    </Form.Item>
                  )}
                </Form.Item>
              );
            }}
          </Form.Item>
        }
      </Col>
      <Col span={12}>
        {
          <Form.Item shouldUpdate={(prev, next) => prev.status !== next.status}>
            {(form: FormInstance) => {
              const status = form.getFieldValue("status");
              if (
                status === "WAITING" ||
                status === "PENDING" ||
                status === "PROCESSING"
              )
                return <></>;
              return (
                <Form.Item
                  shouldUpdate={true}
                  className="custom-select-ant "
                  label={
                    <Row>
                      {t("verification-sign")}
                      <>
                        <Col
                          onClick={() => {
                            setSignVerification(false);
                            form.setFieldsValue({
                              verificationSignImage: undefined,
                            });
                          }}
                          className="px-2"
                        >
                          <div
                            className={`button-upload ${
                              !signVerification ? "button-upload-active" : ""
                            }`}
                          >
                            <span>{t("upload-picture")}</span>
                          </div>
                        </Col>
                        <Col
                          onClick={() => {
                            setSignVerification(true);
                            form.setFieldsValue({
                              verificationSignImage: undefined,
                            });
                          }}
                          className="pr-2"
                        >
                          <div
                            className={`button-signature ${
                              signVerification ? "button-signature-active" : ""
                            }`}
                          >
                            <span>{t("sign")}</span>
                          </div>
                        </Col>
                        {signVerification && (
                          <Col
                            onClick={() => {
                              onClear("verification");
                            }}
                          >
                            <div className="button-clear-web">
                              <span>{t("clear")}</span>
                            </div>
                          </Col>
                        )}
                      </>
                    </Row>
                  }
                >
                  {signVerification && (
                    <>
                      <Form.Item name="verificationSignImage">
                        <SignaturePad
                          canvasProps={{
                            className: "sigContainer-web",
                          }}
                          ref={sigPadVerification}
                        />
                      </Form.Item>
                    </>
                  )}
                  {!signVerification && (
                    <Form.Item name="verificationSignImage">
                      <ImageUpload />
                    </Form.Item>
                  )}
                </Form.Item>
              );
            }}
          </Form.Item>
        }
      </Col>
    </Row>
  );
};

export default RepairingEdit;
