import React from "react";

const UNITS = (props: React.SVGProps<SVGSVGElement> = {}) => {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141.73 141.73"
      {...props}
    >
      <path
        data-name="Path 21845"
        d="M139.7 65.24v-45H91.51v16.88H50.22V20.25H2v45h48.22V48.37H64v56.24h27.52v16.87h48.18v-45H91.52v16.88H77.75v-45h13.77v16.88z"
        fill="#b59d4e"
      />
    </svg>
  );
};

export default UNITS;
