import ApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { Badge, Col, Empty, Row } from "antd";
import { useTranslation } from "react-i18next";
import { HistoryOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
interface IChartData {
  label: string;
  value: number | string;
  room?: number;
}

const DonutChart = ({
  type = "donut",
  data,
  historyRepairing = false,
}: {
  type?: "donut" | "pie";
  data: IChartData[];
  historyRepairing?: boolean;
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const colors = ["#BCBCBC", "#54544D", "#B69E4F"];
  const labels = data.map((e) => e.label);
  const series = data.map((e) => e.value);

  const options: ApexOptions = {
    plotOptions: {
      pie: {
        donut: {
          size: "80%",
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: 0,
    },
    labels: labels,
    legend: {
      show: false,
    },
    colors: colors,
  };

  if (data.length <= 0) {
    return (
      <div className="c-container h-100">
        <Empty />
      </div>
    );
  }

  const onPush = (id?: number) => {
    if (!id) return;
    history.push(`units/${id}/repairing`);
  };

  return (
    <div className="h-100">
      <ApexChart
        options={options}
        series={series}
        type={type}
        width="100%"
        height={220}
      />
      <Row className="mt-3" gutter={[6, 6]}>
        {data.map((item, index) => {
          const color = colors[index];
          return [
            <Col key={`a_${index}`} span={2} className="custom-badge-dot">
              <Badge color={color} />
            </Col>,
            <Col key={`b_${index}`} span={16} className="text-truncate">
              {item.label}
            </Col>,
            <Col key={`c_${index}`} span={5} className="text-end">
              <span className="mr-3">{item.value}</span> {t("times")}
            </Col>,
            <Col key={`d_${index}`} span={1}>
              {historyRepairing && (
                <HistoryOutlined onClick={() => onPush(item.room)} />
              )}
            </Col>,
          ];
        })}
      </Row>
    </div>
  );
};

export default DonutChart;
