import { CameraFilled } from "@ant-design/icons";
import { useRef, useState, useEffect } from "react";
import { fileToBase64, resizeImage } from "../tools/convert";
import { Image } from "antd";
import NO_PROFILE from "../assets/images/no_profile.png";

const ImageProfileUpload = ({
  value,
  onChange,
}: {
  value?: any;
  onChange?: any;
}) => {
  const ref = useRef<HTMLInputElement>(null);
  const support = ["jpeg", "jpg", "png"];
  const [imageUrl, setImageUrl] = useState<undefined | string>(value);

  useEffect(() => {
    initial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const initial = () => {
    if (typeof value === "object") return;
    setImageUrl(value);
  };

  const onPicked = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files?.length > 0) {
      try {
        const image = files[0];
        const extension = image.name.split(".").pop();
        if (!extension || !support.includes(extension)) {
          throw new Error("รองรับไฟล์ประเภท .jpg, .jpeg และ .png เท่านั้น");
        }

        const base64 = await fileToBase64(image);
        if (typeof base64 !== "string") {
          throw new Error("เกิดข้อผิดพลาด");
        }
        const resized = await resizeImage({ file: image });
        setImageUrl(base64);
        onChange(resized);
      } catch (err) {}
    }
  };

  const clearPreviousValue = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.value = "";
  };

  const openPickFileDialog = () => {
    ref.current?.click();
  };

  return (
    <div className="image-profile-upload" onClick={openPickFileDialog}>
      <Image
        src={imageUrl || NO_PROFILE}
        preview={false}
        // fallback={ImageError}
      />
      <div className="addition">
        <CameraFilled style={{ color: "white", fontSize: 20 }} />
      </div>
      <input
        accept=".jpg,.jpeg,.png"
        multiple={false}
        type="file"
        ref={ref}
        style={{ display: "none" }}
        onChange={onPicked}
        onClick={clearPreviousValue}
      />
    </div>
  );
};

export default ImageProfileUpload;
