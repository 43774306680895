import { ColumnsType } from "antd/lib/table";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import CustomStatusBackground from "../../../components/custom-status";
import CustomTable from "../../../components/custom-table";
import PageFilter, { IPageFilterChoice } from "../../../components/page-filter";
import PageHeader, { ISearchInput } from "../../../components/page-header";
import { SERVICE_LIST_PATH } from "../../../router/path";
import {
  BULK_DELETE_SERVICE_LIST,
  DELETE_SERVICE_LIST,
  GET_SERVICE_LIST,
  IService,
  IServiceParams,
} from "../../../store/service";
import { findStartOrEndTime, parse } from "../../../tools/datetime";
import { swalDelete, swalError, swalSuccess } from "../../../tools/swal";
import { getKeyByLocale } from "../../../tools/translation";

const ServiceListPage = () => {
  const { i18n, t } = useTranslation();
  const [params, setParams] = useState<IServiceParams>({ page: 1, limit: 10 });

  const { data } = GET_SERVICE_LIST(params);
  const delete_sevice = DELETE_SERVICE_LIST();
  const bulk_delete_service = BULK_DELETE_SERVICE_LIST();

  const client = useQueryClient();

  const searchInput: ISearchInput[] = [
    {
      label: t("search"),
      key: "search",
      span: 10,
      type: "input",
      props: {
        placeholder: t("search-name-EN-TH"),
      },
    },
    {
      label: t("creation-date"),
      key: "date",
      span: 7,
      type: "range-picker",
      props: {
        placeholder: [t("start-date"), t("end-date")],
        getPopupContainer: (props: HTMLElement) => {
          return props;
        },
      },
    },
  ];

  const choices: IPageFilterChoice[] = [
    {
      label: t("all"),
      total: data?.countData?.countAll || 0,
    },
    {
      label: t("active"),
      key: "ACTIVE",
      total: data?.countData?.countActive || 0,
    },
    {
      label: t("inactive"),
      key: "INACTIVE",
      total: data?.countData?.countInActive || 0,
    },
  ];

  const columns: ColumnsType<IService> = [
    {
      dataIndex: "name",
      title: t("name"),
      width: "30vw",
      render: (text, rc) => (
        <div className="line-clamp">
          {i18n.language === "en"
            ? rc.nameEn
              ? rc.nameEn
              : "-"
            : rc.nameTh
            ? rc.nameTh
            : "-"}
        </div>
      ),
    },
    {
      dataIndex: "order",
      title: t("order"),
      align: "center",
      render: (text) => {
        if (!isNaN(text)) return text;
        return "-";
      },
    },
    {
      dataIndex: "updatedAt",
      title: t("update"),
      render: (text) =>
        text
          ? parse(
              text,
              i18n.language,
              `${i18n.language === "en" ? "DD MMM YYYY" : "DD MMM BBBB"}`
            )
          : "-",
      width: 200,
      align: "center",
    },
    {
      dataIndex: "category",
      title: t("menu.setting-service-category"),
      align: "center",
      width: 250,
      render: (_, record) => {
        return (
          <CustomStatusBackground
            lable={
              record.serviceCategory
                ? getKeyByLocale({
                    data: record.serviceCategory,
                    key: "name",
                    locale: i18n.language,
                  })
                : "-"
            }
            className={"frame-default-background-overflow"}
          />
        );
      },
    },
    {
      dataIndex: "status",
      title: t("status"),
      render: (text) => {
        return (
          <>
            {!text && "-"}
            {text === "ACTIVE" && (
              <CustomStatusBackground
                lable={t("active")}
                className={"frame-background"}
              />
            )}
            {text === "INACTIVE" && (
              <CustomStatusBackground
                lable={t("inactive")}
                className={"frame-no-background"}
              />
            )}
          </>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    if (!values.date) {
      values.date = [];
    }
    const { date } = values;

    const createdAtStartDate = findStartOrEndTime(date[0], "start");
    const createdAtEndDate = findStartOrEndTime(date[1], "end");
    values = { ...values, createdAtStartDate, createdAtEndDate };
    delete values.date;

    setParams({ ...params, ...values });
  };

  const onFilter = (choice: IPageFilterChoice) => {
    setParams({ ...params, status: choice.key });
  };

  const onDelete = async (id: any) => {
    const confirm = await swalDelete(t, "comfirm-delete-service-list");
    if (confirm) {
      delete_sevice.mutate(
        { id: id },
        {
          onSuccess: () => {
            if (params.page! > 1 && data?.data.length === 1) {
              setParams({ ...params, page: params.page! - 1 });
            }

            swalSuccess();
            client.invalidateQueries("get-services");
          },
          onError: (error: any) => {
            swalError(t, t(error.message));
          },
        }
      );
    }
  };

  const onBulkDeleteSuccess = (length: number) => {
    if (length === data?.data.length && params.page! > 1) {
      setParams({ ...params, page: params.page! - 1 });
    }
  };

  return (
    <div>
      <PageHeader
        menu="service-list"
        onSearch={onSearch}
        searchInput={searchInput}
        utility={["delete"]}
        label={"create"}
        path={SERVICE_LIST_PATH.create}
        bulkDelete={bulk_delete_service}
        invalidateQueries={["get-services", "get-services-id"]}
        onBulkDeleteSuccess={onBulkDeleteSuccess}
        textConfirmDelete="comfirm-delete-service-list"
      />
      <PageFilter
        choices={choices}
        onClick={onFilter}
        current={params.status}
      />
      <CustomTable
        title={t("menu.service-list")}
        dataSource={data?.data || []}
        columns={columns}
        pagination={{
          limit: params.limit!,
          page: params.page!,
          total: data?.count || 0,
        }}
        onPageChange={(page, limit) => {
          setParams({ ...params, page, limit });
        }}
        onDelete={onDelete}
        // actions={["edit"]}
      />
    </div>
  );
};

export default ServiceListPage;
