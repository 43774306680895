import { useMutation, UseMutationResult, UseQueryResult } from "react-query";
import { exportFile, throwResponse } from "./../config/axios";
import axios from "axios";
import { useQuery } from "react-query";
import { IUser } from ".";
import { IService } from "./service";
import { IServiceCategory } from "./service-category";
import { IUnit } from "./units";
import { POST_MEDIA_OBJECT } from "./media";
import { getClientTimeZone } from "../tools/datetime";

export interface IServiceRequest {
  id: number;
  createdAt: string;
  bookingDate: string;
  status: "PENDING" | "SUCCESS" | "CANCEL" | "PAID";
  detail?: string;
  serviceCode: string;
  price: string;
  priceWithVat?: string;
  priceWithSvg?: string;
  totalPrice: string;
  unit: IUnit;
  user: IUser;
  svc: number;
  vat: number;
  serviceCategory: IServiceCategory;
  serviceItem: IService;
  qrImage: IQRImage;
  qrBotImage: IQRImage;
  paymentType: string;
  bookingHour: number;
  adminDetail: string;
  paymentDate: string;
  requirePrivate: boolean;
  phoneNumber: string;
  serviceRef: string;
  taxId: string;
  unitReference: string;
  receiptImage: {
    id: number;
    createdAt: string;
    updatedAt: string;
    imageUrl: string;
    filename: string;
    alternativeText: string;
    uid: string;
  }[];
}

export interface IQRImage {
  id: number;
  createdAt: string;
  updatedAt: string;
  imageUrl: string;
  filename: string;
  alternativeText: string;
}

export interface IServiceRequestParams {
  limit?: number;
  page?: number;
  pagination?: boolean;
  status?: "PENDING" | "COMPLETE" | "CANCEL";
}

export interface IResponseService<T> {
  data: T;
  count: number;
  countStatus: any;
  page?: number;
  pageCount?: number;
  limit?: number;
}

export const GET_SERVICE_REQUEST = (
  params?: IServiceRequestParams
): UseQueryResult<IResponseService<IServiceRequest[]>> => {
  return useQuery(["get-service-request", params], async () => {
    const res = await axios.get(`/api/service-request`, { params });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const GET_SERVICE_REQUEST_ID = (
  id?: string | number
): UseQueryResult<IServiceRequest> => {
  return useQuery(["get-service-request-id", id], async () => {
    if (!id) return;
    const res = await axios.get(`/api/service-request/${id}`);
    if (res.status === 200) {
      const { data } = res.data;
      const receiptImage = data.receiptImage
        ? [
            {
              id: data.receiptImage?.id,
              name: data.receiptImage?.filename,
              uid: data.receiptImage?.uid,
              url: data.receiptImage?.imageUrl,
            },
          ]
        : undefined;

      return { ...data, receiptImage };
    }
    throwResponse(res);
  });
};

export const POST_SERVICE_REQUEST = (): UseMutationResult => {
  return useMutation(async (values) => {
    const res = await axios.post(`/api/service-request`, values);
    if (res.status === 201) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

export const PATCH_SERVICE_REQUEST = (): UseMutationResult => {
  return useMutation(async (values: any) => {
    const id = values.id;
    delete values.id;

    const { picture } = values;
    let receiptImage = undefined;
    let imageId = undefined;
    if (picture)
      imageId = await Promise.all(
        picture.map(async (item: any) => {
          if (!item.id) {
            const idImage = await POST_MEDIA_OBJECT(item.originFileObj);
            return idImage;
          }
          return item.id;
        })
      );
    if (imageId) receiptImage = imageId[0];
    delete values.picture;
    values = { ...values, receiptImage };

    const res = await axios.patch(`/api/service-request/${id}`, values);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const DELETE_SERVICE_REQUEST = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/api/service-request/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const BULK_DELETE_SERVICE_REQUEST = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/api/service-request/bulk`, {
      data: data,
    });
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const GET_GENERATE_QR_CODE_SCB_SERVICE_REQUEST =
  (): UseMutationResult => {
    return useMutation(async (serviceRequestId: any) => {
      const res = await axios.get(
        `/api/scb/${serviceRequestId}/service-request/generate-qrcode-qr30`
      );
      if (res.status === 200) {
        return true;
      }
      throwResponse(res);
    });
  };

export const GET_GENERATE_QR_CODE_BOT_SERVICE_REQUEST =
  (): UseMutationResult => {
    return useMutation(async (serviceRequestId: any) => {
      const res = await axios.get(
        `/api/payment/${serviceRequestId}/service-request/generate-qrcode`
      );
      if (res.status === 200) {
        return true;
      }
      throwResponse(res);
    });
  };

export const POST_SERVICE_REQUEST_CALULATE_PRICE = (): UseMutationResult<{
  price: number;
  priceWithSvg: number;
  priceWithVat: number;
  totalPrice: number;
}> => {
  return useMutation(async (values) => {
    const res = await axios.post("/api/service-request/calulate-price", values);
    if (res.status === 201) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

export const EXPORT_SERVICE_ORDER = (): UseMutationResult<Blob> => {
  return useMutation(
    async (data: any) => {
      const timeZone = getClientTimeZone();
      const res = await exportFile.post(
        "/api/export-excel/service-order",
        {...data, timeZone}
      );
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { retry: false }
  );
};

export const LINE_NOTIFICATION_SERVICE_ORDER = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post(
      "/api/line-notification/send-message-service-request",
      data
    );
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};
