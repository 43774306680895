import React from "react";

const PRIVILEGE = (props: React.SVGProps<SVGSVGElement> = {}) => {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141.73 141.73"
      {...props}
    >
      <path
        data-name="Path 21854"
        d="M139.61 74.71L67 2.16H2.13V67l72.55 72.55zM25.21 35.14a9.9 9.9 0 119.9-9.9 9.89 9.89 0 01-9.9 9.9z"
        fill="#b59d4e"
      />
    </svg>
  );
};

export default PRIVILEGE;
