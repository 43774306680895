

const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const getKeyByLocale = ({
  key,
  data,
  locale,
}: {
  key: string;
  data?: any;
  locale?: string;
}): any => {
  if (!locale || !data) return "";
  const upper = capitalizeFirstLetter(locale);
  const localeKey = `${key}${upper}`;
  return data[localeKey];
};
