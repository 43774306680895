import {
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Tooltip,
} from "antd";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import CustomTimePicker from "../../../components/custom-time-picker";
import UploadImage from "../../../components/custom-upload-image";
import PageHeaderEdit from "../../../components/page-header-create";
import { validateMessages } from "../../../config/validateMessage";
import { GET_FACILITY_CATEGORY } from "../../../store";
import {
  GET_FACILITY_BY_ID,
  PATCH_FACILITY,
  POST_FACILITY,
} from "../../../store/facility";
import { swalError, swalSuccess } from "../../../tools/swal";
import { getKeyByLocale } from "../../../tools/translation";
import { useQueryClient } from "react-query";
import dayjs from "dayjs";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import numeral from "numeral";

const FacilityEdit = () => {
  const { id } = useParams<{ id: string }>();

  const get_category = GET_FACILITY_CATEGORY({ pagination: false });
  const get_facility_id = GET_FACILITY_BY_ID(id);
  const post_facility = POST_FACILITY();
  const patch_facility = PATCH_FACILITY();

  const { t, i18n } = useTranslation();
  const [form] = useForm();
  const history = useHistory();

  const client = useQueryClient();

  useEffect(() => {
    initial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, get_facility_id.data]);

  const initial = () => {
    const { data } = get_facility_id;
    if (!data) return;
    const { openTime, closeTime, isAdditionalCost } = data;

    form.setFieldsValue({
      ...data,
      openTime: dayjs(`2022-01-06T${openTime}:00`),
      closeTime: dayjs(`2022-01-06T${closeTime}:00`),
      facilityCategory: data?.facilityCategory?.id,
      isAdditionalCost: isAdditionalCost,
    });
  };

  const onFinish = (values: any) => {
    values = {
      ...values,
      additionalCost: values?.additionalCost
        ? Number(values.additionalCost)
        : 0,
      openTime: dayjs(values.openTime).format("HH:mm:ssZ"),
      closeTime: dayjs(values.closeTime).format("HH:mm:ssZ"),
    };
    if (id) {
      return patch_facility.mutate(
        { ...values, id },
        {
          onSuccess: () => {
            swalSuccess();
            history.replace("/setting/facility");
            client.invalidateQueries("get-facilities");
            client.invalidateQueries("get-facility-by-id");
          },
          onError: ({ message }: any) => {
            swalError(t, message);
          },
        }
      );
    }

    return post_facility.mutate(values, {
      onSuccess: () => {
        swalSuccess();
        history.replace("/setting/facility");
        client.invalidateQueries("get-facilities");
      },
      onError: ({ message }: any) => {
        swalError(t, message);
      },
    });
  };

  const content = (
    <div>
      <p className="color-white">{t("active-information")}</p>
      <p className="color-white">{t("in-active-information")}</p>
      <p className="color-white">{t("draft-information")}</p>
    </div>
  );

  const disableTimePicker = (open: number) => {
    let time: number[] = [];
    for (let i = 0; i <= open; i++) {
      time = [...time, i];
    }
    return time;
  };

  const FormInput = () => {
    return (
      <Row gutter={[24, 24]} className="px-4 mb-4 pb-2">
        <Col span={12}>
          <Form.Item
            name="nameEn"
            label={`${t("nameEn")}`}
            rules={[{ required: true }]}
            className="custom-input"
          >
            <Input placeholder={`${t("nameEn")}`} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="nameTh"
            label={`${t("nameTh")}`}
            rules={[{ required: true }]}
            className="custom-input"
          >
            <Input placeholder={`${t("nameTh")}`} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="descriptionEn"
            label={`${t("descriptionEn")}`}
            rules={[{ required: true }]}
            className="custom-input"
          >
            <Input.TextArea rows={3} placeholder={`${t("descriptionEn")}`} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="descriptionTh"
            label={`${t("descriptionTh")}`}
            rules={[{ required: true }]}
            className="custom-input"
          >
            <Input.TextArea rows={3} placeholder={`${t("descriptionTh")}`} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            name="inactiveNoteEn"
            label={`${t("inactive-note-en")}`}
            rules={[{ required: true }]}
            className="custom-input"
          >
            <Input.TextArea rows={3} placeholder={`${t("inactive-note-en")}`} />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item
            name="inactiveNoteTh"
            label={`${t("inactive-note-th")}`}
            rules={[{ required: true }]}
            className="custom-input"
          >
            <Input.TextArea rows={3} placeholder={`${t("inactive-note-th")}`} />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="facilityCategory"
            label={`${t("category")}`}
            rules={[{ required: true }]}
            className="custom-select-ant"
          >
            <Select placeholder={`${t("category")}`}>
              {(get_category.data?.data || []).map((item, index) => {
                return (
                  <Select.Option
                    key={index}
                    value={item.id}
                    disabled={item.status === "INACTIVE"}
                  >
                    {getKeyByLocale({
                      key: "name",
                      data: item,
                      locale: i18n.language,
                    })}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="type"
            rules={[{ required: true }]}
            label={`${t("reservation-characteristics")}`}
            className="custom-select-ant "
          >
            <Select placeholder={`${t("reservation-characteristics")}`}>
              <Select.Option value="USE_TOGETHER">
                {t("use-together")}
              </Select.Option>
              <Select.Option value="DO_NOT_USE_TOGETHER">
                {t("not-use-together")}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="bookInAdvance"
            rules={[{ required: true }]}
            label={`${t("book-in-advance")} (${t("hours")})`}
            className="custom-input-number"
          >
            <InputNumber
              min={0}
              placeholder={t("book-in-advance")}
              formatter={(value) => `${value}`.replace(".", "")}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="status"
            rules={[{ required: true }]}
            label={
              <div>
                {t("status")}
                <Tooltip
                  color="#b69e4f"
                  title={content}
                  trigger="hover"
                  className="tooltip"
                >
                  <ExclamationCircleOutlined
                    style={{ color: "#b69e4f", paddingLeft: 5 }}
                  />
                </Tooltip>
              </div>
            }
            className="custom-select-ant "
          >
            <Select placeholder={`${t("status")}`}>
              <Select.Option value="ACTIVE">{t("active")}</Select.Option>
              <Select.Option value="INACTIVE">{t("inactive")}</Select.Option>
              <Select.Option value="DRAFT">{t("draft")}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={`${t("open-close")}`}>
            <Row
              gutter={24}
              className="px-2 pt-4 pb-3"
              style={{
                border: "1px solid #e4e9f2",
                marginLeft: "0px",
                marginRight: "0px",
              }}
            >
              <Col span={6}>
                <Form.Item
                  name="openTime"
                  label={`${t("open-time")}`}
                  rules={[{ required: true }]}
                >
                  <CustomTimePicker
                    secondStep={60}
                    minuteStep={60}
                    showNow={false}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prev, curr) => prev.openTime !== curr.openTime}
                >
                  {(form: FormInstance) => {
                    const openTime = form.getFieldValue("openTime");
                    const open = dayjs(openTime).hour();
                    return (
                      <Form.Item
                        name="closeTime"
                        label={`${t("close-time")}`}
                        rules={[{ required: true }]}
                      >
                        <CustomTimePicker
                          secondStep={60}
                          minuteStep={60}
                          showNow={false}
                          disabledHours={() => disableTimePicker(open)}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="maximumBookingHour"
                  label={`${t("maximum-booking-hours")}`}
                  rules={[{ required: true }]}
                  className="custom-input-number"
                >
                  <InputNumber
                    min={1}
                    placeholder={t("maximum-booking-hours")}
                    formatter={(value) => `${value}`.replace(".", "")}
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="capacity"
                  label={`${t("capacity")}`}
                  rules={[{ required: true }]}
                  className="custom-input-number"
                >
                  <InputNumber
                    min={1}
                    placeholder={t("capacity")}
                    formatter={(value) => `${value}`.replace(".", "")}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={t("additional-cost")}
            name="isAdditionalCost"
            className="custom-ant-switch"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            shouldUpdate={(prev, cur) =>
              prev.isAdditionalCost !== cur.isAdditionalCost
            }
          >
            {(_form: FormInstance) => {
              const disabled = form.getFieldValue("isAdditionalCost");

              return (
                <Form.Item
                  name="additionalCost"
                  label={`${t("additional-cost")}`}
                  className="custom-input-number"
                >
                  <InputNumber
                    disabled={!disabled}
                    style={{ width: "100%" }}
                    placeholder={`${t("additional-cost")}`}
                    formatter={(value) => numeral(value).format("0,000")}
                    min={0}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="facilityMediaObjects"
            label={t("attached-picture")}
            className="custom-ant-upload-image"
          >
            <UploadImage facility />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  return (
    <div className="bg-white color-black pb-1">
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <PageHeaderEdit
          label="menu.setting-facility"
          isLoading={post_facility.isLoading || patch_facility.isLoading}
        />
        <FormInput />
      </Form>
    </div>
  );
};

export default FacilityEdit;
