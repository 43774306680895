import axios from "axios";
import dayjs from "dayjs";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { IResponse } from ".";
import { throwResponse } from "../config/axios";
import { IService } from "./service";

export interface IServiceCategory {
  id: number;
  nameTh: string;
  nameEn: string;
  createdAt: string;
  deletedAt: string;
  status: string;
  updatedAt: string;
  createdBy: string;
  serviceItems: IService[];
}

export const GET_SERVICE_CATEGORY = (params?: {}): UseQueryResult<
  IResponse<IServiceCategory[]>
> => {
  return useQuery(
    ["get-service-category", params],
    async () => {
      const res = await axios.get("/api/service-category", {
        params: params,
      });
      if (res.status === 200) {
        const { data, count, page, countData } = res.data;
        const dataSome = data.map((item: any) => {
          const createdBy = item.createdBy?.fullName;
          return { ...item, createdBy: createdBy };
        });
        return { data: dataSome, count, page, countData };
      }
      throwResponse(res);
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const GET_SERVICE_CATEGORY_ID = (
  id: string
): UseQueryResult<IServiceCategory> => {
  return useQuery(["get-service-category-id", id], async () => {
    if (!id) return;
    const res = await axios.get(`/api/service-category/${id}`);
    if (res.status === 200) {
      const { data } = res.data;
      data.createdAt = dayjs(data.createdAt);
      data.updatedAt = dayjs(data.updatedAt);
      data.createdBy = data.createdBy?.fullName;
      return data;
    }
    throwResponse(res);
  });
};

export const POST_SERVICE_CATEGORY = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post("/api/service-category", data);
    if (res.status === 201) {
      return true;
    }
    throwResponse(res);
  });
};

export const PATCH_SERVICE_CATEGORY = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.patch(`/api/service-category/${data.id}`, data);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const DELETE_SERVICE_CATEGORY = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/api/service-category/${data.id}`);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const DELETE_SERVICE_CATEGORY_BULK = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/api/service-category/bulk`, {
      data: data,
    });
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};
