import axios from "axios";
import dayjs from "dayjs";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { IRepairingZone, IResponse } from ".";
import { throwResponse } from "../config/axios";
import { IRepairingCategory } from "./repairing-category";

export interface IRepairingItem {
  id: number;
  nameTh: string;
  nameEn: string;
  createdAt: string;
  deletedAt: string;
  status: string;
  updatedAt: string;
  createdBy: string;
  repairingZone: IRepairingZone;
  repairingCategory: IRepairingCategory;
}

export const GET_REPAIRING_ITEM = (params?: {}): UseQueryResult<
  IResponse<IRepairingItem[]>
> => {
  return useQuery(
    ["repairing-item", params],
    async () => {
      const res = await axios.get("/api/repairing-item", {
        params: params,
      });
      if (res.status === 200) {
        const { data, count, page, countData } = res.data;
        const dataSome = data.map((item: any) => {
          const createdBy = item.createdBy?.fullName;
          return { ...item, createdBy: createdBy };
        });
        return { data: dataSome, count, page, countData };
      }
      throwResponse(res);
    },
    { retry: false, refetchOnWindowFocus: false }
  );
};

export const GET_REPAIRING_ITEM_ID = (
  id: string
): UseQueryResult<IRepairingItem> => {
  return useQuery(["repairing-item-id", id], async () => {
    if (!id) return;
    const res = await axios.get(`/api/repairing-item/${id}`);
    if (res.status === 200) {
      const { data } = res.data;
      data.createdAt = dayjs(data.createdAt);
      data.updatedAt = dayjs(data.updatedAt);
      data.createdBy = data.createdBy?.fullName;
      return data;
    }
    throwResponse(res);
  });
};

export const POST_REPAIRING_ITEM = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post("/api/repairing-item", data);
    if (res.status === 201) {
      return true;
    }
    throwResponse(res);
  });
};

export const PATCH_REPAIRING_ITEM = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.patch(`/api/repairing-item/${data.id}`, data);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const DELETE_REPAIRING_ITEM = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/api/repairing-item/${data.id}`);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const BULK_DELETE_REPAIRING_ITEM = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete("/api/repairing-item/bulk", {
      data: data,
    });
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};
