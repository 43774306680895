import { Col, Form, Input, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import TextEditor from "../../../components/custom-text-editor";
import PageHeaderEdit from "../../../components/page-header-create";
import PreviewContent from "../../../components/preview-content";
import { VALIDATE_MESSAGES } from "../../../config/validateMessage";
import {
  GET_CONTENT_ABOUT_US_ID,
  PATCH_CONTENT_ABOUT_US,
} from "../../../store";
import { swalError, swalSuccess } from "../../../tools/swal";

const ContentAboutUsEdit = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = GET_CONTENT_ABOUT_US_ID(id);
  const update = PATCH_CONTENT_ABOUT_US();
  const history = useHistory();
  const client = useQueryClient();
  const [form] = useForm();
  const { t } = useTranslation();
  const [previewEn, setPreviewEn] = useState(false);
  const [previewTh, setPreviewTh] = useState(false);
  const [previewData, setPreviewData] = useState();

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data, form]);

  const onFinish = (values: any) => {
    const data = { ...values, id: +id };
    update.mutate(data, {
      onError: (err: any) => {
        swalError(t, t("swal.please-complete-the-information"));
      },
      onSuccess: () => {
        client.invalidateQueries("contents-about-us");
        client.invalidateQueries(["content-about-us", id]);
        swalSuccess(t);
        history.goBack();
      },
    });
  };

  const FormInput = () => {
    return (
      <Row gutter={[24, 24]} className="px-4 mb-4 pb-2">
        <Col span={12}>
          <Form.Item
            name="nameEn"
            label={t("nameEn")}
            className="custom-input"
            rules={[{ required: true }, { max: 255 }]}
          >
            <Input placeholder={t("nameEn")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="nameTh"
            label={t("nameTh")}
            className="custom-input"
            rules={[{ required: true }, { max: 255 }]}
          >
            <Input placeholder={t("nameTh")} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <PreviewContent
            values={previewData}
            reach={data?.reach || 0}
            language="en"
            previewEn={previewEn}
            openPreview={() => {
              setPreviewData(form.getFieldsValue());
              setPreviewEn(true);
            }}
            closePreview={() => setPreviewEn(false)}
          />
          <Form.Item
            name="contentEn"
            label={t("contentEn")}
            rules={[{ required: true }]}
          >
            <TextEditor />
          </Form.Item>
        </Col>
        <Col span={24}>
          <PreviewContent
            values={previewData}
            reach={data?.reach || 0}
            language="th"
            previewTh={previewTh}
            openPreview={() => {
              setPreviewData(form.getFieldsValue());
              setPreviewTh(true);
            }}
            closePreview={() => setPreviewTh(false)}
          />
          <Form.Item
            name="contentTh"
            label={t("contentTh")}
            rules={[{ required: true }]}
          >
            <TextEditor />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="status"
            label={t("status")}
            className="custom-select-ant "
          >
            <Select placeholder={t("status")}>
              <Select.Option value="ACTIVE">{t("active")}</Select.Option>
              <Select.Option value="INACTIVE">{t("inactive")}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  return (
    <div className="bg-white color-black pb-1">
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        validateMessages={VALIDATE_MESSAGES(t)}
        className="fixed-input-size"
      >
        <PageHeaderEdit
          label="menu.setting-content-about-us"
          labelTo="menu.setting-content-about-us-edit"
          isLoading={update.isLoading}
        />
        <FormInput />
      </Form>
    </div>
  );
};

export default ContentAboutUsEdit;
