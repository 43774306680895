import { useEffect, useState } from "react";
import { IUser } from "../../../store";
import { GET_UNIT } from "../../../store/units";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { GET_LAUNDRY_LIST, GET_LAUNDRY_TAX } from "../../../store/laundry-list";
import { VALIDATE_MESSAGES } from "../../../config/validateMessage";
import { useHistory, useParams } from "react-router-dom";
import { FormInstance, useForm } from "antd/lib/form/Form";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  InputNumber,
  Image,
  Switch,
} from "antd";

import InputArrayLaundryOrder from "../../../components/custom-input-array-laundry-order";
import PageHeaderEdit from "../../../components/page-header-create";
import {
  GET_GENERATE_QR_CODE_BOT_LAUNDRY_ORDER,
  // GET_GENERATE_QR_CODE_SCB_LAUNDRY_ORDER,
  GET_LAUNDRY_ORDER_ID,
  PATCH_LAUNDRY_ORDER,
  POST_LAUNDRY_ORDER,
} from "../../../store/laundry-order";
import { swalError, swalSuccess } from "../../../tools/swal";
import { LAUNDRY_ORDER_PATH } from "../../../router/path";
import CustomDatePicker from "../../../components/custom-date-picker";
import dayjs from "dayjs";
import UploadImage from "../../../components/custom-upload-image";
import { ReloadOutlined } from "@ant-design/icons";
import { GET_BILL_TYPE } from "../../../store/bill";

export const vatOrSvc = (sub_total: number, tax_percent: number): number => {
  let total = 0;
  total = (sub_total * tax_percent) / 100;
  return total + sub_total;
};

const LaundryOrderEditPage = () => {
  const client = useQueryClient();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const units = GET_UNIT({ pagination: false, status: "ACTIVE" });
  const laundryList = GET_LAUNDRY_LIST({ pagination: false, status: "ACTIVE" });
  const { data } = GET_LAUNDRY_ORDER_ID(id);
  const post_laundry_order = POST_LAUNDRY_ORDER();
  const update_laundry_order = PATCH_LAUNDRY_ORDER();
  const billType = GET_BILL_TYPE({ pagination: false, status: "ACTIVE" });
  // const generateQrSCB = GET_GENERATE_QR_CODE_SCB_LAUNDRY_ORDER();
  const generateQrBOT = GET_GENERATE_QR_CODE_BOT_LAUNDRY_ORDER();
  const tax = GET_LAUNDRY_TAX();

  const [form] = useForm();
  const [getMembers, setMembers] = useState<IUser[]>();

  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (!data) return;
    initial(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, form]);

  useEffect(() => {
    const taxId = billType.data?.data.find(
      (item) => item.slug === "laundry"
    )?.taxId;
    const unitReference = units.data?.data.find(
      (item) => item.id === data?.unit
    )?.unitReference;
    if (!form.getFieldValue("taxId")) {
      if (taxId && !data?.taxId) form.setFieldsValue({ taxId });
    }
    if (!form.getFieldValue("unitReference")) {
      if (unitReference && !data?.unitReference)
        form.setFieldsValue({ unitReference });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billType, data, units]);

  const initial = (data: any) => {
    if (data.unit) onSelectUnit(+data.unit?.id);
    if (data.customer) onSelectUser(+data.customer?.id);

    const laundryOrders = data.laundryOrdersList;
    const totalPrice = laundryOrders
      .map((item: any) => Number(item.totalPrice) || 0)
      .reduce((a: number, b: number) => a + b, 0);

    const complimentary = data.unit?.totalLaundryComplimentary || 0;
    const remaining = data.unit?.laundryComplimentary || 0;
    // const usedComplimentary = data.laundryOrdersList
    //   .map((item: any) => Number(item?.quantityOfFree) || 0)
    //   .reduce((a: number, b: number) => a + b, 0);

    const svgPrice = vatOrSvc(Number(totalPrice), Number(data.svc));
    const vatPrice = vatOrSvc(Number(svgPrice), Number(data.vat));

    const quantityOfFree = laundryOrders
      ?.map((item: any) => item?.quantityOfFree || 0)
      .reduce((a: number, b: number) => a + b, 0);

    const svc = svgPrice - Number(totalPrice);
    const vat = vatPrice - svgPrice;

    form.setFieldsValue({
      ...data,
      unit: +data.unit?.id,
      svgPrice: +svc.toFixed(2),
      vatPrice: +vat.toFixed(2),
      complimentary: complimentary,
      remaining: remaining,
      isComplimentary: quantityOfFree >= 1,
      customer: data?.customer?.fullName || undefined,
    });
  };

  const onFinish = (values: any, goBack?: boolean) => {
    values.laundryOrdersList = values.laundryOrdersList?.map((item: any) => {
      const requireDryCleaning = item.type === "dry-cleaning" ? true : false;
      const requireLaundry = item.type === "laundry" ? true : false;
      const requirePressingOnly = item.type === "pressing-only" ? true : false;
      if (values.isComplimentary) item.quantityOfFree = item.quantity;
      return {
        ...item,
        requireDryCleaning,
        requireLaundry,
        requirePressingOnly,
      };
    });

    values.customer =
      typeof values.customer === "number" ? values.customer : undefined;
    values = { ...values, setOwnPrice: +values.totalPrice };

    const reg = /^-?\d*(\.\d*)?$/;
    const newRef = getNewRef();

    if (values.laundryRef)
      if (isNaN(values.laundryRef) && !reg.test(values.laundryRef))
        return swalError(
          t,
          t("laundry-order-reference-number") + ": " + t("validate.number")
        );

    if (id) {
      const dataId = { ...values, ...newRef, id: +id };
      update_laundry_order.mutate(dataId, {
        onError: (err: any) => {
          swalError(t, t(err));
        },
        onSuccess: () => {
          if (!data?.qrImage || !data?.qrBotImage) generateQrCode(+id, goBack);
          client.invalidateQueries("get-laundry-orders");
          client.invalidateQueries(["get-laundry-order", id]);
          client.invalidateQueries("units");
          if (goBack !== false) swalSuccess(t);
          if (goBack !== false) history.goBack();
        },
      });
    } else {
      const svc = Number(tax.data?.svg);
      const vat = Number(tax.data?.vat);
      post_laundry_order.mutate(
        { ...values, svc, vat },
        {
          onSuccess: (res: any) => {
            swalSuccess();
            generateQrCode(res.id, goBack);
            client.invalidateQueries("get-laundry-orders");
            client.invalidateQueries("units");
            history.replace(LAUNDRY_ORDER_PATH.index);
          },
          onError: ({ message }: any) => {
            swalError(t, t(message));
          },
        }
      );
    }
  };

  const generateQrCode = (createId: number, goBack?: boolean) => {
    // generateQrSCB.mutate(createId, {
    //   onError: (err: any) => {
    //     swalError(t, err.message);
    //   },
    //   onSuccess: () => {
    //     if (goBack === false)
    //       swalSuccess(t, `${t("qr-code-scb")} ${t("success")}`);
    //   },
    // });
    generateQrBOT.mutate(createId, {
      onError: (err: any) => {
        swalError(t, err.message);
      },
      onSuccess: () => {
        if (goBack === false)
          swalSuccess(t, `${t("qr-code-bot")} ${t("success")}`);
      },
    });
  };

  const getNewRef = () => {
    const taxId = billType.data?.data.find(
      (item) => item.slug === "laundry"
    )?.taxId;
    const unitId = form.getFieldValue("unit");
    const unitReference = units.data?.data.find(
      (item) => item.id === unitId
    )?.unitReference;
    return { taxId, unitReference };
  };

  const onSelectUnit = (id: number) => {
    form.setFieldsValue({
      consignee: undefined,
      lineName: undefined,
      phoneNumber: undefined,
    });
    const unit = units.data?.data.find((item) => item.id === id);
    const members = unit?.userUnits
      .map((item: any) => {
        return {
          ...item.user,
          fullName: item.user?.fullName + ` (${t(item.role.toLowerCase())})`,
        };
      })
      .filter((item: any) => item.isActive === true);
    const unitReference = unit?.unitReference;
    const complimentary = unit?.totalLaundryComplimentary;
    const remaining = unit?.laundryComplimentary;
    form.setFieldsValue({ complimentary, unitReference, remaining });
    setMembers(members);
  };

  const onSelectUser = (value: number) => {
    const member = getMembers?.find((item) => item.id === value);
    const phoneNumber = member?.phoneNumber;
    const lineName = member?.lineName;
    form.setFieldsValue({ phoneNumber, lineName });
  };

  const FormInput = () => {
    return (
      <Row gutter={[24, 24]} className="px-4 mb-4 pb-2">
        <Col span={12}>
          <Form.Item
            name="laundryCode"
            label={`${t("order-number")}`}
            className="custom-input"
            rules={[{ required: true }]}
          >
            <Input placeholder={`${t("order-number")}`} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="unit"
            label={`${t("house-number")}`} //unit number to use, house number to show
            rules={[{ required: true }]}
            className="custom-select-ant"
          >
            <Select
              placeholder={`${t("house-number")}`} //unit number to use, house number to show
              onSelect={onSelectUnit}
              showSearch
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              disabled={id ? true : false}
              getPopupContainer={(e) => e}
            >
              {units.data?.data.map((item) => {
                return (
                  <Select.Option key={item.id} value={item.id}>
                    {item.unitNumber}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="customer"
            label={`${t("recipient-name")}`}
            className="custom-select-ant"
            rules={[{ required: true }]}
          >
            <Select
              placeholder={`${t("recipient-name")}`}
              disabled={id ? true : false}
              onSelect={onSelectUser}
              getPopupContainer={(e) => e}
            >
              {getMembers?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item?.fullName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="phoneNumber"
            label={`${t("contact-number")}`}
            rules={[{ required: true }]}
            className="custom-input"
          >
            <Input placeholder={`${t("contact-number")}`} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="lineName"
            label={t("line-name")}
            className="custom-input"
          >
            <Input placeholder={t("line-name")} disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="unitReference"
            label={
              t("unit-reference-number") + t("this-information-comes-from-unit")
            }
            className="custom-input"
            rules={[{ required: true }]}
          >
            <Input placeholder={t("unit-reference-number")} disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="laundryRef"
            label={t("laundry-order-reference-number")}
            className="custom-input"
            rules={[{ required: true }]}
          >
            <Input placeholder={t("laundry-order-reference-number")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="taxId"
            label={t("tax-id") + t("this-information-comes-from-bill-type")}
            className="custom-input"
            rules={[{ required: true }]}
          >
            <Input placeholder={t("tax-id")} disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="createdAt" label={t("required-date")}>
            <CustomDatePicker
              format={
                i18n.language === "en"
                  ? "DD-MM-YYYY / HH:00 a"
                  : "DD-MM-BBBB / HH:00 a"
              }
              disabled
              defaultValue={dayjs()}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="status"
            rules={[{ required: true }]}
            label={`${t("status")}`}
            className="custom-select-ant"
            initialValue="PENDING"
          >
            <Select
              placeholder={`${t("status")}`}
              defaultValue="PENDING"
              getPopupContainer={(e) => e}
            >
              <Select.Option value="PENDING">{t("pending")}</Select.Option>
              {/* <Select.Option value="PAID">{t("paid")}</Select.Option> */}
              <Select.Option value="SUCCESS">{t("complete")}</Select.Option>
              <Select.Option value="CANCEL">{t("cancel")}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="paymentDate" label={t("payment-date")}>
            <CustomDatePicker
              format={
                i18n.language === "en"
                  ? "DD-MM-YYYY / HH:mm a"
                  : "DD-MM-BBBB / HH:mm a"
              }
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            shouldUpdate={(prev, next) =>
              prev.isComplimentary !== next.isComplimentary
            }
          >
            {(form: FormInstance) => {
              const required = form.getFieldValue("isComplimentary");

              return (
                <Form.Item
                  name="paymentType"
                  label={t("payment-type")}
                  rules={[{ required: !required }]}
                  className="custom-select-ant "
                >
                  <Select
                    placeholder={t("payment-type")}
                    getPopupContainer={(e) => e}
                  >
                    <Select.Option value="QR_CODE_BOT">
                      {t("qr-code-bot")}
                    </Select.Option>
                    {/* <Select.Option value="QR_CODE_SCB">
                      {t("qr-code-scb")}
                    </Select.Option> */}
                    <Select.Option value="CASH">{t("cash")}</Select.Option>
                    <Select.Option value="CREDIT_CARD">
                      {t("credit-card")}
                    </Select.Option>
                  </Select>
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="remaining"
            label={t("remaining")}
            className="custom-input-number"
          >
            <InputNumber disabled placeholder={t("remaining")} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="complimentary"
            label={t("complimentary")}
            className="custom-input-number"
          >
            <InputNumber disabled placeholder={t("complimentary")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            shouldUpdate={(prev, next) =>
              prev.laundryOrdersList !== next.laundryOrdersList
            }
          >
            {(form: FormInstance) => {
              const isComplimentary = form.getFieldValue("isComplimentary");
              const complimentary = form.getFieldValue("complimentary");
              const laundryOrdersList = form.getFieldValue("laundryOrdersList");
              const onChange = () => {
                form.setFieldsValue({ laundryOrdersList: [{}] });
              };

              return (
                <Form.Item
                  name="isComplimentary"
                  label={t("is-complimentary")}
                  valuePropName="checked"
                >
                  <Switch
                    checked={isComplimentary}
                    disabled={laundryOrdersList?.length > 1 || !complimentary}
                    onChange={onChange}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        {id &&
          (data?.paymentType === "QR_CODE_SCB" ||
            data?.paymentType === "QR_CODE_BOT") && (
            <Col span={12}>
              <Form.Item
                shouldUpdate={(prev, next) =>
                  prev.paymentType !== next.paymentType
                }
              >
                {(form: FormInstance) => {
                  let generateQr: any = undefined;
                  let showQr = true;
                  const paymentType = form.getFieldValue("paymentType");
                  const qrImage =
                    paymentType === "QR_CODE_SCB" ? "qrImage" : "qrBotImage";
                  // if (paymentType === "QR_CODE_SCB") generateQr = generateQrSCB;
                  if (paymentType === "QR_CODE_BOT") generateQr = generateQrBOT;
                  if (paymentType?.split("_")[0] !== "QR") showQr = false;
                  if (!generateQr) return <></>;

                  const onClick = () => {
                    saveNewRef();
                  };

                  const saveNewRef = () => {
                    const taxId = billType.data?.data.find(
                      (item) => item.slug === "laundry"
                    )?.taxId;
                    const unitId = form.getFieldValue("unit");
                    const unitReference = units.data?.data.find(
                      (item) => item.id === unitId
                    )?.unitReference;
                    const values = form.getFieldsValue();
                    onFinish({ ...values, taxId, unitReference }, false);
                  };

                  const reloadSCB =
                    paymentType === "QR_CODE_SCB" && !data.qrImage;
                  const reloadBOT = paymentType === "QR_CODE_BOT";

                  return (
                    <Row>
                      <Col>
                        <Form.Item label={t("qr-code")} hidden={!showQr}>
                          <Image
                            src={data[qrImage]?.imageUrl}
                            preview={false}
                            height={228}
                            width={228}
                          />
                        </Form.Item>
                      </Col>
                      {(reloadSCB || reloadBOT) && showQr && (
                        <Col className="d-flex align-center pl-4 color-white">
                          <div
                            style={{
                              marginTop: 41,
                              borderRadius: 50,
                            }}
                            className="p-1 d-flex align-center bg-grey pointer"
                            onClick={onClick}
                          >
                            <ReloadOutlined
                              style={{
                                fontSize: 24,
                                fontWeight: "bold",
                              }}
                            />
                          </div>
                        </Col>
                      )}
                    </Row>
                  );
                }}
              </Form.Item>
            </Col>
          )}
      </Row>
    );
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      validateMessages={VALIDATE_MESSAGES(t)}
    >
      <div className="bg-white color-black pb-1">
        <PageHeaderEdit
          label="menu.laundry-order"
          isLoading={
            post_laundry_order.isLoading || update_laundry_order.isLoading
          }
        />
        <FormInput />
      </div>
      <div className="bg-white color-black mt-4 pb-1">
        <InputArrayLaundryOrder
          data={data}
          form={form}
          laundryList={laundryList.data?.data}
        />
      </div>
      {id && (
        <div className="bg-white color-black mt-4 px-4 pb-4 pt-2">
          <Col span={24}>
            <Form.Item
              name="picture"
              label={t("attached-picture")}
              className="custom-ant-upload-image"
            >
              <UploadImage onPage="laundry-order" isReceiptImage />
            </Form.Item>
          </Col>
        </div>
      )}
    </Form>
  );
};

export default LaundryOrderEditPage;
