import { Button, Divider, Form, Row, Spin, Table, Image } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import CustomDatePicker from "../../../components/custom-date-picker";
import {
  GET_REPAIRING_APPOINTMENT,
  IAppointment,
  POST_REPAIRING_APPOINTMENT,
  DELETE_REPAIRING_APPOINTMENT,
} from "../../../store/repairing";
import th from "antd/es/date-picker/locale/th_TH";
import en from "antd/es/date-picker/locale/en_US";
import { ColumnsType } from "antd/lib/table";
import { parse } from "../../../tools/datetime";
import loadding from "../../../assets/images/loding.gif";
import { swalDelete, swalError, swalSuccess } from "../../../tools/swal";
import { useQueryClient } from "react-query";

const AppointmentDate = ({ id }: { id: string }) => {
  const { t, i18n } = useTranslation();
  const [appointmentForm] = useForm();
  const client = useQueryClient();
  const { data, isLoading, refetch } = GET_REPAIRING_APPOINTMENT({
    repairing: id,
  });
  const create = POST_REPAIRING_APPOINTMENT();
  const deleteAppointment = DELETE_REPAIRING_APPOINTMENT();

  const onFinish = (values: any) => {
    if (!values.appointmentFrom) return false;
    create.mutate(
      { ...values, repairing: parseInt(id) },
      {
        onError: (err: any) => {
          swalError(t, err.message);
        },
        onSuccess: () => {
          client.invalidateQueries("get-repairing-appointment");
          client.invalidateQueries("get-repairing-ref");
          swalSuccess(t);
          appointmentForm.resetFields();
        },
      }
    );
  };

  const onDelete = async (id: number) => {
    const confirm = await swalDelete(t);
    if (confirm) {
      deleteAppointment.mutate(
        { id },
        {
          onError: (err: any) => {
            swalError(t, err.message);
          },
          onSuccess: () => {
            client.invalidateQueries("get-repairing-appointment");
            client.invalidateQueries("get-repairing-ref");
            swalSuccess(t);
            refetch();
          },
        }
      );
    }
  };

  const columns: ColumnsType<IAppointment> = [
    {
      title: "",
    },
    {
      dataIndex: "appointmentFrom",
      title: t("appointment-date"),
      width: "85%",
      render: (text, record) => {
        return (
          <>
            {text
              ? parse(
                  text,
                  i18n.language,
                  `${
                    i18n.language === "en"
                      ? "DD MMM YYYY / HH:mm a"
                      : "DD MMM BBBB / HH:mm a"
                  }`
                )
              : "-"}
          </>
        );
      },
    },
    {
      title: t("delete"),
      render: (___, record) => {
        return (
          <>
            <Button
              className="button-save f-14"
              style={{ width: 100, height: 30 }}
              onClick={() => onDelete(record.id)}
              loading={deleteAppointment.isLoading}
            >
              {t("delete")}
            </Button>
          </>
        );
      },
    },
  ];

  const antIcon = (
    <Image src={loadding} preview={false} style={{ fontSize: "60px" }} />
  );

  const tableLoading = {
    spinning: isLoading,
    indicator: <Spin indicator={antIcon} />,
  };

  return (
    <div className="bg-white color-black w-100">
      <div className="p-4  w-100">
        <Form
          form={appointmentForm}
          onFinish={onFinish}
          layout="vertical"
          className="fixed-input-size"
        >
          <Row className="d-flex" justify="space-between">
            <div className="d-flex align-center f-18">
              {t("appointment-date")}
            </div>
            <div>
              <Button
                className="button-save f-16"
                style={{ width: 120, height: 40 }}
                htmlType="submit"
                loading={create.isLoading}
              >
                {t("save")}
              </Button>
            </div>
          </Row>
          <div
            style={{ border: "1px solid #e4e9f2", height: "100%" }}
            className="p-4 mt-4"
          >
            <Form.Item
              name="appointmentFrom"
              label={t("appointment-date")}
              className="date-picker-style date-picker-time-style "
            >
              <CustomDatePicker
                getPopupContainer={(e) => e}
                placeholder={t("appointment-date")}
                locale={i18n.language === "en" ? en : th}
                format={
                  i18n.language === "en"
                    ? "DD-MM-YYYY / HH:mm a"
                    : "DD-MM-BBBB / HH:mm a"
                }
                showTime={{ format: "HH:mm" }}
              />
            </Form.Item>
          </div>
        </Form>
      </div>
      <Divider />
      <div className="px-4 pb-4  w-100">
        <Table
          className="p-0 custom-table"
          scroll={{ y: "50vh", x: "auto" }}
          dataSource={data?.data || []}
          columns={columns}
          rowKey="id"
          pagination={false}
          loading={tableLoading}
        />
      </div>
    </div>
  );
};

export default AppointmentDate;
