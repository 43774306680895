import { ColumnsType } from "antd/lib/table";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import CustomStatusBackground from "../../components/custom-status";
import CustomTable from "../../components/custom-table";
import PageFilter, { IPageFilterChoice } from "../../components/page-filter";
import PageHeader, { ISearchInput } from "../../components/page-header";
import { PAYMENT_LIST_CREATE_PATH } from "../../router/path";
import {
  BULK_DELETE_PAYMENT_LISTS,
  DELETE_PAYMENT_LIST,
  GET_PAYMENT_LISTS,
} from "../../store/payment-list";
import { swalDelete, swalError, swalSuccess } from "../../tools/swal";
import { getKeyByLocale } from "../../tools/translation";

const PaymentLists = () => {
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    status: undefined,
  });
  const { t, i18n } = useTranslation();
  const { data, isLoading } = GET_PAYMENT_LISTS(params);
  const delete_list = DELETE_PAYMENT_LIST();
  const bulk_delete_payment_list = BULK_DELETE_PAYMENT_LISTS();

  const client = useQueryClient();

  const searchInput: ISearchInput[] = [
    {
      label: t("search"),
      key: "search",
      span: 10,
      type: "input",
      props: {
        placeholder: t("search-name-EN-TH"),
      },
    },
  ];
  const columns: ColumnsType = [
    {
      dataIndex: "nameEn",
      title: t("name"),
      width: "60%",
      render: (___, rc) => (
        <div className="line-clamp">
          {getKeyByLocale({ key: "name", data: rc, locale: i18n.language })}
        </div>
      ),
    },
    {
      dataIndex: "status",
      title: t("status"),
      width: "25%",
      render: (text) => {
        return (
          <>
            {!text && "-"}
            {text === "ACTIVE" && (
              <CustomStatusBackground
                lable={t("active")}
                className={"frame-background"}
              />
            )}
            {text === "INACTIVE" && (
              <CustomStatusBackground
                lable={t("inactive")}
                className={"frame-no-background"}
              />
            )}
          </>
        );
      },
    },
  ];

  const choices: IPageFilterChoice[] = [
    {
      label: t("all"),
      total: data?.countData.countAll || 0,
    },
    {
      label: t("active"),
      key: "ACTIVE",
      total: data?.countData.countActive || 0,
    },
    {
      label: t("inactive"),
      key: "INACTIVE",
      total: data?.countData.countInActive || 0,
    },
  ];

  const onDelete = async (id: any) => {
    const confirm = await swalDelete();
    if (confirm) {
      delete_list.mutate(
        { id: id },
        {
          onSuccess: () => {
            swalSuccess();
            if (params.page > 1 && data?.data.length === 1) {
              return setParams({ ...params, page: params.page - 1 });
            }
            client.invalidateQueries("get-payment-type-lists");
          },
          onError: (error: any) => {
            swalError(t, error.message);
          },
        }
      );
    }
  };

  const onSearch = (values: any) => {
    setParams({ ...params, ...values });
  };

  const onFilter = (choice: IPageFilterChoice) => {
    const status = choice.key;
    setParams({ ...params, status });
  };

  const _getCurrentFilter = (): string | undefined => {
    const { status } = params;
    if (status) return status;
    return undefined;
  };

  const onBulkDeleteSuccess = (length: number) => {
    if (length === data?.data.length && params.page > 1) {
      setParams({ ...params, page: params.page - 1 });
    }
  };

  return (
    <>
      <PageHeader
        menu="payment-list"
        onSearch={onSearch}
        searchInput={searchInput}
        label={"create"}
        utility={["delete"]}
        bulkDelete={bulk_delete_payment_list}
        onBulkDeleteSuccess={onBulkDeleteSuccess}
        path={PAYMENT_LIST_CREATE_PATH}
        invalidateQueries={["get-payment-type-lists"]}
      />
      <PageFilter
        choices={choices}
        current={_getCurrentFilter()}
        onClick={onFilter}
      />
      <CustomTable
        rowKey="id"
        title={t("payment-list")}
        dataSource={data?.data || []}
        columns={columns}
        onDelete={onDelete}
        pagination={{
          limit: params.limit,
          page: params.page,
          total: data?.count || 0,
        }}
        onPageChange={(page, limit) => setParams({ ...params, page, limit })}
        isLoadingTable={isLoading}
      />
    </>
  );
};

export default PaymentLists;
