import axios from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { throwResponse } from "../config/axios";

export interface IFoodOptional {
  createdAt: string;
  updatedAt: string;
  nameEn: string;
  nameTh: string;
  price: number;
  id: number;
}

export const GET_FOODS_OPTIONAL_LIST = (): UseQueryResult<IFoodOptional[]> => {
  return useQuery(["get-food-optional-list"], async () => {
    const res = await axios.get("/api/food-optional-list", {
      params: { pagination: false },
    });
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

export const POST_FOOD_OPTIONAL_LIST = (): UseMutationResult => {
  return useMutation(async (values) => {
    const res = await axios.post(`/api/food-optional-list`, values);
    if (res.status === 201) {
      return;
    }
    throwResponse(res);
  });
};

export const PATCH_FOOD_OPTIONAL_LIST = (): UseMutationResult => {
  return useMutation(async (values: any) => {
    const id = values.id;
    delete values.id;
    const res = await axios.patch(`/api/food-optional-list/${id}`, values);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const DELETE_FOOD_OPTIONAL_LIST = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/api/food-optional-list/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const BULK_DELETE_FOOD_OPTIONAL_LIST = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/api/food-optional-list/bulk`, {
      data: data,
    });
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};
