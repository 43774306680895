import axios from "axios";
import dayjs from "dayjs";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { IResponse } from ".";
import { throwResponse } from "../config/axios";
import { IRepairingCategory } from "./repairing-category";

export interface IRepairingZone {
  id: number;
  nameTh: string;
  nameEn: string;
  createdAt: string;
  deletedAt: string;
  status: string;
  updatedAt: string;
  createdBy: string;
  isPrivateArea: boolean;
  repairingCategories: IRepairingCategory[];
}

export const GET_REPAIRING_ZONE = (params?: {}): UseQueryResult<
  IResponse<IRepairingZone[]>
> => {
  return useQuery(
    ["setting-repairing-zones", params],
    async () => {
      const res = await axios.get("/api/repairing-zone", {
        params: params,
      });

      if (res.status === 200) {
        const { data, count, page, countData } = res.data;
        const dataSome = data.map((item: any) => {
          const createdBy = item.createdBy?.fullName;
          return { ...item, createdBy: createdBy };
        });
        return { data: dataSome, count, page, countData };
      }
      throwResponse(res);
    },
    { retry: false, refetchOnWindowFocus: false }
  );
};

export const GET_REPAIRING_ZONE_ID = (
  id: string | undefined
): UseQueryResult<IRepairingZone> => {
  return useQuery(["setting-repairing-zone", id], async () => {
    if (!id) return;
    const res = await axios.get(`/api/repairing-zone/${id}`);
    if (res.status === 200) {
      const { data } = res.data;
      data.createdAt = dayjs(data.createdAt);
      data.updatedAt = dayjs(data.updatedAt);
      data.createdBy = data.createdBy?.fullName;
      return data;
    }
    throwResponse(res);
  });
};

export const POST_REPAIRING_ZONE = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post("/api/repairing-zone", data);
    if (res.status === 201) {
      return true;
    }
    throwResponse(res);
  });
};

export const PATCH_REPAIRING_ZONE = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.patch(`/api/repairing-zone/${data.id}`, data);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const DELETE_REPAIRING_ZONE = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/api/repairing-zone/${data.id}`);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const BULK_DELETE_REPAIRING_ZONE = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete("/api/repairing-zone/bulk", {
      data: data,
    });
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};
