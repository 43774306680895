import React from "react";

const EMAIL = (props: React.SVGProps<SVGSVGElement> = {}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="27"
      viewBox="0 0 33 27"
      {...props}
    >
      <defs>
        <clipPath id="clip-Artboard_3">
          <rect width="33" height="27" />
        </clipPath>
      </defs>
      <g
        id="Artboard_3"
        data-name="Artboard – 3"
        clipPath="url(#clip-Artboard_3)"
      >
        <path
          id="Path_19931"
          data-name="Path 19931"
          d="M31.064,4H5.229A3.225,3.225,0,0,0,2.016,7.229L2,26.605a3.239,3.239,0,0,0,3.229,3.229H31.064a3.239,3.239,0,0,0,3.229-3.229V7.229A3.239,3.239,0,0,0,31.064,4Zm-.646,6.862L19,18a1.633,1.633,0,0,1-1.712,0L5.875,10.862A1.371,1.371,0,1,1,7.328,8.537L18.147,15.3,28.965,8.537a1.371,1.371,0,1,1,1.453,2.325Z"
          transform="translate(-1.646 -3.417)"
          fill="#a9a9a5"
        />
      </g>
    </svg>
  );
};

export default EMAIL;
