import { Row, Col, Button, DatePicker, Form } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { EXPORT_REPAIR_SETUP } from "../../../store/repair-setup";
import { downloadFile } from "../../../tools/files";
import { swalError } from "../../../tools/swal";
import { fix_height } from "../../news/news-edit";

const { RangePicker } = DatePicker;

const ExportExcel = ({
  handleCancel,
  formDate,
}: {
  handleCancel: () => void;
  formDate: FormInstance;
}) => {
  const { t } = useTranslation();
  const exportExcel = EXPORT_REPAIR_SETUP();
  const onFinish = (value: any) => {
    if (value.date === undefined) return;
    const date = {
      startDate: dayjs(value.date[0])
        .hour(0)
        .minute(0)
        .second(0)
        .format("YYYY-MM-DDTHH:mm:ss[Z]"),
      endDate: dayjs(value.date[1])
        .hour(23)
        .minute(59)
        .second(59)
        .format("YYYY-MM-DDTHH:mm:ss[Z]"),
    };
    exportExcel.mutate(
      { ...date },
      {
        onSuccess: (data: any) => {
          handleCancel();
          downloadFile(
            data,
            "xlsx",
            `Repair-Setup-${dayjs().format("DD-MM-YYYY")}`
          );
        },
        onError: (error: any) => {
          swalError(t, t("swal.please-select-an-information"));
        },
      }
    );
  };

  return (
    <div className="px-4 py-2">
      <Form
        form={formDate}
        onFinish={onFinish}
        className="w-100 custom-ant-picker"
      >
        <Row justify="space-between" className="mt-4 mb-4">
          <Col className="d-flex flex-column justify-center f-18">
            {t("export-excel")}
          </Col>

          <Col>
            <Row gutter={[12, 12]}>
              <Col>
                <Button
                  style={{ height: 45, width: 120 }}
                  className="button-cancel-setup"
                  onClick={handleCancel}
                >
                  {t("cancel")}
                </Button>
              </Col>
              <Col>
                <Button
                  style={{ height: 45, width: 120 }}
                  htmlType="submit"
                  className="button-submit-setup"
                  loading={exportExcel.isLoading}
                >
                  {t("export")}
                </Button>
              </Col>
            </Row>
          </Col>

          <Col span={24} className="pt-4">
            <Form.Item className="w-100" name="date">
              <RangePicker
                getPopupContainer={(props: HTMLElement) => {
                  return props;
                }}
                className="w-100"
                style={{ height: `${fix_height}` }}
                format="YYYY-MM-DD"
                showTime={false}
                placeholder={[t("start-date"), t("end-date")]}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ExportExcel;
