/* eslint-disable jsx-a11y/alt-text */
import { PlusCircleFilled } from "@ant-design/icons";
import React, { useRef, useState, useEffect } from "react";
import { Image } from "antd";
import { swalError } from "../tools/swal";
import { useTranslation } from "react-i18next";
import { resizeImage } from "../tools/convert";

const ImageUpload = ({
  value,
  onChange,
  disable = false,
}: {
  value?: any;
  onChange?: any;
  disable?: boolean;
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>(null);
  const support = ["jpeg", "jpg", "png", "JPEG", "JPG", "PNG"];
  const [imageUrl, setImageUrl] = useState<undefined | string>(value);

  useEffect(() => {
    initial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const initial = () => {
    if (typeof value === "object") return;
    setImageUrl(value);
  };

  const fileToBase64 = (file: File) =>
    new Promise<string | ArrayBuffer | null>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onPicked = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files && files?.length > 0) {
      try {
        const image = files[0];
        const extension = image.name.split(".").pop();
        if (!extension || !support.includes(extension)) {
          swalError(t, t("validate.image-types"));
        }

        const base64 = await fileToBase64(image);
        if (typeof base64 !== "string") {
          throw new Error("เกิดข้อผิดพลาด");
        }

        const resized = await resizeImage({
          file: image,
          maxHeight: 720,
          maxWidth: 1280,
        });
        setImageUrl(base64);
        onChange(resized);
      } catch (err) {}
    }
  };

  const clearPreviousValue = (e: React.MouseEvent<HTMLInputElement>) => {
    e.currentTarget.value = "";
  };

  const openPickFileDialog = () => {
    if (disable) return;
    ref.current?.click();
  };

  return (
    <div className="image-upload" onClick={openPickFileDialog}>
      <Image src={imageUrl} preview={false} />
      <div className="addition">
        {!imageUrl && (
          <PlusCircleFilled style={{ color: "#CCCDCD", fontSize: 24 }} />
        )}
      </div>
      <input
        accept=".jpg,.jpeg,.png"
        multiple={false}
        type="file"
        ref={ref}
        style={{ display: "none" }}
        onChange={onPicked}
        onClick={clearPreviousValue}
      />
    </div>
  );
};

export default ImageUpload;
