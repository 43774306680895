import { Col, Form, FormInstance, TimePicker } from "antd";
import CustomSelect from "../../../components/custom-select";

export interface IDayOffWeek {
  id: number;
  dayEn: string;
  dayTh: string;
}

export interface IMonthOffYear {
  id: number;
  monthEn: string;
  monthTh: string;
}

const dayOffWeek: IDayOffWeek[] = [
  { id: 1, dayEn: "Sunday", dayTh: "วันอาทิตย์" },
  { id: 2, dayEn: "Monday", dayTh: "วันจันทร์" },
  { id: 3, dayEn: "Tuesday", dayTh: "วันอังคาร" },
  { id: 4, dayEn: "Wednesday", dayTh: "วันพุทธ" },
  { id: 5, dayEn: "Thursday", dayTh: "วันพฤหัสบดี" },
  { id: 6, dayEn: "Friday", dayTh: "วันศุกร์" },
  { id: 7, dayEn: "Saturday", dayTh: "วันเสาร์" },
];

const monthOffYear: IMonthOffYear[] = [
  { id: 1, monthEn: "January ", monthTh: "มกราคม" },
  { id: 2, monthEn: "February", monthTh: "กุมภาพันธ์" },
  { id: 3, monthEn: "March", monthTh: "มีนาคม" },
  { id: 4, monthEn: "April", monthTh: "เมษายน" },
  { id: 5, monthEn: "May", monthTh: "พฤษภาคม" },
  { id: 6, monthEn: "June", monthTh: "มิถุนายน" },
  { id: 7, monthEn: "July", monthTh: "กรกฎาคม" },
  { id: 8, monthEn: "August", monthTh: "สิงหารคม" },
  { id: 9, monthEn: "September ", monthTh: "กันยายน" },
  { id: 10, monthEn: "October ", monthTh: "ตุลาคม" },
  { id: 11, monthEn: "November", monthTh: "พฤศจิกายน" },
  { id: 12, monthEn: "December", monthTh: "ธันวาคม" },
];

export const FormRepeat = (
  form: FormInstance,
  t: any,
  i18n: any,
  year: any[],
  editId: string
) => {
  return (
    <>
      <Col span={12}>
        <Form.Item
          name="day"
          label={t("day")}
          rules={[{ required: true }]}
          className="custom-select-ant"
        >
          <CustomSelect
            disabled={editId === "create" ? false : true}
            hasTranslation={true}
            placeholder={t("day")}
            optionValue={{
              values: dayOffWeek || [],
              key: "id",
              name: i18n.language === "en" ? "dayEn" : "dayTh",
            }}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="month"
          label={t("month")}
          className="custom-select-ant"
        >
          <CustomSelect
            allowClear={true}
            disabled={editId === "create" ? false : true}
            hasTranslation={true}
            placeholder={t("month")}
            optionValue={{
              values: monthOffYear || [],
              key: "id",
              name: i18n.language === "en" ? "monthEn" : "monthTh",
            }}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="year" label={t("year")} className="custom-select-ant">
          <CustomSelect
            allowClear={true}
            disabled={editId === "create" ? false : true}
            hasTranslation={true}
            placeholder={t("year")}
            optionValue={{
              values: year || [],
              key: "id",
              name: i18n.language === "en" ? "yearEn" : "yearTh",
            }}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          rules={[{ required: true }]}
          label={t("time")}
          name="time"
          className="custom-ant-picker"
        >
          <TimePicker.RangePicker
            disabled={editId === "create" ? false : true}
            getPopupContainer={(props: HTMLElement) => {
              return props;
            }}
            placeholder={[t("start-time"), t("end-time")]}
            format="HH:mm"
            className="w-100"
          />
        </Form.Item>
      </Col>
    </>
  );
};
