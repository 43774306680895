import React from "react";

const LAUNDRY_ICON = (props: React.SVGProps<SVGSVGElement> = {}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={50}
      height={50}
      viewBox="0 2 60 56"
      {...props}
    >
      <g id="C" clip-path="url(#clip-C)">
        <g
          id="vuesax_bold_document-filter"
          data-name="vuesax/bold/document-filter"
          transform="translate(-602.465 -298.465)"
        >
          <g id="document-filter" transform="translate(620 316)">
            <path
              id="Vector"
              d="M0,0H24.93V24.93H0Z"
              transform="translate(24.93 24.93) rotate(180)"
              fill="none"
              opacity="0"
            />
            <path
              id="Vector-2"
              data-name="Vector"
              d="M1.278,0H9.723A1.288,1.288,0,0,1,11,1.3V2.722a2.4,2.4,0,0,1-.644,1.485L7.614,6.679A2.1,2.1,0,0,0,6.98,8.165v2.784a1.482,1.482,0,0,1-.571,1.1l-.9.592a1.3,1.3,0,0,1-1.984-1.1V8.1a2.557,2.557,0,0,0-.509-1.361L.582,4.145A2.143,2.143,0,0,1,0,2.857v-1.5A1.3,1.3,0,0,1,1.278,0Z"
              transform="translate(2.078 1.039)"
              fill="#aaaaa7"
            />
            <path
              id="Vector-3"
              data-name="Vector"
              d="M15.581,0H13.088a.514.514,0,0,0-.519.519V1.683a3.958,3.958,0,0,1-1.091,2.587L8.653,6.793c-.031.073-.083.177-.114.26V9.91a3.019,3.019,0,0,1-1.33,2.441l-.852.551a2.869,2.869,0,0,1-1.537.447,2.9,2.9,0,0,1-1.392-.364,2.837,2.837,0,0,1-1.35-1.693V8.528a.487.487,0,0,0-.156-.364L.883,7.126A.514.514,0,0,0,0,7.489v8.092a5.2,5.2,0,0,0,5.194,5.194H15.581a5.2,5.2,0,0,0,5.194-5.194V5.194A5.2,5.2,0,0,0,15.581,0ZM16.62,16.36H9.349a.779.779,0,0,1,0-1.558H16.62a.779.779,0,1,1,0,1.558Zm0-4.155H11.426a.779.779,0,0,1,0-1.558H16.62a.779.779,0,1,1,0,1.558Z"
              transform="translate(2.078 2.078)"
              fill="#aaaaa7"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LAUNDRY_ICON;
