import { Typography } from "antd";

const CustomStatusBackground = ({
  lable,
  className,
}: {
  lable: string;
  className: string;
}) => {
  return (
    <div className={className}>
      <Typography.Text ellipsis>{lable}</Typography.Text>
    </div>
  );
};

export default CustomStatusBackground;
