import axios from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { exportFile, throwResponse } from "../config/axios";
import { getClientTimeZone } from "../tools/datetime";

export interface IDonutChart {
  id: number;
  count: number;
  labelEn: string;
  labelTh: string;
}

export interface IDbNewsMonth {
  newsId: number;
  newsHeaderEn: string;
  newsHeaderTh: string;
  count: number;
}

export interface IDbNewsYear {
  name: string;
  no: string;
  count: number;
}

export interface IDbPrivilegeMost {
  privilegeId: number;
  headerEn: string;
  headerTh: string;
  reach: number;
}

export interface IDbPrivilegeUse {
  userFullName: string;
  count: number;
}

export const DASHBOARD_NEWS_MONTH = (params?: {
  startDate: string;
  endDate: string;
}): UseQueryResult<IDbNewsMonth[]> => {
  return useQuery(["get-db-news-month", params], async () => {
    const res = await axios.get(`/api/dashboard/news`, { params });
    if (res.status === 200) {
      return res.data.countNews.getCountNews;
    }
    throwResponse(res);
  });
};

export const DASHBOARD_NEWS_YEAR = (params?: {
  year: string;
}): UseQueryResult<IDbNewsYear[]> => {
  return useQuery(["get-db-news-year", params], async () => {
    const res = await axios.get(`/api/dashboard/news/month`, { params });
    if (res.status === 200) {
      return res.data.countNews.getCountNewsReachPerMonth;
    }
    throwResponse(res);
  });
};

export const DASHBOARD_PRIVILEGE_MOST = (params?: {
  startDate: string;
  endDate: string;
}): UseQueryResult<IDbPrivilegeMost[]> => {
  return useQuery(["get-privilege-most-db", params], async () => {
    const res = await axios.get(`/api/dashboard/privilege/most`, { params });
    if (res.status === 200) {
      return res.data.countMostPrivilege;
    }
    throwResponse(res);
  });
};

export const DASHBOARD_PRIVILEGE_USE = (params?: {
  startDate: string;
  endDate: string;
}): UseQueryResult<IDbPrivilegeUse[]> => {
  return useQuery(["get-privilege-most-use", params], async () => {
    const res = await axios.get("/api/dashboard/privilege/use", { params });
    if (res.status === 200) {
      return res.data.countMostUserUsePrivilege;
    }
    throwResponse(res);
  });
};

export interface IDbHomeCareLeft {
  unitId: number;
  unitNumber: string;
  count: number;
}

export const DASHBOARD_HOMECARE_LEFT = (params?: {
  startDate: string;
  endDate: string;
}): UseQueryResult<IDbHomeCareLeft[]> => {
  return useQuery(["get-db-homecare-left", params], async () => {
    const res = await axios.get("/api/dashboard/repairing/unit", { params });
    if (res.status === 200) {
      return res.data.data.countUnitMostRepairing;
    }
    throwResponse(res);
  });
};

export const DASHBOARD_HOMECARE_MIDDLE = (params?: {
  startDate: string;
  endDate: string;
}): UseQueryResult<{ countRepairingExpiredWarranty: number }> => {
  return useQuery(["get-db-homecare-left", params], async () => {
    const res = await axios.get("/api/dashboard/repairing/expired-warranty", {
      params,
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const DASHBOARD_HOMECARE_RIGHT = (params?: {
  startDate: string;
  endDate: string;
}): UseQueryResult<{ countOverSla: number }> => {
  return useQuery(["get-db-homecare-left", params], async () => {
    const res = await axios.get("/api/dashboard/repairing/over-sla", {
      params,
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export interface IDbHomeCareRight {
  count: number;
  categoryNameEn: string;
  categoryNameTh: string;
}
export const DASHBOARD_HOMECARE_BOTTOM = (params?: {
  startDate: string;
  endDate: string;
}): UseQueryResult<IDbHomeCareRight[]> => {
  return useQuery(["get-db-homecare-right", params], async () => {
    const res = await axios.get("/api/dashboard/repairing/category", {
      params,
    });
    if (res.status === 200) {
      return res.data.data.countCategoryMostRepairing;
    }
    throwResponse(res);
  });
};

export const DASHBOARD_PAYMENT_LEFT = (params?: {
  startDate: string;
  endDate: string;
}): UseQueryResult<{ countPaid: number; countOverdue: number }> => {
  return useQuery(["get-db-payment-left", params], async () => {
    const res = await axios.get("/api/dashboard/payment/count", { params });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const DASHBOARD_PAYMENT_RIGHT = (params?: {
  startDate: string;
  endDate: string;
}): UseQueryResult<{
  countPaid: number;
  // countOverdue: number;
  paidTypes: {
    type: "qr-code-bot" | "qr-code-scb" | "cash" | "credit-card";
    amount: number;
  }[];
  // overdueTypes: {
  //   type: "qr-code-bot" | "qr-code-scb" | "cash" | "credit-card";
  //   amount: number;
  // }[];
}> => {
  return useQuery(["get-db-payment-right", params], async () => {
    const res = await axios.get("/api/dashboard/payment/total", { params });

    if (res.status === 200) {
      const { paid } = res.data;

      const types = ["QR_CODE_BOT", "QR_CODE_SCB", "CASH", "CREDIT_CARD"];

      const paidTypes = types.map((item) => {
        const type = paid.listPaid?.find((p: any) => p.type === item);
        const name = item.toLowerCase().split("_").join("-");
        if (type) return { type: name, amount: type.amount };
        return { type: name, amount: 0 };
      });
      // const overdueTypes = types.map((item) => {
      //   const type = overdue.listOverdue?.find((p: any) => p.type === item);
      //   const name = item.toLowerCase().split("_").join("-");
      //   if (type) return { type: name, amount: type.amount };
      //   return { type: name, amount: 0 };
      // });

      return {
        countPaid: paid?.sumPaid,
        paidTypes,
        // countOverdue: overdue?.sumOverdue,
        // overdueTypes,
      };
    }
    throwResponse(res);
  });
};

export const DASHBOARD_PARCEL_TOP_LEFT = (params?: {
  startDate: string;
  endDate: string;
}): UseQueryResult<{ countParcel: number }> => {
  return useQuery(["get-parcel-top-left", params], async () => {
    const res = await axios.get("/api/dashboard/parcel/all", { params });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const DASHBOARD_PARCEL_TOP_RIGHT = (params?: {
  startDate: string;
  endDate: string;
}): UseQueryResult<{ parcelType: string; count: number }[]> => {
  return useQuery(["get-parcel-top-right", params], async () => {
    const res = await axios.get("/api/dashboard/parcel/type", { params });
    if (res.status === 200) {
      return res.data.mostParcelType;
    }
    throwResponse(res);
  });
};

export const DASHBOARD_PARCEL_BOTTOM_LEFT = (params?: {
  startDate: string;
  endDate: string;
}): UseQueryResult<{ unitNumber: string; count: number }[]> => {
  return useQuery(["get-parcel-bottom-left", params], async () => {
    const res = await axios.get("/api/dashboard/parcel/unit", { params });
    if (res.status === 200) {
      return res.data.mostParcelUnit;
    }
    throwResponse(res);
  });
};

export const DASHBOARD_PARCEL_BOTTOM_RIGHT = (params?: {
  startDate: string;
  endDate: string;
}): UseQueryResult<{ timeStart: string; count: number; timeEnd: string }[]> => {
  return useQuery(["get-parcel-bottom-right", params], async () => {
    const res = await axios.get("/api/dashboard/parcel/create", { params });
    if (res.status === 200) {
      return res.data.mostTimeParcelCreate.map((item: any) => {
        return { ...item, count: +item.count };
      });
    }
    throwResponse(res);
  });
};

export interface IDbFacilityTopLeft {
  facilityId: number;
  facilityNameEn: string;
  facilityNameTh: string;
  count: number;
}
export const DASHBOARD_FACILITY_TOP_LEFT = (params?: {
  startDate: string;
  endDate: string;
}): UseQueryResult<IDbFacilityTopLeft[]> => {
  return useQuery(["get-db-facility-top-left", params], async () => {
    const res = await axios.get("/api/dashboard/facility-booking/most", {
      params,
    });
    if (res.status === 200) {
      return res.data.data.countFaclilityMostBooking;
    }
    throwResponse(res);
  });
};

export interface IDbFacilityTopRight {
  name: string;
  no: string;
  count: number;
}
export const DASHBOARD_FACILITY_TOP_RIGHT = (params?: {
  year: string;
}): UseQueryResult<IDbFacilityTopRight[]> => {
  return useQuery(["get-db-facility-top-right", params], async () => {
    const res = await axios.get("/api/dashboard/facility-booking/use", {
      params,
    });
    if (res.status === 200) {
      return res.data.data.countFaclilityMostBooking;
    }
    throwResponse(res);
  });
};

export const DASHBOARD_FACILITY_BOTTOM_LEFT = (params?: {
  startDate: string;
  endDate: string;
}): UseQueryResult<{ unitNumber: string; count: number }[]> => {
  return useQuery(["get-db-facility-bottom-left", params], async () => {
    const res = await axios.get("/api/dashboard/facility-booking/unit", {
      params,
    });
    if (res.status === 200) {
      return res.data.data.countUnitUseFacility;
    }
    throwResponse(res);
  });
};

export interface IDbFacilityBottomRight {
  timeStart: string;
  timeEnd: string;
  count: number;
}
export const DASHBOARD_FACILITY_BOTTOM_RIGHT = (params?: {
  startDate: string;
  endDate: string;
}): UseQueryResult<IDbFacilityBottomRight[]> => {
  return useQuery(["get-db-facility-bottom-right", params], async () => {
    const res = await axios.get("/api/dashboard/facility-booking/create", {
      params,
    });
    if (res.status === 200) {
      return res.data.data.getTimeCreateFacilityBooking.map((item: any) => {
        return { ...item, count: +item.count };
      });
    }
    throwResponse(res);
  });
};

export const DASHBOARD_SERVICE_LEFT = (params?: {
  startDate: string;
  endDate: string;
}): UseQueryResult<{ unitNumber: string; count: number }[]> => {
  return useQuery(["get-db-service-left", params], async () => {
    const res = await axios.get(`/api/dashboard/service/unit`, { params });
    if (res.status === 200) {
      return res.data.countUnitMostUseService;
    }
    throwResponse(res);
  });
};

export interface IDbServiceRight {
  categoryNameTh: string;
  categoryNameEn: string;
  count: number;
}

export const DASHBOARD_SERVICE_RIGHT = (params?: {
  startDate: string;
  endDate: string;
}): UseQueryResult<IDbServiceRight[]> => {
  return useQuery(["get-db-service-right", params], async () => {
    const res = await axios.get(`/api/dashboard/service/category`, { params });
    if (res.status === 200) {
      return res.data.countServiceCategoryMostUseService;
    }
    throwResponse(res);
  });
};

export const DASHBOARD_LAUNDRY_LEFT = (params?: {
  startDate: string;
  endDate: string;
}): UseQueryResult<{ unitNumber: string; count: number }[]> => {
  return useQuery(["get-db-laundry-left", params], async () => {
    const res = await axios.get(`/api/dashboard/laundry/unit`, { params });
    if (res.status === 200) {
      return res.data.countUnitLaundry;
    }
    throwResponse(res);
  });
};

export interface IDbLaundryRight {
  laundryItemNameTh: string;
  laundryItemNameEn: string;
  count: number;
}

export const DASHBOARD_LAUNDRY_RIGHT = (params?: {
  startDate: string;
  endDate: string;
}): UseQueryResult<IDbLaundryRight[]> => {
  return useQuery(["get-db-laundry-right", params], async () => {
    const res = await axios.get(`/api/dashboard/laundry/item`, { params });
    if (res.status === 200) {
      return res.data.countLaundryItem;
    }
    throwResponse(res);
  });
};

export const DASHBOARD_FOOD_LEFT = (params?: {
  startDate: string;
  endDate: string;
}): UseQueryResult<{ unitNumber: string; count: number }[]> => {
  return useQuery(["get-db-food-left", params], async () => {
    const res = await axios.get(`/api/dashboard/food/unit`, { params });
    if (res.status === 200) {
      return res.data.countUnitMostUseFoodService;
    }
    throwResponse(res);
  });
};

export interface IDbFoodRight {
  foodNameTh: string;
  foodNameEn: string;
  count: number;
}

export const DASHBOARD_FOOD_RIGHT = (params?: {
  startDate: string;
  endDate: string;
}): UseQueryResult<IDbFoodRight[]> => {
  return useQuery(["get-db-food-right", params], async () => {
    const res = await axios.get(`/api/dashboard/food/menu`, { params });
    if (res.status === 200) {
      return res.data.countFoodMenu;
    }
    throwResponse(res);
  });
};

export const DASHBOARD_USER_LEFT = (params?: {
  startDate?: string;
  endDate?: string;
  useDate: boolean;
}): UseQueryResult<{ all: number; active: number; inActive: number }> => {
  return useQuery(["get-user-db-left", params], async () => {
    const res = await axios.get("/api/dashboard/resident/unit", { params });
    if (res.status === 200) {
      const { unitAll, unitActive, unitInActive } = res.data;
      return { all: unitAll, active: unitActive, inActive: unitInActive };
    }

    throwResponse(res);
  });
};

export const DASHBOARD_USER_MIDDLE = (params?: {
  startDate?: string;
  endDate?: string;
  useDate: boolean;
}): UseQueryResult<{ all: number; active: number; inActive: number }> => {
  return useQuery(["get-user-db-middle", params], async () => {
    const res = await axios.get("/api/dashboard/resident/admin", { params });
    if (res.status === 200) {
      const { adminAll, adminActive, adminInActive } = res.data;
      return { all: adminAll, active: adminActive, inActive: adminInActive };
    }

    throwResponse(res);
  });
};

export const DASHBOARD_USER_RIGHT = (params?: {
  startDate?: string;
  endDate?: string;
  useDate: boolean;
}): UseQueryResult<{ all: number; active: number; inActive: number }> => {
  return useQuery(["get-user-db-right", params], async () => {
    const res = await axios.get("/api/dashboard/resident/user", { params });
    if (res.status === 200) {
      const { userAll, userActive, userInActive } = res.data;
      return { all: userAll, active: userActive, inActive: userInActive };
    }

    throwResponse(res);
  });
};

export const EXPORT_DASHBOARD = (): UseMutationResult<Blob> => {
  return useMutation(
    async (data: any) => {
      const timeZone = getClientTimeZone();
      const res = await exportFile.post("/api/export-excel/dashboard", {
        ...data,
        timeZone,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { retry: false }
  );
};
