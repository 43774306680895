import React from "react";

const LINE_ICON = (props: React.SVGProps<SVGSVGElement> = {}) => {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141.73 141.73"
      {...props}
    >
      <path
        data-name="Path 21859"
        d="M70.08 5.08a78.06 78.06 0 0145.07 13.28 54.67 54.67 0 0121.66 27.21 45.34 45.34 0 01-5.73 42.21 94.27 94.27 0 01-17.75 19.08A254.41 254.41 0 0176 133.1c-2.26 1.3-4.73 2.23-7.12 3.32a2.87 2.87 0 01-.91.21c-2.45.26-3.53-.82-3.21-3.28.24-1.9.66-3.78.88-5.69s.34-3.91.39-5.86a3.74 3.74 0 00-2.4-3.84 30.87 30.87 0 00-5.88-1.69 71.36 71.36 0 01-42.15-21.7A49.44 49.44 0 012.77 68.79a45.82 45.82 0 015.12-30 61.68 61.68 0 0129.55-26.62 76.48 76.48 0 0126.3-6.77c2.4-.16 4.82-.23 6.34-.32zm.33 54.43l.29-.09c.31.41.63.81.93 1.22q6.24 8.55 12.5 17.09A3.68 3.68 0 0091 75.57V48.89a5.14 5.14 0 00-.66-2.4A3.41 3.41 0 0086.47 45a3.68 3.68 0 00-2.71 3.71v15.12c0 .42-.05.84-.08 1.55l-4.29-5.84c-3.2-4.35-6.38-8.72-9.61-13a3.43 3.43 0 00-4.7-1.14 3.27 3.27 0 00-1.14 1.14 5.49 5.49 0 00-.62 2.67v22.4c0 1.61 0 3.22.06 4.82a3.39 3.39 0 003.38 3.16 3.53 3.53 0 003.7-3.33 4.52 4.52 0 000-.52V61.01zm32.67-7.39h9.86a3.62 3.62 0 000-7.23H99.53a3.48 3.48 0 00-3.67 3.29v27.79a3.41 3.41 0 003.26 3.56H113.08a4.35 4.35 0 002.2-.77 3.46 3.46 0 001.19-4 3.65 3.65 0 00-3.63-2.45c-2.77-.06-5.54 0-8.32 0-.46 0-.94 0-1.38-.07v-6.4H113.11a3.53 3.53 0 003.53-3.51 3.36 3.36 0 00-.06-.63 3.57 3.57 0 00-3.77-3.09h-9.68zM33.88 72.3V52.4c0-1.43 0-2.86-.05-4.28a3.27 3.27 0 00-2.28-3 3.36 3.36 0 00-3.68.76 4.11 4.11 0 00-1.16 3.31v26a8.93 8.93 0 00.13 1.47 3.36 3.36 0 003.23 2.86q7.11.07 14.21 0a3.4 3.4 0 003.26-3.42A3.68 3.68 0 0044 72.29a2.9 2.9 0 00-.51 0h-9.58zm24.19-10v-8.16c0-2 0-4-.06-6A3.46 3.46 0 0055 44.9a3.55 3.55 0 00-3.79 2.34 6.59 6.59 0 00-.23 2v26c0 .4 0 .81.06 1.21a3.44 3.44 0 003.4 3.15 3.51 3.51 0 003.69-3.33 2.9 2.9 0 000-.51V62.34z"
        fill="#ababa8"
      />
    </svg>
  );
};

export default LINE_ICON;
