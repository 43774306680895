import React from "react";

const LAUNDRY = (props: React.SVGProps<SVGSVGElement> = {}) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="Layer 1"
    viewBox="0 0 110.39 137.99"
    {...props}
  >
    <path
      data-name="Icon material-local-laundry-service"
      d="M52.43 104.54a27.61 27.61 0 1 0 39.44-38.65l-.39-.4ZM113.35 2.29l-82.79-.07a13.75 13.75 0 0 0-13.8 13.71v110.48a13.74 13.74 0 0 0 13.7 13.8h82.89a13.76 13.76 0 0 0 13.8-13.7V16a13.69 13.69 0 0 0-13.66-13.71ZM58.15 16a6.91 6.91 0 1 1-6.9 6.9 6.93 6.93 0 0 1 6.9-6.9Zm-20.69 0a6.91 6.91 0 1 1-6.91 6.9 6.93 6.93 0 0 1 6.91-6.9ZM72 126.41A41.4 41.4 0 1 1 113.35 85 41.42 41.42 0 0 1 72 126.41Z"
      transform="translate(-16.76 -2.22)"
      fill="#b59d4e"
    />
  </svg>
  );
};

export default LAUNDRY;
