import { LeftOutlined } from "@ant-design/icons";
import { Button, Col, Row, Avatar } from "antd";
import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import DefaultAvatar from "../assets/images/no_profile.png";
import { USER_PATH } from "../mobile/router-mobile/path";
import { GET_USER_BY_ID, IUser } from "../store";
import { GET_USER_PROFILE } from "../store/auth";

const MobileHeader = ({ isGoBack = false }: { isGoBack?: boolean }) => {
  const { push, goBack } = useHistory();
  const { id } = GET_USER_PROFILE();
  const { data } = GET_USER_BY_ID(id);

  const [profile, setProfile] = useState<IUser | undefined>();

  useEffect(() => {
    inititalProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const inititalProfile = () => {
    setProfile(data?.data);
  };
  const onClickName = () => {
    push(USER_PATH);
  };

  return (
    <div className="navbar-mobile">
      <Row
        className="my-2 px-3 w-100 d-flex "
        align="middle"
        justify="space-between"
      >
        <Col className="icon">
          <Button
            type="link"
            block
            icon={<LeftOutlined className="f-18" />}
            onClick={() => goBack()}
            disabled={isGoBack}
          ></Button>
        </Col>
        <Col onClick={onClickName}>
          <Avatar
            size="large"
            src={profile?.avatar?.imageUrl || DefaultAvatar}
          />
        </Col>
      </Row>
      {/* <Row className="mt-2 px-3 w-100 " align="middle">
        <Col span={24} className="w-100 d-flex justify-end">
          <Button
            className="button-logout-mobile"
            style={{ width: 120 }}
            onClick={_logout}
          >
            {t("log-out")}
          </Button>
        </Col>
      </Row> */}
    </div>
  );
};

export default MobileHeader;
