import { Col, Form, FormInstance, Row } from "antd";
import numeral from "numeral";
import { useTranslation } from "react-i18next";
import {
  DASHBOARD_PARCEL_BOTTOM_LEFT,
  DASHBOARD_PARCEL_BOTTOM_RIGHT,
  DASHBOARD_PARCEL_TOP_LEFT,
  DASHBOARD_PARCEL_TOP_RIGHT,
} from "../../../store/dashboard";
import BarChart from "../bar-chart";
import DonutChart from "../donut-chart";
import {
  appendStartAndEndOfTime,
  DBHeader,
  ErrorPage,
  LoadingPage,
} from "../header";

const parcelLeft = "parcelLeft";
const parcelRight = "parcelRight";
const parcelBottomLeft = "parcelBottomLeft";
const parcelBottomRight = "parcelBottomRight";

const ParcelSection = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-white p-4">
      <p className="f-24 mb-4 bold">{t(`menu.parcel`)}</p>
      <Row gutter={[12, 12]}>
        <Col span={8}>
          <div className="p-3 db-border h-100">
            <DBHeader
              label={t("all-parcels")}
              selectType="range"
              formItemName={parcelLeft}
            />
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev[parcelLeft] !== next[parcelLeft]
              }
            >
              {(form: FormInstance) => {
                const value = form.getFieldValue(parcelLeft);
                return <TopLeft value={value} />;
              }}
            </Form.Item>
          </div>
        </Col>
        <Col span={16}>
          <div className="p-3 db-border h-100">
            <DBHeader
              label={t("top-5-most-type-of-parcel-this-year")}
              selectType="range"
              formItemName={parcelRight}
            />
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev[parcelRight] !== next[parcelRight]
              }
            >
              {(form: FormInstance) => {
                const value = form.getFieldValue(parcelRight);
                return <TopRight value={value} />;
              }}
            </Form.Item>
          </div>
        </Col>
        <Col span={8}>
          <div className="p-3 db-border h-100">
            <DBHeader
              label={t("top-3-most-room-of-parcel-this-year")}
              selectType="range"
              formItemName={parcelBottomLeft}
            />
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev[parcelBottomLeft] !== next[parcelBottomLeft]
              }
            >
              {(form: FormInstance) => {
                const value = form.getFieldValue(parcelBottomLeft);
                return <BottomLeft value={value} />;
              }}
            </Form.Item>
          </div>
        </Col>
        <Col span={16}>
          <div className="p-3 db-border h-100">
            <DBHeader
              label={t("the-time-when-the-parcels-are-received-the-most")}
              selectType="range"
              formItemName={parcelBottomRight}
            />
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev[parcelBottomRight] !== next[parcelBottomRight]
              }
            >
              {(form: FormInstance) => {
                const value = form.getFieldValue(parcelBottomRight);
                return <BottomRight value={value} />;
              }}
            </Form.Item>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const TopLeft = ({ value }: { value: any }) => {
  const params = appendStartAndEndOfTime(value);
  const { data, isLoading, isError, error } = DASHBOARD_PARCEL_TOP_LEFT(params);
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div style={{ height: 300 }}>
        <LoadingPage />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div style={{ height: 300 }}>
        <ErrorPage error={error} />
      </div>
    );
  }

  return (
    <div style={{ height: 300 }} className="c-container flex-column">
      <span className="f-28 bold color-gold">
        {numeral(data.countParcel).format("0,0")}
      </span>
      <span className="f-18">{t("items")}</span>
    </div>
  );
};

const TopRight = ({ value }: { value: any }) => {
  const params = appendStartAndEndOfTime(value);
  const { data, isLoading, isError, error } =
    DASHBOARD_PARCEL_TOP_RIGHT(params);

  if (isLoading) {
    return (
      <div style={{ height: 300 }}>
        <LoadingPage />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div style={{ height: 300 }}>
        <ErrorPage error={error} />
      </div>
    );
  }

  return (
    <div style={{ height: 300 }}>
      <BarChart
        data={data.map((e) => {
          return { label: e.parcelType, value: e.count };
        })}
      />
    </div>
  );
};

const BottomLeft = ({ value }: { value: any }) => {
  const params = appendStartAndEndOfTime(value);
  const { data, isLoading, isError, error } =
    DASHBOARD_PARCEL_BOTTOM_LEFT(params);

  if (isLoading) {
    return (
      <div style={{ height: 300 }}>
        <LoadingPage />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div style={{ height: 300 }}>
        <ErrorPage error={error} />
      </div>
    );
  }

  return (
    <div style={{ height: 300 }}>
      <DonutChart
        data={data.map((e) => {
          return { label: e.unitNumber, value: e.count };
        })}
      />
    </div>
  );
};

const BottomRight = ({ value }: { value: any }) => {
  const params = appendStartAndEndOfTime(value);
  const { data, isLoading, isError, error } =
    DASHBOARD_PARCEL_BOTTOM_RIGHT(params);

  if (isLoading) {
    return (
      <div style={{ height: 300 }}>
        <LoadingPage />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div style={{ height: 300 }}>
        <ErrorPage error={error} />
      </div>
    );
  }

  return (
    <div style={{ height: 300 }}>
      <BarChart
        data={data.map((item) => {
          const s = item.timeStart.substring(0, 5);
          const e = item.timeEnd.substring(0, 5);
          return { label: `${s} - ${e}`, value: item.count };
        })}
      />
    </div>
  );
};

export default ParcelSection;
