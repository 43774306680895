import { Button, Col, Row, Table, Tag, Upload } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  FolderAddOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import { t } from "i18next";
import { POST_REPAIRING_SETUP } from "../../../store/repair-setup";
import { IUser } from "../../../store";
import { IRepairingCategory } from "../../../store/repairing-category";
import dayjs from "dayjs";
import { swalError, swalSuccess } from "../../../tools/swal";
import { useQueryClient } from "react-query";

interface IExcel {
  Date: string;
  Time: string;
  RepairingCategory_EN: string;
  Email_user: string;
}

const { Dragger } = Upload;
const ImportExcel = ({
  handleCancel,
  setItems,
  items,
  technical,
  repairing_category,
}: {
  handleCancel: () => void;
  setItems: React.Dispatch<any>;
  items: any;
  technical: IUser[] | undefined;
  repairing_category: IRepairingCategory[] | undefined;
}) => {
  const [fileList] = useState<any | undefined>([]);
  const create = POST_REPAIRING_SETUP();
  const [data, setData] = useState<any>([]);
  const client = useQueryClient();
  const [diable, setDiable] = useState(false);

  useEffect(() => {
    if (items.langth !== 0) {
      const technical = items.some(
        (item: any) => item.checkTechnical_filter === false
      );
      const repairing = items.some(
        (item: any) => item.checkRepairingCa === false
      );
      const date = items.some((item: any) => item.checkDate === false);
      const time = items.some((item: any) => item.checkTime === false);
      const date2 = items.some((item: any) => item.checkDate2 === false);
      if ((technical || repairing || date || time || date2) === true) {
        setDiable(true);
      } else {
        setDiable(false);
      }
    }
  }, [items]);

  const beforeUpload = (newFileList: any) => {
    const { file } = newFileList;
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        if (!e.target) return;
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((d: any) => {
      const dataExcel: any = [];
      d.map((item: any, index: any) => {
        const { Email_user, RepairingCategory_EN, Time, Date } = item;
        const checkTime = Time[6];
        const checkDate = Date[4];
        const checkDate2 = Date[7];
        return dataExcel.push({
          ...item,
          checkTechnical_filter: technical?.some(
            (item) => item.email === Email_user
          ),
          checkRepairingCa: repairing_category?.some(
            (item) => item.nameEn === RepairingCategory_EN
          ),
          checkDate: checkDate === "-" ? true : false,
          checkTime: checkTime === "-" ? true : false,
          checkDate2: checkDate2 === "-" ? true : false,
          id: index + 1,
        });
      });
      setItems(dataExcel);
      mapDataInExcel(d);
    });
  };

  const mapDataInExcel = (value: any) => {
    const data: any = [];
    value.map((itemExcel: IExcel) => {
      const { Email_user, RepairingCategory_EN, Date, Time } = itemExcel;
      const repairing_Category = repairing_category?.find(
        (item) => item.nameEn === RepairingCategory_EN
      );
      const repairMan = technical?.find((item) => item.email === Email_user);
      let s0 = 0;
      let s1 = 0;
      let e0 = 0;
      let e1 = 0;
      if (Time[6] === "-") {
        const t = Time?.split("-");
        const st = t[0].split(":");
        const et = t[1].split(":");
        s0 = parseInt(st[0]);
        e0 = parseInt(et[0]);
        s1 = parseInt(st[1]);
        e1 = parseInt(et[1]);
      }
      const startTime = dayjs().hour(s0).minute(s1).second(0);
      const endTime = dayjs().hour(e0).minute(e1).second(0);
      const repairingSetupDate = dayjs(Date);
      const dataItem = {
        repairingSetupDate: repairingSetupDate.format(),
        user: repairMan,
        repairingCategory: repairing_Category,
        startTime: startTime.format("HH:mm:ssZ"),
        endTime: endTime.format("HH:mm:ssZ"),
      };
      return data.push(dataItem);
    });
    setData(data);
  };

  const onImport = () => {
    if (items.length === 0) {
      setData([]);
      return false;
    }
    // console.log(items,data);
    data.map((item: any, index: any) => {
      const { user, repairingCategory } = item;
      const value = {
        ...item,
        user: user.id,
        repairingCategory: repairingCategory.id,
      };
      return create.mutate(value, {
        onError: ({ message }: any) => {
          swalError(t, message);
        },
        onSuccess: () => {
          client.invalidateQueries("get-repairing-setup");
          if (data.length === index + 1) {
            handleCancel();
            swalSuccess(t);
          }
        },
      });
    });
  };

  const columns = [
    {
      dataIndex: "technical_filter",
      title: t("check"),
      render: (text: any, record: any) => {
        const {
          checkDate,
          checkRepairingCa,
          checkTechnical_filter,
          checkTime,
          checkDate2,
        } = record;
        return (
          <>
            {(checkRepairingCa &&
              checkTime &&
              checkDate &&
              checkTechnical_filter &&
              checkDate2) === true ? (
              <Tag
                className="ant-icon"
                icon={<CheckCircleOutlined />}
                color="success"
              >
                {t("success")}
              </Tag>
            ) : (
              <Tag
                className="ant-icon"
                icon={<CloseCircleOutlined />}
                color="error"
              >
                {t("error")}
              </Tag>
            )}
          </>
        );
      },
    },
    {
      title: t("repairman-name"),
      dataIndex: "Email_user",
      render: (text: any, record: any) => {
        const { checkTechnical_filter } = record;
        return (
          <>
            {checkTechnical_filter === false ? (
              <div className="color-red">{text}</div>
            ) : (
              <div>{text}</div>
            )}
          </>
        );
      },
    },
    {
      title: t("repairing-category"),
      dataIndex: "RepairingCategory_EN",
      render: (text: any, record: any) => {
        const { checkRepairingCa } = record;
        return (
          <>
            {checkRepairingCa === false ? (
              <div className="color-red">{text}</div>
            ) : (
              <div>{text}</div>
            )}
          </>
        );
      },
    },
    {
      title: t("date"),
      dataIndex: "Date",
      width: 110,
      render: (text: any, record: any) => {
        const { checkDate, checkDate2 } = record;
        return (
          <>
            {checkDate === false || checkDate2 === false ? (
              <div className="color-red">{text}</div>
            ) : (
              <div>{text}</div>
            )}
          </>
        );
      },
    },
    {
      title: t("time"),
      dataIndex: "Time",
      width: 110,
      render: (text: any, record: any) => {
        const { checkTime } = record;
        return (
          <>
            {checkTime === false ? (
              <div className="color-red">{text}</div>
            ) : (
              <div>{text}</div>
            )}
          </>
        );
      },
    },
  ];
  return (
    <div className="px-4 py-2 w-100 h-100">
      <Row justify="space-between" className="mt-4 mb-4">
        <Col className="d-flex flex-column justify-center">
          <h2 className="bold mb-0"> {t("import-excel")}</h2>
        </Col>
        <Col>
          <Row gutter={[12, 12]}>
            <Col>
              <Button
                style={{ height: 45, width: 120 }}
                className="button-cancel-setup"
                onClick={handleCancel}
              >
                {t("cancel")}
              </Button>
            </Col>
            <Col>
              <Button
                style={{ height: 45, width: 120 }}
                htmlType="submit"
                className="button-submit-setup"
                loading={create.isLoading}
                onClick={() => onImport()}
                disabled={diable}
              >
                {t("import")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <div style={{ height: "calc(100% - 105px)", overflowY: "auto" }}>
        {items.length > 0 && (
          <Table
            rowKey="id"
            className="pt-4 custom-table"
            dataSource={items}
            columns={columns}
            pagination={false}
            size="small"
          />
        )}
        {items.length < 1 && (
          <Dragger
            fileList={fileList}
            multiple={false}
            onChange={beforeUpload}
            beforeUpload={() => false}
            accept=".xlsx"
          >
            <p className="ant-upload-drag-icon mb-1">
              <FolderAddOutlined />
            </p>
            <p className="mb-0">{t("upload")}</p>
          </Dragger>
        )}
      </div>
    </div>
  );
};

export default ImportExcel;
