import { Col, Form, FormInstance, Row } from "antd";
import { useTranslation } from "react-i18next";
import {
  DASHBOARD_FOOD_LEFT,
  DASHBOARD_FOOD_RIGHT,
} from "../../../store/dashboard";
import { getKeyByLocale } from "../../../tools/translation";
import BarChart from "../bar-chart";
import DonutChart from "../donut-chart";
import {
  appendStartAndEndOfTime,
  DBHeader,
  ErrorPage,
  LoadingPage,
} from "../header";

const foodLeft = "foodLeft";
const foodRight = "foodRight";

const FoodSection = () => {
  const { t } = useTranslation();
  return (
    <div className="p-4 bg-white">
      <p className="mb-4 f-24 bold">{t(`menu.food`)}</p>
      <Row gutter={12}>
        <Col span={8}>
          <div className="p-3 db-border h-100">
            <DBHeader
              selectType="range"
              label={t("top-3-rooms-that-order-the-most-food")}
              formItemName={foodLeft}
            />
            <Form.Item
              shouldUpdate={(prev, next) => prev[foodLeft] !== next[foodLeft]}
              noStyle
            >
              {(form: FormInstance) => {
                const value = form.getFieldValue(foodLeft);
                return <FoodLeft value={value} />;
              }}
            </Form.Item>
          </div>
        </Col>
        <Col span={16}>
          <div className="p-3 db-border h-100">
            <DBHeader
              selectType="range"
              label={t("the-most-ordered-food-this-month")}
              formItemName={foodRight}
            />
            <Form.Item
              shouldUpdate={(prev, next) => prev[foodRight] !== next[foodRight]}
              noStyle
            >
              {(form: FormInstance) => {
                const value = form.getFieldValue(foodRight);
                return <FoodRight value={value} />;
              }}
            </Form.Item>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const FoodLeft = ({ value }: { value: any }) => {
  const params = appendStartAndEndOfTime(value);
  const { isLoading, isError, error, data } = DASHBOARD_FOOD_LEFT(params);

  if (isLoading) {
    return (
      <div style={{ height: 300 }}>
        <LoadingPage />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div style={{ height: 300 }}>
        <ErrorPage error={error} />
      </div>
    );
  }

  return (
    <div style={{ height: 300 }}>
      <DonutChart
        data={data.map((e) => {
          return { label: e.unitNumber, value: e.count };
        })}
      />
    </div>
  );
};

const FoodRight = ({ value }: { value: any }) => {
  const params = appendStartAndEndOfTime(value);
  const { isLoading, isError, error, data } = DASHBOARD_FOOD_RIGHT(params);
  const { i18n } = useTranslation();

  if (isLoading) {
    return (
      <div style={{ height: 300 }}>
        <LoadingPage />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div style={{ height: 300 }}>
        <ErrorPage error={error} />
      </div>
    );
  }

  return (
    <div style={{ height: 300 }}>
      <BarChart
        data={data.map((e) => {
          return {
            label:
              getKeyByLocale({
                data: e,
                key: "foodName",
                locale: i18n.language,
              }) || "",
            value: e.count,
          };
        })}
      />
    </div>
  );
};

export default FoodSection;
