import axios from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { IResponse } from ".";
import { throwResponse } from "../config/axios";

interface IPaymentList {
  id: number;
  createdAt: string;
  updatedAt: string;
  nameEn: string;
  nameTh: string;
  status: string;
  createdBy: string;
  updatedBy: string;
}

export const GET_PAYMENT_LISTS = (params?: {}): UseQueryResult<
  IResponse<IPaymentList[]>
> => {
  return useQuery(
    ["get-payment-type-lists", params],
    async () => {
      const res = await axios.get("/api/payment-type-list", {
        params: params,
      });
      if (res.status === 200) {
        const { data, count, page, countData } = res.data;
        return { data: data, count, page, countData };
      }
      throwResponse(res);
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const GET_PAYMENT_LIST_ID = (
  id: string
): UseQueryResult<IPaymentList> => {
  return useQuery(["get-payment-type-list-id", id], async () => {
    if (!id) return;
    const res = await axios.get(`/api/payment-type-list/${id}`);
    if (res.status === 200) {
      const { data } = res.data;
      return data;
    }
    throwResponse(res);
  });
};

export const POST_PAYMENT_LIST = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post("/api/payment-type-list", data);
    if (res.status === 201) {
      return true;
    }
    throwResponse(res);
  });
};

export const PATCH_PAYMENT_LIST = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.patch(`/api/payment-type-list/${data.id}`, data);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const DELETE_PAYMENT_LIST = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/api/payment-type-list/${data.id}`);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const BULK_DELETE_PAYMENT_LISTS = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/api/payment-type-list/bulk`, {
      data: data,
    });
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};
