import ApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useTranslation } from "react-i18next";
import { Empty } from "antd";

const LineChart = ({
  data,
  // lineName,
}: {
  data: number[];
  lineName: string;
}) => {
  const { t } = useTranslation();

  const options: ApexOptions = {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 3,
    },
    grid: {
      row: {
        colors: ["#F9F9F9", "transparent"], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
      borderColor: "#F9F9F9",
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      categories: [
        t("jan"),
        t("feb"),
        t("mar"),
        t("apr"),
        t("may"),
        t("jun"),
        t("jul"),
        t("aug"),
        t("sep"),
        t("oct"),
        t("nov"),
        t("dec"),
      ],
    },
    colors: ["#B69E4F"],
  };

  if (data.length <= 0 || data.every((e) => e === 0)) {
    return (
      <div className="h-100">
        <Empty />
      </div>
    );
  }

  return (
    <div className="h-100">
      <ApexChart
        options={options}
        series={[
          {
            name: t('amount'),
            data: data,
          },
        ]}
        type="line"
        width="100%"
        height={300}
      />
    </div>
  );
};

export default LineChart;
