import { TFunction } from "react-i18next";

interface _ {
  rule: RegExp;
  tooltip: string;
}

export interface IRules {
  eng: _;
  password: _;
  passport: _;
  number: _;
}

export const validateMessages = {
  required: "กรุณากรอกข้อมูล",
  types: {
    email: "รูปแบบอีเมลไม่ถูกต้อง",
    number: "รูปแบบตัวเลขไม่ถูกต้อง (0-9)",
  },
  number: {},
  string: {
    // eslint-disable-next-line no-template-curly-in-string
    min: "จำนวนตัวอักษรขั้นต่ำ ${min}",
    // eslint-disable-next-line no-template-curly-in-string
    max: "จำนวนตัวอักษรสูงสุด ${max}",
  },
};

export const VALIDATE_MESSAGES = (t: TFunction) => {
  // const { t } = useTranslation();

  return {
    required: t("validate.required"),
    types: {
      email: t("validate.email"),
      number: t("validate.number"),
    },
    string: {
      min: t("validate.min-string"),
      max: t("validate.max-string"),
    },
  };
};

export const rules: IRules = {
  eng: {
    rule: /^(?!.*[\u0E01-\u0E5B]).*$/,
    tooltip: "validate.eng",
  },
  password: {
    rule: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@$!%*#?&])(?=.{8,})/gm,
    tooltip: "validate.password",
  },
  passport: {
    rule: /^[A-Za-z][A-Za-z0-9]*$/,
    tooltip: "validate.passport",
  },
  number: {
    rule: /^[0-9]*$/gm,
    tooltip: "validate.number",
  },
};
