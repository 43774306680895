import React from "react";

const HOMECARE = (props: React.SVGProps<SVGSVGElement> = {}) => {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141.73 141.73"
      {...props}
    >
      <path
        data-name="Path 20621"
        d="M9.51 48.14V139h43.83V84.5H88.4V139h43.82V48.14L70.87 2.69z"
        fill="#b59d4e"
      />
    </svg>
  );
};

export default HOMECARE;
