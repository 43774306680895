import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Divider, Form, Input, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  GET_REPAIRING_COMMENT,
  POST_REPAIRING_COMMENT,
} from "../../../store/repairing";
import NoProfile from "../../../assets/images/no_profile.png";
import { swalError, swalSuccess } from "../../../tools/swal";
import { useQueryClient } from "react-query";
import { parse } from "../../../tools/datetime";
import InfiniteScroll from "react-infinite-scroll-component";

const RepairingComment = ({ id, i18n }: { id: string; i18n: any }) => {
  const { t } = useTranslation();
  const [commentForm] = useForm();
  const client = useQueryClient();
  const { data } = GET_REPAIRING_COMMENT({ repairing: parseInt(id) });
  const create = POST_REPAIRING_COMMENT();

  useEffect(() => {}, [data, id]);

  const onFinish = (values: any) => {
    if (!values.comment) return false;
    values = { ...values, repairing: parseInt(id) };
    create.mutate(values, {
      onSuccess: () => {
        client.invalidateQueries("get-repairing-comment");
        swalSuccess(t);
        commentForm.setFieldsValue({ comment: undefined });
      },
      onError: () => {
        swalError(t, t("swal.please-complete-the-information"));
      },
    });
  };

  return (
    <>
      <Row className="w-100 px-4">
        <Col span={24} className="mb-5">
          <span className="f-16">{t("comment")}</span>
          <InfiniteScroll
            dataLength={data?.data.length || 0}
            hasMore={true}
            loader={() => {}}
            height="40vh"
            next={() => {}}
            style={{
              overflowX: "hidden",
              width: "100%",
              border: "1px solid #e4e9f2",
            }}
            className="p-4 mt-2"
          >
            <Row>
              {data?.data.map((item, index) => {
                const { createdBy, comment, createdAt } = item;
                const { fullName, avatar } = createdBy;
                const result = index + 1;
                return (
                  <Col className="w-100" key={index}>
                    <Row>
                      <Avatar
                        size="large"
                        src={avatar?.imageUrl || NoProfile}
                        style={{ backgroundColor: "#F4F4F4" }}
                        icon={<UserOutlined style={{ color: "#D1D1D1" }} />}
                      />
                      <span className="d-flex flex-column justify-center pl-2 color-primary">
                        {fullName}
                      </span>
                      <span className="d-flex flex-column justify-center pl-2 color-primary">
                        {createdAt
                          ? parse(
                              createdAt,
                              i18n.language,
                              `${
                                i18n.language === "en"
                                  ? "DD MMM YYYY HH:mm"
                                  : "DD MMM BBBB HH:mm"
                              }`
                            )
                          : "-"}
                      </span>
                    </Row>
                    <Row>
                      <Col span={2}></Col>
                      <Col className="f-16">{comment ? comment : "-"}</Col>
                    </Row>
                    {result < data.data.length && <Divider />}
                  </Col>
                );
              })}
            </Row>
          </InfiniteScroll>
        </Col>
        <Col className="w-100">
          <Form
            layout="vertical"
            onFinish={onFinish}
            className="w-100"
            form={commentForm}
          >
            <Row gutter={[24, 24]} className="">
              <Col span={24}>
                <Form.Item name="comment" className="custom-input">
                  <Input.TextArea rows={4} />
                </Form.Item>
              </Col>
              <Row className="w-100" justify="space-between">
                <Col span={12}></Col>
                <Col span={12} className="d-flex justify-end pr-4">
                  <Button
                    className="button-add-comment"
                    htmlType="submit"
                    loading={create.isLoading}
                  >
                    {t("add-comment")}
                  </Button>
                </Col>
              </Row>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default RepairingComment;
