import { LOCAL_TOKEN_KEY, throwResponse } from "./../config/axios";
import { useMutation, UseMutationResult } from "react-query";
import axios from "axios";
import { IUser } from ".";

export const USER_PROFILE_KEY = "intercon-user-profile";

export const GET_USER_PROFILE = (): IUser => {
  const profile = localStorage.getItem(USER_PROFILE_KEY);
  return JSON.parse(profile || "{}");
};

export const SET_USER_PROFILE = (profile: IUser) => {
  const stringtify = JSON.stringify(profile);
  localStorage.setItem(USER_PROFILE_KEY, stringtify);
};

export const CLEAR_STORAGE = (withReload: boolean = true) => {
  localStorage.removeItem(LOCAL_TOKEN_KEY);
  localStorage.removeItem(USER_PROFILE_KEY);
  if (withReload) {
    window.location.reload();
  }
};

interface ILoginRes {
  payload: IUser;
  accessToken: string;
}

export const LOGIN = (): UseMutationResult<ILoginRes> => {
  return useMutation(async ({ usr, pwd }: any) => {
    const res = await axios.post("/api/auth/login", {
      email: usr,
      password: pwd,
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const FORGET_PASSWORD = (): UseMutationResult => {
  return useMutation(async ({ email }: any) => {
    const res = await axios.post(`/api/auth/forgot-password`, { email });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const RESET_PASSWORD = (): UseMutationResult => {
  return useMutation(async (values: any) => {
    const res = await axios.post("/api/auth/reset-password", values);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const TO_LOGIN_PAGE = () => {
  CLEAR_STORAGE();
};
