import { MinusCircleOutlined } from "@ant-design/icons";
import { JSXElementConstructor } from "react";
import { Button, Col, Divider, Form, Input, InputNumber, Row } from "antd";
import { useTranslation } from "react-i18next";
import CustomSelect from "./custom-select";

type TSearchInput = "input" | "number" | "select";

export interface IInputArray {
  name: string;
  label: string;
  input?: TSearchInput;
  placeholder?: string;
  props?: any;
  disabled?: boolean;
  calculate?: boolean;
  demical?: boolean;
}

const InputArray = ({
  formName,
  form,
  inputs,
  totalPrice = true,
  totalLabel,
  calculateTotalPrice,
  req = true,
  span = 0,
}: {
  formName: string;
  form: any;
  inputs: IInputArray[];
  totalPrice?: boolean;
  totalLabel?: any;
  calculateTotalPrice?: () => void;
  req?: boolean;
  span?: number;
}) => {
  const { t } = useTranslation();

  const LabelInput = () => {
    if (span === 0) {
      span = 24 / (inputs.length + 1);
    }

    return (
      <div className="w-100 py-2 d-flex justify-center">
        <Row gutter={[24, 24]} className="w-100 px-2">
          {inputs.map((item, index) => {
            return (
              <Col span={span}>
                <div style={{ borderLeft: "1px solid #e4e9f2" }}>
                  <span className="color-gold ml-2">{req && "* "}</span>{" "}
                  {t(item.label)}
                </div>
              </Col>
            );
          })}
          {totalPrice && (
            <Col span={span}>
              <div style={{ borderLeft: "1px solid #e4e9f2" }}>
                <span className="color-gold ml-2">{req && "* "}</span>{" "}
                {t("total-price")}
              </div>
            </Col>
          )}
        </Row>
      </div>
    );
  };

  const ValueInput = () => {
    const getInput = (type: TSearchInput): JSXElementConstructor<any> => {
      switch (type) {
        case "input":
          return Input;
        case "select":
          return CustomSelect;
        case "number":
          return InputNumber;
      }
    };

    return (
      <>
        <div className="w-100 px-2 py-2 d-flex align-center flex-column">
          <Form.List name={formName}>
            {(fields, { add, remove }) => {
              if (!fields.length && req) add();
              if (span === 0) {
                //check no total
                span = 24 / (inputs.length + 1);
              }
              // const span = 24 / (inputs.length + 1);

              return (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <Row
                      gutter={[24, 24]}
                      key={key}
                      className="w-100 pb-2 pt-3"
                    >
                      {inputs.map((item) => {
                        const INPUT = getInput(item.input || "input");
                        const placeholder = item.placeholder
                          ? item.placeholder
                          : item.label;

                        return (
                          <Col span={span}>
                            <Form.Item
                              {...restField}
                              name={[name, item.name]}
                              rules={[{ required: true }]}
                              className="custom-input custom-input-number custom-select-ant"
                            >
                              <INPUT
                                {...item.props}
                                placeholder={t(placeholder)}
                                allowClear={false}
                                onChange={item.calculate ? onChange : undefined}
                                step="0.01"
                                formatter={(value: any) => {
                                  return `${Number(value)}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  );
                                }}
                              />
                            </Form.Item>
                          </Col>
                        );
                      })}
                      {totalPrice && (
                        <Col span={span - 1}>
                          <Form.Item
                            {...restField}
                            name={[name, "totalPrice"]}
                            className="custom-input-number"
                          >
                            <InputNumber
                              disabled
                              placeholder={t("total-price")}
                              formatter={(value) =>
                                `${Number(value).toFixed(2)}`.replace(
                                  /\B(?=(\d{3})+(?!\d))/g,
                                  ","
                                )
                              }
                            />
                          </Form.Item>
                        </Col>
                      )}
                      <Col span={1} className="d-flex align-center">
                        {!!key && req && (
                          <MinusCircleOutlined
                            onClick={() => {
                              remove(name);
                              onChange();
                            }}
                          />
                        )}
                        {req === false && ( // show delete fields
                          <MinusCircleOutlined
                            onClick={() => {
                              remove(name);
                              // if (onDeleteOptional) onDeleteOptional();
                            }}
                          />
                        )}
                      </Col>
                      <Divider
                        style={{ margin: 0, minWidth: "98%", width: "98%" }}
                      />
                    </Row>
                  ))}
                  <div className="w-100 my-2 pt-2" style={{ paddingLeft: 12 }}>
                    <Form.Item>
                      <Button
                        className="button-save"
                        style={{ height: 45, width: 120 }}
                        onClick={() => add()}
                      >
                        {t("add-list")}
                      </Button>
                    </Form.Item>
                  </div>
                </>
              );
            }}
          </Form.List>
        </div>
        <div className="py-2">
          <Divider style={{ margin: 0, minWidth: "98%", width: "98%" }} />
          <div className="px-3 pb-2 d-flex justify-end">
            {totalLabel && totalPrice && (
              <div
                className="d-flex align-end mr-4"
                style={{ paddingBottom: 12 }}
              >
                {totalLabel()}
              </div>
            )}
            {totalPrice && (
              <>
                <Form.Item
                  name="amount"
                  label={`${t("total")}`}
                  rules={[{ required: true }]}
                  className="custom-input-number pt-3"
                >
                  <InputNumber
                    disabled
                    style={{ width: 250 }}
                    placeholder={`${t("total")}`}
                    formatter={(value) =>
                      `${Number(value).toFixed(2)}`.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      )
                    }
                  />
                </Form.Item>
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  const onChange = () => {
    if (!calculateTotalPrice) return;
    const formData = form.getFieldsValue();
    formData[formName] = calculateTotalPrice();
    const amount = formData[formName]
      .map((item: any) => item.totalPrice)
      .reduce((a: number, b: number) => a + b, 0);
    form.setFieldsValue({ ...formData, amount });
  };

  return (
    <div className="w-100 px-3 py-3">
      <LabelInput />
      <div className="d-flex justify-center pt-2">
        <Divider style={{ margin: 0, minWidth: "98%", width: "98%" }} />
      </div>
      <ValueInput />
    </div>
  );
};

export default InputArray;
