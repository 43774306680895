import {
  Col,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
} from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import PageHeaderEdit from "../../../components/page-header-create";
import { validateMessages } from "../../../config/validateMessage";
import { LAUNDRY_LIST_PATH } from "../../../router/path";
import {
  GET_LAUNDRY_LIST_ID,
  PATCH_LAUNDRY_LIST,
  POST_LAUNDRY_LIST,
} from "../../../store/laundry-list";
import { swalError, swalSuccess } from "../../../tools/swal";

const LaundryListEditPage = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams<{ id?: string }>();
  const { data } = GET_LAUNDRY_LIST_ID(id);
  const post_laundry_list = POST_LAUNDRY_LIST();
  const update_laundry_list = PATCH_LAUNDRY_LIST();
  const client = useQueryClient();

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data, form]);

  const onFinish = (values: any) => {
    values.laundryPrice = +`${values?.laundryPrice || 0}`;
    values.dryCleaningPrice = +`${values?.dryCleaningPrice || 0}`;
    values.pressingOnlyPrice = +`${values?.pressingOnlyPrice || 0}`;
    if (id) {
      const dataId = { ...values, id: +id };
      update_laundry_list.mutate(dataId, {
        onError: (err: any) => {
          swalError(t, t("swal.please-complete-the-information"));
        },
        onSuccess: () => {
          client.invalidateQueries("get-luandry-lists");
          client.invalidateQueries(["get-laundry-list", id]);
          swalSuccess(t);
          history.goBack();
        },
      });
    } else {
      post_laundry_list.mutate(values, {
        onSuccess: () => {
          swalSuccess();
          history.replace(LAUNDRY_LIST_PATH.index);
          client.invalidateQueries("get-luandry-lists");
        },
        onError: ({ message }: any) => {
          swalError(t, message);
        },
      });
    }
  };

  return (
    <div className="bg-white color-black pb-1">
      <Form
        layout="vertical"
        form={form}
        validateMessages={validateMessages}
        className="main-input-form custom-input"
        onFinish={onFinish}
      >
        <PageHeaderEdit
          label="menu.laundry-list"
          isLoading={
            post_laundry_list.isLoading || update_laundry_list.isLoading
          }
        />
        <FormEdit form={form} />
      </Form>
    </div>
  );
};

const FormEdit = ({ form }: { form: any }) => {
  const { t } = useTranslation();

  // const getSvcPrice = () => {
  //   const formData = form.getFieldsValue();
  //   const { svc, dryCleaningPrice, laundryPrice, pressingOnlyPrice } = formData;
  //   let dry = 0;
  //   let laundry = 0;
  //   let pressing = 0;

  //   if (svc) {
  //     dry = (svc / 100) * +dryCleaningPrice + +dryCleaningPrice;
  //     laundry = (svc / 100) * +laundryPrice + +laundryPrice;
  //     pressing = (svc / 100) * +pressingOnlyPrice + +pressingOnlyPrice;
  //   }

  //   return { dry, laundry, pressing };
  // };

  // const getVatPrice = () => {
  //   const formData = form.getFieldsValue();
  //   const { svc, dryCleaningPrice, laundryPrice, pressingOnlyPrice, vat } =
  //     formData;
  //   const scvPrice = getSvcPrice();
  //   let dry = 0;
  //   let laundry = 0;
  //   let pressing = 0;

  //   if (svc && vat) {
  //     dry = (vat / 100) * +scvPrice.dry + +scvPrice.dry;
  //     laundry = (vat / 100) * +scvPrice.laundry + +scvPrice.laundry;
  //     pressing = (vat / 100) * +scvPrice.pressing + +scvPrice.pressing;
  //   }

  //   if (!svc && vat) {
  //     dry = (vat / 100) * +dryCleaningPrice + +dryCleaningPrice;
  //     laundry = (vat / 100) * +laundryPrice + +laundryPrice;
  //     pressing = (vat / 100) * +pressingOnlyPrice + +pressingOnlyPrice;
  //   }

  //   return { dry, laundry, pressing };
  // };

  return (
    <Row gutter={[24, 24]} className="px-4 mb-4 pb-2">
      <Col span={12}>
        <Form.Item
          name="nameEn"
          label={t("nameEn")}
          rules={[{ required: true }, { max: 100 }]}
        >
          <Input placeholder={t("nameEn")} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="nameTh"
          label={t("nameTh")}
          rules={[{ required: true, max: 100 }]}
        >
          <Input placeholder={t("nameTh")} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="status"
          rules={[{ required: true }]}
          label={`${t("status")}`}
          className="custom-select-ant "
          initialValue="ACTIVE"
        >
          <Select
            placeholder={`${t("status")}`}
            getPopupContainer={(e) => e}
            defaultValue="ACTIVE"
          >
            <Select.Option value="ACTIVE">{t("active")}</Select.Option>
            <Select.Option value="INACTIVE">{t("inactive")}</Select.Option>
          </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          shouldUpdate={(prev, cur) =>
            prev.isComplimentary !== cur.isComplimentary
          }
          className="w-100"
        >
          {(_form: FormInstance) => {
            const checked = form.getFieldValue("isComplimentary");

            return (
              <Form.Item name="isComplimentary" label={t("is-complimentary")}>
                <Switch checked={checked} />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={12}>
        <SwitchWithTextInput
          name="dryCleaningPrice"
          label="dry-cleaning"
          form={form}
          placeholder="dry-cleaning"
        />
      </Col>
      <Col span={12}>
        <SwitchWithTextInput
          form={form}
          name="laundryPrice"
          label={t("menu.laundry")}
          placeholder="menu.laundry"
        />
      </Col>
      <Col span={12}>
        <SwitchWithTextInput
          form={form}
          name="pressingOnlyPrice"
          label={t("pressing-only")}
          placeholder="pressing-only"
        />
      </Col>
      <Col span={12}></Col>
      {/* <Col span={12}>
        <SwitchWithTextInput
          form={form}
          name="svc"
          label={t("svc")}
          baht={false}
          placeholder={t("svc")}
        />
        <Row gutter={24}>
          <Col span={4}></Col>
          <Col span={20}>
            <Form.Item
              shouldUpdate={(prev, cur) =>
                prev.svc !== cur.svc ||
                prev.dryCleaningPrice !== cur.dryCleaningPrice ||
                prev.laundryPrice !== cur.laundryPrice ||
                prev.pressingOnlyPrice !== cur.pressingOnlyPrice
              }
            >
              {(_form: FormInstance) => {
                const svcPrice = getSvcPrice();
                return (
                  <div className="mt-2">
                    {!!svcPrice.dry && (
                      <div>
                        -
                        <span className="ml-2">
                          {t("dry-cleaning")} {svcPrice.dry} {t("baht")}
                        </span>
                      </div>
                    )}
                    {!!svcPrice.laundry && (
                      <div>
                        -
                        <span className="ml-2">
                          {t("laundry")} {svcPrice.laundry} {t("baht")}
                        </span>
                      </div>
                    )}
                    {!!svcPrice.pressing && (
                      <div>
                        -
                        <span className="ml-2">
                          {t("pressing-only")} {svcPrice.pressing} {t("baht")}
                        </span>
                      </div>
                    )}
                  </div>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col span={12}>
        <SwitchWithTextInput
          form={form}
          name="vat"
          label={t("vat")}
          baht={false}
          placeholder={t("vat")}
        />
        <Row gutter={24}>
          <Col span={4}></Col>
          <Col span={20}>
            <Form.Item
              shouldUpdate={(prev, cur) =>
                prev.svc !== cur.svc ||
                prev.vat !== cur.vat ||
                prev.dryCleaningPrice !== cur.dryCleaningPrice ||
                prev.laundryPrice !== cur.laundryPrice ||
                prev.pressingOnlyPrice !== cur.pressingOnlyPrice
              }
            >
              {(_form: FormInstance) => {
                const vatPrice = getVatPrice();
                return (
                  <div className="mt-2">
                    {!!vatPrice.dry && (
                      <div>
                        -
                        <span className="ml-2">
                          {t("dry-cleaning")} {vatPrice.dry} {t("baht")}
                        </span>
                      </div>
                    )}
                    {!!vatPrice.laundry && (
                      <div>
                        -
                        <span className="ml-2">
                          {t("laundry")} {vatPrice.laundry} {t("baht")}
                        </span>
                      </div>
                    )}
                    {!!vatPrice.pressing && (
                      <div>
                        -
                        <span className="ml-2">
                          {t("pressing-only")} {vatPrice.pressing} {t("baht")}
                        </span>
                      </div>
                    )}
                  </div>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      </Col> */}
    </Row>
  );
};

const SwitchWithTextInput = ({
  form,
  name,
  label,
  baht = true,
  placeholder,
}: {
  form: any;
  name: string;
  label: string;
  baht?: boolean;
  placeholder: string;
}) => {
  const { t } = useTranslation();
  const requireField =
    "require" + name.charAt(0).toUpperCase() + name.slice(1).split("Price")[0];
  const onChange = (value: boolean) => {};

  return (
    <Row align="bottom" justify="center">
      <Col span={3} className="pb-2">
        <Form.Item
          shouldUpdate={(prev, cur) => prev[requireField] !== cur[requireField]}
          className="w-100"
        >
          {(_form: FormInstance) => {
            const checked = form.getFieldValue(requireField);

            return (
              <Form.Item name={requireField} className="custom-ant-switch">
                <Switch checked={checked} onChange={onChange} />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={18}>
        <Form.Item
          shouldUpdate={(prev, cur) => prev[requireField] !== cur[requireField]}
          className="w-100"
        >
          {(_form: FormInstance) => {
            const disabled = form.getFieldValue(requireField);

            return (
              <Form.Item
                name={name}
                label={t(label)}
                rules={[{ required: disabled }]}
                className="custom-input-number"
              >
                <InputNumber
                  disabled={!disabled}
                  placeholder={t(placeholder)}
                  min={0}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={1}></Col>
      <Col span={1} style={{ paddingBottom: 12 }}>
        {baht ? t("baht") : "%"}
      </Col>
    </Row>
  );
};

export default LaundryListEditPage;
