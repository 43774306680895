import Sidebar from "./sidebar";
import Navbar from "./navbar";
import Content from "./content";
import { Layout } from "antd";
import NotificationProvider from "../store/notification";

const AppLayout = ({ ...props }) => {
  return (
    <NotificationProvider>
      <Layout className="h-100">
        <Sidebar />
        <Layout>
          <Navbar />
          <Content />
        </Layout>
      </Layout>
    </NotificationProvider>
  );
};

export default AppLayout;
