import { ColumnsType } from "antd/lib/table";
import numeral from "numeral";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import CustomStatusBackground from "../../../components/custom-status";
import CustomTable from "../../../components/custom-table";
import PageFilter, { IPageFilterChoice } from "../../../components/page-filter";
import PageHeader, { ISearchInput } from "../../../components/page-header";
import { SETTING_FACILITY_CREATE_PATH } from "../../../router/path";
import { GET_FACILITY_CATEGORY } from "../../../store";
import {
  BULK_DELETE_FACILITY,
  DELETE_FACILITY,
  GET_FACILITY,
  IFacility,
  IFacilityParams,
} from "../../../store/facility";
import { findStartOrEndTime } from "../../../tools/datetime";
import { swalDelete, swalError, swalSuccess } from "../../../tools/swal";
import { getKeyByLocale } from "../../../tools/translation";

const FacilityPage = () => {
  const [params, setParams] = useState<IFacilityParams>({ page: 1, limit: 10 });
  const { data, isLoading } = GET_FACILITY(params);
  const get_category = GET_FACILITY_CATEGORY();
  const delete_facility = DELETE_FACILITY();
  const bulk_delete_facility = BULK_DELETE_FACILITY();
  const client = useQueryClient();
  const { t, i18n } = useTranslation();

  const searchInput: ISearchInput[] = [
    {
      label: t("search"),
      key: "search",
      span: 10,
      type: "input",
      props: {
        placeholder: t("search-name-EN-TH"),
      },
    },
    {
      label: t("category"),
      key: "facilityCategory",
      span: 7,
      type: "select",
      props: {
        placeholder: t("all"),
        optionValue: {
          values:
            get_category.data?.data.map((i) => {
              return {
                ...i,
                name: i18n.language === "en" ? i.nameEn : i.nameTh,
              };
            }) || [],
          key: "id",
          name: "name",
        },
      },
    },
    {
      label: t("creation-date"),
      key: "date",
      span: 7,
      type: "range-picker",
      props: {
        placeholder: [t("start-date"), t("end-date")],
        getPopupContainer: (props: HTMLElement) => {
          return props;
        },
      },
    },
  ];
  const choices: IPageFilterChoice[] = [
    {
      label: t("all"),
      total: data?.countData?.countAll || 0,
    },
    {
      label: t("active"),
      key: "ACTIVE",
      total: data?.countData?.countActive || 0,
    },
    {
      label: t("inactive"),
      key: "INACTIVE",
      total: data?.countData?.countInActive || 0,
    },
    {
      label: t("draft"),
      key: "DRAFT",
      total: data?.countData?.countDraft || 0,
    },
  ];

  const columns: ColumnsType<IFacility> = [
    {
      dataIndex: i18n.language === "en" ? "nameEn" : "nameTh",
      title: t("name"),
      width: "20%",
      render: (text, __) => {
        return <div className="line-clamp">{text || "-"}</div>;
      },
    },
    {
      title: t("open-hours"),
      align: "center",
      width: 150,
      render: (_, record) => {
        const { openTime, closeTime } = record;
        if (!openTime || !closeTime) return "-";
        return `${openTime.slice(0, 5)} - ${closeTime.slice(0, 5)}`;
      },
    },
    {
      dataIndex: "capacity",
      align: "center",
      title: t("capacity"),
      render: (text, _) => numeral(text).format("0,0") || "-",
    },
    {
      dataIndex: "category",
      title: t("category"),
      align: "center",
      width: 200,
      render: (_, record) => {
        return (
          <CustomStatusBackground
            lable={
              record.facilityCategory
                ? getKeyByLocale({
                    data: record.facilityCategory,
                    key: "name",
                    locale: i18n.language,
                  })
                : "-"
            }
            className={"frame-default-background-overflow"}
          />
        );
      },
    },
    {
      dataIndex: "status",
      title: t("status"),
      render: (text) => {
        if (!text) return "-";
        if (text === "ACTIVE") {
          return (
            <CustomStatusBackground
              lable={t("active")}
              className={"frame-background"}
            />
          );
        } else if (text === "INACTIVE") {
          return (
            <CustomStatusBackground
              lable={t("inactive")}
              className={"frame-no-background"}
            />
          );
        } else if (text === "DRAFT") {
          return (
            <CustomStatusBackground
              lable={t("draft")}
              className={"frame-no-background"}
            />
          );
        }
      },
    },
  ];

  const onSearch = (values: any) => {
    if (!values.date) {
      values.date = [];
    }
    const { date } = values;

    const startDate = findStartOrEndTime(date[0], "start");
    const endDate = findStartOrEndTime(date[1], "end");
    values = { ...values, startDate, endDate };
    delete values.date;

    setParams({ ...params, ...values });
  };

  const onDelete = async (id: any) => {
    const confirm = await swalDelete();
    if (confirm) {
      delete_facility.mutate(
        { id: id },
        {
          onSuccess: () => {
            if (params.page > 1 && data?.data.length === 1) {
              setParams({ ...params, page: params.page - 1 });
            }

            swalSuccess();
            client.invalidateQueries("get-facilities");
          },
          onError: (error: any) => {
            swalError(t, t(error.message));
          },
        }
      );
    }
  };

  const onFilter = (choice: IPageFilterChoice) => {
    setParams({ ...params, status: choice.key });
  };

  const onBulkDeleteSuccess = (length: number) => {
    if (length === data?.data.length && params.page > 1) {
      setParams({ ...params, page: params.page - 1 });
    }
  };

  return (
    <div>
      <PageHeader
        menu="setting-facility"
        onSearch={onSearch}
        searchInput={searchInput}
        label="create"
        utility={["delete"]}
        path={SETTING_FACILITY_CREATE_PATH}
        bulkDelete={bulk_delete_facility}
        invalidateQueries={["get-facilities"]}
        onBulkDeleteSuccess={onBulkDeleteSuccess}
      />
      <PageFilter
        choices={choices}
        onClick={onFilter}
        current={params.status}
      />
      <CustomTable
        title={t("facility-list")}
        dataSource={data?.data || []}
        isLoadingTable={isLoading}
        columns={columns}
        pagination={{
          limit: params.limit,
          page: params.page,
          total: data?.count || 0,
        }}
        onPageChange={(page, limit) => {
          setParams({ ...params, page, limit });
        }}
        onDelete={onDelete}
      />
    </div>
  );
};

export default FacilityPage;
