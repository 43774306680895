import i18n from "i18next";
import dayjs, { Dayjs } from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advanced from "dayjs/plugin/advancedFormat";
import "dayjs/locale/en";
import "dayjs/locale/th";

dayjs.locale(i18n.language);
dayjs.extend(buddhistEra);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

export const getNow = () => {
  return dayjs();
};

export const parse = (
  date: any,
  language: any,
  format: string = "DD/MM/BBBB"
) => {
  return dayjs(date).locale(language).format(format);
};

export const parseRepairman = (
  date: any,
  language: any,
  format: string = "DD/MM/BBBB"
) => {
  return dayjs.utc(date).locale(language).format(format);
};

export const findStartOrEndTime = (date?: any, mode?: "start" | "end") => {
  if (!date) return undefined;
  if (mode === "start") {
    return dayjs(date).set("hour", 0).set("minute", 0).set("second", 0);
  }
  return dayjs(date).set("hour", 23).set("minute", 59).set("second", 59);
};

export const dateByOnlyTime = (time: string): Dayjs => {
  const now = dayjs().format();
  const s = now.substring(0, 11);
  const trim = `${s}${time}:00`;
  return dayjs(trim);
};

export const getClientTimeZone = () => {
  return dayjs.tz.guess();
};