import { Row, Col, Button, Table, Divider } from "antd";
import { useState } from "react";
import { useHistory } from "react-router";
import QrReader from "react-qr-reader";
import { ExportOutlined, LeftOutlined, ScanOutlined } from "@ant-design/icons";
import MobileHeader from "../../../components/page-header-mobile";
import {
  GET_PARCEL_ID_MOBILE,
  PATCH_POST_PARCEL_CHECKOUT,
} from "../../../store";
import { swalError, swalSuccess } from "../../../tools/swal";
import { SCAN_QRCODE_PATH } from "../../router-mobile/path";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";

const ListParcel = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [dataSource, setvalue] = useState<any[]>([]);
  const [isShowScanQR, setIsShowScanQR] = useState(true);
  const [back, setBack] = useState(true);
  const { mutate } = PATCH_POST_PARCEL_CHECKOUT();

  const handleError = (err: any) => {
    swalError(err);
  };

  const handleScan = (id: any) => {
    if (id) {
      const data = GET_PARCEL_ID_MOBILE(id);
      data.then((result) => {
        if (result.success && result.data) {
          setvalue([
            ...dataSource,
            {
              id: result.data.id,
              parcelNumber: result.data.parcelNumber,
              roomNumber: result.data.unit?.unitNumber,
            },
          ]);
          setBack(false);
          setIsShowScanQR(false);
        } else {
          setBack(false);
          setIsShowScanQR(false);
          swalError(t, t("swal.error-scan-parcel"));
        }
      });
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "#",
      render: (item, rc, index) => {
        return index + 1;
      },
    },
    {
      title: `${t("mobile.parcel-number")}`,
      dataIndex: "parcelNumber",
    },
    {
      title: `${t("mobile.house-number")}`,
      dataIndex: "roomNumber",
      render: (text, record) => {
        return <>{text || "-"}</>;
      },
    },
  ];

  const onClickBack = () => {
    if (back) return history.goBack();
    return setIsShowScanQR(false);
  };

  const onSubmit = () => {
    const ids = dataSource.map((item) => item.id);
    mutate(
      { ids: ids },
      {
        onError: (err: any) => {
          swalError(err);
        },
        onSuccess: () => {
          swalSuccess();
          history.push(SCAN_QRCODE_PATH);
        },
      }
    );
  };

  return (
    <div className="w-100 h-100">
      {isShowScanQR && (
        <ScanQRCode
          handleError={handleError}
          handleScan={handleScan}
          onClickBack={onClickBack}
        />
      )}

      {isShowScanQR === false && (
        <>
          <MobileHeader />
          <Row className="mb-3 w-100 px-3 mt-4 pt-6 pb-1">
            <Col span={11}>
              <Button
                icon={<ScanOutlined className="f-14" />}
                className="button-scan-mobile f-14 mt-0 w-100"
                style={{ height: 35 }}
                onClick={() => {
                  setIsShowScanQR(true);
                }}
              >
                {t("mobile.scan")}
              </Button>
            </Col>
            <Col span={2}></Col>
            <Col span={11}>
              <Button
                icon={<ExportOutlined className="f-14" />}
                className="button-scan-mobile f-14 mt-0 w-100"
                style={{ height: 35 }}
                onClick={() => {
                  onSubmit();
                }}
              >
                {t("mobile.submit")}
              </Button>
            </Col>
          </Row>
          <Row className="mb-1 w-100 px-3 w-100">
            <span className="f-18">{t("mobile.parcel-list")}</span>
          </Row>
          <Row className="mb-3 w-100 px-3 w-100">
            <Table
              dataSource={dataSource}
              columns={columns}
              className="custom-table w-100"
            />
          </Row>
        </>
      )}
    </div>
  );
};

const ScanQRCode = ({
  handleError,
  handleScan,
  onClickBack,
}: {
  handleError: (err: any) => void;
  handleScan: (data: any) => void;
  onClickBack: () => void;
}) => {
  return (
    <div
      className="w-100 h-100 mt-0"
      style={{ backgroundColor: "transparent" }}
    >
      <Row className="p-0 h-100">
        <Button
          className="button-back-mobile z-index-button mt-2 ml-3"
          icon={<LeftOutlined className="f-18 " />}
          onClick={onClickBack}
        ></Button>
        <Row className=" line-border ">
          <Divider />
        </Row>
        <QrReader
          showViewFinder={true}
          delay={100}
          onError={handleError}
          onScan={handleScan}
          style={{ width: "100%", height: "90%" }}
          className="h-100 custom-scan-qr"
        />
      </Row>
    </div>
  );
};

export default ListParcel;
