import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Divider, Empty, Form, Input, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  GET_REPAIRING_COMMENT,
  POST_REPAIRING_COMMENT,
} from "../../../store/repairing";
import NoProfile from "../../../assets/images/no_profile.png";
import { swalError, swalSuccess } from "../../../tools/swal";
import { useQueryClient } from "react-query";
import { parse } from "../../../tools/datetime";
import InfiniteScroll from "react-infinite-scroll-component";
import { ClipLoader } from "react-spinners";
import ErrorPage from "../../../pages/page-error-mobile";

const RepairingComment = ({
  id,
  i18n,
  modalComment,
}: {
  id: string;
  i18n: any;
  modalComment: boolean;
}) => {
  const { t } = useTranslation();
  const [commentForm] = useForm();
  const client = useQueryClient();
  const { data, isError, isLoading } = GET_REPAIRING_COMMENT({
    repairing: parseInt(id),
  });
  const create = POST_REPAIRING_COMMENT();

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, id]);

  if (isLoading) {
    return (
      <div style={{ height: "calc(100vh - 77px)" }} className="px-3">
        <ClipLoader color="#b69e4f" />
      </div>
    );
  }

  if (isError) {
    return (
      <div style={{ height: "calc(100vh - 77px)" }} className="px-3">
        <ErrorPage />
      </div>
    );
  }

  if (!data) {
    return (
      <div style={{ height: "calc(100vh - 77px)" }} className="px-3">
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </div>
    );
  }

  if (modalComment) {
    commentForm.setFieldsValue({ comment: undefined });
  }

  const onFinish = (values: any) => {
    values = { ...values, repairing: parseInt(id) };
    if (values.comment) {
      create.mutate(values, {
        onSuccess: () => {
          client.invalidateQueries("get-repairing-comment");
          swalSuccess(t);
          commentForm.setFieldsValue({ comment: undefined });
        },
        onError: () => {
          swalError(t, t("swal.please-complete-the-information"));
        },
      });
    }
  };

  return (
    <>
      <Row className="w-100">
        <Col span={24} className="mb-4 mt-3">
          <InfiniteScroll
            dataLength={data?.data.length || 0}
            hasMore={true}
            loader={() => {}}
            height="50vh"
            next={() => {}}
            style={{
              overflowX: "hidden",
              width: "100%",
              border: "1px solid #e4e9f2",
            }}
            className="p-2 mt-1"
          >
            {isLoading && (
              <div
                className="px-3 h-100"
                style={{
                  width: "100%",
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ClipLoader />
              </div>
            )}
            {isError && (
              <div
                className="px-3 h-100 w-100"
                style={{
                  width: "100%",
                }}
              >
                <ErrorPage />
              </div>
            )}
            {!data.data && (
              <div
                className="px-3 h-100"
                style={{
                  width: "100%",
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
            <Row>
              {data?.data.map((item, index) => {
                const { createdBy, comment, createdAt } = item;
                const { fullName, avatar } = createdBy;
                const result = index + 1;

                if (!data.data) {
                  return <></>;
                }
                return (
                  <Col className="w-100" key={index}>
                    <Row>
                      <Avatar
                        size="default"
                        src={avatar?.imageUrl || NoProfile}
                        style={{ backgroundColor: "#F4F4F4" }}
                        icon={<UserOutlined style={{ color: "#D1D1D1" }} />}
                      />
                      <span className="d-flex flex-column justify-center pl-2 color-primary f-14">
                        {fullName}
                      </span>
                      <span className="d-flex flex-column justify-center pl-2 color-primary f-14">
                        {parse(
                          createdAt,
                          i18n.language,
                          `${
                            i18n.language === "en"
                              ? "DD MMM YYYY HH:mm a"
                              : "DD MMM BBBB HH:mm a"
                          }`
                        )}
                      </span>
                    </Row>
                    <Row>
                      <Col span={2}></Col>
                      <Col span={21} className="f-14 color-gold">
                        {comment}
                      </Col>
                    </Row>
                    {result < data.data.length && <Divider />}
                  </Col>
                );
              })}
            </Row>
          </InfiniteScroll>
        </Col>
        <Col className="w-100">
          <Form
            layout="vertical"
            onFinish={onFinish}
            className="w-100"
            form={commentForm}
          >
            <Row gutter={[24, 24]} className="">
              <Col span={24}>
                <Form.Item name="comment" className="custom-input">
                  <Input.TextArea rows={5} />
                </Form.Item>
              </Col>
              <Row className="w-100" justify="space-between">
                <Col span={12}></Col>
                <Col span={12} className="d-flex justify-end pr-4">
                  <Button
                    className="button-add-comment-mobile"
                    htmlType="submit"
                    loading={create.isLoading}
                  >
                    {t("add-comment")}
                  </Button>
                </Col>
              </Row>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default RepairingComment;
