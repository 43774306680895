import { IRepairingZone } from "./../../../store/repairing-zone";
import { IUserUnit } from "../../../store/units";
import { IRepairingCategory } from "../../../store/repairing-category";
import { IRepairingItem } from "../../../store/repairing-item";
import { Reducer } from "react";
import { IUser } from "../../../store";

export interface IRepairingState {
  unitUser?: IUserUnit[];
  repairingZone?: IRepairingZone[];
  repairingCategory?: IRepairingCategory[];
  repairingItem?: IRepairingItem[];
  repairMan?: IUser[];
}

export type TRepairingAction = {
  type:
    | "unit"
    | "repairing-zone"
    | "repairing-category"
    | "repairing-item"
    | "repair-man";
  data?: any;
};

export type TRepairingReducer = Reducer<IRepairingState, TRepairingAction>;

export const repairingReducer = (
  state: IRepairingState,
  action: TRepairingAction
) => {
  const { type, data } = action;
  switch (type) {
    case "unit":
      return { ...state, unitUser: data };
    case "repairing-item":
      return {
        ...state,
        repairingItem: data,
      };
    case "repairing-category":
      return { ...state, repairingCategory: data };
    case "repairing-zone":
      return { ...state, repairingZone: data };
    case "repair-man":
      return { ...state, repairMan: data };
  }
};
