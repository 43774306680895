import { throwResponse } from "./../config/axios";
import axios from "axios";
import {
  UseQueryResult,
  useQuery,
  useMutation,
  UseMutationResult,
} from "react-query";

export interface ILaundryTax {
  svg: string;
  vat: string;
  id: number;
}

export const GET_LAUNDRY_TAX = (): UseQueryResult<ILaundryTax[]> => {
  return useQuery(["get-laundry-tax"], async () => {
    const res = await axios.get("/api/laundry-tax");
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

export const POST_LAUNDRY_TAX = (): UseMutationResult => {
  return useMutation(async (values) => {
    const res = await axios.post(`/api/laundry-tax`, values);
    if (res.status === 201) {
      return;
    }
    throwResponse(res);
  });
};

export const PATCH_LAUNDRY_TAX = (): UseMutationResult => {
  return useMutation(async (values: any) => {
    const id = values.id;
    delete values.id;
    const res = await axios.patch(`/api/laundry-tax/${id}`, values);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
