import { FolderAddOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Table, Upload } from "antd";
import { TableProps } from "antd/lib/table";
import { RcFile } from "antd/lib/upload/interface";
import { forwardRef, Ref, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseMutationResult, useQueryClient } from "react-query";
import XLSX from "xlsx";
import { swalError, swalSuccess } from "../tools/swal";

export type TModalRef = {
  triggerVisible: () => void;
  reset: () => void;
};

interface IExcelImport {
  onFinish?: () => void;
  tableProps: TableProps<any>;
  range: {
    colStart: number;
    colEnd: number;
    rowStart: number;
  };
  headerKey: string[];
  invalidateQueries?: string[];
  mutation: UseMutationResult;
  transformJson?: (value: any[]) => any[];
  width?: string;
  payment?: boolean;
}

const ModalImportExcelUnitUser = forwardRef(
  (
    {
      onFinish,
      tableProps,
      range,
      headerKey,
      invalidateQueries,
      mutation,
      transformJson,
      width,
      payment = false,
    }: IExcelImport,
    ref: Ref<TModalRef>
  ) => {
    const { t } = useTranslation();
    const { mutate, isLoading } = mutation;
    const client = useQueryClient();

    const [visible, setVisible] = useState(false);
    const [dataSource, setDataSource] = useState<any[]>([]);

    useImperativeHandle(ref, () => {
      return {
        triggerVisible: () => {
          setVisible(!visible);
        },
        reset: () => {
          setDataSource([]);
        },
      };
    });

    const beforeUpload = (file: RcFile) => {
      const reader = new FileReader();
      const rABS = !!reader.readAsBinaryString;
      const ext = file.name.split(".").pop()?.toLocaleLowerCase();

      reader.onload = async (e) => {
        try {
          if (!["xlsx", "xls"].includes(ext || "")) return;

          const bstr = e.target?.result;
          const wb = XLSX.read(bstr, {
            type: rABS ? "binary" : "array",
            bookVBA: true,
          });
          const worksheetName = wb.SheetNames[0];
          const worksheet = wb.Sheets[worksheetName];
          let r = XLSX.utils.decode_range(wb.Sheets[worksheetName]["!ref"]!);
          r.s.c = range.colStart;
          r.e.c = range.colEnd;
          r.s.r = range.rowStart;

          const json = XLSX.utils.sheet_to_json(worksheet, {
            defval: null,
            range: XLSX.utils.encode_range(r),
            header: headerKey,
          });

          if (transformJson) {
            const nJson = transformJson(json);
            return setDataSource(nJson);
          }

          setDataSource(json);
        } catch (err: any) {
          swalError(t, err.message);
        }
      };

      if (rABS) {
        reader.readAsBinaryString(file);
      } else {
        reader.readAsArrayBuffer(file);
      }

      return false;
    };

    const dataImport = (dataSource: any[]) => {
      const result = dataSource.map((item) => {
        const email = item.email;
        const unit = item["house number"];
        const role = item.role.toUpperCase();
        return { email, unit, role };
      });
      return result;
    };

    const onUpload = () => {
      const data = dataImport(dataSource);
      const value = payment ? data : dataSource;

      mutate(value, {
        onSuccess: () => {
          client.invalidateQueries(invalidateQueries);
          swalSuccess();
          setVisible(false);
          if (onFinish) {
            onFinish();
          }
        },
        onError: ({ message }: any) => {
          swalError(t, message);
        },
      });
    };

    const UploadC = (
      <Upload.Dragger
        fileList={[]}
        beforeUpload={beforeUpload}
        accept=".xlsx, .xls"
        // onChange={onChange}
        multiple={false}
      >
        <p className="ant-upload-drag-icon mb-1">
          <FolderAddOutlined />
        </p>
        <p className="mb-0">{t("upload")}</p>
      </Upload.Dragger>
    );

    const TableC = (
      <Table
        {...tableProps}
        dataSource={dataSource}
        pagination={false}
        size="small"
        rowKey={(_, index) => `${index}`}
      />
    );

    return (
      <Modal
        closable={false}
        onCancel={() => setVisible(false)}
        centered
        footer={null}
        visible={visible}
        className="modal-import-excel"
        width={width ? width : "50vw"}
        destroyOnClose={true}
        afterClose={() => setDataSource([])}
      >
        <div className="p-4 h-100">
          <Row
            justify="space-between"
            className="pb-4"
            align="middle"
            gutter={[0, 24]}
          >
            <Col>
              <h2 className="bold mb-0"> {t("import-excel")}</h2>
            </Col>
            <Col>
              <Row gutter={[12, 12]}>
                <Col>
                  <Button
                    style={{ height: 45, width: 120 }}
                    className="button-cancel-setup"
                    onClick={() => setVisible(false)}
                  >
                    {t("cancel")}
                  </Button>
                </Col>
                <Col>
                  <Button
                    style={{ height: 45, width: 120 }}
                    loading={isLoading}
                    disabled={dataSource.length <= 0}
                    className="button-submit-setup"
                    onClick={onUpload}
                  >
                    {t("import")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <div style={{ height: "calc(100% - 65px)", overflowY: "auto" }}>
            {dataSource.length <= 0 ? UploadC : TableC}
          </div>
        </div>
      </Modal>
    );
  }
);

export default ModalImportExcelUnitUser;
