import { useQuery } from "react-query";
import { UseQueryResult } from "react-query";
import axios, { AxiosInstance } from "axios";

interface IHoliday {
  HolidayWeekDay: string;
  HolidayWeekDayThai: string;
  Date: string;
  DateThai: string;
  HolidayDescription: string;
  HolidayDescriptionThai: string;
}

export interface IHolidayParams {
  year: string;
}

export const GET_HOLIDAY = (
  params: IHolidayParams
): UseQueryResult<IHoliday[]> => {
  return useQuery(["get-holiday-date"], async () => {
    const instance = createHolidayInstance();
    const res = await instance.get("/financial-institutions-holidays", {
      params,
    });
    if (res.status === 200) {
      const { data } = res.data.result;
      return data;
    }
    throw new Error(`failed with status code ${res.status}`);
  });
};

const createHolidayInstance = (): AxiosInstance => {
  const instance = axios.create({
    headers: {
      "X-IBM-Client-Id": "f536c05f-a99e-4ee1-9cd7-a9c7b54ee30e",
      accept: "application/json",
    },
    baseURL: "https://apigw1.bot.or.th/bot/public",
  });
  return instance;
};
