import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import { LoginPage } from "./pages/login";
import AppLayout from "./layout";
import { ForgotPasswordPage } from "./pages/forgot-password";
import { isMobile } from "react-device-detect";
import AppMobile from "./mobile/App";
import { NewPasswordPage } from "./pages/new-password";
import { GET_LOCAL_TOKEN } from "./config/axios";
import { CLEAR_STORAGE, GET_USER_PROFILE } from "./store/auth";
import { useEffect } from "react";
import { appendLangToHtmlTag } from "./i18n";
import { swalError } from "./tools/swal";
import { useTranslation } from "react-i18next";

const App = () => {
  useEffect(() => {
    appendLangToHtmlTag();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HashRouter>
      <Switch>
        <Route path="/login" render={(props) => <LoginPage {...props} />} />
        <Route
          path="/forgot-password"
          render={(props) => <ForgotPasswordPage {...props} />}
        />
        <Route
          path="/new-password"
          render={(props) => <NewPasswordPage {...props} />}
        />
        <ProtectedRoute
          path="/"
          render={(props: any) => <AppLayout {...props} />}
        />
      </Switch>
    </HashRouter>
  );
};

const ProtectedRoute = (rest: any): any => {
  const { t } = useTranslation();
  const token = GET_LOCAL_TOKEN();
  const user = GET_USER_PROFILE();

  if (!token || !user) return <Redirect to="/login" />;

  if (isMobile) {
    return <Route exact={true} render={(_) => <AppMobile />} />;
  }
  if (user.role.title === "REPAIRMAN" || user.role.title === "MEMBER") {
    CLEAR_STORAGE(false);
    swalError(t, t("swal.unable-login-member-repairman"));
    return <Redirect to="/login" />;
  }

  return <Route {...rest} exact={true} />;
};

export default App;
