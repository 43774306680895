import axios from "axios";
import { useQuery, UseQueryResult } from "react-query";
import { throwResponse } from "../config/axios";

interface IMenu {
  id: number;
  code: string;
  title: string;
}

export const GET_MENUS = (params?: {}): UseQueryResult<IMenu[]> => {
  return useQuery(["menus", params], async () => {
    const res = await axios.get("/api/menu", {
      params: params,
    });
    if (res.status === 200) {
      const { data } = res.data;
      return data;
    }
    throwResponse(res);
  });
};
