import { Col, Form, Row } from "antd";
import { FormInstance } from "rc-field-form";
import { useTranslation } from "react-i18next";
import {
  DASHBOARD_NEWS_MONTH,
  DASHBOARD_NEWS_YEAR,
} from "../../../store/dashboard";
import { getKeyByLocale } from "../../../tools/translation";
import DonutChart from "../donut-chart";
import {
  appendStartAndEndOfTime,
  DBHeader,
  ErrorPage,
  LoadingPage,
} from "../header";
import LineChart from "../line-chart";

const newsLeft = "newsLeft";
const newsRight = "newsRight";

const NewsSection = () => {
  const { t } = useTranslation();
  return (
    <div className="p-4 bg-white">
      <p className="mb-4 f-24 bold">{t(`menu.news`)}</p>
      <Row gutter={12}>
        <Col span={8}>
          <div className="p-3 db-border h-100">
            <DBHeader
              selectType="range"
              label={t("dashboard-most-news")}
              formItemName={newsLeft}
            />
            <Form.Item
              shouldUpdate={(prev, next) => prev[newsLeft] !== next[newsLeft]}
              noStyle
            >
              {(form: FormInstance) => {
                const value = form.getFieldValue(newsLeft);
                return <NewsLeft value={value} />;
              }}
            </Form.Item>
          </div>
        </Col>
        <Col span={16}>
          <div className="p-3 db-border h-100">
            <DBHeader
              selectType="year"
              label={t("news-times-year")}
              formItemName={newsRight}
            />
            <Form.Item
              shouldUpdate={(prev, next) => prev[newsRight] !== next[newsRight]}
              noStyle
            >
              {(form: FormInstance) => {
                const value = form.getFieldValue(newsRight);
                return <NewsRight value={value} />;
              }}
            </Form.Item>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const NewsLeft = ({ value }: { value: any }) => {
  const params = appendStartAndEndOfTime(value);
  const { data, isLoading, isError, error } = DASHBOARD_NEWS_MONTH(params);
  const { i18n } = useTranslation();

  if (isLoading) {
    return (
      <div style={{ height: 300 }}>
        <LoadingPage />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div style={{ height: 300 }}>
        <ErrorPage error={error} />
      </div>
    );
  }

  const mapKey = () => {
    return data.map((item) => {
      return {
        label: getKeyByLocale({
          locale: i18n.language,
          data: item,
          key: "newsHeader",
        }),
        value: item.count,
      };
    });
  };

  const k = mapKey();

  return (
    <div style={{ height: 300, overflowX: "hidden", overflowY: "auto" }}>
      <DonutChart type="donut" data={k} />
    </div>
  );
};

const NewsRight = ({ value }: { value: any }) => {
  // const params = appendStartAndEndOfTime(value);
  const { t } = useTranslation();
  const { data, isLoading, isError, error } = DASHBOARD_NEWS_YEAR({
    year: value,
  });

  if (isLoading) {
    return (
      <div style={{ height: 300 }}>
        <LoadingPage />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div style={{ height: 300 }}>
        <ErrorPage error={error} />
      </div>
    );
  }

  return (
    <div style={{ height: 300, overflow: "hidden" }}>
      <LineChart
        lineName={t("number-of-times")}
        data={data.map((e) => e.count)}
      />
    </div>
  );
};

export default NewsSection;
