import dayjs from "dayjs";
import axios from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { IResponse } from ".";
import { exportFile, throwResponse } from "../config/axios";
import { IQRImage } from "./payment";
import { POST_MEDIA_OBJECT } from "./media";
import { getClientTimeZone } from "../tools/datetime";

export interface ILaundryOrderParams {
  limit: 10;
  page: number;
  status?: "PENDING" | "COMPLETE" | "CANCEL";
  search?: string;
  startDate?: any;
  endDate?: any;
  pagination?: boolean;
}

interface IlaundryOrdersList {
  laundryItem: any;
  requireDryCleaning: boolean;
  requireLaundry: boolean;
  requirePressingOnly: boolean;
  quantity: number;
  quantityOfFree: number;
  priceOfType: number;
  isExpress: boolean;
}

export interface ILaundryOrder {
  id: number;
  svc: number;
  vat: number;
  unit: number;
  customer: number;
  nameEn: string;
  nameTh: string;
  allList: number;
  qrImage: IQRImage;
  qrBotImage: IQRImage;
  createdAt: string;
  updatedAt: string;
  roomNumber: string;
  totalPrice: number;
  laundryCode: string;
  phoneNumber: string;
  paymentType: string;
  paymentDate: string;
  specialWash: boolean;
  roomOwnerName: string;
  laundryOrdersList: IlaundryOrdersList[];
  receiptImage: {
    id: number;
    createdAt: string;
    updatedAt: string;
    imageUrl: string;
    filename: string;
    alternativeText: string;
    uid: string;
  }[];
  status: "PENDING" | "SUCCESS" | "CANCEL" | "PAID";
  taxId: string;
  unitReference: string;
  laundryRef: string;
}

export const GET_LAUNDRY_ORDER = (params?: {}): UseQueryResult<
  IResponse<ILaundryOrder[]>
> => {
  return useQuery(
    ["get-laundry-orders", params],
    async () => {
      const res = await axios.get("/api/laundry-order", {
        params: params,
      });
      if (res.status === 200) {
        const { data, count, page, countData } = res.data;
        return { data, count, page, countData };
      }
      throwResponse(res);
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const GET_LAUNDRY_ORDER_ID = (
  id?: string
): UseQueryResult<ILaundryOrder> => {
  return useQuery(["get-laundry-order", id], async () => {
    if (!id) return;
    const res = await axios.get(`/api/laundry-order/${id}`);
    if (res.status === 200) {
      const { data } = res.data;
      data.complimentary = data.unit?.laundryComplimentary;
      data.lineName = data.customer.lineName;
      // data.unit = data.unit?.id;
      if (data.createdAt) data.createdAt = dayjs(data.createdAt);
      if (data.paymentDate) data.paymentDate = dayjs(data.paymentDate);
      data.laundryOrdersList = data.laundryOrdersList?.map((item: any) => {
        const laundryItem = item.laundryItem?.id;
        let type = undefined;
        if (item.requireDryCleaning) type = "dry-cleaning";
        if (item.requireLaundry) type = "laundry";
        if (item.requirePressingOnly) type = "pressing-only";
        return { ...item, laundryItem, type };
      });
      const picture = data.receiptImage
        ? [
            {
              id: data.receiptImage?.id,
              name: data.receiptImage?.filename,
              // uid: data.receiptImage?.uid,
              url: data.receiptImage?.imageUrl,
            },
          ]
        : undefined;
      const next = { ...data, picture };
      return next;
    }
    throwResponse(res);
  });
};

export const POST_LAUNDRY_ORDER = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post("/api/laundry-order", data);
    if (res.status === 201) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

export const PATCH_LAUNDRY_ORDER = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const { picture } = data;
    let receiptImage = undefined;
    let imageId = undefined;
    if (picture)
      imageId = await Promise.all(
        picture.map(async (item: any) => {
          if (!item.id) {
            const idImage = await POST_MEDIA_OBJECT(item.originFileObj);
            return idImage;
          }
          return item.id;
        })
      );
    if (imageId) receiptImage = imageId[0];
    delete data.picture;
    data = { ...data, receiptImage };

    const res = await axios.patch(`/api/laundry-order/${data.id}`, data);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const DELETE_LAUNDRY_ORDER = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/api/laundry-order/${data.id}`);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const BULK_DELETE_LAUNDRY_ORDER = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/api/laundry-order/bulk`, {
      data: data,
    });
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const EXPORT_LAUNDRY_ORDER = (): UseMutationResult<Blob> => {
  return useMutation(
    async (data: any) => {
      const timeZone = getClientTimeZone();
      const res = await exportFile.post("/api/export-excel/laundry-order", {
        ...data,
        timeZone,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { retry: false }
  );
};

export const LINE_NOTIFICATION_LAUNDRY_ORDER = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post(
      "/api/line-notification/send-message-laundry-order",
      data
    );
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const GET_GENERATE_QR_CODE_SCB_LAUNDRY_ORDER = (): UseMutationResult => {
  return useMutation(async (laundryOrderId: any) => {
    const res = await axios.get(
      `/api/scb/${laundryOrderId}/laundry-order/generate-qrcode-qr30`
    );
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const GET_GENERATE_QR_CODE_BOT_LAUNDRY_ORDER = (): UseMutationResult => {
  return useMutation(async (laundryOrderId: any) => {
    const res = await axios.get(
      `/api/payment/${laundryOrderId}/laundry-order/generate-qrcode`
    );
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};
