import { splitMediaObject } from "./media";
import { IPageParams } from "./global";
import { throwResponse } from "./../config/axios";
import { IResponse } from ".";
import { IFacilityCategory } from "./facility-category";
import { useMutation, UseMutationResult, UseQueryResult } from "react-query";
import { useQuery } from "react-query";
import axios from "axios";

export interface IFacilityParams extends Omit<IPageParams, "total"> {
  page: number;
  status?: string;
  search?: string;
  startDate?: any;
  endDate?: any;
  facilityCategory?: number;
  pagination?: boolean;
}

type TFacilityType = "USE_TOGETHER" | "DO_NOT_USE_TOGETHER";

export interface IFacility {
  id: number;
  createdAt: string;
  updatedAt: string;
  nameEn: string;
  nameTh: string;
  descriptionEn: string;
  descriptionTh: string;
  type: TFacilityType;
  status: "ACTIVE" | "INACTIVE" | "DRAFT";
  openTime?: string;
  closeTime?: string;
  maximumBookingHour: number;
  capacity: number;
  inactiveNoteEn?: string;
  inactiveNoteTh?: string;
  facilityCategory: IFacilityCategory;
  bookInAdvance?: number;
  isAdditionalCost: boolean;
  additionalCost: string;
  facilityMediaObjects: {
    id: number;
    name: string;
    imageUrl: string;
    uid: string;
  }[];
}

export const GET_FACILITY = (
  params?: IFacilityParams
): UseQueryResult<IResponse<IFacility[]>> => {
  return useQuery(
    ["get-facilities", params],
    async () => {
      const res = await axios.get("/api/facility", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const GET_FACILITY_BY_ID = (id?: string): UseQueryResult<IFacility> => {
  return useQuery(["get-facility-by-id", id], async () => {
    if (!id) return;
    const res = await axios.get(`/api/facility/${id}`);
    if (res.status === 200) {
      const { data } = res.data;
      const facilityMediaObjects = data.facilityMediaObjects.map(
        (item: any) => {
          return {
            id: item.mediaObject.id,
            name: item.mediaObject.filename,
            url: item.mediaObject.imageUrl,
            uid: item.createdAt,
          };
        }
      );
      return { ...res.data.data, facilityMediaObjects };
    }
    throwResponse(res);
  });
};

export const POST_FACILITY = (): UseMutationResult => {
  return useMutation(async (values: any) => {
    const { facilityMediaObjects } = values;
    let mediaObjects: number[] | undefined;
    if (facilityMediaObjects) {
      mediaObjects = await splitMediaObject(facilityMediaObjects);
    }
    delete values["facilityMediaObjects"];
    const res = await axios.post("/api/facility", { ...values, mediaObjects });
    if (res.status === 201) {
      return;
    }
    throwResponse(res);
  });
};

export const PATCH_FACILITY = (): UseMutationResult => {
  return useMutation(async (values: any) => {
    const { facilityMediaObjects, id } = values;
    let mediaObjects: number[] = await splitMediaObject(facilityMediaObjects);

    const _id = id;
    delete values["facilityMediaObjects"];
    delete values["id"];
    const res = await axios.patch(`/api/facility/${_id}`, {
      ...values,
      mediaObjects,
    });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const DELETE_FACILITY = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/api/facility/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const BULK_DELETE_FACILITY = (): UseMutationResult => {
  return useMutation(async ({ ids }: any) => {
    const res = await axios.delete(`/api/facility/bulk`, {
      data: { ids: ids },
    });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
