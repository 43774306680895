import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Tabs,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import CustomTable from "../../components/custom-table";
import CustomHistoryRepairing from "../../components/custom-table-history-repairing";
import PageHeaderEdit from "../../components/page-header-create";
import { validateMessages } from "../../config/validateMessage";
import {
  IDistrict,
  IProvince,
  ISubdistrict,
  zipcode,
} from "../../config/zipcode";
import { GET_USER_MEMBERS, page_params } from "../../store";
import { GET_REPAIRING, IRepairing } from "../../store/repairing";
import {
  DELETE_UNIT_MEMBER,
  GET_UNIT_BY_ID,
  PATCH_UNIT,
  PATCH_UNIT_MEMBER,
  POST_UNIT,
  POST_UNIT_MEMBER,
} from "../../store/units";
import { parse } from "../../tools/datetime";
import { swalDelete, swalError, swalSuccess } from "../../tools/swal";
import { fix_height } from "../news/news-edit";

const UnitEdit = () => {
  const { id, tab } = useParams<{ id: string; tab: string }>();
  const { t } = useTranslation();
  const [unitForm] = useForm();
  const history = useHistory();
  const { data } = GET_UNIT_BY_ID(id);
  const members = GET_USER_MEMBERS({ pagination: false });
  const create = POST_UNIT();
  const update = PATCH_UNIT();
  const { mutate } = DELETE_UNIT_MEMBER();
  const client = useQueryClient();
  const [getProvince, setProvince] = useState<IProvince>();
  const [getDistrict, setDistrict] = useState<IDistrict[]>();
  const [getSubdistrict, setSubdistrict] = useState<ISubdistrict[]>();
  const [getMemberId, setMemberId] = useState<number>();
  const [onTab, setTab] = useState<string>(tab);

  const columns: ColumnsType = [
    {
      dataIndex: "user",
      title: t("members"),
      width: "45%",
      render: (text) => <>{text?.fullName || "-"}</>,
    },
    {
      dataIndex: "role",
      title: t("role"),
      width: "40%",
      render: (text) => <>{text ? t(text.toLowerCase()) : "-"}</>,
    },
  ];

  useEffect(() => {
    unitForm.setFieldsValue(data || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    setTab(tab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values: any) => {
    const reg = /^-?\d*(\.\d*)?$/;
    const regUnitNumber = /^[0-9/]*$/;
    values.status = values.status === undefined ? "ACTIVE" : values.status;

    if (values.unitReference)
      if (isNaN(values.unitReference) && !reg.test(values.unitReference))
        return swalError(
          t,
          t("unit-reference-number") + ": " + t("validate.number")
        );
    if (!regUnitNumber.test(values.unitNumber))
      return swalError(
        t,
        t("house-number") + ": " + t("validate.number-slash")
      );
    if (id) {
      const data = { ...values, id: +id };
      update.mutate(data, {
        onError: (err: any) => {
          swalError(t, t("swal.please-complete-the-information"));
        },
        onSuccess: () => {
          client.invalidateQueries("units");
          client.invalidateQueries(["unit", id]);
          swalSuccess(t);
          history.goBack();
        },
      });
    } else {
      create.mutate(
        { ...values, roomOwnerName: "" },
        {
          onError: (err: any) => {
            swalError(t, t("swal.please-complete-the-information"));
          },
          onSuccess: () => {
            client.invalidateQueries("units");
            client.invalidateQueries(["unit", id]);
            swalSuccess(t);
            history.goBack();
          },
        }
      );
    }
  };

  const onEditMember = (id: number) => {
    setMemberId(id);
  };

  const onSelectProvince = (value: string) => {
    const province = zipcode.find(
      (item) => item.province_en === value || item.province_th === value
    );
    setProvince(province);
    unitForm.setFieldsValue({
      provinceEn: province?.province_en,
      provinceTh: province?.province_th,
      districtEn: undefined,
      districtTh: undefined,
      subDistrictEn: undefined,
      subDistrictTh: undefined,
    });
    setDistrict(province?.children);
  };

  const onSelectDistrict = (value: string) => {
    const district = getProvince?.children.find(
      (item) => item.district_en === value || item.district_th === value
    );
    unitForm.setFieldsValue({
      districtEn: district?.district_en,
      districtTh: district?.district_th,
      subDistrictEn: undefined,
      subDistrictTh: undefined,
    });
    setSubdistrict(district?.children);
  };

  const onSelectSubdistrict = (value: string) => {
    const subdistrict = getSubdistrict?.find(
      (item) => item.subdistrict_en === value || item.subdistrict_th === value
    );
    unitForm.setFieldsValue({
      subDistrictEn: subdistrict?.subdistrict_en,
      subDistrictTh: subdistrict?.subdistrict_th,
      postcode: subdistrict?.post_code,
    });
  };

  const onDelete = async (userUnitId: number) => {
    const confirm = await swalDelete(t);
    if (confirm) {
      mutate(
        { id: userUnitId },
        {
          onError: (err: any) => {
            swalError(t, err.message);
          },
          onSuccess: () => {
            client.invalidateQueries("units");
            client.invalidateQueries(["unit", id]);
            swalSuccess(t);
          },
        }
      );
    }
  };

  const FormInput = () => {
    return (
      <Row gutter={[24, 24]} className="mb-4 pb-2">
        <Col span={12}>
          <Form.Item
            name="houseNumber"
            label={`${t("room-number")}`} //this house number but show unit number, switch data between unit number and house number
            className="custom-input"
          >
            <Input
              placeholder={`${t("room-number")}`}
              //this house number but show unit number, switch data between unit number and house number
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="building"
            label={`${t("building")}`}
            className="custom-input"
          >
            <Input placeholder={`${t("building")}`} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="floor"
            label={`${t("floor")}`}
            className="custom-input-number"
          >
            <InputNumber placeholder={`${t("floor")}`} min={0} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="unitNumber"
            label={`${t("house-number")}`} //this unit number but show house number, switch data between unit number and house number, unit number is used to connect large amounts of data
            className="custom-input"
            rules={[{ required: true }]}
          >
            <Input
              placeholder={`${t("house-number")}`}
              //this unit number but show house number, switch data between unit number and house number, unit number is used to connect large amounts of data
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="typeOfBedroom"
            label={`${t("type-of-bed-room")}`}
            className="custom-select-ant"
            rules={[{ required: true }]}
          >
            <Select placeholder={`${t("type-of-bed-room")}`}>
              <Select.Option value="ONE_BEDROOM">
                {t("1-bed-room")}
              </Select.Option>
              <Select.Option value="TWO_BEDROOM">
                {t("2-bed-room")}
              </Select.Option>
              <Select.Option value="THREE_BEDROOM">
                {t("3-bed-room")}
              </Select.Option>
              <Select.Option value="FOUR_BEDROOM">
                {t("4-bed-room")}
              </Select.Option>
              <Select.Option value="DUPLEX">{t("duplex-room")}</Select.Option>
              <Select.Option value="PENTHOUSE">
                {t("penthouse-room")}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="projectId"
            label={`${t("project-id")}`}
            className="custom-input"
          >
            <Input placeholder={`${t("project-id")}`} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="unitReference"
            label={`${t("unit-reference-number")}`}
            className="custom-input"
          >
            <Input placeholder={`${t("unit-reference-number")}`} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="status"
            label={`${t("status")}`}
            className="custom-select-ant"
            initialValue="ACTIVE"
          >
            <Select placeholder={`${t("status")}`}>
              <Select.Option value="ACTIVE">{t("active")}</Select.Option>
              <Select.Option value="INACTIVE">{t("inactive")}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        {/* <Col span={12}>
          <Form.Item
            name="unitType"
            label={`${t("unit-type")}`}
            className="custom-input"
          >
            <Input placeholder={`${t("unit-type")}`} />
          </Form.Item>
        </Col> */}
        <Divider />
        <Col span={12}>
          <Form.Item
            name="provinceEn"
            label={`${t("provinceEn")}`}
            className="custom-select-ant"
          >
            <Select
              placeholder={`${t("provinceEn")}`}
              onSelect={onSelectProvince}
            >
              {zipcode.map((item) => (
                <Select.Option key={item.province_en} value={item.province_en}>
                  {item.province_en}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="provinceTh"
            label={`${t("provinceTh")}`}
            className="custom-select-ant"
          >
            <Select
              placeholder={`${t("provinceTh")}`}
              onSelect={onSelectProvince}
            >
              {zipcode.map((item) => (
                <Select.Option key={item.province_th} value={item.province_th}>
                  {item.province_th}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="districtEn"
            label={`${t("districtEn")}`}
            className="custom-select-ant"
          >
            <Select
              placeholder={`${t("districtEn")}`}
              onSelect={onSelectDistrict}
            >
              {getDistrict?.map((item) => (
                <Select.Option key={item.district_en} value={item.district_en}>
                  {item.district_en}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="districtTh"
            label={`${t("districtTh")}`}
            className="custom-select-ant"
          >
            <Select
              placeholder={`${t("districtTh")}`}
              onSelect={onSelectDistrict}
            >
              {getDistrict?.map((item) => (
                <Select.Option key={item.district_th} value={item.district_th}>
                  {item.district_th}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="subDistrictEn"
            label={`${t("subdistrictEn")}`}
            className="custom-select-ant"
          >
            <Select
              placeholder={`${t("subdistrictEn")}`}
              onSelect={onSelectSubdistrict}
            >
              {getSubdistrict?.map((item) => (
                <Select.Option
                  key={item.subdistrict_en}
                  value={item.subdistrict_en}
                >
                  {item.subdistrict_en}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="subDistrictTh"
            label={`${t("subdistrictTh")}`}
            className="custom-select-ant"
          >
            <Select
              placeholder={`${t("subdistrictTh")}`}
              onSelect={onSelectSubdistrict}
            >
              {getSubdistrict?.map((item) => (
                <Select.Option
                  key={item.subdistrict_th}
                  value={item.subdistrict_th}
                >
                  {item.subdistrict_th}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="roadEn"
            label={`${t("roadEn")}`}
            className="custom-input"
          >
            <Input placeholder={`${t("roadEn")}`} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="roadTh"
            label={`${t("roadTh")}`}
            className="custom-input"
          >
            <Input placeholder={`${t("roadTh")}`} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="soiEn"
            label={`${t("soiEn")}`}
            className="custom-input"
          >
            <Input placeholder={`${t("soiEn")}`} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="soiTh"
            label={`${t("soiTh")}`}
            className="custom-input"
          >
            <Input placeholder={`${t("soiTh")}`} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="mooEn"
            label={`${t("mooEn")}`}
            className="custom-input"
          >
            <Input placeholder={`${t("mooEn")}`} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="mooTh"
            label={`${t("mooTh")}`}
            className="custom-input"
          >
            <Input placeholder={`${t("mooTh")}`} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="postcode"
            label={`${t("post-code")}`}
            className="custom-input"
          >
            <Input placeholder={`${t("post-code")}`} />
          </Form.Item>
        </Col>
        <Divider />
        <Col span={12}>
          <Form.Item
            name="transferredDate"
            label={`${t("transferred-date")}`}
            className="custom-ant-picker"
          >
            <DatePicker
              placeholder={`${t("transferred-date")}`}
              style={{ width: "100%", height: `${fix_height}` }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="houseYearBe"
            label={`${t("house-year-be")}`}
            className="custom-input"
          >
            <DatePicker
              placeholder={`${t("house-year-be")}`}
              style={{ width: "100%", height: `${fix_height}` }}
            />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <div className="bg-white color-black pb-1">
        <Form form={unitForm} onFinish={onFinish}>
          <PageHeaderEdit
            label="menu.units"
            labelTo="menu.units"
            isLoading={create.isLoading || update.isLoading}
          />
        </Form>
        <Tabs
          tabPosition={"top"}
          className="px-4 custom-tabs"
          onChange={(e) => setTab(e)}
          activeKey={onTab}
        >
          <Tabs.TabPane tab={t("menu.units")} key="unit">
            <Form
              layout="vertical"
              form={unitForm}
              className="fixed-input-size"
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <FormInput />
            </Form>
          </Tabs.TabPane>
          {id && (
            <Tabs.TabPane tab={t("members")} key="member">
              <EditMembers
                members={members.data?.data}
                usersUnit={data?.userUnits}
                editMember={getMemberId}
                resetMember={() => setMemberId(NaN)}
              />
            </Tabs.TabPane>
          )}
          {id && (
            <Tabs.TabPane tab={t("menu.homecare-repairing")} key="repairing">
              <HistoryRepairing idUnit={id} />
            </Tabs.TabPane>
          )}
        </Tabs>
      </div>
      <div className="mt-3">
        {(data?.userUnits?.length || []) > 0 && onTab === "member" && (
          <CustomTable
            rowKey="id"
            title={t("members")}
            dataSource={data?.userUnits || []}
            columns={columns}
            onPageChange={() => {}}
            actions={["edit", "delete"]}
            onDelete={onDelete}
            editMember={onEditMember}
            pagination={{
              limit: 100,
              page: 1,
              total: data?.userUnits.length || 0,
            }}
            disabledSelect={true}
            disablePagination={true}
          />
        )}
      </div>
    </>
  );
};

const EditMembers = ({
  members,
  usersUnit,
  editMember,
  resetMember,
}: {
  members: any;
  usersUnit: any;
  editMember?: number;
  resetMember: () => void;
}) => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const client = useQueryClient();
  const [memberForm] = useForm();
  const create = POST_UNIT_MEMBER();
  const update = PATCH_UNIT_MEMBER();

  useEffect(() => {
    if (editMember) {
      const unit = usersUnit.find((item: any) => item.id === editMember);
      memberForm.setFieldsValue({ member: unit?.user?.id, role: unit?.role });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMember, usersUnit]);

  const onFinish = (values: any) => {
    if (editMember) {
      onEditMember(values);
    } else {
      onAddMember(values);
    }
  };

  const onAddMember = (values: any) => {
    const member = { unit: +id, user: values.member, role: values.role };
    const duplicateId = usersUnit.find(
      (item: any) => item.user?.id === values.member
    );
    if (duplicateId) return onEditMember({ ...values, id: duplicateId.id });
    return create.mutate(member, {
      onError: (err: any) => {
        swalError(t, t("swal.please-complete-the-information"));
      },
      onSuccess: () => {
        client.invalidateQueries("units");
        client.invalidateQueries(["unit", id]);
        memberForm.setFieldsValue({ member: undefined, role: undefined });
        swalSuccess(t);
      },
    });
  };

  const onEditMember = (values: any) => {
    const member = {
      id: editMember ? editMember : values.id,
      unit: +id,
      user: values.member,
      role: values.role,
    };
    update.mutate(member, {
      onError: (err: any) => {
        swalError(t, t(err));
      },
      onSuccess: () => {
        client.invalidateQueries("units");
        client.invalidateQueries(["unit", id]);
        resetMember();
        memberForm.setFieldsValue({ member: undefined, role: undefined });
        swalSuccess(t);
      },
    });
  };

  return (
    <Form
      layout="vertical"
      form={memberForm}
      onFinish={onFinish}
      className="fixed-input-size"
      validateMessages={validateMessages}
    >
      <Row gutter={[24, 24]} className="mb-4 pb-2">
        <Col span={10}>
          <Form.Item
            name="member"
            label={`${t("members")}`}
            rules={[{ required: true }]}
            className="custom-select-ant "
          >
            <Select
              placeholder={`${t("members")}`}
              showSearch
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {members?.map((item: any) => {
                return (
                  <Select.Option key={item.id} value={item.id}>
                    {item.firstName + " " + item.lastName}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name="role"
            label={`${t("role")}`}
            rules={[{ required: true }]}
            className="custom-select-ant "
          >
            <Select placeholder={`${t("role")}`}>
              <Select.Option value="OWNER">{t("owner")}</Select.Option>
              <Select.Option value="RESIDENT">{t("resident")}</Select.Option>
              <Select.Option value="TENANT">{t("tenant")}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={4} className="d-flex align-end">
          <Button
            className="button-save f-18"
            style={{ width: 180, height: 45 }}
            htmlType="submit"
            loading={create.isLoading || update.isLoading}
          >
            {t("save")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const HistoryRepairing = ({ idUnit }: { idUnit: string }) => {
  const { t, i18n } = useTranslation();
  const [params, setParams] = useRecoilState(page_params);
  const { data, isLoading } = GET_REPAIRING(params);

  useEffect(() => {
    setParams({ ...params, ...{ unit: idUnit } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log("idUnit", idUnit);
  // console.log("data", data);
  const columns: ColumnsType<IRepairing> = [
    {
      dataIndex: "repairingCode",
      title: t("job-number"),
      render: (text, rc) => <>{text ? text : "-"}</>,
    },
    {
      dataIndex: "repairingZone",
      title: t("repairing-zone"),
      render: (___, rc) => (
        <>
          {rc.repairingZone
            ? i18n.language === "en"
              ? rc.repairingZone.nameEn
              : rc.repairingZone.nameTh
            : "-"}
        </>
      ),
    },
    {
      dataIndex: "repairingCategory",
      title: t("repairing-category"),
      render: (___, rc) => (
        <>
          {rc.repairingCategory
            ? i18n.language === "en"
              ? rc.repairingCategory.nameEn
              : rc.repairingCategory.nameTh
            : "-"}
        </>
      ),
    },
    {
      dataIndex: "repairingItem",
      title: t("repairing-item"),
      render: (___, rc) => (
        <>
          {rc.repairingItem
            ? i18n.language === "en"
              ? rc.repairingItem.nameEn
              : rc.repairingItem.nameTh
            : "-"}
        </>
      ),
    },
    {
      dataIndex: "createdAt",
      title: t("created-at"),
      render: (___, record) => {
        return (
          <>
            {record.createdAt
              ? parse(
                  record.createdAt,
                  i18n.language,
                  `${
                    i18n.language === "en"
                      ? "DD MMM YYYY / HH:mm a"
                      : "DD MMM BBBB / HH:mm a"
                  }`
                )
              : "-"}
          </>
        );
      },
    },
    {
      dataIndex: "finishDate",
      title: t("finish-date"),
      render: (___, record) => {
        return (
          <>
            {record.finishDate
              ? parse(
                  record.finishDate,
                  i18n.language,
                  `${
                    i18n.language === "en"
                      ? "DD MMM YYYY / HH:mm a"
                      : "DD MMM BBBB / HH:mm a"
                  }`
                )
              : "-"}
          </>
        );
      },
    },
  ];
  return (
    <>
      <CustomHistoryRepairing
        rowKey="id"
        title={t("history-repairing")}
        dataSource={data?.data || []}
        columns={columns}
        pagination={{
          limit: params.limit,
          page: params.page,
          total: data?.count || 0,
        }}
        isLoadingTable={isLoading}
        onPageChange={(page, limit) => setParams({ ...params, page, limit })}
        actions={["edit"]}
        disablePagination
      />
    </>
  );
};

export default UnitEdit;
