import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import CustomStatusBackground from "../../../components/custom-status";
import CustomTable from "../../../components/custom-table";
import PageFilter, { IPageFilterChoice } from "../../../components/page-filter";
import PageHeader, { ISearchInput } from "../../../components/page-header";
import { SETTING_SERVICE_CATEGORY_CREATE_PATH } from "../../../router/path";
import { page_params } from "../../../store";
import {
  DELETE_SERVICE_CATEGORY,
  DELETE_SERVICE_CATEGORY_BULK,
  GET_SERVICE_CATEGORY,
} from "../../../store/service-category";
import { findStartOrEndTime, parse } from "../../../tools/datetime";
import { swalDelete, swalError, swalSuccess } from "../../../tools/swal";

const ServiceCategoryPage = () => {
  const { t, i18n } = useTranslation();
  const [params, setParams] = useRecoilState(page_params);
  const { data, refetch, isLoading } = GET_SERVICE_CATEGORY(params);
  const { mutate } = DELETE_SERVICE_CATEGORY();
  const bulkDelete = DELETE_SERVICE_CATEGORY_BULK();

  const searchInput: ISearchInput[] = [
    {
      label: t("search"),
      key: "search",
      span: 10,
      type: "input",
      props: {
        placeholder: t("search-name-EN-TH-create-by"),
      },
    },
    {
      label: t("creation-date"),
      key: "date",
      span: 7,
      type: "range-picker",
      props: {
        placeholder: [t("start-date"), t("end-date")],
        allowClear: false,
        getPopupContainer: (props: HTMLElement) => {
          return props;
        },
      },
    },
  ];
  const choices: IPageFilterChoice[] = [
    {
      label: t("all"),
      total: data?.countData?.countAll || 0,
    },
    {
      label: t("active"),
      key: "ACTIVE",
      total: data?.countData?.countActive || 0,
    },
    {
      label: t("inactive"),
      key: "INACTIVE",
      total: data?.countData?.countInActive || 0,
    },
  ];

  const columns: ColumnsType = [
    {
      dataIndex: i18n.language === "en" ? "nameEn" : "nameTh",
      title: t("name"),
      render: (text, __) => {
        return <div className="line-clamp">{text || "-"}</div>;
      },
    },
    {
      dataIndex: "order",
      title: t("order"),
      render: (text) => {
        if (text === undefined || text === null) return "-";
        if (!isNaN(text)) return text;
        return "-";
      },
    },
    {
      dataIndex: "updatedAt",
      title: t("update"),
      render: (text, __) => {
        return (
          <p style={{ whiteSpace: "pre", display: "contents" }}>
            {text
              ? parse(
                  text,
                  i18n.language,
                  `${i18n.language === "en" ? "DD MMM YYYY" : "DD MMM BBBB"}`
                )
              : "-"}
          </p>
        );
      },
    },
    {
      dataIndex: "createdBy",
      title: t("created-by"),
      render: (text, __) => {
        return (
          <p style={{ whiteSpace: "pre", display: "contents" }}>
            {text || "-"}
          </p>
        );
      },
    },
    {
      dataIndex: "status",
      title: t("status"),
      render: (text, __) => {
        return (
          <>
            {!text && "-"}
            {text === "INACTIVE" && (
              <CustomStatusBackground
                lable={t("inactive")}
                className={"frame-no-background"}
              />
            )}
            {text === "ACTIVE" && (
              <CustomStatusBackground
                lable={t("active")}
                className={"frame-background"}
              />
            )}
          </>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    if (!values.date) {
      values.date = [];
    }
    const { date } = values;

    const startDate = findStartOrEndTime(date[0], "start");
    const endDate = findStartOrEndTime(date[1], "end");
    values = { ...values, startDate, endDate };
    delete values.date;

    setParams({ ...params, ...values });
  };

  const onFilter = (choice: IPageFilterChoice) => {
    // const status = choice.key === "ALL" ? undefined : choice.key;
    setParams({ ...params, status: choice.key });
  };

  const onDelete = async (id: number) => {
    const confirm = await swalDelete(t);
    if (confirm) {
      mutate(
        { id },
        {
          onError: (err: any) => {
            swalError(t, t(err.message));
          },
          onSuccess: () => {
            swalSuccess(t);
            refetch();
          },
        }
      );
    }
  };

  const onReset = () => {
    const values = { startDate: undefined, endDate: undefined };
    setParams({
      ...params,
      ...values,
    });
  };

  const _getCurrentFilter = (): string | undefined => {
    const { status } = params;
    if (status) return status;
    return undefined;
  };

  return (
    <div>
      <PageHeader
        menu="setting-service-category"
        onSearch={onSearch}
        searchInput={searchInput}
        path={SETTING_SERVICE_CATEGORY_CREATE_PATH}
        utility={["delete"]}
        label={"create"}
        bulkDelete={bulkDelete}
        invalidateQueries={["get-service-category"]}
        onReset={onReset}
      />
      <PageFilter
        choices={choices}
        current={_getCurrentFilter()}
        onClick={onFilter}
      />
      <CustomTable
        rowKey="id"
        title={`${t("service-category-lists")}`}
        dataSource={data?.data || []}
        columns={columns}
        pagination={{
          limit: params.limit,
          page: params.page,
          total: data?.count || 0,
        }}
        onDelete={onDelete}
        onPageChange={(page, limit) => setParams({ ...params, page, limit })}
        isLoadingTable={isLoading}
      />
    </div>
  );
};

export default ServiceCategoryPage;
