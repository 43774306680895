import { Input } from "antd";

const InputSearch = ({
  className = "",
  placeholder,
  onSearch,
}: {
  className?: string;
  placeholder?: string;
  onSearch?: (value: any) => void;
}) => {
  return (
    <Input.Search
      bordered={false}
      style={{ height: 45 }}
      placeholder={placeholder}
      className={`${className} custom-search bg-white`}
      onSearch={onSearch}
    />
  );
};

export default InputSearch;