import axios from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { IResponse } from ".";
import { exportFile, throwResponse } from "../config/axios";
import { getClientTimeZone } from "../tools/datetime";

export interface IParcel {
  id: number;
  parcelNumber: string;
  unit: any;
  createdAt: string;
  deletedAt: string;
  type: string;
  updatedAt: string;
  deliveryDate: string;
  receivedDate: string;
  note: string;
  isReceived: boolean;
  isCancel: boolean;
  consignee: any;
  cancelNote: string;
}

export const parcelType = [
  { key: "Parcel box", label: "parcel-box" },
  // { key: "EMS", label: "ems" },
  { key: "Register letter", label: "register-letter" },
  { key: "Bag", label: "bag" },
  { key: "Envelope", label: "envelope" },
];

export const GET_POST_PARCEL = (params?: {}): UseQueryResult<
  IResponse<IParcel[]>
> => {
  return useQuery(
    ["post-parcels", params],
    async () => {
      const res = await axios.get("/api/post-parcel", {
        params: params,
      });
      const { data, count, page, countData } = res.data;
      if (res.status === 200) {
        return { data: data, count: count, page: page, countData: countData };
      }
      throwResponse(res);
    },
    { retry: false, refetchOnWindowFocus: false }
  );
};

export const GET_POST_PARCEL_ID = (id: string): UseQueryResult<IParcel> => {
  return useQuery(["post-parcel", id], async () => {
    if (!id) return;
    const res = await axios.get(`/api/post-parcel/${id}`);
    if (res.status === 200) {
      const { data } = res.data;
      return data;
    }
    throwResponse(res);
  });
};

export const POST_POST_PARCEL = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post("/api/post-parcel", data);
    if (res.status === 201) {
      return true;
    }
    throwResponse(res);
  });
};

export const PATCH_POST_PARCEL = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.patch(`/api/post-parcel/${data.id}`, data);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const CHECKOUT_POST_PARCEL = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.patch(`/api/post-parcel/checkout`, data);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const DELETE_POST_PARCEL = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/api/post-parcel/${data.id}`, {
      data: data,
    });
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const BULK_DELETE_POST_PARCEL = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete("/api/post-parcel/bulk", { data: data });
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const GET_PARCEL_ID_MOBILE = async (id: string): Promise<any> => {
  const res = await axios.get(`/api/post-parcel/${id}`);
  if (res.status === 200) {
    return res.data;
  } else {
    return res.data;
  }
};

export const PATCH_POST_PARCEL_CHECKOUT = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.patch(`/api/post-parcel/checkout`, data);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const EXPORT_PARCEL = (): UseMutationResult<Blob> => {
  return useMutation(
    async (data: any) => {
      const timeZone = getClientTimeZone();
      const res = await exportFile.post("/api/export-excel/parcel", {
        ...data,
        timeZone,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { retry: false }
  );
};

export const EXPORT_PARCEL_PDF = (): UseMutationResult<Blob> => {
  return useMutation(
    async (data: any) => {
      const timeZone = getClientTimeZone();
      const res = await exportFile.post("/api/export-pdf/parcel", {
        ...data,
        timeZone,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { retry: false }
  );
};

export const LINE_NOTIFICATION_POST_PARCEL = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post(
      "/api/line-notification/send-message-post-parcel",
      data
    );
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};
