import { Input, Image, Button, Form, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { WEB_LOGO } from "../assets/icons";
import PASSWORD from "../assets/icons/login/password";
import { validateMessages } from "../config/validateMessage";
import { RESET_PASSWORD } from "../store/auth";
import { swalSuccess, swalError } from "../tools/swal";

export const NewPasswordPage = ({ ...props }) => {
  const { t } = useTranslation();
  const { mutate, isLoading } = RESET_PASSWORD();
  const { replace, location } = useHistory();
  const onFinish = (values: any) => {
    const token = new URLSearchParams(location.search).get("token");
    mutate(
      { ...values, token },
      {
        onSuccess: () => {
          swalSuccess();
          replace("/login");
        },
        onError: (error: any) => {
          swalError(t, error.message);
        },
      }
    );
  };

  const checkForDevice = () => {
    let windowWidth = window.innerWidth;
    let windowHeight = window.innerHeight;
    if (windowWidth <= 1024 && windowHeight <= 1366) {
      return true;
    } else {
      return false;
    }
  };

  if (checkForDevice()) {
    return <MobileLogin isLoading={isLoading} onFinish={onFinish} />;
  }

  return (
    <Row className="h-100" style={{ backgroundColor: "#E1DED9" }}>
      <Col xs={2} md={4} xl={8}></Col>
      <Col
        xs={20}
        md={16}
        xl={8}
        className="text-center d-flex flex-column justify-center align-center"
      >
        <div className="w-100 h-60 d-flex align-end">
          <Form
            validateMessages={validateMessages}
            onFinish={onFinish}
            className="forget-password"
          >
            <Form.Item
              className="relative"
              name="newPassword"
              rules={[{ required: true }]}
            >
              <Input.Password
                prefix={PASSWORD()}
                placeholder={t("new-password")}
              />
            </Form.Item>

            <Form.Item
              className="mt-4 pt-2 relative"
              name="confirmNewPassword"
              rules={[{ required: true }]}
            >
              <Input.Password
                prefix={PASSWORD()}
                placeholder={t("confirm-new-password")}
              />
            </Form.Item>

            <Form.Item className="mt-4 pt-2">
              <Button
                htmlType="submit"
                loading={isLoading}
                className="button-submit"
              >
                {t("submit")}
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="w-100 h-40 pb-5 d-flex justify-center align-end">
          <Image
            src={WEB_LOGO}
            preview={false}
            width="80%"
            className="mb-5 px-3"
          />
        </div>
      </Col>
      <Col xs={2} md={4} xl={8}></Col>
    </Row>
  );
};

const MobileLogin = ({
  isLoading,
  onFinish,
}: {
  isLoading: boolean;
  onFinish: (values: any) => void;
}) => {
  const { t } = useTranslation();

  return (
    <div
      className="w-100 h-100 d-flex justify-center flex-column align-center px-4"
      style={{ backgroundColor: "#E1DED9" }}
    >
      <Form
        validateMessages={validateMessages}
        onFinish={onFinish}
        className="forget-password"
      >
        <Form.Item
          className="relative"
          name="newPassword"
          rules={[{ required: true }]}
        >
          <Input.Password prefix={PASSWORD()} placeholder={t("new-password")} />
        </Form.Item>

        <Form.Item
          className="mt-4 pt-2 relative"
          name="confirmNewPassword"
          rules={[{ required: true }]}
        >
          <Input.Password
            prefix={PASSWORD()}
            placeholder={t("confirm-new-password")}
          />
        </Form.Item>

        <Form.Item className="mt-4 pt-2">
          <Button
            htmlType="submit"
            loading={isLoading}
            className="button-submit"
          >
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
      <Image src={WEB_LOGO} preview={false} width="80%" className="mt-5 px-3" />
    </div>
  );
};
