import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { swalError } from "../tools/swal";
import { useTranslation } from "react-i18next";
import { POST_MEDIA_OBJECT_CONTENT } from "../store/media";
import { useMemo, useRef } from "react";

const TextEditor = ({ value, onChange }: { value?: any; onChange?: any }) => {
  const ref = useRef<ReactQuill>(null);
  const { t } = useTranslation();

  const imageHandler = (e: any) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files![0];

      if (/^image\//.test(file?.type)) {
        try {
          const asRef = ref.current as any;
          const range = asRef.getEditorSelection();
          const res = await POST_MEDIA_OBJECT_CONTENT(file);

          ref.current
            ?.getEditor()
            .insertEmbed(range.index, "image", res.data.data?.imageUrl);
        } catch (error: any) {
          return swalError(t, error?.message);
        }
      } else {
        return swalError(t, t("validate.image-types"));
      }
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ size: ["small", false, "large", "huge"] }],
          [{ font: [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
            { align: [] },
          ],
          ["link", "image", "video"],
          ["clean"],
        ],
        handlers: {
          image: imageHandler,
        },
      },
      clipboard: { matchVisual: false },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const formats = [
    "header",
    "font",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "size",
    "color",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "align",
  ];

  return (
    <ReactQuill
      theme="snow"
      value={value || ""}
      modules={modules}
      formats={formats}
      onChange={onChange}
      className="custom-quill-text-editor"
      style={{ height: 500, marginBottom: 40 }}
      ref={ref}
    />
  );
};

export default TextEditor;
