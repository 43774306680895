import {
  USER_MANAGEMENT_ADMIN_PATH,
  SETTING_BILL_TYPE_PATH,
  SETTING_CONTENT_ABOUT_US_PATH,
  DASHBOARD_PATH,
  FACILITY_LIST_PATH,
  SETTING_FACILITY_CATEGORY_PATH,
  SETTING_FACILITY_PATH,
  HOMECARE_PATH,
  NEWS_PATH,
  PARCEL_PATH,
  PAYMENT_PATH,
  HOMECARE_PRIVATE_AREA_REPAIRING_PATH,
  PRIVILEGE_PATH,
  SETTING_REPAIRING_CATEGORY_PATH,
  HOMECARE_REPAIRING_SETUP_PATH,
  SETTING_REPAIRING_ZONE_PATH,
  USER_MANAGEMENT_RESIDENT_PATH,
  SETTING_SERVICE_CATEGORY_PATH,
  SERVICE_PATH,
  SETTING_PATH,
  UNIT_PATH,
  USER_MANAGEMENT_PATH,
  SETTING_REPAIRING_ITEM_PATH,
  FACILITY_LIST_BOOKING_PATH,
  FACILITY_LIST_CALENDAR_PATH,
  FOOD_PATH,
  FOOD_LIST_PATH,
  FOOD_ORDER_PATH,
  SERVICE_LIST_PATH,
  SERVICE_ORDER_PATH,
  LAUNDRY_PATH,
  LAUNDRY_LIST_PATH,
  LAUNDRY_ORDER_PATH,
  ROLE_PATH,
} from "./path";

import {
  NAV_DASHBOARD,
  NAV_FACILITY,
  NAV_HOMECARE,
  NAV_NEWS,
  NAV_PARCEL,
  NAV_PAYMENT,
  NAV_PRIVILEGE,
  NAV_SERVICE,
  NAV_SETTING,
  NAV_UNITS,
  NAV_USER_MANAGEMENT,
  NAV_LAUNDRY,
  NAV_FOOD,
} from "../assets/icons";

export interface IMenu {
  code?: string;
  key: TMenuKey;
  to: string;
  icon?: any;
  children?: IMenu[];
}

export type TMenuKey =
  | "dashboard"
  | "news"
  | "privilege"
  | "homecare"
  | "homecare-repairing"
  | "homecare-repair-setup"
  | "payment"
  | "payment-list"
  | "parcel"
  | "facility-list"
  | "facility-list-booking"
  | "facility-list-calendar"
  | "food"
  | "food-list"
  | "food-order"
  | "service"
  | "service-list"
  | "service-order"
  | "laundry"
  | "laundry-list"
  | "laundry-order"
  | "user-management"
  | "user-management-resident"
  | "user-management-admin"
  | "setting"
  | "setting-facility-category"
  | "setting-facility"
  | "setting-repairing-item"
  | "setting-repairing-zone"
  | "setting-repairing-category"
  | "setting-bill-type"
  | "setting-service-category"
  | "setting-content-about-us"
  | "setting-role"
  | "units";

export const menu: IMenu[] = [
  {
    to: DASHBOARD_PATH,
    key: "dashboard",
    icon: NAV_DASHBOARD,
    code: "001",
  },
  {
    to: NEWS_PATH,
    key: "news",
    icon: NAV_NEWS,
    code: "002",
  },
  {
    to: PRIVILEGE_PATH,
    key: "privilege",
    icon: NAV_PRIVILEGE,
    code: "003",
  },
  {
    to: HOMECARE_PATH,
    key: "homecare",
    icon: NAV_HOMECARE,
    code: "005",
    children: [
      {
        to: `${HOMECARE_PRIVATE_AREA_REPAIRING_PATH.index}`,
        key: "homecare-repairing",
        icon: NAV_HOMECARE,
      },
      {
        to: `${HOMECARE_REPAIRING_SETUP_PATH.index}`,
        key: "homecare-repair-setup",
        icon: NAV_HOMECARE,
      },
    ],
  },
  {
    to: PAYMENT_PATH,
    key: "payment",
    icon: NAV_PAYMENT,
    code: "006",
  },
  {
    to: PARCEL_PATH,
    key: "parcel",
    icon: NAV_PARCEL,
    code: "007",
  },
  {
    to: FACILITY_LIST_PATH,
    key: "facility-list",
    icon: NAV_FACILITY,
    code: "008",
    children: [
      {
        to: `${FACILITY_LIST_BOOKING_PATH}`,
        key: "facility-list-booking",
        icon: NAV_FACILITY,
      },
      {
        to: `${FACILITY_LIST_CALENDAR_PATH}`,
        key: "facility-list-calendar",
        icon: NAV_FACILITY,
      },
    ],
  },
  {
    to: SERVICE_PATH,
    key: "service",
    icon: NAV_SERVICE,
    code: "009",
    children: [
      {
        to: SERVICE_LIST_PATH.index,
        key: "service-list",
        icon: NAV_USER_MANAGEMENT,
      },
      {
        to: SERVICE_ORDER_PATH.index,
        key: "service-order",
        icon: NAV_USER_MANAGEMENT,
      },
    ],
  },
  {
    key: "food",
    to: `${FOOD_PATH}`,
    icon: NAV_FOOD,
    code: "013",
    children: [
      {
        to: FOOD_LIST_PATH.index,
        key: "food-list",
        icon: NAV_USER_MANAGEMENT,
      },
      {
        to: FOOD_ORDER_PATH.index,
        key: "food-order",
        icon: NAV_USER_MANAGEMENT,
      },
    ],
  },
  {
    key: "laundry",
    to: `${LAUNDRY_PATH}`,
    icon: NAV_LAUNDRY,
    code: "014",
    children: [
      {
        to: LAUNDRY_LIST_PATH.index,
        key: "laundry-list",
        icon: NAV_USER_MANAGEMENT,
      },
      {
        to: LAUNDRY_ORDER_PATH.index,
        key: "laundry-order",
        icon: NAV_USER_MANAGEMENT,
      },
    ],
  },
  {
    to: USER_MANAGEMENT_PATH,
    key: "user-management",
    icon: NAV_USER_MANAGEMENT,
    code: "010",
    children: [
      {
        to: `${USER_MANAGEMENT_RESIDENT_PATH.index}`,
        key: "user-management-resident",
        icon: NAV_USER_MANAGEMENT,
      },
      {
        to: `${USER_MANAGEMENT_ADMIN_PATH.index}`,
        key: "user-management-admin",
        icon: NAV_USER_MANAGEMENT,
      },
    ],
  },
  {
    to: SETTING_PATH,
    key: "setting",
    icon: NAV_SETTING,
    code: "011",
    children: [
      {
        key: "setting-facility-category",
        to: `${SETTING_FACILITY_CATEGORY_PATH}`,
        icon: NAV_USER_MANAGEMENT,
        code: "015",
      },
      {
        key: "setting-facility",
        to: `${SETTING_FACILITY_PATH}`,
        icon: NAV_USER_MANAGEMENT,
        code: "016",
      },

      {
        key: "setting-repairing-zone",
        to: `${SETTING_REPAIRING_ZONE_PATH}`,
        icon: NAV_USER_MANAGEMENT,
        code: "017",
      },
      {
        key: "setting-repairing-category",
        to: `${SETTING_REPAIRING_CATEGORY_PATH}`,
        icon: NAV_USER_MANAGEMENT,
        code: "018",
      },
      {
        key: "setting-repairing-item",
        to: `${SETTING_REPAIRING_ITEM_PATH}`,
        icon: NAV_USER_MANAGEMENT,
        code: "019",
      },
      {
        key: "setting-bill-type",
        to: `${SETTING_BILL_TYPE_PATH}`,
        icon: NAV_USER_MANAGEMENT,
        code: "020",
      },
      {
        key: "setting-service-category",
        to: `${SETTING_SERVICE_CATEGORY_PATH}`,
        icon: NAV_USER_MANAGEMENT,
        code: "021",
      },
      {
        key: "setting-content-about-us",
        to: `${SETTING_CONTENT_ABOUT_US_PATH}`,
        icon: NAV_USER_MANAGEMENT,
        code: "022",
      },
      {
        key: "setting-role",
        to: `${ROLE_PATH.index}`,
        icon: NAV_USER_MANAGEMENT,
        code: "023",
      },
    ],
  },
  {
    to: UNIT_PATH,
    key: "units",
    icon: NAV_UNITS,
    code: "012",
  },
];
