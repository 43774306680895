import axios from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { exportFile, throwResponse } from "../config/axios";
import { getClientTimeZone } from "../tools/datetime";

export const GET_REPAIR_SETUP = (params?: {}): UseQueryResult<any[]> => {
  return useQuery(
    ["get-repairing-setup", params],
    async () => {
      const res = await axios.get("/api/repairing-setup", {
        params: params,
      });
      if (res.status === 200) {
        return res.data.data;
      }
      throwResponse(res);
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const GET_REPAIR_SETUP_ID = async (id: string) => {
  if (!id) return;
  const res = await axios.get(`/api/repairing-setup/${id}`);
  if (res.status === 200) {
    const { data } = res.data;
    return data;
  }
  throwResponse(res);
};

export const POST_REPAIRING_SETUP = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post("/api/repairing-setup", data);
    if (res.status === 201) {
      return true;
    }
    throwResponse(res);
  });
};

export const PATCH_REPAIRING_SETUP = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.patch(`/api/repairing-setup/${data.id}`, data);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const DELETE_REPAIR_SETUP = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/api/repairing-setup/${data.id}`);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const EXPORT_REPAIR_SETUP = (): UseMutationResult<Blob> => {
  return useMutation(
    async (data: any) => {
      const timeZone = getClientTimeZone();
      const res = await exportFile.post("/api/export-excel/repair-setup", {...data, timeZone});
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { retry: false }
  );
};
