import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { useHistory } from "react-router";
import { FormInstance, useForm } from "antd/lib/form/Form";
import PageHeaderEdit from "../../components/page-header-create";
import { useParams } from "react-router-dom";
import {
  GET_POST_PARCEL_ID,
  parcelType,
  PATCH_POST_PARCEL,
} from "../../store/parcel";
import { useQueryClient } from "react-query";
import { swalError, swalSuccess } from "../../tools/swal";
import { useEffect, useState } from "react";
import { GET_UNIT } from "../../store/units";
import QRCode from "react-qr-code";
import { IUser } from "../../store/user";
import { DownloadOutlined } from "@ant-design/icons";
import html2canvas from "html2canvas";
import { VALIDATE_MESSAGES } from "../../config/validateMessage";
import { fix_height } from "../news/news-edit";
import dayjs from "dayjs";

const EditParcel = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = GET_POST_PARCEL_ID(id);
  const update = PATCH_POST_PARCEL();
  const units = GET_UNIT({ pagination: false, status: "ACTIVE" });
  const client = useQueryClient();
  const [form] = useForm();
  const history = useHistory();
  const { t } = useTranslation();
  const [getMembers, setMembers] = useState<IUser[]>();
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const status = data?.isReceived ? "true" : "false";
    const isCancel = data?.isCancel ? "cancel" : undefined;
    const unit = data?.unit?.id;
    const consignee = data?.consignee?.id;
    const createdAt = data?.createdAt ? dayjs(data.createdAt) : undefined;
    const receivedDate = data?.receivedDate
      ? dayjs(data.receivedDate)
      : undefined;
    const member = units.data?.data
      .find((item) => item.id === data?.unit?.id)
      ?.userUnits.map((item: any) => {
        return {
          ...item.user,
          fullName: item.user?.fullName + ` (${t(item.role.toLowerCase())})`,
        };
      })
      .filter((item: any) => item.isActive === true);
    const lineName = member?.find((item) => item.id === consignee)?.lineName;
    const next = {
      ...data,
      isReceived: isCancel ? "cancel" : status,
      unit,
      lineName,
      consignee,
      createdAt,
      receivedDate,
    };
    setMembers(member);
    form.setFieldsValue(next || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, form, t]);

  const onFinish = (values: any) => {
    let status = undefined;
    if (values.isReceived === "cancel")
      status = {
        isCancel: true,
        isReceived: data?.isReceived,
      };
    if (values.isReceived !== "cancel")
      status = {
        isCancel: false,
        isReceived: JSON.parse(`${values.isReceived}`),
      };
    const next = { ...values, id: +id, ...status };
    update.mutate(next, {
      onError: (err: any) => {
        swalError(t, t(err));
      },
      onSuccess: () => {
        client.invalidateQueries("post-parcels");
        client.invalidateQueries(["post-parcel", id]);
        swalSuccess(t);
        history.goBack();
      },
    });
  };

  const onSelectUnit = (id: number) => {
    form.setFieldsValue({ consignee: undefined, phoneNumber: undefined });
    const members = units.data?.data
      .find((item) => item.id === id)
      ?.userUnits.map((item: any) => {
        return {
          ...item.user,
          fullName: item.user?.fullName + ` (${t(item.role.toLowerCase())})`,
        };
      })
      .filter((item: any) => item.isActive === true);
    setMembers(members);
  };

  const onSelectUser = (value: number) => {
    const member = getMembers?.find((item) => item.id === value);
    const phoneNumber = member?.phoneNumber;
    const lineName = member?.lineName;
    form.setFieldsValue({ phoneNumber, lineName });
  };

  const downloadQRCode = async () => {
    const element: HTMLElement | null = document.querySelector(
      "#qr-code-parcel-download"
    );
    if (!element) return;
    const canvas = await html2canvas(element);
    const img = canvas.toDataURL("image/png");

    var qrCodeParcel = document.createElement("a");
    qrCodeParcel.download = `${data?.parcelNumber}.png`;
    qrCodeParcel.href = img;
    qrCodeParcel.click();
    setLoad(false);
  };

  const FormEdit = () => {
    return (
      <Row gutter={[24, 24]} className="px-4 mb-4 pb-2">
        <Col span={12}>
          <Form.Item
            name="parcelNumber"
            label={`${t("parcel-number")}`}
            rules={[{ required: true }]}
            className="custom-input"
          >
            <Input disabled placeholder={`${t("parcel-number")}`} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="parcelCode"
            label={t("tracking-number")}
            className="custom-input "
          >
            <Input placeholder={t("tracking-number")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="unit"
            label={`${t("house-number")}`} //unit number to use, house number to show
            rules={[{ required: true }]}
            className="custom-select-ant "
          >
            <Select
              placeholder={`${t("house-number")}`} //unit number to use, house number to show
              onSelect={onSelectUnit}
              showSearch
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {units.data?.data.map((item) => {
                return (
                  <Select.Option key={item.id} value={item.id}>
                    {item.unitNumber}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="consignee"
            label={`${t("name")}`}
            className="custom-select-ant"
            rules={[{ required: true }]}
          >
            <Select placeholder={`${t("name")}`} onSelect={onSelectUser}>
              {getMembers?.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item?.fullName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="phoneNumber"
            label={t("phone-number")}
            className="custom-input"
            rules={[{ required: true }]}
          >
            <Input placeholder={t("phone-number")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="lineName"
            label={t("line-name")}
            className="custom-input"
          >
            <Input disabled placeholder={t("line-name")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="type"
            label={`${t("type")}`}
            className="custom-select-ant "
            rules={[{ required: true }]}
          >
            <Select placeholder={`${t("type")}`}>
              {parcelType.map((item) => (
                <Select.Option key={item.label} value={item.key}>
                  {t(item.label)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="createdAt"
            label={t("created-at")}
            className="custom-ant-picker"
          >
            <DatePicker
              disabled
              placeholder={`${t("delivery-date")}`}
              style={{ width: "100%", height: `${fix_height}` }}
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="receivedDate"
            label={t("parcel-recieved")}
            className="custom-ant-picker"
          >
            <DatePicker
              placeholder={`${t("parcel-recieved")}`}
              style={{ width: "100%", height: `${fix_height}` }}
              format="YYYY-MM-DD HH:mm"
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="isReceived"
            label={`${t("status")}`}
            className="custom-select-ant"
          >
            <Select placeholder={`${t("status")}`}>
              <Select.Option value="false">{t("not-received")}</Select.Option>
              <Select.Option value="true">{t("received")}</Select.Option>
              <Select.Option value="cancel">{t("cancel")}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="note"
            label={`${t("note")}`}
            rules={[{ max: 255 }]}
            className="custom-input"
          >
            <Input.TextArea rows={3} placeholder={`${t("note")}`} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            shouldUpdate={(prev, next) => prev.isReceived !== next.isReceived}
          >
            {(form: FormInstance) => {
              const isCancel = form.getFieldValue("isReceived") === "cancel";

              return (
                <Form.Item
                  name="cancelNote"
                  label={`${t("cancel-note")}`}
                  rules={[{ max: 255 }]}
                  className="custom-input"
                >
                  <Input.TextArea
                    rows={3}
                    placeholder={`${t("cancelNote")}`}
                    disabled={!isCancel}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
        <Col>
          <Form.Item>
            <Row className="mb-2">
              <div
                className="mr-3 f-16"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                {t("qr-code")}
              </div>
              <Button
                type="link"
                block
                icon={<DownloadOutlined className="f-20" />}
                onClick={() => {
                  setLoad(true);
                  downloadQRCode();
                }}
                loading={load}
                style={{ width: 30 }}
              ></Button>
            </Row>
            <Row
              style={{ border: "1px solid #e4e9f2" }}
              className="px-2 py-2"
              id="qr-code-parcel-download"
            >
              <QRCode value={id} id="qrCode" level="Q" />
            </Row>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  return (
    <div className="bg-white color-black pb-1">
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        validateMessages={VALIDATE_MESSAGES(t)}
        className="fixed-input-size"
      >
        <PageHeaderEdit
          label="menu.parcel"
          labelTo="menu.parcel"
          isLoading={update.isLoading}
        />
        <FormEdit />
      </Form>
    </div>
  );
};

export default EditParcel;
