import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import CustomTable from "../../components/custom-table";
import PageFilter, { IPageFilterChoice } from "../../components/page-filter";
import PageHeader, { ISearchInput } from "../../components/page-header";
import { UNIT_CREATE_PATH } from "../../router/path";
import {
  GET_UNIT,
  DELETE_UNIT,
  BULK_DELETE_UNIT,
  EXPORT_UNIT,
  EXPORT_TEMPLATE_UNIT_USER,
  IMPORT_UNIT_USER,
} from "../../store/units";
import { page_params } from "../../store";
import { swalDelete, swalError, swalSuccess } from "../../tools/swal";
import CustomStatusBackground from "../../components/custom-status";
import { useRecoilState } from "recoil";
import { findStartOrEndTime } from "../../tools/datetime";
import { useHistory } from "react-router-dom";
import { Modal } from "antd";
import { useRef, useState } from "react";
import { downloadFile } from "../../tools/files";
import ModalImportExcelUnitUser, {
  TModalRef,
} from "../../components/modal-import-excel-unit-user";

interface IUserUnit {
  count: number;
  data: { fullname: string; email: string }[];
}

const UnitPage = () => {
  const [params, setParams] = useRecoilState(page_params);
  const { t } = useTranslation();
  const history = useHistory();
  const { data, refetch, isLoading } = GET_UNIT(params);
  const { mutate } = DELETE_UNIT();
  const bulkDelete = BULK_DELETE_UNIT();
  const exportExcel = EXPORT_UNIT();
  const importExcel = IMPORT_UNIT_USER();
  const exportTmplate = EXPORT_TEMPLATE_UNIT_USER();
  const [userVisible, setUserVisible] = useState<boolean>(false);
  const [paramsUser, setParamsUser] = useState({ limit: 10, page: 1 });
  const [userUnits, setUserUnits] = useState<IUserUnit>();

  const searchInput: ISearchInput[] = [
    {
      label: t("search"),
      key: "roomOwnerName",
      span: 10,
      type: "input",
      props: {
        placeholder: t("search-room-owner-name"),
      },
    },
    {
      label: t("house-number"), //unit number to use, house number to show
      key: "unitNumber",
      span: 7,
      type: "input",
      props: {
        placeholder: t("house-number"), //unit number to use, house number to show
      },
    },
    {
      label: t("creation-date"),
      key: "date",
      span: 7,
      type: "range-picker",
      props: {
        placeholder: [t("start-date"), t("end-date")],
        getPopupContainer: (props: HTMLElement) => {
          return props;
        },
      },
    },
  ];
  const choices: IPageFilterChoice[] = [
    {
      label: t("all"),
      total: data?.countData?.countAll,
    },
    {
      label: t("active"),
      key: "ACTIVE",
      total: data?.countData?.countActive,
    },
    {
      label: t("inactive"),
      key: "INACTIVE",
      total: data?.countData?.countInActive,
    },
  ];

  const columnsUser: ColumnsType = [
    {
      dataIndex: "fullname",
      title: t("name"),
      width: "40%",
      render: (text) => {
        return <span>{text}</span>;
      },
    },
    {
      dataIndex: "email",
      title: t("email"),
      width: "40%",
      render: (text) => {
        return <span>{text}</span>;
      },
    },
  ];

  const columns: ColumnsType = [
    {
      dataIndex: "userUnits",
      title: t("room-owner-name"),
      render: (text, record) => {
        const ownerName = text.find((item: any) => item.role === "OWNER")?.user
          .fullName;
        return <>{ownerName ? ownerName : "-"}</>;
      },
    },
    {
      dataIndex: "unitNumber",
      title: t("house-number"), //this unit number but show house number, switch data between unit number and house number, unit number is used to connect large amounts of data
      render: (text, record) => <>{text ? text : "-"}</>,
    },
    {
      dataIndex: "houseNumber",
      title: t("room-number"), //this house number but show unit number, switch data between unit number and house number
      render: (text, record) => <>{text ? text : "-"}</>,
    },
    {
      dataIndex: "floor",
      title: t("floor"),
      render: (text, record) => <>{text ? text : "-"}</>,
    },
    {
      dataIndex: "status",
      title: t("status"),
      render: (text, record) => {
        return (
          <>
            {!text && "-"}
            {text === "INACTIVE" && (
              <CustomStatusBackground
                lable={t("inactive")}
                className={"frame-no-background"}
              />
            )}
            {text === "ACTIVE" && (
              <CustomStatusBackground
                lable={t("active")}
                className={"frame-background"}
              />
            )}
          </>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    if (!values.date) {
      values.date = [];
    }
    const { date } = values;

    const createdAtStartDate = findStartOrEndTime(date[0], "start");
    const createdAtEndDate = findStartOrEndTime(date[1], "end");
    values = { ...values, createdAtStartDate, createdAtEndDate };
    delete values.date;

    setParams({ ...params, ...values });
  };

  const onFilter = (choice: IPageFilterChoice) => {
    setParams({ ...params, status: choice.key });
  };

  const onDelete = async (id: number) => {
    const confirm = await swalDelete(t);
    if (confirm) {
      mutate(
        { id },
        {
          onError: async (err: any) => {
            let errMessage = undefined;
            if (err.message.slice(0, 12) === "error delete") {
              errMessage = t(
                "this-room-is-already-a-user-and-cannot-be-deleted"
              );
            } else {
              errMessage = err.message;
            }
            const error = await swalError(t, errMessage);
            if (err.message.slice(0, 12) === "error delete" && error) {
              const userUnits = err.message
                .slice(12, err.message.length)
                .split(",")
                .map((item: any) => {
                  const email = item.split("/")[0];
                  const fullname = item.split("/")[1];
                  return { email, fullname };
                });
              setUserUnits({ count: userUnits.length, data: userUnits });
              setUserVisible(true);
            }
          },
          onSuccess: () => {
            refetch();
            swalSuccess(t);
          },
        }
      );
    }
  };

  const onBulkDeleteUnit = async (ids: number[]) => {
    bulkDelete?.mutate(
      { ids },
      {
        onError: async (err: any) => {
          let errMessage = undefined;
          if (err.message.slice(0, 12) === "error delete") {
            errMessage = t("this-room-is-already-a-user-and-cannot-be-deleted");
          } else {
            errMessage = err.message;
          }
          const error = await swalError(t, errMessage);
          if (err.message.slice(0, 12) === "error delete" && error) {
            const userUnits = err.message
              .slice(12, err.message.length)
              .split(",")
              .map((item: any) => {
                const email = item.split("/")[0];
                const fullname = item.split("/")[1];
                return { email, fullname };
              });
            setUserUnits({ count: userUnits.length, data: userUnits });
            setUserVisible(true);
          }
        },
        onSuccess: () => {
          refetch();
          swalSuccess(t);
        },
      }
    );
  };

  const _getCurrentFilter = (): string | undefined => {
    const { status } = params;
    if (status) return status;
    return undefined;
  };

  const onEditUnit = (id: number) => {
    history.push(`units/${id}/unit`);
  };

  const transformJson = (value: any[]): any[] => {
    return value.map((item, index) => {
      const number = item.number ? item.number : index + 1;
      return { ...item, number };
    });
  };

  const importRef = useRef<TModalRef>(null);

  return (
    <div>
      <ModalImportExcelUnitUser
        width="75vw"
        transformJson={transformJson}
        mutation={importExcel}
        ref={importRef}
        payment
        headerKey={["email", "house number", "role"]}
        range={{
          rowStart: 1,
          colStart: 0,
          colEnd: 13,
        }}
        tableProps={{
          columns: [
            {
              title: "#",
              dataIndex: "number",
            },
            {
              dataIndex: "email",
              title: t("email"),
            },
            {
              dataIndex: "house number",
              title: t("house-number"),
            },
            {
              dataIndex: "role",
              title: t("role"),
              render: (text) => <>{t(text.toLowerCase())}</>,
            },
          ],
        }}
      />
      <PageHeader
        menu="units"
        onSearch={onSearch}
        searchInput={searchInput}
        utility={["delete"]}
        label={"create"}
        path={UNIT_CREATE_PATH}
        bulkDelete={bulkDelete}
        invalidateQueries={["units"]}
        // exportExcel={exportExcel}
        topicExport="Unit"
        onBulkDelete={onBulkDeleteUnit}
        excelOptions={{
          import: () => {
            importRef?.current?.triggerVisible();
          },
          export: exportExcel,
          template: () =>
            exportTmplate.mutate(
              {},
              {
                onSuccess: (data: any) => {
                  downloadFile(data, "xlsx", "Template unit user");
                },
                onError: (error: any) => {
                  swalError(t, error.message);
                },
              }
            ),
        }}
      />
      <PageFilter
        choices={choices}
        onClick={onFilter}
        current={_getCurrentFilter()}
      />
      <CustomTable
        rowKey="id"
        title={t("unit-list")}
        dataSource={data?.data || []}
        columns={columns}
        pagination={{
          limit: params.limit,
          page: params.page,
          total: data?.count || 0,
        }}
        editUnit={onEditUnit}
        onDelete={onDelete}
        isLoadingTable={isLoading}
        onPageChange={(page, limit) => setParams({ ...params, page, limit })}
      />
      <Modal
        title={null}
        visible={userVisible}
        onOk={() => setUserVisible(false)}
        onCancel={() => setUserVisible(false)}
        footer={null}
        width="50vw"
      >
        <CustomTable
          rowKey="id"
          title={t("user-list")}
          columns={columnsUser}
          dataSource={
            userUnits?.data.slice(
              (paramsUser.page - 1) * paramsUser.limit,
              paramsUser.page * paramsUser.limit
            ) || []
          }
          pagination={{
            limit: paramsUser.limit,
            page: paramsUser.page,
            total: userUnits?.count || 0,
          }}
          onPageChange={(page, limit) =>
            setParamsUser({ ...paramsUser, page, limit })
          }
          disabledSelect
          actions={[]}
        />
      </Modal>
    </div>
  );
};

export default UnitPage;
