import { exportFile, throwResponse } from "./../config/axios";
import axios from "axios";
import { IPageParams, IResponse } from "./global";
import {
  UseQueryResult,
  useQuery,
  UseMutationResult,
  useMutation,
} from "react-query";
import { POST_MEDIA_OBJECT } from "./media";
import { getClientTimeZone } from "../tools/datetime";

export interface IUser {
  id: number;
  createdAt: string;
  updatedAt: string;
  email: string;
  secondEmail: string;
  identityNumber: string;
  firstName: string;
  lastName: string;
  fullName: string;
  phoneNumber?: string;
  lineName?: string;
  acceptPdpa?: boolean;
  acceptPolicy?: boolean;
  acceptTermCondition?: boolean;
  acceptPdpaAt?: string;
  acceptPolicyAt?: string;
  acceptTermAt?: string;
  address: string;
  passportId: string;
  avatar?: {
    imageUrl: string;
  };
  countryCode: string;
  dob: string;
  gender: "MALE" | "FEMALE";
  isActive: boolean;
  language: "THAI" | "ENGLISH";
  role: {
    createdAt: string;
    id: number;
    isActive: boolean;
    title: string;
    updatedAt: string;
  };
  userUnits?: {
    id: number;
    unit: {
      id: number;
      unitNumber: string;
      createdAt: string;
      updatedAt: string;
      roomOwnerName: string;
    };
  }[];
}

export interface IUserParams extends Omit<IPageParams, "total"> {
  search?: string;
  isActive?: boolean;
  unit?: number;
  pagination?: boolean;
}

// export const GET_USERS = (
//   params?: IUserParams
// ): UseQueryResult<IResponse<IUser[]>> => {
//   return useQuery(
//     ["get-users", params],
//     async () => {
//       const res = await axios.get("/api/user", { params: params });
//       if (res.status === 200) {
//         return res.data;
//       }
//       throw new Error(`failed with status ${res.status}`);
//     },
//     { keepPreviousData: true }
//   );
// };

export const GET_USERS_ADMIN = (
  params?: IUserParams
): UseQueryResult<IResponse<IUser[]>> => {
  return useQuery(
    ["get-users-admin", params],
    async () => {
      const res = await axios.get(`/api/user/admin`, { params: params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const GET_USERS_MEMBER = (
  params?: IUserParams | {}
): UseQueryResult<IResponse<IUser[]>> => {
  return useQuery(
    ["get-users-member", params],
    async () => {
      const res = await axios.get(`/api/user/member`, { params: params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const GET_USER_BY_ID = (
  id?: string | number
): UseQueryResult<IResponse<IUser>> => {
  return useQuery(["get-user", id], async () => {
    if (!id) return;
    const res = await axios.get(`/api/user/${id}`);
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const DELETE_USER = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/api/user/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const DELETE_MULTI_USER = (): UseMutationResult => {
  return useMutation(async ({ ids }: any) => {
    const res = await axios.delete(`/api/user/bulk`, { data: { ids } });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const POST_USER = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const { avatar } = data;
    if (avatar && typeof avatar === "object") {
      const id = await POST_MEDIA_OBJECT(avatar);
      data = { ...data, avatar: id };
    }
    const res = await axios.post(`/api/user`, data);
    if (res.status === 201) {
      return;
    }
    throwResponse(res);
  });
};

export const PATCH_USER = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const { id, avatar, newPassword, confirmNewPassword, oldPassword } = data;
    if (id && avatar && typeof avatar === "object") {
      await CHANGE_AVATAR(id, avatar);
      delete data["avatar"];
    }
    if (newPassword) {
      await CHANGE_PASSWORD(id, {
        newPassword,
        oldPassword,
        confirmNewPassword,
      });
    }
    const res = await axios.patch(`/api/user/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

const CHANGE_AVATAR = async (id: string, file: File): Promise<any> => {
  let formData = new FormData();
  formData.append("file", file);
  const res = await axios.patch(`/api/user/${id}/change-avatar`, formData);
  if (res.status === 200) {
    return;
  }
  throwResponse(res);
};

const CHANGE_PASSWORD = async (id: string, values: any): Promise<any> => {
  const res = await axios.patch(`/api/user/${id}/change-password`, values);
  if (res.status === 200) {
    return;
  }
  throwResponse(res);
  //
};

export const GET_USER_MEMBERS = (params?: {}): UseQueryResult<
  IResponse<IUser[]>
> => {
  return useQuery(
    ["user-members", params],
    async () => {
      const res = await axios.get("/api/user/member", {
        params: params,
      });
      const { data, count, page, countData } = res.data;
      if (res.status === 200) {
        return { data: data, count: count, page: page, countData: countData };
      }
      throwResponse(res);
    },
    { retry: false, refetchOnWindowFocus: false }
  );
};

export const EXPORT_RESIDENT = (): UseMutationResult<Blob> => {
  return useMutation(
    async (data: any) => {
      const timeZone = getClientTimeZone();
      const res = await exportFile.post("/api/export-excel/resident", {
        ...data,
        timeZone,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { retry: false }
  );
};

export const EXPORT_RESIDENT_TEMPLATE = (): UseMutationResult<Blob> => {
  return useMutation(
    async (data: any) => {
      const timeZone = getClientTimeZone();
      const res = await exportFile.post("/api/export-excel/template-resident", {
        ...data,
        timeZone,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { retry: false }
  );
};

export const EXPORT_ADMIN = (): UseMutationResult<Blob> => {
  return useMutation(
    async (data: any) => {
      const timeZone = getClientTimeZone();
      const res = await exportFile.post("/api/export-excel/admin", {
        ...data,
        timeZone,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { retry: false }
  );
};

export const IMPORT_RESIDENT = (): UseMutationResult => {
  return useMutation(async (values) => {
    const res = await axios.post(`/api/user/import-residents`, values);
    if (res.status === 201) {
      return;
    }
    throwResponse(res);
  });
};
