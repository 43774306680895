import { FileFilled, ToolFilled } from "@ant-design/icons";
import { Col, Divider, Empty, Row } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import MobileHeader from "../../../components/page-header-mobile";
import InputSearch from "../../../components/input-search";
import { t } from "i18next";
import { parse } from "../../../tools/datetime";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { GET_USER_PROFILE } from "../../../store/auth";
import { GET_REPAIRING_MOBILE, IRepairing } from "../../../store/repairing";
import { IUser } from "../../../store";
import { ClipLoader } from "react-spinners";
import ErrorPage from "../../../pages/page-error-mobile";

const _placeholder = "search-list";

const _colors_status = [
  { key: "WAITING", color: "#EBD05F", name: "waiting" },
  { key: "PENDING", color: "#DF9C3F", name: "pending" },
  { key: "PROCESSING", color: "#A455E8", name: "processing" },
  { key: "CHECKING", color: "#5FA3FF", name: "checking" },
  { key: "COMPLETE", color: "#009681", name: "complete" },
  { key: "CANCEL", color: "#E61E1E", name: "cancel" },
];

const RepairmanPage = () => {
  const user = GET_USER_PROFILE();
  const { t } = useTranslation();
  const [currentKey, setCurrentKey] = useState<number>(0);

  const buttons = [
    {
      key: 0,
      label: t("mobile.repair"),
      icon: <ToolFilled style={{ fontSize: "1.2rem" }} />,
    },
    {
      key: 1,
      label: t("mobile.history"),
      icon: <FileFilled style={{ fontSize: "1.2rem" }} />,
    },
  ];

  const onTabChange = (key: number) => {
    setCurrentKey(key);
  };

  return (
    <div className="w-100 background-mobile">
      <MobileHeader isGoBack={user.role.title === "REPAIRMAN" && true} />
      <div className={"px-3 w-100 pt-5-5 pb-4"}>
        <Row gutter={[3, 3]} className={" pb-3  "}>
          {buttons.map((item, index) => {
            const active = item.key === currentKey;
            const first = item.key === 0;
            return (
              <Col
                span={12}
                key={index}
                className={`${active && "active"}`}
                style={first ? { paddingLeft: 0 } : { paddingRight: 0 }}
              >
                <div
                  className="switch-tab-button"
                  onClick={() => onTabChange(item.key)}
                >
                  {item.icon}
                  <div className="mx-1" />
                  <span className="color-white">{item.label}</span>
                </div>
              </Col>
            );
          })}
        </Row>
        <div className="pb-2">
          {currentKey === 0 ? (
            <Repairman user={user} />
          ) : (
            <History user={user} />
          )}
        </div>
      </div>
    </div>
  );
};

const Repairman = ({ user }: { user: IUser }) => {
  const [params, setParams] = useState({});
  const { data, isLoading, isError } = GET_REPAIRING_MOBILE(user.id, params);

  const onSearch = (values: any) => {
    setParams({ search: values });
  };

  if (isLoading) {
    return (
      <div style={{ height: "calc(100vh - 77px)" }} className="px-3">
        <InputSearch placeholder={t(_placeholder)} onSearch={onSearch} />
        <div className="mt-2">{t("mobile.repair-lists")}</div>
        <div className="w-100 h-40 d-flex flex-column justify-end align-center">
          <ClipLoader color="#b69e4f" />
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div style={{ height: "calc(100vh - 77px)" }} className="px-3">
        <InputSearch placeholder={t(_placeholder)} onSearch={onSearch} />
        <div className="mt-2">{t("mobile.repair-lists")}</div>
        <div className="w-100 h-40 d-flex flex-column justify-end align-center">
          <ErrorPage />
        </div>
      </div>
    );
  }

  if (data?.data.length === 0 || !data?.data) {
    return (
      <div style={{ height: "calc(100vh - 77px)" }} className="px-3">
        <InputSearch placeholder={t(_placeholder)} onSearch={onSearch} />
        <div className="mt-2">{t("mobile.repair-lists")}</div>
        <div className="w-100 h-40 d-flex flex-column justify-end align-center">
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            className="color-gold expty-color"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="h-100">
      <InputSearch placeholder={t(_placeholder)} onSearch={onSearch} />
      <div className="mt-2">{t("mobile.repair-lists")}</div>
      {data?.data.map((item, index) => {
        if (item.status === "CANCEL" || item.status === "COMPLETE")
          return <div key={index}></div>;
        return <RepairmanCard values={item} key={index} />;
      })}
    </div>
  );
};

const History = ({ user }: { user: IUser }) => {
  const [params, setParams] = useState({});
  const { data, isLoading, isError } = GET_REPAIRING_MOBILE(user.id, params);

  const onSearch = (values: any) => {
    setParams({ ...params, search: values });
  };

  if (isLoading) {
    return (
      <div style={{ height: "calc(100vh - 77px)" }} className="px-3">
        <InputSearch placeholder={t(_placeholder)} onSearch={onSearch} />
        <div className="mt-2">{t("mobile.repair-lists")}</div>
        <div className="w-100 h-40 d-flex flex-column justify-end align-center">
          <ClipLoader color="#b69e4f" />
        </div>
      </div>
    );
  }

  if (isError) {
    return (
      <div style={{ height: "calc(100vh - 77px)" }} className="px-3">
        <InputSearch placeholder={t(_placeholder)} onSearch={onSearch} />
        <div className="mt-2">{t("mobile.repair-lists")}</div>
        <div className="w-100 h-40 d-flex flex-column justify-end align-center">
          <ErrorPage />
        </div>
      </div>
    );
  }

  if (data?.data.length === 0 || !data?.data) {
    return (
      <div style={{ height: "calc(100vh - 77px)" }} className="px-3">
        <InputSearch placeholder={t(_placeholder)} onSearch={onSearch} />
        <div className="mt-2">{t("mobile.repair-lists")}</div>
        <div className="w-100 h-40 d-flex flex-column justify-end align-center">
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            className="color-gold expty-color"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="h-100">
      <InputSearch placeholder={t(_placeholder)} onSearch={onSearch} />
      <div className="mt-2">{t("mobile.repair-lists")}</div>
      {data?.data.map((item, index) => {
        if (item.status === "CANCEL" || item.status === "COMPLETE")
          return <RepairmanCard values={item} key={index} />;
        return <div key={index}></div>;
      })}
    </div>
  );
};

export const RepairmanCard = ({
  values,
  showStatus = true,
  isClassName = true,
  isClick = true,
}: {
  values: IRepairing;
  showStatus?: boolean;
  isClassName?: boolean;
  isClick?: boolean;
}) => {
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const { push } = useHistory();

  const {
    repairingCategory,
    repairingItem,
    repairingZone,
    unit,
    user,
    status,
    finishDate,
    repairingAppointments,
    detail,
    createdAt,
    repairingCode,
  } = values;

  const onClick = ({ id }: { id: number }) => {
    if (status === "PROCESSING") {
      return push(`${pathname}/${id}`);
    }
    return push(`${pathname}/view/${id}`);
  };
  const statusColor = _colors_status.find((item) => item.key === status)?.color;
  const statusText = _colors_status.find((item) => item.key === status)?.name;

  const appointment = repairingAppointments?.sort((a, b) => {
    return b.id - a.id;
  });
  let zone: string | undefined;
  if (repairingZone) {
    zone = i18n.language === "en" ? repairingZone.nameEn : repairingZone.nameTh;
  }
  let category: string | undefined;
  if (repairingCategory) {
    category =
      i18n.language === "en"
        ? repairingCategory.nameEn
        : repairingCategory.nameTh;
  }
  let item: string | undefined;
  if (repairingItem) {
    item = i18n.language === "en" ? repairingItem.nameEn : repairingItem.nameTh;
  }

  return (
    <div
      className={`repairman-card ${isClassName ? "my-2" : "mb-1"} px-3 py-2 `}
      onClick={isClick ? () => onClick(values) : () => {}}
    >
      <Row>
        <Col span={24} className="font-bold f-16">
          {unit ? unit.unitNumber : "-"}
        </Col>
        <Col span={24}>
          {t("job-number")} {repairingCode || "-"}
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={12} className="text-left">
          <Row>{t("user-request")}</Row>
          <Row>{t("phone-number")}</Row>
        </Col>
        <Col span={12} className="text-right">
          <Row>{user ? user.fullName : "-"}</Row>
          <Row>{user ? user.phoneNumber : "-"}</Row>
        </Col>
      </Row>
      <Divider />
      <Row className="body">
        <Col span={12} className="text-left">
          <Row>{t("repairing-zone")}</Row>
          <Row>{t("repairing-category")}</Row>
          <Row>{t("repairing-item")}</Row>
          <Row>{t("details")}</Row>
          <Row>{t("created-date")}</Row>
        </Col>
        <Col span={12} className="text-right">
          <Row>{repairingZone ? zone : "-"}</Row>
          <Row>{repairingCategory ? category : "-"}</Row>
          <Row>{repairingItem ? item : "-"}</Row>
          <Row className="line-clamp">{detail || "-"}</Row>
          <Row>
            {parse(
              createdAt,
              i18n.language,
              `${
                i18n.language === "en"
                  ? "DD MMM YYYY / HH:mm a"
                  : "DD MMM BBBB / HH:mm a"
              }`
            ) || "-"}
          </Row>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24} style={{ color: "#333333", opacity: 0.5 }}>
          {t("appointment-date")}
        </Col>
        <Col span={24} className="color-gold">
          {repairingAppointments.length > 0
            ? parse(
                appointment[0].appointmentFrom,
                i18n.language,
                `${
                  i18n.language === "en"
                    ? "DD MMM YYYY / HH:mm a"
                    : "DD MMM BBBB / HH:mm a"
                }`
              )
            : "-"}
        </Col>
      </Row>

      {showStatus && (
        <>
          <Divider />
          <Row>
            <div className="pr-2 font-bold f-16" style={{ color: statusColor }}>
              {t(`${statusText}`)}
            </div>
            {(status === "COMPLETE" || status === "CANCEL") && (
              <div
                className="f-16"
                style={{ color: status === "COMPLETE" ? "#009681" : "#E61E1E" }}
              >
                {finishDate
                  ? parse(
                      finishDate,
                      i18n.language,
                      `${
                        i18n.language === "en"
                          ? "DD MMM YYYY / HH:mm a"
                          : "DD MMM BBBB / HH:mm a"
                      }`
                    )
                  : "-"}
              </div>
            )}
          </Row>
        </>
      )}
    </div>
  );
};

export default RepairmanPage;
