import { Layout, Image, Select, Col, Row, notification, Button } from "antd";
import { ExclamationCircleFilled, LogoutOutlined } from "@ant-design/icons";
import DefaultAvatar from "../assets/images/no_profile.png";
import { useTranslation } from "react-i18next";
import { appendLangToHtmlTag, setLocalLanguage } from "../i18n";
import { GET_USER_PROFILE, TO_LOGIN_PAGE } from "../store/auth";
import { GET_USER_BY_ID, IUser } from "../store";
import { useEffect, useState } from "react";

const Navbar = () => {
  return (
    <Layout.Header className="layout-navbar">
      <ProfileBox />
    </Layout.Header>
  );
};

const ProfileBox = () => {
  const { i18n, t } = useTranslation();
  const { id } = GET_USER_PROFILE();
  const { data } = GET_USER_BY_ID(id);

  const [profile, setProfile] = useState<IUser | undefined>();

  useEffect(() => {
    inititalProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const inititalProfile = () => {
    setProfile(data?.data);
  };

  const _logout = async () => {
    TO_LOGIN_PAGE();
  };

  const onLangChange = (e: any) => {
    i18n.changeLanguage(e);
    setLocalLanguage(e);
    appendLangToHtmlTag(e)
  };

  // const overlay = (
  //   <Menu>
  //     <Menu.Item onClick={_logout}>
  //       <span style={{ color: "black" }}>{t("log-out")}</span>
  //     </Menu.Item>
  //   </Menu>
  // );

  const openNotificationWithIcon = () => {
    const btn = (
      <Button className="button-log-out" size="small" onClick={_logout}>
        {t("log-out")}
      </Button>
    );
    notification.warning({
      message: t("log-out"),
      description: t("swal.press-confirm-to-sign-out"),
      btn,
      duration: null,
      icon: <ExclamationCircleFilled style={{ color: "#deb961" }} />,
    });
  };
  return (
    <Row className="h-100" gutter={[24, 0]} align="middle" justify="end">
      <Col className="h-100 py-3" style={{ width: "130px" }}>
        <Select
          onChange={onLangChange}
          className="w-100 navbar-select"
          value={i18n.language}
        >
          <Select.Option value="en">EN</Select.Option>
          <Select.Option value="th">TH</Select.Option>
        </Select>
      </Col>
      <Col className="h-100 py-3">
        <Image
          src={profile?.avatar?.imageUrl || DefaultAvatar}
          preview={false}
          height="100%"
        />
      </Col>
      <Col>
        <div className="h-100">
          <h4 className="mb-0 color-white">{profile?.fullName || ""}</h4>
          <h5 className="mb-0 color-white">{profile?.email || ""}</h5>
        </div>
      </Col>
      <Col className="h-100">
        {/* <Dropdown overlay={overlay}> */}
        <div className="h-100 d-flex align-center">
          <LogoutOutlined
            className="color-white f-28 pointer"
            onClick={() => openNotificationWithIcon()}
          />
        </div>
        {/* </Dropdown> */}
      </Col>
    </Row>
  );
};

export default Navbar;
