export interface IProvince {
  province_th: string;
  province_en: string;
  children: IDistrict[];
}

export interface IDistrict {
  district_th: string;
  district_en: string;
  children: ISubdistrict[];
}

export interface ISubdistrict {
  subdistrict_th: string;
  subdistrict_en: string;
  post_code: string;
}

export const zipcode = [
  {
    province_th: "กรุงเทพมหานคร",
    province_en: "Bangkok",
    children: [
      {
        district_th: "เขตพระนคร",
        district_en: "Khet Phra Nakhon",
        children: [
          {
            subdistrict_th: "แขวงพระบรมมหาราชวัง",
            subdistrict_en: "Khwaeng Phra Borom Maha Ratchawang",
            post_code: "10200",
          },
          {
            subdistrict_th: "แขวงวังบูรพาภิรมย์",
            subdistrict_en: "Khwaeng Wang Burapha Phirom",
            post_code: "10200",
          },
          {
            subdistrict_th: "แขวงวัดราชบพิธ",
            subdistrict_en: "Khwaeng Wat Ratchabophit",
            post_code: "10200",
          },
          {
            subdistrict_th: "แขวงสำราญราษฎร์",
            subdistrict_en: "Khwaeng Samran Rat",
            post_code: "10200",
          },
          {
            subdistrict_th: "แขวงศาลเจ้าพ่อเสือ",
            subdistrict_en: "Khwaeng San Chao Pho Suea",
            post_code: "10200",
          },
          {
            subdistrict_th: "แขวงเสาชิงช้า",
            subdistrict_en: "Khwaeng Sao Chingcha",
            post_code: "10200",
          },
          {
            subdistrict_th: "แขวงบวรนิเวศ",
            subdistrict_en: "Khwaeng Bowon Niwet",
            post_code: "10200",
          },
          {
            subdistrict_th: "แขวงตลาดยอด",
            subdistrict_en: "Khwaeng Talat Yot",
            post_code: "10200",
          },
          {
            subdistrict_th: "แขวงชนะสงคราม",
            subdistrict_en: "Khwaeng Chana Songkhram",
            post_code: "10200",
          },
          {
            subdistrict_th: "แขวงบ้านพานถม",
            subdistrict_en: "Khwaeng Ban Phan Thom",
            post_code: "10200",
          },
          {
            subdistrict_th: "แขวงบางขุนพรหม",
            subdistrict_en: "Khwaeng Bang Khun Phrom",
            post_code: "10200",
          },
          {
            subdistrict_th: "แขวงวัดสามพระยา",
            subdistrict_en: "Khwaeng Wat Sam Phraya",
            post_code: "10200",
          },
        ],
      },
      {
        district_th: "เขตดุสิต",
        district_en: "Khet Dusit",
        children: [
          {
            subdistrict_th: "แขวงดุสิต",
            subdistrict_en: "Khwaeng Dusit",
            post_code: "10300",
          },
          {
            subdistrict_th: "แขวงวชิรพยาบาล",
            subdistrict_en: "Khwaeng Wachira Phayaban",
            post_code: "10300",
          },
          {
            subdistrict_th: "แขวงสวนจิตรลดา",
            subdistrict_en: "Khwaeng Suan Chitlada",
            post_code: "10300",
          },
          {
            subdistrict_th: "แขวงสี่แยกมหานาค",
            subdistrict_en: "Khwaeng Si Yaek Mahanak",
            post_code: "10300",
          },
          {
            subdistrict_th: "แขวงถนนนครไชยศรี",
            subdistrict_en: "Khwaeng Thanon Nakhon Chai Si",
            post_code: "10300",
          },
        ],
      },
      {
        district_th: "เขตหนองจอก",
        district_en: "Khet Nong Chok",
        children: [
          {
            subdistrict_th: "แขวงกระทุ่มราย",
            subdistrict_en: "Khwaeng Krathum Rai",
            post_code: "10530",
          },
          {
            subdistrict_th: "แขวงหนองจอก",
            subdistrict_en: "Khwaeng Nong Chok",
            post_code: "10530",
          },
          {
            subdistrict_th: "แขวงคลองสิบ",
            subdistrict_en: "Khwaeng Khlong Sip",
            post_code: "10530",
          },
          {
            subdistrict_th: "แขวงคลองสิบสอง",
            subdistrict_en: "Khwaeng Khlong Sip Song",
            post_code: "10530",
          },
          {
            subdistrict_th: "แขวงโคกแฝด",
            subdistrict_en: "Khwaeng Khok Faet",
            post_code: "10530",
          },
          {
            subdistrict_th: "แขวงคู้ฝั่งเหนือ",
            subdistrict_en: "Khwaeng Khu Fang Nuea",
            post_code: "10530",
          },
          {
            subdistrict_th: "แขวงลำผักชี",
            subdistrict_en: "Khwaeng Lam Phak Chi",
            post_code: "10530",
          },
          {
            subdistrict_th: "แขวงลำต้อยติ่ง",
            subdistrict_en: "Khwaeng Lam Toiting",
            post_code: "10530",
          },
        ],
      },
      {
        district_th: "เขตบางรัก",
        district_en: "Khet Bang Rak",
        children: [
          {
            subdistrict_th: "แขวงมหาพฤฒาราม",
            subdistrict_en: "Khwaeng Maha Phruettharam",
            post_code: "10500",
          },
          {
            subdistrict_th: "แขวงสีลม",
            subdistrict_en: "Khwaeng Si Lom",
            post_code: "10500",
          },
          {
            subdistrict_th: "แขวงสุริยวงศ์",
            subdistrict_en: "Khwaeng Suriyawong",
            post_code: "10500",
          },
          {
            subdistrict_th: "แขวงบางรัก",
            subdistrict_en: "Khwaeng Bang Rak",
            post_code: "10500",
          },
          {
            subdistrict_th: "แขวงสี่พระยา",
            subdistrict_en: "Khwaeng Si Phraya",
            post_code: "10500",
          },
        ],
      },
      {
        district_th: "เขตบางเขน",
        district_en: "Khet Bang Khen",
        children: [
          {
            subdistrict_th: "แขวงอนุสาวรีย์",
            subdistrict_en: "Khwaeng Anusawari",
            post_code: "10220",
          },
          {
            subdistrict_th: "แขวงท่าแร้ง",
            subdistrict_en: "Khwaeng Tha Raeng",
            post_code: "10220",
          },
        ],
      },
      {
        district_th: "เขตบางกะปิ",
        district_en: "Khet Bang Kapi",
        children: [
          {
            subdistrict_th: "แขวงคลองจั่น",
            subdistrict_en: "Khwaeng Khlong Chan",
            post_code: "10240",
          },
          {
            subdistrict_th: "แขวงหัวหมาก",
            subdistrict_en: "Khwaeng Hua Mak",
            post_code: "10240",
          },
        ],
      },
      {
        district_th: "เขตปทุมวัน",
        district_en: "Khet Pathum Wan",
        children: [
          {
            subdistrict_th: "แขวงรองเมือง",
            subdistrict_en: "Khwaeng Rong Mueang",
            post_code: "10330",
          },
          {
            subdistrict_th: "แขวงวังใหม่",
            subdistrict_en: "Khwaeng Wang Mai",
            post_code: "10330",
          },
          {
            subdistrict_th: "แขวงปทุมวัน",
            subdistrict_en: "Khwaeng Pathum Wan",
            post_code: "10330",
          },
          {
            subdistrict_th: "แขวงลุมพินี",
            subdistrict_en: "Khwaeng Lumphini",
            post_code: "10330",
          },
        ],
      },
      {
        district_th: "เขตป้อมปราบศัตรูพ่าย",
        district_en: "Khet Pom Prap Sattru Phai",
        children: [
          {
            subdistrict_th: "แขวงป้อมปราบ",
            subdistrict_en: "Khwaeng Pom Prap",
            post_code: "10100",
          },
          {
            subdistrict_th: "แขวงวัดเทพศิรินทร์",
            subdistrict_en: "Khwaeng Wat Thep Sirin",
            post_code: "10100",
          },
          {
            subdistrict_th: "แขวงคลองมหานาค",
            subdistrict_en: "Khwaeng Khlong Maha Nak",
            post_code: "10100",
          },
          {
            subdistrict_th: "แขวงบ้านบาตร",
            subdistrict_en: "Khwaeng Ban Bat",
            post_code: "10100",
          },
          {
            subdistrict_th: "แขวงวัดโสมนัส",
            subdistrict_en: "Khwaeng Wat Sommanat",
            post_code: "10100",
          },
        ],
      },
      {
        district_th: "เขตพระโขนง",
        district_en: "Khet Phra Khanong",
        children: [
          {
            subdistrict_th: "แขวงบางจาก",
            subdistrict_en: "Khwaeng Bang Chak",
            post_code: "10260",
          },
          {
            subdistrict_th: "แขวงพระโขนงใต้",
            subdistrict_en: "Khwaeng Phra Khanong Tai",
            post_code: "10260",
          },
        ],
      },
      {
        district_th: "เขตมีนบุรี",
        district_en: "Khet Min Buri",
        children: [
          {
            subdistrict_th: "แขวงมีนบุรี",
            subdistrict_en: "Khwaeng Min Buri",
            post_code: "10510",
          },
          {
            subdistrict_th: "แขวงแสนแสบ",
            subdistrict_en: "Khwaeng Saen Saep",
            post_code: "10510",
          },
        ],
      },
      {
        district_th: "เขตลาดกระบัง",
        district_en: "Khet Lat Krabang",
        children: [
          {
            subdistrict_th: "แขวงลาดกระบัง",
            subdistrict_en: "Khwaeng Lat Krabang",
            post_code: "10520",
          },
          {
            subdistrict_th: "แขวงคลองสองต้นนุ่น",
            subdistrict_en: "Khwaeng Khlong Song Ton Nun",
            post_code: "10520",
          },
          {
            subdistrict_th: "แขวงคลองสามประเวศ",
            subdistrict_en: "Khwaeng Khlong Sam Prawet",
            post_code: "10520",
          },
          {
            subdistrict_th: "แขวงลำปลาทิว",
            subdistrict_en: "Khwaeng Lam Pla Thio",
            post_code: "10520",
          },
          {
            subdistrict_th: "แขวงทับยาว",
            subdistrict_en: "Khwaeng Thap Yao",
            post_code: "10520",
          },
          {
            subdistrict_th: "แขวงขุมทอง",
            subdistrict_en: "Khwaeng Khum Thong",
            post_code: "10520",
          },
        ],
      },
      {
        district_th: "เขตยานนาวา",
        district_en: "Khet Yan Nawa",
        children: [
          {
            subdistrict_th: "แขวงช่องนนทรี",
            subdistrict_en: "Khwaeng Chong Nonsi",
            post_code: "10120",
          },
          {
            subdistrict_th: "แขวงบางโพงพาง",
            subdistrict_en: "Khwaeng Bang Phongphang",
            post_code: "10120",
          },
        ],
      },
      {
        district_th: "เขตสัมพันธวงศ์",
        district_en: "Khet Samphanthawong",
        children: [
          {
            subdistrict_th: "แขวงจักรวรรดิ",
            subdistrict_en: "Khwaeng Chakkrawat",
            post_code: "10100",
          },
          {
            subdistrict_th: "แขวงสัมพันธวงศ์",
            subdistrict_en: "Khwaeng Samphanthawong",
            post_code: "10100",
          },
          {
            subdistrict_th: "แขวงตลาดน้อย",
            subdistrict_en: "Khwaeng Talat Noi",
            post_code: "10100",
          },
        ],
      },
      {
        district_th: "เขตพญาไท",
        district_en: "Khet Phaya Thai",
        children: [
          {
            subdistrict_th: "แขวงสามเสนใน",
            subdistrict_en: "Khwaeng Sam Sen Nai",
            post_code: "10400",
          },
          {
            subdistrict_th: "แขวงพญาไท",
            subdistrict_en: "Khwaeng Phaya Thai",
            post_code: "10400",
          },
        ],
      },
      {
        district_th: "เขตธนบุรี",
        district_en: "Khet Thon Buri",
        children: [
          {
            subdistrict_th: "แขวงวัดกัลยาณ์",
            subdistrict_en: "Khwaeng Wat Kanlaya",
            post_code: "10600",
          },
          {
            subdistrict_th: "แขวงหิรัญรูจี",
            subdistrict_en: "Khwaeng Hiran Ruchi",
            post_code: "10600",
          },
          {
            subdistrict_th: "แขวงบางยี่เรือ",
            subdistrict_en: "Khwaeng Bang Yi Ruea",
            post_code: "10600",
          },
          {
            subdistrict_th: "แขวงบุคคโล",
            subdistrict_en: "Khwaeng Bukkhalo",
            post_code: "10600",
          },
          {
            subdistrict_th: "แขวงตลาดพลู",
            subdistrict_en: "Khwaeng Talat Phlu",
            post_code: "10600",
          },
          {
            subdistrict_th: "แขวงดาวคะนอง",
            subdistrict_en: "Khwaeng Dao Khanong",
            post_code: "10600",
          },
          {
            subdistrict_th: "แขวงสำเหร่",
            subdistrict_en: "Khwaeng Samre",
            post_code: "10600",
          },
        ],
      },
      {
        district_th: "เขตบางกอกใหญ่",
        district_en: "Khet Bangkok Yai",
        children: [
          {
            subdistrict_th: "แขวงวัดอรุณ",
            subdistrict_en: "Khwaeng Wat Arun",
            post_code: "10600",
          },
          {
            subdistrict_th: "แขวงวัดท่าพระ",
            subdistrict_en: "Khwaeng Wat Tha Phra",
            post_code: "10600",
          },
        ],
      },
      {
        district_th: "เขตห้วยขวาง",
        district_en: "Khet Huai Khwang",
        children: [
          {
            subdistrict_th: "แขวงห้วยขวาง",
            subdistrict_en: "Khwaeng Huai Khwang",
            post_code: "10310",
          },
          {
            subdistrict_th: "แขวงบางกะปิ",
            subdistrict_en: "Khwaeng Bang Kapi",
            post_code: "10310",
          },
          {
            subdistrict_th: "แขวงสามเสนนอก",
            subdistrict_en: "Khwaeng Sam Sen Nok",
            post_code: "10310",
          },
        ],
      },
      {
        district_th: "เขตคลองสาน",
        district_en: "Khet Khlong San",
        children: [
          {
            subdistrict_th: "แขวงสมเด็จเจ้าพระยา",
            subdistrict_en: "Khwaeng Somdet Chao Phraya",
            post_code: "10600",
          },
          {
            subdistrict_th: "แขวงคลองสาน",
            subdistrict_en: "Khwaeng Khlong San",
            post_code: "10600",
          },
          {
            subdistrict_th: "แขวงบางลำภูล่าง",
            subdistrict_en: "Khwaeng Bang Lamphu Lang",
            post_code: "10600",
          },
          {
            subdistrict_th: "แขวงคลองต้นไทร",
            subdistrict_en: "Khwaeng Khlong Ton Sai",
            post_code: "10600",
          },
        ],
      },
      {
        district_th: "เขตตลิ่งชัน",
        district_en: "Khet Taling Chan",
        children: [
          {
            subdistrict_th: "แขวงคลองชักพระ",
            subdistrict_en: "Khwaeng Khlong Chak Phra",
            post_code: "10170",
          },
          {
            subdistrict_th: "แขวงตลิ่งชัน",
            subdistrict_en: "Khwaeng Taling Chan",
            post_code: "10170",
          },
          {
            subdistrict_th: "แขวงฉิมพลี",
            subdistrict_en: "Khwaeng Chimphli",
            post_code: "10170",
          },
          {
            subdistrict_th: "แขวงบางพรม",
            subdistrict_en: "Khwaeng Bang Phrom",
            post_code: "10170",
          },
          {
            subdistrict_th: "แขวงบางระมาด",
            subdistrict_en: "Khwaeng Bang Ramat",
            post_code: "10170",
          },
          {
            subdistrict_th: "แขวงบางเชือกหนัง",
            subdistrict_en: "Khwaeng Bang Chueak Nang",
            post_code: "10170",
          },
        ],
      },
      {
        district_th: "เขตบางกอกน้อย",
        district_en: "Khet Bangkok Noi",
        children: [
          {
            subdistrict_th: "แขวงศิริราช",
            subdistrict_en: "Khwaeng Sirirat",
            post_code: "10700",
          },
          {
            subdistrict_th: "แขวงบ้านช่างหล่อ",
            subdistrict_en: "Khwaeng Ban Chang Lo",
            post_code: "10700",
          },
          {
            subdistrict_th: "แขวงบางขุนนนท์",
            subdistrict_en: "Khwaeng Bang Khun Non",
            post_code: "10700",
          },
          {
            subdistrict_th: "แขวงบางขุนศรี",
            subdistrict_en: "Khwaeng Bang Khun Si",
            post_code: "10700",
          },
          {
            subdistrict_th: "แขวงอรุณอมรินทร์",
            subdistrict_en: "Khwaeng Arun Ammarin",
            post_code: "10700",
          },
        ],
      },
      {
        district_th: "เขตบางขุนเทียน",
        district_en: "Khet Bang Khun Thian",
        children: [
          {
            subdistrict_th: "แขวงท่าข้าม",
            subdistrict_en: "Khwaeng Tha Kham",
            post_code: "10150",
          },
          {
            subdistrict_th: "แขวงแสมดำ",
            subdistrict_en: "Khwaeng Samae Dam",
            post_code: "10150",
          },
        ],
      },
      {
        district_th: "เขตภาษีเจริญ",
        district_en: "Khet Phasi Charoen",
        children: [
          {
            subdistrict_th: "แขวงบางหว้า",
            subdistrict_en: "Khwaeng Bang Wa",
            post_code: "10160",
          },
          {
            subdistrict_th: "แขวงบางด้วน",
            subdistrict_en: "Khwaeng Bang Duan",
            post_code: "10160",
          },
          {
            subdistrict_th: "แขวงบางจาก",
            subdistrict_en: "Khwaeng Bang Chak",
            post_code: "10160",
          },
          {
            subdistrict_th: "แขวงบางแวก",
            subdistrict_en: "Khwaeng Bang Waek",
            post_code: "10160",
          },
          {
            subdistrict_th: "แขวงคลองขวาง",
            subdistrict_en: "Khwaeng Khlong Khwang",
            post_code: "10160",
          },
          {
            subdistrict_th: "แขวงปากคลองภาษีเจริญ",
            subdistrict_en: "Khwaeng Pak Khlong Phasi Charoen",
            post_code: "10160",
          },
          {
            subdistrict_th: "แขวงคูหาสวรรค์",
            subdistrict_en: "Khwaeng Khuha Sawan",
            post_code: "10160",
          },
        ],
      },
      {
        district_th: "เขตหนองแขม",
        district_en: "Khet Nong Khaem",
        children: [
          {
            subdistrict_th: "แขวงหนองแขม",
            subdistrict_en: "Khwaeng Nong Khaem",
            post_code: "10160",
          },
          {
            subdistrict_th: "แขวงหนองค้างพลู",
            subdistrict_en: "Khwaeng Nong Khang Phlu",
            post_code: "10160",
          },
        ],
      },
      {
        district_th: "เขตราษฎร์บูรณะ",
        district_en: "Khet Rat Burana",
        children: [
          {
            subdistrict_th: "แขวงราษฎร์บูรณะ",
            subdistrict_en: "Khwaeng Rat Burana",
            post_code: "10140",
          },
          {
            subdistrict_th: "แขวงบางปะกอก",
            subdistrict_en: "Khwaeng Bang Pa Kok",
            post_code: "10140",
          },
        ],
      },
      {
        district_th: "เขตบางพลัด",
        district_en: "Khet Bang Phlat",
        children: [
          {
            subdistrict_th: "แขวงบางพลัด",
            subdistrict_en: "Khwaeng Bang Phlat",
            post_code: "10700",
          },
          {
            subdistrict_th: "แขวงบางอ้อ",
            subdistrict_en: "Khwaeng Bang O",
            post_code: "10700",
          },
          {
            subdistrict_th: "แขวงบางบำหรุ",
            subdistrict_en: "Khwaeng Bang Bamru",
            post_code: "10700",
          },
          {
            subdistrict_th: "แขวงบางยี่ขัน",
            subdistrict_en: "Khwaeng Bang Yi Khan",
            post_code: "10700",
          },
        ],
      },
      {
        district_th: "เขตดินแดง",
        district_en: "Khet Din Daeng",
        children: [
          {
            subdistrict_th: "แขวงดินแดง",
            subdistrict_en: "Khwaeng Din Daeng",
            post_code: "10400",
          },
          {
            subdistrict_th: "แขวงรัชดาภิเษก",
            subdistrict_en: "Khwaeng Ratchadaphisek",
            post_code: "10400",
          },
        ],
      },
      {
        district_th: "เขตบึงกุ่ม",
        district_en: "Khet Bueng Kum",
        children: [
          {
            subdistrict_th: "แขวงคลองกุ่ม",
            subdistrict_en: "Khwaeng Khlong Kum",
            post_code: "10240",
          },
          {
            subdistrict_th: "แขวงนวมินทร์",
            subdistrict_en: "Khwaeng Nawamin",
            post_code: "10240",
          },
          {
            subdistrict_th: "แขวงนวลจันทร์",
            subdistrict_en: "Khwaeng Nuan Chan",
            post_code: "10230",
          },
        ],
      },
      {
        district_th: "เขตสาทร",
        district_en: "Khet Sathon",
        children: [
          {
            subdistrict_th: "แขวงทุ่งวัดดอน",
            subdistrict_en: "Khwaeng Thung Wat Don",
            post_code: "10120",
          },
          {
            subdistrict_th: "แขวงยานนาวา",
            subdistrict_en: "Khwaeng Yan Nawa",
            post_code: "10120",
          },
          {
            subdistrict_th: "แขวงทุ่งมหาเมฆ",
            subdistrict_en: "Khwaeng Thung Maha Mek",
            post_code: "10120",
          },
        ],
      },
      {
        district_th: "เขตบางซื่อ",
        district_en: "Khet Bang Sue",
        children: [
          {
            subdistrict_th: "แขวงบางซื่อ",
            subdistrict_en: "Khwaeng Bang Sue",
            post_code: "10800",
          },
          {
            subdistrict_th: "แขวงวงศ์สว่าง",
            subdistrict_en: "Khwaeng Wong Sawang",
            post_code: "10800",
          },
        ],
      },
      {
        district_th: "เขตจตุจักร",
        district_en: "Khet Chatuchak",
        children: [
          {
            subdistrict_th: "แขวงลาดยาว",
            subdistrict_en: "Khwaeng Lat Yao",
            post_code: "10900",
          },
          {
            subdistrict_th: "แขวงเสนานิคม",
            subdistrict_en: "Khwaeng Sena Nikhom",
            post_code: "10900",
          },
          {
            subdistrict_th: "แขวงจันทรเกษม",
            subdistrict_en: "Khwaeng Chan Kasem",
            post_code: "10900",
          },
          {
            subdistrict_th: "แขวงจอมพล",
            subdistrict_en: "Khwaeng Chom Phon",
            post_code: "10900",
          },
          {
            subdistrict_th: "แขวงจตุจักร",
            subdistrict_en: "Khwaeng Chatuchak",
            post_code: "10900",
          },
        ],
      },
      {
        district_th: "เขตบางคอแหลม",
        district_en: "Khet Bang Kho Laem",
        children: [
          {
            subdistrict_th: "แขวงบางคอแหลม",
            subdistrict_en: "Khwaeng Bang Kho Laem",
            post_code: "10120",
          },
          {
            subdistrict_th: "แขวงวัดพระยาไกร",
            subdistrict_en: "Khwaeng Wat Phraya Krai",
            post_code: "10120",
          },
          {
            subdistrict_th: "แขวงบางโคล่",
            subdistrict_en: "Khwaeng Bang Khlo",
            post_code: "10120",
          },
        ],
      },
      {
        district_th: "เขตประเวศ",
        district_en: "Khet Prawet",
        children: [
          {
            subdistrict_th: "แขวงประเวศ",
            subdistrict_en: "Khwaeng Prawet",
            post_code: "10250",
          },
          {
            subdistrict_th: "แขวงหนองบอน",
            subdistrict_en: "Khwaeng Nong Bon",
            post_code: "10250",
          },
          {
            subdistrict_th: "แขวงดอกไม้",
            subdistrict_en: "Khwaeng Dok Mai",
            post_code: "10250",
          },
        ],
      },
      {
        district_th: "เขตคลองเตย",
        district_en: "Khet Khlong Toei",
        children: [
          {
            subdistrict_th: "แขวงคลองเตย",
            subdistrict_en: "Khwaeng Khlong Toei",
            post_code: "10110",
          },
          {
            subdistrict_th: "แขวงคลองตัน",
            subdistrict_en: "Khwaeng Khlong Tan",
            post_code: "10110",
          },
          {
            subdistrict_th: "แขวงพระโขนง",
            subdistrict_en: "Khwaeng Phra Khanong",
            post_code: "10110",
          },
        ],
      },
      {
        district_th: "เขตสวนหลวง",
        district_en: "Khet Suan Luang",
        children: [
          {
            subdistrict_th: "แขวงสวนหลวง",
            subdistrict_en: "Khwaeng Suan Luang",
            post_code: "10250",
          },
          {
            subdistrict_th: "แขวงอ่อนนุช",
            subdistrict_en: "Khwaeng On Nut",
            post_code: "10250",
          },
          {
            subdistrict_th: "แขวงพัฒนาการ",
            subdistrict_en: "Khwaeng Phatthanakan",
            post_code: "10250",
          },
        ],
      },
      {
        district_th: "เขตจอมทอง",
        district_en: "Khet Chom Thong",
        children: [
          {
            subdistrict_th: "แขวงบางขุนเทียน",
            subdistrict_en: "Khwaeng Bang Khun Thian",
            post_code: "10150",
          },
          {
            subdistrict_th: "แขวงบางค้อ",
            subdistrict_en: "Khwaeng Bang Kho",
            post_code: "10150",
          },
          {
            subdistrict_th: "แขวงบางมด",
            subdistrict_en: "Khwaeng Bang Mot",
            post_code: "10150",
          },
          {
            subdistrict_th: "แขวงจอมทอง",
            subdistrict_en: "Khwaeng Chom Thong",
            post_code: "10150",
          },
        ],
      },
      {
        district_th: "เขตดอนเมือง",
        district_en: "Khet Don Mueang",
        children: [
          {
            subdistrict_th: "แขวงสีกัน",
            subdistrict_en: "Khwaeng Si Kan",
            post_code: "10210",
          },
          {
            subdistrict_th: "แขวงดอนเมือง",
            subdistrict_en: "Khwaeng Don Mueang",
            post_code: "10210",
          },
          {
            subdistrict_th: "แขวงสนามบิน",
            subdistrict_en: "Khwaeng Sanam Bin",
            post_code: "10210",
          },
        ],
      },
      {
        district_th: "เขตราชเทวี",
        district_en: "Khet Ratchathewi",
        children: [
          {
            subdistrict_th: "แขวงทุ่งพญาไท",
            subdistrict_en: "Khwaeng Thung Phaya Thai",
            post_code: "10400",
          },
          {
            subdistrict_th: "แขวงถนนพญาไท",
            subdistrict_en: "Khwaeng Thanon Phaya Thai",
            post_code: "10400",
          },
          {
            subdistrict_th: "แขวงถนนเพชรบุรี",
            subdistrict_en: "Khwaeng Thanon Phetchaburi",
            post_code: "10400",
          },
          {
            subdistrict_th: "แขวงมักกะสัน",
            subdistrict_en: "Khwaeng Makkasan",
            post_code: "10400",
          },
        ],
      },
      {
        district_th: "เขตลาดพร้าว",
        district_en: "Khet Lat Phrao",
        children: [
          {
            subdistrict_th: "แขวงลาดพร้าว",
            subdistrict_en: "Khwaeng Lat Phrao",
            post_code: "10230",
          },
          {
            subdistrict_th: "แขวงจรเข้บัว",
            subdistrict_en: "Khwaeng Chorakhe Bua",
            post_code: "10230",
          },
        ],
      },
      {
        district_th: "เขตวัฒนา",
        district_en: "Khet Vadhana",
        children: [
          {
            subdistrict_th: "แขวงคลองเตยเหนือ",
            subdistrict_en: "Khwaeng Khlong Toei Nuea",
            post_code: "10110",
          },
          {
            subdistrict_th: "แขวงคลองตันเหนือ",
            subdistrict_en: "Khwaeng Khlong Tan Nuea",
            post_code: "10110",
          },
          {
            subdistrict_th: "แขวงพระโขนงเหนือ",
            subdistrict_en: "Khwaeng Phra Khanong Nuea",
            post_code: "10110",
          },
        ],
      },
      {
        district_th: "เขตบางแค",
        district_en: "Khet Bang Khae",
        children: [
          {
            subdistrict_th: "แขวงบางแค",
            subdistrict_en: "Khwaeng Bang Khae",
            post_code: "10160",
          },
          {
            subdistrict_th: "แขวงบางแคเหนือ",
            subdistrict_en: "Khwaeng Bang Khae Nuea",
            post_code: "10160",
          },
          {
            subdistrict_th: "แขวงบางไผ่",
            subdistrict_en: "Khwaeng Bang Phai",
            post_code: "10160",
          },
          {
            subdistrict_th: "แขวงหลักสอง",
            subdistrict_en: "Khwaeng Lak Song",
            post_code: "10160",
          },
        ],
      },
      {
        district_th: "เขตหลักสี่",
        district_en: "Khet Lak Si",
        children: [
          {
            subdistrict_th: "แขวงทุ่งสองห้อง",
            subdistrict_en: "Khwaeng Thung Song Hong",
            post_code: "10210",
          },
          {
            subdistrict_th: "แขวงตลาดบางเขน",
            subdistrict_en: "Khwaeng Talat Bang Khen",
            post_code: "10210",
          },
        ],
      },
      {
        district_th: "เขตสายไหม",
        district_en: "Khet Sai Mai",
        children: [
          {
            subdistrict_th: "แขวงสายไหม",
            subdistrict_en: "Khwaeng Sai Mai",
            post_code: "10220",
          },
          {
            subdistrict_th: "แขวงออเงิน",
            subdistrict_en: "Khwaeng O Ngoen",
            post_code: "10220",
          },
          {
            subdistrict_th: "แขวงคลองถนน",
            subdistrict_en: "Khwaeng Khlong Thanon",
            post_code: "10220",
          },
        ],
      },
      {
        district_th: "เขตคันนายาว",
        district_en: "Khet Khan Na Yao",
        children: [
          {
            subdistrict_th: "แขวงคันนายาว",
            subdistrict_en: "Khwaeng Khan Na Yao",
            post_code: "10230",
          },
          {
            subdistrict_th: "แขวงรามอินทรา",
            subdistrict_en: "Khwaeng Ram Inthra",
            post_code: "10230",
          },
        ],
      },
      {
        district_th: "เขตสะพานสูง",
        district_en: "Khet Saphan Sung",
        children: [
          {
            subdistrict_th: "แขวงสะพานสูง",
            subdistrict_en: "Khwaeng Saphan Sung",
            post_code: "10240",
          },
          {
            subdistrict_th: "แขวงราษฎร์พัฒนา",
            subdistrict_en: "Khwaeng Rat Phatthana",
            post_code: "10240",
          },
          {
            subdistrict_th: "แขวงทับช้าง",
            subdistrict_en: "Khwaeng Thap Chang",
            post_code: "10250",
          },
        ],
      },
      {
        district_th: "เขตวังทองหลาง",
        district_en: "Khet Wang Thonglang",
        children: [
          {
            subdistrict_th: "แขวงวังทองหลาง",
            subdistrict_en: "Khwaeng Wang Thonglang",
            post_code: "10310",
          },
          {
            subdistrict_th: "แขวงสะพานสอง",
            subdistrict_en: "Khwaeng Saphan Song",
            post_code: "10310",
          },
          {
            subdistrict_th: "แขวงคลองเจ้าคุณสิงห์",
            subdistrict_en: "Khwaeng Khlong Chao Khun Sing",
            post_code: "10310",
          },
          {
            subdistrict_th: "แขวงพลับพลา",
            subdistrict_en: "Khwaeng Phlapphla",
            post_code: "10310",
          },
        ],
      },
      {
        district_th: "เขตคลองสามวา",
        district_en: "Khet Khlong Sam Wa",
        children: [
          {
            subdistrict_th: "แขวงสามวาตะวันตก",
            subdistrict_en: "Khwaeng Sam Wa Tawan Tok",
            post_code: "10510",
          },
          {
            subdistrict_th: "แขวงสามวาตะวันออก",
            subdistrict_en: "Khwaeng Sam Wa Tawan Ok",
            post_code: "10510",
          },
          {
            subdistrict_th: "แขวงบางชัน",
            subdistrict_en: "Khwaeng Bang Chan",
            post_code: "10510",
          },
          {
            subdistrict_th: "แขวงทรายกองดิน",
            subdistrict_en: "Khwaeng Sai Kong Din",
            post_code: "10510",
          },
          {
            subdistrict_th: "แขวงทรายกองดินใต้",
            subdistrict_en: "Khwaeng Sai Kong Din Tai",
            post_code: "10510",
          },
        ],
      },
      {
        district_th: "เขตบางนา",
        district_en: "Khet Bang Na",
        children: [
          {
            subdistrict_th: "แขวงบางนาเหนือ",
            subdistrict_en: "Khwaeng Bang Na Nuea",
            post_code: "10260",
          },
          {
            subdistrict_th: "แขวงบางนาใต้",
            subdistrict_en: "Khwaeng Bang Na Tai",
            post_code: "10260",
          },
        ],
      },
      {
        district_th: "เขตทวีวัฒนา",
        district_en: "Khet Thawi Watthana",
        children: [
          {
            subdistrict_th: "แขวงทวีวัฒนา",
            subdistrict_en: "Khwaeng Thawi Watthana",
            post_code: "10170",
          },
          {
            subdistrict_th: "แขวงศาลาธรรมสพน์",
            subdistrict_en: "Khwaeng Sala Thammasop",
            post_code: "10170",
          },
        ],
      },
      {
        district_th: "เขตทุ่งครุ",
        district_en: "Khet Thung Khru",
        children: [
          {
            subdistrict_th: "แขวงบางมด",
            subdistrict_en: "Khwaeng Bang Mot",
            post_code: "10140",
          },
          {
            subdistrict_th: "แขวงทุ่งครุ",
            subdistrict_en: "Khwaeng Thung Khru",
            post_code: "10140",
          },
        ],
      },
      {
        district_th: "เขตบางบอน",
        district_en: "Khet Bang Bon",
        children: [
          {
            subdistrict_th: "แขวงบางบอนเหนือ",
            subdistrict_en: "Khwaeng Bang Bon Nuea",
            post_code: "10150",
          },
          {
            subdistrict_th: "แขวงบางบอนใต้",
            subdistrict_en: "Khwaeng Bang Bon Tai",
            post_code: "10150",
          },
          {
            subdistrict_th: "แขวงคลองบางพราน",
            subdistrict_en: "Khwaeng Khlong Bang Phran",
            post_code: "10150",
          },
          {
            subdistrict_th: "แขวงคลองบางบอน",
            subdistrict_en: "Khwaeng Khlong Bang Bon",
            post_code: "10150",
          },
        ],
      },
    ],
  },
  {
    province_th: "สมุทรปราการ",
    province_en: "Samut Prakan",
    children: [
      {
        district_th: "อำเภอเมืองสมุทรปราการ",
        district_en: "Amphoe Mueang Samut Prakan",
        children: [
          {
            subdistrict_th: "ปากน้ำ",
            subdistrict_en: "Pak Nam",
            post_code: "10270",
          },
          {
            subdistrict_th: "สำโรงเหนือ",
            subdistrict_en: "Samrong Nuea",
            post_code: "10270",
          },
          {
            subdistrict_th: "บางเมือง",
            subdistrict_en: "Bang Mueang",
            post_code: "10270",
          },
          {
            subdistrict_th: "ท้ายบ้าน",
            subdistrict_en: "Thai Ban",
            post_code: "10280",
          },
          {
            subdistrict_th: "บางปูใหม่",
            subdistrict_en: "Bang Pu Mai",
            post_code: "10280",
          },
          {
            subdistrict_th: "แพรกษา",
            subdistrict_en: "Phraek Sa",
            post_code: "10280",
          },
          {
            subdistrict_th: "บางโปรง",
            subdistrict_en: "Bang Prong",
            post_code: "10270",
          },
          {
            subdistrict_th: "บางปู",
            subdistrict_en: "Bang Pu",
            post_code: "10280",
          },
          {
            subdistrict_th: "บางด้วน",
            subdistrict_en: "Bang Duan",
            post_code: "10270",
          },
          {
            subdistrict_th: "บางเมืองใหม่",
            subdistrict_en: "Bang Mueang Mai",
            post_code: "10270",
          },
          {
            subdistrict_th: "เทพารักษ์",
            subdistrict_en: "Thepharak",
            post_code: "10270",
          },
          {
            subdistrict_th: "ท้ายบ้านใหม่",
            subdistrict_en: "Thai Ban Mai",
            post_code: "10280",
          },
          {
            subdistrict_th: "แพรกษาใหม่",
            subdistrict_en: "Phraek Sa Mai",
            post_code: "10280",
          },
        ],
      },
      {
        district_th: "อำเภอบางบ่อ",
        district_en: "Amphoe Bang Bo",
        children: [
          {
            subdistrict_th: "บางบ่อ",
            subdistrict_en: "Bang Bo",
            post_code: "10560",
          },
          {
            subdistrict_th: "บ้านระกาศ",
            subdistrict_en: "Ban Rakat",
            post_code: "10560",
          },
          {
            subdistrict_th: "บางพลีน้อย",
            subdistrict_en: "Bang Phli Noi",
            post_code: "10560",
          },
          {
            subdistrict_th: "บางเพรียง",
            subdistrict_en: "Bang Phriang",
            post_code: "10560",
          },
          {
            subdistrict_th: "คลองด่าน",
            subdistrict_en: "Khlong Dan",
            post_code: "10550",
          },
          {
            subdistrict_th: "คลองสวน",
            subdistrict_en: "Khlong Suan",
            post_code: "10560",
          },
          {
            subdistrict_th: "เปร็ง",
            subdistrict_en: "Preng",
            post_code: "10560",
          },
          {
            subdistrict_th: "คลองนิยมยาตรา",
            subdistrict_en: "Khlong Niyom Yattra",
            post_code: "10560",
          },
        ],
      },
      {
        district_th: "อำเภอบางพลี",
        district_en: "Amphoe Bang Phli",
        children: [
          {
            subdistrict_th: "บางพลีใหญ่",
            subdistrict_en: "Blang Phli Yai",
            post_code: "10540",
          },
          {
            subdistrict_th: "บางแก้ว",
            subdistrict_en: "Bang Kaeo",
            post_code: "10540",
          },
          {
            subdistrict_th: "บางปลา",
            subdistrict_en: "Bang Pla",
            post_code: "10540",
          },
          {
            subdistrict_th: "บางโฉลง",
            subdistrict_en: "Bang Chalong",
            post_code: "10540",
          },
          {
            subdistrict_th: "ราชาเทวะ",
            subdistrict_en: "Racha Thewa",
            post_code: "10540",
          },
          {
            subdistrict_th: "หนองปรือ",
            subdistrict_en: "Nong Prue",
            post_code: "10540",
          },
        ],
      },
      {
        district_th: "อำเภอพระประแดง",
        district_en: "Amphoe Phra Pradaeng",
        children: [
          {
            subdistrict_th: "ตลาด",
            subdistrict_en: "Talat",
            post_code: "10130",
          },
          {
            subdistrict_th: "บางพึ่ง",
            subdistrict_en: "Bang Phueng",
            post_code: "10130",
          },
          {
            subdistrict_th: "บางจาก",
            subdistrict_en: "Bang Chak",
            post_code: "10130",
          },
          {
            subdistrict_th: "บางครุ",
            subdistrict_en: "Bang Khru",
            post_code: "10130",
          },
          {
            subdistrict_th: "บางหญ้าแพรก",
            subdistrict_en: "Bang Ya Phraek",
            post_code: "10130",
          },
          {
            subdistrict_th: "บางหัวเสือ",
            subdistrict_en: "Bang Hua Suea",
            post_code: "10130",
          },
          {
            subdistrict_th: "สำโรงใต้",
            subdistrict_en: "Samrong Tai",
            post_code: "10130",
          },
          {
            subdistrict_th: "บางยอ",
            subdistrict_en: "Bang Yo",
            post_code: "10130",
          },
          {
            subdistrict_th: "บางกะเจ้า",
            subdistrict_en: "Bang Kachao",
            post_code: "10130",
          },
          {
            subdistrict_th: "บางน้ำผึ้ง",
            subdistrict_en: "Bang Nam Phueng",
            post_code: "10130",
          },
          {
            subdistrict_th: "บางกระสอบ",
            subdistrict_en: "Bang Krasop",
            post_code: "10130",
          },
          {
            subdistrict_th: "บางกอบัว",
            subdistrict_en: "Bang Ko Bua",
            post_code: "10130",
          },
          {
            subdistrict_th: "ทรงคนอง",
            subdistrict_en: "Song Khanong",
            post_code: "10130",
          },
          {
            subdistrict_th: "สำโรง",
            subdistrict_en: "Samrong",
            post_code: "10130",
          },
          {
            subdistrict_th: "สำโรงกลาง",
            subdistrict_en: "Samrong Klang",
            post_code: "10130",
          },
        ],
      },
      {
        district_th: "อำเภอพระสมุทรเจดีย์",
        district_en: "Amphoe Phra Samut Chedi",
        children: [
          {
            subdistrict_th: "นาเกลือ",
            subdistrict_en: "Na Kluea",
            post_code: "10290",
          },
          {
            subdistrict_th: "บ้านคลองสวน",
            subdistrict_en: "Ban Khlong Suan",
            post_code: "10290",
          },
          {
            subdistrict_th: "แหลมฟ้าผ่า",
            subdistrict_en: "Laem Fa Pha",
            post_code: "10290",
          },
          {
            subdistrict_th: "ปากคลองบางปลากด",
            subdistrict_en: "Pak Khlong Bang Pla Kot",
            post_code: "10290",
          },
          {
            subdistrict_th: "ในคลองบางปลากด",
            subdistrict_en: "Nai Khlong Bang Pla Kot",
            post_code: "10290",
          },
        ],
      },
      {
        district_th: "อำเภอบางเสาธง",
        district_en: "Amphoe Bang Sao Thong",
        children: [
          {
            subdistrict_th: "บางเสาธง",
            subdistrict_en: "Bang Sao Thong",
            post_code: "10570",
          },
          {
            subdistrict_th: "ศีรษะจรเข้น้อย",
            subdistrict_en: "Sisa Chorakhe Noi",
            post_code: "10570",
          },
          {
            subdistrict_th: "ศีรษะจรเข้ใหญ่",
            subdistrict_en: "Sisa Chorakhe Yai",
            post_code: "10570",
          },
        ],
      },
    ],
  },
  {
    province_th: "นนทบุรี",
    province_en: "Nonthaburi",
    children: [
      {
        district_th: "อำเภอเมืองนนทบุรี",
        district_en: "Amphoe Mueang Nonthaburi",
        children: [
          {
            subdistrict_th: "สวนใหญ่",
            subdistrict_en: "Suan Yai",
            post_code: "11000",
          },
          {
            subdistrict_th: "ตลาดขวัญ",
            subdistrict_en: "Talat Khwan",
            post_code: "11000",
          },
          {
            subdistrict_th: "บางเขน",
            subdistrict_en: "Bang Khen",
            post_code: "11000",
          },
          {
            subdistrict_th: "บางกระสอ",
            subdistrict_en: "Bang Kraso",
            post_code: "11000",
          },
          {
            subdistrict_th: "ท่าทราย",
            subdistrict_en: "Tha Sai",
            post_code: "11000",
          },
          {
            subdistrict_th: "บางไผ่",
            subdistrict_en: "Bang Phai",
            post_code: "11000",
          },
          {
            subdistrict_th: "บางศรีเมือง",
            subdistrict_en: "Bang Si Mueang",
            post_code: "11000",
          },
          {
            subdistrict_th: "บางกร่าง",
            subdistrict_en: "Bang Krang",
            post_code: "11000",
          },
          {
            subdistrict_th: "ไทรม้า",
            subdistrict_en: "Sai Ma",
            post_code: "11000",
          },
          {
            subdistrict_th: "บางรักน้อย",
            subdistrict_en: "Bang Rak Noi",
            post_code: "11000",
          },
        ],
      },
      {
        district_th: "อำเภอบางกรวย",
        district_en: "Amphoe Bang Kruai",
        children: [
          {
            subdistrict_th: "วัดชลอ",
            subdistrict_en: "Wat Chalo",
            post_code: "11130",
          },
          {
            subdistrict_th: "บางกรวย",
            subdistrict_en: "Bang Kruai",
            post_code: "11130",
          },
          {
            subdistrict_th: "บางสีทอง",
            subdistrict_en: "Bang Si Thong",
            post_code: "11130",
          },
          {
            subdistrict_th: "บางขนุน",
            subdistrict_en: "Bang Khanun",
            post_code: "11130",
          },
          {
            subdistrict_th: "บางขุนกอง",
            subdistrict_en: "Bang Khun Kong",
            post_code: "11130",
          },
          {
            subdistrict_th: "บางคูเวียง",
            subdistrict_en: "Bang Khu Wiang",
            post_code: "11130",
          },
          {
            subdistrict_th: "มหาสวัสดิ์",
            subdistrict_en: "Maha Sawat",
            post_code: "11130",
          },
          {
            subdistrict_th: "ปลายบาง",
            subdistrict_en: "Plai Bang",
            post_code: "11130",
          },
          {
            subdistrict_th: "ศาลากลาง",
            subdistrict_en: "Sala Klang",
            post_code: "11130",
          },
        ],
      },
      {
        district_th: "อำเภอบางใหญ่",
        district_en: "Amphoe Bang Yai",
        children: [
          {
            subdistrict_th: "บางม่วง",
            subdistrict_en: "Bang Muang",
            post_code: "11140",
          },
          {
            subdistrict_th: "บางแม่นาง",
            subdistrict_en: "Bang Mae Nang",
            post_code: "11140",
          },
          {
            subdistrict_th: "บางเลน",
            subdistrict_en: "Bang Len",
            post_code: "11140",
          },
          {
            subdistrict_th: "เสาธงหิน",
            subdistrict_en: "Sao Thong Hin",
            post_code: "11140",
          },
          {
            subdistrict_th: "บางใหญ่",
            subdistrict_en: "Bang Yai",
            post_code: "11140",
          },
          {
            subdistrict_th: "บ้านใหม่",
            subdistrict_en: "Ban Mai",
            post_code: "11140",
          },
        ],
      },
      {
        district_th: "อำเภอบางบัวทอง",
        district_en: "Amphoe Bang Bua Thong",
        children: [
          {
            subdistrict_th: "โสนลอย",
            subdistrict_en: "Sano Loi",
            post_code: "11110",
          },
          {
            subdistrict_th: "บางบัวทอง",
            subdistrict_en: "Bang Bua Thong",
            post_code: "11110",
          },
          {
            subdistrict_th: "บางรักใหญ่",
            subdistrict_en: "Bang Rak Yai",
            post_code: "11110",
          },
          {
            subdistrict_th: "บางคูรัด",
            subdistrict_en: "Bang Khu Rat",
            post_code: "11110",
          },
          {
            subdistrict_th: "ละหาร",
            subdistrict_en: "Lahan",
            post_code: "11110",
          },
          {
            subdistrict_th: "ลำโพ",
            subdistrict_en: "Lam Pho",
            post_code: "11110",
          },
          {
            subdistrict_th: "พิมลราช",
            subdistrict_en: "Phimon Rat",
            post_code: "11110",
          },
          {
            subdistrict_th: "บางรักพัฒนา",
            subdistrict_en: "Bang Rak Phatthana",
            post_code: "11110",
          },
        ],
      },
      {
        district_th: "อำเภอไทรน้อย",
        district_en: "Amphoe Sai Noi",
        children: [
          {
            subdistrict_th: "ไทรน้อย",
            subdistrict_en: "Sai Noi",
            post_code: "11150",
          },
          {
            subdistrict_th: "ราษฎร์นิยม",
            subdistrict_en: "Rat Niyom",
            post_code: "11150",
          },
          {
            subdistrict_th: "หนองเพรางาย",
            subdistrict_en: "Nong Phrao Ngai",
            post_code: "11150",
          },
          {
            subdistrict_th: "ไทรใหญ่",
            subdistrict_en: "Sai Yai",
            post_code: "11150",
          },
          {
            subdistrict_th: "ขุนศรี",
            subdistrict_en: "Khun Si",
            post_code: "11150",
          },
          {
            subdistrict_th: "คลองขวาง",
            subdistrict_en: "Khlong Khwang",
            post_code: "11150",
          },
          {
            subdistrict_th: "ทวีวัฒนา",
            subdistrict_en: "Thawi Watthana",
            post_code: "11150",
          },
        ],
      },
      {
        district_th: "อำเภอปากเกร็ด",
        district_en: "Amphoe Pak Kret",
        children: [
          {
            subdistrict_th: "ปากเกร็ด",
            subdistrict_en: "Pak Kret",
            post_code: "11120",
          },
          {
            subdistrict_th: "บางตลาด",
            subdistrict_en: "Bang Talat",
            post_code: "11120",
          },
          {
            subdistrict_th: "บ้านใหม่",
            subdistrict_en: "Ban Mai",
            post_code: "11120",
          },
          {
            subdistrict_th: "บางพูด",
            subdistrict_en: "Bang Phut",
            post_code: "11120",
          },
          {
            subdistrict_th: "บางตะไนย์",
            subdistrict_en: "Bang Tanai",
            post_code: "11120",
          },
          {
            subdistrict_th: "คลองพระอุดม",
            subdistrict_en: "Khlong Phra Udom",
            post_code: "11120",
          },
          {
            subdistrict_th: "ท่าอิฐ",
            subdistrict_en: "Tha It",
            post_code: "11120",
          },
          {
            subdistrict_th: "เกาะเกร็ด",
            subdistrict_en: "Ko Kret",
            post_code: "11120",
          },
          {
            subdistrict_th: "อ้อมเกร็ด",
            subdistrict_en: "Om Kret",
            post_code: "11120",
          },
          {
            subdistrict_th: "คลองข่อย",
            subdistrict_en: "Khlong Khoi",
            post_code: "11120",
          },
          {
            subdistrict_th: "บางพลับ",
            subdistrict_en: "Bang Phlap",
            post_code: "11120",
          },
          {
            subdistrict_th: "คลองเกลือ",
            subdistrict_en: "Khlong Kluea",
            post_code: "11120",
          },
        ],
      },
    ],
  },
  {
    province_th: "ปทุมธานี",
    province_en: "Pathum Thani",
    children: [
      {
        district_th: "อำเภอเมืองปทุมธานี",
        district_en: "Amphoe Mueang Pathum Thani",
        children: [
          {
            subdistrict_th: "บางปรอก",
            subdistrict_en: "Bang Prok",
            post_code: "12000",
          },
          {
            subdistrict_th: "บ้านใหม่",
            subdistrict_en: "Ban Mai",
            post_code: "12000",
          },
          {
            subdistrict_th: "บ้านกลาง",
            subdistrict_en: "Ban Klang",
            post_code: "12000",
          },
          {
            subdistrict_th: "บ้านฉาง",
            subdistrict_en: "Ban Chang",
            post_code: "12000",
          },
          {
            subdistrict_th: "บ้านกระแชง",
            subdistrict_en: "Ban Krachaeng",
            post_code: "12000",
          },
          {
            subdistrict_th: "บางขะแยง",
            subdistrict_en: "Bang Khayaeng",
            post_code: "12000",
          },
          {
            subdistrict_th: "บางคูวัด",
            subdistrict_en: "Bang Khu Wat",
            post_code: "12000",
          },
          {
            subdistrict_th: "บางหลวง",
            subdistrict_en: "Bang Luang",
            post_code: "12000",
          },
          {
            subdistrict_th: "บางเดื่อ",
            subdistrict_en: "Bang Duea",
            post_code: "12000",
          },
          {
            subdistrict_th: "บางพูด",
            subdistrict_en: "Bang Phut",
            post_code: "12000",
          },
          {
            subdistrict_th: "บางพูน",
            subdistrict_en: "Bang Phun",
            post_code: "12000",
          },
          {
            subdistrict_th: "บางกะดี",
            subdistrict_en: "Bang Kadi",
            post_code: "12000",
          },
          {
            subdistrict_th: "สวนพริกไทย",
            subdistrict_en: "Suan Phrik Thai",
            post_code: "12000",
          },
          {
            subdistrict_th: "หลักหก",
            subdistrict_en: "Lak Hok",
            post_code: "12000",
          },
        ],
      },
      {
        district_th: "อำเภอคลองหลวง",
        district_en: "Amphoe Khlong Luang",
        children: [
          {
            subdistrict_th: "คลองหนึ่ง",
            subdistrict_en: "Khlong Nueng",
            post_code: "12120",
          },
          {
            subdistrict_th: "คลองสอง",
            subdistrict_en: "Khlong Song",
            post_code: "12120",
          },
          {
            subdistrict_th: "คลองสาม",
            subdistrict_en: "Khlong Sam",
            post_code: "12120",
          },
          {
            subdistrict_th: "คลองสี่",
            subdistrict_en: "Khlong Si",
            post_code: "12120",
          },
          {
            subdistrict_th: "คลองห้า",
            subdistrict_en: "Khlong Ha",
            post_code: "12120",
          },
          {
            subdistrict_th: "คลองหก",
            subdistrict_en: "Khlong Hok",
            post_code: "12120",
          },
          {
            subdistrict_th: "คลองเจ็ด",
            subdistrict_en: "Khlong Chet",
            post_code: "12120",
          },
        ],
      },
      {
        district_th: "อำเภอธัญบุรี",
        district_en: "Amphoe Thanyaburi",
        children: [
          {
            subdistrict_th: "ประชาธิปัตย์",
            subdistrict_en: "Prachathipat",
            post_code: "12130",
          },
          {
            subdistrict_th: "บึงยี่โถ",
            subdistrict_en: "Bueng Yitho",
            post_code: "12130",
          },
          {
            subdistrict_th: "รังสิต",
            subdistrict_en: "Rangsit",
            post_code: "12110",
          },
          {
            subdistrict_th: "ลำผักกูด",
            subdistrict_en: "Lam Phak Kut",
            post_code: "12110",
          },
          {
            subdistrict_th: "บึงสนั่น",
            subdistrict_en: "Bueng Sanan",
            post_code: "12110",
          },
          {
            subdistrict_th: "บึงน้ำรักษ์",
            subdistrict_en: "Bueng Nam Rak",
            post_code: "12110",
          },
        ],
      },
      {
        district_th: "อำเภอหนองเสือ",
        district_en: "Amphoe Nong Suea",
        children: [
          {
            subdistrict_th: "บึงบา",
            subdistrict_en: "Bueng Ba",
            post_code: "12170",
          },
          {
            subdistrict_th: "บึงบอน",
            subdistrict_en: "Bueng Bon",
            post_code: "12170",
          },
          {
            subdistrict_th: "บึงกาสาม",
            subdistrict_en: "Bueng Ka Sam",
            post_code: "12170",
          },
          {
            subdistrict_th: "บึงชำอ้อ",
            subdistrict_en: "Bueng Cham O",
            post_code: "12170",
          },
          {
            subdistrict_th: "หนองสามวัง",
            subdistrict_en: "Nong Sam Wang",
            post_code: "12170",
          },
          {
            subdistrict_th: "ศาลาครุ",
            subdistrict_en: "Sala Khru",
            post_code: "12170",
          },
          {
            subdistrict_th: "นพรัตน์",
            subdistrict_en: "Noppharat",
            post_code: "12170",
          },
        ],
      },
      {
        district_th: "อำเภอลาดหลุมแก้ว",
        district_en: "Amphoe Lat Lum Kaeo",
        children: [
          {
            subdistrict_th: "ระแหง",
            subdistrict_en: "Rahaeng",
            post_code: "12140",
          },
          {
            subdistrict_th: "ลาดหลุมแก้ว",
            subdistrict_en: "Lat Lum Kaeo",
            post_code: "12140",
          },
          {
            subdistrict_th: "คูบางหลวง",
            subdistrict_en: "Khu Bang Luang",
            post_code: "12140",
          },
          {
            subdistrict_th: "คูขวาง",
            subdistrict_en: "Khu Khwang",
            post_code: "12140",
          },
          {
            subdistrict_th: "คลองพระอุดม",
            subdistrict_en: "Khlong Phra Udom",
            post_code: "12140",
          },
          {
            subdistrict_th: "บ่อเงิน",
            subdistrict_en: "Bo Ngoen",
            post_code: "12140",
          },
          {
            subdistrict_th: "หน้าไม้",
            subdistrict_en: "Na Mai",
            post_code: "12140",
          },
        ],
      },
      {
        district_th: "อำเภอลำลูกกา",
        district_en: "Amphoe Lam Luk Ka",
        children: [
          {
            subdistrict_th: "คูคต",
            subdistrict_en: "Khu Khot",
            post_code: "12130",
          },
          {
            subdistrict_th: "ลาดสวาย",
            subdistrict_en: "Lat Sawai",
            post_code: "12150",
          },
          {
            subdistrict_th: "บึงคำพร้อย",
            subdistrict_en: "Bueng Kham Phroi",
            post_code: "12150",
          },
          {
            subdistrict_th: "ลำลูกกา",
            subdistrict_en: "Lum Luk Ka",
            post_code: "12150",
          },
          {
            subdistrict_th: "บึงทองหลาง",
            subdistrict_en: "Bueng Thonglang",
            post_code: "12150",
          },
          {
            subdistrict_th: "ลำไทร",
            subdistrict_en: "Lam Sai",
            post_code: "12150",
          },
          {
            subdistrict_th: "บึงคอไห",
            subdistrict_en: "Bueng Kho Hai",
            post_code: "12150",
          },
          {
            subdistrict_th: "พืชอุดม",
            subdistrict_en: "Phuet Udom",
            post_code: "12150",
          },
        ],
      },
      {
        district_th: "อำเภอสามโคก",
        district_en: "Amphoe Sam Khok",
        children: [
          {
            subdistrict_th: "บางเตย",
            subdistrict_en: "Bang Toei",
            post_code: "12160",
          },
          {
            subdistrict_th: "คลองควาย",
            subdistrict_en: "Khlong Khwai",
            post_code: "12160",
          },
          {
            subdistrict_th: "สามโคก",
            subdistrict_en: "Sam Khok",
            post_code: "12160",
          },
          {
            subdistrict_th: "กระแชง",
            subdistrict_en: "Krachaeng",
            post_code: "12160",
          },
          {
            subdistrict_th: "บางโพธิ์เหนือ",
            subdistrict_en: "Bang Pho Nuea",
            post_code: "12160",
          },
          {
            subdistrict_th: "เชียงรากใหญ่",
            subdistrict_en: "Chiang Rak Yai",
            post_code: "12160",
          },
          {
            subdistrict_th: "บ้านปทุม",
            subdistrict_en: "Ban Pathum",
            post_code: "12160",
          },
          {
            subdistrict_th: "บ้านงิ้ว",
            subdistrict_en: "Ban Ngio",
            post_code: "12160",
          },
          {
            subdistrict_th: "เชียงรากน้อย",
            subdistrict_en: "Chiang Rak Noi",
            post_code: "12160",
          },
          {
            subdistrict_th: "บางกระบือ",
            subdistrict_en: "Bang Krabue",
            post_code: "12160",
          },
          {
            subdistrict_th: "ท้ายเกาะ",
            subdistrict_en: "Thai Ko",
            post_code: "12160",
          },
        ],
      },
    ],
  },
  {
    province_th: "พระนครศรีอยุธยา",
    province_en: "Phra Nakhon Si Ayutthaya",
    children: [
      {
        district_th: "อำเภอพระนครศรีอยุธยา",
        district_en: "Amphoe Phra Nakhon Si Ayutthaya",
        children: [
          {
            subdistrict_th: "ประตูชัย",
            subdistrict_en: "Pratu Chai",
            post_code: "13000",
          },
          {
            subdistrict_th: "กะมัง",
            subdistrict_en: "Kamang",
            post_code: "13000",
          },
          {
            subdistrict_th: "หอรัตนไชย",
            subdistrict_en: "Ho Rattanachai",
            post_code: "13000",
          },
          {
            subdistrict_th: "หัวรอ",
            subdistrict_en: "Hua Ro",
            post_code: "13000",
          },
          {
            subdistrict_th: "ท่าวาสุกรี",
            subdistrict_en: "Tha Wasukri",
            post_code: "13000",
          },
          {
            subdistrict_th: "ไผ่ลิง",
            subdistrict_en: "Phai Ling",
            post_code: "13000",
          },
          {
            subdistrict_th: "ปากกราน",
            subdistrict_en: "Pak Kran",
            post_code: "13000",
          },
          {
            subdistrict_th: "ภูเขาทอง",
            subdistrict_en: "Phukhao Thong",
            post_code: "13000",
          },
          {
            subdistrict_th: "สำเภาล่ม",
            subdistrict_en: "Samphao Lom",
            post_code: "13000",
          },
          {
            subdistrict_th: "สวนพริก",
            subdistrict_en: "Suan Phrik",
            post_code: "13000",
          },
          {
            subdistrict_th: "คลองตะเคียน",
            subdistrict_en: "Khlong Takhian",
            post_code: "13000",
          },
          {
            subdistrict_th: "วัดตูม",
            subdistrict_en: "Wat Tum",
            post_code: "13000",
          },
          {
            subdistrict_th: "หันตรา",
            subdistrict_en: "Hantra",
            post_code: "13000",
          },
          {
            subdistrict_th: "ลุมพลี",
            subdistrict_en: "Lumphli",
            post_code: "13000",
          },
          {
            subdistrict_th: "บ้านใหม่",
            subdistrict_en: "Ban Mai",
            post_code: "13000",
          },
          {
            subdistrict_th: "บ้านเกาะ",
            subdistrict_en: "Ban Ko",
            post_code: "13000",
          },
          {
            subdistrict_th: "คลองสวนพลู",
            subdistrict_en: "Khlong Suan Phlu",
            post_code: "13000",
          },
          {
            subdistrict_th: "คลองสระบัว",
            subdistrict_en: "Khlong Sa Bua",
            post_code: "13000",
          },
          {
            subdistrict_th: "เกาะเรียน",
            subdistrict_en: "Ko Rian",
            post_code: "13000",
          },
          {
            subdistrict_th: "บ้านป้อม",
            subdistrict_en: "Ban Pom",
            post_code: "13000",
          },
          {
            subdistrict_th: "บ้านรุน",
            subdistrict_en: "Ban Run",
            post_code: "13000",
          },
        ],
      },
      {
        district_th: "อำเภอท่าเรือ",
        district_en: "Amphoe Tha Ruea",
        children: [
          {
            subdistrict_th: "ท่าเรือ",
            subdistrict_en: "Tha Ruea",
            post_code: "13130",
          },
          {
            subdistrict_th: "จำปา",
            subdistrict_en: "Champa",
            post_code: "13130",
          },
          {
            subdistrict_th: "ท่าหลวง",
            subdistrict_en: "Tha Luang",
            post_code: "18270",
          },
          {
            subdistrict_th: "บ้านร่อม",
            subdistrict_en: "Ban Rom",
            post_code: "13130",
          },
          {
            subdistrict_th: "ศาลาลอย",
            subdistrict_en: "Sala Loi",
            post_code: "13130",
          },
          {
            subdistrict_th: "วังแดง",
            subdistrict_en: "Wang Daeng",
            post_code: "13130",
          },
          {
            subdistrict_th: "โพธิ์เอน",
            subdistrict_en: "Pho En",
            post_code: "13130",
          },
          {
            subdistrict_th: "ปากท่า",
            subdistrict_en: "Pak Tha",
            post_code: "13130",
          },
          {
            subdistrict_th: "หนองขนาก",
            subdistrict_en: "Nong Khanak",
            post_code: "13130",
          },
          {
            subdistrict_th: "ท่าเจ้าสนุก",
            subdistrict_en: "Tha Chao Sanuk",
            post_code: "13130",
          },
        ],
      },
      {
        district_th: "อำเภอนครหลวง",
        district_en: "Amphoe Nakhon Luang",
        children: [
          {
            subdistrict_th: "นครหลวง",
            subdistrict_en: "Nakhon Luang",
            post_code: "13260",
          },
          {
            subdistrict_th: "ท่าช้าง",
            subdistrict_en: "Tha Chang",
            post_code: "13260",
          },
          {
            subdistrict_th: "บ่อโพง",
            subdistrict_en: "Bo Phong",
            post_code: "13260",
          },
          {
            subdistrict_th: "บ้านชุ้ง",
            subdistrict_en: "Ban Chung",
            post_code: "13260",
          },
          {
            subdistrict_th: "ปากจั่น",
            subdistrict_en: "Pak Chan",
            post_code: "13260",
          },
          {
            subdistrict_th: "บางระกำ",
            subdistrict_en: "Bang Rakam",
            post_code: "13260",
          },
          {
            subdistrict_th: "บางพระครู",
            subdistrict_en: "Bang Phra Khru",
            post_code: "13260",
          },
          {
            subdistrict_th: "แม่ลา",
            subdistrict_en: "Mae La",
            post_code: "13260",
          },
          {
            subdistrict_th: "หนองปลิง",
            subdistrict_en: "Nong Pling",
            post_code: "13260",
          },
          {
            subdistrict_th: "คลองสะแก",
            subdistrict_en: "Khlong Sakae",
            post_code: "13260",
          },
          {
            subdistrict_th: "สามไถ",
            subdistrict_en: "Sam Thai",
            post_code: "13260",
          },
          {
            subdistrict_th: "พระนอน",
            subdistrict_en: "Phra Non",
            post_code: "13260",
          },
        ],
      },
      {
        district_th: "อำเภอบางไทร",
        district_en: "Amphoe Bang Sai",
        children: [
          {
            subdistrict_th: "บางไทร",
            subdistrict_en: "Bang Sai",
            post_code: "13190",
          },
          {
            subdistrict_th: "บางพลี",
            subdistrict_en: "Bang Phli",
            post_code: "13190",
          },
          {
            subdistrict_th: "สนามชัย",
            subdistrict_en: "Sanam Chai",
            post_code: "13190",
          },
          {
            subdistrict_th: "บ้านแป้ง",
            subdistrict_en: "Ban Paeng",
            post_code: "13190",
          },
          {
            subdistrict_th: "หน้าไม้",
            subdistrict_en: "Na Mai",
            post_code: "13190",
          },
          {
            subdistrict_th: "บางยี่โท",
            subdistrict_en: "Bang Yi Tho",
            post_code: "13190",
          },
          {
            subdistrict_th: "แคออก",
            subdistrict_en: "Khae Ok",
            post_code: "13190",
          },
          {
            subdistrict_th: "แคตก",
            subdistrict_en: "Khae Tok",
            post_code: "13190",
          },
          {
            subdistrict_th: "ช่างเหล็ก",
            subdistrict_en: "Chang Lek",
            post_code: "13190",
          },
          {
            subdistrict_th: "กระแชง",
            subdistrict_en: "Krachaeng",
            post_code: "13190",
          },
          {
            subdistrict_th: "บ้านกลึง",
            subdistrict_en: "Ban Klueng",
            post_code: "13190",
          },
          {
            subdistrict_th: "ช้างน้อย",
            subdistrict_en: "Chang Noi",
            post_code: "13190",
          },
          {
            subdistrict_th: "ห่อหมก",
            subdistrict_en: "Homok",
            post_code: "13190",
          },
          {
            subdistrict_th: "ไผ่พระ",
            subdistrict_en: "Phai Phra",
            post_code: "13190",
          },
          {
            subdistrict_th: "กกแก้วบูรพา",
            subdistrict_en: "Kok Kaeo Burapha",
            post_code: "13190",
          },
          {
            subdistrict_th: "ไม้ตรา",
            subdistrict_en: "Mai Tra",
            post_code: "13190",
          },
          {
            subdistrict_th: "บ้านม้า",
            subdistrict_en: "Ban Ma",
            post_code: "13190",
          },
          {
            subdistrict_th: "บ้านเกาะ",
            subdistrict_en: "Ban Ko",
            post_code: "13190",
          },
          {
            subdistrict_th: "ราชคราม",
            subdistrict_en: "Ratchakhram",
            post_code: "13290",
          },
          {
            subdistrict_th: "ช้างใหญ่",
            subdistrict_en: "Chang Yai",
            post_code: "13290",
          },
          {
            subdistrict_th: "โพแตง",
            subdistrict_en: "Pho Taeng",
            post_code: "13290",
          },
          {
            subdistrict_th: "เชียงรากน้อย",
            subdistrict_en: "Chiang Rak Noi",
            post_code: "13290",
          },
          {
            subdistrict_th: "โคกช้าง",
            subdistrict_en: "Khok Chang",
            post_code: "13190",
          },
        ],
      },
      {
        district_th: "อำเภอบางบาล",
        district_en: "Amphoe Bang Ban",
        children: [
          {
            subdistrict_th: "บางบาล",
            subdistrict_en: "Bang Ban",
            post_code: "13250",
          },
          {
            subdistrict_th: "วัดยม",
            subdistrict_en: "Wat Yom",
            post_code: "13250",
          },
          {
            subdistrict_th: "ไทรน้อย",
            subdistrict_en: "Sai Noi",
            post_code: "13250",
          },
          {
            subdistrict_th: "สะพานไทย",
            subdistrict_en: "Saphan Thai",
            post_code: "13250",
          },
          {
            subdistrict_th: "มหาพราหมณ์",
            subdistrict_en: "Maha Phram",
            post_code: "13250",
          },
          {
            subdistrict_th: "กบเจา",
            subdistrict_en: "Kop Chao",
            post_code: "13250",
          },
          {
            subdistrict_th: "บ้านคลัง",
            subdistrict_en: "Ban Khlang",
            post_code: "13250",
          },
          {
            subdistrict_th: "พระขาว",
            subdistrict_en: "Phra Khao",
            post_code: "13250",
          },
          {
            subdistrict_th: "น้ำเต้า",
            subdistrict_en: "Nam Tao",
            post_code: "13250",
          },
          {
            subdistrict_th: "ทางช้าง",
            subdistrict_en: "Thang Chang",
            post_code: "13250",
          },
          {
            subdistrict_th: "วัดตะกู",
            subdistrict_en: "Wat Taku",
            post_code: "13250",
          },
          {
            subdistrict_th: "บางหลวง",
            subdistrict_en: "Bang Luang",
            post_code: "13250",
          },
          {
            subdistrict_th: "บางหลวงโดด",
            subdistrict_en: "Bang Luang Dot",
            post_code: "13250",
          },
          {
            subdistrict_th: "บางหัก",
            subdistrict_en: "Bang Hak",
            post_code: "13250",
          },
          {
            subdistrict_th: "บางชะนี",
            subdistrict_en: "Bang Chani",
            post_code: "13250",
          },
          {
            subdistrict_th: "บ้านกุ่ม",
            subdistrict_en: "Ban Kum",
            post_code: "13250",
          },
        ],
      },
      {
        district_th: "อำเภอบางปะอิน",
        district_en: "Amphoe Bang Pa-in",
        children: [
          {
            subdistrict_th: "บ้านเลน",
            subdistrict_en: "Ban Len",
            post_code: "13160",
          },
          {
            subdistrict_th: "เชียงรากน้อย",
            subdistrict_en: "Chiang Rak Noi",
            post_code: "13180",
          },
          {
            subdistrict_th: "บ้านโพ",
            subdistrict_en: "Ban Pho",
            post_code: "13160",
          },
          {
            subdistrict_th: "บ้านกรด",
            subdistrict_en: "Ban Krot",
            post_code: "13160",
          },
          {
            subdistrict_th: "บางกระสั้น",
            subdistrict_en: "Bang Krasan",
            post_code: "13160",
          },
          {
            subdistrict_th: "คลองจิก",
            subdistrict_en: "Khlong Chik",
            post_code: "13160",
          },
          {
            subdistrict_th: "บ้านหว้า",
            subdistrict_en: "Ban Wa",
            post_code: "13160",
          },
          {
            subdistrict_th: "วัดยม",
            subdistrict_en: "Wat Yom",
            post_code: "13160",
          },
          {
            subdistrict_th: "บางประแดง",
            subdistrict_en: "Bang Pradaeng",
            post_code: "13160",
          },
          {
            subdistrict_th: "สามเรือน",
            subdistrict_en: "Sam Ruean",
            post_code: "13160",
          },
          {
            subdistrict_th: "เกาะเกิด",
            subdistrict_en: "Ko Koet",
            post_code: "13160",
          },
          {
            subdistrict_th: "บ้านพลับ",
            subdistrict_en: "Ban Phlap",
            post_code: "13160",
          },
          {
            subdistrict_th: "บ้านแป้ง",
            subdistrict_en: "Ban Paeng",
            post_code: "13160",
          },
          {
            subdistrict_th: "คุ้งลาน",
            subdistrict_en: "Khung Lan",
            post_code: "13160",
          },
          {
            subdistrict_th: "ตลิ่งชัน",
            subdistrict_en: "Taling Chan",
            post_code: "13160",
          },
          {
            subdistrict_th: "บ้านสร้าง",
            subdistrict_en: "Ban Sang",
            post_code: "13170",
          },
          {
            subdistrict_th: "ตลาดเกรียบ",
            subdistrict_en: "Talat Kriap",
            post_code: "13160",
          },
          {
            subdistrict_th: "ขนอนหลวง",
            subdistrict_en: "Khanon Luang",
            post_code: "13160",
          },
        ],
      },
      {
        district_th: "อำเภอบางปะหัน",
        district_en: "Amphoe Bang Pahan",
        children: [
          {
            subdistrict_th: "บางปะหัน",
            subdistrict_en: "Bang Pahan",
            post_code: "13220",
          },
          {
            subdistrict_th: "ขยาย",
            subdistrict_en: "Khayai",
            post_code: "13220",
          },
          {
            subdistrict_th: "บางเดื่อ",
            subdistrict_en: "Bang Duea",
            post_code: "13220",
          },
          {
            subdistrict_th: "เสาธง",
            subdistrict_en: "Sao Thong",
            post_code: "13220",
          },
          {
            subdistrict_th: "ทางกลาง",
            subdistrict_en: "Thang Klang",
            post_code: "13220",
          },
          {
            subdistrict_th: "บางเพลิง",
            subdistrict_en: "Bang Phloeng",
            post_code: "13220",
          },
          {
            subdistrict_th: "หันสัง",
            subdistrict_en: "Han Sang",
            post_code: "13220",
          },
          {
            subdistrict_th: "บางนางร้า",
            subdistrict_en: "Bang Nang Ra",
            post_code: "13220",
          },
          {
            subdistrict_th: "ตานิม",
            subdistrict_en: "Ta Nim",
            post_code: "13220",
          },
          {
            subdistrict_th: "ทับน้ำ",
            subdistrict_en: "Thap Nam",
            post_code: "13220",
          },
          {
            subdistrict_th: "บ้านม้า",
            subdistrict_en: "Ban Ma",
            post_code: "13220",
          },
          {
            subdistrict_th: "ขวัญเมือง",
            subdistrict_en: "Khwan Mueang",
            post_code: "13220",
          },
          {
            subdistrict_th: "บ้านลี่",
            subdistrict_en: "Ban Li",
            post_code: "13220",
          },
          {
            subdistrict_th: "โพธิ์สามต้น",
            subdistrict_en: "Pho Sam Ton",
            post_code: "13220",
          },
          {
            subdistrict_th: "พุทเลา",
            subdistrict_en: "Phut Lao",
            post_code: "13220",
          },
          {
            subdistrict_th: "ตาลเอน",
            subdistrict_en: "Tan En",
            post_code: "13220",
          },
          {
            subdistrict_th: "บ้านขล้อ",
            subdistrict_en: "Ban Khlo",
            post_code: "13220",
          },
        ],
      },
      {
        district_th: "อำเภอผักไห่",
        district_en: "Amphoe Phak Hai",
        children: [
          {
            subdistrict_th: "ผักไห่",
            subdistrict_en: "Phak Hai",
            post_code: "13120",
          },
          {
            subdistrict_th: "อมฤต",
            subdistrict_en: "Ammarit",
            post_code: "13120",
          },
          {
            subdistrict_th: "บ้านแค",
            subdistrict_en: "Ban Khae",
            post_code: "13120",
          },
          {
            subdistrict_th: "ลาดน้ำเค็ม",
            subdistrict_en: "Lat Nam Khem",
            post_code: "13120",
          },
          {
            subdistrict_th: "ตาลาน",
            subdistrict_en: "Ta Lan",
            post_code: "13120",
          },
          {
            subdistrict_th: "ท่าดินแดง",
            subdistrict_en: "Tha Din Daeng",
            post_code: "13120",
          },
          {
            subdistrict_th: "ดอนลาน",
            subdistrict_en: "Don Lan",
            post_code: "13280",
          },
          {
            subdistrict_th: "นาคู",
            subdistrict_en: "Na Khu",
            post_code: "13280",
          },
          {
            subdistrict_th: "กุฎี",
            subdistrict_en: "Kudi",
            post_code: "13120",
          },
          {
            subdistrict_th: "ลำตะเคียน",
            subdistrict_en: "Lam Takhian",
            post_code: "13280",
          },
          {
            subdistrict_th: "โคกช้าง",
            subdistrict_en: "Khok Chang",
            post_code: "13120",
          },
          {
            subdistrict_th: "จักราช",
            subdistrict_en: "Chakkarat",
            post_code: "13280",
          },
          {
            subdistrict_th: "หนองน้ำใหญ่",
            subdistrict_en: "Nong Nam Yai",
            post_code: "13280",
          },
          {
            subdistrict_th: "ลาดชิด",
            subdistrict_en: "Lat Chit",
            post_code: "13120",
          },
          {
            subdistrict_th: "หน้าโคก",
            subdistrict_en: "Na Khok",
            post_code: "13120",
          },
          {
            subdistrict_th: "บ้านใหญ่",
            subdistrict_en: "Ban Yai",
            post_code: "13120",
          },
        ],
      },
      {
        district_th: "อำเภอภาชี",
        district_en: "Amphoe Phachi",
        children: [
          {
            subdistrict_th: "ภาชี",
            subdistrict_en: "Phachi",
            post_code: "13140",
          },
          {
            subdistrict_th: "โคกม่วง",
            subdistrict_en: "Khok Muang",
            post_code: "13140",
          },
          {
            subdistrict_th: "ระโสม",
            subdistrict_en: "Rasom",
            post_code: "13140",
          },
          {
            subdistrict_th: "หนองน้ำใส",
            subdistrict_en: "Nong Nam Sai",
            post_code: "13140",
          },
          {
            subdistrict_th: "ดอนหญ้านาง",
            subdistrict_en: "Don Ya Nang",
            post_code: "13140",
          },
          {
            subdistrict_th: "ไผ่ล้อม",
            subdistrict_en: "Phai Lom",
            post_code: "13140",
          },
          {
            subdistrict_th: "กระจิว",
            subdistrict_en: "Krachio",
            post_code: "13140",
          },
          {
            subdistrict_th: "พระแก้ว",
            subdistrict_en: "Phra Kaeo",
            post_code: "13140",
          },
        ],
      },
      {
        district_th: "อำเภอลาดบัวหลวง",
        district_en: "Amphoe Lat Bua Luang",
        children: [
          {
            subdistrict_th: "ลาดบัวหลวง",
            subdistrict_en: "Lat Bua Luang",
            post_code: "13230",
          },
          {
            subdistrict_th: "หลักชัย",
            subdistrict_en: "Lak Chai",
            post_code: "13230",
          },
          {
            subdistrict_th: "สามเมือง",
            subdistrict_en: "Sam Mueang",
            post_code: "13230",
          },
          {
            subdistrict_th: "พระยาบันลือ",
            subdistrict_en: "Phraya Banlue",
            post_code: "13230",
          },
          {
            subdistrict_th: "สิงหนาท",
            subdistrict_en: "Singhanat",
            post_code: "13230",
          },
          {
            subdistrict_th: "คู้สลอด",
            subdistrict_en: "Khu Salot",
            post_code: "13230",
          },
          {
            subdistrict_th: "คลองพระยาบันลือ",
            subdistrict_en: "Khlong Phraya Banlue",
            post_code: "13230",
          },
        ],
      },
      {
        district_th: "อำเภอวังน้อย",
        district_en: "Amphoe Wang Noi",
        children: [
          {
            subdistrict_th: "ลำตาเสา",
            subdistrict_en: "Lam Ta Sao",
            post_code: "13170",
          },
          {
            subdistrict_th: "บ่อตาโล่",
            subdistrict_en: "Bo ta Lo",
            post_code: "13170",
          },
          {
            subdistrict_th: "วังน้อย",
            subdistrict_en: "Wang Noi",
            post_code: "13170",
          },
          {
            subdistrict_th: "ลำไทร",
            subdistrict_en: "Lam Sai",
            post_code: "13170",
          },
          {
            subdistrict_th: "สนับทึบ",
            subdistrict_en: "Sanap Thuep",
            post_code: "13170",
          },
          {
            subdistrict_th: "พยอม",
            subdistrict_en: "Phayom",
            post_code: "13170",
          },
          {
            subdistrict_th: "หันตะเภา",
            subdistrict_en: "Han Taphao",
            post_code: "13170",
          },
          {
            subdistrict_th: "วังจุฬา",
            subdistrict_en: "Wang Chula",
            post_code: "13170",
          },
          {
            subdistrict_th: "ข้าวงาม",
            subdistrict_en: "Khao Ngam",
            post_code: "13170",
          },
          {
            subdistrict_th: "ชะแมบ",
            subdistrict_en: "Chamaep",
            post_code: "13170",
          },
        ],
      },
      {
        district_th: "อำเภอเสนา",
        district_en: "Amphoe Sena",
        children: [
          {
            subdistrict_th: "เสนา",
            subdistrict_en: "Sena",
            post_code: "13110",
          },
          {
            subdistrict_th: "บ้านแพน",
            subdistrict_en: "Ban Phaen",
            post_code: "13110",
          },
          {
            subdistrict_th: "เจ้าเจ็ด",
            subdistrict_en: "Chao Chet",
            post_code: "13110",
          },
          {
            subdistrict_th: "สามกอ",
            subdistrict_en: "Sam Ko",
            post_code: "13110",
          },
          {
            subdistrict_th: "บางนมโค",
            subdistrict_en: "Bang Nom Kho",
            post_code: "13110",
          },
          {
            subdistrict_th: "หัวเวียง",
            subdistrict_en: "Hua Wiang",
            post_code: "13110",
          },
          {
            subdistrict_th: "มารวิชัย",
            subdistrict_en: "Manwichai",
            post_code: "13110",
          },
          {
            subdistrict_th: "บ้านโพธิ์",
            subdistrict_en: "Ban Pho",
            post_code: "13110",
          },
          {
            subdistrict_th: "รางจรเข้",
            subdistrict_en: "Rang Chorakhe",
            post_code: "13110",
          },
          {
            subdistrict_th: "บ้านกระทุ่ม",
            subdistrict_en: "Ban Krathum",
            post_code: "13110",
          },
          {
            subdistrict_th: "บ้านแถว",
            subdistrict_en: "Ban Thaeo",
            post_code: "13110",
          },
          {
            subdistrict_th: "ชายนา",
            subdistrict_en: "Chai Na",
            post_code: "13110",
          },
          {
            subdistrict_th: "สามตุ่ม",
            subdistrict_en: "Sam Tum",
            post_code: "13110",
          },
          {
            subdistrict_th: "ลาดงา",
            subdistrict_en: "Lat Nga",
            post_code: "13110",
          },
          {
            subdistrict_th: "ดอนทอง",
            subdistrict_en: "Don Thong",
            post_code: "13110",
          },
          {
            subdistrict_th: "บ้านหลวง",
            subdistrict_en: "Ban Luang",
            post_code: "13110",
          },
          {
            subdistrict_th: "เจ้าเสด็จ",
            subdistrict_en: "Chao Sadet",
            post_code: "13110",
          },
        ],
      },
      {
        district_th: "อำเภอบางซ้าย",
        district_en: "Amphoe Bang Sai",
        children: [
          {
            subdistrict_th: "บางซ้าย",
            subdistrict_en: "Bang Sai",
            post_code: "13270",
          },
          {
            subdistrict_th: "แก้วฟ้า",
            subdistrict_en: "Kaeo Fa",
            post_code: "13270",
          },
          {
            subdistrict_th: "เต่าเล่า",
            subdistrict_en: "Tao Lao",
            post_code: "13270",
          },
          {
            subdistrict_th: "ปลายกลัด",
            subdistrict_en: "Plai Klat",
            post_code: "13270",
          },
          {
            subdistrict_th: "เทพมงคล",
            subdistrict_en: "Thep Mongkhon",
            post_code: "13270",
          },
          {
            subdistrict_th: "วังพัฒนา",
            subdistrict_en: "Wang Phatthana",
            post_code: "13270",
          },
        ],
      },
      {
        district_th: "อำเภออุทัย",
        district_en: "Amphoe Uthai",
        children: [
          {
            subdistrict_th: "คานหาม",
            subdistrict_en: "Khan Ham",
            post_code: "13210",
          },
          {
            subdistrict_th: "บ้านช้าง",
            subdistrict_en: "Ban Chang",
            post_code: "13210",
          },
          {
            subdistrict_th: "สามบัณฑิต",
            subdistrict_en: "Sam Bandit",
            post_code: "13210",
          },
          {
            subdistrict_th: "บ้านหีบ",
            subdistrict_en: "Ban Hip",
            post_code: "13210",
          },
          {
            subdistrict_th: "หนองไม้ซุง",
            subdistrict_en: "Nong Mai Sung",
            post_code: "13210",
          },
          {
            subdistrict_th: "อุทัย",
            subdistrict_en: "Uthai",
            post_code: "13210",
          },
          {
            subdistrict_th: "เสนา",
            subdistrict_en: "Sena",
            post_code: "13210",
          },
          {
            subdistrict_th: "หนองน้ำส้ม",
            subdistrict_en: "Nong Nam Som",
            post_code: "13210",
          },
          {
            subdistrict_th: "โพสาวหาญ",
            subdistrict_en: "Pho Sao Han",
            post_code: "13210",
          },
          {
            subdistrict_th: "ธนู",
            subdistrict_en: "Thanu",
            post_code: "13210",
          },
          {
            subdistrict_th: "ข้าวเม่า",
            subdistrict_en: "Khao Mao",
            post_code: "13210",
          },
        ],
      },
      {
        district_th: "อำเภอมหาราช",
        district_en: "Amphoe Maha Rat",
        children: [
          {
            subdistrict_th: "หัวไผ่",
            subdistrict_en: "Hua Phai",
            post_code: "13150",
          },
          {
            subdistrict_th: "กะทุ่ม",
            subdistrict_en: "Kathum",
            post_code: "13150",
          },
          {
            subdistrict_th: "มหาราช",
            subdistrict_en: "Maha Rat",
            post_code: "13150",
          },
          {
            subdistrict_th: "น้ำเต้า",
            subdistrict_en: "Nam Tao",
            post_code: "13150",
          },
          {
            subdistrict_th: "บางนา",
            subdistrict_en: "Bang Na",
            post_code: "13150",
          },
          {
            subdistrict_th: "โรงช้าง",
            subdistrict_en: "Rong Chang",
            post_code: "13150",
          },
          {
            subdistrict_th: "เจ้าปลุก",
            subdistrict_en: "Chao Pluk",
            post_code: "13150",
          },
          {
            subdistrict_th: "พิตเพียน",
            subdistrict_en: "Phit Phian",
            post_code: "13150",
          },
          {
            subdistrict_th: "บ้านนา",
            subdistrict_en: "Ban Na",
            post_code: "13150",
          },
          {
            subdistrict_th: "บ้านขวาง",
            subdistrict_en: "Ban Khwang",
            post_code: "13150",
          },
          {
            subdistrict_th: "ท่าตอ",
            subdistrict_en: "Tha To",
            post_code: "13150",
          },
          {
            subdistrict_th: "บ้านใหม่",
            subdistrict_en: "Ban Mai",
            post_code: "13150",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านแพรก",
        district_en: "Amphoe Ban Phraek",
        children: [
          {
            subdistrict_th: "บ้านแพรก",
            subdistrict_en: "Ban Phraek",
            post_code: "13240",
          },
          {
            subdistrict_th: "บ้านใหม่",
            subdistrict_en: "Ban Mai",
            post_code: "13240",
          },
          {
            subdistrict_th: "สำพะเนียง",
            subdistrict_en: "Sam Phaniang",
            post_code: "13240",
          },
          {
            subdistrict_th: "คลองน้อย",
            subdistrict_en: "Khlong Noi",
            post_code: "13240",
          },
          {
            subdistrict_th: "สองห้อง",
            subdistrict_en: "Song Hong",
            post_code: "13240",
          },
        ],
      },
    ],
  },
  {
    province_th: "อ่างทอง",
    province_en: "Ang Thong",
    children: [
      {
        district_th: "อำเภอเมืองอ่างทอง",
        district_en: "Amphoe Mueang Ang Thong",
        children: [
          {
            subdistrict_th: "ตลาดหลวง",
            subdistrict_en: "Talat Luang",
            post_code: "14000",
          },
          {
            subdistrict_th: "บางแก้ว",
            subdistrict_en: "Bang Kaeo",
            post_code: "14000",
          },
          {
            subdistrict_th: "ศาลาแดง",
            subdistrict_en: "Sala Daeng",
            post_code: "14000",
          },
          {
            subdistrict_th: "ป่างิ้ว",
            subdistrict_en: "Pa Ngio",
            post_code: "14000",
          },
          {
            subdistrict_th: "บ้านแห",
            subdistrict_en: "Ban Hae",
            post_code: "14000",
          },
          {
            subdistrict_th: "ตลาดกรวด",
            subdistrict_en: "Talat Kruat",
            post_code: "14000",
          },
          {
            subdistrict_th: "มหาดไทย",
            subdistrict_en: "Mahat Thai",
            post_code: "14000",
          },
          {
            subdistrict_th: "บ้านอิฐ",
            subdistrict_en: "Ban It",
            post_code: "14000",
          },
          {
            subdistrict_th: "หัวไผ่",
            subdistrict_en: "Hua Phai",
            post_code: "14000",
          },
          {
            subdistrict_th: "จำปาหล่อ",
            subdistrict_en: "Champa Lo",
            post_code: "14000",
          },
          {
            subdistrict_th: "โพสะ",
            subdistrict_en: "Phosa",
            post_code: "14000",
          },
          {
            subdistrict_th: "บ้านรี",
            subdistrict_en: "Ban Ri",
            post_code: "14000",
          },
          {
            subdistrict_th: "คลองวัว",
            subdistrict_en: "Khlong Wua",
            post_code: "14000",
          },
          {
            subdistrict_th: "ย่านซื่อ",
            subdistrict_en: "Yan Sue",
            post_code: "14000",
          },
        ],
      },
      {
        district_th: "อำเภอไชโย",
        district_en: "Amphoe Chaiyo",
        children: [
          {
            subdistrict_th: "จรเข้ร้อง",
            subdistrict_en: "Chorakhe Rong",
            post_code: "14140",
          },
          {
            subdistrict_th: "ไชยภูมิ",
            subdistrict_en: "Chaiyaphum",
            post_code: "14140",
          },
          {
            subdistrict_th: "ชัยฤทธิ์",
            subdistrict_en: "Chai Rit",
            post_code: "14140",
          },
          {
            subdistrict_th: "เทวราช",
            subdistrict_en: "Thewarat",
            post_code: "14140",
          },
          {
            subdistrict_th: "ราชสถิตย์",
            subdistrict_en: "Rat Sathit",
            post_code: "14140",
          },
          {
            subdistrict_th: "ไชโย",
            subdistrict_en: "Chaiyo",
            post_code: "14140",
          },
          {
            subdistrict_th: "หลักฟ้า",
            subdistrict_en: "Lak Fa",
            post_code: "14140",
          },
          {
            subdistrict_th: "ชะไว",
            subdistrict_en: "Chawai",
            post_code: "14140",
          },
          {
            subdistrict_th: "ตรีณรงค์",
            subdistrict_en: "Tri Narong",
            post_code: "14140",
          },
        ],
      },
      {
        district_th: "อำเภอป่าโมก",
        district_en: "Amphoe Pa Mok",
        children: [
          {
            subdistrict_th: "บางปลากด",
            subdistrict_en: "Bang Pla Kot",
            post_code: "14130",
          },
          {
            subdistrict_th: "ป่าโมก",
            subdistrict_en: "Pa Mok",
            post_code: "14130",
          },
          {
            subdistrict_th: "สายทอง",
            subdistrict_en: "Sai Thong",
            post_code: "14130",
          },
          {
            subdistrict_th: "โรงช้าง",
            subdistrict_en: "Rong Chang",
            post_code: "14130",
          },
          {
            subdistrict_th: "บางเสด็จ",
            subdistrict_en: "Bang Sadet",
            post_code: "14130",
          },
          {
            subdistrict_th: "นรสิงห์",
            subdistrict_en: "Norasing",
            post_code: "14130",
          },
          {
            subdistrict_th: "เอกราช",
            subdistrict_en: "Ekkarat",
            post_code: "14130",
          },
          {
            subdistrict_th: "โผงเผง",
            subdistrict_en: "Phongpheng",
            post_code: "14130",
          },
        ],
      },
      {
        district_th: "อำเภอโพธิ์ทอง",
        district_en: "Amphoe Pho Thong",
        children: [
          {
            subdistrict_th: "อ่างแก้ว",
            subdistrict_en: "Ang Kaeo",
            post_code: "14120",
          },
          {
            subdistrict_th: "อินทประมูล",
            subdistrict_en: "Inthapramun",
            post_code: "14120",
          },
          {
            subdistrict_th: "บางพลับ",
            subdistrict_en: "Bang Phlap",
            post_code: "14120",
          },
          {
            subdistrict_th: "หนองแม่ไก่",
            subdistrict_en: "Nong Mae Kai",
            post_code: "14120",
          },
          {
            subdistrict_th: "รำมะสัก",
            subdistrict_en: "Ram Masak",
            post_code: "14120",
          },
          {
            subdistrict_th: "บางระกำ",
            subdistrict_en: "Bang Rakam",
            post_code: "14120",
          },
          {
            subdistrict_th: "โพธิ์รังนก",
            subdistrict_en: "Pho Rang Nok",
            post_code: "14120",
          },
          {
            subdistrict_th: "องครักษ์",
            subdistrict_en: "Ongkharak",
            post_code: "14120",
          },
          {
            subdistrict_th: "โคกพุทรา",
            subdistrict_en: "Khok Phutsa",
            post_code: "14120",
          },
          {
            subdistrict_th: "ยางช้าย",
            subdistrict_en: "Yang Chai",
            post_code: "14120",
          },
          {
            subdistrict_th: "บ่อแร่",
            subdistrict_en: "Bo Rae",
            post_code: "14120",
          },
          {
            subdistrict_th: "ทางพระ",
            subdistrict_en: "Thang Phra",
            post_code: "14120",
          },
          {
            subdistrict_th: "สามง่าม",
            subdistrict_en: "Sam Ngam",
            post_code: "14120",
          },
          {
            subdistrict_th: "บางเจ้าฉ่า",
            subdistrict_en: "Bang Chao Cha",
            post_code: "14120",
          },
          {
            subdistrict_th: "คำหยาด",
            subdistrict_en: "Kham Yat",
            post_code: "14120",
          },
        ],
      },
      {
        district_th: "อำเภอแสวงหา",
        district_en: "Amphoe Sawaeng Ha",
        children: [
          {
            subdistrict_th: "แสวงหา",
            subdistrict_en: "Sawaeng Ha",
            post_code: "14150",
          },
          {
            subdistrict_th: "ศรีพราน",
            subdistrict_en: "Si Phran",
            post_code: "14150",
          },
          {
            subdistrict_th: "บ้านพราน",
            subdistrict_en: "Ban Phran",
            post_code: "14150",
          },
          {
            subdistrict_th: "วังน้ำเย็น",
            subdistrict_en: "Wang Nam Yen",
            post_code: "14150",
          },
          {
            subdistrict_th: "สีบัวทอง",
            subdistrict_en: "Si Bua Thong",
            post_code: "14150",
          },
          {
            subdistrict_th: "ห้วยไผ่",
            subdistrict_en: "Huai Phai",
            post_code: "14150",
          },
          {
            subdistrict_th: "จำลอง",
            subdistrict_en: "Chamlong",
            post_code: "14150",
          },
        ],
      },
      {
        district_th: "อำเภอวิเศษชัยชาญ",
        district_en: "Amphoe Wiset Chai Chan",
        children: [
          {
            subdistrict_th: "ไผ่จำศีล",
            subdistrict_en: "Phai Cham Sin",
            post_code: "14110",
          },
          {
            subdistrict_th: "ศาลเจ้าโรงทอง",
            subdistrict_en: "San Chao Rong Thong",
            post_code: "14110",
          },
          {
            subdistrict_th: "ไผ่ดำพัฒนา",
            subdistrict_en: "Phai Dam Phatthana",
            post_code: "14110",
          },
          {
            subdistrict_th: "สาวร้องไห้",
            subdistrict_en: "Sao Rong Hai",
            post_code: "14110",
          },
          {
            subdistrict_th: "ท่าช้าง",
            subdistrict_en: "Tha Chang",
            post_code: "14110",
          },
          {
            subdistrict_th: "ยี่ล้น",
            subdistrict_en: "Yi Lon",
            post_code: "14110",
          },
          {
            subdistrict_th: "บางจัก",
            subdistrict_en: "Bang Chak",
            post_code: "14110",
          },
          {
            subdistrict_th: "ห้วยคันแหลน",
            subdistrict_en: "Huai Khan Laen",
            post_code: "14110",
          },
          {
            subdistrict_th: "คลองขนาก",
            subdistrict_en: "Khlong Khanak",
            post_code: "14110",
          },
          {
            subdistrict_th: "ไผ่วง",
            subdistrict_en: "Phai Wong",
            post_code: "14110",
          },
          {
            subdistrict_th: "สี่ร้อย",
            subdistrict_en: "Si Roi",
            post_code: "14110",
          },
          {
            subdistrict_th: "ม่วงเตี้ย",
            subdistrict_en: "Muang Tia",
            post_code: "14110",
          },
          {
            subdistrict_th: "หัวตะพาน",
            subdistrict_en: "Hua Taphan",
            post_code: "14110",
          },
          {
            subdistrict_th: "หลักแก้ว",
            subdistrict_en: "Lak Kaeo",
            post_code: "14110",
          },
          {
            subdistrict_th: "ตลาดใหม่",
            subdistrict_en: "Talat Mai",
            post_code: "14110",
          },
        ],
      },
      {
        district_th: "อำเภอสามโก้",
        district_en: "Amphoe Samko",
        children: [
          {
            subdistrict_th: "สามโก้",
            subdistrict_en: "Samko",
            post_code: "14160",
          },
          {
            subdistrict_th: "ราษฎรพัฒนา",
            subdistrict_en: "Ratsadon Phatthana",
            post_code: "14160",
          },
          {
            subdistrict_th: "อบทม",
            subdistrict_en: "Op Thom",
            post_code: "14160",
          },
          {
            subdistrict_th: "โพธิ์ม่วงพันธ์",
            subdistrict_en: "Pho Muang Phan",
            post_code: "14160",
          },
          {
            subdistrict_th: "มงคลธรรมนิมิต",
            subdistrict_en: "Mongkhon Tham Nimit",
            post_code: "14160",
          },
        ],
      },
    ],
  },
  {
    province_th: "ลพบุรี",
    province_en: "Lop Buri",
    children: [
      {
        district_th: "อำเภอเมืองลพบุรี",
        district_en: "Amphoe Mueang Lop Buri",
        children: [
          {
            subdistrict_th: "ทะเลชุบศร",
            subdistrict_en: "Thale Chup Son",
            post_code: "15000",
          },
          {
            subdistrict_th: "ท่าหิน",
            subdistrict_en: "Tha Hin",
            post_code: "15000",
          },
          {
            subdistrict_th: "กกโก",
            subdistrict_en: "Kok Ko",
            post_code: "15000",
          },
          {
            subdistrict_th: "โก่งธนู",
            subdistrict_en: "Kong Thanu",
            post_code: "13240",
          },
          {
            subdistrict_th: "เขาพระงาม",
            subdistrict_en: "Khao Phra Ngam",
            post_code: "15160",
          },
          {
            subdistrict_th: "เขาสามยอด",
            subdistrict_en: "Khao Sam Yot",
            post_code: "15000",
          },
          {
            subdistrict_th: "โคกกะเทียม",
            subdistrict_en: "Khok Kathiam",
            post_code: "15160",
          },
          {
            subdistrict_th: "โคกลำพาน",
            subdistrict_en: "Khok Lam Phan",
            post_code: "15000",
          },
          {
            subdistrict_th: "โคกตูม",
            subdistrict_en: "Khok Tum",
            post_code: "15210",
          },
          {
            subdistrict_th: "งิ้วราย",
            subdistrict_en: "Ngio Rai",
            post_code: "15000",
          },
          {
            subdistrict_th: "ดอนโพธิ์",
            subdistrict_en: "Don Pho",
            post_code: "15000",
          },
          {
            subdistrict_th: "ตะลุง",
            subdistrict_en: "Talung",
            post_code: "15000",
          },
          {
            subdistrict_th: "ท่าแค",
            subdistrict_en: "Tha Khae",
            post_code: "15000",
          },
          {
            subdistrict_th: "ท่าศาลา",
            subdistrict_en: "Tha Sala",
            post_code: "15000",
          },
          {
            subdistrict_th: "นิคมสร้างตนเอง",
            subdistrict_en: "Nikhom Sang Ton Eng",
            post_code: "15000",
          },
          {
            subdistrict_th: "บางขันหมาก",
            subdistrict_en: "Bang Khan Mak",
            post_code: "15000",
          },
          {
            subdistrict_th: "บ้านข่อย",
            subdistrict_en: "Ban Khoi",
            post_code: "15000",
          },
          {
            subdistrict_th: "ท้ายตลาด",
            subdistrict_en: "Thai Talat",
            post_code: "15000",
          },
          {
            subdistrict_th: "ป่าตาล",
            subdistrict_en: "Pa Tan",
            post_code: "15000",
          },
          {
            subdistrict_th: "พรหมมาสตร์",
            subdistrict_en: "Phrommat",
            post_code: "15000",
          },
          {
            subdistrict_th: "โพธิ์เก้าต้น",
            subdistrict_en: "Pho Kao Ton",
            post_code: "15000",
          },
          {
            subdistrict_th: "โพธิ์ตรุ",
            subdistrict_en: "Pho Tru",
            post_code: "15000",
          },
          {
            subdistrict_th: "สี่คลอง",
            subdistrict_en: "Si Khlong",
            post_code: "15000",
          },
          {
            subdistrict_th: "ถนนใหญ่",
            subdistrict_en: "Thanon Yai",
            post_code: "15000",
          },
        ],
      },
      {
        district_th: "อำเภอพัฒนานิคม",
        district_en: "Amphoe Phatthana Nikhom",
        children: [
          {
            subdistrict_th: "พัฒนานิคม",
            subdistrict_en: "Phatthana Nikhom",
            post_code: "15140",
          },
          {
            subdistrict_th: "ช่องสาริกา",
            subdistrict_en: "Chong Sarika",
            post_code: "15220",
          },
          {
            subdistrict_th: "มะนาวหวาน",
            subdistrict_en: "Manao Wan",
            post_code: "15140",
          },
          {
            subdistrict_th: "ดีลัง",
            subdistrict_en: "Di Lang",
            post_code: "15220",
          },
          {
            subdistrict_th: "โคกสลุง",
            subdistrict_en: "Khok Salung",
            post_code: "15140",
          },
          {
            subdistrict_th: "ชอนน้อย",
            subdistrict_en: "Chon Noi",
            post_code: "15140",
          },
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "15140",
          },
          {
            subdistrict_th: "ห้วยขุนราม",
            subdistrict_en: "Huai Khun Ram",
            post_code: "18220",
          },
          {
            subdistrict_th: "น้ำสุด",
            subdistrict_en: "Nam Sut",
            post_code: "15140",
          },
        ],
      },
      {
        district_th: "อำเภอโคกสำโรง",
        district_en: "Amphoe Khok Samrong",
        children: [
          {
            subdistrict_th: "โคกสำโรง",
            subdistrict_en: "Khok Samrong",
            post_code: "15120",
          },
          {
            subdistrict_th: "เกาะแก้ว",
            subdistrict_en: "Ko Kaeo",
            post_code: "15120",
          },
          {
            subdistrict_th: "ถลุงเหล็ก",
            subdistrict_en: "Thalung Lek",
            post_code: "15120",
          },
          {
            subdistrict_th: "หลุมข้าว",
            subdistrict_en: "Lum Khao",
            post_code: "15120",
          },
          {
            subdistrict_th: "ห้วยโป่ง",
            subdistrict_en: "Huai Pong",
            post_code: "15120",
          },
          {
            subdistrict_th: "คลองเกตุ",
            subdistrict_en: "Khlong Ket",
            post_code: "15120",
          },
          {
            subdistrict_th: "สะแกราบ",
            subdistrict_en: "Sakae Rap",
            post_code: "15120",
          },
          {
            subdistrict_th: "เพนียด",
            subdistrict_en: "Phaniat",
            post_code: "15120",
          },
          {
            subdistrict_th: "วังเพลิง",
            subdistrict_en: "Wang Phloeng",
            post_code: "15120",
          },
          {
            subdistrict_th: "ดงมะรุม",
            subdistrict_en: "Dong Marum",
            post_code: "15120",
          },
          {
            subdistrict_th: "วังขอนขว้าง",
            subdistrict_en: "Wang Khon Khwang",
            post_code: "15120",
          },
          {
            subdistrict_th: "วังจั่น",
            subdistrict_en: "Wang Chan",
            post_code: "15120",
          },
          {
            subdistrict_th: "หนองแขม",
            subdistrict_en: "Nong Khaem",
            post_code: "15120",
          },
        ],
      },
      {
        district_th: "อำเภอชัยบาดาล",
        district_en: "Amphoe Chai Badan",
        children: [
          {
            subdistrict_th: "ลำนารายณ์",
            subdistrict_en: "Lam Narai",
            post_code: "15130",
          },
          {
            subdistrict_th: "ชัยนารายณ์",
            subdistrict_en: "Chai Narai",
            post_code: "15130",
          },
          {
            subdistrict_th: "ศิลาทิพย์",
            subdistrict_en: "Sila Thip",
            post_code: "15130",
          },
          {
            subdistrict_th: "ห้วยหิน",
            subdistrict_en: "Huai Hin",
            post_code: "15130",
          },
          {
            subdistrict_th: "ม่วงค่อม",
            subdistrict_en: "Muang Khom",
            post_code: "15230",
          },
          {
            subdistrict_th: "บัวชุม",
            subdistrict_en: "Bua Chum",
            post_code: "15130",
          },
          {
            subdistrict_th: "ท่าดินดำ",
            subdistrict_en: "Tha Din Dam",
            post_code: "15130",
          },
          {
            subdistrict_th: "มะกอกหวาน",
            subdistrict_en: "Makok Wan",
            post_code: "15230",
          },
          {
            subdistrict_th: "ซับตะเคียน",
            subdistrict_en: "Sap Takhian",
            post_code: "15130",
          },
          {
            subdistrict_th: "นาโสม",
            subdistrict_en: "Na Som",
            post_code: "15190",
          },
          {
            subdistrict_th: "หนองยายโต๊ะ",
            subdistrict_en: "Nong Yai To",
            post_code: "15130",
          },
          {
            subdistrict_th: "เกาะรัง",
            subdistrict_en: "Ko Rang",
            post_code: "15130",
          },
          {
            subdistrict_th: "ท่ามะนาว",
            subdistrict_en: "Tha Manao",
            post_code: "15130",
          },
          {
            subdistrict_th: "นิคมลำนารายณ์",
            subdistrict_en: "Nikhom Lam Narai",
            post_code: "15130",
          },
          {
            subdistrict_th: "ชัยบาดาล",
            subdistrict_en: "Chai Badan",
            post_code: "15230",
          },
          {
            subdistrict_th: "บ้านใหม่สามัคคี",
            subdistrict_en: "Ban Mai Samakkhi",
            post_code: "15130",
          },
          {
            subdistrict_th: "เขาแหลม",
            subdistrict_en: "Khao Laem",
            post_code: "15130",
          },
        ],
      },
      {
        district_th: "อำเภอท่าวุ้ง",
        district_en: "Amphoe Tha Wung",
        children: [
          {
            subdistrict_th: "ท่าวุ้ง",
            subdistrict_en: "Tha Wung",
            post_code: "15150",
          },
          {
            subdistrict_th: "บางคู้",
            subdistrict_en: "Bang Khu",
            post_code: "15150",
          },
          {
            subdistrict_th: "โพตลาดแก้ว",
            subdistrict_en: "Pho Talat Kaeo",
            post_code: "15150",
          },
          {
            subdistrict_th: "บางลี่",
            subdistrict_en: "Bang Li",
            post_code: "15150",
          },
          {
            subdistrict_th: "บางงา",
            subdistrict_en: "Bang Nga",
            post_code: "15150",
          },
          {
            subdistrict_th: "โคกสลุด",
            subdistrict_en: "Khok Salut",
            post_code: "15150",
          },
          {
            subdistrict_th: "เขาสมอคอน",
            subdistrict_en: "Khao Samo Khon",
            post_code: "15180",
          },
          {
            subdistrict_th: "หัวสำโรง",
            subdistrict_en: "Hua Samrong",
            post_code: "15150",
          },
          {
            subdistrict_th: "ลาดสาลี่",
            subdistrict_en: "Lat Sali",
            post_code: "15150",
          },
          {
            subdistrict_th: "บ้านเบิก",
            subdistrict_en: "Ban Boek",
            post_code: "15150",
          },
          {
            subdistrict_th: "มุจลินท์",
            subdistrict_en: "Mutchalin",
            post_code: "15150",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านหมี่",
        district_en: "Amphoe Ban Mi",
        children: [
          {
            subdistrict_th: "ไผ่ใหญ่",
            subdistrict_en: "Phai Yai",
            post_code: "15110",
          },
          {
            subdistrict_th: "บ้านทราย",
            subdistrict_en: "Ban Sai",
            post_code: "15110",
          },
          {
            subdistrict_th: "บ้านกล้วย",
            subdistrict_en: "Ban Kluai",
            post_code: "15110",
          },
          {
            subdistrict_th: "ดงพลับ",
            subdistrict_en: "Dong Phlap",
            post_code: "15110",
          },
          {
            subdistrict_th: "บ้านชี",
            subdistrict_en: "Ban Chi",
            post_code: "15180",
          },
          {
            subdistrict_th: "พุคา",
            subdistrict_en: "Phu Kha",
            post_code: "15110",
          },
          {
            subdistrict_th: "หินปัก",
            subdistrict_en: "Hin Pak",
            post_code: "15110",
          },
          {
            subdistrict_th: "บางพึ่ง",
            subdistrict_en: "Bang Phueng",
            post_code: "15110",
          },
          {
            subdistrict_th: "หนองทรายขาว",
            subdistrict_en: "Nong Sai Khao",
            post_code: "15110",
          },
          {
            subdistrict_th: "บางกะพี้",
            subdistrict_en: "Bang Kaphi",
            post_code: "15110",
          },
          {
            subdistrict_th: "หนองเต่า",
            subdistrict_en: "Nong Tao",
            post_code: "15110",
          },
          {
            subdistrict_th: "โพนทอง",
            subdistrict_en: "Phon Thong",
            post_code: "15110",
          },
          {
            subdistrict_th: "บางขาม",
            subdistrict_en: "Bang Kham",
            post_code: "15180",
          },
          {
            subdistrict_th: "ดอนดึง",
            subdistrict_en: "Don Dueng",
            post_code: "15110",
          },
          {
            subdistrict_th: "ชอนม่วง",
            subdistrict_en: "Chon Muang",
            post_code: "15110",
          },
          {
            subdistrict_th: "หนองกระเบียน",
            subdistrict_en: "Nong Krabian",
            post_code: "15110",
          },
          {
            subdistrict_th: "สายห้วยแก้ว",
            subdistrict_en: "Sai Huai Kaeo",
            post_code: "15110",
          },
          {
            subdistrict_th: "มหาสอน",
            subdistrict_en: "Maha Son",
            post_code: "15110",
          },
          {
            subdistrict_th: "บ้านหมี่",
            subdistrict_en: "Ban Mi",
            post_code: "15110",
          },
          {
            subdistrict_th: "เชียงงา",
            subdistrict_en: "Chiang Nga",
            post_code: "15110",
          },
          {
            subdistrict_th: "หนองเมือง",
            subdistrict_en: "Nong Mueang",
            post_code: "15110",
          },
          {
            subdistrict_th: "สนามแจง",
            subdistrict_en: "Sanam Chaeng",
            post_code: "15110",
          },
        ],
      },
      {
        district_th: "อำเภอท่าหลวง",
        district_en: "Amphoe Tha Luang",
        children: [
          {
            subdistrict_th: "ท่าหลวง",
            subdistrict_en: "Tha Luang",
            post_code: "15230",
          },
          {
            subdistrict_th: "แก่งผักกูด",
            subdistrict_en: "Kaeng Phak Kut",
            post_code: "15230",
          },
          {
            subdistrict_th: "ซับจำปา",
            subdistrict_en: "Sap Champa",
            post_code: "15230",
          },
          {
            subdistrict_th: "หนองผักแว่น",
            subdistrict_en: "Nong Phak Waen",
            post_code: "15230",
          },
          {
            subdistrict_th: "ทะเลวังวัด",
            subdistrict_en: "Thale Wang Wat",
            post_code: "15230",
          },
          {
            subdistrict_th: "หัวลำ",
            subdistrict_en: "Hua Lam",
            post_code: "15230",
          },
        ],
      },
      {
        district_th: "อำเภอสระโบสถ์",
        district_en: "Amphoe Sa Bot",
        children: [
          {
            subdistrict_th: "สระโบสถ์",
            subdistrict_en: "Sa Bot",
            post_code: "15240",
          },
          {
            subdistrict_th: "มหาโพธิ",
            subdistrict_en: "Maha Phot",
            post_code: "15240",
          },
          {
            subdistrict_th: "ทุ่งท่าช้าง",
            subdistrict_en: "Thung Tha Chang",
            post_code: "15240",
          },
          {
            subdistrict_th: "ห้วยใหญ่",
            subdistrict_en: "Huai Yai",
            post_code: "15240",
          },
          {
            subdistrict_th: "นิยมชัย",
            subdistrict_en: "Niyom Chai",
            post_code: "15240",
          },
        ],
      },
      {
        district_th: "อำเภอโคกเจริญ",
        district_en: "Amphoe Khok Charoen",
        children: [
          {
            subdistrict_th: "โคกเจริญ",
            subdistrict_en: "Khok Charoen",
            post_code: "15250",
          },
          {
            subdistrict_th: "ยางราก",
            subdistrict_en: "Yang Rak",
            post_code: "15250",
          },
          {
            subdistrict_th: "หนองมะค่า",
            subdistrict_en: "Nong Makha",
            post_code: "15250",
          },
          {
            subdistrict_th: "วังทอง",
            subdistrict_en: "Wang Thong",
            post_code: "15250",
          },
          {
            subdistrict_th: "โคกแสมสาร",
            subdistrict_en: "Khok Samae San",
            post_code: "15250",
          },
        ],
      },
      {
        district_th: "อำเภอลำสนธิ",
        district_en: "Amphoe Lam Sonthi",
        children: [
          {
            subdistrict_th: "ลำสนธิ",
            subdistrict_en: "Lam Sonthi",
            post_code: "15190",
          },
          {
            subdistrict_th: "ซับสมบูรณ์",
            subdistrict_en: "Sap Sombun",
            post_code: "15190",
          },
          {
            subdistrict_th: "หนองรี",
            subdistrict_en: "Nong Ri",
            post_code: "15190",
          },
          {
            subdistrict_th: "กุดตาเพชร",
            subdistrict_en: "Kut Ta Phet",
            post_code: "15190",
          },
          {
            subdistrict_th: "เขารวก",
            subdistrict_en: "Khao Ruak",
            post_code: "15190",
          },
          {
            subdistrict_th: "เขาน้อย",
            subdistrict_en: "Khao Noi",
            post_code: "15130",
          },
        ],
      },
      {
        district_th: "อำเภอหนองม่วง",
        district_en: "Amphoe Nong Muang",
        children: [
          {
            subdistrict_th: "หนองม่วง",
            subdistrict_en: "Nong Muang",
            post_code: "15170",
          },
          {
            subdistrict_th: "บ่อทอง",
            subdistrict_en: "Bo Thong",
            post_code: "15170",
          },
          {
            subdistrict_th: "ดงดินแดง",
            subdistrict_en: "Dong Din Daeng",
            post_code: "15170",
          },
          {
            subdistrict_th: "ชอนสมบูรณ์",
            subdistrict_en: "Chon Sombun",
            post_code: "15170",
          },
          {
            subdistrict_th: "ยางโทน",
            subdistrict_en: "Yang Thon",
            post_code: "15170",
          },
          {
            subdistrict_th: "ชอนสารเดช",
            subdistrict_en: "Chon Saradet",
            post_code: "15170",
          },
        ],
      },
    ],
  },
  {
    province_th: "สิงห์บุรี",
    province_en: "Sing Buri",
    children: [
      {
        district_th: "อำเภอเมืองสิงห์บุรี",
        district_en: "Amphoe Mueang Sing Buri",
        children: [
          {
            subdistrict_th: "บางพุทรา",
            subdistrict_en: "Bang Phutsa",
            post_code: "16000",
          },
          {
            subdistrict_th: "บางมัญ",
            subdistrict_en: "Bang Man",
            post_code: "16000",
          },
          {
            subdistrict_th: "โพกรวม",
            subdistrict_en: "Phok Ruam",
            post_code: "16000",
          },
          {
            subdistrict_th: "ม่วงหมู่",
            subdistrict_en: "Muang Mu",
            post_code: "16000",
          },
          {
            subdistrict_th: "หัวไผ่",
            subdistrict_en: "Hua Phai",
            post_code: "16000",
          },
          {
            subdistrict_th: "ต้นโพธิ์",
            subdistrict_en: "Ton Pho",
            post_code: "16000",
          },
          {
            subdistrict_th: "จักรสีห์",
            subdistrict_en: "Chaksi",
            post_code: "16000",
          },
          {
            subdistrict_th: "บางกระบือ",
            subdistrict_en: "Bang Krabue",
            post_code: "16000",
          },
        ],
      },
      {
        district_th: "อำเภอบางระจัน",
        district_en: "Amphoe Bang Rachan",
        children: [
          {
            subdistrict_th: "สิงห์",
            subdistrict_en: "Sing",
            post_code: "16130",
          },
          {
            subdistrict_th: "ไม้ดัด",
            subdistrict_en: "Mai Dat",
            post_code: "16130",
          },
          {
            subdistrict_th: "เชิงกลัด",
            subdistrict_en: "Choeng Klat",
            post_code: "16130",
          },
          {
            subdistrict_th: "โพชนไก่",
            subdistrict_en: "Pho Chon Kai",
            post_code: "16130",
          },
          {
            subdistrict_th: "แม่ลา",
            subdistrict_en: "Mae La",
            post_code: "16130",
          },
          {
            subdistrict_th: "บ้านจ่า",
            subdistrict_en: "Ban Cha",
            post_code: "16130",
          },
          {
            subdistrict_th: "พักทัน",
            subdistrict_en: "Phak Than",
            post_code: "16130",
          },
          {
            subdistrict_th: "สระแจง",
            subdistrict_en: "Sa Chaeng",
            post_code: "16130",
          },
        ],
      },
      {
        district_th: "อำเภอค่ายบางระจัน",
        district_en: "Amphoe Khai Bang Rachan",
        children: [
          {
            subdistrict_th: "โพทะเล",
            subdistrict_en: "Pho Thale",
            post_code: "16150",
          },
          {
            subdistrict_th: "บางระจัน",
            subdistrict_en: "Bang Rachan",
            post_code: "16150",
          },
          {
            subdistrict_th: "โพสังโฆ",
            subdistrict_en: "Pho Sangkho",
            post_code: "16150",
          },
          {
            subdistrict_th: "ท่าข้าม",
            subdistrict_en: "Tha Kham",
            post_code: "16150",
          },
          {
            subdistrict_th: "คอทราย",
            subdistrict_en: "Kho Sai",
            post_code: "16150",
          },
          {
            subdistrict_th: "หนองกระทุ่ม",
            subdistrict_en: "Nong Krathum",
            post_code: "16150",
          },
        ],
      },
      {
        district_th: "อำเภอพรหมบุรี",
        district_en: "Amphoe Phrom Buri",
        children: [
          {
            subdistrict_th: "พระงาม",
            subdistrict_en: "Phra Ngam",
            post_code: "16120",
          },
          {
            subdistrict_th: "พรหมบุรี",
            subdistrict_en: "Phrom Buri",
            post_code: "16160",
          },
          {
            subdistrict_th: "บางน้ำเชี่ยว",
            subdistrict_en: "Bang Nam Chiao",
            post_code: "16120",
          },
          {
            subdistrict_th: "บ้านหม้อ",
            subdistrict_en: "Ban Mo",
            post_code: "16120",
          },
          {
            subdistrict_th: "บ้านแป้ง",
            subdistrict_en: "Ban Paeng",
            post_code: "16120",
          },
          {
            subdistrict_th: "หัวป่า",
            subdistrict_en: "Hua Pa",
            post_code: "16120",
          },
          {
            subdistrict_th: "โรงช้าง",
            subdistrict_en: "Rong Chang",
            post_code: "16120",
          },
        ],
      },
      {
        district_th: "อำเภอท่าช้าง",
        district_en: "Amphoe Tha Chang",
        children: [
          {
            subdistrict_th: "ถอนสมอ",
            subdistrict_en: "Thon Samo",
            post_code: "16140",
          },
          {
            subdistrict_th: "โพประจักษ์",
            subdistrict_en: "Pho Prachak",
            post_code: "16140",
          },
          {
            subdistrict_th: "วิหารขาว",
            subdistrict_en: "Wihan Khao",
            post_code: "16140",
          },
          {
            subdistrict_th: "พิกุลทอง",
            subdistrict_en: "Phikun Thong",
            post_code: "16140",
          },
        ],
      },
      {
        district_th: "อำเภออินทร์บุรี",
        district_en: "Amphoe In Buri",
        children: [
          {
            subdistrict_th: "อินทร์บุรี",
            subdistrict_en: "In Buri",
            post_code: "16110",
          },
          {
            subdistrict_th: "ประศุก",
            subdistrict_en: "Prasuk",
            post_code: "16110",
          },
          {
            subdistrict_th: "ทับยา",
            subdistrict_en: "Thap Ya",
            post_code: "16110",
          },
          {
            subdistrict_th: "งิ้วราย",
            subdistrict_en: "Ngio Rai",
            post_code: "16110",
          },
          {
            subdistrict_th: "ชีน้ำร้าย",
            subdistrict_en: "Chi Nam Rai",
            post_code: "16110",
          },
          {
            subdistrict_th: "ท่างาม",
            subdistrict_en: "Tha Ngam",
            post_code: "16110",
          },
          {
            subdistrict_th: "น้ำตาล",
            subdistrict_en: "Nam Tan",
            post_code: "16110",
          },
          {
            subdistrict_th: "ทองเอน",
            subdistrict_en: "Thong En",
            post_code: "16110",
          },
          {
            subdistrict_th: "ห้วยชัน",
            subdistrict_en: "Huai Chan",
            post_code: "16110",
          },
          {
            subdistrict_th: "โพธิ์ชัย",
            subdistrict_en: "Pho Chai",
            post_code: "16110",
          },
        ],
      },
    ],
  },
  {
    province_th: "ชัยนาท",
    province_en: "Chai Nat",
    children: [
      {
        district_th: "อำเภอเมืองชัยนาท",
        district_en: "Amphoe Mueang Chai Nat",
        children: [
          {
            subdistrict_th: "ในเมือง",
            subdistrict_en: "Nai Mueang",
            post_code: "17000",
          },
          {
            subdistrict_th: "บ้านกล้วย",
            subdistrict_en: "Ban Kuai",
            post_code: "17000",
          },
          {
            subdistrict_th: "ท่าชัย",
            subdistrict_en: "Tha Chai",
            post_code: "17000",
          },
          {
            subdistrict_th: "ชัยนาท",
            subdistrict_en: "Chai Nat",
            post_code: "17000",
          },
          {
            subdistrict_th: "เขาท่าพระ",
            subdistrict_en: "Khao Tha Phra",
            post_code: "17000",
          },
          {
            subdistrict_th: "หาดท่าเสา",
            subdistrict_en: "Hat Tha Sao",
            post_code: "17000",
          },
          {
            subdistrict_th: "ธรรมามูล",
            subdistrict_en: "Thammamun",
            post_code: "17000",
          },
          {
            subdistrict_th: "เสือโฮก",
            subdistrict_en: "Suea Hok",
            post_code: "17000",
          },
          {
            subdistrict_th: "นางลือ",
            subdistrict_en: "Nang Lue",
            post_code: "17000",
          },
        ],
      },
      {
        district_th: "อำเภอมโนรมย์",
        district_en: "Amphoe Manorom",
        children: [
          {
            subdistrict_th: "คุ้งสำเภา",
            subdistrict_en: "Khung Samphao",
            post_code: "17110",
          },
          {
            subdistrict_th: "วัดโคก",
            subdistrict_en: "Wat Khok",
            post_code: "17110",
          },
          {
            subdistrict_th: "ศิลาดาน",
            subdistrict_en: "Sila Dan",
            post_code: "17110",
          },
          {
            subdistrict_th: "ท่าฉนวน",
            subdistrict_en: "Tha Chanuan",
            post_code: "17110",
          },
          {
            subdistrict_th: "หางน้ำสาคร",
            subdistrict_en: "Hang Nam Sakhon",
            post_code: "17170",
          },
          {
            subdistrict_th: "ไร่พัฒนา",
            subdistrict_en: "Rai Phatthana",
            post_code: "17170",
          },
          {
            subdistrict_th: "อู่ตะเภา",
            subdistrict_en: "U Taphao",
            post_code: "17170",
          },
        ],
      },
      {
        district_th: "อำเภอวัดสิงห์",
        district_en: "Amphoe Wat Sing",
        children: [
          {
            subdistrict_th: "วัดสิงห์",
            subdistrict_en: "Wat Sing",
            post_code: "17120",
          },
          {
            subdistrict_th: "มะขามเฒ่า",
            subdistrict_en: "Makham Thao",
            post_code: "17120",
          },
          {
            subdistrict_th: "หนองน้อย",
            subdistrict_en: "Nong Noi",
            post_code: "17120",
          },
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "17120",
          },
          {
            subdistrict_th: "หนองขุ่น",
            subdistrict_en: "Nong Khun",
            post_code: "17120",
          },
          {
            subdistrict_th: "บ่อแร่",
            subdistrict_en: "Bo Rae",
            post_code: "17120",
          },
          {
            subdistrict_th: "วังหมัน",
            subdistrict_en: "Wang Man",
            post_code: "17120",
          },
        ],
      },
      {
        district_th: "อำเภอสรรพยา",
        district_en: "Amphoe Sapphaya",
        children: [
          {
            subdistrict_th: "สรรพยา",
            subdistrict_en: "Sapphaya",
            post_code: "17150",
          },
          {
            subdistrict_th: "ตลุก",
            subdistrict_en: "Taluk",
            post_code: "17150",
          },
          {
            subdistrict_th: "เขาแก้ว",
            subdistrict_en: "Khao Kaeo",
            post_code: "17150",
          },
          {
            subdistrict_th: "โพนางดำตก",
            subdistrict_en: "Pho Nang Dam Tok",
            post_code: "17150",
          },
          {
            subdistrict_th: "โพนางดำออก",
            subdistrict_en: "Pho Nang Dam Ok",
            post_code: "17150",
          },
          {
            subdistrict_th: "บางหลวง",
            subdistrict_en: "Bang Luang",
            post_code: "17150",
          },
          {
            subdistrict_th: "หาดอาษา",
            subdistrict_en: "Hat Asa",
            post_code: "17150",
          },
        ],
      },
      {
        district_th: "อำเภอสรรคบุรี",
        district_en: "Amphoe Sankhaburi",
        children: [
          {
            subdistrict_th: "แพรกศรีราชา",
            subdistrict_en: "Phraek Si Racha",
            post_code: "17140",
          },
          {
            subdistrict_th: "เที่ยงแท้",
            subdistrict_en: "Thiang Thae",
            post_code: "17140",
          },
          {
            subdistrict_th: "ห้วยกรด",
            subdistrict_en: "Huai Krot",
            post_code: "17140",
          },
          {
            subdistrict_th: "โพงาม",
            subdistrict_en: "Pho Ngam",
            post_code: "17140",
          },
          {
            subdistrict_th: "บางขุด",
            subdistrict_en: "Bang Khut",
            post_code: "17140",
          },
          {
            subdistrict_th: "ดงคอน",
            subdistrict_en: "Dong Khon",
            post_code: "17140",
          },
          {
            subdistrict_th: "ดอนกำ",
            subdistrict_en: "Don Kam",
            post_code: "17140",
          },
          {
            subdistrict_th: "ห้วยกรดพัฒนา",
            subdistrict_en: "Huai Krot Phatthana",
            post_code: "17140",
          },
        ],
      },
      {
        district_th: "อำเภอหันคา",
        district_en: "Amphoe Hankha",
        children: [
          {
            subdistrict_th: "หันคา",
            subdistrict_en: "Hankha",
            post_code: "17130",
          },
          {
            subdistrict_th: "บ้านเชี่ยน",
            subdistrict_en: "Ban Chian",
            post_code: "17130",
          },
          {
            subdistrict_th: "ไพรนกยูง",
            subdistrict_en: "Phrai Nok Yung",
            post_code: "17130",
          },
          {
            subdistrict_th: "หนองแซง",
            subdistrict_en: "Nong Saeng",
            post_code: "17160",
          },
          {
            subdistrict_th: "ห้วยงู",
            subdistrict_en: "Huai Ngu",
            post_code: "17160",
          },
          {
            subdistrict_th: "วังไก่เถื่อน",
            subdistrict_en: "Wang Kai Thuean",
            post_code: "17130",
          },
          {
            subdistrict_th: "เด่นใหญ่",
            subdistrict_en: "Den Yai",
            post_code: "17130",
          },
          {
            subdistrict_th: "สามง่ามท่าโบสถ์",
            subdistrict_en: "Sam Ngam Tha Bot",
            post_code: "17160",
          },
        ],
      },
      {
        district_th: "อำเภอหนองมะโมง",
        district_en: "Amphoe Nong Mamong",
        children: [
          {
            subdistrict_th: "หนองมะโมง",
            subdistrict_en: "Nong Mamong",
            post_code: "17120",
          },
          {
            subdistrict_th: "วังตะเคียน",
            subdistrict_en: "Wang Takhian",
            post_code: "17120",
          },
          {
            subdistrict_th: "สะพานหิน",
            subdistrict_en: "Saphan Hin",
            post_code: "17120",
          },
          {
            subdistrict_th: "กุดจอก",
            subdistrict_en: "Kut Chok",
            post_code: "17120",
          },
        ],
      },
      {
        district_th: "อำเภอเนินขาม",
        district_en: "Amphoe Noen Kham",
        children: [
          {
            subdistrict_th: "เนินขาม",
            subdistrict_en: "Noen Kham",
            post_code: "17130",
          },
          {
            subdistrict_th: "กะบกเตี้ย",
            subdistrict_en: "Kabok Tia",
            post_code: "17130",
          },
          {
            subdistrict_th: "สุขเดือนห้า",
            subdistrict_en: "Suk Duean Ha",
            post_code: "17130",
          },
        ],
      },
    ],
  },
  {
    province_th: "สระบุรี",
    province_en: "Saraburi",
    children: [
      {
        district_th: "อำเภอเมืองสระบุรี",
        district_en: "Amphoe Mueang Saraburi",
        children: [
          {
            subdistrict_th: "ปากเพรียว",
            subdistrict_en: "Pak Phriao",
            post_code: "18000",
          },
          {
            subdistrict_th: "ดาวเรือง",
            subdistrict_en: "Dao Rueang",
            post_code: "18000",
          },
          {
            subdistrict_th: "นาโฉง",
            subdistrict_en: "Na Chong",
            post_code: "18000",
          },
          {
            subdistrict_th: "โคกสว่าง",
            subdistrict_en: "Khok Sawang",
            post_code: "18000",
          },
          {
            subdistrict_th: "หนองโน",
            subdistrict_en: "Nong No",
            post_code: "18000",
          },
          {
            subdistrict_th: "หนองยาว",
            subdistrict_en: "Nong Yao",
            post_code: "18000",
          },
          {
            subdistrict_th: "ปากข้าวสาร",
            subdistrict_en: "Pak Khao San",
            post_code: "18000",
          },
          {
            subdistrict_th: "หนองปลาไหล",
            subdistrict_en: "Nong Pla Lai",
            post_code: "18000",
          },
          {
            subdistrict_th: "กุดนกเปล้า",
            subdistrict_en: "Kut Nok Plao",
            post_code: "18000",
          },
          {
            subdistrict_th: "ตลิ่งชัน",
            subdistrict_en: "Taling Chan",
            post_code: "18000",
          },
          {
            subdistrict_th: "ตะกุด",
            subdistrict_en: "Takut",
            post_code: "18000",
          },
        ],
      },
      {
        district_th: "อำเภอแก่งคอย",
        district_en: "Amphoe Kaeng Khoi",
        children: [
          {
            subdistrict_th: "แก่งคอย",
            subdistrict_en: "Kaeng Khoi",
            post_code: "18110",
          },
          {
            subdistrict_th: "ทับกวาง",
            subdistrict_en: "Thap Kwang",
            post_code: "18260",
          },
          {
            subdistrict_th: "ตาลเดี่ยว",
            subdistrict_en: "Tan Diao",
            post_code: "18110",
          },
          {
            subdistrict_th: "ห้วยแห้ง",
            subdistrict_en: "Huai Haeng",
            post_code: "18110",
          },
          {
            subdistrict_th: "ท่าคล้อ",
            subdistrict_en: "Tha Khlo",
            post_code: "18110",
          },
          {
            subdistrict_th: "หินซ้อน",
            subdistrict_en: "Hin Son",
            post_code: "18110",
          },
          {
            subdistrict_th: "บ้านธาตุ",
            subdistrict_en: "Ban That",
            post_code: "18110",
          },
          {
            subdistrict_th: "บ้านป่า",
            subdistrict_en: "Ban Pa",
            post_code: "18110",
          },
          {
            subdistrict_th: "ท่าตูม",
            subdistrict_en: "Tha Tum",
            post_code: "18110",
          },
          {
            subdistrict_th: "ชะอม",
            subdistrict_en: "Cha-om",
            post_code: "18110",
          },
          {
            subdistrict_th: "สองคอน",
            subdistrict_en: "Song Khon",
            post_code: "18110",
          },
          {
            subdistrict_th: "เตาปูน",
            subdistrict_en: "Tao Pun",
            post_code: "18110",
          },
          {
            subdistrict_th: "ชำผักแพว",
            subdistrict_en: "Cham Phak Phaeo",
            post_code: "18110",
          },
          {
            subdistrict_th: "ท่ามะปราง",
            subdistrict_en: "Tha Maprang",
            post_code: "18110",
          },
        ],
      },
      {
        district_th: "อำเภอหนองแค",
        district_en: "Amphoe Nong Khae",
        children: [
          {
            subdistrict_th: "หนองแค",
            subdistrict_en: "Nong Khae",
            post_code: "18140",
          },
          {
            subdistrict_th: "กุ่มหัก",
            subdistrict_en: "Kum Hak",
            post_code: "18140",
          },
          {
            subdistrict_th: "คชสิทธิ์",
            subdistrict_en: "Khotchasit",
            post_code: "18250",
          },
          {
            subdistrict_th: "โคกตูม",
            subdistrict_en: "Khok Tum",
            post_code: "18250",
          },
          {
            subdistrict_th: "โคกแย้",
            subdistrict_en: "Khok Yae",
            post_code: "18230",
          },
          {
            subdistrict_th: "บัวลอย",
            subdistrict_en: "Bua Loi",
            post_code: "18230",
          },
          {
            subdistrict_th: "ไผ่ต่ำ",
            subdistrict_en: "Phai Tam",
            post_code: "18140",
          },
          {
            subdistrict_th: "โพนทอง",
            subdistrict_en: "Phon Thong",
            post_code: "18250",
          },
          {
            subdistrict_th: "ห้วยขมิ้น",
            subdistrict_en: "Huai Khamin",
            post_code: "18230",
          },
          {
            subdistrict_th: "ห้วยทราย",
            subdistrict_en: "Huai Sai",
            post_code: "18230",
          },
          {
            subdistrict_th: "หนองไข่น้ำ",
            subdistrict_en: "Nong Khai Nam",
            post_code: "18140",
          },
          {
            subdistrict_th: "หนองแขม",
            subdistrict_en: "Nong Khaem",
            post_code: "18140",
          },
          {
            subdistrict_th: "หนองจิก",
            subdistrict_en: "Nong Chik",
            post_code: "18230",
          },
          {
            subdistrict_th: "หนองจรเข้",
            subdistrict_en: "Nong Chorakhe",
            post_code: "18140",
          },
          {
            subdistrict_th: "หนองนาก",
            subdistrict_en: "Nong Nak",
            post_code: "18230",
          },
          {
            subdistrict_th: "หนองปลาหมอ",
            subdistrict_en: "Nong Pla Mo",
            post_code: "18140",
          },
          {
            subdistrict_th: "หนองปลิง",
            subdistrict_en: "Nong Pling",
            post_code: "18140",
          },
          {
            subdistrict_th: "หนองโรง",
            subdistrict_en: "Nong Rong",
            post_code: "18140",
          },
        ],
      },
      {
        district_th: "อำเภอวิหารแดง",
        district_en: "Amphoe Wihan Daeng",
        children: [
          {
            subdistrict_th: "หนองหมู",
            subdistrict_en: "Nong Mu",
            post_code: "18150",
          },
          {
            subdistrict_th: "บ้านลำ",
            subdistrict_en: "Ban Lam",
            post_code: "18150",
          },
          {
            subdistrict_th: "คลองเรือ",
            subdistrict_en: "Khlong Ruea",
            post_code: "18150",
          },
          {
            subdistrict_th: "วิหารแดง",
            subdistrict_en: "Wihan Daeng",
            post_code: "18150",
          },
          {
            subdistrict_th: "หนองสรวง",
            subdistrict_en: "Nong Suang",
            post_code: "18150",
          },
          {
            subdistrict_th: "เจริญธรรม",
            subdistrict_en: "Charoen Tham",
            post_code: "18150",
          },
        ],
      },
      {
        district_th: "อำเภอหนองแซง",
        district_en: "Amphoe Nong Saeng",
        children: [
          {
            subdistrict_th: "หนองแซง",
            subdistrict_en: "Nong Saeng",
            post_code: "18170",
          },
          {
            subdistrict_th: "หนองควายโซ",
            subdistrict_en: "Nong Khwai So",
            post_code: "18170",
          },
          {
            subdistrict_th: "หนองหัวโพ",
            subdistrict_en: "Nong Hua Pho",
            post_code: "18170",
          },
          {
            subdistrict_th: "หนองสีดา",
            subdistrict_en: "Nong Sida",
            post_code: "18170",
          },
          {
            subdistrict_th: "หนองกบ",
            subdistrict_en: "Nong Kop",
            post_code: "18170",
          },
          {
            subdistrict_th: "ไก่เส่า",
            subdistrict_en: "Kai Sao",
            post_code: "18170",
          },
          {
            subdistrict_th: "โคกสะอาด",
            subdistrict_en: "Khok Sa-at",
            post_code: "18170",
          },
          {
            subdistrict_th: "ม่วงหวาน",
            subdistrict_en: "Muang Wan",
            post_code: "18170",
          },
          {
            subdistrict_th: "เขาดิน",
            subdistrict_en: "Khao Din",
            post_code: "18170",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านหมอ",
        district_en: "Amphoe Ban Mo",
        children: [
          {
            subdistrict_th: "บ้านหมอ",
            subdistrict_en: "Ban Mo",
            post_code: "18130",
          },
          {
            subdistrict_th: "บางโขมด",
            subdistrict_en: "Bang Khamot",
            post_code: "18130",
          },
          {
            subdistrict_th: "สร่างโศก",
            subdistrict_en: "Sang Sok",
            post_code: "18130",
          },
          {
            subdistrict_th: "ตลาดน้อย",
            subdistrict_en: "Talat Noi",
            post_code: "18130",
          },
          {
            subdistrict_th: "หรเทพ",
            subdistrict_en: "Horathep",
            post_code: "18130",
          },
          {
            subdistrict_th: "โคกใหญ่",
            subdistrict_en: "Khok Yai",
            post_code: "18130",
          },
          {
            subdistrict_th: "ไผ่ขวาง",
            subdistrict_en: "Phai Khwang",
            post_code: "18130",
          },
          {
            subdistrict_th: "บ้านครัว",
            subdistrict_en: "Ban Khrua",
            post_code: "18270",
          },
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "18130",
          },
        ],
      },
      {
        district_th: "อำเภอดอนพุด",
        district_en: "Amphoe Don Phut",
        children: [
          {
            subdistrict_th: "ดอนพุด",
            subdistrict_en: "Don Phut",
            post_code: "18210",
          },
          {
            subdistrict_th: "ไผ่หลิ่ว",
            subdistrict_en: "Phai Lio",
            post_code: "18210",
          },
          {
            subdistrict_th: "บ้านหลวง",
            subdistrict_en: "Ban Luang",
            post_code: "18210",
          },
          {
            subdistrict_th: "ดงตะงาว",
            subdistrict_en: "Dong Ta-ngao",
            post_code: "18210",
          },
        ],
      },
      {
        district_th: "อำเภอหนองโดน",
        district_en: "Amphoe Nong Don",
        children: [
          {
            subdistrict_th: "หนองโดน",
            subdistrict_en: "Nong Don",
            post_code: "18190",
          },
          {
            subdistrict_th: "บ้านกลับ",
            subdistrict_en: "Ban Klap",
            post_code: "18190",
          },
          {
            subdistrict_th: "ดอนทอง",
            subdistrict_en: "Don Thong",
            post_code: "18190",
          },
          {
            subdistrict_th: "บ้านโปร่ง",
            subdistrict_en: "Ban Prong",
            post_code: "18190",
          },
        ],
      },
      {
        district_th: "อำเภอพระพุทธบาท",
        district_en: "Amphoe Phra Phutthabat",
        children: [
          {
            subdistrict_th: "พระพุทธบาท",
            subdistrict_en: "Phra Phutthabat",
            post_code: "18120",
          },
          {
            subdistrict_th: "ขุนโขลน",
            subdistrict_en: "Khun Khlon",
            post_code: "18120",
          },
          {
            subdistrict_th: "ธารเกษม",
            subdistrict_en: "Than Kasem",
            post_code: "18120",
          },
          {
            subdistrict_th: "นายาว",
            subdistrict_en: "Na Yao",
            post_code: "18120",
          },
          {
            subdistrict_th: "พุคำจาน",
            subdistrict_en: "Phu Kham Chan",
            post_code: "18120",
          },
          {
            subdistrict_th: "เขาวง",
            subdistrict_en: "Khao Wong",
            post_code: "18120",
          },
          {
            subdistrict_th: "ห้วยป่าหวาย",
            subdistrict_en: "Huai Pa Wai",
            post_code: "18120",
          },
          {
            subdistrict_th: "พุกร่าง",
            subdistrict_en: "Phu Krang",
            post_code: "18120",
          },
          {
            subdistrict_th: "หนองแก",
            subdistrict_en: "Nong Kae",
            post_code: "18120",
          },
        ],
      },
      {
        district_th: "อำเภอเสาไห้",
        district_en: "Amphoe Sao Hai",
        children: [
          {
            subdistrict_th: "เสาไห้",
            subdistrict_en: "Sao Hai",
            post_code: "18160",
          },
          {
            subdistrict_th: "บ้านยาง",
            subdistrict_en: "Ban Yang",
            post_code: "18160",
          },
          {
            subdistrict_th: "หัวปลวก",
            subdistrict_en: "Hua Pluak",
            post_code: "18160",
          },
          {
            subdistrict_th: "งิ้วงาม",
            subdistrict_en: "Ngio Ngam",
            post_code: "18160",
          },
          {
            subdistrict_th: "ศาลารีไทย",
            subdistrict_en: "Sala Ri Thai",
            post_code: "18160",
          },
          {
            subdistrict_th: "ต้นตาล",
            subdistrict_en: "Ton Tan",
            post_code: "18160",
          },
          {
            subdistrict_th: "ท่าช้าง",
            subdistrict_en: "Tha Chang",
            post_code: "18160",
          },
          {
            subdistrict_th: "พระยาทด",
            subdistrict_en: "Phraya Thot",
            post_code: "18160",
          },
          {
            subdistrict_th: "ม่วงงาม",
            subdistrict_en: "Muang Ngam",
            post_code: "18160",
          },
          {
            subdistrict_th: "เริงราง",
            subdistrict_en: "Roeng Rang",
            post_code: "18160",
          },
          {
            subdistrict_th: "เมืองเก่า",
            subdistrict_en: "Mueang Kao",
            post_code: "18160",
          },
          {
            subdistrict_th: "สวนดอกไม้",
            subdistrict_en: "Suan Dok Mai",
            post_code: "18160",
          },
        ],
      },
      {
        district_th: "อำเภอมวกเหล็ก",
        district_en: "Amphoe Muak Lek",
        children: [
          {
            subdistrict_th: "มวกเหล็ก",
            subdistrict_en: "Muak Lek",
            post_code: "18180",
          },
          {
            subdistrict_th: "มิตรภาพ",
            subdistrict_en: "Mittraphap",
            post_code: "18180",
          },
          {
            subdistrict_th: "หนองย่างเสือ",
            subdistrict_en: "Nong Yang Suea",
            post_code: "18180",
          },
          {
            subdistrict_th: "ลำสมพุง",
            subdistrict_en: "Lam Somphung",
            post_code: "30130",
          },
          {
            subdistrict_th: "ลำพญากลาง",
            subdistrict_en: "Lam Phaya Klang",
            post_code: "30130",
          },
          {
            subdistrict_th: "ซับสนุ่น",
            subdistrict_en: "Sap Sanun",
            post_code: "18220",
          },
        ],
      },
      {
        district_th: "อำเภอวังม่วง",
        district_en: "Amphoe Wang Muang",
        children: [
          {
            subdistrict_th: "แสลงพัน",
            subdistrict_en: "Salaeng Phan",
            post_code: "18220",
          },
          {
            subdistrict_th: "คำพราน",
            subdistrict_en: "Kham Phran",
            post_code: "18220",
          },
          {
            subdistrict_th: "วังม่วง",
            subdistrict_en: "Wang Muang",
            post_code: "18220",
          },
        ],
      },
      {
        district_th: "อำเภอเฉลิมพระเกียรติ",
        district_en: "Amphoe Chaloem Phra Kiat",
        children: [
          {
            subdistrict_th: "เขาดินพัฒนา",
            subdistrict_en: "Khao Din Phatthana",
            post_code: "18000",
          },
          {
            subdistrict_th: "บ้านแก้ง",
            subdistrict_en: "Ban Kaeng",
            post_code: "18000",
          },
          {
            subdistrict_th: "ผึ้งรวง",
            subdistrict_en: "Phueng Ruang",
            post_code: "18000",
          },
          {
            subdistrict_th: "พุแค",
            subdistrict_en: "Phu Khae",
            post_code: "18240",
          },
          {
            subdistrict_th: "ห้วยบง",
            subdistrict_en: "Huai Bong",
            post_code: "18000",
          },
          {
            subdistrict_th: "หน้าพระลาน",
            subdistrict_en: "Na Phra Lan",
            post_code: "18240",
          },
          {
            subdistrict_th: "ช้างทอง",
            subdistrict_en: "Chang Thong",
            post_code: "30230",
          },
          {
            subdistrict_th: "ท่าช้าง",
            subdistrict_en: "Tha Chang",
            post_code: "30230",
          },
          {
            subdistrict_th: "พระพุทธ",
            subdistrict_en: "Phra Phut",
            post_code: "30230",
          },
          {
            subdistrict_th: "หนองงูเหลือม",
            subdistrict_en: "Nong Ngu Lueam",
            post_code: "30000",
          },
          {
            subdistrict_th: "หนองยาง",
            subdistrict_en: "Nong Yang",
            post_code: "30230",
          },
          {
            subdistrict_th: "เจริญสุข",
            subdistrict_en: "Charoen Suk",
            post_code: "31110",
          },
          {
            subdistrict_th: "ตาเป๊ก",
            subdistrict_en: "Ta Pek",
            post_code: "31110",
          },
          {
            subdistrict_th: "อีสานเขต",
            subdistrict_en: "Isan Khet",
            post_code: "31110",
          },
          {
            subdistrict_th: "ถาวร",
            subdistrict_en: "Thawon",
            post_code: "31170",
          },
          {
            subdistrict_th: "ยายแย้มวัฒนา",
            subdistrict_en: "Yai Yaem Watthana",
            post_code: "31170",
          },
          {
            subdistrict_th: "ห้วยโก๋น",
            subdistrict_en: "Huai Kon",
            post_code: "55130",
          },
          {
            subdistrict_th: "ขุนน่าน",
            subdistrict_en: "Khun Nan",
            post_code: "55220",
          },
          {
            subdistrict_th: "เชียรเขา",
            subdistrict_en: "Chian Khao",
            post_code: "80190",
          },
          {
            subdistrict_th: "ดอนตรอ",
            subdistrict_en: "Don Tro",
            post_code: "80290",
          },
          {
            subdistrict_th: "สวนหลวง",
            subdistrict_en: "Suan Luang",
            post_code: "80190",
          },
          {
            subdistrict_th: "ทางพูน",
            subdistrict_en: "Thang Phun",
            post_code: "80290",
          },
        ],
      },
    ],
  },
  {
    province_th: "ชลบุรี",
    province_en: "Chon Buri",
    children: [
      {
        district_th: "อำเภอเมืองชลบุรี",
        district_en: "Amphoe Mueang Chon Buri",
        children: [
          {
            subdistrict_th: "บางปลาสร้อย",
            subdistrict_en: "Bang Pla Soi",
            post_code: "20000",
          },
          {
            subdistrict_th: "มะขามหย่ง",
            subdistrict_en: "Makham Yong",
            post_code: "20000",
          },
          {
            subdistrict_th: "บ้านโขด",
            subdistrict_en: "Ban Khot",
            post_code: "20000",
          },
          {
            subdistrict_th: "แสนสุข",
            subdistrict_en: "Saen Suk",
            post_code: "20130",
          },
          {
            subdistrict_th: "บ้านสวน",
            subdistrict_en: "Ban Suan",
            post_code: "20000",
          },
          {
            subdistrict_th: "หนองรี",
            subdistrict_en: "Nong Ri",
            post_code: "20000",
          },
          {
            subdistrict_th: "นาป่า",
            subdistrict_en: "Na Pa",
            post_code: "20000",
          },
          {
            subdistrict_th: "หนองข้างคอก",
            subdistrict_en: "Nong Khang Khok",
            post_code: "20000",
          },
          {
            subdistrict_th: "ดอนหัวฬ่อ",
            subdistrict_en: "Don Hua Lo",
            post_code: "20000",
          },
          {
            subdistrict_th: "หนองไม้แดง",
            subdistrict_en: "Nong Mai Daeng",
            post_code: "20000",
          },
          {
            subdistrict_th: "บางทราย",
            subdistrict_en: "Bang Sai",
            post_code: "20000",
          },
          {
            subdistrict_th: "คลองตำหรุ",
            subdistrict_en: "Khlong Tamru",
            post_code: "20000",
          },
          {
            subdistrict_th: "เหมือง",
            subdistrict_en: "Mueang",
            post_code: "20130",
          },
          {
            subdistrict_th: "บ้านปึก",
            subdistrict_en: "Ban Puek",
            post_code: "20130",
          },
          {
            subdistrict_th: "ห้วยกะปิ",
            subdistrict_en: "Huai Kapi",
            post_code: "20000",
          },
          {
            subdistrict_th: "เสม็ด",
            subdistrict_en: "Samet",
            post_code: "20000",
          },
          {
            subdistrict_th: "อ่างศิลา",
            subdistrict_en: "Ang Sila",
            post_code: "20000",
          },
          {
            subdistrict_th: "สำนักบก",
            subdistrict_en: "Samnak Bok",
            post_code: "20000",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านบึง",
        district_en: "Amphoe Ban Bueng",
        children: [
          {
            subdistrict_th: "บ้านบึง",
            subdistrict_en: "Ban Bueng",
            post_code: "20170",
          },
          {
            subdistrict_th: "คลองกิ่ว",
            subdistrict_en: "Khlong Kio",
            post_code: "20220",
          },
          {
            subdistrict_th: "มาบไผ่",
            subdistrict_en: "Map Phai",
            post_code: "20170",
          },
          {
            subdistrict_th: "หนองซ้ำซาก",
            subdistrict_en: "Nong Samsak",
            post_code: "20170",
          },
          {
            subdistrict_th: "หนองบอนแดง",
            subdistrict_en: "Nong Bon Daeng",
            post_code: "20170",
          },
          {
            subdistrict_th: "หนองชาก",
            subdistrict_en: "Nong Chak",
            post_code: "20170",
          },
          {
            subdistrict_th: "หนองอิรุณ",
            subdistrict_en: "Nong Irun",
            post_code: "20220",
          },
          {
            subdistrict_th: "หนองไผ่แก้ว",
            subdistrict_en: "Nong Phai Kaeo",
            post_code: "20220",
          },
        ],
      },
      {
        district_th: "อำเภอหนองใหญ่",
        district_en: "Amphoe Nong Yai",
        children: [
          {
            subdistrict_th: "หนองใหญ่",
            subdistrict_en: "Nong Yai",
            post_code: "20190",
          },
          {
            subdistrict_th: "คลองพลู",
            subdistrict_en: "Khlong Phlu",
            post_code: "20190",
          },
          {
            subdistrict_th: "หนองเสือช้าง",
            subdistrict_en: "Nong Suea Chang",
            post_code: "20190",
          },
          {
            subdistrict_th: "ห้างสูง",
            subdistrict_en: "Hang Sung",
            post_code: "20190",
          },
          {
            subdistrict_th: "เขาซก",
            subdistrict_en: "Khao Sok",
            post_code: "20190",
          },
        ],
      },
      {
        district_th: "อำเภอบางละมุง",
        district_en: "Amphoe Bang Lamung",
        children: [
          {
            subdistrict_th: "บางละมุง",
            subdistrict_en: "Bang Lamung",
            post_code: "20150",
          },
          {
            subdistrict_th: "หนองปรือ",
            subdistrict_en: "Nong Prue",
            post_code: "20150",
          },
          {
            subdistrict_th: "หนองปลาไหล",
            subdistrict_en: "Nong Pla Lai",
            post_code: "20150",
          },
          {
            subdistrict_th: "โป่ง",
            subdistrict_en: "Pong",
            post_code: "20150",
          },
          {
            subdistrict_th: "เขาไม้แก้ว",
            subdistrict_en: "Khao Mai Kaeo",
            post_code: "20150",
          },
          {
            subdistrict_th: "ห้วยใหญ่",
            subdistrict_en: "Huai Yai",
            post_code: "20150",
          },
          {
            subdistrict_th: "ตะเคียนเตี้ย",
            subdistrict_en: "Takhian Tia",
            post_code: "20150",
          },
          {
            subdistrict_th: "นาเกลือ",
            subdistrict_en: "Na Kluea",
            post_code: "20150",
          },
        ],
      },
      {
        district_th: "อำเภอพานทอง",
        district_en: "Amphoe Phan Thong",
        children: [
          {
            subdistrict_th: "พานทอง",
            subdistrict_en: "Phan Thong",
            post_code: "20160",
          },
          {
            subdistrict_th: "หนองตำลึง",
            subdistrict_en: "Nong Tamlueng",
            post_code: "20160",
          },
          {
            subdistrict_th: "มาบโป่ง",
            subdistrict_en: "Map Pong",
            post_code: "20160",
          },
          {
            subdistrict_th: "หนองกะขะ",
            subdistrict_en: "Nong Kakha",
            post_code: "20160",
          },
          {
            subdistrict_th: "หนองหงษ์",
            subdistrict_en: "Nong Hong",
            post_code: "20160",
          },
          {
            subdistrict_th: "โคกขี้หนอน",
            subdistrict_en: "Khok Khi Non",
            post_code: "20160",
          },
          {
            subdistrict_th: "บ้านเก่า",
            subdistrict_en: "Ban Kao",
            post_code: "20160",
          },
          {
            subdistrict_th: "หน้าประดู่",
            subdistrict_en: "Na Pradu",
            post_code: "20160",
          },
          {
            subdistrict_th: "บางนาง",
            subdistrict_en: "Bang Nang",
            post_code: "20160",
          },
          {
            subdistrict_th: "เกาะลอย",
            subdistrict_en: "Ko Loi",
            post_code: "20160",
          },
          {
            subdistrict_th: "บางหัก",
            subdistrict_en: "Bang Hak",
            post_code: "20160",
          },
        ],
      },
      {
        district_th: "อำเภอพนัสนิคม",
        district_en: "Amphoe Phanat Nikhom",
        children: [
          {
            subdistrict_th: "พนัสนิคม",
            subdistrict_en: "Phanat Nikhom",
            post_code: "20140",
          },
          {
            subdistrict_th: "หน้าพระธาตุ",
            subdistrict_en: "Na Phra That",
            post_code: "20140",
          },
          {
            subdistrict_th: "วัดหลวง",
            subdistrict_en: "Wat Luang",
            post_code: "20140",
          },
          {
            subdistrict_th: "บ้านเซิด",
            subdistrict_en: "Ban Soet",
            post_code: "20140",
          },
          {
            subdistrict_th: "นาเริก",
            subdistrict_en: "Na Roek",
            post_code: "20140",
          },
          {
            subdistrict_th: "หมอนนาง",
            subdistrict_en: "Mon Nang",
            post_code: "20140",
          },
          {
            subdistrict_th: "สระสี่เหลี่ยม",
            subdistrict_en: "Sa Si Liam",
            post_code: "20140",
          },
          {
            subdistrict_th: "วัดโบสถ์",
            subdistrict_en: "Wat Bot",
            post_code: "20140",
          },
          {
            subdistrict_th: "กุฎโง้ง",
            subdistrict_en: "Kut Ngong",
            post_code: "20140",
          },
          {
            subdistrict_th: "หัวถนน",
            subdistrict_en: "Hua Thanon",
            post_code: "20140",
          },
          {
            subdistrict_th: "ท่าข้าม",
            subdistrict_en: "Tha Kham",
            post_code: "20140",
          },
          {
            subdistrict_th: "หนองปรือ",
            subdistrict_en: "Nong Prue",
            post_code: "20140",
          },
          {
            subdistrict_th: "หนองขยาด",
            subdistrict_en: "Nong Khayat",
            post_code: "20140",
          },
          {
            subdistrict_th: "ทุ่งขวาง",
            subdistrict_en: "Thung Khwang",
            post_code: "20140",
          },
          {
            subdistrict_th: "หนองเหียง",
            subdistrict_en: "Nong Hiang",
            post_code: "20140",
          },
          {
            subdistrict_th: "นาวังหิน",
            subdistrict_en: "Na Wang Hin",
            post_code: "20140",
          },
          {
            subdistrict_th: "บ้านช้าง",
            subdistrict_en: "Ban Chang",
            post_code: "20140",
          },
          {
            subdistrict_th: "โคกเพลาะ",
            subdistrict_en: "Khok Phlo",
            post_code: "20140",
          },
          {
            subdistrict_th: "ไร่หลักทอง",
            subdistrict_en: "Rai Lak Thong",
            post_code: "20140",
          },
          {
            subdistrict_th: "นามะตูม",
            subdistrict_en: "Na Matum",
            post_code: "20140",
          },
        ],
      },
      {
        district_th: "อำเภอศรีราชา",
        district_en: "Amphoe Si Racha",
        children: [
          {
            subdistrict_th: "ศรีราชา",
            subdistrict_en: "Si Racha",
            post_code: "20110",
          },
          {
            subdistrict_th: "สุรศักดิ์",
            subdistrict_en: "Surasak",
            post_code: "20110",
          },
          {
            subdistrict_th: "ทุ่งสุขลา",
            subdistrict_en: "Thung Sukhla",
            post_code: "20230",
          },
          {
            subdistrict_th: "บึง",
            subdistrict_en: "Bueng",
            post_code: "20230",
          },
          {
            subdistrict_th: "หนองขาม",
            subdistrict_en: "Nong Kham",
            post_code: "20110",
          },
          {
            subdistrict_th: "เขาคันทรง",
            subdistrict_en: "Khao Khansong",
            post_code: "20110",
          },
          {
            subdistrict_th: "บางพระ",
            subdistrict_en: "Bang Phra",
            post_code: "20110",
          },
          {
            subdistrict_th: "บ่อวิน",
            subdistrict_en: "Bo Win",
            post_code: "20230",
          },
        ],
      },
      {
        district_th: "อำเภอเกาะสีชัง",
        district_en: "Amphoe Ko Sichang",
        children: [
          {
            subdistrict_th: "ท่าเทววงษ์",
            subdistrict_en: "Tha Thewawong",
            post_code: "20120",
          },
        ],
      },
      {
        district_th: "อำเภอสัตหีบ",
        district_en: "Amphoe Sattahip",
        children: [
          {
            subdistrict_th: "สัตหีบ",
            subdistrict_en: "Sattahip",
            post_code: "20180",
          },
          {
            subdistrict_th: "นาจอมเทียน",
            subdistrict_en: "Na Chom Thian",
            post_code: "20250",
          },
          {
            subdistrict_th: "พลูตาหลวง",
            subdistrict_en: "Phlu Ta Luang",
            post_code: "20180",
          },
          {
            subdistrict_th: "บางเสร่",
            subdistrict_en: "Bang Sare",
            post_code: "20250",
          },
          {
            subdistrict_th: "แสมสาร",
            subdistrict_en: "Samaesan",
            post_code: "20180",
          },
        ],
      },
      {
        district_th: "อำเภอบ่อทอง",
        district_en: "Amphoe Bo Thong",
        children: [
          {
            subdistrict_th: "บ่อทอง",
            subdistrict_en: "Bo Thong",
            post_code: "20270",
          },
          {
            subdistrict_th: "วัดสุวรรณ",
            subdistrict_en: "Wat Suwan",
            post_code: "20270",
          },
          {
            subdistrict_th: "บ่อกวางทอง",
            subdistrict_en: "Bo Kwang Thong",
            post_code: "20270",
          },
          {
            subdistrict_th: "ธาตุทอง",
            subdistrict_en: "That Thong",
            post_code: "20270",
          },
          {
            subdistrict_th: "เกษตรสุวรรณ",
            subdistrict_en: "Kaset Suwan",
            post_code: "20270",
          },
          {
            subdistrict_th: "พลวงทอง",
            subdistrict_en: "Phluang Thong",
            post_code: "20270",
          },
        ],
      },
      {
        district_th: "อำเภอเกาะจันทร์",
        district_en: "Amphoe Ko Chan",
        children: [
          {
            subdistrict_th: "เกาะจันทร์",
            subdistrict_en: "Ko Chan",
            post_code: "20240",
          },
          {
            subdistrict_th: "ท่าบุญมี",
            subdistrict_en: "Tha Bun Mi",
            post_code: "20240",
          },
        ],
      },
    ],
  },
  {
    province_th: "ระยอง",
    province_en: "Rayong",
    children: [
      {
        district_th: "อำเภอเมืองระยอง",
        district_en: "Amphoe Mueang Rayong",
        children: [
          {
            subdistrict_th: "ท่าประดู่",
            subdistrict_en: "Tha Pradu",
            post_code: "21000",
          },
          {
            subdistrict_th: "เชิงเนิน",
            subdistrict_en: "Choeng Noen",
            post_code: "21000",
          },
          {
            subdistrict_th: "ตะพง",
            subdistrict_en: "Taphong",
            post_code: "21000",
          },
          {
            subdistrict_th: "ปากน้ำ",
            subdistrict_en: "Pak Nam",
            post_code: "21000",
          },
          {
            subdistrict_th: "เพ",
            subdistrict_en: "Phe",
            post_code: "21160",
          },
          {
            subdistrict_th: "แกลง",
            subdistrict_en: "Klaeng",
            post_code: "21160",
          },
          {
            subdistrict_th: "บ้านแลง",
            subdistrict_en: "Ban Laeng",
            post_code: "21000",
          },
          {
            subdistrict_th: "นาตาขวัญ",
            subdistrict_en: "Na Ta Khwan",
            post_code: "21000",
          },
          {
            subdistrict_th: "เนินพระ",
            subdistrict_en: "Noen Phra",
            post_code: "21000",
          },
          {
            subdistrict_th: "กะเฉด",
            subdistrict_en: "Kachet",
            post_code: "21100",
          },
          {
            subdistrict_th: "ทับมา",
            subdistrict_en: "Thap Ma",
            post_code: "21000",
          },
          {
            subdistrict_th: "น้ำคอก",
            subdistrict_en: "Nam Khok",
            post_code: "21000",
          },
          {
            subdistrict_th: "ห้วยโป่ง",
            subdistrict_en: "Huai Pong",
            post_code: "21150",
          },
          {
            subdistrict_th: "มาบตาพุด",
            subdistrict_en: "Map Ta Phut",
            post_code: "21150",
          },
          {
            subdistrict_th: "สำนักทอง",
            subdistrict_en: "Samnak Thong",
            post_code: "21100",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านฉาง",
        district_en: "Amphoe Ban Chang",
        children: [
          {
            subdistrict_th: "สำนักท้อน",
            subdistrict_en: "Samnak Thon",
            post_code: "21130",
          },
          {
            subdistrict_th: "พลา",
            subdistrict_en: "Phala",
            post_code: "21130",
          },
          {
            subdistrict_th: "บ้านฉาง",
            subdistrict_en: "Ban Chang",
            post_code: "21130",
          },
        ],
      },
      {
        district_th: "อำเภอแกลง",
        district_en: "Amphoe Klaeng",
        children: [
          {
            subdistrict_th: "ทางเกวียน",
            subdistrict_en: "Thang Kwian",
            post_code: "21110",
          },
          {
            subdistrict_th: "วังหว้า",
            subdistrict_en: "Wang Wa",
            post_code: "21110",
          },
          {
            subdistrict_th: "ชากโดน",
            subdistrict_en: "Chak Don",
            post_code: "21110",
          },
          {
            subdistrict_th: "เนินฆ้อ",
            subdistrict_en: "Noen Kho",
            post_code: "21110",
          },
          {
            subdistrict_th: "กร่ำ",
            subdistrict_en: "Kram",
            post_code: "21190",
          },
          {
            subdistrict_th: "ชากพง",
            subdistrict_en: "Chak Phong",
            post_code: "21190",
          },
          {
            subdistrict_th: "กระแสบน",
            subdistrict_en: "Krasae Bon",
            post_code: "21110",
          },
          {
            subdistrict_th: "บ้านนา",
            subdistrict_en: "Ban Na",
            post_code: "21110",
          },
          {
            subdistrict_th: "ทุ่งควายกิน",
            subdistrict_en: "Thung Khwai Kin",
            post_code: "21110",
          },
          {
            subdistrict_th: "กองดิน",
            subdistrict_en: "Kong Din",
            post_code: "22160",
          },
          {
            subdistrict_th: "คลองปูน",
            subdistrict_en: "Khlong Pun",
            post_code: "21170",
          },
          {
            subdistrict_th: "พังราด",
            subdistrict_en: "Phang Rat",
            post_code: "21170",
          },
          {
            subdistrict_th: "ปากน้ำกระแส",
            subdistrict_en: "Pak Nam Krasae",
            post_code: "21170",
          },
          {
            subdistrict_th: "ห้วยยาง",
            subdistrict_en: "Huai Yang",
            post_code: "21110",
          },
          {
            subdistrict_th: "สองสลึง",
            subdistrict_en: "Song Salueng",
            post_code: "21110",
          },
        ],
      },
      {
        district_th: "อำเภอวังจันทร์",
        district_en: "Amphoe Wang Chan",
        children: [
          {
            subdistrict_th: "วังจันทร์",
            subdistrict_en: "Wang Chan",
            post_code: "21210",
          },
          {
            subdistrict_th: "ชุมแสง",
            subdistrict_en: "Chum Saeng",
            post_code: "21210",
          },
          {
            subdistrict_th: "ป่ายุบใน",
            subdistrict_en: "Pa Yup Nai",
            post_code: "21210",
          },
          {
            subdistrict_th: "พลงตาเอี่ยม",
            subdistrict_en: "Phlong Ta Iam",
            post_code: "21210",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านค่าย",
        district_en: "Amphoe Ban Khai",
        children: [
          {
            subdistrict_th: "บ้านค่าย",
            subdistrict_en: "Ban Khai",
            post_code: "21120",
          },
          {
            subdistrict_th: "หนองละลอก",
            subdistrict_en: "Nong Lalok",
            post_code: "21120",
          },
          {
            subdistrict_th: "หนองตะพาน",
            subdistrict_en: "Nong Taphan",
            post_code: "21120",
          },
          {
            subdistrict_th: "ตาขัน",
            subdistrict_en: "Ta Khan",
            post_code: "21120",
          },
          {
            subdistrict_th: "บางบุตร",
            subdistrict_en: "Bang But",
            post_code: "21120",
          },
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "21120",
          },
          {
            subdistrict_th: "ชากบก",
            subdistrict_en: "Chak Bok",
            post_code: "21120",
          },
        ],
      },
      {
        district_th: "อำเภอปลวกแดง",
        district_en: "Amphoe Pluak Daeng",
        children: [
          {
            subdistrict_th: "ปลวกแดง",
            subdistrict_en: "Pluak Daeng",
            post_code: "21140",
          },
          {
            subdistrict_th: "ตาสิทธิ์",
            subdistrict_en: "Ta Sit",
            post_code: "21140",
          },
          {
            subdistrict_th: "ละหาร",
            subdistrict_en: "Lahan",
            post_code: "21140",
          },
          {
            subdistrict_th: "แม่น้ำคู้",
            subdistrict_en: "Maenam Khu",
            post_code: "21140",
          },
          {
            subdistrict_th: "มาบยางพร",
            subdistrict_en: "Map Yang Phon",
            post_code: "21140",
          },
          {
            subdistrict_th: "หนองไร่",
            subdistrict_en: "Nong Rai",
            post_code: "21140",
          },
        ],
      },
      {
        district_th: "อำเภอเขาชะเมา",
        district_en: "Amphoe Khao Chamao",
        children: [
          {
            subdistrict_th: "น้ำเป็น",
            subdistrict_en: "Nam Pen",
            post_code: "21110",
          },
          {
            subdistrict_th: "ห้วยทับมอญ",
            subdistrict_en: "Huai Thap Mon",
            post_code: "21110",
          },
          {
            subdistrict_th: "ชำฆ้อ",
            subdistrict_en: "Cham Kho",
            post_code: "21110",
          },
          {
            subdistrict_th: "เขาน้อย",
            subdistrict_en: "Khao Noi",
            post_code: "21110",
          },
        ],
      },
      {
        district_th: "อำเภอนิคมพัฒนา",
        district_en: "Amphoe Nikhom Phatthana",
        children: [
          {
            subdistrict_th: "นิคมพัฒนา",
            subdistrict_en: "Nikhom Phatthana",
            post_code: "21180",
          },
          {
            subdistrict_th: "มาบข่า",
            subdistrict_en: "Map Kha",
            post_code: "21180",
          },
          {
            subdistrict_th: "พนานิคม",
            subdistrict_en: "Phana Nikhom",
            post_code: "21180",
          },
          {
            subdistrict_th: "มะขามคู่",
            subdistrict_en: "Makham Khu",
            post_code: "21180",
          },
        ],
      },
    ],
  },
  {
    province_th: "จันทบุรี",
    province_en: "Chanthaburi",
    children: [
      {
        district_th: "อำเภอเมืองจันทบุรี",
        district_en: "Amphoe Mueang Chanthaburi",
        children: [
          {
            subdistrict_th: "ตลาด",
            subdistrict_en: "Talat",
            post_code: "22000",
          },
          {
            subdistrict_th: "วัดใหม่",
            subdistrict_en: "Wat Mai",
            post_code: "22000",
          },
          {
            subdistrict_th: "คลองนารายณ์",
            subdistrict_en: "Khlong Narai",
            post_code: "22000",
          },
          {
            subdistrict_th: "เกาะขวาง",
            subdistrict_en: "Ko Khwang",
            post_code: "22000",
          },
          {
            subdistrict_th: "คมบาง",
            subdistrict_en: "Khom Bang",
            post_code: "22000",
          },
          {
            subdistrict_th: "ท่าช้าง",
            subdistrict_en: "Tha Chang",
            post_code: "22000",
          },
          {
            subdistrict_th: "จันทนิมิต",
            subdistrict_en: "Chanthanimit",
            post_code: "22000",
          },
          {
            subdistrict_th: "บางกะจะ",
            subdistrict_en: "Bang Kacha",
            post_code: "22000",
          },
          {
            subdistrict_th: "แสลง",
            subdistrict_en: "Salaeng",
            post_code: "22000",
          },
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "22000",
          },
          {
            subdistrict_th: "พลับพลา",
            subdistrict_en: "Phlapphla",
            post_code: "22000",
          },
        ],
      },
      {
        district_th: "อำเภอขลุง",
        district_en: "Amphoe Khlung",
        children: [
          {
            subdistrict_th: "ขลุง",
            subdistrict_en: "Khlung",
            post_code: "22110",
          },
          {
            subdistrict_th: "บ่อ",
            subdistrict_en: "Bo",
            post_code: "22110",
          },
          {
            subdistrict_th: "เกวียนหัก",
            subdistrict_en: "Kwian Hak",
            post_code: "22110",
          },
          {
            subdistrict_th: "ตะปอน",
            subdistrict_en: "Tapon",
            post_code: "22110",
          },
          {
            subdistrict_th: "บางชัน",
            subdistrict_en: "Bang Chan",
            post_code: "22110",
          },
          {
            subdistrict_th: "วันยาว",
            subdistrict_en: "Wan Yao",
            post_code: "22110",
          },
          {
            subdistrict_th: "ซึ้ง",
            subdistrict_en: "Sueng",
            post_code: "22110",
          },
          {
            subdistrict_th: "มาบไพ",
            subdistrict_en: "Map Phai",
            post_code: "22110",
          },
          {
            subdistrict_th: "วังสรรพรส",
            subdistrict_en: "Wang Sappharot",
            post_code: "22110",
          },
          {
            subdistrict_th: "ตรอกนอง",
            subdistrict_en: "Trok Nong",
            post_code: "22110",
          },
          {
            subdistrict_th: "ตกพรม",
            subdistrict_en: "Tok Phom",
            post_code: "22110",
          },
          {
            subdistrict_th: "บ่อเวฬุ",
            subdistrict_en: "Bo Weru",
            post_code: "22150",
          },
        ],
      },
      {
        district_th: "อำเภอท่าใหม่",
        district_en: "Amphoe Tha Mai",
        children: [
          {
            subdistrict_th: "ท่าใหม่",
            subdistrict_en: "Tha Mai",
            post_code: "22120",
          },
          {
            subdistrict_th: "ยายร้า",
            subdistrict_en: "Yai Ra",
            post_code: "22120",
          },
          {
            subdistrict_th: "สีพยา",
            subdistrict_en: "Si Phaya",
            post_code: "22120",
          },
          {
            subdistrict_th: "บ่อพุ",
            subdistrict_en: "Bo Phu",
            post_code: "22120",
          },
          {
            subdistrict_th: "พลอยแหวน",
            subdistrict_en: "Phloi Waen",
            post_code: "22120",
          },
          {
            subdistrict_th: "เขาวัว",
            subdistrict_en: "Khao Wua",
            post_code: "22120",
          },
          {
            subdistrict_th: "เขาบายศรี",
            subdistrict_en: "Khao Baisi",
            post_code: "22120",
          },
          {
            subdistrict_th: "สองพี่น้อง",
            subdistrict_en: "Song Phi Nong",
            post_code: "22120",
          },
          {
            subdistrict_th: "ทุ่งเบญจา",
            subdistrict_en: "Thung Bencha",
            post_code: "22170",
          },
          {
            subdistrict_th: "รำพัน",
            subdistrict_en: "Ramphan",
            post_code: "22170",
          },
          {
            subdistrict_th: "โขมง",
            subdistrict_en: "Khamong",
            post_code: "22170",
          },
          {
            subdistrict_th: "ตะกาดเง้า",
            subdistrict_en: "Takat Ngao",
            post_code: "22120",
          },
          {
            subdistrict_th: "คลองขุด",
            subdistrict_en: "Khlong Khut",
            post_code: "22120",
          },
          {
            subdistrict_th: "เขาแก้ว",
            subdistrict_en: "Khao Kaeo",
            post_code: "22170",
          },
        ],
      },
      {
        district_th: "อำเภอโป่งน้ำร้อน",
        district_en: "Amphoe Pong Nam Ron",
        children: [
          {
            subdistrict_th: "ทับไทร",
            subdistrict_en: "Thap Sai",
            post_code: "22140",
          },
          {
            subdistrict_th: "โป่งน้ำร้อน",
            subdistrict_en: "Pong Nam Ron",
            post_code: "22140",
          },
          {
            subdistrict_th: "หนองตาคง",
            subdistrict_en: "Nong Ta Khong",
            post_code: "22140",
          },
          {
            subdistrict_th: "เทพนิมิต",
            subdistrict_en: "Thep Nimit",
            post_code: "22140",
          },
          {
            subdistrict_th: "คลองใหญ่",
            subdistrict_en: "Khlong Yai",
            post_code: "22140",
          },
        ],
      },
      {
        district_th: "อำเภอมะขาม",
        district_en: "Amphoe Makham",
        children: [
          {
            subdistrict_th: "มะขาม",
            subdistrict_en: "Makham",
            post_code: "22150",
          },
          {
            subdistrict_th: "ท่าหลวง",
            subdistrict_en: "Tha Luang",
            post_code: "22150",
          },
          {
            subdistrict_th: "ปัถวี",
            subdistrict_en: "Patthawi",
            post_code: "22150",
          },
          {
            subdistrict_th: "วังแซ้ม",
            subdistrict_en: "Wang Saem",
            post_code: "22150",
          },
          {
            subdistrict_th: "ฉมัน",
            subdistrict_en: "Chaman",
            post_code: "22150",
          },
          {
            subdistrict_th: "อ่างคีรี",
            subdistrict_en: "Ang Khiri",
            post_code: "22150",
          },
        ],
      },
      {
        district_th: "อำเภอแหลมสิงห์",
        district_en: "Amphoe Laem Sing",
        children: [
          {
            subdistrict_th: "ปากน้ำแหลมสิงห์",
            subdistrict_en: "Pak Nam Laem Sing",
            post_code: "22130",
          },
          {
            subdistrict_th: "เกาะเปริด",
            subdistrict_en: "Ko Proet",
            post_code: "22130",
          },
          {
            subdistrict_th: "หนองชิ่ม",
            subdistrict_en: "Nong Chim",
            post_code: "22130",
          },
          {
            subdistrict_th: "พลิ้ว",
            subdistrict_en: "Phlio",
            post_code: "22190",
          },
          {
            subdistrict_th: "คลองน้ำเค็ม",
            subdistrict_en: "Khlong Nam Khem",
            post_code: "22190",
          },
          {
            subdistrict_th: "บางสระเก้า",
            subdistrict_en: "Bang Sa Kao",
            post_code: "22190",
          },
          {
            subdistrict_th: "บางกะไชย",
            subdistrict_en: "Bang Kachai",
            post_code: "22120",
          },
        ],
      },
      {
        district_th: "อำเภอสอยดาว",
        district_en: "Amphoe Soi Dao",
        children: [
          {
            subdistrict_th: "ปะตง",
            subdistrict_en: "Patong",
            post_code: "22180",
          },
          {
            subdistrict_th: "ทุ่งขนาน",
            subdistrict_en: "Thung Khanan",
            post_code: "22180",
          },
          {
            subdistrict_th: "ทับช้าง",
            subdistrict_en: "Thap Chang",
            post_code: "22180",
          },
          {
            subdistrict_th: "ทรายขาว",
            subdistrict_en: "Sai Khao",
            post_code: "22180",
          },
          {
            subdistrict_th: "สะตอน",
            subdistrict_en: "Saton",
            post_code: "22180",
          },
        ],
      },
      {
        district_th: "อำเภอแก่งหางแมว",
        district_en: "Amphoe Kaeng Hang Maeo",
        children: [
          {
            subdistrict_th: "แก่งหางแมว",
            subdistrict_en: "Kaeng Hang Maeo",
            post_code: "22160",
          },
          {
            subdistrict_th: "ขุนซ่อง",
            subdistrict_en: "Khun Song",
            post_code: "22160",
          },
          {
            subdistrict_th: "สามพี่น้อง",
            subdistrict_en: "Sam Phi Nong",
            post_code: "22160",
          },
          {
            subdistrict_th: "พวา",
            subdistrict_en: "Phawa",
            post_code: "22160",
          },
          {
            subdistrict_th: "เขาวงกต",
            subdistrict_en: "Khao Wongkot",
            post_code: "22160",
          },
        ],
      },
      {
        district_th: "อำเภอนายายอาม",
        district_en: "Amphoe Na Yai Am",
        children: [
          {
            subdistrict_th: "นายายอาม",
            subdistrict_en: "Na Yai Am",
            post_code: "22160",
          },
          {
            subdistrict_th: "วังโตนด",
            subdistrict_en: "Wang Tanot",
            post_code: "22170",
          },
          {
            subdistrict_th: "กระแจะ",
            subdistrict_en: "Krachae",
            post_code: "22170",
          },
          {
            subdistrict_th: "สนามไชย",
            subdistrict_en: "Sanam Chai",
            post_code: "22170",
          },
          {
            subdistrict_th: "ช้างข้าม",
            subdistrict_en: "Chang Kham",
            post_code: "22160",
          },
          {
            subdistrict_th: "วังใหม่",
            subdistrict_en: "Wang Mai",
            post_code: "22170",
          },
        ],
      },
      {
        district_th: "อำเภอเขาคิชฌกูฏ",
        district_en: "Amphoe Khao Khitchakut",
        children: [
          {
            subdistrict_th: "ชากไทย",
            subdistrict_en: "Chak Thai",
            post_code: "22210",
          },
          {
            subdistrict_th: "พลวง",
            subdistrict_en: "Phluang",
            post_code: "22210",
          },
          {
            subdistrict_th: "ตะเคียนทอง",
            subdistrict_en: "Takhian Thong",
            post_code: "22210",
          },
          {
            subdistrict_th: "คลองพลู",
            subdistrict_en: "Khlong Phlu",
            post_code: "22210",
          },
          {
            subdistrict_th: "จันทเขลม",
            subdistrict_en: "Chanthakhlem",
            post_code: "22210",
          },
        ],
      },
    ],
  },
  {
    province_th: "ตราด",
    province_en: "Trat",
    children: [
      {
        district_th: "อำเภอเมืองตราด",
        district_en: "Amphoe Mueang Trat",
        children: [
          {
            subdistrict_th: "บางพระ",
            subdistrict_en: "Bang Phra",
            post_code: "23000",
          },
          {
            subdistrict_th: "หนองเสม็ด",
            subdistrict_en: "Nong Samet",
            post_code: "23000",
          },
          {
            subdistrict_th: "หนองโสน",
            subdistrict_en: "Nong Sano",
            post_code: "23000",
          },
          {
            subdistrict_th: "หนองคันทรง",
            subdistrict_en: "Nong Khan Song",
            post_code: "23000",
          },
          {
            subdistrict_th: "ห้วงน้ำขาว",
            subdistrict_en: "Huang Nam Khao",
            post_code: "23000",
          },
          {
            subdistrict_th: "อ่าวใหญ่",
            subdistrict_en: "Ao Yai",
            post_code: "23000",
          },
          {
            subdistrict_th: "วังกระแจะ",
            subdistrict_en: "Wang Krachae",
            post_code: "23000",
          },
          {
            subdistrict_th: "ห้วยแร้ง",
            subdistrict_en: "Huai Raeng",
            post_code: "23000",
          },
          {
            subdistrict_th: "เนินทราย",
            subdistrict_en: "Noen Sai",
            post_code: "23000",
          },
          {
            subdistrict_th: "ท่าพริก",
            subdistrict_en: "Tha Phrik",
            post_code: "23000",
          },
          {
            subdistrict_th: "ท่ากุ่ม",
            subdistrict_en: "Tha Kum",
            post_code: "23000",
          },
          {
            subdistrict_th: "ตะกาง",
            subdistrict_en: "Takang",
            post_code: "23000",
          },
          {
            subdistrict_th: "ชำราก",
            subdistrict_en: "Chamrak",
            post_code: "23000",
          },
          {
            subdistrict_th: "แหลมกลัด",
            subdistrict_en: "Laem Klat",
            post_code: "23000",
          },
        ],
      },
      {
        district_th: "อำเภอคลองใหญ่",
        district_en: "Amphoe Khlong Yai",
        children: [
          {
            subdistrict_th: "คลองใหญ่",
            subdistrict_en: "Khlong Yai",
            post_code: "23110",
          },
          {
            subdistrict_th: "ไม้รูด",
            subdistrict_en: "Mai Rut",
            post_code: "23110",
          },
          {
            subdistrict_th: "หาดเล็ก",
            subdistrict_en: "Hat Lek",
            post_code: "23110",
          },
        ],
      },
      {
        district_th: "อำเภอเขาสมิง",
        district_en: "Amphoe Khao Saming",
        children: [
          {
            subdistrict_th: "เขาสมิง",
            subdistrict_en: "Khao Saming",
            post_code: "23130",
          },
          {
            subdistrict_th: "แสนตุ้ง",
            subdistrict_en: "Saen Tung",
            post_code: "23150",
          },
          {
            subdistrict_th: "วังตะเคียน",
            subdistrict_en: "Wang Takhian",
            post_code: "23130",
          },
          {
            subdistrict_th: "ท่าโสม",
            subdistrict_en: "Tha Som",
            post_code: "23150",
          },
          {
            subdistrict_th: "สะตอ",
            subdistrict_en: "Sato",
            post_code: "23150",
          },
          {
            subdistrict_th: "ประณีต",
            subdistrict_en: "Pranit",
            post_code: "23150",
          },
          {
            subdistrict_th: "เทพนิมิต",
            subdistrict_en: "Thep Nimit",
            post_code: "23150",
          },
          {
            subdistrict_th: "ทุ่งนนทรี",
            subdistrict_en: "Thung Nonsi",
            post_code: "23130",
          },
        ],
      },
      {
        district_th: "อำเภอบ่อไร่",
        district_en: "Amphoe Bo Rai",
        children: [
          {
            subdistrict_th: "บ่อพลอย",
            subdistrict_en: "Bo Phloi",
            post_code: "23140",
          },
          {
            subdistrict_th: "ช้างทูน",
            subdistrict_en: "Chang Thun",
            post_code: "23140",
          },
          {
            subdistrict_th: "ด่านชุมพล",
            subdistrict_en: "Dan Chumphon",
            post_code: "23140",
          },
          {
            subdistrict_th: "หนองบอน",
            subdistrict_en: "Nong Bon",
            post_code: "23140",
          },
          {
            subdistrict_th: "นนทรีย์",
            subdistrict_en: "Nonsi",
            post_code: "23140",
          },
        ],
      },
      {
        district_th: "อำเภอแหลมงอบ",
        district_en: "Amphoe Laem Ngop",
        children: [
          {
            subdistrict_th: "แหลมงอบ",
            subdistrict_en: "Laem Ngop",
            post_code: "23120",
          },
          {
            subdistrict_th: "น้ำเชี่ยว",
            subdistrict_en: "Nam Chiao",
            post_code: "23120",
          },
          {
            subdistrict_th: "บางปิด",
            subdistrict_en: "Bang Pit",
            post_code: "23120",
          },
          {
            subdistrict_th: "คลองใหญ่",
            subdistrict_en: "Khlong Yai",
            post_code: "23120",
          },
        ],
      },
      {
        district_th: "อำเภอเกาะกูด",
        district_en: "Amphoe Ko Kut",
        children: [
          {
            subdistrict_th: "เกาะหมาก",
            subdistrict_en: "Ko Mak",
            post_code: "23120",
          },
          {
            subdistrict_th: "เกาะกูด",
            subdistrict_en: "Ko Kut",
            post_code: "23000",
          },
        ],
      },
      {
        district_th: "อำเภอเกาะช้าง",
        district_en: "Amphoe Ko Chang",
        children: [
          {
            subdistrict_th: "เกาะช้าง",
            subdistrict_en: "Ko Chang",
            post_code: "23170",
          },
          {
            subdistrict_th: "เกาะช้างใต้",
            subdistrict_en: "Ko Chang Tai",
            post_code: "23170",
          },
        ],
      },
    ],
  },
  {
    province_th: "ฉะเชิงเทรา",
    province_en: "Chachoengsao",
    children: [
      {
        district_th: "อำเภอเมืองฉะเชิงเทรา",
        district_en: "Amphoe Mueang Chachoengsao",
        children: [
          {
            subdistrict_th: "หน้าเมือง",
            subdistrict_en: "Na Mueang",
            post_code: "24000",
          },
          {
            subdistrict_th: "ท่าไข่",
            subdistrict_en: "Tha Khai",
            post_code: "24000",
          },
          {
            subdistrict_th: "บ้านใหม่",
            subdistrict_en: "Ban Mai",
            post_code: "24000",
          },
          {
            subdistrict_th: "คลองนา",
            subdistrict_en: "Khlong Na",
            post_code: "24000",
          },
          {
            subdistrict_th: "บางตีนเป็ด",
            subdistrict_en: "Bang Tin Pet",
            post_code: "24000",
          },
          {
            subdistrict_th: "บางไผ่",
            subdistrict_en: "Bang Phai",
            post_code: "24000",
          },
          {
            subdistrict_th: "คลองจุกกระเฌอ",
            subdistrict_en: "Khlong Chuk Krachoe",
            post_code: "24000",
          },
          {
            subdistrict_th: "บางแก้ว",
            subdistrict_en: "Bang Kaeo",
            post_code: "24000",
          },
          {
            subdistrict_th: "บางขวัญ",
            subdistrict_en: "Bang Khwan",
            post_code: "24000",
          },
          {
            subdistrict_th: "คลองนครเนื่องเขต",
            subdistrict_en: "Khlong Nakhon Nueang Khet",
            post_code: "24000",
          },
          {
            subdistrict_th: "วังตะเคียน",
            subdistrict_en: "Wang Takhian",
            post_code: "24000",
          },
          {
            subdistrict_th: "โสธร",
            subdistrict_en: "Sothon",
            post_code: "24000",
          },
          {
            subdistrict_th: "บางพระ",
            subdistrict_en: "Bang Phra",
            post_code: "24000",
          },
          {
            subdistrict_th: "บางกะไห",
            subdistrict_en: "Bang Kahai",
            post_code: "24000",
          },
          {
            subdistrict_th: "หนามแดง",
            subdistrict_en: "Nam Daeng",
            post_code: "24000",
          },
          {
            subdistrict_th: "คลองเปรง",
            subdistrict_en: "Khlong Preng",
            post_code: "24000",
          },
          {
            subdistrict_th: "คลองอุดมชลจร",
            subdistrict_en: "Khlong Udom Chonlachon",
            post_code: "24000",
          },
          {
            subdistrict_th: "คลองหลวงแพ่ง",
            subdistrict_en: "Khlong Luang Phaeng",
            post_code: "24000",
          },
          {
            subdistrict_th: "บางเตย",
            subdistrict_en: "Bang Toei",
            post_code: "24000",
          },
        ],
      },
      {
        district_th: "อำเภอบางคล้า",
        district_en: "Amphoe Bang Khla",
        children: [
          {
            subdistrict_th: "บางคล้า",
            subdistrict_en: "Bang Khla",
            post_code: "24110",
          },
          {
            subdistrict_th: "บางสวน",
            subdistrict_en: "Bang Suan",
            post_code: "24110",
          },
          {
            subdistrict_th: "บางกระเจ็ด",
            subdistrict_en: "Bang Krachet",
            post_code: "24110",
          },
          {
            subdistrict_th: "ปากน้ำ",
            subdistrict_en: "Pak Nam",
            post_code: "24110",
          },
          {
            subdistrict_th: "ท่าทองหลาง",
            subdistrict_en: "Tha Thonglang",
            post_code: "24110",
          },
          {
            subdistrict_th: "สาวชะโงก",
            subdistrict_en: "Sao Cha-ngok",
            post_code: "24110",
          },
          {
            subdistrict_th: "เสม็ดเหนือ",
            subdistrict_en: "Samet Nuea",
            post_code: "24110",
          },
          {
            subdistrict_th: "เสม็ดใต้",
            subdistrict_en: "Samet Tai",
            post_code: "24110",
          },
          {
            subdistrict_th: "หัวไทร",
            subdistrict_en: "Hua Sai",
            post_code: "24110",
          },
        ],
      },
      {
        district_th: "อำเภอบางน้ำเปรี้ยว",
        district_en: "Amphoe Bang Nam Priao",
        children: [
          {
            subdistrict_th: "บางน้ำเปรี้ยว",
            subdistrict_en: "Bang Nam Priao",
            post_code: "24150",
          },
          {
            subdistrict_th: "บางขนาก",
            subdistrict_en: "Bang Khanak",
            post_code: "24150",
          },
          {
            subdistrict_th: "สิงโตทอง",
            subdistrict_en: "Singto Thong",
            post_code: "24150",
          },
          {
            subdistrict_th: "หมอนทอง",
            subdistrict_en: "Mon Thong",
            post_code: "24150",
          },
          {
            subdistrict_th: "บึงน้ำรักษ์",
            subdistrict_en: "Bueng Nam Rak",
            post_code: "24170",
          },
          {
            subdistrict_th: "ดอนเกาะกา",
            subdistrict_en: "Don Ko Ka",
            post_code: "24170",
          },
          {
            subdistrict_th: "โยธะกา",
            subdistrict_en: "Yothaka",
            post_code: "24150",
          },
          {
            subdistrict_th: "ดอนฉิมพลี",
            subdistrict_en: "Don Chimphli",
            post_code: "24170",
          },
          {
            subdistrict_th: "ศาลาแดง",
            subdistrict_en: "Sala Daeng",
            post_code: "24000",
          },
          {
            subdistrict_th: "โพรงอากาศ",
            subdistrict_en: "Phrong Akat",
            post_code: "24150",
          },
        ],
      },
      {
        district_th: "อำเภอบางปะกง",
        district_en: "Amphoe Bang Pakong",
        children: [
          {
            subdistrict_th: "บางปะกง",
            subdistrict_en: "Bang Pakong",
            post_code: "24130",
          },
          {
            subdistrict_th: "ท่าสะอ้าน",
            subdistrict_en: "Tha Sa-an",
            post_code: "24130",
          },
          {
            subdistrict_th: "บางวัว",
            subdistrict_en: "Bang Wua",
            post_code: "24180",
          },
          {
            subdistrict_th: "บางสมัคร",
            subdistrict_en: "Bang Samak",
            post_code: "24180",
          },
          {
            subdistrict_th: "บางผึ้ง",
            subdistrict_en: "Bang Phueng",
            post_code: "24130",
          },
          {
            subdistrict_th: "บางเกลือ",
            subdistrict_en: "Bang Kluea",
            post_code: "24180",
          },
          {
            subdistrict_th: "สองคลอง",
            subdistrict_en: "Song Khlong",
            post_code: "24130",
          },
          {
            subdistrict_th: "หนองจอก",
            subdistrict_en: "Nong Chok",
            post_code: "24130",
          },
          {
            subdistrict_th: "พิมพา",
            subdistrict_en: "Phimpha",
            post_code: "24180",
          },
          {
            subdistrict_th: "ท่าข้าม",
            subdistrict_en: "Tha Kham",
            post_code: "24130",
          },
          {
            subdistrict_th: "หอมศีล",
            subdistrict_en: "Hom Sin",
            post_code: "24180",
          },
          {
            subdistrict_th: "เขาดิน",
            subdistrict_en: "Khao Din",
            post_code: "24130",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านโพธิ์",
        district_en: "Amphoe Ban Pho",
        children: [
          {
            subdistrict_th: "บ้านโพธิ์",
            subdistrict_en: "Ban Pho",
            post_code: "24140",
          },
          {
            subdistrict_th: "เกาะไร่",
            subdistrict_en: "Ko Rai",
            post_code: "24140",
          },
          {
            subdistrict_th: "คลองขุด",
            subdistrict_en: "Khlong Khut",
            post_code: "24140",
          },
          {
            subdistrict_th: "คลองบ้านโพธิ์",
            subdistrict_en: "Khlong Ban Pho",
            post_code: "24140",
          },
          {
            subdistrict_th: "คลองประเวศ",
            subdistrict_en: "Khlong Prawet",
            post_code: "24140",
          },
          {
            subdistrict_th: "ดอนทราย",
            subdistrict_en: "Don Sai",
            post_code: "24140",
          },
          {
            subdistrict_th: "เทพราช",
            subdistrict_en: "Theppharat",
            post_code: "24140",
          },
          {
            subdistrict_th: "ท่าพลับ",
            subdistrict_en: "Tha Phlap",
            post_code: "24140",
          },
          {
            subdistrict_th: "หนองตีนนก",
            subdistrict_en: "Nong Tin Nok",
            post_code: "24140",
          },
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "24140",
          },
          {
            subdistrict_th: "บางซ่อน",
            subdistrict_en: "Bang Son",
            post_code: "24140",
          },
          {
            subdistrict_th: "บางกรูด",
            subdistrict_en: "Bang Krut",
            post_code: "24140",
          },
          {
            subdistrict_th: "แหลมประดู่",
            subdistrict_en: "Laem Pradu",
            post_code: "24140",
          },
          {
            subdistrict_th: "ลาดขวาง",
            subdistrict_en: "Lat Khwang",
            post_code: "24140",
          },
          {
            subdistrict_th: "สนามจันทร์",
            subdistrict_en: "Sanam Chan",
            post_code: "24140",
          },
          {
            subdistrict_th: "แสนภูดาษ",
            subdistrict_en: "Saen Phu Dat",
            post_code: "24140",
          },
          {
            subdistrict_th: "สิบเอ็ดศอก",
            subdistrict_en: "Sip Et Sok",
            post_code: "24140",
          },
        ],
      },
      {
        district_th: "อำเภอพนมสารคาม",
        district_en: "Amphoe Phanom Sarakham",
        children: [
          {
            subdistrict_th: "เกาะขนุน",
            subdistrict_en: "Ko Khanun",
            post_code: "24120",
          },
          {
            subdistrict_th: "บ้านซ่อง",
            subdistrict_en: "Ban Song",
            post_code: "24120",
          },
          {
            subdistrict_th: "พนมสารคาม",
            subdistrict_en: "Phanom Sarakham",
            post_code: "24120",
          },
          {
            subdistrict_th: "เมืองเก่า",
            subdistrict_en: "Mueang Kao",
            post_code: "24120",
          },
          {
            subdistrict_th: "หนองยาว",
            subdistrict_en: "Nong Yao",
            post_code: "24120",
          },
          {
            subdistrict_th: "ท่าถ่าน",
            subdistrict_en: "Tha Than",
            post_code: "24120",
          },
          {
            subdistrict_th: "หนองแหน",
            subdistrict_en: "Nong Nae",
            post_code: "24120",
          },
          {
            subdistrict_th: "เขาหินซ้อน",
            subdistrict_en: "Khao Hin Son",
            post_code: "24120",
          },
        ],
      },
      {
        district_th: "อำเภอราชสาส์น",
        district_en: "Amphoe Ratchasan",
        children: [
          {
            subdistrict_th: "บางคา",
            subdistrict_en: "Bang Kha",
            post_code: "24120",
          },
          {
            subdistrict_th: "เมืองใหม่",
            subdistrict_en: "Mueang Mai",
            post_code: "24120",
          },
          {
            subdistrict_th: "ดงน้อย",
            subdistrict_en: "Dong Noi",
            post_code: "24120",
          },
        ],
      },
      {
        district_th: "อำเภอสนามชัยเขต",
        district_en: "Amphoe Sanam Chai Khet",
        children: [
          {
            subdistrict_th: "คู้ยายหมี",
            subdistrict_en: "Ku Yai Mi",
            post_code: "24160",
          },
          {
            subdistrict_th: "ท่ากระดาน",
            subdistrict_en: "Tha Kradan",
            post_code: "24160",
          },
          {
            subdistrict_th: "ทุ่งพระยา",
            subdistrict_en: "Thung Phraya",
            post_code: "24160",
          },
          {
            subdistrict_th: "ลาดกระทิง",
            subdistrict_en: "Lat Krathing",
            post_code: "24160",
          },
        ],
      },
      {
        district_th: "อำเภอแปลงยาว",
        district_en: "Amphoe Plaeng Yao",
        children: [
          {
            subdistrict_th: "แปลงยาว",
            subdistrict_en: "Plaeng Yao",
            post_code: "24190",
          },
          {
            subdistrict_th: "วังเย็น",
            subdistrict_en: "Wang Yen",
            post_code: "24190",
          },
          {
            subdistrict_th: "หัวสำโรง",
            subdistrict_en: "Hua Samrong",
            post_code: "24190",
          },
          {
            subdistrict_th: "หนองไม้แก่น",
            subdistrict_en: "Nong Mai Kaen",
            post_code: "24190",
          },
        ],
      },
      {
        district_th: "อำเภอท่าตะเกียบ",
        district_en: "Amphoe Tha Takiap",
        children: [
          {
            subdistrict_th: "ท่าตะเกียบ",
            subdistrict_en: "Tha Takiap",
            post_code: "24160",
          },
          {
            subdistrict_th: "คลองตะเกรา",
            subdistrict_en: "Khlong Takrao",
            post_code: "24160",
          },
        ],
      },
      {
        district_th: "อำเภอคลองเขื่อน",
        district_en: "Amphoe Khlong Khuean",
        children: [
          {
            subdistrict_th: "ก้อนแก้ว",
            subdistrict_en: "Kon Kaeo",
            post_code: "24000",
          },
          {
            subdistrict_th: "คลองเขื่อน",
            subdistrict_en: "Khlong Khuean",
            post_code: "24000",
          },
          {
            subdistrict_th: "บางเล่า",
            subdistrict_en: "Bang Lao",
            post_code: "24000",
          },
          {
            subdistrict_th: "บางโรง",
            subdistrict_en: "Bang Rong",
            post_code: "24000",
          },
          {
            subdistrict_th: "บางตลาด",
            subdistrict_en: "Bang Talat",
            post_code: "24110",
          },
        ],
      },
    ],
  },
  {
    province_th: "ปราจีนบุรี",
    province_en: "Prachin Buri",
    children: [
      {
        district_th: "อำเภอเมืองปราจีนบุรี",
        district_en: "Amphoe Mueang Prachin Buri",
        children: [
          {
            subdistrict_th: "หน้าเมือง",
            subdistrict_en: "Na Mueang",
            post_code: "25000",
          },
          {
            subdistrict_th: "รอบเมือง",
            subdistrict_en: "Rop Mueang",
            post_code: "25000",
          },
          {
            subdistrict_th: "วัดโบสถ์",
            subdistrict_en: "Wat Bot",
            post_code: "25000",
          },
          {
            subdistrict_th: "บางเดชะ",
            subdistrict_en: "Bang Decha",
            post_code: "25000",
          },
          {
            subdistrict_th: "ท่างาม",
            subdistrict_en: "Tha Ngam",
            post_code: "25000",
          },
          {
            subdistrict_th: "บางบริบูรณ์",
            subdistrict_en: "Bang Boribun",
            post_code: "25000",
          },
          {
            subdistrict_th: "ดงพระราม",
            subdistrict_en: "Dong Phra Ram",
            post_code: "25000",
          },
          {
            subdistrict_th: "บ้านพระ",
            subdistrict_en: "Ban Phra",
            post_code: "25230",
          },
          {
            subdistrict_th: "โคกไม้ลาย",
            subdistrict_en: "Khok Mai Lai",
            post_code: "25230",
          },
          {
            subdistrict_th: "ไม้เค็ด",
            subdistrict_en: "Mai Khet",
            post_code: "25230",
          },
          {
            subdistrict_th: "ดงขี้เหล็ก",
            subdistrict_en: "Dong Khilek",
            post_code: "25000",
          },
          {
            subdistrict_th: "เนินหอม",
            subdistrict_en: "Noen Hom",
            post_code: "25230",
          },
          {
            subdistrict_th: "โนนห้อม",
            subdistrict_en: "Non Hom",
            post_code: "25000",
          },
        ],
      },
      {
        district_th: "อำเภอกบินทร์บุรี",
        district_en: "Amphoe Kabin Buri",
        children: [
          {
            subdistrict_th: "กบินทร์",
            subdistrict_en: "Kabin",
            post_code: "25110",
          },
          {
            subdistrict_th: "เมืองเก่า",
            subdistrict_en: "Mueang Kao",
            post_code: "25240",
          },
          {
            subdistrict_th: "วังดาล",
            subdistrict_en: "Wang Dan",
            post_code: "25110",
          },
          {
            subdistrict_th: "นนทรี",
            subdistrict_en: "Nonsi",
            post_code: "25110",
          },
          {
            subdistrict_th: "ย่านรี",
            subdistrict_en: "Yan Ri",
            post_code: "25110",
          },
          {
            subdistrict_th: "วังตะเคียน",
            subdistrict_en: "Wang Takhian",
            post_code: "25110",
          },
          {
            subdistrict_th: "หาดนางแก้ว",
            subdistrict_en: "Hat Nang Kaeo",
            post_code: "25110",
          },
          {
            subdistrict_th: "ลาดตะเคียน",
            subdistrict_en: "Lat Takhian",
            post_code: "25110",
          },
          {
            subdistrict_th: "บ้านนา",
            subdistrict_en: "Ban Na",
            post_code: "25110",
          },
          {
            subdistrict_th: "บ่อทอง",
            subdistrict_en: "Bo Thong",
            post_code: "25110",
          },
          {
            subdistrict_th: "หนองกี่",
            subdistrict_en: "Nong Ki",
            post_code: "25110",
          },
          {
            subdistrict_th: "นาแขม",
            subdistrict_en: "Na Khaem",
            post_code: "25110",
          },
          {
            subdistrict_th: "เขาไม้แก้ว",
            subdistrict_en: "Khao Mai Kaeo",
            post_code: "25110",
          },
          {
            subdistrict_th: "วังท่าช้าง",
            subdistrict_en: "Wang Tha Chang",
            post_code: "25110",
          },
        ],
      },
      {
        district_th: "อำเภอนาดี",
        district_en: "Amphoe Na Di",
        children: [
          {
            subdistrict_th: "นาดี",
            subdistrict_en: "Na Di",
            post_code: "25220",
          },
          {
            subdistrict_th: "สำพันตา",
            subdistrict_en: "Sam Phan Ta",
            post_code: "25220",
          },
          {
            subdistrict_th: "สะพานหิน",
            subdistrict_en: "Saphan Hin",
            post_code: "25220",
          },
          {
            subdistrict_th: "ทุ่งโพธิ์",
            subdistrict_en: "Thung Pho",
            post_code: "25220",
          },
          {
            subdistrict_th: "แก่งดินสอ",
            subdistrict_en: "Kaeng Din So",
            post_code: "25220",
          },
          {
            subdistrict_th: "บุพราหมณ์",
            subdistrict_en: "Bu Phram",
            post_code: "25220",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านสร้าง",
        district_en: "Amphoe Ban Sang",
        children: [
          {
            subdistrict_th: "บ้านสร้าง",
            subdistrict_en: "Ban Sang",
            post_code: "25150",
          },
          {
            subdistrict_th: "บางกระเบา",
            subdistrict_en: "Bang Krabao",
            post_code: "25150",
          },
          {
            subdistrict_th: "บางเตย",
            subdistrict_en: "Bang Toei",
            post_code: "25150",
          },
          {
            subdistrict_th: "บางยาง",
            subdistrict_en: "Bang Yang",
            post_code: "25150",
          },
          {
            subdistrict_th: "บางแตน",
            subdistrict_en: "Bang Taen",
            post_code: "25150",
          },
          {
            subdistrict_th: "บางพลวง",
            subdistrict_en: "Bang Phluang",
            post_code: "25150",
          },
          {
            subdistrict_th: "บางปลาร้า",
            subdistrict_en: "Bang Pla Ra",
            post_code: "25150",
          },
          {
            subdistrict_th: "บางขาม",
            subdistrict_en: "Bang Kham",
            post_code: "25150",
          },
          {
            subdistrict_th: "กระทุ่มแพ้ว",
            subdistrict_en: "Krathum Phaeo",
            post_code: "25150",
          },
        ],
      },
      {
        district_th: "อำเภอประจันตคาม",
        district_en: "Amphoe Prachantakham",
        children: [
          {
            subdistrict_th: "ประจันตคาม",
            subdistrict_en: "Prachantakham",
            post_code: "25130",
          },
          {
            subdistrict_th: "เกาะลอย",
            subdistrict_en: "Ko Loi",
            post_code: "25130",
          },
          {
            subdistrict_th: "บ้านหอย",
            subdistrict_en: "Ban Hoi",
            post_code: "25130",
          },
          {
            subdistrict_th: "หนองแสง",
            subdistrict_en: "Nong Saeng",
            post_code: "25130",
          },
          {
            subdistrict_th: "ดงบัง",
            subdistrict_en: "Dong Bang",
            post_code: "25130",
          },
          {
            subdistrict_th: "คำโตนด",
            subdistrict_en: "Kham Tanot",
            post_code: "25130",
          },
          {
            subdistrict_th: "บุฝ้าย",
            subdistrict_en: "Bu Fai",
            post_code: "25130",
          },
          {
            subdistrict_th: "หนองแก้ว",
            subdistrict_en: "Nong Kaeo",
            post_code: "25130",
          },
          {
            subdistrict_th: "โพธิ์งาม",
            subdistrict_en: "Pho Ngam",
            post_code: "25130",
          },
        ],
      },
      {
        district_th: "อำเภอศรีมหาโพธิ",
        district_en: "Amphoe Si Maha Phot",
        children: [
          {
            subdistrict_th: "ศรีมหาโพธิ",
            subdistrict_en: "Si Maha Phot",
            post_code: "25140",
          },
          {
            subdistrict_th: "สัมพันธ์",
            subdistrict_en: "Samphan",
            post_code: "25140",
          },
          {
            subdistrict_th: "บ้านทาม",
            subdistrict_en: "Ban Tham",
            post_code: "25140",
          },
          {
            subdistrict_th: "ท่าตูม",
            subdistrict_en: "Tha Tum",
            post_code: "25140",
          },
          {
            subdistrict_th: "บางกุ้ง",
            subdistrict_en: "Bang Kung",
            post_code: "25140",
          },
          {
            subdistrict_th: "ดงกระทงยาม",
            subdistrict_en: "Dong Krathong Yam",
            post_code: "25140",
          },
          {
            subdistrict_th: "หนองโพรง",
            subdistrict_en: "Nong Phrong",
            post_code: "25140",
          },
          {
            subdistrict_th: "หัวหว้า",
            subdistrict_en: "Hua Wa",
            post_code: "25140",
          },
          {
            subdistrict_th: "หาดยาง",
            subdistrict_en: "Hat Yang",
            post_code: "25140",
          },
          {
            subdistrict_th: "กรอกสมบูรณ์",
            subdistrict_en: "Krok Sombun",
            post_code: "25140",
          },
        ],
      },
      {
        district_th: "อำเภอศรีมโหสถ",
        district_en: "Amphoe Si Mahosot",
        children: [
          {
            subdistrict_th: "โคกปีบ",
            subdistrict_en: "Khok Pip",
            post_code: "25190",
          },
          {
            subdistrict_th: "โคกไทย",
            subdistrict_en: "Khok Thai",
            post_code: "25190",
          },
          {
            subdistrict_th: "คู้ลำพัน",
            subdistrict_en: "Khu Lam Phan",
            post_code: "25190",
          },
          {
            subdistrict_th: "ไผ่ชะเลือด",
            subdistrict_en: "Phai Cha Lueat",
            post_code: "25190",
          },
        ],
      },
    ],
  },
  {
    province_th: "นครนายก",
    province_en: "Nakhon Nayok",
    children: [
      {
        district_th: "อำเภอเมืองนครนายก",
        district_en: "Amphoe Mueang Nakhon Nayok",
        children: [
          {
            subdistrict_th: "นครนายก",
            subdistrict_en: "Nakhon Nayok",
            post_code: "26000",
          },
          {
            subdistrict_th: "ท่าช้าง",
            subdistrict_en: "Tha Chang",
            post_code: "26000",
          },
          {
            subdistrict_th: "บ้านใหญ่",
            subdistrict_en: "Ban Yai",
            post_code: "26000",
          },
          {
            subdistrict_th: "วังกระโจม",
            subdistrict_en: "Wang Krachom",
            post_code: "26000",
          },
          {
            subdistrict_th: "ท่าทราย",
            subdistrict_en: "Tha Sai",
            post_code: "26000",
          },
          {
            subdistrict_th: "ดอนยอ",
            subdistrict_en: "Don Yo",
            post_code: "26000",
          },
          {
            subdistrict_th: "ศรีจุฬา",
            subdistrict_en: "Si Chula",
            post_code: "26000",
          },
          {
            subdistrict_th: "ดงละคร",
            subdistrict_en: "Dong Lakhon",
            post_code: "26000",
          },
          {
            subdistrict_th: "ศรีนาวา",
            subdistrict_en: "Si Nawa",
            post_code: "26000",
          },
          {
            subdistrict_th: "สาริกา",
            subdistrict_en: "Sarika",
            post_code: "26000",
          },
          {
            subdistrict_th: "หินตั้ง",
            subdistrict_en: "Hin Tang",
            post_code: "26000",
          },
          {
            subdistrict_th: "เขาพระ",
            subdistrict_en: "Khao Phra",
            post_code: "26000",
          },
          {
            subdistrict_th: "พรหมณี",
            subdistrict_en: "Phrommani",
            post_code: "26000",
          },
        ],
      },
      {
        district_th: "อำเภอปากพลี",
        district_en: "Amphoe Pak Phli",
        children: [
          {
            subdistrict_th: "เกาะหวาย",
            subdistrict_en: "Ko Wai",
            post_code: "26130",
          },
          {
            subdistrict_th: "เกาะโพธิ์",
            subdistrict_en: "Ko Pho",
            post_code: "26130",
          },
          {
            subdistrict_th: "ปากพลี",
            subdistrict_en: "Pak Phli",
            post_code: "26130",
          },
          {
            subdistrict_th: "โคกกรวด",
            subdistrict_en: "Khok Kruat",
            post_code: "26130",
          },
          {
            subdistrict_th: "ท่าเรือ",
            subdistrict_en: "Tha Ruea",
            post_code: "26130",
          },
          {
            subdistrict_th: "หนองแสง",
            subdistrict_en: "Nong Saeng",
            post_code: "26130",
          },
          {
            subdistrict_th: "นาหินลาด",
            subdistrict_en: "Na Hin Lat",
            post_code: "26130",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านนา",
        district_en: "Amphoe Ban Na",
        children: [
          {
            subdistrict_th: "บ้านนา",
            subdistrict_en: "Ban Na",
            post_code: "26110",
          },
          {
            subdistrict_th: "บ้านพร้าว",
            subdistrict_en: "Ban Phrao",
            post_code: "26110",
          },
          {
            subdistrict_th: "บ้านพริก",
            subdistrict_en: "Ban Phrik",
            post_code: "26110",
          },
          {
            subdistrict_th: "อาษา",
            subdistrict_en: "Asa",
            post_code: "26110",
          },
          {
            subdistrict_th: "ทองหลาง",
            subdistrict_en: "Thonglang",
            post_code: "26110",
          },
          {
            subdistrict_th: "บางอ้อ",
            subdistrict_en: "Bang O",
            post_code: "26110",
          },
          {
            subdistrict_th: "พิกุลออก",
            subdistrict_en: "Phikun Ok",
            post_code: "26110",
          },
          {
            subdistrict_th: "ป่าขะ",
            subdistrict_en: "Pa Kha",
            post_code: "26110",
          },
          {
            subdistrict_th: "เขาเพิ่ม",
            subdistrict_en: "Khao Phoem",
            post_code: "26110",
          },
          {
            subdistrict_th: "ศรีกะอาง",
            subdistrict_en: "Si Ka-ang",
            post_code: "26110",
          },
        ],
      },
      {
        district_th: "อำเภอองครักษ์",
        district_en: "Amphoe Ongkharak",
        children: [
          {
            subdistrict_th: "พระอาจารย์",
            subdistrict_en: "Phra Achan",
            post_code: "26120",
          },
          {
            subdistrict_th: "บึงศาล",
            subdistrict_en: "Bueng San",
            post_code: "26120",
          },
          {
            subdistrict_th: "ศีรษะกระบือ",
            subdistrict_en: "Sisa Krabue",
            post_code: "26120",
          },
          {
            subdistrict_th: "โพธิ์แทน",
            subdistrict_en: "Pho Thaen",
            post_code: "26120",
          },
          {
            subdistrict_th: "บางสมบูรณ์",
            subdistrict_en: "Bang Sombun",
            post_code: "26120",
          },
          {
            subdistrict_th: "ทรายมูล",
            subdistrict_en: "Sai Mun",
            post_code: "26120",
          },
          {
            subdistrict_th: "บางปลากด",
            subdistrict_en: "Bang Pla Kot",
            post_code: "26120",
          },
          {
            subdistrict_th: "บางลูกเสือ",
            subdistrict_en: "Bang Luk Suea",
            post_code: "26120",
          },
          {
            subdistrict_th: "องครักษ์",
            subdistrict_en: "Ongkharak",
            post_code: "26120",
          },
          {
            subdistrict_th: "ชุมพล",
            subdistrict_en: "Chumphon",
            post_code: "26120",
          },
          {
            subdistrict_th: "คลองใหญ่",
            subdistrict_en: "Khlong Yai",
            post_code: "26120",
          },
        ],
      },
    ],
  },
  {
    province_th: "สระแก้ว",
    province_en: "Sa Kaeo",
    children: [
      {
        district_th: "อำเภอเมืองสระแก้ว",
        district_en: "Amphoe Mueang Sa Kaeo",
        children: [
          {
            subdistrict_th: "สระแก้ว",
            subdistrict_en: "Sa Kaeo",
            post_code: "27000",
          },
          {
            subdistrict_th: "บ้านแก้ง",
            subdistrict_en: "Ban Kaeng",
            post_code: "27000",
          },
          {
            subdistrict_th: "ศาลาลำดวน",
            subdistrict_en: "Sala Lamduan",
            post_code: "27000",
          },
          {
            subdistrict_th: "โคกปี่ฆ้อง",
            subdistrict_en: "Khok Pi Khong",
            post_code: "27000",
          },
          {
            subdistrict_th: "ท่าแยก",
            subdistrict_en: "Tha Yaek",
            post_code: "27000",
          },
          {
            subdistrict_th: "ท่าเกษม",
            subdistrict_en: "Tha Kasem",
            post_code: "27000",
          },
          {
            subdistrict_th: "สระขวัญ",
            subdistrict_en: "Sa Khwan",
            post_code: "27000",
          },
          {
            subdistrict_th: "หนองบอน",
            subdistrict_en: "Nong Bon",
            post_code: "27000",
          },
        ],
      },
      {
        district_th: "อำเภอคลองหาด",
        district_en: "Amphoe Khlong Hat",
        children: [
          {
            subdistrict_th: "คลองหาด",
            subdistrict_en: "Khlong Hat",
            post_code: "27260",
          },
          {
            subdistrict_th: "ไทยอุดม",
            subdistrict_en: "Thai Udom",
            post_code: "27260",
          },
          {
            subdistrict_th: "ซับมะกรูด",
            subdistrict_en: "Sap Makrut",
            post_code: "27260",
          },
          {
            subdistrict_th: "ไทรเดี่ยว",
            subdistrict_en: "Sai Diao",
            post_code: "27260",
          },
          {
            subdistrict_th: "คลองไก่เถื่อน",
            subdistrict_en: "Khlogn Kai Thuean",
            post_code: "27260",
          },
          {
            subdistrict_th: "เบญจขร",
            subdistrict_en: "Benchakhon",
            post_code: "27260",
          },
          {
            subdistrict_th: "ไทรทอง",
            subdistrict_en: "Sai Thong",
            post_code: "27260",
          },
        ],
      },
      {
        district_th: "อำเภอตาพระยา",
        district_en: "Amphoe Ta Phraya",
        children: [
          {
            subdistrict_th: "ตาพระยา",
            subdistrict_en: "Ta Phraya",
            post_code: "27180",
          },
          {
            subdistrict_th: "ทัพเสด็จ",
            subdistrict_en: "Thap Sadet",
            post_code: "27180",
          },
          {
            subdistrict_th: "ทัพราช",
            subdistrict_en: "Thap Rat",
            post_code: "27180",
          },
          {
            subdistrict_th: "ทัพไทย",
            subdistrict_en: "Thap Thai",
            post_code: "27180",
          },
          {
            subdistrict_th: "โคคลาน",
            subdistrict_en: "Kho Khlan",
            post_code: "27180",
          },
        ],
      },
      {
        district_th: "อำเภอวังน้ำเย็น",
        district_en: "Amphoe Wang Nam Yen",
        children: [
          {
            subdistrict_th: "วังน้ำเย็น",
            subdistrict_en: "Wang Nam Yen",
            post_code: "27210",
          },
          {
            subdistrict_th: "ตาหลังใน",
            subdistrict_en: "Ta Lang Nai",
            post_code: "27210",
          },
          {
            subdistrict_th: "คลองหินปูน",
            subdistrict_en: "Khlong Hin Pun",
            post_code: "27210",
          },
          {
            subdistrict_th: "ทุ่งมหาเจริญ",
            subdistrict_en: "Thung Maha Charoen",
            post_code: "27210",
          },
        ],
      },
      {
        district_th: "อำเภอวัฒนานคร",
        district_en: "Amphoe Watthana Nakhon",
        children: [
          {
            subdistrict_th: "วัฒนานคร",
            subdistrict_en: "Watthana Nakhon",
            post_code: "27160",
          },
          {
            subdistrict_th: "ท่าเกวียน",
            subdistrict_en: "Tha Kwian",
            post_code: "27160",
          },
          {
            subdistrict_th: "ผักขะ",
            subdistrict_en: "Phak Kha",
            post_code: "27160",
          },
          {
            subdistrict_th: "โนนหมากเค็ง",
            subdistrict_en: "Non Mak Kheng",
            post_code: "27160",
          },
          {
            subdistrict_th: "หนองน้ำใส",
            subdistrict_en: "Nong Nam Sai",
            post_code: "27160",
          },
          {
            subdistrict_th: "ช่องกุ่ม",
            subdistrict_en: "Chong Kum",
            post_code: "27160",
          },
          {
            subdistrict_th: "หนองแวง",
            subdistrict_en: "Nong Waeng",
            post_code: "27160",
          },
          {
            subdistrict_th: "แซร์ออ",
            subdistrict_en: "Sae-o",
            post_code: "27160",
          },
          {
            subdistrict_th: "หนองหมากฝ้าย",
            subdistrict_en: "Nong Mak Fai",
            post_code: "27160",
          },
          {
            subdistrict_th: "หนองตะเคียนบอน",
            subdistrict_en: "Nong Takian Bon",
            post_code: "27160",
          },
          {
            subdistrict_th: "ห้วยโจด",
            subdistrict_en: "Huai Chot",
            post_code: "27160",
          },
        ],
      },
      {
        district_th: "อำเภออรัญประเทศ",
        district_en: "Amphoe Aranyaprathet",
        children: [
          {
            subdistrict_th: "อรัญประเทศ",
            subdistrict_en: "Aranyaprathet",
            post_code: "27120",
          },
          {
            subdistrict_th: "เมืองไผ่",
            subdistrict_en: "Mueang Phai",
            post_code: "27120",
          },
          {
            subdistrict_th: "หันทราย",
            subdistrict_en: "Han Sai",
            post_code: "27120",
          },
          {
            subdistrict_th: "คลองน้ำใส",
            subdistrict_en: "Khlong Nam Sai",
            post_code: "27120",
          },
          {
            subdistrict_th: "ท่าข้าม",
            subdistrict_en: "Tha Kham",
            post_code: "27120",
          },
          {
            subdistrict_th: "ป่าไร่",
            subdistrict_en: "Pa Rai",
            post_code: "27120",
          },
          {
            subdistrict_th: "ทับพริก",
            subdistrict_en: "Thap Phrik",
            post_code: "27120",
          },
          {
            subdistrict_th: "บ้านใหม่หนองไทร",
            subdistrict_en: "Ban Mai Nong Sai",
            post_code: "27120",
          },
          {
            subdistrict_th: "ผ่านศึก",
            subdistrict_en: "Phan Suek",
            post_code: "27120",
          },
          {
            subdistrict_th: "หนองสังข์",
            subdistrict_en: "Nong Sang",
            post_code: "27120",
          },
          {
            subdistrict_th: "คลองทับจันทร์",
            subdistrict_en: "Khlong Thap Chan",
            post_code: "27120",
          },
          {
            subdistrict_th: "ฟากห้วย",
            subdistrict_en: "Fak Huai",
            post_code: "27120",
          },
          {
            subdistrict_th: "บ้านด่าน",
            subdistrict_en: "Ban Dan",
            post_code: "27120",
          },
        ],
      },
      {
        district_th: "อำเภอเขาฉกรรจ์",
        district_en: "Amphoe Khao Chakan",
        children: [
          {
            subdistrict_th: "เขาฉกรรจ์",
            subdistrict_en: "Khao Chakan",
            post_code: "27000",
          },
          {
            subdistrict_th: "หนองหว้า",
            subdistrict_en: "Nong Wa",
            post_code: "27000",
          },
          {
            subdistrict_th: "พระเพลิง",
            subdistrict_en: "Phra Phloeng",
            post_code: "27000",
          },
          {
            subdistrict_th: "เขาสามสิบ",
            subdistrict_en: "Khao Sam Sip",
            post_code: "27000",
          },
        ],
      },
      {
        district_th: "อำเภอโคกสูง",
        district_en: "Amphoe Khok Sung",
        children: [
          {
            subdistrict_th: "โคกสูง",
            subdistrict_en: "Khok Sung",
            post_code: "27120",
          },
          {
            subdistrict_th: "หนองม่วง",
            subdistrict_en: "Nong Muang",
            post_code: "27120",
          },
          {
            subdistrict_th: "หนองแวง",
            subdistrict_en: "Nong Waeng",
            post_code: "27120",
          },
          {
            subdistrict_th: "โนนหมากมุ่น",
            subdistrict_en: "Non Mak Mun",
            post_code: "27120",
          },
        ],
      },
      {
        district_th: "อำเภอวังสมบูรณ์",
        district_en: "Amphoe Wang Sombun",
        children: [
          {
            subdistrict_th: "วังสมบูรณ์",
            subdistrict_en: "Wang Sombun",
            post_code: "27250",
          },
          {
            subdistrict_th: "วังใหม่",
            subdistrict_en: "Wang Mai",
            post_code: "27250",
          },
          {
            subdistrict_th: "วังทอง",
            subdistrict_en: "Wang Thong",
            post_code: "27250",
          },
        ],
      },
    ],
  },
  {
    province_th: "นครราชสีมา",
    province_en: "Nakhon Ratchasima",
    children: [
      {
        district_th: "อำเภอเมืองนครราชสีมา",
        district_en: "Amphoe Mueang Nakhon Ratchasima",
        children: [
          {
            subdistrict_th: "ในเมือง",
            subdistrict_en: "Nai Mueang",
            post_code: "30000",
          },
          {
            subdistrict_th: "โพธิ์กลาง",
            subdistrict_en: "Pho Klang",
            post_code: "30000",
          },
          {
            subdistrict_th: "หนองจะบก",
            subdistrict_en: "Nong Chabok",
            post_code: "30000",
          },
          {
            subdistrict_th: "โคกสูง",
            subdistrict_en: "Khok Sung",
            post_code: "30310",
          },
          {
            subdistrict_th: "มะเริง",
            subdistrict_en: "Maroeng",
            post_code: "30000",
          },
          {
            subdistrict_th: "หนองระเวียง",
            subdistrict_en: "Nong Rawiang",
            post_code: "30000",
          },
          {
            subdistrict_th: "ปรุใหญ่",
            subdistrict_en: "Pru Yai",
            post_code: "30000",
          },
          {
            subdistrict_th: "หมื่นไวย",
            subdistrict_en: "Muen Wai",
            post_code: "30000",
          },
          {
            subdistrict_th: "พลกรัง",
            subdistrict_en: "Phon Krang",
            post_code: "30000",
          },
          {
            subdistrict_th: "หนองไผ่ล้อม",
            subdistrict_en: "Nong Phai Lom",
            post_code: "30000",
          },
          {
            subdistrict_th: "หัวทะเล",
            subdistrict_en: "Hua Thale",
            post_code: "30000",
          },
          {
            subdistrict_th: "บ้านเกาะ",
            subdistrict_en: "Ban Ko",
            post_code: "30000",
          },
          {
            subdistrict_th: "บ้านใหม่",
            subdistrict_en: "Ban Mai",
            post_code: "30000",
          },
          {
            subdistrict_th: "พุดซา",
            subdistrict_en: "Phutsa",
            post_code: "30000",
          },
          {
            subdistrict_th: "บ้านโพธิ์",
            subdistrict_en: "Ban Pho",
            post_code: "30310",
          },
          {
            subdistrict_th: "จอหอ",
            subdistrict_en: "Cho Ho",
            post_code: "30310",
          },
          {
            subdistrict_th: "โคกกรวด",
            subdistrict_en: "Khok Kruat",
            post_code: "30280",
          },
          {
            subdistrict_th: "ไชยมงคล",
            subdistrict_en: "Chai Mongkhon",
            post_code: "30000",
          },
          {
            subdistrict_th: "หนองบัวศาลา",
            subdistrict_en: "Nong Bua Sala",
            post_code: "30000",
          },
          {
            subdistrict_th: "สุรนารี",
            subdistrict_en: "Suranari",
            post_code: "30000",
          },
          {
            subdistrict_th: "สีมุม",
            subdistrict_en: "Si Mum",
            post_code: "30000",
          },
          {
            subdistrict_th: "ตลาด",
            subdistrict_en: "Talat",
            post_code: "30310",
          },
          {
            subdistrict_th: "พะเนา",
            subdistrict_en: "Phanao",
            post_code: "30000",
          },
          {
            subdistrict_th: "หนองกระทุ่ม",
            subdistrict_en: "Nong Krathum",
            post_code: "30000",
          },
          {
            subdistrict_th: "หนองไข่น้ำ",
            subdistrict_en: "Nong Khai Nam",
            post_code: "30310",
          },
        ],
      },
      {
        district_th: "อำเภอครบุรี",
        district_en: "Amphoe Khon Buri",
        children: [
          {
            subdistrict_th: "แชะ",
            subdistrict_en: "Chae",
            post_code: "30250",
          },
          {
            subdistrict_th: "เฉลียง",
            subdistrict_en: "Chaliang",
            post_code: "30250",
          },
          {
            subdistrict_th: "ครบุรี",
            subdistrict_en: "Khon Buri",
            post_code: "30250",
          },
          {
            subdistrict_th: "โคกกระชาย",
            subdistrict_en: "Khok Krachai",
            post_code: "30250",
          },
          {
            subdistrict_th: "จระเข้หิน",
            subdistrict_en: "Chorakhe Hin",
            post_code: "30250",
          },
          {
            subdistrict_th: "มาบตะโกเอน",
            subdistrict_en: "Map Tako En",
            post_code: "30250",
          },
          {
            subdistrict_th: "อรพิมพ์",
            subdistrict_en: "Oraphim",
            post_code: "30250",
          },
          {
            subdistrict_th: "บ้านใหม่",
            subdistrict_en: "Ban Mai",
            post_code: "30250",
          },
          {
            subdistrict_th: "ลำเพียก",
            subdistrict_en: "Lam Phiak",
            post_code: "30250",
          },
          {
            subdistrict_th: "ครบุรีใต้",
            subdistrict_en: "Khon Buri Tai",
            post_code: "30250",
          },
          {
            subdistrict_th: "ตะแบกบาน",
            subdistrict_en: "Tabaek Ban",
            post_code: "30250",
          },
          {
            subdistrict_th: "สระว่านพระยา",
            subdistrict_en: "Sa Wan Phraya",
            post_code: "30250",
          },
        ],
      },
      {
        district_th: "อำเภอเสิงสาง",
        district_en: "Amphoe Soeng Sang",
        children: [
          {
            subdistrict_th: "เสิงสาง",
            subdistrict_en: "Soeng Sang",
            post_code: "30330",
          },
          {
            subdistrict_th: "สระตะเคียน",
            subdistrict_en: "Sa Takhian",
            post_code: "30330",
          },
          {
            subdistrict_th: "โนนสมบูรณ์",
            subdistrict_en: "Non Sombun",
            post_code: "30330",
          },
          {
            subdistrict_th: "กุดโบสถ์",
            subdistrict_en: "Kut Bot",
            post_code: "30330",
          },
          {
            subdistrict_th: "สุขไพบูลย์",
            subdistrict_en: "Suk Phaibun",
            post_code: "30330",
          },
          {
            subdistrict_th: "บ้านราษฎร์",
            subdistrict_en: "Ban Rat",
            post_code: "30330",
          },
        ],
      },
      {
        district_th: "อำเภอคง",
        district_en: "Amphoe Khong",
        children: [
          {
            subdistrict_th: "เมืองคง",
            subdistrict_en: "Mueang Khong",
            post_code: "30260",
          },
          {
            subdistrict_th: "คูขาด",
            subdistrict_en: "Khu Khat",
            post_code: "30260",
          },
          {
            subdistrict_th: "เทพาลัย",
            subdistrict_en: "Thephalai",
            post_code: "30260",
          },
          {
            subdistrict_th: "ตาจั่น",
            subdistrict_en: "Ta Chan",
            post_code: "30260",
          },
          {
            subdistrict_th: "บ้านปรางค์",
            subdistrict_en: "Ban Prang",
            post_code: "30260",
          },
          {
            subdistrict_th: "หนองมะนาว",
            subdistrict_en: "Nong Manao",
            post_code: "30260",
          },
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "30260",
          },
          {
            subdistrict_th: "โนนเต็ง",
            subdistrict_en: "Non Teng",
            post_code: "30260",
          },
          {
            subdistrict_th: "ดอนใหญ่",
            subdistrict_en: "Don Yai",
            post_code: "30260",
          },
          {
            subdistrict_th: "ขามสมบูรณ์",
            subdistrict_en: "Kham Sombun",
            post_code: "30260",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านเหลื่อม",
        district_en: "Amphoe Ban Lueam",
        children: [
          {
            subdistrict_th: "บ้านเหลื่อม",
            subdistrict_en: "Ban Lueam",
            post_code: "30350",
          },
          {
            subdistrict_th: "วังโพธิ์",
            subdistrict_en: "Wang Pho",
            post_code: "30350",
          },
          {
            subdistrict_th: "โคกกระเบื้อง",
            subdistrict_en: "Khok Krabueang",
            post_code: "30350",
          },
          {
            subdistrict_th: "ช่อระกา",
            subdistrict_en: "Cho Raka",
            post_code: "30350",
          },
        ],
      },
      {
        district_th: "อำเภอจักราช",
        district_en: "Amphoe Chakkarat",
        children: [
          {
            subdistrict_th: "จักราช",
            subdistrict_en: "Chakkarat",
            post_code: "30230",
          },
          {
            subdistrict_th: "ทองหลาง",
            subdistrict_en: "Thonglang",
            post_code: "30230",
          },
          {
            subdistrict_th: "สีสุก",
            subdistrict_en: "Si Suk",
            post_code: "30230",
          },
          {
            subdistrict_th: "หนองขาม",
            subdistrict_en: "Nong Kham",
            post_code: "30230",
          },
          {
            subdistrict_th: "หนองพลวง",
            subdistrict_en: "Nong Phluang",
            post_code: "30230",
          },
          {
            subdistrict_th: "ศรีละกอ",
            subdistrict_en: "Si Lako",
            post_code: "30230",
          },
          {
            subdistrict_th: "คลองเมือง",
            subdistrict_en: "Khlong Mueang",
            post_code: "30230",
          },
          {
            subdistrict_th: "หินโคน",
            subdistrict_en: "Hin Khon",
            post_code: "30230",
          },
        ],
      },
      {
        district_th: "อำเภอโชคชัย",
        district_en: "Amphoe Chok Chai",
        children: [
          {
            subdistrict_th: "กระโทก",
            subdistrict_en: "Krathok",
            post_code: "30190",
          },
          {
            subdistrict_th: "พลับพลา",
            subdistrict_en: "Phlapphla",
            post_code: "30190",
          },
          {
            subdistrict_th: "ท่าอ่าง",
            subdistrict_en: "Tha Ang",
            post_code: "30190",
          },
          {
            subdistrict_th: "ทุ่งอรุณ",
            subdistrict_en: "Thung Arun",
            post_code: "30190",
          },
          {
            subdistrict_th: "ท่าลาดขาว",
            subdistrict_en: "Tha Lat Khao",
            post_code: "30190",
          },
          {
            subdistrict_th: "ท่าจะหลุง",
            subdistrict_en: "Tha Chalung",
            post_code: "30190",
          },
          {
            subdistrict_th: "ท่าเยี่ยม",
            subdistrict_en: "Tha Yiam",
            post_code: "30190",
          },
          {
            subdistrict_th: "โชคชัย",
            subdistrict_en: "Chok Chai",
            post_code: "30190",
          },
          {
            subdistrict_th: "ละลมใหม่พัฒนา",
            subdistrict_en: "Lalom Mai Phatthana",
            post_code: "30190",
          },
          {
            subdistrict_th: "ด่านเกวียน",
            subdistrict_en: "Dan Kwian",
            post_code: "30190",
          },
        ],
      },
      {
        district_th: "อำเภอด่านขุนทด",
        district_en: "Amphoe Dan Khun Thot",
        children: [
          {
            subdistrict_th: "กุดพิมาน",
            subdistrict_en: "Kut Phiman",
            post_code: "30210",
          },
          {
            subdistrict_th: "ด่านขุนทด",
            subdistrict_en: "Dan Khun Thot",
            post_code: "30210",
          },
          {
            subdistrict_th: "ด่านนอก",
            subdistrict_en: "Dan Nok",
            post_code: "30210",
          },
          {
            subdistrict_th: "ด่านใน",
            subdistrict_en: "Dan Nai",
            post_code: "30210",
          },
          {
            subdistrict_th: "ตะเคียน",
            subdistrict_en: "Takhian",
            post_code: "30210",
          },
          {
            subdistrict_th: "บ้านเก่า",
            subdistrict_en: "Ban Kao",
            post_code: "30210",
          },
          {
            subdistrict_th: "บ้านแปรง",
            subdistrict_en: "Ban Praeng",
            post_code: "36220",
          },
          {
            subdistrict_th: "พันชนะ",
            subdistrict_en: "Phan Chana",
            post_code: "30210",
          },
          {
            subdistrict_th: "สระจรเข้",
            subdistrict_en: "Sa Chorakhe",
            post_code: "30210",
          },
          {
            subdistrict_th: "หนองกราด",
            subdistrict_en: "Nong Krat",
            post_code: "30210",
          },
          {
            subdistrict_th: "หนองบัวตะเกียด",
            subdistrict_en: "Nong Bua Takiat",
            post_code: "30210",
          },
          {
            subdistrict_th: "หนองบัวละคร",
            subdistrict_en: "Nong Bua Lakhon",
            post_code: "30210",
          },
          {
            subdistrict_th: "หินดาด",
            subdistrict_en: "Hin Dat",
            post_code: "30210",
          },
          {
            subdistrict_th: "ห้วยบง",
            subdistrict_en: "Huai Bong",
            post_code: "30210",
          },
          {
            subdistrict_th: "โนนเมืองพัฒนา",
            subdistrict_en: "Non Mueang Phatthana",
            post_code: "30210",
          },
          {
            subdistrict_th: "หนองไทร",
            subdistrict_en: "Nong Sai",
            post_code: "36220",
          },
        ],
      },
      {
        district_th: "อำเภอโนนไทย",
        district_en: "Amphoe Non Thai",
        children: [
          {
            subdistrict_th: "โนนไทย",
            subdistrict_en: "Non Thai",
            post_code: "30220",
          },
          {
            subdistrict_th: "ด่านจาก",
            subdistrict_en: "Dan Chak",
            post_code: "30220",
          },
          {
            subdistrict_th: "กำปัง",
            subdistrict_en: "Kampang",
            post_code: "30220",
          },
          {
            subdistrict_th: "สำโรง",
            subdistrict_en: "Samrong",
            post_code: "30220",
          },
          {
            subdistrict_th: "ค้างพลู",
            subdistrict_en: "Khang Phu",
            post_code: "30220",
          },
          {
            subdistrict_th: "บ้านวัง",
            subdistrict_en: "Ban Wang",
            post_code: "30220",
          },
          {
            subdistrict_th: "บัลลังก์",
            subdistrict_en: "Banlang",
            post_code: "30220",
          },
          {
            subdistrict_th: "สายออ",
            subdistrict_en: "Sai O",
            post_code: "30220",
          },
          {
            subdistrict_th: "ถนนโพธิ์",
            subdistrict_en: "Thanon Pho",
            post_code: "30220",
          },
          {
            subdistrict_th: "มะค่า",
            subdistrict_en: "Makha",
            post_code: "30220",
          },
        ],
      },
      {
        district_th: "อำเภอโนนสูง",
        district_en: "Amphoe Non Sung",
        children: [
          {
            subdistrict_th: "โนนสูง",
            subdistrict_en: "Non Sung",
            post_code: "30160",
          },
          {
            subdistrict_th: "ใหม่",
            subdistrict_en: "Mai",
            post_code: "30160",
          },
          {
            subdistrict_th: "โตนด",
            subdistrict_en: "Tanot",
            post_code: "30160",
          },
          {
            subdistrict_th: "บิง",
            subdistrict_en: "Bing",
            post_code: "30160",
          },
          {
            subdistrict_th: "ดอนชมพู",
            subdistrict_en: "Don Chomphu",
            post_code: "30160",
          },
          {
            subdistrict_th: "ธารปราสาท",
            subdistrict_en: "Than Prasat",
            post_code: "30420",
          },
          {
            subdistrict_th: "หลุมข้าว",
            subdistrict_en: "Lum Khao",
            post_code: "30160",
          },
          {
            subdistrict_th: "มะค่า",
            subdistrict_en: "Makha",
            post_code: "30160",
          },
          {
            subdistrict_th: "พลสงคราม",
            subdistrict_en: "Phon Songkhram",
            post_code: "30160",
          },
          {
            subdistrict_th: "จันอัด",
            subdistrict_en: "Chan At",
            post_code: "30160",
          },
          {
            subdistrict_th: "ขามเฒ่า",
            subdistrict_en: "Kham Thao",
            post_code: "30160",
          },
          {
            subdistrict_th: "ด่านคล้า",
            subdistrict_en: "Dan Khla",
            post_code: "30160",
          },
          {
            subdistrict_th: "ลำคอหงษ์",
            subdistrict_en: "Lam Kho Hong",
            post_code: "30160",
          },
          {
            subdistrict_th: "เมืองปราสาท",
            subdistrict_en: "Mueang Prasat",
            post_code: "30160",
          },
          {
            subdistrict_th: "ดอนหวาย",
            subdistrict_en: "Don Wai",
            post_code: "30160",
          },
          {
            subdistrict_th: "ลำมูล",
            subdistrict_en: "Lam Mun",
            post_code: "30160",
          },
        ],
      },
      {
        district_th: "อำเภอขามสะแกแสง",
        district_en: "Amphoe Kham Sakaesaeng",
        children: [
          {
            subdistrict_th: "ขามสะแกแสง",
            subdistrict_en: "Kham Sakaesaeng",
            post_code: "30290",
          },
          {
            subdistrict_th: "โนนเมือง",
            subdistrict_en: "Non Mueang",
            post_code: "30290",
          },
          {
            subdistrict_th: "เมืองนาท",
            subdistrict_en: "Mueang Nat",
            post_code: "30290",
          },
          {
            subdistrict_th: "ชีวึก",
            subdistrict_en: "Chiwuek",
            post_code: "30290",
          },
          {
            subdistrict_th: "พะงาด",
            subdistrict_en: "Pha-ngat",
            post_code: "30290",
          },
          {
            subdistrict_th: "หนองหัวฟาน",
            subdistrict_en: "Nong Hua Fan",
            post_code: "30290",
          },
          {
            subdistrict_th: "เมืองเกษตร",
            subdistrict_en: "Mueang Kaset",
            post_code: "30290",
          },
        ],
      },
      {
        district_th: "อำเภอบัวใหญ่",
        district_en: "Amphoe Bua Yai",
        children: [
          {
            subdistrict_th: "บัวใหญ่",
            subdistrict_en: "Bua Yai",
            post_code: "30120",
          },
          {
            subdistrict_th: "ห้วยยาง",
            subdistrict_en: "Huai Yang",
            post_code: "30120",
          },
          {
            subdistrict_th: "เสมาใหญ่",
            subdistrict_en: "Sema Yai",
            post_code: "30120",
          },
          {
            subdistrict_th: "ดอนตะหนิน",
            subdistrict_en: "Don Tanin",
            post_code: "30120",
          },
          {
            subdistrict_th: "หนองบัวสะอาด",
            subdistrict_en: "Nong Bua Sa-at",
            post_code: "30120",
          },
          {
            subdistrict_th: "โนนทองหลาง",
            subdistrict_en: "Non Thonglang",
            post_code: "30120",
          },
          {
            subdistrict_th: "กุดจอก",
            subdistrict_en: "Kut Chok",
            post_code: "30120",
          },
          {
            subdistrict_th: "ด่านช้าง",
            subdistrict_en: "Dan Chang",
            post_code: "30120",
          },
          {
            subdistrict_th: "ขุนทอง",
            subdistrict_en: "Khun Thong",
            post_code: "30120",
          },
          {
            subdistrict_th: "หนองแจ้งใหญ่",
            subdistrict_en: "Nong Chaeng Yai",
            post_code: "30120",
          },
        ],
      },
      {
        district_th: "อำเภอประทาย",
        district_en: "Amphoe Prathai",
        children: [
          {
            subdistrict_th: "ประทาย",
            subdistrict_en: "Prathai",
            post_code: "30180",
          },
          {
            subdistrict_th: "กระทุ่มราย",
            subdistrict_en: "Krathum Rai",
            post_code: "30180",
          },
          {
            subdistrict_th: "วังไม้แดง",
            subdistrict_en: "Wang Mai Daeng",
            post_code: "30180",
          },
          {
            subdistrict_th: "ตลาดไทร",
            subdistrict_en: "Talat Sai",
            post_code: "30180",
          },
          {
            subdistrict_th: "หนองพลวง",
            subdistrict_en: "Nong Phluang",
            post_code: "30180",
          },
          {
            subdistrict_th: "หนองค่าย",
            subdistrict_en: "Nong Khai",
            post_code: "30180",
          },
          {
            subdistrict_th: "หันห้วยทราย",
            subdistrict_en: "Han Huai Sai",
            post_code: "30180",
          },
          {
            subdistrict_th: "ดอนมัน",
            subdistrict_en: "Don Man",
            post_code: "30180",
          },
          {
            subdistrict_th: "นางรำ",
            subdistrict_en: "Nang Ram",
            post_code: "30180",
          },
          {
            subdistrict_th: "โนนเพ็ด",
            subdistrict_en: "Non Phet",
            post_code: "30180",
          },
          {
            subdistrict_th: "ทุ่งสว่าง",
            subdistrict_en: "Thung Sawang",
            post_code: "30180",
          },
          {
            subdistrict_th: "โคกกลาง",
            subdistrict_en: "Khok Klang",
            post_code: "30180",
          },
          {
            subdistrict_th: "เมืองโดน",
            subdistrict_en: "Mueang Don",
            post_code: "30180",
          },
        ],
      },
      {
        district_th: "อำเภอปักธงชัย",
        district_en: "Amphoe Pak Thong Chai",
        children: [
          {
            subdistrict_th: "เมืองปัก",
            subdistrict_en: "Mueang Pak",
            post_code: "30150",
          },
          {
            subdistrict_th: "ตะคุ",
            subdistrict_en: "Takhu",
            post_code: "30150",
          },
          {
            subdistrict_th: "โคกไทย",
            subdistrict_en: "Khok Thai",
            post_code: "30150",
          },
          {
            subdistrict_th: "สำโรง",
            subdistrict_en: "Samrong",
            post_code: "30150",
          },
          {
            subdistrict_th: "ตะขบ",
            subdistrict_en: "Takhop",
            post_code: "30150",
          },
          {
            subdistrict_th: "นกออก",
            subdistrict_en: "Nok Ok",
            post_code: "30150",
          },
          {
            subdistrict_th: "ดอน",
            subdistrict_en: "Don",
            post_code: "30150",
          },
          {
            subdistrict_th: "ตูม",
            subdistrict_en: "Tum",
            post_code: "30150",
          },
          {
            subdistrict_th: "งิ้ว",
            subdistrict_en: "Ngio",
            post_code: "30150",
          },
          {
            subdistrict_th: "สะแกราช",
            subdistrict_en: "Sakae Rat",
            post_code: "30150",
          },
          {
            subdistrict_th: "ลำนางแก้ว",
            subdistrict_en: "Lam Nang Kaeo",
            post_code: "30150",
          },
          {
            subdistrict_th: "ภูหลวง",
            subdistrict_en: "Phu Luang",
            post_code: "30150",
          },
          {
            subdistrict_th: "ธงชัยเหนือ",
            subdistrict_en: "Thong Chai Nuea",
            post_code: "30150",
          },
          {
            subdistrict_th: "สุขเกษม",
            subdistrict_en: "Suk Kasem",
            post_code: "30150",
          },
          {
            subdistrict_th: "เกษมทรัพย์",
            subdistrict_en: "Kasem Sap",
            post_code: "30150",
          },
          {
            subdistrict_th: "บ่อปลาทอง",
            subdistrict_en: "Bo Pla Thong",
            post_code: "30150",
          },
        ],
      },
      {
        district_th: "อำเภอพิมาย",
        district_en: "Amphoe Phimai",
        children: [
          {
            subdistrict_th: "ในเมือง",
            subdistrict_en: "Nai Mueang",
            post_code: "30110",
          },
          {
            subdistrict_th: "สัมฤทธิ์",
            subdistrict_en: "Samrit",
            post_code: "30110",
          },
          {
            subdistrict_th: "โบสถ์",
            subdistrict_en: "Bot",
            post_code: "30110",
          },
          {
            subdistrict_th: "กระเบื้องใหญ่",
            subdistrict_en: "Krabueang Yai",
            post_code: "30110",
          },
          {
            subdistrict_th: "ท่าหลวง",
            subdistrict_en: "Tha Luang",
            post_code: "30110",
          },
          {
            subdistrict_th: "รังกาใหญ่",
            subdistrict_en: "Rang Ka Yai",
            post_code: "30110",
          },
          {
            subdistrict_th: "ชีวาน",
            subdistrict_en: "Chiwan",
            post_code: "30110",
          },
          {
            subdistrict_th: "นิคมสร้างตนเอง",
            subdistrict_en: "Nikhom Sang Ton-eng Phimai",
            post_code: "30110",
          },
          {
            subdistrict_th: "กระชอน",
            subdistrict_en: "Krachon",
            post_code: "30110",
          },
          {
            subdistrict_th: "ดงใหญ่",
            subdistrict_en: "Dong Yai",
            post_code: "30110",
          },
          {
            subdistrict_th: "ธารละหลอด",
            subdistrict_en: "Than Lalot",
            post_code: "30110",
          },
          {
            subdistrict_th: "หนองระเวียง",
            subdistrict_en: "Nong Rawiang",
            post_code: "30110",
          },
        ],
      },
      {
        district_th: "อำเภอห้วยแถลง",
        district_en: "Amphoe Huai Thalaeng",
        children: [
          {
            subdistrict_th: "ห้วยแถลง",
            subdistrict_en: "Huai Thalaeng",
            post_code: "30240",
          },
          {
            subdistrict_th: "ทับสวาย",
            subdistrict_en: "Thap Sawai",
            post_code: "30240",
          },
          {
            subdistrict_th: "เมืองพลับพลา",
            subdistrict_en: "Mueang Phlapphla",
            post_code: "30240",
          },
          {
            subdistrict_th: "หลุ่งตะเคียน",
            subdistrict_en: "Lung Takhian",
            post_code: "30240",
          },
          {
            subdistrict_th: "หินดาด",
            subdistrict_en: "Hin Dat",
            post_code: "30240",
          },
          {
            subdistrict_th: "งิ้ว",
            subdistrict_en: "Ngio",
            post_code: "30240",
          },
          {
            subdistrict_th: "กงรถ",
            subdistrict_en: "Kong Rot",
            post_code: "30240",
          },
          {
            subdistrict_th: "หลุ่งประดู่",
            subdistrict_en: "Lung Pradu",
            post_code: "30240",
          },
          {
            subdistrict_th: "ตะโก",
            subdistrict_en: "Tako",
            post_code: "30240",
          },
          {
            subdistrict_th: "ห้วยแคน",
            subdistrict_en: "Huai Khaen",
            post_code: "30240",
          },
        ],
      },
      {
        district_th: "อำเภอชุมพวง",
        district_en: "Amphoe Chum Phuang",
        children: [
          {
            subdistrict_th: "ชุมพวง",
            subdistrict_en: "Chum Phuang",
            post_code: "30270",
          },
          {
            subdistrict_th: "ประสุข",
            subdistrict_en: "Prasuk",
            post_code: "30270",
          },
          {
            subdistrict_th: "ท่าลาด",
            subdistrict_en: "Tha Lat",
            post_code: "30270",
          },
          {
            subdistrict_th: "สาหร่าย",
            subdistrict_en: "Sarai",
            post_code: "30270",
          },
          {
            subdistrict_th: "ตลาดไทร",
            subdistrict_en: "Talat Sai",
            post_code: "30270",
          },
          {
            subdistrict_th: "โนนรัง",
            subdistrict_en: "Non Rang",
            post_code: "30270",
          },
          {
            subdistrict_th: "หนองหลัก",
            subdistrict_en: "Nong Lak",
            post_code: "30270",
          },
          {
            subdistrict_th: "โนนตูม",
            subdistrict_en: "Non Tum",
            post_code: "30270",
          },
          {
            subdistrict_th: "โนนยอ",
            subdistrict_en: "Non Yo",
            post_code: "30270",
          },
        ],
      },
      {
        district_th: "อำเภอสูงเนิน",
        district_en: "Amphoe Sung Noen",
        children: [
          {
            subdistrict_th: "สูงเนิน",
            subdistrict_en: "Sung Noen",
            post_code: "30170",
          },
          {
            subdistrict_th: "เสมา",
            subdistrict_en: "Sema",
            post_code: "30170",
          },
          {
            subdistrict_th: "โคราช",
            subdistrict_en: "Khorat",
            post_code: "30170",
          },
          {
            subdistrict_th: "บุ่งขี้เหล็ก",
            subdistrict_en: "Bung Khilek",
            post_code: "30170",
          },
          {
            subdistrict_th: "โนนค่า",
            subdistrict_en: "Non Kha",
            post_code: "30170",
          },
          {
            subdistrict_th: "โค้งยาง",
            subdistrict_en: "Khong Yang",
            post_code: "30170",
          },
          {
            subdistrict_th: "มะเกลือเก่า",
            subdistrict_en: "Makluea Kao",
            post_code: "30170",
          },
          {
            subdistrict_th: "มะเกลือใหม่",
            subdistrict_en: "Makluea Mai",
            post_code: "30170",
          },
          {
            subdistrict_th: "นากลาง",
            subdistrict_en: "Na Klang",
            post_code: "30380",
          },
          {
            subdistrict_th: "หนองตะไก้",
            subdistrict_en: "Nong Takai",
            post_code: "30380",
          },
          {
            subdistrict_th: "กุดจิก",
            subdistrict_en: "Kut Chik",
            post_code: "30380",
          },
        ],
      },
      {
        district_th: "อำเภอขามทะเลสอ",
        district_en: "Amphoe Kham Thale So",
        children: [
          {
            subdistrict_th: "ขามทะเลสอ",
            subdistrict_en: "Kham Thale So",
            post_code: "30280",
          },
          {
            subdistrict_th: "โป่งแดง",
            subdistrict_en: "Pong Daeng",
            post_code: "30280",
          },
          {
            subdistrict_th: "พันดุง",
            subdistrict_en: "Phan Dung",
            post_code: "30280",
          },
          {
            subdistrict_th: "หนองสรวง",
            subdistrict_en: "Nong Suang",
            post_code: "30280",
          },
          {
            subdistrict_th: "บึงอ้อ",
            subdistrict_en: "Bueng O",
            post_code: "30280",
          },
        ],
      },
      {
        district_th: "อำเภอสีคิ้ว",
        district_en: "Amphoe Sikhio",
        children: [
          {
            subdistrict_th: "สีคิ้ว",
            subdistrict_en: "Sikhio",
            post_code: "30140",
          },
          {
            subdistrict_th: "บ้านหัน",
            subdistrict_en: "Ban Han",
            post_code: "30140",
          },
          {
            subdistrict_th: "กฤษณา",
            subdistrict_en: "Kritsana",
            post_code: "30140",
          },
          {
            subdistrict_th: "ลาดบัวขาว",
            subdistrict_en: "Lat Bua Khao",
            post_code: "30340",
          },
          {
            subdistrict_th: "หนองหญ้าขาว",
            subdistrict_en: "Nong Ya Khao",
            post_code: "30140",
          },
          {
            subdistrict_th: "กุดน้อย",
            subdistrict_en: "Kut Noi",
            post_code: "30140",
          },
          {
            subdistrict_th: "หนองน้ำใส",
            subdistrict_en: "Nong Nam Sai",
            post_code: "30140",
          },
          {
            subdistrict_th: "วังโรงใหญ่",
            subdistrict_en: "Wang Rong Yai",
            post_code: "30140",
          },
          {
            subdistrict_th: "มิตรภาพ",
            subdistrict_en: "Mittraphap",
            post_code: "30140",
          },
          {
            subdistrict_th: "คลองไผ่",
            subdistrict_en: "Khlong Phai",
            post_code: "30340",
          },
          {
            subdistrict_th: "ดอนเมือง",
            subdistrict_en: "Don Mueang",
            post_code: "30140",
          },
          {
            subdistrict_th: "หนองบัวน้อย",
            subdistrict_en: "Nong Bua Noi",
            post_code: "30140",
          },
        ],
      },
      {
        district_th: "อำเภอปากช่อง",
        district_en: "Amphoe Pak Chong",
        children: [
          {
            subdistrict_th: "ปากช่อง",
            subdistrict_en: "Pak Chong",
            post_code: "30130",
          },
          {
            subdistrict_th: "กลางดง",
            subdistrict_en: "Klang Dong",
            post_code: "30320",
          },
          {
            subdistrict_th: "จันทึก",
            subdistrict_en: "Chanthuek",
            post_code: "30130",
          },
          {
            subdistrict_th: "วังกะทะ",
            subdistrict_en: "Wang Katha",
            post_code: "30130",
          },
          {
            subdistrict_th: "หมูสี",
            subdistrict_en: "Mu Si",
            post_code: "30130",
          },
          {
            subdistrict_th: "หนองสาหร่าย",
            subdistrict_en: "Nong Sarai",
            post_code: "30130",
          },
          {
            subdistrict_th: "ขนงพระ",
            subdistrict_en: "Khanong Phra",
            post_code: "30130",
          },
          {
            subdistrict_th: "โป่งตาลอง",
            subdistrict_en: "Pong Talong",
            post_code: "30130",
          },
          {
            subdistrict_th: "คลองม่วง",
            subdistrict_en: "Khlong Muang",
            post_code: "30130",
          },
          {
            subdistrict_th: "หนองน้ำแดง",
            subdistrict_en: "Nong Nam Daeng",
            post_code: "30130",
          },
          {
            subdistrict_th: "วังไทร",
            subdistrict_en: "Wang Sai",
            post_code: "30130",
          },
          {
            subdistrict_th: "พญาเย็น",
            subdistrict_en: "Phaya Yen",
            post_code: "30320",
          },
        ],
      },
      {
        district_th: "อำเภอหนองบุญมาก",
        district_en: "Amphoe Nong Bunmak",
        children: [
          {
            subdistrict_th: "หนองบุนนาก",
            subdistrict_en: "Nong Bunnak",
            post_code: "30410",
          },
          {
            subdistrict_th: "สารภี",
            subdistrict_en: "Saraphi",
            post_code: "30410",
          },
          {
            subdistrict_th: "ไทยเจริญ",
            subdistrict_en: "Thai Charoen",
            post_code: "30410",
          },
          {
            subdistrict_th: "หนองหัวแรต",
            subdistrict_en: "Nong Hua Raet",
            post_code: "30410",
          },
          {
            subdistrict_th: "แหลมทอง",
            subdistrict_en: "Laem Thong",
            post_code: "30410",
          },
          {
            subdistrict_th: "หนองตะไก้",
            subdistrict_en: "Nong Takai",
            post_code: "30410",
          },
          {
            subdistrict_th: "ลุงเขว้า",
            subdistrict_en: "Lung Khwao",
            post_code: "30410",
          },
          {
            subdistrict_th: "หนองไม้ไผ่",
            subdistrict_en: "Nong Mai Phai",
            post_code: "30410",
          },
          {
            subdistrict_th: "บ้านใหม่",
            subdistrict_en: "Ban Mai",
            post_code: "30410",
          },
        ],
      },
      {
        district_th: "อำเภอแก้งสนามนาง",
        district_en: "Amphoe Kaeng Sanam Nang",
        children: [
          {
            subdistrict_th: "แก้งสนามนาง",
            subdistrict_en: "Kaeng Sanam Nang",
            post_code: "30440",
          },
          {
            subdistrict_th: "โนนสำราญ",
            subdistrict_en: "Non Samran",
            post_code: "30440",
          },
          {
            subdistrict_th: "บึงพะไล",
            subdistrict_en: "Bueng Phalai",
            post_code: "30440",
          },
          {
            subdistrict_th: "สีสุก",
            subdistrict_en: "Si Suk",
            post_code: "30440",
          },
          {
            subdistrict_th: "บึงสำโรง",
            subdistrict_en: "Bueng Samrong",
            post_code: "30440",
          },
        ],
      },
      {
        district_th: "อำเภอโนนแดง",
        district_en: "Amphoe Non Daeng",
        children: [
          {
            subdistrict_th: "โนนแดง",
            subdistrict_en: "Non Daeng",
            post_code: "30360",
          },
          {
            subdistrict_th: "โนนตาเถร",
            subdistrict_en: "Non Ta Then",
            post_code: "30360",
          },
          {
            subdistrict_th: "สำพะเนียง",
            subdistrict_en: "Lam Phaniang",
            post_code: "30360",
          },
          {
            subdistrict_th: "วังหิน",
            subdistrict_en: "Wang Hin",
            post_code: "30360",
          },
          {
            subdistrict_th: "ดอนยาวใหญ่",
            subdistrict_en: "Don Yao Yai",
            post_code: "30360",
          },
        ],
      },
      {
        district_th: "อำเภอวังน้ำเขียว",
        district_en: "Amphoe Wang Nam Khiao",
        children: [
          {
            subdistrict_th: "วังน้ำเขียว",
            subdistrict_en: "Wang Nam Khiao",
            post_code: "30370",
          },
          {
            subdistrict_th: "วังหมี",
            subdistrict_en: "Wang Mi",
            post_code: "30370",
          },
          {
            subdistrict_th: "ระเริง",
            subdistrict_en: "Raroeng",
            post_code: "30150",
          },
          {
            subdistrict_th: "อุดมทรัพย์",
            subdistrict_en: "Udom Sap",
            post_code: "30370",
          },
          {
            subdistrict_th: "ไทยสามัคคี",
            subdistrict_en: "Thai Samakkhi",
            post_code: "30370",
          },
        ],
      },
      {
        district_th: "อำเภอเทพารักษ์",
        district_en: "Amphoe Thepharak",
        children: [
          {
            subdistrict_th: "สำนักตะคร้อ",
            subdistrict_en: "Samnak Takhro",
            post_code: "30210",
          },
          {
            subdistrict_th: "หนองแวง",
            subdistrict_en: "Nong Waeng",
            post_code: "30210",
          },
          {
            subdistrict_th: "บึงปรือ",
            subdistrict_en: "Bueng Prue",
            post_code: "30210",
          },
          {
            subdistrict_th: "วังยายทอง",
            subdistrict_en: "Wang Yai Thong",
            post_code: "30210",
          },
        ],
      },
      {
        district_th: "อำเภอเมืองยาง",
        district_en: "Amphoe Mueang Yang",
        children: [
          {
            subdistrict_th: "เมืองยาง",
            subdistrict_en: "Mueang Yang",
            post_code: "30270",
          },
          {
            subdistrict_th: "กระเบื้องนอก",
            subdistrict_en: "Krabueang Nok",
            post_code: "30270",
          },
          {
            subdistrict_th: "ละหานปลาค้าว",
            subdistrict_en: "Lahan Pla Khao",
            post_code: "30270",
          },
          {
            subdistrict_th: "โนนอุดม",
            subdistrict_en: "Non Udom",
            post_code: "30270",
          },
        ],
      },
      {
        district_th: "อำเภอพระทองคำ",
        district_en: "Amphoe Phra Thong Kham",
        children: [
          {
            subdistrict_th: "สระพระ",
            subdistrict_en: "Sa Phra",
            post_code: "30220",
          },
          {
            subdistrict_th: "มาบกราด",
            subdistrict_en: "Map Krat",
            post_code: "30220",
          },
          {
            subdistrict_th: "พังเทียม",
            subdistrict_en: "Phang Thiam",
            post_code: "30220",
          },
          {
            subdistrict_th: "ทัพรั้ง",
            subdistrict_en: "Thap Rang",
            post_code: "30220",
          },
          {
            subdistrict_th: "หนองหอย",
            subdistrict_en: "Nong Hoi",
            post_code: "30220",
          },
        ],
      },
      {
        district_th: "อำเภอลำทะเมนชัย",
        district_en: "Amphoe Lam Thamenchai",
        children: [
          {
            subdistrict_th: "ขุย",
            subdistrict_en: "Khui",
            post_code: "30270",
          },
          {
            subdistrict_th: "บ้านยาง",
            subdistrict_en: "Ban Yang",
            post_code: "30270",
          },
          {
            subdistrict_th: "ช่องแมว",
            subdistrict_en: "Chong Maeo",
            post_code: "30270",
          },
          {
            subdistrict_th: "ไพล",
            subdistrict_en: "Phlai",
            post_code: "30270",
          },
        ],
      },
      {
        district_th: "อำเภอบัวลาย",
        district_en: "Amphoe Bua Lai",
        children: [
          {
            subdistrict_th: "เมืองพะไล",
            subdistrict_en: "Mueang Phalai",
            post_code: "30120",
          },
          {
            subdistrict_th: "โนนจาน",
            subdistrict_en: "Non Chan",
            post_code: "30120",
          },
          {
            subdistrict_th: "บัวลาย",
            subdistrict_en: "Bua Lai",
            post_code: "30120",
          },
          {
            subdistrict_th: "หนองหว้า",
            subdistrict_en: "Nong Wa",
            post_code: "30120",
          },
        ],
      },
      {
        district_th: "อำเภอสีดา",
        district_en: "Amphoe Sida",
        children: [
          {
            subdistrict_th: "สีดา",
            subdistrict_en: "Sida",
            post_code: "30430",
          },
          {
            subdistrict_th: "โพนทอง",
            subdistrict_en: "Phon Thong",
            post_code: "30430",
          },
          {
            subdistrict_th: "โนนประดู่",
            subdistrict_en: "Non Pladu",
            post_code: "30430",
          },
          {
            subdistrict_th: "สามเมือง",
            subdistrict_en: "Sam Mueang",
            post_code: "30430",
          },
          {
            subdistrict_th: "หนองตาดใหญ่",
            subdistrict_en: "Nong Tat Yai",
            post_code: "30430",
          },
        ],
      },
      {
        district_th: "อำเภอเฉลิมพระเกียรติ",
        district_en: "Amphoe Chaloem Phra Kiat",
        children: [
          {
            subdistrict_th: "เขาดินพัฒนา",
            subdistrict_en: "Khao Din Phatthana",
            post_code: "18000",
          },
          {
            subdistrict_th: "บ้านแก้ง",
            subdistrict_en: "Ban Kaeng",
            post_code: "18000",
          },
          {
            subdistrict_th: "ผึ้งรวง",
            subdistrict_en: "Phueng Ruang",
            post_code: "18000",
          },
          {
            subdistrict_th: "พุแค",
            subdistrict_en: "Phu Khae",
            post_code: "18240",
          },
          {
            subdistrict_th: "ห้วยบง",
            subdistrict_en: "Huai Bong",
            post_code: "18000",
          },
          {
            subdistrict_th: "หน้าพระลาน",
            subdistrict_en: "Na Phra Lan",
            post_code: "18240",
          },
          {
            subdistrict_th: "ช้างทอง",
            subdistrict_en: "Chang Thong",
            post_code: "30230",
          },
          {
            subdistrict_th: "ท่าช้าง",
            subdistrict_en: "Tha Chang",
            post_code: "30230",
          },
          {
            subdistrict_th: "พระพุทธ",
            subdistrict_en: "Phra Phut",
            post_code: "30230",
          },
          {
            subdistrict_th: "หนองงูเหลือม",
            subdistrict_en: "Nong Ngu Lueam",
            post_code: "30000",
          },
          {
            subdistrict_th: "หนองยาง",
            subdistrict_en: "Nong Yang",
            post_code: "30230",
          },
          {
            subdistrict_th: "เจริญสุข",
            subdistrict_en: "Charoen Suk",
            post_code: "31110",
          },
          {
            subdistrict_th: "ตาเป๊ก",
            subdistrict_en: "Ta Pek",
            post_code: "31110",
          },
          {
            subdistrict_th: "อีสานเขต",
            subdistrict_en: "Isan Khet",
            post_code: "31110",
          },
          {
            subdistrict_th: "ถาวร",
            subdistrict_en: "Thawon",
            post_code: "31170",
          },
          {
            subdistrict_th: "ยายแย้มวัฒนา",
            subdistrict_en: "Yai Yaem Watthana",
            post_code: "31170",
          },
          {
            subdistrict_th: "ห้วยโก๋น",
            subdistrict_en: "Huai Kon",
            post_code: "55130",
          },
          {
            subdistrict_th: "ขุนน่าน",
            subdistrict_en: "Khun Nan",
            post_code: "55220",
          },
          {
            subdistrict_th: "เชียรเขา",
            subdistrict_en: "Chian Khao",
            post_code: "80190",
          },
          {
            subdistrict_th: "ดอนตรอ",
            subdistrict_en: "Don Tro",
            post_code: "80290",
          },
          {
            subdistrict_th: "สวนหลวง",
            subdistrict_en: "Suan Luang",
            post_code: "80190",
          },
          {
            subdistrict_th: "ทางพูน",
            subdistrict_en: "Thang Phun",
            post_code: "80290",
          },
        ],
      },
    ],
  },
  {
    province_th: "บุรีรัมย์",
    province_en: "Buri Ram",
    children: [
      {
        district_th: "อำเภอเมืองบุรีรัมย์",
        district_en: "Amphoe Mueang Buri Ram",
        children: [
          {
            subdistrict_th: "ในเมือง",
            subdistrict_en: "Nai Mueang",
            post_code: "31000",
          },
          {
            subdistrict_th: "อิสาณ",
            subdistrict_en: "Isan",
            post_code: "31000",
          },
          {
            subdistrict_th: "เสม็ด",
            subdistrict_en: "Samet",
            post_code: "31000",
          },
          {
            subdistrict_th: "บ้านบัว",
            subdistrict_en: "Ban Bua",
            post_code: "31000",
          },
          {
            subdistrict_th: "สะแกโพรง",
            subdistrict_en: "Sakae Phrong",
            post_code: "31000",
          },
          {
            subdistrict_th: "สวายจีก",
            subdistrict_en: "Sawai Chik",
            post_code: "31000",
          },
          {
            subdistrict_th: "บ้านยาง",
            subdistrict_en: "Ban Yang",
            post_code: "31000",
          },
          {
            subdistrict_th: "พระครู",
            subdistrict_en: "Phra Khru",
            post_code: "31000",
          },
          {
            subdistrict_th: "ถลุงเหล็ก",
            subdistrict_en: "Thalung Lek",
            post_code: "31000",
          },
          {
            subdistrict_th: "หนองตาด",
            subdistrict_en: "Nong Tat",
            post_code: "31000",
          },
          {
            subdistrict_th: "ลุมปุ๊ก",
            subdistrict_en: "Lumpuk",
            post_code: "31000",
          },
          {
            subdistrict_th: "สองห้อง",
            subdistrict_en: "Song Hong",
            post_code: "31000",
          },
          {
            subdistrict_th: "บัวทอง",
            subdistrict_en: "Bua Thong",
            post_code: "31000",
          },
          {
            subdistrict_th: "ชุมเห็ด",
            subdistrict_en: "Chum Het",
            post_code: "31000",
          },
          {
            subdistrict_th: "หลักเขต",
            subdistrict_en: "Lak Khet",
            post_code: "31000",
          },
          {
            subdistrict_th: "สะแกซำ",
            subdistrict_en: "Sakae Sam",
            post_code: "31000",
          },
          {
            subdistrict_th: "กลันทา",
            subdistrict_en: "Kalantha",
            post_code: "31000",
          },
          {
            subdistrict_th: "กระสัง",
            subdistrict_en: "Krasang",
            post_code: "31000",
          },
          {
            subdistrict_th: "เมืองฝาง",
            subdistrict_en: "Mueang Fang",
            post_code: "31000",
          },
        ],
      },
      {
        district_th: "อำเภอคูเมือง",
        district_en: "Amphoe Khu Mueang",
        children: [
          {
            subdistrict_th: "คูเมือง",
            subdistrict_en: "Khu Mueang",
            post_code: "31190",
          },
          {
            subdistrict_th: "ปะเคียบ",
            subdistrict_en: "Pakhiap",
            post_code: "31190",
          },
          {
            subdistrict_th: "บ้านแพ",
            subdistrict_en: "Ban Phae",
            post_code: "31190",
          },
          {
            subdistrict_th: "พรสำราญ",
            subdistrict_en: "Phon Samran",
            post_code: "31190",
          },
          {
            subdistrict_th: "หินเหล็กไฟ",
            subdistrict_en: "Hin Lek Fai",
            post_code: "31190",
          },
          {
            subdistrict_th: "ตูมใหญ่",
            subdistrict_en: "Tum Yai",
            post_code: "31190",
          },
          {
            subdistrict_th: "หนองขมาร",
            subdistrict_en: "Nong Khaman",
            post_code: "31190",
          },
        ],
      },
      {
        district_th: "อำเภอกระสัง",
        district_en: "Amphoe Krasang",
        children: [
          {
            subdistrict_th: "กระสัง",
            subdistrict_en: "Krasang",
            post_code: "31160",
          },
          {
            subdistrict_th: "ลำดวน",
            subdistrict_en: "Lamduan",
            post_code: "31160",
          },
          {
            subdistrict_th: "สองชั้น",
            subdistrict_en: "Song Chan",
            post_code: "31160",
          },
          {
            subdistrict_th: "สูงเนิน",
            subdistrict_en: "Sung Noen",
            post_code: "31160",
          },
          {
            subdistrict_th: "หนองเต็ง",
            subdistrict_en: "Nong Teng",
            post_code: "31160",
          },
          {
            subdistrict_th: "เมืองไผ่",
            subdistrict_en: "Mueang Phai",
            post_code: "31160",
          },
          {
            subdistrict_th: "ชุมแสง",
            subdistrict_en: "Chum Saeng",
            post_code: "31160",
          },
          {
            subdistrict_th: "บ้านปรือ",
            subdistrict_en: "Ban Prue",
            post_code: "31160",
          },
          {
            subdistrict_th: "ห้วยสำราญ",
            subdistrict_en: "Huai Samran",
            post_code: "31160",
          },
          {
            subdistrict_th: "กันทรารมย์",
            subdistrict_en: "Kanthararom",
            post_code: "31160",
          },
          {
            subdistrict_th: "ศรีภูมิ",
            subdistrict_en: "Si Phum",
            post_code: "31160",
          },
        ],
      },
      {
        district_th: "อำเภอนางรอง",
        district_en: "Amphoe Nang Rong",
        children: [
          {
            subdistrict_th: "นางรอง",
            subdistrict_en: "Nang Rong",
            post_code: "31110",
          },
          {
            subdistrict_th: "สะเดา",
            subdistrict_en: "Sadao",
            post_code: "31110",
          },
          {
            subdistrict_th: "ชุมแสง",
            subdistrict_en: "Chum Saeng",
            post_code: "31110",
          },
          {
            subdistrict_th: "หนองโบสถ์",
            subdistrict_en: "Nong Bot",
            post_code: "31110",
          },
          {
            subdistrict_th: "หนองกง",
            subdistrict_en: "Nong Kong",
            post_code: "31110",
          },
          {
            subdistrict_th: "ถนนหัก",
            subdistrict_en: "Thanon Hak",
            post_code: "31110",
          },
          {
            subdistrict_th: "หนองไทร",
            subdistrict_en: "Nong Sai",
            post_code: "31110",
          },
          {
            subdistrict_th: "ก้านเหลือง",
            subdistrict_en: "Kan Lueang",
            post_code: "31110",
          },
          {
            subdistrict_th: "บ้านสิงห์",
            subdistrict_en: "Ban Sing",
            post_code: "31110",
          },
          {
            subdistrict_th: "ลำไทรโยง",
            subdistrict_en: "Lam Sai Yong",
            post_code: "31110",
          },
          {
            subdistrict_th: "ทรัพย์พระยา",
            subdistrict_en: "Sap Phraya",
            post_code: "31110",
          },
          {
            subdistrict_th: "หนองยายพิมพ์",
            subdistrict_en: "Nong Yai Phim",
            post_code: "31110",
          },
          {
            subdistrict_th: "หัวถนน",
            subdistrict_en: "Hua Thanon",
            post_code: "31110",
          },
          {
            subdistrict_th: "ทุ่งแสงทอง",
            subdistrict_en: "Thung Saeng Thong",
            post_code: "31110",
          },
          {
            subdistrict_th: "หนองโสน",
            subdistrict_en: "Nong Sano",
            post_code: "31110",
          },
        ],
      },
      {
        district_th: "อำเภอหนองกี่",
        district_en: "Amphoe Nong Ki",
        children: [
          {
            subdistrict_th: "หนองกี่",
            subdistrict_en: "Nong Ki",
            post_code: "31210",
          },
          {
            subdistrict_th: "เย้ยปราสาท",
            subdistrict_en: "Yoei Prasat",
            post_code: "31210",
          },
          {
            subdistrict_th: "เมืองไผ่",
            subdistrict_en: "Mueang Phai",
            post_code: "31210",
          },
          {
            subdistrict_th: "ดอนอะราง",
            subdistrict_en: "Don Arang",
            post_code: "31210",
          },
          {
            subdistrict_th: "โคกสว่าง",
            subdistrict_en: "Khok Sawang",
            post_code: "31210",
          },
          {
            subdistrict_th: "ทุ่งกระตาดพัฒนา",
            subdistrict_en: "Thung Kratat Phatthana",
            post_code: "31210",
          },
          {
            subdistrict_th: "ทุ่งกระเต็น",
            subdistrict_en: "Thung Kraten",
            post_code: "31210",
          },
          {
            subdistrict_th: "ท่าโพธิ์ชัย",
            subdistrict_en: "Tha Pho Chai",
            post_code: "31210",
          },
          {
            subdistrict_th: "โคกสูง",
            subdistrict_en: "Khok Sung",
            post_code: "31210",
          },
          {
            subdistrict_th: "บุกระสัง",
            subdistrict_en: "Bu Krasang",
            post_code: "31210",
          },
        ],
      },
      {
        district_th: "อำเภอละหานทราย",
        district_en: "Amphoe Lahan Sai",
        children: [
          {
            subdistrict_th: "ละหานทราย",
            subdistrict_en: "Lahan Sai",
            post_code: "31170",
          },
          {
            subdistrict_th: "ตาจง",
            subdistrict_en: "Ta Chong",
            post_code: "31170",
          },
          {
            subdistrict_th: "สำโรงใหม่",
            subdistrict_en: "Samrong Mai",
            post_code: "31170",
          },
          {
            subdistrict_th: "หนองแวง",
            subdistrict_en: "Nong Waeng",
            post_code: "31170",
          },
          {
            subdistrict_th: "หนองตะครอง",
            subdistrict_en: "Nong Takhrong",
            post_code: "31170",
          },
          {
            subdistrict_th: "โคกว่าน",
            subdistrict_en: "Khok Wan",
            post_code: "31170",
          },
        ],
      },
      {
        district_th: "อำเภอประโคนชัย",
        district_en: "Amphoe Prakhon Chai",
        children: [
          {
            subdistrict_th: "ประโคนชัย",
            subdistrict_en: "Prakhon Chai",
            post_code: "31140",
          },
          {
            subdistrict_th: "แสลงโทน",
            subdistrict_en: "Salaeng Thon",
            post_code: "31140",
          },
          {
            subdistrict_th: "บ้านไทร",
            subdistrict_en: "Ban Sai",
            post_code: "31140",
          },
          {
            subdistrict_th: "ละเวี้ย",
            subdistrict_en: "Lawia",
            post_code: "31140",
          },
          {
            subdistrict_th: "จรเข้มาก",
            subdistrict_en: "Chorakhe Mak",
            post_code: "31140",
          },
          {
            subdistrict_th: "ปังกู",
            subdistrict_en: "Pang Ku",
            post_code: "31140",
          },
          {
            subdistrict_th: "โคกย่าง",
            subdistrict_en: "Khok Yang",
            post_code: "31140",
          },
          {
            subdistrict_th: "โคกม้า",
            subdistrict_en: "Khok Ma",
            post_code: "31140",
          },
          {
            subdistrict_th: "ไพศาล",
            subdistrict_en: "Phaisan",
            post_code: "31140",
          },
          {
            subdistrict_th: "ตะโกตาพิ",
            subdistrict_en: "Tako Taphi",
            post_code: "31140",
          },
          {
            subdistrict_th: "เขาคอก",
            subdistrict_en: "Khao Khok",
            post_code: "31140",
          },
          {
            subdistrict_th: "หนองบอน",
            subdistrict_en: "Bong Bon",
            post_code: "31140",
          },
          {
            subdistrict_th: "โคกมะขาม",
            subdistrict_en: "Khok Makham",
            post_code: "31140",
          },
          {
            subdistrict_th: "โคกตูม",
            subdistrict_en: "Khok Tum",
            post_code: "31140",
          },
          {
            subdistrict_th: "ประทัดบุ",
            subdistrict_en: "Prathat Bu",
            post_code: "31140",
          },
          {
            subdistrict_th: "สี่เหลี่ยม",
            subdistrict_en: "Si Liam",
            post_code: "31140",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านกรวด",
        district_en: "Amphoe Ban Kruat",
        children: [
          {
            subdistrict_th: "บ้านกรวด",
            subdistrict_en: "Ban Kruat",
            post_code: "31180",
          },
          {
            subdistrict_th: "โนนเจริญ",
            subdistrict_en: "Non Charoen",
            post_code: "31180",
          },
          {
            subdistrict_th: "หนองไม้งาม",
            subdistrict_en: "Nong Mai Ngam",
            post_code: "31180",
          },
          {
            subdistrict_th: "ปราสาท",
            subdistrict_en: "Prasat",
            post_code: "31180",
          },
          {
            subdistrict_th: "สายตะกู",
            subdistrict_en: "Sai Taku",
            post_code: "31180",
          },
          {
            subdistrict_th: "หินลาด",
            subdistrict_en: "Hin Lat",
            post_code: "31180",
          },
          {
            subdistrict_th: "บึงเจริญ",
            subdistrict_en: "Bueng Charoen",
            post_code: "31180",
          },
          {
            subdistrict_th: "จันทบเพชร",
            subdistrict_en: "Chanthop Phet",
            post_code: "31180",
          },
          {
            subdistrict_th: "เขาดินเหนือ",
            subdistrict_en: "Khao Din Nuea",
            post_code: "31180",
          },
        ],
      },
      {
        district_th: "อำเภอพุทไธสง",
        district_en: "Amphoe Phutthaisong",
        children: [
          {
            subdistrict_th: "พุทไธสง",
            subdistrict_en: "Phutthaisong",
            post_code: "31120",
          },
          {
            subdistrict_th: "มะเฟือง",
            subdistrict_en: "Mafueang",
            post_code: "31120",
          },
          {
            subdistrict_th: "บ้านจาน",
            subdistrict_en: "Ban Chan",
            post_code: "31120",
          },
          {
            subdistrict_th: "บ้านเป้า",
            subdistrict_en: "Ban Pao",
            post_code: "31120",
          },
          {
            subdistrict_th: "บ้านแวง",
            subdistrict_en: "Ban Waeng",
            post_code: "31120",
          },
          {
            subdistrict_th: "บ้านยาง",
            subdistrict_en: "Ban Yang",
            post_code: "31120",
          },
          {
            subdistrict_th: "หายโศก",
            subdistrict_en: "Hai Sok",
            post_code: "31120",
          },
        ],
      },
      {
        district_th: "อำเภอลำปลายมาศ",
        district_en: "Amphoe Lam Plai Mat",
        children: [
          {
            subdistrict_th: "ลำปลายมาศ",
            subdistrict_en: "Lam Plai Mat",
            post_code: "31130",
          },
          {
            subdistrict_th: "หนองคู",
            subdistrict_en: "Nong Khu",
            post_code: "31130",
          },
          {
            subdistrict_th: "แสลงพัน",
            subdistrict_en: "Salaeng Phan",
            post_code: "31130",
          },
          {
            subdistrict_th: "ทะเมนชัย",
            subdistrict_en: "Thamen Chai",
            post_code: "31130",
          },
          {
            subdistrict_th: "ตลาดโพธิ์",
            subdistrict_en: "Talat Pho",
            post_code: "31130",
          },
          {
            subdistrict_th: "หนองกะทิง",
            subdistrict_en: "Nong Kathing",
            post_code: "31130",
          },
          {
            subdistrict_th: "โคกกลาง",
            subdistrict_en: "Khok Klang",
            post_code: "31130",
          },
          {
            subdistrict_th: "โคกสะอาด",
            subdistrict_en: "Khok Sa-at",
            post_code: "31130",
          },
          {
            subdistrict_th: "เมืองแฝก",
            subdistrict_en: "Mueang Faek",
            post_code: "31130",
          },
          {
            subdistrict_th: "บ้านยาง",
            subdistrict_en: "Ban Yang",
            post_code: "31130",
          },
          {
            subdistrict_th: "ผไทรินทร์",
            subdistrict_en: "Phathairin",
            post_code: "31130",
          },
          {
            subdistrict_th: "โคกล่าม",
            subdistrict_en: "Khok Lam",
            post_code: "31130",
          },
          {
            subdistrict_th: "หินโคน",
            subdistrict_en: "Hin Khon",
            post_code: "31130",
          },
          {
            subdistrict_th: "หนองบัวโคก",
            subdistrict_en: "Nong Bua Khok",
            post_code: "31130",
          },
          {
            subdistrict_th: "บุโพธิ์",
            subdistrict_en: "Bu Pho",
            post_code: "31130",
          },
          {
            subdistrict_th: "หนองโดน",
            subdistrict_en: "Nong Don",
            post_code: "31130",
          },
        ],
      },
      {
        district_th: "อำเภอสตึก",
        district_en: "Amphoe Satuek",
        children: [
          {
            subdistrict_th: "สตึก",
            subdistrict_en: "Satuek",
            post_code: "31150",
          },
          {
            subdistrict_th: "นิคม",
            subdistrict_en: "Nikhom",
            post_code: "31150",
          },
          {
            subdistrict_th: "ทุ่งวัง",
            subdistrict_en: "Thung Wang",
            post_code: "31150",
          },
          {
            subdistrict_th: "เมืองแก",
            subdistrict_en: "Mueang Kae",
            post_code: "31150",
          },
          {
            subdistrict_th: "หนองใหญ่",
            subdistrict_en: "Nong Yai",
            post_code: "31150",
          },
          {
            subdistrict_th: "ร่อนทอง",
            subdistrict_en: "Ron Thong",
            post_code: "31150",
          },
          {
            subdistrict_th: "ดอนมนต์",
            subdistrict_en: "Don Mon",
            post_code: "31150",
          },
          {
            subdistrict_th: "ชุมแสง",
            subdistrict_en: "Chum Saeng",
            post_code: "31150",
          },
          {
            subdistrict_th: "ท่าม่วง",
            subdistrict_en: "Tha Muang",
            post_code: "31150",
          },
          {
            subdistrict_th: "สะแก",
            subdistrict_en: "Sakae",
            post_code: "31150",
          },
          {
            subdistrict_th: "สนามชัย",
            subdistrict_en: "Sanam Chai",
            post_code: "31150",
          },
          {
            subdistrict_th: "กระสัง",
            subdistrict_en: "Krasang",
            post_code: "31150",
          },
        ],
      },
      {
        district_th: "อำเภอปะคำ",
        district_en: "Amphoe Pakham",
        children: [
          {
            subdistrict_th: "ปะคำ",
            subdistrict_en: "Pakham",
            post_code: "31220",
          },
          {
            subdistrict_th: "ไทยเจริญ",
            subdistrict_en: "Thai Charoen",
            post_code: "31220",
          },
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "31220",
          },
          {
            subdistrict_th: "โคกมะม่วง",
            subdistrict_en: "Khok Mamuang",
            post_code: "31220",
          },
          {
            subdistrict_th: "หูทำนบ",
            subdistrict_en: "Hu Thamnop",
            post_code: "31220",
          },
        ],
      },
      {
        district_th: "อำเภอนาโพธิ์",
        district_en: "Amphoe Na Pho",
        children: [
          {
            subdistrict_th: "นาโพธิ์",
            subdistrict_en: "Na Pho",
            post_code: "31230",
          },
          {
            subdistrict_th: "บ้านคู",
            subdistrict_en: "Ban Khu",
            post_code: "31230",
          },
          {
            subdistrict_th: "บ้านดู่",
            subdistrict_en: "Ban Du",
            post_code: "31230",
          },
          {
            subdistrict_th: "ดอนกอก",
            subdistrict_en: "Don Kok",
            post_code: "31230",
          },
          {
            subdistrict_th: "ศรีสว่าง",
            subdistrict_en: "Si Sawang",
            post_code: "31230",
          },
        ],
      },
      {
        district_th: "อำเภอหนองหงส์",
        district_en: "Amphoe Nong Hong",
        children: [
          {
            subdistrict_th: "สระแก้ว",
            subdistrict_en: "Sa Kaeo",
            post_code: "31240",
          },
          {
            subdistrict_th: "ห้วยหิน",
            subdistrict_en: "Huai Hin",
            post_code: "31240",
          },
          {
            subdistrict_th: "ไทยสามัคคี",
            subdistrict_en: "Thai Samakkhi",
            post_code: "31240",
          },
          {
            subdistrict_th: "หนองชัยศรี",
            subdistrict_en: "Nong Chai Si",
            post_code: "31240",
          },
          {
            subdistrict_th: "เสาเดียว",
            subdistrict_en: "Sao Diao",
            post_code: "31240",
          },
          {
            subdistrict_th: "เมืองฝ้าย",
            subdistrict_en: "Mueang Fai",
            post_code: "31240",
          },
          {
            subdistrict_th: "สระทอง",
            subdistrict_en: "Sa Thong",
            post_code: "31240",
          },
        ],
      },
      {
        district_th: "อำเภอพลับพลาชัย",
        district_en: "Amphoe Phlapphla Chai",
        children: [
          {
            subdistrict_th: "จันดุม",
            subdistrict_en: "Chan Dum",
            post_code: "31250",
          },
          {
            subdistrict_th: "โคกขมิ้น",
            subdistrict_en: "Khok Khamin",
            post_code: "31250",
          },
          {
            subdistrict_th: "ป่าชัน",
            subdistrict_en: "Pa Chan",
            post_code: "31250",
          },
          {
            subdistrict_th: "สะเดา",
            subdistrict_en: "Sadao",
            post_code: "31250",
          },
          {
            subdistrict_th: "สำโรง",
            subdistrict_en: "Samrong",
            post_code: "31250",
          },
        ],
      },
      {
        district_th: "อำเภอห้วยราช",
        district_en: "Amphoe Huai Rat",
        children: [
          {
            subdistrict_th: "ห้วยราช",
            subdistrict_en: "Huai Rat",
            post_code: "31000",
          },
          {
            subdistrict_th: "สามแวง",
            subdistrict_en: "Sam Waeng",
            post_code: "31000",
          },
          {
            subdistrict_th: "ตาเสา",
            subdistrict_en: "Ta Sao",
            post_code: "31000",
          },
          {
            subdistrict_th: "บ้านตะโก",
            subdistrict_en: "Ban Tako",
            post_code: "31000",
          },
          {
            subdistrict_th: "สนวน",
            subdistrict_en: "Sanuan",
            post_code: "31000",
          },
          {
            subdistrict_th: "โคกเหล็ก",
            subdistrict_en: "Khok Lek",
            post_code: "31000",
          },
          {
            subdistrict_th: "เมืองโพธิ์",
            subdistrict_en: "Mueang Pho",
            post_code: "31000",
          },
          {
            subdistrict_th: "ห้วยราชา",
            subdistrict_en: "Huai Racha",
            post_code: "31000",
          },
        ],
      },
      {
        district_th: "อำเภอโนนสุวรรณ",
        district_en: "Amphoe Non Suwan",
        children: [
          {
            subdistrict_th: "โนนสุวรรณ",
            subdistrict_en: "Non Suwan",
            post_code: "31110",
          },
          {
            subdistrict_th: "ทุ่งจังหัน",
            subdistrict_en: "Thung Changhan",
            post_code: "31110",
          },
          {
            subdistrict_th: "โกรกแก้ว",
            subdistrict_en: "Krok Kaeo",
            post_code: "31110",
          },
          {
            subdistrict_th: "ดงอีจาน",
            subdistrict_en: "Dong I Chan",
            post_code: "31110",
          },
        ],
      },
      {
        district_th: "อำเภอชำนิ",
        district_en: "Amphoe Chamni",
        children: [
          {
            subdistrict_th: "ชำนิ",
            subdistrict_en: "Chamni",
            post_code: "31110",
          },
          {
            subdistrict_th: "หนองปล่อง",
            subdistrict_en: "Nong Plong",
            post_code: "31110",
          },
          {
            subdistrict_th: "เมืองยาง",
            subdistrict_en: "Mueang Yang",
            post_code: "31110",
          },
          {
            subdistrict_th: "ช่อผกา",
            subdistrict_en: "Cho Phaka",
            post_code: "31110",
          },
          {
            subdistrict_th: "ละลวด",
            subdistrict_en: "Laluat",
            post_code: "31110",
          },
          {
            subdistrict_th: "โคกสนวน",
            subdistrict_en: "Khok Sanuan",
            post_code: "31110",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านใหม่ไชยพจน์",
        district_en: "Amphoe Ban Mai Chaiyaphot",
        children: [
          {
            subdistrict_th: "หนองแวง",
            subdistrict_en: "Nong Waeng",
            post_code: "31120",
          },
          {
            subdistrict_th: "ทองหลาง",
            subdistrict_en: "Thonglang",
            post_code: "31120",
          },
          {
            subdistrict_th: "แดงใหญ่",
            subdistrict_en: "Daeng Yai",
            post_code: "31120",
          },
          {
            subdistrict_th: "กู่สวนแตง",
            subdistrict_en: "Ku Suan Taeng",
            post_code: "31120",
          },
          {
            subdistrict_th: "หนองเยือง",
            subdistrict_en: "Nong Yueang",
            post_code: "31120",
          },
        ],
      },
      {
        district_th: "อำเภอโนนดินแดง",
        district_en: "Amphoe Non Din Daeng",
        children: [
          {
            subdistrict_th: "โนนดินแดง",
            subdistrict_en: "Non Din Daeng",
            post_code: "31260",
          },
          {
            subdistrict_th: "ส้มป่อย",
            subdistrict_en: "Som Poi",
            post_code: "31260",
          },
          {
            subdistrict_th: "ลำนางรอง",
            subdistrict_en: "Lam Nang Rong",
            post_code: "31260",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านด่าน",
        district_en: "Amphoe Ban Dan",
        children: [
          {
            subdistrict_th: "บ้านด่าน",
            subdistrict_en: "Ban Dan",
            post_code: "31000",
          },
          {
            subdistrict_th: "ปราสาท",
            subdistrict_en: "Prasat",
            post_code: "31000",
          },
          {
            subdistrict_th: "วังเหนือ",
            subdistrict_en: "Wang Nuea",
            post_code: "31000",
          },
          {
            subdistrict_th: "โนนขวาง",
            subdistrict_en: "Non Khwang",
            post_code: "31000",
          },
        ],
      },
      {
        district_th: "อำเภอแคนดง",
        district_en: "Amphoe Khaen Dong",
        children: [
          {
            subdistrict_th: "แคนดง",
            subdistrict_en: "Khaen Dong",
            post_code: "31150",
          },
          {
            subdistrict_th: "ดงพลอง",
            subdistrict_en: "Dong Phlong",
            post_code: "31150",
          },
          {
            subdistrict_th: "สระบัว",
            subdistrict_en: "Sa Bua",
            post_code: "31150",
          },
          {
            subdistrict_th: "หัวฝาย",
            subdistrict_en: "Hua Fai",
            post_code: "31150",
          },
        ],
      },
      {
        district_th: "อำเภอเฉลิมพระเกียรติ",
        district_en: "Amphoe Chaloem Phra Kiat",
        children: [
          {
            subdistrict_th: "เขาดินพัฒนา",
            subdistrict_en: "Khao Din Phatthana",
            post_code: "18000",
          },
          {
            subdistrict_th: "บ้านแก้ง",
            subdistrict_en: "Ban Kaeng",
            post_code: "18000",
          },
          {
            subdistrict_th: "ผึ้งรวง",
            subdistrict_en: "Phueng Ruang",
            post_code: "18000",
          },
          {
            subdistrict_th: "พุแค",
            subdistrict_en: "Phu Khae",
            post_code: "18240",
          },
          {
            subdistrict_th: "ห้วยบง",
            subdistrict_en: "Huai Bong",
            post_code: "18000",
          },
          {
            subdistrict_th: "หน้าพระลาน",
            subdistrict_en: "Na Phra Lan",
            post_code: "18240",
          },
          {
            subdistrict_th: "ช้างทอง",
            subdistrict_en: "Chang Thong",
            post_code: "30230",
          },
          {
            subdistrict_th: "ท่าช้าง",
            subdistrict_en: "Tha Chang",
            post_code: "30230",
          },
          {
            subdistrict_th: "พระพุทธ",
            subdistrict_en: "Phra Phut",
            post_code: "30230",
          },
          {
            subdistrict_th: "หนองงูเหลือม",
            subdistrict_en: "Nong Ngu Lueam",
            post_code: "30000",
          },
          {
            subdistrict_th: "หนองยาง",
            subdistrict_en: "Nong Yang",
            post_code: "30230",
          },
          {
            subdistrict_th: "เจริญสุข",
            subdistrict_en: "Charoen Suk",
            post_code: "31110",
          },
          {
            subdistrict_th: "ตาเป๊ก",
            subdistrict_en: "Ta Pek",
            post_code: "31110",
          },
          {
            subdistrict_th: "อีสานเขต",
            subdistrict_en: "Isan Khet",
            post_code: "31110",
          },
          {
            subdistrict_th: "ถาวร",
            subdistrict_en: "Thawon",
            post_code: "31170",
          },
          {
            subdistrict_th: "ยายแย้มวัฒนา",
            subdistrict_en: "Yai Yaem Watthana",
            post_code: "31170",
          },
          {
            subdistrict_th: "ห้วยโก๋น",
            subdistrict_en: "Huai Kon",
            post_code: "55130",
          },
          {
            subdistrict_th: "ขุนน่าน",
            subdistrict_en: "Khun Nan",
            post_code: "55220",
          },
          {
            subdistrict_th: "เชียรเขา",
            subdistrict_en: "Chian Khao",
            post_code: "80190",
          },
          {
            subdistrict_th: "ดอนตรอ",
            subdistrict_en: "Don Tro",
            post_code: "80290",
          },
          {
            subdistrict_th: "สวนหลวง",
            subdistrict_en: "Suan Luang",
            post_code: "80190",
          },
          {
            subdistrict_th: "ทางพูน",
            subdistrict_en: "Thang Phun",
            post_code: "80290",
          },
        ],
      },
    ],
  },
  {
    province_th: "สุรินทร์",
    province_en: "Surin",
    children: [
      {
        district_th: "อำเภอเมืองสุรินทร์",
        district_en: "Amphoe Mueang Surin",
        children: [
          {
            subdistrict_th: "ในเมือง",
            subdistrict_en: "Nai Mueang",
            post_code: "32000",
          },
          {
            subdistrict_th: "ตั้งใจ",
            subdistrict_en: "Tang Chai",
            post_code: "32000",
          },
          {
            subdistrict_th: "เพี้ยราม",
            subdistrict_en: "Phia Ram",
            post_code: "32000",
          },
          {
            subdistrict_th: "นาดี",
            subdistrict_en: "Na Di",
            post_code: "32000",
          },
          {
            subdistrict_th: "ท่าสว่าง",
            subdistrict_en: "Tha Sawang",
            post_code: "32000",
          },
          {
            subdistrict_th: "สลักได",
            subdistrict_en: "Salakdai",
            post_code: "32000",
          },
          {
            subdistrict_th: "ตาอ็อง",
            subdistrict_en: "Ta Ong",
            post_code: "32000",
          },
          {
            subdistrict_th: "สำโรง",
            subdistrict_en: "Samrong",
            post_code: "32000",
          },
          {
            subdistrict_th: "แกใหญ่",
            subdistrict_en: "Kae Yai",
            post_code: "32000",
          },
          {
            subdistrict_th: "นอกเมือง",
            subdistrict_en: "Nok Mueang",
            post_code: "32000",
          },
          {
            subdistrict_th: "คอโค",
            subdistrict_en: "Kho Kho",
            post_code: "32000",
          },
          {
            subdistrict_th: "สวาย",
            subdistrict_en: "Sawai",
            post_code: "32000",
          },
          {
            subdistrict_th: "เฉนียง",
            subdistrict_en: "Chaniang",
            post_code: "32000",
          },
          {
            subdistrict_th: "เทนมีย์",
            subdistrict_en: "Thenmi",
            post_code: "32000",
          },
          {
            subdistrict_th: "นาบัว",
            subdistrict_en: "Na Bua",
            post_code: "32000",
          },
          {
            subdistrict_th: "เมืองที",
            subdistrict_en: "Mueang Thi",
            post_code: "32000",
          },
          {
            subdistrict_th: "ราม",
            subdistrict_en: "Ram",
            post_code: "32000",
          },
          {
            subdistrict_th: "บุฤาษี",
            subdistrict_en: "Bu Ruesi",
            post_code: "32000",
          },
          {
            subdistrict_th: "ตระแสง",
            subdistrict_en: "Trasaeng",
            post_code: "32000",
          },
          {
            subdistrict_th: "แสลงพันธ์",
            subdistrict_en: "Salaeng Phan",
            post_code: "32000",
          },
          {
            subdistrict_th: "กาเกาะ",
            subdistrict_en: "Ka Ko",
            post_code: "32000",
          },
        ],
      },
      {
        district_th: "อำเภอชุมพลบุรี",
        district_en: "Amphoe Chumphon Buri",
        children: [
          {
            subdistrict_th: "ชุมพลบุรี",
            subdistrict_en: "Chumphon Buri",
            post_code: "32190",
          },
          {
            subdistrict_th: "นาหนองไผ่",
            subdistrict_en: "Na Nong Phai",
            post_code: "32190",
          },
          {
            subdistrict_th: "ไพรขลา",
            subdistrict_en: "Phrai Khla",
            post_code: "32190",
          },
          {
            subdistrict_th: "ศรีณรงค์",
            subdistrict_en: "Si Narong",
            post_code: "32190",
          },
          {
            subdistrict_th: "ยะวึก",
            subdistrict_en: "Yawuek",
            post_code: "32190",
          },
          {
            subdistrict_th: "เมืองบัว",
            subdistrict_en: "Mueang Bua",
            post_code: "32190",
          },
          {
            subdistrict_th: "สระขุด",
            subdistrict_en: "Sa Khut",
            post_code: "32190",
          },
          {
            subdistrict_th: "กระเบื้อง",
            subdistrict_en: "Krabueang",
            post_code: "32190",
          },
          {
            subdistrict_th: "หนองเรือ",
            subdistrict_en: "Nong Ruea",
            post_code: "32190",
          },
        ],
      },
      {
        district_th: "อำเภอท่าตูม",
        district_en: "Amphoe Tha Tum",
        children: [
          {
            subdistrict_th: "ท่าตูม",
            subdistrict_en: "Tha Tum",
            post_code: "32120",
          },
          {
            subdistrict_th: "กระโพ",
            subdistrict_en: "Krapho",
            post_code: "32120",
          },
          {
            subdistrict_th: "พรมเทพ",
            subdistrict_en: "Phrom Thep",
            post_code: "32120",
          },
          {
            subdistrict_th: "โพนครก",
            subdistrict_en: "Phon Khrok",
            post_code: "32120",
          },
          {
            subdistrict_th: "เมืองแก",
            subdistrict_en: "Mueang Kae",
            post_code: "32120",
          },
          {
            subdistrict_th: "บะ",
            subdistrict_en: "Ba",
            post_code: "32120",
          },
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "32120",
          },
          {
            subdistrict_th: "บัวโคก",
            subdistrict_en: "Bua Khok",
            post_code: "32120",
          },
          {
            subdistrict_th: "หนองเมธี",
            subdistrict_en: "Nong Methi",
            post_code: "32120",
          },
          {
            subdistrict_th: "ทุ่งกุลา",
            subdistrict_en: "Thung Kula",
            post_code: "32120",
          },
        ],
      },
      {
        district_th: "อำเภอจอมพระ",
        district_en: "Amphoe Chom Phra",
        children: [
          {
            subdistrict_th: "จอมพระ",
            subdistrict_en: "Chom Phra",
            post_code: "32180",
          },
          {
            subdistrict_th: "เมืองลีง",
            subdistrict_en: "Mueang Ling",
            post_code: "32180",
          },
          {
            subdistrict_th: "กระหาด",
            subdistrict_en: "Krahat",
            post_code: "32180",
          },
          {
            subdistrict_th: "บุแกรง",
            subdistrict_en: "Bu Kraeng",
            post_code: "32180",
          },
          {
            subdistrict_th: "หนองสนิท",
            subdistrict_en: "Nong Sanit",
            post_code: "32180",
          },
          {
            subdistrict_th: "บ้านผือ",
            subdistrict_en: "Ban Phue",
            post_code: "32180",
          },
          {
            subdistrict_th: "ลุ่มระวี",
            subdistrict_en: "Lum Rawi",
            post_code: "32180",
          },
          {
            subdistrict_th: "ชุมแสง",
            subdistrict_en: "Chum Saeng",
            post_code: "32180",
          },
          {
            subdistrict_th: "เป็นสุข",
            subdistrict_en: "Pen Suk",
            post_code: "32180",
          },
        ],
      },
      {
        district_th: "อำเภอปราสาท",
        district_en: "Amphoe Prasat",
        children: [
          {
            subdistrict_th: "กังแอน",
            subdistrict_en: "Kang Aen",
            post_code: "32140",
          },
          {
            subdistrict_th: "ทมอ",
            subdistrict_en: "Thamo",
            post_code: "32140",
          },
          {
            subdistrict_th: "ไพล",
            subdistrict_en: "Phlai",
            post_code: "32140",
          },
          {
            subdistrict_th: "ปรือ",
            subdistrict_en: "Prue",
            post_code: "32140",
          },
          {
            subdistrict_th: "ทุ่งมน",
            subdistrict_en: "Thung Mon",
            post_code: "32140",
          },
          {
            subdistrict_th: "ตาเบา",
            subdistrict_en: "Ta Bao",
            post_code: "32140",
          },
          {
            subdistrict_th: "หนองใหญ่",
            subdistrict_en: "Nong Yai",
            post_code: "32140",
          },
          {
            subdistrict_th: "โคกยาง",
            subdistrict_en: "Khok Yang",
            post_code: "32140",
          },
          {
            subdistrict_th: "โคกสะอาด",
            subdistrict_en: "Khok Sa-at",
            post_code: "32140",
          },
          {
            subdistrict_th: "บ้านไทร",
            subdistrict_en: "Ban Sai",
            post_code: "32140",
          },
          {
            subdistrict_th: "โชคนาสาม",
            subdistrict_en: "Chok Na Sam",
            post_code: "32140",
          },
          {
            subdistrict_th: "เชื้อเพลิง",
            subdistrict_en: "Chuea Phloeng",
            post_code: "32140",
          },
          {
            subdistrict_th: "ปราสาททนง",
            subdistrict_en: "Prasat Thanong",
            post_code: "32140",
          },
          {
            subdistrict_th: "ตานี",
            subdistrict_en: "Tani",
            post_code: "32140",
          },
          {
            subdistrict_th: "บ้านพลวง",
            subdistrict_en: "Ban Phluang",
            post_code: "32140",
          },
          {
            subdistrict_th: "กันตวจระมวล",
            subdistrict_en: "Kantuat Ramuan",
            post_code: "32140",
          },
          {
            subdistrict_th: "สมุด",
            subdistrict_en: "Samut",
            post_code: "32140",
          },
          {
            subdistrict_th: "ประทัดบุ",
            subdistrict_en: "Prathat Bu",
            post_code: "32140",
          },
        ],
      },
      {
        district_th: "อำเภอกาบเชิง",
        district_en: "Amphoe Kap Choeng",
        children: [
          {
            subdistrict_th: "กาบเชิง",
            subdistrict_en: "Kap Choeng",
            post_code: "32210",
          },
          {
            subdistrict_th: "คูตัน",
            subdistrict_en: "Khu Tan",
            post_code: "32210",
          },
          {
            subdistrict_th: "ด่าน",
            subdistrict_en: "Dan",
            post_code: "32210",
          },
          {
            subdistrict_th: "แนงมุด",
            subdistrict_en: "Naeng Mut",
            post_code: "32210",
          },
          {
            subdistrict_th: "โคกตะเคียน",
            subdistrict_en: "Khok Takhian",
            post_code: "32210",
          },
          {
            subdistrict_th: "ตะเคียน",
            subdistrict_en: "Takhian",
            post_code: "32210",
          },
        ],
      },
      {
        district_th: "อำเภอรัตนบุรี",
        district_en: "Amphoe Rattanaburi",
        children: [
          {
            subdistrict_th: "รัตนบุรี",
            subdistrict_en: "Rattanaburi",
            post_code: "32130",
          },
          {
            subdistrict_th: "ธาตุ",
            subdistrict_en: "That",
            post_code: "32130",
          },
          {
            subdistrict_th: "แก",
            subdistrict_en: "Kae",
            post_code: "32130",
          },
          {
            subdistrict_th: "ดอนแรด",
            subdistrict_en: "Don Raet",
            post_code: "32130",
          },
          {
            subdistrict_th: "หนองบัวทอง",
            subdistrict_en: "Nong Bua Thong",
            post_code: "32130",
          },
          {
            subdistrict_th: "หนองบัวบาน",
            subdistrict_en: "Nong Bua Ban",
            post_code: "32130",
          },
          {
            subdistrict_th: "ไผ่",
            subdistrict_en: "Phai",
            post_code: "32130",
          },
          {
            subdistrict_th: "เบิด",
            subdistrict_en: "Boet",
            post_code: "32130",
          },
          {
            subdistrict_th: "น้ำเขียว",
            subdistrict_en: "Nam Khiao",
            post_code: "32130",
          },
          {
            subdistrict_th: "กุดขาคีม",
            subdistrict_en: "Kut Kha Khim",
            post_code: "32130",
          },
          {
            subdistrict_th: "ยางสว่าง",
            subdistrict_en: "Yang Sawang",
            post_code: "32130",
          },
          {
            subdistrict_th: "ทับใหญ่",
            subdistrict_en: "Thap Yai",
            post_code: "32130",
          },
        ],
      },
      {
        district_th: "อำเภอสนม",
        district_en: "Amphoe Sanom",
        children: [
          {
            subdistrict_th: "สนม",
            subdistrict_en: "Sanom",
            post_code: "32160",
          },
          {
            subdistrict_th: "โพนโก",
            subdistrict_en: "Phon Ko",
            post_code: "32160",
          },
          {
            subdistrict_th: "หนองระฆัง",
            subdistrict_en: "Nong Rakhang",
            post_code: "32160",
          },
          {
            subdistrict_th: "นานวน",
            subdistrict_en: "Na Nuan",
            post_code: "32160",
          },
          {
            subdistrict_th: "แคน",
            subdistrict_en: "Khaen",
            post_code: "32160",
          },
          {
            subdistrict_th: "หัวงัว",
            subdistrict_en: "Hua Ngua",
            post_code: "32160",
          },
          {
            subdistrict_th: "หนองอียอ",
            subdistrict_en: "Nong I Yo",
            post_code: "32160",
          },
        ],
      },
      {
        district_th: "อำเภอศีขรภูมิ",
        district_en: "Amphoe Sikhoraphum",
        children: [
          {
            subdistrict_th: "ระแงง",
            subdistrict_en: "Ra-ngaeng",
            post_code: "32110",
          },
          {
            subdistrict_th: "ตรึม",
            subdistrict_en: "Truem",
            post_code: "32110",
          },
          {
            subdistrict_th: "จารพัต",
            subdistrict_en: "Charaphat",
            post_code: "32110",
          },
          {
            subdistrict_th: "ยาง",
            subdistrict_en: "Yang",
            post_code: "32110",
          },
          {
            subdistrict_th: "แตล",
            subdistrict_en: "Taen",
            post_code: "32110",
          },
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "32110",
          },
          {
            subdistrict_th: "คาละแมะ",
            subdistrict_en: "Khalamae",
            post_code: "32110",
          },
          {
            subdistrict_th: "หนองเหล็ก",
            subdistrict_en: "Nong Lek",
            post_code: "32110",
          },
          {
            subdistrict_th: "หนองขวาว",
            subdistrict_en: "Nong Khwao",
            post_code: "32110",
          },
          {
            subdistrict_th: "ช่างปี่",
            subdistrict_en: "Chang Pi",
            post_code: "32110",
          },
          {
            subdistrict_th: "กุดหวาย",
            subdistrict_en: "Kut Wai",
            post_code: "32110",
          },
          {
            subdistrict_th: "ขวาวใหญ่",
            subdistrict_en: "Khwao Yai",
            post_code: "32110",
          },
          {
            subdistrict_th: "นารุ่ง",
            subdistrict_en: "Na Rung",
            post_code: "32110",
          },
          {
            subdistrict_th: "ตรมไพร",
            subdistrict_en: "Trom Phrai",
            post_code: "32110",
          },
          {
            subdistrict_th: "ผักไหม",
            subdistrict_en: "Phak Mai",
            post_code: "32110",
          },
        ],
      },
      {
        district_th: "อำเภอสังขะ",
        district_en: "Amphoe Sangkha",
        children: [
          {
            subdistrict_th: "สังขะ",
            subdistrict_en: "Sangkha",
            post_code: "32150",
          },
          {
            subdistrict_th: "ขอนแตก",
            subdistrict_en: "Khon Taek",
            post_code: "32150",
          },
          {
            subdistrict_th: "ดม",
            subdistrict_en: "Dom",
            post_code: "32150",
          },
          {
            subdistrict_th: "พระแก้ว",
            subdistrict_en: "Phra Kaeo",
            post_code: "32150",
          },
          {
            subdistrict_th: "บ้านจารย์",
            subdistrict_en: "Ban Chan",
            post_code: "32150",
          },
          {
            subdistrict_th: "กระเทียม",
            subdistrict_en: "Krathiam",
            post_code: "32150",
          },
          {
            subdistrict_th: "สะกาด",
            subdistrict_en: "Sakat",
            post_code: "32150",
          },
          {
            subdistrict_th: "ตาตุม",
            subdistrict_en: "Ta Tum",
            post_code: "32150",
          },
          {
            subdistrict_th: "ทับทัน",
            subdistrict_en: "Thap Than",
            post_code: "32150",
          },
          {
            subdistrict_th: "ตาคง",
            subdistrict_en: "Ta Khong",
            post_code: "32150",
          },
          {
            subdistrict_th: "บ้านชบ",
            subdistrict_en: "Ban Chop",
            post_code: "32150",
          },
          {
            subdistrict_th: "เทพรักษา",
            subdistrict_en: "Thep Raksa",
            post_code: "32150",
          },
        ],
      },
      {
        district_th: "อำเภอลำดวน",
        district_en: "Amphoe Lamduan",
        children: [
          {
            subdistrict_th: "ลำดวน",
            subdistrict_en: "Lamduan",
            post_code: "32220",
          },
          {
            subdistrict_th: "โชคเหนือ",
            subdistrict_en: "Chok Nuea",
            post_code: "32220",
          },
          {
            subdistrict_th: "อู่โลก",
            subdistrict_en: "U Lok",
            post_code: "32220",
          },
          {
            subdistrict_th: "ตรำดม",
            subdistrict_en: "Tram Dom",
            post_code: "32220",
          },
          {
            subdistrict_th: "ตระเปียงเตีย",
            subdistrict_en: "Tra Priang Tia",
            post_code: "32220",
          },
        ],
      },
      {
        district_th: "อำเภอสำโรงทาบ",
        district_en: "Amphoe Samrong Thap",
        children: [
          {
            subdistrict_th: "สำโรงทาบ",
            subdistrict_en: "Samrong Thap",
            post_code: "32170",
          },
          {
            subdistrict_th: "หนองไผ่ล้อม",
            subdistrict_en: "Nong Phai Lom",
            post_code: "32170",
          },
          {
            subdistrict_th: "กระออม",
            subdistrict_en: "Kra-om",
            post_code: "32170",
          },
          {
            subdistrict_th: "หนองฮะ",
            subdistrict_en: "Nong Ha",
            post_code: "32170",
          },
          {
            subdistrict_th: "ศรีสุข",
            subdistrict_en: "Si Suk",
            post_code: "32170",
          },
          {
            subdistrict_th: "เกาะแก้ว",
            subdistrict_en: "Ko Kaeo",
            post_code: "32170",
          },
          {
            subdistrict_th: "หมื่นศรี",
            subdistrict_en: "Muen Si",
            post_code: "32170",
          },
          {
            subdistrict_th: "เสม็จ",
            subdistrict_en: "Samet",
            post_code: "32170",
          },
          {
            subdistrict_th: "สะโน",
            subdistrict_en: "Sano",
            post_code: "32170",
          },
          {
            subdistrict_th: "ประดู่",
            subdistrict_en: "Pradu",
            post_code: "32170",
          },
        ],
      },
      {
        district_th: "อำเภอบัวเชด",
        district_en: "Amphoe Buachet",
        children: [
          {
            subdistrict_th: "บัวเชด",
            subdistrict_en: "Buachet",
            post_code: "32230",
          },
          {
            subdistrict_th: "สะเดา",
            subdistrict_en: "Sadao",
            post_code: "32230",
          },
          {
            subdistrict_th: "จรัส",
            subdistrict_en: "Charat",
            post_code: "32230",
          },
          {
            subdistrict_th: "ตาวัง",
            subdistrict_en: "Ta Wang",
            post_code: "32230",
          },
          {
            subdistrict_th: "อาโพน",
            subdistrict_en: "A Phon",
            post_code: "32230",
          },
          {
            subdistrict_th: "สำเภาลูน",
            subdistrict_en: "Samphao Lun",
            post_code: "32230",
          },
        ],
      },
      {
        district_th: "อำเภอพนมดงรัก",
        district_en: "Amphoe Phanom Dong Rak",
        children: [
          {
            subdistrict_th: "บักได",
            subdistrict_en: "Bak Dai",
            post_code: "32140",
          },
          {
            subdistrict_th: "โคกกลาง",
            subdistrict_en: "Khok Klang",
            post_code: "32140",
          },
          {
            subdistrict_th: "จีกแดก",
            subdistrict_en: "Chik Daek",
            post_code: "32140",
          },
          {
            subdistrict_th: "ตาเมียง",
            subdistrict_en: "Ta Miang",
            post_code: "32140",
          },
        ],
      },
      {
        district_th: "อำเภอศรีณรงค์",
        district_en: "Amphoe Si Narong",
        children: [
          {
            subdistrict_th: "ณรงค์",
            subdistrict_en: "Narong",
            post_code: "32150",
          },
          {
            subdistrict_th: "แจนแวน",
            subdistrict_en: "Chaenwaen",
            post_code: "32150",
          },
          {
            subdistrict_th: "ตรวจ",
            subdistrict_en: "Truat",
            post_code: "32150",
          },
          {
            subdistrict_th: "หนองแวง",
            subdistrict_en: "Nong Waeng",
            post_code: "32150",
          },
          {
            subdistrict_th: "ศรีสุข",
            subdistrict_en: "Si Suk",
            post_code: "32150",
          },
        ],
      },
      {
        district_th: "อำเภอเขวาสินรินทร์",
        district_en: "Amphoe Khwao Sinrin",
        children: [
          {
            subdistrict_th: "เขวาสินรินทร์",
            subdistrict_en: "Khwao Sinrin",
            post_code: "32000",
          },
          {
            subdistrict_th: "บึง",
            subdistrict_en: "Bueng",
            post_code: "32000",
          },
          {
            subdistrict_th: "ตากูก",
            subdistrict_en: "Ta Kuk",
            post_code: "32000",
          },
          {
            subdistrict_th: "ปราสาททอง",
            subdistrict_en: "Prasat Thong",
            post_code: "32000",
          },
          {
            subdistrict_th: "บ้านแร่",
            subdistrict_en: "Ban Rae",
            post_code: "32000",
          },
        ],
      },
      {
        district_th: "อำเภอโนนนารายณ์",
        district_en: "Amphoe Non Narai",
        children: [
          {
            subdistrict_th: "หนองหลวง",
            subdistrict_en: "Nong Luang",
            post_code: "32130",
          },
          {
            subdistrict_th: "คำผง",
            subdistrict_en: "Kham Phong",
            post_code: "32130",
          },
          {
            subdistrict_th: "โนน",
            subdistrict_en: "Non",
            post_code: "32130",
          },
          {
            subdistrict_th: "ระเวียง",
            subdistrict_en: "Rawiang",
            post_code: "32130",
          },
          {
            subdistrict_th: "หนองเทพ",
            subdistrict_en: "Nong Thep",
            post_code: "32130",
          },
        ],
      },
    ],
  },
  {
    province_th: "ศรีสะเกษ",
    province_en: "Si Sa Ket",
    children: [
      {
        district_th: "อำเภอเมืองศรีสะเกษ",
        district_en: "Amphoe Mueang Si Sa Ket",
        children: [
          {
            subdistrict_th: "เมืองเหนือ",
            subdistrict_en: "Mueang Nuea",
            post_code: "33000",
          },
          {
            subdistrict_th: "เมืองใต้",
            subdistrict_en: "Mueang Tai",
            post_code: "33000",
          },
          {
            subdistrict_th: "คูซอด",
            subdistrict_en: "Khu Sot",
            post_code: "33000",
          },
          {
            subdistrict_th: "ซำ",
            subdistrict_en: "Sam",
            post_code: "33000",
          },
          {
            subdistrict_th: "จาน",
            subdistrict_en: "Chan",
            post_code: "33000",
          },
          {
            subdistrict_th: "ตะดอบ",
            subdistrict_en: "Tadop",
            post_code: "33000",
          },
          {
            subdistrict_th: "หนองครก",
            subdistrict_en: "Nong Khrok",
            post_code: "33000",
          },
          {
            subdistrict_th: "โพนข่า",
            subdistrict_en: "Phon Kha",
            post_code: "33000",
          },
          {
            subdistrict_th: "โพนค้อ",
            subdistrict_en: "Phon Kho",
            post_code: "33000",
          },
          {
            subdistrict_th: "โพนเขวา",
            subdistrict_en: "Phon Khwao",
            post_code: "33000",
          },
          {
            subdistrict_th: "หญ้าปล้อง",
            subdistrict_en: "Ya Plong",
            post_code: "33000",
          },
          {
            subdistrict_th: "ทุ่ม",
            subdistrict_en: "Thum",
            post_code: "33000",
          },
          {
            subdistrict_th: "หนองไฮ",
            subdistrict_en: "Nong Hai",
            post_code: "33000",
          },
          {
            subdistrict_th: "หนองแก้ว",
            subdistrict_en: "Nong Kaeo",
            post_code: "33000",
          },
          {
            subdistrict_th: "น้ำคำ",
            subdistrict_en: "Nam Kham",
            post_code: "33000",
          },
          {
            subdistrict_th: "โพธิ์",
            subdistrict_en: "Pho",
            post_code: "33000",
          },
          {
            subdistrict_th: "หมากเขียบ",
            subdistrict_en: "Mak Khiap",
            post_code: "33000",
          },
          {
            subdistrict_th: "หนองไผ่",
            subdistrict_en: "Nong Phai",
            post_code: "33000",
          },
        ],
      },
      {
        district_th: "อำเภอยางชุมน้อย",
        district_en: "Amphoe Yang Chum Noi",
        children: [
          {
            subdistrict_th: "ยางชุมน้อย",
            subdistrict_en: "Yang Chum Noi",
            post_code: "33190",
          },
          {
            subdistrict_th: "ลิ้นฟ้า",
            subdistrict_en: "Lin Fa",
            post_code: "33190",
          },
          {
            subdistrict_th: "คอนกาม",
            subdistrict_en: "Khon Kam",
            post_code: "33190",
          },
          {
            subdistrict_th: "โนนคูณ",
            subdistrict_en: "Non Khun",
            post_code: "33190",
          },
          {
            subdistrict_th: "กุดเมืองฮาม",
            subdistrict_en: "Kut Mueang Ham",
            post_code: "33190",
          },
          {
            subdistrict_th: "บึงบอน",
            subdistrict_en: "Bueng Bon",
            post_code: "33190",
          },
          {
            subdistrict_th: "ยางชุมใหญ่",
            subdistrict_en: "Yang Chum Yai",
            post_code: "33190",
          },
        ],
      },
      {
        district_th: "อำเภอกันทรารมย์",
        district_en: "Amphoe Kanthararom",
        children: [
          {
            subdistrict_th: "ดูน",
            subdistrict_en: "Dun",
            post_code: "33130",
          },
          {
            subdistrict_th: "โนนสัง",
            subdistrict_en: "Non Sang",
            post_code: "33130",
          },
          {
            subdistrict_th: "หนองหัวช้าง",
            subdistrict_en: "Nong Hua Chang",
            post_code: "33130",
          },
          {
            subdistrict_th: "ยาง",
            subdistrict_en: "Yang",
            post_code: "33130",
          },
          {
            subdistrict_th: "หนองแวง",
            subdistrict_en: "Nong Waeng",
            post_code: "33130",
          },
          {
            subdistrict_th: "หนองแก้ว",
            subdistrict_en: "Nong Kaeo",
            post_code: "33130",
          },
          {
            subdistrict_th: "ทาม",
            subdistrict_en: "Tham",
            post_code: "33130",
          },
          {
            subdistrict_th: "ละทาย",
            subdistrict_en: "Lathai",
            post_code: "33130",
          },
          {
            subdistrict_th: "เมืองน้อย",
            subdistrict_en: "Mueang Noi",
            post_code: "33130",
          },
          {
            subdistrict_th: "อีปาด",
            subdistrict_en: "I Pat",
            post_code: "33130",
          },
          {
            subdistrict_th: "บัวน้อย",
            subdistrict_en: "Bua Noi",
            post_code: "33130",
          },
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "33130",
          },
          {
            subdistrict_th: "ดู่",
            subdistrict_en: "Du",
            post_code: "33130",
          },
          {
            subdistrict_th: "ผักแพว",
            subdistrict_en: "Phak Phaeo",
            post_code: "33130",
          },
          {
            subdistrict_th: "จาน",
            subdistrict_en: "Chan",
            post_code: "33130",
          },
          {
            subdistrict_th: "คำเนียม",
            subdistrict_en: "Kham Niam",
            post_code: "33130",
          },
        ],
      },
      {
        district_th: "อำเภอกันทรลักษ์",
        district_en: "Amphoe Kantharalak",
        children: [
          {
            subdistrict_th: "บึงมะลู",
            subdistrict_en: "Bueng Malu",
            post_code: "33110",
          },
          {
            subdistrict_th: "กุดเสลา",
            subdistrict_en: "Kut Salao",
            post_code: "33110",
          },
          {
            subdistrict_th: "เมือง",
            subdistrict_en: "Mueang",
            post_code: "33110",
          },
          {
            subdistrict_th: "สังเม็ก",
            subdistrict_en: "Sang Mek",
            post_code: "33110",
          },
          {
            subdistrict_th: "น้ำอ้อม",
            subdistrict_en: "Nam Om",
            post_code: "33110",
          },
          {
            subdistrict_th: "ละลาย",
            subdistrict_en: "Lalai",
            post_code: "33110",
          },
          {
            subdistrict_th: "รุง",
            subdistrict_en: "Rung",
            post_code: "33110",
          },
          {
            subdistrict_th: "ตระกาจ",
            subdistrict_en: "Trakat",
            post_code: "33110",
          },
          {
            subdistrict_th: "จานใหญ่",
            subdistrict_en: "Chan Yai",
            post_code: "33110",
          },
          {
            subdistrict_th: "ภูเงิน",
            subdistrict_en: "Phu Ngoen",
            post_code: "33110",
          },
          {
            subdistrict_th: "ชำ",
            subdistrict_en: "Cham",
            post_code: "33110",
          },
          {
            subdistrict_th: "กระแชง",
            subdistrict_en: "Krachaeng",
            post_code: "33110",
          },
          {
            subdistrict_th: "โนนสำราญ",
            subdistrict_en: "Non Samran",
            post_code: "33110",
          },
          {
            subdistrict_th: "หนองหญ้าลาด",
            subdistrict_en: "Nong Ya Lat",
            post_code: "33110",
          },
          {
            subdistrict_th: "เสาธงชัย",
            subdistrict_en: "Sao Thong Chai",
            post_code: "33110",
          },
          {
            subdistrict_th: "ขนุน",
            subdistrict_en: "Khanun",
            post_code: "33110",
          },
          {
            subdistrict_th: "สวนกล้วย",
            subdistrict_en: "Suan Kluai",
            post_code: "33110",
          },
          {
            subdistrict_th: "เวียงเหนือ",
            subdistrict_en: "Wiang Nuea",
            post_code: "33110",
          },
          {
            subdistrict_th: "ทุ่งใหญ่",
            subdistrict_en: "Thung Yai",
            post_code: "33110",
          },
          {
            subdistrict_th: "ภูผาหมอก",
            subdistrict_en: "Phu Pha Mok",
            post_code: "33110",
          },
        ],
      },
      {
        district_th: "อำเภอขุขันธ์",
        district_en: "Amphoe Khukhan",
        children: [
          {
            subdistrict_th: "กันทรารมย์",
            subdistrict_en: "Kanthararom",
            post_code: "33140",
          },
          {
            subdistrict_th: "จะกง",
            subdistrict_en: "Chakong",
            post_code: "33140",
          },
          {
            subdistrict_th: "ใจดี",
            subdistrict_en: "Chai Di",
            post_code: "33140",
          },
          {
            subdistrict_th: "ดองกำเม็ด",
            subdistrict_en: "Dong Kam Met",
            post_code: "33140",
          },
          {
            subdistrict_th: "โสน",
            subdistrict_en: "Sano",
            post_code: "33140",
          },
          {
            subdistrict_th: "ปรือใหญ่",
            subdistrict_en: "Prue Yai",
            post_code: "33140",
          },
          {
            subdistrict_th: "สะเดาใหญ่",
            subdistrict_en: "Sadao Yai",
            post_code: "33140",
          },
          {
            subdistrict_th: "ตาอุด",
            subdistrict_en: "Ta Ut",
            post_code: "33140",
          },
          {
            subdistrict_th: "ห้วยเหนือ",
            subdistrict_en: "Huai Nuea",
            post_code: "33140",
          },
          {
            subdistrict_th: "ห้วยใต้",
            subdistrict_en: "Huai Tai",
            post_code: "33140",
          },
          {
            subdistrict_th: "หัวเสือ",
            subdistrict_en: "Hua Suea",
            post_code: "33140",
          },
          {
            subdistrict_th: "ตะเคียน",
            subdistrict_en: "Takhian",
            post_code: "33140",
          },
          {
            subdistrict_th: "นิคมพัฒนา",
            subdistrict_en: "Nikhom Phatthana",
            post_code: "33140",
          },
          {
            subdistrict_th: "โคกเพชร",
            subdistrict_en: "Khok Phet",
            post_code: "33140",
          },
          {
            subdistrict_th: "ปราสาท",
            subdistrict_en: "Prasat",
            post_code: "33140",
          },
          {
            subdistrict_th: "สำโรงตาเจ็น",
            subdistrict_en: "Samrong Ta Chen",
            post_code: "33140",
          },
          {
            subdistrict_th: "ห้วยสำราญ",
            subdistrict_en: "Huai Samran",
            post_code: "33140",
          },
          {
            subdistrict_th: "กฤษณา",
            subdistrict_en: "Kritsana",
            post_code: "33140",
          },
          {
            subdistrict_th: "ลมศักดิ์",
            subdistrict_en: "Lom Sak",
            post_code: "33140",
          },
          {
            subdistrict_th: "หนองฉลอง",
            subdistrict_en: "Nong Chalong",
            post_code: "33140",
          },
          {
            subdistrict_th: "ศรีตระกูล",
            subdistrict_en: "Si Trakun",
            post_code: "33140",
          },
          {
            subdistrict_th: "ศรีสะอาด",
            subdistrict_en: "Si Sa-at",
            post_code: "33140",
          },
        ],
      },
      {
        district_th: "อำเภอไพรบึง",
        district_en: "Amphoe Phrai Bueng",
        children: [
          {
            subdistrict_th: "ไพรบึง",
            subdistrict_en: "Phrai Bueng",
            post_code: "33180",
          },
          {
            subdistrict_th: "ดินแดง",
            subdistrict_en: "Din Daeng",
            post_code: "33180",
          },
          {
            subdistrict_th: "ปราสาทเยอ",
            subdistrict_en: "Prasat Yoe",
            post_code: "33180",
          },
          {
            subdistrict_th: "สำโรงพลัน",
            subdistrict_en: "Samrong Phlan",
            post_code: "33180",
          },
          {
            subdistrict_th: "สุขสวัสดิ์",
            subdistrict_en: "Suk Sawat",
            post_code: "33180",
          },
          {
            subdistrict_th: "โนนปูน",
            subdistrict_en: "Non Pun",
            post_code: "33180",
          },
        ],
      },
      {
        district_th: "อำเภอปรางค์กู่",
        district_en: "Amphoe Prang Ku",
        children: [
          {
            subdistrict_th: "พิมาย",
            subdistrict_en: "Phimai",
            post_code: "33170",
          },
          {
            subdistrict_th: "กู่",
            subdistrict_en: "Ku",
            post_code: "33170",
          },
          {
            subdistrict_th: "หนองเชียงทูน",
            subdistrict_en: "Nong Chiang Thun",
            post_code: "33170",
          },
          {
            subdistrict_th: "ตูม",
            subdistrict_en: "Tum",
            post_code: "33170",
          },
          {
            subdistrict_th: "สมอ",
            subdistrict_en: "Samo",
            post_code: "33170",
          },
          {
            subdistrict_th: "โพธิ์ศรี",
            subdistrict_en: "Pho Si",
            post_code: "33170",
          },
          {
            subdistrict_th: "สำโรงปราสาท",
            subdistrict_en: "Samrong Prasat",
            post_code: "33170",
          },
          {
            subdistrict_th: "ดู่",
            subdistrict_en: "Du",
            post_code: "33170",
          },
          {
            subdistrict_th: "สวาย",
            subdistrict_en: "Sawai",
            post_code: "33170",
          },
          {
            subdistrict_th: "พิมายเหนือ",
            subdistrict_en: "Phimai Nuea",
            post_code: "33170",
          },
        ],
      },
      {
        district_th: "อำเภอขุนหาญ",
        district_en: "Amphoe Khun Han",
        children: [
          {
            subdistrict_th: "สิ",
            subdistrict_en: "Si",
            post_code: "33150",
          },
          {
            subdistrict_th: "บักดอง",
            subdistrict_en: "Bak Dong",
            post_code: "33150",
          },
          {
            subdistrict_th: "พราน",
            subdistrict_en: "Phran",
            post_code: "33150",
          },
          {
            subdistrict_th: "โพธิ์วงศ์",
            subdistrict_en: "Pho Wong",
            post_code: "33150",
          },
          {
            subdistrict_th: "ไพร",
            subdistrict_en: "Phrai",
            post_code: "33150",
          },
          {
            subdistrict_th: "กระหวัน",
            subdistrict_en: "Krawan",
            post_code: "33150",
          },
          {
            subdistrict_th: "ขุนหาญ",
            subdistrict_en: "Khun Han",
            post_code: "33150",
          },
          {
            subdistrict_th: "โนนสูง",
            subdistrict_en: "Non Sung",
            post_code: "33150",
          },
          {
            subdistrict_th: "กันทรอม",
            subdistrict_en: "Kanthrom",
            post_code: "33150",
          },
          {
            subdistrict_th: "ภูฝ้าย",
            subdistrict_en: "Phu Fai",
            post_code: "33150",
          },
          {
            subdistrict_th: "โพธิ์กระสังข์",
            subdistrict_en: "Pho Krasang",
            post_code: "33150",
          },
          {
            subdistrict_th: "ห้วยจันทร์",
            subdistrict_en: "Huai Chan",
            post_code: "33150",
          },
        ],
      },
      {
        district_th: "อำเภอราษีไศล",
        district_en: "Amphoe Rasi Salai",
        children: [
          {
            subdistrict_th: "เมืองคง",
            subdistrict_en: "Mueang Khong",
            post_code: "33160",
          },
          {
            subdistrict_th: "เมืองแคน",
            subdistrict_en: "Mueang Khaen",
            post_code: "33160",
          },
          {
            subdistrict_th: "หนองแค",
            subdistrict_en: "Nong Khae",
            post_code: "33160",
          },
          {
            subdistrict_th: "จิกสังข์ทอง",
            subdistrict_en: "Chik Sang Thong",
            post_code: "33160",
          },
          {
            subdistrict_th: "ด่าน",
            subdistrict_en: "Dan",
            post_code: "33160",
          },
          {
            subdistrict_th: "ดู่",
            subdistrict_en: "Du",
            post_code: "33160",
          },
          {
            subdistrict_th: "หนองอึ่ง",
            subdistrict_en: "Nong Ueng",
            post_code: "33160",
          },
          {
            subdistrict_th: "บัวหุ่ง",
            subdistrict_en: "Bua Hung",
            post_code: "33160",
          },
          {
            subdistrict_th: "ไผ่",
            subdistrict_en: "Phai",
            post_code: "33160",
          },
          {
            subdistrict_th: "ส้มป่อย",
            subdistrict_en: "Som Poi",
            post_code: "33160",
          },
          {
            subdistrict_th: "หนองหมี",
            subdistrict_en: "Nong Mi",
            post_code: "33160",
          },
          {
            subdistrict_th: "หว้านคำ",
            subdistrict_en: "Wan Kham",
            post_code: "33160",
          },
          {
            subdistrict_th: "สร้างปี่",
            subdistrict_en: "Sang Pi",
            post_code: "33160",
          },
        ],
      },
      {
        district_th: "อำเภออุทุมพรพิสัย",
        district_en: "Amphoe Uthumphon Phisai",
        children: [
          {
            subdistrict_th: "กำแพง",
            subdistrict_en: "Kamphaeng",
            post_code: "33120",
          },
          {
            subdistrict_th: "อี่หล่ำ",
            subdistrict_en: "I Lam",
            post_code: "33120",
          },
          {
            subdistrict_th: "ก้านเหลือง",
            subdistrict_en: "Kan Lueang",
            post_code: "33120",
          },
          {
            subdistrict_th: "ทุ่งไชย",
            subdistrict_en: "Thung Chai",
            post_code: "33120",
          },
          {
            subdistrict_th: "สำโรง",
            subdistrict_en: "Samrong",
            post_code: "33120",
          },
          {
            subdistrict_th: "แขม",
            subdistrict_en: "Khaem",
            post_code: "33120",
          },
          {
            subdistrict_th: "หนองไฮ",
            subdistrict_en: "Nong Hai",
            post_code: "33120",
          },
          {
            subdistrict_th: "ขะยูง",
            subdistrict_en: "Khayung",
            post_code: "33120",
          },
          {
            subdistrict_th: "ตาเกษ",
            subdistrict_en: "Ta Ket",
            post_code: "33120",
          },
          {
            subdistrict_th: "หัวช้าง",
            subdistrict_en: "Hua Chang",
            post_code: "33120",
          },
          {
            subdistrict_th: "รังแร้ง",
            subdistrict_en: "Rang Raeng",
            post_code: "33120",
          },
          {
            subdistrict_th: "แต้",
            subdistrict_en: "Tae",
            post_code: "33120",
          },
          {
            subdistrict_th: "แข้",
            subdistrict_en: "Khae",
            post_code: "33120",
          },
          {
            subdistrict_th: "โพธิ์ชัย",
            subdistrict_en: "Pho Chai",
            post_code: "33120",
          },
          {
            subdistrict_th: "ปะอาว",
            subdistrict_en: "Pa Ao",
            post_code: "33120",
          },
          {
            subdistrict_th: "หนองห้าง",
            subdistrict_en: "Nong Hang",
            post_code: "33120",
          },
          {
            subdistrict_th: "สระกำแพงใหญ่",
            subdistrict_en: "Sa Kamphaeng Yai",
            post_code: "33120",
          },
          {
            subdistrict_th: "โคกหล่าม",
            subdistrict_en: "Khok Lam",
            post_code: "33120",
          },
          {
            subdistrict_th: "โคกจาน",
            subdistrict_en: "Khok Chan",
            post_code: "33120",
          },
        ],
      },
      {
        district_th: "อำเภอบึงบูรพ์",
        district_en: "Amphoe Bueng Bun",
        children: [
          {
            subdistrict_th: "เป๊าะ",
            subdistrict_en: "Po",
            post_code: "33220",
          },
          {
            subdistrict_th: "บึงบูรพ์",
            subdistrict_en: "Bueng Bun",
            post_code: "33220",
          },
        ],
      },
      {
        district_th: "อำเภอห้วยทับทัน",
        district_en: "Amphoe Huai Thap Than",
        children: [
          {
            subdistrict_th: "ห้วยทับทัน",
            subdistrict_en: "Huai Thap Than",
            post_code: "33210",
          },
          {
            subdistrict_th: "เมืองหลวง",
            subdistrict_en: "Mueang Luang",
            post_code: "33210",
          },
          {
            subdistrict_th: "กล้วยกว้าง",
            subdistrict_en: "Kluai Kwang",
            post_code: "33210",
          },
          {
            subdistrict_th: "ผักไหม",
            subdistrict_en: "Phak Mai",
            post_code: "33210",
          },
          {
            subdistrict_th: "จานแสนไชย",
            subdistrict_en: "Chan Saen Chai",
            post_code: "33210",
          },
          {
            subdistrict_th: "ปราสาท",
            subdistrict_en: "Prasat",
            post_code: "33210",
          },
        ],
      },
      {
        district_th: "อำเภอโนนคูณ",
        district_en: "Amphoe Non Khun",
        children: [
          {
            subdistrict_th: "โนนค้อ",
            subdistrict_en: "Non Kho",
            post_code: "33250",
          },
          {
            subdistrict_th: "บก",
            subdistrict_en: "Bok",
            post_code: "33250",
          },
          {
            subdistrict_th: "โพธิ์",
            subdistrict_en: "Pho",
            post_code: "33250",
          },
          {
            subdistrict_th: "หนองกุง",
            subdistrict_en: "Nong Kung",
            post_code: "33250",
          },
          {
            subdistrict_th: "เหล่ากวาง",
            subdistrict_en: "Lao Kwang",
            post_code: "33250",
          },
        ],
      },
      {
        district_th: "อำเภอศรีรัตนะ",
        district_en: "Amphoe Si Rattana",
        children: [
          {
            subdistrict_th: "ศรีแก้ว",
            subdistrict_en: "Si Kaeo",
            post_code: "33240",
          },
          {
            subdistrict_th: "พิงพวย",
            subdistrict_en: "Phing Phuai",
            post_code: "33240",
          },
          {
            subdistrict_th: "สระเยาว์",
            subdistrict_en: "Sa Yao",
            post_code: "33240",
          },
          {
            subdistrict_th: "ตูม",
            subdistrict_en: "Tum",
            post_code: "33240",
          },
          {
            subdistrict_th: "เสื่องข้าว",
            subdistrict_en: "Sueang Khao",
            post_code: "33240",
          },
          {
            subdistrict_th: "ศรีโนนงาม",
            subdistrict_en: "Si Non Ngam",
            post_code: "33240",
          },
          {
            subdistrict_th: "สะพุง",
            subdistrict_en: "Saphung",
            post_code: "33240",
          },
        ],
      },
      {
        district_th: "อำเภอน้ำเกลี้ยง",
        district_en: "Amphoe Nam Kliang",
        children: [
          {
            subdistrict_th: "น้ำเกลี้ยง",
            subdistrict_en: "Nam Kliang",
            post_code: "33130",
          },
          {
            subdistrict_th: "ละเอาะ",
            subdistrict_en: "La-o",
            post_code: "33130",
          },
          {
            subdistrict_th: "ตองปิด",
            subdistrict_en: "Tong Pit",
            post_code: "33130",
          },
          {
            subdistrict_th: "เขิน",
            subdistrict_en: "Khoen",
            post_code: "33130",
          },
          {
            subdistrict_th: "รุ่งระวี",
            subdistrict_en: "Rung Rawi",
            post_code: "33130",
          },
          {
            subdistrict_th: "คูบ",
            subdistrict_en: "Khup",
            post_code: "33130",
          },
        ],
      },
      {
        district_th: "อำเภอวังหิน",
        district_en: "Amphoe Wang Hin",
        children: [
          {
            subdistrict_th: "บุสูง",
            subdistrict_en: "Bu Sung",
            post_code: "33270",
          },
          {
            subdistrict_th: "ธาตุ",
            subdistrict_en: "That",
            post_code: "33270",
          },
          {
            subdistrict_th: "ดวนใหญ่",
            subdistrict_en: "Duan Yai",
            post_code: "33270",
          },
          {
            subdistrict_th: "บ่อแก้ว",
            subdistrict_en: "Bo Kaeo",
            post_code: "33270",
          },
          {
            subdistrict_th: "ศรีสำราญ",
            subdistrict_en: "Si Samran",
            post_code: "33270",
          },
          {
            subdistrict_th: "ทุ่งสว่าง",
            subdistrict_en: "Thung Sawang",
            post_code: "33270",
          },
          {
            subdistrict_th: "วังหิน",
            subdistrict_en: "Wang Hin",
            post_code: "33270",
          },
          {
            subdistrict_th: "โพนยาง",
            subdistrict_en: "Phon Yang",
            post_code: "33270",
          },
        ],
      },
      {
        district_th: "อำเภอภูสิงห์",
        district_en: "Amphoe Phu Sing",
        children: [
          {
            subdistrict_th: "โคกตาล",
            subdistrict_en: "Khok Tan",
            post_code: "33140",
          },
          {
            subdistrict_th: "ห้วยตามอญ",
            subdistrict_en: "Huai Ta Mon",
            post_code: "33140",
          },
          {
            subdistrict_th: "ห้วยตึ๊กชู",
            subdistrict_en: "Huai Tuekchu",
            post_code: "33140",
          },
          {
            subdistrict_th: "ละลม",
            subdistrict_en: "Lalom",
            post_code: "33140",
          },
          {
            subdistrict_th: "ตะเคียนราม",
            subdistrict_en: "Takhian Ram",
            post_code: "33140",
          },
          {
            subdistrict_th: "ดงรัก",
            subdistrict_en: "Dong Rak",
            post_code: "33140",
          },
          {
            subdistrict_th: "ไพรพัฒนา",
            subdistrict_en: "Phrai Phatthana",
            post_code: "33140",
          },
        ],
      },
      {
        district_th: "อำเภอเมืองจันทร์",
        district_en: "Amphoe Mueang Chan",
        children: [
          {
            subdistrict_th: "เมืองจันทร์",
            subdistrict_en: "Mueang Chan",
            post_code: "33120",
          },
          {
            subdistrict_th: "ตาโกน",
            subdistrict_en: "Ta Kon",
            post_code: "33120",
          },
          {
            subdistrict_th: "หนองใหญ่",
            subdistrict_en: "Nong Yai",
            post_code: "33120",
          },
        ],
      },
      {
        district_th: "อำเภอเบญจลักษ์",
        district_en: "Amphoe Benchalak",
        children: [
          {
            subdistrict_th: "เสียว",
            subdistrict_en: "Siao",
            post_code: "33110",
          },
          {
            subdistrict_th: "หนองหว้า",
            subdistrict_en: "Nong Wa",
            post_code: "33110",
          },
          {
            subdistrict_th: "หนองงูเหลือม",
            subdistrict_en: "Nong Ngu Lueam",
            post_code: "33110",
          },
          {
            subdistrict_th: "หนองฮาง",
            subdistrict_en: "Nong Hang",
            post_code: "33110",
          },
          {
            subdistrict_th: "ท่าคล้อ",
            subdistrict_en: "Tha Khlo",
            post_code: "33110",
          },
        ],
      },
      {
        district_th: "อำเภอพยุห์",
        district_en: "Amphoe Phayu",
        children: [
          {
            subdistrict_th: "พยุห์",
            subdistrict_en: "Phayu",
            post_code: "33230",
          },
          {
            subdistrict_th: "พรหมสวัสดิ์",
            subdistrict_en: "Phrom Sawat",
            post_code: "33230",
          },
          {
            subdistrict_th: "ตำแย",
            subdistrict_en: "Tamyae",
            post_code: "33230",
          },
          {
            subdistrict_th: "โนนเพ็ก",
            subdistrict_en: "Nong Phek",
            post_code: "33230",
          },
          {
            subdistrict_th: "หนองค้า",
            subdistrict_en: "Nong Kha",
            post_code: "33230",
          },
        ],
      },
      {
        district_th: "อำเภอโพธิ์ศรีสุวรรณ",
        district_en: "Amphoe Pho Si Suwan",
        children: [
          {
            subdistrict_th: "โดด",
            subdistrict_en: "Dot",
            post_code: "33120",
          },
          {
            subdistrict_th: "เสียว",
            subdistrict_en: "Siao",
            post_code: "33120",
          },
          {
            subdistrict_th: "หนองม้า",
            subdistrict_en: "Nong Ma",
            post_code: "33120",
          },
          {
            subdistrict_th: "ผือใหญ่",
            subdistrict_en: "Phue Yai",
            post_code: "33120",
          },
          {
            subdistrict_th: "อีเซ",
            subdistrict_en: "I Se",
            post_code: "33120",
          },
        ],
      },
      {
        district_th: "อำเภอศิลาลาด",
        district_en: "Amphoe Sila Lat",
        children: [
          {
            subdistrict_th: "กุง",
            subdistrict_en: "Kung",
            post_code: "33160",
          },
          {
            subdistrict_th: "คลีกลิ้ง",
            subdistrict_en: "Khli Kling",
            post_code: "33160",
          },
          {
            subdistrict_th: "หนองบัวดง",
            subdistrict_en: "Nong Bua Dong",
            post_code: "33160",
          },
          {
            subdistrict_th: "โจดม่วง",
            subdistrict_en: "Chot Muang",
            post_code: "33160",
          },
        ],
      },
    ],
  },
  {
    province_th: "อุบลราชธานี",
    province_en: "Ubon Ratchathani",
    children: [
      {
        district_th: "อำเภอเมืองอุบลราชธานี",
        district_en: "Amphoe Mueang Ubon Ratchathani",
        children: [
          {
            subdistrict_th: "ในเมือง",
            subdistrict_en: "Nai Mueang",
            post_code: "34000",
          },
          {
            subdistrict_th: "หัวเรือ",
            subdistrict_en: "Hua Ruea",
            post_code: "34000",
          },
          {
            subdistrict_th: "หนองขอน",
            subdistrict_en: "Nong Khon",
            post_code: "34000",
          },
          {
            subdistrict_th: "ปทุม",
            subdistrict_en: "Pathum",
            post_code: "34000",
          },
          {
            subdistrict_th: "ขามใหญ่",
            subdistrict_en: "Kham Yai",
            post_code: "34000",
          },
          {
            subdistrict_th: "แจระแม",
            subdistrict_en: "Chaeramae",
            post_code: "34000",
          },
          {
            subdistrict_th: "หนองบ่อ",
            subdistrict_en: "Nong Bo",
            post_code: "34000",
          },
          {
            subdistrict_th: "ไร่น้อย",
            subdistrict_en: "Rai Noi",
            post_code: "34000",
          },
          {
            subdistrict_th: "กระโสบ",
            subdistrict_en: "Krasop",
            post_code: "34000",
          },
          {
            subdistrict_th: "กุดลาด",
            subdistrict_en: "Kut Lat",
            post_code: "34000",
          },
          {
            subdistrict_th: "ขี้เหล็ก",
            subdistrict_en: "Khilek",
            post_code: "34000",
          },
          {
            subdistrict_th: "ปะอาว",
            subdistrict_en: "Pa-ao",
            post_code: "34000",
          },
        ],
      },
      {
        district_th: "อำเภอศรีเมืองใหม่",
        district_en: "Amphoe Si Mueang Mai",
        children: [
          {
            subdistrict_th: "นาคำ",
            subdistrict_en: "Na Kham",
            post_code: "34250",
          },
          {
            subdistrict_th: "แก้งกอก",
            subdistrict_en: "Kaeng Kok",
            post_code: "34250",
          },
          {
            subdistrict_th: "เอือดใหญ่",
            subdistrict_en: "Ueat Yai",
            post_code: "34250",
          },
          {
            subdistrict_th: "วาริน",
            subdistrict_en: "Warin",
            post_code: "34250",
          },
          {
            subdistrict_th: "ลาดควาย",
            subdistrict_en: "Lat Khwai",
            post_code: "34250",
          },
          {
            subdistrict_th: "สงยาง",
            subdistrict_en: "Song Yang",
            post_code: "34250",
          },
          {
            subdistrict_th: "ตะบ่าย",
            subdistrict_en: "Tabai",
            post_code: "34250",
          },
          {
            subdistrict_th: "คำไหล",
            subdistrict_en: "Kham Lai",
            post_code: "34250",
          },
          {
            subdistrict_th: "หนามแท่ง",
            subdistrict_en: "Nam Thaeng",
            post_code: "34250",
          },
          {
            subdistrict_th: "นาเลิน",
            subdistrict_en: "Na Loen",
            post_code: "34250",
          },
          {
            subdistrict_th: "ดอนใหญ่",
            subdistrict_en: "Don Yai",
            post_code: "34250",
          },
        ],
      },
      {
        district_th: "อำเภอโขงเจียม",
        district_en: "Amphoe Khong Chiam",
        children: [
          {
            subdistrict_th: "โขงเจียม",
            subdistrict_en: "Khong Chiam",
            post_code: "34220",
          },
          {
            subdistrict_th: "ห้วยยาง",
            subdistrict_en: "Huai Yang",
            post_code: "34220",
          },
          {
            subdistrict_th: "นาโพธิ์กลาง",
            subdistrict_en: "Na Pho Klang",
            post_code: "34220",
          },
          {
            subdistrict_th: "หนองแสงใหญ่",
            subdistrict_en: "Nong Saeng Yai",
            post_code: "34220",
          },
          {
            subdistrict_th: "ห้วยไผ่",
            subdistrict_en: "Huai Phai",
            post_code: "34220",
          },
        ],
      },
      {
        district_th: "อำเภอเขื่องใน",
        district_en: "Amphoe Khueang Nai",
        children: [
          {
            subdistrict_th: "เขื่องใน",
            subdistrict_en: "Khueang Nai",
            post_code: "34150",
          },
          {
            subdistrict_th: "สร้างถ่อ",
            subdistrict_en: "Sang Tho",
            post_code: "34150",
          },
          {
            subdistrict_th: "ค้อทอง",
            subdistrict_en: "Kho Thong",
            post_code: "34150",
          },
          {
            subdistrict_th: "ก่อเอ้",
            subdistrict_en: "Ko E",
            post_code: "34150",
          },
          {
            subdistrict_th: "หัวดอน",
            subdistrict_en: "Hua Don",
            post_code: "34150",
          },
          {
            subdistrict_th: "ชีทวน",
            subdistrict_en: "Chi Thuan",
            post_code: "34150",
          },
          {
            subdistrict_th: "ท่าไห",
            subdistrict_en: "Tha Hai",
            post_code: "34150",
          },
          {
            subdistrict_th: "นาคำใหญ่",
            subdistrict_en: "Na Kham Yai",
            post_code: "34150",
          },
          {
            subdistrict_th: "แดงหม้อ",
            subdistrict_en: "Daeng Mo",
            post_code: "34150",
          },
          {
            subdistrict_th: "ธาตุน้อย",
            subdistrict_en: "That Noi",
            post_code: "34150",
          },
          {
            subdistrict_th: "บ้านไทย",
            subdistrict_en: "Ban Thai",
            post_code: "34320",
          },
          {
            subdistrict_th: "บ้านกอก",
            subdistrict_en: "Ban Kok",
            post_code: "34320",
          },
          {
            subdistrict_th: "กลางใหญ่",
            subdistrict_en: "Klang Yai",
            post_code: "34320",
          },
          {
            subdistrict_th: "โนนรัง",
            subdistrict_en: "Non Rang",
            post_code: "34320",
          },
          {
            subdistrict_th: "ยางขี้นก",
            subdistrict_en: "Yang Khi Nok",
            post_code: "34150",
          },
          {
            subdistrict_th: "ศรีสุข",
            subdistrict_en: "Si Suk",
            post_code: "34150",
          },
          {
            subdistrict_th: "สหธาตุ",
            subdistrict_en: "Sahathat",
            post_code: "34150",
          },
          {
            subdistrict_th: "หนองเหล่า",
            subdistrict_en: "Nong Lao",
            post_code: "34150",
          },
        ],
      },
      {
        district_th: "อำเภอเขมราฐ",
        district_en: "Amphoe Khemarat",
        children: [
          {
            subdistrict_th: "เขมราฐ",
            subdistrict_en: "Khemarat",
            post_code: "34170",
          },
          {
            subdistrict_th: "ขามป้อม",
            subdistrict_en: "Kham Pom",
            post_code: "34170",
          },
          {
            subdistrict_th: "เจียด",
            subdistrict_en: "Chiat",
            post_code: "34170",
          },
          {
            subdistrict_th: "หนองผือ",
            subdistrict_en: "Nong Phue",
            post_code: "34170",
          },
          {
            subdistrict_th: "นาแวง",
            subdistrict_en: "Na Waeng",
            post_code: "34170",
          },
          {
            subdistrict_th: "แก้งเหนือ",
            subdistrict_en: "Kaeng Nuea",
            post_code: "34170",
          },
          {
            subdistrict_th: "หนองนกทา",
            subdistrict_en: "Nong Nok Tha",
            post_code: "34170",
          },
          {
            subdistrict_th: "หนองสิม",
            subdistrict_en: "Nong Sim",
            post_code: "34170",
          },
          {
            subdistrict_th: "หัวนา",
            subdistrict_en: "Hua Na",
            post_code: "34170",
          },
        ],
      },
      {
        district_th: "อำเภอเดชอุดม",
        district_en: "Amphoe Det Udom",
        children: [
          {
            subdistrict_th: "เมืองเดช",
            subdistrict_en: "Mueang Det",
            post_code: "34160",
          },
          {
            subdistrict_th: "นาส่วง",
            subdistrict_en: "Na Suang",
            post_code: "34160",
          },
          {
            subdistrict_th: "นาเจริญ",
            subdistrict_en: "Na Charoen",
            post_code: "34160",
          },
          {
            subdistrict_th: "ทุ่งเทิง",
            subdistrict_en: "Thung Thoeng",
            post_code: "34160",
          },
          {
            subdistrict_th: "สมสะอาด",
            subdistrict_en: "Som Sa-at",
            post_code: "34160",
          },
          {
            subdistrict_th: "กุดประทาย",
            subdistrict_en: "Kut Prathai",
            post_code: "34160",
          },
          {
            subdistrict_th: "ตบหู",
            subdistrict_en: "Top Hu",
            post_code: "34160",
          },
          {
            subdistrict_th: "กลาง",
            subdistrict_en: "Klang",
            post_code: "34160",
          },
          {
            subdistrict_th: "แก้ง",
            subdistrict_en: "Kaeng",
            post_code: "34160",
          },
          {
            subdistrict_th: "ท่าโพธิ์ศรี",
            subdistrict_en: "Tha Pho Si",
            post_code: "34160",
          },
          {
            subdistrict_th: "บัวงาม",
            subdistrict_en: "Bua Ngam",
            post_code: "34160",
          },
          {
            subdistrict_th: "คำครั่ง",
            subdistrict_en: "Kham Khrang",
            post_code: "34160",
          },
          {
            subdistrict_th: "นากระแซง",
            subdistrict_en: "Na Krasaeng",
            post_code: "34160",
          },
          {
            subdistrict_th: "โพนงาม",
            subdistrict_en: "Phon Ngam",
            post_code: "34160",
          },
          {
            subdistrict_th: "ป่าโมง",
            subdistrict_en: "Pa Mong",
            post_code: "34160",
          },
          {
            subdistrict_th: "โนนสมบูรณ์",
            subdistrict_en: "Non Sombun",
            post_code: "34160",
          },
        ],
      },
      {
        district_th: "อำเภอนาจะหลวย",
        district_en: "Amphoe Na Chaluai",
        children: [
          {
            subdistrict_th: "นาจะหลวย",
            subdistrict_en: "Na Chaluai",
            post_code: "34280",
          },
          {
            subdistrict_th: "โนนสมบูรณ์",
            subdistrict_en: "Non Sombun",
            post_code: "34280",
          },
          {
            subdistrict_th: "พรสวรรค์",
            subdistrict_en: "Phon Sawan",
            post_code: "34280",
          },
          {
            subdistrict_th: "บ้านตูม",
            subdistrict_en: "Ban Tum",
            post_code: "34280",
          },
          {
            subdistrict_th: "โสกแสง",
            subdistrict_en: "Sok Saeng",
            post_code: "34280",
          },
          {
            subdistrict_th: "โนนสวรรค์",
            subdistrict_en: "Non Sawan",
            post_code: "34280",
          },
        ],
      },
      {
        district_th: "อำเภอน้ำยืน",
        district_en: "Amphoe Nam Yuen",
        children: [
          {
            subdistrict_th: "โซง",
            subdistrict_en: "Song",
            post_code: "34260",
          },
          {
            subdistrict_th: "ยาง",
            subdistrict_en: "Yang",
            post_code: "34260",
          },
          {
            subdistrict_th: "โดมประดิษฐ์",
            subdistrict_en: "Dom Pradit",
            post_code: "34260",
          },
          {
            subdistrict_th: "บุเปือย",
            subdistrict_en: "Bu Pueai",
            post_code: "34260",
          },
          {
            subdistrict_th: "สีวิเชียร",
            subdistrict_en: "Si Wichian",
            post_code: "34260",
          },
          {
            subdistrict_th: "ยางใหญ่",
            subdistrict_en: "Yang Yai",
            post_code: "34260",
          },
          {
            subdistrict_th: "เก่าขาม",
            subdistrict_en: "Kao Kham",
            post_code: "34260",
          },
        ],
      },
      {
        district_th: "อำเภอบุณฑริก",
        district_en: "Amphoe Buntharik",
        children: [
          {
            subdistrict_th: "โพนงาม",
            subdistrict_en: "Phon Ngam",
            post_code: "34230",
          },
          {
            subdistrict_th: "ห้วยข่า",
            subdistrict_en: "Huai Kha",
            post_code: "34230",
          },
          {
            subdistrict_th: "คอแลน",
            subdistrict_en: "Kho Laen",
            post_code: "34230",
          },
          {
            subdistrict_th: "นาโพธิ์",
            subdistrict_en: "Na Pho",
            post_code: "34230",
          },
          {
            subdistrict_th: "หนองสะโน",
            subdistrict_en: "Nong Sano",
            post_code: "34230",
          },
          {
            subdistrict_th: "โนนค้อ",
            subdistrict_en: "Non Kho",
            post_code: "34230",
          },
          {
            subdistrict_th: "บัวงาม",
            subdistrict_en: "Bua Ngam",
            post_code: "34230",
          },
          {
            subdistrict_th: "บ้านแมด",
            subdistrict_en: "Ban Maet",
            post_code: "34230",
          },
        ],
      },
      {
        district_th: "อำเภอตระการพืชผล",
        district_en: "Amphoe Trakan Phuet Phon",
        children: [
          {
            subdistrict_th: "ขุหลุ",
            subdistrict_en: "Khulu",
            post_code: "34130",
          },
          {
            subdistrict_th: "กระเดียน",
            subdistrict_en: "Kradian",
            post_code: "34130",
          },
          {
            subdistrict_th: "เกษม",
            subdistrict_en: "Kasem",
            post_code: "34130",
          },
          {
            subdistrict_th: "กุศกร",
            subdistrict_en: "Kutsakon",
            post_code: "34130",
          },
          {
            subdistrict_th: "ขามเปี้ย",
            subdistrict_en: "Kham Pia",
            post_code: "34130",
          },
          {
            subdistrict_th: "คอนสาย",
            subdistrict_en: "Khon Sai",
            post_code: "34130",
          },
          {
            subdistrict_th: "โคกจาน",
            subdistrict_en: "Khok Chan",
            post_code: "34130",
          },
          {
            subdistrict_th: "นาพิน",
            subdistrict_en: "Na Phin",
            post_code: "34130",
          },
          {
            subdistrict_th: "นาสะไม",
            subdistrict_en: "Na Samai",
            post_code: "34130",
          },
          {
            subdistrict_th: "โนนกุง",
            subdistrict_en: "Non Kung",
            post_code: "34130",
          },
          {
            subdistrict_th: "ตระการ",
            subdistrict_en: "Trakan",
            post_code: "34130",
          },
          {
            subdistrict_th: "ตากแดด",
            subdistrict_en: "Tak Daet",
            post_code: "34130",
          },
          {
            subdistrict_th: "ไหล่ทุ่ง",
            subdistrict_en: "Lai Thung",
            post_code: "34130",
          },
          {
            subdistrict_th: "เป้า",
            subdistrict_en: "Pao",
            post_code: "34130",
          },
          {
            subdistrict_th: "เซเป็ด",
            subdistrict_en: "Se Pet",
            post_code: "34130",
          },
          {
            subdistrict_th: "สะพือ",
            subdistrict_en: "Saphue",
            post_code: "34130",
          },
          {
            subdistrict_th: "หนองเต่า",
            subdistrict_en: "Nong Tao",
            post_code: "34130",
          },
          {
            subdistrict_th: "ถ้ำแข้",
            subdistrict_en: "Tham Khae",
            post_code: "34130",
          },
          {
            subdistrict_th: "ท่าหลวง",
            subdistrict_en: "Tha Luang",
            post_code: "34130",
          },
          {
            subdistrict_th: "ห้วยฝ้ายพัฒนา",
            subdistrict_en: "Huai Fai Phatthana",
            post_code: "34130",
          },
          {
            subdistrict_th: "กุดยาลวน",
            subdistrict_en: "Kut Ya Luan",
            post_code: "34130",
          },
          {
            subdistrict_th: "บ้านแดง",
            subdistrict_en: "Ban Daeng",
            post_code: "34130",
          },
          {
            subdistrict_th: "คำเจริญ",
            subdistrict_en: "Kham Charoen",
            post_code: "34130",
          },
        ],
      },
      {
        district_th: "อำเภอกุดข้าวปุ้น",
        district_en: "Amphoe Kut Khaopun",
        children: [
          {
            subdistrict_th: "ข้าวปุ้น",
            subdistrict_en: "Khao Pun",
            post_code: "34270",
          },
          {
            subdistrict_th: "โนนสวาง",
            subdistrict_en: "Non Sawang",
            post_code: "34270",
          },
          {
            subdistrict_th: "แก่งเค็ง",
            subdistrict_en: "Kaeng Kheng",
            post_code: "34270",
          },
          {
            subdistrict_th: "กาบิน",
            subdistrict_en: "Ka Bin",
            post_code: "34270",
          },
          {
            subdistrict_th: "หนองทันน้ำ",
            subdistrict_en: "Nong Than Nam",
            post_code: "34270",
          },
        ],
      },
      {
        district_th: "อำเภอม่วงสามสิบ",
        district_en: "Amphoe Muang Sam Sip",
        children: [
          {
            subdistrict_th: "ม่วงสามสิบ",
            subdistrict_en: "Muang Sam Sip",
            post_code: "34140",
          },
          {
            subdistrict_th: "เหล่าบก",
            subdistrict_en: "Lao Bok",
            post_code: "34140",
          },
          {
            subdistrict_th: "ดุมใหญ่",
            subdistrict_en: "Dum Yai",
            post_code: "34140",
          },
          {
            subdistrict_th: "หนองช้างใหญ่",
            subdistrict_en: "Nong Chang Yai",
            post_code: "34140",
          },
          {
            subdistrict_th: "หนองเมือง",
            subdistrict_en: "Nong Mueang",
            post_code: "34140",
          },
          {
            subdistrict_th: "เตย",
            subdistrict_en: "Toei",
            post_code: "34140",
          },
          {
            subdistrict_th: "ยางสักกระโพหลุ่ม",
            subdistrict_en: "Yang Sak Krapho Lum",
            post_code: "34140",
          },
          {
            subdistrict_th: "หนองไข่นก",
            subdistrict_en: "Nong Khai Nok",
            post_code: "34140",
          },
          {
            subdistrict_th: "หนองเหล่า",
            subdistrict_en: "Nong Lao",
            post_code: "34140",
          },
          {
            subdistrict_th: "หนองฮาง",
            subdistrict_en: "Nong Hang",
            post_code: "34140",
          },
          {
            subdistrict_th: "ยางโยภาพ",
            subdistrict_en: "Yang Yo Phap",
            post_code: "34140",
          },
          {
            subdistrict_th: "ไผ่ใหญ่",
            subdistrict_en: "Phai Yai",
            post_code: "34140",
          },
          {
            subdistrict_th: "นาเลิง",
            subdistrict_en: "Na Loeng",
            post_code: "34140",
          },
          {
            subdistrict_th: "โพนแพง",
            subdistrict_en: "Phon Phaeng",
            post_code: "34140",
          },
        ],
      },
      {
        district_th: "อำเภอวารินชำราบ",
        district_en: "Amphoe Warin Chamrap",
        children: [
          {
            subdistrict_th: "วารินชำราบ",
            subdistrict_en: "Warin Chamrap",
            post_code: "34190",
          },
          {
            subdistrict_th: "ธาตุ",
            subdistrict_en: "That",
            post_code: "34190",
          },
          {
            subdistrict_th: "ท่าลาด",
            subdistrict_en: "Tha Lat",
            post_code: "34310",
          },
          {
            subdistrict_th: "โนนโหนน",
            subdistrict_en: "Non Non",
            post_code: "34190",
          },
          {
            subdistrict_th: "คูเมือง",
            subdistrict_en: "Khu Mueang",
            post_code: "34190",
          },
          {
            subdistrict_th: "สระสมิง",
            subdistrict_en: "Sa Saming",
            post_code: "34190",
          },
          {
            subdistrict_th: "คำน้ำแซบ",
            subdistrict_en: "Kham Nam Saep",
            post_code: "34190",
          },
          {
            subdistrict_th: "บุ่งหวาย",
            subdistrict_en: "Bung Wai",
            post_code: "34310",
          },
          {
            subdistrict_th: "คำขวาง",
            subdistrict_en: "Kham Khwang",
            post_code: "34190",
          },
          {
            subdistrict_th: "โพธิ์ใหญ่",
            subdistrict_en: "Pho Yai",
            post_code: "34190",
          },
          {
            subdistrict_th: "แสนสุข",
            subdistrict_en: "Saen Suk",
            post_code: "34190",
          },
          {
            subdistrict_th: "หนองกินเพล",
            subdistrict_en: "Nong Kin Phen",
            post_code: "34190",
          },
          {
            subdistrict_th: "โนนผึ้ง",
            subdistrict_en: "Non Phueng",
            post_code: "34190",
          },
          {
            subdistrict_th: "เมืองศรีไค",
            subdistrict_en: "Mueang Si Khai",
            post_code: "34190",
          },
          {
            subdistrict_th: "ห้วยขะยุง",
            subdistrict_en: "Huai Khayung",
            post_code: "34310",
          },
          {
            subdistrict_th: "บุ่งไหม",
            subdistrict_en: "Bung Mai",
            post_code: "34190",
          },
        ],
      },
      {
        district_th: "อำเภอพิบูลมังสาหาร",
        district_en: "Amphoe Phibun Mangsahan",
        children: [
          {
            subdistrict_th: "พิบูล",
            subdistrict_en: "Phibun",
            post_code: "34110",
          },
          {
            subdistrict_th: "กุดชมภู",
            subdistrict_en: "Kut Chomphu",
            post_code: "34110",
          },
          {
            subdistrict_th: "ดอนจิก",
            subdistrict_en: "Don Chik",
            post_code: "34110",
          },
          {
            subdistrict_th: "ทรายมูล",
            subdistrict_en: "Sai Mun",
            post_code: "34110",
          },
          {
            subdistrict_th: "นาโพธิ์",
            subdistrict_en: "Na Pho",
            post_code: "34110",
          },
          {
            subdistrict_th: "โนนกลาง",
            subdistrict_en: "Non Klang",
            post_code: "34110",
          },
          {
            subdistrict_th: "โพธิ์ไทร",
            subdistrict_en: "Pho Sai",
            post_code: "34110",
          },
          {
            subdistrict_th: "โพธิ์ศรี",
            subdistrict_en: "Pho Si",
            post_code: "34110",
          },
          {
            subdistrict_th: "ระเว",
            subdistrict_en: "Rawe",
            post_code: "34110",
          },
          {
            subdistrict_th: "ไร่ใต้",
            subdistrict_en: "Rai Tai",
            post_code: "34110",
          },
          {
            subdistrict_th: "หนองบัวฮี",
            subdistrict_en: "Nong Bua Hi",
            post_code: "34110",
          },
          {
            subdistrict_th: "อ่างศิลา",
            subdistrict_en: "Ang Sila",
            post_code: "34110",
          },
          {
            subdistrict_th: "โนนกาหลง",
            subdistrict_en: "Non Kalong",
            post_code: "34110",
          },
          {
            subdistrict_th: "บ้านแขม",
            subdistrict_en: "Ban Khaem",
            post_code: "34110",
          },
        ],
      },
      {
        district_th: "อำเภอตาลสุม",
        district_en: "Amphoe Tan Sum",
        children: [
          {
            subdistrict_th: "ตาลสุม",
            subdistrict_en: "Tan Sum",
            post_code: "34330",
          },
          {
            subdistrict_th: "สำโรง",
            subdistrict_en: "Samrong",
            post_code: "34330",
          },
          {
            subdistrict_th: "จิกเทิง",
            subdistrict_en: "Chik Thoeng",
            post_code: "34330",
          },
          {
            subdistrict_th: "หนองกุง",
            subdistrict_en: "Nong Kung",
            post_code: "34330",
          },
          {
            subdistrict_th: "นาคาย",
            subdistrict_en: "Na Khai",
            post_code: "34330",
          },
          {
            subdistrict_th: "คำหว้า",
            subdistrict_en: "Kham Wa",
            post_code: "34330",
          },
        ],
      },
      {
        district_th: "อำเภอโพธิ์ไทร",
        district_en: "Amphoe Pho Sai",
        children: [
          {
            subdistrict_th: "โพธิ์ไทร",
            subdistrict_en: "Pho Sai",
            post_code: "34340",
          },
          {
            subdistrict_th: "ม่วงใหญ่",
            subdistrict_en: "Muang Yai",
            post_code: "34340",
          },
          {
            subdistrict_th: "สำโรง",
            subdistrict_en: "Samrong",
            post_code: "34340",
          },
          {
            subdistrict_th: "สองคอน",
            subdistrict_en: "Song Khon",
            post_code: "34340",
          },
          {
            subdistrict_th: "สารภี",
            subdistrict_en: "Saraphi",
            post_code: "34340",
          },
          {
            subdistrict_th: "เหล่างาม",
            subdistrict_en: "Lao Ngam",
            post_code: "34340",
          },
        ],
      },
      {
        district_th: "อำเภอสำโรง",
        district_en: "Amphoe Samrong",
        children: [
          {
            subdistrict_th: "สำโรง",
            subdistrict_en: "Samrong",
            post_code: "34360",
          },
          {
            subdistrict_th: "โคกก่อง",
            subdistrict_en: "Khok Kong",
            post_code: "34360",
          },
          {
            subdistrict_th: "หนองไฮ",
            subdistrict_en: "Nong Hai",
            post_code: "34360",
          },
          {
            subdistrict_th: "ค้อน้อย",
            subdistrict_en: "Kho Noi",
            post_code: "34360",
          },
          {
            subdistrict_th: "โนนกาเล็น",
            subdistrict_en: "Non Ka Len",
            post_code: "34360",
          },
          {
            subdistrict_th: "โคกสว่าง",
            subdistrict_en: "Khok Sawang",
            post_code: "34360",
          },
          {
            subdistrict_th: "โนนกลาง",
            subdistrict_en: "Non Klang",
            post_code: "34360",
          },
          {
            subdistrict_th: "บอน",
            subdistrict_en: "Bon",
            post_code: "34360",
          },
          {
            subdistrict_th: "ขามป้อม",
            subdistrict_en: "Kham Pom",
            post_code: "34360",
          },
        ],
      },
      {
        district_th: "อำเภอดอนมดแดง",
        district_en: "Amphoe Don Mot Daeng",
        children: [
          {
            subdistrict_th: "ดอนมดแดง",
            subdistrict_en: "Don Mot Daeng",
            post_code: "34000",
          },
          {
            subdistrict_th: "เหล่าแดง",
            subdistrict_en: "Lao Daeng",
            post_code: "34000",
          },
          {
            subdistrict_th: "ท่าเมือง",
            subdistrict_en: "Tha Muaeng",
            post_code: "34000",
          },
          {
            subdistrict_th: "คำไฮใหญ่",
            subdistrict_en: "Kham Hai Yai",
            post_code: "34000",
          },
        ],
      },
      {
        district_th: "อำเภอสิรินธร",
        district_en: "Amphoe Sirindhorn",
        children: [
          {
            subdistrict_th: "คันไร่",
            subdistrict_en: "Khan Rai",
            post_code: "34350",
          },
          {
            subdistrict_th: "ช่องเม็ก",
            subdistrict_en: "Chong Mek",
            post_code: "34350",
          },
          {
            subdistrict_th: "โนนก่อ",
            subdistrict_en: "Non Ko",
            post_code: "34350",
          },
          {
            subdistrict_th: "นิคมสร้างตนเองลำโดมน้อย",
            subdistrict_en: "Nikhom Sang Ton-eng Lam Dom Noi",
            post_code: "34350",
          },
          {
            subdistrict_th: "ฝางคำ",
            subdistrict_en: "Fang Kham",
            post_code: "34350",
          },
          {
            subdistrict_th: "คำเขื่อนแก้ว",
            subdistrict_en: "Kham Khuean Kaeo",
            post_code: "34350",
          },
        ],
      },
      {
        district_th: "อำเภอทุ่งศรีอุดม",
        district_en: "Amphoe Thung Si Udom",
        children: [
          {
            subdistrict_th: "หนองอ้ม",
            subdistrict_en: "Nong Om",
            post_code: "34160",
          },
          {
            subdistrict_th: "นาเกษม",
            subdistrict_en: "Na Kasem",
            post_code: "34160",
          },
          {
            subdistrict_th: "กุดเรือ",
            subdistrict_en: "Kut Ruea",
            post_code: "34160",
          },
          {
            subdistrict_th: "โคกชำแระ",
            subdistrict_en: "Khok Chamrae",
            post_code: "34160",
          },
          {
            subdistrict_th: "นาห่อม",
            subdistrict_en: "Na Hom",
            post_code: "34160",
          },
        ],
      },
      {
        district_th: "อำเภอนาเยีย",
        district_en: "Amphoe Na Yia",
        children: [
          {
            subdistrict_th: "นาเยีย",
            subdistrict_en: "Na Yia",
            post_code: "34160",
          },
          {
            subdistrict_th: "นาดี",
            subdistrict_en: "Na Di",
            post_code: "34160",
          },
          {
            subdistrict_th: "นาเรือง",
            subdistrict_en: "Na Rueang",
            post_code: "34160",
          },
        ],
      },
      {
        district_th: "อำเภอนาตาล",
        district_en: "Amphoe Na Tan",
        children: [
          {
            subdistrict_th: "นาตาล",
            subdistrict_en: "Na Tan",
            post_code: "34170",
          },
          {
            subdistrict_th: "พะลาน",
            subdistrict_en: "Phalan",
            post_code: "34170",
          },
          {
            subdistrict_th: "กองโพน",
            subdistrict_en: "Kong Phon",
            post_code: "34170",
          },
          {
            subdistrict_th: "พังเคน",
            subdistrict_en: "Phang Khen",
            post_code: "34170",
          },
        ],
      },
      {
        district_th: "อำเภอเหล่าเสือโก้ก",
        district_en: "Amphoe Lao Suea Kok",
        children: [
          {
            subdistrict_th: "เหล่าเสือโก้ก",
            subdistrict_en: "Lao Suea Kok",
            post_code: "34000",
          },
          {
            subdistrict_th: "โพนเมือง",
            subdistrict_en: "Phon Mueang",
            post_code: "34000",
          },
          {
            subdistrict_th: "แพงใหญ่",
            subdistrict_en: "Phaeng Yai",
            post_code: "34000",
          },
          {
            subdistrict_th: "หนองบก",
            subdistrict_en: "Nong Bok",
            post_code: "34000",
          },
        ],
      },
      {
        district_th: "อำเภอสว่างวีระวงศ์",
        district_en: "Amphoe Sawang Wirawong",
        children: [
          {
            subdistrict_th: "แก่งโดม",
            subdistrict_en: "Kaeng Dom",
            post_code: "34190",
          },
          {
            subdistrict_th: "ท่าช้าง",
            subdistrict_en: "Tha Chang",
            post_code: "34190",
          },
          {
            subdistrict_th: "บุ่งมะแลง",
            subdistrict_en: "Bung Malaeng",
            post_code: "34190",
          },
          {
            subdistrict_th: "สว่าง",
            subdistrict_en: "Sawang",
            post_code: "34190",
          },
        ],
      },
      {
        district_th: "อำเภอน้ำขุ่น",
        district_en: "Amphoe Nam Khun",
        children: [
          {
            subdistrict_th: "ตาเกา",
            subdistrict_en: "Ta Kao",
            post_code: "34260",
          },
          {
            subdistrict_th: "ไพบูลย์",
            subdistrict_en: "Phaibun",
            post_code: "34260",
          },
          {
            subdistrict_th: "ขี้เหล็ก",
            subdistrict_en: "Khilek",
            post_code: "34260",
          },
          {
            subdistrict_th: "โคกสะอาด",
            subdistrict_en: "Khok Sa-at",
            post_code: "34260",
          },
        ],
      },
    ],
  },
  {
    province_th: "ยโสธร",
    province_en: "Yasothon",
    children: [
      {
        district_th: "อำเภอเมืองยโสธร",
        district_en: "Amphoe Mueang Yasothon",
        children: [
          {
            subdistrict_th: "ในเมือง",
            subdistrict_en: "Nai Mueang",
            post_code: "35000",
          },
          {
            subdistrict_th: "น้ำคำใหญ่",
            subdistrict_en: "Nam Kham Yai",
            post_code: "35000",
          },
          {
            subdistrict_th: "ตาดทอง",
            subdistrict_en: "Tat Thong",
            post_code: "35000",
          },
          {
            subdistrict_th: "สำราญ",
            subdistrict_en: "Samran",
            post_code: "35000",
          },
          {
            subdistrict_th: "ค้อเหนือ",
            subdistrict_en: "Kho Nuea",
            post_code: "35000",
          },
          {
            subdistrict_th: "ดู่ทุ่ง",
            subdistrict_en: "Du Thung",
            post_code: "35000",
          },
          {
            subdistrict_th: "เดิด",
            subdistrict_en: "Doet",
            post_code: "35000",
          },
          {
            subdistrict_th: "ขั้นไดใหญ่",
            subdistrict_en: "Khan Dai Yai",
            post_code: "35000",
          },
          {
            subdistrict_th: "ทุ่งแต้",
            subdistrict_en: "Thung Tae",
            post_code: "35000",
          },
          {
            subdistrict_th: "สิงห์",
            subdistrict_en: "Sing",
            post_code: "35000",
          },
          {
            subdistrict_th: "นาสะไมย์",
            subdistrict_en: "Na Samai",
            post_code: "35000",
          },
          {
            subdistrict_th: "เขื่องคำ",
            subdistrict_en: "Khueang Kham",
            post_code: "35000",
          },
          {
            subdistrict_th: "หนองหิน",
            subdistrict_en: "Nong Hin",
            post_code: "35000",
          },
          {
            subdistrict_th: "หนองคู",
            subdistrict_en: "Nong Khu",
            post_code: "35000",
          },
          {
            subdistrict_th: "ขุมเงิน",
            subdistrict_en: "Khum Ngoen",
            post_code: "35000",
          },
          {
            subdistrict_th: "ทุ่งนางโอก",
            subdistrict_en: "Thung Nang Ok",
            post_code: "35000",
          },
          {
            subdistrict_th: "หนองเรือ",
            subdistrict_en: "Nong Ruea",
            post_code: "35000",
          },
          {
            subdistrict_th: "หนองเป็ด",
            subdistrict_en: "Nong Pet",
            post_code: "35000",
          },
        ],
      },
      {
        district_th: "อำเภอทรายมูล",
        district_en: "Amphoe Sai Mun",
        children: [
          {
            subdistrict_th: "ทรายมูล",
            subdistrict_en: "Sai Mun",
            post_code: "35170",
          },
          {
            subdistrict_th: "ดู่ลาด",
            subdistrict_en: "Du Lat",
            post_code: "35170",
          },
          {
            subdistrict_th: "ดงมะไฟ",
            subdistrict_en: "Dong Mafai",
            post_code: "35170",
          },
          {
            subdistrict_th: "นาเวียง",
            subdistrict_en: "Na Wiang",
            post_code: "35170",
          },
          {
            subdistrict_th: "ไผ่",
            subdistrict_en: "Phai",
            post_code: "35170",
          },
        ],
      },
      {
        district_th: "อำเภอกุดชุม",
        district_en: "Amphoe Kut Chum",
        children: [
          {
            subdistrict_th: "กุดชุม",
            subdistrict_en: "Kut Chum",
            post_code: "35140",
          },
          {
            subdistrict_th: "โนนเปือย",
            subdistrict_en: "Non Pueai",
            post_code: "35140",
          },
          {
            subdistrict_th: "กำแมด",
            subdistrict_en: "Kammaet",
            post_code: "35140",
          },
          {
            subdistrict_th: "นาโส่",
            subdistrict_en: "Na So",
            post_code: "35140",
          },
          {
            subdistrict_th: "ห้วยแก้ง",
            subdistrict_en: "Huai Kaeng",
            post_code: "35140",
          },
          {
            subdistrict_th: "หนองหมี",
            subdistrict_en: "Nong Mi",
            post_code: "35140",
          },
          {
            subdistrict_th: "โพนงาม",
            subdistrict_en: "Phon Ngam",
            post_code: "35140",
          },
          {
            subdistrict_th: "คำน้ำสร้าง",
            subdistrict_en: "Kham Nam Sang",
            post_code: "35140",
          },
          {
            subdistrict_th: "หนองแหน",
            subdistrict_en: "Nong Nae",
            post_code: "35140",
          },
        ],
      },
      {
        district_th: "อำเภอคำเขื่อนแก้ว",
        district_en: "Amphoe Kham Khuean Kaeo",
        children: [
          {
            subdistrict_th: "ลุมพุก",
            subdistrict_en: "Lumphuk",
            post_code: "35110",
          },
          {
            subdistrict_th: "ย่อ",
            subdistrict_en: "Yo",
            post_code: "35110",
          },
          {
            subdistrict_th: "สงเปือย",
            subdistrict_en: "Song Pueai",
            post_code: "35110",
          },
          {
            subdistrict_th: "โพนทัน",
            subdistrict_en: "Phon Than",
            post_code: "35110",
          },
          {
            subdistrict_th: "ทุ่งมน",
            subdistrict_en: "Thung Mon",
            post_code: "35110",
          },
          {
            subdistrict_th: "นาคำ",
            subdistrict_en: "Na Kham",
            post_code: "35180",
          },
          {
            subdistrict_th: "ดงแคนใหญ่",
            subdistrict_en: "Dong Khaen Yai",
            post_code: "35180",
          },
          {
            subdistrict_th: "กู่จาน",
            subdistrict_en: "Ku Chan",
            post_code: "35110",
          },
          {
            subdistrict_th: "นาแก",
            subdistrict_en: "Na Kae",
            post_code: "35180",
          },
          {
            subdistrict_th: "กุดกุง",
            subdistrict_en: "Kut Kung",
            post_code: "35110",
          },
          {
            subdistrict_th: "เหล่าไฮ",
            subdistrict_en: "Lao Hai",
            post_code: "35110",
          },
          {
            subdistrict_th: "แคนน้อย",
            subdistrict_en: "Khaen Noi",
            post_code: "35180",
          },
          {
            subdistrict_th: "ดงเจริญ",
            subdistrict_en: "Dong Charoen",
            post_code: "35110",
          },
        ],
      },
      {
        district_th: "อำเภอป่าติ้ว",
        district_en: "Amphoe Pa Tio",
        children: [
          {
            subdistrict_th: "โพธิ์ไทร",
            subdistrict_en: "Pho Sai",
            post_code: "35150",
          },
          {
            subdistrict_th: "กระจาย",
            subdistrict_en: "Krachai",
            post_code: "35150",
          },
          {
            subdistrict_th: "โคกนาโก",
            subdistrict_en: "Khok Na Ko",
            post_code: "35150",
          },
          {
            subdistrict_th: "เชียงเพ็ง",
            subdistrict_en: "Chiang Pheng",
            post_code: "35150",
          },
          {
            subdistrict_th: "ศรีฐาน",
            subdistrict_en: "Si Than",
            post_code: "35150",
          },
        ],
      },
      {
        district_th: "อำเภอมหาชนะชัย",
        district_en: "Amphoe Maha Chana Chai",
        children: [
          {
            subdistrict_th: "ฟ้าหยาด",
            subdistrict_en: "Fa Yat",
            post_code: "35130",
          },
          {
            subdistrict_th: "หัวเมือง",
            subdistrict_en: "Hua Mueang",
            post_code: "35130",
          },
          {
            subdistrict_th: "คูเมือง",
            subdistrict_en: "Khu Mueang",
            post_code: "35130",
          },
          {
            subdistrict_th: "ผือฮี",
            subdistrict_en: "Phue Hi",
            post_code: "35130",
          },
          {
            subdistrict_th: "บากเรือ",
            subdistrict_en: "Bak Ruea",
            post_code: "35130",
          },
          {
            subdistrict_th: "ม่วง",
            subdistrict_en: "Muang",
            post_code: "35130",
          },
          {
            subdistrict_th: "โนนทราย",
            subdistrict_en: "Non Sai",
            post_code: "35130",
          },
          {
            subdistrict_th: "บึงแก",
            subdistrict_en: "Bueng Kae",
            post_code: "35130",
          },
          {
            subdistrict_th: "พระเสาร์",
            subdistrict_en: "Phra Sao",
            post_code: "35130",
          },
          {
            subdistrict_th: "สงยาง",
            subdistrict_en: "Song Yang",
            post_code: "35130",
          },
        ],
      },
      {
        district_th: "อำเภอค้อวัง",
        district_en: "Amphoe Kho Wang",
        children: [
          {
            subdistrict_th: "ฟ้าห่วน",
            subdistrict_en: "Fa Huan",
            post_code: "35160",
          },
          {
            subdistrict_th: "กุดน้ำใส",
            subdistrict_en: "Kut Nam Sai",
            post_code: "35160",
          },
          {
            subdistrict_th: "น้ำอ้อม",
            subdistrict_en: "Nam Om",
            post_code: "35160",
          },
          {
            subdistrict_th: "ค้อวัง",
            subdistrict_en: "Kho Wang",
            post_code: "35160",
          },
        ],
      },
      {
        district_th: "อำเภอเลิงนกทา",
        district_en: "Amphoe Loeng Nok Tha",
        children: [
          {
            subdistrict_th: "บุ่งค้า",
            subdistrict_en: "Bung Kha",
            post_code: "35120",
          },
          {
            subdistrict_th: "สวาท",
            subdistrict_en: "Sawat",
            post_code: "35120",
          },
          {
            subdistrict_th: "ห้องแซง",
            subdistrict_en: "Hong Saeng",
            post_code: "35120",
          },
          {
            subdistrict_th: "สามัคคี",
            subdistrict_en: "Samakkhi",
            post_code: "35120",
          },
          {
            subdistrict_th: "กุดเชียงหมี",
            subdistrict_en: "Kut Chiang Mi",
            post_code: "35120",
          },
          {
            subdistrict_th: "สามแยก",
            subdistrict_en: "Sam Yaek",
            post_code: "35120",
          },
          {
            subdistrict_th: "กุดแห่",
            subdistrict_en: "Kut Hae",
            post_code: "35120",
          },
          {
            subdistrict_th: "โคกสำราญ",
            subdistrict_en: "Khok Samran",
            post_code: "35120",
          },
          {
            subdistrict_th: "สร้างมิ่ง",
            subdistrict_en: "Sang Ming",
            post_code: "35120",
          },
          {
            subdistrict_th: "ศรีแก้ว",
            subdistrict_en: "Si Kaeo",
            post_code: "35120",
          },
        ],
      },
      {
        district_th: "อำเภอไทยเจริญ",
        district_en: "Amphoe Thai Charoen",
        children: [
          {
            subdistrict_th: "ไทยเจริญ",
            subdistrict_en: "Thai Charoen",
            post_code: "35120",
          },
          {
            subdistrict_th: "น้ำคำ",
            subdistrict_en: "Nam Kham",
            post_code: "35120",
          },
          {
            subdistrict_th: "ส้มผ่อ",
            subdistrict_en: "Som Pho",
            post_code: "35120",
          },
          {
            subdistrict_th: "คำเตย",
            subdistrict_en: "Kham Toei",
            post_code: "35120",
          },
          {
            subdistrict_th: "คำไผ่",
            subdistrict_en: "Kham Phai",
            post_code: "35120",
          },
        ],
      },
    ],
  },
  {
    province_th: "ชัยภูมิ",
    province_en: "Chaiyaphum",
    children: [
      {
        district_th: "อำเภอเมืองชัยภูมิ",
        district_en: "Amphoe Mueang Chaiyaphum",
        children: [
          {
            subdistrict_th: "ในเมือง",
            subdistrict_en: "Nai Mueang",
            post_code: "36000",
          },
          {
            subdistrict_th: "รอบเมือง",
            subdistrict_en: "Rop Mueang",
            post_code: "36000",
          },
          {
            subdistrict_th: "โพนทอง",
            subdistrict_en: "Phon Thong",
            post_code: "36000",
          },
          {
            subdistrict_th: "นาฝาย",
            subdistrict_en: "Na Fai",
            post_code: "36000",
          },
          {
            subdistrict_th: "บ้านค่าย",
            subdistrict_en: "Ban Khai",
            post_code: "36240",
          },
          {
            subdistrict_th: "กุดตุ้ม",
            subdistrict_en: "Kut Tum",
            post_code: "36000",
          },
          {
            subdistrict_th: "ชีลอง",
            subdistrict_en: "Chi Long",
            post_code: "36000",
          },
          {
            subdistrict_th: "บ้านเล่า",
            subdistrict_en: "Ban Lao",
            post_code: "36000",
          },
          {
            subdistrict_th: "นาเสียว",
            subdistrict_en: "Na Siao",
            post_code: "36000",
          },
          {
            subdistrict_th: "หนองนาแซง",
            subdistrict_en: "Nong Na Saeng",
            post_code: "36000",
          },
          {
            subdistrict_th: "ลาดใหญ่",
            subdistrict_en: "Lat Yai",
            post_code: "36000",
          },
          {
            subdistrict_th: "หนองไผ่",
            subdistrict_en: "Nong Phai",
            post_code: "36240",
          },
          {
            subdistrict_th: "ท่าหินโงม",
            subdistrict_en: "Tha Hin Ngom",
            post_code: "36000",
          },
          {
            subdistrict_th: "ห้วยต้อน",
            subdistrict_en: "Huai Ton",
            post_code: "36000",
          },
          {
            subdistrict_th: "ห้วยบง",
            subdistrict_en: "Huai Bong",
            post_code: "36000",
          },
          {
            subdistrict_th: "โนนสำราญ",
            subdistrict_en: "Non Samran",
            post_code: "36240",
          },
          {
            subdistrict_th: "โคกสูง",
            subdistrict_en: "Khok Sung",
            post_code: "36000",
          },
          {
            subdistrict_th: "บุ่งคล้า",
            subdistrict_en: "Bung Khla",
            post_code: "36000",
          },
          {
            subdistrict_th: "ซับสีทอง",
            subdistrict_en: "Sap Si Thong",
            post_code: "36000",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านเขว้า",
        district_en: "Amphoe Ban Khwao",
        children: [
          {
            subdistrict_th: "บ้านเขว้า",
            subdistrict_en: "Ban Khwao",
            post_code: "36170",
          },
          {
            subdistrict_th: "ตลาดแร้ง",
            subdistrict_en: "Talat Raeng",
            post_code: "36170",
          },
          {
            subdistrict_th: "ลุ่มลำชี",
            subdistrict_en: "Lum Lam Chi",
            post_code: "36170",
          },
          {
            subdistrict_th: "ชีบน",
            subdistrict_en: "Chi Bon",
            post_code: "36170",
          },
          {
            subdistrict_th: "ภูแลนคา",
            subdistrict_en: "Phu Laen Kha",
            post_code: "36170",
          },
          {
            subdistrict_th: "โนนแดง",
            subdistrict_en: "Non Daeng",
            post_code: "36170",
          },
        ],
      },
      {
        district_th: "อำเภอคอนสวรรค์",
        district_en: "Amphoe Khon Sawan",
        children: [
          {
            subdistrict_th: "คอนสวรรค์",
            subdistrict_en: "Khon Sawan",
            post_code: "36140",
          },
          {
            subdistrict_th: "ยางหวาย",
            subdistrict_en: "Yang Wai",
            post_code: "36140",
          },
          {
            subdistrict_th: "ช่องสามหมอ",
            subdistrict_en: "Chong Sam Mo",
            post_code: "36140",
          },
          {
            subdistrict_th: "โนนสะอาด",
            subdistrict_en: "Non Sa-at",
            post_code: "36140",
          },
          {
            subdistrict_th: "ห้วยไร่",
            subdistrict_en: "Huai Rai",
            post_code: "36140",
          },
          {
            subdistrict_th: "บ้านโสก",
            subdistrict_en: "Ban Sok",
            post_code: "36140",
          },
          {
            subdistrict_th: "โคกมั่งงอย",
            subdistrict_en: "Khok Mang Ngoi",
            post_code: "36140",
          },
          {
            subdistrict_th: "หนองขาม",
            subdistrict_en: "Nong Kham",
            post_code: "36140",
          },
          {
            subdistrict_th: "ศรีสำราญ",
            subdistrict_en: "Si Samran",
            post_code: "36140",
          },
        ],
      },
      {
        district_th: "อำเภอเกษตรสมบูรณ์",
        district_en: "Amphoe Kaset Sombun",
        children: [
          {
            subdistrict_th: "บ้านยาง",
            subdistrict_en: "Ban Yang",
            post_code: "36120",
          },
          {
            subdistrict_th: "บ้านหัน",
            subdistrict_en: "Ban Han",
            post_code: "36120",
          },
          {
            subdistrict_th: "บ้านเดื่อ",
            subdistrict_en: "Ban Duea",
            post_code: "36120",
          },
          {
            subdistrict_th: "บ้านเป้า",
            subdistrict_en: "Ban Pao",
            post_code: "36120",
          },
          {
            subdistrict_th: "กุดเลาะ",
            subdistrict_en: "Kut Lo",
            post_code: "36120",
          },
          {
            subdistrict_th: "โนนกอก",
            subdistrict_en: "Non Kok",
            post_code: "36120",
          },
          {
            subdistrict_th: "สระโพนทอง",
            subdistrict_en: "Sa Phon Thong",
            post_code: "36120",
          },
          {
            subdistrict_th: "หนองข่า",
            subdistrict_en: "Nong Kha",
            post_code: "36120",
          },
          {
            subdistrict_th: "หนองโพนงาม",
            subdistrict_en: "Nong Phon Ngam",
            post_code: "36120",
          },
          {
            subdistrict_th: "บ้านบัว",
            subdistrict_en: "Ban Bua",
            post_code: "36120",
          },
          {
            subdistrict_th: "โนนทอง",
            subdistrict_en: "Non Thong",
            post_code: "36120",
          },
        ],
      },
      {
        district_th: "อำเภอหนองบัวแดง",
        district_en: "Amphoe Nong Bua Daeng",
        children: [
          {
            subdistrict_th: "หนองบัวแดง",
            subdistrict_en: "Nong Bua Daeng",
            post_code: "36210",
          },
          {
            subdistrict_th: "กุดชุมแสง",
            subdistrict_en: "Kut Chum Saeng",
            post_code: "36210",
          },
          {
            subdistrict_th: "ถ้ำวัวแดง",
            subdistrict_en: "Tham Wua Daeng",
            post_code: "36210",
          },
          {
            subdistrict_th: "นางแดด",
            subdistrict_en: "Nang Daet",
            post_code: "36210",
          },
          {
            subdistrict_th: "หนองแวง",
            subdistrict_en: "Nong Waeng",
            post_code: "36210",
          },
          {
            subdistrict_th: "คูเมือง",
            subdistrict_en: "Khu Mueang",
            post_code: "36210",
          },
          {
            subdistrict_th: "ท่าใหญ่",
            subdistrict_en: "Tha Yai",
            post_code: "36210",
          },
          {
            subdistrict_th: "วังชมภู",
            subdistrict_en: "Wang Chomphu",
            post_code: "36210",
          },
        ],
      },
      {
        district_th: "อำเภอจัตุรัส",
        district_en: "Amphoe Chatturat",
        children: [
          {
            subdistrict_th: "บ้านกอก",
            subdistrict_en: "Ban Kok",
            post_code: "36130",
          },
          {
            subdistrict_th: "หนองบัวบาน",
            subdistrict_en: "Nong Bua Ban",
            post_code: "36130",
          },
          {
            subdistrict_th: "บ้านขาม",
            subdistrict_en: "Ban Kham",
            post_code: "36130",
          },
          {
            subdistrict_th: "กุดน้ำใส",
            subdistrict_en: "Kut Nam Sai",
            post_code: "36130",
          },
          {
            subdistrict_th: "หนองโดน",
            subdistrict_en: "Nong Don",
            post_code: "36130",
          },
          {
            subdistrict_th: "ละหาน",
            subdistrict_en: "Lahan",
            post_code: "36130",
          },
          {
            subdistrict_th: "หนองบัวใหญ่",
            subdistrict_en: "Nong Bua Yai",
            post_code: "36130",
          },
          {
            subdistrict_th: "หนองบัวโคก",
            subdistrict_en: "Nong Bua Khok",
            post_code: "36220",
          },
          {
            subdistrict_th: "ส้มป่อย",
            subdistrict_en: "Som Poi",
            post_code: "36130",
          },
        ],
      },
      {
        district_th: "อำเภอบำเหน็จณรงค์",
        district_en: "Amphoe Bamnet Narong",
        children: [
          {
            subdistrict_th: "บ้านชวน",
            subdistrict_en: "Ban Chuan",
            post_code: "36160",
          },
          {
            subdistrict_th: "บ้านเพชร",
            subdistrict_en: "Ban Phet",
            post_code: "36160",
          },
          {
            subdistrict_th: "บ้านตาล",
            subdistrict_en: "Ban Tan",
            post_code: "36220",
          },
          {
            subdistrict_th: "หัวทะเล",
            subdistrict_en: "Hua Thale",
            post_code: "36220",
          },
          {
            subdistrict_th: "โคกเริงรมย์",
            subdistrict_en: "Khok Roeng Rom",
            post_code: "36160",
          },
          {
            subdistrict_th: "เกาะมะนาว",
            subdistrict_en: "Ko Manao",
            post_code: "36160",
          },
          {
            subdistrict_th: "โคกเพชรพัฒนา",
            subdistrict_en: "Khok Phet Phatthana",
            post_code: "36160",
          },
        ],
      },
      {
        district_th: "อำเภอหนองบัวระเหว",
        district_en: "Amphoe Nong Bua Rawe",
        children: [
          {
            subdistrict_th: "หนองบัวระเหว",
            subdistrict_en: "Nong Bua Rawe",
            post_code: "36250",
          },
          {
            subdistrict_th: "วังตะเฆ่",
            subdistrict_en: "Wang Takhe",
            post_code: "36250",
          },
          {
            subdistrict_th: "ห้วยแย้",
            subdistrict_en: "Huai Yae",
            post_code: "36250",
          },
          {
            subdistrict_th: "โคกสะอาด",
            subdistrict_en: "Khok Sa-at",
            post_code: "36250",
          },
          {
            subdistrict_th: "โสกปลาดุก",
            subdistrict_en: "Sok Pla Duk",
            post_code: "36250",
          },
        ],
      },
      {
        district_th: "อำเภอเทพสถิต",
        district_en: "Amphoe Thep Sathit",
        children: [
          {
            subdistrict_th: "วะตะแบก",
            subdistrict_en: "Wa Tabaek",
            post_code: "36230",
          },
          {
            subdistrict_th: "ห้วยยายจิ๋ว",
            subdistrict_en: "Huai Yai Chio",
            post_code: "36230",
          },
          {
            subdistrict_th: "นายางกลัก",
            subdistrict_en: "Na yang Klak",
            post_code: "36230",
          },
          {
            subdistrict_th: "บ้านไร่",
            subdistrict_en: "Ban Rai",
            post_code: "36230",
          },
          {
            subdistrict_th: "โป่งนก",
            subdistrict_en: "Pong Nok",
            post_code: "36230",
          },
        ],
      },
      {
        district_th: "อำเภอภูเขียว",
        district_en: "Amphoe Phu Khiao",
        children: [
          {
            subdistrict_th: "ผักปัง",
            subdistrict_en: "Phak Pang",
            post_code: "36110",
          },
          {
            subdistrict_th: "กวางโจน",
            subdistrict_en: "Kwang Chon",
            post_code: "36110",
          },
          {
            subdistrict_th: "หนองคอนไทย",
            subdistrict_en: "Nong Khon Thai",
            post_code: "36110",
          },
          {
            subdistrict_th: "บ้านแก้ง",
            subdistrict_en: "Ban Kaeng",
            post_code: "36110",
          },
          {
            subdistrict_th: "กุดยม",
            subdistrict_en: "Kut Yom",
            post_code: "36110",
          },
          {
            subdistrict_th: "บ้านเพชร",
            subdistrict_en: "Ban Phet",
            post_code: "36110",
          },
          {
            subdistrict_th: "โคกสะอาด",
            subdistrict_en: "Khok Sa-at",
            post_code: "36110",
          },
          {
            subdistrict_th: "หนองตูม",
            subdistrict_en: "Nong Tum",
            post_code: "36110",
          },
          {
            subdistrict_th: "โอโล",
            subdistrict_en: "Olo",
            post_code: "36110",
          },
          {
            subdistrict_th: "ธาตุทอง",
            subdistrict_en: "That Thong",
            post_code: "36110",
          },
          {
            subdistrict_th: "บ้านดอน",
            subdistrict_en: "Ban Don",
            post_code: "36110",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านแท่น",
        district_en: "Amphoe Ban Thaen",
        children: [
          {
            subdistrict_th: "บ้านแท่น",
            subdistrict_en: "Ban Thaen",
            post_code: "36190",
          },
          {
            subdistrict_th: "สามสวน",
            subdistrict_en: "Sam Suan",
            post_code: "36190",
          },
          {
            subdistrict_th: "สระพัง",
            subdistrict_en: "Sa Phang",
            post_code: "36190",
          },
          {
            subdistrict_th: "บ้านเต่า",
            subdistrict_en: "Ban Tao",
            post_code: "36190",
          },
          {
            subdistrict_th: "หนองคู",
            subdistrict_en: "Nong Khu",
            post_code: "36190",
          },
        ],
      },
      {
        district_th: "อำเภอแก้งคร้อ",
        district_en: "Amphoe Kaeng Khro",
        children: [
          {
            subdistrict_th: "ช่องสามหมอ",
            subdistrict_en: "Chong Sam Mo",
            post_code: "36150",
          },
          {
            subdistrict_th: "หนองขาม",
            subdistrict_en: "Nong Kham",
            post_code: "36150",
          },
          {
            subdistrict_th: "นาหนองทุ่ม",
            subdistrict_en: "Na Nong Thum",
            post_code: "36150",
          },
          {
            subdistrict_th: "บ้านแก้ง",
            subdistrict_en: "Ban Kaeng",
            post_code: "36150",
          },
          {
            subdistrict_th: "หนองสังข์",
            subdistrict_en: "Nong Sang",
            post_code: "36150",
          },
          {
            subdistrict_th: "หลุบคา",
            subdistrict_en: "Lup Kha",
            post_code: "36150",
          },
          {
            subdistrict_th: "โคกกุง",
            subdistrict_en: "Khok Kung",
            post_code: "36150",
          },
          {
            subdistrict_th: "เก่าย่าดี",
            subdistrict_en: "Kao Ya Di",
            post_code: "36150",
          },
          {
            subdistrict_th: "ท่ามะไฟหวาน",
            subdistrict_en: "Tha Mafai Wan",
            post_code: "36150",
          },
          {
            subdistrict_th: "หนองไผ่",
            subdistrict_en: "Nong Phai",
            post_code: "36150",
          },
        ],
      },
      {
        district_th: "อำเภอคอนสาร",
        district_en: "Amphoe Khon San",
        children: [
          {
            subdistrict_th: "คอนสาร",
            subdistrict_en: "Khon San",
            post_code: "36180",
          },
          {
            subdistrict_th: "ทุ่งพระ",
            subdistrict_en: "Thung Phra",
            post_code: "36180",
          },
          {
            subdistrict_th: "โนนคูณ",
            subdistrict_en: "Non Khun",
            post_code: "36180",
          },
          {
            subdistrict_th: "ห้วยยาง",
            subdistrict_en: "Huai Yang",
            post_code: "36180",
          },
          {
            subdistrict_th: "ทุ่งลุยลาย",
            subdistrict_en: "Thung Luilai",
            post_code: "36180",
          },
          {
            subdistrict_th: "ดงบัง",
            subdistrict_en: "Dong Bang",
            post_code: "36180",
          },
          {
            subdistrict_th: "ทุ่งนาเลา",
            subdistrict_en: "Thung Na Lao",
            post_code: "36180",
          },
          {
            subdistrict_th: "ดงกลาง",
            subdistrict_en: "Dong Klang",
            post_code: "36180",
          },
        ],
      },
      {
        district_th: "อำเภอภักดีชุมพล",
        district_en: "Amphoe Phakdi Chumphon",
        children: [
          {
            subdistrict_th: "บ้านเจียง",
            subdistrict_en: "Ban Chiang",
            post_code: "36260",
          },
          {
            subdistrict_th: "เจาทอง",
            subdistrict_en: "Chao Thong",
            post_code: "36260",
          },
          {
            subdistrict_th: "วังทอง",
            subdistrict_en: "Wang Thong",
            post_code: "36260",
          },
          {
            subdistrict_th: "แหลมทอง",
            subdistrict_en: "Laem Thong",
            post_code: "36260",
          },
        ],
      },
      {
        district_th: "อำเภอเนินสง่า",
        district_en: "Amphoe Noen Sa-nga",
        children: [
          {
            subdistrict_th: "หนองฉิม",
            subdistrict_en: "Nong Chim",
            post_code: "36130",
          },
          {
            subdistrict_th: "ตาเนิน",
            subdistrict_en: "Ta Noen",
            post_code: "36130",
          },
          {
            subdistrict_th: "กะฮาด",
            subdistrict_en: "Kahat",
            post_code: "36130",
          },
          {
            subdistrict_th: "รังงาม",
            subdistrict_en: "Rang Ngam",
            post_code: "36130",
          },
        ],
      },
      {
        district_th: "อำเภอซับใหญ่",
        district_en: "Amphoe Sap Yai",
        children: [
          {
            subdistrict_th: "ซับใหญ่",
            subdistrict_en: "Sap Yai",
            post_code: "36130",
          },
          {
            subdistrict_th: "ท่ากูบ",
            subdistrict_en: "Tha Kup",
            post_code: "36130",
          },
          {
            subdistrict_th: "ตะโกทอง",
            subdistrict_en: "Tako Thong",
            post_code: "36130",
          },
        ],
      },
    ],
  },
  {
    province_th: "อำนาจเจริญ",
    province_en: "Amnat Charoen",
    children: [
      {
        district_th: "อำเภอเมืองอำนาจเจริญ",
        district_en: "Amphoe Mueang Amnat Charoen",
        children: [
          {
            subdistrict_th: "บุ่ง",
            subdistrict_en: "Bung",
            post_code: "37000",
          },
          {
            subdistrict_th: "ไก่คำ",
            subdistrict_en: "Kai Kham",
            post_code: "37000",
          },
          {
            subdistrict_th: "นาจิก",
            subdistrict_en: "Na Chik",
            post_code: "37000",
          },
          {
            subdistrict_th: "ปลาค้าว",
            subdistrict_en: "Pla Khao",
            post_code: "37000",
          },
          {
            subdistrict_th: "เหล่าพรวน",
            subdistrict_en: "Lao Phruan",
            post_code: "37000",
          },
          {
            subdistrict_th: "สร้างนกทา",
            subdistrict_en: "Sang Nok Tha",
            post_code: "37000",
          },
          {
            subdistrict_th: "คึมใหญ่",
            subdistrict_en: "Khuem Yai",
            post_code: "37000",
          },
          {
            subdistrict_th: "นาผือ",
            subdistrict_en: "Na Phue",
            post_code: "37000",
          },
          {
            subdistrict_th: "น้ำปลีก",
            subdistrict_en: "Nam Plik",
            post_code: "37000",
          },
          {
            subdistrict_th: "นาวัง",
            subdistrict_en: "Na Wang",
            post_code: "37000",
          },
          {
            subdistrict_th: "นาหมอม้า",
            subdistrict_en: "Na Mo Ma",
            post_code: "37000",
          },
          {
            subdistrict_th: "โนนโพธิ์",
            subdistrict_en: "Non Pho",
            post_code: "37000",
          },
          {
            subdistrict_th: "โนนหนามแท่ง",
            subdistrict_en: "Non Nam Thaeng",
            post_code: "37000",
          },
          {
            subdistrict_th: "ห้วยไร่",
            subdistrict_en: "Huai Rai",
            post_code: "37000",
          },
          {
            subdistrict_th: "หนองมะแซว",
            subdistrict_en: "Nong Masaeo",
            post_code: "37000",
          },
          {
            subdistrict_th: "กุดปลาดุก",
            subdistrict_en: "Kut Pla Duk",
            post_code: "37000",
          },
          {
            subdistrict_th: "ดอนเมย",
            subdistrict_en: "Don Moei",
            post_code: "37000",
          },
          {
            subdistrict_th: "นายม",
            subdistrict_en: "Na Yom",
            post_code: "37000",
          },
          {
            subdistrict_th: "นาแต้",
            subdistrict_en: "Na Tae",
            post_code: "37000",
          },
        ],
      },
      {
        district_th: "อำเภอชานุมาน",
        district_en: "Amphoe Chanuman",
        children: [
          {
            subdistrict_th: "ชานุมาน",
            subdistrict_en: "Chanuman",
            post_code: "37210",
          },
          {
            subdistrict_th: "โคกสาร",
            subdistrict_en: "Khok San",
            post_code: "37210",
          },
          {
            subdistrict_th: "คำเขื่อนแก้ว",
            subdistrict_en: "Kham Khuean Kaeo",
            post_code: "37210",
          },
          {
            subdistrict_th: "โคกก่ง",
            subdistrict_en: "Khok Kong",
            post_code: "37210",
          },
          {
            subdistrict_th: "ป่าก่อ",
            subdistrict_en: "Pa Ko",
            post_code: "37210",
          },
        ],
      },
      {
        district_th: "อำเภอปทุมราชวงศา",
        district_en: "Amphoe Pathum Ratchawongsa",
        children: [
          {
            subdistrict_th: "หนองข่า",
            subdistrict_en: "Nong Kha",
            post_code: "37110",
          },
          {
            subdistrict_th: "คำโพน",
            subdistrict_en: "Kham Phon",
            post_code: "37110",
          },
          {
            subdistrict_th: "นาหว้า",
            subdistrict_en: "Na Wa",
            post_code: "37110",
          },
          {
            subdistrict_th: "ลือ",
            subdistrict_en: "Lue",
            post_code: "37110",
          },
          {
            subdistrict_th: "ห้วย",
            subdistrict_en: "Huai",
            post_code: "37110",
          },
          {
            subdistrict_th: "โนนงาม",
            subdistrict_en: "Non Ngam",
            post_code: "37110",
          },
          {
            subdistrict_th: "นาป่าแซง",
            subdistrict_en: "Na Pa Saeng",
            post_code: "37110",
          },
        ],
      },
      {
        district_th: "อำเภอพนา",
        district_en: "Amphoe Phana",
        children: [
          {
            subdistrict_th: "พนา",
            subdistrict_en: "Phana",
            post_code: "37180",
          },
          {
            subdistrict_th: "จานลาน",
            subdistrict_en: "Chan Lan",
            post_code: "37180",
          },
          {
            subdistrict_th: "ไม้กลอน",
            subdistrict_en: "Mai Klon",
            post_code: "37180",
          },
          {
            subdistrict_th: "พระเหลา",
            subdistrict_en: "Phra Lao",
            post_code: "37180",
          },
        ],
      },
      {
        district_th: "อำเภอเสนางคนิคม",
        district_en: "Amphoe Senangkhanikhom",
        children: [
          {
            subdistrict_th: "เสนางคนิคม",
            subdistrict_en: "Senangkhanikhom",
            post_code: "37290",
          },
          {
            subdistrict_th: "โพนทอง",
            subdistrict_en: "Phon Thong",
            post_code: "37290",
          },
          {
            subdistrict_th: "ไร่สีสุก",
            subdistrict_en: "Rai Si Suk",
            post_code: "37290",
          },
          {
            subdistrict_th: "นาเวียง",
            subdistrict_en: "Na Wiang",
            post_code: "37290",
          },
          {
            subdistrict_th: "หนองไฮ",
            subdistrict_en: "Nong Hai",
            post_code: "37290",
          },
          {
            subdistrict_th: "หนองสามสี",
            subdistrict_en: "Nong Sam Si",
            post_code: "37290",
          },
        ],
      },
      {
        district_th: "อำเภอหัวตะพาน",
        district_en: "Amphoe Hua Taphan",
        children: [
          {
            subdistrict_th: "หัวตะพาน",
            subdistrict_en: "Hua Taphan",
            post_code: "37240",
          },
          {
            subdistrict_th: "คำพระ",
            subdistrict_en: "Kham Phra",
            post_code: "37240",
          },
          {
            subdistrict_th: "เค็งใหญ่",
            subdistrict_en: "Kheng Yai",
            post_code: "37240",
          },
          {
            subdistrict_th: "หนองแก้ว",
            subdistrict_en: "Nong Kaeo",
            post_code: "37240",
          },
          {
            subdistrict_th: "โพนเมืองน้อย",
            subdistrict_en: "Phon Mueang Noi",
            post_code: "37240",
          },
          {
            subdistrict_th: "สร้างถ่อน้อย",
            subdistrict_en: "Sang Tho Noi",
            post_code: "37240",
          },
          {
            subdistrict_th: "จิกดู่",
            subdistrict_en: "Chik Du",
            post_code: "37240",
          },
          {
            subdistrict_th: "รัตนวารี",
            subdistrict_en: "Rattanawari",
            post_code: "37240",
          },
        ],
      },
      {
        district_th: "อำเภอลืออำนาจ",
        district_en: "Amphoe Lue Amnat",
        children: [
          {
            subdistrict_th: "อำนาจ",
            subdistrict_en: "amnat",
            post_code: "37120",
          },
          {
            subdistrict_th: "ดงมะยาง",
            subdistrict_en: "Dong Mayang",
            post_code: "37120",
          },
          {
            subdistrict_th: "เปือย",
            subdistrict_en: "Pueai",
            post_code: "37120",
          },
          {
            subdistrict_th: "ดงบัง",
            subdistrict_en: "Dong Bang",
            post_code: "37120",
          },
          {
            subdistrict_th: "ไร่ขี",
            subdistrict_en: "Rai Khi",
            post_code: "37120",
          },
          {
            subdistrict_th: "แมด",
            subdistrict_en: "Maet",
            post_code: "37120",
          },
          {
            subdistrict_th: "โคกกลาง",
            subdistrict_en: "Khok Klang",
            post_code: "37120",
          },
        ],
      },
    ],
  },
  {
    province_th: "บึงกาฬ",
    province_en: "Bueng Kan",
    children: [
      {
        district_th: "อำเภอเมืองบึงกาฬ",
        district_en: "Amphoe Mueang Bueng Kan",
        children: [
          {
            subdistrict_th: "บึงกาฬ",
            subdistrict_en: "Bueng Kan",
            post_code: "38000",
          },
          {
            subdistrict_th: "โนนสมบูรณ์",
            subdistrict_en: "Non Sombun",
            post_code: "38000",
          },
          {
            subdistrict_th: "โนนสว่าง",
            subdistrict_en: "Non Sawang",
            post_code: "38000",
          },
          {
            subdistrict_th: "หอคำ",
            subdistrict_en: "Ho Kham",
            post_code: "38000",
          },
          {
            subdistrict_th: "หนองเลิง",
            subdistrict_en: "Nong Loeng",
            post_code: "38000",
          },
          {
            subdistrict_th: "โคกก่อง",
            subdistrict_en: "Khok Kong",
            post_code: "38000",
          },
          {
            subdistrict_th: "นาสวรรค์",
            subdistrict_en: "Na Sawan",
            post_code: "38000",
          },
          {
            subdistrict_th: "ไคสี",
            subdistrict_en: "Khai Si",
            post_code: "38000",
          },
          {
            subdistrict_th: "ชัยพร",
            subdistrict_en: "Chaiyaphon",
            post_code: "38000",
          },
          {
            subdistrict_th: "วิศิษฐ์",
            subdistrict_en: "Wisit",
            post_code: "38000",
          },
          {
            subdistrict_th: "คำนาดี",
            subdistrict_en: "Kham Na Di",
            post_code: "38000",
          },
          {
            subdistrict_th: "โป่งเปือย",
            subdistrict_en: "Pong Pueai",
            post_code: "38000",
          },
        ],
      },
      {
        district_th: "อำเภอพรเจริญ",
        district_en: "Amphoe Phon Charoen",
        children: [
          {
            subdistrict_th: "ศรีชมภู",
            subdistrict_en: "Si Chomphu",
            post_code: "38180",
          },
          {
            subdistrict_th: "ดอนหญ้านาง",
            subdistrict_en: "Don Ya Nang",
            post_code: "38180",
          },
          {
            subdistrict_th: "พรเจริญ",
            subdistrict_en: "Phon Charoen",
            post_code: "38180",
          },
          {
            subdistrict_th: "หนองหัวช้าง",
            subdistrict_en: "Nong Hua Chang",
            post_code: "38180",
          },
          {
            subdistrict_th: "วังชมภู",
            subdistrict_en: "Wang Chomphu",
            post_code: "38180",
          },
          {
            subdistrict_th: "ป่าแฝก",
            subdistrict_en: "Pa Faek",
            post_code: "38180",
          },
          {
            subdistrict_th: "ศรีสำราญ",
            subdistrict_en: "Si Samran",
            post_code: "38180",
          },
        ],
      },
      {
        district_th: "อำเภอโซ่พิสัย",
        district_en: "Amphoe So Phisai",
        children: [
          {
            subdistrict_th: "โซ่",
            subdistrict_en: "So",
            post_code: "38170",
          },
          {
            subdistrict_th: "หนองพันทา",
            subdistrict_en: "Nong Phan Tha",
            post_code: "38170",
          },
          {
            subdistrict_th: "ศรีชมภู",
            subdistrict_en: "Si Chomphu",
            post_code: "38170",
          },
          {
            subdistrict_th: "คำแก้ว",
            subdistrict_en: "Kham Kaeo",
            post_code: "38170",
          },
          {
            subdistrict_th: "บัวตูม",
            subdistrict_en: "Bua Tum",
            post_code: "38170",
          },
          {
            subdistrict_th: "ถ้ำเจริญ",
            subdistrict_en: "Tham Charoen",
            post_code: "38170",
          },
          {
            subdistrict_th: "เหล่าทอง",
            subdistrict_en: "Lao Thong",
            post_code: "38170",
          },
        ],
      },
      {
        district_th: "อำเภอเซกา",
        district_en: "Amphoe Seka",
        children: [
          {
            subdistrict_th: "เซกา",
            subdistrict_en: "Seka",
            post_code: "38150",
          },
          {
            subdistrict_th: "ซาง",
            subdistrict_en: "Sang",
            post_code: "38150",
          },
          {
            subdistrict_th: "ท่ากกแดง",
            subdistrict_en: "Tha Kok Daeng",
            post_code: "38150",
          },
          {
            subdistrict_th: "บ้านต้อง",
            subdistrict_en: "Ban Tong",
            post_code: "38150",
          },
          {
            subdistrict_th: "ป่งไฮ",
            subdistrict_en: "Pong Hai",
            post_code: "38150",
          },
          {
            subdistrict_th: "น้ำจั้น",
            subdistrict_en: "Nam Chan",
            post_code: "38150",
          },
          {
            subdistrict_th: "ท่าสะอาด",
            subdistrict_en: "Tha Sa-at",
            post_code: "38150",
          },
          {
            subdistrict_th: "หนองทุ่ม",
            subdistrict_en: "Nong Thum",
            post_code: "38150",
          },
          {
            subdistrict_th: "โสกก่าม",
            subdistrict_en: "Sok Kam",
            post_code: "38150",
          },
        ],
      },
      {
        district_th: "อำเภอปากคาด",
        district_en: "Amphoe Pak Khat",
        children: [
          {
            subdistrict_th: "ปากคาด",
            subdistrict_en: "Pak Khat",
            post_code: "38190",
          },
          {
            subdistrict_th: "หนองยอง",
            subdistrict_en: "Nong Yong",
            post_code: "38190",
          },
          {
            subdistrict_th: "นากั้ง",
            subdistrict_en: "Na Kang",
            post_code: "38190",
          },
          {
            subdistrict_th: "โนนศิลา",
            subdistrict_en: "Non Sila",
            post_code: "38190",
          },
          {
            subdistrict_th: "สมสนุก",
            subdistrict_en: "Som Sanuk",
            post_code: "38190",
          },
          {
            subdistrict_th: "นาดง",
            subdistrict_en: "Na Dong",
            post_code: "38190",
          },
        ],
      },
      {
        district_th: "อำเภอบึงโขงหลง",
        district_en: "Amphoe Bueng Khong Long",
        children: [
          {
            subdistrict_th: "บึงโขงหลง",
            subdistrict_en: "Bueng Khong Long",
            post_code: "38220",
          },
          {
            subdistrict_th: "โพธิ์หมากแข้ง",
            subdistrict_en: "Pho Mak Khaeng",
            post_code: "38220",
          },
          {
            subdistrict_th: "ดงบัง",
            subdistrict_en: "Dong Bang",
            post_code: "38220",
          },
          {
            subdistrict_th: "ท่าดอกคำ",
            subdistrict_en: "Tha Dok Kham",
            post_code: "38220",
          },
        ],
      },
      {
        district_th: "อำเภอศรีวิไล",
        district_en: "Amphoe Si Wilai",
        children: [
          {
            subdistrict_th: "ศรีวิไล",
            subdistrict_en: "Si Wilai",
            post_code: "38210",
          },
          {
            subdistrict_th: "ชุมภูพร",
            subdistrict_en: "Chumphu Phon",
            post_code: "38210",
          },
          {
            subdistrict_th: "นาแสง",
            subdistrict_en: "Na Saeng",
            post_code: "38210",
          },
          {
            subdistrict_th: "นาสะแบง",
            subdistrict_en: "Na Sabaeng",
            post_code: "38210",
          },
          {
            subdistrict_th: "นาสิงห์",
            subdistrict_en: "Na Sing",
            post_code: "38210",
          },
        ],
      },
      {
        district_th: "อำเภอบุ่งคล้า",
        district_en: "Amphoe Bung Khla",
        children: [
          {
            subdistrict_th: "บุ่งคล้า",
            subdistrict_en: "Bung Khla",
            post_code: "38000",
          },
          {
            subdistrict_th: "หนองเดิ่น",
            subdistrict_en: "Nong Doen",
            post_code: "38000",
          },
          {
            subdistrict_th: "โคกกว้าง",
            subdistrict_en: "Khok Kwang",
            post_code: "38000",
          },
        ],
      },
    ],
  },
  {
    province_th: "หนองบัวลำภู",
    province_en: "Nong Bua Lam Phu",
    children: [
      {
        district_th: "อำเภอเมืองหนองบัวลำภู",
        district_en: "Amphoe Mueang Nong Bua Lam Phu",
        children: [
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "39000",
          },
          {
            subdistrict_th: "หนองภัยศูนย์",
            subdistrict_en: "Nong Phai Sun",
            post_code: "39000",
          },
          {
            subdistrict_th: "โพธิ์ชัย",
            subdistrict_en: "Pho Chai",
            post_code: "39000",
          },
          {
            subdistrict_th: "หนองสวรรค์",
            subdistrict_en: "Nong Sawan",
            post_code: "39000",
          },
          {
            subdistrict_th: "หัวนา",
            subdistrict_en: "Hua Na",
            post_code: "39000",
          },
          {
            subdistrict_th: "บ้านขาม",
            subdistrict_en: "Ban Kham",
            post_code: "39000",
          },
          {
            subdistrict_th: "นามะเฟือง",
            subdistrict_en: "Na Mafueang",
            post_code: "39000",
          },
          {
            subdistrict_th: "บ้านพร้าว",
            subdistrict_en: "Ban Phrao",
            post_code: "39000",
          },
          {
            subdistrict_th: "โนนขมิ้น",
            subdistrict_en: "Non Khamin",
            post_code: "39000",
          },
          {
            subdistrict_th: "ลำภู",
            subdistrict_en: "Lumphu",
            post_code: "39000",
          },
          {
            subdistrict_th: "กุดจิก",
            subdistrict_en: "Kut Chik",
            post_code: "39000",
          },
          {
            subdistrict_th: "โนนทัน",
            subdistrict_en: "Non Than",
            post_code: "39000",
          },
          {
            subdistrict_th: "นาคำไฮ",
            subdistrict_en: "Na Kham Hai",
            post_code: "39000",
          },
          {
            subdistrict_th: "ป่าไม้งาม",
            subdistrict_en: "Pa Mai Ngam",
            post_code: "39000",
          },
          {
            subdistrict_th: "หนองหว้า",
            subdistrict_en: "Nong Wa",
            post_code: "39000",
          },
        ],
      },
      {
        district_th: "อำเภอนากลาง",
        district_en: "Amphoe Na Klang",
        children: [
          {
            subdistrict_th: "นากลาง",
            subdistrict_en: "Na Klang",
            post_code: "39170",
          },
          {
            subdistrict_th: "ด่านช้าง",
            subdistrict_en: "Dan Chang",
            post_code: "39170",
          },
          {
            subdistrict_th: "กุดดินจี่",
            subdistrict_en: "Kut Din Chi",
            post_code: "39350",
          },
          {
            subdistrict_th: "ฝั่งแดง",
            subdistrict_en: "Fang Daeng",
            post_code: "39170",
          },
          {
            subdistrict_th: "เก่ากลอย",
            subdistrict_en: "Kao Kloi",
            post_code: "39350",
          },
          {
            subdistrict_th: "โนนเมือง",
            subdistrict_en: "Non Mueang",
            post_code: "39170",
          },
          {
            subdistrict_th: "อุทัยสวรรค์",
            subdistrict_en: "Uthai Sawan",
            post_code: "39170",
          },
          {
            subdistrict_th: "ดงสวรรค์",
            subdistrict_en: "Dong Sawan",
            post_code: "39350",
          },
          {
            subdistrict_th: "กุดแห่",
            subdistrict_en: "Kut Hae",
            post_code: "39170",
          },
        ],
      },
      {
        district_th: "อำเภอโนนสัง",
        district_en: "Amphoe Non Sang",
        children: [
          {
            subdistrict_th: "โนนสัง",
            subdistrict_en: "Non Sang",
            post_code: "39140",
          },
          {
            subdistrict_th: "บ้านถิ่น",
            subdistrict_en: "Ban Thin",
            post_code: "39140",
          },
          {
            subdistrict_th: "หนองเรือ",
            subdistrict_en: "Nong Ruea",
            post_code: "39140",
          },
          {
            subdistrict_th: "กุดดู่",
            subdistrict_en: "Kut Du",
            post_code: "39140",
          },
          {
            subdistrict_th: "บ้านค้อ",
            subdistrict_en: "Ban Kho",
            post_code: "39140",
          },
          {
            subdistrict_th: "โนนเมือง",
            subdistrict_en: "Non Mueang",
            post_code: "39140",
          },
          {
            subdistrict_th: "โคกใหญ่",
            subdistrict_en: "Khok Yai",
            post_code: "39140",
          },
          {
            subdistrict_th: "โคกม่วง",
            subdistrict_en: "Khok Muang",
            post_code: "39140",
          },
          {
            subdistrict_th: "นิคมพัฒนา",
            subdistrict_en: "Nikhom Phatthana",
            post_code: "39140",
          },
          {
            subdistrict_th: "ปางกู่",
            subdistrict_en: "Pang Ku",
            post_code: "39140",
          },
        ],
      },
      {
        district_th: "อำเภอศรีบุญเรือง",
        district_en: "Amphoe Si Bun Rueang",
        children: [
          {
            subdistrict_th: "เมืองใหม่",
            subdistrict_en: "Mueang Mai",
            post_code: "39180",
          },
          {
            subdistrict_th: "ศรีบุญเรือง",
            subdistrict_en: "Si Bun Rueang",
            post_code: "39180",
          },
          {
            subdistrict_th: "หนองบัวใต้",
            subdistrict_en: "Nong Bua Tai",
            post_code: "39180",
          },
          {
            subdistrict_th: "กุดสะเทียน",
            subdistrict_en: "Kut Sathian",
            post_code: "39180",
          },
          {
            subdistrict_th: "นากอก",
            subdistrict_en: "Na Kok",
            post_code: "39180",
          },
          {
            subdistrict_th: "โนนสะอาด",
            subdistrict_en: "Non Sa-at",
            post_code: "39180",
          },
          {
            subdistrict_th: "ยางหล่อ",
            subdistrict_en: "Yang Lo",
            post_code: "39180",
          },
          {
            subdistrict_th: "โนนม่วง",
            subdistrict_en: "Non Muang",
            post_code: "39180",
          },
          {
            subdistrict_th: "หนองกุงแก้ว",
            subdistrict_en: "Nong Kung Kaeo",
            post_code: "39180",
          },
          {
            subdistrict_th: "หนองแก",
            subdistrict_en: "Nong Kae",
            post_code: "39180",
          },
          {
            subdistrict_th: "ทรายทอง",
            subdistrict_en: "Sai Thong",
            post_code: "39180",
          },
          {
            subdistrict_th: "หันนางาม",
            subdistrict_en: "Han Na Ngam",
            post_code: "39180",
          },
        ],
      },
      {
        district_th: "อำเภอสุวรรณคูหา",
        district_en: "Amphoe Suwannakhuha",
        children: [
          {
            subdistrict_th: "นาสี",
            subdistrict_en: "Na Si",
            post_code: "39270",
          },
          {
            subdistrict_th: "บ้านโคก",
            subdistrict_en: "Ban Khok",
            post_code: "39270",
          },
          {
            subdistrict_th: "นาดี",
            subdistrict_en: "Na Di",
            post_code: "39270",
          },
          {
            subdistrict_th: "นาด่าน",
            subdistrict_en: "Na Dan",
            post_code: "39270",
          },
          {
            subdistrict_th: "ดงมะไฟ",
            subdistrict_en: "Dong Mafai",
            post_code: "39270",
          },
          {
            subdistrict_th: "สุวรรณคูหา",
            subdistrict_en: "Suwannakhuha",
            post_code: "39270",
          },
          {
            subdistrict_th: "บุญทัน",
            subdistrict_en: "Bun Than",
            post_code: "39270",
          },
          {
            subdistrict_th: "กุดผึ้ง",
            subdistrict_en: "Kut Phueng",
            post_code: "39270",
          },
        ],
      },
      {
        district_th: "อำเภอนาวัง",
        district_en: "Amphoe Na Wang",
        children: [
          {
            subdistrict_th: "นาเหล่า",
            subdistrict_en: "Na Lao",
            post_code: "39170",
          },
          {
            subdistrict_th: "นาแก",
            subdistrict_en: "Na Kae",
            post_code: "39170",
          },
          {
            subdistrict_th: "วังทอง",
            subdistrict_en: "Wang Thong",
            post_code: "39170",
          },
          {
            subdistrict_th: "วังปลาป้อม",
            subdistrict_en: "Wang Pla Pom",
            post_code: "39170",
          },
          {
            subdistrict_th: "เทพคีรี",
            subdistrict_en: "Thep Khiri",
            post_code: "39170",
          },
        ],
      },
    ],
  },
  {
    province_th: "ขอนแก่น",
    province_en: "Khon Kaen",
    children: [
      {
        district_th: "อำเภอเมืองขอนแก่น",
        district_en: "Amphoe Mueang Khon Kaen",
        children: [
          {
            subdistrict_th: "ในเมือง",
            subdistrict_en: "Nai Mueang",
            post_code: "40000",
          },
          {
            subdistrict_th: "สำราญ",
            subdistrict_en: "Samran",
            post_code: "40000",
          },
          {
            subdistrict_th: "โคกสี",
            subdistrict_en: "Khok Si",
            post_code: "40000",
          },
          {
            subdistrict_th: "ท่าพระ",
            subdistrict_en: "Tha Phra",
            post_code: "40260",
          },
          {
            subdistrict_th: "บ้านทุ่ม",
            subdistrict_en: "Ban Thum",
            post_code: "40000",
          },
          {
            subdistrict_th: "เมืองเก่า",
            subdistrict_en: "Mueang Kao",
            post_code: "40000",
          },
          {
            subdistrict_th: "พระลับ",
            subdistrict_en: "Phra Lap",
            post_code: "40000",
          },
          {
            subdistrict_th: "สาวะถี",
            subdistrict_en: "Sawathi",
            post_code: "40000",
          },
          {
            subdistrict_th: "บ้านหว้า",
            subdistrict_en: "Ban Wa",
            post_code: "40000",
          },
          {
            subdistrict_th: "บ้านค้อ",
            subdistrict_en: "Ban Kho",
            post_code: "40000",
          },
          {
            subdistrict_th: "แดงใหญ่",
            subdistrict_en: "Daeng Yai",
            post_code: "40000",
          },
          {
            subdistrict_th: "ดอนช้าง",
            subdistrict_en: "Don Chang",
            post_code: "40000",
          },
          {
            subdistrict_th: "ดอนหัน",
            subdistrict_en: "Don Han",
            post_code: "40260",
          },
          {
            subdistrict_th: "ศิลา",
            subdistrict_en: "Sila",
            post_code: "40000",
          },
          {
            subdistrict_th: "บ้านเป็ด",
            subdistrict_en: "Ban Pet",
            post_code: "40000",
          },
          {
            subdistrict_th: "หนองตูม",
            subdistrict_en: "Nong Tum",
            post_code: "40000",
          },
          {
            subdistrict_th: "บึงเนียม",
            subdistrict_en: "Bueng Niam",
            post_code: "40000",
          },
          {
            subdistrict_th: "โนนท่อน",
            subdistrict_en: "Non Thon",
            post_code: "40000",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านฝาง",
        district_en: "Amphoe Ban Fang",
        children: [
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "40270",
          },
          {
            subdistrict_th: "ป่าหวายนั่ง",
            subdistrict_en: "Pa Wai Nang",
            post_code: "40270",
          },
          {
            subdistrict_th: "โนนฆ้อง",
            subdistrict_en: "Non Khong",
            post_code: "40270",
          },
          {
            subdistrict_th: "บ้านเหล่า",
            subdistrict_en: "Ban Lao",
            post_code: "40270",
          },
          {
            subdistrict_th: "ป่ามะนาว",
            subdistrict_en: "Pa Manao",
            post_code: "40270",
          },
          {
            subdistrict_th: "บ้านฝาง",
            subdistrict_en: "Ban Fang",
            post_code: "40270",
          },
          {
            subdistrict_th: "โคกงาม",
            subdistrict_en: "Khok Ngam",
            post_code: "40270",
          },
        ],
      },
      {
        district_th: "อำเภอพระยืน",
        district_en: "Amphoe Phra Yuen",
        children: [
          {
            subdistrict_th: "พระยืน",
            subdistrict_en: "Phra Yuen",
            post_code: "40320",
          },
          {
            subdistrict_th: "พระบุ",
            subdistrict_en: "Phra Bu",
            post_code: "40320",
          },
          {
            subdistrict_th: "บ้านโต้น",
            subdistrict_en: "Ban Ton",
            post_code: "40320",
          },
          {
            subdistrict_th: "หนองแวง",
            subdistrict_en: "Nong Waeng",
            post_code: "40320",
          },
          {
            subdistrict_th: "ขามป้อม",
            subdistrict_en: "Kham Pom",
            post_code: "40320",
          },
        ],
      },
      {
        district_th: "อำเภอหนองเรือ",
        district_en: "Amphoe Nong Ruea",
        children: [
          {
            subdistrict_th: "หนองเรือ",
            subdistrict_en: "Nong Ruea",
            post_code: "40210",
          },
          {
            subdistrict_th: "บ้านเม็ง",
            subdistrict_en: "Ban Meng",
            post_code: "40210",
          },
          {
            subdistrict_th: "บ้านกง",
            subdistrict_en: "Ban Kong",
            post_code: "40240",
          },
          {
            subdistrict_th: "ยางคำ",
            subdistrict_en: "Yang Kham",
            post_code: "40240",
          },
          {
            subdistrict_th: "จระเข้",
            subdistrict_en: "Chorakhe",
            post_code: "40240",
          },
          {
            subdistrict_th: "โนนทอง",
            subdistrict_en: "Non Thong",
            post_code: "40210",
          },
          {
            subdistrict_th: "กุดกว้าง",
            subdistrict_en: "Kut Kwang",
            post_code: "40210",
          },
          {
            subdistrict_th: "โนนทัน",
            subdistrict_en: "Non Than",
            post_code: "40210",
          },
          {
            subdistrict_th: "โนนสะอาด",
            subdistrict_en: "Non Sa-at",
            post_code: "40210",
          },
          {
            subdistrict_th: "บ้านผือ",
            subdistrict_en: "Ban Phue",
            post_code: "40240",
          },
        ],
      },
      {
        district_th: "อำเภอชุมแพ",
        district_en: "Amphoe Chum Phae",
        children: [
          {
            subdistrict_th: "ชุมแพ",
            subdistrict_en: "Chum Phae",
            post_code: "40130",
          },
          {
            subdistrict_th: "โนนหัน",
            subdistrict_en: "Non Han",
            post_code: "40290",
          },
          {
            subdistrict_th: "นาหนองทุ่ม",
            subdistrict_en: "Na Nong Thum",
            post_code: "40290",
          },
          {
            subdistrict_th: "โนนอุดม",
            subdistrict_en: "Non Udom",
            post_code: "40130",
          },
          {
            subdistrict_th: "ขัวเรียง",
            subdistrict_en: "Khua Riang",
            post_code: "40130",
          },
          {
            subdistrict_th: "หนองไผ่",
            subdistrict_en: "Nong Phai",
            post_code: "40130",
          },
          {
            subdistrict_th: "ไชยสอ",
            subdistrict_en: "Chai So",
            post_code: "40130",
          },
          {
            subdistrict_th: "วังหินลาด",
            subdistrict_en: "Wang Hin Lat",
            post_code: "40130",
          },
          {
            subdistrict_th: "นาเพียง",
            subdistrict_en: "Na Phiang",
            post_code: "40130",
          },
          {
            subdistrict_th: "หนองเขียด",
            subdistrict_en: "Nong Khiat",
            post_code: "40290",
          },
          {
            subdistrict_th: "หนองเสาเล้า",
            subdistrict_en: "Nong Sao Lao",
            post_code: "40130",
          },
          {
            subdistrict_th: "โนนสะอาด",
            subdistrict_en: "Non Sa-at",
            post_code: "40290",
          },
        ],
      },
      {
        district_th: "อำเภอสีชมพู",
        district_en: "Amphoe Si Chomphu",
        children: [
          {
            subdistrict_th: "สีชมพู",
            subdistrict_en: "Si Chomphu",
            post_code: "40220",
          },
          {
            subdistrict_th: "ศรีสุข",
            subdistrict_en: "Si Suk",
            post_code: "40220",
          },
          {
            subdistrict_th: "นาจาน",
            subdistrict_en: "Na Chan",
            post_code: "40220",
          },
          {
            subdistrict_th: "วังเพิ่ม",
            subdistrict_en: "Wang Phoem",
            post_code: "40220",
          },
          {
            subdistrict_th: "ซำยาง",
            subdistrict_en: "Sam Yang",
            post_code: "40220",
          },
          {
            subdistrict_th: "หนองแดง",
            subdistrict_en: "Nong Daeng",
            post_code: "40220",
          },
          {
            subdistrict_th: "ดงลาน",
            subdistrict_en: "Dong Lan",
            post_code: "40220",
          },
          {
            subdistrict_th: "บริบูรณ์",
            subdistrict_en: "Boribun",
            post_code: "40220",
          },
          {
            subdistrict_th: "บ้านใหม่",
            subdistrict_en: "Ban Mai",
            post_code: "40220",
          },
          {
            subdistrict_th: "ภูห่าน",
            subdistrict_en: "Phu Han",
            post_code: "40220",
          },
        ],
      },
      {
        district_th: "อำเภอน้ำพอง",
        district_en: "Amphoe Nam Phong",
        children: [
          {
            subdistrict_th: "น้ำพอง",
            subdistrict_en: "Nam Phong",
            post_code: "40140",
          },
          {
            subdistrict_th: "วังชัย",
            subdistrict_en: "Wang Chai",
            post_code: "40140",
          },
          {
            subdistrict_th: "หนองกุง",
            subdistrict_en: "Nong Kung",
            post_code: "40140",
          },
          {
            subdistrict_th: "บัวใหญ่",
            subdistrict_en: "Bua Yai",
            post_code: "40140",
          },
          {
            subdistrict_th: "สะอาด",
            subdistrict_en: "Sa-at",
            post_code: "40310",
          },
          {
            subdistrict_th: "ม่วงหวาน",
            subdistrict_en: "Muang Wan",
            post_code: "40310",
          },
          {
            subdistrict_th: "บ้านขาม",
            subdistrict_en: "Ban Kham",
            post_code: "40140",
          },
          {
            subdistrict_th: "บัวเงิน",
            subdistrict_en: "Bua Ngoen",
            post_code: "40140",
          },
          {
            subdistrict_th: "ทรายมูล",
            subdistrict_en: "Sai Mun",
            post_code: "40140",
          },
          {
            subdistrict_th: "ท่ากระเสริม",
            subdistrict_en: "Tha Krasoem",
            post_code: "40140",
          },
          {
            subdistrict_th: "พังทุย",
            subdistrict_en: "Phang Thui",
            post_code: "40140",
          },
          {
            subdistrict_th: "กุดน้ำใส",
            subdistrict_en: "Kut Nam Sai",
            post_code: "40310",
          },
        ],
      },
      {
        district_th: "อำเภออุบลรัตน์",
        district_en: "Amphoe Ubolratana",
        children: [
          {
            subdistrict_th: "โคกสูง",
            subdistrict_en: "Khok Sung",
            post_code: "40250",
          },
          {
            subdistrict_th: "บ้านดง",
            subdistrict_en: "Ban Dong",
            post_code: "40250",
          },
          {
            subdistrict_th: "เขื่อนอุบลรัตน์",
            subdistrict_en: "Khuean Ubolratana",
            post_code: "40250",
          },
          {
            subdistrict_th: "นาคำ",
            subdistrict_en: "Na Kham",
            post_code: "40250",
          },
          {
            subdistrict_th: "ศรีสุขสำราญ",
            subdistrict_en: "Si Suk Samran",
            post_code: "40250",
          },
          {
            subdistrict_th: "ทุ่งโป่ง",
            subdistrict_en: "Thung Pong",
            post_code: "40250",
          },
        ],
      },
      {
        district_th: "อำเภอกระนวน",
        district_en: "Amphoe Kranuan",
        children: [
          {
            subdistrict_th: "หนองโก",
            subdistrict_en: "Nong Ko",
            post_code: "40170",
          },
          {
            subdistrict_th: "หนองกุงใหญ่",
            subdistrict_en: "Nong Kung Yai",
            post_code: "40170",
          },
          {
            subdistrict_th: "ห้วยโจด",
            subdistrict_en: "Huai Chot",
            post_code: "40170",
          },
          {
            subdistrict_th: "ห้วยยาง",
            subdistrict_en: "Huai Yang",
            post_code: "40170",
          },
          {
            subdistrict_th: "บ้านฝาง",
            subdistrict_en: "Ban Fang",
            post_code: "40170",
          },
          {
            subdistrict_th: "ดูนสาด",
            subdistrict_en: "Dun Sat",
            post_code: "40170",
          },
          {
            subdistrict_th: "หนองโน",
            subdistrict_en: "Nong No",
            post_code: "40170",
          },
          {
            subdistrict_th: "น้ำอ้อม",
            subdistrict_en: "Nam Om",
            post_code: "40170",
          },
          {
            subdistrict_th: "หัวนาคำ",
            subdistrict_en: "Hua Na Kham",
            post_code: "40170",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านไผ่",
        district_en: "Amphoe Ban Phai",
        children: [
          {
            subdistrict_th: "บ้านไผ่",
            subdistrict_en: "Ban Phai",
            post_code: "40110",
          },
          {
            subdistrict_th: "ในเมือง",
            subdistrict_en: "Nai Mueang",
            post_code: "40110",
          },
          {
            subdistrict_th: "เมืองเพีย",
            subdistrict_en: "Mueang Phia",
            post_code: "40110",
          },
          {
            subdistrict_th: "บ้านลาน",
            subdistrict_en: "Ban Lan",
            post_code: "40110",
          },
          {
            subdistrict_th: "แคนเหนือ",
            subdistrict_en: "Khaen Nuea",
            post_code: "40110",
          },
          {
            subdistrict_th: "ภูเหล็ก",
            subdistrict_en: "Phu Lek",
            post_code: "40110",
          },
          {
            subdistrict_th: "ป่าปอ",
            subdistrict_en: "Pa Po",
            post_code: "40110",
          },
          {
            subdistrict_th: "หินตั้ง",
            subdistrict_en: "Hin Tang",
            post_code: "40110",
          },
          {
            subdistrict_th: "หนองน้ำใส",
            subdistrict_en: "Nong Nam Sai",
            post_code: "40110",
          },
          {
            subdistrict_th: "หัวหนอง",
            subdistrict_en: "Hua Nong",
            post_code: "40110",
          },
        ],
      },
      {
        district_th: "อำเภอเปือยน้อย",
        district_en: "Amphoe Pueai Noi",
        children: [
          {
            subdistrict_th: "เปือยน้อย",
            subdistrict_en: "Pueai Noi",
            post_code: "40340",
          },
          {
            subdistrict_th: "วังม่วง",
            subdistrict_en: "Wang Muang",
            post_code: "40340",
          },
          {
            subdistrict_th: "ขามป้อม",
            subdistrict_en: "Kham Pom",
            post_code: "40340",
          },
          {
            subdistrict_th: "สระแก้ว",
            subdistrict_en: "Sa Kaeo",
            post_code: "40340",
          },
        ],
      },
      {
        district_th: "อำเภอพล",
        district_en: "Amphoe Phon",
        children: [
          {
            subdistrict_th: "เมืองพล",
            subdistrict_en: "Mueang Phon",
            post_code: "40120",
          },
          {
            subdistrict_th: "โจดหนองแก",
            subdistrict_en: "Chot Nong Kae",
            post_code: "40120",
          },
          {
            subdistrict_th: "เก่างิ้ว",
            subdistrict_en: "Kao Ngio",
            post_code: "40120",
          },
          {
            subdistrict_th: "หนองมะเขือ",
            subdistrict_en: "Nong Makhuea",
            post_code: "40120",
          },
          {
            subdistrict_th: "หนองแวงโสกพระ",
            subdistrict_en: "Nong Waeng Sok Phra",
            post_code: "40120",
          },
          {
            subdistrict_th: "เพ็กใหญ่",
            subdistrict_en: "Phek Yai",
            post_code: "40120",
          },
          {
            subdistrict_th: "โคกสง่า",
            subdistrict_en: "Khok Sa-nga",
            post_code: "40120",
          },
          {
            subdistrict_th: "หนองแวงนางเบ้า",
            subdistrict_en: "Nong Waeng Nang Bao",
            post_code: "40120",
          },
          {
            subdistrict_th: "ลอมคอม",
            subdistrict_en: "Lom Khom",
            post_code: "40120",
          },
          {
            subdistrict_th: "โนนข่า",
            subdistrict_en: "Non Kha",
            post_code: "40120",
          },
          {
            subdistrict_th: "โสกนกเต็น",
            subdistrict_en: "Sok Nok Ten",
            post_code: "40120",
          },
          {
            subdistrict_th: "หัวทุ่ง",
            subdistrict_en: "Hua Thung",
            post_code: "40120",
          },
        ],
      },
      {
        district_th: "อำเภอแวงใหญ่",
        district_en: "Amphoe Waeng Yai",
        children: [
          {
            subdistrict_th: "คอนฉิม",
            subdistrict_en: "Khon Chim",
            post_code: "40330",
          },
          {
            subdistrict_th: "ใหม่นาเพียง",
            subdistrict_en: "Mai Na Phiang",
            post_code: "40330",
          },
          {
            subdistrict_th: "โนนทอง",
            subdistrict_en: "Non Thong",
            post_code: "40330",
          },
          {
            subdistrict_th: "แวงใหญ่",
            subdistrict_en: "Waeng Yai",
            post_code: "40330",
          },
          {
            subdistrict_th: "โนนสะอาด",
            subdistrict_en: "Non Sa-at",
            post_code: "40330",
          },
        ],
      },
      {
        district_th: "อำเภอแวงน้อย",
        district_en: "Amphoe Waeng Noi",
        children: [
          {
            subdistrict_th: "แวงน้อย",
            subdistrict_en: "Waeng Noi",
            post_code: "40230",
          },
          {
            subdistrict_th: "ก้านเหลือง",
            subdistrict_en: "Kan Lueang",
            post_code: "40230",
          },
          {
            subdistrict_th: "ท่านางแนว",
            subdistrict_en: "Tha Nang Naeo",
            post_code: "40230",
          },
          {
            subdistrict_th: "ละหานนา",
            subdistrict_en: "Lahan Na",
            post_code: "40230",
          },
          {
            subdistrict_th: "ท่าวัด",
            subdistrict_en: "Tha Wat",
            post_code: "40230",
          },
          {
            subdistrict_th: "ทางขวาง",
            subdistrict_en: "Thang Khwang",
            post_code: "40230",
          },
        ],
      },
      {
        district_th: "อำเภอหนองสองห้อง",
        district_en: "Amphoe Nong Song Hong",
        children: [
          {
            subdistrict_th: "หนองสองห้อง",
            subdistrict_en: "Nong Song Hong",
            post_code: "40190",
          },
          {
            subdistrict_th: "คึมชาด",
            subdistrict_en: "Khuemchat",
            post_code: "40190",
          },
          {
            subdistrict_th: "โนนธาตุ",
            subdistrict_en: "Non That",
            post_code: "40190",
          },
          {
            subdistrict_th: "ตะกั่วป่า",
            subdistrict_en: "Takua Pa",
            post_code: "40190",
          },
          {
            subdistrict_th: "สำโรง",
            subdistrict_en: "Samrong",
            post_code: "40190",
          },
          {
            subdistrict_th: "หนองเม็ก",
            subdistrict_en: "Nong Mek",
            post_code: "40190",
          },
          {
            subdistrict_th: "ดอนดู่",
            subdistrict_en: "Don Du",
            post_code: "40190",
          },
          {
            subdistrict_th: "ดงเค็ง",
            subdistrict_en: "Dong Kheng",
            post_code: "40190",
          },
          {
            subdistrict_th: "หันโจด",
            subdistrict_en: "Han Chot",
            post_code: "40190",
          },
          {
            subdistrict_th: "ดอนดั่ง",
            subdistrict_en: "Don Dang",
            post_code: "40190",
          },
          {
            subdistrict_th: "วังหิน",
            subdistrict_en: "Wang Hin",
            post_code: "40190",
          },
          {
            subdistrict_th: "หนองไผ่ล้อม",
            subdistrict_en: "Nong Phai Lom",
            post_code: "40190",
          },
        ],
      },
      {
        district_th: "อำเภอภูเวียง",
        district_en: "Amphoe Phu Wiang",
        children: [
          {
            subdistrict_th: "บ้านเรือ",
            subdistrict_en: "Ban Ruea",
            post_code: "40150",
          },
          {
            subdistrict_th: "หว้าทอง",
            subdistrict_en: "Wa Thong",
            post_code: "40150",
          },
          {
            subdistrict_th: "กุดขอนแก่น",
            subdistrict_en: "Kut Khon Kaen",
            post_code: "40150",
          },
          {
            subdistrict_th: "นาชุมแสง",
            subdistrict_en: "Na Chum Saeng",
            post_code: "40150",
          },
          {
            subdistrict_th: "นาหว้า",
            subdistrict_en: "Na Wa",
            post_code: "40150",
          },
          {
            subdistrict_th: "หนองกุงธนสาร",
            subdistrict_en: "Nong Kung Thanasan",
            post_code: "40150",
          },
          {
            subdistrict_th: "หนองกุงเซิน",
            subdistrict_en: "Nong Kung Soen",
            post_code: "40150",
          },
          {
            subdistrict_th: "สงเปือย",
            subdistrict_en: "Song Pueai",
            post_code: "40150",
          },
          {
            subdistrict_th: "ทุ่งชมพู",
            subdistrict_en: "Thung Chomphu",
            post_code: "40150",
          },
          {
            subdistrict_th: "ดินดำ",
            subdistrict_en: "Din Dam",
            post_code: "40150",
          },
          {
            subdistrict_th: "ภูเวียง",
            subdistrict_en: "Phu Wiang",
            post_code: "40150",
          },
        ],
      },
      {
        district_th: "อำเภอมัญจาคีรี",
        district_en: "Amphoe Mancha Khiri",
        children: [
          {
            subdistrict_th: "กุดเค้า",
            subdistrict_en: "Kut Khao",
            post_code: "40160",
          },
          {
            subdistrict_th: "สวนหม่อน",
            subdistrict_en: "Suan Mon",
            post_code: "40160",
          },
          {
            subdistrict_th: "หนองแปน",
            subdistrict_en: "Nong Paen",
            post_code: "40160",
          },
          {
            subdistrict_th: "โพนเพ็ก",
            subdistrict_en: "Phon Phek",
            post_code: "40160",
          },
          {
            subdistrict_th: "คำแคน",
            subdistrict_en: "Kham Khaen",
            post_code: "40160",
          },
          {
            subdistrict_th: "นาข่า",
            subdistrict_en: "Na Kha",
            post_code: "40160",
          },
          {
            subdistrict_th: "นางาม",
            subdistrict_en: "Na Ngam",
            post_code: "40160",
          },
          {
            subdistrict_th: "ท่าศาลา",
            subdistrict_en: "Tha Sala",
            post_code: "40160",
          },
        ],
      },
      {
        district_th: "อำเภอชนบท",
        district_en: "Amphoe Chonnabot",
        children: [
          {
            subdistrict_th: "ชนบท",
            subdistrict_en: "Chonnabot",
            post_code: "40180",
          },
          {
            subdistrict_th: "กุดเพียขอม",
            subdistrict_en: "Kut Phia Khom",
            post_code: "40180",
          },
          {
            subdistrict_th: "วังแสง",
            subdistrict_en: "Wang Saeng",
            post_code: "40180",
          },
          {
            subdistrict_th: "ห้วยแก",
            subdistrict_en: "Huai Kae",
            post_code: "40180",
          },
          {
            subdistrict_th: "บ้านแท่น",
            subdistrict_en: "Ban Thaen",
            post_code: "40180",
          },
          {
            subdistrict_th: "ศรีบุญเรือง",
            subdistrict_en: "Si Bun Rueang",
            post_code: "40180",
          },
          {
            subdistrict_th: "โนนพะยอม",
            subdistrict_en: "Non Phayom",
            post_code: "40180",
          },
          {
            subdistrict_th: "ปอแดง",
            subdistrict_en: "Po Daeng",
            post_code: "40180",
          },
        ],
      },
      {
        district_th: "อำเภอเขาสวนกวาง",
        district_en: "Amphoe Khao Suan Kwang",
        children: [
          {
            subdistrict_th: "เขาสวนกวาง",
            subdistrict_en: "Khao Suan Kwang",
            post_code: "40280",
          },
          {
            subdistrict_th: "ดงเมืองแอม",
            subdistrict_en: "Dong Mueang Aem",
            post_code: "40280",
          },
          {
            subdistrict_th: "นางิ้ว",
            subdistrict_en: "Na Ngio",
            post_code: "40280",
          },
          {
            subdistrict_th: "โนนสมบูรณ์",
            subdistrict_en: "Non Sombun",
            post_code: "40280",
          },
          {
            subdistrict_th: "คำม่วง",
            subdistrict_en: "Kham Muang",
            post_code: "40280",
          },
        ],
      },
      {
        district_th: "อำเภอภูผาม่าน",
        district_en: "Amphoe Phu Pha Man",
        children: [
          {
            subdistrict_th: "โนนคอม",
            subdistrict_en: "Non Khom",
            post_code: "40350",
          },
          {
            subdistrict_th: "นาฝาย",
            subdistrict_en: "Na Fai",
            post_code: "40350",
          },
          {
            subdistrict_th: "ภูผาม่าน",
            subdistrict_en: "Phu Pha Man",
            post_code: "40350",
          },
          {
            subdistrict_th: "วังสวาบ",
            subdistrict_en: "Wang Sawap",
            post_code: "40350",
          },
          {
            subdistrict_th: "ห้วยม่วง",
            subdistrict_en: "huai Muang",
            post_code: "40350",
          },
        ],
      },
      {
        district_th: "อำเภอซำสูง",
        district_en: "Amphoe Sam Sung",
        children: [
          {
            subdistrict_th: "กระนวน",
            subdistrict_en: "Kranuan",
            post_code: "40170",
          },
          {
            subdistrict_th: "คำแมด",
            subdistrict_en: "Kham Maet",
            post_code: "40170",
          },
          {
            subdistrict_th: "บ้านโนน",
            subdistrict_en: "Ban Non",
            post_code: "40170",
          },
          {
            subdistrict_th: "คูคำ",
            subdistrict_en: "Khu Kham",
            post_code: "40170",
          },
          {
            subdistrict_th: "ห้วยเตย",
            subdistrict_en: "Huai Toei",
            post_code: "40170",
          },
        ],
      },
      {
        district_th: "อำเภอโคกโพธิ์ไชย",
        district_en: "Amphoe Khok Pho Chai",
        children: [
          {
            subdistrict_th: "บ้านโคก",
            subdistrict_en: "Ban Khok",
            post_code: "40160",
          },
          {
            subdistrict_th: "โพธิ์ไชย",
            subdistrict_en: "Pho Chai",
            post_code: "40160",
          },
          {
            subdistrict_th: "ซับสมบูรณ์",
            subdistrict_en: "Sap Sombun",
            post_code: "40160",
          },
          {
            subdistrict_th: "นาแพง",
            subdistrict_en: "Na Phaeng",
            post_code: "40160",
          },
        ],
      },
      {
        district_th: "อำเภอหนองนาคำ",
        district_en: "Amphoe Nong Na Kham",
        children: [
          {
            subdistrict_th: "กุดธาตุ",
            subdistrict_en: "Kut That",
            post_code: "40150",
          },
          {
            subdistrict_th: "บ้านโคก",
            subdistrict_en: "Ban Khok",
            post_code: "40150",
          },
          {
            subdistrict_th: "ขนวน",
            subdistrict_en: "Khanuan",
            post_code: "40150",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านแฮด",
        district_en: "Amphoe Ban Haet",
        children: [
          {
            subdistrict_th: "บ้านแฮด",
            subdistrict_en: "Ban Haet",
            post_code: "40110",
          },
          {
            subdistrict_th: "โคกสำราญ",
            subdistrict_en: "Khok Samran",
            post_code: "40110",
          },
          {
            subdistrict_th: "โนนสมบูรณ์",
            subdistrict_en: "Non Sombun",
            post_code: "40110",
          },
          {
            subdistrict_th: "หนองแซง",
            subdistrict_en: "Nong Saeng",
            post_code: "40110",
          },
        ],
      },
      {
        district_th: "อำเภอโนนศิลา",
        district_en: "Amphoe Non Sila",
        children: [
          {
            subdistrict_th: "โนนศิลา",
            subdistrict_en: "Non Sila",
            post_code: "40110",
          },
          {
            subdistrict_th: "หนองปลาหมอ",
            subdistrict_en: "Nong Pla Mo",
            post_code: "40110",
          },
          {
            subdistrict_th: "บ้านหัน",
            subdistrict_en: "Ban Han",
            post_code: "40110",
          },
          {
            subdistrict_th: "เปือยใหญ่",
            subdistrict_en: "Pueai Yai",
            post_code: "40110",
          },
          {
            subdistrict_th: "โนนแดง",
            subdistrict_en: "Non Daeng",
            post_code: "40110",
          },
        ],
      },
      {
        district_th: "อำเภอเวียงเก่า",
        district_en: "Amphoe Wiang Kao",
        children: [
          {
            subdistrict_th: "ในเมือง",
            subdistrict_en: "Nai Mueang",
            post_code: "40150",
          },
          {
            subdistrict_th: "เมืองเก่าพัฒนา",
            subdistrict_en: "Mueang Kao Phatthana",
            post_code: "40150",
          },
          {
            subdistrict_th: "เขาน้อย",
            subdistrict_en: "Khao Noi",
            post_code: "40150",
          },
        ],
      },
    ],
  },
  {
    province_th: "อุดรธานี",
    province_en: "Udon Thani",
    children: [
      {
        district_th: "อำเภอเมืองอุดรธานี",
        district_en: "Amphoe Mueang Udon Thani",
        children: [
          {
            subdistrict_th: "หมากแข้ง",
            subdistrict_en: "Mak Khaeng",
            post_code: "41000",
          },
          {
            subdistrict_th: "นิคมสงเคราะห์",
            subdistrict_en: "Nikhom Songkhro",
            post_code: "41000",
          },
          {
            subdistrict_th: "บ้านขาว",
            subdistrict_en: "Ban Khao",
            post_code: "41000",
          },
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "41000",
          },
          {
            subdistrict_th: "บ้านตาด",
            subdistrict_en: "Ban Tat",
            post_code: "41000",
          },
          {
            subdistrict_th: "โนนสูง",
            subdistrict_en: "Non Sung",
            post_code: "41330",
          },
          {
            subdistrict_th: "หมูม่น",
            subdistrict_en: "Mu Mon",
            post_code: "41000",
          },
          {
            subdistrict_th: "เชียงยืน",
            subdistrict_en: "Chiang Yuen",
            post_code: "41000",
          },
          {
            subdistrict_th: "หนองนาคำ",
            subdistrict_en: "Nong Na Kham",
            post_code: "41000",
          },
          {
            subdistrict_th: "กุดสระ",
            subdistrict_en: "Kut Sa",
            post_code: "41000",
          },
          {
            subdistrict_th: "นาดี",
            subdistrict_en: "Na Di",
            post_code: "41000",
          },
          {
            subdistrict_th: "บ้านเลื่อม",
            subdistrict_en: "Ban Lueam",
            post_code: "41000",
          },
          {
            subdistrict_th: "เชียงพิณ",
            subdistrict_en: "Chiang Phin",
            post_code: "41000",
          },
          {
            subdistrict_th: "สามพร้าว",
            subdistrict_en: "Sam Phrao",
            post_code: "41000",
          },
          {
            subdistrict_th: "หนองไฮ",
            subdistrict_en: "Nong Hai",
            post_code: "41000",
          },
          {
            subdistrict_th: "นาข่า",
            subdistrict_en: "Na Kha",
            post_code: "41000",
          },
          {
            subdistrict_th: "บ้านจั่น",
            subdistrict_en: "Ban Chan",
            post_code: "41000",
          },
          {
            subdistrict_th: "หนองขอนกว้าง",
            subdistrict_en: "Nong Khon Kwang",
            post_code: "41000",
          },
          {
            subdistrict_th: "โคกสะอาด",
            subdistrict_en: "Khok Sa-at",
            post_code: "41000",
          },
          {
            subdistrict_th: "นากว้าง",
            subdistrict_en: "Na Kwang",
            post_code: "41000",
          },
          {
            subdistrict_th: "หนองไผ่",
            subdistrict_en: "Nong Phai",
            post_code: "41330",
          },
        ],
      },
      {
        district_th: "อำเภอกุดจับ",
        district_en: "Amphoe Kut Chap",
        children: [
          {
            subdistrict_th: "กุดจับ",
            subdistrict_en: "Kut Chap",
            post_code: "41250",
          },
          {
            subdistrict_th: "ปะโค",
            subdistrict_en: "Pa Kho",
            post_code: "41250",
          },
          {
            subdistrict_th: "ขอนยูง",
            subdistrict_en: "Khon Yung",
            post_code: "41250",
          },
          {
            subdistrict_th: "เชียงเพ็ง",
            subdistrict_en: "Chiang Pheng",
            post_code: "41250",
          },
          {
            subdistrict_th: "สร้างก่อ",
            subdistrict_en: "Sang Ko",
            post_code: "41250",
          },
          {
            subdistrict_th: "เมืองเพีย",
            subdistrict_en: "Mueang Phia",
            post_code: "41250",
          },
          {
            subdistrict_th: "ตาลเลียน",
            subdistrict_en: "Tan Lian",
            post_code: "41250",
          },
        ],
      },
      {
        district_th: "อำเภอหนองวัวซอ",
        district_en: "Amphoe Nong Wua So",
        children: [
          {
            subdistrict_th: "หมากหญ้า",
            subdistrict_en: "Mak Ya",
            post_code: "41360",
          },
          {
            subdistrict_th: "หนองอ้อ",
            subdistrict_en: "Nong O",
            post_code: "41220",
          },
          {
            subdistrict_th: "อูบมุง",
            subdistrict_en: "Up Mung",
            post_code: "41220",
          },
          {
            subdistrict_th: "กุดหมากไฟ",
            subdistrict_en: "Kut Mak Fai",
            post_code: "41220",
          },
          {
            subdistrict_th: "น้ำพ่น",
            subdistrict_en: "Nam Phon",
            post_code: "41360",
          },
          {
            subdistrict_th: "หนองบัวบาน",
            subdistrict_en: "Nong Bua Ban",
            post_code: "41360",
          },
          {
            subdistrict_th: "โนนหวาย",
            subdistrict_en: "Non Wai",
            post_code: "41220",
          },
          {
            subdistrict_th: "หนองวัวซอ",
            subdistrict_en: "Nong Wua So",
            post_code: "41360",
          },
        ],
      },
      {
        district_th: "อำเภอกุมภวาปี",
        district_en: "Amphoe Kumphawapi",
        children: [
          {
            subdistrict_th: "ตูมใต้",
            subdistrict_en: "Tum Tai",
            post_code: "41110",
          },
          {
            subdistrict_th: "พันดอน",
            subdistrict_en: "Phan Don",
            post_code: "41370",
          },
          {
            subdistrict_th: "เวียงคำ",
            subdistrict_en: "Wiang Kham",
            post_code: "41110",
          },
          {
            subdistrict_th: "แชแล",
            subdistrict_en: "Chaelae",
            post_code: "41110",
          },
          {
            subdistrict_th: "เชียงแหว",
            subdistrict_en: "Chiang Wae",
            post_code: "41110",
          },
          {
            subdistrict_th: "ห้วยเกิ้ง",
            subdistrict_en: "Huai Koeng",
            post_code: "41110",
          },
          {
            subdistrict_th: "เสอเพลอ",
            subdistrict_en: "Soephloe",
            post_code: "41370",
          },
          {
            subdistrict_th: "สีออ",
            subdistrict_en: "Si O",
            post_code: "41110",
          },
          {
            subdistrict_th: "ปะโค",
            subdistrict_en: "Pakho",
            post_code: "41370",
          },
          {
            subdistrict_th: "ผาสุก",
            subdistrict_en: "Pha Suk",
            post_code: "41370",
          },
          {
            subdistrict_th: "ท่าลี่",
            subdistrict_en: "Tha Li",
            post_code: "41110",
          },
          {
            subdistrict_th: "กุมภวาปี",
            subdistrict_en: "Kumphawapi",
            post_code: "41110",
          },
          {
            subdistrict_th: "หนองหว้า",
            subdistrict_en: "Nong Wa",
            post_code: "41110",
          },
        ],
      },
      {
        district_th: "อำเภอโนนสะอาด",
        district_en: "Amphoe Non Sa-at",
        children: [
          {
            subdistrict_th: "โนนสะอาด",
            subdistrict_en: "Non Sa-at",
            post_code: "41240",
          },
          {
            subdistrict_th: "บุ่งแก้ว",
            subdistrict_en: "Bung Kaeo",
            post_code: "41240",
          },
          {
            subdistrict_th: "โพธิ์ศรีสำราญ",
            subdistrict_en: "Pho Si Samran",
            post_code: "41240",
          },
          {
            subdistrict_th: "ทมนางาม",
            subdistrict_en: "Thom Na Ngam",
            post_code: "41240",
          },
          {
            subdistrict_th: "หนองกุงศรี",
            subdistrict_en: "Nong Kung Si",
            post_code: "41240",
          },
          {
            subdistrict_th: "โคกกลาง",
            subdistrict_en: "Khok Klang",
            post_code: "41240",
          },
        ],
      },
      {
        district_th: "อำเภอหนองหาน",
        district_en: "Amphoe Nong Han",
        children: [
          {
            subdistrict_th: "หนองหาน",
            subdistrict_en: "Nong Han",
            post_code: "41130",
          },
          {
            subdistrict_th: "หนองเม็ก",
            subdistrict_en: "Nong Mek",
            post_code: "41130",
          },
          {
            subdistrict_th: "พังงู",
            subdistrict_en: "Phang Ngu",
            post_code: "41130",
          },
          {
            subdistrict_th: "สะแบง",
            subdistrict_en: "Sabaeng",
            post_code: "41130",
          },
          {
            subdistrict_th: "สร้อยพร้าว",
            subdistrict_en: "Soi Phrao",
            post_code: "41130",
          },
          {
            subdistrict_th: "บ้านเชียง",
            subdistrict_en: "Ban Chiang",
            post_code: "41320",
          },
          {
            subdistrict_th: "บ้านยา",
            subdistrict_en: "Ban Ya",
            post_code: "41320",
          },
          {
            subdistrict_th: "โพนงาม",
            subdistrict_en: "Phon Ngam",
            post_code: "41130",
          },
          {
            subdistrict_th: "ผักตบ",
            subdistrict_en: "Phak Top",
            post_code: "41130",
          },
          {
            subdistrict_th: "หนองไผ่",
            subdistrict_en: "Nong Phai",
            post_code: "41130",
          },
          {
            subdistrict_th: "ดอนหายโศก",
            subdistrict_en: "Don Hai Sok",
            post_code: "41130",
          },
          {
            subdistrict_th: "หนองสระปลา",
            subdistrict_en: "Nong Sa Pla",
            post_code: "41320",
          },
        ],
      },
      {
        district_th: "อำเภอทุ่งฝน",
        district_en: "Amphoe Thung Fon",
        children: [
          {
            subdistrict_th: "ทุ่งฝน",
            subdistrict_en: "Thung Fon",
            post_code: "41310",
          },
          {
            subdistrict_th: "ทุ่งใหญ่",
            subdistrict_en: "Thung Yai",
            post_code: "41310",
          },
          {
            subdistrict_th: "นาชุมแสง",
            subdistrict_en: "Na Chum Saeng",
            post_code: "41310",
          },
          {
            subdistrict_th: "นาทม",
            subdistrict_en: "Na Thom",
            post_code: "41310",
          },
        ],
      },
      {
        district_th: "อำเภอไชยวาน",
        district_en: "Amphoe Chai Wan",
        children: [
          {
            subdistrict_th: "ไชยวาน",
            subdistrict_en: "Chai Wan",
            post_code: "41290",
          },
          {
            subdistrict_th: "หนองหลัก",
            subdistrict_en: "Nong Lak",
            post_code: "41290",
          },
          {
            subdistrict_th: "คำเลาะ",
            subdistrict_en: "Kham Lo",
            post_code: "41290",
          },
          {
            subdistrict_th: "โพนสูง",
            subdistrict_en: "Phon Sung",
            post_code: "41290",
          },
        ],
      },
      {
        district_th: "อำเภอศรีธาตุ",
        district_en: "Amphoe Si That",
        children: [
          {
            subdistrict_th: "ศรีธาตุ",
            subdistrict_en: "Si That",
            post_code: "41230",
          },
          {
            subdistrict_th: "จำปี",
            subdistrict_en: "Champi",
            post_code: "41230",
          },
          {
            subdistrict_th: "บ้านโปร่ง",
            subdistrict_en: "Ban Prong",
            post_code: "41230",
          },
          {
            subdistrict_th: "หัวนาคำ",
            subdistrict_en: "Hua Na Kham",
            post_code: "41230",
          },
          {
            subdistrict_th: "หนองนกเขียน",
            subdistrict_en: "Nong Nok Khian",
            post_code: "41230",
          },
          {
            subdistrict_th: "นายูง",
            subdistrict_en: "Na Yung",
            post_code: "41230",
          },
          {
            subdistrict_th: "ตาดทอง",
            subdistrict_en: "Tat Thong",
            post_code: "41230",
          },
        ],
      },
      {
        district_th: "อำเภอวังสามหมอ",
        district_en: "Amphoe Wang Sam Mo",
        children: [
          {
            subdistrict_th: "หนองกุงทับม้า",
            subdistrict_en: "Nong Kung Thap Ma",
            post_code: "41280",
          },
          {
            subdistrict_th: "หนองหญ้าไซ",
            subdistrict_en: "Nong Ya Sai",
            post_code: "41280",
          },
          {
            subdistrict_th: "บะยาว",
            subdistrict_en: "Ba Yao",
            post_code: "41280",
          },
          {
            subdistrict_th: "ผาสุก",
            subdistrict_en: "Phasuk",
            post_code: "41280",
          },
          {
            subdistrict_th: "คำโคกสูง",
            subdistrict_en: "Kham Khok Sung",
            post_code: "41280",
          },
          {
            subdistrict_th: "วังสามหมอ",
            subdistrict_en: "Wang Sam Mo",
            post_code: "41280",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านดุง",
        district_en: "Amphoe Ban Dung",
        children: [
          {
            subdistrict_th: "ศรีสุทโธ",
            subdistrict_en: "Si Suttho",
            post_code: "41190",
          },
          {
            subdistrict_th: "บ้านดุง",
            subdistrict_en: "Ban Dung",
            post_code: "41190",
          },
          {
            subdistrict_th: "ดงเย็น",
            subdistrict_en: "Dong Yen",
            post_code: "41190",
          },
          {
            subdistrict_th: "โพนสูง",
            subdistrict_en: "Phon Sung",
            post_code: "41190",
          },
          {
            subdistrict_th: "อ้อมกอ",
            subdistrict_en: "Om Ko",
            post_code: "41190",
          },
          {
            subdistrict_th: "บ้านจันทน์",
            subdistrict_en: "Ban Chan",
            post_code: "41190",
          },
          {
            subdistrict_th: "บ้านชัย",
            subdistrict_en: "Ban Chai",
            post_code: "41190",
          },
          {
            subdistrict_th: "นาไหม",
            subdistrict_en: "Na Mai",
            post_code: "41190",
          },
          {
            subdistrict_th: "ถ่อนนาลับ",
            subdistrict_en: "Thon Na Lap",
            post_code: "41190",
          },
          {
            subdistrict_th: "วังทอง",
            subdistrict_en: "Wang Thong",
            post_code: "41190",
          },
          {
            subdistrict_th: "บ้านม่วง",
            subdistrict_en: "Ban Muang",
            post_code: "41190",
          },
          {
            subdistrict_th: "บ้านตาด",
            subdistrict_en: "Ban Tat",
            post_code: "41190",
          },
          {
            subdistrict_th: "นาคำ",
            subdistrict_en: "Na Kham",
            post_code: "41190",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านผือ",
        district_en: "Amphoe Ban Phue",
        children: [
          {
            subdistrict_th: "บ้านผือ",
            subdistrict_en: "Ban Phue",
            post_code: "41160",
          },
          {
            subdistrict_th: "หายโศก",
            subdistrict_en: "Hai Sok",
            post_code: "41160",
          },
          {
            subdistrict_th: "เขือน้ำ",
            subdistrict_en: "Khuea Nam",
            post_code: "41160",
          },
          {
            subdistrict_th: "คำบง",
            subdistrict_en: "Kham Bong",
            post_code: "41160",
          },
          {
            subdistrict_th: "โนนทอง",
            subdistrict_en: "Non Thong",
            post_code: "41160",
          },
          {
            subdistrict_th: "ข้าวสาร",
            subdistrict_en: "Khao San",
            post_code: "41160",
          },
          {
            subdistrict_th: "จำปาโมง",
            subdistrict_en: "Champa Mong",
            post_code: "41160",
          },
          {
            subdistrict_th: "กลางใหญ่",
            subdistrict_en: "Klang Yai",
            post_code: "41160",
          },
          {
            subdistrict_th: "เมืองพาน",
            subdistrict_en: "Mueang Phan",
            post_code: "41160",
          },
          {
            subdistrict_th: "คำด้วง",
            subdistrict_en: "Kham Duang",
            post_code: "41160",
          },
          {
            subdistrict_th: "หนองหัวคู",
            subdistrict_en: "Nong Hua Khu",
            post_code: "41160",
          },
          {
            subdistrict_th: "บ้านค้อ",
            subdistrict_en: "Ban Kho",
            post_code: "41160",
          },
          {
            subdistrict_th: "หนองแวง",
            subdistrict_en: "Nong Waeng",
            post_code: "41160",
          },
        ],
      },
      {
        district_th: "อำเภอน้ำโสม",
        district_en: "Amphoe Nam Som",
        children: [
          {
            subdistrict_th: "นางัว",
            subdistrict_en: "Na Ngua",
            post_code: "41210",
          },
          {
            subdistrict_th: "น้ำโสม",
            subdistrict_en: "Nam Som",
            post_code: "41210",
          },
          {
            subdistrict_th: "หนองแวง",
            subdistrict_en: "Nong Waeng",
            post_code: "41210",
          },
          {
            subdistrict_th: "บ้านหยวก",
            subdistrict_en: "Ban Yuak",
            post_code: "41210",
          },
          {
            subdistrict_th: "โสมเยี่ยม",
            subdistrict_en: "Som Yiam",
            post_code: "41210",
          },
          {
            subdistrict_th: "ศรีสำราญ",
            subdistrict_en: "Si Samran",
            post_code: "41210",
          },
          {
            subdistrict_th: "สามัคคี",
            subdistrict_en: "Samakkhi",
            post_code: "41210",
          },
        ],
      },
      {
        district_th: "อำเภอเพ็ญ",
        district_en: "Amphoe Phen",
        children: [
          {
            subdistrict_th: "เพ็ญ",
            subdistrict_en: "Phen",
            post_code: "41150",
          },
          {
            subdistrict_th: "บ้านธาตุ",
            subdistrict_en: "Ban That",
            post_code: "41150",
          },
          {
            subdistrict_th: "นาพู่",
            subdistrict_en: "Na Phu",
            post_code: "41150",
          },
          {
            subdistrict_th: "เชียงหวาง",
            subdistrict_en: "Chiang Wang",
            post_code: "41150",
          },
          {
            subdistrict_th: "สุมเส้า",
            subdistrict_en: "Sum Sao",
            post_code: "41150",
          },
          {
            subdistrict_th: "นาบัว",
            subdistrict_en: "Na Bua",
            post_code: "41150",
          },
          {
            subdistrict_th: "บ้านเหล่า",
            subdistrict_en: "Ban Lao",
            post_code: "41150",
          },
          {
            subdistrict_th: "จอมศรี",
            subdistrict_en: "Chom Si",
            post_code: "41150",
          },
          {
            subdistrict_th: "เตาไห",
            subdistrict_en: "Tao Hai",
            post_code: "41150",
          },
          {
            subdistrict_th: "โคกกลาง",
            subdistrict_en: "Khok Klang",
            post_code: "41150",
          },
          {
            subdistrict_th: "สร้างแป้น",
            subdistrict_en: "Sang Paen",
            post_code: "41150",
          },
        ],
      },
      {
        district_th: "อำเภอสร้างคอม",
        district_en: "Amphoe Sang Khom",
        children: [
          {
            subdistrict_th: "สร้างคอม",
            subdistrict_en: "Sang Khom",
            post_code: "41260",
          },
          {
            subdistrict_th: "เชียงดา",
            subdistrict_en: "Chiang Da",
            post_code: "41260",
          },
          {
            subdistrict_th: "บ้านยวด",
            subdistrict_en: "Ban Yuat",
            post_code: "41260",
          },
          {
            subdistrict_th: "บ้านโคก",
            subdistrict_en: "Ban Khok",
            post_code: "41260",
          },
          {
            subdistrict_th: "นาสะอาด",
            subdistrict_en: "Na Sa-at",
            post_code: "41260",
          },
          {
            subdistrict_th: "บ้านหินโงม",
            subdistrict_en: "Bang Hin Ngom",
            post_code: "41260",
          },
        ],
      },
      {
        district_th: "อำเภอหนองแสง",
        district_en: "Amphoe Nong Saeng",
        children: [
          {
            subdistrict_th: "หนองแสง",
            subdistrict_en: "Nong Saeng",
            post_code: "41340",
          },
          {
            subdistrict_th: "แสงสว่าง",
            subdistrict_en: "Saeng Sawang",
            post_code: "41340",
          },
          {
            subdistrict_th: "นาดี",
            subdistrict_en: "Na Di",
            post_code: "41340",
          },
          {
            subdistrict_th: "ทับกุง",
            subdistrict_en: "Thap Kung",
            post_code: "41340",
          },
        ],
      },
      {
        district_th: "อำเภอนายูง",
        district_en: "Amphoe Na Yung",
        children: [
          {
            subdistrict_th: "นายูง",
            subdistrict_en: "Na Yung",
            post_code: "41380",
          },
          {
            subdistrict_th: "บ้านก้อง",
            subdistrict_en: "Ban Kong",
            post_code: "41380",
          },
          {
            subdistrict_th: "นาแค",
            subdistrict_en: "Na Khae",
            post_code: "41380",
          },
          {
            subdistrict_th: "โนนทอง",
            subdistrict_en: "Non Thong",
            post_code: "41380",
          },
        ],
      },
      {
        district_th: "อำเภอพิบูลย์รักษ์",
        district_en: "Amphoe Phibun Rak",
        children: [
          {
            subdistrict_th: "บ้านแดง",
            subdistrict_en: "Ban Daeng",
            post_code: "41130",
          },
          {
            subdistrict_th: "นาทราย",
            subdistrict_en: "Na Sai",
            post_code: "41130",
          },
          {
            subdistrict_th: "ดอนกลอย",
            subdistrict_en: "Don Kloi",
            post_code: "41130",
          },
        ],
      },
      {
        district_th: "อำเภอกู่แก้ว",
        district_en: "Amphoe Ku Kaeo",
        children: [
          {
            subdistrict_th: "บ้านจีต",
            subdistrict_en: "Ban Chit",
            post_code: "41130",
          },
          {
            subdistrict_th: "โนนทองอินทร์",
            subdistrict_en: "Non Thong In",
            post_code: "41130",
          },
          {
            subdistrict_th: "ค้อใหญ่",
            subdistrict_en: "Kho Yai",
            post_code: "41130",
          },
          {
            subdistrict_th: "คอนสาย",
            subdistrict_en: "Khon Sai",
            post_code: "41130",
          },
        ],
      },
      {
        district_th: "อำเภอประจักษ์ศิลปาคม",
        district_en: "Amphoe Prachak Sinlapakhom",
        children: [
          {
            subdistrict_th: "นาม่วง",
            subdistrict_en: "Na Muang",
            post_code: "41110",
          },
          {
            subdistrict_th: "ห้วยสามพาด",
            subdistrict_en: "Huai Sam Phat",
            post_code: "41110",
          },
          {
            subdistrict_th: "อุ่มจาน",
            subdistrict_en: "Um Chan",
            post_code: "41110",
          },
        ],
      },
    ],
  },
  {
    province_th: "เลย",
    province_en: "Loei",
    children: [
      {
        district_th: "อำเภอเมืองเลย",
        district_en: "Amphoe Mueang Loei",
        children: [
          {
            subdistrict_th: "กุดป่อง",
            subdistrict_en: "Kut Pong",
            post_code: "42000",
          },
          {
            subdistrict_th: "เมือง",
            subdistrict_en: "Mueang",
            post_code: "42000",
          },
          {
            subdistrict_th: "นาอ้อ",
            subdistrict_en: "Na O",
            post_code: "42100",
          },
          {
            subdistrict_th: "กกดู่",
            subdistrict_en: "Kok Du",
            post_code: "42000",
          },
          {
            subdistrict_th: "น้ำหมาน",
            subdistrict_en: "Nam Man",
            post_code: "42000",
          },
          {
            subdistrict_th: "เสี้ยว",
            subdistrict_en: "Siao",
            post_code: "42000",
          },
          {
            subdistrict_th: "นาอาน",
            subdistrict_en: "Na An",
            post_code: "42000",
          },
          {
            subdistrict_th: "นาโป่ง",
            subdistrict_en: "Na Pong",
            post_code: "42000",
          },
          {
            subdistrict_th: "นาดินดำ",
            subdistrict_en: "Na Din Dam",
            post_code: "42000",
          },
          {
            subdistrict_th: "น้ำสวย",
            subdistrict_en: "Nam Suai",
            post_code: "42000",
          },
          {
            subdistrict_th: "ชัยพฤกษ์",
            subdistrict_en: "Chaiyaphruek",
            post_code: "42000",
          },
          {
            subdistrict_th: "นาแขม",
            subdistrict_en: "Na Khaem",
            post_code: "42000",
          },
          {
            subdistrict_th: "ศรีสองรัก",
            subdistrict_en: "Si Song Rak",
            post_code: "42100",
          },
          {
            subdistrict_th: "กกทอง",
            subdistrict_en: "Kok Thong",
            post_code: "42000",
          },
        ],
      },
      {
        district_th: "อำเภอนาด้วง",
        district_en: "Amphoe Na Duang",
        children: [
          {
            subdistrict_th: "นาด้วง",
            subdistrict_en: "Na Duang",
            post_code: "42210",
          },
          {
            subdistrict_th: "นาดอกคำ",
            subdistrict_en: "Na Dok Kham",
            post_code: "42210",
          },
          {
            subdistrict_th: "ท่าสะอาด",
            subdistrict_en: "Tha Sa-at",
            post_code: "42210",
          },
          {
            subdistrict_th: "ท่าสวรรค์",
            subdistrict_en: "Tha Sawan",
            post_code: "42210",
          },
        ],
      },
      {
        district_th: "อำเภอเชียงคาน",
        district_en: "Amphoe Chiang Khan",
        children: [
          {
            subdistrict_th: "เชียงคาน",
            subdistrict_en: "Chiang Khan",
            post_code: "42110",
          },
          {
            subdistrict_th: "ธาตุ",
            subdistrict_en: "That",
            post_code: "42110",
          },
          {
            subdistrict_th: "นาซ่าว",
            subdistrict_en: "Na Sao",
            post_code: "42110",
          },
          {
            subdistrict_th: "เขาแก้ว",
            subdistrict_en: "Khao Kaeo",
            post_code: "42110",
          },
          {
            subdistrict_th: "ปากตม",
            subdistrict_en: "Pak Tom",
            post_code: "42110",
          },
          {
            subdistrict_th: "บุฮม",
            subdistrict_en: "Bu Hom",
            post_code: "42110",
          },
          {
            subdistrict_th: "จอมศรี",
            subdistrict_en: "Chom Si",
            post_code: "42110",
          },
          {
            subdistrict_th: "หาดทรายขาว",
            subdistrict_en: "Hat Sai Khao",
            post_code: "42110",
          },
        ],
      },
      {
        district_th: "อำเภอปากชม",
        district_en: "Amphoe Pak Chom",
        children: [
          {
            subdistrict_th: "ปากชม",
            subdistrict_en: "Pak Chom",
            post_code: "42150",
          },
          {
            subdistrict_th: "เชียงกลม",
            subdistrict_en: "Chiang Klom",
            post_code: "42150",
          },
          {
            subdistrict_th: "หาดคัมภีร์",
            subdistrict_en: "Hat Khamphi",
            post_code: "42150",
          },
          {
            subdistrict_th: "ห้วยบ่อซืน",
            subdistrict_en: "Huai Bo Suen",
            post_code: "42150",
          },
          {
            subdistrict_th: "ห้วยพิชัย",
            subdistrict_en: "Huai Phichai",
            post_code: "42150",
          },
          {
            subdistrict_th: "ชมเจริญ",
            subdistrict_en: "Chom Charoen",
            post_code: "42150",
          },
        ],
      },
      {
        district_th: "อำเภอด่านซ้าย",
        district_en: "Amphoe Dan Sai",
        children: [
          {
            subdistrict_th: "ด่านซ้าย",
            subdistrict_en: "Dan Sai",
            post_code: "42120",
          },
          {
            subdistrict_th: "ปากหมัน",
            subdistrict_en: "Pak Man",
            post_code: "42120",
          },
          {
            subdistrict_th: "นาดี",
            subdistrict_en: "Na Di",
            post_code: "42120",
          },
          {
            subdistrict_th: "โคกงาม",
            subdistrict_en: "Khok Ngam",
            post_code: "42120",
          },
          {
            subdistrict_th: "โพนสูง",
            subdistrict_en: "Phon Sung",
            post_code: "42120",
          },
          {
            subdistrict_th: "อิปุ่ม",
            subdistrict_en: "Ipum",
            post_code: "42120",
          },
          {
            subdistrict_th: "กกสะทอน",
            subdistrict_en: "Kok Sathon",
            post_code: "42120",
          },
          {
            subdistrict_th: "โป่ง",
            subdistrict_en: "Pong",
            post_code: "42120",
          },
          {
            subdistrict_th: "วังยาว",
            subdistrict_en: "Wang Yao",
            post_code: "42120",
          },
          {
            subdistrict_th: "นาหอ",
            subdistrict_en: "Na Ho",
            post_code: "42120",
          },
        ],
      },
      {
        district_th: "อำเภอนาแห้ว",
        district_en: "Amphoe Na Haeo",
        children: [
          {
            subdistrict_th: "นาแห้ว",
            subdistrict_en: "Na Haeo",
            post_code: "42170",
          },
          {
            subdistrict_th: "แสงภา",
            subdistrict_en: "Saeng Pha",
            post_code: "42170",
          },
          {
            subdistrict_th: "นาพึง",
            subdistrict_en: "Na Phueng",
            post_code: "42170",
          },
          {
            subdistrict_th: "นามาลา",
            subdistrict_en: "Na Ma La",
            post_code: "42170",
          },
          {
            subdistrict_th: "เหล่ากอหก",
            subdistrict_en: "Lao Ko Hok",
            post_code: "42170",
          },
        ],
      },
      {
        district_th: "อำเภอภูเรือ",
        district_en: "Amphoe Phu Ruea",
        children: [
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "42160",
          },
          {
            subdistrict_th: "ท่าศาลา",
            subdistrict_en: "Tha Sala",
            post_code: "42160",
          },
          {
            subdistrict_th: "ร่องจิก",
            subdistrict_en: "Rong Chik",
            post_code: "42160",
          },
          {
            subdistrict_th: "ปลาบ่า",
            subdistrict_en: "Pla Ba",
            post_code: "42160",
          },
          {
            subdistrict_th: "ลาดค่าง",
            subdistrict_en: "Lat Khang",
            post_code: "42160",
          },
          {
            subdistrict_th: "สานตม",
            subdistrict_en: "San Tom",
            post_code: "42160",
          },
        ],
      },
      {
        district_th: "อำเภอท่าลี่",
        district_en: "Amphoe Tha Li",
        children: [
          {
            subdistrict_th: "ท่าลี่",
            subdistrict_en: "Tha Li",
            post_code: "42140",
          },
          {
            subdistrict_th: "หนองผือ",
            subdistrict_en: "Nong Phue",
            post_code: "42140",
          },
          {
            subdistrict_th: "อาฮี",
            subdistrict_en: "Ahi",
            post_code: "42140",
          },
          {
            subdistrict_th: "น้ำแคม",
            subdistrict_en: "Nam Khaem",
            post_code: "42140",
          },
          {
            subdistrict_th: "โคกใหญ่",
            subdistrict_en: "Khok Yai",
            post_code: "42140",
          },
          {
            subdistrict_th: "น้ำทูน",
            subdistrict_en: "Nam Thun",
            post_code: "42140",
          },
        ],
      },
      {
        district_th: "อำเภอวังสะพุง",
        district_en: "Amphoe Wang Saphung",
        children: [
          {
            subdistrict_th: "วังสะพุง",
            subdistrict_en: "Wang Saphung",
            post_code: "42130",
          },
          {
            subdistrict_th: "ทรายขาว",
            subdistrict_en: "Sai Khao",
            post_code: "42130",
          },
          {
            subdistrict_th: "หนองหญ้าปล้อง",
            subdistrict_en: "Nong Ya Plong",
            post_code: "42130",
          },
          {
            subdistrict_th: "หนองงิ้ว",
            subdistrict_en: "Nong Ngio",
            post_code: "42130",
          },
          {
            subdistrict_th: "ปากปวน",
            subdistrict_en: "Pak Puan",
            post_code: "42130",
          },
          {
            subdistrict_th: "ผาน้อย",
            subdistrict_en: "Pha Noi",
            post_code: "42130",
          },
          {
            subdistrict_th: "ผาบิ้ง",
            subdistrict_en: "Pha Bing",
            post_code: "42130",
          },
          {
            subdistrict_th: "เขาหลวง",
            subdistrict_en: "Khao Luang",
            post_code: "42130",
          },
          {
            subdistrict_th: "โคกขมิ้น",
            subdistrict_en: "Khok Khamin",
            post_code: "42130",
          },
          {
            subdistrict_th: "ศรีสงคราม",
            subdistrict_en: "Si Songkhram",
            post_code: "42130",
          },
        ],
      },
      {
        district_th: "อำเภอภูกระดึง",
        district_en: "Amphoe Phu Kradueng",
        children: [
          {
            subdistrict_th: "ศรีฐาน",
            subdistrict_en: "Si Than",
            post_code: "42180",
          },
          {
            subdistrict_th: "ผานกเค้า",
            subdistrict_en: "Pha Nok Khao",
            post_code: "42180",
          },
          {
            subdistrict_th: "ภูกระดึง",
            subdistrict_en: "Phu Kradueng",
            post_code: "42180",
          },
          {
            subdistrict_th: "ห้วยส้ม",
            subdistrict_en: "Huai Som",
            post_code: "42180",
          },
        ],
      },
      {
        district_th: "อำเภอภูหลวง",
        district_en: "Amphoe Phu Luang",
        children: [
          {
            subdistrict_th: "ภูหอ",
            subdistrict_en: "Phu Ho",
            post_code: "42230",
          },
          {
            subdistrict_th: "หนองคัน",
            subdistrict_en: "Nong Khan",
            post_code: "42230",
          },
          {
            subdistrict_th: "ห้วยสีเสียด",
            subdistrict_en: "Huai Sisiat",
            post_code: "42230",
          },
          {
            subdistrict_th: "เลยวังไสย์",
            subdistrict_en: "Loei Wang Sai",
            post_code: "42230",
          },
          {
            subdistrict_th: "แก่งศรีภูมิ",
            subdistrict_en: "Kaeng Si Phum",
            post_code: "42230",
          },
        ],
      },
      {
        district_th: "อำเภอผาขาว",
        district_en: "Amphoe Pha Khao",
        children: [
          {
            subdistrict_th: "ผาขาว",
            subdistrict_en: "Pha Khao",
            post_code: "42240",
          },
          {
            subdistrict_th: "ท่าช้างคล้อง",
            subdistrict_en: "Tha Chang Khlong",
            post_code: "42240",
          },
          {
            subdistrict_th: "โนนปอแดง",
            subdistrict_en: "Non Po Daeng",
            post_code: "42240",
          },
          {
            subdistrict_th: "โนนป่าซาง",
            subdistrict_en: "Non Pa Sang",
            post_code: "42240",
          },
          {
            subdistrict_th: "บ้านเพิ่ม",
            subdistrict_en: "Ban Phoem",
            post_code: "42240",
          },
        ],
      },
      {
        district_th: "อำเภอเอราวัณ",
        district_en: "Amphoe Erawan",
        children: [
          {
            subdistrict_th: "เอราวัณ",
            subdistrict_en: "Erawan",
            post_code: "42220",
          },
          {
            subdistrict_th: "ผาอินทร์แปลง",
            subdistrict_en: "Pha In Plaeng",
            post_code: "42220",
          },
          {
            subdistrict_th: "ผาสามยอด",
            subdistrict_en: "Pha Sam Yot",
            post_code: "42220",
          },
          {
            subdistrict_th: "ทรัพย์ไพวัลย์",
            subdistrict_en: "Sap Phaiwan",
            post_code: "42220",
          },
        ],
      },
      {
        district_th: "อำเภอหนองหิน",
        district_en: "Amphoe Nong Hin",
        children: [
          {
            subdistrict_th: "หนองหิน",
            subdistrict_en: "Nong Hin",
            post_code: "42190",
          },
          {
            subdistrict_th: "ตาดข่า",
            subdistrict_en: "Tat Kha",
            post_code: "42190",
          },
          {
            subdistrict_th: "ปวนพุ",
            subdistrict_en: "Puan Phu",
            post_code: "42190",
          },
        ],
      },
    ],
  },
  {
    province_th: "หนองคาย",
    province_en: "Nong Khai",
    children: [
      {
        district_th: "อำเภอเมืองหนองคาย",
        district_en: "Amphoe Mueang Nong Khai",
        children: [
          {
            subdistrict_th: "ในเมือง",
            subdistrict_en: "Nai Mueang",
            post_code: "43000",
          },
          {
            subdistrict_th: "มีชัย",
            subdistrict_en: "Mi Chai",
            post_code: "43000",
          },
          {
            subdistrict_th: "โพธิ์ชัย",
            subdistrict_en: "Pho Chai",
            post_code: "43000",
          },
          {
            subdistrict_th: "กวนวัน",
            subdistrict_en: "Kuan Wan",
            post_code: "43000",
          },
          {
            subdistrict_th: "เวียงคุก",
            subdistrict_en: "Wiang Khuk",
            post_code: "43000",
          },
          {
            subdistrict_th: "วัดธาตุ",
            subdistrict_en: "Wat That",
            post_code: "43000",
          },
          {
            subdistrict_th: "หาดคำ",
            subdistrict_en: "Hat Kham",
            post_code: "43000",
          },
          {
            subdistrict_th: "หินโงม",
            subdistrict_en: "Hin Ngom",
            post_code: "43000",
          },
          {
            subdistrict_th: "บ้านเดื่อ",
            subdistrict_en: "Ban Duea",
            post_code: "43000",
          },
          {
            subdistrict_th: "ค่ายบกหวาน",
            subdistrict_en: "Khai Bok Wan",
            post_code: "43100",
          },
          {
            subdistrict_th: "โพนสว่าง",
            subdistrict_en: "Phon Sawang",
            post_code: "43100",
          },
          {
            subdistrict_th: "พระธาตุบังพวน",
            subdistrict_en: "Phra That Bang Phuan",
            post_code: "43100",
          },
          {
            subdistrict_th: "หนองกอมเกาะ",
            subdistrict_en: "Nong Kom Ko",
            post_code: "43000",
          },
          {
            subdistrict_th: "ปะโค",
            subdistrict_en: "Pa Kho",
            post_code: "43000",
          },
          {
            subdistrict_th: "เมืองหมี",
            subdistrict_en: "Mueang Mi",
            post_code: "43000",
          },
          {
            subdistrict_th: "สีกาย",
            subdistrict_en: "Si Kai",
            post_code: "43000",
          },
        ],
      },
      {
        district_th: "อำเภอท่าบ่อ",
        district_en: "Amphoe Tha Bo",
        children: [
          {
            subdistrict_th: "ท่าบ่อ",
            subdistrict_en: "Tha Bo",
            post_code: "43110",
          },
          {
            subdistrict_th: "น้ำโมง",
            subdistrict_en: "Nam Mong",
            post_code: "43110",
          },
          {
            subdistrict_th: "กองนาง",
            subdistrict_en: "Kong Nang",
            post_code: "43110",
          },
          {
            subdistrict_th: "โคกคอน",
            subdistrict_en: "Khok Khon",
            post_code: "43110",
          },
          {
            subdistrict_th: "บ้านเดื่อ",
            subdistrict_en: "Ban Duea",
            post_code: "43110",
          },
          {
            subdistrict_th: "บ้านถ่อน",
            subdistrict_en: "Ban Thon",
            post_code: "43110",
          },
          {
            subdistrict_th: "บ้านว่าน",
            subdistrict_en: "Ban Wan",
            post_code: "43110",
          },
          {
            subdistrict_th: "นาข่า",
            subdistrict_en: "Na Kha",
            post_code: "43110",
          },
          {
            subdistrict_th: "โพนสา",
            subdistrict_en: "Phon Sa",
            post_code: "43110",
          },
          {
            subdistrict_th: "หนองนาง",
            subdistrict_en: "Nong Nang",
            post_code: "43110",
          },
        ],
      },
      {
        district_th: "อำเภอโพนพิสัย",
        district_en: "Amphoe Phon Phisai",
        children: [
          {
            subdistrict_th: "จุมพล",
            subdistrict_en: "Chumphon",
            post_code: "43120",
          },
          {
            subdistrict_th: "วัดหลวง",
            subdistrict_en: "Wat Luang",
            post_code: "43120",
          },
          {
            subdistrict_th: "กุดบง",
            subdistrict_en: "Kut Bong",
            post_code: "43120",
          },
          {
            subdistrict_th: "ชุมช้าง",
            subdistrict_en: "Chum Chang",
            post_code: "43120",
          },
          {
            subdistrict_th: "ทุ่งหลวง",
            subdistrict_en: "Thung Luang",
            post_code: "43120",
          },
          {
            subdistrict_th: "เหล่าต่างคำ",
            subdistrict_en: "Lao Tang Kham",
            post_code: "43120",
          },
          {
            subdistrict_th: "นาหนัง",
            subdistrict_en: "Na Nang",
            post_code: "43120",
          },
          {
            subdistrict_th: "เซิม",
            subdistrict_en: "Soem",
            post_code: "43120",
          },
          {
            subdistrict_th: "บ้านโพธิ์",
            subdistrict_en: "Ban Pho",
            post_code: "43120",
          },
          {
            subdistrict_th: "บ้านผือ",
            subdistrict_en: "Ban Phue",
            post_code: "43120",
          },
          {
            subdistrict_th: "สร้างนางขาว",
            subdistrict_en: "Sang Nang Khao",
            post_code: "43120",
          },
        ],
      },
      {
        district_th: "อำเภอศรีเชียงใหม่",
        district_en: "Amphoe Si Chiang Mai",
        children: [
          {
            subdistrict_th: "พานพร้าว",
            subdistrict_en: "Phan Phrao",
            post_code: "43130",
          },
          {
            subdistrict_th: "บ้านหม้อ",
            subdistrict_en: "Ban Mo",
            post_code: "43130",
          },
          {
            subdistrict_th: "พระพุทธบาท",
            subdistrict_en: "Phra Phutthabat",
            post_code: "43130",
          },
          {
            subdistrict_th: "หนองปลาปาก",
            subdistrict_en: "Nong Pla Pak",
            post_code: "43130",
          },
        ],
      },
      {
        district_th: "อำเภอสังคม",
        district_en: "Amphoe Sangkhom",
        children: [
          {
            subdistrict_th: "แก้งไก่",
            subdistrict_en: "Kaeng Kai",
            post_code: "43160",
          },
          {
            subdistrict_th: "ผาตั้ง",
            subdistrict_en: "Pha Tang",
            post_code: "43160",
          },
          {
            subdistrict_th: "บ้านม่วง",
            subdistrict_en: "Ban Muang",
            post_code: "43160",
          },
          {
            subdistrict_th: "นางิ้ว",
            subdistrict_en: "Na Ngio",
            post_code: "43160",
          },
          {
            subdistrict_th: "สังคม",
            subdistrict_en: "Sangkhom",
            post_code: "43160",
          },
        ],
      },
      {
        district_th: "อำเภอสระใคร",
        district_en: "Amphoe Sakhrai",
        children: [
          {
            subdistrict_th: "สระใคร",
            subdistrict_en: "Sakhrai",
            post_code: "43100",
          },
          {
            subdistrict_th: "คอกช้าง",
            subdistrict_en: "Khok Chang",
            post_code: "43100",
          },
          {
            subdistrict_th: "บ้านฝาง",
            subdistrict_en: "Ban Fang",
            post_code: "43100",
          },
        ],
      },
      {
        district_th: "อำเภอเฝ้าไร่",
        district_en: "Amphoe Fao Rai",
        children: [
          {
            subdistrict_th: "เฝ้าไร่",
            subdistrict_en: "Fao Rai",
            post_code: "43230",
          },
          {
            subdistrict_th: "นาดี",
            subdistrict_en: "Na Di",
            post_code: "43230",
          },
          {
            subdistrict_th: "หนองหลวง",
            subdistrict_en: "Nong Luang",
            post_code: "43230",
          },
          {
            subdistrict_th: "วังหลวง",
            subdistrict_en: "wang Luang",
            post_code: "43230",
          },
          {
            subdistrict_th: "อุดมพร",
            subdistrict_en: "Udom Phon",
            post_code: "43230",
          },
        ],
      },
      {
        district_th: "อำเภอรัตนวาปี",
        district_en: "Amphoe Rattanawapi",
        children: [
          {
            subdistrict_th: "รัตนวาปี",
            subdistrict_en: "Rattanawapi",
            post_code: "43120",
          },
          {
            subdistrict_th: "นาทับไฮ",
            subdistrict_en: "Na Thap Hai",
            post_code: "43120",
          },
          {
            subdistrict_th: "บ้านต้อน",
            subdistrict_en: "Ban Ton",
            post_code: "43120",
          },
          {
            subdistrict_th: "พระบาทนาสิงห์",
            subdistrict_en: "Phra Bat Na Sing",
            post_code: "43120",
          },
          {
            subdistrict_th: "โพนแพง",
            subdistrict_en: "Phon Phaeng",
            post_code: "43120",
          },
        ],
      },
      {
        district_th: "อำเภอโพธิ์ตาก",
        district_en: "Amphoe Pho Tak",
        children: [
          {
            subdistrict_th: "โพธิ์ตาก",
            subdistrict_en: "Pho Tak",
            post_code: "43130",
          },
          {
            subdistrict_th: "โพนทอง",
            subdistrict_en: "Phon Thong",
            post_code: "43130",
          },
          {
            subdistrict_th: "ด่านศรีสุข",
            subdistrict_en: "Dan Si Suk",
            post_code: "43130",
          },
        ],
      },
    ],
  },
  {
    province_th: "มหาสารคาม",
    province_en: "Maha Sarakham",
    children: [
      {
        district_th: "อำเภอเมืองมหาสารคาม",
        district_en: "Amphoe Mueang Maha Sarakham",
        children: [
          {
            subdistrict_th: "ตลาด",
            subdistrict_en: "Talat",
            post_code: "44000",
          },
          {
            subdistrict_th: "เขวา",
            subdistrict_en: "Khwao",
            post_code: "44000",
          },
          {
            subdistrict_th: "ท่าตูม",
            subdistrict_en: "Tha Tum",
            post_code: "44000",
          },
          {
            subdistrict_th: "แวงน่าง",
            subdistrict_en: "Waeng Nang",
            post_code: "44000",
          },
          {
            subdistrict_th: "โคกก่อ",
            subdistrict_en: "Khok Ko",
            post_code: "44000",
          },
          {
            subdistrict_th: "ดอนหว่าน",
            subdistrict_en: "Don Wan",
            post_code: "44000",
          },
          {
            subdistrict_th: "เกิ้ง",
            subdistrict_en: "Koeng",
            post_code: "44000",
          },
          {
            subdistrict_th: "แก่งเลิงจาน",
            subdistrict_en: "Kaeng Loeng Chan",
            post_code: "44000",
          },
          {
            subdistrict_th: "ท่าสองคอน",
            subdistrict_en: "Tha Song Khon",
            post_code: "44000",
          },
          {
            subdistrict_th: "ลาดพัฒนา",
            subdistrict_en: "Lat Phatthana",
            post_code: "44000",
          },
          {
            subdistrict_th: "หนองปลิง",
            subdistrict_en: "Nong Pling",
            post_code: "44000",
          },
          {
            subdistrict_th: "ห้วยแอ่ง",
            subdistrict_en: "Huai Aeng",
            post_code: "44000",
          },
          {
            subdistrict_th: "หนองโน",
            subdistrict_en: "Nong No",
            post_code: "44000",
          },
          {
            subdistrict_th: "บัวค้อ",
            subdistrict_en: "Bua Kho",
            post_code: "44000",
          },
        ],
      },
      {
        district_th: "อำเภอแกดำ",
        district_en: "Amphoe Kae Dam",
        children: [
          {
            subdistrict_th: "แกดำ",
            subdistrict_en: "Kae Dam",
            post_code: "44190",
          },
          {
            subdistrict_th: "วังแสง",
            subdistrict_en: "Wang Saeng",
            post_code: "44190",
          },
          {
            subdistrict_th: "มิตรภาพ",
            subdistrict_en: "Mittraphap",
            post_code: "44190",
          },
          {
            subdistrict_th: "หนองกุง",
            subdistrict_en: "Nong Kung",
            post_code: "44190",
          },
          {
            subdistrict_th: "โนนภิบาล",
            subdistrict_en: "Non Phiban",
            post_code: "44190",
          },
        ],
      },
      {
        district_th: "อำเภอโกสุมพิสัย",
        district_en: "Amphoe Kosum Phisai",
        children: [
          {
            subdistrict_th: "หัวขวาง",
            subdistrict_en: "Hua Khwang",
            post_code: "44140",
          },
          {
            subdistrict_th: "ยางน้อย",
            subdistrict_en: "Yang Noi",
            post_code: "44140",
          },
          {
            subdistrict_th: "วังยาว",
            subdistrict_en: "Wang Yao",
            post_code: "44140",
          },
          {
            subdistrict_th: "เขวาไร่",
            subdistrict_en: "Khwao Rai",
            post_code: "44140",
          },
          {
            subdistrict_th: "แพง",
            subdistrict_en: "Phaeng",
            post_code: "44140",
          },
          {
            subdistrict_th: "แก้งแก",
            subdistrict_en: "Kaeng Kae",
            post_code: "44140",
          },
          {
            subdistrict_th: "หนองเหล็ก",
            subdistrict_en: "Nong Lek",
            post_code: "44140",
          },
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "44140",
          },
          {
            subdistrict_th: "เหล่า",
            subdistrict_en: "Lao",
            post_code: "44140",
          },
          {
            subdistrict_th: "เขื่อน",
            subdistrict_en: "Khuean",
            post_code: "44140",
          },
          {
            subdistrict_th: "หนองบอน",
            subdistrict_en: "Nong Bon",
            post_code: "44140",
          },
          {
            subdistrict_th: "โพนงาม",
            subdistrict_en: "Phon Ngam",
            post_code: "44140",
          },
          {
            subdistrict_th: "ยางท่าแจ้ง",
            subdistrict_en: "Yang Tha Chaeng",
            post_code: "44140",
          },
          {
            subdistrict_th: "แห่ใต้",
            subdistrict_en: "Hae Tai",
            post_code: "44140",
          },
          {
            subdistrict_th: "หนองกุงสวรรค์",
            subdistrict_en: "Nong Kung Sawan",
            post_code: "44140",
          },
          {
            subdistrict_th: "เลิงใต้",
            subdistrict_en: "Loeng Tai",
            post_code: "44140",
          },
          {
            subdistrict_th: "ดอนกลาง",
            subdistrict_en: "Don Klang",
            post_code: "44140",
          },
        ],
      },
      {
        district_th: "อำเภอกันทรวิชัย",
        district_en: "Amphoe Kantharawichai",
        children: [
          {
            subdistrict_th: "โคกพระ",
            subdistrict_en: "Khok Phra",
            post_code: "44150",
          },
          {
            subdistrict_th: "คันธารราษฎร์",
            subdistrict_en: "Khanthararat",
            post_code: "44150",
          },
          {
            subdistrict_th: "มะค่า",
            subdistrict_en: "Makha",
            post_code: "44150",
          },
          {
            subdistrict_th: "ท่าขอนยาง",
            subdistrict_en: "Tha Khon Yang",
            post_code: "44150",
          },
          {
            subdistrict_th: "นาสีนวน",
            subdistrict_en: "Na Si Nuan",
            post_code: "44150",
          },
          {
            subdistrict_th: "ขามเรียง",
            subdistrict_en: "Kham Riang",
            post_code: "44150",
          },
          {
            subdistrict_th: "เขวาใหญ่",
            subdistrict_en: "Khwao Yai",
            post_code: "44150",
          },
          {
            subdistrict_th: "ศรีสุข",
            subdistrict_en: "Si Suk",
            post_code: "44150",
          },
          {
            subdistrict_th: "กุดใส้จ่อ",
            subdistrict_en: "Kut Sai Cho",
            post_code: "44150",
          },
          {
            subdistrict_th: "ขามเฒ่าพัฒนา",
            subdistrict_en: "Kham Thao Phatthana",
            post_code: "44150",
          },
        ],
      },
      {
        district_th: "อำเภอเชียงยืน",
        district_en: "Amphoe Chiang Yuen",
        children: [
          {
            subdistrict_th: "เชียงยืน",
            subdistrict_en: "Chiang Yuen",
            post_code: "44160",
          },
          {
            subdistrict_th: "หนองซอน",
            subdistrict_en: "Nong Son",
            post_code: "44160",
          },
          {
            subdistrict_th: "ดอนเงิน",
            subdistrict_en: "Don Ngoen",
            post_code: "44160",
          },
          {
            subdistrict_th: "กู่ทอง",
            subdistrict_en: "Ku Thong",
            post_code: "44160",
          },
          {
            subdistrict_th: "นาทอง",
            subdistrict_en: "Na Thong",
            post_code: "44160",
          },
          {
            subdistrict_th: "เสือเฒ่า",
            subdistrict_en: "Suea Thao",
            post_code: "44160",
          },
          {
            subdistrict_th: "โพนทอง",
            subdistrict_en: "Phon Thong",
            post_code: "44160",
          },
          {
            subdistrict_th: "เหล่าบัวบาน",
            subdistrict_en: "Lao Bua Ban",
            post_code: "44160",
          },
        ],
      },
      {
        district_th: "อำเภอบรบือ",
        district_en: "Amphoe Borabue",
        children: [
          {
            subdistrict_th: "บรบือ",
            subdistrict_en: "Borabue",
            post_code: "44130",
          },
          {
            subdistrict_th: "บ่อใหญ่",
            subdistrict_en: "Bo Yai",
            post_code: "44130",
          },
          {
            subdistrict_th: "วังไชย",
            subdistrict_en: "Wang Chai",
            post_code: "44130",
          },
          {
            subdistrict_th: "หนองม่วง",
            subdistrict_en: "Nong Muang",
            post_code: "44130",
          },
          {
            subdistrict_th: "กำพี้",
            subdistrict_en: "Kam Phi",
            post_code: "44130",
          },
          {
            subdistrict_th: "โนนราษี",
            subdistrict_en: "Non Rasi",
            post_code: "44130",
          },
          {
            subdistrict_th: "โนนแดง",
            subdistrict_en: "Non Daeng",
            post_code: "44130",
          },
          {
            subdistrict_th: "หนองจิก",
            subdistrict_en: "Nong Chik",
            post_code: "44130",
          },
          {
            subdistrict_th: "บัวมาศ",
            subdistrict_en: "Bua Mat",
            post_code: "44130",
          },
          {
            subdistrict_th: "หนองคูขาด",
            subdistrict_en: "Nong Khu Khat",
            post_code: "44130",
          },
          {
            subdistrict_th: "วังใหม่",
            subdistrict_en: "Wang Mai",
            post_code: "44130",
          },
          {
            subdistrict_th: "ยาง",
            subdistrict_en: "Yang",
            post_code: "44130",
          },
          {
            subdistrict_th: "หนองสิม",
            subdistrict_en: "Nong Sim",
            post_code: "44130",
          },
          {
            subdistrict_th: "หนองโก",
            subdistrict_en: "Nong Ko",
            post_code: "44130",
          },
          {
            subdistrict_th: "ดอนงัว",
            subdistrict_en: "Don Ngua",
            post_code: "44130",
          },
        ],
      },
      {
        district_th: "อำเภอนาเชือก",
        district_en: "Amphoe Na Chueak",
        children: [
          {
            subdistrict_th: "นาเชือก",
            subdistrict_en: "Na Chueak",
            post_code: "44170",
          },
          {
            subdistrict_th: "สำโรง",
            subdistrict_en: "Samrong",
            post_code: "44170",
          },
          {
            subdistrict_th: "หนองแดง",
            subdistrict_en: "Nong Daeng",
            post_code: "44170",
          },
          {
            subdistrict_th: "เขวาไร่",
            subdistrict_en: "Khwao Rai",
            post_code: "44170",
          },
          {
            subdistrict_th: "หนองโพธิ์",
            subdistrict_en: "Nong Pho",
            post_code: "44170",
          },
          {
            subdistrict_th: "ปอพาน",
            subdistrict_en: "Po Phan",
            post_code: "44170",
          },
          {
            subdistrict_th: "หนองเม็ก",
            subdistrict_en: "Nong Mek",
            post_code: "44170",
          },
          {
            subdistrict_th: "หนองเรือ",
            subdistrict_en: "Nong Ruea",
            post_code: "44170",
          },
          {
            subdistrict_th: "หนองกุง",
            subdistrict_en: "Nong Kung",
            post_code: "44170",
          },
          {
            subdistrict_th: "สันป่าตอง",
            subdistrict_en: "San Pa Tong",
            post_code: "44170",
          },
        ],
      },
      {
        district_th: "อำเภอพยัคฆภูมิพิสัย",
        district_en: "Amphoe Phayakkhaphum Phisai",
        children: [
          {
            subdistrict_th: "ปะหลาน",
            subdistrict_en: "Palan",
            post_code: "44110",
          },
          {
            subdistrict_th: "ก้ามปู",
            subdistrict_en: "Kham Pu",
            post_code: "44110",
          },
          {
            subdistrict_th: "เวียงสะอาด",
            subdistrict_en: "Wiang Sa-at",
            post_code: "44110",
          },
          {
            subdistrict_th: "เม็กดำ",
            subdistrict_en: "Mek Dam",
            post_code: "44110",
          },
          {
            subdistrict_th: "นาสีนวล",
            subdistrict_en: "Na Si Nuan",
            post_code: "44110",
          },
          {
            subdistrict_th: "ราษฎร์เจริญ",
            subdistrict_en: "Rat Charoen",
            post_code: "44110",
          },
          {
            subdistrict_th: "หนองบัวแก้ว",
            subdistrict_en: "Nong Bua Kaeo",
            post_code: "44110",
          },
          {
            subdistrict_th: "เมืองเตา",
            subdistrict_en: "Mueang Tao",
            post_code: "44110",
          },
          {
            subdistrict_th: "ลานสะแก",
            subdistrict_en: "Lan Sakae",
            post_code: "44110",
          },
          {
            subdistrict_th: "เวียงชัย",
            subdistrict_en: "Wiang Chai",
            post_code: "44110",
          },
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "44110",
          },
          {
            subdistrict_th: "ราษฎร์พัฒนา",
            subdistrict_en: "Rat Phatthana",
            post_code: "44110",
          },
          {
            subdistrict_th: "เมืองเสือ",
            subdistrict_en: "Mueang Suea",
            post_code: "44110",
          },
          {
            subdistrict_th: "ภารแอ่น",
            subdistrict_en: "Phan Aen",
            post_code: "44110",
          },
        ],
      },
      {
        district_th: "อำเภอวาปีปทุม",
        district_en: "Amphoe Wapi Pathum",
        children: [
          {
            subdistrict_th: "หนองแสง",
            subdistrict_en: "Nong Saeng",
            post_code: "44120",
          },
          {
            subdistrict_th: "ขามป้อม",
            subdistrict_en: "Kham Pom",
            post_code: "44120",
          },
          {
            subdistrict_th: "เสือโก้ก",
            subdistrict_en: "Suea Kok",
            post_code: "44120",
          },
          {
            subdistrict_th: "ดงใหญ่",
            subdistrict_en: "Dong Yai",
            post_code: "44120",
          },
          {
            subdistrict_th: "โพธิ์ชัย",
            subdistrict_en: "Pho Chai",
            post_code: "44120",
          },
          {
            subdistrict_th: "หัวเรือ",
            subdistrict_en: "Hua Ruea",
            post_code: "44120",
          },
          {
            subdistrict_th: "แคน",
            subdistrict_en: "Khaen",
            post_code: "44120",
          },
          {
            subdistrict_th: "งัวบา",
            subdistrict_en: "Ngua Ba",
            post_code: "44120",
          },
          {
            subdistrict_th: "นาข่า",
            subdistrict_en: "Na Kha",
            post_code: "44120",
          },
          {
            subdistrict_th: "บ้านหวาย",
            subdistrict_en: "Ban Wai",
            post_code: "44120",
          },
          {
            subdistrict_th: "หนองไฮ",
            subdistrict_en: "Nong Hai",
            post_code: "44120",
          },
          {
            subdistrict_th: "ประชาพัฒนา",
            subdistrict_en: "Pracha Phatthana",
            post_code: "44120",
          },
          {
            subdistrict_th: "หนองทุ่ม",
            subdistrict_en: "Nong Thum",
            post_code: "44120",
          },
          {
            subdistrict_th: "หนองแสน",
            subdistrict_en: "Nong Saen",
            post_code: "44120",
          },
          {
            subdistrict_th: "โคกสีทองหลาง",
            subdistrict_en: "Khok Si Thonglang",
            post_code: "44120",
          },
        ],
      },
      {
        district_th: "อำเภอนาดูน",
        district_en: "Amphoe Na Dun",
        children: [
          {
            subdistrict_th: "นาดูน",
            subdistrict_en: "Na Dun",
            post_code: "44180",
          },
          {
            subdistrict_th: "หนองไผ่",
            subdistrict_en: "Nong Phai",
            post_code: "44180",
          },
          {
            subdistrict_th: "หนองคู",
            subdistrict_en: "Nong Khu",
            post_code: "44180",
          },
          {
            subdistrict_th: "ดงบัง",
            subdistrict_en: "Dong Bang",
            post_code: "44180",
          },
          {
            subdistrict_th: "ดงดวน",
            subdistrict_en: "Dong Duan",
            post_code: "44180",
          },
          {
            subdistrict_th: "หัวดง",
            subdistrict_en: "Hua Dong",
            post_code: "44180",
          },
          {
            subdistrict_th: "ดงยาง",
            subdistrict_en: "Dong Yang",
            post_code: "44180",
          },
          {
            subdistrict_th: "กู่สันตรัตน์",
            subdistrict_en: "Ku Santarat",
            post_code: "44180",
          },
          {
            subdistrict_th: "พระธาตุ",
            subdistrict_en: "Phra That",
            post_code: "44180",
          },
        ],
      },
      {
        district_th: "อำเภอยางสีสุราช",
        district_en: "Amphoe Yang Si Surat",
        children: [
          {
            subdistrict_th: "ยางสีสุราช",
            subdistrict_en: "Yang Si Surat",
            post_code: "44210",
          },
          {
            subdistrict_th: "นาภู",
            subdistrict_en: "Na Phu",
            post_code: "44210",
          },
          {
            subdistrict_th: "แวงดง",
            subdistrict_en: "Waeng Dong",
            post_code: "44210",
          },
          {
            subdistrict_th: "บ้านกู่",
            subdistrict_en: "Ban Ku",
            post_code: "44210",
          },
          {
            subdistrict_th: "ดงเมือง",
            subdistrict_en: "Dong Mueang",
            post_code: "44210",
          },
          {
            subdistrict_th: "สร้างแซ่ง",
            subdistrict_en: "Sang Saeng",
            post_code: "44210",
          },
          {
            subdistrict_th: "หนองบัวสันตุ",
            subdistrict_en: "Nong Bua Santu",
            post_code: "44210",
          },
        ],
      },
      {
        district_th: "อำเภอกุดรัง",
        district_en: "Amphoe Kut Rang",
        children: [
          {
            subdistrict_th: "กุดรัง",
            subdistrict_en: "Kut Rang",
            post_code: "44130",
          },
          {
            subdistrict_th: "นาโพธิ์",
            subdistrict_en: "Na Pho",
            post_code: "44130",
          },
          {
            subdistrict_th: "เลิงแฝก",
            subdistrict_en: "Loeng Faek",
            post_code: "44130",
          },
          {
            subdistrict_th: "หนองแวง",
            subdistrict_en: "Nong Waeng",
            post_code: "44130",
          },
          {
            subdistrict_th: "ห้วยเตย",
            subdistrict_en: "Huai Toei",
            post_code: "44130",
          },
        ],
      },
      {
        district_th: "อำเภอชื่นชม",
        district_en: "Amphoe Chuen Chom",
        children: [
          {
            subdistrict_th: "ชื่นชม",
            subdistrict_en: "Chuen Chom",
            post_code: "44160",
          },
          {
            subdistrict_th: "กุดปลาดุก",
            subdistrict_en: "Kut Pla Duk",
            post_code: "44160",
          },
          {
            subdistrict_th: "เหล่าดอกไม้",
            subdistrict_en: "Lao Dok Mai",
            post_code: "44160",
          },
          {
            subdistrict_th: "หนองกุง",
            subdistrict_en: "Nong Kung",
            post_code: "44160",
          },
        ],
      },
    ],
  },
  {
    province_th: "ร้อยเอ็ด",
    province_en: "Roi Et",
    children: [
      {
        district_th: "อำเภอเมืองร้อยเอ็ด",
        district_en: "Amphoe Mueang Roi Et",
        children: [
          {
            subdistrict_th: "ในเมือง",
            subdistrict_en: "Nai Mueang",
            post_code: "45000",
          },
          {
            subdistrict_th: "รอบเมือง",
            subdistrict_en: "Rop Mueang",
            post_code: "45000",
          },
          {
            subdistrict_th: "เหนือเมือง",
            subdistrict_en: "Nuea Mueang",
            post_code: "45000",
          },
          {
            subdistrict_th: "ขอนแก่น",
            subdistrict_en: "Khon Kaen",
            post_code: "45000",
          },
          {
            subdistrict_th: "นาโพธิ์",
            subdistrict_en: "Na Pho",
            post_code: "45000",
          },
          {
            subdistrict_th: "สะอาดสมบูรณ์",
            subdistrict_en: "Sa-at Sombun",
            post_code: "45000",
          },
          {
            subdistrict_th: "สีแก้ว",
            subdistrict_en: "Si Kaeo",
            post_code: "45000",
          },
          {
            subdistrict_th: "ปอภาร",
            subdistrict_en: "Po Phan",
            post_code: "45000",
          },
          {
            subdistrict_th: "โนนรัง",
            subdistrict_en: "Non Rang",
            post_code: "45000",
          },
          {
            subdistrict_th: "หนองแก้ว",
            subdistrict_en: "Nong Kaeo",
            post_code: "45000",
          },
          {
            subdistrict_th: "หนองแวง",
            subdistrict_en: "Nong Waeng",
            post_code: "45000",
          },
          {
            subdistrict_th: "ดงลาน",
            subdistrict_en: "Dong Lan",
            post_code: "45000",
          },
          {
            subdistrict_th: "แคนใหญ่",
            subdistrict_en: "Khaen Yai",
            post_code: "45000",
          },
          {
            subdistrict_th: "โนนตาล",
            subdistrict_en: "Non Tan",
            post_code: "45000",
          },
          {
            subdistrict_th: "เมืองทอง",
            subdistrict_en: "Mueang Thong",
            post_code: "45000",
          },
        ],
      },
      {
        district_th: "อำเภอเกษตรวิสัย",
        district_en: "Amphoe Kaset Wisai",
        children: [
          {
            subdistrict_th: "เกษตรวิสัย",
            subdistrict_en: "Kaset Wisai",
            post_code: "45150",
          },
          {
            subdistrict_th: "เมืองบัว",
            subdistrict_en: "Mueang Bua",
            post_code: "45150",
          },
          {
            subdistrict_th: "เหล่าหลวง",
            subdistrict_en: "Lao Luang",
            post_code: "45150",
          },
          {
            subdistrict_th: "สิงห์โคก",
            subdistrict_en: "Sing Khok",
            post_code: "45150",
          },
          {
            subdistrict_th: "ดงครั่งใหญ่",
            subdistrict_en: "Dong Khrang Yai",
            post_code: "45150",
          },
          {
            subdistrict_th: "บ้านฝาง",
            subdistrict_en: "Ban Fang",
            post_code: "45150",
          },
          {
            subdistrict_th: "หนองแวง",
            subdistrict_en: "Nong Waeng",
            post_code: "45150",
          },
          {
            subdistrict_th: "กำแพง",
            subdistrict_en: "Kamphaeng",
            post_code: "45150",
          },
          {
            subdistrict_th: "กู่กาสิงห์",
            subdistrict_en: "Ku Ka Sing",
            post_code: "45150",
          },
          {
            subdistrict_th: "น้ำอ้อม",
            subdistrict_en: "Nam Om",
            post_code: "45150",
          },
          {
            subdistrict_th: "โนนสว่าง",
            subdistrict_en: "Non Sawang",
            post_code: "45150",
          },
          {
            subdistrict_th: "ทุ่งทอง",
            subdistrict_en: "Thung Thong",
            post_code: "45150",
          },
          {
            subdistrict_th: "ดงครั่งน้อย",
            subdistrict_en: "Dong Khrang Noi",
            post_code: "45150",
          },
        ],
      },
      {
        district_th: "อำเภอปทุมรัตต์",
        district_en: "Amphoe Pathum Rat",
        children: [
          {
            subdistrict_th: "บัวแดง",
            subdistrict_en: "Bua Daeng",
            post_code: "45190",
          },
          {
            subdistrict_th: "ดอกล้ำ",
            subdistrict_en: "Dok Lam",
            post_code: "45190",
          },
          {
            subdistrict_th: "หนองแคน",
            subdistrict_en: "Nong Khaen",
            post_code: "45190",
          },
          {
            subdistrict_th: "โพนสูง",
            subdistrict_en: "Phon Sung",
            post_code: "45190",
          },
          {
            subdistrict_th: "โนนสวรรค์",
            subdistrict_en: "Non Sawan",
            post_code: "45190",
          },
          {
            subdistrict_th: "สระบัว",
            subdistrict_en: "Sa Bua",
            post_code: "45190",
          },
          {
            subdistrict_th: "โนนสง่า",
            subdistrict_en: "Non Sa-nga",
            post_code: "45190",
          },
          {
            subdistrict_th: "ขี้เหล็ก",
            subdistrict_en: "Khilek",
            post_code: "45190",
          },
        ],
      },
      {
        district_th: "อำเภอจตุรพักตรพิมาน",
        district_en: "Amphoe Chaturaphak Phiman",
        children: [
          {
            subdistrict_th: "หัวช้าง",
            subdistrict_en: "Hua Chang",
            post_code: "45180",
          },
          {
            subdistrict_th: "หนองผือ",
            subdistrict_en: "Nong Phue",
            post_code: "45180",
          },
          {
            subdistrict_th: "เมืองหงส์",
            subdistrict_en: "Mueang Hong",
            post_code: "45180",
          },
          {
            subdistrict_th: "โคกล่าม",
            subdistrict_en: "Khok Lam",
            post_code: "45180",
          },
          {
            subdistrict_th: "น้ำใส",
            subdistrict_en: "Nam Sai",
            post_code: "45180",
          },
          {
            subdistrict_th: "ดงแดง",
            subdistrict_en: "Dong Daeng",
            post_code: "45180",
          },
          {
            subdistrict_th: "ดงกลาง",
            subdistrict_en: "Dong Klang",
            post_code: "45180",
          },
          {
            subdistrict_th: "ป่าสังข์",
            subdistrict_en: "Pa Sang",
            post_code: "45180",
          },
          {
            subdistrict_th: "อีง่อง",
            subdistrict_en: "I Ngong",
            post_code: "45180",
          },
          {
            subdistrict_th: "ลิ้นฟ้า",
            subdistrict_en: "Lin Fa",
            post_code: "45180",
          },
          {
            subdistrict_th: "ดู่น้อย",
            subdistrict_en: "Du Noi",
            post_code: "45180",
          },
          {
            subdistrict_th: "ศรีโคตร",
            subdistrict_en: "Si Khot",
            post_code: "45180",
          },
        ],
      },
      {
        district_th: "อำเภอธวัชบุรี",
        district_en: "Amphoe Thawat Buri",
        children: [
          {
            subdistrict_th: "นิเวศน์",
            subdistrict_en: "Niwet",
            post_code: "45170",
          },
          {
            subdistrict_th: "ธงธานี",
            subdistrict_en: "Thong Thani",
            post_code: "45170",
          },
          {
            subdistrict_th: "หนองไผ่",
            subdistrict_en: "Nong Phai",
            post_code: "45170",
          },
          {
            subdistrict_th: "ธวัชบุรี",
            subdistrict_en: "Thawat Buri",
            post_code: "45170",
          },
          {
            subdistrict_th: "อุ่มเม้า",
            subdistrict_en: "Um Mao",
            post_code: "45170",
          },
          {
            subdistrict_th: "มะอึ",
            subdistrict_en: "Ma-ue",
            post_code: "45170",
          },
          {
            subdistrict_th: "เขวาทุ่ง",
            subdistrict_en: "Khwao Thung",
            post_code: "45170",
          },
          {
            subdistrict_th: "ไพศาล",
            subdistrict_en: "Phaisan",
            post_code: "45170",
          },
          {
            subdistrict_th: "เมืองน้อย",
            subdistrict_en: "Mueang Noi",
            post_code: "45170",
          },
          {
            subdistrict_th: "บึงนคร",
            subdistrict_en: "Bueng Nakhon",
            post_code: "45170",
          },
          {
            subdistrict_th: "ราชธานี",
            subdistrict_en: "Ratchathani",
            post_code: "45170",
          },
          {
            subdistrict_th: "หนองพอก",
            subdistrict_en: "Nong Phok",
            post_code: "45170",
          },
        ],
      },
      {
        district_th: "อำเภอพนมไพร",
        district_en: "Amphoe Phanom Phrai",
        children: [
          {
            subdistrict_th: "พนมไพร",
            subdistrict_en: "Phanom Phrai",
            post_code: "45140",
          },
          {
            subdistrict_th: "แสนสุข",
            subdistrict_en: "Saen Suk",
            post_code: "45140",
          },
          {
            subdistrict_th: "กุดน้ำใส",
            subdistrict_en: "Kut Nam Sai",
            post_code: "45140",
          },
          {
            subdistrict_th: "หนองทัพไทย",
            subdistrict_en: "Nong Thap Thai",
            post_code: "45140",
          },
          {
            subdistrict_th: "โพธิ์ใหญ่",
            subdistrict_en: "Pho Yai",
            post_code: "45140",
          },
          {
            subdistrict_th: "วารีสวัสดิ์",
            subdistrict_en: "Wari Sawat",
            post_code: "45140",
          },
          {
            subdistrict_th: "โคกสว่าง",
            subdistrict_en: "Khok Sawang",
            post_code: "45140",
          },
          {
            subdistrict_th: "โพธิ์ชัย",
            subdistrict_en: "Pho Chai",
            post_code: "45140",
          },
          {
            subdistrict_th: "นานวล",
            subdistrict_en: "Na Nuan",
            post_code: "45140",
          },
          {
            subdistrict_th: "คำไฮ",
            subdistrict_en: "Kham Hai",
            post_code: "45140",
          },
          {
            subdistrict_th: "สระแก้ว",
            subdistrict_en: "Sa Kaeo",
            post_code: "45140",
          },
          {
            subdistrict_th: "ค้อใหญ่",
            subdistrict_en: "Kho Yai",
            post_code: "45140",
          },
          {
            subdistrict_th: "ชานุวรรณ",
            subdistrict_en: "Chanuwan",
            post_code: "45140",
          },
        ],
      },
      {
        district_th: "อำเภอโพนทอง",
        district_en: "Amphoe Phon Thong",
        children: [
          {
            subdistrict_th: "แวง",
            subdistrict_en: "Waeng",
            post_code: "45110",
          },
          {
            subdistrict_th: "โคกกกม่วง",
            subdistrict_en: "Khok Kok Muang",
            post_code: "45110",
          },
          {
            subdistrict_th: "นาอุดม",
            subdistrict_en: "Na Udom",
            post_code: "45110",
          },
          {
            subdistrict_th: "สว่าง",
            subdistrict_en: "Sawang",
            post_code: "45110",
          },
          {
            subdistrict_th: "หนองใหญ่",
            subdistrict_en: "Nong Yai",
            post_code: "45110",
          },
          {
            subdistrict_th: "โพธิ์ทอง",
            subdistrict_en: "Pho Thong",
            post_code: "45110",
          },
          {
            subdistrict_th: "โนนชัยศรี",
            subdistrict_en: "Non Chai Si",
            post_code: "45110",
          },
          {
            subdistrict_th: "โพธิ์ศรีสว่าง",
            subdistrict_en: "Pho Si Sawang",
            post_code: "45110",
          },
          {
            subdistrict_th: "อุ่มเม่า",
            subdistrict_en: "Um Mao",
            post_code: "45110",
          },
          {
            subdistrict_th: "คำนาดี",
            subdistrict_en: "Na Di",
            post_code: "45110",
          },
          {
            subdistrict_th: "พรมสวรรค์",
            subdistrict_en: "Phrom Sawan",
            post_code: "45110",
          },
          {
            subdistrict_th: "สระนกแก้ว",
            subdistrict_en: "Sa Nok Kaeo",
            post_code: "45110",
          },
          {
            subdistrict_th: "วังสามัคคี",
            subdistrict_en: "Wang Samakkhi",
            post_code: "45110",
          },
          {
            subdistrict_th: "โคกสูง",
            subdistrict_en: "Khok Sung",
            post_code: "45110",
          },
        ],
      },
      {
        district_th: "อำเภอโพธิ์ชัย",
        district_en: "Amphoe Pho Chai",
        children: [
          {
            subdistrict_th: "ขามเปี้ย",
            subdistrict_en: "Kham Pia",
            post_code: "45230",
          },
          {
            subdistrict_th: "เชียงใหม่",
            subdistrict_en: "Chiang Mai",
            post_code: "45230",
          },
          {
            subdistrict_th: "บัวคำ",
            subdistrict_en: "Bua Kham",
            post_code: "45230",
          },
          {
            subdistrict_th: "อัคคะคำ",
            subdistrict_en: "Akkha Kham",
            post_code: "45230",
          },
          {
            subdistrict_th: "สะอาด",
            subdistrict_en: "Sa-at",
            post_code: "45230",
          },
          {
            subdistrict_th: "คำพอุง",
            subdistrict_en: "Kham Pha-ung",
            post_code: "45230",
          },
          {
            subdistrict_th: "หนองตาไก้",
            subdistrict_en: "Nong Ta Kai",
            post_code: "45230",
          },
          {
            subdistrict_th: "ดอนโอง",
            subdistrict_en: "Don Ong",
            post_code: "45230",
          },
          {
            subdistrict_th: "โพธิ์ศรี",
            subdistrict_en: "Pho Si",
            post_code: "45230",
          },
        ],
      },
      {
        district_th: "อำเภอหนองพอก",
        district_en: "Amphoe Nong Phok",
        children: [
          {
            subdistrict_th: "หนองพอก",
            subdistrict_en: "Nong Phok",
            post_code: "45210",
          },
          {
            subdistrict_th: "บึงงาม",
            subdistrict_en: "Bueng Ngam",
            post_code: "45210",
          },
          {
            subdistrict_th: "ภูเขาทอง",
            subdistrict_en: "Phukhao Thong",
            post_code: "45210",
          },
          {
            subdistrict_th: "กกโพธิ์",
            subdistrict_en: "Kok Pho",
            post_code: "45210",
          },
          {
            subdistrict_th: "โคกสว่าง",
            subdistrict_en: "Khok Sawang",
            post_code: "45210",
          },
          {
            subdistrict_th: "หนองขุ่นใหญ่",
            subdistrict_en: "Nong Khun Yai",
            post_code: "45210",
          },
          {
            subdistrict_th: "รอบเมือง",
            subdistrict_en: "Rop Mueang",
            post_code: "45210",
          },
          {
            subdistrict_th: "ผาน้ำย้อย",
            subdistrict_en: "Pha Nam Yoi",
            post_code: "45210",
          },
          {
            subdistrict_th: "ท่าสีดา",
            subdistrict_en: "Tha Sida",
            post_code: "45210",
          },
        ],
      },
      {
        district_th: "อำเภอเสลภูมิ",
        district_en: "Amphoe Selaphum",
        children: [
          {
            subdistrict_th: "กลาง",
            subdistrict_en: "Klang",
            post_code: "45120",
          },
          {
            subdistrict_th: "นางาม",
            subdistrict_en: "Na Ngam",
            post_code: "45120",
          },
          {
            subdistrict_th: "เมืองไพร",
            subdistrict_en: "Mueang Phrai",
            post_code: "45120",
          },
          {
            subdistrict_th: "นาแซง",
            subdistrict_en: "Na Saeng",
            post_code: "45120",
          },
          {
            subdistrict_th: "นาเมือง",
            subdistrict_en: "Na Mueang",
            post_code: "45120",
          },
          {
            subdistrict_th: "วังหลวง",
            subdistrict_en: "wang Luang",
            post_code: "45120",
          },
          {
            subdistrict_th: "ท่าม่วง",
            subdistrict_en: "Tha Muang",
            post_code: "45120",
          },
          {
            subdistrict_th: "ขวาว",
            subdistrict_en: "Khwao",
            post_code: "45120",
          },
          {
            subdistrict_th: "โพธิ์ทอง",
            subdistrict_en: "Pho Thong",
            post_code: "45120",
          },
          {
            subdistrict_th: "ภูเงิน",
            subdistrict_en: "Phu Ngoen",
            post_code: "45120",
          },
          {
            subdistrict_th: "เกาะแก้ว",
            subdistrict_en: "Ko Kaeo",
            post_code: "45120",
          },
          {
            subdistrict_th: "นาเลิง",
            subdistrict_en: "Na Loeng",
            post_code: "45120",
          },
          {
            subdistrict_th: "เหล่าน้อย",
            subdistrict_en: "Lao Noi",
            post_code: "45120",
          },
          {
            subdistrict_th: "ศรีวิลัย",
            subdistrict_en: "Si Wilai",
            post_code: "45120",
          },
          {
            subdistrict_th: "หนองหลวง",
            subdistrict_en: "Nong Luang",
            post_code: "45120",
          },
          {
            subdistrict_th: "พรสวรรค์",
            subdistrict_en: "Phon Sawan",
            post_code: "45120",
          },
          {
            subdistrict_th: "ขวัญเมือง",
            subdistrict_en: "Khwan Mueang",
            post_code: "45120",
          },
          {
            subdistrict_th: "บึงเกลือ",
            subdistrict_en: "Bueng Kluea",
            post_code: "45120",
          },
        ],
      },
      {
        district_th: "อำเภอสุวรรณภูมิ",
        district_en: "Amphoe Suwannaphum",
        children: [
          {
            subdistrict_th: "สระคู",
            subdistrict_en: "Sa Khu",
            post_code: "45130",
          },
          {
            subdistrict_th: "ดอกไม้",
            subdistrict_en: "Dok Mai",
            post_code: "45130",
          },
          {
            subdistrict_th: "นาใหญ่",
            subdistrict_en: "Na Yai",
            post_code: "45130",
          },
          {
            subdistrict_th: "หินกอง",
            subdistrict_en: "Hin Kong",
            post_code: "45130",
          },
          {
            subdistrict_th: "เมืองทุ่ง",
            subdistrict_en: "Mueang Thung",
            post_code: "45130",
          },
          {
            subdistrict_th: "หัวโทน",
            subdistrict_en: "Hua Thon",
            post_code: "45130",
          },
          {
            subdistrict_th: "บ่อพันขัน",
            subdistrict_en: "Bo Phan Khan",
            post_code: "45130",
          },
          {
            subdistrict_th: "ทุ่งหลวง",
            subdistrict_en: "Thung Luang",
            post_code: "45130",
          },
          {
            subdistrict_th: "หัวช้าง",
            subdistrict_en: "Hua Chang",
            post_code: "45130",
          },
          {
            subdistrict_th: "น้ำคำ",
            subdistrict_en: "Nam Kham",
            post_code: "45130",
          },
          {
            subdistrict_th: "ห้วยหินลาด",
            subdistrict_en: "Huai Hin Lat",
            post_code: "45130",
          },
          {
            subdistrict_th: "ช้างเผือก",
            subdistrict_en: "Chang Phueak",
            post_code: "45130",
          },
          {
            subdistrict_th: "ทุ่งกุลา",
            subdistrict_en: "Thung Kula",
            post_code: "45130",
          },
          {
            subdistrict_th: "ทุ่งศรีเมือง",
            subdistrict_en: "Thung Si Mueang",
            post_code: "45130",
          },
          {
            subdistrict_th: "จำปาขัน",
            subdistrict_en: "Champa Khan",
            post_code: "45130",
          },
        ],
      },
      {
        district_th: "อำเภอเมืองสรวง",
        district_en: "Amphoe Mueang Suang",
        children: [
          {
            subdistrict_th: "หนองผือ",
            subdistrict_en: "Nong Phue",
            post_code: "45220",
          },
          {
            subdistrict_th: "หนองหิน",
            subdistrict_en: "Nong Hin",
            post_code: "45220",
          },
          {
            subdistrict_th: "คูเมือง",
            subdistrict_en: "Khu Mueang",
            post_code: "45220",
          },
          {
            subdistrict_th: "กกกุง",
            subdistrict_en: "Kok Kung",
            post_code: "45220",
          },
          {
            subdistrict_th: "เมืองสรวง",
            subdistrict_en: "Mueang Suang",
            post_code: "45220",
          },
        ],
      },
      {
        district_th: "อำเภอโพนทราย",
        district_en: "Amphoe Phon Sai",
        children: [
          {
            subdistrict_th: "โพนทราย",
            subdistrict_en: "Phon Sai",
            post_code: "45240",
          },
          {
            subdistrict_th: "สามขา",
            subdistrict_en: "Sam Kha",
            post_code: "45240",
          },
          {
            subdistrict_th: "ศรีสว่าง",
            subdistrict_en: "Si Sawang",
            post_code: "45240",
          },
          {
            subdistrict_th: "ยางคำ",
            subdistrict_en: "Yang Kham",
            post_code: "45240",
          },
          {
            subdistrict_th: "ท่าหาดยาว",
            subdistrict_en: "Tha Hat Yao",
            post_code: "45240",
          },
        ],
      },
      {
        district_th: "อำเภออาจสามารถ",
        district_en: "Amphoe At Samat",
        children: [
          {
            subdistrict_th: "อาจสามารถ",
            subdistrict_en: "At Samat",
            post_code: "45160",
          },
          {
            subdistrict_th: "โพนเมือง",
            subdistrict_en: "Phon Mueang",
            post_code: "45160",
          },
          {
            subdistrict_th: "บ้านแจ้ง",
            subdistrict_en: "Ban Chaeng",
            post_code: "45160",
          },
          {
            subdistrict_th: "หน่อม",
            subdistrict_en: "Nom",
            post_code: "45160",
          },
          {
            subdistrict_th: "หนองหมื่นถ่าน",
            subdistrict_en: "Nong Muen Than",
            post_code: "45160",
          },
          {
            subdistrict_th: "หนองขาม",
            subdistrict_en: "Nong Kham",
            post_code: "45160",
          },
          {
            subdistrict_th: "โหรา",
            subdistrict_en: "Hora",
            post_code: "45160",
          },
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "45160",
          },
          {
            subdistrict_th: "ขี้เหล็ก",
            subdistrict_en: "Khilek",
            post_code: "45160",
          },
          {
            subdistrict_th: "บ้านดู่",
            subdistrict_en: "Ban Du",
            post_code: "45160",
          },
        ],
      },
      {
        district_th: "อำเภอเมยวดี",
        district_en: "Amphoe Moei Wadi",
        children: [
          {
            subdistrict_th: "เมยวดี",
            subdistrict_en: "Moei Wadi",
            post_code: "45250",
          },
          {
            subdistrict_th: "ชุมพร",
            subdistrict_en: "Chumphon",
            post_code: "45250",
          },
          {
            subdistrict_th: "บุ่งเลิศ",
            subdistrict_en: "Bung Loet",
            post_code: "45250",
          },
          {
            subdistrict_th: "ชมสะอาด",
            subdistrict_en: "Chom Sa-at",
            post_code: "45250",
          },
        ],
      },
      {
        district_th: "อำเภอศรีสมเด็จ",
        district_en: "Amphoe Si Somdet",
        children: [
          {
            subdistrict_th: "โพธิ์ทอง",
            subdistrict_en: "Pho Thong",
            post_code: "45000",
          },
          {
            subdistrict_th: "ศรีสมเด็จ",
            subdistrict_en: "Si Somdet",
            post_code: "45000",
          },
          {
            subdistrict_th: "เมืองเปลือย",
            subdistrict_en: "Mueang Plueai",
            post_code: "45000",
          },
          {
            subdistrict_th: "หนองใหญ่",
            subdistrict_en: "Nong Yai",
            post_code: "45000",
          },
          {
            subdistrict_th: "สวนจิก",
            subdistrict_en: "Suan Chik",
            post_code: "45280",
          },
          {
            subdistrict_th: "โพธิ์สัย",
            subdistrict_en: "Pho Sai",
            post_code: "45280",
          },
          {
            subdistrict_th: "หนองแวงควง",
            subdistrict_en: "Nong Waeng Khuang",
            post_code: "45000",
          },
          {
            subdistrict_th: "บ้านบาก",
            subdistrict_en: "Ban Bak",
            post_code: "45000",
          },
        ],
      },
      {
        district_th: "อำเภอจังหาร",
        district_en: "Amphoe Changhan",
        children: [
          {
            subdistrict_th: "ดินดำ",
            subdistrict_en: "Din Dam",
            post_code: "45000",
          },
          {
            subdistrict_th: "ปาฝา",
            subdistrict_en: "Pa Fa",
            post_code: "45000",
          },
          {
            subdistrict_th: "ม่วงลาด",
            subdistrict_en: "Muang Lat",
            post_code: "45000",
          },
          {
            subdistrict_th: "จังหาร",
            subdistrict_en: "Changhan",
            post_code: "45000",
          },
          {
            subdistrict_th: "ดงสิงห์",
            subdistrict_en: "Dong Sing",
            post_code: "45000",
          },
          {
            subdistrict_th: "ยางใหญ่",
            subdistrict_en: "Yang Yai",
            post_code: "45000",
          },
          {
            subdistrict_th: "ผักแว่น",
            subdistrict_en: "Phak Waen",
            post_code: "45000",
          },
          {
            subdistrict_th: "แสนชาติ",
            subdistrict_en: "Saen Chat",
            post_code: "45000",
          },
        ],
      },
      {
        district_th: "อำเภอเชียงขวัญ",
        district_en: "Amphoe Chiang Khwan",
        children: [
          {
            subdistrict_th: "เชียงขวัญ",
            subdistrict_en: "Chiang Khwan",
            post_code: "45000",
          },
          {
            subdistrict_th: "พลับพลา",
            subdistrict_en: "Phlapphla",
            post_code: "45170",
          },
          {
            subdistrict_th: "พระธาตุ",
            subdistrict_en: "Phra That",
            post_code: "45000",
          },
          {
            subdistrict_th: "พระเจ้า",
            subdistrict_en: "Phra Chao",
            post_code: "45000",
          },
          {
            subdistrict_th: "หมูม้น",
            subdistrict_en: "Mu Mon",
            post_code: "45170",
          },
          {
            subdistrict_th: "บ้านเขือง",
            subdistrict_en: "Ban Khueang",
            post_code: "45000",
          },
        ],
      },
      {
        district_th: "อำเภอหนองฮี",
        district_en: "Amphoe Nong Hi",
        children: [
          {
            subdistrict_th: "หนองฮี",
            subdistrict_en: "Nong Hi",
            post_code: "45140",
          },
          {
            subdistrict_th: "สาวแห",
            subdistrict_en: "Sao Hae",
            post_code: "45140",
          },
          {
            subdistrict_th: "ดูกอึ่ง",
            subdistrict_en: "Duk Ueng",
            post_code: "45140",
          },
          {
            subdistrict_th: "เด่นราษฎร์",
            subdistrict_en: "Den Rat",
            post_code: "45140",
          },
        ],
      },
      {
        district_th: "อำเภอทุ่งเขาหลวง",
        district_en: "Amphoe Thung Khao Luang",
        children: [
          {
            subdistrict_th: "ทุ่งเขาหลวง",
            subdistrict_en: "Thung Khao Luang",
            post_code: "45170",
          },
          {
            subdistrict_th: "เทอดไทย",
            subdistrict_en: "Thoet Thai",
            post_code: "45170",
          },
          {
            subdistrict_th: "บึงงาม",
            subdistrict_en: "Bueng Ngam",
            post_code: "45170",
          },
          {
            subdistrict_th: "มะบ้า",
            subdistrict_en: "Maba",
            post_code: "45170",
          },
          {
            subdistrict_th: "เหล่า",
            subdistrict_en: "Lao",
            post_code: "45170",
          },
        ],
      },
    ],
  },
  {
    province_th: "กาฬสินธุ์",
    province_en: "Kalasin",
    children: [
      {
        district_th: "อำเภอเมืองกาฬสินธุ์",
        district_en: "Amphoe Mueang Kalasin",
        children: [
          {
            subdistrict_th: "กาฬสินธุ์",
            subdistrict_en: "Kalasin",
            post_code: "46000",
          },
          {
            subdistrict_th: "เหนือ",
            subdistrict_en: "Nuea",
            post_code: "46000",
          },
          {
            subdistrict_th: "หลุบ",
            subdistrict_en: "Lup",
            post_code: "46000",
          },
          {
            subdistrict_th: "ไผ่",
            subdistrict_en: "Phai",
            post_code: "46000",
          },
          {
            subdistrict_th: "ลำปาว",
            subdistrict_en: "Lam Pao",
            post_code: "46000",
          },
          {
            subdistrict_th: "ลำพาน",
            subdistrict_en: "Lam Phan",
            post_code: "46000",
          },
          {
            subdistrict_th: "เชียงเครือ",
            subdistrict_en: "Chiang Khruea",
            post_code: "46000",
          },
          {
            subdistrict_th: "บึงวิชัย",
            subdistrict_en: "Bueng Wichai",
            post_code: "46000",
          },
          {
            subdistrict_th: "ห้วยโพธิ์",
            subdistrict_en: "Huai Pho",
            post_code: "46000",
          },
          {
            subdistrict_th: "ภูปอ",
            subdistrict_en: "Phu Po",
            post_code: "46000",
          },
          {
            subdistrict_th: "ภูดิน",
            subdistrict_en: "Phu Din",
            post_code: "46000",
          },
          {
            subdistrict_th: "หนองกุง",
            subdistrict_en: "Nong Kung",
            post_code: "46000",
          },
          {
            subdistrict_th: "กลางหมื่น",
            subdistrict_en: "Klang Muen",
            post_code: "46000",
          },
          {
            subdistrict_th: "ขมิ้น",
            subdistrict_en: "Khamin",
            post_code: "46000",
          },
          {
            subdistrict_th: "โพนทอง",
            subdistrict_en: "Phon Thong",
            post_code: "46000",
          },
          {
            subdistrict_th: "นาจารย์",
            subdistrict_en: "Na Chan",
            post_code: "46000",
          },
          {
            subdistrict_th: "ลำคลอง",
            subdistrict_en: "Lam Khlong",
            post_code: "46000",
          },
        ],
      },
      {
        district_th: "อำเภอนามน",
        district_en: "Amphoe Na Mon",
        children: [
          {
            subdistrict_th: "นามน",
            subdistrict_en: "Na Mon",
            post_code: "46230",
          },
          {
            subdistrict_th: "ยอดแกง",
            subdistrict_en: "Yot Kaeng",
            post_code: "46230",
          },
          {
            subdistrict_th: "สงเปลือย",
            subdistrict_en: "Song Plueai",
            post_code: "46230",
          },
          {
            subdistrict_th: "หลักเหลี่ยม",
            subdistrict_en: "Lak Liam",
            post_code: "46230",
          },
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "46230",
          },
        ],
      },
      {
        district_th: "อำเภอกมลาไสย",
        district_en: "Amphoe Kamalasai",
        children: [
          {
            subdistrict_th: "กมลาไสย",
            subdistrict_en: "Kamalasai",
            post_code: "46130",
          },
          {
            subdistrict_th: "หลักเมือง",
            subdistrict_en: "Lak Mueang",
            post_code: "46130",
          },
          {
            subdistrict_th: "โพนงาม",
            subdistrict_en: "Phon Ngam",
            post_code: "46130",
          },
          {
            subdistrict_th: "ดงลิง",
            subdistrict_en: "Dong Ling",
            post_code: "46130",
          },
          {
            subdistrict_th: "ธัญญา",
            subdistrict_en: "Thanya",
            post_code: "46130",
          },
          {
            subdistrict_th: "หนองแปน",
            subdistrict_en: "Nong Paen",
            post_code: "46130",
          },
          {
            subdistrict_th: "เจ้าท่า",
            subdistrict_en: "Chao Tha",
            post_code: "46130",
          },
          {
            subdistrict_th: "โคกสมบูรณ์",
            subdistrict_en: "Khok Sombun",
            post_code: "46130",
          },
        ],
      },
      {
        district_th: "อำเภอร่องคำ",
        district_en: "Amphoe Rong Kham",
        children: [
          {
            subdistrict_th: "ร่องคำ",
            subdistrict_en: "Rong Kham",
            post_code: "46210",
          },
          {
            subdistrict_th: "สามัคคี",
            subdistrict_en: "Samakkhi",
            post_code: "46210",
          },
          {
            subdistrict_th: "เหล่าอ้อย",
            subdistrict_en: "Lao Oi",
            post_code: "46210",
          },
        ],
      },
      {
        district_th: "อำเภอกุฉินารายณ์",
        district_en: "Amphoe Kuchinarai",
        children: [
          {
            subdistrict_th: "บัวขาว",
            subdistrict_en: "Bua Khao",
            post_code: "46110",
          },
          {
            subdistrict_th: "แจนแลน",
            subdistrict_en: "Chaen Laen",
            post_code: "46110",
          },
          {
            subdistrict_th: "เหล่าใหญ่",
            subdistrict_en: "Lao Yai",
            post_code: "46110",
          },
          {
            subdistrict_th: "จุมจัง",
            subdistrict_en: "Chum Chang",
            post_code: "46110",
          },
          {
            subdistrict_th: "เหล่าไฮงาม",
            subdistrict_en: "Lao Hai Ngam",
            post_code: "46110",
          },
          {
            subdistrict_th: "กุดหว้า",
            subdistrict_en: "Kut Wa",
            post_code: "46110",
          },
          {
            subdistrict_th: "สามขา",
            subdistrict_en: "Sam Kha",
            post_code: "46110",
          },
          {
            subdistrict_th: "นาขาม",
            subdistrict_en: "Na Kham",
            post_code: "46110",
          },
          {
            subdistrict_th: "หนองห้าง",
            subdistrict_en: "Nong Hang",
            post_code: "46110",
          },
          {
            subdistrict_th: "นาโก",
            subdistrict_en: "Na Ko",
            post_code: "46110",
          },
          {
            subdistrict_th: "สมสะอาด",
            subdistrict_en: "Som Sa-at",
            post_code: "46110",
          },
          {
            subdistrict_th: "กุดค้าว",
            subdistrict_en: "Kut Khao",
            post_code: "46110",
          },
        ],
      },
      {
        district_th: "อำเภอเขาวง",
        district_en: "Amphoe Khao Wong",
        children: [
          {
            subdistrict_th: "คุ้มเก่า",
            subdistrict_en: "Khum Kao",
            post_code: "46160",
          },
          {
            subdistrict_th: "สงเปลือย",
            subdistrict_en: "Song Plueai",
            post_code: "46160",
          },
          {
            subdistrict_th: "หนองผือ",
            subdistrict_en: "Nong Phue",
            post_code: "46160",
          },
          {
            subdistrict_th: "กุดสิมคุ้มใหม่",
            subdistrict_en: "Kut Sim Khum Mai",
            post_code: "46160",
          },
          {
            subdistrict_th: "สระพังทอง",
            subdistrict_en: "Sa Phang Thong",
            post_code: "46160",
          },
          {
            subdistrict_th: "กุดปลาค้าว",
            subdistrict_en: "Kut Pla Khao",
            post_code: "46160",
          },
        ],
      },
      {
        district_th: "อำเภอยางตลาด",
        district_en: "Amphoe Yang Talat",
        children: [
          {
            subdistrict_th: "ยางตลาด",
            subdistrict_en: "Yang Talat",
            post_code: "46120",
          },
          {
            subdistrict_th: "หัวงัว",
            subdistrict_en: "Hua Ngua",
            post_code: "46120",
          },
          {
            subdistrict_th: "อุ่มเม่า",
            subdistrict_en: "Um Mao",
            post_code: "46120",
          },
          {
            subdistrict_th: "บัวบาน",
            subdistrict_en: "Bua Ban",
            post_code: "46120",
          },
          {
            subdistrict_th: "เว่อ",
            subdistrict_en: "Woe",
            post_code: "46120",
          },
          {
            subdistrict_th: "อิตื้อ",
            subdistrict_en: "Itue",
            post_code: "46120",
          },
          {
            subdistrict_th: "หัวนาคำ",
            subdistrict_en: "Hua Na Kham",
            post_code: "46120",
          },
          {
            subdistrict_th: "หนองอิเฒ่า",
            subdistrict_en: "Nong I Thao",
            post_code: "46120",
          },
          {
            subdistrict_th: "ดอนสมบูรณ์",
            subdistrict_en: "Don Sombun",
            post_code: "46120",
          },
          {
            subdistrict_th: "นาเชือก",
            subdistrict_en: "Na Chueak",
            post_code: "46120",
          },
          {
            subdistrict_th: "คลองขาม",
            subdistrict_en: "Khlong Kham",
            post_code: "46120",
          },
          {
            subdistrict_th: "เขาพระนอน",
            subdistrict_en: "Khao Phra Non",
            post_code: "46120",
          },
          {
            subdistrict_th: "นาดี",
            subdistrict_en: "Na Di",
            post_code: "46120",
          },
          {
            subdistrict_th: "โนนสูง",
            subdistrict_en: "Non Sung",
            post_code: "46120",
          },
          {
            subdistrict_th: "หนองตอกแป้น",
            subdistrict_en: "Nong Tok Paen",
            post_code: "46120",
          },
        ],
      },
      {
        district_th: "อำเภอห้วยเม็ก",
        district_en: "Amphoe Huai Mek",
        children: [
          {
            subdistrict_th: "ห้วยเม็ก",
            subdistrict_en: "Huai Mek",
            post_code: "46170",
          },
          {
            subdistrict_th: "คำใหญ่",
            subdistrict_en: "Kham Yai",
            post_code: "46170",
          },
          {
            subdistrict_th: "กุดโดน",
            subdistrict_en: "Kut Don",
            post_code: "46170",
          },
          {
            subdistrict_th: "บึงนาเรียง",
            subdistrict_en: "Bueng Na Riang",
            post_code: "46170",
          },
          {
            subdistrict_th: "หัวหิน",
            subdistrict_en: "Hua Hin",
            post_code: "46170",
          },
          {
            subdistrict_th: "พิมูล",
            subdistrict_en: "Phimun",
            post_code: "46170",
          },
          {
            subdistrict_th: "คำเหมือดแก้ว",
            subdistrict_en: "Kham Mueat Kaeo",
            post_code: "46170",
          },
          {
            subdistrict_th: "โนนสะอาด",
            subdistrict_en: "Non Sa-at",
            post_code: "46170",
          },
          {
            subdistrict_th: "ทรายทอง",
            subdistrict_en: "Sai Thong",
            post_code: "46170",
          },
        ],
      },
      {
        district_th: "อำเภอสหัสขันธ์",
        district_en: "Amphoe Sahatsakhan",
        children: [
          {
            subdistrict_th: "ภูสิงห์",
            subdistrict_en: "Phu Sing",
            post_code: "46140",
          },
          {
            subdistrict_th: "สหัสขันธ์",
            subdistrict_en: "Sahatsakhan",
            post_code: "46140",
          },
          {
            subdistrict_th: "นามะเขือ",
            subdistrict_en: "Na Makhuea",
            post_code: "46140",
          },
          {
            subdistrict_th: "โนนศิลา",
            subdistrict_en: "Non Sila",
            post_code: "46140",
          },
          {
            subdistrict_th: "นิคม",
            subdistrict_en: "Nikhom",
            post_code: "46140",
          },
          {
            subdistrict_th: "โนนแหลมทอง",
            subdistrict_en: "Non Laem Thong",
            post_code: "46140",
          },
          {
            subdistrict_th: "โนนบุรี",
            subdistrict_en: "Non Buri",
            post_code: "46140",
          },
          {
            subdistrict_th: "โนนน้ำเกลี้ยง",
            subdistrict_en: "Non Nam Kliang",
            post_code: "46140",
          },
        ],
      },
      {
        district_th: "อำเภอคำม่วง",
        district_en: "Amphoe Kham Muang",
        children: [
          {
            subdistrict_th: "ทุ่งคลอง",
            subdistrict_en: "Thung Khlong",
            post_code: "46180",
          },
          {
            subdistrict_th: "โพน",
            subdistrict_en: "Phon",
            post_code: "46180",
          },
          {
            subdistrict_th: "ดินจี่",
            subdistrict_en: "Din Chi",
            post_code: "46180",
          },
          {
            subdistrict_th: "นาบอน",
            subdistrict_en: "Na Bon",
            post_code: "46180",
          },
          {
            subdistrict_th: "นาทัน",
            subdistrict_en: "Na Than",
            post_code: "46180",
          },
          {
            subdistrict_th: "เนินยาง",
            subdistrict_en: "Noen Yang",
            post_code: "46180",
          },
        ],
      },
      {
        district_th: "อำเภอท่าคันโท",
        district_en: "Amphoe Tha Khantho",
        children: [
          {
            subdistrict_th: "ท่าคันโท",
            subdistrict_en: "Tha Khantho",
            post_code: "46190",
          },
          {
            subdistrict_th: "กุงเก่า",
            subdistrict_en: "Kung Kao",
            post_code: "46190",
          },
          {
            subdistrict_th: "ยางอู้ม",
            subdistrict_en: "Yang Um",
            post_code: "46190",
          },
          {
            subdistrict_th: "กุดจิก",
            subdistrict_en: "Kut Chik",
            post_code: "46190",
          },
          {
            subdistrict_th: "นาตาล",
            subdistrict_en: "Na Tan",
            post_code: "46190",
          },
          {
            subdistrict_th: "ดงสมบูรณ์",
            subdistrict_en: "Dong Sombun",
            post_code: "46190",
          },
        ],
      },
      {
        district_th: "อำเภอหนองกุงศรี",
        district_en: "Amphoe Nong Kung Si",
        children: [
          {
            subdistrict_th: "หนองกุงศรี",
            subdistrict_en: "Nong Kung Si",
            post_code: "46220",
          },
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "46220",
          },
          {
            subdistrict_th: "โคกเครือ",
            subdistrict_en: "Khok Khruea",
            post_code: "46220",
          },
          {
            subdistrict_th: "หนองสรวง",
            subdistrict_en: "Nong Suang",
            post_code: "46220",
          },
          {
            subdistrict_th: "เสาเล้า",
            subdistrict_en: "Sao Lao",
            post_code: "46220",
          },
          {
            subdistrict_th: "หนองใหญ่",
            subdistrict_en: "Nong Yai",
            post_code: "46220",
          },
          {
            subdistrict_th: "ดงมูล",
            subdistrict_en: "Dong Mun",
            post_code: "46220",
          },
          {
            subdistrict_th: "ลำหนองแสน",
            subdistrict_en: "Lam Nong Saen",
            post_code: "46220",
          },
          {
            subdistrict_th: "หนองหิน",
            subdistrict_en: "Nong Hin",
            post_code: "46220",
          },
        ],
      },
      {
        district_th: "อำเภอสมเด็จ",
        district_en: "Amphoe Somdet",
        children: [
          {
            subdistrict_th: "สมเด็จ",
            subdistrict_en: "Somdet",
            post_code: "46150",
          },
          {
            subdistrict_th: "หนองแวง",
            subdistrict_en: "Nong Waeng",
            post_code: "46150",
          },
          {
            subdistrict_th: "แซงบาดาล",
            subdistrict_en: "Saeng Badan",
            post_code: "46150",
          },
          {
            subdistrict_th: "มหาไชย",
            subdistrict_en: "Maha Chai",
            post_code: "46150",
          },
          {
            subdistrict_th: "หมูม่น",
            subdistrict_en: "Mu Mon",
            post_code: "46150",
          },
          {
            subdistrict_th: "ผาเสวย",
            subdistrict_en: "Pha Sawoei",
            post_code: "46150",
          },
          {
            subdistrict_th: "ศรีสมเด็จ",
            subdistrict_en: "Si Somdet",
            post_code: "46150",
          },
          {
            subdistrict_th: "ลำห้วยหลัว",
            subdistrict_en: "Lam Huai Lua",
            post_code: "46150",
          },
        ],
      },
      {
        district_th: "อำเภอห้วยผึ้ง",
        district_en: "Amphoe Huai Phueng",
        children: [
          {
            subdistrict_th: "คำบง",
            subdistrict_en: "Kham Bong",
            post_code: "46240",
          },
          {
            subdistrict_th: "ไค้นุ่น",
            subdistrict_en: "Khai Nun",
            post_code: "46240",
          },
          {
            subdistrict_th: "นิคมห้วยผึ้ง",
            subdistrict_en: "Nikhom Huai Phueng",
            post_code: "46240",
          },
          {
            subdistrict_th: "หนองอีบุตร",
            subdistrict_en: "Nong I But",
            post_code: "46240",
          },
        ],
      },
      {
        district_th: "อำเภอสามชัย",
        district_en: "Amphoe Sam Chai",
        children: [
          {
            subdistrict_th: "สำราญ",
            subdistrict_en: "Samran",
            post_code: "46180",
          },
          {
            subdistrict_th: "สำราญใต้",
            subdistrict_en: "Samran Tai",
            post_code: "46180",
          },
          {
            subdistrict_th: "คำสร้างเที่ยง",
            subdistrict_en: "Kham Sang Thiang",
            post_code: "46180",
          },
          {
            subdistrict_th: "หนองช้าง",
            subdistrict_en: "Nong Chang",
            post_code: "46180",
          },
        ],
      },
      {
        district_th: "อำเภอนาคู",
        district_en: "Amphoe Na Khu",
        children: [
          {
            subdistrict_th: "นาคู",
            subdistrict_en: "Na Khu",
            post_code: "46160",
          },
          {
            subdistrict_th: "สายนาวัง",
            subdistrict_en: "Sai Na Wang",
            post_code: "46160",
          },
          {
            subdistrict_th: "โนนนาจาน",
            subdistrict_en: "Non Na Chan",
            post_code: "46160",
          },
          {
            subdistrict_th: "บ่อแก้ว",
            subdistrict_en: "Bo Kaeo",
            post_code: "46160",
          },
          {
            subdistrict_th: "ภูแล่นช้าง",
            subdistrict_en: "Phu Laen Chang",
            post_code: "46160",
          },
        ],
      },
      {
        district_th: "อำเภอดอนจาน",
        district_en: "Amphoe Don Chan",
        children: [
          {
            subdistrict_th: "ดอนจาน",
            subdistrict_en: "Don Chan",
            post_code: "46000",
          },
          {
            subdistrict_th: "สะอาดไชยศรี",
            subdistrict_en: "Sa-at Chai Si",
            post_code: "46000",
          },
          {
            subdistrict_th: "ดงพยุง",
            subdistrict_en: "Dong Phayung",
            post_code: "46000",
          },
          {
            subdistrict_th: "ม่วงนา",
            subdistrict_en: "Muang Na",
            post_code: "46000",
          },
          {
            subdistrict_th: "นาจำปา",
            subdistrict_en: "Na Champa",
            post_code: "46000",
          },
        ],
      },
      {
        district_th: "อำเภอฆ้องชัย",
        district_en: "Amphoe Khong Chai",
        children: [
          {
            subdistrict_th: "ฆ้องชัยพัฒนา",
            subdistrict_en: "Khong Chai Phatthana",
            post_code: "46130",
          },
          {
            subdistrict_th: "เหล่ากลาง",
            subdistrict_en: "Lao Klang",
            post_code: "46130",
          },
          {
            subdistrict_th: "โคกสะอาด",
            subdistrict_en: "Khok Sa-at",
            post_code: "46130",
          },
          {
            subdistrict_th: "โนนศิลาเลิง",
            subdistrict_en: "Non Sila Loeng",
            post_code: "46130",
          },
          {
            subdistrict_th: "ลำชี",
            subdistrict_en: "Lam Chi",
            post_code: "46130",
          },
        ],
      },
    ],
  },
  {
    province_th: "สกลนคร",
    province_en: "Sakon Nakhon",
    children: [
      {
        district_th: "อำเภอเมืองสกลนคร",
        district_en: "Amphoe Mueang Sakon Nakhon",
        children: [
          {
            subdistrict_th: "ธาตุเชิงชุม",
            subdistrict_en: "That Choeng Chum",
            post_code: "47000",
          },
          {
            subdistrict_th: "ขมิ้น",
            subdistrict_en: "Khamin",
            post_code: "47220",
          },
          {
            subdistrict_th: "งิ้วด่อน",
            subdistrict_en: "Ngio Don",
            post_code: "47000",
          },
          {
            subdistrict_th: "โนนหอม",
            subdistrict_en: "Non Hom",
            post_code: "47000",
          },
          {
            subdistrict_th: "เชียงเครือ",
            subdistrict_en: "Chiang Khruea",
            post_code: "47000",
          },
          {
            subdistrict_th: "ท่าแร่",
            subdistrict_en: "Tha Rae",
            post_code: "47230",
          },
          {
            subdistrict_th: "ม่วงลาย",
            subdistrict_en: "Muang Lai",
            post_code: "47000",
          },
          {
            subdistrict_th: "ดงชน",
            subdistrict_en: "Dong Chon",
            post_code: "47000",
          },
          {
            subdistrict_th: "ห้วยยาง",
            subdistrict_en: "Huai Yang",
            post_code: "47000",
          },
          {
            subdistrict_th: "พังขว้าง",
            subdistrict_en: "Phang Khwang",
            post_code: "47000",
          },
          {
            subdistrict_th: "ดงมะไฟ",
            subdistrict_en: "Dong Mafai",
            post_code: "47000",
          },
          {
            subdistrict_th: "ธาตุนาเวง",
            subdistrict_en: "That Na Weng",
            post_code: "47000",
          },
          {
            subdistrict_th: "เหล่าปอแดง",
            subdistrict_en: "Lao Po Daeng",
            post_code: "47000",
          },
          {
            subdistrict_th: "หนองลาด",
            subdistrict_en: "Nong Lat",
            post_code: "47220",
          },
          {
            subdistrict_th: "ฮางโฮง",
            subdistrict_en: "Hang Hong",
            post_code: "47000",
          },
          {
            subdistrict_th: "โคกก่อง",
            subdistrict_en: "Khok Kong",
            post_code: "47000",
          },
        ],
      },
      {
        district_th: "อำเภอกุสุมาลย์",
        district_en: "Amphoe Kusuman",
        children: [
          {
            subdistrict_th: "กุสุมาลย์",
            subdistrict_en: "Kusuman",
            post_code: "47210",
          },
          {
            subdistrict_th: "นาโพธิ์",
            subdistrict_en: "Na Pho",
            post_code: "47210",
          },
          {
            subdistrict_th: "นาเพียง",
            subdistrict_en: "Na Phiang",
            post_code: "47230",
          },
          {
            subdistrict_th: "โพธิไพศาล",
            subdistrict_en: "Phothiphaisan",
            post_code: "47210",
          },
          {
            subdistrict_th: "อุ่มจาน",
            subdistrict_en: "Um Chan",
            post_code: "47230",
          },
        ],
      },
      {
        district_th: "อำเภอกุดบาก",
        district_en: "Amphoe Kut Bak",
        children: [
          {
            subdistrict_th: "กุดบาก",
            subdistrict_en: "Kut Bak",
            post_code: "47180",
          },
          {
            subdistrict_th: "นาม่อง",
            subdistrict_en: "Na Mong",
            post_code: "47180",
          },
          {
            subdistrict_th: "กุดไห",
            subdistrict_en: "Kut Hai",
            post_code: "47180",
          },
        ],
      },
      {
        district_th: "อำเภอพรรณานิคม",
        district_en: "Amphoe Phanna Nikhom",
        children: [
          {
            subdistrict_th: "พรรณา",
            subdistrict_en: "Phanna",
            post_code: "47130",
          },
          {
            subdistrict_th: "วังยาง",
            subdistrict_en: "Wang Yang",
            post_code: "47130",
          },
          {
            subdistrict_th: "พอกน้อย",
            subdistrict_en: "Phok Noi",
            post_code: "47220",
          },
          {
            subdistrict_th: "นาหัวบ่อ",
            subdistrict_en: "Na Hua Bo",
            post_code: "47220",
          },
          {
            subdistrict_th: "ไร่",
            subdistrict_en: "Rai",
            post_code: "47130",
          },
          {
            subdistrict_th: "ช้างมิ่ง",
            subdistrict_en: "Chang Ming",
            post_code: "47130",
          },
          {
            subdistrict_th: "นาใน",
            subdistrict_en: "Na Nai",
            post_code: "47130",
          },
          {
            subdistrict_th: "สว่าง",
            subdistrict_en: "Sawang",
            post_code: "47130",
          },
          {
            subdistrict_th: "บะฮี",
            subdistrict_en: "Ba Hi",
            post_code: "47130",
          },
          {
            subdistrict_th: "เชิงชุม",
            subdistrict_en: "Choeng Chum",
            post_code: "47130",
          },
        ],
      },
      {
        district_th: "อำเภอพังโคน",
        district_en: "Amphoe Phang Khon",
        children: [
          {
            subdistrict_th: "พังโคน",
            subdistrict_en: "Phang Khon",
            post_code: "47160",
          },
          {
            subdistrict_th: "ม่วงไข่",
            subdistrict_en: "Muang Khai",
            post_code: "47160",
          },
          {
            subdistrict_th: "แร่",
            subdistrict_en: "Rae",
            post_code: "47160",
          },
          {
            subdistrict_th: "ไฮหย่อง",
            subdistrict_en: "Hai Yong",
            post_code: "47160",
          },
          {
            subdistrict_th: "ต้นผึ้ง",
            subdistrict_en: "Ton Phueng",
            post_code: "47160",
          },
        ],
      },
      {
        district_th: "อำเภอวาริชภูมิ",
        district_en: "Amphoe Waritchaphum",
        children: [
          {
            subdistrict_th: "วาริชภูมิ",
            subdistrict_en: "Waritchaphum",
            post_code: "47150",
          },
          {
            subdistrict_th: "ปลาโหล",
            subdistrict_en: "Pla Lo",
            post_code: "47150",
          },
          {
            subdistrict_th: "หนองลาด",
            subdistrict_en: "Nong Lat",
            post_code: "47150",
          },
          {
            subdistrict_th: "คำบ่อ",
            subdistrict_en: "Kham Bo",
            post_code: "47150",
          },
          {
            subdistrict_th: "ค้อเขียว",
            subdistrict_en: "Kho Khiao",
            post_code: "47150",
          },
        ],
      },
      {
        district_th: "อำเภอนิคมน้ำอูน",
        district_en: "Amphoe Nikhom Nam Un",
        children: [
          {
            subdistrict_th: "นิคมน้ำอูน",
            subdistrict_en: "Nikhom Nam Un",
            post_code: "47270",
          },
          {
            subdistrict_th: "หนองปลิง",
            subdistrict_en: "Nong Pling",
            post_code: "47270",
          },
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "47270",
          },
          {
            subdistrict_th: "สุวรรณคาม",
            subdistrict_en: "Suwannakham",
            post_code: "47270",
          },
        ],
      },
      {
        district_th: "อำเภอวานรนิวาส",
        district_en: "Amphoe Wanon Niwat",
        children: [
          {
            subdistrict_th: "วานรนิวาส",
            subdistrict_en: "Wanon Niwat",
            post_code: "47120",
          },
          {
            subdistrict_th: "เดื่อศรีคันไชย",
            subdistrict_en: "Duea Si Khan Chai",
            post_code: "47120",
          },
          {
            subdistrict_th: "ขัวก่าย",
            subdistrict_en: "Khua Kai",
            post_code: "47120",
          },
          {
            subdistrict_th: "หนองสนม",
            subdistrict_en: "Nong Sanom",
            post_code: "47120",
          },
          {
            subdistrict_th: "คูสะคาม",
            subdistrict_en: "Khu Sakham",
            post_code: "47120",
          },
          {
            subdistrict_th: "ธาตุ",
            subdistrict_en: "That",
            post_code: "47120",
          },
          {
            subdistrict_th: "หนองแวง",
            subdistrict_en: "Nong Waeng",
            post_code: "47120",
          },
          {
            subdistrict_th: "ศรีวิชัย",
            subdistrict_en: "Si Wichai",
            post_code: "47120",
          },
          {
            subdistrict_th: "นาซอ",
            subdistrict_en: "Na So",
            post_code: "47120",
          },
          {
            subdistrict_th: "อินทร์แปลง",
            subdistrict_en: "In Plaeng",
            post_code: "47120",
          },
          {
            subdistrict_th: "นาคำ",
            subdistrict_en: "Na Kham",
            post_code: "47120",
          },
          {
            subdistrict_th: "คอนสวรรค์",
            subdistrict_en: "Khon Sawan",
            post_code: "47120",
          },
          {
            subdistrict_th: "กุดเรือคำ",
            subdistrict_en: "Kut Ruea Kham",
            post_code: "47120",
          },
          {
            subdistrict_th: "หนองแวงใต้",
            subdistrict_en: "Nong Waeng Tai",
            post_code: "47120",
          },
        ],
      },
      {
        district_th: "อำเภอคำตากล้า",
        district_en: "Amphoe Kham Ta Kla",
        children: [
          {
            subdistrict_th: "คำตากล้า",
            subdistrict_en: "Kham Ta Kla",
            post_code: "47250",
          },
          {
            subdistrict_th: "หนองบัวสิม",
            subdistrict_en: "Nong Bua Sim",
            post_code: "47250",
          },
          {
            subdistrict_th: "นาแต้",
            subdistrict_en: "Na Tae",
            post_code: "47250",
          },
          {
            subdistrict_th: "แพด",
            subdistrict_en: "Phaet",
            post_code: "47250",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านม่วง",
        district_en: "Amphoe Ban Muang",
        children: [
          {
            subdistrict_th: "ม่วง",
            subdistrict_en: "Muang",
            post_code: "47140",
          },
          {
            subdistrict_th: "มาย",
            subdistrict_en: "Mai",
            post_code: "47140",
          },
          {
            subdistrict_th: "ดงหม้อทอง",
            subdistrict_en: "Dong Mo Thong",
            post_code: "47140",
          },
          {
            subdistrict_th: "ดงเหนือ",
            subdistrict_en: "Dong Nuea",
            post_code: "47140",
          },
          {
            subdistrict_th: "ดงหม้อทองใต้",
            subdistrict_en: "Dong Mo Thong Tai",
            post_code: "47140",
          },
          {
            subdistrict_th: "ห้วยหลัว",
            subdistrict_en: "Huai Lua",
            post_code: "47140",
          },
          {
            subdistrict_th: "โนนสะอาด",
            subdistrict_en: "Non Sa-at",
            post_code: "47140",
          },
          {
            subdistrict_th: "หนองกวั่ง",
            subdistrict_en: "Nong Kwang",
            post_code: "47140",
          },
          {
            subdistrict_th: "บ่อแก้ว",
            subdistrict_en: "Bo Kaeo",
            post_code: "47140",
          },
        ],
      },
      {
        district_th: "อำเภออากาศอำนวย",
        district_en: "Amphoe Akat Amnuai",
        children: [
          {
            subdistrict_th: "อากาศ",
            subdistrict_en: "Akat",
            post_code: "47170",
          },
          {
            subdistrict_th: "โพนแพง",
            subdistrict_en: "Phon Phaeng",
            post_code: "47170",
          },
          {
            subdistrict_th: "วาใหญ่",
            subdistrict_en: "Wa Yai",
            post_code: "47170",
          },
          {
            subdistrict_th: "โพนงาม",
            subdistrict_en: "Phon Ngam",
            post_code: "47170",
          },
          {
            subdistrict_th: "ท่าก้อน",
            subdistrict_en: "Tha Kon",
            post_code: "47170",
          },
          {
            subdistrict_th: "นาฮี",
            subdistrict_en: "Na Hi",
            post_code: "47170",
          },
          {
            subdistrict_th: "บะหว้า",
            subdistrict_en: "Ba Wa",
            post_code: "47170",
          },
          {
            subdistrict_th: "สามัคคีพัฒนา",
            subdistrict_en: "Samakkhi Phatthana",
            post_code: "47170",
          },
        ],
      },
      {
        district_th: "อำเภอสว่างแดนดิน",
        district_en: "Amphoe Sawang Daen Din",
        children: [
          {
            subdistrict_th: "สว่างแดนดิน",
            subdistrict_en: "Sawang Daen Din",
            post_code: "47110",
          },
          {
            subdistrict_th: "คำสะอาด",
            subdistrict_en: "Kham Sa-at",
            post_code: "47110",
          },
          {
            subdistrict_th: "บ้านต้าย",
            subdistrict_en: "Ban Tai",
            post_code: "47110",
          },
          {
            subdistrict_th: "บงเหนือ",
            subdistrict_en: "Bong Nuea",
            post_code: "47110",
          },
          {
            subdistrict_th: "โพนสูง",
            subdistrict_en: "Phon Sung",
            post_code: "47110",
          },
          {
            subdistrict_th: "โคกสี",
            subdistrict_en: "Khok Si",
            post_code: "47110",
          },
          {
            subdistrict_th: "หนองหลวง",
            subdistrict_en: "Nong Luang",
            post_code: "47110",
          },
          {
            subdistrict_th: "บงใต้",
            subdistrict_en: "Bong Tai",
            post_code: "47110",
          },
          {
            subdistrict_th: "ค้อใต้",
            subdistrict_en: "Kho Tai",
            post_code: "47110",
          },
          {
            subdistrict_th: "พันนา",
            subdistrict_en: "Phan Na",
            post_code: "47240",
          },
          {
            subdistrict_th: "แวง",
            subdistrict_en: "Waeng",
            post_code: "47240",
          },
          {
            subdistrict_th: "ทรายมูล",
            subdistrict_en: "Sai Mun",
            post_code: "47110",
          },
          {
            subdistrict_th: "ตาลโกน",
            subdistrict_en: "Tan Kon",
            post_code: "47240",
          },
          {
            subdistrict_th: "ตาลเนิ้ง",
            subdistrict_en: "Tan Noeng",
            post_code: "47240",
          },
          {
            subdistrict_th: "ธาตุทอง",
            subdistrict_en: "That Thong",
            post_code: "47240",
          },
          {
            subdistrict_th: "บ้านถ่อน",
            subdistrict_en: "Ban Thon",
            post_code: "47110",
          },
        ],
      },
      {
        district_th: "อำเภอส่องดาว",
        district_en: "Amphoe Song Dao",
        children: [
          {
            subdistrict_th: "ส่องดาว",
            subdistrict_en: "Song Dao",
            post_code: "47190",
          },
          {
            subdistrict_th: "ท่าศิลา",
            subdistrict_en: "Tha Sila",
            post_code: "47190",
          },
          {
            subdistrict_th: "วัฒนา",
            subdistrict_en: "Watthana",
            post_code: "47190",
          },
          {
            subdistrict_th: "ปทุมวาปี",
            subdistrict_en: "Pathum Wapi",
            post_code: "47190",
          },
        ],
      },
      {
        district_th: "อำเภอเต่างอย",
        district_en: "Amphoe Tao Ngoi",
        children: [
          {
            subdistrict_th: "เต่างอย",
            subdistrict_en: "Tao Ngoi",
            post_code: "47260",
          },
          {
            subdistrict_th: "บึงทวาย",
            subdistrict_en: "Bueng Thawai",
            post_code: "47260",
          },
          {
            subdistrict_th: "นาตาล",
            subdistrict_en: "Na Tan",
            post_code: "47260",
          },
          {
            subdistrict_th: "จันทร์เพ็ญ",
            subdistrict_en: "Chan Phen",
            post_code: "47260",
          },
        ],
      },
      {
        district_th: "อำเภอโคกศรีสุพรรณ",
        district_en: "Amphoe Khok Si Suphan",
        children: [
          {
            subdistrict_th: "ตองโขบ",
            subdistrict_en: "Tong Khop",
            post_code: "47280",
          },
          {
            subdistrict_th: "เหล่าโพนค้อ",
            subdistrict_en: "Lao Phon Kho",
            post_code: "47280",
          },
          {
            subdistrict_th: "ด่านม่วงคำ",
            subdistrict_en: "Dan Muang Kham",
            post_code: "47280",
          },
          {
            subdistrict_th: "แมดนาท่ม",
            subdistrict_en: "Maet Na Thom",
            post_code: "47280",
          },
        ],
      },
      {
        district_th: "อำเภอเจริญศิลป์",
        district_en: "Amphoe Charoen Sin",
        children: [
          {
            subdistrict_th: "บ้านเหล่า",
            subdistrict_en: "Ban Lao",
            post_code: "47290",
          },
          {
            subdistrict_th: "เจริญศิลป์",
            subdistrict_en: "Charoen Sin",
            post_code: "47290",
          },
          {
            subdistrict_th: "ทุ่งแก",
            subdistrict_en: "Thung Kae",
            post_code: "47290",
          },
          {
            subdistrict_th: "โคกศิลา",
            subdistrict_en: "Khok Sila",
            post_code: "47290",
          },
          {
            subdistrict_th: "หนองแปน",
            subdistrict_en: "Nong Paen",
            post_code: "47290",
          },
        ],
      },
      {
        district_th: "อำเภอโพนนาแก้ว",
        district_en: "Amphoe Phon Na Kaeo",
        children: [
          {
            subdistrict_th: "บ้านโพน",
            subdistrict_en: "Ban Phon",
            post_code: "47230",
          },
          {
            subdistrict_th: "นาแก้ว",
            subdistrict_en: "Na Kaeo",
            post_code: "47230",
          },
          {
            subdistrict_th: "นาตงวัฒนา",
            subdistrict_en: "Na Tong Watthana",
            post_code: "47230",
          },
          {
            subdistrict_th: "บ้านแป้น",
            subdistrict_en: "Ban Paen",
            post_code: "47230",
          },
          {
            subdistrict_th: "เชียงสือ",
            subdistrict_en: "Chiang Sue",
            post_code: "47230",
          },
        ],
      },
      {
        district_th: "อำเภอภูพาน",
        district_en: "Amphoe Phu Phan",
        children: [
          {
            subdistrict_th: "สร้างค้อ",
            subdistrict_en: "Sang Kho",
            post_code: "47180",
          },
          {
            subdistrict_th: "หลุบเลา",
            subdistrict_en: "Lup Lao",
            post_code: "47180",
          },
          {
            subdistrict_th: "โคกภู",
            subdistrict_en: "Khok Phu",
            post_code: "47180",
          },
          {
            subdistrict_th: "กกปลาซิว",
            subdistrict_en: "Kok Pla Sio",
            post_code: "47180",
          },
        ],
      },
    ],
  },
  {
    province_th: "นครพนม",
    province_en: "Nakhon Phanom",
    children: [
      {
        district_th: "อำเภอเมืองนครพนม",
        district_en: "Amphoe Mueang Nakhon Phanom",
        children: [
          {
            subdistrict_th: "ในเมือง",
            subdistrict_en: "Nai Mueang",
            post_code: "48000",
          },
          {
            subdistrict_th: "หนองแสง",
            subdistrict_en: "Nong Saeng",
            post_code: "48000",
          },
          {
            subdistrict_th: "นาทราย",
            subdistrict_en: "Na Sai",
            post_code: "48000",
          },
          {
            subdistrict_th: "นาราชควาย",
            subdistrict_en: "Na Rat Khwai",
            post_code: "48000",
          },
          {
            subdistrict_th: "กุรุคุ",
            subdistrict_en: "Kurukhu",
            post_code: "48000",
          },
          {
            subdistrict_th: "บ้านผึ้ง",
            subdistrict_en: "Ban Phueng",
            post_code: "48000",
          },
          {
            subdistrict_th: "อาจสามารถ",
            subdistrict_en: "At Samat",
            post_code: "48000",
          },
          {
            subdistrict_th: "ขามเฒ่า",
            subdistrict_en: "Kham Thao",
            post_code: "48000",
          },
          {
            subdistrict_th: "บ้านกลาง",
            subdistrict_en: "Ban Klang",
            post_code: "48000",
          },
          {
            subdistrict_th: "ท่าค้อ",
            subdistrict_en: "Tha Kho",
            post_code: "48000",
          },
          {
            subdistrict_th: "คำเตย",
            subdistrict_en: "Kham Toei",
            post_code: "48000",
          },
          {
            subdistrict_th: "หนองญาติ",
            subdistrict_en: "Nong Yat",
            post_code: "48000",
          },
          {
            subdistrict_th: "ดงขวาง",
            subdistrict_en: "Dong Khwang",
            post_code: "48000",
          },
          {
            subdistrict_th: "วังตามัว",
            subdistrict_en: "Wang Ta Mua",
            post_code: "48000",
          },
          {
            subdistrict_th: "โพธิ์ตาก",
            subdistrict_en: "Pho Tak",
            post_code: "48000",
          },
        ],
      },
      {
        district_th: "อำเภอปลาปาก",
        district_en: "Amphoe Pla Pak",
        children: [
          {
            subdistrict_th: "ปลาปาก",
            subdistrict_en: "Pla Pak",
            post_code: "48160",
          },
          {
            subdistrict_th: "หนองฮี",
            subdistrict_en: "Nong Hi",
            post_code: "48160",
          },
          {
            subdistrict_th: "กุตาไก้",
            subdistrict_en: "Kutakai",
            post_code: "48160",
          },
          {
            subdistrict_th: "โคกสว่าง",
            subdistrict_en: "Khok Sawang",
            post_code: "48160",
          },
          {
            subdistrict_th: "โคกสูง",
            subdistrict_en: "Khok Sung",
            post_code: "48160",
          },
          {
            subdistrict_th: "มหาชัย",
            subdistrict_en: "Maha Chai",
            post_code: "48160",
          },
          {
            subdistrict_th: "นามะเขือ",
            subdistrict_en: "Na Makhuea",
            post_code: "48160",
          },
          {
            subdistrict_th: "หนองเทาใหญ่",
            subdistrict_en: "Nong Thao Yai",
            post_code: "48160",
          },
        ],
      },
      {
        district_th: "อำเภอท่าอุเทน",
        district_en: "Amphoe Tha Uthen",
        children: [
          {
            subdistrict_th: "ท่าอุเทน",
            subdistrict_en: "Tha Uthen",
            post_code: "48120",
          },
          {
            subdistrict_th: "โนนตาล",
            subdistrict_en: "Non Tan",
            post_code: "48120",
          },
          {
            subdistrict_th: "ท่าจำปา",
            subdistrict_en: "Tha Champa",
            post_code: "48120",
          },
          {
            subdistrict_th: "ไชยบุรี",
            subdistrict_en: "Chaiburi",
            post_code: "48120",
          },
          {
            subdistrict_th: "พนอม",
            subdistrict_en: "Phanom",
            post_code: "48120",
          },
          {
            subdistrict_th: "พะทาย",
            subdistrict_en: "Phathai",
            post_code: "48120",
          },
          {
            subdistrict_th: "เวินพระบาท",
            subdistrict_en: "Woen Phrabat",
            post_code: "48120",
          },
          {
            subdistrict_th: "รามราช",
            subdistrict_en: "Ram Rat",
            post_code: "48120",
          },
          {
            subdistrict_th: "หนองเทา",
            subdistrict_en: "Nong Thao",
            post_code: "48120",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านแพง",
        district_en: "Amphoe Ban Phaeng",
        children: [
          {
            subdistrict_th: "บ้านแพง",
            subdistrict_en: "Ban Phaeng",
            post_code: "48140",
          },
          {
            subdistrict_th: "ไผ่ล้อม",
            subdistrict_en: "Phai Lom",
            post_code: "48140",
          },
          {
            subdistrict_th: "โพนทอง",
            subdistrict_en: "Phon Thong",
            post_code: "48140",
          },
          {
            subdistrict_th: "หนองแวง",
            subdistrict_en: "Nong Waeng",
            post_code: "48140",
          },
          {
            subdistrict_th: "นางัว",
            subdistrict_en: "Na Ngua",
            post_code: "48140",
          },
          {
            subdistrict_th: "นาเข",
            subdistrict_en: "Na Khe",
            post_code: "48140",
          },
        ],
      },
      {
        district_th: "อำเภอธาตุพนม",
        district_en: "Amphoe That Phanom",
        children: [
          {
            subdistrict_th: "ธาตุพนม",
            subdistrict_en: "That Phanom",
            post_code: "48110",
          },
          {
            subdistrict_th: "ฝั่งแดง",
            subdistrict_en: "Fang Daeng",
            post_code: "48110",
          },
          {
            subdistrict_th: "โพนแพง",
            subdistrict_en: "Phon Phaeng",
            post_code: "48110",
          },
          {
            subdistrict_th: "พระกลางทุ่ง",
            subdistrict_en: "Phra Klang Thung",
            post_code: "48110",
          },
          {
            subdistrict_th: "นาถ่อน",
            subdistrict_en: "Na Thon",
            post_code: "48110",
          },
          {
            subdistrict_th: "แสนพัน",
            subdistrict_en: "Saen Phan",
            post_code: "48110",
          },
          {
            subdistrict_th: "ดอนนางหงส์",
            subdistrict_en: "Don Nang Hong",
            post_code: "48110",
          },
          {
            subdistrict_th: "น้ำก่ำ",
            subdistrict_en: "Nam Kam",
            post_code: "48110",
          },
          {
            subdistrict_th: "อุ่มเหม้า",
            subdistrict_en: "Um Mao",
            post_code: "48110",
          },
          {
            subdistrict_th: "นาหนาด",
            subdistrict_en: "Na Nat",
            post_code: "48110",
          },
          {
            subdistrict_th: "กุดฉิม",
            subdistrict_en: "Kut Chim",
            post_code: "48110",
          },
          {
            subdistrict_th: "ธาตุพนมเหนือ",
            subdistrict_en: "That Phanom Nuea",
            post_code: "48110",
          },
        ],
      },
      {
        district_th: "อำเภอเรณูนคร",
        district_en: "Amphoe Renu Nakhon",
        children: [
          {
            subdistrict_th: "เรณู",
            subdistrict_en: "Renu",
            post_code: "48170",
          },
          {
            subdistrict_th: "โพนทอง",
            subdistrict_en: "Phon Thong",
            post_code: "48170",
          },
          {
            subdistrict_th: "ท่าลาด",
            subdistrict_en: "Tha Lat",
            post_code: "48170",
          },
          {
            subdistrict_th: "นางาม",
            subdistrict_en: "Na Ngam",
            post_code: "48170",
          },
          {
            subdistrict_th: "โคกหินแฮ่",
            subdistrict_en: "Khok Hin Hae",
            post_code: "48170",
          },
          {
            subdistrict_th: "หนองย่างชิ้น",
            subdistrict_en: "Nong Yang Chin",
            post_code: "48170",
          },
          {
            subdistrict_th: "เรณูใต้",
            subdistrict_en: "Renu Tai",
            post_code: "48170",
          },
          {
            subdistrict_th: "นาขาม",
            subdistrict_en: "Na Kham",
            post_code: "48170",
          },
        ],
      },
      {
        district_th: "อำเภอนาแก",
        district_en: "Amphoe Na Kae",
        children: [
          {
            subdistrict_th: "นาแก",
            subdistrict_en: "Na Kae",
            post_code: "48130",
          },
          {
            subdistrict_th: "พระซอง",
            subdistrict_en: "Phra Song",
            post_code: "48130",
          },
          {
            subdistrict_th: "หนองสังข์",
            subdistrict_en: "Nong Sang",
            post_code: "48130",
          },
          {
            subdistrict_th: "นาคู่",
            subdistrict_en: "Na Khu",
            post_code: "48130",
          },
          {
            subdistrict_th: "พิมาน",
            subdistrict_en: "Phiman",
            post_code: "48130",
          },
          {
            subdistrict_th: "พุ่มแก",
            subdistrict_en: "Phum Kae",
            post_code: "48130",
          },
          {
            subdistrict_th: "ก้านเหลือง",
            subdistrict_en: "Kan Lueang",
            post_code: "48130",
          },
          {
            subdistrict_th: "หนองบ่อ",
            subdistrict_en: "Nong Bo",
            post_code: "48130",
          },
          {
            subdistrict_th: "นาเลียง",
            subdistrict_en: "Na Liang",
            post_code: "48130",
          },
          {
            subdistrict_th: "บ้านแก้ง",
            subdistrict_en: "Ban Kaeng",
            post_code: "48130",
          },
          {
            subdistrict_th: "คำพี้",
            subdistrict_en: "Ham Phi",
            post_code: "48130",
          },
          {
            subdistrict_th: "สีชมพู",
            subdistrict_en: "Si Chomphu",
            post_code: "48130",
          },
        ],
      },
      {
        district_th: "อำเภอศรีสงคราม",
        district_en: "Amphoe Si Songkhram",
        children: [
          {
            subdistrict_th: "ศรีสงคราม",
            subdistrict_en: "Si Songkhram",
            post_code: "48150",
          },
          {
            subdistrict_th: "นาเดื่อ",
            subdistrict_en: "Na Duea",
            post_code: "48150",
          },
          {
            subdistrict_th: "บ้านเอื้อง",
            subdistrict_en: "Ban Ueang",
            post_code: "48150",
          },
          {
            subdistrict_th: "สามผง",
            subdistrict_en: "sam Phong",
            post_code: "48150",
          },
          {
            subdistrict_th: "ท่าบ่อสงคราม",
            subdistrict_en: "Tha Bo Songkhram",
            post_code: "48150",
          },
          {
            subdistrict_th: "บ้านข่า",
            subdistrict_en: "Ban Kha",
            post_code: "48150",
          },
          {
            subdistrict_th: "นาคำ",
            subdistrict_en: "Na Kham",
            post_code: "48150",
          },
          {
            subdistrict_th: "โพนสว่าง",
            subdistrict_en: "Phon Sawang",
            post_code: "48150",
          },
          {
            subdistrict_th: "หาดแพง",
            subdistrict_en: "Hat Phaeng",
            post_code: "48150",
          },
        ],
      },
      {
        district_th: "อำเภอนาหว้า",
        district_en: "Amphoe Na Wa",
        children: [
          {
            subdistrict_th: "นาหว้า",
            subdistrict_en: "Na Wa",
            post_code: "48180",
          },
          {
            subdistrict_th: "นางัว",
            subdistrict_en: "Na Ngua",
            post_code: "48180",
          },
          {
            subdistrict_th: "บ้านเสียว",
            subdistrict_en: "Ban Siao",
            post_code: "48180",
          },
          {
            subdistrict_th: "นาคูณใหญ่",
            subdistrict_en: "Na Khun Yai",
            post_code: "48180",
          },
          {
            subdistrict_th: "เหล่าพัฒนา",
            subdistrict_en: "Lao Phatthana",
            post_code: "48180",
          },
          {
            subdistrict_th: "ท่าเรือ",
            subdistrict_en: "Tha Ruea",
            post_code: "48180",
          },
        ],
      },
      {
        district_th: "อำเภอโพนสวรรค์",
        district_en: "Amphoe Phon Sawan",
        children: [
          {
            subdistrict_th: "โพนสวรรค์",
            subdistrict_en: "Phon Sawan",
            post_code: "48190",
          },
          {
            subdistrict_th: "นาหัวบ่อ",
            subdistrict_en: "Na Hua Bo",
            post_code: "48190",
          },
          {
            subdistrict_th: "นาขมิ้น",
            subdistrict_en: "Na Khamin",
            post_code: "48190",
          },
          {
            subdistrict_th: "โพนบก",
            subdistrict_en: "Phon Bok",
            post_code: "48190",
          },
          {
            subdistrict_th: "บ้านค้อ",
            subdistrict_en: "Ban Kho",
            post_code: "48190",
          },
          {
            subdistrict_th: "โพนจาน",
            subdistrict_en: "Phon Chan",
            post_code: "48190",
          },
          {
            subdistrict_th: "นาใน",
            subdistrict_en: "Na Nai",
            post_code: "48190",
          },
        ],
      },
      {
        district_th: "อำเภอนาทม",
        district_en: "Amphoe Na Thom",
        children: [
          {
            subdistrict_th: "นาทม",
            subdistrict_en: "Na Thom",
            post_code: "48140",
          },
          {
            subdistrict_th: "หนองซน",
            subdistrict_en: "Nong Son",
            post_code: "48140",
          },
          {
            subdistrict_th: "ดอนเตย",
            subdistrict_en: "Don Toei",
            post_code: "48140",
          },
        ],
      },
      {
        district_th: "อำเภอวังยาง",
        district_en: "Amphoe Wang Yang",
        children: [
          {
            subdistrict_th: "วังยาง",
            subdistrict_en: "Wang Yang",
            post_code: "48130",
          },
          {
            subdistrict_th: "โคกสี",
            subdistrict_en: "Khok Si",
            post_code: "48130",
          },
          {
            subdistrict_th: "ยอดชาด",
            subdistrict_en: "Yot Chat",
            post_code: "48130",
          },
          {
            subdistrict_th: "หนองโพธิ์",
            subdistrict_en: "Nong Pho",
            post_code: "48130",
          },
        ],
      },
    ],
  },
  {
    province_th: "มุกดาหาร",
    province_en: "Mukdahan",
    children: [
      {
        district_th: "อำเภอเมืองมุกดาหาร",
        district_en: "Amphoe Mueang Mukdahan",
        children: [
          {
            subdistrict_th: "มุกดาหาร",
            subdistrict_en: "Mukdahan",
            post_code: "49000",
          },
          {
            subdistrict_th: "ศรีบุญเรือง",
            subdistrict_en: "Si Bun Rueang",
            post_code: "49000",
          },
          {
            subdistrict_th: "บ้านโคก",
            subdistrict_en: "Ban Khok",
            post_code: "49000",
          },
          {
            subdistrict_th: "บางทรายใหญ่",
            subdistrict_en: "Bang Sai Yai",
            post_code: "49000",
          },
          {
            subdistrict_th: "โพนทราย",
            subdistrict_en: "Phon Sai",
            post_code: "49000",
          },
          {
            subdistrict_th: "ผึ่งแดด",
            subdistrict_en: "Phueng Daet",
            post_code: "49000",
          },
          {
            subdistrict_th: "นาโสก",
            subdistrict_en: "Na Sok",
            post_code: "49000",
          },
          {
            subdistrict_th: "นาสีนวน",
            subdistrict_en: "Na Si Nuan",
            post_code: "49000",
          },
          {
            subdistrict_th: "คำป่าหลาย",
            subdistrict_en: "Kham Pa Lai",
            post_code: "49000",
          },
          {
            subdistrict_th: "คำอาฮวน",
            subdistrict_en: "Kham Ahuan",
            post_code: "49000",
          },
          {
            subdistrict_th: "ดงเย็น",
            subdistrict_en: "Dong Yen",
            post_code: "49000",
          },
          {
            subdistrict_th: "ดงมอน",
            subdistrict_en: "Dong Mon",
            post_code: "49000",
          },
          {
            subdistrict_th: "กุดแข้",
            subdistrict_en: "Kut Khae",
            post_code: "49000",
          },
        ],
      },
      {
        district_th: "อำเภอนิคมคำสร้อย",
        district_en: "Amphoe Nikhom Kham Soi",
        children: [
          {
            subdistrict_th: "นิคมคำสร้อย",
            subdistrict_en: "Nikhom Kham Soi",
            post_code: "49130",
          },
          {
            subdistrict_th: "นากอก",
            subdistrict_en: "Na Kok",
            post_code: "49130",
          },
          {
            subdistrict_th: "หนองแวง",
            subdistrict_en: "Nong Waeng",
            post_code: "49130",
          },
          {
            subdistrict_th: "กกแดง",
            subdistrict_en: "Kok Daeng",
            post_code: "49130",
          },
          {
            subdistrict_th: "นาอุดม",
            subdistrict_en: "Na Udom",
            post_code: "49130",
          },
          {
            subdistrict_th: "โชคชัย",
            subdistrict_en: "Chok Chai",
            post_code: "49130",
          },
          {
            subdistrict_th: "ร่มเกล้า",
            subdistrict_en: "Rom Klao",
            post_code: "49130",
          },
        ],
      },
      {
        district_th: "อำเภอดอนตาล",
        district_en: "Amphoe Don Tan",
        children: [
          {
            subdistrict_th: "ดอนตาล",
            subdistrict_en: "Don Tan",
            post_code: "49120",
          },
          {
            subdistrict_th: "โพธิ์ไทร",
            subdistrict_en: "Pho Sai",
            post_code: "49120",
          },
          {
            subdistrict_th: "ป่าไร่",
            subdistrict_en: "Pa Rai",
            post_code: "49120",
          },
          {
            subdistrict_th: "เหล่าหมี",
            subdistrict_en: "Lao Mi",
            post_code: "49120",
          },
          {
            subdistrict_th: "บ้านบาก",
            subdistrict_en: "Ban Bak",
            post_code: "49120",
          },
          {
            subdistrict_th: "นาสะเม็ง",
            subdistrict_en: "Na Sameng",
            post_code: "49120",
          },
          {
            subdistrict_th: "บ้านแก้ง",
            subdistrict_en: "Ban Kaeng",
            post_code: "49120",
          },
        ],
      },
      {
        district_th: "อำเภอดงหลวง",
        district_en: "Amphoe Dong Luang",
        children: [
          {
            subdistrict_th: "ดงหลวง",
            subdistrict_en: "Dong Luang",
            post_code: "49140",
          },
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "49140",
          },
          {
            subdistrict_th: "กกตูม",
            subdistrict_en: "Kok Tum",
            post_code: "49140",
          },
          {
            subdistrict_th: "หนองแคน",
            subdistrict_en: "Nong Khaen",
            post_code: "49140",
          },
          {
            subdistrict_th: "ชะโนดน้อย",
            subdistrict_en: "Chanot Noi",
            post_code: "49140",
          },
          {
            subdistrict_th: "พังแดง",
            subdistrict_en: "Phang Daeng",
            post_code: "49140",
          },
        ],
      },
      {
        district_th: "อำเภอคำชะอี",
        district_en: "Amphoe Khamcha-i",
        children: [
          {
            subdistrict_th: "บ้านซ่ง",
            subdistrict_en: "Ban Song",
            post_code: "49110",
          },
          {
            subdistrict_th: "คำชะอี",
            subdistrict_en: "Kham Cha-i",
            post_code: "49110",
          },
          {
            subdistrict_th: "หนองเอี่ยน",
            subdistrict_en: "Nong Ian",
            post_code: "49110",
          },
          {
            subdistrict_th: "บ้านค้อ",
            subdistrict_en: "Ban Kho",
            post_code: "49110",
          },
          {
            subdistrict_th: "บ้านเหล่า",
            subdistrict_en: "Ban Lao",
            post_code: "49110",
          },
          {
            subdistrict_th: "โพนงาม",
            subdistrict_en: "Phon Ngam",
            post_code: "49110",
          },
          {
            subdistrict_th: "เหล่าสร้างถ่อ",
            subdistrict_en: "Lao Sang Tho",
            post_code: "49110",
          },
          {
            subdistrict_th: "คำบก",
            subdistrict_en: "Kham Bok",
            post_code: "49110",
          },
          {
            subdistrict_th: "น้ำเที่ยง",
            subdistrict_en: "Nam Thiang",
            post_code: "49110",
          },
        ],
      },
      {
        district_th: "อำเภอหว้านใหญ่",
        district_en: "Amphoe Wan Yai",
        children: [
          {
            subdistrict_th: "หว้านใหญ่",
            subdistrict_en: "Wan Yai",
            post_code: "49150",
          },
          {
            subdistrict_th: "ป่งขาม",
            subdistrict_en: "Pong Kham",
            post_code: "49150",
          },
          {
            subdistrict_th: "บางทรายน้อย",
            subdistrict_en: "Bang Sai Noi",
            post_code: "49150",
          },
          {
            subdistrict_th: "ชะโนด",
            subdistrict_en: "Chanot",
            post_code: "49150",
          },
          {
            subdistrict_th: "ดงหมู",
            subdistrict_en: "Dong Mu",
            post_code: "49150",
          },
        ],
      },
      {
        district_th: "อำเภอหนองสูง",
        district_en: "Amphoe Nong Sung",
        children: [
          {
            subdistrict_th: "หนองสูง",
            subdistrict_en: "Nong Sung",
            post_code: "49160",
          },
          {
            subdistrict_th: "โนนยาง",
            subdistrict_en: "Non Yang",
            post_code: "49160",
          },
          {
            subdistrict_th: "ภูวง",
            subdistrict_en: "Phu Wong",
            post_code: "49160",
          },
          {
            subdistrict_th: "บ้านเป้า",
            subdistrict_en: "Ban Pao",
            post_code: "49160",
          },
          {
            subdistrict_th: "หนองสูงใต้",
            subdistrict_en: "Nong Sung Tai",
            post_code: "49160",
          },
          {
            subdistrict_th: "หนองสูงเหนือ",
            subdistrict_en: "Nong Sung Nuea",
            post_code: "49160",
          },
        ],
      },
    ],
  },
  {
    province_th: "เชียงใหม่",
    province_en: "Chiang Mai",
    children: [
      {
        district_th: "อำเภอเมืองเชียงใหม่",
        district_en: "Amphoe Mueang Chiang Mai",
        children: [
          {
            subdistrict_th: "ศรีภูมิ",
            subdistrict_en: "Si Phum",
            post_code: "50200",
          },
          {
            subdistrict_th: "พระสิงห์",
            subdistrict_en: "Phra Sing",
            post_code: "50200",
          },
          {
            subdistrict_th: "หายยา",
            subdistrict_en: "Hai Ya",
            post_code: "50100",
          },
          {
            subdistrict_th: "ช้างม่อย",
            subdistrict_en: "Chang Moi",
            post_code: "50300",
          },
          {
            subdistrict_th: "ช้างคลาน",
            subdistrict_en: "Chang Khlan",
            post_code: "50100",
          },
          {
            subdistrict_th: "วัดเกต",
            subdistrict_en: "Wat Ket",
            post_code: "50000",
          },
          {
            subdistrict_th: "ช้างเผือก",
            subdistrict_en: "Chang Phueak",
            post_code: "50300",
          },
          {
            subdistrict_th: "สุเทพ",
            subdistrict_en: "Suthep",
            post_code: "50200",
          },
          {
            subdistrict_th: "แม่เหียะ",
            subdistrict_en: "Mae Hia",
            post_code: "50100",
          },
          {
            subdistrict_th: "ป่าแดด",
            subdistrict_en: "Pa Daet",
            post_code: "50100",
          },
          {
            subdistrict_th: "หนองหอย",
            subdistrict_en: "Nong Hoi",
            post_code: "50000",
          },
          {
            subdistrict_th: "ท่าศาลา",
            subdistrict_en: "Tha Sala",
            post_code: "50000",
          },
          {
            subdistrict_th: "หนองป่าครั่ง",
            subdistrict_en: "Nong Pa Khrang",
            post_code: "50000",
          },
          {
            subdistrict_th: "ฟ้าฮ่าม",
            subdistrict_en: "Fa Ham",
            post_code: "50000",
          },
          {
            subdistrict_th: "ป่าตัน",
            subdistrict_en: "Pa Tan",
            post_code: "50300",
          },
          {
            subdistrict_th: "สันผีเสื้อ",
            subdistrict_en: "San Phi Suea",
            post_code: "50300",
          },
        ],
      },
      {
        district_th: "อำเภอจอมทอง",
        district_en: "Amphoe Chom Thong",
        children: [
          {
            subdistrict_th: "บ้านหลวง",
            subdistrict_en: "Ban Luang",
            post_code: "50160",
          },
          {
            subdistrict_th: "ข่วงเปา",
            subdistrict_en: "Khuang Pao",
            post_code: "50160",
          },
          {
            subdistrict_th: "สบเตี๊ยะ",
            subdistrict_en: "Sop Tia",
            post_code: "50160",
          },
          {
            subdistrict_th: "บ้านแปะ",
            subdistrict_en: "Ban Pae",
            post_code: "50240",
          },
          {
            subdistrict_th: "ดอยแก้ว",
            subdistrict_en: "Doi Kaeo",
            post_code: "50160",
          },
          {
            subdistrict_th: "แม่สอย",
            subdistrict_en: "Mae Soi",
            post_code: "50240",
          },
        ],
      },
      {
        district_th: "อำเภอแม่แจ่ม",
        district_en: "Amphoe Mae Chaem",
        children: [
          {
            subdistrict_th: "ช่างเคิ่ง",
            subdistrict_en: "Chang Khoeng",
            post_code: "50270",
          },
          {
            subdistrict_th: "ท่าผา",
            subdistrict_en: "The Pha",
            post_code: "50270",
          },
          {
            subdistrict_th: "บ้านทับ",
            subdistrict_en: "Ban Thap",
            post_code: "50270",
          },
          {
            subdistrict_th: "แม่ศึก",
            subdistrict_en: "Mae Suek",
            post_code: "50270",
          },
          {
            subdistrict_th: "แม่นาจร",
            subdistrict_en: "Mae Na Chon",
            post_code: "50270",
          },
          {
            subdistrict_th: "ปางหินฝน",
            subdistrict_en: "Pang Hin Fon",
            post_code: "50270",
          },
          {
            subdistrict_th: "กองแขก",
            subdistrict_en: "Kong Khaek",
            post_code: "50270",
          },
        ],
      },
      {
        district_th: "อำเภอเชียงดาว",
        district_en: "Amphoe Chiang Dao",
        children: [
          {
            subdistrict_th: "เชียงดาว",
            subdistrict_en: "Chiang Dao",
            post_code: "50170",
          },
          {
            subdistrict_th: "เมืองนะ",
            subdistrict_en: "Mueang Na",
            post_code: "50170",
          },
          {
            subdistrict_th: "เมืองงาย",
            subdistrict_en: "Mueang Ngai",
            post_code: "50170",
          },
          {
            subdistrict_th: "แม่นะ",
            subdistrict_en: "Mae Na",
            post_code: "50170",
          },
          {
            subdistrict_th: "เมืองคอง",
            subdistrict_en: "Mueang Khong",
            post_code: "50170",
          },
          {
            subdistrict_th: "ปิงโค้ง",
            subdistrict_en: "Ping Khong",
            post_code: "50170",
          },
          {
            subdistrict_th: "ทุ่งข้าวพวง",
            subdistrict_en: "Thung Khao Phuang",
            post_code: "50170",
          },
        ],
      },
      {
        district_th: "อำเภอดอยสะเก็ด",
        district_en: "Amphoe Doi Saket",
        children: [
          {
            subdistrict_th: "เชิงดอย",
            subdistrict_en: "Choeng Doi",
            post_code: "50220",
          },
          {
            subdistrict_th: "สันปูเลย",
            subdistrict_en: "San Pu Loei",
            post_code: "50220",
          },
          {
            subdistrict_th: "ลวงเหนือ",
            subdistrict_en: "Luang Nuea",
            post_code: "50220",
          },
          {
            subdistrict_th: "ป่าป้อง",
            subdistrict_en: "Pa Pong",
            post_code: "50220",
          },
          {
            subdistrict_th: "สง่าบ้าน",
            subdistrict_en: "Sa-nga Ban",
            post_code: "50220",
          },
          {
            subdistrict_th: "ป่าลาน",
            subdistrict_en: "Pa Lan",
            post_code: "50220",
          },
          {
            subdistrict_th: "ตลาดขวัญ",
            subdistrict_en: "Talat Khwan",
            post_code: "50220",
          },
          {
            subdistrict_th: "สำราญราษฎร์",
            subdistrict_en: "Samran Rat",
            post_code: "50220",
          },
          {
            subdistrict_th: "แม่คือ",
            subdistrict_en: "Mae Khue",
            post_code: "50220",
          },
          {
            subdistrict_th: "ตลาดใหญ่",
            subdistrict_en: "Talat Yai",
            post_code: "50220",
          },
          {
            subdistrict_th: "แม่ฮ้อยเงิน",
            subdistrict_en: "Mae Hoi Ngoen",
            post_code: "50220",
          },
          {
            subdistrict_th: "แม่โป่ง",
            subdistrict_en: "Mae Pong",
            post_code: "50220",
          },
          {
            subdistrict_th: "ป่าเมี่ยง",
            subdistrict_en: "Pa Miang",
            post_code: "50220",
          },
          {
            subdistrict_th: "เทพเสด็จ",
            subdistrict_en: "Thep Sadet",
            post_code: "50220",
          },
        ],
      },
      {
        district_th: "อำเภอแม่แตง",
        district_en: "Amphoe Mae Taeng",
        children: [
          {
            subdistrict_th: "สันมหาพน",
            subdistrict_en: "San Maha Phon",
            post_code: "50150",
          },
          {
            subdistrict_th: "แม่แตง",
            subdistrict_en: "Mae Taeng",
            post_code: "50150",
          },
          {
            subdistrict_th: "ขี้เหล็ก",
            subdistrict_en: "Khilek",
            post_code: "50150",
          },
          {
            subdistrict_th: "ช่อแล",
            subdistrict_en: "Cho Lae",
            post_code: "50150",
          },
          {
            subdistrict_th: "แม่หอพระ",
            subdistrict_en: "Mae Ho Phra",
            post_code: "50150",
          },
          {
            subdistrict_th: "สบเปิง",
            subdistrict_en: "Sop Poeng",
            post_code: "50150",
          },
          {
            subdistrict_th: "บ้านเป้า",
            subdistrict_en: "Ban Pao",
            post_code: "50150",
          },
          {
            subdistrict_th: "สันป่ายาง",
            subdistrict_en: "San Pa Yang",
            post_code: "50330",
          },
          {
            subdistrict_th: "ป่าแป๋",
            subdistrict_en: "Pa Pae",
            post_code: "50150",
          },
          {
            subdistrict_th: "เมืองก๋าย",
            subdistrict_en: "Mueang Kai",
            post_code: "50150",
          },
          {
            subdistrict_th: "บ้านช้าง",
            subdistrict_en: "Ban Chang",
            post_code: "50150",
          },
          {
            subdistrict_th: "กื้ดช้าง",
            subdistrict_en: "Kuet Chang",
            post_code: "50150",
          },
          {
            subdistrict_th: "อินทขิล",
            subdistrict_en: "Inthakhin",
            post_code: "50150",
          },
        ],
      },
      {
        district_th: "อำเภอแม่ริม",
        district_en: "Amphoe Mae Rim",
        children: [
          {
            subdistrict_th: "ริมใต้",
            subdistrict_en: "Rim Tai",
            post_code: "50180",
          },
          {
            subdistrict_th: "ริมเหนือ",
            subdistrict_en: "Rim Nuea",
            post_code: "50180",
          },
          {
            subdistrict_th: "สันโป่ง",
            subdistrict_en: "San Pong",
            post_code: "50180",
          },
          {
            subdistrict_th: "ขี้เหล็ก",
            subdistrict_en: "Khilek",
            post_code: "50180",
          },
          {
            subdistrict_th: "สะลวง",
            subdistrict_en: "Saluang",
            post_code: "50330",
          },
          {
            subdistrict_th: "ห้วยทราย",
            subdistrict_en: "Huai Sai",
            post_code: "50180",
          },
          {
            subdistrict_th: "แม่แรม",
            subdistrict_en: "Mae Raem",
            post_code: "50180",
          },
          {
            subdistrict_th: "โป่งแยง",
            subdistrict_en: "Pong Yaeng",
            post_code: "50180",
          },
          {
            subdistrict_th: "แม่สา",
            subdistrict_en: "Mae Sa",
            post_code: "50180",
          },
          {
            subdistrict_th: "ดอนแก้ว",
            subdistrict_en: "Don Kaeo",
            post_code: "50180",
          },
          {
            subdistrict_th: "เหมืองแก้ว",
            subdistrict_en: "Mueang Kaeo",
            post_code: "50180",
          },
        ],
      },
      {
        district_th: "อำเภอสะเมิง",
        district_en: "Amphoe Samoeng",
        children: [
          {
            subdistrict_th: "สะเมิงใต้",
            subdistrict_en: "Samoeng Tai",
            post_code: "50250",
          },
          {
            subdistrict_th: "สะเมิงเหนือ",
            subdistrict_en: "Samoeng Nuea",
            post_code: "50250",
          },
          {
            subdistrict_th: "แม่สาบ",
            subdistrict_en: "Mae Sap",
            post_code: "50250",
          },
          {
            subdistrict_th: "บ่อแก้ว",
            subdistrict_en: "Bo Kaeo",
            post_code: "50250",
          },
          {
            subdistrict_th: "ยั้งเมิน",
            subdistrict_en: "Yang Moen",
            post_code: "50250",
          },
        ],
      },
      {
        district_th: "อำเภอฝาง",
        district_en: "Amphoe Fang",
        children: [
          {
            subdistrict_th: "เวียง",
            subdistrict_en: "Wiang",
            post_code: "50110",
          },
          {
            subdistrict_th: "ม่อนปิ่น",
            subdistrict_en: "Mon Pin",
            post_code: "50110",
          },
          {
            subdistrict_th: "แม่งอน",
            subdistrict_en: "Mae Ngon",
            post_code: "50320",
          },
          {
            subdistrict_th: "แม่สูน",
            subdistrict_en: "Mae Sun",
            post_code: "50110",
          },
          {
            subdistrict_th: "สันทราย",
            subdistrict_en: "San Sai",
            post_code: "50110",
          },
          {
            subdistrict_th: "แม่คะ",
            subdistrict_en: "Mae Kha",
            post_code: "50110",
          },
          {
            subdistrict_th: "แม่ข่า",
            subdistrict_en: "Mae Kha",
            post_code: "50320",
          },
          {
            subdistrict_th: "โป่งน้ำร้อน",
            subdistrict_en: "Pong Nam Ron",
            post_code: "50110",
          },
        ],
      },
      {
        district_th: "อำเภอแม่อาย",
        district_en: "Amphoe Mae Ai",
        children: [
          {
            subdistrict_th: "แม่อาย",
            subdistrict_en: "Mae Ai",
            post_code: "50280",
          },
          {
            subdistrict_th: "แม่สาว",
            subdistrict_en: "Mae Sao",
            post_code: "50280",
          },
          {
            subdistrict_th: "สันต้นหมื้อ",
            subdistrict_en: "San Ton Mue",
            post_code: "50280",
          },
          {
            subdistrict_th: "แม่นาวาง",
            subdistrict_en: "Mae Na Wang",
            post_code: "50280",
          },
          {
            subdistrict_th: "ท่าตอน",
            subdistrict_en: "Tha Ton",
            post_code: "50280",
          },
          {
            subdistrict_th: "บ้านหลวง",
            subdistrict_en: "Ban Luang",
            post_code: "50280",
          },
          {
            subdistrict_th: "มะลิกา",
            subdistrict_en: "Malika",
            post_code: "50280",
          },
        ],
      },
      {
        district_th: "อำเภอพร้าว",
        district_en: "Amphoe Phrao",
        children: [
          {
            subdistrict_th: "เวียง",
            subdistrict_en: "Wiang",
            post_code: "50190",
          },
          {
            subdistrict_th: "ทุ่งหลวง",
            subdistrict_en: "Thung Luang",
            post_code: "50190",
          },
          {
            subdistrict_th: "ป่าตุ้ม",
            subdistrict_en: "Pa Tum",
            post_code: "50190",
          },
          {
            subdistrict_th: "ป่าไหน่",
            subdistrict_en: "Pa Nai",
            post_code: "50190",
          },
          {
            subdistrict_th: "สันทราย",
            subdistrict_en: "San Sai",
            post_code: "50190",
          },
          {
            subdistrict_th: "บ้านโป่ง",
            subdistrict_en: "Ban Pong",
            post_code: "50190",
          },
          {
            subdistrict_th: "น้ำแพร่",
            subdistrict_en: "Nam Phrae",
            post_code: "50190",
          },
          {
            subdistrict_th: "เขื่อนผาก",
            subdistrict_en: "Khuean Phak",
            post_code: "50190",
          },
          {
            subdistrict_th: "แม่แวน",
            subdistrict_en: "Mae Waen",
            post_code: "50190",
          },
          {
            subdistrict_th: "แม่ปั๋ง",
            subdistrict_en: "Mae Pang",
            post_code: "50190",
          },
          {
            subdistrict_th: "โหล่งขอด",
            subdistrict_en: "Long Khot",
            post_code: "50190",
          },
        ],
      },
      {
        district_th: "อำเภอสันป่าตอง",
        district_en: "Amphoe San Pa Tong",
        children: [
          {
            subdistrict_th: "ยุหว่า",
            subdistrict_en: "Yu Wa",
            post_code: "50120",
          },
          {
            subdistrict_th: "สันกลาง",
            subdistrict_en: "San Klang",
            post_code: "50120",
          },
          {
            subdistrict_th: "ท่าวังพร้าว",
            subdistrict_en: "Tha Wang Phrao",
            post_code: "50120",
          },
          {
            subdistrict_th: "มะขามหลวง",
            subdistrict_en: "Makham Luang",
            post_code: "50120",
          },
          {
            subdistrict_th: "แม่ก๊า",
            subdistrict_en: "Mae Ka",
            post_code: "50120",
          },
          {
            subdistrict_th: "บ้านแม",
            subdistrict_en: "Ban Mae",
            post_code: "50120",
          },
          {
            subdistrict_th: "บ้านกลาง",
            subdistrict_en: "Ban Klang",
            post_code: "50120",
          },
          {
            subdistrict_th: "ทุ่งสะโตก",
            subdistrict_en: "Thung Satok",
            post_code: "50120",
          },
          {
            subdistrict_th: "ทุ่งต้อม",
            subdistrict_en: "Thung Tom",
            post_code: "50120",
          },
          {
            subdistrict_th: "น้ำบ่อหลวง",
            subdistrict_en: "Nam Bo Luang",
            post_code: "50120",
          },
          {
            subdistrict_th: "มะขุนหวาน",
            subdistrict_en: "Makhun Wan",
            post_code: "50120",
          },
        ],
      },
      {
        district_th: "อำเภอสันกำแพง",
        district_en: "Amphoe San Kamphaeng",
        children: [
          {
            subdistrict_th: "สันกำแพง",
            subdistrict_en: "San Kamphaeng",
            post_code: "50130",
          },
          {
            subdistrict_th: "ทรายมูล",
            subdistrict_en: "Sai Mun",
            post_code: "50130",
          },
          {
            subdistrict_th: "ร้องวัวแดง",
            subdistrict_en: "Rong Wua Daeng",
            post_code: "50130",
          },
          {
            subdistrict_th: "บวกค้าง",
            subdistrict_en: "Buak Khang",
            post_code: "50130",
          },
          {
            subdistrict_th: "แช่ช้าง",
            subdistrict_en: "Chae Chang",
            post_code: "50130",
          },
          {
            subdistrict_th: "ออนใต้",
            subdistrict_en: "On Tai",
            post_code: "50130",
          },
          {
            subdistrict_th: "แม่ปูคา",
            subdistrict_en: "Mae Pu Kha",
            post_code: "50130",
          },
          {
            subdistrict_th: "ห้วยทราย",
            subdistrict_en: "Huai Sai",
            post_code: "50130",
          },
          {
            subdistrict_th: "ต้นเปา",
            subdistrict_en: "Ton Pao",
            post_code: "50130",
          },
          {
            subdistrict_th: "สันกลาง",
            subdistrict_en: "San Klang",
            post_code: "50130",
          },
        ],
      },
      {
        district_th: "อำเภอสันทราย",
        district_en: "Amphoe San Sai",
        children: [
          {
            subdistrict_th: "สันทรายหลวง",
            subdistrict_en: "San Sai Luang",
            post_code: "50210",
          },
          {
            subdistrict_th: "สันทรายน้อย",
            subdistrict_en: "San Sai Noi",
            post_code: "50210",
          },
          {
            subdistrict_th: "สันพระเนตร",
            subdistrict_en: "San Phra Net",
            post_code: "50210",
          },
          {
            subdistrict_th: "สันนาเม็ง",
            subdistrict_en: "San Na Meng",
            post_code: "50210",
          },
          {
            subdistrict_th: "สันป่าเปา",
            subdistrict_en: "San Pa Pao",
            post_code: "50210",
          },
          {
            subdistrict_th: "หนองแหย่ง",
            subdistrict_en: "Nong Yaeng",
            post_code: "50210",
          },
          {
            subdistrict_th: "หนองจ๊อม",
            subdistrict_en: "Nong Chom",
            post_code: "50210",
          },
          {
            subdistrict_th: "หนองหาร",
            subdistrict_en: "Nong Han",
            post_code: "50290",
          },
          {
            subdistrict_th: "แม่แฝก",
            subdistrict_en: "Mae Faek",
            post_code: "50290",
          },
          {
            subdistrict_th: "แม่แฝกใหม่",
            subdistrict_en: "Mae Faek Mai",
            post_code: "50290",
          },
          {
            subdistrict_th: "เมืองเล็น",
            subdistrict_en: "Mueang Len",
            post_code: "50210",
          },
          {
            subdistrict_th: "ป่าไผ่",
            subdistrict_en: "Pa Phai",
            post_code: "50210",
          },
        ],
      },
      {
        district_th: "อำเภอหางดง",
        district_en: "Amphoe Hang Dong",
        children: [
          {
            subdistrict_th: "หางดง",
            subdistrict_en: "Hang Dong",
            post_code: "50230",
          },
          {
            subdistrict_th: "หนองแก๋ว",
            subdistrict_en: "Nong Kaeo",
            post_code: "50230",
          },
          {
            subdistrict_th: "หารแก้ว",
            subdistrict_en: "Han Kaeo",
            post_code: "50230",
          },
          {
            subdistrict_th: "หนองตอง",
            subdistrict_en: "Nong Tong",
            post_code: "50340",
          },
          {
            subdistrict_th: "ขุนคง",
            subdistrict_en: "Khun Khong",
            post_code: "50230",
          },
          {
            subdistrict_th: "สบแม่ข่า",
            subdistrict_en: "Sop Mae Kha",
            post_code: "50230",
          },
          {
            subdistrict_th: "บ้านแหวน",
            subdistrict_en: "Ban Waen",
            post_code: "50230",
          },
          {
            subdistrict_th: "สันผักหวาน",
            subdistrict_en: "San Phak Wan",
            post_code: "50230",
          },
          {
            subdistrict_th: "หนองควาย",
            subdistrict_en: "Nong Khwai",
            post_code: "50230",
          },
          {
            subdistrict_th: "บ้านปง",
            subdistrict_en: "Ban Pong",
            post_code: "50230",
          },
          {
            subdistrict_th: "น้ำแพร่",
            subdistrict_en: "Nam Phrae",
            post_code: "50230",
          },
        ],
      },
      {
        district_th: "อำเภอฮอด",
        district_en: "Amphoe Hot",
        children: [
          {
            subdistrict_th: "หางดง",
            subdistrict_en: "Hang Dong",
            post_code: "50240",
          },
          {
            subdistrict_th: "ฮอด",
            subdistrict_en: "Hot",
            post_code: "50240",
          },
          {
            subdistrict_th: "บ้านตาล",
            subdistrict_en: "Ban Tan",
            post_code: "50240",
          },
          {
            subdistrict_th: "บ่อหลวง",
            subdistrict_en: "Bo Luang",
            post_code: "50240",
          },
          {
            subdistrict_th: "บ่อสลี",
            subdistrict_en: "Bo Sali",
            post_code: "50240",
          },
          {
            subdistrict_th: "นาคอเรือ",
            subdistrict_en: "Na Kho Ruea",
            post_code: "50240",
          },
        ],
      },
      {
        district_th: "อำเภอดอยเต่า",
        district_en: "Amphoe Doi Tao",
        children: [
          {
            subdistrict_th: "ดอยเต่า",
            subdistrict_en: "Doi Tao",
            post_code: "50260",
          },
          {
            subdistrict_th: "ท่าเดื่อ",
            subdistrict_en: "Tha Duea",
            post_code: "50260",
          },
          {
            subdistrict_th: "มืดกา",
            subdistrict_en: "Muet Ka",
            post_code: "50260",
          },
          {
            subdistrict_th: "บ้านแอ่น",
            subdistrict_en: "Ban Aen",
            post_code: "50260",
          },
          {
            subdistrict_th: "บงตัน",
            subdistrict_en: "Bong Tan",
            post_code: "50260",
          },
          {
            subdistrict_th: "โปงทุ่ง",
            subdistrict_en: "Pong Thung",
            post_code: "50260",
          },
        ],
      },
      {
        district_th: "อำเภออมก๋อย",
        district_en: "Amphoe Omkoi",
        children: [
          {
            subdistrict_th: "อมก๋อย",
            subdistrict_en: "Omkoi",
            post_code: "50310",
          },
          {
            subdistrict_th: "ยางเปียง",
            subdistrict_en: "Yang Piang",
            post_code: "50310",
          },
          {
            subdistrict_th: "แม่ตื่น",
            subdistrict_en: "Mae Tuen",
            post_code: "50310",
          },
          {
            subdistrict_th: "ม่อนจอง",
            subdistrict_en: "Mon Chong",
            post_code: "50310",
          },
          {
            subdistrict_th: "สบโขง",
            subdistrict_en: "Sop Khong",
            post_code: "50310",
          },
          {
            subdistrict_th: "นาเกียน",
            subdistrict_en: "Na Kian",
            post_code: "50310",
          },
        ],
      },
      {
        district_th: "อำเภอสารภี",
        district_en: "Amphoe Saraphi",
        children: [
          {
            subdistrict_th: "ยางเนิ้ง",
            subdistrict_en: "Yang Noeng",
            post_code: "50140",
          },
          {
            subdistrict_th: "สารภี",
            subdistrict_en: "Saraphi",
            post_code: "50140",
          },
          {
            subdistrict_th: "ชมภู",
            subdistrict_en: "Chomphu",
            post_code: "50140",
          },
          {
            subdistrict_th: "ไชยสถาน",
            subdistrict_en: "Chai Sathan",
            post_code: "50140",
          },
          {
            subdistrict_th: "ขัวมุง",
            subdistrict_en: "Khua Mung",
            post_code: "50140",
          },
          {
            subdistrict_th: "หนองแฝก",
            subdistrict_en: "Nong Faek",
            post_code: "50140",
          },
          {
            subdistrict_th: "หนองผึ้ง",
            subdistrict_en: "Nong Phueng",
            post_code: "50140",
          },
          {
            subdistrict_th: "ท่ากว้าง",
            subdistrict_en: "Tha Kwang",
            post_code: "50140",
          },
          {
            subdistrict_th: "ดอนแก้ว",
            subdistrict_en: "Don Kaeo",
            post_code: "50140",
          },
          {
            subdistrict_th: "ท่าวังตาล",
            subdistrict_en: "Tha Wang Tan",
            post_code: "50140",
          },
          {
            subdistrict_th: "สันทราย",
            subdistrict_en: "San Sai",
            post_code: "50140",
          },
          {
            subdistrict_th: "ป่าบง",
            subdistrict_en: "Pa Bong",
            post_code: "50140",
          },
        ],
      },
      {
        district_th: "อำเภอเวียงแหง",
        district_en: "Amphoe Wiang Haeng",
        children: [
          {
            subdistrict_th: "เมืองแหง",
            subdistrict_en: "Mueang Haeng",
            post_code: "50350",
          },
          {
            subdistrict_th: "เปียงหลวง",
            subdistrict_en: "Piang Luang",
            post_code: "50350",
          },
          {
            subdistrict_th: "แสนไห",
            subdistrict_en: "Saen Hai",
            post_code: "50350",
          },
        ],
      },
      {
        district_th: "อำเภอไชยปราการ",
        district_en: "Amphoe Chai Prakan",
        children: [
          {
            subdistrict_th: "ปงตำ",
            subdistrict_en: "Pong Tam",
            post_code: "50320",
          },
          {
            subdistrict_th: "ศรีดงเย็น",
            subdistrict_en: "Si Dong Yen",
            post_code: "50320",
          },
          {
            subdistrict_th: "แม่ทะลบ",
            subdistrict_en: "Mae Thalop",
            post_code: "50320",
          },
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "50320",
          },
        ],
      },
      {
        district_th: "อำเภอแม่วาง",
        district_en: "Amphoe Mae Wang",
        children: [
          {
            subdistrict_th: "บ้านกาด",
            subdistrict_en: "Ban Kat",
            post_code: "50360",
          },
          {
            subdistrict_th: "ทุ่งปี๊",
            subdistrict_en: "Thung Pi",
            post_code: "50360",
          },
          {
            subdistrict_th: "ทุ่งรวงทอง",
            subdistrict_en: "Thung Ruang Thong",
            post_code: "50360",
          },
          {
            subdistrict_th: "แม่วิน",
            subdistrict_en: "Mae Win",
            post_code: "50360",
          },
          {
            subdistrict_th: "ดอนเปา",
            subdistrict_en: "Don Pao",
            post_code: "50360",
          },
        ],
      },
      {
        district_th: "อำเภอแม่ออน",
        district_en: "Amphoe Mae On",
        children: [
          {
            subdistrict_th: "ออนเหนือ",
            subdistrict_en: "On Nuea",
            post_code: "50130",
          },
          {
            subdistrict_th: "ออนกลาง",
            subdistrict_en: "On Klang",
            post_code: "50130",
          },
          {
            subdistrict_th: "บ้านสหกรณ์",
            subdistrict_en: "Ban Sahakon",
            post_code: "50130",
          },
          {
            subdistrict_th: "ห้วยแก้ว",
            subdistrict_en: "Huai Kaeo",
            post_code: "50130",
          },
          {
            subdistrict_th: "แม่ทา",
            subdistrict_en: "Mae Tha",
            post_code: "50130",
          },
          {
            subdistrict_th: "ทาเหนือ",
            subdistrict_en: "Tha Nuea",
            post_code: "50130",
          },
        ],
      },
      {
        district_th: "อำเภอดอยหล่อ",
        district_en: "Amphoe Doi Lo",
        children: [
          {
            subdistrict_th: "ดอยหล่อ",
            subdistrict_en: "Doi Lo",
            post_code: "50160",
          },
          {
            subdistrict_th: "สองแคว",
            subdistrict_en: "Song Khwae",
            post_code: "50160",
          },
          {
            subdistrict_th: "ยางคราม",
            subdistrict_en: "Yang Khram",
            post_code: "50160",
          },
          {
            subdistrict_th: "สันติสุข",
            subdistrict_en: "Santi Suk",
            post_code: "50160",
          },
        ],
      },
      {
        district_th: "อำเภอกัลยาณิวัฒนา",
        district_en: "Amphoe Galayani Vadhana",
        children: [
          {
            subdistrict_th: "บ้านจันทร์",
            subdistrict_en: "Ban Chan",
            post_code: "58130",
          },
          {
            subdistrict_th: "แม่แดด",
            subdistrict_en: "Mae Daet",
            post_code: "58130",
          },
          {
            subdistrict_th: "แจ่มหลวง",
            subdistrict_en: "Chaem Luang",
            post_code: "58130",
          },
        ],
      },
    ],
  },
  {
    province_th: "ลำพูน",
    province_en: "Lamphun",
    children: [
      {
        district_th: "อำเภอเมืองลำพูน",
        district_en: "Amphoe Mueang Lamphun",
        children: [
          {
            subdistrict_th: "ในเมือง",
            subdistrict_en: "Nai Mueang",
            post_code: "51000",
          },
          {
            subdistrict_th: "เหมืองง่า",
            subdistrict_en: "Mueang Nga",
            post_code: "51000",
          },
          {
            subdistrict_th: "อุโมงค์",
            subdistrict_en: "Umong",
            post_code: "51150",
          },
          {
            subdistrict_th: "หนองช้างคืน",
            subdistrict_en: "Nong Chang Khuen",
            post_code: "51150",
          },
          {
            subdistrict_th: "ประตูป่า",
            subdistrict_en: "Pratu Pa",
            post_code: "51000",
          },
          {
            subdistrict_th: "ริมปิง",
            subdistrict_en: "Rim Ping",
            post_code: "51000",
          },
          {
            subdistrict_th: "ต้นธง",
            subdistrict_en: "Ton Thong",
            post_code: "51000",
          },
          {
            subdistrict_th: "บ้านแป้น",
            subdistrict_en: "Ban Paen",
            post_code: "51000",
          },
          {
            subdistrict_th: "เหมืองจี้",
            subdistrict_en: "Mueang Chi",
            post_code: "51000",
          },
          {
            subdistrict_th: "ป่าสัก",
            subdistrict_en: "Pa Sak",
            post_code: "51000",
          },
          {
            subdistrict_th: "เวียงยอง",
            subdistrict_en: "Wiang Yong",
            post_code: "51000",
          },
          {
            subdistrict_th: "บ้านกลาง",
            subdistrict_en: "Ban Klang",
            post_code: "51000",
          },
          {
            subdistrict_th: "มะเขือแจ้",
            subdistrict_en: "Makhuea Chae",
            post_code: "51000",
          },
          {
            subdistrict_th: "ศรีบัวบาน",
            subdistrict_en: "Si Bua Ban",
            post_code: "51000",
          },
          {
            subdistrict_th: "หนองหนาม",
            subdistrict_en: "Nong Nam",
            post_code: "51000",
          },
        ],
      },
      {
        district_th: "อำเภอแม่ทา",
        district_en: "Amphoe Mae Tha",
        children: [
          {
            subdistrict_th: "ทาปลาดุก",
            subdistrict_en: "Tha Pla Duk",
            post_code: "51140",
          },
          {
            subdistrict_th: "ทาสบเส้า",
            subdistrict_en: "Tha Sop Sao",
            post_code: "51140",
          },
          {
            subdistrict_th: "ทากาศ",
            subdistrict_en: "Tha Kat",
            post_code: "51170",
          },
          {
            subdistrict_th: "ทาขุมเงิน",
            subdistrict_en: "Tha Khum Ngoen",
            post_code: "51170",
          },
          {
            subdistrict_th: "ทาทุ่งหลวง",
            subdistrict_en: "Tha Thung Luang",
            post_code: "51170",
          },
          {
            subdistrict_th: "ทาแม่ลอบ",
            subdistrict_en: "Tha Mae Lop",
            post_code: "51170",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านโฮ่ง",
        district_en: "Amphoe Ban Hong",
        children: [
          {
            subdistrict_th: "บ้านโฮ่ง",
            subdistrict_en: "Ban Hong",
            post_code: "51130",
          },
          {
            subdistrict_th: "ป่าพลู",
            subdistrict_en: "Pa Phlu",
            post_code: "51130",
          },
          {
            subdistrict_th: "เหล่ายาว",
            subdistrict_en: "Lao Yao",
            post_code: "51130",
          },
          {
            subdistrict_th: "ศรีเตี้ย",
            subdistrict_en: "Si Tia",
            post_code: "51130",
          },
          {
            subdistrict_th: "หนองปลาสะวาย",
            subdistrict_en: "Nong Pla Sawai",
            post_code: "51130",
          },
        ],
      },
      {
        district_th: "อำเภอลี้",
        district_en: "Amphoe Li",
        children: [
          {
            subdistrict_th: "ลี้",
            subdistrict_en: "Li",
            post_code: "51110",
          },
          {
            subdistrict_th: "แม่ตืน",
            subdistrict_en: "Mae Tuen",
            post_code: "51110",
          },
          {
            subdistrict_th: "นาทราย",
            subdistrict_en: "Na Sai",
            post_code: "51110",
          },
          {
            subdistrict_th: "ดงดำ",
            subdistrict_en: "Dong Dam",
            post_code: "51110",
          },
          {
            subdistrict_th: "ก้อ",
            subdistrict_en: "Ko",
            post_code: "51110",
          },
          {
            subdistrict_th: "แม่ลาน",
            subdistrict_en: "Mae Lan",
            post_code: "51110",
          },
          {
            subdistrict_th: "ป่าไผ่",
            subdistrict_en: "Pha Phai",
            post_code: "51110",
          },
          {
            subdistrict_th: "ศรีวิชัย",
            subdistrict_en: "Si Wichai",
            post_code: "51110",
          },
        ],
      },
      {
        district_th: "อำเภอทุ่งหัวช้าง",
        district_en: "Amphoe Thung Hua Chang",
        children: [
          {
            subdistrict_th: "ทุ่งหัวช้าง",
            subdistrict_en: "Thung Hua Chang",
            post_code: "51160",
          },
          {
            subdistrict_th: "บ้านปวง",
            subdistrict_en: "Ban Puang",
            post_code: "51160",
          },
          {
            subdistrict_th: "ตะเคียนปม",
            subdistrict_en: "Takhian Pom",
            post_code: "51160",
          },
        ],
      },
      {
        district_th: "อำเภอป่าซาง",
        district_en: "Amphoe Pa Sang",
        children: [
          {
            subdistrict_th: "ปากบ่อง",
            subdistrict_en: "Pak Bong",
            post_code: "51120",
          },
          {
            subdistrict_th: "ป่าซาง",
            subdistrict_en: "Pa Sang",
            post_code: "51120",
          },
          {
            subdistrict_th: "แม่แรง",
            subdistrict_en: "Mae Raeng",
            post_code: "51120",
          },
          {
            subdistrict_th: "ม่วงน้อย",
            subdistrict_en: "Muang Noi",
            post_code: "51120",
          },
          {
            subdistrict_th: "บ้านเรือน",
            subdistrict_en: "Ban Ruean",
            post_code: "51120",
          },
          {
            subdistrict_th: "มะกอก",
            subdistrict_en: "Makok",
            post_code: "51120",
          },
          {
            subdistrict_th: "ท่าตุ้ม",
            subdistrict_en: "Tha Tum",
            post_code: "51120",
          },
          {
            subdistrict_th: "น้ำดิบ",
            subdistrict_en: "Nam Dip",
            post_code: "51120",
          },
          {
            subdistrict_th: "นครเจดีย์",
            subdistrict_en: "Nakhon Chedi",
            post_code: "51120",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านธิ",
        district_en: "Amphoe Ban Thi",
        children: [
          {
            subdistrict_th: "บ้านธิ",
            subdistrict_en: "Ban Thi",
            post_code: "51180",
          },
          {
            subdistrict_th: "ห้วยยาบ",
            subdistrict_en: "Huai Yap",
            post_code: "51180",
          },
        ],
      },
      {
        district_th: "อำเภอเวียงหนองล่อง",
        district_en: "Amphoe Wiang Nong Long",
        children: [
          {
            subdistrict_th: "หนองล่อง",
            subdistrict_en: "Nong Long",
            post_code: "51120",
          },
          {
            subdistrict_th: "หนองยวง",
            subdistrict_en: "Nong Yuang",
            post_code: "51120",
          },
          {
            subdistrict_th: "วังผาง",
            subdistrict_en: "Wang Phang",
            post_code: "51120",
          },
        ],
      },
    ],
  },
  {
    province_th: "ลำปาง",
    province_en: "Lampang",
    children: [
      {
        district_th: "อำเภอเมืองลำปาง",
        district_en: "Amphoe Mueang Lampang",
        children: [
          {
            subdistrict_th: "เวียงเหนือ",
            subdistrict_en: "Wiang Nuea",
            post_code: "52000",
          },
          {
            subdistrict_th: "หัวเวียง",
            subdistrict_en: "Hua Wiang",
            post_code: "52000",
          },
          {
            subdistrict_th: "สวนดอก",
            subdistrict_en: "Suan Dok",
            post_code: "52100",
          },
          {
            subdistrict_th: "สบตุ๋ย",
            subdistrict_en: "Sop Tui",
            post_code: "52100",
          },
          {
            subdistrict_th: "พระบาท",
            subdistrict_en: "Phra Bat",
            post_code: "52000",
          },
          {
            subdistrict_th: "ชมพู",
            subdistrict_en: "Chomphu",
            post_code: "52100",
          },
          {
            subdistrict_th: "กล้วยแพะ",
            subdistrict_en: "Kluai Phae",
            post_code: "52000",
          },
          {
            subdistrict_th: "ปงแสนทอง",
            subdistrict_en: "Pong Saen Thong",
            post_code: "52100",
          },
          {
            subdistrict_th: "บ้านแลง",
            subdistrict_en: "Ban Laeng",
            post_code: "52000",
          },
          {
            subdistrict_th: "บ้านเสด็จ",
            subdistrict_en: "Ban Sadet",
            post_code: "52000",
          },
          {
            subdistrict_th: "พิชัย",
            subdistrict_en: "Phichai",
            post_code: "52000",
          },
          {
            subdistrict_th: "ทุ่งฝาย",
            subdistrict_en: "Thung Fai",
            post_code: "52000",
          },
          {
            subdistrict_th: "บ้านเอื้อม",
            subdistrict_en: "Ban Ueam",
            post_code: "52100",
          },
          {
            subdistrict_th: "บ้านเป้า",
            subdistrict_en: "Ban Pao",
            post_code: "52100",
          },
          {
            subdistrict_th: "บ้านค่า",
            subdistrict_en: "Ban Kha",
            post_code: "52100",
          },
          {
            subdistrict_th: "บ่อแฮ้ว",
            subdistrict_en: "Bo Haeo",
            post_code: "52100",
          },
          {
            subdistrict_th: "ต้นธงชัย",
            subdistrict_en: "Ton Thong Chai",
            post_code: "52000",
          },
          {
            subdistrict_th: "นิคมพัฒนา",
            subdistrict_en: "Nikhom Phatthana",
            post_code: "52000",
          },
          {
            subdistrict_th: "บุญนาคพัฒนา",
            subdistrict_en: "Bunnak Phatthana",
            post_code: "52000",
          },
        ],
      },
      {
        district_th: "อำเภอแม่เมาะ",
        district_en: "Amphoe Mae Mo",
        children: [
          {
            subdistrict_th: "บ้านดง",
            subdistrict_en: "Ban Dong",
            post_code: "52220",
          },
          {
            subdistrict_th: "นาสัก",
            subdistrict_en: "Na Sak",
            post_code: "52220",
          },
          {
            subdistrict_th: "จางเหนือ",
            subdistrict_en: "Chang Nuea",
            post_code: "52220",
          },
          {
            subdistrict_th: "แม่เมาะ",
            subdistrict_en: "Mae Mo",
            post_code: "52220",
          },
          {
            subdistrict_th: "สบป้าด",
            subdistrict_en: "Sop Pat",
            post_code: "52220",
          },
        ],
      },
      {
        district_th: "อำเภอเกาะคา",
        district_en: "Amphoe Ko Kha",
        children: [
          {
            subdistrict_th: "ลำปางหลวง",
            subdistrict_en: "Lampang Luang",
            post_code: "52130",
          },
          {
            subdistrict_th: "นาแก้ว",
            subdistrict_en: "Na Kaeo",
            post_code: "52130",
          },
          {
            subdistrict_th: "ไหล่หิน",
            subdistrict_en: "Lai Hin",
            post_code: "52130",
          },
          {
            subdistrict_th: "วังพร้าว",
            subdistrict_en: "Wang Phrao",
            post_code: "52130",
          },
          {
            subdistrict_th: "ศาลา",
            subdistrict_en: "Sala",
            post_code: "52130",
          },
          {
            subdistrict_th: "เกาะคา",
            subdistrict_en: "Ko Kha",
            post_code: "52130",
          },
          {
            subdistrict_th: "นาแส่ง",
            subdistrict_en: "Na Saeng",
            post_code: "52130",
          },
          {
            subdistrict_th: "ท่าผา",
            subdistrict_en: "Tha Pha",
            post_code: "52130",
          },
          {
            subdistrict_th: "ใหม่พัฒนา",
            subdistrict_en: "Mai Phatthana",
            post_code: "52130",
          },
        ],
      },
      {
        district_th: "อำเภอเสริมงาม",
        district_en: "Amphoe Soem Ngam",
        children: [
          {
            subdistrict_th: "ทุ่งงาม",
            subdistrict_en: "Thung Ngam",
            post_code: "52210",
          },
          {
            subdistrict_th: "เสริมขวา",
            subdistrict_en: "Soem Khwa",
            post_code: "52210",
          },
          {
            subdistrict_th: "เสริมซ้าย",
            subdistrict_en: "Soem Sai",
            post_code: "52210",
          },
          {
            subdistrict_th: "เสริมกลาง",
            subdistrict_en: "Soem Klang",
            post_code: "52210",
          },
        ],
      },
      {
        district_th: "อำเภองาว",
        district_en: "Amphoe Ngao",
        children: [
          {
            subdistrict_th: "หลวงเหนือ",
            subdistrict_en: "Luang Nuea",
            post_code: "52110",
          },
          {
            subdistrict_th: "หลวงใต้",
            subdistrict_en: "Luang Tai",
            post_code: "52110",
          },
          {
            subdistrict_th: "บ้านโป่ง",
            subdistrict_en: "Ban Pong",
            post_code: "52110",
          },
          {
            subdistrict_th: "บ้านร้อง",
            subdistrict_en: "Ban Rong",
            post_code: "52110",
          },
          {
            subdistrict_th: "ปงเตา",
            subdistrict_en: "Pong Tao",
            post_code: "52110",
          },
          {
            subdistrict_th: "นาแก",
            subdistrict_en: "Na Kae",
            post_code: "52110",
          },
          {
            subdistrict_th: "บ้านอ้อน",
            subdistrict_en: "Ban On",
            post_code: "52110",
          },
          {
            subdistrict_th: "บ้านแหง",
            subdistrict_en: "Ban Haeng",
            post_code: "52110",
          },
          {
            subdistrict_th: "บ้านหวด",
            subdistrict_en: "Ban Huat",
            post_code: "52110",
          },
          {
            subdistrict_th: "แม่ตีบ",
            subdistrict_en: "Mae Tip",
            post_code: "52110",
          },
        ],
      },
      {
        district_th: "อำเภอแจ้ห่ม",
        district_en: "Amphoe Chae Hom",
        children: [
          {
            subdistrict_th: "แจ้ห่ม",
            subdistrict_en: "Chae Hom",
            post_code: "52120",
          },
          {
            subdistrict_th: "บ้านสา",
            subdistrict_en: "Ban Sa",
            post_code: "52120",
          },
          {
            subdistrict_th: "ปงดอน",
            subdistrict_en: "Pong Don",
            post_code: "52120",
          },
          {
            subdistrict_th: "แม่สุก",
            subdistrict_en: "Mae Suk",
            post_code: "52120",
          },
          {
            subdistrict_th: "เมืองมาย",
            subdistrict_en: "Mueang Mai",
            post_code: "52120",
          },
          {
            subdistrict_th: "ทุ่งผึ้ง",
            subdistrict_en: "Thung Phueng",
            post_code: "52120",
          },
          {
            subdistrict_th: "วิเชตนคร",
            subdistrict_en: "Wichet Nakhon",
            post_code: "52120",
          },
        ],
      },
      {
        district_th: "อำเภอวังเหนือ",
        district_en: "Amphoe Wang Nuea",
        children: [
          {
            subdistrict_th: "ทุ่งฮั้ว",
            subdistrict_en: "Thung Hua",
            post_code: "52140",
          },
          {
            subdistrict_th: "วังเหนือ",
            subdistrict_en: "Wang Nuea",
            post_code: "52140",
          },
          {
            subdistrict_th: "วังใต้",
            subdistrict_en: "Wang Tai",
            post_code: "52140",
          },
          {
            subdistrict_th: "ร่องเคาะ",
            subdistrict_en: "Rong Kho",
            post_code: "52140",
          },
          {
            subdistrict_th: "วังทอง",
            subdistrict_en: "Wang Thong",
            post_code: "52140",
          },
          {
            subdistrict_th: "วังซ้าย",
            subdistrict_en: "Wang Sai",
            post_code: "52140",
          },
          {
            subdistrict_th: "วังแก้ว",
            subdistrict_en: "Wang Kaeo",
            post_code: "52140",
          },
          {
            subdistrict_th: "วังทรายคำ",
            subdistrict_en: "Wang Sai Kham",
            post_code: "52140",
          },
        ],
      },
      {
        district_th: "อำเภอเถิน",
        district_en: "Amphoe Thoen",
        children: [
          {
            subdistrict_th: "ล้อมแรด",
            subdistrict_en: "Lom Raet",
            post_code: "52160",
          },
          {
            subdistrict_th: "แม่วะ",
            subdistrict_en: "Mae Wa",
            post_code: "52230",
          },
          {
            subdistrict_th: "แม่ปะ",
            subdistrict_en: "Mae Pa",
            post_code: "52160",
          },
          {
            subdistrict_th: "แม่มอก",
            subdistrict_en: "Mae Mok",
            post_code: "52160",
          },
          {
            subdistrict_th: "เวียงมอก",
            subdistrict_en: "Wiang Mok",
            post_code: "52160",
          },
          {
            subdistrict_th: "นาโป่ง",
            subdistrict_en: "Na Pong",
            post_code: "52160",
          },
          {
            subdistrict_th: "แม่ถอด",
            subdistrict_en: "Mae Thot",
            post_code: "52160",
          },
          {
            subdistrict_th: "เถินบุรี",
            subdistrict_en: "Thoen Buri",
            post_code: "52160",
          },
        ],
      },
      {
        district_th: "อำเภอแม่พริก",
        district_en: "Amphoe Mae Phrik",
        children: [
          {
            subdistrict_th: "แม่พริก",
            subdistrict_en: "Mae Phrik",
            post_code: "52180",
          },
          {
            subdistrict_th: "ผาปัง",
            subdistrict_en: "Pha Pang",
            post_code: "52180",
          },
          {
            subdistrict_th: "แม่ปุ",
            subdistrict_en: "Mae Pu",
            post_code: "52180",
          },
          {
            subdistrict_th: "พระบาทวังตวง",
            subdistrict_en: "Phra Bat Wang Tuang",
            post_code: "52230",
          },
        ],
      },
      {
        district_th: "อำเภอแม่ทะ",
        district_en: "Amphoe Mae Tha",
        children: [
          {
            subdistrict_th: "แม่ทะ",
            subdistrict_en: "Mae Tha",
            post_code: "52150",
          },
          {
            subdistrict_th: "นาครัว",
            subdistrict_en: "Na Khrua",
            post_code: "52150",
          },
          {
            subdistrict_th: "ป่าตัน",
            subdistrict_en: "Pa Tan",
            post_code: "52150",
          },
          {
            subdistrict_th: "บ้านกิ่ว",
            subdistrict_en: "Ban Kio",
            post_code: "52150",
          },
          {
            subdistrict_th: "บ้านบอม",
            subdistrict_en: "Ban Bom",
            post_code: "52150",
          },
          {
            subdistrict_th: "น้ำโจ้",
            subdistrict_en: "Nam Cho",
            post_code: "52150",
          },
          {
            subdistrict_th: "ดอนไฟ",
            subdistrict_en: "Don Fai",
            post_code: "52150",
          },
          {
            subdistrict_th: "หัวเสือ",
            subdistrict_en: "Hua Suea",
            post_code: "52150",
          },
          {
            subdistrict_th: "วังเงิน",
            subdistrict_en: "Wang Ngoen",
            post_code: "52150",
          },
          {
            subdistrict_th: "สันดอนแก้ว",
            subdistrict_en: "San Don Kaeo",
            post_code: "52150",
          },
        ],
      },
      {
        district_th: "อำเภอสบปราบ",
        district_en: "Amphoe Sop Prap",
        children: [
          {
            subdistrict_th: "สบปราบ",
            subdistrict_en: "Sop Prap",
            post_code: "52170",
          },
          {
            subdistrict_th: "สมัย",
            subdistrict_en: "Samai",
            post_code: "52170",
          },
          {
            subdistrict_th: "แม่กัวะ",
            subdistrict_en: "Mae Kua",
            post_code: "52170",
          },
          {
            subdistrict_th: "นายาง",
            subdistrict_en: "Na Yang",
            post_code: "52170",
          },
        ],
      },
      {
        district_th: "อำเภอห้างฉัตร",
        district_en: "Amphoe Hang Chat",
        children: [
          {
            subdistrict_th: "ห้างฉัตร",
            subdistrict_en: "Hang Chat",
            post_code: "52190",
          },
          {
            subdistrict_th: "หนองหล่ม",
            subdistrict_en: "Nong Lom",
            post_code: "52190",
          },
          {
            subdistrict_th: "เมืองยาว",
            subdistrict_en: "Mueang Yao",
            post_code: "52190",
          },
          {
            subdistrict_th: "ปงยางคก",
            subdistrict_en: "Pong Yang Khok",
            post_code: "52190",
          },
          {
            subdistrict_th: "เวียงตาล",
            subdistrict_en: "Wiang Tan",
            post_code: "52190",
          },
          {
            subdistrict_th: "แม่สัน",
            subdistrict_en: "Mae San",
            post_code: "52190",
          },
          {
            subdistrict_th: "วอแก้ว",
            subdistrict_en: "Wo Kaeo",
            post_code: "52190",
          },
        ],
      },
      {
        district_th: "อำเภอเมืองปาน",
        district_en: "Amphoe Mueang Pan",
        children: [
          {
            subdistrict_th: "เมืองปาน",
            subdistrict_en: "Mueang Pan",
            post_code: "52240",
          },
          {
            subdistrict_th: "บ้านขอ",
            subdistrict_en: "Ban Kho",
            post_code: "52240",
          },
          {
            subdistrict_th: "ทุ่งกว๋าว",
            subdistrict_en: "Thung Kwao",
            post_code: "52240",
          },
          {
            subdistrict_th: "แจ้ซ้อน",
            subdistrict_en: "Chae Son",
            post_code: "52240",
          },
          {
            subdistrict_th: "หัวเมือง",
            subdistrict_en: "Hua Mueang",
            post_code: "52240",
          },
        ],
      },
    ],
  },
  {
    province_th: "อุตรดิตถ์",
    province_en: "Uttaradit",
    children: [
      {
        district_th: "อำเภอเมืองอุตรดิตถ์",
        district_en: "Amphoe Mueang Uttaradit",
        children: [
          {
            subdistrict_th: "ท่าอิฐ",
            subdistrict_en: "Tha It",
            post_code: "53000",
          },
          {
            subdistrict_th: "ท่าเสา",
            subdistrict_en: "Tha Sao",
            post_code: "53000",
          },
          {
            subdistrict_th: "บ้านเกาะ",
            subdistrict_en: "Ban Ko",
            post_code: "53000",
          },
          {
            subdistrict_th: "ป่าเซ่า",
            subdistrict_en: "Pa Sao",
            post_code: "53000",
          },
          {
            subdistrict_th: "คุ้งตะเภา",
            subdistrict_en: "Khung Taphao",
            post_code: "53000",
          },
          {
            subdistrict_th: "วังกะพี้",
            subdistrict_en: "Wang Kaphi",
            post_code: "53170",
          },
          {
            subdistrict_th: "หาดกรวด",
            subdistrict_en: "Hat Kruat",
            post_code: "53000",
          },
          {
            subdistrict_th: "น้ำริด",
            subdistrict_en: "Nam Rit",
            post_code: "53000",
          },
          {
            subdistrict_th: "งิ้วงาม",
            subdistrict_en: "Ngio Ngam",
            post_code: "53000",
          },
          {
            subdistrict_th: "บ้านด่านนาขาม",
            subdistrict_en: "Ban Dan Na Kham",
            post_code: "53000",
          },
          {
            subdistrict_th: "บ้านด่าน",
            subdistrict_en: "Ban Dan",
            post_code: "53000",
          },
          {
            subdistrict_th: "ผาจุก",
            subdistrict_en: "Pha Chuk",
            post_code: "53000",
          },
          {
            subdistrict_th: "วังดิน",
            subdistrict_en: "Wang Din",
            post_code: "53000",
          },
          {
            subdistrict_th: "แสนตอ",
            subdistrict_en: "Saen To",
            post_code: "53000",
          },
          {
            subdistrict_th: "หาดงิ้ว",
            subdistrict_en: "Hat Ngio",
            post_code: "53000",
          },
          {
            subdistrict_th: "ขุนฝาง",
            subdistrict_en: "Khun Fang",
            post_code: "53000",
          },
          {
            subdistrict_th: "ถ้ำฉลอง",
            subdistrict_en: "Tham Chalong",
            post_code: "53000",
          },
        ],
      },
      {
        district_th: "อำเภอตรอน",
        district_en: "Amphoe Tron",
        children: [
          {
            subdistrict_th: "วังแดง",
            subdistrict_en: "Wang Daeng",
            post_code: "53140",
          },
          {
            subdistrict_th: "บ้านแก่ง",
            subdistrict_en: "Ban Kaeng",
            post_code: "53140",
          },
          {
            subdistrict_th: "หาดสองแคว",
            subdistrict_en: "Hat Song Khwae",
            post_code: "53140",
          },
          {
            subdistrict_th: "น้ำอ่าง",
            subdistrict_en: "Nam Ang",
            post_code: "53140",
          },
          {
            subdistrict_th: "ข่อยสูง",
            subdistrict_en: "Khoi Sung",
            post_code: "53140",
          },
        ],
      },
      {
        district_th: "อำเภอท่าปลา",
        district_en: "Amphoe Tha Pla",
        children: [
          {
            subdistrict_th: "ท่าปลา",
            subdistrict_en: "Tha Pla",
            post_code: "53150",
          },
          {
            subdistrict_th: "หาดล้า",
            subdistrict_en: "Hat La",
            post_code: "53190",
          },
          {
            subdistrict_th: "ผาเลือด",
            subdistrict_en: "Pha Lueat",
            post_code: "53190",
          },
          {
            subdistrict_th: "จริม",
            subdistrict_en: "Chalim",
            post_code: "53150",
          },
          {
            subdistrict_th: "น้ำหมัน",
            subdistrict_en: "Nam Man",
            post_code: "53150",
          },
          {
            subdistrict_th: "นางพญา",
            subdistrict_en: "Nang Phaya",
            post_code: "53150",
          },
          {
            subdistrict_th: "ร่วมจิต",
            subdistrict_en: "Ruam Chit",
            post_code: "53190",
          },
        ],
      },
      {
        district_th: "อำเภอน้ำปาด",
        district_en: "Amphoe Nam Pat",
        children: [
          {
            subdistrict_th: "แสนตอ",
            subdistrict_en: "Saen To",
            post_code: "53110",
          },
          {
            subdistrict_th: "บ้านฝาย",
            subdistrict_en: "Ban Fai",
            post_code: "53110",
          },
          {
            subdistrict_th: "เด่นเหล็ก",
            subdistrict_en: "Den Lek",
            post_code: "53110",
          },
          {
            subdistrict_th: "น้ำไคร้",
            subdistrict_en: "Nam Khrai",
            post_code: "53110",
          },
          {
            subdistrict_th: "น้ำไผ่",
            subdistrict_en: "Nam Phai",
            post_code: "53110",
          },
          {
            subdistrict_th: "ห้วยมุ่น",
            subdistrict_en: "Huai Mun",
            post_code: "53110",
          },
          {
            subdistrict_th: "ท่าแฝก",
            subdistrict_en: "Tha Faek",
            post_code: "53110",
          },
        ],
      },
      {
        district_th: "อำเภอฟากท่า",
        district_en: "Amphoe Fak Tha",
        children: [
          {
            subdistrict_th: "ฟากท่า",
            subdistrict_en: "Fak Tha",
            post_code: "53160",
          },
          {
            subdistrict_th: "สองคอน",
            subdistrict_en: "Song Khon",
            post_code: "53160",
          },
          {
            subdistrict_th: "บ้านเสี้ยว",
            subdistrict_en: "Ban Siao",
            post_code: "53160",
          },
          {
            subdistrict_th: "สองห้อง",
            subdistrict_en: "Song Hong",
            post_code: "53160",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านโคก",
        district_en: "Amphoe Ban Khok",
        children: [
          {
            subdistrict_th: "ม่วงเจ็ดต้น",
            subdistrict_en: "Muang Chet Ton",
            post_code: "53180",
          },
          {
            subdistrict_th: "บ้านโคก",
            subdistrict_en: "Ban Khok",
            post_code: "53180",
          },
          {
            subdistrict_th: "นาขุม",
            subdistrict_en: "Na Khum",
            post_code: "53180",
          },
          {
            subdistrict_th: "บ่อเบี้ย",
            subdistrict_en: "Bo Bia",
            post_code: "53180",
          },
        ],
      },
      {
        district_th: "อำเภอพิชัย",
        district_en: "Amphoe Phichai",
        children: [
          {
            subdistrict_th: "ในเมือง",
            subdistrict_en: "Nai Mueang",
            post_code: "53120",
          },
          {
            subdistrict_th: "บ้านดารา",
            subdistrict_en: "Ban Dara",
            post_code: "53220",
          },
          {
            subdistrict_th: "ไร่อ้อย",
            subdistrict_en: "Rai Oi",
            post_code: "53120",
          },
          {
            subdistrict_th: "ท่าสัก",
            subdistrict_en: "Tha Sak",
            post_code: "53220",
          },
          {
            subdistrict_th: "คอรุม",
            subdistrict_en: "Kho Rum",
            post_code: "53120",
          },
          {
            subdistrict_th: "บ้านหม้อ",
            subdistrict_en: "Ban Mo",
            post_code: "53120",
          },
          {
            subdistrict_th: "ท่ามะเฟือง",
            subdistrict_en: "Tha Mafueang",
            post_code: "53120",
          },
          {
            subdistrict_th: "บ้านโคน",
            subdistrict_en: "Ban Khon",
            post_code: "53120",
          },
          {
            subdistrict_th: "พญาแมน",
            subdistrict_en: "Phaya Maen",
            post_code: "53120",
          },
          {
            subdistrict_th: "นาอิน",
            subdistrict_en: "Na In",
            post_code: "53120",
          },
          {
            subdistrict_th: "นายาง",
            subdistrict_en: "Na Yang",
            post_code: "53120",
          },
        ],
      },
      {
        district_th: "อำเภอลับแล",
        district_en: "Amphoe Laplae",
        children: [
          {
            subdistrict_th: "ศรีพนมมาศ",
            subdistrict_en: "Si Phanom Mat",
            post_code: "53130",
          },
          {
            subdistrict_th: "แม่พูล",
            subdistrict_en: "Mae Phun",
            post_code: "53130",
          },
          {
            subdistrict_th: "นานกกก",
            subdistrict_en: "Na Nok Kok",
            post_code: "53130",
          },
          {
            subdistrict_th: "ฝายหลวง",
            subdistrict_en: "Fai Luang",
            post_code: "53130",
          },
          {
            subdistrict_th: "ชัยจุมพล",
            subdistrict_en: "Chai Chumphon",
            post_code: "53130",
          },
          {
            subdistrict_th: "ไผ่ล้อม",
            subdistrict_en: "Phai Lom",
            post_code: "53210",
          },
          {
            subdistrict_th: "ทุ่งยั้ง",
            subdistrict_en: "Thung Yang",
            post_code: "53210",
          },
          {
            subdistrict_th: "ด่านแม่คำมัน",
            subdistrict_en: "Dan Mae Kham Man",
            post_code: "53210",
          },
        ],
      },
      {
        district_th: "อำเภอทองแสนขัน",
        district_en: "Amphoe Thong Saen Khan",
        children: [
          {
            subdistrict_th: "ผักขวง",
            subdistrict_en: "Phak Khuang",
            post_code: "53230",
          },
          {
            subdistrict_th: "บ่อทอง",
            subdistrict_en: "Bo Thong",
            post_code: "53230",
          },
          {
            subdistrict_th: "ป่าคาย",
            subdistrict_en: "Pa Khai",
            post_code: "53230",
          },
          {
            subdistrict_th: "น้ำพี้",
            subdistrict_en: "Nam Phi",
            post_code: "53230",
          },
        ],
      },
    ],
  },
  {
    province_th: "แพร่",
    province_en: "Phrae",
    children: [
      {
        district_th: "อำเภอเมืองแพร่",
        district_en: "Amphoe Mueang Phrae",
        children: [
          {
            subdistrict_th: "ในเวียง",
            subdistrict_en: "Nai Wiang",
            post_code: "54000",
          },
          {
            subdistrict_th: "นาจักร",
            subdistrict_en: "Na Chak",
            post_code: "54000",
          },
          {
            subdistrict_th: "น้ำชำ",
            subdistrict_en: "Nam Cham",
            post_code: "54000",
          },
          {
            subdistrict_th: "ป่าแดง",
            subdistrict_en: "Pa Daeng",
            post_code: "54000",
          },
          {
            subdistrict_th: "ทุ่งโฮ้ง",
            subdistrict_en: "Thung Hong",
            post_code: "54000",
          },
          {
            subdistrict_th: "เหมืองหม้อ",
            subdistrict_en: "Mueang Mo",
            post_code: "54000",
          },
          {
            subdistrict_th: "วังธง",
            subdistrict_en: "Wang Thong",
            post_code: "54000",
          },
          {
            subdistrict_th: "แม่หล่าย",
            subdistrict_en: "Mae Lai",
            post_code: "54000",
          },
          {
            subdistrict_th: "ห้วยม้า",
            subdistrict_en: "Huai Ma",
            post_code: "54000",
          },
          {
            subdistrict_th: "ป่าแมต",
            subdistrict_en: "Pa Maet",
            post_code: "54000",
          },
          {
            subdistrict_th: "บ้านถิ่น",
            subdistrict_en: "Ban Thin",
            post_code: "54000",
          },
          {
            subdistrict_th: "สวนเขื่อน",
            subdistrict_en: "Suan Khuean",
            post_code: "54000",
          },
          {
            subdistrict_th: "วังหงส์",
            subdistrict_en: "Wang Hong",
            post_code: "54000",
          },
          {
            subdistrict_th: "แม่คำมี",
            subdistrict_en: "Mae Kham Mi",
            post_code: "54000",
          },
          {
            subdistrict_th: "ทุ่งกวาว",
            subdistrict_en: "Thung Kwao",
            post_code: "54000",
          },
          {
            subdistrict_th: "ท่าข้าม",
            subdistrict_en: "Tha Kham",
            post_code: "54000",
          },
          {
            subdistrict_th: "แม่ยม",
            subdistrict_en: "Mae Yom",
            post_code: "54000",
          },
          {
            subdistrict_th: "ช่อแฮ",
            subdistrict_en: "Cho Hae",
            post_code: "54000",
          },
          {
            subdistrict_th: "ร่องฟอง",
            subdistrict_en: "Rong Fong",
            post_code: "54000",
          },
          {
            subdistrict_th: "กาญจนา",
            subdistrict_en: "Kanchana",
            post_code: "54000",
          },
        ],
      },
      {
        district_th: "อำเภอร้องกวาง",
        district_en: "Amphoe Rong Kwang",
        children: [
          {
            subdistrict_th: "ร้องกวาง",
            subdistrict_en: "Rong Kwang",
            post_code: "54140",
          },
          {
            subdistrict_th: "ร้องเข็ม",
            subdistrict_en: "Rong Khem",
            post_code: "54140",
          },
          {
            subdistrict_th: "น้ำเลา",
            subdistrict_en: "Nam Lao",
            post_code: "54140",
          },
          {
            subdistrict_th: "บ้านเวียง",
            subdistrict_en: "Ban Wiang",
            post_code: "54140",
          },
          {
            subdistrict_th: "ทุ่งศรี",
            subdistrict_en: "Thung Si",
            post_code: "54140",
          },
          {
            subdistrict_th: "แม่ยางตาล",
            subdistrict_en: "Mae Yang Tan",
            post_code: "54140",
          },
          {
            subdistrict_th: "แม่ยางฮ่อ",
            subdistrict_en: "Mae Yang Ho",
            post_code: "54140",
          },
          {
            subdistrict_th: "ไผ่โทน",
            subdistrict_en: "Phai Thon",
            post_code: "54140",
          },
          {
            subdistrict_th: "ห้วยโรง",
            subdistrict_en: "Huai Rong",
            post_code: "54140",
          },
          {
            subdistrict_th: "แม่ทราย",
            subdistrict_en: "Mae Sai",
            post_code: "54140",
          },
          {
            subdistrict_th: "แม่ยางร้อง",
            subdistrict_en: "Mae Yang Rong",
            post_code: "54140",
          },
        ],
      },
      {
        district_th: "อำเภอลอง",
        district_en: "Amphoe Long",
        children: [
          {
            subdistrict_th: "ห้วยอ้อ",
            subdistrict_en: "Huai O",
            post_code: "54150",
          },
          {
            subdistrict_th: "บ้านปิน",
            subdistrict_en: "Ban Pin",
            post_code: "54150",
          },
          {
            subdistrict_th: "ต้าผามอก",
            subdistrict_en: "Ta Pha Mok",
            post_code: "54150",
          },
          {
            subdistrict_th: "เวียงต้า",
            subdistrict_en: "Wiang Ta",
            post_code: "54150",
          },
          {
            subdistrict_th: "ปากกาง",
            subdistrict_en: "Pak Kang",
            post_code: "54150",
          },
          {
            subdistrict_th: "หัวทุ่ง",
            subdistrict_en: "Hua Thung",
            post_code: "54150",
          },
          {
            subdistrict_th: "ทุ่งแล้ง",
            subdistrict_en: "Thung Laeng",
            post_code: "54150",
          },
          {
            subdistrict_th: "บ่อเหล็กลอง",
            subdistrict_en: "Bo Lek Long",
            post_code: "54150",
          },
          {
            subdistrict_th: "แม่ปาน",
            subdistrict_en: "Mae Pan",
            post_code: "54150",
          },
        ],
      },
      {
        district_th: "อำเภอสูงเม่น",
        district_en: "Amphoe Sung Men",
        children: [
          {
            subdistrict_th: "สูงเม่น",
            subdistrict_en: "Sung Men",
            post_code: "54130",
          },
          {
            subdistrict_th: "น้ำชำ",
            subdistrict_en: "Nam Cham",
            post_code: "54130",
          },
          {
            subdistrict_th: "หัวฝาย",
            subdistrict_en: "Hua Fai",
            post_code: "54130",
          },
          {
            subdistrict_th: "ดอนมูล",
            subdistrict_en: "Don Mun",
            post_code: "54130",
          },
          {
            subdistrict_th: "บ้านเหล่า",
            subdistrict_en: "Ban Lao",
            post_code: "54130",
          },
          {
            subdistrict_th: "บ้านกวาง",
            subdistrict_en: "Ban Kwang",
            post_code: "54130",
          },
          {
            subdistrict_th: "บ้านปง",
            subdistrict_en: "Ban Pong",
            post_code: "54130",
          },
          {
            subdistrict_th: "บ้านกาศ",
            subdistrict_en: "Ban Kat",
            post_code: "54130",
          },
          {
            subdistrict_th: "ร่องกาศ",
            subdistrict_en: "Rong Kat",
            post_code: "54130",
          },
          {
            subdistrict_th: "สบสาย",
            subdistrict_en: "Sop Sai",
            post_code: "54130",
          },
          {
            subdistrict_th: "เวียงทอง",
            subdistrict_en: "Wiang Thong",
            post_code: "54000",
          },
          {
            subdistrict_th: "พระหลวง",
            subdistrict_en: "Phra Luang",
            post_code: "54130",
          },
        ],
      },
      {
        district_th: "อำเภอเด่นชัย",
        district_en: "Amphoe Den Chai",
        children: [
          {
            subdistrict_th: "เด่นชัย",
            subdistrict_en: "Den Chai",
            post_code: "54110",
          },
          {
            subdistrict_th: "แม่จั๊วะ",
            subdistrict_en: "Mae Chua",
            post_code: "54110",
          },
          {
            subdistrict_th: "ไทรย้อย",
            subdistrict_en: "Sai Yoi",
            post_code: "54110",
          },
          {
            subdistrict_th: "ห้วยไร่",
            subdistrict_en: "Huai Rai",
            post_code: "54110",
          },
          {
            subdistrict_th: "ปงป่าหวาย",
            subdistrict_en: "Pong Pa Wai",
            post_code: "54110",
          },
        ],
      },
      {
        district_th: "อำเภอสอง",
        district_en: "Amphoe Song",
        children: [
          {
            subdistrict_th: "บ้านหนุน",
            subdistrict_en: "Ban Nun",
            post_code: "54120",
          },
          {
            subdistrict_th: "บ้านกลาง",
            subdistrict_en: "Ban Klang",
            post_code: "54120",
          },
          {
            subdistrict_th: "ห้วยหม้าย",
            subdistrict_en: "Huai Mai",
            post_code: "54120",
          },
          {
            subdistrict_th: "เตาปูน",
            subdistrict_en: "Tao Pun",
            post_code: "54120",
          },
          {
            subdistrict_th: "หัวเมือง",
            subdistrict_en: "Hua Mueang",
            post_code: "54120",
          },
          {
            subdistrict_th: "สะเอียบ",
            subdistrict_en: "Sa-iap",
            post_code: "54120",
          },
          {
            subdistrict_th: "แดนชุมพล",
            subdistrict_en: "Daen Chumphon",
            post_code: "54120",
          },
          {
            subdistrict_th: "ทุ่งน้าว",
            subdistrict_en: "Thung Nao",
            post_code: "54120",
          },
        ],
      },
      {
        district_th: "อำเภอวังชิ้น",
        district_en: "Amphoe Wang Chin",
        children: [
          {
            subdistrict_th: "วังชิ้น",
            subdistrict_en: "Wang Chin",
            post_code: "54160",
          },
          {
            subdistrict_th: "สรอย",
            subdistrict_en: "Soi",
            post_code: "54160",
          },
          {
            subdistrict_th: "แม่ป้าก",
            subdistrict_en: "Mae Pak",
            post_code: "54160",
          },
          {
            subdistrict_th: "นาพูน",
            subdistrict_en: "Na Phun",
            post_code: "54160",
          },
          {
            subdistrict_th: "แม่พุง",
            subdistrict_en: "Mae Phung",
            post_code: "54160",
          },
          {
            subdistrict_th: "ป่าสัก",
            subdistrict_en: "Pa Sak",
            post_code: "54160",
          },
          {
            subdistrict_th: "แม่เกิ๋ง",
            subdistrict_en: "Mae Koeng",
            post_code: "54160",
          },
        ],
      },
      {
        district_th: "อำเภอหนองม่วงไข่",
        district_en: "Amphoe Nong Muang Khai",
        children: [
          {
            subdistrict_th: "แม่คำมี",
            subdistrict_en: "Mae Kham Mi",
            post_code: "54170",
          },
          {
            subdistrict_th: "หนองม่วงไข่",
            subdistrict_en: "Nong Muang Khai",
            post_code: "54170",
          },
          {
            subdistrict_th: "น้ำรัด",
            subdistrict_en: "Nam Rat",
            post_code: "54170",
          },
          {
            subdistrict_th: "วังหลวง",
            subdistrict_en: "wang Luang",
            post_code: "54170",
          },
          {
            subdistrict_th: "ตำหนักธรรม",
            subdistrict_en: "Tamnak Tham",
            post_code: "54170",
          },
          {
            subdistrict_th: "ทุ่งแค้ว",
            subdistrict_en: "Thung Khaeo",
            post_code: "54170",
          },
        ],
      },
    ],
  },
  {
    province_th: "น่าน",
    province_en: "Nan",
    children: [
      {
        district_th: "อำเภอเมืองน่าน",
        district_en: "Amphoe Mueang Nan",
        children: [
          {
            subdistrict_th: "ในเวียง",
            subdistrict_en: "Nai Wiang",
            post_code: "55000",
          },
          {
            subdistrict_th: "บ่อ",
            subdistrict_en: "Bo",
            post_code: "55000",
          },
          {
            subdistrict_th: "ผาสิงห์",
            subdistrict_en: "Pha Sing",
            post_code: "55000",
          },
          {
            subdistrict_th: "ไชยสถาน",
            subdistrict_en: "Chai Sathan",
            post_code: "55000",
          },
          {
            subdistrict_th: "ถืมตอง",
            subdistrict_en: "Thuem Tong",
            post_code: "55000",
          },
          {
            subdistrict_th: "เรือง",
            subdistrict_en: "Rueang",
            post_code: "55000",
          },
          {
            subdistrict_th: "นาซาว",
            subdistrict_en: "Na Sao",
            post_code: "55000",
          },
          {
            subdistrict_th: "ดู่ใต้",
            subdistrict_en: "Du Tai",
            post_code: "55000",
          },
          {
            subdistrict_th: "กองควาย",
            subdistrict_en: "Kong Khwai",
            post_code: "55000",
          },
          {
            subdistrict_th: "บ่อสวก",
            subdistrict_en: "Bo Suak",
            post_code: "55000",
          },
          {
            subdistrict_th: "สะเนียน",
            subdistrict_en: "Sanian",
            post_code: "55000",
          },
        ],
      },
      {
        district_th: "อำเภอแม่จริม",
        district_en: "Amphoe Mae Charim",
        children: [
          {
            subdistrict_th: "หนองแดง",
            subdistrict_en: "Nong Daeng",
            post_code: "55170",
          },
          {
            subdistrict_th: "หมอเมือง",
            subdistrict_en: "Mo Mueang",
            post_code: "55170",
          },
          {
            subdistrict_th: "น้ำพาง",
            subdistrict_en: "Nam Phang",
            post_code: "55170",
          },
          {
            subdistrict_th: "น้ำปาย",
            subdistrict_en: "Nam Pai",
            post_code: "55170",
          },
          {
            subdistrict_th: "แม่จริม",
            subdistrict_en: "Mae Charim",
            post_code: "55170",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านหลวง",
        district_en: "Amphoe Ban Luang",
        children: [
          {
            subdistrict_th: "บ้านฟ้า",
            subdistrict_en: "Ban Fa",
            post_code: "55190",
          },
          {
            subdistrict_th: "ป่าคาหลวง",
            subdistrict_en: "Pa Kha Luang",
            post_code: "55190",
          },
          {
            subdistrict_th: "สวด",
            subdistrict_en: "Suat",
            post_code: "55190",
          },
          {
            subdistrict_th: "บ้านพี้",
            subdistrict_en: "Ban Phi",
            post_code: "55190",
          },
        ],
      },
      {
        district_th: "อำเภอนาน้อย",
        district_en: "Amphoe Na Noi",
        children: [
          {
            subdistrict_th: "นาน้อย",
            subdistrict_en: "Na Noi",
            post_code: "55150",
          },
          {
            subdistrict_th: "เชียงของ",
            subdistrict_en: "Chiang Khong",
            post_code: "55150",
          },
          {
            subdistrict_th: "ศรีษะเกษ",
            subdistrict_en: "Sisa Ket",
            post_code: "55150",
          },
          {
            subdistrict_th: "สถาน",
            subdistrict_en: "Sathan",
            post_code: "55150",
          },
          {
            subdistrict_th: "สันทะ",
            subdistrict_en: "Santha",
            post_code: "55150",
          },
          {
            subdistrict_th: "บัวใหญ่",
            subdistrict_en: "Bua Yai",
            post_code: "55150",
          },
          {
            subdistrict_th: "น้ำตก",
            subdistrict_en: "Nam Tok",
            post_code: "55150",
          },
        ],
      },
      {
        district_th: "อำเภอปัว",
        district_en: "Amphoe Pua",
        children: [
          {
            subdistrict_th: "ปัว",
            subdistrict_en: "Pua",
            post_code: "55120",
          },
          {
            subdistrict_th: "แงง",
            subdistrict_en: "Ngaeng",
            post_code: "55120",
          },
          {
            subdistrict_th: "สถาน",
            subdistrict_en: "Sathan",
            post_code: "55120",
          },
          {
            subdistrict_th: "ศิลาแลง",
            subdistrict_en: "Sila Laeng",
            post_code: "55120",
          },
          {
            subdistrict_th: "ศิลาเพชร",
            subdistrict_en: "Sila Phet",
            post_code: "55120",
          },
          {
            subdistrict_th: "อวน",
            subdistrict_en: "Uan",
            post_code: "55120",
          },
          {
            subdistrict_th: "ไชยวัฒนา",
            subdistrict_en: "Chai Watthana",
            post_code: "55120",
          },
          {
            subdistrict_th: "เจดีย์ชัย",
            subdistrict_en: "Chedi Chai",
            post_code: "55120",
          },
          {
            subdistrict_th: "ภูคา",
            subdistrict_en: "Phu Kha",
            post_code: "55120",
          },
          {
            subdistrict_th: "สกาด",
            subdistrict_en: "Sakat",
            post_code: "55120",
          },
          {
            subdistrict_th: "ป่ากลาง",
            subdistrict_en: "Pa Klang",
            post_code: "55120",
          },
          {
            subdistrict_th: "วรนคร",
            subdistrict_en: "Woranakhon",
            post_code: "55120",
          },
        ],
      },
      {
        district_th: "อำเภอท่าวังผา",
        district_en: "Amphoe Tha Wang Pha",
        children: [
          {
            subdistrict_th: "ริม",
            subdistrict_en: "Rim",
            post_code: "55140",
          },
          {
            subdistrict_th: "ป่าคา",
            subdistrict_en: "Pa Kha",
            post_code: "55140",
          },
          {
            subdistrict_th: "ผาตอ",
            subdistrict_en: "Pha To",
            post_code: "55140",
          },
          {
            subdistrict_th: "ยม",
            subdistrict_en: "Yom",
            post_code: "55140",
          },
          {
            subdistrict_th: "ตาลชุม",
            subdistrict_en: "Tan Chum",
            post_code: "55140",
          },
          {
            subdistrict_th: "ศรีภูมิ",
            subdistrict_en: "Si Phum",
            post_code: "55140",
          },
          {
            subdistrict_th: "จอมพระ",
            subdistrict_en: "Chom Phra",
            post_code: "55140",
          },
          {
            subdistrict_th: "แสนทอง",
            subdistrict_en: "Saen Thong",
            post_code: "55140",
          },
          {
            subdistrict_th: "ท่าวังผา",
            subdistrict_en: "Tha Wang Pha",
            post_code: "55140",
          },
          {
            subdistrict_th: "ผาทอง",
            subdistrict_en: "Pha Thong",
            post_code: "55140",
          },
        ],
      },
      {
        district_th: "อำเภอเวียงสา",
        district_en: "Amphoe Wiang Sa",
        children: [
          {
            subdistrict_th: "กลางเวียง",
            subdistrict_en: "Klang Wiang",
            post_code: "55110",
          },
          {
            subdistrict_th: "ขึ่ง",
            subdistrict_en: "Khueng",
            post_code: "55110",
          },
          {
            subdistrict_th: "ไหล่น่าน",
            subdistrict_en: "Lai Nan",
            post_code: "55110",
          },
          {
            subdistrict_th: "ตาลชุม",
            subdistrict_en: "Tan Chum",
            post_code: "55110",
          },
          {
            subdistrict_th: "นาเหลือง",
            subdistrict_en: "Na Lueang",
            post_code: "55110",
          },
          {
            subdistrict_th: "ส้าน",
            subdistrict_en: "San",
            post_code: "55110",
          },
          {
            subdistrict_th: "น้ำมวบ",
            subdistrict_en: "Nam Muap",
            post_code: "55110",
          },
          {
            subdistrict_th: "น้ำปั้ว",
            subdistrict_en: "Nam Pua",
            post_code: "55110",
          },
          {
            subdistrict_th: "ยาบหัวนา",
            subdistrict_en: "Yap Hua Na",
            post_code: "55110",
          },
          {
            subdistrict_th: "ปงสนุก",
            subdistrict_en: "Pong Sanuk",
            post_code: "55110",
          },
          {
            subdistrict_th: "อ่ายนาไลย",
            subdistrict_en: "Ai Na Lai",
            post_code: "55110",
          },
          {
            subdistrict_th: "ส้านนาหนองใหม่",
            subdistrict_en: "San Na Nong Mai",
            post_code: "55110",
          },
          {
            subdistrict_th: "แม่ขะนิง",
            subdistrict_en: "Mae Khaning",
            post_code: "55110",
          },
          {
            subdistrict_th: "แม่สาคร",
            subdistrict_en: "Mae Sakhon",
            post_code: "55110",
          },
          {
            subdistrict_th: "จอมจันทร์",
            subdistrict_en: "Chom Chan",
            post_code: "55110",
          },
          {
            subdistrict_th: "แม่สา",
            subdistrict_en: "Mae Sa",
            post_code: "55110",
          },
          {
            subdistrict_th: "ทุ่งศรีทอง",
            subdistrict_en: "Thung Si Thong",
            post_code: "55110",
          },
        ],
      },
      {
        district_th: "อำเภอทุ่งช้าง",
        district_en: "Amphoe Thung Chang",
        children: [
          {
            subdistrict_th: "ปอน",
            subdistrict_en: "Pon",
            post_code: "55130",
          },
          {
            subdistrict_th: "งอบ",
            subdistrict_en: "Ngop",
            post_code: "55130",
          },
          {
            subdistrict_th: "และ",
            subdistrict_en: "Lae",
            post_code: "55130",
          },
          {
            subdistrict_th: "ทุ่งช้าง",
            subdistrict_en: "Thung Chang",
            post_code: "55130",
          },
        ],
      },
      {
        district_th: "อำเภอเชียงกลาง",
        district_en: "Amphoe Chiang Klang",
        children: [
          {
            subdistrict_th: "เชียงกลาง",
            subdistrict_en: "Chiang Klang",
            post_code: "55160",
          },
          {
            subdistrict_th: "เปือ",
            subdistrict_en: "Puea",
            post_code: "55160",
          },
          {
            subdistrict_th: "เชียงคาน",
            subdistrict_en: "Chiang Khan",
            post_code: "55160",
          },
          {
            subdistrict_th: "พระธาตุ",
            subdistrict_en: "Phra That",
            post_code: "55160",
          },
          {
            subdistrict_th: "พญาแก้ว",
            subdistrict_en: "Phaya Kaeo",
            post_code: "55160",
          },
          {
            subdistrict_th: "พระพุทธบาท",
            subdistrict_en: "Phra Phutthabat",
            post_code: "55160",
          },
        ],
      },
      {
        district_th: "อำเภอนาหมื่น",
        district_en: "Amphoe Na Muen",
        children: [
          {
            subdistrict_th: "นาทะนุง",
            subdistrict_en: "Na Thanung",
            post_code: "55180",
          },
          {
            subdistrict_th: "บ่อแก้ว",
            subdistrict_en: "Bo Kaeo",
            post_code: "55180",
          },
          {
            subdistrict_th: "เมืองลี",
            subdistrict_en: "Mueang Li",
            post_code: "55180",
          },
          {
            subdistrict_th: "ปิงหลวง",
            subdistrict_en: "Ping Luang",
            post_code: "55180",
          },
        ],
      },
      {
        district_th: "อำเภอสันติสุข",
        district_en: "Amphoe Santi Suk",
        children: [
          {
            subdistrict_th: "ดู่พงษ์",
            subdistrict_en: "Du Phong",
            post_code: "55210",
          },
          {
            subdistrict_th: "ป่าแลวหลวง",
            subdistrict_en: "Pa Laeo Luang",
            post_code: "55210",
          },
          {
            subdistrict_th: "พงษ์",
            subdistrict_en: "Phong",
            post_code: "55210",
          },
        ],
      },
      {
        district_th: "อำเภอบ่อเกลือ",
        district_en: "Amphoe Bo Kluea",
        children: [
          {
            subdistrict_th: "บ่อเกลือเหนือ",
            subdistrict_en: "Bo Kluea Nuea",
            post_code: "55220",
          },
          {
            subdistrict_th: "บ่อเกลือใต้",
            subdistrict_en: "Bo Kluea Tai",
            post_code: "55220",
          },
          {
            subdistrict_th: "ภูฟ้า",
            subdistrict_en: "Phu Fa",
            post_code: "55220",
          },
          {
            subdistrict_th: "ดงพญา",
            subdistrict_en: "Dong Phaya",
            post_code: "55220",
          },
        ],
      },
      {
        district_th: "อำเภอสองแคว",
        district_en: "Amphoe Song Khwae",
        children: [
          {
            subdistrict_th: "นาไร่หลวง",
            subdistrict_en: "Na Rai Luang",
            post_code: "55160",
          },
          {
            subdistrict_th: "ชนแดน",
            subdistrict_en: "Chon Daen",
            post_code: "55160",
          },
          {
            subdistrict_th: "ยอด",
            subdistrict_en: "Yot",
            post_code: "55160",
          },
        ],
      },
      {
        district_th: "อำเภอภูเพียง",
        district_en: "Amphoe Phu Phiang",
        children: [
          {
            subdistrict_th: "ม่วงตึ๊ด",
            subdistrict_en: "Muang Tuet",
            post_code: "55000",
          },
          {
            subdistrict_th: "นาปัง",
            subdistrict_en: "Na Pang",
            post_code: "55000",
          },
          {
            subdistrict_th: "น้ำแก่น",
            subdistrict_en: "Nam Kaen",
            post_code: "55000",
          },
          {
            subdistrict_th: "น้ำเกี๋ยน",
            subdistrict_en: "Nam Kian",
            post_code: "55000",
          },
          {
            subdistrict_th: "เมืองจัง",
            subdistrict_en: "Mueang Chang",
            post_code: "55000",
          },
          {
            subdistrict_th: "ท่าน้าว",
            subdistrict_en: "Tha Nao",
            post_code: "55000",
          },
          {
            subdistrict_th: "ฝายแก้ว",
            subdistrict_en: "Fai Kaeo",
            post_code: "55000",
          },
        ],
      },
      {
        district_th: "อำเภอเฉลิมพระเกียรติ",
        district_en: "Amphoe Chaloem Phra Kiat",
        children: [
          {
            subdistrict_th: "เขาดินพัฒนา",
            subdistrict_en: "Khao Din Phatthana",
            post_code: "18000",
          },
          {
            subdistrict_th: "บ้านแก้ง",
            subdistrict_en: "Ban Kaeng",
            post_code: "18000",
          },
          {
            subdistrict_th: "ผึ้งรวง",
            subdistrict_en: "Phueng Ruang",
            post_code: "18000",
          },
          {
            subdistrict_th: "พุแค",
            subdistrict_en: "Phu Khae",
            post_code: "18240",
          },
          {
            subdistrict_th: "ห้วยบง",
            subdistrict_en: "Huai Bong",
            post_code: "18000",
          },
          {
            subdistrict_th: "หน้าพระลาน",
            subdistrict_en: "Na Phra Lan",
            post_code: "18240",
          },
          {
            subdistrict_th: "ช้างทอง",
            subdistrict_en: "Chang Thong",
            post_code: "30230",
          },
          {
            subdistrict_th: "ท่าช้าง",
            subdistrict_en: "Tha Chang",
            post_code: "30230",
          },
          {
            subdistrict_th: "พระพุทธ",
            subdistrict_en: "Phra Phut",
            post_code: "30230",
          },
          {
            subdistrict_th: "หนองงูเหลือม",
            subdistrict_en: "Nong Ngu Lueam",
            post_code: "30000",
          },
          {
            subdistrict_th: "หนองยาง",
            subdistrict_en: "Nong Yang",
            post_code: "30230",
          },
          {
            subdistrict_th: "เจริญสุข",
            subdistrict_en: "Charoen Suk",
            post_code: "31110",
          },
          {
            subdistrict_th: "ตาเป๊ก",
            subdistrict_en: "Ta Pek",
            post_code: "31110",
          },
          {
            subdistrict_th: "อีสานเขต",
            subdistrict_en: "Isan Khet",
            post_code: "31110",
          },
          {
            subdistrict_th: "ถาวร",
            subdistrict_en: "Thawon",
            post_code: "31170",
          },
          {
            subdistrict_th: "ยายแย้มวัฒนา",
            subdistrict_en: "Yai Yaem Watthana",
            post_code: "31170",
          },
          {
            subdistrict_th: "ห้วยโก๋น",
            subdistrict_en: "Huai Kon",
            post_code: "55130",
          },
          {
            subdistrict_th: "ขุนน่าน",
            subdistrict_en: "Khun Nan",
            post_code: "55220",
          },
          {
            subdistrict_th: "เชียรเขา",
            subdistrict_en: "Chian Khao",
            post_code: "80190",
          },
          {
            subdistrict_th: "ดอนตรอ",
            subdistrict_en: "Don Tro",
            post_code: "80290",
          },
          {
            subdistrict_th: "สวนหลวง",
            subdistrict_en: "Suan Luang",
            post_code: "80190",
          },
          {
            subdistrict_th: "ทางพูน",
            subdistrict_en: "Thang Phun",
            post_code: "80290",
          },
        ],
      },
    ],
  },
  {
    province_th: "พะเยา",
    province_en: "Phayao",
    children: [
      {
        district_th: "อำเภอเมืองพะเยา",
        district_en: "Amphoe Mueang Phayao",
        children: [
          {
            subdistrict_th: "เวียง",
            subdistrict_en: "Wiang",
            post_code: "56000",
          },
          {
            subdistrict_th: "แม่ต๋ำ",
            subdistrict_en: "Mae Tam",
            post_code: "56000",
          },
          {
            subdistrict_th: "แม่นาเรือ",
            subdistrict_en: "Mae Na Ruea",
            post_code: "56000",
          },
          {
            subdistrict_th: "บ้านตุ่น",
            subdistrict_en: "Ban Tun",
            post_code: "56000",
          },
          {
            subdistrict_th: "บ้านต๊ำ",
            subdistrict_en: "Ban Tam",
            post_code: "56000",
          },
          {
            subdistrict_th: "บ้านต๋อม",
            subdistrict_en: "Ban Tom",
            post_code: "56000",
          },
          {
            subdistrict_th: "แม่ปืม",
            subdistrict_en: "Mae Puem",
            post_code: "56000",
          },
          {
            subdistrict_th: "แม่กา",
            subdistrict_en: "Mae Ka",
            post_code: "56000",
          },
          {
            subdistrict_th: "บ้านใหม่",
            subdistrict_en: "Ban Mai",
            post_code: "56000",
          },
          {
            subdistrict_th: "จำป่าหวาย",
            subdistrict_en: "Cham Pa Wai",
            post_code: "56000",
          },
          {
            subdistrict_th: "ท่าวังทอง",
            subdistrict_en: "Tha Wang Thong",
            post_code: "56000",
          },
          {
            subdistrict_th: "แม่ใส",
            subdistrict_en: "Mae Sai",
            post_code: "56000",
          },
          {
            subdistrict_th: "บ้านสาง",
            subdistrict_en: "Ban Sang",
            post_code: "56000",
          },
          {
            subdistrict_th: "ท่าจำปี",
            subdistrict_en: "Tha Champi",
            post_code: "56000",
          },
          {
            subdistrict_th: "สันป่าม่วง",
            subdistrict_en: "San Pa Muang",
            post_code: "56000",
          },
        ],
      },
      {
        district_th: "อำเภอจุน",
        district_en: "Amphoe Chun",
        children: [
          {
            subdistrict_th: "ห้วยข้าวก่ำ",
            subdistrict_en: "Huai Khao Kam",
            post_code: "56150",
          },
          {
            subdistrict_th: "จุน",
            subdistrict_en: "Chun",
            post_code: "56150",
          },
          {
            subdistrict_th: "ลอ",
            subdistrict_en: "Lo",
            post_code: "56150",
          },
          {
            subdistrict_th: "หงส์หิน",
            subdistrict_en: "Hong Hin",
            post_code: "56150",
          },
          {
            subdistrict_th: "ทุ่งรวงทอง",
            subdistrict_en: "Thung Ruang Thong",
            post_code: "56150",
          },
          {
            subdistrict_th: "ห้วยยางขาม",
            subdistrict_en: "Huai Yang Kham",
            post_code: "56150",
          },
          {
            subdistrict_th: "พระธาตุขิงแกง",
            subdistrict_en: "Phra That Khing Kaeng",
            post_code: "56150",
          },
        ],
      },
      {
        district_th: "อำเภอเชียงคำ",
        district_en: "Amphoe Chiang Kham",
        children: [
          {
            subdistrict_th: "หย่วน",
            subdistrict_en: "Yuan",
            post_code: "56110",
          },
          {
            subdistrict_th: "น้ำแวน",
            subdistrict_en: "Nam Waen",
            post_code: "56110",
          },
          {
            subdistrict_th: "เวียง",
            subdistrict_en: "Wiang",
            post_code: "56110",
          },
          {
            subdistrict_th: "ฝายกวาง",
            subdistrict_en: "Fai Kwang",
            post_code: "56110",
          },
          {
            subdistrict_th: "เจดีย์คำ",
            subdistrict_en: "Chedi Kham",
            post_code: "56110",
          },
          {
            subdistrict_th: "ร่มเย็น",
            subdistrict_en: "Rom Yen",
            post_code: "56110",
          },
          {
            subdistrict_th: "เชียงบาน",
            subdistrict_en: "Chiang Ban",
            post_code: "56110",
          },
          {
            subdistrict_th: "แม่ลาว",
            subdistrict_en: "Mae Lao",
            post_code: "56110",
          },
          {
            subdistrict_th: "อ่างทอง",
            subdistrict_en: "Ang Thong",
            post_code: "56110",
          },
          {
            subdistrict_th: "ทุ่งผาสุข",
            subdistrict_en: "Thung Pha Suk",
            post_code: "56110",
          },
        ],
      },
      {
        district_th: "อำเภอเชียงม่วน",
        district_en: "Amphoe Chiang Muan",
        children: [
          {
            subdistrict_th: "เชียงม่วน",
            subdistrict_en: "Chiang Muan",
            post_code: "56160",
          },
          {
            subdistrict_th: "บ้านมาง",
            subdistrict_en: "Ban Mang",
            post_code: "56160",
          },
          {
            subdistrict_th: "สระ",
            subdistrict_en: "Sa",
            post_code: "56160",
          },
        ],
      },
      {
        district_th: "อำเภอดอกคำใต้",
        district_en: "Amphoe Dok Khamtai",
        children: [
          {
            subdistrict_th: "ดอกคำใต้",
            subdistrict_en: "Dok Kham Tai",
            post_code: "56120",
          },
          {
            subdistrict_th: "ดอนศรีชุม",
            subdistrict_en: "Don Si Chum",
            post_code: "56120",
          },
          {
            subdistrict_th: "บ้านถ้ำ",
            subdistrict_en: "Ban Tham",
            post_code: "56120",
          },
          {
            subdistrict_th: "บ้านปิน",
            subdistrict_en: "Ban Pin",
            post_code: "56120",
          },
          {
            subdistrict_th: "ห้วยลาน",
            subdistrict_en: "Huai Lan",
            post_code: "56120",
          },
          {
            subdistrict_th: "สันโค้ง",
            subdistrict_en: "San Khong",
            post_code: "56120",
          },
          {
            subdistrict_th: "ป่าซาง",
            subdistrict_en: "Pa Sang",
            post_code: "56120",
          },
          {
            subdistrict_th: "หนองหล่ม",
            subdistrict_en: "Nong Lom",
            post_code: "56120",
          },
          {
            subdistrict_th: "ดงสุวรรณ",
            subdistrict_en: "Dong Suwan",
            post_code: "56120",
          },
          {
            subdistrict_th: "บุญเกิด",
            subdistrict_en: "Bun Koet",
            post_code: "56120",
          },
          {
            subdistrict_th: "สว่างอารมณ์",
            subdistrict_en: "Sawang Arom",
            post_code: "56120",
          },
          {
            subdistrict_th: "คือเวียง",
            subdistrict_en: "Khue Wiang",
            post_code: "56120",
          },
        ],
      },
      {
        district_th: "อำเภอปง",
        district_en: "Amphoe Pong",
        children: [
          {
            subdistrict_th: "ปง",
            subdistrict_en: "Pong",
            post_code: "56140",
          },
          {
            subdistrict_th: "ควร",
            subdistrict_en: "Khuan",
            post_code: "56140",
          },
          {
            subdistrict_th: "ออย",
            subdistrict_en: "Oi",
            post_code: "56140",
          },
          {
            subdistrict_th: "งิม",
            subdistrict_en: "Ngim",
            post_code: "56140",
          },
          {
            subdistrict_th: "ผาช้างน้อย",
            subdistrict_en: "Pha Chang Noi",
            post_code: "56140",
          },
          {
            subdistrict_th: "นาปรัง",
            subdistrict_en: "Na Prang",
            post_code: "56140",
          },
          {
            subdistrict_th: "ขุนควร",
            subdistrict_en: "Khun Khuan",
            post_code: "56140",
          },
        ],
      },
      {
        district_th: "อำเภอแม่ใจ",
        district_en: "Amphoe Mae Chai",
        children: [
          {
            subdistrict_th: "แม่ใจ",
            subdistrict_en: "Mae Chai",
            post_code: "56130",
          },
          {
            subdistrict_th: "ศรีถ้อย",
            subdistrict_en: "Si Thoi",
            post_code: "56130",
          },
          {
            subdistrict_th: "แม่สุก",
            subdistrict_en: "Mae Suk",
            post_code: "56130",
          },
          {
            subdistrict_th: "ป่าแฝก",
            subdistrict_en: "Pa Faek",
            post_code: "56130",
          },
          {
            subdistrict_th: "บ้านเหล่า",
            subdistrict_en: "Ban Lao",
            post_code: "56130",
          },
          {
            subdistrict_th: "เจริญราษฎร์",
            subdistrict_en: "Charoen Rat",
            post_code: "56130",
          },
        ],
      },
      {
        district_th: "อำเภอภูซาง",
        district_en: "Amphoe Phu Sang",
        children: [
          {
            subdistrict_th: "ภูซาง",
            subdistrict_en: "Phu Sang",
            post_code: "56110",
          },
          {
            subdistrict_th: "ป่าสัก",
            subdistrict_en: "Pa Sak",
            post_code: "56110",
          },
          {
            subdistrict_th: "ทุ่งกล้วย",
            subdistrict_en: "Thung Kluai",
            post_code: "56110",
          },
          {
            subdistrict_th: "เชียงแรง",
            subdistrict_en: "Chiang Raeng",
            post_code: "56110",
          },
          {
            subdistrict_th: "สบบง",
            subdistrict_en: "Sop Bong",
            post_code: "56110",
          },
        ],
      },
      {
        district_th: "อำเภอภูกามยาว",
        district_en: "Amphoe Phu Kamyao",
        children: [
          {
            subdistrict_th: "ห้วยแก้ว",
            subdistrict_en: "Huai Kaeo",
            post_code: "56000",
          },
          {
            subdistrict_th: "ดงเจน",
            subdistrict_en: "Dong Chen",
            post_code: "56000",
          },
          {
            subdistrict_th: "แม่อิง",
            subdistrict_en: "Mae Ing",
            post_code: "56000",
          },
        ],
      },
    ],
  },
  {
    province_th: "เชียงราย",
    province_en: "Chiang Rai",
    children: [
      {
        district_th: "อำเภอเมืองเชียงราย",
        district_en: "Amphoe Mueang Chiang Rai",
        children: [
          {
            subdistrict_th: "เวียง",
            subdistrict_en: "Wiang",
            post_code: "57000",
          },
          {
            subdistrict_th: "รอบเวียง",
            subdistrict_en: "Rop Wiang",
            post_code: "57000",
          },
          {
            subdistrict_th: "บ้านดู่",
            subdistrict_en: "Ban Du",
            post_code: "57100",
          },
          {
            subdistrict_th: "นางแล",
            subdistrict_en: "Nang Lae",
            post_code: "57100",
          },
          {
            subdistrict_th: "แม่ข้าวต้ม",
            subdistrict_en: "Mae Khao Tom",
            post_code: "57100",
          },
          {
            subdistrict_th: "แม่ยาว",
            subdistrict_en: "Mae Yao",
            post_code: "57100",
          },
          {
            subdistrict_th: "สันทราย",
            subdistrict_en: "San Sai",
            post_code: "57000",
          },
          {
            subdistrict_th: "แม่กรณ์",
            subdistrict_en: "Mae Kon",
            post_code: "57000",
          },
          {
            subdistrict_th: "ห้วยชมภู",
            subdistrict_en: "Huai Chomphu",
            post_code: "57000",
          },
          {
            subdistrict_th: "ห้วยสัก",
            subdistrict_en: "Huai Sak",
            post_code: "57000",
          },
          {
            subdistrict_th: "ริมกก",
            subdistrict_en: "Rim Kok",
            post_code: "57100",
          },
          {
            subdistrict_th: "ดอยลาน",
            subdistrict_en: "Doi Lan",
            post_code: "57000",
          },
          {
            subdistrict_th: "ป่าอ้อดอนชัย",
            subdistrict_en: "Pa O Don Chai",
            post_code: "57000",
          },
          {
            subdistrict_th: "ท่าสาย",
            subdistrict_en: "Tha Sai",
            post_code: "57000",
          },
          {
            subdistrict_th: "ดอยฮาง",
            subdistrict_en: "Doi Hang",
            post_code: "57000",
          },
          {
            subdistrict_th: "ท่าสุด",
            subdistrict_en: "Tha Sut",
            post_code: "57100",
          },
        ],
      },
      {
        district_th: "อำเภอเวียงชัย",
        district_en: "Amphoe Wiang Chai",
        children: [
          {
            subdistrict_th: "เวียงชัย",
            subdistrict_en: "Wiang Chai",
            post_code: "57210",
          },
          {
            subdistrict_th: "ผางาม",
            subdistrict_en: "Pha Ngam",
            post_code: "57210",
          },
          {
            subdistrict_th: "เวียงเหนือ",
            subdistrict_en: "Wiang Nuea",
            post_code: "57210",
          },
          {
            subdistrict_th: "ดอนศิลา",
            subdistrict_en: "Don Sila",
            post_code: "57210",
          },
          {
            subdistrict_th: "เมืองชุม",
            subdistrict_en: "Mueang Chum",
            post_code: "57210",
          },
        ],
      },
      {
        district_th: "อำเภอเชียงของ",
        district_en: "Amphoe Chiang Khong",
        children: [
          {
            subdistrict_th: "เวียง",
            subdistrict_en: "Wiang",
            post_code: "57140",
          },
          {
            subdistrict_th: "สถาน",
            subdistrict_en: "Sathan",
            post_code: "57140",
          },
          {
            subdistrict_th: "ครึ่ง",
            subdistrict_en: "Khrueng",
            post_code: "57140",
          },
          {
            subdistrict_th: "บุญเรือง",
            subdistrict_en: "Bun Rueang",
            post_code: "57140",
          },
          {
            subdistrict_th: "ห้วยซ้อ",
            subdistrict_en: "Huai So",
            post_code: "57140",
          },
          {
            subdistrict_th: "ศรีดอนชัย",
            subdistrict_en: "Si Don Chai",
            post_code: "57140",
          },
          {
            subdistrict_th: "ริมโขง",
            subdistrict_en: "Rim Khong",
            post_code: "57140",
          },
        ],
      },
      {
        district_th: "อำเภอเทิง",
        district_en: "Amphoe Thoeng",
        children: [
          {
            subdistrict_th: "เวียง",
            subdistrict_en: "Wiang",
            post_code: "57160",
          },
          {
            subdistrict_th: "งิ้ว",
            subdistrict_en: "Ngio",
            post_code: "57160",
          },
          {
            subdistrict_th: "ปล้อง",
            subdistrict_en: "Plong",
            post_code: "57230",
          },
          {
            subdistrict_th: "แม่ลอย",
            subdistrict_en: "Mae Loi",
            post_code: "57230",
          },
          {
            subdistrict_th: "เชียงเคี่ยน",
            subdistrict_en: "Chiang Khian",
            post_code: "57230",
          },
          {
            subdistrict_th: "ตับเต่า",
            subdistrict_en: "Tap Tao",
            post_code: "57160",
          },
          {
            subdistrict_th: "หงาว",
            subdistrict_en: "Ngao",
            post_code: "57160",
          },
          {
            subdistrict_th: "สันทรายงาม",
            subdistrict_en: "San Sai Ngam",
            post_code: "57160",
          },
          {
            subdistrict_th: "ศรีดอนไชย",
            subdistrict_en: "Si Don Chai",
            post_code: "57230",
          },
          {
            subdistrict_th: "หนองแรด",
            subdistrict_en: "Nong Raet",
            post_code: "57230",
          },
        ],
      },
      {
        district_th: "อำเภอพาน",
        district_en: "Amphoe Phan",
        children: [
          {
            subdistrict_th: "สันมะเค็ด",
            subdistrict_en: "San Makhet",
            post_code: "57120",
          },
          {
            subdistrict_th: "แม่อ้อ",
            subdistrict_en: "Mae O",
            post_code: "57120",
          },
          {
            subdistrict_th: "ธารทอง",
            subdistrict_en: "Than Thong",
            post_code: "57250",
          },
          {
            subdistrict_th: "สันติสุข",
            subdistrict_en: "Santi Suk",
            post_code: "57120",
          },
          {
            subdistrict_th: "ดอยงาม",
            subdistrict_en: "Doi Ngam",
            post_code: "57120",
          },
          {
            subdistrict_th: "หัวง้ม",
            subdistrict_en: "Hua Ngom",
            post_code: "57120",
          },
          {
            subdistrict_th: "เจริญเมือง",
            subdistrict_en: "Charoen Mueang",
            post_code: "57120",
          },
          {
            subdistrict_th: "ป่าหุ่ง",
            subdistrict_en: "Pa Hung",
            post_code: "57120",
          },
          {
            subdistrict_th: "ม่วงคำ",
            subdistrict_en: "Muang Kham",
            post_code: "57120",
          },
          {
            subdistrict_th: "ทรายขาว",
            subdistrict_en: "Sai Khao",
            post_code: "57120",
          },
          {
            subdistrict_th: "สันกลาง",
            subdistrict_en: "San Klang",
            post_code: "57120",
          },
          {
            subdistrict_th: "แม่เย็น",
            subdistrict_en: "Mae Yen",
            post_code: "57280",
          },
          {
            subdistrict_th: "เมืองพาน",
            subdistrict_en: "Mueang Phan",
            post_code: "57120",
          },
          {
            subdistrict_th: "ทานตะวัน",
            subdistrict_en: "Than Tawan",
            post_code: "57280",
          },
          {
            subdistrict_th: "เวียงห้าว",
            subdistrict_en: "Wiang Hao",
            post_code: "57120",
          },
        ],
      },
      {
        district_th: "อำเภอป่าแดด",
        district_en: "Amphoe Pa Daet",
        children: [
          {
            subdistrict_th: "ป่าแดด",
            subdistrict_en: "Pa Daet",
            post_code: "57190",
          },
          {
            subdistrict_th: "ป่าแงะ",
            subdistrict_en: "Pa Ngae",
            post_code: "57190",
          },
          {
            subdistrict_th: "สันมะค่า",
            subdistrict_en: "San Makha",
            post_code: "57190",
          },
          {
            subdistrict_th: "โรงช้าง",
            subdistrict_en: "Rong Chang",
            post_code: "57190",
          },
          {
            subdistrict_th: "ศรีโพธิ์เงิน",
            subdistrict_en: "Si Pho Ngoen",
            post_code: "57190",
          },
        ],
      },
      {
        district_th: "อำเภอแม่จัน",
        district_en: "Amphoe Mae Chan",
        children: [
          {
            subdistrict_th: "แม่จัน",
            subdistrict_en: "Mae Chan",
            post_code: "57110",
          },
          {
            subdistrict_th: "จันจว้า",
            subdistrict_en: "Chan Chwa",
            post_code: "57270",
          },
          {
            subdistrict_th: "แม่คำ",
            subdistrict_en: "Mae Kham",
            post_code: "57240",
          },
          {
            subdistrict_th: "ป่าซาง",
            subdistrict_en: "Pa Sang",
            post_code: "57110",
          },
          {
            subdistrict_th: "สันทราย",
            subdistrict_en: "San Sai",
            post_code: "57110",
          },
          {
            subdistrict_th: "ท่าข้าวเปลือก",
            subdistrict_en: "Tha Khao Plueak",
            post_code: "57110",
          },
          {
            subdistrict_th: "ป่าตึง",
            subdistrict_en: "Pa Tueng",
            post_code: "57110",
          },
          {
            subdistrict_th: "แม่ไร่",
            subdistrict_en: "Mae Rai",
            post_code: "57240",
          },
          {
            subdistrict_th: "ศรีค้ำ",
            subdistrict_en: "Si Kham",
            post_code: "57110",
          },
          {
            subdistrict_th: "จันจว้าใต้",
            subdistrict_en: "Chan Chwa Tai",
            post_code: "57270",
          },
          {
            subdistrict_th: "จอมสวรรค์",
            subdistrict_en: "Chom Sawan",
            post_code: "57110",
          },
        ],
      },
      {
        district_th: "อำเภอเชียงแสน",
        district_en: "Amphoe Chiang Saen",
        children: [
          {
            subdistrict_th: "เวียง",
            subdistrict_en: "Wiang",
            post_code: "57150",
          },
          {
            subdistrict_th: "ป่าสัก",
            subdistrict_en: "Pa Sak",
            post_code: "57150",
          },
          {
            subdistrict_th: "บ้านแซว",
            subdistrict_en: "Ban Saeo",
            post_code: "57150",
          },
          {
            subdistrict_th: "ศรีดอนมูล",
            subdistrict_en: "Si Don Mun",
            post_code: "57150",
          },
          {
            subdistrict_th: "แม่เงิน",
            subdistrict_en: "Mae Ngoen",
            post_code: "57150",
          },
          {
            subdistrict_th: "โยนก",
            subdistrict_en: "Yonok",
            post_code: "57150",
          },
        ],
      },
      {
        district_th: "อำเภอแม่สาย",
        district_en: "Amphoe Mae Sai",
        children: [
          {
            subdistrict_th: "แม่สาย",
            subdistrict_en: "Mae Sai",
            post_code: "57130",
          },
          {
            subdistrict_th: "ห้วยไคร้",
            subdistrict_en: "Huai Khrai",
            post_code: "57220",
          },
          {
            subdistrict_th: "เกาะช้าง",
            subdistrict_en: "Ko Chang",
            post_code: "57130",
          },
          {
            subdistrict_th: "โป่งผา",
            subdistrict_en: "Pong Pha",
            post_code: "57130",
          },
          {
            subdistrict_th: "ศรีเมืองชุม",
            subdistrict_en: "Si Mueang Chum",
            post_code: "57130",
          },
          {
            subdistrict_th: "เวียงพางคำ",
            subdistrict_en: "Wiang Phang Kham",
            post_code: "57130",
          },
          {
            subdistrict_th: "บ้านด้าย",
            subdistrict_en: "Ban Dai",
            post_code: "57220",
          },
          {
            subdistrict_th: "โป่งงาม",
            subdistrict_en: "Pong Ngam",
            post_code: "57130",
          },
        ],
      },
      {
        district_th: "อำเภอแม่สรวย",
        district_en: "Amphoe Mae Suai",
        children: [
          {
            subdistrict_th: "แม่สรวย",
            subdistrict_en: "Mae Suai",
            post_code: "57180",
          },
          {
            subdistrict_th: "ป่าแดด",
            subdistrict_en: "Pa Daet",
            post_code: "57180",
          },
          {
            subdistrict_th: "แม่พริก",
            subdistrict_en: "Mae Phrik",
            post_code: "57180",
          },
          {
            subdistrict_th: "ศรีถ้อย",
            subdistrict_en: "Si Thoi",
            post_code: "57180",
          },
          {
            subdistrict_th: "ท่าก๊อ",
            subdistrict_en: "Tha Ko",
            post_code: "57180",
          },
          {
            subdistrict_th: "วาวี",
            subdistrict_en: "Wawi",
            post_code: "57180",
          },
          {
            subdistrict_th: "เจดีย์หลวง",
            subdistrict_en: "Chedi Luang",
            post_code: "57180",
          },
        ],
      },
      {
        district_th: "อำเภอเวียงป่าเป้า",
        district_en: "Amphoe Wiang Pa Pao",
        children: [
          {
            subdistrict_th: "สันสลี",
            subdistrict_en: "San Sali",
            post_code: "57170",
          },
          {
            subdistrict_th: "เวียง",
            subdistrict_en: "Wiang",
            post_code: "57170",
          },
          {
            subdistrict_th: "บ้านโป่ง",
            subdistrict_en: "Ban Pong",
            post_code: "57170",
          },
          {
            subdistrict_th: "ป่างิ้ว",
            subdistrict_en: "Pa Ngio",
            post_code: "57170",
          },
          {
            subdistrict_th: "เวียงกาหลง",
            subdistrict_en: "Wiang Ka Long",
            post_code: "57260",
          },
          {
            subdistrict_th: "แม่เจดีย์",
            subdistrict_en: "Mae Chedi",
            post_code: "57260",
          },
          {
            subdistrict_th: "แม่เจดีย์ใหม่",
            subdistrict_en: "Mae Chedi Mai",
            post_code: "57260",
          },
        ],
      },
      {
        district_th: "อำเภอพญาเม็งราย",
        district_en: "Amphoe Phaya Mengrai",
        children: [
          {
            subdistrict_th: "แม่เปา",
            subdistrict_en: "Mae Pao",
            post_code: "57290",
          },
          {
            subdistrict_th: "แม่ต๋ำ",
            subdistrict_en: "Mae Tam",
            post_code: "57290",
          },
          {
            subdistrict_th: "ไม้ยา",
            subdistrict_en: "Mai Ya",
            post_code: "57290",
          },
          {
            subdistrict_th: "เม็งราย",
            subdistrict_en: "Mengrai",
            post_code: "57290",
          },
          {
            subdistrict_th: "ตาดควัน",
            subdistrict_en: "Tat Khwan",
            post_code: "57290",
          },
        ],
      },
      {
        district_th: "อำเภอเวียงแก่น",
        district_en: "Amphoe Wiang Kaen",
        children: [
          {
            subdistrict_th: "ม่วงยาย",
            subdistrict_en: "Muang Yai",
            post_code: "57310",
          },
          {
            subdistrict_th: "ปอ",
            subdistrict_en: "Po",
            post_code: "57310",
          },
          {
            subdistrict_th: "หล่ายงาว",
            subdistrict_en: "Lai Ngao",
            post_code: "57310",
          },
          {
            subdistrict_th: "ท่าข้าม",
            subdistrict_en: "Tha Kham",
            post_code: "57310",
          },
        ],
      },
      {
        district_th: "อำเภอขุนตาล",
        district_en: "Amphoe Khun Tan",
        children: [
          {
            subdistrict_th: "ต้า",
            subdistrict_en: "Ta",
            post_code: "57340",
          },
          {
            subdistrict_th: "ป่าตาล",
            subdistrict_en: "Pa Tan",
            post_code: "57340",
          },
          {
            subdistrict_th: "ยางฮอม",
            subdistrict_en: "Yang Hom",
            post_code: "57340",
          },
        ],
      },
      {
        district_th: "อำเภอแม่ฟ้าหลวง",
        district_en: "Amphoe Mae Fa Luang",
        children: [
          {
            subdistrict_th: "เทอดไทย",
            subdistrict_en: "Thoet Thai",
            post_code: "57240",
          },
          {
            subdistrict_th: "แม่สลองใน",
            subdistrict_en: "Mae Salong Nai",
            post_code: "57110",
          },
          {
            subdistrict_th: "แม่สลองนอก",
            subdistrict_en: "Mae Salong Nok",
            post_code: "57110",
          },
          {
            subdistrict_th: "แม่ฟ้าหลวง",
            subdistrict_en: "Mae Fa Luang",
            post_code: "57240",
          },
        ],
      },
      {
        district_th: "อำเภอแม่ลาว",
        district_en: "Amphoe Mae Lao",
        children: [
          {
            subdistrict_th: "ดงมะดะ",
            subdistrict_en: "Dong Mada",
            post_code: "57250",
          },
          {
            subdistrict_th: "จอมหมอกแก้ว",
            subdistrict_en: "Chom Mok Kaeo",
            post_code: "57250",
          },
          {
            subdistrict_th: "บัวสลี",
            subdistrict_en: "Bua Sali",
            post_code: "57250",
          },
          {
            subdistrict_th: "ป่าก่อดำ",
            subdistrict_en: "Pa Ko Dam",
            post_code: "57250",
          },
          {
            subdistrict_th: "โป่งแพร่",
            subdistrict_en: "Pong Phrae",
            post_code: "57000",
          },
        ],
      },
      {
        district_th: "อำเภอเวียงเชียงรุ้ง",
        district_en: "Amphoe Wiang Chiang Rung",
        children: [
          {
            subdistrict_th: "ทุ่งก่อ",
            subdistrict_en: "Thung Ko",
            post_code: "57210",
          },
          {
            subdistrict_th: "ดงมหาวัน",
            subdistrict_en: "Dong Maha Wan",
            post_code: "57210",
          },
          {
            subdistrict_th: "ป่าซาง",
            subdistrict_en: "Pa Sang",
            post_code: "57210",
          },
        ],
      },
      {
        district_th: "อำเภอดอยหลวง",
        district_en: "Amphoe Doi Luang",
        children: [
          {
            subdistrict_th: "ปงน้อย",
            subdistrict_en: "Pong Noi",
            post_code: "57110",
          },
          {
            subdistrict_th: "โชคชัย",
            subdistrict_en: "Chok Chai",
            post_code: "57110",
          },
          {
            subdistrict_th: "หนองป่าก่อ",
            subdistrict_en: "Nong Pa Ko",
            post_code: "57110",
          },
        ],
      },
    ],
  },
  {
    province_th: "แม่ฮ่องสอน",
    province_en: "Mae Hong Son",
    children: [
      {
        district_th: "อำเภอเมืองแม่ฮ่องสอน",
        district_en: "Amphoe Mueang Mae Hong Son",
        children: [
          {
            subdistrict_th: "จองคำ",
            subdistrict_en: "Chong Kham",
            post_code: "58000",
          },
          {
            subdistrict_th: "ห้วยโป่ง",
            subdistrict_en: "Huai Pong",
            post_code: "58000",
          },
          {
            subdistrict_th: "ผาบ่อง",
            subdistrict_en: "Pha Bong",
            post_code: "58000",
          },
          {
            subdistrict_th: "ปางหมู",
            subdistrict_en: "Pang Mu",
            post_code: "58000",
          },
          {
            subdistrict_th: "หมอกจำแป่",
            subdistrict_en: "Mok Champae",
            post_code: "58000",
          },
          {
            subdistrict_th: "ห้วยผา",
            subdistrict_en: "Huai Pha",
            post_code: "58000",
          },
          {
            subdistrict_th: "ห้วยปูลิง",
            subdistrict_en: "Huai Pu Ling",
            post_code: "58000",
          },
        ],
      },
      {
        district_th: "อำเภอขุนยวม",
        district_en: "Amphoe Khun Yuam",
        children: [
          {
            subdistrict_th: "ขุนยวม",
            subdistrict_en: "Khun Yuam",
            post_code: "58140",
          },
          {
            subdistrict_th: "แม่เงา",
            subdistrict_en: "Mae Ngao",
            post_code: "58140",
          },
          {
            subdistrict_th: "เมืองปอน",
            subdistrict_en: "Mueang Pon",
            post_code: "58140",
          },
          {
            subdistrict_th: "แม่ยวมน้อย",
            subdistrict_en: "Mae Yuam Noi",
            post_code: "58140",
          },
          {
            subdistrict_th: "แม่กิ๊",
            subdistrict_en: "Mae Ki",
            post_code: "58140",
          },
          {
            subdistrict_th: "แม่อูคอ",
            subdistrict_en: "Mae Ukho",
            post_code: "58140",
          },
        ],
      },
      {
        district_th: "อำเภอปาย",
        district_en: "Amphoe Pai",
        children: [
          {
            subdistrict_th: "เวียงใต้",
            subdistrict_en: "Wiang Tai",
            post_code: "58130",
          },
          {
            subdistrict_th: "เวียงเหนือ",
            subdistrict_en: "Wiang Nuea",
            post_code: "58130",
          },
          {
            subdistrict_th: "แม่นาเติง",
            subdistrict_en: "Mae Na Toeng",
            post_code: "58130",
          },
          {
            subdistrict_th: "แม่ฮี้",
            subdistrict_en: "Mae Hi",
            post_code: "58130",
          },
          {
            subdistrict_th: "ทุ่งยาว",
            subdistrict_en: "Thung Yao",
            post_code: "58130",
          },
          {
            subdistrict_th: "เมืองแปง",
            subdistrict_en: "Mueang Paeng",
            post_code: "58130",
          },
          {
            subdistrict_th: "โป่งสา",
            subdistrict_en: "Pong Sa",
            post_code: "58130",
          },
        ],
      },
      {
        district_th: "อำเภอแม่สะเรียง",
        district_en: "Amphoe Mae Sariang",
        children: [
          {
            subdistrict_th: "บ้านกาศ",
            subdistrict_en: "Ban Kat",
            post_code: "58110",
          },
          {
            subdistrict_th: "แม่สะเรียง",
            subdistrict_en: "Mae Sariang",
            post_code: "58110",
          },
          {
            subdistrict_th: "แม่คง",
            subdistrict_en: "Mae Khong",
            post_code: "58110",
          },
          {
            subdistrict_th: "แม่เหาะ",
            subdistrict_en: "Mae Ho",
            post_code: "58110",
          },
          {
            subdistrict_th: "แม่ยวม",
            subdistrict_en: "Mae Yuam",
            post_code: "58110",
          },
          {
            subdistrict_th: "เสาหิน",
            subdistrict_en: "Sao Hin",
            post_code: "58110",
          },
          {
            subdistrict_th: "ป่าแป๋",
            subdistrict_en: "Pa Pae",
            post_code: "58110",
          },
        ],
      },
      {
        district_th: "อำเภอแม่ลาน้อย",
        district_en: "Amphoe Mae La Noi",
        children: [
          {
            subdistrict_th: "แม่ลาน้อย",
            subdistrict_en: "Mae La Noi",
            post_code: "58120",
          },
          {
            subdistrict_th: "แม่ลาหลวง",
            subdistrict_en: "Mae La Luang",
            post_code: "58120",
          },
          {
            subdistrict_th: "ท่าผาปุ้ม",
            subdistrict_en: "Tha Pha Pum",
            post_code: "58120",
          },
          {
            subdistrict_th: "แม่โถ",
            subdistrict_en: "Mae Tho",
            post_code: "58120",
          },
          {
            subdistrict_th: "ห้วยห้อม",
            subdistrict_en: "Huai Hom",
            post_code: "58120",
          },
          {
            subdistrict_th: "แม่นาจาง",
            subdistrict_en: "Mae Na Chang",
            post_code: "58120",
          },
          {
            subdistrict_th: "สันติคีรี",
            subdistrict_en: "Santi Khiri",
            post_code: "58120",
          },
          {
            subdistrict_th: "ขุนแม่ลาน้อย",
            subdistrict_en: "Khun Mae La Noi",
            post_code: "58120",
          },
        ],
      },
      {
        district_th: "อำเภอสบเมย",
        district_en: "Amphoe Sop Moei",
        children: [
          {
            subdistrict_th: "สบเมย",
            subdistrict_en: "Sop Moei",
            post_code: "58110",
          },
          {
            subdistrict_th: "แม่คะตวน",
            subdistrict_en: "Mae Khatuan",
            post_code: "58110",
          },
          {
            subdistrict_th: "กองก๋อย",
            subdistrict_en: "Kong Koi",
            post_code: "58110",
          },
          {
            subdistrict_th: "แม่สวด",
            subdistrict_en: "Mae Suat",
            post_code: "58110",
          },
          {
            subdistrict_th: "ป่าโปง",
            subdistrict_en: "Pa Pong",
            post_code: "58110",
          },
          {
            subdistrict_th: "แม่สามแลบ",
            subdistrict_en: "Mae Sam Laep",
            post_code: "58110",
          },
        ],
      },
      {
        district_th: "อำเภอปางมะผ้า",
        district_en: "Amphoe Pang Mapha",
        children: [
          {
            subdistrict_th: "สบป่อง",
            subdistrict_en: "Sop Pong",
            post_code: "58150",
          },
          {
            subdistrict_th: "ปางมะผ้า",
            subdistrict_en: "Pang Mapha",
            post_code: "58150",
          },
          {
            subdistrict_th: "ถ้ำลอด",
            subdistrict_en: "Tham Lot",
            post_code: "58150",
          },
          {
            subdistrict_th: "นาปู่ป้อม",
            subdistrict_en: "Na Pu Pom",
            post_code: "58150",
          },
        ],
      },
    ],
  },
  {
    province_th: "นครสวรรค์",
    province_en: "Nakhon Sawan",
    children: [
      {
        district_th: "อำเภอเมืองนครสวรรค์",
        district_en: "Amphoe Mueang Nakhon Sawan",
        children: [
          {
            subdistrict_th: "ปากน้ำโพ",
            subdistrict_en: "Pak Nam Pho",
            post_code: "60000",
          },
          {
            subdistrict_th: "กลางแดด",
            subdistrict_en: "Klang Daet",
            post_code: "60000",
          },
          {
            subdistrict_th: "เกรียงไกร",
            subdistrict_en: "Kriangkrai",
            post_code: "60000",
          },
          {
            subdistrict_th: "แควใหญ่",
            subdistrict_en: "Khwae Yai",
            post_code: "60000",
          },
          {
            subdistrict_th: "ตะเคียนเลื่อน",
            subdistrict_en: "Takhian Luean",
            post_code: "60000",
          },
          {
            subdistrict_th: "นครสวรรค์ตก",
            subdistrict_en: "Nakhon Sawan Tok",
            post_code: "60000",
          },
          {
            subdistrict_th: "นครสวรรค์ออก",
            subdistrict_en: "Nakhon Sawan Ok",
            post_code: "60000",
          },
          {
            subdistrict_th: "บางพระหลวง",
            subdistrict_en: "Bang Phra Luang",
            post_code: "60000",
          },
          {
            subdistrict_th: "บางม่วง",
            subdistrict_en: "Bang Muang",
            post_code: "60000",
          },
          {
            subdistrict_th: "บ้านมะเกลือ",
            subdistrict_en: "Ban Makluea",
            post_code: "60000",
          },
          {
            subdistrict_th: "บ้านแก่ง",
            subdistrict_en: "Ban Kaeng",
            post_code: "60000",
          },
          {
            subdistrict_th: "พระนอน",
            subdistrict_en: "Phra Non",
            post_code: "60000",
          },
          {
            subdistrict_th: "วัดไทร",
            subdistrict_en: "Wat Sai",
            post_code: "60000",
          },
          {
            subdistrict_th: "หนองกรด",
            subdistrict_en: "Nong Krot",
            post_code: "60240",
          },
          {
            subdistrict_th: "หนองกระโดน",
            subdistrict_en: "Nong Kradon",
            post_code: "60240",
          },
          {
            subdistrict_th: "หนองปลิง",
            subdistrict_en: "Nong Pling",
            post_code: "60000",
          },
          {
            subdistrict_th: "บึงเสนาท",
            subdistrict_en: "Bueng Senat",
            post_code: "60000",
          },
        ],
      },
      {
        district_th: "อำเภอโกรกพระ",
        district_en: "Amphoe Krok Phra",
        children: [
          {
            subdistrict_th: "โกรกพระ",
            subdistrict_en: "Krok Phra",
            post_code: "60170",
          },
          {
            subdistrict_th: "ยางตาล",
            subdistrict_en: "Yang Tan",
            post_code: "60170",
          },
          {
            subdistrict_th: "บางมะฝ่อ",
            subdistrict_en: "Bang Mafo",
            post_code: "60170",
          },
          {
            subdistrict_th: "บางประมุง",
            subdistrict_en: "Bang Pramung",
            post_code: "60170",
          },
          {
            subdistrict_th: "นากลาง",
            subdistrict_en: "Na Klang",
            post_code: "60170",
          },
          {
            subdistrict_th: "ศาลาแดง",
            subdistrict_en: "Sala Daeng",
            post_code: "60170",
          },
          {
            subdistrict_th: "เนินกว้าว",
            subdistrict_en: "Noen Kwao",
            post_code: "60170",
          },
          {
            subdistrict_th: "เนินศาลา",
            subdistrict_en: "Noen Sala",
            post_code: "60170",
          },
          {
            subdistrict_th: "หาดสูง",
            subdistrict_en: "Hat Sung",
            post_code: "60170",
          },
        ],
      },
      {
        district_th: "อำเภอชุมแสง",
        district_en: "Amphoe Chum Saeng",
        children: [
          {
            subdistrict_th: "ชุมแสง",
            subdistrict_en: "Chum Saeng",
            post_code: "60120",
          },
          {
            subdistrict_th: "ทับกฤช",
            subdistrict_en: "Thap Krit",
            post_code: "60250",
          },
          {
            subdistrict_th: "พิกุล",
            subdistrict_en: "Phikun",
            post_code: "60120",
          },
          {
            subdistrict_th: "เกยไชย",
            subdistrict_en: "Koei Chai",
            post_code: "60120",
          },
          {
            subdistrict_th: "ท่าไม้",
            subdistrict_en: "Tha Mai",
            post_code: "60120",
          },
          {
            subdistrict_th: "บางเคียน",
            subdistrict_en: "Bang Khian",
            post_code: "60120",
          },
          {
            subdistrict_th: "หนองกระเจา",
            subdistrict_en: "Nong Krachao",
            post_code: "60120",
          },
          {
            subdistrict_th: "พันลาน",
            subdistrict_en: "Phan Lan",
            post_code: "60250",
          },
          {
            subdistrict_th: "โคกหม้อ",
            subdistrict_en: "Khok Mo",
            post_code: "60120",
          },
          {
            subdistrict_th: "ไผ่สิงห์",
            subdistrict_en: "Phai Sing",
            post_code: "60120",
          },
          {
            subdistrict_th: "ฆะมัง",
            subdistrict_en: "Khamang",
            post_code: "60120",
          },
          {
            subdistrict_th: "ทับกฤชใต้",
            subdistrict_en: "Thap Krit Tai",
            post_code: "60250",
          },
        ],
      },
      {
        district_th: "อำเภอหนองบัว",
        district_en: "Amphoe Nong Bua",
        children: [
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "60110",
          },
          {
            subdistrict_th: "หนองกลับ",
            subdistrict_en: "Nong Klap",
            post_code: "60110",
          },
          {
            subdistrict_th: "ธารทหาร",
            subdistrict_en: "Than Thahan",
            post_code: "60110",
          },
          {
            subdistrict_th: "ห้วยร่วม",
            subdistrict_en: "Huai Ruam",
            post_code: "60110",
          },
          {
            subdistrict_th: "ห้วยถั่วใต้",
            subdistrict_en: "Huai Thua Tai",
            post_code: "60110",
          },
          {
            subdistrict_th: "ห้วยถั่วเหนือ",
            subdistrict_en: "Huai Thua Nuea",
            post_code: "60110",
          },
          {
            subdistrict_th: "ห้วยใหญ่",
            subdistrict_en: "Huai Yai",
            post_code: "60110",
          },
          {
            subdistrict_th: "ทุ่งทอง",
            subdistrict_en: "Thung Thong",
            post_code: "60110",
          },
          {
            subdistrict_th: "วังบ่อ",
            subdistrict_en: "Wang Bo",
            post_code: "60110",
          },
        ],
      },
      {
        district_th: "อำเภอบรรพตพิสัย",
        district_en: "Amphoe Banphot Phisai",
        children: [
          {
            subdistrict_th: "ท่างิ้ว",
            subdistrict_en: "Tha Ngio",
            post_code: "60180",
          },
          {
            subdistrict_th: "บางตาหงาย",
            subdistrict_en: "Bang Ta Ngai",
            post_code: "60180",
          },
          {
            subdistrict_th: "หูกวาง",
            subdistrict_en: "Hu Kwang",
            post_code: "60180",
          },
          {
            subdistrict_th: "อ่างทอง",
            subdistrict_en: "Ang Thong",
            post_code: "60180",
          },
          {
            subdistrict_th: "บ้านแดน",
            subdistrict_en: "Ban Daen",
            post_code: "60180",
          },
          {
            subdistrict_th: "บางแก้ว",
            subdistrict_en: "Bang Kaeo",
            post_code: "60180",
          },
          {
            subdistrict_th: "ตาขีด",
            subdistrict_en: "Ta Khit",
            post_code: "60180",
          },
          {
            subdistrict_th: "ตาสัง",
            subdistrict_en: "Ta Sang",
            post_code: "60180",
          },
          {
            subdistrict_th: "ด่านช้าง",
            subdistrict_en: "Dan Chang",
            post_code: "60180",
          },
          {
            subdistrict_th: "หนองกรด",
            subdistrict_en: "Nong Krot",
            post_code: "60180",
          },
          {
            subdistrict_th: "หนองตางู",
            subdistrict_en: "Nong Ta Ngu",
            post_code: "60180",
          },
          {
            subdistrict_th: "บึงปลาทู",
            subdistrict_en: "Bueng Pla Thu",
            post_code: "60180",
          },
          {
            subdistrict_th: "เจริญผล",
            subdistrict_en: "Charoen Phon",
            post_code: "60180",
          },
        ],
      },
      {
        district_th: "อำเภอเก้าเลี้ยว",
        district_en: "Amphoe Kao Liao",
        children: [
          {
            subdistrict_th: "มหาโพธิ",
            subdistrict_en: "Maha Phot",
            post_code: "60230",
          },
          {
            subdistrict_th: "เก้าเลี้ยว",
            subdistrict_en: "Kao Liao",
            post_code: "60230",
          },
          {
            subdistrict_th: "หนองเต่า",
            subdistrict_en: "Nong Tao",
            post_code: "60230",
          },
          {
            subdistrict_th: "เขาดิน",
            subdistrict_en: "Khao Din",
            post_code: "60230",
          },
          {
            subdistrict_th: "หัวดง",
            subdistrict_en: "Hua Dong",
            post_code: "60230",
          },
        ],
      },
      {
        district_th: "อำเภอตาคลี",
        district_en: "Amphoe Takhli",
        children: [
          {
            subdistrict_th: "ตาคลี",
            subdistrict_en: "Takhli",
            post_code: "60140",
          },
          {
            subdistrict_th: "ช่องแค",
            subdistrict_en: "Chong Khae",
            post_code: "60210",
          },
          {
            subdistrict_th: "จันเสน",
            subdistrict_en: "Chan Sen",
            post_code: "60260",
          },
          {
            subdistrict_th: "ห้วยหอม",
            subdistrict_en: "Huai Hom",
            post_code: "60210",
          },
          {
            subdistrict_th: "หัวหวาย",
            subdistrict_en: "Hua Wai",
            post_code: "60140",
          },
          {
            subdistrict_th: "หนองโพ",
            subdistrict_en: "Nong Pho",
            post_code: "60140",
          },
          {
            subdistrict_th: "หนองหม้อ",
            subdistrict_en: "Nong Mo",
            post_code: "60140",
          },
          {
            subdistrict_th: "สร้อยทอง",
            subdistrict_en: "Soi Thong",
            post_code: "60210",
          },
          {
            subdistrict_th: "ลาดทิพรส",
            subdistrict_en: "Lat Thippharot",
            post_code: "60260",
          },
          {
            subdistrict_th: "พรหมนิมิต",
            subdistrict_en: "Phrom Nimit",
            post_code: "60210",
          },
        ],
      },
      {
        district_th: "อำเภอท่าตะโก",
        district_en: "Amphoe Tha Tako",
        children: [
          {
            subdistrict_th: "ท่าตะโก",
            subdistrict_en: "Tha Tako",
            post_code: "60160",
          },
          {
            subdistrict_th: "พนมรอก",
            subdistrict_en: "Phanom Rok",
            post_code: "60160",
          },
          {
            subdistrict_th: "หัวถนน",
            subdistrict_en: "Hua Thanon",
            post_code: "60160",
          },
          {
            subdistrict_th: "สายลำโพง",
            subdistrict_en: "Sai Lamphong",
            post_code: "60160",
          },
          {
            subdistrict_th: "วังมหากร",
            subdistrict_en: "Wang Mahakon",
            post_code: "60160",
          },
          {
            subdistrict_th: "ดอนคา",
            subdistrict_en: "Don Kha",
            post_code: "60160",
          },
          {
            subdistrict_th: "ทำนบ",
            subdistrict_en: "Thamnop",
            post_code: "60160",
          },
          {
            subdistrict_th: "วังใหญ่",
            subdistrict_en: "Wang Yai",
            post_code: "60160",
          },
          {
            subdistrict_th: "พนมเศษ",
            subdistrict_en: "Phanom Set",
            post_code: "60160",
          },
          {
            subdistrict_th: "หนองหลวง",
            subdistrict_en: "Nong Luang",
            post_code: "60160",
          },
        ],
      },
      {
        district_th: "อำเภอไพศาลี",
        district_en: "Amphoe Phaisali",
        children: [
          {
            subdistrict_th: "โคกเดื่อ",
            subdistrict_en: "Khok Duea",
            post_code: "60220",
          },
          {
            subdistrict_th: "สำโรงชัย",
            subdistrict_en: "Samrong Chai",
            post_code: "60220",
          },
          {
            subdistrict_th: "วังน้ำลัด",
            subdistrict_en: "Wang Nam Lat",
            post_code: "60220",
          },
          {
            subdistrict_th: "ตะคร้อ",
            subdistrict_en: "Takhro",
            post_code: "60220",
          },
          {
            subdistrict_th: "โพธิ์ประสาท",
            subdistrict_en: "Pho Prasat",
            post_code: "60220",
          },
          {
            subdistrict_th: "วังข่อย",
            subdistrict_en: "Wang Khoi",
            post_code: "60220",
          },
          {
            subdistrict_th: "นาขอม",
            subdistrict_en: "Na Khom",
            post_code: "60220",
          },
          {
            subdistrict_th: "ไพศาลี",
            subdistrict_en: "Phaisali",
            post_code: "60220",
          },
        ],
      },
      {
        district_th: "อำเภอพยุหะคีรี",
        district_en: "Amphoe Phayuha Khiri",
        children: [
          {
            subdistrict_th: "พยุหะ",
            subdistrict_en: "Phayuha",
            post_code: "60130",
          },
          {
            subdistrict_th: "เนินมะกอก",
            subdistrict_en: "Noen Makok",
            post_code: "60130",
          },
          {
            subdistrict_th: "นิคมเขาบ่อแก้ว",
            subdistrict_en: "Nikhom Khao Bo Kaeo",
            post_code: "60130",
          },
          {
            subdistrict_th: "ม่วงหัก",
            subdistrict_en: "Muang Hak",
            post_code: "60130",
          },
          {
            subdistrict_th: "ยางขาว",
            subdistrict_en: "Yang Khao",
            post_code: "60130",
          },
          {
            subdistrict_th: "ย่านมัทรี",
            subdistrict_en: "Yan Matsi",
            post_code: "60130",
          },
          {
            subdistrict_th: "เขาทอง",
            subdistrict_en: "Khao Thong",
            post_code: "60130",
          },
          {
            subdistrict_th: "ท่าน้ำอ้อย",
            subdistrict_en: "Tha Nam Oi",
            post_code: "60130",
          },
          {
            subdistrict_th: "น้ำทรง",
            subdistrict_en: "Nam Song",
            post_code: "60130",
          },
          {
            subdistrict_th: "เขากะลา",
            subdistrict_en: "Khao Kala",
            post_code: "60130",
          },
          {
            subdistrict_th: "สระทะเล",
            subdistrict_en: "Sa Thale",
            post_code: "60130",
          },
        ],
      },
      {
        district_th: "อำเภอลาดยาว",
        district_en: "Amphoe Lat Yao",
        children: [
          {
            subdistrict_th: "ลาดยาว",
            subdistrict_en: "Lat Yao",
            post_code: "60150",
          },
          {
            subdistrict_th: "ห้วยน้ำหอม",
            subdistrict_en: "Huai Nam Hom",
            post_code: "60150",
          },
          {
            subdistrict_th: "วังม้า",
            subdistrict_en: "Wang Ma",
            post_code: "60150",
          },
          {
            subdistrict_th: "วังเมือง",
            subdistrict_en: "Wang Mueang",
            post_code: "60150",
          },
          {
            subdistrict_th: "สร้อยละคร",
            subdistrict_en: "Soi Lakhon",
            post_code: "60150",
          },
          {
            subdistrict_th: "มาบแก",
            subdistrict_en: "Map Kae",
            post_code: "60150",
          },
          {
            subdistrict_th: "หนองยาว",
            subdistrict_en: "Nong Yao",
            post_code: "60150",
          },
          {
            subdistrict_th: "หนองนมวัว",
            subdistrict_en: "Nong Nom Wua",
            post_code: "60150",
          },
          {
            subdistrict_th: "บ้านไร่",
            subdistrict_en: "Ban Rai",
            post_code: "60150",
          },
          {
            subdistrict_th: "เนินขี้เหล็ก",
            subdistrict_en: "Noen Khilek",
            post_code: "60150",
          },
          {
            subdistrict_th: "ศาลเจ้าไก่ต่อ",
            subdistrict_en: "San Chao Kai to",
            post_code: "60150",
          },
          {
            subdistrict_th: "สระแก้ว",
            subdistrict_en: "Sa Kaeo",
            post_code: "60150",
          },
        ],
      },
      {
        district_th: "อำเภอตากฟ้า",
        district_en: "Amphoe Tak Fa",
        children: [
          {
            subdistrict_th: "ตากฟ้า",
            subdistrict_en: "Tak Fa",
            post_code: "60190",
          },
          {
            subdistrict_th: "ลำพยนต์",
            subdistrict_en: "Lam Phayon",
            post_code: "60190",
          },
          {
            subdistrict_th: "สุขสำราญ",
            subdistrict_en: "Suk Samran",
            post_code: "60190",
          },
          {
            subdistrict_th: "หนองพิกุล",
            subdistrict_en: "Nong Phikun",
            post_code: "60190",
          },
          {
            subdistrict_th: "พุนกยูง",
            subdistrict_en: "Phu Nok Yung",
            post_code: "60190",
          },
          {
            subdistrict_th: "อุดมธัญญา",
            subdistrict_en: "Udom Thanya",
            post_code: "60190",
          },
          {
            subdistrict_th: "เขาชายธง",
            subdistrict_en: "Khao Chai Thong",
            post_code: "60190",
          },
        ],
      },
      {
        district_th: "อำเภอแม่วงก์",
        district_en: "Amphoe Mae Wong",
        children: [
          {
            subdistrict_th: "แม่วงก์",
            subdistrict_en: "Mae Wong",
            post_code: "60150",
          },
          {
            subdistrict_th: "แม่เล่ย์",
            subdistrict_en: "Mae Le",
            post_code: "60150",
          },
          {
            subdistrict_th: "วังซ่าน",
            subdistrict_en: "Wang San",
            post_code: "60150",
          },
          {
            subdistrict_th: "เขาชนกัน",
            subdistrict_en: "Khao Chon Kan",
            post_code: "60150",
          },
        ],
      },
      {
        district_th: "อำเภอแม่เปิน",
        district_en: "Amphoe Mae Poen",
        children: [
          {
            subdistrict_th: "แม่เปิน",
            subdistrict_en: "Mae Poen",
            post_code: "60150",
          },
        ],
      },
      {
        district_th: "อำเภอชุมตาบง",
        district_en: "Amphoe Chum Ta Bong",
        children: [
          {
            subdistrict_th: "ชุมตาบง",
            subdistrict_en: "Chum Ta Bong",
            post_code: "60150",
          },
          {
            subdistrict_th: "ปางสวรรค์",
            subdistrict_en: "Pang Sawan",
            post_code: "60150",
          },
        ],
      },
    ],
  },
  {
    province_th: "อุทัยธานี",
    province_en: "Uthai Thani",
    children: [
      {
        district_th: "อำเภอเมืองอุทัยธานี",
        district_en: "Amphoe Mueang Uthai Thani",
        children: [
          {
            subdistrict_th: "อุทัยใหม่",
            subdistrict_en: "Uthai Mai",
            post_code: "61000",
          },
          {
            subdistrict_th: "น้ำซึม",
            subdistrict_en: "Nam Suem",
            post_code: "61000",
          },
          {
            subdistrict_th: "สะแกกรัง",
            subdistrict_en: "Sakae Krang",
            post_code: "61000",
          },
          {
            subdistrict_th: "ดอนขวาง",
            subdistrict_en: "Don Khwang",
            post_code: "61000",
          },
          {
            subdistrict_th: "หาดทนง",
            subdistrict_en: "Hat Thanong",
            post_code: "61000",
          },
          {
            subdistrict_th: "เกาะเทโพ",
            subdistrict_en: "Ko Thepho",
            post_code: "61000",
          },
          {
            subdistrict_th: "ท่าซุง",
            subdistrict_en: "Tha Sung",
            post_code: "61000",
          },
          {
            subdistrict_th: "หนองแก",
            subdistrict_en: "Nong Kae",
            post_code: "61000",
          },
          {
            subdistrict_th: "โนนเหล็ก",
            subdistrict_en: "Non Lek",
            post_code: "61000",
          },
          {
            subdistrict_th: "หนองเต่า",
            subdistrict_en: "Nong Tao",
            post_code: "61000",
          },
          {
            subdistrict_th: "หนองไผ่แบน",
            subdistrict_en: "Nong Phai Baen",
            post_code: "61000",
          },
          {
            subdistrict_th: "หนองพังค่า",
            subdistrict_en: "Nong Phang Kha",
            post_code: "61000",
          },
          {
            subdistrict_th: "ทุ่งใหญ่",
            subdistrict_en: "Thung Yai",
            post_code: "61000",
          },
          {
            subdistrict_th: "เนินแจง",
            subdistrict_en: "Noen Chaeng",
            post_code: "61000",
          },
        ],
      },
      {
        district_th: "อำเภอทัพทัน",
        district_en: "Amphoe Thap Than",
        children: [
          {
            subdistrict_th: "ทัพทัน",
            subdistrict_en: "Thap Than",
            post_code: "61120",
          },
          {
            subdistrict_th: "ทุ่งนาไทย",
            subdistrict_en: "Thung Na Thai",
            post_code: "61120",
          },
          {
            subdistrict_th: "เขาขี้ฝอย",
            subdistrict_en: "Khao Khi Foi",
            post_code: "61120",
          },
          {
            subdistrict_th: "หนองหญ้าปล้อง",
            subdistrict_en: "Nong Ya Plong",
            post_code: "61120",
          },
          {
            subdistrict_th: "โคกหม้อ",
            subdistrict_en: "Khok Mo",
            post_code: "61120",
          },
          {
            subdistrict_th: "หนองยายดา",
            subdistrict_en: "Nong Yai Da",
            post_code: "61120",
          },
          {
            subdistrict_th: "หนองกลางดง",
            subdistrict_en: "Nong Klang Dong",
            post_code: "61120",
          },
          {
            subdistrict_th: "หนองกระทุ่ม",
            subdistrict_en: "Nong Krathum",
            post_code: "61120",
          },
          {
            subdistrict_th: "หนองสระ",
            subdistrict_en: "Nong Sa",
            post_code: "61120",
          },
          {
            subdistrict_th: "ตลุกดู่",
            subdistrict_en: "Taluk Du",
            post_code: "61120",
          },
        ],
      },
      {
        district_th: "อำเภอสว่างอารมณ์",
        district_en: "Amphoe Sawang Arom",
        children: [
          {
            subdistrict_th: "สว่างอารมณ์",
            subdistrict_en: "Sawang Arom",
            post_code: "61150",
          },
          {
            subdistrict_th: "หนองหลวง",
            subdistrict_en: "Nong Luang",
            post_code: "61150",
          },
          {
            subdistrict_th: "พลวงสองนาง",
            subdistrict_en: "Phluang Song Nang",
            post_code: "61150",
          },
          {
            subdistrict_th: "ไผ่เขียว",
            subdistrict_en: "Phai Khiao",
            post_code: "61150",
          },
          {
            subdistrict_th: "บ่อยาง",
            subdistrict_en: "Bo Yang",
            post_code: "61150",
          },
        ],
      },
      {
        district_th: "อำเภอหนองฉาง",
        district_en: "Amphoe Nong Chang",
        children: [
          {
            subdistrict_th: "หนองฉาง",
            subdistrict_en: "Nong Chang",
            post_code: "61110",
          },
          {
            subdistrict_th: "หนองยาง",
            subdistrict_en: "Nong Yang",
            post_code: "61110",
          },
          {
            subdistrict_th: "หนองนางนวล",
            subdistrict_en: "Nong Nang Nuan",
            post_code: "61110",
          },
          {
            subdistrict_th: "หนองสรวง",
            subdistrict_en: "Nong Suang",
            post_code: "61110",
          },
          {
            subdistrict_th: "บ้านเก่า",
            subdistrict_en: "Ban Kao",
            post_code: "61110",
          },
          {
            subdistrict_th: "อุทัยเก่า",
            subdistrict_en: "Utai Kao",
            post_code: "61110",
          },
          {
            subdistrict_th: "ทุ่งโพ",
            subdistrict_en: "Thung Pho",
            post_code: "61110",
          },
          {
            subdistrict_th: "ทุ่งพง",
            subdistrict_en: "Thung Phong",
            post_code: "61110",
          },
          {
            subdistrict_th: "เขาบางแกรก",
            subdistrict_en: "Khao Bang Kraek",
            post_code: "61170",
          },
          {
            subdistrict_th: "เขากวางทอง",
            subdistrict_en: "Khao Kwang Thong",
            post_code: "61110",
          },
        ],
      },
      {
        district_th: "อำเภอหนองขาหย่าง",
        district_en: "Amphoe Nong Khayang",
        children: [
          {
            subdistrict_th: "หนองขาหย่าง",
            subdistrict_en: "Nong Khayang",
            post_code: "61130",
          },
          {
            subdistrict_th: "หนองไผ่",
            subdistrict_en: "Nong Phai",
            post_code: "61130",
          },
          {
            subdistrict_th: "ดอนกลอย",
            subdistrict_en: "Don Kloi",
            post_code: "61130",
          },
          {
            subdistrict_th: "ห้วยรอบ",
            subdistrict_en: "Huai Rop",
            post_code: "61130",
          },
          {
            subdistrict_th: "ทุ่งพึ่ง",
            subdistrict_en: "Thung Phueng",
            post_code: "61130",
          },
          {
            subdistrict_th: "ท่าโพ",
            subdistrict_en: "Tha Pho",
            post_code: "61130",
          },
          {
            subdistrict_th: "หมกแถว",
            subdistrict_en: "Mok Thaeo",
            post_code: "61130",
          },
          {
            subdistrict_th: "หลุมเข้า",
            subdistrict_en: "Lum Khao",
            post_code: "61130",
          },
          {
            subdistrict_th: "ดงขวาง",
            subdistrict_en: "Dong Khwang",
            post_code: "61130",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านไร่",
        district_en: "Amphoe Ban Rai",
        children: [
          {
            subdistrict_th: "บ้านไร่",
            subdistrict_en: "Ban Rai",
            post_code: "61140",
          },
          {
            subdistrict_th: "ทัพหลวง",
            subdistrict_en: "Thap Luang",
            post_code: "61140",
          },
          {
            subdistrict_th: "ห้วยแห้ง",
            subdistrict_en: "Huai Haeng",
            post_code: "61140",
          },
          {
            subdistrict_th: "คอกควาย",
            subdistrict_en: "Khok Khwai",
            post_code: "61140",
          },
          {
            subdistrict_th: "วังหิน",
            subdistrict_en: "Wang Hin",
            post_code: "61180",
          },
          {
            subdistrict_th: "เมืองการุ้ง",
            subdistrict_en: "Muang Ka Rung",
            post_code: "61180",
          },
          {
            subdistrict_th: "แก่นมะกรูด",
            subdistrict_en: "Kaen Makrut",
            post_code: "61140",
          },
          {
            subdistrict_th: "หนองจอก",
            subdistrict_en: "Nong Chok",
            post_code: "61180",
          },
          {
            subdistrict_th: "หูช้าง",
            subdistrict_en: "Hu Chang",
            post_code: "61180",
          },
          {
            subdistrict_th: "บ้านบึง",
            subdistrict_en: "Ban Bueng",
            post_code: "61140",
          },
          {
            subdistrict_th: "บ้านใหม่คลองเคียน",
            subdistrict_en: "Ban Mai Khlong Khian",
            post_code: "61180",
          },
          {
            subdistrict_th: "หนองบ่มกล้วย",
            subdistrict_en: "Nong Bom Kluai",
            post_code: "61180",
          },
          {
            subdistrict_th: "เจ้าวัด",
            subdistrict_en: "Chao Wat",
            post_code: "61140",
          },
        ],
      },
      {
        district_th: "อำเภอลานสัก",
        district_en: "Amphoe Lan Sak",
        children: [
          {
            subdistrict_th: "ลานสัก",
            subdistrict_en: "Lan Sak",
            post_code: "61160",
          },
          {
            subdistrict_th: "ประดู่ยืน",
            subdistrict_en: "Pradu Yuen",
            post_code: "61160",
          },
          {
            subdistrict_th: "ป่าอ้อ",
            subdistrict_en: "Pa O",
            post_code: "61160",
          },
          {
            subdistrict_th: "ระบำ",
            subdistrict_en: "Rabam",
            post_code: "61160",
          },
          {
            subdistrict_th: "น้ำรอบ",
            subdistrict_en: "Nam Rop",
            post_code: "61160",
          },
          {
            subdistrict_th: "ทุ่งนางาม",
            subdistrict_en: "Thung Na Ngam",
            post_code: "61160",
          },
        ],
      },
      {
        district_th: "อำเภอห้วยคต",
        district_en: "Amphoe Huai Khot",
        children: [
          {
            subdistrict_th: "สุขฤทัย",
            subdistrict_en: "Suk Ruethai",
            post_code: "61170",
          },
          {
            subdistrict_th: "ทองหลาง",
            subdistrict_en: "Thong Lang",
            post_code: "61170",
          },
          {
            subdistrict_th: "ห้วยคต",
            subdistrict_en: "Huai Khot",
            post_code: "61170",
          },
        ],
      },
    ],
  },
  {
    province_th: "กำแพงเพชร",
    province_en: "Kamphaeng Phet",
    children: [
      {
        district_th: "อำเภอเมืองกำแพงเพชร",
        district_en: "Amphoe Mueang Kamphaeng Phet",
        children: [
          {
            subdistrict_th: "ในเมือง",
            subdistrict_en: "Nai Mueang",
            post_code: "62000",
          },
          {
            subdistrict_th: "ไตรตรึงษ์",
            subdistrict_en: "Trai Trueng",
            post_code: "62160",
          },
          {
            subdistrict_th: "อ่างทอง",
            subdistrict_en: "Ang Thong",
            post_code: "62000",
          },
          {
            subdistrict_th: "นาบ่อคำ",
            subdistrict_en: "Na Bo Kham",
            post_code: "62000",
          },
          {
            subdistrict_th: "นครชุม",
            subdistrict_en: "Nakhon Chum",
            post_code: "62000",
          },
          {
            subdistrict_th: "ทรงธรรม",
            subdistrict_en: "Song Tham",
            post_code: "62000",
          },
          {
            subdistrict_th: "ลานดอกไม้",
            subdistrict_en: "Lan Dokmai",
            post_code: "62000",
          },
          {
            subdistrict_th: "หนองปลิง",
            subdistrict_en: "Nong Pling",
            post_code: "62000",
          },
          {
            subdistrict_th: "คณฑี",
            subdistrict_en: "Khonthi",
            post_code: "62000",
          },
          {
            subdistrict_th: "นิคมทุ่งโพธิ์ทะเล",
            subdistrict_en: "Nikhom Thung Pho Thale",
            post_code: "62000",
          },
          {
            subdistrict_th: "เทพนคร",
            subdistrict_en: "Thep Nakhon",
            post_code: "62000",
          },
          {
            subdistrict_th: "วังทอง",
            subdistrict_en: "Wang Thong",
            post_code: "62000",
          },
          {
            subdistrict_th: "ท่าขุนราม",
            subdistrict_en: "Tha Khun Ram",
            post_code: "62000",
          },
          {
            subdistrict_th: "คลองแม่ลาย",
            subdistrict_en: "Khlong Mae Lai",
            post_code: "62000",
          },
          {
            subdistrict_th: "ธำมรงค์",
            subdistrict_en: "Thammarong",
            post_code: "62160",
          },
          {
            subdistrict_th: "สระแก้ว",
            subdistrict_en: "Sa Kaeo",
            post_code: "62000",
          },
        ],
      },
      {
        district_th: "อำเภอไทรงาม",
        district_en: "Amphoe Sai Ngam",
        children: [
          {
            subdistrict_th: "ไทรงาม",
            subdistrict_en: "Sai Ngam",
            post_code: "62150",
          },
          {
            subdistrict_th: "หนองคล้า",
            subdistrict_en: "Nong Khla",
            post_code: "62150",
          },
          {
            subdistrict_th: "หนองทอง",
            subdistrict_en: "Nong Thong",
            post_code: "62150",
          },
          {
            subdistrict_th: "หนองไม้กอง",
            subdistrict_en: "Nong Mai Kong",
            post_code: "62150",
          },
          {
            subdistrict_th: "มหาชัย",
            subdistrict_en: "Maha Chai",
            post_code: "62150",
          },
          {
            subdistrict_th: "พานทอง",
            subdistrict_en: "Phan Thong",
            post_code: "62150",
          },
          {
            subdistrict_th: "หนองแม่แตง",
            subdistrict_en: "Nong Mae Taeng",
            post_code: "62150",
          },
        ],
      },
      {
        district_th: "อำเภอคลองลาน",
        district_en: "Amphoe Khlong Lan",
        children: [
          {
            subdistrict_th: "คลองน้ำไหล",
            subdistrict_en: "Khlong Nam Lai",
            post_code: "62180",
          },
          {
            subdistrict_th: "โป่งน้ำร้อน",
            subdistrict_en: "Pong Nam Ron",
            post_code: "62180",
          },
          {
            subdistrict_th: "คลองลานพัฒนา",
            subdistrict_en: "Khlong Lan Phatthana",
            post_code: "62180",
          },
          {
            subdistrict_th: "สักงาม",
            subdistrict_en: "Sak Ngam",
            post_code: "62180",
          },
        ],
      },
      {
        district_th: "อำเภอขาณุวรลักษบุรี",
        district_en: "Amphoe Khanu Woralaksaburi",
        children: [
          {
            subdistrict_th: "ยางสูง",
            subdistrict_en: "Yang Sung",
            post_code: "62130",
          },
          {
            subdistrict_th: "ป่าพุทรา",
            subdistrict_en: "Pa Phutsa",
            post_code: "62130",
          },
          {
            subdistrict_th: "แสนตอ",
            subdistrict_en: "Saen To",
            post_code: "62130",
          },
          {
            subdistrict_th: "สลกบาตร",
            subdistrict_en: "Salokbat",
            post_code: "62140",
          },
          {
            subdistrict_th: "บ่อถ้ำ",
            subdistrict_en: "Bo Tham",
            post_code: "62140",
          },
          {
            subdistrict_th: "ดอนแตง",
            subdistrict_en: "Don Taeng",
            post_code: "62140",
          },
          {
            subdistrict_th: "วังชะพลู",
            subdistrict_en: "Wang Chaphlu",
            post_code: "62140",
          },
          {
            subdistrict_th: "โค้งไผ่",
            subdistrict_en: "Khong Phai",
            post_code: "62140",
          },
          {
            subdistrict_th: "ปางมะค่า",
            subdistrict_en: "Pang Makha",
            post_code: "62140",
          },
          {
            subdistrict_th: "วังหามแห",
            subdistrict_en: "Wang Ham Hae",
            post_code: "62140",
          },
          {
            subdistrict_th: "เกาะตาล",
            subdistrict_en: "Ko Tan",
            post_code: "62130",
          },
        ],
      },
      {
        district_th: "อำเภอคลองขลุง",
        district_en: "Amphoe Khlong Khlung",
        children: [
          {
            subdistrict_th: "คลองขลุง",
            subdistrict_en: "Khlong Khlung",
            post_code: "62120",
          },
          {
            subdistrict_th: "ท่ามะเขือ",
            subdistrict_en: "Tha Makhuea",
            post_code: "62120",
          },
          {
            subdistrict_th: "ท่าพุทรา",
            subdistrict_en: "Tha Phutsa",
            post_code: "62120",
          },
          {
            subdistrict_th: "แม่ลาด",
            subdistrict_en: "Mae Lat",
            post_code: "62120",
          },
          {
            subdistrict_th: "วังยาง",
            subdistrict_en: "Wang Yang",
            post_code: "62120",
          },
          {
            subdistrict_th: "วังแขม",
            subdistrict_en: "Wang Khaem",
            post_code: "62120",
          },
          {
            subdistrict_th: "หัวถนน",
            subdistrict_en: "Hua Thanon",
            post_code: "62120",
          },
          {
            subdistrict_th: "วังไทร",
            subdistrict_en: "Wang Sai",
            post_code: "62120",
          },
          {
            subdistrict_th: "วังบัว",
            subdistrict_en: "Wang Bua",
            post_code: "62120",
          },
          {
            subdistrict_th: "คลองสมบูรณ์",
            subdistrict_en: "Khlong Sombun",
            post_code: "62120",
          },
        ],
      },
      {
        district_th: "อำเภอพรานกระต่าย",
        district_en: "Amphoe Phran Kratai",
        children: [
          {
            subdistrict_th: "พรานกระต่าย",
            subdistrict_en: "Phran Kratai",
            post_code: "62110",
          },
          {
            subdistrict_th: "หนองหัววัว",
            subdistrict_en: "Nong Hua Wua",
            post_code: "62110",
          },
          {
            subdistrict_th: "ท่าไม้",
            subdistrict_en: "Tha Mai",
            post_code: "62110",
          },
          {
            subdistrict_th: "วังควง",
            subdistrict_en: "Wang Khuang",
            post_code: "62110",
          },
          {
            subdistrict_th: "วังตะแบก",
            subdistrict_en: "Wang Tabaek",
            post_code: "62110",
          },
          {
            subdistrict_th: "เขาคีริส",
            subdistrict_en: "Khao Khirit",
            post_code: "62110",
          },
          {
            subdistrict_th: "คุยบ้านโอง",
            subdistrict_en: "Khui Ban Ong",
            post_code: "62110",
          },
          {
            subdistrict_th: "คลองพิไกร",
            subdistrict_en: "Khlong Phikrai",
            post_code: "62110",
          },
          {
            subdistrict_th: "ถ้ำกระต่ายทอง",
            subdistrict_en: "Tham Kratai Thong",
            post_code: "62110",
          },
          {
            subdistrict_th: "ห้วยยั้ง",
            subdistrict_en: "Huai Yang",
            post_code: "62110",
          },
        ],
      },
      {
        district_th: "อำเภอลานกระบือ",
        district_en: "Amphoe Lan Krabue",
        children: [
          {
            subdistrict_th: "ลานกระบือ",
            subdistrict_en: "Lan Krabue",
            post_code: "62170",
          },
          {
            subdistrict_th: "ช่องลม",
            subdistrict_en: "Chong Lom",
            post_code: "62170",
          },
          {
            subdistrict_th: "หนองหลวง",
            subdistrict_en: "Nong Luang",
            post_code: "62170",
          },
          {
            subdistrict_th: "โนนพลวง",
            subdistrict_en: "Non Phluang",
            post_code: "62170",
          },
          {
            subdistrict_th: "ประชาสุขสันต์",
            subdistrict_en: "Pracha Suk San",
            post_code: "62170",
          },
          {
            subdistrict_th: "บึงทับแรต",
            subdistrict_en: "Bueng Thap Raet",
            post_code: "62170",
          },
          {
            subdistrict_th: "จันทิมา",
            subdistrict_en: "Chanthima",
            post_code: "62170",
          },
        ],
      },
      {
        district_th: "อำเภอทรายทองวัฒนา",
        district_en: "Amphoe Sai Thong Watthana",
        children: [
          {
            subdistrict_th: "ทุ่งทราย",
            subdistrict_en: "Thung Sai",
            post_code: "62190",
          },
          {
            subdistrict_th: "ทุ่งทอง",
            subdistrict_en: "Thung Thong",
            post_code: "62190",
          },
          {
            subdistrict_th: "ถาวรวัฒนา",
            subdistrict_en: "Thawon Watthana",
            post_code: "62190",
          },
        ],
      },
      {
        district_th: "อำเภอปางศิลาทอง",
        district_en: "Amphoe Pang Sila Thong",
        children: [
          {
            subdistrict_th: "โพธิ์ทอง",
            subdistrict_en: "Pho Thong",
            post_code: "62120",
          },
          {
            subdistrict_th: "หินดาต",
            subdistrict_en: "Hin Dat",
            post_code: "62120",
          },
          {
            subdistrict_th: "ปางตาไว",
            subdistrict_en: "Pang Ta Wai",
            post_code: "62120",
          },
        ],
      },
      {
        district_th: "อำเภอบึงสามัคคี",
        district_en: "Amphoe Bueng Samakkhi",
        children: [
          {
            subdistrict_th: "บึงสามัคคี",
            subdistrict_en: "Bueng Samakkhi",
            post_code: "62210",
          },
          {
            subdistrict_th: "วังชะโอน",
            subdistrict_en: "Wang Cha-on",
            post_code: "62210",
          },
          {
            subdistrict_th: "ระหาน",
            subdistrict_en: "Rahan",
            post_code: "62210",
          },
          {
            subdistrict_th: "เทพนิมิต",
            subdistrict_en: "Thep Nimit",
            post_code: "62210",
          },
        ],
      },
      {
        district_th: "อำเภอโกสัมพีนคร",
        district_en: "Amphoe Kosamphi Nakhon",
        children: [
          {
            subdistrict_th: "โกสัมพี",
            subdistrict_en: "Kosamphi",
            post_code: "62000",
          },
          {
            subdistrict_th: "เพชรชมภู",
            subdistrict_en: "Phet Chomphu",
            post_code: "62000",
          },
          {
            subdistrict_th: "ลานดอกไม้ตก",
            subdistrict_en: "Lan Dokmai Tok",
            post_code: "62000",
          },
        ],
      },
    ],
  },
  {
    province_th: "ตาก",
    province_en: "Tak",
    children: [
      {
        district_th: "อำเภอเมืองตาก",
        district_en: "Amphoe Mueang Tak",
        children: [
          {
            subdistrict_th: "ระแหง",
            subdistrict_en: "Rahaeng",
            post_code: "63000",
          },
          {
            subdistrict_th: "หนองหลวง",
            subdistrict_en: "Nong Luang",
            post_code: "63000",
          },
          {
            subdistrict_th: "เชียงเงิน",
            subdistrict_en: "Chiang Ngoen",
            post_code: "63000",
          },
          {
            subdistrict_th: "หัวเดียด",
            subdistrict_en: "Hua Diat",
            post_code: "63000",
          },
          {
            subdistrict_th: "หนองบัวเหนือ",
            subdistrict_en: "Nong Bua Nuea",
            post_code: "63000",
          },
          {
            subdistrict_th: "ไม้งาม",
            subdistrict_en: "Mai Ngam",
            post_code: "63000",
          },
          {
            subdistrict_th: "โป่งแดง",
            subdistrict_en: "Pong Daeng",
            post_code: "63000",
          },
          {
            subdistrict_th: "น้ำรึม",
            subdistrict_en: "Nam Ruem",
            post_code: "63000",
          },
          {
            subdistrict_th: "วังหิน",
            subdistrict_en: "Wang Hin",
            post_code: "63000",
          },
          {
            subdistrict_th: "แม่ท้อ",
            subdistrict_en: "Mae Tho",
            post_code: "63000",
          },
          {
            subdistrict_th: "ป่ามะม่วง",
            subdistrict_en: "Pa Mamuang",
            post_code: "63000",
          },
          {
            subdistrict_th: "หนองบัวใต้",
            subdistrict_en: "Nong Bua Tai",
            post_code: "63000",
          },
          {
            subdistrict_th: "วังประจบ",
            subdistrict_en: "Wang Prachop",
            post_code: "63000",
          },
          {
            subdistrict_th: "ตลุกกลางทุ่ง",
            subdistrict_en: "Taluk Klang Thung",
            post_code: "63000",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านตาก",
        district_en: "Amphoe Ban Tak",
        children: [
          {
            subdistrict_th: "ตากออก",
            subdistrict_en: "Tak Ok",
            post_code: "63120",
          },
          {
            subdistrict_th: "สมอโคน",
            subdistrict_en: "Samo Khon",
            post_code: "63120",
          },
          {
            subdistrict_th: "แม่สลิด",
            subdistrict_en: "Mae Salit",
            post_code: "63120",
          },
          {
            subdistrict_th: "ตากตก",
            subdistrict_en: "Tak Tok",
            post_code: "63120",
          },
          {
            subdistrict_th: "เกาะตะเภา",
            subdistrict_en: "Ko Taphao",
            post_code: "63120",
          },
          {
            subdistrict_th: "ทุ่งกระเชาะ",
            subdistrict_en: "Thung Kracho",
            post_code: "63120",
          },
          {
            subdistrict_th: "ท้องฟ้า",
            subdistrict_en: "Thong Fa",
            post_code: "63120",
          },
        ],
      },
      {
        district_th: "อำเภอสามเงา",
        district_en: "Amphoe Sam Ngao",
        children: [
          {
            subdistrict_th: "สามเงา",
            subdistrict_en: "Sam Ngao",
            post_code: "63130",
          },
          {
            subdistrict_th: "วังหมัน",
            subdistrict_en: "Wang Man",
            post_code: "63130",
          },
          {
            subdistrict_th: "ยกกระบัตร",
            subdistrict_en: "Yokkrabat",
            post_code: "63130",
          },
          {
            subdistrict_th: "ย่านรี",
            subdistrict_en: "Yan Ri",
            post_code: "63130",
          },
          {
            subdistrict_th: "บ้านนา",
            subdistrict_en: "Ban Na",
            post_code: "63130",
          },
          {
            subdistrict_th: "วังจันทร์",
            subdistrict_en: "Wang Chan",
            post_code: "63130",
          },
        ],
      },
      {
        district_th: "อำเภอแม่ระมาด",
        district_en: "Amphoe Mae Ramat",
        children: [
          {
            subdistrict_th: "แม่ระมาด",
            subdistrict_en: "Mae Ramat",
            post_code: "63140",
          },
          {
            subdistrict_th: "แม่จะเรา",
            subdistrict_en: "Mae Charao",
            post_code: "63140",
          },
          {
            subdistrict_th: "ขะเนจื้อ",
            subdistrict_en: "Khane Chue",
            post_code: "63140",
          },
          {
            subdistrict_th: "แม่ตื่น",
            subdistrict_en: "Mae Tuen",
            post_code: "63140",
          },
          {
            subdistrict_th: "สามหมื่น",
            subdistrict_en: "Sam Muen",
            post_code: "63140",
          },
          {
            subdistrict_th: "พระธาตุ",
            subdistrict_en: "Phra That",
            post_code: "63140",
          },
        ],
      },
      {
        district_th: "อำเภอท่าสองยาง",
        district_en: "Amphoe Tha Song Yang",
        children: [
          {
            subdistrict_th: "ท่าสองยาง",
            subdistrict_en: "Tha Song Yang",
            post_code: "63150",
          },
          {
            subdistrict_th: "แม่ต้าน",
            subdistrict_en: "Mae Tan",
            post_code: "63150",
          },
          {
            subdistrict_th: "แม่สอง",
            subdistrict_en: "Mae Song",
            post_code: "63150",
          },
          {
            subdistrict_th: "แม่หละ",
            subdistrict_en: "Mae La",
            post_code: "63150",
          },
          {
            subdistrict_th: "แม่วะหลวง",
            subdistrict_en: "Mae Wa Luang",
            post_code: "63150",
          },
          {
            subdistrict_th: "แม่อุสุ",
            subdistrict_en: "Mae U-su",
            post_code: "63150",
          },
        ],
      },
      {
        district_th: "อำเภอแม่สอด",
        district_en: "Amphoe Mae Sot",
        children: [
          {
            subdistrict_th: "แม่สอด",
            subdistrict_en: "Mae Sot",
            post_code: "63110",
          },
          {
            subdistrict_th: "แม่กุ",
            subdistrict_en: "Mae Ku",
            post_code: "63110",
          },
          {
            subdistrict_th: "พะวอ",
            subdistrict_en: "Phawo",
            post_code: "63110",
          },
          {
            subdistrict_th: "แม่ตาว",
            subdistrict_en: "Mae Tao",
            post_code: "63110",
          },
          {
            subdistrict_th: "แม่กาษา",
            subdistrict_en: "Mae Kasa",
            post_code: "63110",
          },
          {
            subdistrict_th: "ท่าสายลวด",
            subdistrict_en: "Tha Sai Luat",
            post_code: "63110",
          },
          {
            subdistrict_th: "แม่ปะ",
            subdistrict_en: "Mae Pa",
            post_code: "63110",
          },
          {
            subdistrict_th: "มหาวัน",
            subdistrict_en: "Maha Wan",
            post_code: "63110",
          },
          {
            subdistrict_th: "ด่านแม่ละเมา",
            subdistrict_en: "Dan Mae Lamao",
            post_code: "63110",
          },
          {
            subdistrict_th: "พระธาตุผาแดง",
            subdistrict_en: "Phra That Pha Daeng",
            post_code: "63110",
          },
        ],
      },
      {
        district_th: "อำเภอพบพระ",
        district_en: "Amphoe Phop Phra",
        children: [
          {
            subdistrict_th: "พบพระ",
            subdistrict_en: "Phop Phra",
            post_code: "63160",
          },
          {
            subdistrict_th: "ช่องแคบ",
            subdistrict_en: "Chong Khaep",
            post_code: "63160",
          },
          {
            subdistrict_th: "คีรีราษฎร์",
            subdistrict_en: "Khiri Rat",
            post_code: "63160",
          },
          {
            subdistrict_th: "วาเล่ย์",
            subdistrict_en: "Wale",
            post_code: "63160",
          },
          {
            subdistrict_th: "รวมไทยพัฒนา",
            subdistrict_en: "Ruam Thai Phatthana",
            post_code: "63160",
          },
        ],
      },
      {
        district_th: "อำเภออุ้มผาง",
        district_en: "Amphoe Umphang",
        children: [
          {
            subdistrict_th: "อุ้มผาง",
            subdistrict_en: "Umphang",
            post_code: "63170",
          },
          {
            subdistrict_th: "หนองหลวง",
            subdistrict_en: "Nong Luang",
            post_code: "63170",
          },
          {
            subdistrict_th: "โมโกร",
            subdistrict_en: "Mokro",
            post_code: "63170",
          },
          {
            subdistrict_th: "แม่จัน",
            subdistrict_en: "Mae Chan",
            post_code: "63170",
          },
          {
            subdistrict_th: "แม่ละมุ้ง",
            subdistrict_en: "Mae Lamung",
            post_code: "63170",
          },
          {
            subdistrict_th: "แม่กลอง",
            subdistrict_en: "Mae Klong",
            post_code: "63170",
          },
        ],
      },
      {
        district_th: "อำเภอวังเจ้า",
        district_en: "Amphoe Wang Chao",
        children: [
          {
            subdistrict_th: "เชียงทอง",
            subdistrict_en: "Chiang Thong",
            post_code: "63180",
          },
          {
            subdistrict_th: "นาโบสถ์",
            subdistrict_en: "Na Bot",
            post_code: "63180",
          },
          {
            subdistrict_th: "ประดาง",
            subdistrict_en: "Pradang",
            post_code: "63180",
          },
        ],
      },
    ],
  },
  {
    province_th: "สุโขทัย",
    province_en: "Sukhothai",
    children: [
      {
        district_th: "อำเภอเมืองสุโขทัย",
        district_en: "Amphoe Mueang Sukhothai",
        children: [
          {
            subdistrict_th: "ธานี",
            subdistrict_en: "Thani",
            post_code: "64000",
          },
          {
            subdistrict_th: "บ้านสวน",
            subdistrict_en: "Ban Suan",
            post_code: "64220",
          },
          {
            subdistrict_th: "เมืองเก่า",
            subdistrict_en: "Mueang Kao",
            post_code: "64210",
          },
          {
            subdistrict_th: "ปากแคว",
            subdistrict_en: "Pak Khwae",
            post_code: "64000",
          },
          {
            subdistrict_th: "ยางซ้าย",
            subdistrict_en: "Yang Sai",
            post_code: "64000",
          },
          {
            subdistrict_th: "บ้านกล้วย",
            subdistrict_en: "Ban Kluai",
            post_code: "64000",
          },
          {
            subdistrict_th: "บ้านหลุม",
            subdistrict_en: "Ban Lum",
            post_code: "64000",
          },
          {
            subdistrict_th: "ตาลเตี้ย",
            subdistrict_en: "Tan Tia",
            post_code: "64220",
          },
          {
            subdistrict_th: "ปากพระ",
            subdistrict_en: "Pak Phra",
            post_code: "64000",
          },
          {
            subdistrict_th: "วังทองแดง",
            subdistrict_en: "Wang Thong Daeng",
            post_code: "64210",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านด่านลานหอย",
        district_en: "Amphoe Ban Dan Lan Hoi",
        children: [
          {
            subdistrict_th: "ลานหอย",
            subdistrict_en: "Lan Hoi",
            post_code: "64140",
          },
          {
            subdistrict_th: "บ้านด่าน",
            subdistrict_en: "Ban Dan",
            post_code: "64140",
          },
          {
            subdistrict_th: "วังตะคร้อ",
            subdistrict_en: "Wang Takhro",
            post_code: "64140",
          },
          {
            subdistrict_th: "วังน้ำขาว",
            subdistrict_en: "Wang Nam Khao",
            post_code: "64140",
          },
          {
            subdistrict_th: "ตลิ่งชัน",
            subdistrict_en: "Taling Chan",
            post_code: "64140",
          },
          {
            subdistrict_th: "หนองหญ้าปล้อง",
            subdistrict_en: "Nong Ya Plong",
            post_code: "64140",
          },
          {
            subdistrict_th: "วังลึก",
            subdistrict_en: "Wang Luek",
            post_code: "64140",
          },
        ],
      },
      {
        district_th: "อำเภอคีรีมาศ",
        district_en: "Amphoe Khiri Mat",
        children: [
          {
            subdistrict_th: "โตนด",
            subdistrict_en: "Tanot",
            post_code: "64160",
          },
          {
            subdistrict_th: "ทุ่งหลวง",
            subdistrict_en: "Thung Luang",
            post_code: "64160",
          },
          {
            subdistrict_th: "บ้านป้อม",
            subdistrict_en: "Ban Pom",
            post_code: "64160",
          },
          {
            subdistrict_th: "สามพวง",
            subdistrict_en: "Sam Phuang",
            post_code: "64160",
          },
          {
            subdistrict_th: "ศรีคีรีมาศ",
            subdistrict_en: "Si Khiri Mat",
            post_code: "64160",
          },
          {
            subdistrict_th: "หนองจิก",
            subdistrict_en: "Nong Chik",
            post_code: "64160",
          },
          {
            subdistrict_th: "นาเชิงคีรี",
            subdistrict_en: "Na Choeng Khiri",
            post_code: "64160",
          },
          {
            subdistrict_th: "หนองกระดิ่ง",
            subdistrict_en: "Nong Krading",
            post_code: "64160",
          },
          {
            subdistrict_th: "บ้านน้ำพุ",
            subdistrict_en: "Ban Nam Phu",
            post_code: "64160",
          },
          {
            subdistrict_th: "ทุ่งยางเมือง",
            subdistrict_en: "Thung Yang Mueang",
            post_code: "64160",
          },
        ],
      },
      {
        district_th: "อำเภอกงไกรลาศ",
        district_en: "Amphoe Kong Krailat",
        children: [
          {
            subdistrict_th: "กง",
            subdistrict_en: "Kong",
            post_code: "64170",
          },
          {
            subdistrict_th: "บ้านกร่าง",
            subdistrict_en: "Ban Krang",
            post_code: "64170",
          },
          {
            subdistrict_th: "ไกรนอก",
            subdistrict_en: "Krai Nok",
            post_code: "64170",
          },
          {
            subdistrict_th: "ไกรกลาง",
            subdistrict_en: "Krai Klang",
            post_code: "64170",
          },
          {
            subdistrict_th: "ไกรใน",
            subdistrict_en: "Krai Nai",
            post_code: "64170",
          },
          {
            subdistrict_th: "ดงเดือย",
            subdistrict_en: "Dong Dueai",
            post_code: "64170",
          },
          {
            subdistrict_th: "ป่าแฝก",
            subdistrict_en: "Pa Faek",
            post_code: "64170",
          },
          {
            subdistrict_th: "กกแรต",
            subdistrict_en: "Kok Raet",
            post_code: "64170",
          },
          {
            subdistrict_th: "ท่าฉนวน",
            subdistrict_en: "Tha Chanuan",
            post_code: "64170",
          },
          {
            subdistrict_th: "หนองตูม",
            subdistrict_en: "Nong Tum",
            post_code: "64170",
          },
          {
            subdistrict_th: "บ้านใหม่สุขเกษม",
            subdistrict_en: "Ban Mai Suk Kasem",
            post_code: "64170",
          },
        ],
      },
      {
        district_th: "อำเภอศรีสัชนาลัย",
        district_en: "Amphoe Si Satchanalai",
        children: [
          {
            subdistrict_th: "หาดเสี้ยว",
            subdistrict_en: "Hat Siao",
            post_code: "64130",
          },
          {
            subdistrict_th: "ป่างิ้ว",
            subdistrict_en: "Pa Ngio",
            post_code: "64130",
          },
          {
            subdistrict_th: "แม่สำ",
            subdistrict_en: "Mae Sam",
            post_code: "64130",
          },
          {
            subdistrict_th: "แม่สิน",
            subdistrict_en: "Mae Sin",
            post_code: "64130",
          },
          {
            subdistrict_th: "บ้านตึก",
            subdistrict_en: "Ban Tuek",
            post_code: "64130",
          },
          {
            subdistrict_th: "หนองอ้อ",
            subdistrict_en: "Nong O",
            post_code: "64130",
          },
          {
            subdistrict_th: "ท่าชัย",
            subdistrict_en: "Tha Chai",
            post_code: "64190",
          },
          {
            subdistrict_th: "ศรีสัชนาลัย",
            subdistrict_en: "Si Satchanalai",
            post_code: "64190",
          },
          {
            subdistrict_th: "ดงคู่",
            subdistrict_en: "Dong Khu",
            post_code: "64130",
          },
          {
            subdistrict_th: "บ้านแก่ง",
            subdistrict_en: "Ban Kaeng",
            post_code: "64130",
          },
          {
            subdistrict_th: "สารจิตร",
            subdistrict_en: "San Chit",
            post_code: "64130",
          },
        ],
      },
      {
        district_th: "อำเภอศรีสำโรง",
        district_en: "Amphoe Si Samrong",
        children: [
          {
            subdistrict_th: "คลองตาล",
            subdistrict_en: "Khlong Tan",
            post_code: "64120",
          },
          {
            subdistrict_th: "วังลึก",
            subdistrict_en: "Wang Luek",
            post_code: "64120",
          },
          {
            subdistrict_th: "สามเรือน",
            subdistrict_en: "Sam Ruean",
            post_code: "64120",
          },
          {
            subdistrict_th: "บ้านนา",
            subdistrict_en: "Ban Na",
            post_code: "64120",
          },
          {
            subdistrict_th: "วังทอง",
            subdistrict_en: "Wang Thong",
            post_code: "64120",
          },
          {
            subdistrict_th: "นาขุนไกร",
            subdistrict_en: "Na Khun Krai",
            post_code: "64120",
          },
          {
            subdistrict_th: "เกาะตาเลี้ยง",
            subdistrict_en: "Ko Ta Liang",
            post_code: "64120",
          },
          {
            subdistrict_th: "วัดเกาะ",
            subdistrict_en: "Wat Ko",
            post_code: "64120",
          },
          {
            subdistrict_th: "บ้านไร่",
            subdistrict_en: "Ban Rai",
            post_code: "64120",
          },
          {
            subdistrict_th: "ทับผึ้ง",
            subdistrict_en: "Thap Phueng",
            post_code: "64120",
          },
          {
            subdistrict_th: "บ้านซ่าน",
            subdistrict_en: "Ban San",
            post_code: "64120",
          },
          {
            subdistrict_th: "วังใหญ่",
            subdistrict_en: "Wang Yai",
            post_code: "64120",
          },
          {
            subdistrict_th: "ราวต้นจันทร์",
            subdistrict_en: "Rao Ton Chan",
            post_code: "64120",
          },
        ],
      },
      {
        district_th: "อำเภอสวรรคโลก",
        district_en: "Amphoe Sawankhalok",
        children: [
          {
            subdistrict_th: "เมืองสวรรคโลก",
            subdistrict_en: "Mueang Sawankhalok",
            post_code: "64110",
          },
          {
            subdistrict_th: "ในเมือง",
            subdistrict_en: "Nai Mueang",
            post_code: "64110",
          },
          {
            subdistrict_th: "คลองกระจง",
            subdistrict_en: "Khlong Krachong",
            post_code: "64110",
          },
          {
            subdistrict_th: "วังพิณพาทย์",
            subdistrict_en: "Wang Phinphat",
            post_code: "64110",
          },
          {
            subdistrict_th: "วังไม้ขอน",
            subdistrict_en: "Wang Mai Khon",
            post_code: "64110",
          },
          {
            subdistrict_th: "ย่านยาว",
            subdistrict_en: "Yan Yao",
            post_code: "64110",
          },
          {
            subdistrict_th: "นาทุ่ง",
            subdistrict_en: "Na Thung",
            post_code: "64110",
          },
          {
            subdistrict_th: "คลองยาง",
            subdistrict_en: "Khlong Yang",
            post_code: "64110",
          },
          {
            subdistrict_th: "เมืองบางยม",
            subdistrict_en: "Mueang Bang Yom",
            post_code: "64110",
          },
          {
            subdistrict_th: "ท่าทอง",
            subdistrict_en: "Tha Tong",
            post_code: "64110",
          },
          {
            subdistrict_th: "ปากน้ำ",
            subdistrict_en: "Pak Nam",
            post_code: "64110",
          },
          {
            subdistrict_th: "ป่ากุมเกาะ",
            subdistrict_en: "Pa Kum Ko",
            post_code: "64110",
          },
          {
            subdistrict_th: "เมืองบางขลัง",
            subdistrict_en: "Mueang Bang Khlang",
            post_code: "64110",
          },
          {
            subdistrict_th: "หนองกลับ",
            subdistrict_en: "Nong Klap",
            post_code: "64110",
          },
        ],
      },
      {
        district_th: "อำเภอศรีนคร",
        district_en: "Amphoe Si Nakhon",
        children: [
          {
            subdistrict_th: "ศรีนคร",
            subdistrict_en: "Si Nakhon",
            post_code: "64180",
          },
          {
            subdistrict_th: "นครเดิฐ",
            subdistrict_en: "Nakhon Doet",
            post_code: "64180",
          },
          {
            subdistrict_th: "น้ำขุม",
            subdistrict_en: "Nam Khum",
            post_code: "64180",
          },
          {
            subdistrict_th: "คลองมะพลับ",
            subdistrict_en: "Khlong Maphlap",
            post_code: "64180",
          },
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "64180",
          },
        ],
      },
      {
        district_th: "อำเภอทุ่งเสลี่ยม",
        district_en: "Amphoe Thung Saliam",
        children: [
          {
            subdistrict_th: "บ้านใหม่ไชยมงคล",
            subdistrict_en: "Ban Mai Chai Mongkhon",
            post_code: "64230",
          },
          {
            subdistrict_th: "ไทยชนะศึก",
            subdistrict_en: "Thai Chana Suek",
            post_code: "64150",
          },
          {
            subdistrict_th: "ทุ่งเสลี่ยม",
            subdistrict_en: "Thung Saliam",
            post_code: "64150",
          },
          {
            subdistrict_th: "กลางดง",
            subdistrict_en: "Klang Dong",
            post_code: "64150",
          },
          {
            subdistrict_th: "เขาแก้วศรีสมบูรณ์",
            subdistrict_en: "Khao Kaeo Si Sombun",
            post_code: "64230",
          },
        ],
      },
    ],
  },
  {
    province_th: "พิษณุโลก",
    province_en: "Phitsanulok",
    children: [
      {
        district_th: "อำเภอเมืองพิษณุโลก",
        district_en: "Amphoe Mueang Phitsanulok",
        children: [
          {
            subdistrict_th: "ในเมือง",
            subdistrict_en: "Nai Mueang",
            post_code: "65000",
          },
          {
            subdistrict_th: "วังน้ำคู้",
            subdistrict_en: "Wang Nam Khu",
            post_code: "65230",
          },
          {
            subdistrict_th: "วัดจันทร์",
            subdistrict_en: "Wat Chan",
            post_code: "65000",
          },
          {
            subdistrict_th: "วัดพริก",
            subdistrict_en: "Wat Phrik",
            post_code: "65230",
          },
          {
            subdistrict_th: "ท่าทอง",
            subdistrict_en: "Tha Thong",
            post_code: "65000",
          },
          {
            subdistrict_th: "ท่าโพธิ์",
            subdistrict_en: "Tha Pho",
            post_code: "65000",
          },
          {
            subdistrict_th: "สมอแข",
            subdistrict_en: "Samo Khae",
            post_code: "65000",
          },
          {
            subdistrict_th: "ดอนทอง",
            subdistrict_en: "Don Thong",
            post_code: "65000",
          },
          {
            subdistrict_th: "บ้านป่า",
            subdistrict_en: "Ban Pa",
            post_code: "65000",
          },
          {
            subdistrict_th: "ปากโทก",
            subdistrict_en: "Pak Thok",
            post_code: "65000",
          },
          {
            subdistrict_th: "หัวรอ",
            subdistrict_en: "Hua Ro",
            post_code: "65000",
          },
          {
            subdistrict_th: "จอมทอง",
            subdistrict_en: "Chom Thong",
            post_code: "65000",
          },
          {
            subdistrict_th: "บ้านกร่าง",
            subdistrict_en: "Ban Krang",
            post_code: "65000",
          },
          {
            subdistrict_th: "บ้านคลอง",
            subdistrict_en: "Ban Khlong",
            post_code: "65000",
          },
          {
            subdistrict_th: "พลายชุมพล",
            subdistrict_en: "Phlai Chumphon",
            post_code: "65000",
          },
          {
            subdistrict_th: "มะขามสูง",
            subdistrict_en: "Makham Sung",
            post_code: "65000",
          },
          {
            subdistrict_th: "อรัญญิก",
            subdistrict_en: "Aranyik",
            post_code: "65000",
          },
          {
            subdistrict_th: "บึงพระ",
            subdistrict_en: "Bueng Phra",
            post_code: "65000",
          },
          {
            subdistrict_th: "ไผ่ขอดอน",
            subdistrict_en: "Phai Kho Don",
            post_code: "65000",
          },
          {
            subdistrict_th: "งิ้วงาม",
            subdistrict_en: "Ngio Ngam",
            post_code: "65230",
          },
        ],
      },
      {
        district_th: "อำเภอนครไทย",
        district_en: "Amphoe Nakhon Thai",
        children: [
          {
            subdistrict_th: "นครไทย",
            subdistrict_en: "Nakhon Thai",
            post_code: "65120",
          },
          {
            subdistrict_th: "หนองกะท้าว",
            subdistrict_en: "Nong Kathao",
            post_code: "65120",
          },
          {
            subdistrict_th: "บ้านแยง",
            subdistrict_en: "Ban Yaeng",
            post_code: "65120",
          },
          {
            subdistrict_th: "เนินเพิ่ม",
            subdistrict_en: "Noen Phoem",
            post_code: "65120",
          },
          {
            subdistrict_th: "นาบัว",
            subdistrict_en: "Na Bua",
            post_code: "65120",
          },
          {
            subdistrict_th: "นครชุม",
            subdistrict_en: "Nakhon Chum",
            post_code: "65120",
          },
          {
            subdistrict_th: "น้ำกุ่ม",
            subdistrict_en: "Nam Kum",
            post_code: "65120",
          },
          {
            subdistrict_th: "ยางโกลน",
            subdistrict_en: "Yang Klon",
            post_code: "65120",
          },
          {
            subdistrict_th: "บ่อโพธิ์",
            subdistrict_en: "Bo Pho",
            post_code: "65120",
          },
          {
            subdistrict_th: "บ้านพร้าว",
            subdistrict_en: "Ban Phrao",
            post_code: "65120",
          },
          {
            subdistrict_th: "ห้วยเฮี้ย",
            subdistrict_en: "Huai Hia",
            post_code: "65120",
          },
        ],
      },
      {
        district_th: "อำเภอชาติตระการ",
        district_en: "Amphoe Chat Trakan",
        children: [
          {
            subdistrict_th: "ป่าแดง",
            subdistrict_en: "Pa Daeng",
            post_code: "65170",
          },
          {
            subdistrict_th: "ชาติตระการ",
            subdistrict_en: "Chat Trakan",
            post_code: "65170",
          },
          {
            subdistrict_th: "สวนเมี่ยง",
            subdistrict_en: "Suan Miang",
            post_code: "65170",
          },
          {
            subdistrict_th: "บ้านดง",
            subdistrict_en: "Ban Dong",
            post_code: "65170",
          },
          {
            subdistrict_th: "บ่อภาค",
            subdistrict_en: "Bo Phak",
            post_code: "65170",
          },
          {
            subdistrict_th: "ท่าสะแก",
            subdistrict_en: "Tha Sakae",
            post_code: "65170",
          },
        ],
      },
      {
        district_th: "อำเภอบางระกำ",
        district_en: "Amphoe Bang Rakam",
        children: [
          {
            subdistrict_th: "บางระกำ",
            subdistrict_en: "Bang Rakam",
            post_code: "65140",
          },
          {
            subdistrict_th: "ปลักแรด",
            subdistrict_en: "Plak Raet",
            post_code: "65140",
          },
          {
            subdistrict_th: "พันเสา",
            subdistrict_en: "Phan Sao",
            post_code: "65140",
          },
          {
            subdistrict_th: "วังอิทก",
            subdistrict_en: "Wang Ithok",
            post_code: "65140",
          },
          {
            subdistrict_th: "บึงกอก",
            subdistrict_en: "Bueng Kok",
            post_code: "65140",
          },
          {
            subdistrict_th: "หนองกุลา",
            subdistrict_en: "Nong Kula",
            post_code: "65140",
          },
          {
            subdistrict_th: "ชุมแสงสงคราม",
            subdistrict_en: "Chum Saeng Songkhram",
            post_code: "65240",
          },
          {
            subdistrict_th: "นิคมพัฒนา",
            subdistrict_en: "Nikhom Phatthana",
            post_code: "65140",
          },
          {
            subdistrict_th: "บ่อทอง",
            subdistrict_en: "Bo Thong",
            post_code: "65140",
          },
          {
            subdistrict_th: "ท่านางงาม",
            subdistrict_en: "Tha Nang Ngam",
            post_code: "65140",
          },
          {
            subdistrict_th: "คุยม่วง",
            subdistrict_en: "Khui Muang",
            post_code: "65240",
          },
        ],
      },
      {
        district_th: "อำเภอบางกระทุ่ม",
        district_en: "Amphoe Bang Krathum",
        children: [
          {
            subdistrict_th: "บางกระทุ่ม",
            subdistrict_en: "Bang Krathum",
            post_code: "65110",
          },
          {
            subdistrict_th: "บ้านไร่",
            subdistrict_en: "Ban Rai",
            post_code: "65110",
          },
          {
            subdistrict_th: "โคกสลุด",
            subdistrict_en: "Khok Salut",
            post_code: "65110",
          },
          {
            subdistrict_th: "สนามคลี",
            subdistrict_en: "Sanam Khli",
            post_code: "65110",
          },
          {
            subdistrict_th: "ท่าตาล",
            subdistrict_en: "Tha Tan",
            post_code: "65110",
          },
          {
            subdistrict_th: "ไผ่ล้อม",
            subdistrict_en: "Phai Lom",
            post_code: "65110",
          },
          {
            subdistrict_th: "นครป่าหมาก",
            subdistrict_en: "Nakhon Pa Mak",
            post_code: "65110",
          },
          {
            subdistrict_th: "เนินกุ่ม",
            subdistrict_en: "Noen Kum",
            post_code: "65210",
          },
          {
            subdistrict_th: "วัดตายม",
            subdistrict_en: "Wat Ta Yom",
            post_code: "65210",
          },
        ],
      },
      {
        district_th: "อำเภอพรหมพิราม",
        district_en: "Amphoe Phrom Phiram",
        children: [
          {
            subdistrict_th: "พรหมพิราม",
            subdistrict_en: "Phrom Phiram",
            post_code: "65150",
          },
          {
            subdistrict_th: "ท่าช้าง",
            subdistrict_en: "Tha Chang",
            post_code: "65150",
          },
          {
            subdistrict_th: "วงฆ้อง",
            subdistrict_en: "Wong Khong",
            post_code: "65180",
          },
          {
            subdistrict_th: "มะตูม",
            subdistrict_en: "Matum",
            post_code: "65150",
          },
          {
            subdistrict_th: "หอกลอง",
            subdistrict_en: "Ho Klong",
            post_code: "65150",
          },
          {
            subdistrict_th: "ศรีภิรมย์",
            subdistrict_en: "Si Phirom",
            post_code: "65180",
          },
          {
            subdistrict_th: "ตลุกเทียม",
            subdistrict_en: "Taluk Thiam",
            post_code: "65180",
          },
          {
            subdistrict_th: "วังวน",
            subdistrict_en: "Wang Won",
            post_code: "65150",
          },
          {
            subdistrict_th: "หนองแขม",
            subdistrict_en: "Nong Khaem",
            post_code: "65150",
          },
          {
            subdistrict_th: "มะต้อง",
            subdistrict_en: "Matong",
            post_code: "65180",
          },
          {
            subdistrict_th: "ทับยายเชียง",
            subdistrict_en: "Thap Yai Chiang",
            post_code: "65150",
          },
          {
            subdistrict_th: "ดงประคำ",
            subdistrict_en: "Dong Prakham",
            post_code: "65180",
          },
        ],
      },
      {
        district_th: "อำเภอวัดโบสถ์",
        district_en: "Amphoe Wat Bot",
        children: [
          {
            subdistrict_th: "วัดโบสถ์",
            subdistrict_en: "Wat Bot",
            post_code: "65160",
          },
          {
            subdistrict_th: "ท่างาม",
            subdistrict_en: "Tha Ngam",
            post_code: "65160",
          },
          {
            subdistrict_th: "ท้อแท้",
            subdistrict_en: "Thothae",
            post_code: "65160",
          },
          {
            subdistrict_th: "บ้านยาง",
            subdistrict_en: "Ban Yang",
            post_code: "65160",
          },
          {
            subdistrict_th: "หินลาด",
            subdistrict_en: "Hin Lat",
            post_code: "65160",
          },
          {
            subdistrict_th: "คันโช้ง",
            subdistrict_en: "Khan Chong",
            post_code: "65160",
          },
        ],
      },
      {
        district_th: "อำเภอวังทอง",
        district_en: "Amphoe Wang Thong",
        children: [
          {
            subdistrict_th: "วังทอง",
            subdistrict_en: "Wang Thong",
            post_code: "65130",
          },
          {
            subdistrict_th: "พันชาลี",
            subdistrict_en: "Phan Chali",
            post_code: "65130",
          },
          {
            subdistrict_th: "แม่ระกา",
            subdistrict_en: "Mae Raka",
            post_code: "65130",
          },
          {
            subdistrict_th: "บ้านกลาง",
            subdistrict_en: "Ban Klang",
            post_code: "65220",
          },
          {
            subdistrict_th: "วังพิกุล",
            subdistrict_en: "Wang Phikun",
            post_code: "65130",
          },
          {
            subdistrict_th: "แก่งโสภา",
            subdistrict_en: "Kaeng Sopha",
            post_code: "65220",
          },
          {
            subdistrict_th: "ท่าหมื่นราม",
            subdistrict_en: "Tha Muen Ram",
            post_code: "65130",
          },
          {
            subdistrict_th: "วังนกแอ่น",
            subdistrict_en: "Wang Nok Aen",
            post_code: "65130",
          },
          {
            subdistrict_th: "หนองพระ",
            subdistrict_en: "Nong Phra",
            post_code: "65130",
          },
          {
            subdistrict_th: "ชัยนาม",
            subdistrict_en: "Chai Nam",
            post_code: "65130",
          },
          {
            subdistrict_th: "ดินทอง",
            subdistrict_en: "Din Thong",
            post_code: "65130",
          },
        ],
      },
      {
        district_th: "อำเภอเนินมะปราง",
        district_en: "Amphoe Noen Maprang",
        children: [
          {
            subdistrict_th: "ชมพู",
            subdistrict_en: "Chomphu",
            post_code: "65190",
          },
          {
            subdistrict_th: "บ้านมุง",
            subdistrict_en: "Ban Mung",
            post_code: "65190",
          },
          {
            subdistrict_th: "ไทรย้อย",
            subdistrict_en: "Sai Yoi",
            post_code: "65190",
          },
          {
            subdistrict_th: "วังโพรง",
            subdistrict_en: "Wang Phrong",
            post_code: "65190",
          },
          {
            subdistrict_th: "บ้านน้อยซุ้มขี้เหล็ก",
            subdistrict_en: "Ban Noi Sum Khilek",
            post_code: "65190",
          },
          {
            subdistrict_th: "เนินมะปราง",
            subdistrict_en: "Noen Maprang",
            post_code: "65190",
          },
          {
            subdistrict_th: "วังยาง",
            subdistrict_en: "Wang Yang",
            post_code: "65190",
          },
        ],
      },
    ],
  },
  {
    province_th: "พิจิตร",
    province_en: "Phichit",
    children: [
      {
        district_th: "อำเภอเมืองพิจิตร",
        district_en: "Amphoe Mueang Phichit",
        children: [
          {
            subdistrict_th: "ในเมือง",
            subdistrict_en: "Nai Mueang",
            post_code: "66000",
          },
          {
            subdistrict_th: "ไผ่ขวาง",
            subdistrict_en: "Phai Khwang",
            post_code: "66000",
          },
          {
            subdistrict_th: "ย่านยาว",
            subdistrict_en: "Yan Yao",
            post_code: "66000",
          },
          {
            subdistrict_th: "ท่าฬ่อ",
            subdistrict_en: "Tha Lo",
            post_code: "66000",
          },
          {
            subdistrict_th: "ปากทาง",
            subdistrict_en: "Pak Thang",
            post_code: "66000",
          },
          {
            subdistrict_th: "คลองคะเชนทร์",
            subdistrict_en: "Khlong Khachen",
            post_code: "66000",
          },
          {
            subdistrict_th: "โรงช้าง",
            subdistrict_en: "Rong Chang",
            post_code: "66000",
          },
          {
            subdistrict_th: "เมืองเก่า",
            subdistrict_en: "Mueang Kao",
            post_code: "66000",
          },
          {
            subdistrict_th: "ท่าหลวง",
            subdistrict_en: "Tha Luang",
            post_code: "66000",
          },
          {
            subdistrict_th: "บ้านบุ่ง",
            subdistrict_en: "Ban Bung",
            post_code: "66000",
          },
          {
            subdistrict_th: "ฆะมัง",
            subdistrict_en: "Kha Mung",
            post_code: "66000",
          },
          {
            subdistrict_th: "ดงป่าคำ",
            subdistrict_en: "Dong Pa Kham",
            post_code: "66170",
          },
          {
            subdistrict_th: "หัวดง",
            subdistrict_en: "Hua Dong",
            post_code: "66170",
          },
          {
            subdistrict_th: "ป่ามะคาบ",
            subdistrict_en: "Pa Makhap",
            post_code: "66000",
          },
          {
            subdistrict_th: "สายคำโห้",
            subdistrict_en: "Sai Kham Ho",
            post_code: "66000",
          },
          {
            subdistrict_th: "ดงกลาง",
            subdistrict_en: "Dong Klang",
            post_code: "66170",
          },
        ],
      },
      {
        district_th: "อำเภอวังทรายพูน",
        district_en: "Amphoe Wang Sai Phun",
        children: [
          {
            subdistrict_th: "วังทรายพูน",
            subdistrict_en: "Wang Sai Phun",
            post_code: "66180",
          },
          {
            subdistrict_th: "หนองปลาไหล",
            subdistrict_en: "Nong Pla Lai",
            post_code: "66180",
          },
          {
            subdistrict_th: "หนองพระ",
            subdistrict_en: "Nong Phra",
            post_code: "66180",
          },
          {
            subdistrict_th: "หนองปล้อง",
            subdistrict_en: "Nong Plong",
            post_code: "66180",
          },
        ],
      },
      {
        district_th: "อำเภอโพธิ์ประทับช้าง",
        district_en: "Amphoe Pho Prathap Chang",
        children: [
          {
            subdistrict_th: "โพธิ์ประทับช้าง",
            subdistrict_en: "Pho Prathap Chang",
            post_code: "66190",
          },
          {
            subdistrict_th: "ไผ่ท่าโพ",
            subdistrict_en: "Phai Tha Pho",
            post_code: "66190",
          },
          {
            subdistrict_th: "วังจิก",
            subdistrict_en: "Wang Chik",
            post_code: "66190",
          },
          {
            subdistrict_th: "ไผ่รอบ",
            subdistrict_en: "Phai Rop",
            post_code: "66190",
          },
          {
            subdistrict_th: "ดงเสือเหลือง",
            subdistrict_en: "Dong Suea Lueang",
            post_code: "66190",
          },
          {
            subdistrict_th: "เนินสว่าง",
            subdistrict_en: "Noen Sawang",
            post_code: "66190",
          },
          {
            subdistrict_th: "ทุ่งใหญ่",
            subdistrict_en: "Thung Yai",
            post_code: "66190",
          },
        ],
      },
      {
        district_th: "อำเภอตะพานหิน",
        district_en: "Amphoe Taphan Hin",
        children: [
          {
            subdistrict_th: "ตะพานหิน",
            subdistrict_en: "Taphan Hin",
            post_code: "66110",
          },
          {
            subdistrict_th: "งิ้วราย",
            subdistrict_en: "Ngio Rai",
            post_code: "66110",
          },
          {
            subdistrict_th: "ห้วยเกตุ",
            subdistrict_en: "Huai Ket",
            post_code: "66110",
          },
          {
            subdistrict_th: "ไทรโรงโขน",
            subdistrict_en: "Sai Rong Khon",
            post_code: "66110",
          },
          {
            subdistrict_th: "หนองพยอม",
            subdistrict_en: "Nong Phayom",
            post_code: "66110",
          },
          {
            subdistrict_th: "ทุ่งโพธิ์",
            subdistrict_en: "Thung Pho",
            post_code: "66150",
          },
          {
            subdistrict_th: "ดงตะขบ",
            subdistrict_en: "Dong Takhop",
            post_code: "66110",
          },
          {
            subdistrict_th: "คลองคูณ",
            subdistrict_en: "Khlong Khun",
            post_code: "66110",
          },
          {
            subdistrict_th: "วังสำโรง",
            subdistrict_en: "Wang Samrong",
            post_code: "66110",
          },
          {
            subdistrict_th: "วังหว้า",
            subdistrict_en: "Wang Wa",
            post_code: "66110",
          },
          {
            subdistrict_th: "วังหลุม",
            subdistrict_en: "Wang Lum",
            post_code: "66150",
          },
          {
            subdistrict_th: "ทับหมัน",
            subdistrict_en: "Thap Man",
            post_code: "66110",
          },
          {
            subdistrict_th: "ไผ่หลวง",
            subdistrict_en: "Phai Luang",
            post_code: "66110",
          },
        ],
      },
      {
        district_th: "อำเภอบางมูลนาก",
        district_en: "Amphoe Bang Mun Nak",
        children: [
          {
            subdistrict_th: "บางมูลนาก",
            subdistrict_en: "Bang Mun Nak",
            post_code: "66120",
          },
          {
            subdistrict_th: "บางไผ่",
            subdistrict_en: "Bang Phai",
            post_code: "66120",
          },
          {
            subdistrict_th: "หอไกร",
            subdistrict_en: "Ho Krai",
            post_code: "66120",
          },
          {
            subdistrict_th: "เนินมะกอก",
            subdistrict_en: "Noen Makok",
            post_code: "66120",
          },
          {
            subdistrict_th: "วังสำโรง",
            subdistrict_en: "Wang Samrong",
            post_code: "66120",
          },
          {
            subdistrict_th: "ภูมิ",
            subdistrict_en: "Phum",
            post_code: "66120",
          },
          {
            subdistrict_th: "วังกรด",
            subdistrict_en: "Wang Krot",
            post_code: "66120",
          },
          {
            subdistrict_th: "ห้วยเขน",
            subdistrict_en: "Huai Khen",
            post_code: "66120",
          },
          {
            subdistrict_th: "วังตะกู",
            subdistrict_en: "Wang Taku",
            post_code: "66210",
          },
          {
            subdistrict_th: "ลำประดา",
            subdistrict_en: "Lam Prada",
            post_code: "66120",
          },
        ],
      },
      {
        district_th: "อำเภอโพทะเล",
        district_en: "Amphoe Pho Thale",
        children: [
          {
            subdistrict_th: "โพทะเล",
            subdistrict_en: "Pho Thale",
            post_code: "66130",
          },
          {
            subdistrict_th: "ท้ายน้ำ",
            subdistrict_en: "Thai Nam",
            post_code: "66130",
          },
          {
            subdistrict_th: "ทะนง",
            subdistrict_en: "Thanong",
            post_code: "66130",
          },
          {
            subdistrict_th: "ท่าบัว",
            subdistrict_en: "Tha Bua",
            post_code: "66130",
          },
          {
            subdistrict_th: "ทุ่งน้อย",
            subdistrict_en: "Thung Noi",
            post_code: "66130",
          },
          {
            subdistrict_th: "ท่าขมิ้น",
            subdistrict_en: "Tha Khamin",
            post_code: "66130",
          },
          {
            subdistrict_th: "ท่าเสา",
            subdistrict_en: "Tha Sao",
            post_code: "66130",
          },
          {
            subdistrict_th: "บางคลาน",
            subdistrict_en: "Bang Khlan",
            post_code: "66130",
          },
          {
            subdistrict_th: "ท่านั่ง",
            subdistrict_en: "Tha Nang",
            post_code: "66130",
          },
          {
            subdistrict_th: "บ้านน้อย",
            subdistrict_en: "Ban Noi",
            post_code: "66130",
          },
          {
            subdistrict_th: "วัดขวาง",
            subdistrict_en: "Wat Khwang",
            post_code: "66130",
          },
        ],
      },
      {
        district_th: "อำเภอสามง่าม",
        district_en: "Amphoe Sam Ngam",
        children: [
          {
            subdistrict_th: "สามง่าม",
            subdistrict_en: "Sam Ngam",
            post_code: "66140",
          },
          {
            subdistrict_th: "กำแพงดิน",
            subdistrict_en: "Kamphaeng Din",
            post_code: "66220",
          },
          {
            subdistrict_th: "รังนก",
            subdistrict_en: "Rang Nok",
            post_code: "66140",
          },
          {
            subdistrict_th: "เนินปอ",
            subdistrict_en: "Noen Po",
            post_code: "66140",
          },
          {
            subdistrict_th: "หนองโสน",
            subdistrict_en: "Nong Sano",
            post_code: "66140",
          },
        ],
      },
      {
        district_th: "อำเภอทับคล้อ",
        district_en: "Amphoe Thap Khlo",
        children: [
          {
            subdistrict_th: "ทับคล้อ",
            subdistrict_en: "Thap Khlo",
            post_code: "66150",
          },
          {
            subdistrict_th: "เขาทราย",
            subdistrict_en: "Khao Sai",
            post_code: "66230",
          },
          {
            subdistrict_th: "เขาเจ็ดลูก",
            subdistrict_en: "Khao Chet Luk",
            post_code: "66230",
          },
          {
            subdistrict_th: "ท้ายทุ่ง",
            subdistrict_en: "Thai Thung",
            post_code: "66150",
          },
        ],
      },
      {
        district_th: "อำเภอสากเหล็ก",
        district_en: "Amphoe Sak Lek",
        children: [
          {
            subdistrict_th: "สากเหล็ก",
            subdistrict_en: "Sak Lek",
            post_code: "66160",
          },
          {
            subdistrict_th: "ท่าเยี่ยม",
            subdistrict_en: "Tha Yiam",
            post_code: "66160",
          },
          {
            subdistrict_th: "คลองทราย",
            subdistrict_en: "Khlong Sai",
            post_code: "66160",
          },
          {
            subdistrict_th: "หนองหญ้าไทร",
            subdistrict_en: "Nong Ya Sai",
            post_code: "66160",
          },
          {
            subdistrict_th: "วังทับไทร",
            subdistrict_en: "Wang Thap Sai",
            post_code: "66160",
          },
        ],
      },
      {
        district_th: "อำเภอบึงนาราง",
        district_en: "Amphoe Bueng Na Rang",
        children: [
          {
            subdistrict_th: "ห้วยแก้ว",
            subdistrict_en: "Huai Kaeo",
            post_code: "66130",
          },
          {
            subdistrict_th: "โพธิ์ไทรงาม",
            subdistrict_en: "Pho Sai Ngam",
            post_code: "66130",
          },
          {
            subdistrict_th: "แหลมรัง",
            subdistrict_en: "Laem Rang",
            post_code: "66130",
          },
          {
            subdistrict_th: "บางลาย",
            subdistrict_en: "Bang Lai",
            post_code: "66130",
          },
          {
            subdistrict_th: "บึงนาราง",
            subdistrict_en: "Bueng Na Rang",
            post_code: "66130",
          },
        ],
      },
      {
        district_th: "อำเภอดงเจริญ",
        district_en: "Amphoe Dong Charoen",
        children: [
          {
            subdistrict_th: "วังงิ้วใต้",
            subdistrict_en: "Wang Ngio Tai",
            post_code: "66210",
          },
          {
            subdistrict_th: "วังงิ้ว",
            subdistrict_en: "Wang Ngio",
            post_code: "66210",
          },
          {
            subdistrict_th: "ห้วยร่วม",
            subdistrict_en: "Huai Ruam",
            post_code: "66210",
          },
          {
            subdistrict_th: "ห้วยพุก",
            subdistrict_en: "Huai Phuk",
            post_code: "66210",
          },
          {
            subdistrict_th: "สำนักขุนเณร",
            subdistrict_en: "Samnak Khun Nen",
            post_code: "66210",
          },
        ],
      },
      {
        district_th: "อำเภอวชิรบารมี",
        district_en: "Amphoe Wachirabarami",
        children: [
          {
            subdistrict_th: "บ้านนา",
            subdistrict_en: "Ban Na",
            post_code: "66140",
          },
          {
            subdistrict_th: "บึงบัว",
            subdistrict_en: "Bueng Bua",
            post_code: "66140",
          },
          {
            subdistrict_th: "วังโมกข์",
            subdistrict_en: "Wang Mok",
            post_code: "66140",
          },
          {
            subdistrict_th: "หนองหลุม",
            subdistrict_en: "Nong Lum",
            post_code: "66220",
          },
        ],
      },
    ],
  },
  {
    province_th: "เพชรบูรณ์",
    province_en: "Phetchabun",
    children: [
      {
        district_th: "อำเภอเมืองเพชรบูรณ์",
        district_en: "Amphoe Mueang Phetchabun",
        children: [
          {
            subdistrict_th: "ในเมือง",
            subdistrict_en: "Nai Mueang",
            post_code: "67000",
          },
          {
            subdistrict_th: "ตะเบาะ",
            subdistrict_en: "Tabo",
            post_code: "67000",
          },
          {
            subdistrict_th: "บ้านโตก",
            subdistrict_en: "Ban Tok",
            post_code: "67000",
          },
          {
            subdistrict_th: "สะเดียง",
            subdistrict_en: "Sadiang",
            post_code: "67000",
          },
          {
            subdistrict_th: "ป่าเลา",
            subdistrict_en: "Pa Lao",
            post_code: "67000",
          },
          {
            subdistrict_th: "นางั่ว",
            subdistrict_en: "Na Ngua",
            post_code: "67000",
          },
          {
            subdistrict_th: "ท่าพล",
            subdistrict_en: "Tha Phon",
            post_code: "67250",
          },
          {
            subdistrict_th: "ดงมูลเหล็ก",
            subdistrict_en: "Dong Mun Lek",
            post_code: "67000",
          },
          {
            subdistrict_th: "บ้านโคก",
            subdistrict_en: "Ban Khok",
            post_code: "67000",
          },
          {
            subdistrict_th: "ชอนไพร",
            subdistrict_en: "Chon Phrai",
            post_code: "67000",
          },
          {
            subdistrict_th: "นาป่า",
            subdistrict_en: "Na Pa",
            post_code: "67000",
          },
          {
            subdistrict_th: "นายม",
            subdistrict_en: "Na Yom",
            post_code: "67210",
          },
          {
            subdistrict_th: "วังชมภู",
            subdistrict_en: "Wang Chomphu",
            post_code: "67210",
          },
          {
            subdistrict_th: "น้ำร้อน",
            subdistrict_en: "Nam Ron",
            post_code: "67000",
          },
          {
            subdistrict_th: "ห้วยสะแก",
            subdistrict_en: "Huai Sakae",
            post_code: "67210",
          },
          {
            subdistrict_th: "ห้วยใหญ่",
            subdistrict_en: "Huai Yai",
            post_code: "67000",
          },
          {
            subdistrict_th: "ระวิง",
            subdistrict_en: "Rawing",
            post_code: "67210",
          },
        ],
      },
      {
        district_th: "อำเภอชนแดน",
        district_en: "Amphoe Chon Daen",
        children: [
          {
            subdistrict_th: "ชนแดน",
            subdistrict_en: "Chon Daen",
            post_code: "67150",
          },
          {
            subdistrict_th: "ดงขุย",
            subdistrict_en: "Dong Khui",
            post_code: "67190",
          },
          {
            subdistrict_th: "ท่าข้าม",
            subdistrict_en: "Tha Kham",
            post_code: "67150",
          },
          {
            subdistrict_th: "พุทธบาท",
            subdistrict_en: "Phutthabat",
            post_code: "67150",
          },
          {
            subdistrict_th: "ลาดแค",
            subdistrict_en: "Lat Khae",
            post_code: "67150",
          },
          {
            subdistrict_th: "บ้านกล้วย",
            subdistrict_en: "Ban Kluai",
            post_code: "67190",
          },
          {
            subdistrict_th: "ซับพุทรา",
            subdistrict_en: "Sap Phutsa",
            post_code: "67150",
          },
          {
            subdistrict_th: "ตะกุดไร",
            subdistrict_en: "Takut Rai",
            post_code: "67190",
          },
          {
            subdistrict_th: "ศาลาลาย",
            subdistrict_en: "Sala Lai",
            post_code: "67150",
          },
        ],
      },
      {
        district_th: "อำเภอหล่มสัก",
        district_en: "Amphoe Lom Sak",
        children: [
          {
            subdistrict_th: "หล่มสัก",
            subdistrict_en: "Lom Sak",
            post_code: "67110",
          },
          {
            subdistrict_th: "วัดป่า",
            subdistrict_en: "Wat Pa",
            post_code: "67110",
          },
          {
            subdistrict_th: "ตาลเดี่ยว",
            subdistrict_en: "Tan Diao",
            post_code: "67110",
          },
          {
            subdistrict_th: "ฝายนาแซง",
            subdistrict_en: "Fai Na Saeng",
            post_code: "67110",
          },
          {
            subdistrict_th: "หนองสว่าง",
            subdistrict_en: "Nong Sawang",
            post_code: "67110",
          },
          {
            subdistrict_th: "น้ำเฮี้ย",
            subdistrict_en: "Nam Hia",
            post_code: "67110",
          },
          {
            subdistrict_th: "สักหลง",
            subdistrict_en: "Sak Long",
            post_code: "67110",
          },
          {
            subdistrict_th: "ท่าอิบุญ",
            subdistrict_en: "Tha Ibun",
            post_code: "67110",
          },
          {
            subdistrict_th: "บ้านโสก",
            subdistrict_en: "Ban Sok",
            post_code: "67110",
          },
          {
            subdistrict_th: "บ้านติ้ว",
            subdistrict_en: "Ban Tio",
            post_code: "67110",
          },
          {
            subdistrict_th: "ห้วยไร่",
            subdistrict_en: "Huai Rai",
            post_code: "67110",
          },
          {
            subdistrict_th: "น้ำก้อ",
            subdistrict_en: "Nam Ko",
            post_code: "67110",
          },
          {
            subdistrict_th: "ปากช่อง",
            subdistrict_en: "Pak Chong",
            post_code: "67110",
          },
          {
            subdistrict_th: "น้ำชุน",
            subdistrict_en: "Nam Chun",
            post_code: "67110",
          },
          {
            subdistrict_th: "หนองไขว่",
            subdistrict_en: "Nong Khwai",
            post_code: "67110",
          },
          {
            subdistrict_th: "ลานบ่า",
            subdistrict_en: "Lan Ba",
            post_code: "67110",
          },
          {
            subdistrict_th: "บุ่งคล้า",
            subdistrict_en: "Bung Khla",
            post_code: "67110",
          },
          {
            subdistrict_th: "บุ่งน้ำเต้า",
            subdistrict_en: "Bung Namtao",
            post_code: "67110",
          },
          {
            subdistrict_th: "บ้านกลาง",
            subdistrict_en: "Ban Klang",
            post_code: "67110",
          },
          {
            subdistrict_th: "ช้างตะลูด",
            subdistrict_en: "Chang Talut",
            post_code: "67110",
          },
          {
            subdistrict_th: "บ้านไร่",
            subdistrict_en: "Ban Rai",
            post_code: "67110",
          },
          {
            subdistrict_th: "ปากดุก",
            subdistrict_en: "Pak Duk",
            post_code: "67110",
          },
          {
            subdistrict_th: "บ้านหวาย",
            subdistrict_en: "Ban Wai",
            post_code: "67110",
          },
        ],
      },
      {
        district_th: "อำเภอหล่มเก่า",
        district_en: "Amphoe Lom Kao",
        children: [
          {
            subdistrict_th: "หล่มเก่า",
            subdistrict_en: "Lom Kao",
            post_code: "67120",
          },
          {
            subdistrict_th: "นาซำ",
            subdistrict_en: "Na Sam",
            post_code: "67120",
          },
          {
            subdistrict_th: "หินฮาว",
            subdistrict_en: "Hin Hao",
            post_code: "67120",
          },
          {
            subdistrict_th: "บ้านเนิน",
            subdistrict_en: "Ban Noen",
            post_code: "67120",
          },
          {
            subdistrict_th: "ศิลา",
            subdistrict_en: "Sila",
            post_code: "67120",
          },
          {
            subdistrict_th: "นาแซง",
            subdistrict_en: "Na Saeng",
            post_code: "67120",
          },
          {
            subdistrict_th: "วังบาล",
            subdistrict_en: "Wang Ban",
            post_code: "67120",
          },
          {
            subdistrict_th: "นาเกาะ",
            subdistrict_en: "Na Ko",
            post_code: "67120",
          },
          {
            subdistrict_th: "ตาดกลอย",
            subdistrict_en: "Tat Kloi",
            post_code: "67120",
          },
        ],
      },
      {
        district_th: "อำเภอวิเชียรบุรี",
        district_en: "Amphoe Wichian Buri",
        children: [
          {
            subdistrict_th: "ท่าโรง",
            subdistrict_en: "Tha Rong",
            post_code: "67130",
          },
          {
            subdistrict_th: "สระประดู่",
            subdistrict_en: "Sa Pradu",
            post_code: "67130",
          },
          {
            subdistrict_th: "สามแยก",
            subdistrict_en: "Sam Yaek",
            post_code: "67130",
          },
          {
            subdistrict_th: "โคกปรง",
            subdistrict_en: "Khok Prong",
            post_code: "67130",
          },
          {
            subdistrict_th: "น้ำร้อน",
            subdistrict_en: "Nam Ron",
            post_code: "67130",
          },
          {
            subdistrict_th: "บ่อรัง",
            subdistrict_en: "Bo Rang",
            post_code: "67130",
          },
          {
            subdistrict_th: "พุเตย",
            subdistrict_en: "Phu Toei",
            post_code: "67180",
          },
          {
            subdistrict_th: "พุขาม",
            subdistrict_en: "Phu Kham",
            post_code: "67180",
          },
          {
            subdistrict_th: "ภูน้ำหยด",
            subdistrict_en: "Phu Nam Yot",
            post_code: "67180",
          },
          {
            subdistrict_th: "ซับสมบูรณ์",
            subdistrict_en: "Sap Sombun",
            post_code: "67180",
          },
          {
            subdistrict_th: "บึงกระจับ",
            subdistrict_en: "Bueng Krachap",
            post_code: "67130",
          },
          {
            subdistrict_th: "วังใหญ่",
            subdistrict_en: "Wang Yai",
            post_code: "67180",
          },
          {
            subdistrict_th: "ยางสาว",
            subdistrict_en: "Yang Sao",
            post_code: "67130",
          },
          {
            subdistrict_th: "ซับน้อย",
            subdistrict_en: "Sap Noi",
            post_code: "67180",
          },
        ],
      },
      {
        district_th: "อำเภอศรีเทพ",
        district_en: "Amphoe Si Thep",
        children: [
          {
            subdistrict_th: "ศรีเทพ",
            subdistrict_en: "Si Thep",
            post_code: "67170",
          },
          {
            subdistrict_th: "สระกรวด",
            subdistrict_en: "Sa Kruat",
            post_code: "67170",
          },
          {
            subdistrict_th: "คลองกระจัง",
            subdistrict_en: "Khlong Krachang",
            post_code: "67170",
          },
          {
            subdistrict_th: "นาสนุ่น",
            subdistrict_en: "Na Sanun",
            post_code: "67170",
          },
          {
            subdistrict_th: "โคกสะอาด",
            subdistrict_en: "Khok Sa-at",
            post_code: "67170",
          },
          {
            subdistrict_th: "หนองย่างทอย",
            subdistrict_en: "Nong Yang Thoi",
            post_code: "67170",
          },
          {
            subdistrict_th: "ประดู่งาม",
            subdistrict_en: "Pradu Ngam",
            post_code: "67170",
          },
        ],
      },
      {
        district_th: "อำเภอหนองไผ่",
        district_en: "Amphoe Nong Phai",
        children: [
          {
            subdistrict_th: "กองทูล",
            subdistrict_en: "Kong Thun",
            post_code: "67140",
          },
          {
            subdistrict_th: "นาเฉลียง",
            subdistrict_en: "Na Chaliang",
            post_code: "67220",
          },
          {
            subdistrict_th: "บ้านโภชน์",
            subdistrict_en: "Ban Phot",
            post_code: "67140",
          },
          {
            subdistrict_th: "ท่าแดง",
            subdistrict_en: "Tha Daeng",
            post_code: "67140",
          },
          {
            subdistrict_th: "เพชรละคร",
            subdistrict_en: "Phet Lakhon",
            post_code: "67140",
          },
          {
            subdistrict_th: "บ่อไทย",
            subdistrict_en: "Bo Thai",
            post_code: "67140",
          },
          {
            subdistrict_th: "ห้วยโป่ง",
            subdistrict_en: "Huai Pong",
            post_code: "67220",
          },
          {
            subdistrict_th: "วังท่าดี",
            subdistrict_en: "Wang Tha Di",
            post_code: "67140",
          },
          {
            subdistrict_th: "บัววัฒนา",
            subdistrict_en: "Bua Watthana",
            post_code: "67140",
          },
          {
            subdistrict_th: "หนองไผ่",
            subdistrict_en: "Nong Phai",
            post_code: "67140",
          },
          {
            subdistrict_th: "วังโบสถ์",
            subdistrict_en: "Wang Bot",
            post_code: "67140",
          },
          {
            subdistrict_th: "ยางงาม",
            subdistrict_en: "Yang Ngam",
            post_code: "67220",
          },
          {
            subdistrict_th: "ท่าด้วง",
            subdistrict_en: "Tha Duang",
            post_code: "67140",
          },
        ],
      },
      {
        district_th: "อำเภอบึงสามพัน",
        district_en: "Amphoe Bueng Sam Phan",
        children: [
          {
            subdistrict_th: "ซับสมอทอด",
            subdistrict_en: "Sap Samo Thot",
            post_code: "67160",
          },
          {
            subdistrict_th: "ซับไม้แดง",
            subdistrict_en: "Sap Mai Daeng",
            post_code: "67160",
          },
          {
            subdistrict_th: "หนองแจง",
            subdistrict_en: "Nong Chaeng",
            post_code: "67160",
          },
          {
            subdistrict_th: "กันจุ",
            subdistrict_en: "Kanchu",
            post_code: "67160",
          },
          {
            subdistrict_th: "วังพิกุล",
            subdistrict_en: "Wang Phikun",
            post_code: "67230",
          },
          {
            subdistrict_th: "พญาวัง",
            subdistrict_en: "Phaya Wang",
            post_code: "67160",
          },
          {
            subdistrict_th: "ศรีมงคล",
            subdistrict_en: "Si Mongkhon",
            post_code: "67160",
          },
          {
            subdistrict_th: "สระแก้ว",
            subdistrict_en: "Sa Kaeo",
            post_code: "67160",
          },
          {
            subdistrict_th: "บึงสามพัน",
            subdistrict_en: "Bueng Sam Phan",
            post_code: "67160",
          },
        ],
      },
      {
        district_th: "อำเภอน้ำหนาว",
        district_en: "Amphoe Nam Nao",
        children: [
          {
            subdistrict_th: "น้ำหนาว",
            subdistrict_en: "Nam Nao",
            post_code: "67260",
          },
          {
            subdistrict_th: "หลักด่าน",
            subdistrict_en: "Lak Dan",
            post_code: "67260",
          },
          {
            subdistrict_th: "วังกวาง",
            subdistrict_en: "Wang Kwang",
            post_code: "67260",
          },
          {
            subdistrict_th: "โคกมน",
            subdistrict_en: "Khok Mon",
            post_code: "67260",
          },
        ],
      },
      {
        district_th: "อำเภอวังโป่ง",
        district_en: "Amphoe Wang Pong",
        children: [
          {
            subdistrict_th: "วังโป่ง",
            subdistrict_en: "Wang Pong",
            post_code: "67240",
          },
          {
            subdistrict_th: "ท้ายดง",
            subdistrict_en: "Thai Dong",
            post_code: "67240",
          },
          {
            subdistrict_th: "ซับเปิบ",
            subdistrict_en: "Sap Poep",
            post_code: "67240",
          },
          {
            subdistrict_th: "วังหิน",
            subdistrict_en: "Wang Hin",
            post_code: "67240",
          },
          {
            subdistrict_th: "วังศาล",
            subdistrict_en: "Wang San",
            post_code: "67240",
          },
        ],
      },
      {
        district_th: "อำเภอเขาค้อ",
        district_en: "Amphoe Khao Kho",
        children: [
          {
            subdistrict_th: "ทุ่งสมอ",
            subdistrict_en: "Thung Samo",
            post_code: "67270",
          },
          {
            subdistrict_th: "แคมป์สน",
            subdistrict_en: "Khaem Son",
            post_code: "67280",
          },
          {
            subdistrict_th: "เขาค้อ",
            subdistrict_en: "Khao Kho",
            post_code: "67270",
          },
          {
            subdistrict_th: "ริมสีม่วง",
            subdistrict_en: "Rim Si Muang",
            post_code: "67270",
          },
          {
            subdistrict_th: "สะเดาะพง",
            subdistrict_en: "Sado Phong",
            post_code: "67270",
          },
          {
            subdistrict_th: "หนองแม่นา",
            subdistrict_en: "Nong Mae Na",
            post_code: "67270",
          },
          {
            subdistrict_th: "เข็กน้อย",
            subdistrict_en: "Khek Noi",
            post_code: "67280",
          },
        ],
      },
    ],
  },
  {
    province_th: "ราชบุรี",
    province_en: "Ratchaburi",
    children: [
      {
        district_th: "อำเภอเมืองราชบุรี",
        district_en: "Amphoe Mueang Ratchaburi",
        children: [
          {
            subdistrict_th: "หน้าเมือง",
            subdistrict_en: "Na Mueang",
            post_code: "70000",
          },
          {
            subdistrict_th: "เจดีย์หัก",
            subdistrict_en: "Chedi Hak",
            post_code: "70000",
          },
          {
            subdistrict_th: "ดอนตะโก",
            subdistrict_en: "Don Tako",
            post_code: "70000",
          },
          {
            subdistrict_th: "หนองกลางนา",
            subdistrict_en: "Nong Klang Na",
            post_code: "70000",
          },
          {
            subdistrict_th: "ห้วยไผ่",
            subdistrict_en: "Huai Phai",
            post_code: "70000",
          },
          {
            subdistrict_th: "คุ้งน้ำวน",
            subdistrict_en: "Khung Nam Won",
            post_code: "70000",
          },
          {
            subdistrict_th: "คุ้งกระถิน",
            subdistrict_en: "Khung Krathin",
            post_code: "70000",
          },
          {
            subdistrict_th: "อ่างทอง",
            subdistrict_en: "Ang Thong",
            post_code: "70000",
          },
          {
            subdistrict_th: "โคกหม้อ",
            subdistrict_en: "Khok Mo",
            post_code: "70000",
          },
          {
            subdistrict_th: "สามเรือน",
            subdistrict_en: "Sam Ruean",
            post_code: "70000",
          },
          {
            subdistrict_th: "พิกุลทอง",
            subdistrict_en: "Phikun Thong",
            post_code: "70000",
          },
          {
            subdistrict_th: "น้ำพุ",
            subdistrict_en: "Nam Phu",
            post_code: "70000",
          },
          {
            subdistrict_th: "ดอนแร่",
            subdistrict_en: "Don Rae",
            post_code: "70000",
          },
          {
            subdistrict_th: "หินกอง",
            subdistrict_en: "Hin Kong",
            post_code: "70000",
          },
          {
            subdistrict_th: "เขาแร้ง",
            subdistrict_en: "Khao Raeng",
            post_code: "70000",
          },
          {
            subdistrict_th: "เกาะพลับพลา",
            subdistrict_en: "Ko Phlapphla",
            post_code: "70000",
          },
          {
            subdistrict_th: "หลุมดิน",
            subdistrict_en: "Lum Din",
            post_code: "70000",
          },
          {
            subdistrict_th: "บางป่า",
            subdistrict_en: "Bang Pa",
            post_code: "70000",
          },
          {
            subdistrict_th: "พงสวาย",
            subdistrict_en: "Phong Sawai",
            post_code: "70000",
          },
          {
            subdistrict_th: "คูบัว",
            subdistrict_en: "Khu Bua",
            post_code: "70000",
          },
          {
            subdistrict_th: "ท่าราบ",
            subdistrict_en: "Tha Rap",
            post_code: "70000",
          },
          {
            subdistrict_th: "บ้านไร่",
            subdistrict_en: "Ban Rai",
            post_code: "70000",
          },
        ],
      },
      {
        district_th: "อำเภอจอมบึง",
        district_en: "Amphoe Chom Bueng",
        children: [
          {
            subdistrict_th: "จอมบึง",
            subdistrict_en: "Chom Bueng",
            post_code: "70150",
          },
          {
            subdistrict_th: "ปากช่อง",
            subdistrict_en: "Pak Chong",
            post_code: "70150",
          },
          {
            subdistrict_th: "เบิกไพร",
            subdistrict_en: "Boek Phrai",
            post_code: "70150",
          },
          {
            subdistrict_th: "ด่านทับตะโก",
            subdistrict_en: "Dan Thap Tako",
            post_code: "70150",
          },
          {
            subdistrict_th: "แก้มอ้น",
            subdistrict_en: "Kaem On",
            post_code: "70150",
          },
          {
            subdistrict_th: "รางบัว",
            subdistrict_en: "Rang Bua",
            post_code: "70150",
          },
        ],
      },
      {
        district_th: "อำเภอสวนผึ้ง",
        district_en: "Amphoe Suan Phueng",
        children: [
          {
            subdistrict_th: "สวนผึ้ง",
            subdistrict_en: "Suan Phueng",
            post_code: "70180",
          },
          {
            subdistrict_th: "ป่าหวาย",
            subdistrict_en: "Pa Wai",
            post_code: "70180",
          },
          {
            subdistrict_th: "ท่าเคย",
            subdistrict_en: "Tha Khoei",
            post_code: "70180",
          },
          {
            subdistrict_th: "ตะนาวศรี",
            subdistrict_en: "Tanao Si",
            post_code: "70180",
          },
        ],
      },
      {
        district_th: "อำเภอดำเนินสะดวก",
        district_en: "Amphoe Damnoen Saduak",
        children: [
          {
            subdistrict_th: "ดำเนินสะดวก",
            subdistrict_en: "Damnoen Saduak",
            post_code: "70130",
          },
          {
            subdistrict_th: "ประสาทสิทธิ์",
            subdistrict_en: "Prasat Sit",
            post_code: "70210",
          },
          {
            subdistrict_th: "ศรีสุราษฎร์",
            subdistrict_en: "Si Surat",
            post_code: "70130",
          },
          {
            subdistrict_th: "ตาหลวง",
            subdistrict_en: "Ta Luang",
            post_code: "70130",
          },
          {
            subdistrict_th: "ดอนกรวย",
            subdistrict_en: "Don Kruai",
            post_code: "70130",
          },
          {
            subdistrict_th: "ดอนคลัง",
            subdistrict_en: "Don Khlang",
            post_code: "70130",
          },
          {
            subdistrict_th: "บัวงาม",
            subdistrict_en: "Bua Ngam",
            post_code: "70210",
          },
          {
            subdistrict_th: "บ้านไร่",
            subdistrict_en: "Ban Rai",
            post_code: "70130",
          },
          {
            subdistrict_th: "แพงพวย",
            subdistrict_en: "Phaengphuai",
            post_code: "70130",
          },
          {
            subdistrict_th: "สี่หมื่น",
            subdistrict_en: "Si Muen",
            post_code: "70130",
          },
          {
            subdistrict_th: "ท่านัด",
            subdistrict_en: "Tha Nat",
            post_code: "70130",
          },
          {
            subdistrict_th: "ขุนพิทักษ์",
            subdistrict_en: "Khun Phithak",
            post_code: "70130",
          },
          {
            subdistrict_th: "ดอนไผ่",
            subdistrict_en: "Don Phai",
            post_code: "70130",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านโป่ง",
        district_en: "Amphoe Ban Pong",
        children: [
          {
            subdistrict_th: "บ้านโป่ง",
            subdistrict_en: "Ban Pong",
            post_code: "70110",
          },
          {
            subdistrict_th: "ท่าผา",
            subdistrict_en: "Tha Pha",
            post_code: "70110",
          },
          {
            subdistrict_th: "กรับใหญ่",
            subdistrict_en: "Krap Yai",
            post_code: "70190",
          },
          {
            subdistrict_th: "ปากแรต",
            subdistrict_en: "Pak Raet",
            post_code: "70110",
          },
          {
            subdistrict_th: "หนองกบ",
            subdistrict_en: "Nong Kop",
            post_code: "70110",
          },
          {
            subdistrict_th: "หนองอ้อ",
            subdistrict_en: "Nong O",
            post_code: "70110",
          },
          {
            subdistrict_th: "ดอนกระเบื้อง",
            subdistrict_en: "Don Krabueang",
            post_code: "70110",
          },
          {
            subdistrict_th: "สวนกล้วย",
            subdistrict_en: "Suan Kluai",
            post_code: "70110",
          },
          {
            subdistrict_th: "นครชุมน์",
            subdistrict_en: "Nakhon Chum",
            post_code: "70110",
          },
          {
            subdistrict_th: "บ้านม่วง",
            subdistrict_en: "Ban Muang",
            post_code: "70110",
          },
          {
            subdistrict_th: "คุ้งพยอม",
            subdistrict_en: "Khung Phayom",
            post_code: "70110",
          },
          {
            subdistrict_th: "หนองปลาหมอ",
            subdistrict_en: "Nong Pla Mo",
            post_code: "70110",
          },
          {
            subdistrict_th: "เขาขลุง",
            subdistrict_en: "Khao Khlung",
            post_code: "70110",
          },
          {
            subdistrict_th: "เบิกไพร",
            subdistrict_en: "Boek Phrai",
            post_code: "70110",
          },
          {
            subdistrict_th: "ลาดบัวขาว",
            subdistrict_en: "Lat Bua Khao",
            post_code: "70110",
          },
        ],
      },
      {
        district_th: "อำเภอบางแพ",
        district_en: "Amphoe Bang Phae",
        children: [
          {
            subdistrict_th: "บางแพ",
            subdistrict_en: "Bang Phae",
            post_code: "70160",
          },
          {
            subdistrict_th: "วังเย็น",
            subdistrict_en: "Wang Yen",
            post_code: "70160",
          },
          {
            subdistrict_th: "หัวโพ",
            subdistrict_en: "Hua Pho",
            post_code: "70160",
          },
          {
            subdistrict_th: "วัดแก้ว",
            subdistrict_en: "Wat Kaeo",
            post_code: "70160",
          },
          {
            subdistrict_th: "ดอนใหญ่",
            subdistrict_en: "Don Yai",
            post_code: "70160",
          },
          {
            subdistrict_th: "ดอนคา",
            subdistrict_en: "Don Kha",
            post_code: "70160",
          },
          {
            subdistrict_th: "โพหัก",
            subdistrict_en: "Pho Hak",
            post_code: "70160",
          },
        ],
      },
      {
        district_th: "อำเภอโพธาราม",
        district_en: "Amphoe Photharam",
        children: [
          {
            subdistrict_th: "โพธาราม",
            subdistrict_en: "Photharam",
            post_code: "70120",
          },
          {
            subdistrict_th: "ดอนกระเบื้อง",
            subdistrict_en: "Don Krabueang",
            post_code: "70120",
          },
          {
            subdistrict_th: "หนองโพ",
            subdistrict_en: "Nong Pho",
            post_code: "70120",
          },
          {
            subdistrict_th: "บ้านเลือก",
            subdistrict_en: "Ban Lueak",
            post_code: "70120",
          },
          {
            subdistrict_th: "คลองตาคต",
            subdistrict_en: "Khlong Ta Khot",
            post_code: "70120",
          },
          {
            subdistrict_th: "บ้านฆ้อง",
            subdistrict_en: "Ban Khong",
            post_code: "70120",
          },
          {
            subdistrict_th: "บ้านสิงห์",
            subdistrict_en: "Ban Sing",
            post_code: "70120",
          },
          {
            subdistrict_th: "ดอนทราย",
            subdistrict_en: "Don Sai",
            post_code: "70120",
          },
          {
            subdistrict_th: "เจ็ดเสมียน",
            subdistrict_en: "Chet Samian",
            post_code: "70120",
          },
          {
            subdistrict_th: "คลองข่อย",
            subdistrict_en: "Khlong Khoi",
            post_code: "70120",
          },
          {
            subdistrict_th: "ชำแระ",
            subdistrict_en: "Chamrae",
            post_code: "70120",
          },
          {
            subdistrict_th: "สร้อยฟ้า",
            subdistrict_en: "Soi Fa",
            post_code: "70120",
          },
          {
            subdistrict_th: "ท่าชุมพล",
            subdistrict_en: "Tha Chumphon",
            post_code: "70120",
          },
          {
            subdistrict_th: "บางโตนด",
            subdistrict_en: "Bang Tanot",
            post_code: "70120",
          },
          {
            subdistrict_th: "เตาปูน",
            subdistrict_en: "Tao Pun",
            post_code: "70120",
          },
          {
            subdistrict_th: "นางแก้ว",
            subdistrict_en: "Nang Kaeo",
            post_code: "70120",
          },
          {
            subdistrict_th: "ธรรมเสน",
            subdistrict_en: "Thammasen",
            post_code: "70120",
          },
          {
            subdistrict_th: "เขาชะงุ้ม",
            subdistrict_en: "Khao Cha-ngum",
            post_code: "70120",
          },
          {
            subdistrict_th: "หนองกวาง",
            subdistrict_en: "Nong Kwang",
            post_code: "70120",
          },
        ],
      },
      {
        district_th: "อำเภอปากท่อ",
        district_en: "Amphoe Pak Tho",
        children: [
          {
            subdistrict_th: "ทุ่งหลวง",
            subdistrict_en: "Thung Luang",
            post_code: "70140",
          },
          {
            subdistrict_th: "วังมะนาว",
            subdistrict_en: "Wang Manao",
            post_code: "70140",
          },
          {
            subdistrict_th: "ดอนทราย",
            subdistrict_en: "Don Sai",
            post_code: "70140",
          },
          {
            subdistrict_th: "หนองกระทุ่ม",
            subdistrict_en: "Nong Krathum",
            post_code: "70140",
          },
          {
            subdistrict_th: "ปากท่อ",
            subdistrict_en: "Pak Tho",
            post_code: "70140",
          },
          {
            subdistrict_th: "ป่าไก่",
            subdistrict_en: "Pa Kai",
            post_code: "70140",
          },
          {
            subdistrict_th: "วัดยางงาม",
            subdistrict_en: "Wat Yang Ngam",
            post_code: "70140",
          },
          {
            subdistrict_th: "อ่างหิน",
            subdistrict_en: "Ang Hin",
            post_code: "70140",
          },
          {
            subdistrict_th: "บ่อกระดาน",
            subdistrict_en: "Bo Kradan",
            post_code: "70140",
          },
          {
            subdistrict_th: "ยางหัก",
            subdistrict_en: "Yang Hak",
            post_code: "70140",
          },
          {
            subdistrict_th: "วันดาว",
            subdistrict_en: "Wan Dao",
            post_code: "70140",
          },
          {
            subdistrict_th: "ห้วยยางโทน",
            subdistrict_en: "Huai Yang Thon",
            post_code: "70140",
          },
        ],
      },
      {
        district_th: "อำเภอวัดเพลง",
        district_en: "Amphoe Wat Phleng",
        children: [
          {
            subdistrict_th: "เกาะศาลพระ",
            subdistrict_en: "Ko San Phra",
            post_code: "70170",
          },
          {
            subdistrict_th: "จอมประทัด",
            subdistrict_en: "Chom Prathat",
            post_code: "70170",
          },
          {
            subdistrict_th: "วัดเพลง",
            subdistrict_en: "Wat Phleng",
            post_code: "70170",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านคา",
        district_en: "Amphoe Ban Kha",
        children: [
          {
            subdistrict_th: "บ้านคา",
            subdistrict_en: "Ban Kha",
            post_code: "70180",
          },
          {
            subdistrict_th: "บ้านบึง",
            subdistrict_en: "Ban Bueng",
            post_code: "70180",
          },
          {
            subdistrict_th: "หนองพันจันทร์",
            subdistrict_en: "Nong Phan Chan",
            post_code: "70180",
          },
        ],
      },
    ],
  },
  {
    province_th: "กาญจนบุรี",
    province_en: "Kanchanaburi",
    children: [
      {
        district_th: "อำเภอเมืองกาญจนบุรี",
        district_en: "Amphoe Mueang Kanchanaburi",
        children: [
          {
            subdistrict_th: "บ้านเหนือ",
            subdistrict_en: "Ban Nuea",
            post_code: "71000",
          },
          {
            subdistrict_th: "บ้านใต้",
            subdistrict_en: "Ban Tai",
            post_code: "71000",
          },
          {
            subdistrict_th: "ปากแพรก",
            subdistrict_en: "Pak Phraek",
            post_code: "71000",
          },
          {
            subdistrict_th: "ท่ามะขาม",
            subdistrict_en: "Tha Makham",
            post_code: "71000",
          },
          {
            subdistrict_th: "แก่งเสี้ยน",
            subdistrict_en: "Kaeng Sian",
            post_code: "71000",
          },
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "71190",
          },
          {
            subdistrict_th: "ลาดหญ้า",
            subdistrict_en: "Lat Ya",
            post_code: "71190",
          },
          {
            subdistrict_th: "วังด้ง",
            subdistrict_en: "Wang Dong",
            post_code: "71190",
          },
          {
            subdistrict_th: "ช่องสะเดา",
            subdistrict_en: "Chong Sadao",
            post_code: "71190",
          },
          {
            subdistrict_th: "หนองหญ้า",
            subdistrict_en: "Nong Ya",
            post_code: "71000",
          },
          {
            subdistrict_th: "เกาะสำโรง",
            subdistrict_en: "Ko Samrong",
            post_code: "71000",
          },
          {
            subdistrict_th: "บ้านเก่า",
            subdistrict_en: "Ban Kao",
            post_code: "71000",
          },
          {
            subdistrict_th: "วังเย็น",
            subdistrict_en: "Wang Yen",
            post_code: "71000",
          },
        ],
      },
      {
        district_th: "อำเภอไทรโยค",
        district_en: "Amphoe Sai Yok",
        children: [
          {
            subdistrict_th: "ลุ่มสุ่ม",
            subdistrict_en: "Lum Sum",
            post_code: "71150",
          },
          {
            subdistrict_th: "ท่าเสา",
            subdistrict_en: "Tha Sao",
            post_code: "71150",
          },
          {
            subdistrict_th: "สิงห์",
            subdistrict_en: "Sing",
            post_code: "71150",
          },
          {
            subdistrict_th: "ไทรโยค",
            subdistrict_en: "Sai Yok",
            post_code: "71150",
          },
          {
            subdistrict_th: "วังกระแจะ",
            subdistrict_en: "Wang Khrachae",
            post_code: "71150",
          },
          {
            subdistrict_th: "ศรีมงคล",
            subdistrict_en: "Si Mongkhon",
            post_code: "71150",
          },
          {
            subdistrict_th: "บ้องตี้",
            subdistrict_en: "Bongti",
            post_code: "71150",
          },
        ],
      },
      {
        district_th: "อำเภอบ่อพลอย",
        district_en: "Amphoe Bo Phloi",
        children: [
          {
            subdistrict_th: "บ่อพลอย",
            subdistrict_en: "Bo Phloi",
            post_code: "71160",
          },
          {
            subdistrict_th: "หนองกุ่ม",
            subdistrict_en: "Nong Kum",
            post_code: "71160",
          },
          {
            subdistrict_th: "หนองรี",
            subdistrict_en: "Nong Ri",
            post_code: "71220",
          },
          {
            subdistrict_th: "หลุมรัง",
            subdistrict_en: "Lum Rang",
            post_code: "71160",
          },
          {
            subdistrict_th: "ช่องด่าน",
            subdistrict_en: "Chong Dan",
            post_code: "71160",
          },
          {
            subdistrict_th: "หนองกร่าง",
            subdistrict_en: "Nong Krang",
            post_code: "71220",
          },
        ],
      },
      {
        district_th: "อำเภอศรีสวัสดิ์",
        district_en: "Amphoe Si Sawat",
        children: [
          {
            subdistrict_th: "นาสวน",
            subdistrict_en: "Na Suan",
            post_code: "71250",
          },
          {
            subdistrict_th: "ด่านแม่แฉลบ",
            subdistrict_en: "Dan Mae Chalaep",
            post_code: "71250",
          },
          {
            subdistrict_th: "หนองเป็ด",
            subdistrict_en: "Nong Pet",
            post_code: "71250",
          },
          {
            subdistrict_th: "ท่ากระดาน",
            subdistrict_en: "Tha Kradan",
            post_code: "71250",
          },
          {
            subdistrict_th: "เขาโจด",
            subdistrict_en: "Khao Chot",
            post_code: "71220",
          },
          {
            subdistrict_th: "แม่กระบุง",
            subdistrict_en: "Mae Krabung",
            post_code: "71250",
          },
        ],
      },
      {
        district_th: "อำเภอท่ามะกา",
        district_en: "Amphoe Tha Maka",
        children: [
          {
            subdistrict_th: "พงตึก",
            subdistrict_en: "Phong Tuek",
            post_code: "71120",
          },
          {
            subdistrict_th: "ยางม่วง",
            subdistrict_en: "Yang Muang",
            post_code: "71120",
          },
          {
            subdistrict_th: "ดอนชะเอม",
            subdistrict_en: "Don Cha-em",
            post_code: "71130",
          },
          {
            subdistrict_th: "ท่าไม้",
            subdistrict_en: "Tha Mai",
            post_code: "71120",
          },
          {
            subdistrict_th: "ตะคร้ำเอน",
            subdistrict_en: "Takhram En",
            post_code: "71130",
          },
          {
            subdistrict_th: "ท่ามะกา",
            subdistrict_en: "Tha Maka",
            post_code: "71120",
          },
          {
            subdistrict_th: "ท่าเรือ",
            subdistrict_en: "Tha Ruea",
            post_code: "71130",
          },
          {
            subdistrict_th: "โคกตะบอง",
            subdistrict_en: "Khok Tabong",
            post_code: "71120",
          },
          {
            subdistrict_th: "ดอนขมิ้น",
            subdistrict_en: "Don Khamin",
            post_code: "71120",
          },
          {
            subdistrict_th: "อุโลกสี่หมื่น",
            subdistrict_en: "Ulok Si Muen",
            post_code: "71130",
          },
          {
            subdistrict_th: "เขาสามสิบหาบ",
            subdistrict_en: "Khao Sam Sip Hap",
            post_code: "71120",
          },
          {
            subdistrict_th: "พระแท่น",
            subdistrict_en: "Phra Thaen",
            post_code: "71130",
          },
          {
            subdistrict_th: "หวายเหนียว",
            subdistrict_en: "Wai Niao",
            post_code: "71120",
          },
          {
            subdistrict_th: "แสนตอ",
            subdistrict_en: "Saen To",
            post_code: "71130",
          },
          {
            subdistrict_th: "สนามแย้",
            subdistrict_en: "Sanam Yae",
            post_code: "70190",
          },
          {
            subdistrict_th: "ท่าเสา",
            subdistrict_en: "Tha Sao",
            post_code: "71120",
          },
          {
            subdistrict_th: "หนองลาน",
            subdistrict_en: "Nong lan",
            post_code: "71130",
          },
        ],
      },
      {
        district_th: "อำเภอท่าม่วง",
        district_en: "Amphoe Tha Muang",
        children: [
          {
            subdistrict_th: "ท่าม่วง",
            subdistrict_en: "Tha Muang",
            post_code: "71110",
          },
          {
            subdistrict_th: "วังขนาย",
            subdistrict_en: "Wang Khanai",
            post_code: "71110",
          },
          {
            subdistrict_th: "วังศาลา",
            subdistrict_en: "Wang Sala",
            post_code: "71110",
          },
          {
            subdistrict_th: "ท่าล้อ",
            subdistrict_en: "Tha Lo",
            post_code: "71000",
          },
          {
            subdistrict_th: "หนองขาว",
            subdistrict_en: "Nong Khao",
            post_code: "71110",
          },
          {
            subdistrict_th: "ทุ่งทอง",
            subdistrict_en: "Thung Thong",
            post_code: "71110",
          },
          {
            subdistrict_th: "เขาน้อย",
            subdistrict_en: "Khao Noi",
            post_code: "71110",
          },
          {
            subdistrict_th: "ม่วงชุม",
            subdistrict_en: "Muang Chum",
            post_code: "71110",
          },
          {
            subdistrict_th: "บ้านใหม่",
            subdistrict_en: "Ban Mai",
            post_code: "71110",
          },
          {
            subdistrict_th: "พังตรุ",
            subdistrict_en: "Phang Tru",
            post_code: "71110",
          },
          {
            subdistrict_th: "ท่าตะคร้อ",
            subdistrict_en: "Tha Takro",
            post_code: "71130",
          },
          {
            subdistrict_th: "รางสาลี่",
            subdistrict_en: "Rang Sali",
            post_code: "71110",
          },
          {
            subdistrict_th: "หนองตากยา",
            subdistrict_en: "Nong Tak Ya",
            post_code: "71110",
          },
        ],
      },
      {
        district_th: "อำเภอทองผาภูมิ",
        district_en: "Amphoe Thong Pha Phum",
        children: [
          {
            subdistrict_th: "ท่าขนุน",
            subdistrict_en: "Tha Khanun",
            post_code: "71180",
          },
          {
            subdistrict_th: "ปิล๊อก",
            subdistrict_en: "Pilok",
            post_code: "71180",
          },
          {
            subdistrict_th: "หินดาด",
            subdistrict_en: "Hin Dat",
            post_code: "71180",
          },
          {
            subdistrict_th: "ลิ่นถิ่น",
            subdistrict_en: "Linthin",
            post_code: "71180",
          },
          {
            subdistrict_th: "ชะแล",
            subdistrict_en: "Chalae",
            post_code: "71180",
          },
          {
            subdistrict_th: "ห้วยเขย่ง",
            subdistrict_en: "Huai Khayeng",
            post_code: "71180",
          },
          {
            subdistrict_th: "สหกรณ์นิคม",
            subdistrict_en: "Sahakon Nikhom",
            post_code: "71180",
          },
        ],
      },
      {
        district_th: "อำเภอสังขละบุรี",
        district_en: "Amphoe Sangkhla Buri",
        children: [
          {
            subdistrict_th: "หนองลู",
            subdistrict_en: "Nong Lu",
            post_code: "71240",
          },
          {
            subdistrict_th: "ปรังเผล",
            subdistrict_en: "Prang Phle",
            post_code: "71240",
          },
          {
            subdistrict_th: "ไล่โว่",
            subdistrict_en: "Laiwo",
            post_code: "71240",
          },
        ],
      },
      {
        district_th: "อำเภอพนมทวน",
        district_en: "Amphoe Phanom Thuan",
        children: [
          {
            subdistrict_th: "พนมทวน",
            subdistrict_en: "Phanom Thuan",
            post_code: "71140",
          },
          {
            subdistrict_th: "หนองโรง",
            subdistrict_en: "Nong Rong",
            post_code: "71140",
          },
          {
            subdistrict_th: "ทุ่งสมอ",
            subdistrict_en: "Thung Samo",
            post_code: "71140",
          },
          {
            subdistrict_th: "ดอนเจดีย์",
            subdistrict_en: "Don Chedi",
            post_code: "71140",
          },
          {
            subdistrict_th: "พังตรุ",
            subdistrict_en: "Phang Tru",
            post_code: "71140",
          },
          {
            subdistrict_th: "รางหวาย",
            subdistrict_en: "Rang Wai",
            post_code: "71170",
          },
          {
            subdistrict_th: "หนองสาหร่าย",
            subdistrict_en: "Nong Sarai",
            post_code: "71140",
          },
          {
            subdistrict_th: "ดอนตาเพชร",
            subdistrict_en: "Don Ta Phet",
            post_code: "71140",
          },
        ],
      },
      {
        district_th: "อำเภอเลาขวัญ",
        district_en: "Amphoe Lao Khwan",
        children: [
          {
            subdistrict_th: "เลาขวัญ",
            subdistrict_en: "Lao Khwan",
            post_code: "71210",
          },
          {
            subdistrict_th: "หนองโสน",
            subdistrict_en: "Nong Sano",
            post_code: "71210",
          },
          {
            subdistrict_th: "หนองประดู่",
            subdistrict_en: "Nong Pradu",
            post_code: "71210",
          },
          {
            subdistrict_th: "หนองปลิง",
            subdistrict_en: "Nong Pling",
            post_code: "71210",
          },
          {
            subdistrict_th: "หนองนกแก้ว",
            subdistrict_en: "Nong Nok Kaeo",
            post_code: "71210",
          },
          {
            subdistrict_th: "ทุ่งกระบ่ำ",
            subdistrict_en: "Thung Krabam",
            post_code: "71210",
          },
          {
            subdistrict_th: "หนองฝ้าย",
            subdistrict_en: "Nong Fai",
            post_code: "71210",
          },
        ],
      },
      {
        district_th: "อำเภอด่านมะขามเตี้ย",
        district_en: "Amphoe Dan Makham Tia",
        children: [
          {
            subdistrict_th: "ด่านมะขามเตี้ย",
            subdistrict_en: "Dan Makham Tia",
            post_code: "71260",
          },
          {
            subdistrict_th: "กลอนโด",
            subdistrict_en: "Klon Do",
            post_code: "71260",
          },
          {
            subdistrict_th: "จรเข้เผือก",
            subdistrict_en: "Chorakhe Phueak",
            post_code: "71260",
          },
          {
            subdistrict_th: "หนองไผ่",
            subdistrict_en: "Nong Phai",
            post_code: "71260",
          },
        ],
      },
      {
        district_th: "อำเภอหนองปรือ",
        district_en: "Amphoe Nong Prue",
        children: [
          {
            subdistrict_th: "หนองปรือ",
            subdistrict_en: "Nong Prue",
            post_code: "71220",
          },
          {
            subdistrict_th: "หนองปลาไหล",
            subdistrict_en: "Nong Pla Lai",
            post_code: "71220",
          },
          {
            subdistrict_th: "สมเด็จเจริญ",
            subdistrict_en: "Somdet Charoen",
            post_code: "71220",
          },
        ],
      },
      {
        district_th: "อำเภอห้วยกระเจา",
        district_en: "Amphoe Huai Krachao",
        children: [
          {
            subdistrict_th: "ห้วยกระเจา",
            subdistrict_en: "Huai Krachao",
            post_code: "71170",
          },
          {
            subdistrict_th: "วังไผ่",
            subdistrict_en: "Wang Phai",
            post_code: "71170",
          },
          {
            subdistrict_th: "ดอนแสลบ",
            subdistrict_en: "Don Salaep",
            post_code: "71170",
          },
          {
            subdistrict_th: "สระลงเรือ",
            subdistrict_en: "Sa Long Ruea",
            post_code: "71170",
          },
        ],
      },
    ],
  },
  {
    province_th: "สุพรรณบุรี",
    province_en: "Suphan Buri",
    children: [
      {
        district_th: "อำเภอเมืองสุพรรณบุรี",
        district_en: "Amphoe Mueang Suphan Buri",
        children: [
          {
            subdistrict_th: "ท่าพี่เลี้ยง",
            subdistrict_en: "Tha Phi Liang",
            post_code: "72000",
          },
          {
            subdistrict_th: "รั้วใหญ่",
            subdistrict_en: "Rua Yai",
            post_code: "72000",
          },
          {
            subdistrict_th: "ทับตีเหล็ก",
            subdistrict_en: "Thap Ti Lek",
            post_code: "72000",
          },
          {
            subdistrict_th: "ท่าระหัด",
            subdistrict_en: "Tha Rahat",
            post_code: "72000",
          },
          {
            subdistrict_th: "ไผ่ขวาง",
            subdistrict_en: "Phai Khwang",
            post_code: "72000",
          },
          {
            subdistrict_th: "โคกโคเฒ่า",
            subdistrict_en: "Khok Kho Thao",
            post_code: "72000",
          },
          {
            subdistrict_th: "ดอนตาล",
            subdistrict_en: "Don Tan",
            post_code: "72000",
          },
          {
            subdistrict_th: "ดอนมะสังข์",
            subdistrict_en: "Don Masang",
            post_code: "72000",
          },
          {
            subdistrict_th: "พิหารแดง",
            subdistrict_en: "Phihan Daeng",
            post_code: "72000",
          },
          {
            subdistrict_th: "ดอนกำยาน",
            subdistrict_en: "Don Kamyan",
            post_code: "72000",
          },
          {
            subdistrict_th: "ดอนโพธิ์ทอง",
            subdistrict_en: "Don Pho Thong",
            post_code: "72000",
          },
          {
            subdistrict_th: "บ้านโพธิ์",
            subdistrict_en: "Bang Pho",
            post_code: "72000",
          },
          {
            subdistrict_th: "สระแก้ว",
            subdistrict_en: "Sa Kaeo",
            post_code: "72230",
          },
          {
            subdistrict_th: "ตลิ่งชัน",
            subdistrict_en: "Taling Chan",
            post_code: "72230",
          },
          {
            subdistrict_th: "บางกุ้ง",
            subdistrict_en: "Bang Kung",
            post_code: "72210",
          },
          {
            subdistrict_th: "ศาลาขาว",
            subdistrict_en: "Sala Khao",
            post_code: "72210",
          },
          {
            subdistrict_th: "สวนแตง",
            subdistrict_en: "Suan Taeng",
            post_code: "72210",
          },
          {
            subdistrict_th: "สนามชัย",
            subdistrict_en: "Sanam Chai",
            post_code: "72000",
          },
          {
            subdistrict_th: "โพธิ์พระยา",
            subdistrict_en: "Pho Phraya",
            post_code: "72000",
          },
          {
            subdistrict_th: "สนามคลี",
            subdistrict_en: "Sanam Khli",
            post_code: "72230",
          },
        ],
      },
      {
        district_th: "อำเภอเดิมบางนางบวช",
        district_en: "Amphoe Doem Bang Nang Buat",
        children: [
          {
            subdistrict_th: "เขาพระ",
            subdistrict_en: "Khao Phra",
            post_code: "72120",
          },
          {
            subdistrict_th: "เดิมบาง",
            subdistrict_en: "Doem Bang",
            post_code: "72120",
          },
          {
            subdistrict_th: "นางบวช",
            subdistrict_en: "Nang Buat",
            post_code: "72120",
          },
          {
            subdistrict_th: "เขาดิน",
            subdistrict_en: "Khao Din",
            post_code: "72120",
          },
          {
            subdistrict_th: "ปากน้ำ",
            subdistrict_en: "Pak Nam",
            post_code: "72120",
          },
          {
            subdistrict_th: "ทุ่งคลี",
            subdistrict_en: "Thung Khli",
            post_code: "72120",
          },
          {
            subdistrict_th: "โคกช้าง",
            subdistrict_en: "Khok Chang",
            post_code: "72120",
          },
          {
            subdistrict_th: "หัวเขา",
            subdistrict_en: "Hua Khao",
            post_code: "72120",
          },
          {
            subdistrict_th: "หัวนา",
            subdistrict_en: "Hua Na",
            post_code: "72120",
          },
          {
            subdistrict_th: "บ่อกรุ",
            subdistrict_en: "Bo Kru",
            post_code: "72120",
          },
          {
            subdistrict_th: "วังศรีราช",
            subdistrict_en: "Wang Si Rat",
            post_code: "72120",
          },
          {
            subdistrict_th: "ป่าสะแก",
            subdistrict_en: "Pa Sakae",
            post_code: "72120",
          },
          {
            subdistrict_th: "ยางนอน",
            subdistrict_en: "Yang Non",
            post_code: "72120",
          },
          {
            subdistrict_th: "หนองกระทุ่ม",
            subdistrict_en: "Nong Krathum",
            post_code: "72120",
          },
        ],
      },
      {
        district_th: "อำเภอด่านช้าง",
        district_en: "Amphoe Dan Chang",
        children: [
          {
            subdistrict_th: "หนองมะค่าโมง",
            subdistrict_en: "Nong Makha Mong",
            post_code: "72180",
          },
          {
            subdistrict_th: "ด่านช้าง",
            subdistrict_en: "Dan Chang",
            post_code: "72180",
          },
          {
            subdistrict_th: "ห้วยขมิ้น",
            subdistrict_en: "Huai Khamin",
            post_code: "72180",
          },
          {
            subdistrict_th: "องค์พระ",
            subdistrict_en: "Ong Phra",
            post_code: "72180",
          },
          {
            subdistrict_th: "วังคัน",
            subdistrict_en: "Wang Khan",
            post_code: "72180",
          },
          {
            subdistrict_th: "นิคมกระเสียว",
            subdistrict_en: "Nikhom Krasiao",
            post_code: "72180",
          },
          {
            subdistrict_th: "วังยาว",
            subdistrict_en: "Wang Yao",
            post_code: "72180",
          },
        ],
      },
      {
        district_th: "อำเภอบางปลาม้า",
        district_en: "Amphoe Bang Pla Ma",
        children: [
          {
            subdistrict_th: "โคกคราม",
            subdistrict_en: "Khok Khram",
            post_code: "72150",
          },
          {
            subdistrict_th: "บางปลาม้า",
            subdistrict_en: "Bang Pla Ma",
            post_code: "72150",
          },
          {
            subdistrict_th: "ตะค่า",
            subdistrict_en: "Takha",
            post_code: "72150",
          },
          {
            subdistrict_th: "บางใหญ่",
            subdistrict_en: "Bang Yai",
            post_code: "72150",
          },
          {
            subdistrict_th: "กฤษณา",
            subdistrict_en: "Kritsana",
            post_code: "72150",
          },
          {
            subdistrict_th: "สาลี",
            subdistrict_en: "Sali",
            post_code: "72150",
          },
          {
            subdistrict_th: "ไผ่กองดิน",
            subdistrict_en: "Phai Kong Din",
            post_code: "72150",
          },
          {
            subdistrict_th: "องครักษ์",
            subdistrict_en: "Ongkharak",
            post_code: "72150",
          },
          {
            subdistrict_th: "จรเข้ใหญ่",
            subdistrict_en: "Chorakhe Yai",
            post_code: "72150",
          },
          {
            subdistrict_th: "บ้านแหลม",
            subdistrict_en: "Ban Laem",
            post_code: "72150",
          },
          {
            subdistrict_th: "มะขามล้ม",
            subdistrict_en: "Makham Lom",
            post_code: "72150",
          },
          {
            subdistrict_th: "วังน้ำเย็น",
            subdistrict_en: "Wang Nam Yen",
            post_code: "72150",
          },
          {
            subdistrict_th: "วัดโบสถ์",
            subdistrict_en: "Wat Bot",
            post_code: "72150",
          },
          {
            subdistrict_th: "วัดดาว",
            subdistrict_en: "Wat Dao",
            post_code: "72150",
          },
        ],
      },
      {
        district_th: "อำเภอศรีประจันต์",
        district_en: "Amphoe Si Prachan",
        children: [
          {
            subdistrict_th: "ศรีประจันต์",
            subdistrict_en: "Si Prachan",
            post_code: "72140",
          },
          {
            subdistrict_th: "บ้านกร่าง",
            subdistrict_en: "Ban Krang",
            post_code: "72140",
          },
          {
            subdistrict_th: "มดแดง",
            subdistrict_en: "Mot Daeng",
            post_code: "72140",
          },
          {
            subdistrict_th: "บางงาม",
            subdistrict_en: "Bang Ngam",
            post_code: "72140",
          },
          {
            subdistrict_th: "ดอนปรู",
            subdistrict_en: "Don Pru",
            post_code: "72140",
          },
          {
            subdistrict_th: "ปลายนา",
            subdistrict_en: "Plai Na",
            post_code: "72140",
          },
          {
            subdistrict_th: "วังหว้า",
            subdistrict_en: "Wang Wa",
            post_code: "72140",
          },
          {
            subdistrict_th: "วังน้ำซับ",
            subdistrict_en: "Wang Nam Sap",
            post_code: "72140",
          },
          {
            subdistrict_th: "วังยาง",
            subdistrict_en: "Wang Yang",
            post_code: "72140",
          },
        ],
      },
      {
        district_th: "อำเภอดอนเจดีย์",
        district_en: "Amphoe Don Chedi",
        children: [
          {
            subdistrict_th: "ดอนเจดีย์",
            subdistrict_en: "Don Chedi",
            post_code: "72170",
          },
          {
            subdistrict_th: "หนองสาหร่าย",
            subdistrict_en: "Nong Sarai",
            post_code: "72170",
          },
          {
            subdistrict_th: "ไร่รถ",
            subdistrict_en: "Rai Rot",
            post_code: "72170",
          },
          {
            subdistrict_th: "สระกระโจม",
            subdistrict_en: "Sa Krachom",
            post_code: "72250",
          },
          {
            subdistrict_th: "ทะเลบก",
            subdistrict_en: "Thale Bok",
            post_code: "72250",
          },
        ],
      },
      {
        district_th: "อำเภอสองพี่น้อง",
        district_en: "Amphoe Song Phi Nong",
        children: [
          {
            subdistrict_th: "สองพี่น้อง",
            subdistrict_en: "Song Phi Nong",
            post_code: "72110",
          },
          {
            subdistrict_th: "บางเลน",
            subdistrict_en: "Bang Len",
            post_code: "72110",
          },
          {
            subdistrict_th: "บางตาเถร",
            subdistrict_en: "Bang Ta Then",
            post_code: "72110",
          },
          {
            subdistrict_th: "บางตะเคียน",
            subdistrict_en: "Bang Takhian",
            post_code: "72110",
          },
          {
            subdistrict_th: "บ้านกุ่ม",
            subdistrict_en: "Ban Kum",
            post_code: "72110",
          },
          {
            subdistrict_th: "หัวโพธิ์",
            subdistrict_en: "Hua Pho",
            post_code: "72110",
          },
          {
            subdistrict_th: "บางพลับ",
            subdistrict_en: "Bang Phlap",
            post_code: "72110",
          },
          {
            subdistrict_th: "เนินพระปรางค์",
            subdistrict_en: "Noen Phra Prang",
            post_code: "72110",
          },
          {
            subdistrict_th: "บ้านช้าง",
            subdistrict_en: "Ban Chang",
            post_code: "72110",
          },
          {
            subdistrict_th: "ต้นตาล",
            subdistrict_en: "Ton Tan",
            post_code: "72110",
          },
          {
            subdistrict_th: "ศรีสำราญ",
            subdistrict_en: "Si Samran",
            post_code: "72110",
          },
          {
            subdistrict_th: "ทุ่งคอก",
            subdistrict_en: "Thung Khok",
            post_code: "72110",
          },
          {
            subdistrict_th: "หนองบ่อ",
            subdistrict_en: "Nong Bo",
            post_code: "72190",
          },
          {
            subdistrict_th: "บ่อสุพรรณ",
            subdistrict_en: "Bo Suphan",
            post_code: "72190",
          },
          {
            subdistrict_th: "ดอนมะนาว",
            subdistrict_en: "Don Manao",
            post_code: "72110",
          },
        ],
      },
      {
        district_th: "อำเภอสามชุก",
        district_en: "Amphoe Sam Chuk",
        children: [
          {
            subdistrict_th: "ย่านยาว",
            subdistrict_en: "Yan Yao",
            post_code: "72130",
          },
          {
            subdistrict_th: "วังลึก",
            subdistrict_en: "Wang Luek",
            post_code: "72130",
          },
          {
            subdistrict_th: "สามชุก",
            subdistrict_en: "Sam Chuk",
            post_code: "72130",
          },
          {
            subdistrict_th: "หนองผักนาก",
            subdistrict_en: "Nong Phak Nak",
            post_code: "72130",
          },
          {
            subdistrict_th: "บ้านสระ",
            subdistrict_en: "Ban Sa",
            post_code: "72130",
          },
          {
            subdistrict_th: "หนองสะเดา",
            subdistrict_en: "Nong Sadao",
            post_code: "72130",
          },
          {
            subdistrict_th: "กระเสียว",
            subdistrict_en: "Krasiao",
            post_code: "72130",
          },
        ],
      },
      {
        district_th: "อำเภออู่ทอง",
        district_en: "Amphoe U Thong",
        children: [
          {
            subdistrict_th: "อู่ทอง",
            subdistrict_en: "U Thong",
            post_code: "72160",
          },
          {
            subdistrict_th: "สระยายโสม",
            subdistrict_en: "Sa Yai Som",
            post_code: "72220",
          },
          {
            subdistrict_th: "จรเข้สามพัน",
            subdistrict_en: "Chorakhe Sam Phan",
            post_code: "71170",
          },
          {
            subdistrict_th: "บ้านดอน",
            subdistrict_en: "Ban Don",
            post_code: "72160",
          },
          {
            subdistrict_th: "ยุ้งทะลาย",
            subdistrict_en: "Yung Thalai",
            post_code: "72160",
          },
          {
            subdistrict_th: "ดอนมะเกลือ",
            subdistrict_en: "Don Makluea",
            post_code: "72220",
          },
          {
            subdistrict_th: "หนองโอ่ง",
            subdistrict_en: "Nong Ong",
            post_code: "72160",
          },
          {
            subdistrict_th: "ดอนคา",
            subdistrict_en: "Don Kha",
            post_code: "72160",
          },
          {
            subdistrict_th: "พลับพลาไชย",
            subdistrict_en: "Phlapphla Chai",
            post_code: "72160",
          },
          {
            subdistrict_th: "บ้านโข้ง",
            subdistrict_en: "Ban Khong",
            post_code: "72160",
          },
          {
            subdistrict_th: "เจดีย์",
            subdistrict_en: "Chedi",
            post_code: "72160",
          },
          {
            subdistrict_th: "สระพังลาน",
            subdistrict_en: "Sa Phang Lan",
            post_code: "72220",
          },
          {
            subdistrict_th: "กระจัน",
            subdistrict_en: "Krachan",
            post_code: "72160",
          },
        ],
      },
      {
        district_th: "อำเภอหนองหญ้าไซ",
        district_en: "Amphoe Nong Ya Sai",
        children: [
          {
            subdistrict_th: "หนองหญ้าไซ",
            subdistrict_en: "Nong Ya Sai",
            post_code: "72240",
          },
          {
            subdistrict_th: "หนองราชวัตร",
            subdistrict_en: "Nong Ratchawat",
            post_code: "72240",
          },
          {
            subdistrict_th: "หนองโพธิ์",
            subdistrict_en: "Nong Pho",
            post_code: "72240",
          },
          {
            subdistrict_th: "แจงงาม",
            subdistrict_en: "Chaeng Ngam",
            post_code: "72240",
          },
          {
            subdistrict_th: "หนองขาม",
            subdistrict_en: "Nong Kham",
            post_code: "72240",
          },
          {
            subdistrict_th: "ทัพหลวง",
            subdistrict_en: "Thap Luang",
            post_code: "72240",
          },
        ],
      },
    ],
  },
  {
    province_th: "นครปฐม",
    province_en: "Nakhon Pathom",
    children: [
      {
        district_th: "อำเภอเมืองนครปฐม",
        district_en: "Amphoe Mueang Nakhon Pathom",
        children: [
          {
            subdistrict_th: "พระปฐมเจดีย์",
            subdistrict_en: "Phra Pathom Chedi",
            post_code: "73000",
          },
          {
            subdistrict_th: "บางแขม",
            subdistrict_en: "Bang Khaem",
            post_code: "73000",
          },
          {
            subdistrict_th: "พระประโทน",
            subdistrict_en: "Phra Prathon",
            post_code: "73000",
          },
          {
            subdistrict_th: "ธรรมศาลา",
            subdistrict_en: "Thammasala",
            post_code: "73000",
          },
          {
            subdistrict_th: "ตาก้อง",
            subdistrict_en: "Ta Kong",
            post_code: "73000",
          },
          {
            subdistrict_th: "มาบแค",
            subdistrict_en: "Map Khae",
            post_code: "73000",
          },
          {
            subdistrict_th: "สนามจันทร์",
            subdistrict_en: "Sanam Chan",
            post_code: "73000",
          },
          {
            subdistrict_th: "ดอนยายหอม",
            subdistrict_en: "Don Yai Hom",
            post_code: "73000",
          },
          {
            subdistrict_th: "ถนนขาด",
            subdistrict_en: "Thanon Khat",
            post_code: "73000",
          },
          {
            subdistrict_th: "บ่อพลับ",
            subdistrict_en: "Bo Phlap",
            post_code: "73000",
          },
          {
            subdistrict_th: "นครปฐม",
            subdistrict_en: "Nakhon Pathom",
            post_code: "73000",
          },
          {
            subdistrict_th: "วังตะกู",
            subdistrict_en: "Wang Taku",
            post_code: "73000",
          },
          {
            subdistrict_th: "หนองปากโลง",
            subdistrict_en: "Nong Pak Long",
            post_code: "73000",
          },
          {
            subdistrict_th: "สามควายเผือก",
            subdistrict_en: "Sam Khwai Phueak",
            post_code: "73000",
          },
          {
            subdistrict_th: "ทุ่งน้อย",
            subdistrict_en: "Thung Noi",
            post_code: "73000",
          },
          {
            subdistrict_th: "หนองดินแดง",
            subdistrict_en: "Nong Din Daeng",
            post_code: "73000",
          },
          {
            subdistrict_th: "วังเย็น",
            subdistrict_en: "Wang Yen",
            post_code: "73000",
          },
          {
            subdistrict_th: "โพรงมะเดื่อ",
            subdistrict_en: "Phrong Maduea",
            post_code: "73000",
          },
          {
            subdistrict_th: "ลำพยา",
            subdistrict_en: "Lam Phaya",
            post_code: "73000",
          },
          {
            subdistrict_th: "สระกะเทียม",
            subdistrict_en: "Sra Kathiam",
            post_code: "73000",
          },
          {
            subdistrict_th: "สวนป่าน",
            subdistrict_en: "Suan Pan",
            post_code: "73000",
          },
          {
            subdistrict_th: "ห้วยจรเข้",
            subdistrict_en: "Huai Chorakhe",
            post_code: "73000",
          },
          {
            subdistrict_th: "ทัพหลวง",
            subdistrict_en: "Thap Luang",
            post_code: "73000",
          },
          {
            subdistrict_th: "หนองงูเหลือม",
            subdistrict_en: "Nong Ngu Lueam",
            post_code: "73000",
          },
          {
            subdistrict_th: "บ้านยาง",
            subdistrict_en: "Ban Yang",
            post_code: "73000",
          },
        ],
      },
      {
        district_th: "อำเภอกำแพงแสน",
        district_en: "Amphoe Kamphaeng Saen",
        children: [
          {
            subdistrict_th: "ทุ่งกระพังโหม",
            subdistrict_en: "Thung Kraphang Hom",
            post_code: "73140",
          },
          {
            subdistrict_th: "กระตีบ",
            subdistrict_en: "Kratip",
            post_code: "73180",
          },
          {
            subdistrict_th: "ทุ่งลูกนก",
            subdistrict_en: "Thung Luk Nok",
            post_code: "73140",
          },
          {
            subdistrict_th: "ห้วยขวาง",
            subdistrict_en: "Huai Khwang",
            post_code: "73140",
          },
          {
            subdistrict_th: "ทุ่งขวาง",
            subdistrict_en: "Thung Khwang",
            post_code: "73140",
          },
          {
            subdistrict_th: "สระสี่มุม",
            subdistrict_en: "Sa Si Mum",
            post_code: "73180",
          },
          {
            subdistrict_th: "ทุ่งบัว",
            subdistrict_en: "Thung Bua",
            post_code: "73140",
          },
          {
            subdistrict_th: "ดอนข่อย",
            subdistrict_en: "Don Khoi",
            post_code: "73140",
          },
          {
            subdistrict_th: "สระพัฒนา",
            subdistrict_en: "Sa Phatthana",
            post_code: "73180",
          },
          {
            subdistrict_th: "ห้วยหมอนทอง",
            subdistrict_en: "Huai Mon Thong",
            post_code: "73140",
          },
          {
            subdistrict_th: "ห้วยม่วง",
            subdistrict_en: "huai Muang",
            post_code: "73180",
          },
          {
            subdistrict_th: "กำแพงแสน",
            subdistrict_en: "Kamphaeng Saen",
            post_code: "73140",
          },
          {
            subdistrict_th: "รางพิกุล",
            subdistrict_en: "Rang Phikun",
            post_code: "73140",
          },
          {
            subdistrict_th: "หนองกระทุ่ม",
            subdistrict_en: "Nong Krathum",
            post_code: "73140",
          },
          {
            subdistrict_th: "วังน้ำเขียว",
            subdistrict_en: "Wang Nam Khiao",
            post_code: "73140",
          },
        ],
      },
      {
        district_th: "อำเภอนครชัยศรี",
        district_en: "Amphoe Nakhon Chai Si",
        children: [
          {
            subdistrict_th: "นครชัยศรี",
            subdistrict_en: "Nakhon Chai Si",
            post_code: "73120",
          },
          {
            subdistrict_th: "บางกระเบา",
            subdistrict_en: "Bang Krabao",
            post_code: "73120",
          },
          {
            subdistrict_th: "วัดแค",
            subdistrict_en: "Wat Khae",
            post_code: "73120",
          },
          {
            subdistrict_th: "ท่าตำหนัก",
            subdistrict_en: "Tha Tamnak",
            post_code: "73120",
          },
          {
            subdistrict_th: "บางแก้ว",
            subdistrict_en: "Bang Kaeo",
            post_code: "73120",
          },
          {
            subdistrict_th: "ท่ากระชับ",
            subdistrict_en: "Tha Krachap",
            post_code: "73120",
          },
          {
            subdistrict_th: "ขุนแก้ว",
            subdistrict_en: "Khun Kaeo",
            post_code: "73120",
          },
          {
            subdistrict_th: "ท่าพระยา",
            subdistrict_en: "Tha Phraya",
            post_code: "73120",
          },
          {
            subdistrict_th: "พะเนียด",
            subdistrict_en: "Phaniat",
            post_code: "73120",
          },
          {
            subdistrict_th: "บางระกำ",
            subdistrict_en: "Bang Rakam",
            post_code: "73120",
          },
          {
            subdistrict_th: "โคกพระเจดีย์",
            subdistrict_en: "Khok Phra Chedi",
            post_code: "73120",
          },
          {
            subdistrict_th: "ศรีษะทอง",
            subdistrict_en: "Sisa Thong",
            post_code: "73120",
          },
          {
            subdistrict_th: "แหลมบัว",
            subdistrict_en: "Laem Bua",
            post_code: "73120",
          },
          {
            subdistrict_th: "ศรีมหาโพธิ์",
            subdistrict_en: "Si Maha Pho",
            post_code: "73120",
          },
          {
            subdistrict_th: "สัมปทวน",
            subdistrict_en: "Sampathuan",
            post_code: "73120",
          },
          {
            subdistrict_th: "วัดสำโรง",
            subdistrict_en: "Wat Samrong",
            post_code: "73120",
          },
          {
            subdistrict_th: "ดอนแฝก",
            subdistrict_en: "Don Faek",
            post_code: "73120",
          },
          {
            subdistrict_th: "ห้วยพลู",
            subdistrict_en: "Huai Phlu",
            post_code: "73120",
          },
          {
            subdistrict_th: "วัดละมุด",
            subdistrict_en: "Wat Lamut",
            post_code: "73120",
          },
          {
            subdistrict_th: "บางพระ",
            subdistrict_en: "Bang Phra",
            post_code: "73120",
          },
          {
            subdistrict_th: "บางแก้วฟ้า",
            subdistrict_en: "Bang Kaeo Fa",
            post_code: "73120",
          },
          {
            subdistrict_th: "ลานตากฟ้า",
            subdistrict_en: "Lan Tak Fa",
            post_code: "73120",
          },
          {
            subdistrict_th: "งิ้วราย",
            subdistrict_en: "Ngio Rai",
            post_code: "73120",
          },
          {
            subdistrict_th: "ไทยาวาส",
            subdistrict_en: "Thaiyawat",
            post_code: "73120",
          },
        ],
      },
      {
        district_th: "อำเภอดอนตูม",
        district_en: "Amphoe Don Tum",
        children: [
          {
            subdistrict_th: "สามง่าม",
            subdistrict_en: "Sam Ngam",
            post_code: "73150",
          },
          {
            subdistrict_th: "ห้วยพระ",
            subdistrict_en: "Huai Phra",
            post_code: "73150",
          },
          {
            subdistrict_th: "ลำเหย",
            subdistrict_en: "Lam Hoei",
            post_code: "73150",
          },
          {
            subdistrict_th: "ดอนพุทรา",
            subdistrict_en: "Don Phutsa",
            post_code: "73150",
          },
          {
            subdistrict_th: "บ้านหลวง",
            subdistrict_en: "Ban Luang",
            post_code: "73150",
          },
          {
            subdistrict_th: "ดอนรวก",
            subdistrict_en: "Don Ruak",
            post_code: "73150",
          },
          {
            subdistrict_th: "ห้วยด้วน",
            subdistrict_en: "Huai Duan",
            post_code: "73150",
          },
          {
            subdistrict_th: "ลำลูกบัว",
            subdistrict_en: "Lam Luk Bua",
            post_code: "73150",
          },
        ],
      },
      {
        district_th: "อำเภอบางเลน",
        district_en: "Amphoe Bang Len",
        children: [
          {
            subdistrict_th: "บางเลน",
            subdistrict_en: "Bang Len",
            post_code: "73130",
          },
          {
            subdistrict_th: "บางปลา",
            subdistrict_en: "Bang Pla",
            post_code: "73130",
          },
          {
            subdistrict_th: "บางหลวง",
            subdistrict_en: "Bang Luang",
            post_code: "73190",
          },
          {
            subdistrict_th: "บางภาษี",
            subdistrict_en: "Bang Phasi",
            post_code: "73130",
          },
          {
            subdistrict_th: "บางระกำ",
            subdistrict_en: "Bang Rakam",
            post_code: "73130",
          },
          {
            subdistrict_th: "บางไทรป่า",
            subdistrict_en: "Bang Sai Pa",
            post_code: "73130",
          },
          {
            subdistrict_th: "หินมูล",
            subdistrict_en: "Hin Mun",
            post_code: "73190",
          },
          {
            subdistrict_th: "ไทรงาม",
            subdistrict_en: "Sai Ngam",
            post_code: "73130",
          },
          {
            subdistrict_th: "ดอนตูม",
            subdistrict_en: "Don Tum",
            post_code: "73130",
          },
          {
            subdistrict_th: "นิลเพชร",
            subdistrict_en: "Nin Phet",
            post_code: "73130",
          },
          {
            subdistrict_th: "บัวปากท่า",
            subdistrict_en: "Bua Pak Tha",
            post_code: "73130",
          },
          {
            subdistrict_th: "คลองนกกระทุง",
            subdistrict_en: "Khlong Nok Krathung",
            post_code: "73130",
          },
          {
            subdistrict_th: "นราภิรมย์",
            subdistrict_en: "Naraphirom",
            post_code: "73130",
          },
          {
            subdistrict_th: "ลำพญา",
            subdistrict_en: "Lam Phaya",
            post_code: "73130",
          },
          {
            subdistrict_th: "ไผ่หูช้าง",
            subdistrict_en: "Phai Hu Chang",
            post_code: "73130",
          },
        ],
      },
      {
        district_th: "อำเภอสามพราน",
        district_en: "Amphoe Sam Phran",
        children: [
          {
            subdistrict_th: "ท่าข้าม",
            subdistrict_en: "Tha Kham",
            post_code: "73110",
          },
          {
            subdistrict_th: "ทรงคนอง",
            subdistrict_en: "Song Khanong",
            post_code: "73210",
          },
          {
            subdistrict_th: "หอมเกร็ด",
            subdistrict_en: "Hom Kret",
            post_code: "73110",
          },
          {
            subdistrict_th: "บางกระทึก",
            subdistrict_en: "Bang Krathuek",
            post_code: "73210",
          },
          {
            subdistrict_th: "บางเตย",
            subdistrict_en: "Bang Toei",
            post_code: "73210",
          },
          {
            subdistrict_th: "สามพราน",
            subdistrict_en: "Sam Phran",
            post_code: "73110",
          },
          {
            subdistrict_th: "บางช้าง",
            subdistrict_en: "Bang Chang",
            post_code: "73110",
          },
          {
            subdistrict_th: "ไร่ขิง",
            subdistrict_en: "Rai Khing",
            post_code: "73210",
          },
          {
            subdistrict_th: "ท่าตลาด",
            subdistrict_en: "Tha Talat",
            post_code: "73110",
          },
          {
            subdistrict_th: "กระทุ่มล้ม",
            subdistrict_en: "Krathum Lom",
            post_code: "73220",
          },
          {
            subdistrict_th: "คลองใหม่",
            subdistrict_en: "Khlong Mai",
            post_code: "73110",
          },
          {
            subdistrict_th: "ตลาดจินดา",
            subdistrict_en: "Talat Chinda",
            post_code: "73110",
          },
          {
            subdistrict_th: "คลองจินดา",
            subdistrict_en: "Khlong Chinda",
            post_code: "73110",
          },
          {
            subdistrict_th: "ยายชา",
            subdistrict_en: "Yai Cha",
            post_code: "73110",
          },
          {
            subdistrict_th: "บ้านใหม่",
            subdistrict_en: "Ban Mai",
            post_code: "73110",
          },
          {
            subdistrict_th: "อ้อมใหญ่",
            subdistrict_en: "Om Yai",
            post_code: "73160",
          },
        ],
      },
      {
        district_th: "อำเภอพุทธมณฑล",
        district_en: "Amphoe Phutthamonthon",
        children: [
          {
            subdistrict_th: "ศาลายา",
            subdistrict_en: "Salaya",
            post_code: "73170",
          },
          {
            subdistrict_th: "คลองโยง",
            subdistrict_en: "Khlong Yong",
            post_code: "73170",
          },
          {
            subdistrict_th: "มหาสวัสดิ์",
            subdistrict_en: "Maha Sawat",
            post_code: "73170",
          },
        ],
      },
    ],
  },
  {
    province_th: "สมุทรสาคร",
    province_en: "Samut Sakhon",
    children: [
      {
        district_th: "อำเภอเมืองสมุทรสาคร",
        district_en: "Amphoe Mueang Samut Sakhon",
        children: [
          {
            subdistrict_th: "มหาชัย",
            subdistrict_en: "Maha Chai",
            post_code: "74000",
          },
          {
            subdistrict_th: "ท่าฉลอม",
            subdistrict_en: "Tha Chalom",
            post_code: "74000",
          },
          {
            subdistrict_th: "โกรกกราก",
            subdistrict_en: "Krokkrak",
            post_code: "74000",
          },
          {
            subdistrict_th: "บ้านบ่อ",
            subdistrict_en: "Ban Bo",
            post_code: "74000",
          },
          {
            subdistrict_th: "บางโทรัด",
            subdistrict_en: "Bang Tho Rat",
            post_code: "74000",
          },
          {
            subdistrict_th: "กาหลง",
            subdistrict_en: "Kalong",
            post_code: "74000",
          },
          {
            subdistrict_th: "นาโคก",
            subdistrict_en: "Na Khok",
            post_code: "74000",
          },
          {
            subdistrict_th: "ท่าจีน",
            subdistrict_en: "Tha Chin",
            post_code: "74000",
          },
          {
            subdistrict_th: "นาดี",
            subdistrict_en: "Na Di",
            post_code: "74000",
          },
          {
            subdistrict_th: "ท่าทราย",
            subdistrict_en: "Tha Sai",
            post_code: "74000",
          },
          {
            subdistrict_th: "คอกกระบือ",
            subdistrict_en: "Khok Krabue",
            post_code: "74000",
          },
          {
            subdistrict_th: "บางน้ำจืด",
            subdistrict_en: "Bang Nam Chuet",
            post_code: "74000",
          },
          {
            subdistrict_th: "พันท้ายนรสิงห์",
            subdistrict_en: "Phan Thai Norasing",
            post_code: "74000",
          },
          {
            subdistrict_th: "โคกขาม",
            subdistrict_en: "Khok Kham",
            post_code: "74000",
          },
          {
            subdistrict_th: "บ้านเกาะ",
            subdistrict_en: "Ban Ko",
            post_code: "74000",
          },
          {
            subdistrict_th: "บางกระเจ้า",
            subdistrict_en: "Bang Krachao",
            post_code: "74000",
          },
          {
            subdistrict_th: "บางหญ้าแพรก",
            subdistrict_en: "Bang Ya Phraek",
            post_code: "74000",
          },
          {
            subdistrict_th: "ชัยมงคล",
            subdistrict_en: "Chai Mongkon",
            post_code: "74000",
          },
        ],
      },
      {
        district_th: "อำเภอกระทุ่มแบน",
        district_en: "Amphoe Krathum Baen",
        children: [
          {
            subdistrict_th: "ตลาดกระทุ่มแบน",
            subdistrict_en: "Talad Krathum Baen",
            post_code: "74110",
          },
          {
            subdistrict_th: "อ้อมน้อย",
            subdistrict_en: "Om Noi",
            post_code: "74130",
          },
          {
            subdistrict_th: "ท่าไม้",
            subdistrict_en: "Tha Mai",
            post_code: "74110",
          },
          {
            subdistrict_th: "สวนหลวง",
            subdistrict_en: "Suan Luang",
            post_code: "74110",
          },
          {
            subdistrict_th: "บางยาง",
            subdistrict_en: "Bang Yang",
            post_code: "74110",
          },
          {
            subdistrict_th: "คลองมะเดื่อ",
            subdistrict_en: "Khlong Maduea",
            post_code: "74110",
          },
          {
            subdistrict_th: "หนองนกไข่",
            subdistrict_en: "Nong Nok Khai",
            post_code: "74110",
          },
          {
            subdistrict_th: "ดอนไก่ดี",
            subdistrict_en: "Don Kai Di",
            post_code: "74110",
          },
          {
            subdistrict_th: "แคราย",
            subdistrict_en: "Khae Rai",
            post_code: "74110",
          },
          {
            subdistrict_th: "ท่าเสา",
            subdistrict_en: "Tha Sao",
            post_code: "74110",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านแพ้ว",
        district_en: "Amphoe Ban Phaeo",
        children: [
          {
            subdistrict_th: "บ้านแพ้ว",
            subdistrict_en: "Ban Phaeo",
            post_code: "74120",
          },
          {
            subdistrict_th: "หลักสาม",
            subdistrict_en: "Lak Sam",
            post_code: "74120",
          },
          {
            subdistrict_th: "ยกกระบัตร",
            subdistrict_en: "Yokkrabat",
            post_code: "74120",
          },
          {
            subdistrict_th: "โรงเข้",
            subdistrict_en: "Rong Khe",
            post_code: "74120",
          },
          {
            subdistrict_th: "หนองสองห้อง",
            subdistrict_en: "Nong Song Hong",
            post_code: "74120",
          },
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "74120",
          },
          {
            subdistrict_th: "หลักสอง",
            subdistrict_en: "Lak Song",
            post_code: "74120",
          },
          {
            subdistrict_th: "เจ็ดริ้ว",
            subdistrict_en: "Chet Rio",
            post_code: "74120",
          },
          {
            subdistrict_th: "คลองตัน",
            subdistrict_en: "Khlong Tan",
            post_code: "74120",
          },
          {
            subdistrict_th: "อำแพง",
            subdistrict_en: "Amphaeng",
            post_code: "74120",
          },
          {
            subdistrict_th: "สวนส้ม",
            subdistrict_en: "Suan Som",
            post_code: "74120",
          },
          {
            subdistrict_th: "เกษตรพัฒนา",
            subdistrict_en: "Kaset Phatthana",
            post_code: "74120",
          },
        ],
      },
    ],
  },
  {
    province_th: "สมุทรสงคราม",
    province_en: "Samut Songkhram",
    children: [
      {
        district_th: "อำเภอเมืองสมุทรสงคราม",
        district_en: "Amphoe Mueang Samut Songkhram",
        children: [
          {
            subdistrict_th: "แม่กลอง",
            subdistrict_en: "Mae Klong",
            post_code: "75000",
          },
          {
            subdistrict_th: "บางขันแตก",
            subdistrict_en: "Bang Khan Taek",
            post_code: "75000",
          },
          {
            subdistrict_th: "ลาดใหญ่",
            subdistrict_en: "Lat Yai",
            post_code: "75000",
          },
          {
            subdistrict_th: "บ้านปรก",
            subdistrict_en: "Ban Prok",
            post_code: "75000",
          },
          {
            subdistrict_th: "บางแก้ว",
            subdistrict_en: "Bang Kaeo",
            post_code: "75000",
          },
          {
            subdistrict_th: "ท้ายหาด",
            subdistrict_en: "Thai Hat",
            post_code: "75000",
          },
          {
            subdistrict_th: "แหลมใหญ่",
            subdistrict_en: "Laem Yai",
            post_code: "75000",
          },
          {
            subdistrict_th: "คลองเขิน",
            subdistrict_en: "Khlong Khoen",
            post_code: "75000",
          },
          {
            subdistrict_th: "คลองโคน",
            subdistrict_en: "Khlong Khon",
            post_code: "75000",
          },
          {
            subdistrict_th: "นางตะเคียน",
            subdistrict_en: "Nang Takian",
            post_code: "75000",
          },
          {
            subdistrict_th: "บางจะเกร็ง",
            subdistrict_en: "Bang Chakreng",
            post_code: "75000",
          },
        ],
      },
      {
        district_th: "อำเภอบางคนที",
        district_en: "Amphoe Bang Khonthi",
        children: [
          {
            subdistrict_th: "กระดังงา",
            subdistrict_en: "Kradangnga",
            post_code: "75120",
          },
          {
            subdistrict_th: "บางสะแก",
            subdistrict_en: "Bang Sakae",
            post_code: "75120",
          },
          {
            subdistrict_th: "บางยี่รงค์",
            subdistrict_en: "Bang Yi Rong",
            post_code: "75120",
          },
          {
            subdistrict_th: "โรงหีบ",
            subdistrict_en: "Rong Hip",
            post_code: "75120",
          },
          {
            subdistrict_th: "บางคนที",
            subdistrict_en: "Bang Khonthi",
            post_code: "75120",
          },
          {
            subdistrict_th: "ดอนมะโนรา",
            subdistrict_en: "Don Manora",
            post_code: "75120",
          },
          {
            subdistrict_th: "บางพรม",
            subdistrict_en: "Bang Phrom",
            post_code: "75120",
          },
          {
            subdistrict_th: "บางกุ้ง",
            subdistrict_en: "Bang Kung",
            post_code: "75120",
          },
          {
            subdistrict_th: "จอมปลวก",
            subdistrict_en: "Chom Pluak",
            post_code: "75120",
          },
          {
            subdistrict_th: "บางนกแขวก",
            subdistrict_en: "Bang Nok Khwaek",
            post_code: "75120",
          },
          {
            subdistrict_th: "ยายแพง",
            subdistrict_en: "Yai Phaeng",
            post_code: "75120",
          },
          {
            subdistrict_th: "บางกระบือ",
            subdistrict_en: "Bang Krabue",
            post_code: "75120",
          },
          {
            subdistrict_th: "บ้านปราโมทย์",
            subdistrict_en: "Ban Pramot",
            post_code: "75120",
          },
        ],
      },
      {
        district_th: "อำเภออัมพวา",
        district_en: "Amphoe Amphawa",
        children: [
          {
            subdistrict_th: "อัมพวา",
            subdistrict_en: "Amphawa",
            post_code: "75110",
          },
          {
            subdistrict_th: "สวนหลวง",
            subdistrict_en: "Suan Luang",
            post_code: "75110",
          },
          {
            subdistrict_th: "ท่าคา",
            subdistrict_en: "Tha Kha",
            post_code: "75110",
          },
          {
            subdistrict_th: "วัดประดู่",
            subdistrict_en: "Wat Pradu",
            post_code: "75110",
          },
          {
            subdistrict_th: "เหมืองใหม่",
            subdistrict_en: "Mueang Mai",
            post_code: "75110",
          },
          {
            subdistrict_th: "บางช้าง",
            subdistrict_en: "Bang Chang",
            post_code: "75110",
          },
          {
            subdistrict_th: "แควอ้อม",
            subdistrict_en: "Khwaae Om",
            post_code: "75110",
          },
          {
            subdistrict_th: "ปลายโพงพาง",
            subdistrict_en: "Plai Phongphang",
            post_code: "75110",
          },
          {
            subdistrict_th: "บางแค",
            subdistrict_en: "Bang Khae",
            post_code: "75110",
          },
          {
            subdistrict_th: "แพรกหนามแดง",
            subdistrict_en: "Phraek Nam Daeng",
            post_code: "75110",
          },
          {
            subdistrict_th: "ยี่สาร",
            subdistrict_en: "Yi San",
            post_code: "75110",
          },
          {
            subdistrict_th: "บางนางลี่",
            subdistrict_en: "Bang Nang Li",
            post_code: "75110",
          },
        ],
      },
    ],
  },
  {
    province_th: "เพชรบุรี",
    province_en: "Phetchaburi",
    children: [
      {
        district_th: "อำเภอเมืองเพชรบุรี",
        district_en: "Amphoe Mueang Phetchaburi",
        children: [
          {
            subdistrict_th: "ท่าราบ",
            subdistrict_en: "Tha Rap",
            post_code: "76000",
          },
          {
            subdistrict_th: "คลองกระแชง",
            subdistrict_en: "Khlong Krachaeng",
            post_code: "76000",
          },
          {
            subdistrict_th: "บางจาน",
            subdistrict_en: "Bang Chan",
            post_code: "76000",
          },
          {
            subdistrict_th: "นาพันสาม",
            subdistrict_en: "Na Phan Sam",
            post_code: "76000",
          },
          {
            subdistrict_th: "ธงชัย",
            subdistrict_en: "Thong Chai",
            post_code: "76000",
          },
          {
            subdistrict_th: "บ้านกุ่ม",
            subdistrict_en: "Ban Kum",
            post_code: "76000",
          },
          {
            subdistrict_th: "หนองโสน",
            subdistrict_en: "Nong Sano",
            post_code: "76000",
          },
          {
            subdistrict_th: "ไร่ส้ม",
            subdistrict_en: "Rai Som",
            post_code: "76000",
          },
          {
            subdistrict_th: "เวียงคอย",
            subdistrict_en: "Wiang Khoi",
            post_code: "76000",
          },
          {
            subdistrict_th: "บางจาก",
            subdistrict_en: "Bang Chak",
            post_code: "76000",
          },
          {
            subdistrict_th: "บ้านหม้อ",
            subdistrict_en: "Ban Mo",
            post_code: "76000",
          },
          {
            subdistrict_th: "ต้นมะม่วง",
            subdistrict_en: "Ton Mamuang",
            post_code: "76000",
          },
          {
            subdistrict_th: "ช่องสะแก",
            subdistrict_en: "Chong Sakae",
            post_code: "76000",
          },
          {
            subdistrict_th: "นาวุ้ง",
            subdistrict_en: "Na Wung",
            post_code: "76000",
          },
          {
            subdistrict_th: "สำมะโรง",
            subdistrict_en: "Sam Marong",
            post_code: "76000",
          },
          {
            subdistrict_th: "โพพระ",
            subdistrict_en: "Pho Phra",
            post_code: "76000",
          },
          {
            subdistrict_th: "หาดเจ้าสำราญ",
            subdistrict_en: "Hat Chao Samran",
            post_code: "76100",
          },
          {
            subdistrict_th: "หัวสะพาน",
            subdistrict_en: "Hua Saphan",
            post_code: "76000",
          },
          {
            subdistrict_th: "ต้นมะพร้าว",
            subdistrict_en: "Ton Maphrao",
            post_code: "76000",
          },
          {
            subdistrict_th: "วังตะโก",
            subdistrict_en: "Wang Tako",
            post_code: "76000",
          },
          {
            subdistrict_th: "โพไร่หวาน",
            subdistrict_en: "Pho Rai Wan",
            post_code: "76000",
          },
          {
            subdistrict_th: "ดอนยาง",
            subdistrict_en: "Don Yang",
            post_code: "76000",
          },
          {
            subdistrict_th: "หนองขนาน",
            subdistrict_en: "Nong Khanan",
            post_code: "76000",
          },
          {
            subdistrict_th: "หนองพลับ",
            subdistrict_en: "Nong Phlap",
            post_code: "76000",
          },
        ],
      },
      {
        district_th: "อำเภอเขาย้อย",
        district_en: "Amphoe Khao Yoi",
        children: [
          {
            subdistrict_th: "เขาย้อย",
            subdistrict_en: "Khao Yoi",
            post_code: "76140",
          },
          {
            subdistrict_th: "สระพัง",
            subdistrict_en: "Sa Phang",
            post_code: "76140",
          },
          {
            subdistrict_th: "บางเค็ม",
            subdistrict_en: "Bang Khem",
            post_code: "76140",
          },
          {
            subdistrict_th: "ทับคาง",
            subdistrict_en: "Thap Khang",
            post_code: "76140",
          },
          {
            subdistrict_th: "หนองปลาไหล",
            subdistrict_en: "Nong Pla Lai",
            post_code: "76140",
          },
          {
            subdistrict_th: "หนองปรง",
            subdistrict_en: "Nong Prong",
            post_code: "76140",
          },
          {
            subdistrict_th: "หนองชุมพล",
            subdistrict_en: "Nong Chumphon",
            post_code: "76140",
          },
          {
            subdistrict_th: "ห้วยโรง",
            subdistrict_en: "Huai Rong",
            post_code: "76140",
          },
          {
            subdistrict_th: "ห้วยท่าช้าง",
            subdistrict_en: "Huai Tha Chang",
            post_code: "76140",
          },
          {
            subdistrict_th: "หนองชุมพลเหนือ",
            subdistrict_en: "Nong Chumphon Nuea",
            post_code: "76140",
          },
        ],
      },
      {
        district_th: "อำเภอหนองหญ้าปล้อง",
        district_en: "Amphoe Nong Ya Plong",
        children: [
          {
            subdistrict_th: "หนองหญ้าปล้อง",
            subdistrict_en: "Nong Ya Plong",
            post_code: "76160",
          },
          {
            subdistrict_th: "ยางน้ำกลัดเหนือ",
            subdistrict_en: "Yang Nam Klat Nuea",
            post_code: "76160",
          },
          {
            subdistrict_th: "ยางน้ำกลัดใต้",
            subdistrict_en: "Yang Nam Klat Tai",
            post_code: "76160",
          },
          {
            subdistrict_th: "ท่าตะคร้อ",
            subdistrict_en: "Tha Ta Khro",
            post_code: "76160",
          },
        ],
      },
      {
        district_th: "อำเภอชะอำ",
        district_en: "Amphoe Cha-am",
        children: [
          {
            subdistrict_th: "ชะอำ",
            subdistrict_en: "Cha-am",
            post_code: "76120",
          },
          {
            subdistrict_th: "บางเก่า",
            subdistrict_en: "Bang Kao",
            post_code: "76120",
          },
          {
            subdistrict_th: "นายาง",
            subdistrict_en: "Na Yang",
            post_code: "76120",
          },
          {
            subdistrict_th: "เขาใหญ่",
            subdistrict_en: "Khao Yai",
            post_code: "76120",
          },
          {
            subdistrict_th: "หนองศาลา",
            subdistrict_en: "Nong Sala",
            post_code: "76120",
          },
          {
            subdistrict_th: "ห้วยทรายเหนือ",
            subdistrict_en: "Huai Sai Nuea",
            post_code: "76120",
          },
          {
            subdistrict_th: "ไร่ใหม่พัฒนา",
            subdistrict_en: "Rai Mai Phatthana",
            post_code: "76120",
          },
          {
            subdistrict_th: "สามพระยา",
            subdistrict_en: "Sam Phraya",
            post_code: "76120",
          },
          {
            subdistrict_th: "ดอนขุนห้วย",
            subdistrict_en: "Don Khun Huai",
            post_code: "76120",
          },
        ],
      },
      {
        district_th: "อำเภอท่ายาง",
        district_en: "Amphoe Tha Yang",
        children: [
          {
            subdistrict_th: "ท่ายาง",
            subdistrict_en: "Tha Yang",
            post_code: "76130",
          },
          {
            subdistrict_th: "ท่าคอย",
            subdistrict_en: "Tha Khoi",
            post_code: "76130",
          },
          {
            subdistrict_th: "ยางหย่อง",
            subdistrict_en: "Yang Yong",
            post_code: "76130",
          },
          {
            subdistrict_th: "หนองจอก",
            subdistrict_en: "Nong Chok",
            post_code: "76130",
          },
          {
            subdistrict_th: "มาบปลาเค้า",
            subdistrict_en: "Map Pla Khao",
            post_code: "76130",
          },
          {
            subdistrict_th: "ท่าไม้รวก",
            subdistrict_en: "Tha Mai Ruak",
            post_code: "76130",
          },
          {
            subdistrict_th: "วังไคร้",
            subdistrict_en: "Wang Khrai",
            post_code: "76130",
          },
          {
            subdistrict_th: "กลัดหลวง",
            subdistrict_en: "Klat Luang",
            post_code: "76130",
          },
          {
            subdistrict_th: "ปึกเตียน",
            subdistrict_en: "Puek Tian",
            post_code: "76130",
          },
          {
            subdistrict_th: "เขากระปุก",
            subdistrict_en: "Khao Krapuk",
            post_code: "76130",
          },
          {
            subdistrict_th: "ท่าแลง",
            subdistrict_en: "Tha Laeng",
            post_code: "76130",
          },
          {
            subdistrict_th: "บ้านในดง",
            subdistrict_en: "Ban Nai Dong",
            post_code: "76130",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านลาด",
        district_en: "Amphoe Ban Lat",
        children: [
          {
            subdistrict_th: "บ้านลาด",
            subdistrict_en: "Ban Lat",
            post_code: "76150",
          },
          {
            subdistrict_th: "บ้านหาด",
            subdistrict_en: "Ban Hat",
            post_code: "76150",
          },
          {
            subdistrict_th: "บ้านทาน",
            subdistrict_en: "Ban Than",
            post_code: "76150",
          },
          {
            subdistrict_th: "ตำหรุ",
            subdistrict_en: "Tamru",
            post_code: "76150",
          },
          {
            subdistrict_th: "สมอพลือ",
            subdistrict_en: "Samo Phlue",
            post_code: "76150",
          },
          {
            subdistrict_th: "ไร่มะขาม",
            subdistrict_en: "Rai Makham",
            post_code: "76150",
          },
          {
            subdistrict_th: "ท่าเสน",
            subdistrict_en: "Tha Sen",
            post_code: "76150",
          },
          {
            subdistrict_th: "หนองกระเจ็ด",
            subdistrict_en: "Nong Krachet",
            post_code: "76150",
          },
          {
            subdistrict_th: "หนองกะปุ",
            subdistrict_en: "Nong Kapu",
            post_code: "76150",
          },
          {
            subdistrict_th: "ลาดโพธิ์",
            subdistrict_en: "Lat Pho",
            post_code: "76150",
          },
          {
            subdistrict_th: "สะพานไกร",
            subdistrict_en: "Saphan Krai",
            post_code: "76150",
          },
          {
            subdistrict_th: "ไร่โคก",
            subdistrict_en: "Rai Khok",
            post_code: "76150",
          },
          {
            subdistrict_th: "โรงเข้",
            subdistrict_en: "Rong Khe",
            post_code: "76150",
          },
          {
            subdistrict_th: "ไร่สะท้อน",
            subdistrict_en: "Rai Sathon",
            post_code: "76150",
          },
          {
            subdistrict_th: "ห้วยข้อง",
            subdistrict_en: "Huai Khong",
            post_code: "76150",
          },
          {
            subdistrict_th: "ท่าช้าง",
            subdistrict_en: "Tha Chang",
            post_code: "76150",
          },
          {
            subdistrict_th: "ถ้ำรงค์",
            subdistrict_en: "Tham Rong",
            post_code: "76150",
          },
          {
            subdistrict_th: "ห้วยลึก",
            subdistrict_en: "Huai Luek",
            post_code: "76150",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านแหลม",
        district_en: "Amphoe Ban Laem",
        children: [
          {
            subdistrict_th: "บ้านแหลม",
            subdistrict_en: "Ban Laem",
            post_code: "76110",
          },
          {
            subdistrict_th: "บางขุนไทร",
            subdistrict_en: "Bang Khun Sai",
            post_code: "76110",
          },
          {
            subdistrict_th: "ปากทะเล",
            subdistrict_en: "Pak Thale",
            post_code: "76110",
          },
          {
            subdistrict_th: "บางแก้ว",
            subdistrict_en: "Bang Kaeo",
            post_code: "76110",
          },
          {
            subdistrict_th: "แหลมผักเบี้ย",
            subdistrict_en: "Laem Phak Bia",
            post_code: "76100",
          },
          {
            subdistrict_th: "บางตะบูน",
            subdistrict_en: "Bang Tabun",
            post_code: "76110",
          },
          {
            subdistrict_th: "บางตะบูนออก",
            subdistrict_en: "Bang Tabun Ok",
            post_code: "76110",
          },
          {
            subdistrict_th: "บางครก",
            subdistrict_en: "Bang Khrok",
            post_code: "76110",
          },
          {
            subdistrict_th: "ท่าแร้ง",
            subdistrict_en: "Tha Raeng",
            post_code: "76110",
          },
          {
            subdistrict_th: "ท่าแร้งออก",
            subdistrict_en: "Tha Raeng Ok",
            post_code: "76110",
          },
        ],
      },
      {
        district_th: "อำเภอแก่งกระจาน",
        district_en: "Amphoe Kaeng Krachan",
        children: [
          {
            subdistrict_th: "แก่งกระจาน",
            subdistrict_en: "Kaeng Krachan",
            post_code: "76170",
          },
          {
            subdistrict_th: "สองพี่น้อง",
            subdistrict_en: "Song Phi Nong",
            post_code: "76170",
          },
          {
            subdistrict_th: "วังจันทร์",
            subdistrict_en: "Wang Chan",
            post_code: "76170",
          },
          {
            subdistrict_th: "ป่าเด็ง",
            subdistrict_en: "Pa Deng",
            post_code: "76170",
          },
          {
            subdistrict_th: "พุสวรรค์",
            subdistrict_en: "Phu Sawan",
            post_code: "76170",
          },
          {
            subdistrict_th: "ห้วยแม่เพรียง",
            subdistrict_en: "Huai Mae Phriang",
            post_code: "76170",
          },
        ],
      },
    ],
  },
  {
    province_th: "ประจวบคีรีขันธ์",
    province_en: "Prachuap Khiri Khan",
    children: [
      {
        district_th: "อำเภอเมืองประจวบคีรีขันธ์",
        district_en: "Amphoe Mueang Prachuap Khiri Khan",
        children: [
          {
            subdistrict_th: "ประจวบคีรีขันธ์",
            subdistrict_en: "Prachuap Khiri Khan",
            post_code: "77000",
          },
          {
            subdistrict_th: "เกาะหลัก",
            subdistrict_en: "Ko Lak",
            post_code: "77000",
          },
          {
            subdistrict_th: "คลองวาฬ",
            subdistrict_en: "Khlong Wan",
            post_code: "77000",
          },
          {
            subdistrict_th: "ห้วยทราย",
            subdistrict_en: "Huai Sai",
            post_code: "77000",
          },
          {
            subdistrict_th: "อ่าวน้อย",
            subdistrict_en: "Ao Noi",
            post_code: "77210",
          },
          {
            subdistrict_th: "บ่อนอก",
            subdistrict_en: "Bo Nok",
            post_code: "77210",
          },
        ],
      },
      {
        district_th: "อำเภอกุยบุรี",
        district_en: "Amphoe Kui Buri",
        children: [
          {
            subdistrict_th: "กุยบุรี",
            subdistrict_en: "Kui Buri",
            post_code: "77150",
          },
          {
            subdistrict_th: "กุยเหนือ",
            subdistrict_en: "Kui Nuea",
            post_code: "77150",
          },
          {
            subdistrict_th: "เขาแดง",
            subdistrict_en: "Khao Daeng",
            post_code: "77150",
          },
          {
            subdistrict_th: "ดอนยายหนู",
            subdistrict_en: "Don Yai Nu",
            post_code: "77150",
          },
          {
            subdistrict_th: "สามกระทาย",
            subdistrict_en: "Sam Krathai",
            post_code: "77150",
          },
          {
            subdistrict_th: "หาดขาม",
            subdistrict_en: "Hat Kham",
            post_code: "77150",
          },
        ],
      },
      {
        district_th: "อำเภอทับสะแก",
        district_en: "Amphoe Thap Sakae",
        children: [
          {
            subdistrict_th: "ทับสะแก",
            subdistrict_en: "Thap Sakae",
            post_code: "77130",
          },
          {
            subdistrict_th: "อ่างทอง",
            subdistrict_en: "Ang Thong",
            post_code: "77130",
          },
          {
            subdistrict_th: "นาหูกวาง",
            subdistrict_en: "Na Hu Kwang",
            post_code: "77130",
          },
          {
            subdistrict_th: "เขาล้าน",
            subdistrict_en: "Khao Lan",
            post_code: "77130",
          },
          {
            subdistrict_th: "ห้วยยาง",
            subdistrict_en: "Huai Yang",
            post_code: "77130",
          },
          {
            subdistrict_th: "แสงอรุณ",
            subdistrict_en: "Saeng Arun",
            post_code: "77130",
          },
        ],
      },
      {
        district_th: "อำเภอบางสะพาน",
        district_en: "Amphoe Bang Saphan",
        children: [
          {
            subdistrict_th: "กำเนิดนพคุณ",
            subdistrict_en: "Kamnoet Nopphakhun",
            post_code: "77140",
          },
          {
            subdistrict_th: "พงศ์ประศาสน์",
            subdistrict_en: "Phong Prasat",
            post_code: "77140",
          },
          {
            subdistrict_th: "ร่อนทอง",
            subdistrict_en: "Ron Thong",
            post_code: "77230",
          },
          {
            subdistrict_th: "ธงชัย",
            subdistrict_en: "Thong Chai",
            post_code: "77190",
          },
          {
            subdistrict_th: "ชัยเกษม",
            subdistrict_en: "Chai Kasem",
            post_code: "77190",
          },
          {
            subdistrict_th: "ทองมงคล",
            subdistrict_en: "Thong Mongkhon",
            post_code: "77230",
          },
          {
            subdistrict_th: "แม่รำพึง",
            subdistrict_en: "Mae Ramphueng",
            post_code: "77140",
          },
        ],
      },
      {
        district_th: "อำเภอบางสะพานน้อย",
        district_en: "Amphoe Bang Saphan Noi",
        children: [
          {
            subdistrict_th: "ปากแพรก",
            subdistrict_en: "Pak Phraek",
            post_code: "77170",
          },
          {
            subdistrict_th: "บางสะพาน",
            subdistrict_en: "Bang Saphan",
            post_code: "77170",
          },
          {
            subdistrict_th: "ทรายทอง",
            subdistrict_en: "Sai Thong",
            post_code: "77170",
          },
          {
            subdistrict_th: "ช้างแรก",
            subdistrict_en: "Chang Raek",
            post_code: "77170",
          },
          {
            subdistrict_th: "ไชยราช",
            subdistrict_en: "Chai Rat",
            post_code: "77170",
          },
        ],
      },
      {
        district_th: "อำเภอปราณบุรี",
        district_en: "Amphoe Pran Buri",
        children: [
          {
            subdistrict_th: "ปราณบุรี",
            subdistrict_en: "Pran Buri",
            post_code: "77120",
          },
          {
            subdistrict_th: "เขาน้อย",
            subdistrict_en: "Khao Noi",
            post_code: "77120",
          },
          {
            subdistrict_th: "ปากน้ำปราณ",
            subdistrict_en: "Pak Nam Pran",
            post_code: "77220",
          },
          {
            subdistrict_th: "หนองตาแต้ม",
            subdistrict_en: "Nong Ta Taem",
            post_code: "77120",
          },
          {
            subdistrict_th: "วังก์พง",
            subdistrict_en: "Wang Phong",
            post_code: "77120",
          },
          {
            subdistrict_th: "เขาจ้าว",
            subdistrict_en: "Khao Chao",
            post_code: "77120",
          },
        ],
      },
      {
        district_th: "อำเภอหัวหิน",
        district_en: "Amphoe Hua Hin",
        children: [
          {
            subdistrict_th: "หัวหิน",
            subdistrict_en: "Hua Hin",
            post_code: "77110",
          },
          {
            subdistrict_th: "หนองแก",
            subdistrict_en: "Nong Kae",
            post_code: "77110",
          },
          {
            subdistrict_th: "หินเหล็กไฟ",
            subdistrict_en: "Hin Lek Fai",
            post_code: "77110",
          },
          {
            subdistrict_th: "หนองพลับ",
            subdistrict_en: "Nong Phlap",
            post_code: "77110",
          },
          {
            subdistrict_th: "ทับใต้",
            subdistrict_en: "Thap Tai",
            post_code: "77110",
          },
          {
            subdistrict_th: "ห้วยสัตว์ใหญ่",
            subdistrict_en: "Huai Sat Yai",
            post_code: "77110",
          },
          {
            subdistrict_th: "บึงนคร",
            subdistrict_en: "Bueng Nakhon",
            post_code: "77110",
          },
        ],
      },
      {
        district_th: "อำเภอสามร้อยยอด",
        district_en: "Amphoe Sam Roi Yot",
        children: [
          {
            subdistrict_th: "สามร้อยยอด",
            subdistrict_en: "Sam Roi Yot",
            post_code: "77120",
          },
          {
            subdistrict_th: "ศิลาลอย",
            subdistrict_en: "Sila Loi",
            post_code: "77180",
          },
          {
            subdistrict_th: "ไร่เก่า",
            subdistrict_en: "Rai Kao",
            post_code: "77180",
          },
          {
            subdistrict_th: "ศาลาลัย",
            subdistrict_en: "Salalai",
            post_code: "77180",
          },
          {
            subdistrict_th: "ไร่ใหม่",
            subdistrict_en: "Rai Mai",
            post_code: "77180",
          },
        ],
      },
    ],
  },
  {
    province_th: "นครศรีธรรมราช",
    province_en: "Nakhon Si Thammarat",
    children: [
      {
        district_th: "อำเภอเมืองนครศรีธรรมราช",
        district_en: "Amphoe Mueang Nakhon Si Thammarat",
        children: [
          {
            subdistrict_th: "ในเมือง",
            subdistrict_en: "Nai Mueang",
            post_code: "80000",
          },
          {
            subdistrict_th: "ท่าวัง",
            subdistrict_en: "Tha Wang",
            post_code: "80000",
          },
          {
            subdistrict_th: "คลัง",
            subdistrict_en: "Khlang",
            post_code: "80000",
          },
          {
            subdistrict_th: "ท่าไร่",
            subdistrict_en: "Tha Rai",
            post_code: "80000",
          },
          {
            subdistrict_th: "ปากนคร",
            subdistrict_en: "Pak Nakhon",
            post_code: "80000",
          },
          {
            subdistrict_th: "นาทราย",
            subdistrict_en: "Na Sai",
            post_code: "80280",
          },
          {
            subdistrict_th: "กำแพงเซา",
            subdistrict_en: "Kamphaeng Sao",
            post_code: "80280",
          },
          {
            subdistrict_th: "ไชยมนตรี",
            subdistrict_en: "Chai Montri",
            post_code: "80000",
          },
          {
            subdistrict_th: "มะม่วงสองต้น",
            subdistrict_en: "Mamuang Song Ton",
            post_code: "80000",
          },
          {
            subdistrict_th: "นาเคียน",
            subdistrict_en: "Na Khian",
            post_code: "80000",
          },
          {
            subdistrict_th: "ท่างิ้ว",
            subdistrict_en: "Tha Ngio",
            post_code: "80280",
          },
          {
            subdistrict_th: "โพธิ์เสด็จ",
            subdistrict_en: "Pho Sadet",
            post_code: "80000",
          },
          {
            subdistrict_th: "บางจาก",
            subdistrict_en: "Bang Chak",
            post_code: "80330",
          },
          {
            subdistrict_th: "ปากพูน",
            subdistrict_en: "Pak Phun",
            post_code: "80000",
          },
          {
            subdistrict_th: "ท่าซัก",
            subdistrict_en: "Tha Sak",
            post_code: "80000",
          },
          {
            subdistrict_th: "ท่าเรือ",
            subdistrict_en: "Tha Ruea",
            post_code: "80000",
          },
        ],
      },
      {
        district_th: "อำเภอพรหมคีรี",
        district_en: "Amphoe Phrom Khiri",
        children: [
          {
            subdistrict_th: "พรหมโลก",
            subdistrict_en: "Phrommalok",
            post_code: "80320",
          },
          {
            subdistrict_th: "บ้านเกาะ",
            subdistrict_en: "Ban Ko",
            post_code: "80320",
          },
          {
            subdistrict_th: "อินคีรี",
            subdistrict_en: "In Khiri",
            post_code: "80320",
          },
          {
            subdistrict_th: "ทอนหงส์",
            subdistrict_en: "Thon Hong",
            post_code: "80320",
          },
          {
            subdistrict_th: "นาเรียง",
            subdistrict_en: "Na Riang",
            post_code: "80320",
          },
        ],
      },
      {
        district_th: "อำเภอลานสกา",
        district_en: "Amphoe Lan Saka",
        children: [
          {
            subdistrict_th: "เขาแก้ว",
            subdistrict_en: "Khao Kaeo",
            post_code: "80230",
          },
          {
            subdistrict_th: "ลานสกา",
            subdistrict_en: "Lan Saka",
            post_code: "80230",
          },
          {
            subdistrict_th: "ท่าดี",
            subdistrict_en: "Tha Di",
            post_code: "80230",
          },
          {
            subdistrict_th: "กำโลน",
            subdistrict_en: "Kamlon",
            post_code: "80230",
          },
          {
            subdistrict_th: "ขุนทะเล",
            subdistrict_en: "Khun Thale",
            post_code: "80230",
          },
        ],
      },
      {
        district_th: "อำเภอฉวาง",
        district_en: "Amphoe Chawang",
        children: [
          {
            subdistrict_th: "ฉวาง",
            subdistrict_en: "Chawang",
            post_code: "80150",
          },
          {
            subdistrict_th: "ละอาย",
            subdistrict_en: "La-ai",
            post_code: "80250",
          },
          {
            subdistrict_th: "นาแว",
            subdistrict_en: "Na Wae",
            post_code: "80260",
          },
          {
            subdistrict_th: "ไม้เรียง",
            subdistrict_en: "Mai Riang",
            post_code: "80260",
          },
          {
            subdistrict_th: "กะเปียด",
            subdistrict_en: "Kapiat",
            post_code: "80260",
          },
          {
            subdistrict_th: "นากะชะ",
            subdistrict_en: "Na Kacha",
            post_code: "80260",
          },
          {
            subdistrict_th: "ห้วยปริก",
            subdistrict_en: "Huai Prik",
            post_code: "80260",
          },
          {
            subdistrict_th: "ไสหร้า",
            subdistrict_en: "Sai Ra",
            post_code: "80150",
          },
          {
            subdistrict_th: "นาเขลียง",
            subdistrict_en: "Na Khliang",
            post_code: "80260",
          },
          {
            subdistrict_th: "จันดี",
            subdistrict_en: "Chan Di",
            post_code: "80250",
          },
        ],
      },
      {
        district_th: "อำเภอพิปูน",
        district_en: "Amphoe Phipun",
        children: [
          {
            subdistrict_th: "พิปูน",
            subdistrict_en: "Phipun",
            post_code: "80270",
          },
          {
            subdistrict_th: "กะทูน",
            subdistrict_en: "Kathun",
            post_code: "80270",
          },
          {
            subdistrict_th: "เขาพระ",
            subdistrict_en: "Khao Phra",
            post_code: "80270",
          },
          {
            subdistrict_th: "ยางค้อม",
            subdistrict_en: "Yang Khom",
            post_code: "80270",
          },
          {
            subdistrict_th: "ควนกลาง",
            subdistrict_en: "Khuan Klang",
            post_code: "80270",
          },
        ],
      },
      {
        district_th: "อำเภอเชียรใหญ่",
        district_en: "Amphoe Chian Yai",
        children: [
          {
            subdistrict_th: "เชียรใหญ่",
            subdistrict_en: "Chian Yai",
            post_code: "80190",
          },
          {
            subdistrict_th: "ท่าขนาน",
            subdistrict_en: "Tha Khanan",
            post_code: "80190",
          },
          {
            subdistrict_th: "บ้านกลาง",
            subdistrict_en: "Ban Klang",
            post_code: "80190",
          },
          {
            subdistrict_th: "บ้านเนิน",
            subdistrict_en: "Ban Noen",
            post_code: "80190",
          },
          {
            subdistrict_th: "ไสหมาก",
            subdistrict_en: "Sai Mak",
            post_code: "80190",
          },
          {
            subdistrict_th: "ท้องลำเจียก",
            subdistrict_en: "Thong Lamchiak",
            post_code: "80190",
          },
          {
            subdistrict_th: "เสือหึง",
            subdistrict_en: "Suea Hueng",
            post_code: "80190",
          },
          {
            subdistrict_th: "การะเกด",
            subdistrict_en: "Karaket",
            post_code: "80190",
          },
          {
            subdistrict_th: "เขาพระบาท",
            subdistrict_en: "Khao Phra Bat",
            post_code: "80190",
          },
          {
            subdistrict_th: "แม่เจ้าอยู่หัว",
            subdistrict_en: "Mae chao Yu Hua",
            post_code: "80190",
          },
        ],
      },
      {
        district_th: "อำเภอชะอวด",
        district_en: "Amphoe Cha-uat",
        children: [
          {
            subdistrict_th: "ชะอวด",
            subdistrict_en: "Cha-uat",
            post_code: "80180",
          },
          {
            subdistrict_th: "ท่าเสม็ด",
            subdistrict_en: "Tha Samet",
            post_code: "80180",
          },
          {
            subdistrict_th: "ท่าประจะ",
            subdistrict_en: "Tha Pracha",
            post_code: "80180",
          },
          {
            subdistrict_th: "เคร็ง",
            subdistrict_en: "Khreng",
            post_code: "80180",
          },
          {
            subdistrict_th: "วังอ่าง",
            subdistrict_en: "Wang Ang",
            post_code: "80180",
          },
          {
            subdistrict_th: "บ้านตูล",
            subdistrict_en: "Ban Tun",
            post_code: "80180",
          },
          {
            subdistrict_th: "ขอนหาด",
            subdistrict_en: "Khon Hat",
            post_code: "80180",
          },
          {
            subdistrict_th: "เกาะขันธ์",
            subdistrict_en: "Ko Khan",
            post_code: "80180",
          },
          {
            subdistrict_th: "ควนหนองหงษ์",
            subdistrict_en: "Khuan Nong Hong",
            post_code: "80180",
          },
          {
            subdistrict_th: "เขาพระทอง",
            subdistrict_en: "Khao Phra Thong",
            post_code: "80180",
          },
          {
            subdistrict_th: "นางหลง",
            subdistrict_en: "Nong Long",
            post_code: "80180",
          },
        ],
      },
      {
        district_th: "อำเภอท่าศาลา",
        district_en: "Amphoe Tha Sala",
        children: [
          {
            subdistrict_th: "ท่าศาลา",
            subdistrict_en: "Tha Sala",
            post_code: "80160",
          },
          {
            subdistrict_th: "กลาย",
            subdistrict_en: "Klai",
            post_code: "80160",
          },
          {
            subdistrict_th: "ท่าขึ้น",
            subdistrict_en: "Tha Khuen",
            post_code: "80160",
          },
          {
            subdistrict_th: "หัวตะพาน",
            subdistrict_en: "Hua Taphan",
            post_code: "80160",
          },
          {
            subdistrict_th: "สระแก้ว",
            subdistrict_en: "Sa Kaeo",
            post_code: "80160",
          },
          {
            subdistrict_th: "โมคลาน",
            subdistrict_en: "Mokkhalan",
            post_code: "80160",
          },
          {
            subdistrict_th: "ไทยบุรี",
            subdistrict_en: "Thai Buri",
            post_code: "80160",
          },
          {
            subdistrict_th: "ดอนตะโก",
            subdistrict_en: "Don Tako",
            post_code: "80160",
          },
          {
            subdistrict_th: "ตลิ่งชัน",
            subdistrict_en: "Taling Chan",
            post_code: "80160",
          },
          {
            subdistrict_th: "โพธิ์ทอง",
            subdistrict_en: "Pho Thong",
            post_code: "80160",
          },
        ],
      },
      {
        district_th: "อำเภอทุ่งสง",
        district_en: "Amphoe Thung Song",
        children: [
          {
            subdistrict_th: "ปากแพรก",
            subdistrict_en: "Pak Phraek",
            post_code: "80110",
          },
          {
            subdistrict_th: "ชะมาย",
            subdistrict_en: "Chamai",
            post_code: "80110",
          },
          {
            subdistrict_th: "หนองหงส์",
            subdistrict_en: "Nong Hong",
            post_code: "80110",
          },
          {
            subdistrict_th: "ควนกรด",
            subdistrict_en: "Khuan Krot",
            post_code: "80110",
          },
          {
            subdistrict_th: "นาไม้ไผ่",
            subdistrict_en: "Na Mai Phai",
            post_code: "80110",
          },
          {
            subdistrict_th: "นาหลวงเสน",
            subdistrict_en: "Na Luang Sen",
            post_code: "80110",
          },
          {
            subdistrict_th: "เขาโร",
            subdistrict_en: "Khao Ro",
            post_code: "80110",
          },
          {
            subdistrict_th: "กะปาง",
            subdistrict_en: "Kapang",
            post_code: "80310",
          },
          {
            subdistrict_th: "ที่วัง",
            subdistrict_en: "Thi Wang",
            post_code: "80110",
          },
          {
            subdistrict_th: "น้ำตก",
            subdistrict_en: "Nam Tok",
            post_code: "80110",
          },
          {
            subdistrict_th: "ถ้ำใหญ่",
            subdistrict_en: "Tham Yai",
            post_code: "80110",
          },
          {
            subdistrict_th: "นาโพธิ์",
            subdistrict_en: "Na Pho",
            post_code: "80110",
          },
          {
            subdistrict_th: "เขาขาว",
            subdistrict_en: "Khao Khao",
            post_code: "80110",
          },
        ],
      },
      {
        district_th: "อำเภอนาบอน",
        district_en: "Amphoe Na Bon",
        children: [
          {
            subdistrict_th: "นาบอน",
            subdistrict_en: "Na Bon",
            post_code: "80220",
          },
          {
            subdistrict_th: "ทุ่งสง",
            subdistrict_en: "Thung Song",
            post_code: "80220",
          },
          {
            subdistrict_th: "แก้วแสน",
            subdistrict_en: "Kaeo Saen",
            post_code: "80220",
          },
        ],
      },
      {
        district_th: "อำเภอทุ่งใหญ่",
        district_en: "Amphoe Thung Yai",
        children: [
          {
            subdistrict_th: "ท่ายาง",
            subdistrict_en: "Tha Yang",
            post_code: "80240",
          },
          {
            subdistrict_th: "ทุ่งสัง",
            subdistrict_en: "Thung Sang",
            post_code: "80240",
          },
          {
            subdistrict_th: "ทุ่งใหญ่",
            subdistrict_en: "Thung Yai",
            post_code: "80240",
          },
          {
            subdistrict_th: "กุแหระ",
            subdistrict_en: "Kurae",
            post_code: "80240",
          },
          {
            subdistrict_th: "ปริก",
            subdistrict_en: "Prik",
            post_code: "80240",
          },
          {
            subdistrict_th: "บางรูป",
            subdistrict_en: "Bang Rup",
            post_code: "80240",
          },
          {
            subdistrict_th: "กรุงหยัน",
            subdistrict_en: "Krung Yan",
            post_code: "80240",
          },
        ],
      },
      {
        district_th: "อำเภอปากพนัง",
        district_en: "Amphoe Pak Phanang",
        children: [
          {
            subdistrict_th: "ปากพนัง",
            subdistrict_en: "Pak Phanang",
            post_code: "80140",
          },
          {
            subdistrict_th: "คลองน้อย",
            subdistrict_en: "Khlong Noi",
            post_code: "80330",
          },
          {
            subdistrict_th: "ป่าระกำ",
            subdistrict_en: "Pa Rakam",
            post_code: "80140",
          },
          {
            subdistrict_th: "ชะเมา",
            subdistrict_en: "Chamao",
            post_code: "80330",
          },
          {
            subdistrict_th: "คลองกระบือ",
            subdistrict_en: "Khlong Krabue",
            post_code: "80140",
          },
          {
            subdistrict_th: "เกาะทวด",
            subdistrict_en: "Ko Thuat",
            post_code: "80330",
          },
          {
            subdistrict_th: "บ้านใหม่",
            subdistrict_en: "Ban Mai",
            post_code: "80140",
          },
          {
            subdistrict_th: "หูล่อง",
            subdistrict_en: "Hu Long",
            post_code: "80140",
          },
          {
            subdistrict_th: "แหลมตะลุมพุก",
            subdistrict_en: "Laem Talumphuk",
            post_code: "80140",
          },
          {
            subdistrict_th: "ปากพนังฝั่งตะวันตก",
            subdistrict_en: "Pak Phanang Fang Tawan Tok",
            post_code: "80140",
          },
          {
            subdistrict_th: "บางศาลา",
            subdistrict_en: "Bang Sala",
            post_code: "80140",
          },
          {
            subdistrict_th: "บางพระ",
            subdistrict_en: "Bang Phra",
            post_code: "80140",
          },
          {
            subdistrict_th: "บางตะพง",
            subdistrict_en: "Bang Taphong",
            post_code: "80140",
          },
          {
            subdistrict_th: "ปากพนังฝั่งตะวันออก",
            subdistrict_en: "Pak Phanang Fang Tawan Ok",
            post_code: "80140",
          },
          {
            subdistrict_th: "บ้านเพิง",
            subdistrict_en: "Ban Phoeng",
            post_code: "80140",
          },
          {
            subdistrict_th: "ท่าพยา",
            subdistrict_en: "Tha Phaya",
            post_code: "80140",
          },
          {
            subdistrict_th: "ปากแพรก",
            subdistrict_en: "Pak Phraek",
            post_code: "80140",
          },
          {
            subdistrict_th: "ขนาบนาก",
            subdistrict_en: "Khanap Nak",
            post_code: "80140",
          },
        ],
      },
      {
        district_th: "อำเภอร่อนพิบูลย์",
        district_en: "Amphoe Ron Phibun",
        children: [
          {
            subdistrict_th: "ร่อนพิบูลย์",
            subdistrict_en: "Ron Phibun",
            post_code: "80130",
          },
          {
            subdistrict_th: "หินตก",
            subdistrict_en: "Hin Tok",
            post_code: "80350",
          },
          {
            subdistrict_th: "เสาธง",
            subdistrict_en: "Sao Thong",
            post_code: "80350",
          },
          {
            subdistrict_th: "ควนเกย",
            subdistrict_en: "Khuan Koei",
            post_code: "80130",
          },
          {
            subdistrict_th: "ควนพัง",
            subdistrict_en: "Khuan Phang",
            post_code: "80130",
          },
          {
            subdistrict_th: "ควนชุม",
            subdistrict_en: "Khuan Chum",
            post_code: "80130",
          },
        ],
      },
      {
        district_th: "อำเภอสิชล",
        district_en: "Amphoe Sichon",
        children: [
          {
            subdistrict_th: "สิชล",
            subdistrict_en: "Sichon",
            post_code: "80120",
          },
          {
            subdistrict_th: "ทุ่งปรัง",
            subdistrict_en: "Thung Prang",
            post_code: "80120",
          },
          {
            subdistrict_th: "ฉลอง",
            subdistrict_en: "Chalong",
            post_code: "80120",
          },
          {
            subdistrict_th: "เสาเภา",
            subdistrict_en: "Sao Phao",
            post_code: "80340",
          },
          {
            subdistrict_th: "เปลี่ยน",
            subdistrict_en: "Plian",
            post_code: "80120",
          },
          {
            subdistrict_th: "สี่ขีด",
            subdistrict_en: "Si Khit",
            post_code: "80120",
          },
          {
            subdistrict_th: "เทพราช",
            subdistrict_en: "Theppharat",
            post_code: "80340",
          },
          {
            subdistrict_th: "เขาน้อย",
            subdistrict_en: "Khao Noi",
            post_code: "80120",
          },
          {
            subdistrict_th: "ทุ่งใส",
            subdistrict_en: "Thung Sai",
            post_code: "80120",
          },
        ],
      },
      {
        district_th: "อำเภอขนอม",
        district_en: "Amphoe Khanom",
        children: [
          {
            subdistrict_th: "ขนอม",
            subdistrict_en: "Khanom",
            post_code: "80210",
          },
          {
            subdistrict_th: "ควนทอง",
            subdistrict_en: "Khuan Thong",
            post_code: "80210",
          },
          {
            subdistrict_th: "ท้องเนียน",
            subdistrict_en: "Thong Nian",
            post_code: "80210",
          },
        ],
      },
      {
        district_th: "อำเภอหัวไทร",
        district_en: "Amphoe Hua Sai",
        children: [
          {
            subdistrict_th: "หัวไทร",
            subdistrict_en: "Hua Sai",
            post_code: "80170",
          },
          {
            subdistrict_th: "หน้าสตน",
            subdistrict_en: "Na Saton",
            post_code: "80170",
          },
          {
            subdistrict_th: "ทรายขาว",
            subdistrict_en: "Sai Khao",
            post_code: "80170",
          },
          {
            subdistrict_th: "แหลม",
            subdistrict_en: "Laem",
            post_code: "80170",
          },
          {
            subdistrict_th: "เขาพังไกร",
            subdistrict_en: "Khao Phang Krai",
            post_code: "80170",
          },
          {
            subdistrict_th: "บ้านราม",
            subdistrict_en: "Ban Ram",
            post_code: "80170",
          },
          {
            subdistrict_th: "บางนบ",
            subdistrict_en: "Bang Nop",
            post_code: "80170",
          },
          {
            subdistrict_th: "ท่าซอม",
            subdistrict_en: "Tha Som",
            post_code: "80170",
          },
          {
            subdistrict_th: "ควนชะลิก",
            subdistrict_en: "Khuan Chalik",
            post_code: "80170",
          },
          {
            subdistrict_th: "รามแก้ว",
            subdistrict_en: "Ram Kaeo",
            post_code: "80170",
          },
          {
            subdistrict_th: "เกาะเพชร",
            subdistrict_en: "Ko Phet",
            post_code: "80170",
          },
        ],
      },
      {
        district_th: "อำเภอบางขัน",
        district_en: "Amphoe Bang Khan",
        children: [
          {
            subdistrict_th: "บางขัน",
            subdistrict_en: "Bang Khan",
            post_code: "80360",
          },
          {
            subdistrict_th: "บ้านลำนาว",
            subdistrict_en: "Ban Lam Nao",
            post_code: "80360",
          },
          {
            subdistrict_th: "วังหิน",
            subdistrict_en: "Wang Hin",
            post_code: "80360",
          },
          {
            subdistrict_th: "บ้านนิคม",
            subdistrict_en: "Ban Nikhom",
            post_code: "80360",
          },
        ],
      },
      {
        district_th: "อำเภอถ้ำพรรณรา",
        district_en: "Amphoe Tham Phannara",
        children: [
          {
            subdistrict_th: "ถ้ำพรรณรา",
            subdistrict_en: "Tham Phannara",
            post_code: "80260",
          },
          {
            subdistrict_th: "คลองเส",
            subdistrict_en: "Khlong Se",
            post_code: "80260",
          },
          {
            subdistrict_th: "ดุสิต",
            subdistrict_en: "Dusit",
            post_code: "80260",
          },
        ],
      },
      {
        district_th: "อำเภอจุฬาภรณ์",
        district_en: "Amphoe Chulabhorn",
        children: [
          {
            subdistrict_th: "บ้านควนมุด",
            subdistrict_en: "Ban Khuan Mut",
            post_code: "80180",
          },
          {
            subdistrict_th: "บ้านชะอวด",
            subdistrict_en: "Ban Cha-uat",
            post_code: "80180",
          },
          {
            subdistrict_th: "ควนหนองคว้า",
            subdistrict_en: "Khuan Nong Khwa",
            post_code: "80130",
          },
          {
            subdistrict_th: "ทุ่งโพธิ์",
            subdistrict_en: "Thung Pho",
            post_code: "80130",
          },
          {
            subdistrict_th: "นาหมอบุญ",
            subdistrict_en: "Na Mo Bun",
            post_code: "80130",
          },
          {
            subdistrict_th: "สามตำบล",
            subdistrict_en: "Sam Tambon",
            post_code: "80130",
          },
        ],
      },
      {
        district_th: "อำเภอพระพรหม",
        district_en: "Amphoe Phra Phrom",
        children: [
          {
            subdistrict_th: "นาพรุ",
            subdistrict_en: "Na Phru",
            post_code: "80000",
          },
          {
            subdistrict_th: "นาสาร",
            subdistrict_en: "Na San",
            post_code: "80000",
          },
          {
            subdistrict_th: "ท้ายสำเภา",
            subdistrict_en: "Thai Samphao",
            post_code: "80000",
          },
          {
            subdistrict_th: "ช้างซ้าย",
            subdistrict_en: "Chang Sai",
            post_code: "80000",
          },
        ],
      },
      {
        district_th: "อำเภอนบพิตำ",
        district_en: "Amphoe Nopphitam",
        children: [
          {
            subdistrict_th: "นบพิตำ",
            subdistrict_en: "Nopphitam",
            post_code: "80160",
          },
          {
            subdistrict_th: "กรุงชิง",
            subdistrict_en: "Krung Ching",
            post_code: "80160",
          },
          {
            subdistrict_th: "กะหรอ",
            subdistrict_en: "Kalo",
            post_code: "80160",
          },
          {
            subdistrict_th: "นาเหรง",
            subdistrict_en: "Na Reng",
            post_code: "80160",
          },
        ],
      },
      {
        district_th: "อำเภอช้างกลาง",
        district_en: "Amphoe Chang Klang",
        children: [
          {
            subdistrict_th: "ช้างกลาง",
            subdistrict_en: "Chang Klang",
            post_code: "80250",
          },
          {
            subdistrict_th: "หลักช้าง",
            subdistrict_en: "Lak Chang",
            post_code: "80250",
          },
          {
            subdistrict_th: "สวนขัน",
            subdistrict_en: "Suan Khan",
            post_code: "80250",
          },
        ],
      },
      {
        district_th: "อำเภอเฉลิมพระเกียรติ",
        district_en: "Amphoe Chaloem Phra Kiat",
        children: [
          {
            subdistrict_th: "เขาดินพัฒนา",
            subdistrict_en: "Khao Din Phatthana",
            post_code: "18000",
          },
          {
            subdistrict_th: "บ้านแก้ง",
            subdistrict_en: "Ban Kaeng",
            post_code: "18000",
          },
          {
            subdistrict_th: "ผึ้งรวง",
            subdistrict_en: "Phueng Ruang",
            post_code: "18000",
          },
          {
            subdistrict_th: "พุแค",
            subdistrict_en: "Phu Khae",
            post_code: "18240",
          },
          {
            subdistrict_th: "ห้วยบง",
            subdistrict_en: "Huai Bong",
            post_code: "18000",
          },
          {
            subdistrict_th: "หน้าพระลาน",
            subdistrict_en: "Na Phra Lan",
            post_code: "18240",
          },
          {
            subdistrict_th: "ช้างทอง",
            subdistrict_en: "Chang Thong",
            post_code: "30230",
          },
          {
            subdistrict_th: "ท่าช้าง",
            subdistrict_en: "Tha Chang",
            post_code: "30230",
          },
          {
            subdistrict_th: "พระพุทธ",
            subdistrict_en: "Phra Phut",
            post_code: "30230",
          },
          {
            subdistrict_th: "หนองงูเหลือม",
            subdistrict_en: "Nong Ngu Lueam",
            post_code: "30000",
          },
          {
            subdistrict_th: "หนองยาง",
            subdistrict_en: "Nong Yang",
            post_code: "30230",
          },
          {
            subdistrict_th: "เจริญสุข",
            subdistrict_en: "Charoen Suk",
            post_code: "31110",
          },
          {
            subdistrict_th: "ตาเป๊ก",
            subdistrict_en: "Ta Pek",
            post_code: "31110",
          },
          {
            subdistrict_th: "อีสานเขต",
            subdistrict_en: "Isan Khet",
            post_code: "31110",
          },
          {
            subdistrict_th: "ถาวร",
            subdistrict_en: "Thawon",
            post_code: "31170",
          },
          {
            subdistrict_th: "ยายแย้มวัฒนา",
            subdistrict_en: "Yai Yaem Watthana",
            post_code: "31170",
          },
          {
            subdistrict_th: "ห้วยโก๋น",
            subdistrict_en: "Huai Kon",
            post_code: "55130",
          },
          {
            subdistrict_th: "ขุนน่าน",
            subdistrict_en: "Khun Nan",
            post_code: "55220",
          },
          {
            subdistrict_th: "เชียรเขา",
            subdistrict_en: "Chian Khao",
            post_code: "80190",
          },
          {
            subdistrict_th: "ดอนตรอ",
            subdistrict_en: "Don Tro",
            post_code: "80290",
          },
          {
            subdistrict_th: "สวนหลวง",
            subdistrict_en: "Suan Luang",
            post_code: "80190",
          },
          {
            subdistrict_th: "ทางพูน",
            subdistrict_en: "Thang Phun",
            post_code: "80290",
          },
        ],
      },
    ],
  },
  {
    province_th: "กระบี่",
    province_en: "Krabi",
    children: [
      {
        district_th: "อำเภอเมืองกระบี่",
        district_en: "Amphoe Mueang Krabi",
        children: [
          {
            subdistrict_th: "ปากน้ำ",
            subdistrict_en: "Pak Nam",
            post_code: "81000",
          },
          {
            subdistrict_th: "กระบี่ใหญ่",
            subdistrict_en: "Krabi Yai",
            post_code: "81000",
          },
          {
            subdistrict_th: "กระบี่น้อย",
            subdistrict_en: "Krabi Noi",
            post_code: "81000",
          },
          {
            subdistrict_th: "เขาคราม",
            subdistrict_en: "Khao Khram",
            post_code: "81000",
          },
          {
            subdistrict_th: "เขาทอง",
            subdistrict_en: "Khao Thong",
            post_code: "81000",
          },
          {
            subdistrict_th: "ทับปริก",
            subdistrict_en: "Thap Prik",
            post_code: "81000",
          },
          {
            subdistrict_th: "ไสไทย",
            subdistrict_en: "Sai Thai",
            post_code: "81000",
          },
          {
            subdistrict_th: "อ่าวนาง",
            subdistrict_en: "Ao Nang",
            post_code: "81000",
          },
          {
            subdistrict_th: "หนองทะเล",
            subdistrict_en: "Nong Thale",
            post_code: "81000",
          },
          {
            subdistrict_th: "คลองประสงค์",
            subdistrict_en: "Khlong Prasong",
            post_code: "81000",
          },
        ],
      },
      {
        district_th: "อำเภอเขาพนม",
        district_en: "Amphoe Khao Phanom",
        children: [
          {
            subdistrict_th: "เขาพนม",
            subdistrict_en: "Khao Phanom",
            post_code: "81140",
          },
          {
            subdistrict_th: "เขาดิน",
            subdistrict_en: "Khao Din",
            post_code: "81140",
          },
          {
            subdistrict_th: "สินปุน",
            subdistrict_en: "Sin Pun",
            post_code: "80240",
          },
          {
            subdistrict_th: "พรุเตียว",
            subdistrict_en: "Phru Tiao",
            post_code: "81140",
          },
          {
            subdistrict_th: "หน้าเขา",
            subdistrict_en: "Na Khao",
            post_code: "81140",
          },
          {
            subdistrict_th: "โคกหาร",
            subdistrict_en: "Khok Han",
            post_code: "80240",
          },
        ],
      },
      {
        district_th: "อำเภอเกาะลันตา",
        district_en: "Amphoe Ko Lanta",
        children: [
          {
            subdistrict_th: "เกาะลันตาใหญ่",
            subdistrict_en: "Ko Lanta Yai",
            post_code: "81150",
          },
          {
            subdistrict_th: "เกาะลันตาน้อย",
            subdistrict_en: "Ko Lanta Noi",
            post_code: "81150",
          },
          {
            subdistrict_th: "เกาะกลาง",
            subdistrict_en: "Ko Klang",
            post_code: "81120",
          },
          {
            subdistrict_th: "คลองยาง",
            subdistrict_en: "Khlong Yang",
            post_code: "81120",
          },
          {
            subdistrict_th: "ศาลาด่าน",
            subdistrict_en: "Sala Dan",
            post_code: "81150",
          },
        ],
      },
      {
        district_th: "อำเภอคลองท่อม",
        district_en: "Amphoe Khlong Thom",
        children: [
          {
            subdistrict_th: "คลองท่อมใต้",
            subdistrict_en: "Khlong Thom Tai",
            post_code: "81120",
          },
          {
            subdistrict_th: "คลองท่อมเหนือ",
            subdistrict_en: "Khlong Thom Nuea",
            post_code: "81120",
          },
          {
            subdistrict_th: "คลองพน",
            subdistrict_en: "Khlong Phon",
            post_code: "81170",
          },
          {
            subdistrict_th: "ทรายขาว",
            subdistrict_en: "Sai Khao",
            post_code: "81170",
          },
          {
            subdistrict_th: "ห้วยน้ำขาว",
            subdistrict_en: "Huai Nam Khao",
            post_code: "81120",
          },
          {
            subdistrict_th: "พรุดินนา",
            subdistrict_en: "Phru Din Na",
            post_code: "81120",
          },
          {
            subdistrict_th: "เพหลา",
            subdistrict_en: "Phela",
            post_code: "81120",
          },
        ],
      },
      {
        district_th: "อำเภออ่าวลึก",
        district_en: "Amphoe Ao Luek",
        children: [
          {
            subdistrict_th: "อ่าวลึกใต้",
            subdistrict_en: "Ao Luek Tai",
            post_code: "81110",
          },
          {
            subdistrict_th: "แหลมสัก",
            subdistrict_en: "Laem Sak",
            post_code: "81110",
          },
          {
            subdistrict_th: "นาเหนือ",
            subdistrict_en: "Na Nuea",
            post_code: "81110",
          },
          {
            subdistrict_th: "คลองหิน",
            subdistrict_en: "Khlong Hin",
            post_code: "81110",
          },
          {
            subdistrict_th: "อ่าวลึกน้อย",
            subdistrict_en: "Ao Luek Noi",
            post_code: "81110",
          },
          {
            subdistrict_th: "อ่าวลึกเหนือ",
            subdistrict_en: "Ao Luek Nuea",
            post_code: "81110",
          },
          {
            subdistrict_th: "เขาใหญ่",
            subdistrict_en: "Khao Yai",
            post_code: "81110",
          },
          {
            subdistrict_th: "คลองยา",
            subdistrict_en: "Khlong Ya",
            post_code: "81110",
          },
          {
            subdistrict_th: "บ้านกลาง",
            subdistrict_en: "Ban Klang",
            post_code: "81110",
          },
        ],
      },
      {
        district_th: "อำเภอปลายพระยา",
        district_en: "Amphoe Plai Phraya",
        children: [
          {
            subdistrict_th: "ปลายพระยา",
            subdistrict_en: "Plai Phraya",
            post_code: "81160",
          },
          {
            subdistrict_th: "เขาเขน",
            subdistrict_en: "Khao Khen",
            post_code: "81160",
          },
          {
            subdistrict_th: "เขาต่อ",
            subdistrict_en: "Khao To",
            post_code: "81160",
          },
          {
            subdistrict_th: "คีรีวง",
            subdistrict_en: "Khiri Wong",
            post_code: "81160",
          },
        ],
      },
      {
        district_th: "อำเภอลำทับ",
        district_en: "Amphoe Lam Thap",
        children: [
          {
            subdistrict_th: "ลำทับ",
            subdistrict_en: "Lam Thap",
            post_code: "81190",
          },
          {
            subdistrict_th: "ดินอุดม",
            subdistrict_en: "Din Udom",
            post_code: "81190",
          },
          {
            subdistrict_th: "ทุ่งไทรทอง",
            subdistrict_en: "Thung Sai Thong",
            post_code: "81190",
          },
          {
            subdistrict_th: "ดินแดง",
            subdistrict_en: "Din Daeng",
            post_code: "81190",
          },
        ],
      },
      {
        district_th: "อำเภอเหนือคลอง",
        district_en: "Amphoe Nuea Khlong",
        children: [
          {
            subdistrict_th: "เหนือคลอง",
            subdistrict_en: "Nuea Khlong",
            post_code: "81130",
          },
          {
            subdistrict_th: "เกาะศรีบอยา",
            subdistrict_en: "Ko Siboya",
            post_code: "81130",
          },
          {
            subdistrict_th: "คลองขนาน",
            subdistrict_en: "Khlong Khanan",
            post_code: "81130",
          },
          {
            subdistrict_th: "คลองเขม้า",
            subdistrict_en: "Khlong Khamao",
            post_code: "81130",
          },
          {
            subdistrict_th: "โคกยาง",
            subdistrict_en: "Khok Yang",
            post_code: "81130",
          },
          {
            subdistrict_th: "ตลิ่งชัน",
            subdistrict_en: "Taling Chan",
            post_code: "81130",
          },
          {
            subdistrict_th: "ปกาสัย",
            subdistrict_en: "Pakasai",
            post_code: "81130",
          },
          {
            subdistrict_th: "ห้วยยูง",
            subdistrict_en: "Huai Yung",
            post_code: "81130",
          },
        ],
      },
    ],
  },
  {
    province_th: "พังงา",
    province_en: "Phangnga",
    children: [
      {
        district_th: "อำเภอเมืองพังงา",
        district_en: "Amphoe Mueang Phang-nga",
        children: [
          {
            subdistrict_th: "ท้ายช้าง",
            subdistrict_en: "Thai Chang",
            post_code: "82000",
          },
          {
            subdistrict_th: "นบปริง",
            subdistrict_en: "Nop Pring",
            post_code: "82000",
          },
          {
            subdistrict_th: "ถ้ำน้ำผุด",
            subdistrict_en: "Tham Nam Phut",
            post_code: "82000",
          },
          {
            subdistrict_th: "บางเตย",
            subdistrict_en: "Bang Toei",
            post_code: "82000",
          },
          {
            subdistrict_th: "ตากแดด",
            subdistrict_en: "Tak Daet",
            post_code: "82000",
          },
          {
            subdistrict_th: "สองแพรก",
            subdistrict_en: "Song Phraek",
            post_code: "82000",
          },
          {
            subdistrict_th: "ทุ่งคาโงก",
            subdistrict_en: "Thung Kha Ngok",
            post_code: "82000",
          },
          {
            subdistrict_th: "เกาะปันหยี",
            subdistrict_en: "Ko Panyi",
            post_code: "82000",
          },
          {
            subdistrict_th: "ป่ากอ",
            subdistrict_en: "Pa Ko",
            post_code: "82000",
          },
        ],
      },
      {
        district_th: "อำเภอเกาะยาว",
        district_en: "Amphoe Ko Yao",
        children: [
          {
            subdistrict_th: "เกาะยาวน้อย",
            subdistrict_en: "Ko Yao Noi",
            post_code: "82160",
          },
          {
            subdistrict_th: "เกาะยาวใหญ่",
            subdistrict_en: "Ko yao Yai",
            post_code: "82160",
          },
          {
            subdistrict_th: "พรุใน",
            subdistrict_en: "Phru Nai",
            post_code: "83000",
          },
        ],
      },
      {
        district_th: "อำเภอกะปง",
        district_en: "Amphoe Kapong",
        children: [
          {
            subdistrict_th: "กะปง",
            subdistrict_en: "Kapong",
            post_code: "82170",
          },
          {
            subdistrict_th: "ท่านา",
            subdistrict_en: "Tha Na",
            post_code: "82170",
          },
          {
            subdistrict_th: "เหมาะ",
            subdistrict_en: "Mo",
            post_code: "82170",
          },
          {
            subdistrict_th: "เหล",
            subdistrict_en: "Le",
            post_code: "82170",
          },
          {
            subdistrict_th: "รมณีย์",
            subdistrict_en: "Rommani",
            post_code: "82170",
          },
        ],
      },
      {
        district_th: "อำเภอตะกั่วทุ่ง",
        district_en: "Amphoe Takua Thung",
        children: [
          {
            subdistrict_th: "ถ้ำ",
            subdistrict_en: "Tham",
            post_code: "82130",
          },
          {
            subdistrict_th: "กระโสม",
            subdistrict_en: "Krasom",
            post_code: "82130",
          },
          {
            subdistrict_th: "กะไหล",
            subdistrict_en: "Kalai",
            post_code: "82130",
          },
          {
            subdistrict_th: "ท่าอยู่",
            subdistrict_en: "Tha Yu",
            post_code: "82130",
          },
          {
            subdistrict_th: "หล่อยูง",
            subdistrict_en: "Lo Yung",
            post_code: "82140",
          },
          {
            subdistrict_th: "โคกกลอย",
            subdistrict_en: "Khok Kloi",
            post_code: "82140",
          },
          {
            subdistrict_th: "คลองเคียน",
            subdistrict_en: "Khlong Khian",
            post_code: "82130",
          },
        ],
      },
      {
        district_th: "อำเภอตะกั่วป่า",
        district_en: "Amphoe Takua Pa",
        children: [
          {
            subdistrict_th: "ตะกั่วป่า",
            subdistrict_en: "Takua Pa",
            post_code: "82110",
          },
          {
            subdistrict_th: "บางนายสี",
            subdistrict_en: "Bang Nai Si",
            post_code: "82110",
          },
          {
            subdistrict_th: "บางไทร",
            subdistrict_en: "Bang Sai",
            post_code: "82110",
          },
          {
            subdistrict_th: "บางม่วง",
            subdistrict_en: "Bang Muang",
            post_code: "82190",
          },
          {
            subdistrict_th: "ตำตัว",
            subdistrict_en: "Tam Tua",
            post_code: "82110",
          },
          {
            subdistrict_th: "โคกเคียน",
            subdistrict_en: "Khok Khian",
            post_code: "82110",
          },
          {
            subdistrict_th: "คึกคัก",
            subdistrict_en: "Khuekkhak",
            post_code: "82190",
          },
          {
            subdistrict_th: "เกาะคอเขา",
            subdistrict_en: "Ko Kho Khao",
            post_code: "82190",
          },
        ],
      },
      {
        district_th: "อำเภอคุระบุรี",
        district_en: "Amphoe Khura Buri",
        children: [
          {
            subdistrict_th: "คุระ",
            subdistrict_en: "Khura",
            post_code: "82150",
          },
          {
            subdistrict_th: "บางวัน",
            subdistrict_en: "Bang Wan",
            post_code: "82150",
          },
          {
            subdistrict_th: "เกาะพระทอง",
            subdistrict_en: "Ko Phra Thong",
            post_code: "82150",
          },
          {
            subdistrict_th: "แม่นางขาว",
            subdistrict_en: "Mae Nang Khao",
            post_code: "82150",
          },
        ],
      },
      {
        district_th: "อำเภอทับปุด",
        district_en: "Amphoe Thap Put",
        children: [
          {
            subdistrict_th: "ทับปุด",
            subdistrict_en: "Thap Put",
            post_code: "82180",
          },
          {
            subdistrict_th: "มะรุ่ย",
            subdistrict_en: "Marui",
            post_code: "82180",
          },
          {
            subdistrict_th: "บ่อแสน",
            subdistrict_en: "Bo Saen",
            post_code: "82180",
          },
          {
            subdistrict_th: "ถ้ำทองหลาง",
            subdistrict_en: "Tham Thonglang",
            post_code: "82180",
          },
          {
            subdistrict_th: "โคกเจริญ",
            subdistrict_en: "Khok Charoen",
            post_code: "82180",
          },
          {
            subdistrict_th: "บางเหรียง",
            subdistrict_en: "Bang Riang",
            post_code: "82180",
          },
        ],
      },
      {
        district_th: "อำเภอท้ายเหมือง",
        district_en: "Amphoe Thai Mueang",
        children: [
          {
            subdistrict_th: "ท้ายเหมือง",
            subdistrict_en: "Thai Mueang",
            post_code: "82120",
          },
          {
            subdistrict_th: "นาเตย",
            subdistrict_en: "Na Toei",
            post_code: "82120",
          },
          {
            subdistrict_th: "บางทอง",
            subdistrict_en: "Bang Thong",
            post_code: "82120",
          },
          {
            subdistrict_th: "ทุ่งมะพร้าว",
            subdistrict_en: "Thung Maphrao",
            post_code: "82120",
          },
          {
            subdistrict_th: "ลำภี",
            subdistrict_en: "Lam Phi",
            post_code: "82120",
          },
          {
            subdistrict_th: "ลำแก่น",
            subdistrict_en: "Lam Kaen",
            post_code: "82210",
          },
        ],
      },
    ],
  },
  {
    province_th: "ภูเก็ต",
    province_en: "Phuket",
    children: [
      {
        district_th: "อำเภอเมืองภูเก็ต",
        district_en: "Amphoe Mueang Phuket",
        children: [
          {
            subdistrict_th: "ตลาดใหญ่",
            subdistrict_en: "Talat Yai",
            post_code: "83000",
          },
          {
            subdistrict_th: "ตลาดเหนือ",
            subdistrict_en: "Talat Nuea",
            post_code: "83000",
          },
          {
            subdistrict_th: "เกาะแก้ว",
            subdistrict_en: "Ko Kaeo",
            post_code: "83000",
          },
          {
            subdistrict_th: "รัษฎา",
            subdistrict_en: "Ratsada",
            post_code: "83000",
          },
          {
            subdistrict_th: "วิชิต",
            subdistrict_en: "Wichit",
            post_code: "83000",
          },
          {
            subdistrict_th: "ฉลอง",
            subdistrict_en: "Chalong",
            post_code: "83130",
          },
          {
            subdistrict_th: "ราไวย์",
            subdistrict_en: "Rawai",
            post_code: "83130",
          },
          {
            subdistrict_th: "กะรน",
            subdistrict_en: "Karon",
            post_code: "83100",
          },
        ],
      },
      {
        district_th: "อำเภอกะทู้",
        district_en: "Amphoe Kathu",
        children: [
          {
            subdistrict_th: "กะทู้",
            subdistrict_en: "Kathu",
            post_code: "83120",
          },
          {
            subdistrict_th: "ป่าตอง",
            subdistrict_en: "Pa Tong",
            post_code: "83150",
          },
          {
            subdistrict_th: "กมลา",
            subdistrict_en: "Kamala",
            post_code: "83150",
          },
        ],
      },
      {
        district_th: "อำเภอถลาง",
        district_en: "Amphoe Thalang",
        children: [
          {
            subdistrict_th: "เทพกระษัตรี",
            subdistrict_en: "Thep Krasatti",
            post_code: "83110",
          },
          {
            subdistrict_th: "ศรีสุนทร",
            subdistrict_en: "Si Sunthon",
            post_code: "83110",
          },
          {
            subdistrict_th: "เชิงทะเล",
            subdistrict_en: "Choeng Thale",
            post_code: "83110",
          },
          {
            subdistrict_th: "ป่าคลอก",
            subdistrict_en: "Pa Khlok",
            post_code: "83110",
          },
          {
            subdistrict_th: "ไม้ขาว",
            subdistrict_en: "Mai Khao",
            post_code: "83110",
          },
          {
            subdistrict_th: "สาคู",
            subdistrict_en: "Sakhu",
            post_code: "83110",
          },
        ],
      },
    ],
  },
  {
    province_th: "สุราษฎร์ธานี",
    province_en: "Surat Thani",
    children: [
      {
        district_th: "อำเภอเมืองสุราษฎร์ธานี",
        district_en: "Amphoe Mueang Surat Thani",
        children: [
          {
            subdistrict_th: "ตลาด",
            subdistrict_en: "Talat",
            post_code: "84000",
          },
          {
            subdistrict_th: "มะขามเตี้ย",
            subdistrict_en: "Makham Tia",
            post_code: "84000",
          },
          {
            subdistrict_th: "วัดประดู่",
            subdistrict_en: "Wat Pradu",
            post_code: "84000",
          },
          {
            subdistrict_th: "ขุนทะเล",
            subdistrict_en: "Khun Thale",
            post_code: "84100",
          },
          {
            subdistrict_th: "บางใบไม้",
            subdistrict_en: "Bang Bai Mai",
            post_code: "84000",
          },
          {
            subdistrict_th: "บางชนะ",
            subdistrict_en: "Bang Chana",
            post_code: "84000",
          },
          {
            subdistrict_th: "คลองน้อย",
            subdistrict_en: "Khlong Noi",
            post_code: "84000",
          },
          {
            subdistrict_th: "บางไทร",
            subdistrict_en: "Bang Sai",
            post_code: "84000",
          },
          {
            subdistrict_th: "บางโพธิ์",
            subdistrict_en: "Bang Pho",
            post_code: "84000",
          },
          {
            subdistrict_th: "บางกุ้ง",
            subdistrict_en: "Bang Kung",
            post_code: "84000",
          },
          {
            subdistrict_th: "คลองฉนาก",
            subdistrict_en: "Khlong Chanak",
            post_code: "84000",
          },
        ],
      },
      {
        district_th: "อำเภอกาญจนดิษฐ์",
        district_en: "Amphoe Kanchanadit",
        children: [
          {
            subdistrict_th: "ท่าทองใหม่",
            subdistrict_en: "Tha Thong Mai",
            post_code: "84290",
          },
          {
            subdistrict_th: "ท่าทอง",
            subdistrict_en: "Tha Thong",
            post_code: "84160",
          },
          {
            subdistrict_th: "กะแดะ",
            subdistrict_en: "Kadae",
            post_code: "84160",
          },
          {
            subdistrict_th: "ทุ่งกง",
            subdistrict_en: "Thung Kong",
            post_code: "84290",
          },
          {
            subdistrict_th: "กรูด",
            subdistrict_en: "Krut",
            post_code: "84160",
          },
          {
            subdistrict_th: "ช้างซ้าย",
            subdistrict_en: "Chang Sai",
            post_code: "84160",
          },
          {
            subdistrict_th: "พลายวาส",
            subdistrict_en: "Phlai Wat",
            post_code: "84160",
          },
          {
            subdistrict_th: "ป่าร่อน",
            subdistrict_en: "Pa Ron",
            post_code: "84160",
          },
          {
            subdistrict_th: "ตะเคียนทอง",
            subdistrict_en: "Takhian Thong",
            post_code: "84160",
          },
          {
            subdistrict_th: "ช้างขวา",
            subdistrict_en: "Chang Khwa",
            post_code: "84160",
          },
          {
            subdistrict_th: "ท่าอุแท",
            subdistrict_en: "Tha U-thae",
            post_code: "84160",
          },
          {
            subdistrict_th: "ทุ่งรัง",
            subdistrict_en: "Thung Rang",
            post_code: "84290",
          },
          {
            subdistrict_th: "คลองสระ",
            subdistrict_en: "Khlong Sa",
            post_code: "84160",
          },
        ],
      },
      {
        district_th: "อำเภอดอนสัก",
        district_en: "Amphoe Don Sak",
        children: [
          {
            subdistrict_th: "ดอนสัก",
            subdistrict_en: "Don Sak",
            post_code: "84220",
          },
          {
            subdistrict_th: "ชลคราม",
            subdistrict_en: "Chonlakhram",
            post_code: "84160",
          },
          {
            subdistrict_th: "ไชยคราม",
            subdistrict_en: "Chaiyakhram",
            post_code: "84220",
          },
          {
            subdistrict_th: "ปากแพรก",
            subdistrict_en: "Pak Phraek",
            post_code: "84340",
          },
        ],
      },
      {
        district_th: "อำเภอเกาะสมุย",
        district_en: "Amphoe Ko Samui",
        children: [
          {
            subdistrict_th: "อ่างทอง",
            subdistrict_en: "Ang Thong",
            post_code: "84140",
          },
          {
            subdistrict_th: "ลิปะน้อย",
            subdistrict_en: "Lipa Noi",
            post_code: "84140",
          },
          {
            subdistrict_th: "ตลิ่งงาม",
            subdistrict_en: "Taling Ngam",
            post_code: "84140",
          },
          {
            subdistrict_th: "หน้าเมือง",
            subdistrict_en: "Na Mueang",
            post_code: "84140",
          },
          {
            subdistrict_th: "มะเร็ต",
            subdistrict_en: "Maret",
            post_code: "84310",
          },
          {
            subdistrict_th: "บ่อผุด",
            subdistrict_en: "Bo Phut",
            post_code: "84320",
          },
          {
            subdistrict_th: "แม่น้ำ",
            subdistrict_en: "Mae Nam",
            post_code: "84330",
          },
        ],
      },
      {
        district_th: "อำเภอเกาะพะงัน",
        district_en: "Amphoe Ko Pha-ngan",
        children: [
          {
            subdistrict_th: "เกาะพะงัน",
            subdistrict_en: "Ko Pha-ngan",
            post_code: "84280",
          },
          {
            subdistrict_th: "บ้านใต้",
            subdistrict_en: "Ban Tai",
            post_code: "84280",
          },
          {
            subdistrict_th: "เกาะเต่า",
            subdistrict_en: "Ko Tao",
            post_code: "84360",
          },
        ],
      },
      {
        district_th: "อำเภอไชยา",
        district_en: "Amphoe Chaiya",
        children: [
          {
            subdistrict_th: "ตลาดไชยา",
            subdistrict_en: "Talat Chaiya",
            post_code: "84110",
          },
          {
            subdistrict_th: "พุมเรียง",
            subdistrict_en: "Phum Riang",
            post_code: "84110",
          },
          {
            subdistrict_th: "เลม็ด",
            subdistrict_en: "Lamet",
            post_code: "84110",
          },
          {
            subdistrict_th: "เวียง",
            subdistrict_en: "Wiang",
            post_code: "84110",
          },
          {
            subdistrict_th: "ทุ่ง",
            subdistrict_en: "Thung",
            post_code: "84110",
          },
          {
            subdistrict_th: "ป่าเว",
            subdistrict_en: "Pa We",
            post_code: "84110",
          },
          {
            subdistrict_th: "ตะกรบ",
            subdistrict_en: "Takrop",
            post_code: "84110",
          },
          {
            subdistrict_th: "โมถ่าย",
            subdistrict_en: "Mo Thai",
            post_code: "84110",
          },
          {
            subdistrict_th: "ปากหมาก",
            subdistrict_en: "Pak Mak",
            post_code: "84110",
          },
        ],
      },
      {
        district_th: "อำเภอท่าชนะ",
        district_en: "Amphoe Tha Chana",
        children: [
          {
            subdistrict_th: "ท่าชนะ",
            subdistrict_en: "Tha Chana",
            post_code: "84170",
          },
          {
            subdistrict_th: "สมอทอง",
            subdistrict_en: "Samo Thong",
            post_code: "84170",
          },
          {
            subdistrict_th: "ประสงค์",
            subdistrict_en: "Prasong",
            post_code: "84170",
          },
          {
            subdistrict_th: "คันธุลี",
            subdistrict_en: "Khan Thuli",
            post_code: "84170",
          },
          {
            subdistrict_th: "วัง",
            subdistrict_en: "Wang",
            post_code: "84170",
          },
          {
            subdistrict_th: "คลองพา",
            subdistrict_en: "Khlong Pha",
            post_code: "84170",
          },
        ],
      },
      {
        district_th: "อำเภอคีรีรัฐนิคม",
        district_en: "Amphoe Khiri Rat Nikhom",
        children: [
          {
            subdistrict_th: "ท่าขนอน",
            subdistrict_en: "Tha Khanon",
            post_code: "84180",
          },
          {
            subdistrict_th: "บ้านยาง",
            subdistrict_en: "Ban Yang",
            post_code: "84180",
          },
          {
            subdistrict_th: "น้ำหัก",
            subdistrict_en: "Nam Hak",
            post_code: "84180",
          },
          {
            subdistrict_th: "กะเปา",
            subdistrict_en: "Kapao",
            post_code: "84180",
          },
          {
            subdistrict_th: "ท่ากระดาน",
            subdistrict_en: "Tha Kradan",
            post_code: "84180",
          },
          {
            subdistrict_th: "ย่านยาว",
            subdistrict_en: "Yan Yao",
            post_code: "84180",
          },
          {
            subdistrict_th: "ถ้ำสิงขร",
            subdistrict_en: "Tham Singkhon",
            post_code: "84180",
          },
          {
            subdistrict_th: "บ้านทำเนียบ",
            subdistrict_en: "Ban Thamniap",
            post_code: "84180",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านตาขุน",
        district_en: "Amphoe Ban Ta Khun",
        children: [
          {
            subdistrict_th: "เขาวง",
            subdistrict_en: "Khao Wong",
            post_code: "84230",
          },
          {
            subdistrict_th: "พะแสง",
            subdistrict_en: "Phasaeng",
            post_code: "84230",
          },
          {
            subdistrict_th: "พรุไทย",
            subdistrict_en: "Phru Thai",
            post_code: "84230",
          },
          {
            subdistrict_th: "เขาพัง",
            subdistrict_en: "Khao Phang",
            post_code: "84230",
          },
        ],
      },
      {
        district_th: "อำเภอพนม",
        district_en: "Amphoe Phanom",
        children: [
          {
            subdistrict_th: "พนม",
            subdistrict_en: "Phanom",
            post_code: "84250",
          },
          {
            subdistrict_th: "ต้นยวน",
            subdistrict_en: "Ton Yuan",
            post_code: "84250",
          },
          {
            subdistrict_th: "คลองศก",
            subdistrict_en: "Khlong Sok",
            post_code: "84250",
          },
          {
            subdistrict_th: "พลูเถื่อน",
            subdistrict_en: "Phlu Thuean",
            post_code: "84250",
          },
          {
            subdistrict_th: "พังกาญจน์",
            subdistrict_en: "Phang Kan",
            post_code: "84250",
          },
          {
            subdistrict_th: "คลองชะอุ่น",
            subdistrict_en: "Khlong Cha-un",
            post_code: "84250",
          },
        ],
      },
      {
        district_th: "อำเภอท่าฉาง",
        district_en: "Amphoe Tha Chang",
        children: [
          {
            subdistrict_th: "ท่าฉาง",
            subdistrict_en: "Tha Chang",
            post_code: "84150",
          },
          {
            subdistrict_th: "ท่าเคย",
            subdistrict_en: "Tha Khoei",
            post_code: "84150",
          },
          {
            subdistrict_th: "คลองไทร",
            subdistrict_en: "Khlong Sai",
            post_code: "84150",
          },
          {
            subdistrict_th: "เขาถ่าน",
            subdistrict_en: "Khao Than",
            post_code: "84150",
          },
          {
            subdistrict_th: "เสวียด",
            subdistrict_en: "Sawiat",
            post_code: "84150",
          },
          {
            subdistrict_th: "ปากฉลุย",
            subdistrict_en: "Pak Chalui",
            post_code: "84150",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านนาสาร",
        district_en: "Amphoe Ban Na San",
        children: [
          {
            subdistrict_th: "นาสาร",
            subdistrict_en: "Na San",
            post_code: "84120",
          },
          {
            subdistrict_th: "พรุพี",
            subdistrict_en: "Phru Phi",
            post_code: "84270",
          },
          {
            subdistrict_th: "ทุ่งเตา",
            subdistrict_en: "Thung Tao",
            post_code: "84120",
          },
          {
            subdistrict_th: "ลำพูน",
            subdistrict_en: "Lamphun",
            post_code: "84120",
          },
          {
            subdistrict_th: "ท่าชี",
            subdistrict_en: "Tha Chi",
            post_code: "84120",
          },
          {
            subdistrict_th: "ควนศรี",
            subdistrict_en: "Khuan Si",
            post_code: "84270",
          },
          {
            subdistrict_th: "ควนสุบรรณ",
            subdistrict_en: "Khuan Suban",
            post_code: "84120",
          },
          {
            subdistrict_th: "คลองปราบ",
            subdistrict_en: "Khlong Prap",
            post_code: "84120",
          },
          {
            subdistrict_th: "น้ำพุ",
            subdistrict_en: "Nam Phu",
            post_code: "84120",
          },
          {
            subdistrict_th: "ทุ่งเตาใหม่",
            subdistrict_en: "Thung Tao Mai",
            post_code: "84120",
          },
          {
            subdistrict_th: "เพิ่มพูนทรัพย์",
            subdistrict_en: "Phoem Phun Sap",
            post_code: "84120",
          },
        ],
      },
      {
        district_th: "อำเภอบ้านนาเดิม",
        district_en: "Amphoe Ban Na Doem",
        children: [
          {
            subdistrict_th: "บ้านนา",
            subdistrict_en: "Ban Na",
            post_code: "84240",
          },
          {
            subdistrict_th: "ท่าเรือ",
            subdistrict_en: "Tha Ruea",
            post_code: "84240",
          },
          {
            subdistrict_th: "ทรัพย์ทวี",
            subdistrict_en: "Sap Thawi",
            post_code: "84240",
          },
          {
            subdistrict_th: "นาใต้",
            subdistrict_en: "Na Tai",
            post_code: "84240",
          },
        ],
      },
      {
        district_th: "อำเภอเคียนซา",
        district_en: "Amphoe Khian Sa",
        children: [
          {
            subdistrict_th: "เคียนซา",
            subdistrict_en: "Khian Sa",
            post_code: "84260",
          },
          {
            subdistrict_th: "พ่วงพรมคร",
            subdistrict_en: "Phuang Phrom Khon",
            post_code: "84210",
          },
          {
            subdistrict_th: "เขาตอก",
            subdistrict_en: "Khao Tok",
            post_code: "84260",
          },
          {
            subdistrict_th: "อรัญคามวารี",
            subdistrict_en: "Aranyakham Wari",
            post_code: "84260",
          },
          {
            subdistrict_th: "บ้านเสด็จ",
            subdistrict_en: "Ban Sadet",
            post_code: "84260",
          },
        ],
      },
      {
        district_th: "อำเภอเวียงสระ",
        district_en: "Amphoe Wiang Sa",
        children: [
          {
            subdistrict_th: "เวียงสระ",
            subdistrict_en: "Wiang Sa",
            post_code: "84190",
          },
          {
            subdistrict_th: "บ้านส้อง",
            subdistrict_en: "Ban Song",
            post_code: "84190",
          },
          {
            subdistrict_th: "คลองฉนวน",
            subdistrict_en: "Khlong Chanuan",
            post_code: "84190",
          },
          {
            subdistrict_th: "ทุ่งหลวง",
            subdistrict_en: "Thung Luang",
            post_code: "84190",
          },
          {
            subdistrict_th: "เขานิพันธ์",
            subdistrict_en: "Khao Niphan",
            post_code: "84190",
          },
        ],
      },
      {
        district_th: "อำเภอพระแสง",
        district_en: "Amphoe Phrasaeng",
        children: [
          {
            subdistrict_th: "อิปัน",
            subdistrict_en: "I-pan",
            post_code: "84210",
          },
          {
            subdistrict_th: "สินปุน",
            subdistrict_en: "Sin Pun",
            post_code: "84210",
          },
          {
            subdistrict_th: "บางสวรรค์",
            subdistrict_en: "Bang Sawan",
            post_code: "84210",
          },
          {
            subdistrict_th: "ไทรขึง",
            subdistrict_en: "Sai Khueng",
            post_code: "84210",
          },
          {
            subdistrict_th: "สินเจริญ",
            subdistrict_en: "Sin Charoen",
            post_code: "84210",
          },
          {
            subdistrict_th: "ไทรโสภา",
            subdistrict_en: "Sai Sopha",
            post_code: "84210",
          },
          {
            subdistrict_th: "สาคู",
            subdistrict_en: "Sakhu",
            post_code: "84210",
          },
        ],
      },
      {
        district_th: "อำเภอพุนพิน",
        district_en: "Amphoe Phunphin",
        children: [
          {
            subdistrict_th: "ท่าข้าม",
            subdistrict_en: "Tha Kham",
            post_code: "84130",
          },
          {
            subdistrict_th: "ท่าสะท้อน",
            subdistrict_en: "Tha Sathon",
            post_code: "84130",
          },
          {
            subdistrict_th: "ลีเล็ด",
            subdistrict_en: "Lilet",
            post_code: "84130",
          },
          {
            subdistrict_th: "บางมะเดื่อ",
            subdistrict_en: "Bang Maduea",
            post_code: "84130",
          },
          {
            subdistrict_th: "บางเดือน",
            subdistrict_en: "Bang Duean",
            post_code: "84130",
          },
          {
            subdistrict_th: "ท่าโรงช้าง",
            subdistrict_en: "Tha Rong Chang",
            post_code: "84130",
          },
          {
            subdistrict_th: "กรูด",
            subdistrict_en: "Krut",
            post_code: "84130",
          },
          {
            subdistrict_th: "พุนพิน",
            subdistrict_en: "Phunphin",
            post_code: "84130",
          },
          {
            subdistrict_th: "บางงอน",
            subdistrict_en: "Bang Ngon",
            post_code: "84130",
          },
          {
            subdistrict_th: "ศรีวิชัย",
            subdistrict_en: "Si Wichai",
            post_code: "84130",
          },
          {
            subdistrict_th: "น้ำรอบ",
            subdistrict_en: "Nam Rop",
            post_code: "84130",
          },
          {
            subdistrict_th: "มะลวน",
            subdistrict_en: "Maluan",
            post_code: "84130",
          },
          {
            subdistrict_th: "หัวเตย",
            subdistrict_en: "Hua Toei",
            post_code: "84130",
          },
          {
            subdistrict_th: "หนองไทร",
            subdistrict_en: "Nong Sai",
            post_code: "84130",
          },
          {
            subdistrict_th: "เขาหัวควาย",
            subdistrict_en: "Khao Hua Khwai",
            post_code: "84130",
          },
          {
            subdistrict_th: "ตะปาน",
            subdistrict_en: "Tapan",
            post_code: "84130",
          },
        ],
      },
      {
        district_th: "อำเภอชัยบุรี",
        district_en: "Amphoe Chai Buri",
        children: [
          {
            subdistrict_th: "สองแพรก",
            subdistrict_en: "Song Phraek",
            post_code: "84350",
          },
          {
            subdistrict_th: "ชัยบุรี",
            subdistrict_en: "Chai Buri",
            post_code: "84350",
          },
          {
            subdistrict_th: "คลองน้อย",
            subdistrict_en: "Khlong Noi",
            post_code: "84350",
          },
          {
            subdistrict_th: "ไทรทอง",
            subdistrict_en: "Sai Thong",
            post_code: "84350",
          },
        ],
      },
      {
        district_th: "อำเภอวิภาวดี",
        district_en: "Amphoe Vibhavadi",
        children: [
          {
            subdistrict_th: "ตะกุกใต้",
            subdistrict_en: "Takuk Tai",
            post_code: "84370",
          },
          {
            subdistrict_th: "ตะกุกเหนือ",
            subdistrict_en: "Takuk Nuea",
            post_code: "84370",
          },
        ],
      },
    ],
  },
  {
    province_th: "ระนอง",
    province_en: "Ranong",
    children: [
      {
        district_th: "อำเภอเมืองระนอง",
        district_en: "Amphoe Mueang Ranong",
        children: [
          {
            subdistrict_th: "เขานิเวศน์",
            subdistrict_en: "Khao Niwet",
            post_code: "85000",
          },
          {
            subdistrict_th: "ราชกรูด",
            subdistrict_en: "Ratchakrut",
            post_code: "85000",
          },
          {
            subdistrict_th: "หงาว",
            subdistrict_en: "Ngao",
            post_code: "85000",
          },
          {
            subdistrict_th: "บางริ้น",
            subdistrict_en: "Bang Rin",
            post_code: "85000",
          },
          {
            subdistrict_th: "ปากน้ำ",
            subdistrict_en: "Pak Nam",
            post_code: "85000",
          },
          {
            subdistrict_th: "บางนอน",
            subdistrict_en: "Bang Non",
            post_code: "85000",
          },
          {
            subdistrict_th: "หาดส้มแป้น",
            subdistrict_en: "Hat Som Paen",
            post_code: "85000",
          },
          {
            subdistrict_th: "ทรายแดง",
            subdistrict_en: "Sai Daeng",
            post_code: "85130",
          },
          {
            subdistrict_th: "เกาะพยาม",
            subdistrict_en: "Ko Phayam",
            post_code: "85000",
          },
        ],
      },
      {
        district_th: "อำเภอละอุ่น",
        district_en: "Amphoe La-un",
        children: [
          {
            subdistrict_th: "ละอุ่นใต้",
            subdistrict_en: "La-un Tai",
            post_code: "85130",
          },
          {
            subdistrict_th: "ละอุ่นเหนือ",
            subdistrict_en: "La-un Nuea",
            post_code: "85130",
          },
          {
            subdistrict_th: "บางพระใต้",
            subdistrict_en: "Bang Phra Tai",
            post_code: "85130",
          },
          {
            subdistrict_th: "บางพระเหนือ",
            subdistrict_en: "Bang Phra Nuea",
            post_code: "85130",
          },
          {
            subdistrict_th: "บางแก้ว",
            subdistrict_en: "Bang Kaeo",
            post_code: "85130",
          },
          {
            subdistrict_th: "ในวงเหนือ",
            subdistrict_en: "Nai Wong Nuea",
            post_code: "85130",
          },
          {
            subdistrict_th: "ในวงใต้",
            subdistrict_en: "Nai Wong Tai",
            post_code: "85130",
          },
        ],
      },
      {
        district_th: "อำเภอกะเปอร์",
        district_en: "Amphoe Kapoe",
        children: [
          {
            subdistrict_th: "ม่วงกลวง",
            subdistrict_en: "Muang Kluang",
            post_code: "85120",
          },
          {
            subdistrict_th: "กะเปอร์",
            subdistrict_en: "Kapoe",
            post_code: "85120",
          },
          {
            subdistrict_th: "เชี่ยวเหลียง",
            subdistrict_en: "Chiao Liang",
            post_code: "85120",
          },
          {
            subdistrict_th: "บ้านนา",
            subdistrict_en: "Ban Na",
            post_code: "85120",
          },
          {
            subdistrict_th: "บางหิน",
            subdistrict_en: "Bang Hin",
            post_code: "85120",
          },
        ],
      },
      {
        district_th: "อำเภอกระบุรี",
        district_en: "Amphoe Kra Buri",
        children: [
          {
            subdistrict_th: "น้ำจืด",
            subdistrict_en: "Nam Chuet",
            post_code: "85110",
          },
          {
            subdistrict_th: "น้ำจืดน้อย",
            subdistrict_en: "Nam Chuet Noi",
            post_code: "85110",
          },
          {
            subdistrict_th: "มะมุ",
            subdistrict_en: "Mamu",
            post_code: "85110",
          },
          {
            subdistrict_th: "ปากจั่น",
            subdistrict_en: "Pak Chan",
            post_code: "85110",
          },
          {
            subdistrict_th: "ลำเลียง",
            subdistrict_en: "Lam Liang",
            post_code: "85110",
          },
          {
            subdistrict_th: "จ.ป.ร.",
            subdistrict_en: "Choporo",
            post_code: "85110",
          },
          {
            subdistrict_th: "บางใหญ่",
            subdistrict_en: "Bang Yai",
            post_code: "85110",
          },
        ],
      },
      {
        district_th: "อำเภอสุขสำราญ",
        district_en: "Amphoe Suk Samran",
        children: [
          {
            subdistrict_th: "นาคา",
            subdistrict_en: "Nakha",
            post_code: "85120",
          },
          {
            subdistrict_th: "กำพวน",
            subdistrict_en: "Kamphuan",
            post_code: "85120",
          },
        ],
      },
    ],
  },
  {
    province_th: "ชุมพร",
    province_en: "Chumphon",
    children: [
      {
        district_th: "อำเภอเมืองชุมพร",
        district_en: "Amphoe Mueang Chumphon",
        children: [
          {
            subdistrict_th: "ท่าตะเภา",
            subdistrict_en: "Tha Taphao",
            post_code: "86000",
          },
          {
            subdistrict_th: "ปากน้ำ",
            subdistrict_en: "Pak Nam",
            post_code: "86120",
          },
          {
            subdistrict_th: "ท่ายาง",
            subdistrict_en: "Tha Yang",
            post_code: "86000",
          },
          {
            subdistrict_th: "บางหมาก",
            subdistrict_en: "Bang Mak",
            post_code: "86000",
          },
          {
            subdistrict_th: "นาทุ่ง",
            subdistrict_en: "Na Thung",
            post_code: "86000",
          },
          {
            subdistrict_th: "นาชะอัง",
            subdistrict_en: "Na Cha-ang",
            post_code: "86000",
          },
          {
            subdistrict_th: "ตากแดด",
            subdistrict_en: "Tak Daet",
            post_code: "86000",
          },
          {
            subdistrict_th: "บางลึก",
            subdistrict_en: "Bang Luek",
            post_code: "86000",
          },
          {
            subdistrict_th: "หาดพันไกร",
            subdistrict_en: "Hat Phan Krai",
            post_code: "86000",
          },
          {
            subdistrict_th: "วังไผ่",
            subdistrict_en: "Wang Phai",
            post_code: "86000",
          },
          {
            subdistrict_th: "วังใหม่",
            subdistrict_en: "Wang Mai",
            post_code: "86190",
          },
          {
            subdistrict_th: "บ้านนา",
            subdistrict_en: "Ban Na",
            post_code: "86190",
          },
          {
            subdistrict_th: "ขุนกระทิง",
            subdistrict_en: "Khun Krathing",
            post_code: "86190",
          },
          {
            subdistrict_th: "ทุ่งคา",
            subdistrict_en: "Thung Kha",
            post_code: "86100",
          },
          {
            subdistrict_th: "วิสัยเหนือ",
            subdistrict_en: "Wisai Nuea",
            post_code: "86100",
          },
          {
            subdistrict_th: "หาดทรายรี",
            subdistrict_en: "Hat Sai Ri",
            post_code: "86120",
          },
          {
            subdistrict_th: "ถ้ำสิงห์",
            subdistrict_en: "Tham Sing",
            post_code: "86100",
          },
        ],
      },
      {
        district_th: "อำเภอท่าแซะ",
        district_en: "Amphoe Tha Sae",
        children: [
          {
            subdistrict_th: "ท่าแซะ",
            subdistrict_en: "Tha Sae",
            post_code: "86140",
          },
          {
            subdistrict_th: "คุริง",
            subdistrict_en: "Khuring",
            post_code: "86140",
          },
          {
            subdistrict_th: "สลุย",
            subdistrict_en: "Salui",
            post_code: "86140",
          },
          {
            subdistrict_th: "นากระตาม",
            subdistrict_en: "Na Kratam",
            post_code: "86140",
          },
          {
            subdistrict_th: "รับร่อ",
            subdistrict_en: "Rap Ro",
            post_code: "86190",
          },
          {
            subdistrict_th: "ท่าข้าม",
            subdistrict_en: "Tha Kham",
            post_code: "86140",
          },
          {
            subdistrict_th: "หงษ์เจริญ",
            subdistrict_en: "Hong Charoen",
            post_code: "86140",
          },
          {
            subdistrict_th: "หินแก้ว",
            subdistrict_en: "Hin Kaeo",
            post_code: "86190",
          },
          {
            subdistrict_th: "ทรัพย์อนันต์",
            subdistrict_en: "Sap Anan",
            post_code: "86140",
          },
          {
            subdistrict_th: "สองพี่น้อง",
            subdistrict_en: "Song Phi Nong",
            post_code: "86140",
          },
        ],
      },
      {
        district_th: "อำเภอปะทิว",
        district_en: "Amphoe Pathio",
        children: [
          {
            subdistrict_th: "บางสน",
            subdistrict_en: "Bang Son",
            post_code: "86160",
          },
          {
            subdistrict_th: "ทะเลทรัพย์",
            subdistrict_en: "Thale Sap",
            post_code: "86160",
          },
          {
            subdistrict_th: "สะพลี",
            subdistrict_en: "Saphli",
            post_code: "86230",
          },
          {
            subdistrict_th: "ชุมโค",
            subdistrict_en: "Chum Kho",
            post_code: "86160",
          },
          {
            subdistrict_th: "ดอนยาง",
            subdistrict_en: "Don Yang",
            post_code: "86210",
          },
          {
            subdistrict_th: "ปากคลอง",
            subdistrict_en: "Pak Khlong",
            post_code: "86210",
          },
          {
            subdistrict_th: "เขาไชยราช",
            subdistrict_en: "Khao Chai Rat",
            post_code: "86210",
          },
        ],
      },
      {
        district_th: "อำเภอหลังสวน",
        district_en: "Amphoe Lang Suan",
        children: [
          {
            subdistrict_th: "หลังสวน",
            subdistrict_en: "Lang Suan",
            post_code: "86110",
          },
          {
            subdistrict_th: "ขันเงิน",
            subdistrict_en: "Khan Ngoen",
            post_code: "86110",
          },
          {
            subdistrict_th: "ท่ามะพลา",
            subdistrict_en: "Tha Maphla",
            post_code: "86110",
          },
          {
            subdistrict_th: "นาขา",
            subdistrict_en: "Na Kha",
            post_code: "86110",
          },
          {
            subdistrict_th: "นาพญา",
            subdistrict_en: "Na Phaya",
            post_code: "86110",
          },
          {
            subdistrict_th: "บ้านควน",
            subdistrict_en: "Ban Khuan",
            post_code: "86110",
          },
          {
            subdistrict_th: "บางมะพร้าว",
            subdistrict_en: "Bang Maphrao",
            post_code: "86110",
          },
          {
            subdistrict_th: "บางน้ำจืด",
            subdistrict_en: "Bang Nam Chuet",
            post_code: "86150",
          },
          {
            subdistrict_th: "ปากน้ำ",
            subdistrict_en: "Pak Nam",
            post_code: "86150",
          },
          {
            subdistrict_th: "พ้อแดง",
            subdistrict_en: "Pho Daeng",
            post_code: "86110",
          },
          {
            subdistrict_th: "แหลมทราย",
            subdistrict_en: "Laem Sai",
            post_code: "86110",
          },
          {
            subdistrict_th: "วังตะกอ",
            subdistrict_en: "Wang Tako",
            post_code: "86110",
          },
          {
            subdistrict_th: "หาดยาย",
            subdistrict_en: "Hat yai",
            post_code: "86110",
          },
        ],
      },
      {
        district_th: "อำเภอละแม",
        district_en: "Amphoe Lamae",
        children: [
          {
            subdistrict_th: "ละแม",
            subdistrict_en: "Lamae",
            post_code: "86170",
          },
          {
            subdistrict_th: "ทุ่งหลวง",
            subdistrict_en: "Thung Luang",
            post_code: "86170",
          },
          {
            subdistrict_th: "สวนแตง",
            subdistrict_en: "Suan Taeng",
            post_code: "86170",
          },
          {
            subdistrict_th: "ทุ่งคาวัด",
            subdistrict_en: "Thung Kha Wat",
            post_code: "86170",
          },
        ],
      },
      {
        district_th: "อำเภอพะโต๊ะ",
        district_en: "Amphoe Phato",
        children: [
          {
            subdistrict_th: "พะโต๊ะ",
            subdistrict_en: "Phato",
            post_code: "86180",
          },
          {
            subdistrict_th: "ปากทรง",
            subdistrict_en: "Pak Song",
            post_code: "86180",
          },
          {
            subdistrict_th: "ปังหวาน",
            subdistrict_en: "Pang Wan",
            post_code: "86180",
          },
          {
            subdistrict_th: "พระรักษ์",
            subdistrict_en: "Phra Rak",
            post_code: "86180",
          },
        ],
      },
      {
        district_th: "อำเภอสวี",
        district_en: "Amphoe Sawi",
        children: [
          {
            subdistrict_th: "นาโพธิ์",
            subdistrict_en: "Na Pho",
            post_code: "86130",
          },
          {
            subdistrict_th: "สวี",
            subdistrict_en: "Sawi",
            post_code: "86130",
          },
          {
            subdistrict_th: "ทุ่งระยะ",
            subdistrict_en: "Thung Raya",
            post_code: "86130",
          },
          {
            subdistrict_th: "ท่าหิน",
            subdistrict_en: "Tha Hin",
            post_code: "86130",
          },
          {
            subdistrict_th: "ปากแพรก",
            subdistrict_en: "Pak Phraek",
            post_code: "86130",
          },
          {
            subdistrict_th: "ด่านสวี",
            subdistrict_en: "Dan Sawi",
            post_code: "86130",
          },
          {
            subdistrict_th: "ครน",
            subdistrict_en: "Khron",
            post_code: "86130",
          },
          {
            subdistrict_th: "วิสัยใต้",
            subdistrict_en: "Wisai Tai",
            post_code: "86130",
          },
          {
            subdistrict_th: "นาสัก",
            subdistrict_en: "Na Sak",
            post_code: "86130",
          },
          {
            subdistrict_th: "เขาทะลุ",
            subdistrict_en: "Khao Thalu",
            post_code: "86130",
          },
          {
            subdistrict_th: "เขาค่าย",
            subdistrict_en: "Khao Khai",
            post_code: "86130",
          },
        ],
      },
      {
        district_th: "อำเภอทุ่งตะโก",
        district_en: "Amphoe Thung Tako",
        children: [
          {
            subdistrict_th: "ปากตะโก",
            subdistrict_en: "Pak Tako",
            post_code: "86220",
          },
          {
            subdistrict_th: "ทุ่งตะไคร",
            subdistrict_en: "Thung Takhrai",
            post_code: "86220",
          },
          {
            subdistrict_th: "ตะโก",
            subdistrict_en: "Tako",
            post_code: "86220",
          },
          {
            subdistrict_th: "ช่องไม้แก้ว",
            subdistrict_en: "Chong Mai Kaeo",
            post_code: "86220",
          },
        ],
      },
    ],
  },
  {
    province_th: "สงขลา",
    province_en: "Songkhla",
    children: [
      {
        district_th: "อำเภอเมืองสงขลา",
        district_en: "Amphoe Mueang Songkhla",
        children: [
          {
            subdistrict_th: "บ่อยาง",
            subdistrict_en: "Bo Yang",
            post_code: "90000",
          },
          {
            subdistrict_th: "เขารูปช้าง",
            subdistrict_en: "Khao Rup Chang",
            post_code: "90000",
          },
          {
            subdistrict_th: "เกาะแต้ว",
            subdistrict_en: "Ko Taeo",
            post_code: "90000",
          },
          {
            subdistrict_th: "พะวง",
            subdistrict_en: "Phawong",
            post_code: "90100",
          },
          {
            subdistrict_th: "ทุ่งหวัง",
            subdistrict_en: "Thung Wang",
            post_code: "90000",
          },
          {
            subdistrict_th: "เกาะยอ",
            subdistrict_en: "Ko Yo",
            post_code: "90100",
          },
        ],
      },
      {
        district_th: "อำเภอสทิงพระ",
        district_en: "Amphoe Sathing Phra",
        children: [
          {
            subdistrict_th: "จะทิ้งพระ",
            subdistrict_en: "Chathing Phra",
            post_code: "90190",
          },
          {
            subdistrict_th: "กระดังงา",
            subdistrict_en: "Kradang-nga",
            post_code: "90190",
          },
          {
            subdistrict_th: "สนามชัย",
            subdistrict_en: "Sanam Chai",
            post_code: "90190",
          },
          {
            subdistrict_th: "ดีหลวง",
            subdistrict_en: "Di Luang",
            post_code: "90190",
          },
          {
            subdistrict_th: "ชุมพล",
            subdistrict_en: "Chumphon",
            post_code: "90190",
          },
          {
            subdistrict_th: "คลองรี",
            subdistrict_en: "Khlong Ri",
            post_code: "90190",
          },
          {
            subdistrict_th: "คูขุด",
            subdistrict_en: "Khu Khut",
            post_code: "90190",
          },
          {
            subdistrict_th: "ท่าหิน",
            subdistrict_en: "Tha Hin",
            post_code: "90190",
          },
          {
            subdistrict_th: "วัดจันทร์",
            subdistrict_en: "Wat Chan",
            post_code: "90190",
          },
          {
            subdistrict_th: "บ่อแดง",
            subdistrict_en: "Bo Daeng",
            post_code: "90190",
          },
          {
            subdistrict_th: "บ่อดาน",
            subdistrict_en: "Bo Dan",
            post_code: "90190",
          },
        ],
      },
      {
        district_th: "อำเภอจะนะ",
        district_en: "Amphoe Chana",
        children: [
          {
            subdistrict_th: "บ้านนา",
            subdistrict_en: "Ban Na",
            post_code: "90130",
          },
          {
            subdistrict_th: "ป่าชิง",
            subdistrict_en: "Pa Ching",
            post_code: "90130",
          },
          {
            subdistrict_th: "สะพานไม้แก่น",
            subdistrict_en: "Saphan Mai Kaen",
            post_code: "90130",
          },
          {
            subdistrict_th: "สะกอม",
            subdistrict_en: "Sakom",
            post_code: "90130",
          },
          {
            subdistrict_th: "นาหว้า",
            subdistrict_en: "Na Wa",
            post_code: "90130",
          },
          {
            subdistrict_th: "นาทับ",
            subdistrict_en: "Na Thap",
            post_code: "90130",
          },
          {
            subdistrict_th: "น้ำขาว",
            subdistrict_en: "Nam Khao",
            post_code: "90130",
          },
          {
            subdistrict_th: "ขุนตัดหวาย",
            subdistrict_en: "Khun Tat Wai",
            post_code: "90130",
          },
          {
            subdistrict_th: "ท่าหมอไทร",
            subdistrict_en: "Tha Mo Sai",
            post_code: "90130",
          },
          {
            subdistrict_th: "จะโหนง",
            subdistrict_en: "Chanong",
            post_code: "90130",
          },
          {
            subdistrict_th: "คู",
            subdistrict_en: "Khu",
            post_code: "90130",
          },
          {
            subdistrict_th: "แค",
            subdistrict_en: "Khae",
            post_code: "90130",
          },
          {
            subdistrict_th: "คลองเปียะ",
            subdistrict_en: "Khlong Pia",
            post_code: "90130",
          },
          {
            subdistrict_th: "ตลิ่งชัน",
            subdistrict_en: "Taling Chan",
            post_code: "90130",
          },
        ],
      },
      {
        district_th: "อำเภอนาทวี",
        district_en: "Amphoe Na Thawi",
        children: [
          {
            subdistrict_th: "นาทวี",
            subdistrict_en: "Na Thawi",
            post_code: "90160",
          },
          {
            subdistrict_th: "ฉาง",
            subdistrict_en: "Chang",
            post_code: "90160",
          },
          {
            subdistrict_th: "นาหมอศรี",
            subdistrict_en: "Na Mo Si",
            post_code: "90160",
          },
          {
            subdistrict_th: "คลองทราย",
            subdistrict_en: "Khlong Sai",
            post_code: "90160",
          },
          {
            subdistrict_th: "ปลักหนู",
            subdistrict_en: "Plak Nu",
            post_code: "90160",
          },
          {
            subdistrict_th: "ท่าประดู่",
            subdistrict_en: "Tha Pradu",
            post_code: "90160",
          },
          {
            subdistrict_th: "สะท้อน",
            subdistrict_en: "Sathon",
            post_code: "90160",
          },
          {
            subdistrict_th: "ทับช้าง",
            subdistrict_en: "Thap Chang",
            post_code: "90160",
          },
          {
            subdistrict_th: "ประกอบ",
            subdistrict_en: "Prakop",
            post_code: "90160",
          },
          {
            subdistrict_th: "คลองกวาง",
            subdistrict_en: "Khlong Kwang",
            post_code: "90160",
          },
        ],
      },
      {
        district_th: "อำเภอเทพา",
        district_en: "Amphoe Thepha",
        children: [
          {
            subdistrict_th: "เทพา",
            subdistrict_en: "Thepha",
            post_code: "90150",
          },
          {
            subdistrict_th: "ปากบาง",
            subdistrict_en: "Pak Bang",
            post_code: "90150",
          },
          {
            subdistrict_th: "เกาะสะบ้า",
            subdistrict_en: "Ko Saba",
            post_code: "90150",
          },
          {
            subdistrict_th: "ลำไพล",
            subdistrict_en: "Lam Phlai",
            post_code: "90260",
          },
          {
            subdistrict_th: "ท่าม่วง",
            subdistrict_en: "Tha Muang",
            post_code: "90150",
          },
          {
            subdistrict_th: "วังใหญ่",
            subdistrict_en: "Wang Yai",
            post_code: "90260",
          },
          {
            subdistrict_th: "สะกอม",
            subdistrict_en: "Sakom",
            post_code: "90150",
          },
        ],
      },
      {
        district_th: "อำเภอสะบ้าย้อย",
        district_en: "Amphoe Saba Yoi",
        children: [
          {
            subdistrict_th: "สะบ้าย้อย",
            subdistrict_en: "Saba Yoi",
            post_code: "90210",
          },
          {
            subdistrict_th: "ทุ่งพอ",
            subdistrict_en: "Thung Pho",
            post_code: "90210",
          },
          {
            subdistrict_th: "เปียน",
            subdistrict_en: "Pian",
            post_code: "90210",
          },
          {
            subdistrict_th: "บ้านโหนด",
            subdistrict_en: "Ban Not",
            post_code: "90210",
          },
          {
            subdistrict_th: "จะแหน",
            subdistrict_en: "Chanae",
            post_code: "90210",
          },
          {
            subdistrict_th: "คูหา",
            subdistrict_en: "Khuha",
            post_code: "90210",
          },
          {
            subdistrict_th: "เขาแดง",
            subdistrict_en: "Khao Daeng",
            post_code: "90210",
          },
          {
            subdistrict_th: "บาโหย",
            subdistrict_en: "Ba Hoi",
            post_code: "90210",
          },
          {
            subdistrict_th: "ธารคีรี",
            subdistrict_en: "Than Khiri",
            post_code: "90210",
          },
        ],
      },
      {
        district_th: "อำเภอระโนด",
        district_en: "Amphoe Ranot",
        children: [
          {
            subdistrict_th: "ระโนด",
            subdistrict_en: "Ranot",
            post_code: "90140",
          },
          {
            subdistrict_th: "คลองแดน",
            subdistrict_en: "Khlong Daen",
            post_code: "90140",
          },
          {
            subdistrict_th: "ตะเครียะ",
            subdistrict_en: "Takhria",
            post_code: "90140",
          },
          {
            subdistrict_th: "ท่าบอน",
            subdistrict_en: "Tha Bon",
            post_code: "90140",
          },
          {
            subdistrict_th: "บ้านใหม่",
            subdistrict_en: "Ban Mai",
            post_code: "90140",
          },
          {
            subdistrict_th: "บ่อตรุ",
            subdistrict_en: "Bo Tru",
            post_code: "90140",
          },
          {
            subdistrict_th: "ปากแตระ",
            subdistrict_en: "Pak Trae",
            post_code: "90140",
          },
          {
            subdistrict_th: "พังยาง",
            subdistrict_en: "Phang Yang",
            post_code: "90140",
          },
          {
            subdistrict_th: "ระวะ",
            subdistrict_en: "Rawa",
            post_code: "90140",
          },
          {
            subdistrict_th: "วัดสน",
            subdistrict_en: "Wat Son",
            post_code: "90140",
          },
          {
            subdistrict_th: "บ้านขาว",
            subdistrict_en: "Ban Khwa",
            post_code: "90140",
          },
          {
            subdistrict_th: "แดนสงวน",
            subdistrict_en: "Daen Sa-nguan",
            post_code: "90140",
          },
        ],
      },
      {
        district_th: "อำเภอกระแสสินธุ์",
        district_en: "Amphoe Krasae Sin",
        children: [
          {
            subdistrict_th: "เกาะใหญ่",
            subdistrict_en: "Ko Yai",
            post_code: "90270",
          },
          {
            subdistrict_th: "โรง",
            subdistrict_en: "Rong",
            post_code: "90270",
          },
          {
            subdistrict_th: "เชิงแส",
            subdistrict_en: "Choeng Sae",
            post_code: "90270",
          },
          {
            subdistrict_th: "กระแสสินธุ์",
            subdistrict_en: "Krasae Sin",
            post_code: "90270",
          },
        ],
      },
      {
        district_th: "อำเภอรัตภูมิ",
        district_en: "Amphoe Rattaphum",
        children: [
          {
            subdistrict_th: "กำแพงเพชร",
            subdistrict_en: "Kamphaeng Phet",
            post_code: "90180",
          },
          {
            subdistrict_th: "ท่าชะมวง",
            subdistrict_en: "Tha Chamuang",
            post_code: "90180",
          },
          {
            subdistrict_th: "คูหาใต้",
            subdistrict_en: "Khuha Tai",
            post_code: "90180",
          },
          {
            subdistrict_th: "ควนรู",
            subdistrict_en: "Khuan Ru",
            post_code: "90180",
          },
          {
            subdistrict_th: "เขาพระ",
            subdistrict_en: "Khao Phra",
            post_code: "90180",
          },
        ],
      },
      {
        district_th: "อำเภอสะเดา",
        district_en: "Amphoe Sadao",
        children: [
          {
            subdistrict_th: "สะเดา",
            subdistrict_en: "Sadao",
            post_code: "90120",
          },
          {
            subdistrict_th: "ปริก",
            subdistrict_en: "Prik",
            post_code: "90120",
          },
          {
            subdistrict_th: "พังลา",
            subdistrict_en: "Phang La",
            post_code: "90170",
          },
          {
            subdistrict_th: "สำนักแต้ว",
            subdistrict_en: "Samnak Taeo",
            post_code: "90120",
          },
          {
            subdistrict_th: "ทุ่งหมอ",
            subdistrict_en: "Thung Mo",
            post_code: "90240",
          },
          {
            subdistrict_th: "ท่าโพธิ์",
            subdistrict_en: "Tha Pho",
            post_code: "90170",
          },
          {
            subdistrict_th: "ปาดังเบซาร์",
            subdistrict_en: "Padang Besa",
            post_code: "90240",
          },
          {
            subdistrict_th: "สำนักขาม",
            subdistrict_en: "Samnak Kham",
            post_code: "90320",
          },
          {
            subdistrict_th: "เขามีเกียรติ",
            subdistrict_en: "Khao Mi Kiat",
            post_code: "90170",
          },
        ],
      },
      {
        district_th: "อำเภอหาดใหญ่",
        district_en: "Amphoe Hat Yai",
        children: [
          {
            subdistrict_th: "หาดใหญ่",
            subdistrict_en: "Hat yai",
            post_code: "90110",
          },
          {
            subdistrict_th: "ควนลัง",
            subdistrict_en: "Khuan Lang",
            post_code: "90110",
          },
          {
            subdistrict_th: "คูเต่า",
            subdistrict_en: "Khu Tao",
            post_code: "90110",
          },
          {
            subdistrict_th: "คอหงส์",
            subdistrict_en: "Kho Hong",
            post_code: "90110",
          },
          {
            subdistrict_th: "คลองแห",
            subdistrict_en: "Khlong Hae",
            post_code: "90110",
          },
          {
            subdistrict_th: "คลองอู่ตะเภา",
            subdistrict_en: "Khlong U Taphao",
            post_code: "90110",
          },
          {
            subdistrict_th: "ฉลุง",
            subdistrict_en: "Chalung",
            post_code: "90110",
          },
          {
            subdistrict_th: "ทุ่งใหญ่",
            subdistrict_en: "Thung Yai",
            post_code: "90110",
          },
          {
            subdistrict_th: "ทุ่งตำเสา",
            subdistrict_en: "Thung Tam Sao",
            post_code: "90110",
          },
          {
            subdistrict_th: "ท่าข้าม",
            subdistrict_en: "Tha Kham",
            post_code: "90110",
          },
          {
            subdistrict_th: "น้ำน้อย",
            subdistrict_en: "Nam Noi",
            post_code: "90110",
          },
          {
            subdistrict_th: "บ้านพรุ",
            subdistrict_en: "Ban Phru",
            post_code: "90250",
          },
          {
            subdistrict_th: "พะตง",
            subdistrict_en: "Phatong",
            post_code: "90230",
          },
        ],
      },
      {
        district_th: "อำเภอนาหม่อม",
        district_en: "Amphoe Na Mom",
        children: [
          {
            subdistrict_th: "นาหม่อม",
            subdistrict_en: "Na Mom",
            post_code: "90310",
          },
          {
            subdistrict_th: "พิจิตร",
            subdistrict_en: "Phichit",
            post_code: "90310",
          },
          {
            subdistrict_th: "ทุ่งขมิ้น",
            subdistrict_en: "Thung Khamin",
            post_code: "90310",
          },
          {
            subdistrict_th: "คลองหรัง",
            subdistrict_en: "Khlong Rang",
            post_code: "90310",
          },
        ],
      },
      {
        district_th: "อำเภอควนเนียง",
        district_en: "Amphoe Khuan Niang",
        children: [
          {
            subdistrict_th: "รัตภูมิ",
            subdistrict_en: "Rattaphum",
            post_code: "90220",
          },
          {
            subdistrict_th: "ควนโส",
            subdistrict_en: "Khuan So",
            post_code: "90220",
          },
          {
            subdistrict_th: "ห้วยลึก",
            subdistrict_en: "Huai Luek",
            post_code: "90220",
          },
          {
            subdistrict_th: "บางเหรียง",
            subdistrict_en: "Bang Riang",
            post_code: "90220",
          },
        ],
      },
      {
        district_th: "อำเภอบางกล่ำ",
        district_en: "Amphoe Bang Klam",
        children: [
          {
            subdistrict_th: "บางกล่ำ",
            subdistrict_en: "Bang Klam",
            post_code: "90110",
          },
          {
            subdistrict_th: "ท่าช้าง",
            subdistrict_en: "Tha Chang",
            post_code: "90110",
          },
          {
            subdistrict_th: "แม่ทอม",
            subdistrict_en: "Mae Thom",
            post_code: "90110",
          },
          {
            subdistrict_th: "บ้านหาร",
            subdistrict_en: "Ban Han",
            post_code: "90110",
          },
        ],
      },
      {
        district_th: "อำเภอสิงหนคร",
        district_en: "Amphoe Singhanakhon",
        children: [
          {
            subdistrict_th: "ชิงโค",
            subdistrict_en: "Ching Kho",
            post_code: "90280",
          },
          {
            subdistrict_th: "สทิงหม้อ",
            subdistrict_en: "Sathing Mo",
            post_code: "90280",
          },
          {
            subdistrict_th: "ทำนบ",
            subdistrict_en: "Thamnop",
            post_code: "90280",
          },
          {
            subdistrict_th: "รำแดง",
            subdistrict_en: "Ram Daeng",
            post_code: "90330",
          },
          {
            subdistrict_th: "วัดขนุน",
            subdistrict_en: "Wat Khanun",
            post_code: "90330",
          },
          {
            subdistrict_th: "ชะแล้",
            subdistrict_en: "Chalae",
            post_code: "90330",
          },
          {
            subdistrict_th: "ปากรอ",
            subdistrict_en: "Pak Ro",
            post_code: "90330",
          },
          {
            subdistrict_th: "ป่าขาด",
            subdistrict_en: "Pa Khat",
            post_code: "90330",
          },
          {
            subdistrict_th: "หัวเขา",
            subdistrict_en: "Hua Khao",
            post_code: "90280",
          },
          {
            subdistrict_th: "บางเขียด",
            subdistrict_en: "Bang Khiat",
            post_code: "90330",
          },
          {
            subdistrict_th: "ม่วงงาม",
            subdistrict_en: "Muang Ngam",
            post_code: "90330",
          },
        ],
      },
      {
        district_th: "อำเภอคลองหอยโข่ง",
        district_en: "Amphoe Khlong Hoi Khong",
        children: [
          {
            subdistrict_th: "คลองหอยโข่ง",
            subdistrict_en: "Khlong Hoi Khong",
            post_code: "90230",
          },
          {
            subdistrict_th: "ทุ่งลาน",
            subdistrict_en: "Thung Lan",
            post_code: "90230",
          },
          {
            subdistrict_th: "โคกม่วง",
            subdistrict_en: "Khok Muang",
            post_code: "90230",
          },
          {
            subdistrict_th: "คลองหลา",
            subdistrict_en: "Khlong La",
            post_code: "90110",
          },
        ],
      },
    ],
  },
  {
    province_th: "สตูล",
    province_en: "Satun",
    children: [
      {
        district_th: "อำเภอเมืองสตูล",
        district_en: "Amphoe Mueang Satun",
        children: [
          {
            subdistrict_th: "พิมาน",
            subdistrict_en: "Phiman",
            post_code: "91000",
          },
          {
            subdistrict_th: "คลองขุด",
            subdistrict_en: "Khlong Khut",
            post_code: "91000",
          },
          {
            subdistrict_th: "ควนขัน",
            subdistrict_en: "Khuan Khan",
            post_code: "91000",
          },
          {
            subdistrict_th: "บ้านควน",
            subdistrict_en: "Ban Khuan",
            post_code: "91140",
          },
          {
            subdistrict_th: "ฉลุง",
            subdistrict_en: "Chalung",
            post_code: "91140",
          },
          {
            subdistrict_th: "เกาะสาหร่าย",
            subdistrict_en: "Ko Sarai",
            post_code: "91000",
          },
          {
            subdistrict_th: "ตันหยงโป",
            subdistrict_en: "Tanyong Po",
            post_code: "91000",
          },
          {
            subdistrict_th: "เจ๊ะบิลัง",
            subdistrict_en: "Che Bilang",
            post_code: "91000",
          },
          {
            subdistrict_th: "ตำมะลัง",
            subdistrict_en: "Tam Malang",
            post_code: "91000",
          },
          {
            subdistrict_th: "ปูยู",
            subdistrict_en: "Puyu",
            post_code: "91000",
          },
          {
            subdistrict_th: "ควนโพธิ์",
            subdistrict_en: "Khuan Pho",
            post_code: "91140",
          },
          {
            subdistrict_th: "เกตรี",
            subdistrict_en: "Ket Ri",
            post_code: "91140",
          },
        ],
      },
      {
        district_th: "อำเภอควนโดน",
        district_en: "Amphoe Khuan Don",
        children: [
          {
            subdistrict_th: "ควนโดน",
            subdistrict_en: "Khuan Don",
            post_code: "91160",
          },
          {
            subdistrict_th: "ควนสตอ",
            subdistrict_en: "Khuan Sato",
            post_code: "91160",
          },
          {
            subdistrict_th: "ย่านซื่อ",
            subdistrict_en: "Yan Sue",
            post_code: "91160",
          },
          {
            subdistrict_th: "วังประจัน",
            subdistrict_en: "Wang Prachan",
            post_code: "91160",
          },
        ],
      },
      {
        district_th: "อำเภอควนกาหลง",
        district_en: "Amphoe Khuan Kalong",
        children: [
          {
            subdistrict_th: "ทุ่งนุ้ย",
            subdistrict_en: "Thung Nui",
            post_code: "91130",
          },
          {
            subdistrict_th: "ควนกาหลง",
            subdistrict_en: "Khuan Kalong",
            post_code: "91130",
          },
          {
            subdistrict_th: "อุใดเจริญ",
            subdistrict_en: "Udai Charoen",
            post_code: "91130",
          },
        ],
      },
      {
        district_th: "อำเภอท่าแพ",
        district_en: "Amphoe Tha Phae",
        children: [
          {
            subdistrict_th: "ท่าแพ",
            subdistrict_en: "Tha Phae",
            post_code: "91150",
          },
          {
            subdistrict_th: "แป-ระ",
            subdistrict_en: "Pae-ra",
            post_code: "91150",
          },
          {
            subdistrict_th: "สาคร",
            subdistrict_en: "Sakhon",
            post_code: "91150",
          },
          {
            subdistrict_th: "ท่าเรือ",
            subdistrict_en: "Tha Ruea",
            post_code: "91150",
          },
        ],
      },
      {
        district_th: "อำเภอละงู",
        district_en: "Amphoe La-ngu",
        children: [
          {
            subdistrict_th: "กำแพง",
            subdistrict_en: "Kamphaeng",
            post_code: "91110",
          },
          {
            subdistrict_th: "ละงู",
            subdistrict_en: "La-ngu",
            post_code: "91110",
          },
          {
            subdistrict_th: "เขาขาว",
            subdistrict_en: "Khao Khao",
            post_code: "91110",
          },
          {
            subdistrict_th: "ปากน้ำ",
            subdistrict_en: "Pak Nam",
            post_code: "91110",
          },
          {
            subdistrict_th: "น้ำผุด",
            subdistrict_en: "Nam Phut",
            post_code: "91110",
          },
          {
            subdistrict_th: "แหลมสน",
            subdistrict_en: "Laem Son",
            post_code: "91110",
          },
        ],
      },
      {
        district_th: "อำเภอทุ่งหว้า",
        district_en: "Amphoe Thung Wa",
        children: [
          {
            subdistrict_th: "ทุ่งหว้า",
            subdistrict_en: "Thung Wa",
            post_code: "91120",
          },
          {
            subdistrict_th: "นาทอน",
            subdistrict_en: "Na Thon",
            post_code: "91120",
          },
          {
            subdistrict_th: "ขอนคลาน",
            subdistrict_en: "Khon Khlan",
            post_code: "91120",
          },
          {
            subdistrict_th: "ทุ่งบุหลัง",
            subdistrict_en: "Thung Bu Lang",
            post_code: "91120",
          },
          {
            subdistrict_th: "ป่าแก่บ่อหิน",
            subdistrict_en: "Pa Kae Bo Hin",
            post_code: "91120",
          },
        ],
      },
      {
        district_th: "อำเภอมะนัง",
        district_en: "Amphoe Manang",
        children: [
          {
            subdistrict_th: "ปาล์มพัฒนา",
            subdistrict_en: "Pam Phatthana",
            post_code: "91130",
          },
          {
            subdistrict_th: "นิคมพัฒนา",
            subdistrict_en: "Nikhom Phatthana",
            post_code: "91130",
          },
        ],
      },
    ],
  },
  {
    province_th: "ตรัง",
    province_en: "Trang",
    children: [
      {
        district_th: "อำเภอเมืองตรัง",
        district_en: "Amphoe Mueang Trang",
        children: [
          {
            subdistrict_th: "ทับเที่ยง",
            subdistrict_en: "Thap Thiang",
            post_code: "92000",
          },
          {
            subdistrict_th: "นาพละ",
            subdistrict_en: "Na Phala",
            post_code: "92000",
          },
          {
            subdistrict_th: "บ้านควน",
            subdistrict_en: "Ban Khuan",
            post_code: "92000",
          },
          {
            subdistrict_th: "นาบินหลา",
            subdistrict_en: "Na Bin La",
            post_code: "92170",
          },
          {
            subdistrict_th: "ควนปริง",
            subdistrict_en: "Khuan Pring",
            post_code: "92000",
          },
          {
            subdistrict_th: "นาโยงใต้",
            subdistrict_en: "Na Yong Tai",
            post_code: "92170",
          },
          {
            subdistrict_th: "บางรัก",
            subdistrict_en: "Bang Rak",
            post_code: "92000",
          },
          {
            subdistrict_th: "โคกหล่อ",
            subdistrict_en: "Khok Lo",
            post_code: "92000",
          },
          {
            subdistrict_th: "นาโต๊ะหมิง",
            subdistrict_en: "Na To Ming",
            post_code: "92000",
          },
          {
            subdistrict_th: "หนองตรุด",
            subdistrict_en: "Nong Trut",
            post_code: "92000",
          },
          {
            subdistrict_th: "น้ำผุด",
            subdistrict_en: "Nam Phut",
            post_code: "92000",
          },
          {
            subdistrict_th: "นาตาล่วง",
            subdistrict_en: "Na Ta Luang",
            post_code: "92000",
          },
          {
            subdistrict_th: "บ้านโพธิ์",
            subdistrict_en: "Ban Pho",
            post_code: "92000",
          },
          {
            subdistrict_th: "นาท่ามเหนือ",
            subdistrict_en: "Na Tham Nuea",
            post_code: "92190",
          },
          {
            subdistrict_th: "นาท่ามใต้",
            subdistrict_en: "Na Tham Tai",
            post_code: "92190",
          },
        ],
      },
      {
        district_th: "อำเภอกันตัง",
        district_en: "Amphoe Kantang",
        children: [
          {
            subdistrict_th: "กันตัง",
            subdistrict_en: "Kantang",
            post_code: "92110",
          },
          {
            subdistrict_th: "ควนธานี",
            subdistrict_en: "Khuan Thani",
            post_code: "92110",
          },
          {
            subdistrict_th: "บางหมาก",
            subdistrict_en: "Bang Mak",
            post_code: "92110",
          },
          {
            subdistrict_th: "บางเป้า",
            subdistrict_en: "Bang Pao",
            post_code: "92110",
          },
          {
            subdistrict_th: "วังวน",
            subdistrict_en: "Wang Won",
            post_code: "92110",
          },
          {
            subdistrict_th: "กันตังใต้",
            subdistrict_en: "Kantang Tai",
            post_code: "92110",
          },
          {
            subdistrict_th: "โคกยาง",
            subdistrict_en: "Khok Yang",
            post_code: "92110",
          },
          {
            subdistrict_th: "คลองลุ",
            subdistrict_en: "Khlong Lu",
            post_code: "92110",
          },
          {
            subdistrict_th: "ย่านซื่อ",
            subdistrict_en: "Yan Sue",
            post_code: "92110",
          },
          {
            subdistrict_th: "บ่อน้ำร้อน",
            subdistrict_en: "Bo Nam Ron",
            post_code: "92110",
          },
          {
            subdistrict_th: "บางสัก",
            subdistrict_en: "Bang Sak",
            post_code: "92110",
          },
          {
            subdistrict_th: "นาเกลือ",
            subdistrict_en: "Na Kluea",
            post_code: "92110",
          },
          {
            subdistrict_th: "เกาะลิบง",
            subdistrict_en: "Ko Libong",
            post_code: "92110",
          },
          {
            subdistrict_th: "คลองชีล้อม",
            subdistrict_en: "Khlong Chi Lom",
            post_code: "92110",
          },
        ],
      },
      {
        district_th: "อำเภอย่านตาขาว",
        district_en: "Amphoe Yan Ta Khao",
        children: [
          {
            subdistrict_th: "ย่านตาขาว",
            subdistrict_en: "Yan Ta Khao",
            post_code: "92140",
          },
          {
            subdistrict_th: "หนองบ่อ",
            subdistrict_en: "Nong Bo",
            post_code: "92140",
          },
          {
            subdistrict_th: "นาชุมเห็ด",
            subdistrict_en: "Na Chum Het",
            post_code: "92140",
          },
          {
            subdistrict_th: "ในควน",
            subdistrict_en: "Nai Khuan",
            post_code: "92140",
          },
          {
            subdistrict_th: "โพรงจระเข้",
            subdistrict_en: "Phrong Chorakhe",
            post_code: "92140",
          },
          {
            subdistrict_th: "ทุ่งกระบือ",
            subdistrict_en: "Thung Krabue",
            post_code: "92140",
          },
          {
            subdistrict_th: "ทุ่งค่าย",
            subdistrict_en: "Thung Khai",
            post_code: "92140",
          },
          {
            subdistrict_th: "เกาะเปียะ",
            subdistrict_en: "Ko Pia",
            post_code: "92140",
          },
        ],
      },
      {
        district_th: "อำเภอปะเหลียน",
        district_en: "Amphoe Palian",
        children: [
          {
            subdistrict_th: "ท่าข้าม",
            subdistrict_en: "Tha Kham",
            post_code: "92120",
          },
          {
            subdistrict_th: "ทุ่งยาว",
            subdistrict_en: "Thung Yao",
            post_code: "92180",
          },
          {
            subdistrict_th: "ปะเหลียน",
            subdistrict_en: "Palian",
            post_code: "92180",
          },
          {
            subdistrict_th: "บางด้วน",
            subdistrict_en: "Bang Duan",
            post_code: "92140",
          },
          {
            subdistrict_th: "บ้านนา",
            subdistrict_en: "Ban Na",
            post_code: "92140",
          },
          {
            subdistrict_th: "สุโสะ",
            subdistrict_en: "Suso",
            post_code: "92120",
          },
          {
            subdistrict_th: "ลิพัง",
            subdistrict_en: "Liphang",
            post_code: "92180",
          },
          {
            subdistrict_th: "เกาะสุกร",
            subdistrict_en: "Ko Sukon",
            post_code: "92120",
          },
          {
            subdistrict_th: "ท่าพญา",
            subdistrict_en: "Tha Phaya",
            post_code: "92140",
          },
          {
            subdistrict_th: "แหลมสอม",
            subdistrict_en: "Laem Som",
            post_code: "92180",
          },
        ],
      },
      {
        district_th: "อำเภอสิเกา",
        district_en: "Amphoe Sikao",
        children: [
          {
            subdistrict_th: "บ่อหิน",
            subdistrict_en: "Bo Hin",
            post_code: "92150",
          },
          {
            subdistrict_th: "เขาไม้แก้ว",
            subdistrict_en: "Khao Mai Kaeo",
            post_code: "92150",
          },
          {
            subdistrict_th: "กะลาเส",
            subdistrict_en: "Kalase",
            post_code: "92150",
          },
          {
            subdistrict_th: "ไม้ฝาด",
            subdistrict_en: "Mai Fat",
            post_code: "92150",
          },
          {
            subdistrict_th: "นาเมืองเพชร",
            subdistrict_en: "Na Mueang Phet",
            post_code: "92000",
          },
        ],
      },
      {
        district_th: "อำเภอห้วยยอด",
        district_en: "Amphoe Huai Yot",
        children: [
          {
            subdistrict_th: "ห้วยยอด",
            subdistrict_en: "Huai Yot",
            post_code: "92130",
          },
          {
            subdistrict_th: "หนองช้างแล่น",
            subdistrict_en: "Nong Chang Laen",
            post_code: "92130",
          },
          {
            subdistrict_th: "บางดี",
            subdistrict_en: "Bang Di",
            post_code: "92210",
          },
          {
            subdistrict_th: "บางกุ้ง",
            subdistrict_en: "Bang Kung",
            post_code: "92210",
          },
          {
            subdistrict_th: "เขากอบ",
            subdistrict_en: "Khao Kop",
            post_code: "92130",
          },
          {
            subdistrict_th: "เขาขาว",
            subdistrict_en: "Khao Khao",
            post_code: "92130",
          },
          {
            subdistrict_th: "เขาปูน",
            subdistrict_en: "Khao Pun",
            post_code: "92130",
          },
          {
            subdistrict_th: "ปากแจ่ม",
            subdistrict_en: "Pak Chaem",
            post_code: "92190",
          },
          {
            subdistrict_th: "ปากคม",
            subdistrict_en: "Pak Khom",
            post_code: "92130",
          },
          {
            subdistrict_th: "ท่างิ้ว",
            subdistrict_en: "Tha Ngio",
            post_code: "92130",
          },
          {
            subdistrict_th: "ลำภูรา",
            subdistrict_en: "Lamphu Ra",
            post_code: "92190",
          },
          {
            subdistrict_th: "นาวง",
            subdistrict_en: "Na Wong",
            post_code: "92210",
          },
          {
            subdistrict_th: "ห้วยนาง",
            subdistrict_en: "Huai Nang",
            post_code: "92130",
          },
          {
            subdistrict_th: "ในเตา",
            subdistrict_en: "Nai Tao",
            post_code: "92130",
          },
          {
            subdistrict_th: "ทุ่งต่อ",
            subdistrict_en: "Thung To",
            post_code: "92130",
          },
          {
            subdistrict_th: "วังคีรี",
            subdistrict_en: "Wang Khiri",
            post_code: "92210",
          },
        ],
      },
      {
        district_th: "อำเภอวังวิเศษ",
        district_en: "Amphoe Wang Wiset",
        children: [
          {
            subdistrict_th: "เขาวิเศษ",
            subdistrict_en: "Khao Wiset",
            post_code: "92220",
          },
          {
            subdistrict_th: "วังมะปราง",
            subdistrict_en: "Wang Maprang",
            post_code: "92220",
          },
          {
            subdistrict_th: "อ่าวตง",
            subdistrict_en: "Ao Tong",
            post_code: "92220",
          },
          {
            subdistrict_th: "ท่าสะบ้า",
            subdistrict_en: "Tha Saba",
            post_code: "92000",
          },
          {
            subdistrict_th: "วังมะปรางเหนือ",
            subdistrict_en: "Wang Maprang Nuea",
            post_code: "92220",
          },
        ],
      },
      {
        district_th: "อำเภอนาโยง",
        district_en: "Amphoe Na Yong",
        children: [
          {
            subdistrict_th: "นาโยงเหนือ",
            subdistrict_en: "Na Yong Nuea",
            post_code: "92170",
          },
          {
            subdistrict_th: "ช่อง",
            subdistrict_en: "Chong",
            post_code: "92170",
          },
          {
            subdistrict_th: "ละมอ",
            subdistrict_en: "Lamo",
            post_code: "92170",
          },
          {
            subdistrict_th: "โคกสะบ้า",
            subdistrict_en: "Khok Saba",
            post_code: "92170",
          },
          {
            subdistrict_th: "นาหมื่นศรี",
            subdistrict_en: "Na Muen Si",
            post_code: "92170",
          },
          {
            subdistrict_th: "นาข้าวเสีย",
            subdistrict_en: "Na Khao Sia",
            post_code: "92170",
          },
        ],
      },
      {
        district_th: "อำเภอรัษฎา",
        district_en: "Amphoe Ratsada",
        children: [
          {
            subdistrict_th: "ควนเมา",
            subdistrict_en: "Khuan Mao",
            post_code: "92160",
          },
          {
            subdistrict_th: "คลองปาง",
            subdistrict_en: "Khlong Pang",
            post_code: "92160",
          },
          {
            subdistrict_th: "หนองบัว",
            subdistrict_en: "Nong Bua",
            post_code: "92160",
          },
          {
            subdistrict_th: "หนองปรือ",
            subdistrict_en: "Nong Prue",
            post_code: "92130",
          },
          {
            subdistrict_th: "เขาไพร",
            subdistrict_en: "Khao Phrai",
            post_code: "92160",
          },
        ],
      },
      {
        district_th: "อำเภอหาดสำราญ",
        district_en: "Amphoe Hat Samran",
        children: [
          {
            subdistrict_th: "หาดสำราญ",
            subdistrict_en: "Hat Samran",
            post_code: "92120",
          },
          {
            subdistrict_th: "บ้าหวี",
            subdistrict_en: "Ba Wi",
            post_code: "92120",
          },
          {
            subdistrict_th: "ตะเสะ",
            subdistrict_en: "Tase",
            post_code: "92120",
          },
        ],
      },
    ],
  },
  {
    province_th: "พัทลุง",
    province_en: "Phatthalung",
    children: [
      {
        district_th: "อำเภอเมืองพัทลุง",
        district_en: "Amphoe Mueang Phatthalung",
        children: [
          {
            subdistrict_th: "คูหาสวรรค์",
            subdistrict_en: "Khuha Sawan",
            post_code: "93000",
          },
          {
            subdistrict_th: "เขาเจียก",
            subdistrict_en: "Khao Chiak",
            post_code: "93000",
          },
          {
            subdistrict_th: "ท่ามิหรำ",
            subdistrict_en: "Tha Miram",
            post_code: "93000",
          },
          {
            subdistrict_th: "โคกชะงาย",
            subdistrict_en: "Khok Cha-ngai",
            post_code: "93000",
          },
          {
            subdistrict_th: "นาท่อม",
            subdistrict_en: "Na Thom",
            post_code: "93000",
          },
          {
            subdistrict_th: "ปรางหมู่",
            subdistrict_en: "Prang Mu",
            post_code: "93000",
          },
          {
            subdistrict_th: "ท่าแค",
            subdistrict_en: "Tha Kae",
            post_code: "93000",
          },
          {
            subdistrict_th: "ลำปำ",
            subdistrict_en: "Lampam",
            post_code: "93000",
          },
          {
            subdistrict_th: "ตำนาน",
            subdistrict_en: "Tamnan",
            post_code: "93000",
          },
          {
            subdistrict_th: "ควนมะพร้าว",
            subdistrict_en: "Khuan Maphrao",
            post_code: "93000",
          },
          {
            subdistrict_th: "ร่มเมือง",
            subdistrict_en: "Rom Mueang",
            post_code: "93000",
          },
          {
            subdistrict_th: "ชัยบุรี",
            subdistrict_en: "Chai Buri",
            post_code: "93000",
          },
          {
            subdistrict_th: "นาโหนด",
            subdistrict_en: "Na Not",
            post_code: "93000",
          },
          {
            subdistrict_th: "พญาขัน",
            subdistrict_en: "Phaya Khan",
            post_code: "93000",
          },
        ],
      },
      {
        district_th: "อำเภอกงหรา",
        district_en: "Amphoe Kong Ra",
        children: [
          {
            subdistrict_th: "กงหรา",
            subdistrict_en: "Kong Ra",
            post_code: "93180",
          },
          {
            subdistrict_th: "ชะรัด",
            subdistrict_en: "Charat",
            post_code: "93000",
          },
          {
            subdistrict_th: "คลองเฉลิม",
            subdistrict_en: "Khlong Chaloem",
            post_code: "93180",
          },
          {
            subdistrict_th: "คลองทรายขาว",
            subdistrict_en: "Khlong Sai Khao",
            post_code: "93180",
          },
          {
            subdistrict_th: "สมหวัง",
            subdistrict_en: "Som Wang",
            post_code: "93000",
          },
        ],
      },
      {
        district_th: "อำเภอเขาชัยสน",
        district_en: "Amphoe Khao Chaison",
        children: [
          {
            subdistrict_th: "เขาชัยสน",
            subdistrict_en: "Khao Chaison",
            post_code: "93130",
          },
          {
            subdistrict_th: "ควนขนุน",
            subdistrict_en: "Khuan Khanun",
            post_code: "93130",
          },
          {
            subdistrict_th: "จองถนน",
            subdistrict_en: "Chong Thanon",
            post_code: "93130",
          },
          {
            subdistrict_th: "หานโพธิ์",
            subdistrict_en: "Han Pho",
            post_code: "93130",
          },
          {
            subdistrict_th: "โคกม่วง",
            subdistrict_en: "Khok Muang",
            post_code: "93130",
          },
        ],
      },
      {
        district_th: "อำเภอตะโหมด",
        district_en: "Amphoe Tamot",
        children: [
          {
            subdistrict_th: "แม่ขรี",
            subdistrict_en: "Mae Khari",
            post_code: "93160",
          },
          {
            subdistrict_th: "ตะโหมด",
            subdistrict_en: "Tamot",
            post_code: "93160",
          },
          {
            subdistrict_th: "คลองใหญ่",
            subdistrict_en: "Khlong Yai",
            post_code: "93160",
          },
        ],
      },
      {
        district_th: "อำเภอควนขนุน",
        district_en: "Amphoe Khuan Khanun",
        children: [
          {
            subdistrict_th: "ควนขนุน",
            subdistrict_en: "Khuan Khanun",
            post_code: "93110",
          },
          {
            subdistrict_th: "ทะเลน้อย",
            subdistrict_en: "Thale Noi",
            post_code: "93150",
          },
          {
            subdistrict_th: "นาขยาด",
            subdistrict_en: "Na Khayat",
            post_code: "93110",
          },
          {
            subdistrict_th: "พนมวังก์",
            subdistrict_en: "Phanom Wang",
            post_code: "93110",
          },
          {
            subdistrict_th: "แหลมโตนด",
            subdistrict_en: "Laem Tanot",
            post_code: "93110",
          },
          {
            subdistrict_th: "ปันแต",
            subdistrict_en: "Pan Tae",
            post_code: "93110",
          },
          {
            subdistrict_th: "โตนดด้วน",
            subdistrict_en: "Tanot Duan",
            post_code: "93110",
          },
          {
            subdistrict_th: "ดอนทราย",
            subdistrict_en: "Don Sai",
            post_code: "93110",
          },
          {
            subdistrict_th: "มะกอกเหนือ",
            subdistrict_en: "Makok Nuea",
            post_code: "93150",
          },
          {
            subdistrict_th: "พนางตุง",
            subdistrict_en: "Phanang Tung",
            post_code: "93150",
          },
          {
            subdistrict_th: "ชะมวง",
            subdistrict_en: "Chamuang",
            post_code: "93110",
          },
          {
            subdistrict_th: "แพรกหา",
            subdistrict_en: "Phraek Ha",
            post_code: "93110",
          },
        ],
      },
      {
        district_th: "อำเภอปากพะยูน",
        district_en: "Amphoe Pak Phayun",
        children: [
          {
            subdistrict_th: "ปากพะยูน",
            subdistrict_en: "Pak Phayun",
            post_code: "93120",
          },
          {
            subdistrict_th: "ดอนประดู่",
            subdistrict_en: "Don Pradu",
            post_code: "93120",
          },
          {
            subdistrict_th: "เกาะนางคำ",
            subdistrict_en: "Ko Nang Kham",
            post_code: "93120",
          },
          {
            subdistrict_th: "เกาะหมาก",
            subdistrict_en: "Ko Mak",
            post_code: "93120",
          },
          {
            subdistrict_th: "ฝาละมี",
            subdistrict_en: "Falami",
            post_code: "93120",
          },
          {
            subdistrict_th: "หารเทา",
            subdistrict_en: "Han Thao",
            post_code: "93120",
          },
          {
            subdistrict_th: "ดอนทราย",
            subdistrict_en: "Don Sai",
            post_code: "93120",
          },
        ],
      },
      {
        district_th: "อำเภอศรีบรรพต",
        district_en: "Amphoe Si Banphot",
        children: [
          {
            subdistrict_th: "เขาย่า",
            subdistrict_en: "Khao Ya",
            post_code: "93190",
          },
          {
            subdistrict_th: "เขาปู่",
            subdistrict_en: "Khao Pu",
            post_code: "93190",
          },
          {
            subdistrict_th: "ตะแพน",
            subdistrict_en: "Taphaen",
            post_code: "93190",
          },
        ],
      },
      {
        district_th: "อำเภอป่าบอน",
        district_en: "Amphoe Pa Bon",
        children: [
          {
            subdistrict_th: "ป่าบอน",
            subdistrict_en: "Pa Bon",
            post_code: "93170",
          },
          {
            subdistrict_th: "โคกทราย",
            subdistrict_en: "Khok Sai",
            post_code: "93170",
          },
          {
            subdistrict_th: "หนองธง",
            subdistrict_en: "Nong Thong",
            post_code: "93170",
          },
          {
            subdistrict_th: "ทุ่งนารี",
            subdistrict_en: "Thung Nari",
            post_code: "93170",
          },
          {
            subdistrict_th: "วังใหม่",
            subdistrict_en: "Wang Mai",
            post_code: "93170",
          },
        ],
      },
      {
        district_th: "อำเภอบางแก้ว",
        district_en: "Amphoe Bang Kaeo",
        children: [
          {
            subdistrict_th: "ท่ามะเดื่อ",
            subdistrict_en: "Tha Maduea",
            post_code: "93140",
          },
          {
            subdistrict_th: "นาปะขอ",
            subdistrict_en: "Na Pakho",
            post_code: "93140",
          },
          {
            subdistrict_th: "โคกสัก",
            subdistrict_en: "Khok Sak",
            post_code: "93140",
          },
        ],
      },
      {
        district_th: "อำเภอป่าพะยอม",
        district_en: "Amphoe Pa Phayom",
        children: [
          {
            subdistrict_th: "ป่าพะยอม",
            subdistrict_en: "Pa Phayom",
            post_code: "93210",
          },
          {
            subdistrict_th: "ลานข่อย",
            subdistrict_en: "Lan Khoi",
            post_code: "93210",
          },
          {
            subdistrict_th: "เกาะเต่า",
            subdistrict_en: "Ko Tao",
            post_code: "93210",
          },
          {
            subdistrict_th: "บ้านพร้าว",
            subdistrict_en: "Ban Phrao",
            post_code: "93210",
          },
        ],
      },
      {
        district_th: "อำเภอศรีนครินทร์",
        district_en: "Amphoe Srinagarindra",
        children: [
          {
            subdistrict_th: "ชุมพล",
            subdistrict_en: "Chumpon",
            post_code: "93000",
          },
          {
            subdistrict_th: "บ้านนา",
            subdistrict_en: "Ban Na",
            post_code: "93000",
          },
          {
            subdistrict_th: "อ่างทอง",
            subdistrict_en: "Ang Thong",
            post_code: "93000",
          },
          {
            subdistrict_th: "ลำสินธุ์",
            subdistrict_en: "Lam Sin",
            post_code: "93000",
          },
        ],
      },
    ],
  },
  {
    province_th: "ปัตตานี",
    province_en: "Pattani",
    children: [
      {
        district_th: "อำเภอเมืองปัตตานี",
        district_en: "Amphoe Mueang Pattani",
        children: [
          {
            subdistrict_th: "สะบารัง",
            subdistrict_en: "Sabarang",
            post_code: "94000",
          },
          {
            subdistrict_th: "อาเนาะรู",
            subdistrict_en: "Anoru",
            post_code: "94000",
          },
          {
            subdistrict_th: "จะบังติกอ",
            subdistrict_en: "Chabang Tiko",
            post_code: "94000",
          },
          {
            subdistrict_th: "บานา",
            subdistrict_en: "Bana",
            post_code: "94000",
          },
          {
            subdistrict_th: "ตันหยงลุโละ",
            subdistrict_en: "Tanyong Lulo",
            post_code: "94000",
          },
          {
            subdistrict_th: "คลองมานิง",
            subdistrict_en: "Khlong Maning",
            post_code: "94000",
          },
          {
            subdistrict_th: "กะมิยอ",
            subdistrict_en: "Kamiyo",
            post_code: "94000",
          },
          {
            subdistrict_th: "บาราโหม",
            subdistrict_en: "Bara Hom",
            post_code: "94000",
          },
          {
            subdistrict_th: "ปะกาฮะรัง",
            subdistrict_en: "Paka Harang",
            post_code: "94000",
          },
          {
            subdistrict_th: "รูสะมิแล",
            subdistrict_en: "Ru Samilae",
            post_code: "94000",
          },
          {
            subdistrict_th: "ตะลุโบะ",
            subdistrict_en: "Talubo",
            post_code: "94000",
          },
          {
            subdistrict_th: "บาราเฮาะ",
            subdistrict_en: "Bara Ho",
            post_code: "94000",
          },
          {
            subdistrict_th: "ปุยุด",
            subdistrict_en: "Puyut",
            post_code: "94000",
          },
        ],
      },
      {
        district_th: "อำเภอโคกโพธิ์",
        district_en: "Amphoe Khok Pho",
        children: [
          {
            subdistrict_th: "โคกโพธิ์",
            subdistrict_en: "Khok Pho",
            post_code: "94120",
          },
          {
            subdistrict_th: "มะกรูด",
            subdistrict_en: "Makrut",
            post_code: "94120",
          },
          {
            subdistrict_th: "บางโกระ",
            subdistrict_en: "Bang Kro",
            post_code: "94120",
          },
          {
            subdistrict_th: "ป่าบอน",
            subdistrict_en: "Pa Bon",
            post_code: "94120",
          },
          {
            subdistrict_th: "ทรายขาว",
            subdistrict_en: "Sai Khao",
            post_code: "94120",
          },
          {
            subdistrict_th: "นาประดู่",
            subdistrict_en: "Na Pradu",
            post_code: "94180",
          },
          {
            subdistrict_th: "ปากล่อ",
            subdistrict_en: "Pak Lo",
            post_code: "94180",
          },
          {
            subdistrict_th: "ทุ่งพลา",
            subdistrict_en: "Thung Phala",
            post_code: "94180",
          },
          {
            subdistrict_th: "ท่าเรือ",
            subdistrict_en: "Tha Ruea",
            post_code: "94120",
          },
          {
            subdistrict_th: "นาเกตุ",
            subdistrict_en: "Na Ket",
            post_code: "94120",
          },
          {
            subdistrict_th: "ควนโนรี",
            subdistrict_en: "Khuan Nori",
            post_code: "94180",
          },
          {
            subdistrict_th: "ช้างให้ตก",
            subdistrict_en: "Chang Hai Tok",
            post_code: "94120",
          },
        ],
      },
      {
        district_th: "อำเภอหนองจิก",
        district_en: "Amphoe Nong Chik",
        children: [
          {
            subdistrict_th: "เกาะเปาะ",
            subdistrict_en: "Ko Po",
            post_code: "94170",
          },
          {
            subdistrict_th: "คอลอตันหยง",
            subdistrict_en: "Kholo Tanyong",
            post_code: "94170",
          },
          {
            subdistrict_th: "ดอนรัก",
            subdistrict_en: "Don Rak",
            post_code: "94170",
          },
          {
            subdistrict_th: "ดาโต๊ะ",
            subdistrict_en: "Dato",
            post_code: "94170",
          },
          {
            subdistrict_th: "ตุยง",
            subdistrict_en: "Tuyong",
            post_code: "94170",
          },
          {
            subdistrict_th: "ท่ากำชำ",
            subdistrict_en: "Tha Kamcham",
            post_code: "94170",
          },
          {
            subdistrict_th: "บ่อทอง",
            subdistrict_en: "Bo Thong",
            post_code: "94170",
          },
          {
            subdistrict_th: "บางเขา",
            subdistrict_en: "Bang Khao",
            post_code: "94170",
          },
          {
            subdistrict_th: "บางตาวา",
            subdistrict_en: "Bang Tawa",
            post_code: "94170",
          },
          {
            subdistrict_th: "ปุโละปุโย",
            subdistrict_en: "Pulo Puyo",
            post_code: "94170",
          },
          {
            subdistrict_th: "ยาบี",
            subdistrict_en: "Yabi",
            post_code: "94170",
          },
          {
            subdistrict_th: "ลิปะสะโง",
            subdistrict_en: "Lipa Sa-ngo",
            post_code: "94170",
          },
        ],
      },
      {
        district_th: "อำเภอปะนาเระ",
        district_en: "Amphoe Panare",
        children: [
          {
            subdistrict_th: "ปะนาเระ",
            subdistrict_en: "Panare",
            post_code: "94130",
          },
          {
            subdistrict_th: "ท่าข้าม",
            subdistrict_en: "Tha Kham",
            post_code: "94130",
          },
          {
            subdistrict_th: "บ้านนอก",
            subdistrict_en: "Ban Nok",
            post_code: "94130",
          },
          {
            subdistrict_th: "ดอน",
            subdistrict_en: "Don",
            post_code: "94190",
          },
          {
            subdistrict_th: "ควน",
            subdistrict_en: "Khuan",
            post_code: "94190",
          },
          {
            subdistrict_th: "ท่าน้ำ",
            subdistrict_en: "Tha Nam",
            post_code: "94130",
          },
          {
            subdistrict_th: "คอกกระบือ",
            subdistrict_en: "Khok Krabue",
            post_code: "94130",
          },
          {
            subdistrict_th: "พ่อมิ่ง",
            subdistrict_en: "Pho Ming",
            post_code: "94130",
          },
          {
            subdistrict_th: "บ้านกลาง",
            subdistrict_en: "Ban Klang",
            post_code: "94130",
          },
          {
            subdistrict_th: "บ้านน้ำบ่อ",
            subdistrict_en: "Ban Nam Bo",
            post_code: "94130",
          },
        ],
      },
      {
        district_th: "อำเภอมายอ",
        district_en: "Amphoe Mayo",
        children: [
          {
            subdistrict_th: "มายอ",
            subdistrict_en: "Mayo",
            post_code: "94140",
          },
          {
            subdistrict_th: "ถนน",
            subdistrict_en: "Thanon",
            post_code: "94140",
          },
          {
            subdistrict_th: "ตรัง",
            subdistrict_en: "Trang",
            post_code: "94140",
          },
          {
            subdistrict_th: "กระหวะ",
            subdistrict_en: "Krawa",
            post_code: "94190",
          },
          {
            subdistrict_th: "ลุโบะยิไร",
            subdistrict_en: "Lubo yilai",
            post_code: "94140",
          },
          {
            subdistrict_th: "ลางา",
            subdistrict_en: "La-nga",
            post_code: "94190",
          },
          {
            subdistrict_th: "กระเสาะ",
            subdistrict_en: "Kraso",
            post_code: "94140",
          },
          {
            subdistrict_th: "เกาะจัน",
            subdistrict_en: "Ko Chan",
            post_code: "94140",
          },
          {
            subdistrict_th: "ปะโด",
            subdistrict_en: "Pado",
            post_code: "94140",
          },
          {
            subdistrict_th: "สาคอบน",
            subdistrict_en: "Sakho Bon",
            post_code: "94140",
          },
          {
            subdistrict_th: "สาคอใต้",
            subdistrict_en: "Sakho Tai",
            post_code: "94140",
          },
          {
            subdistrict_th: "สะกำ",
            subdistrict_en: "Sakam",
            post_code: "94140",
          },
          {
            subdistrict_th: "ปานัน",
            subdistrict_en: "Panan",
            post_code: "94140",
          },
        ],
      },
      {
        district_th: "อำเภอทุ่งยางแดง",
        district_en: "Amphoe Thung Yang Daeng",
        children: [
          {
            subdistrict_th: "ตะโละแมะนา",
            subdistrict_en: "Talo Maena",
            post_code: "94140",
          },
          {
            subdistrict_th: "พิเทน",
            subdistrict_en: "Phithen",
            post_code: "94140",
          },
          {
            subdistrict_th: "น้ำดำ",
            subdistrict_en: "Nam Dam",
            post_code: "94140",
          },
          {
            subdistrict_th: "ปากู",
            subdistrict_en: "Paku",
            post_code: "94140",
          },
        ],
      },
      {
        district_th: "อำเภอสายบุรี",
        district_en: "Amphoe Sai Buri",
        children: [
          {
            subdistrict_th: "ตะลุบัน",
            subdistrict_en: "Taluban",
            post_code: "94110",
          },
          {
            subdistrict_th: "ตะบิ้ง",
            subdistrict_en: "Tabing",
            post_code: "94110",
          },
          {
            subdistrict_th: "ปะเสยะวอ",
            subdistrict_en: "Pase Yawo",
            post_code: "94110",
          },
          {
            subdistrict_th: "บางเก่า",
            subdistrict_en: "Bang Kao",
            post_code: "94110",
          },
          {
            subdistrict_th: "บือเระ",
            subdistrict_en: "Bue Re",
            post_code: "94110",
          },
          {
            subdistrict_th: "เตราะบอน",
            subdistrict_en: "Tro Bon",
            post_code: "94110",
          },
          {
            subdistrict_th: "กะดุนง",
            subdistrict_en: "Kadunong",
            post_code: "94110",
          },
          {
            subdistrict_th: "ละหาร",
            subdistrict_en: "Lahan",
            post_code: "94110",
          },
          {
            subdistrict_th: "มะนังดาลำ",
            subdistrict_en: "Manang Dalam",
            post_code: "94110",
          },
          {
            subdistrict_th: "แป้น",
            subdistrict_en: "Paen",
            post_code: "94110",
          },
          {
            subdistrict_th: "ทุ่งคล้า",
            subdistrict_en: "Thung Khla",
            post_code: "94190",
          },
        ],
      },
      {
        district_th: "อำเภอไม้แก่น",
        district_en: "Amphoe Mai Kaen",
        children: [
          {
            subdistrict_th: "ไทรทอง",
            subdistrict_en: "Sai Thong",
            post_code: "94220",
          },
          {
            subdistrict_th: "ไม้แก่น",
            subdistrict_en: "Mai Kaen",
            post_code: "94220",
          },
          {
            subdistrict_th: "ตะโละไกรทอง",
            subdistrict_en: "Talo Kraithong",
            post_code: "94220",
          },
          {
            subdistrict_th: "ดอนทราย",
            subdistrict_en: "Don Sai",
            post_code: "94220",
          },
        ],
      },
      {
        district_th: "อำเภอยะหริ่ง",
        district_en: "Amphoe Yaring",
        children: [
          {
            subdistrict_th: "ตะโละ",
            subdistrict_en: "Talo",
            post_code: "94150",
          },
          {
            subdistrict_th: "ตะโละกาโปร์",
            subdistrict_en: "Talo Kapo",
            post_code: "94150",
          },
          {
            subdistrict_th: "ตันหยงดาลอ",
            subdistrict_en: "Tanyong Dalo",
            post_code: "94150",
          },
          {
            subdistrict_th: "ตันหยงจึงงา",
            subdistrict_en: "Tanyong Chueng-nga",
            post_code: "94190",
          },
          {
            subdistrict_th: "ตอหลัง",
            subdistrict_en: "Tolang",
            post_code: "94150",
          },
          {
            subdistrict_th: "ตาแกะ",
            subdistrict_en: "Takae",
            post_code: "94150",
          },
          {
            subdistrict_th: "ตาลีอายร์",
            subdistrict_en: "Tali-ai",
            post_code: "94150",
          },
          {
            subdistrict_th: "ยามู",
            subdistrict_en: "Yamu",
            post_code: "94150",
          },
          {
            subdistrict_th: "บางปู",
            subdistrict_en: "Bang Pu",
            post_code: "94150",
          },
          {
            subdistrict_th: "หนองแรต",
            subdistrict_en: "Nong Raet",
            post_code: "94150",
          },
          {
            subdistrict_th: "ปิยามุมัง",
            subdistrict_en: "Piya Mumang",
            post_code: "94150",
          },
          {
            subdistrict_th: "ปุลากง",
            subdistrict_en: "Pulakong",
            post_code: "94150",
          },
          {
            subdistrict_th: "บาโลย",
            subdistrict_en: "Baloi",
            post_code: "94190",
          },
          {
            subdistrict_th: "สาบัน",
            subdistrict_en: "Saban",
            post_code: "94150",
          },
          {
            subdistrict_th: "มะนังยง",
            subdistrict_en: "Manang Yong",
            post_code: "94150",
          },
          {
            subdistrict_th: "ราตาปันยัง",
            subdistrict_en: "Rata Panyang",
            post_code: "94150",
          },
          {
            subdistrict_th: "จะรัง",
            subdistrict_en: "Charang",
            post_code: "94150",
          },
          {
            subdistrict_th: "แหลมโพธิ์",
            subdistrict_en: "Laem Pho",
            post_code: "94150",
          },
        ],
      },
      {
        district_th: "อำเภอยะรัง",
        district_en: "Amphoe Yarang",
        children: [
          {
            subdistrict_th: "ยะรัง",
            subdistrict_en: "Yarang",
            post_code: "94160",
          },
          {
            subdistrict_th: "สะดาวา",
            subdistrict_en: "Sadawa",
            post_code: "94160",
          },
          {
            subdistrict_th: "ประจัน",
            subdistrict_en: "Prachan",
            post_code: "94160",
          },
          {
            subdistrict_th: "สะนอ",
            subdistrict_en: "Sano",
            post_code: "94160",
          },
          {
            subdistrict_th: "ระแว้ง",
            subdistrict_en: "Rawaeng",
            post_code: "94160",
          },
          {
            subdistrict_th: "ปิตูมุดี",
            subdistrict_en: "Pitu Mudi",
            post_code: "94160",
          },
          {
            subdistrict_th: "วัด",
            subdistrict_en: "Wat",
            post_code: "94160",
          },
          {
            subdistrict_th: "กระโด",
            subdistrict_en: "Krado",
            post_code: "94160",
          },
          {
            subdistrict_th: "คลองใหม่",
            subdistrict_en: "Khlong Mai",
            post_code: "94160",
          },
          {
            subdistrict_th: "เมาะมาวี",
            subdistrict_en: "Mo Mawi",
            post_code: "94160",
          },
          {
            subdistrict_th: "กอลำ",
            subdistrict_en: "Kolam",
            post_code: "94160",
          },
          {
            subdistrict_th: "เขาตูม",
            subdistrict_en: "Khao Tum",
            post_code: "94160",
          },
        ],
      },
      {
        district_th: "อำเภอกะพ้อ",
        district_en: "Amphoe Kapho",
        children: [
          {
            subdistrict_th: "กะรุบี",
            subdistrict_en: "Karubi",
            post_code: "94230",
          },
          {
            subdistrict_th: "ตะโละดือรามัน",
            subdistrict_en: "Talo Due Raman",
            post_code: "94230",
          },
          {
            subdistrict_th: "ปล่องหอย",
            subdistrict_en: "Plong Hoi",
            post_code: "94230",
          },
        ],
      },
      {
        district_th: "อำเภอแม่ลาน",
        district_en: "Amphoe Mae Lan",
        children: [
          {
            subdistrict_th: "แม่ลาน",
            subdistrict_en: "Mae Lan",
            post_code: "94180",
          },
          {
            subdistrict_th: "ม่วงเตี้ย",
            subdistrict_en: "Muang Tia",
            post_code: "94180",
          },
          {
            subdistrict_th: "ป่าไร่",
            subdistrict_en: "Pa Rai",
            post_code: "94180",
          },
        ],
      },
    ],
  },
  {
    province_th: "ยะลา",
    province_en: "Yala",
    children: [
      {
        district_th: "อำเภอเมืองยะลา",
        district_en: "Amphoe Mueang Yala",
        children: [
          {
            subdistrict_th: "สะเตง",
            subdistrict_en: "Sateng",
            post_code: "95000",
          },
          {
            subdistrict_th: "บุดี",
            subdistrict_en: "Budi",
            post_code: "95000",
          },
          {
            subdistrict_th: "ยุโป",
            subdistrict_en: "Yupo",
            post_code: "95000",
          },
          {
            subdistrict_th: "ลิดล",
            subdistrict_en: "Lidon",
            post_code: "95160",
          },
          {
            subdistrict_th: "ยะลา",
            subdistrict_en: "Yala",
            post_code: "95000",
          },
          {
            subdistrict_th: "ท่าสาป",
            subdistrict_en: "Tha Sap",
            post_code: "95000",
          },
          {
            subdistrict_th: "ลำใหม่",
            subdistrict_en: "Lam Mai",
            post_code: "95160",
          },
          {
            subdistrict_th: "หน้าถ้ำ",
            subdistrict_en: "Na Tham",
            post_code: "95000",
          },
          {
            subdistrict_th: "ลำพะยา",
            subdistrict_en: "Lam Phaya",
            post_code: "95160",
          },
          {
            subdistrict_th: "เปาะเส้ง",
            subdistrict_en: "Po Seng",
            post_code: "95000",
          },
          {
            subdistrict_th: "พร่อน",
            subdistrict_en: "Phron",
            post_code: "95160",
          },
          {
            subdistrict_th: "บันนังสาเรง",
            subdistrict_en: "Bannang Sareng",
            post_code: "95000",
          },
          {
            subdistrict_th: "สะเตงนอก",
            subdistrict_en: "Sateng Nok",
            post_code: "95000",
          },
          {
            subdistrict_th: "ตาเซะ",
            subdistrict_en: "Tase",
            post_code: "95000",
          },
        ],
      },
      {
        district_th: "อำเภอเบตง",
        district_en: "Amphoe Betong",
        children: [
          {
            subdistrict_th: "เบตง",
            subdistrict_en: "Betong",
            post_code: "95110",
          },
          {
            subdistrict_th: "ยะรม",
            subdistrict_en: "Yarom",
            post_code: "95110",
          },
          {
            subdistrict_th: "ตาเนาะแมเราะ",
            subdistrict_en: "Tano Maero",
            post_code: "95110",
          },
          {
            subdistrict_th: "อัยเยอร์เวง",
            subdistrict_en: "Aiyoeweng",
            post_code: "95110",
          },
          {
            subdistrict_th: "ธารน้ำทิพย์",
            subdistrict_en: "Than Nam Thip",
            post_code: "95110",
          },
        ],
      },
      {
        district_th: "อำเภอบันนังสตา",
        district_en: "Amphoe Bannang Sata",
        children: [
          {
            subdistrict_th: "บันนังสตา",
            subdistrict_en: "Bannang Sata",
            post_code: "95130",
          },
          {
            subdistrict_th: "บาเจาะ",
            subdistrict_en: "Bacho",
            post_code: "95130",
          },
          {
            subdistrict_th: "ตาเนาะปูเต๊ะ",
            subdistrict_en: "Tanao Pute",
            post_code: "95130",
          },
          {
            subdistrict_th: "ถ้ำทะลุ",
            subdistrict_en: "Tham Thalu",
            post_code: "95130",
          },
          {
            subdistrict_th: "ตลิ่งชัน",
            subdistrict_en: "Taling Chan",
            post_code: "95130",
          },
          {
            subdistrict_th: "เขื่อนบางลาง",
            subdistrict_en: "Khuean Bang Lang",
            post_code: "95130",
          },
        ],
      },
      {
        district_th: "อำเภอธารโต",
        district_en: "Amphoe Than To",
        children: [
          {
            subdistrict_th: "ธารโต",
            subdistrict_en: "Than To",
            post_code: "95150",
          },
          {
            subdistrict_th: "บ้านแหร",
            subdistrict_en: "Ban Rae",
            post_code: "95150",
          },
          {
            subdistrict_th: "แม่หวาด",
            subdistrict_en: "Mae Wat",
            post_code: "95170",
          },
          {
            subdistrict_th: "คีรีเขต",
            subdistrict_en: "Khiri Khet",
            post_code: "95150",
          },
        ],
      },
      {
        district_th: "อำเภอยะหา",
        district_en: "Amphoe Yaha",
        children: [
          {
            subdistrict_th: "ยะหา",
            subdistrict_en: "Yaha",
            post_code: "95120",
          },
          {
            subdistrict_th: "ละแอ",
            subdistrict_en: "La-ae",
            post_code: "95120",
          },
          {
            subdistrict_th: "ปะแต",
            subdistrict_en: "Patae",
            post_code: "95120",
          },
          {
            subdistrict_th: "บาโร๊ะ",
            subdistrict_en: "Baro",
            post_code: "95120",
          },
          {
            subdistrict_th: "ตาชี",
            subdistrict_en: "Tashi",
            post_code: "95120",
          },
          {
            subdistrict_th: "บาโงยซิแน",
            subdistrict_en: "Ba-ngoi Sinae",
            post_code: "95120",
          },
          {
            subdistrict_th: "กาตอง",
            subdistrict_en: "Ka Tong",
            post_code: "95120",
          },
        ],
      },
      {
        district_th: "อำเภอรามัน",
        district_en: "Amphoe Raman",
        children: [
          {
            subdistrict_th: "กายูบอเกาะ",
            subdistrict_en: "Kayu Boko",
            post_code: "95140",
          },
          {
            subdistrict_th: "กาลูปัง",
            subdistrict_en: "Kalupang",
            post_code: "95140",
          },
          {
            subdistrict_th: "กาลอ",
            subdistrict_en: "Kalo",
            post_code: "95140",
          },
          {
            subdistrict_th: "กอตอตือร๊ะ",
            subdistrict_en: "Koto Tuera",
            post_code: "95140",
          },
          {
            subdistrict_th: "โกตาบารู",
            subdistrict_en: "Kota Baru",
            post_code: "95140",
          },
          {
            subdistrict_th: "เกะรอ",
            subdistrict_en: "Kero",
            post_code: "95140",
          },
          {
            subdistrict_th: "จะกว๊ะ",
            subdistrict_en: "Cha-kwa",
            post_code: "95140",
          },
          {
            subdistrict_th: "ท่าธง",
            subdistrict_en: "Tha Thong",
            post_code: "95140",
          },
          {
            subdistrict_th: "เนินงาม",
            subdistrict_en: "Noen Ngam",
            post_code: "95140",
          },
          {
            subdistrict_th: "บาลอ",
            subdistrict_en: "Balo",
            post_code: "95140",
          },
          {
            subdistrict_th: "บาโงย",
            subdistrict_en: "Ba-ngoi",
            post_code: "95140",
          },
          {
            subdistrict_th: "บือมัง",
            subdistrict_en: "Buemang",
            post_code: "95140",
          },
          {
            subdistrict_th: "ยะต๊ะ",
            subdistrict_en: "Yata",
            post_code: "95140",
          },
          {
            subdistrict_th: "วังพญา",
            subdistrict_en: "Wang Phaya",
            post_code: "95140",
          },
          {
            subdistrict_th: "อาซ่อง",
            subdistrict_en: "A-song",
            post_code: "95140",
          },
          {
            subdistrict_th: "ตะโล๊ะหะลอ",
            subdistrict_en: "Talo Halo",
            post_code: "95140",
          },
        ],
      },
      {
        district_th: "อำเภอกาบัง",
        district_en: "Amphoe Kabang",
        children: [
          {
            subdistrict_th: "กาบัง",
            subdistrict_en: "Kabang",
            post_code: "95120",
          },
          {
            subdistrict_th: "บาละ",
            subdistrict_en: "Bala",
            post_code: "95120",
          },
        ],
      },
      {
        district_th: "อำเภอกรงปินัง",
        district_en: "Amphoe Krong Pinang",
        children: [
          {
            subdistrict_th: "กรงปินัง",
            subdistrict_en: "Krong Pinang",
            post_code: "95000",
          },
          {
            subdistrict_th: "สะเอะ",
            subdistrict_en: "Sa-e",
            post_code: "95000",
          },
          {
            subdistrict_th: "ห้วยกระทิง",
            subdistrict_en: "Huai Krathing",
            post_code: "95000",
          },
          {
            subdistrict_th: "ปุโรง",
            subdistrict_en: "Purong",
            post_code: "95000",
          },
        ],
      },
    ],
  },
  {
    province_th: "นราธิวาส",
    province_en: "Narathiwat",
    children: [
      {
        district_th: "อำเภอเมืองนราธิวาส",
        district_en: "Amphoe Mueang Narathiwat",
        children: [
          {
            subdistrict_th: "บางนาค",
            subdistrict_en: "Bang Nak",
            post_code: "96000",
          },
          {
            subdistrict_th: "ลำภู",
            subdistrict_en: "Lamphu",
            post_code: "96000",
          },
          {
            subdistrict_th: "มะนังตายอ",
            subdistrict_en: "Manang Tayo",
            post_code: "96000",
          },
          {
            subdistrict_th: "บางปอ",
            subdistrict_en: "Bang Po",
            post_code: "96000",
          },
          {
            subdistrict_th: "กะลุวอ",
            subdistrict_en: "Kaluwo",
            post_code: "96000",
          },
          {
            subdistrict_th: "กะลุวอเหนือ",
            subdistrict_en: "Kaluwo Nuea",
            post_code: "96000",
          },
          {
            subdistrict_th: "โคกเคียน",
            subdistrict_en: "Khok Khian",
            post_code: "96000",
          },
        ],
      },
      {
        district_th: "อำเภอตากใบ",
        district_en: "Amphoe Tak Bai",
        children: [
          {
            subdistrict_th: "เจ๊ะเห",
            subdistrict_en: "Chehe",
            post_code: "96110",
          },
          {
            subdistrict_th: "ไพรวัน",
            subdistrict_en: "Phraiwan",
            post_code: "96110",
          },
          {
            subdistrict_th: "พร่อน",
            subdistrict_en: "Phron",
            post_code: "96110",
          },
          {
            subdistrict_th: "ศาลาใหม่",
            subdistrict_en: "Sala Mai",
            post_code: "96110",
          },
          {
            subdistrict_th: "บางขุนทอง",
            subdistrict_en: "Bang Khun Thong",
            post_code: "96110",
          },
          {
            subdistrict_th: "เกาะสะท้อน",
            subdistrict_en: "Ko Sathon",
            post_code: "96110",
          },
          {
            subdistrict_th: "นานาค",
            subdistrict_en: "Na Nak",
            post_code: "96110",
          },
          {
            subdistrict_th: "โฆษิต",
            subdistrict_en: "Khosit",
            post_code: "96110",
          },
        ],
      },
      {
        district_th: "อำเภอบาเจาะ",
        district_en: "Amphoe Bacho",
        children: [
          {
            subdistrict_th: "บาเจาะ",
            subdistrict_en: "Bacho",
            post_code: "96170",
          },
          {
            subdistrict_th: "ลุโบะสาวอ",
            subdistrict_en: "Lubo Sawo",
            post_code: "96170",
          },
          {
            subdistrict_th: "กาเยาะมาตี",
            subdistrict_en: "Kayo Mati",
            post_code: "96170",
          },
          {
            subdistrict_th: "ปะลุกาสาเมาะ",
            subdistrict_en: "Paluka Samo",
            post_code: "96170",
          },
          {
            subdistrict_th: "บาเระเหนือ",
            subdistrict_en: "Bare Nuea",
            post_code: "96170",
          },
          {
            subdistrict_th: "บาเระใต้",
            subdistrict_en: "Bare Tai",
            post_code: "96170",
          },
        ],
      },
      {
        district_th: "อำเภอยี่งอ",
        district_en: "Amphoe Yi-ngo",
        children: [
          {
            subdistrict_th: "ยี่งอ",
            subdistrict_en: "Yi-ngo",
            post_code: "96180",
          },
          {
            subdistrict_th: "ละหาร",
            subdistrict_en: "Lahan",
            post_code: "96180",
          },
          {
            subdistrict_th: "จอเบาะ",
            subdistrict_en: "Cho Bo",
            post_code: "96180",
          },
          {
            subdistrict_th: "ลุโบะบายะ",
            subdistrict_en: "Lubo Baya",
            post_code: "96180",
          },
          {
            subdistrict_th: "ลุโบะบือซา",
            subdistrict_en: "Lubo Buesa",
            post_code: "96180",
          },
          {
            subdistrict_th: "ตะปอเยาะ",
            subdistrict_en: "Tapoyo",
            post_code: "96180",
          },
        ],
      },
      {
        district_th: "อำเภอระแงะ",
        district_en: "Amphoe Ra-ngae",
        children: [
          {
            subdistrict_th: "ตันหยงมัส",
            subdistrict_en: "Tanyong Mat",
            post_code: "96130",
          },
          {
            subdistrict_th: "ตันหยงลิมอ",
            subdistrict_en: "Tanyong Limo",
            post_code: "96130",
          },
          {
            subdistrict_th: "บองอ",
            subdistrict_en: "Bo-ngo",
            post_code: "96220",
          },
          {
            subdistrict_th: "กาลิซา",
            subdistrict_en: "Kalisa",
            post_code: "96130",
          },
          {
            subdistrict_th: "บาโงสะโต",
            subdistrict_en: "Ba-ngo Sato",
            post_code: "96130",
          },
          {
            subdistrict_th: "เฉลิม",
            subdistrict_en: "Chaloem",
            post_code: "96130",
          },
          {
            subdistrict_th: "มะรือโบตก",
            subdistrict_en: "Marue Botok",
            post_code: "96130",
          },
        ],
      },
      {
        district_th: "อำเภอรือเสาะ",
        district_en: "Amphoe Rueso",
        children: [
          {
            subdistrict_th: "รือเสาะ",
            subdistrict_en: "Rueso",
            post_code: "96150",
          },
          {
            subdistrict_th: "สาวอ",
            subdistrict_en: "Sawo",
            post_code: "96150",
          },
          {
            subdistrict_th: "เรียง",
            subdistrict_en: "Riang",
            post_code: "96150",
          },
          {
            subdistrict_th: "สามัคคี",
            subdistrict_en: "Samakkhi",
            post_code: "96150",
          },
          {
            subdistrict_th: "บาตง",
            subdistrict_en: "Batong",
            post_code: "96150",
          },
          {
            subdistrict_th: "ลาโละ",
            subdistrict_en: "Lalo",
            post_code: "96150",
          },
          {
            subdistrict_th: "รือเสาะออก",
            subdistrict_en: "Rueso Ok",
            post_code: "96150",
          },
          {
            subdistrict_th: "โคกสะตอ",
            subdistrict_en: "Khok Sato",
            post_code: "96150",
          },
          {
            subdistrict_th: "สุวารี",
            subdistrict_en: "Suwari",
            post_code: "96150",
          },
        ],
      },
      {
        district_th: "อำเภอศรีสาคร",
        district_en: "Amphoe Si Sakhon",
        children: [
          {
            subdistrict_th: "ซากอ",
            subdistrict_en: "Sako",
            post_code: "96210",
          },
          {
            subdistrict_th: "ตะมะยูง",
            subdistrict_en: "Tamayung",
            post_code: "96210",
          },
          {
            subdistrict_th: "ศรีสาคร",
            subdistrict_en: "Si Sakhon",
            post_code: "96210",
          },
          {
            subdistrict_th: "เชิงคีรี",
            subdistrict_en: "Choeng Khiri",
            post_code: "96210",
          },
          {
            subdistrict_th: "กาหลง",
            subdistrict_en: "Kalong",
            post_code: "96210",
          },
          {
            subdistrict_th: "ศรีบรรพต",
            subdistrict_en: "Si Banphot",
            post_code: "96210",
          },
        ],
      },
      {
        district_th: "อำเภอแว้ง",
        district_en: "Amphoe Waeng",
        children: [
          {
            subdistrict_th: "แว้ง",
            subdistrict_en: "Waeng",
            post_code: "96160",
          },
          {
            subdistrict_th: "กายูคละ",
            subdistrict_en: "Kayu Khla",
            post_code: "96160",
          },
          {
            subdistrict_th: "ฆอเลาะ",
            subdistrict_en: "Kholo",
            post_code: "96160",
          },
          {
            subdistrict_th: "โละจูด",
            subdistrict_en: "Lochut",
            post_code: "96160",
          },
          {
            subdistrict_th: "แม่ดง",
            subdistrict_en: "Mae Dong",
            post_code: "96160",
          },
          {
            subdistrict_th: "เอราวัณ",
            subdistrict_en: "Erawan",
            post_code: "96160",
          },
        ],
      },
      {
        district_th: "อำเภอสุคิริน",
        district_en: "Amphoe Sukhirin",
        children: [
          {
            subdistrict_th: "มาโมง",
            subdistrict_en: "Mamong",
            post_code: "96190",
          },
          {
            subdistrict_th: "สุคิริน",
            subdistrict_en: "Sukhirin",
            post_code: "96190",
          },
          {
            subdistrict_th: "เกียร์",
            subdistrict_en: "Kia",
            post_code: "96190",
          },
          {
            subdistrict_th: "ภูเขาทอง",
            subdistrict_en: "Phukhao Thong",
            post_code: "96190",
          },
          {
            subdistrict_th: "ร่มไทร",
            subdistrict_en: "Rom Sai",
            post_code: "96190",
          },
        ],
      },
      {
        district_th: "อำเภอสุไหงโก-ลก",
        district_en: "Amphoe Su-ngai Kolok",
        children: [
          {
            subdistrict_th: "สุไหงโก-ลก",
            subdistrict_en: "Su-ngai Kolok",
            post_code: "96120",
          },
          {
            subdistrict_th: "ปาเสมัส",
            subdistrict_en: "Pasemat",
            post_code: "96120",
          },
          {
            subdistrict_th: "มูโนะ",
            subdistrict_en: "Muno",
            post_code: "96120",
          },
          {
            subdistrict_th: "ปูโยะ",
            subdistrict_en: "Puyo",
            post_code: "96120",
          },
        ],
      },
      {
        district_th: "อำเภอสุไหงปาดี",
        district_en: "Amphoe Su-ngai Padi",
        children: [
          {
            subdistrict_th: "ปะลุรู",
            subdistrict_en: "Paluru",
            post_code: "96140",
          },
          {
            subdistrict_th: "สุไหงปาดี",
            subdistrict_en: "Su-ngai Padi",
            post_code: "96140",
          },
          {
            subdistrict_th: "โต๊ะเด็ง",
            subdistrict_en: "Todeng",
            post_code: "96140",
          },
          {
            subdistrict_th: "สากอ",
            subdistrict_en: "Sako",
            post_code: "96140",
          },
          {
            subdistrict_th: "ริโก๋",
            subdistrict_en: "Riko",
            post_code: "96140",
          },
          {
            subdistrict_th: "กาวะ",
            subdistrict_en: "Kawa",
            post_code: "96140",
          },
        ],
      },
      {
        district_th: "อำเภอจะแนะ",
        district_en: "Amphoe Chanae",
        children: [
          {
            subdistrict_th: "จะแนะ",
            subdistrict_en: "Chanae",
            post_code: "96220",
          },
          {
            subdistrict_th: "ดุซงญอ",
            subdistrict_en: "Dusongyo",
            post_code: "96220",
          },
          {
            subdistrict_th: "ผดุงมาตร",
            subdistrict_en: "Phadung Mat",
            post_code: "96220",
          },
          {
            subdistrict_th: "ช้างเผือก",
            subdistrict_en: "Chang Phueak",
            post_code: "96220",
          },
        ],
      },
      {
        district_th: "อำเภอเจาะไอร้อง",
        district_en: "Amphoe Cho-airong",
        children: [
          {
            subdistrict_th: "จวบ",
            subdistrict_en: "Chuap",
            post_code: "96130",
          },
          {
            subdistrict_th: "บูกิต",
            subdistrict_en: "Bukit",
            post_code: "96130",
          },
          {
            subdistrict_th: "มะรือโบออก",
            subdistrict_en: "Maruebo Ok",
            post_code: "96130",
          },
        ],
      },
    ],
  },
];
