import { Input, Image, Button, Form, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { WEB_LOGO } from "../assets/icons";
import EMAIL from "../assets/icons/login/email";
import { validateMessages } from "../config/validateMessage";
import { FORGET_PASSWORD } from "../store/auth";
import { swalError, swalSuccess } from "../tools/swal";

export const ForgotPasswordPage = ({ ...props }) => {
  const { t } = useTranslation();
  const { isLoading, mutate, isSuccess } = FORGET_PASSWORD();
  // const [isSuccess] = useState<boolean>(false)

  const onFinish = (values: any) => {
    mutate(values, {
      onSuccess: () => {
        swalSuccess();
      },
      onError: (error: any) => {
        swalError(t, error.message);
      },
    });
  };

  return (
    <Row className="h-100" style={{ background: "#e1ded9" }}>
      <Col xs={2} md={4} xl={8}></Col>
      <Col
        xs={20}
        md={16}
        xl={8}
        className="text-center d-flex flex-column justify-center align-center"
      >
        <div className="w-100 h-60 d-flex align-end">
          {isSuccess ? (
            <p style={{ fontSize: 20 }} className="mt-5">
              {/* An email has neen sent that contains a link that you can click to
              reset your password. this link will expire in 1 hour(s). if you
              don't receive an email please check your spam folder or try again */}
              {t("forget-email-has-send")}
            </p>
          ) : (
            <Form
              validateMessages={validateMessages}
              onFinish={onFinish}
              className="forget-password"
            >
              <Form.Item
                className="relative"
                name="email"
                rules={[{ required: true, type: "email" }]}
              >
                <Input prefix={EMAIL()} placeholder={t("email")} />
              </Form.Item>
              <Form.Item className="mt-4 pt-2">
                <Button
                  htmlType="submit"
                  loading={isLoading}
                  className="button-submit"
                >
                  {t("submit")}
                </Button>
              </Form.Item>
            </Form>
          )}
        </div>
        <div className="w-100 h-40 pb-5 d-flex justify-center align-end">
          <Image
            src={WEB_LOGO}
            preview={false}
            width="80%"
            className="mb-5 px-3"
          />
        </div>
      </Col>
      <Col xs={2} md={4} xl={8}></Col>
    </Row>
  );
};
