import { Row, Col, Button, Image } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { PERCEL, REPAIRMAN, WEB_LOGO } from "../../../assets/icons";
import MobileHeader from "../../../components/page-header-mobile";
import {
  REPAIRMAN_INDEX_PATH,
  SCAN_QRCODE_PATH,
} from "../../router-mobile/path";

const AdminPage = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const onClickRepairman = () => {
    return push(REPAIRMAN_INDEX_PATH);
  };
  const onClickParcel = () => {
    return push(SCAN_QRCODE_PATH);
  };
  return (
    <div className="w-100 h-100">
      <MobileHeader isGoBack={true} />
      <Row className="h-80" align="middle">
        <Col span={24} className="d-grid justify-center">
          <div className="d-flex justify-center p-5">
            <Image src={WEB_LOGO} preview={false} />
          </div>
          <div className="d-flex justify-center px-3">
            <Button
              className="button-scan-mobile f-18"
              style={{ width: "100%", height: 60 }}
              onClick={onClickRepairman}
            >
              <Row gutter={12}>
                <Col span={1}></Col>
                <Col span={5} className="d-flex ">
                  <Image src={REPAIRMAN} width={45} preview={false} />
                </Col>
                <Col span={10} className="d-flex align-center justify-center">
                  {t("mobile.repairman")}
                </Col>
                <Col span={8}></Col>
              </Row>
            </Button>
          </div>
          <div className="d-flex justify-center px-3">
            <Button
              className="button-scan-mobile f-18 mt-4"
              style={{ width: "100%", height: 60 }}
              onClick={onClickParcel}
            >
              <Row gutter={12}>
                <Col span={1}></Col>
                <Col span={5} className="d-flex ">
                  <Image src={PERCEL} width={45} preview={false} />
                </Col>
                <Col span={10} className="d-flex align-center justify-center">
                  {t("mobile.parcel")}
                </Col>
                <Col span={8}></Col>
              </Row>
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AdminPage;
