import React from "react";

const SETTING = (props: React.SVGProps<SVGSVGElement> = {}) => {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141.73 141.73"
      {...props}
    >
      <path
        data-name="Icon material-settings"
        d="M121.1 77.48a46.68 46.68 0 000-13.25l14.23-11.15a3.41 3.41 0 00.81-4.33l-13.52-23.39a3.4 3.4 0 00-4.13-1.48l-16.83 6.76A49.34 49.34 0 0090.24 24L87.7 6.11a3.3 3.3 0 00-3.31-2.85h-27A3.3 3.3 0 0054 6.11L51.47 24a52.07 52.07 0 00-11.42 6.62L23.22 23.9a3.29 3.29 0 00-4.13 1.48L5.57 48.77a3.33 3.33 0 00.82 4.3l14.23 11.15a54.42 54.42 0 00-.47 6.62 54.44 54.44 0 00.47 6.63L6.39 88.65A3.4 3.4 0 005.58 93l13.52 23.36a3.38 3.38 0 004.12 1.49l16.83-6.76a49.36 49.36 0 0011.42 6.62L54 135.62a3.3 3.3 0 003.31 2.85h27a3.29 3.29 0 003.31-2.85l2.57-17.91a51.94 51.94 0 0011.43-6.63l16.83 6.76a3.27 3.27 0 004.12-1.49L136.17 93a3.4 3.4 0 00-.81-4.32l-14.23-11.19zm-50.23 17a23.66 23.66 0 1123.66-23.62 23.66 23.66 0 01-23.66 23.66z"
        fill="#b59d4e"
      />
    </svg>
  );
};

export default SETTING;
