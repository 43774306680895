import { CommentOutlined, FolderOpenOutlined } from "@ant-design/icons";
import { Button, Col, Empty, Form, Input, Modal, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import CustomSelect from "../../../components/custom-select";
import { fixed_hieght } from "../../../components/page-header";
import MobileHeader from "../../../components/page-header-mobile";
import ImageUpload from "../../../components/upload-image";
import { RepairmanCard } from "./repairman-index";
import SignatureCanvas from "react-signature-canvas";
import { dataURLtoFile } from "../../../tools/convert";
import { ClipLoader } from "react-spinners";
import ErrorPage from "../../../pages/page-error-mobile";
import { GET_REPAIRING_ID, PATCH_REPAIRING } from "../../../store/repairing";
import RepairingComment from "./repairman-comment";
import { swalError, swalSuccess } from "../../../tools/swal";
import { useQueryClient } from "react-query";
import { GET_CONTENT_ABOUT_US } from "../../../store";
import { getKeyByLocale } from "../../../tools/translation";

export const work = [
  { id: 1, name: "upload-picture", key: "UPLOAD" },
  { id: 2, name: "sign", key: "SIGN" },
];

const fixed_hieghts = 40;

const repairingStatus = [
  { id: 3, name: "processing", key: "PROCESSING" },
  { id: 4, name: "checking", key: "CHECKING" },
];

const RepairmanEdit = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [repairmanForm] = useForm();
  const client = useQueryClient();
  const { push } = useHistory();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalComment, setModalComment] = useState(false);
  const sigPad = React.useRef<any>({});
  const [signRepairman, setSignRepairman] = useState<boolean>(false);
  const { data, isError, isLoading } = GET_REPAIRING_ID(id);
  const content = GET_CONTENT_ABOUT_US({ slug: "REPAIRMAN" });
  const update = PATCH_REPAIRING();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const showModalComment = () => {
    setModalComment(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setModalComment(false);
  };

  useEffect(() => {
    initail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, repairmanForm, data]);

  const initail = () => {
    if (!data) {
      return;
    }
    const { status, detail, beforeImage, afterImage, repairmanSignImage } =
      data;
    repairmanForm.setFieldsValue({
      status: status,
      detail: detail,
      beforeImage: beforeImage?.imageUrl || undefined,
      afterImage: afterImage?.imageUrl || undefined,
      repairmanSignImage: repairmanSignImage?.imageUrl || undefined,
    });
  };

  if (isLoading) {
    return (
      <div className="w-100 background-mobile h-100">
        <MobileHeader />
        <Row className="px-3 pt-5-5 pb-3 w-100 h-100 display-flex justify-center align-center flex-column">
          <ClipLoader color="#b69e4f" />
        </Row>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="w-100 background-mobile h-100">
        <MobileHeader />
        <Row className="px-3 pt-5-5 pb-3 w-100 h-100 display-flex justify-center align-center flex-column">
          <ErrorPage />
        </Row>
      </div>
    );
  }

  if (!data) {
    return (
      <div className="w-100 background-mobile h-100">
        <MobileHeader />
        <Row className="px-3 pt-5-5 pb-3 w-100 h-100 display-flex justify-center align-center flex-column">
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            className="color-gold expty-color"
          />
        </Row>
      </div>
    );
  }

  const onFinish = (value: any) => {
    const { repairmanSignImage, beforeImage, afterImage, status } = value;
    let verification: File | undefined;

    if (sigPad?.current !== null && typeof sigPad?.current === "object") {
      if (Object.keys(sigPad?.current).length !== 0) {
        if (
          !sigPad?.current.isEmpty() &&
          sigPad?.current.isEmpty() !== undefined
        ) {
          const canvas = sigPad?.current.getCanvas().toDataURL();
          verification = dataURLtoFile(canvas, "singVerification.png");
        }
      }
    }

    if (repairmanSignImage) {
      if (repairmanSignImage.name) {
        verification = repairmanSignImage;
      }
    }

    const repairmans = data.repairmans.map((e) => e.user.id);

    const dataValue = {
      beforeImage: beforeImage,
      afterImage: afterImage,
      status,
      repairmanSignImage: verification,
      repairmans: repairmans,
    };

    update.mutate(
      { id, ...dataValue },
      {
        onError: (error: any) => {
          swalError(t, error.message);
        },
        onSuccess: () => {
          client.invalidateQueries("get-repairing-mobile");
          client.invalidateQueries(["get-repairing-id", id]);
          swalSuccess(t);
          push("/repairman");
        },
      }
    );
  };

  const MyComponent = () => {
    const dataContent = content?.data?.find((e: any) => e.slug === "REPAIRMAN");
    return (
      <p
        className="color-gold modal-color"
        dangerouslySetInnerHTML={{
          __html: getKeyByLocale({
            key: "content",
            data: dataContent,
            locale: i18n.language,
          }),
        }}
      />
    );
  };

  const onClear = () => {
    sigPad?.current.clear();
  };

  return (
    <div className="w-100 background-mobile">
      <MobileHeader />
      <div className="w-100">
        <Row className="px-3 pt-5-5 pb-3 ">
          <Form
            layout="vertical"
            form={repairmanForm}
            onFinish={onFinish}
            className="fixed-input-size w-100"
          >
            <Row className="w-100">
              <Col span={24}>
                <Form.Item>
                  <RepairmanCard
                    values={data}
                    showStatus={false}
                    isClassName={false}
                    isClick={false}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  name="detail"
                  label={t("details")}
                  className="disabled-textarea"
                >
                  <Input.TextArea disabled autoSize={true} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="beforeImage" label={t("image-before")}>
                  <ImageUpload />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="afterImage" label={t("image-after")}>
                  <ImageUpload />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="status"
                  label={t("status")}
                  className="repairman-disable"
                >
                  <CustomSelect
                    hasTranslation={true}
                    showSearch={false}
                    bordered={false}
                    placeholder={t("repairing-status")}
                    optionValue={{
                      values: repairingStatus || [],
                      key: "key",
                      name: "name",
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label={t("comment")}>
                  <Button
                    icon={<CommentOutlined className="f-24" />}
                    className="button-note f-18"
                    style={{ width: "100%", height: fixed_hieght }}
                    onClick={() => {
                      showModalComment();
                    }}
                  >
                    {t("comment")}
                  </Button>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label={t("mobile.document")}>
                  <Button
                    icon={<FolderOpenOutlined className="f-24" />}
                    className="button-document f-18"
                    style={{ width: "100%", height: fixed_hieght }}
                    onClick={() => {
                      showModal();
                    }}
                  >
                    {t("mobile.document")}
                  </Button>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  shouldUpdate={true}
                  className="custom-select-ant mt-2"
                  label={
                    <Row>
                      <Col span={24}>{t("repairman-sign")}</Col>
                      <Row className="mb-2">
                        <Col
                          onClick={() => {
                            setSignRepairman(false);
                            repairmanForm.setFieldsValue({
                              repairmanSignImage: undefined,
                            });
                          }}
                          className="pr-1"
                        >
                          <div
                            className={`button-upload ${
                              !signRepairman ? "button-upload-active" : ""
                            }`}
                          >
                            <span>{t("upload-picture")}</span>
                          </div>
                        </Col>
                        <Col
                          onClick={() => {
                            setSignRepairman(true);
                            repairmanForm.setFieldsValue({
                              repairmanSignImage: undefined,
                            });
                          }}
                          className="pr-1"
                        >
                          <div
                            className={`button-signature ${
                              signRepairman ? "button-signature-active" : ""
                            }`}
                          >
                            <span>{t("sign")}</span>
                          </div>
                        </Col>
                        {signRepairman && (
                          <Col
                            onClick={() => {
                              onClear();
                            }}
                          >
                            <div className="button-clear-web">
                              <span>{t("clear")}</span>
                            </div>
                          </Col>
                        )}
                      </Row>
                    </Row>
                  }
                >
                  {signRepairman && (
                    <>
                      <Form.Item name="repairmanSignImage">
                        <SignatureCanvas
                          canvasProps={{
                            className: "sigContainer",
                          }}
                          ref={sigPad}
                        />
                      </Form.Item>
                    </>
                  )}
                  {!signRepairman && (
                    <Form.Item name="repairmanSignImage">
                      <ImageUpload />
                    </Form.Item>
                  )}
                </Form.Item>
              </Col>

              <Col span={24} className="mt-4 d-flex justify-center">
                <Form.Item>
                  <Button
                    className="button-submit f-18"
                    style={{ width: 155, height: fixed_hieghts }}
                    htmlType="submit"
                  >
                    {t("submit")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Row>
      </div>
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
        width={800}
        centered
      >
        <span className="mt-3">
          <MyComponent />
        </span>
      </Modal>
      <Modal
        visible={modalComment}
        onCancel={handleCancel}
        footer={null}
        maskClosable={false}
        width={800}
        centered
      >
        <span className="mt-3">
          <RepairingComment id={id} i18n={i18n} modalComment={modalComment} />
        </span>
      </Modal>
    </div>
  );
};

export default RepairmanEdit;
