import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import CustomStatusBackground from "../../components/custom-status";
import CustomTable from "../../components/custom-table";
import PageFilter, { IPageFilterChoice } from "../../components/page-filter";
import PageHeader, { ISearchInput } from "../../components/page-header";
import { PAYMENT_CREATE_PATH, PAYMENT_LIST_PATH } from "../../router/path";
import { page_params, select_rows_atom } from "../../store";
import { GET_BILL_TYPE } from "../../store/bill";
import { useNotification } from "../../store/notification";
import {
  BULK_DELETE_PAYMENTS,
  DELETE_PAYMENT,
  EXPORT_INVOICE_PAYMENT,
  EXPORT_PAYMENT,
  EXPORT_PAYMENT_TEMPLATE,
  EXPORT_RECEIPT_PAYMENT,
  GET_PAYMENTS,
  IMPORT_PAYMENT,
  LINE_NOTIFICATION_PAYMENT,
  PAID_PAYMENT,
} from "../../store/payment";
import { GET_UNIT } from "../../store/units";
import { parse } from "../../tools/datetime";
import { swalDelete, swalError, swalSuccess } from "../../tools/swal";
import ModalImportExcel, {
  TModalRef,
} from "../../components/modal-import-excel";
import numeral from "numeral";
import { downloadFile } from "../../tools/files";
// import file from

const Payment = () => {
  const { t, i18n } = useTranslation();
  const [gSelectRows, sSelectRows] = useRecoilState(select_rows_atom);
  const client = useQueryClient();
  const history = useHistory();
  const [params, setParams] = useRecoilState(page_params);
  const { data, refetch, isLoading } = GET_PAYMENTS({
    ...params,
  });
  const { mutate } = DELETE_PAYMENT();
  const bulkDelete = BULK_DELETE_PAYMENTS();
  const paid = PAID_PAYMENT();
  const billType = GET_BILL_TYPE({ pagination: false, status: "ACTIVE" });
  const units = GET_UNIT({ pagination: false, status: "ACTIVE" });
  const exportExcel = EXPORT_PAYMENT();
  const lineNotification = LINE_NOTIFICATION_PAYMENT();
  const importExcel = IMPORT_PAYMENT();
  const exportInvoice = EXPORT_INVOICE_PAYMENT();
  const exportReceipt = EXPORT_RECEIPT_PAYMENT();
  const exportTmplate = EXPORT_PAYMENT_TEMPLATE();

  const { tableNoti, dispatch } = useNotification();

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableNoti]);

  useEffect(() => {
    return () =>
      dispatch!({
        type: "table",
        action: "clear",
        key: ["payment"],
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchInput: ISearchInput[] = [
    {
      label: t("search"),
      key: "search",
      span: 10,
      type: "input",
      props: {
        placeholder: t("bill-number"),
      },
    },
    {
      label: t("bill-type"),
      key: "billType",
      span: 7,
      type: "select",
      props: {
        hasTranslation: true,
        placeholder: t("all"),
        optionValue: {
          values:
            billType.data?.data.map((item) => {
              const name = i18n.language === "en" ? item.nameEn : item.nameTh;
              return { id: item.id, name, key: item.id };
            }) || [],
          key: "key",
          name: "name",
        },
      },
    },
    {
      label: t("house-number"), //unit number to use, house number to show
      key: "unit",
      span: 7,
      type: "select",
      props: {
        hasTranslation: true,
        placeholder: t("all"),
        optionValue: {
          values:
            units.data?.data.map((item) => {
              return { id: item.id, name: item.unitNumber, key: item.id };
            }) || [],
          key: "key",
          name: "name",
        },
      },
    },
    {
      label: t("creation-date"),
      key: "date",
      span: 7,
      type: "range-picker",
      props: {
        placeholder: [t("start-date"), t("end-date")],
        getPopupContainer: (props: HTMLElement) => {
          return props;
        },
      },
    },
  ];
  const choices: IPageFilterChoice[] = [
    {
      label: t("all"),
      total: data?.countData.countAll || 0,
    },
    {
      label: t("pending"),
      key: "PENDING",
      total: data?.countData.countPending || 0,
    },
    // {
    //   label: t("overdue"),
    //   key: "OVERDUE",
    //   total: data?.countData.countOverdue || 0,
    // },
    // {
    //   label: t("waiting"),
    //   key: "NOT_YET_PAID",
    //   total: data?.countData.countNotYetPaid || 0,
    // },
    {
      label: t("complete"),
      key: "PAID",
      total: data?.countData.countPaid || 0,
    },
    {
      label: t("cancel"),
      key: "CANCEL",
      total: data?.countData.countCancel || 0,
    },
  ];
  const columns: ColumnsType = [
    {
      dataIndex: "unit",
      title: t("house-number"), //unit number to use, house number to show
      render: (text) => text?.unitNumber || "-",
    },
    {
      dataIndex: "billNumber",
      title: t("invoice"),
      render: (text) => text || "-",
    },
    {
      dataIndex: "createdAt",
      title: t("billing-date"),
      render: (text) =>
        text ? parse(text, i18n.language, "DD MMM YYYY") : "-",
    },
    {
      dataIndex: "billType",
      title: t("bill-type"),
      render: (text) => {
        const billType =
          i18n.language === "en"
            ? text?.nameEn
              ? text?.nameEn
              : "-"
            : text?.nameTh
            ? text?.nameTh
            : "-";
        return billType || "-";
      },
    },
    {
      dataIndex: "amount",
      title: t("amount"),
      render: (text) => numeral(text).format("0,0.00") || 0,
    },
    {
      dataIndex: "status",
      title: t("status"),
      render: (text) => {
        const prev = text.toLowerCase().split("_");
        let next = "";
        for (const item of prev) {
          const dash = prev[prev.length - 1] === item ? "" : "-";
          next = next + item + dash;
          next = next === "not-yet-paid" ? "waiting" : next;
          next = next === "paid" ? "complete" : next;
        }
        const className =
          text === "PAID"
            ? "frame-background-paid"
            : text === "CANCEL"
            ? "frame-background-cancel"
            : text === "PENDING"
            ? "frame-background-pending"
            : "";

        const status =
          text === "PAID"
            ? "complete"
            : text === "CANCEL"
            ? "cancel"
            : text === "PENDING"
            ? "pending"
            : "-";
        return (
          <>
            <CustomStatusBackground lable={t(status)} className={className} />
          </>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    if (values.date)
      values = {
        ...values,
        startDate: dayjs(values.date[0]).format("YYYY-MM-DD 00:00"),
        endDate: dayjs(values.date[1]).format("YYYY-MM-DD 23:59"),
      };
    delete values.date;
    setParams({ ...params, ...values });
  };

  const onFilter = (choice: IPageFilterChoice) => {
    const status = choice.key;
    setParams({ ...params, status });
  };

  const onDelete = async (id: number) => {
    const confirm = await swalDelete(t);
    if (confirm) {
      mutate(
        { id },
        {
          onError: (err: any) => {
            swalError(t, err.message);
          },
          onSuccess: () => {
            swalSuccess(t);
            refetch();
            if (params.page > 1 && data?.data.length === 1) {
              return setParams({ ...params, page: params.page - 1 });
            }
          },
        }
      );
    }
  };

  const onPaid = () => {
    const ids = gSelectRows.flatMap((item) => item.data);
    paid.mutate(
      { ids },
      {
        onError: (err: any) => {
          swalError(t, err);
        },
        onSuccess: () => {
          client.invalidateQueries("get-payments");
          sSelectRows([]);
          swalSuccess(t);
        },
      }
    );
  };

  const _getCurrentFilter = (): string | undefined => {
    const { status } = params;
    if (status) return status;
    return undefined;
  };

  const onReset = () => {
    const values = {
      startDate: undefined,
      endDate: undefined,
    };
    setParams({ ...params, ...values });
  };

  const onBulkDeleteSuccess = (length: number) => {
    if (length === data?.data.length && params.page > 1) {
      setParams({ ...params, page: params.page - 1 });
    }
  };

  const transformJson = (value: any[]): any[] => {
    return value
      .map((item) => {
        if (!(Number(item.number) >= 0)) return undefined;
        const paymentDueDate = item.paymentDueDate
          ? dayjs(item.paymentDueDate).format("YYYY-MM-DD")
          : undefined;
        const amount = Number(item.amount).toFixed(2);
        const totalPrice = Number(item.totalPrice).toFixed(2);
        return { ...item, paymentDueDate, amount, totalPrice };
      })
      .filter((e) => e);
  };

  const importRef = useRef<TModalRef>(null);

  return (
    <div>
      <ModalImportExcel
        width="75vw"
        transformJson={transformJson}
        mutation={importExcel}
        ref={importRef}
        payment
        headerKey={[
          "number",
          "unitNumber",
          "billType",
          "paymentDueDate",
          "paymentType",
          "note",
          "list",
          "quantity",
          "price",
          "totalPrice",
          "amount",
          "paymentReference",
          "status",
          "invoice",
        ]}
        range={{
          rowStart: 1,
          colStart: 0,
          colEnd: 13,
        }}
        tableProps={{
          columns: [
            {
              title: "#",
              dataIndex: "number",
              width: 20,
            },
            {
              dataIndex: "unitNumber",
              title: t("house-number"), //unit number to use, house number to show
            },
            {
              dataIndex: "billType",
              title: t("bill-type"),
            },
            {
              dataIndex: "paymentDueDate",
              title: t("payment-due-date"),
            },
            {
              dataIndex: "paymentType",
              title: t("payment-type"),
            },
            {
              dataIndex: "note",
              title: t("note"),
              render: (text) => {
                return <div className="line-clamp">{text}</div>;
              },
            },
            {
              dataIndex: "list",
              title: `${t("list")} ID`,
            },
            {
              dataIndex: "quantity",
              title: t("quantity"),
            },
            {
              dataIndex: "price",
              title: t("price"),
            },
            {
              dataIndex: "totalPrice",
              title: t("total-price"),
              // render: (text) => {
              //   const value = Number(text).toFixed(2);

              //   return <div>{value}</div>;
              // },
            },
            {
              dataIndex: "amount",
              title: t("total"),
              // render: (text) => {
              //   const value = Number(text).toFixed(2);
              //   return <div>{value}</div>;
              // },
            },
            {
              dataIndex: "paymentReference",
              title: t("Ref2"),
            },
            {
              dataIndex: "status",
              title: t("status"),
            },
            {
              dataIndex: "invoice",
              title: t("invoice"),
            },
          ],
        }}
      />
      <PageHeader
        menu="payment"
        onSearch={onSearch}
        searchInput={searchInput}
        label={"create"}
        utility={["payment", "line", "delete"]}
        bulkDelete={bulkDelete}
        onBulkDeleteSuccess={onBulkDeleteSuccess}
        path={PAYMENT_CREATE_PATH}
        invalidateQueries={["get-payments"]}
        // exportExcel={exportExcel}
        topicExport={t("menu.payment")}
        onReset={onReset}
        lineNotification={lineNotification}
        onPaymentListClick={() => history.push(PAYMENT_LIST_PATH)}
        pdfOptions={{
          exportInvoice: exportInvoice,
          exportReceipt: exportReceipt,
        }}
        excelOptions={{
          import: () => {
            importRef?.current?.triggerVisible();
          },
          export: exportExcel,
          template: () =>
            exportTmplate.mutate(
              {},
              {
                onSuccess: (data: any) => {
                  downloadFile(data, "xlsx", "Template payment");
                },
                onError: (error: any) => {
                  swalError(t, error.message);
                },
              }
            ),
        }}
      />
      <PageFilter
        choices={choices}
        current={_getCurrentFilter()}
        onClick={onFilter}
        paymentAction={onPaid}
        isLoading={paid.isLoading}
      />
      <CustomTable
        rowKey="id"
        title={t("payment-list")}
        dataSource={data?.data || []}
        columns={columns}
        onDelete={onDelete}
        pagination={{
          limit: params.limit,
          page: params.page,
          total: data?.count || 0,
        }}
        onPageChange={(page, limit) => setParams({ ...params, page, limit })}
        isLoadingTable={isLoading}
        rowClassName={(record, index) => {
          if (tableNoti["payment"]?.some((e) => e === record.id)) {
            return "table-row-pink";
          }
        }}
      />
    </div>
  );
};

export default Payment;
