import { IPageParams, IResponse } from "./global";
import { IUser } from "./user";
import { exportFile, throwResponse } from "./../config/axios";
import axios from "axios";
import { useMutation, UseMutationResult, useQuery } from "react-query";
import { UseQueryResult } from "react-query";
import { IFacility } from "./facility";
import { getClientTimeZone } from "../tools/datetime";

export type TFacilityBooking = "PENDING" | "COMPLETE" | "CANCEL";

export interface IFacilityBooking {
  id: number;
  createdAt: string;
  updatedAt: string;
  bookingDate: string;
  status: TFacilityBooking;
  numberOfParticipants: number;
  unit: {
    id: number;
    unitNumber: string;
    status: "ACTIVE" | "INACTIVE";
    floor?: number;
  };
  bookingPerson: IUser;
  facilityCategory: {
    id: number;
    status: "ACTIVE" | "INACTIVE";
  };
  facility: IFacility;
  endOfBookingDate: string;
  phoneNumber: string;
}

export interface IBookingAvailable {
  id: number;
  timeAvailable: string;
  numberOfParticipants: number;
  availableForBooking: number;
  capacity: number;
  isFull: boolean;
  maximumBookingHour: number;
}

export interface IFacilityBookingParams extends Omit<IPageParams, "total"> {
  search?: string;
  unit?: number;
  startDate?: any;
  endDate?: any;
  status?: string;
  bookingStartDate?: string;
  bookingEndDate?: string;
}

export const NOTIFY_BOOKING = (): UseMutationResult => {
  return useMutation(async (values) => {
    const res = await axios.post(
      `/api/line-notification/send-message-facility-booking`,
      values
    );
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const EXPORT_BOOKING = (): UseMutationResult => {
  return useMutation(async (values: any) => {
    const timeZone = getClientTimeZone();
    const res = await exportFile.post(`/api/export-excel/facility-booking`, {
      ...values,
      timeZone,
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const GET_FACILITY_BOOKING = (
  params: IFacilityBookingParams
): UseQueryResult<IResponse<IFacilityBooking[]>> => {
  return useQuery(
    ["get-facility-booking", params],
    async () => {
      const res = await axios.get("/api/facility-booking", { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const GET_FACILITY_BOOKING_BY_ID = (
  id?: string
): UseQueryResult<IFacilityBooking> => {
  return useQuery(["get-facility-booking-by-id", id], async () => {
    if (!id) return;
    const res = await axios.get(`/api/facility-booking/${id}`);
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

export const POST_FACILITY_BOOKING = (): UseMutationResult => {
  return useMutation(async (values) => {
    const res = await axios.post("/api/facility-booking", values);
    if (res.status === 201) {
      return;
    }
    throwResponse(res);
  });
};

export const PATCH_FACILITY_BOOKING = (): UseMutationResult => {
  return useMutation(async ({ id, ...rest }: any) => {
    const res = await axios.patch(`/api/facility-booking/${id}`, rest);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const DELETE_FACILITY_BOOKING = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/api/facility-booking/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const BULK_DELETE_FACILITY_BOOKING = (): UseMutationResult => {
  return useMutation(async ({ ids }: any) => {
    const res = await axios.delete("/api/facility-booking/bulk", {
      data: { ids },
    });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const FIND_BOOKING_AVAILABLE = (): UseMutationResult<
  IBookingAvailable[]
> => {
  return useMutation(async (params: any) => {
    const res = await axios.get("/api/facility-booking/get-available-time", {
      params,
    });
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
  });
};
