import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import CustomStatusBackground from "../../../components/custom-status";
import CustomTable from "../../../components/custom-table";
import PageFilter, { IPageFilterChoice } from "../../../components/page-filter";
import PageHeader, { ISearchInput } from "../../../components/page-header";
import { SETTING_REPAIRING_ITEM_CREATE_PATH } from "../../../router/path";
import { page_params } from "../../../store";
import {
  BULK_DELETE_REPAIRING_ITEM,
  DELETE_REPAIRING_ITEM,
  GET_REPAIRING_ITEM,
} from "../../../store/repairing-item";
import { parse } from "../../../tools/datetime";
import { swalDelete, swalError, swalSuccess } from "../../../tools/swal";

const RepairingItemPage = () => {
  const { i18n, t } = useTranslation();
  const [params, setParams] = useRecoilState(page_params);
  const { data, refetch, isLoading } = GET_REPAIRING_ITEM(params);
  const { mutate } = DELETE_REPAIRING_ITEM();
  const bulkDelete = BULK_DELETE_REPAIRING_ITEM();

  const searchInput: ISearchInput[] = [
    {
      label: t("search"),
      key: "search",
      span: 10,
      type: "input",
      props: {
        placeholder: t("search-name-EN-TH-create-by"),
      },
    },
    {
      label: t("creation-date"),
      key: "date",
      span: 7,
      type: "range-picker",
      props: {
        placeholder: [t("start-date"), t("end-date")],
        getPopupContainer: (props: HTMLElement) => {
          return props;
        },
      },
    },
  ];

  const choices: IPageFilterChoice[] = [
    {
      label: t("all"),
      total: data?.countData.countAll || 0,
    },
    {
      label: t("active"),
      key: "ACTIVE",
      total: data?.countData.countActive || 0,
    },
    {
      label: t("inactive"),
      key: "INACTIVE",
      total: data?.countData.countInActive || 0,
    },
  ];

  const columns: ColumnsType = [
    {
      dataIndex: i18n.language === "en" ? "nameEn" : "nameTh",
      title: t("name"),
      render: (text, __) => {
        return <div className="line-clamp">{text || "-"}</div>;
      },
    },
    {
      dataIndex: "updatedAt",
      title: t("update"),
      render: (text) => (
        <>{text ? parse(text, i18n.language, "DD MMM YYYY") : "-"}</>
      ),
    },
    {
      dataIndex: "createdBy",
      title: t("created-by"),
      render: (text, __) => {
        return (
          <p style={{ whiteSpace: "pre", display: "contents" }}>
            {text || "-"}
          </p>
        );
      },
    },
    // {
    //   dataIndex: "repairingZone",
    //   title: t("menu.setting-repairing-zone"),
    //   width: "11%",
    //   render: (text) => {
    //     return (
    //       <CustomStatusBackground
    //         lable={i18n.language === "en" ? text.nameEn : text.nameTh}
    //         className={"frame-default-background line-clamp"}
    //       />
    //     );
    //   },
    // },
    {
      dataIndex: "repairingCategory",
      title: t("menu.setting-repairing-category"),
      width: "11%",
      render: (text) => {
        return (
          <CustomStatusBackground
            lable={
              i18n.language === "en" ? text?.nameEn || "-" : text?.nameTh || "-"
            }
            className={"frame-default-background-overflow"}
          />
        );
      },
    },
    {
      dataIndex: "status",
      title: t("status"),
      width: "15%",
      render: (text, record) => {
        return (
          <>
            {!text && "-"}
            {text === "INACTIVE" && (
              <CustomStatusBackground
                lable={t("inactive")}
                className={"frame-no-background"}
              />
            )}
            {text === "ACTIVE" && (
              <CustomStatusBackground
                lable={t("active")}
                className={"frame-background"}
              />
            )}
          </>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    if (values.date) {
      const startDate = parse(values.date[0], "en", "YYYY-MM-DD 00:00");
      const endDate = parse(values.date[1], "en", "YYYY-MM-DD 23:59");
      values = { ...values, startDate, endDate };
    }
    delete values.date;
    setParams({ ...params, ...values });
  };

  const onFilter = (choice: IPageFilterChoice) => {
    setParams({ ...params, status: choice.key });
  };

  const onDelete = async (id: number) => {
    const confirm = await swalDelete(t);
    if (confirm) {
      mutate(
        { id },
        {
          onError: (err: any) => {
            swalError(t, t(err.message));
          },
          onSuccess: () => {
            if (params.page > 1 && data?.data.length === 1) {
              setParams({ ...params, page: params.page - 1 });
            }

            swalSuccess(t);
            refetch();
          },
        }
      );
    }
  };

  const onReset = () => {
    const values = { startDate: undefined, endDate: undefined };
    setParams({
      ...params,
      ...values,
    });
  };

  const _getCurrentFilter = (): string | undefined => {
    const { status } = params;
    if (status) return status;
    return undefined;
  };

  return (
    <div>
      <PageHeader
        menu="setting-repairing-item"
        onSearch={onSearch}
        searchInput={searchInput}
        utility={["delete"]}
        label="create"
        bulkDelete={bulkDelete}
        invalidateQueries={["repairing-item"]}
        onReset={onReset}
        path={SETTING_REPAIRING_ITEM_CREATE_PATH}
      />
      <PageFilter
        choices={choices}
        onClick={onFilter}
        current={_getCurrentFilter()}
      />
      <CustomTable
        title={t("repairing-item-lists")}
        dataSource={data?.data || []}
        columns={columns}
        pagination={{
          limit: params.limit,
          page: params.page,
          total: data?.count || 0,
        }}
        onDelete={onDelete}
        onPageChange={(page, limit) => setParams({ ...params, page, limit })}
        isLoadingTable={isLoading}
      />
    </div>
  );
};

export default RepairingItemPage;
