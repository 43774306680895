import { Col, Form, Row, FormInstance, Divider } from "antd";
import numeral from "numeral";
import { useTranslation } from "react-i18next";
import {
  DASHBOARD_USER_LEFT,
  DASHBOARD_USER_MIDDLE,
  DASHBOARD_USER_RIGHT,
} from "../../../store/dashboard";
import {
  appendStartAndEndOfTime,
  DBHeader,
  ErrorPage,
  LoadingPage,
} from "../header";

const userLeft = "userLeft";
const userMiddle = "userMiddle";
const userRight = "userRight";

const UserSection = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-white p-4">
      <p className="mb-4 f-24 bold">{t(`menu.user`)}</p>
      <Row gutter={12}>
        <Col span={8}>
          <div className="p-3 db-border h-100">
            <DBHeader
              selectType="range"
              label={t("room")}
              formItemName={userLeft}
            />
            <Form.Item
              shouldUpdate={(prev, next) => prev[userLeft] !== next[userLeft]}
              noStyle
            >
              {(form: FormInstance) => {
                const value = form.getFieldValue(userLeft);
                return <UserLeft value={value} />;
              }}
            </Form.Item>
          </div>
        </Col>
        <Col span={8}>
          <div className="p-3 db-border h-100">
            <DBHeader
              selectType="range"
              label={t("admin")}
              formItemName={userMiddle}
            />
            <Form.Item
              shouldUpdate={(prev, next) =>
                prev[userMiddle] !== next[userMiddle]
              }
              noStyle
            >
              {(form: FormInstance) => {
                const value = form.getFieldValue(userMiddle);
                return <UserMiddle value={value} />;
              }}
            </Form.Item>
          </div>
        </Col>
        <Col span={8}>
          <div className="p-3 db-border h-100">
            <DBHeader
              selectType="range"
              label={t("resident")}
              formItemName={userRight}
            />
            <Form.Item
              shouldUpdate={(prev, next) => prev[userRight] !== next[userRight]}
              noStyle
            >
              {(form: FormInstance) => {
                const value = form.getFieldValue(userRight);
                return <UserRight value={value} />;
              }}
            </Form.Item>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const UserLeft = ({ value }: { value: any }) => {
  const params = {
    ...appendStartAndEndOfTime(value),
    useDate: value ? true : false,
  };
  const { data, isLoading, isError, error } = DASHBOARD_USER_LEFT(params);
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div style={{ height: 150 }}>
        <LoadingPage />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div style={{ height: 150 }}>
        <ErrorPage error={error} />
      </div>
    );
  }

  return (
    <div style={{ height: 150 }} className="c-container flex-column">
      <span className="f-24">{numeral(data.all).format("0,0")}</span>
      <span className="f-20">{t("room")}</span>
    </div>
  );
};

const UserMiddle = ({ value }: { value: any }) => {
  const params = {
    ...appendStartAndEndOfTime(value),
    useDate: value ? true : false,
  };
  const { data, isLoading, isError, error } = DASHBOARD_USER_MIDDLE(params);
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div style={{ height: 150 }}>
        <LoadingPage />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div style={{ height: 150 }}>
        <ErrorPage error={error} />
      </div>
    );
  }

  return (
    <div style={{ height: 150 }} className="c-container flex-column">
      <span className="f-24">{numeral(data.all).format("0,0")}</span>
      <span className="f-20">{t("people")}</span>
    </div>
  );
};

const UserRight = ({ value }: { value: any }) => {
  const params = {
    ...appendStartAndEndOfTime(value),
    useDate: value ? true : false,
  };
  const { data, isLoading, isError, error } = DASHBOARD_USER_RIGHT(params);
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <div style={{ height: 150 }}>
        <LoadingPage />
      </div>
    );
  }

  if (isError || !data) {
    return (
      <div style={{ height: 150 }}>
        <ErrorPage error={error} />
      </div>
    );
  }

  return (
    <div style={{ height: 150 }}>
      <Row className="h-100 p-3">
        <Col span={11} className="c-container flex-column">
          <span className="f-16">{t("registered")}</span>
          <span className="f-24">{numeral(data.active).format("0,0")}</span>
          <span className="f-16">{t("people")}</span>
        </Col>
        <Col span={2}>
          <Divider type="vertical" className="h-100" />
        </Col>
        <Col span={11} className="c-container flex-column">
          <span className="f-16">{t("not-registered")}</span>
          <span className="f-24">{numeral(data.inActive).format("0,0")}</span>
          <span className="f-16">{t("people")}</span>
        </Col>
      </Row>
    </div>
  );
};

export default UserSection;
