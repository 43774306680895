import {
  Pagination,
  Table,
  Row,
  Col,
  Button,
  Image,
  Spin,
  ConfigProvider,
} from "antd";
import { ColumnsType, ColumnType } from "antd/lib/table";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { useEffect } from "react";
import { TableRowSelection } from "antd/lib/table/interface";
import {
  entireSelection,
  IPageParams,
  page_params,
  select_rows_atom,
} from "../store/global";
import { fixed_hieght } from "./page-header";
import loadding from "../assets/images/loding.gif";
import { RowClassName } from "rc-table/lib/interface";
import enUS from "antd/lib/locale/en_US";
import thTH from "antd/lib/locale/th_TH";
import { useNotification } from "../store/notification";

type TAction = "edit" | "delete";
// type TUser = "user" | "resident";

const CustomTable = ({
  columns,
  dataSource,
  rowKey = "id",
  title,
  hasIndex = true,
  actions = ["edit", "delete"],
  actionKey = "id",
  pagination,
  isLoading,
  onDelete,
  onPageChange,
  parcelAction,
  editParcel,
  editMember,
  editUnit,
  disabledSelect = false,
  isLoadingTable = false,
  onEditRole,
  disablePagination = false,
  rowClassName,
  noti = "",
}: {
  columns: ColumnsType<any>;
  dataSource: any[];
  rowKey?: string;
  title?: string;
  hasIndex?: boolean;
  actions?: TAction[];
  actionKey?: string;
  pagination?: IPageParams;
  isLoading?: boolean;
  onDelete?: (id: any) => void;
  onPageChange?: (page: number, limit: any) => void;
  parcelAction?: () => void;
  editParcel?: (id: any) => void;
  editMember?: (id: any) => void;
  editUnit?: (id: any) => void;
  disabledSelect?: boolean;
  isLoadingTable?: boolean;
  onEditRole?: (value: any) => void;
  disablePagination?: boolean;
  rowClassName?: (
    record: any,
    index: number
  ) => RowClassName<any> | string | any;
  noti?: string;
}) => {
  const [gSelectRows, sSelectRows] = useRecoilState(select_rows_atom);
  const [gParams, sParams] = useRecoilState(page_params);
  const { i18n, t } = useTranslation();
  const { pathname } = useLocation();
  const { push } = useHistory();
  const { headerNoti } = useNotification();

  useEffect(() => {
    sSelectRows([]);
    sParams({ limit: 10, page: 1, total: 0 });
    // idNew();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (!dataSource.length) {
      if (gParams.page > 1 && !isLoadingTable)
        sParams({ ...gParams, page: gParams.page - 1 });
    }
    // idNew();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  const onEdit = (id: any, value: any) => {
    if (onEditRole) return onEditRole(value);
    if (editParcel) return editParcel(id);
    if (editMember) return editMember(id);
    if (editUnit) return editUnit(id);
    return push(`${pathname}/${id}`);
  };

  const actionProps = {
    delete: {
      icon: <DeleteFilled />,
      func: (id: any) => {
        if (onDelete) {
          onDelete(id);
        }
      },
    },
    edit: {
      icon: <EditFilled />,
      func: onEdit,
    },
  };

  const _getHasPagination = (): boolean => {
    if (!pagination) return false;
    const { total, limit } = pagination;
    return Math.ceil(total / limit) > 1;
  };

  const _hasPagination = _getHasPagination();

  const countUser = headerNoti["user-management-admin"];
  const countMember = headerNoti["user-management-resident"];

  const _title = (
    <Row align="middle" justify="space-between">
      <Col>
        <Row>
          <span className="f-16 font-bold mr-7">{title}</span>
          {countUser && countUser.length > 0 && noti === "user" && (
            <span className="sider-new-count">{countUser.length}</span>
          )}
          {countMember && countMember.length > 0 && noti === "resident" && (
            <span className="sider-new-count">{countMember.length}</span>
          )}
        </Row>
      </Col>
      <Col className="d-flex align-center">
        {!parcelAction && (
          <span className="mr-3 color-gold">
            <i>{t("total")}</i> : {pagination?.total} {t("item")}
          </span>
        )}
        {!parcelAction && pagination && !disablePagination && (
          <ConfigProvider locale={i18n.language === "en" ? enUS : thTH}>
            <Pagination
              onChange={onPageChange}
              current={pagination.page}
              total={pagination.total}
              pageSize={pagination.limit}
              showSizeChanger
            />
          </ConfigProvider>
        )}
        {parcelAction && (
          <Button
            className="button-save f-18"
            style={{ width: 180, height: fixed_hieght }}
            onClick={parcelAction}
            loading={isLoading}
          >
            {t("save")}
          </Button>
        )}
      </Col>
    </Row>
  );

  const _footer = (
    <Row justify="end">
      <Col>
        <ConfigProvider locale={i18n.language === "en" ? enUS : thTH}>
          {pagination && _hasPagination && (
            <Pagination
              onChange={onPageChange}
              current={pagination.page}
              total={pagination.total}
              pageSize={pagination.limit}
              showSizeChanger
            />
          )}
        </ConfigProvider>
      </Col>
    </Row>
  );

  const getActions = (_actions: TAction[]): ColumnType<any> => {
    return {
      title: t("manage"),
      render: (_, record) => {
        const uniqueKey = record[actionKey];
        if (!uniqueKey) return <></>;
        return (
          <div className="d-flex">
            {_actions.map((item, index) => {
              return (
                <div
                  className={`table-action-button`}
                  onClick={() => {
                    actionProps[item].func(uniqueKey, _);
                  }}
                  key={index}
                >
                  {actionProps[item].icon || ""}
                </div>
              );
            })}
          </div>
        );
      },
    };
  };

  const getColumns = (_columns: any): ColumnsType<any> => {
    if (hasIndex) {
      _columns = [
        {
          title: "#",
          render: (_: any, __: any, index: number) => {
            if (!pagination) return index + 1;
            return (pagination.page - 1) * pagination.limit + (index + 1);
          },
        },
        ..._columns,
      ];
    }
    if (actions.length) {
      const _actions = getActions(actions);
      _columns = [..._columns, _actions];
    }
    return _columns.map((item: any) => {
      if (item.dataIndex === "status") {
        return { ...item, width: 150 };
      }
      if (item.dataIndex === "headerEn" || item.dataIndex === "headerTh") {
        return { ...item, width: 350 };
      }
      if (item.dataIndex === "nameEn" || item.dataIndex === "nameTh") {
        const width = item.width ? item.width : 350;
        return { ...item, width };
      }

      return item;
    });
  };

  const rowSelection: TableRowSelection<any> = {
    selectedRowKeys: entireSelection(gSelectRows),
    onChange: (rowKey) => {
      if (!pagination) return;
      const find = gSelectRows.find((e) => e.page === pagination.page);
      if (!find) {
        const next = [...gSelectRows, { page: pagination.page, data: rowKey }];
        return sSelectRows(next);
      }

      const next = gSelectRows.map((item) => {
        if (item.page === find.page) {
          return { ...item, data: rowKey };
        }
        return item;
      });
      sSelectRows(next);
    },
  };
  const _columns = getColumns(columns);
  const antIcon = (
    <Image src={loadding} preview={false} style={{ fontSize: "60px" }} />
  );
  const tableLoading = {
    spinning: isLoadingTable,
    indicator: <Spin indicator={antIcon} />,
  };

  // const idNew = () => {
  //   if (socketValue.facilityBookingId) {
  //     const booking = [
  //       socketValue.facilityBookingId,
  //       newId["facility-list-booking"],
  //     ].flat();
  //     setNewId({
  //       "facility-list-booking": booking,
  //     });
  //   }
  // };

  return (
    <div className="mt-4 px-4 pt-2 pb-2 bg-white">
      {!disabledSelect ? (
        <Table
          rowSelection={rowSelection}
          className="p-0 custom-table"
          title={() => _title}
          footer={() => _footer}
          pagination={{
            total: pagination?.total,
            pageSize: pagination?.limit,
            position: [],
          }}
          columns={_columns}
          dataSource={dataSource}
          rowKey={rowKey}
          loading={tableLoading}
          scroll={{ x: "auto" }}
          rowClassName={rowClassName}
          // rowClassName={(record, index) => {
          //   if (NewId === "facility_booking") {
          //     if (newId) {
          //       const checkId = newId["facility-list-booking"]?.includes(
          //         record.id
          //       );
          //       return checkId ? "table-row-pink" : "";
          //     }
          //   }
          //   return "";
          // }}
        />
      ) : (
        <Table
          className="p-0 custom-table"
          title={() => _title}
          footer={() => _footer}
          pagination={{
            total: pagination?.total,
            pageSize: pagination?.limit,
            position: [],
          }}
          columns={_columns}
          dataSource={dataSource}
          rowKey={rowKey}
          loading={tableLoading}
          scroll={{ x: "auto" }}
        />
      )}
    </div>
  );
};

export default CustomTable;
