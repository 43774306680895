import { Col, Form, Input, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import CustomDatePicker from "../../../components/custom-date-picker";
import PageHeaderEdit from "../../../components/page-header-create";
import { validateMessages } from "../../../config/validateMessage";
import {
  GET_BILL_TYPE_ID,
  PATCH_BILL_TYPE,
  POST_BILL_TYPE,
} from "../../../store/bill";
import { swalError, swalSuccess } from "../../../tools/swal";
import th from "antd/es/date-picker/locale/th_TH";
import en from "antd/es/date-picker/locale/en_US";
import { banks } from "../../../config/bankCode";

const BillTypeEdit = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = GET_BILL_TYPE_ID(id);
  const create = POST_BILL_TYPE();
  const update = PATCH_BILL_TYPE();
  const client = useQueryClient();
  const [form] = useForm();
  const history = useHistory();
  const { i18n, t } = useTranslation();

  useEffect(() => {
    form.setFieldsValue(data || {});
  }, [data, form]);

  const onFinish = (values: any) => {
    const reg = /^-?\d*(\.\d*)?$/;
    values.status = values.status === undefined ? "ACTIVE" : values.status;

    delete values.createdAt;
    delete values.updatedAt;
    delete values.createdBy;

    if (values.taxId)
      if (isNaN(values.taxId) && !reg.test(values.taxId))
        return swalError(t, t("tax-id") + ": " + t("validate.number"));

    if (id) {
      const data = { ...values, id: +id };
      update.mutate(data, {
        onError: (err: any) => {
          swalError(t, t("swal.please-complete-the-information"));
        },
        onSuccess: () => {
          client.invalidateQueries("bill-type");
          client.invalidateQueries(["bill-type-id", id]);
          swalSuccess(t);
          history.goBack();
        },
      });
    } else {
      create.mutate(values, {
        onError: (err: any) => {
          swalError(t, t("swal.please-complete-the-information"));
        },
        onSuccess: () => {
          client.invalidateQueries("bill-type");
          swalSuccess(t);
          history.goBack();
        },
      });
    }
  };

  const FormInput = () => {
    return (
      <Row gutter={[24, 24]} className="px-4 mb-4 pb-2">
        <Col span={12}>
          <Form.Item
            name="nameEn"
            label={`${t("nameEn")}`}
            className="custom-input"
            rules={[{ required: true }, { max: 255 }]}
          >
            <Input placeholder={`${t("nameEn")}`} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="nameTh"
            label={`${t("nameTh")}`}
            className="custom-input"
            rules={[{ required: true }, { max: 255 }]}
          >
            <Input placeholder={`${t("nameTh")}`} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="appId"
            label={t("app-id")}
            className="custom-input"
            rules={[{ max: 255 }]}
          >
            <Input placeholder={t("app-id")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="appKey"
            label={t("app-key")}
            className="custom-input"
            rules={[{ max: 255 }]}
          >
            <Input placeholder={t("app-key")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="appSecret"
            label={t("app-secret")}
            className="custom-input"
            rules={[{ max: 255 }]}
          >
            <Input placeholder={t("app-secret")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="terminalId"
            label={t("terminal-id")}
            className="custom-input"
            rules={[{ max: 255 }]}
          >
            <Input placeholder={t("terminal-id")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="merchantId"
            label={t("merchant-id")}
            className="custom-input"
            rules={[{ max: 255 }]}
          >
            <Input placeholder={t("merchant-id")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="billerId"
            label={t("biller-id")}
            className="custom-input"
            rules={[{ max: 255 }]}
          >
            <Input placeholder={t("biller-id")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="referencePrefix"
            label={t("reference-prefix")}
            className="custom-input"
            rules={[{ max: 255 }]}
          >
            <Input placeholder={t("reference-prefix")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="taxId"
            label={t("tax-id")}
            className="custom-input "
            rules={[{ max: 15 }, { required: true }]}
          >
            <Input placeholder={t("tax-id")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="status"
            label={`${t("status")}`}
            className="custom-select-ant "
          >
            <Select placeholder={`${t("status")}`} defaultValue="ACTIVE">
              <Select.Option value="ACTIVE">{t("active")}</Select.Option>
              <Select.Option value="INACTIVE">{t("inactive")}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="serviceType"
            label={t("service-type")}
            rules={[{ required: true }]}
            className="custom-select-ant"
          >
            <Select
              placeholder={t("service-type")}
              getPopupContainer={(value) => {
                return value;
              }}
            >
              <Select.Option value="INTERCON">
                {t("InterContinental Residences Hua Hin")}
              </Select.Option>
              <Select.Option value="LEGAL_ENTITY">
                {t("legal-entity")}
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="accountNumber"
            label={t("bank-account-number")}
            className="custom-input "
          >
            <Input placeholder={t("bank-account-number")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="bank"
            label={t("bank")}
            className="custom-input "
            rules={[{ max: 15 }]}
          >
            <Select placeholder={t("bank")}>
              {banks.map((bank) => {
                const name = i18n.language === "en" ? bank.nameEn : bank.nameTh;
                return (
                  <Select.Option value={bank.id} key={bank.id}>
                    {name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="bankAccountNameEn"
            label={t("bank-account-name-en")}
            className="custom-input "
          >
            <Input placeholder={t("bank-account-name-en")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="bankAccountNameTh"
            label={t("bank-account-name-th")}
            className="custom-input "
          >
            <Input placeholder={t("bank-account-name-th")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="branchPaymentEn"
            label={t("branch-payment-en")}
            className="custom-input "
          >
            <Input placeholder={t("branch-payment-en")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="branchPaymentTh"
            label={t("branch-payment-th")}
            className="custom-input "
          >
            <Input placeholder={t("branch-payment-th")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="line" label={t("line")} className="custom-input ">
            <Input placeholder={t("line")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="email" label={t("email")} className="custom-input ">
            <Input placeholder={t("email")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="tel"
            label={t("for-more-infomation")}
            className="custom-input "
          >
            <Input placeholder={t("phone-number")} />
          </Form.Item>
        </Col>
        {id && (
          <>
            <Col span={12}>
              <Form.Item
                name="createdAt"
                label={`${t("creation-date")}`}
                className="custom-ant-picker"
              >
                <CustomDatePicker
                  locale={i18n.language === "en" ? en : th}
                  format={i18n.language === "en" ? "DD-MM-YYYY" : "DD-MM-BBBB"}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="updatedAt"
                label={`${t("update")}`}
                className="custom-ant-picker"
              >
                <CustomDatePicker
                  locale={i18n.language === "en" ? en : th}
                  format={i18n.language === "en" ? "DD-MM-YYYY" : "DD-MM-BBBB"}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="createdBy"
                label={`${t("created-by")}`}
                className="custom-input"
              >
                <Input disabled placeholder={`${t("created-by")}`} />
              </Form.Item>
            </Col>
          </>
        )}
        <Col span={12}>
          <Form.Item
            name="billing"
            label={t("billing")}
            className="custom-select-ant"
          >
            <Select
              placeholder={t("billing")}
              getPopupContainer={(value) => {
                return value;
              }}
            >
              <Select.Option value="MONTHLY">{t("monthly")}</Select.Option>
              <Select.Option value="YEARLY">{t("yearly")}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
    );
  };

  return (
    <div className="bg-white color-black pb-1">
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        validateMessages={validateMessages}
        className="fixed-input-size"
      >
        <PageHeaderEdit
          label="menu.setting-bill-type"
          isLoading={create.isLoading || update.isLoading}
        />
        <FormInput />
      </Form>
    </div>
  );
};

export default BillTypeEdit;
