import { splitMediaObject } from "./media";
import { IUser } from "./user";
import { IResponse } from "./global";
import axios from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { throwResponse } from "../config/axios";
import { IFoodOptional } from "./food-optional-list";

export interface IFood {
  id: number;
  createdAt: string;
  updatedAt: string;
  nameEn: string;
  nameTh: string;
  price: number;
  descriptionEn: string;
  descriptionTh: string;
  status: "ACTIVE" | "INACTIVE";
  serviceCategory: {
    id: number;
    nameEn: string;
    nameTh: string;
    status: "ACTIVE" | "INACTIVE";
    slug: string;
  };
  createdBy?: IUser;
  foodMediaObjects: {
    id: number;
    url: string;
    uid: string;
    name: string;
    mediaObject: {
      imageUrl: string;
    };
  }[];
  foodOptionalLists: IFoodOptional[];
}

export interface IFoodParams {
  limit: 10;
  page: number;
  status?: "ACTIVE" | "INACTIVE";
  search?: string;
  startDate?: any;
  endDate?: any;
  pagination?: boolean;
}

export const GET_FOODS = (
  params?: IFoodParams
): UseQueryResult<IResponse<IFood[]>> => {
  return useQuery(
    ["get-foods", params],
    async () => {
      const res = await axios("/api/food-service", { params });
      if (res.status === 200) {
        const { data } = res.data;
        const mediaKeys = data.map((item: any) => {
          const { foodMediaObjects } = item;
          const objs = foodMediaObjects.map((cItem: any) => {
            return {
              id: cItem.mediaObject.id,
              name: cItem.mediaObject.filename,
              url: cItem.mediaObject.imageUrl,
              uid: cItem.createdAt,
            };
          });
          return { ...item, foodMediaObjects: objs };
        });

        return { ...res.data, data: mediaKeys };
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const GET_FOOD_BY_ID = (id?: string): UseQueryResult<IFood> => {
  return useQuery(["get-food-id", id], async () => {
    if (!id) return;
    const res = await axios.get(`/api/food-service/${id}`);
    if (res.status === 200) {
      const foodMediaObjects = res.data.data.foodMediaObjects.map(
        (item: any) => {
          return {
            id: item.mediaObject.id,
            name: item.mediaObject.filename,
            url: item.mediaObject.imageUrl,
            uid: item.createdAt,
          };
        }
      );
      return {
        ...res.data.data,
        foodMediaObjects,
        price: +res.data.data.price,
      };
    }
    throwResponse(res);
  });
};

export const POST_FOOD = (): UseMutationResult => {
  return useMutation(async (values: any) => {
    const mediaObjects = await splitMediaObject(values.foodMediaObjects);
    delete values.foodMediaObjects;
    const res = await axios.post("/api/food-service", {
      ...values,
      mediaObjects,
    });
    if (res.status === 201) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const PATCH_FOOD = (): UseMutationResult => {
  return useMutation(async (values: any) => {
    const id = values.id;
    const mediaObjects = await splitMediaObject(values.foodMediaObjects);
    delete values.id;
    delete values.foodMediaObjects;

    const res = await axios.patch(`/api/food-service/${id}`, {
      ...values,
      mediaObjects,
    });
    if (res.status === 200) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const DELETE_FOOD = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/api/food-service/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const BULK_DELETE_FOOD = (): UseMutationResult => {
  return useMutation(async ({ ids }: any) => {
    const res = await axios.delete(`/api/food-service/bulk`, {
      data: { ids },
    });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
