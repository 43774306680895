import { Col, Form, FormInstance, TimePicker } from "antd";
import CustomDatePicker from "../../../components/custom-date-picker";
import { fix_height } from "../../news/news-edit";

export const FormNotRepeat = (form: FormInstance, t: any, editId: string) => {
  return (
    <>
      <Col span={12}>
        <Form.Item
          rules={[{ required: true }]}
          label={t("date")}
          name="date"
          className="custom-ant-picker"
        >
          <CustomDatePicker
            disabled={editId === "create" ? false : true}
            placeholder={t("date")}
            className="w-100"
            style={{ height: `${fix_height}` }}
            format="DD MMM YYYY"
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          rules={[{ required: true }]}
          label={t("time")}
          name="time"
          className="custom-ant-picker"
        >
          <TimePicker.RangePicker
            disabled={editId === "create" ? false : true}
            getPopupContainer={(props: HTMLElement) => {
              return props;
            }}
            placeholder={[t("start-time"), t("end-time")]}
            format="HH:mm"
            className="w-100"
          />
        </Form.Item>
      </Col>
    </>
  );
};
