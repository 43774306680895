import { Col, Form, Input, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import PageHeaderEdit from "../../../components/page-header-create";
import { VALIDATE_MESSAGES } from "../../../config/validateMessage";
import {
  GET_FACILITY_CATEGORY_ID,
  PATCH_FACILITY_CATEGORY,
  POST_FACILITY_CATEGORY,
} from "../../../store/facility-category";
import { swalError, swalSuccess } from "../../../tools/swal";
import th from "antd/es/date-picker/locale/th_TH";
import en from "antd/es/date-picker/locale/en_US";
import CustomDatePicker from "../../../components/custom-date-picker";

const FacilityCategoryEdit = () => {
  const { id } = useParams<{ id: string }>();
  const { data } = GET_FACILITY_CATEGORY_ID(id);
  const create = POST_FACILITY_CATEGORY();
  const update = PATCH_FACILITY_CATEGORY();
  const client = useQueryClient();
  const [form] = useForm();
  const history = useHistory();
  const { i18n, t } = useTranslation();

  useEffect(() => {
    form.setFieldsValue(data || {});
  }, [data, form]);

  const onFinish = (values: any) => {
    values.status = values.status === undefined ? "ACTIVE" : values.status;

    delete values.createdAt;
    delete values.updatedAt;
    delete values.createdBy;

    if (id) {
      const data = { ...values, id: +id };
      update.mutate(data, {
        onError: (err: any) => {
          swalError(t, t("swal.please-complete-the-information"));
        },
        onSuccess: () => {
          client.invalidateQueries("setting-facility-categories");
          client.invalidateQueries(["setting-facility-category", id]);
          swalSuccess(t);
          history.goBack();
        },
      });
    } else {
      create.mutate(values, {
        onError: (err: any) => {
          swalError(t, t("swal.please-complete-the-information"));
        },
        onSuccess: () => {
          client.invalidateQueries("setting-facility-category");
          swalSuccess(t);
          history.goBack();
        },
      });
    }
  };

  const FormInput = () => {
    return (
      <Row gutter={[24, 24]} className="px-4 mb-4 pb-2">
        <Col span={12}>
          <Form.Item
            name="nameEn"
            label={`${t("nameEn")}`}
            className="custom-input"
            rules={[{ required: true }, { max: 255 }]}
          >
            <Input placeholder={`${t("nameEn")}`} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="nameTh"
            label={`${t("nameTh")}`}
            className="custom-input"
            rules={[{ required: true }, { max: 255 }]}
          >
            <Input placeholder={`${t("nameTh")}`} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="status"
            label={`${t("status")}`}
            className="custom-select-ant "
          >
            <Select placeholder={`${t("status")}`} defaultValue="ACTIVE">
              <Select.Option value="ACTIVE">{t("active")}</Select.Option>
              <Select.Option value="INACTIVE">{t("inactive")}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        {id && (
          <>
            <Col span={12}>
              <Form.Item
                name="createdAt"
                label={`${t("creation-date")}`}
                className="custom-ant-picker"
              >
                <CustomDatePicker
                  locale={i18n.language === "en" ? en : th}
                  format={i18n.language === "en" ? "DD-MM-YYYY" : "DD-MM-BBBB"}
                  disabled
                  className="w-100"
                  style={{ height: "45px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="updatedAt"
                label={`${t("update")}`}
                className="custom-ant-picker"
              >
                <CustomDatePicker
                  locale={i18n.language === "en" ? en : th}
                  format={i18n.language === "en" ? "DD-MM-YYYY" : "DD-MM-BBBB"}
                  disabled
                  className="w-100"
                  style={{ height: "45px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="createdBy"
                label={`${t("created-by")}`}
                className="custom-input"
              >
                <Input disabled placeholder={`${t("created-by")}`} />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
    );
  };

  return (
    <div className="bg-white color-black pb-1">
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        validateMessages={VALIDATE_MESSAGES(t)}
        className="fixed-input-size"
      >
        <PageHeaderEdit
          label="menu.setting-facility-category"
          isLoading={create.isLoading || update.isLoading}
        />
        <FormInput />
      </Form>
    </div>
  );
};

export default FacilityCategoryEdit;
