import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import CustomStatusBackground from "../../../components/custom-status";
import CustomTable from "../../../components/custom-table";
import PageFilter, { IPageFilterChoice } from "../../../components/page-filter";
import PageHeader, { ISearchInput } from "../../../components/page-header";
import { SETTING_BILL_TYPE_CREATE_PATH } from "../../../router/path";
import { page_params } from "../../../store";
import {
  BULK_DELETE_BILL_TYPE,
  DELETE_BILL_TYPE,
  GET_BILL_TYPE,
} from "../../../store/bill";
import { parse } from "../../../tools/datetime";
import { swalDelete, swalError, swalSuccess } from "../../../tools/swal";

const BillTypePage = () => {
  const { t, i18n } = useTranslation();
  const [params, setParams] = useRecoilState(page_params);
  const { data, isLoading, refetch } = GET_BILL_TYPE(params);
  const { mutate } = DELETE_BILL_TYPE();
  const bulkDelete = BULK_DELETE_BILL_TYPE();
  const searchInput: ISearchInput[] = [
    {
      label: t("search"),
      key: "search",
      span: 10,
      type: "input",
      props: {
        placeholder: t("search-name-EN-TH-create-by"),
      },
    },
    {
      label: t("creation-date"),
      key: "date",
      span: 7,
      type: "range-picker",
      props: {
        placeholder: [t("start-date"), t("end-date")],
        getPopupContainer: (props: HTMLElement) => {
          return props;
        },
      },
    },
  ];
  const choices: IPageFilterChoice[] = [
    {
      label: t("all"),
      total: data?.countData.countAll || 0,
    },
    {
      label: t("active"),
      key: "ACTIVE",
      total: data?.countData.countActive || 0,
    },
    {
      label: t("inactive"),
      key: "INACTIVE",
      total: data?.countData.countInActive || 0,
    },
  ];

  const columns: ColumnsType = [
    {
      dataIndex: i18n.language === "en" ? "nameEn" : "nameTh",
      title: t("name"),
      render: (text, __) => {
        return <div className="line-clamp">{text || "-"}</div>;
      },
    },
    {
      dataIndex: "updatedAt",
      title: t("update"),
      render: (text) => (
        <>{text ? parse(text, i18n.language, "DD MMM YYYY") : "-"}</>
      ),
    },
    {
      dataIndex: "createdBy",
      title: t("created-by"),
      render: (text, __) => {
        return (
          <p style={{ whiteSpace: "pre", display: "contents" }}>
            {text || "-"}
          </p>
        );
      },
    },
    {
      dataIndex: "status",
      title: t("status"),
      width: "15%",
      render: (text, record) => {
        return (
          <>
            {!text && "-"}
            {text === "INACTIVE" && (
              <CustomStatusBackground
                lable={t("inactive")}
                className={"frame-no-background"}
              />
            )}
            {text === "ACTIVE" && (
              <CustomStatusBackground
                lable={t("active")}
                className={"frame-background"}
              />
            )}
          </>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    if (values.date) {
      const startDate = parse(values.date[0], "en", "YYYY-MM-DD 00:00");
      const endDate = parse(values.date[1], "en", "YYYY-MM-DD 23:59");
      values = { ...values, startDate, endDate };
    }
    delete values.date;
    setParams({ ...params, ...values });
  };

  const onFilter = (choice: IPageFilterChoice) => {
    setParams({ ...params, status: choice.key });
  };

  const onDelete = async (id: number) => {
    const confirm = await swalDelete(t);
    if (confirm) {
      mutate(
        { id },
        {
          onError: (err: any) => {
            swalError(t, t(err.message));
          },
          onSuccess: () => {
            if (params.page > 1 && data?.data.length === 1) {
              setParams({ ...params, page: params.page - 1 });
            }
            swalSuccess(t);
            refetch();
          },
        }
      );
    }
  };

  const _getCurrentFilter = (): string | undefined => {
    const { status } = params;
    if (status) return status;
    return undefined;
  };

  const onReset = () => {
    const values = { startDate: undefined, endDate: undefined };
    setParams({
      ...params,
      ...values,
    });
  };

  return (
    <div>
      <PageHeader
        menu="setting-bill-type"
        onSearch={onSearch}
        searchInput={searchInput}
        label={"create"}
        utility={["delete"]}
        path={SETTING_BILL_TYPE_CREATE_PATH}
        bulkDelete={bulkDelete}
        onReset={onReset}
        invalidateQueries={["bill-type"]}
      />
      <PageFilter
        choices={choices}
        onClick={onFilter}
        current={_getCurrentFilter()}
      />
      <CustomTable
        title={t("bill-type-lists")}
        dataSource={data?.data || []}
        columns={columns}
        pagination={{
          limit: params.limit,
          page: params.page,
          total: data?.count || 0,
        }}
        onDelete={onDelete}
        onPageChange={(page, limit) => setParams({ ...params, page, limit })}
        isLoadingTable={isLoading}
      />
    </div>
  );
};

export default BillTypePage;
