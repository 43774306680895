export interface IBankCode {
  id: string;
  nameTh: string;
  nameEn: string;
}

export const banks: IBankCode[] = [
  {
    id: "001",
    nameTh: "ธนาคารแห่งประเทศไทย",
    nameEn: "Bank of Thailand",
  },
  {
    id: "002",
    nameTh: "ธนาคารกรุงเทพ",
    nameEn: "Bangkok Bank",
  },
  {
    id: "004",
    nameTh: "ธนาคารกสิกรไทย",
    nameEn: "Kasikornbank",
  },
  {
    id: "005",
    nameTh: "ธนาคารแห่งประเทศสกอตแลนด์",
    nameEn: "Royal Bank of Scotland",
  },
  {
    id: "006",
    nameTh: "ธนาคารกรุงไทย",
    nameEn: "Krungthai Bank",
  },
  {
    id: "008",
    nameTh: "เจพีมอร์แกนเชส",
    nameEn: "J.P. Morgan",
  },
  {
    id: "010",
    nameTh: "ธนาคารโตเกียว-มิตซูบิชิ ยูเอฟเจ",
    nameEn: "Bank of Tokyo-Mitsubishi UFJ",
  },
  {
    id: "011",
    nameTh: "ธนาคารทหารไทยธนชาต",
    nameEn: "TMB Bank",
  },
  {
    id: "014",
    nameTh: "ธนาคารไทยพาณิชย์",
    nameEn: "SCB Bank",
  },
  {
    id: "017",
    nameTh: "ธนาคารซิตี้แบงก์",
    nameEn: "Citibank",
  },
  {
    id: "018",
    nameTh: "ธนาคาร ซูมิโตโม มิตซุย แบงกิ้ง คอร์ปอเรชั่น",
    nameEn: "Sumitomo Mitsui Banking Corporation",
  },
  {
    id: "020",
    nameTh: "ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย)",
    nameEn: "Standard Chartered (Thai)",
  },
  {
    id: "022",
    nameTh: "ธนาคารซีไอเอ็มบีไทย",
    nameEn: "CIMB Thai Bank",
  },
  {
    id: "024",
    nameTh: "ธนาคารยูโอบี (ไทย)",
    nameEn: "United Overseas Bank (Thai)",
  },
  {
    id: "025",
    nameTh: "ธนาคารกรุงศรีอยุธยา",
    nameEn: "Bank of Ayudhya (Krungsri)",
  },
  {
    id: "026",
    nameTh: "ธนาคารเมกะ สากลพาณิชย์",
    nameEn: "Mega International Commercial Bank",
  },
  {
    id: "027",
    nameTh: "ธนาคารแห่งอเมริกา",
    nameEn: "Bank of America",
  },
  {
    id: "028",
    nameTh: "ธนาคารเครดิต อากริโคล",
    nameEn: "Crédit Agricole",
  },
  {
    id: "030",
    nameTh: "ธนาคารออมสิน",
    nameEn: "Government Savings Bank",
  },
  {
    id: "031",
    nameTh: "ธนาคารฮ่องกงและเซี่ยงไฮ้แบงกิ้งคอร์ปอเรชั่น",
    nameEn: "Hongkong and Shanghai Banking Corporation",
  },
  {
    id: "032",
    nameTh: "ธนาคารดอยซ์แบงก์",
    nameEn: "Deutsche Bank",
  },
  {
    id: "033",
    nameTh: "ธนาคารอาคารสงเคราะห์",
    nameEn: "Government Housing Bank",
  },
  {
    id: "034",
    nameTh: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
    nameEn: "Bank for Agriculture and Agricultural Cooperatives",
  },
  {
    id: "035",
    nameTh: "ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย",
    nameEn: "EXIM Bank",
  },
  {
    id: "039",
    nameTh: "ธนาคารมิซูโฮ",
    nameEn: "Mizuho Bank",
  },
  {
    id: "045",
    nameTh: "ธนาคารบีเอ็นพี พารีบาส์",
    nameEn: "BNP Paribas",
  },
  {
    id: "052",
    nameTh: "ธนาคารแห่งประเทศจีน (ไทย)",
    nameEn: "Bank of China (Thai)",
  },
  {
    id: "065",
    nameTh: "ธนาคารธนชาต",
    nameEn: "Thanachart Bank",
  },
  {
    id: "066",
    nameTh: "ธนาคารอิสลามแห่งประเทศไทย",
    nameEn: "Islamic Bank of Thailand",
  },
  {
    id: "067",
    nameTh: "ธนาคารทิสโก้",
    nameEn: "Tisco Bank",
  },
  {
    id: "069",
    nameTh: "ธนาคารเกียรตินาคินภัทร",
    nameEn: "Kiatnakin Bank",
  },
  {
    id: "070",
    nameTh: "ธนาคารไอซีบีซี (ไทย)",
    nameEn: "Industrial and Commercial Bank of China (Thai)",
  },
  {
    id: "071",
    nameTh: "ธนาคารไทยเครดิตเพื่อรายย่อย",
    nameEn: "Thai Credit Retail Bank",
  },
  {
    id: "073",
    nameTh: "ธนาคารแลนด์ แอนด์ เฮ้าส์",
    nameEn: "Land and Houses Bank",
  },
  {
    id: "079",
    nameTh: "ธนาคารเอเอ็นแซด (ไทย)",
    nameEn: "ANZ BANK (Thai)",
  },
  {
    id: "080",
    nameTh: "ธนาคารซูมิโตโม มิตซุย ทรัสต์ (ไทย)",
    nameEn: "Sumitomo Mitsui Banking Corporation (Thai)",
  },
  {
    id: "098",
    nameTh: "ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย",
    nameEn: "Small and Medium Enterprise Development Bank of Thailand",
  },
];
