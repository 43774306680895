import axios from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { IResponse } from ".";
import { throwResponse } from "../config/axios";

export interface ILaundryListParams {
  limit: 10;
  page: number;
  status?: "ACTIVE" | "INACTIVE";
  search?: string;
  startDate?: any;
  endDate?: any;
  pagination?: boolean;
}

export interface ILaundryList {
  id: number;
  createdAt: string;
  updatedAt: string;
  nameEn: string;
  nameTh: string;
  status: "ACTIVE" | "INACTIVE";
  requireDryCleaning: boolean;
  requireLaundry: boolean;
  requirePressingOnly: boolean;
  dryCleaningPrice: number;
  laundryPrice: number;
  pressingOnlyPrice: number;
  isComplimentary: boolean;
  svc: number;
  vat: number;
}

export const GET_LAUNDRY_LIST = (params?: {}): UseQueryResult<
  IResponse<ILaundryList[]>
> => {
  return useQuery(
    ["get-laundry-lists", params],
    async () => {
      const res = await axios.get("/api/laundry-item", {
        params: params,
      });
      if (res.status === 200) {
        const { data, count, page, countData } = res.data;
        return { data, count, page, countData };
      }
      throwResponse(res);
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const GET_LAUNDRY_LIST_ID = (
  id?: string
): UseQueryResult<ILaundryList> => {
  return useQuery(["get-laundry-list", id], async () => {
    if (!id) return;
    const res = await axios.get(`/api/laundry-item/${id}`);
    if (res.status === 200) {
      const { data } = res.data;
      return data;
    }
    throwResponse(res);
  });
};

export const POST_LAUNDRY_LIST = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post("/api/laundry-item", data);
    if (res.status === 201) {
      return true;
    }
    throwResponse(res);
  });
};

export const PATCH_LAUNDRY_LIST = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.patch(`/api/laundry-item/${data.id}`, data);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const GET_LAUNDRY_COMPLIMENTARY = () => {
  return useQuery(
    "get-laundry-complimentary",
    async () => {
      const res = await axios.get("/api/laundry-complimentary");
      if (res.status === 200) {
        const { data } = res.data;
        return data;
      }
      throwResponse(res);
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );
};

export const PATCH_LAUNDRY_COMPLIMENTARY = () => {
  return useMutation(async (data: any) => {
    const res = await axios.patch(
      `/api/laundry-complimentary/${data.id}`,
      data
    );
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const DELETE_LAUNDRY_LIST = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/api/laundry-item/${data.id}`);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const BULK_DELETE_LAUNDRY_LIST = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/api/laundry-item/bulk`, {
      data: data,
    });
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const GET_LAUNDRY_TAX = (): UseQueryResult<{
  id: number;
  svg: string;
  vat: string;
}> => {
  return useQuery("get-laundry-tax", async () => {
    const res = await axios.get(`/api/laundry-tax/${1}`);
    if (res.status === 200) {
      const { data } = res.data;
      return data;
    }
    throwResponse(res);
  });
};
