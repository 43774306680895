import React from "react";

const FOOD = (props: React.SVGProps<SVGSVGElement> = {}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 141.73 141.73"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <path
            style={{
              fill: "none",
            }}
            d="M19.88 2.33h103.73v139.15H19.88z"
          />
        </clipPath>
        <style>{".cls-3{fill:#b69e4f}"}</style>
      </defs>
      <g
        style={{
          clipPath: "url(#clip-path)",
        }}
        id="Group_21923"
        data-name="Group 21923"
      >
        <g id="Group_21860" data-name="Group 21860">
          <path
            id="Path_21925"
            data-name="Path 21925"
            className="cls-3"
            d="M101.65 1.6c1 .26 2 .46 3 .77 7.66 2.38 12.55 7.74 15.19 15 4.05 11.18 2.8 21.75-4.74 31.22a20.3 20.3 0 0 1-10.81 7.06c-.68.18-1 .44-1 1.17V75.5c0 .2.06.39.11.59h7.14c.08 1.49.17 2.78.21 4.08q.78 23.13 1.55 46.26A13 13 0 0 1 101.58 140a2.23 2.23 0 0 0-.47.22h-3.52a27.68 27.68 0 0 1-4.66-1.71 12.31 12.31 0 0 1-6.67-11.44c.18-9.25.57-18.49.88-27.74l.7-20.56V76.2h7.23a10.23 10.23 0 0 0 .13-1.16v-18c0-.44-.36-1.18-.7-1.28-7.84-2.33-12.94-7.67-15.66-15.1-4.15-11.23-2.94-21.92 4.75-31.43a20 20 0 0 1 13-7.43 1.87 1.87 0 0 0 .49-.2Z"
          />
          <path
            id="Path_21926"
            data-name="Path 21926"
            className="cls-3"
            d="M41.53 140.25c-2-.84-4.12-1.4-5.85-2.59a12.14 12.14 0 0 1-5.38-10.92c.52-16.4 1.11-32.81 1.67-49.21 0-.4.06-.8.1-1.34h7.15c0-.52.08-.91.08-1.3V57.16c0-.6.16-1.18-.76-1.44-8.39-2.44-13.42-8.35-16.13-16.32a31.32 31.32 0 0 1-1.66-9.25c-.21-9.51-.07-19-.07-28.55h8.12v23.21h10.51V1.6h8.13v23.2H58V5.38c0-1.26.06-2.52.1-3.78h8.12c0 .54-.09 1.08-.09 1.61V28.4c0 7.69-2.06 14.69-7.22 20.58a21.86 21.86 0 0 1-10.49 6.72c-.39.12-.94.63-.94 1v19.09c0 .08.06.16.17.41h5.83c.88 0 1.28.16 1.31 1.17.52 16.37 1.11 32.74 1.62 49.11a12.65 12.65 0 0 1-6.93 12.24 27.69 27.69 0 0 1-4.42 1.58Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default FOOD;
