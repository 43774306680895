import axios from "axios";
import moment from "moment";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { IResponse } from ".";
import { exportFile, throwResponse } from "../config/axios";
import { getClientTimeZone } from "../tools/datetime";

export type TUnitType =
  | "ONE_BEDROOM"
  | "TWO_BEDROOM"
  | "THREE_BEDROOM"
  | "FOUR_BEDROOM"
  | "DUPLEX"
  | "PENTHOUSE";

export interface IUserUnit {
  id: number;
  role: string;
  user: {
    id: number;
    isActive: boolean;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    phoneNumber?: string;
    lineName?: string;
  };
}
export interface IUnit {
  id: number;
  createdAt: string;
  updatedAt: string;
  roomOwnerName: any;
  floor: number;
  building: string;
  unitType: string;
  status: string;
  transferredDate: string;
  projectId: string;
  unitNumber: string;
  houseNumber: number;
  mooTh: string;
  mooEn: string;
  soiTh: string;
  soiEn: string;
  roadTh: string;
  roadEn: string;
  subDistrictTh: string;
  subDistrictEn: string;
  districtTh: string;
  districtEn: string;
  provinceTh: string;
  provinceEn: string;
  postcode: string;
  houseYearBe: string;
  userUnits: IUserUnit[];
  laundryComplimentary: number;
  totalLaundryComplimentary: number;
  typeOfBedroom: TUnitType;
  unitReference: string;
}

export const GET_UNIT = (params?: {}): UseQueryResult<IResponse<IUnit[]>> => {
  return useQuery(
    ["units", params],
    async () => {
      const res = await axios.get("/api/unit", {
        params: params,
      });
      const { data, page, count, countData } = res.data;
      if (res.status === 200) {
        return { data, page, count, countData };
      }
      throwResponse(res);
    },
    { retry: false, refetchOnWindowFocus: false }
  );
};

export const GET_UNIT_BY_ID = (id: string): UseQueryResult<IUnit> => {
  return useQuery(["unit", id], async () => {
    if (!id) return;
    const res = await axios.get(`/api/unit/${id}`);
    if (res.status === 200) {
      const { data } = res.data;
      const transferredDate = data?.transferredDate
        ? moment(data.transferredDate)
        : undefined;
      const houseYearBe = data?.houseYearBe
        ? moment(data.houseYearBe)
        : undefined;
      return { ...data, transferredDate, houseYearBe };
    }
    throwResponse(res);
  });
};

export const POST_UNIT = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post("/api/unit", data);
    if (res.status === 201) {
      return true;
    }
    throwResponse(res);
  });
};

export const PATCH_UNIT = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.patch(`/api/unit/${data.id}`, data);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const DELETE_UNIT = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/api/unit/${data.id}`);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const BULK_DELETE_UNIT = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete("/api/unit/bulk", {
      data: data,
    });
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const POST_UNIT_MEMBER = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post("/api/user-unit", data);
    if (res.status === 201) {
      return true;
    }
    throwResponse(res);
  });
};

export const DELETE_UNIT_MEMBER = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/api/user-unit/${data.id}`);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const PATCH_UNIT_MEMBER = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.patch(`/api/user-unit/${data.id}`, data);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const EXPORT_UNIT = (): UseMutationResult<Blob> => {
  return useMutation(
    async (data: any) => {
      const timeZone = getClientTimeZone();
      const res = await exportFile.post("/api/export-excel/unit", {
        ...data,
        timeZone,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { retry: false }
  );
};

export const EXPORT_TEMPLATE_UNIT_USER = (): UseMutationResult<Blob> => {
  return useMutation(
    async (data: any) => {
      const timeZone = getClientTimeZone();
      const res = await exportFile.post(
        "/api/export-excel/template-user-unit",
        { ...data, timeZone }
      );
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { retry: false }
  );
};

export const IMPORT_UNIT_USER = (): UseMutationResult => {
  return useMutation(async (values) => {
    const res = await axios.post(`/api/user-unit/import-user-unit`, values);
    if (res.status === 201) {
      return;
    }
    throwResponse(res);
  });
};
