import React from "react";

const PAYMENT = (props: React.SVGProps<SVGSVGElement> = {}) => {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141.73 141.73"
      {...props}
    >
      <path
        data-name="Path 20610"
        d="M138.36 41.34v76.77H21.78v-11.84h104.3V41.34zm-24.54 53.15H3.37V23.62H113.8z"
        fill="#b59d4e"
      />
    </svg>
  );
};

export default PAYMENT;
