import { throwResponse } from "./../config/axios";
import axios from "axios";
import {
  UseQueryResult,
  useQuery,
  useMutation,
  UseMutationResult,
} from "react-query";

export interface IShopSetting {
  openTime: string;
  closeTime: string;
  id: number;
}

export const GET_SHOP_SETTING = (): UseQueryResult<IShopSetting[]> => {
  return useQuery(["get-shop-setting"], async () => {
    const res = await axios.get("/api/shop-setting");
    if (res.status === 200) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

export const POST_SHOP_SETTING = (): UseMutationResult => {
  return useMutation(async (values) => {
    const res = await axios.post(`/api/shop-setting`, values);
    if (res.status === 201) {
      return;
    }
    throwResponse(res);
  });
};

export const PATCH_SHOP_SETTING = (): UseMutationResult => {
  return useMutation(async (values: any) => {
    const id = values.id;
    delete values.id;
    const res = await axios.patch(`/api/shop-setting/${id}`, values);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};
