import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  InputNumber,
  Modal,
  Popover,
  Row,
  Typography,
} from "antd";
import { ColumnsType } from "antd/lib/table";
import numeral from "numeral";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import Loader from "react-spinners/ClipLoader";
import CustomStatusBackground from "../../../components/custom-status";
import CustomTable from "../../../components/custom-table";
import PageFilter, { IPageFilterChoice } from "../../../components/page-filter";
import PageHeader, { ISearchInput } from "../../../components/page-header";
import { LAUNDRY_LIST_PATH } from "../../../router/path";
import {
  BULK_DELETE_LAUNDRY_LIST,
  DELETE_LAUNDRY_LIST,
  GET_LAUNDRY_COMPLIMENTARY,
  GET_LAUNDRY_LIST,
  ILaundryList,
  ILaundryListParams,
  PATCH_LAUNDRY_COMPLIMENTARY,
} from "../../../store/laundry-list";
import {
  GET_LAUNDRY_TAX,
  PATCH_LAUNDRY_TAX,
  POST_LAUNDRY_TAX,
} from "../../../store/laundry-tax";
import { findStartOrEndTime, parse } from "../../../tools/datetime";
import { swalSuccess, swalError, swalDelete } from "../../../tools/swal";
import { getKeyByLocale } from "../../../tools/translation";

const LaundryListPage = () => {
  const [params, setParams] = useState<ILaundryListParams>({
    page: 1,
    limit: 10,
  });
  const [visible, setVisible] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const { data, isLoading } = GET_LAUNDRY_LIST(params);
  const delete_list = DELETE_LAUNDRY_LIST();
  const bulk_delete_laundry_list = BULK_DELETE_LAUNDRY_LIST();

  const client = useQueryClient();

  const searchInput: ISearchInput[] = [
    {
      label: t("search"),
      key: "search",
      span: 10,
      type: "input",
      props: {
        placeholder: `${t("search-name-EN-TH")} / ${t("created-by")}`,
      },
    },
    {
      label: t("date"),
      key: "date",
      span: 7,
      type: "range-picker",
      props: {
        placeholder: [t("start-date"), t("end-date")],
        getPopupContainer: (props: HTMLElement) => {
          return props;
        },
      },
    },
  ];
  const choices: IPageFilterChoice[] = [
    {
      label: t("all"),
      total: data?.countData.total || 0,
    },
    {
      label: t("active"),
      key: "ACTIVE",
      total: data?.countData.countActive || 0,
    },
    {
      label: t("inactive"),
      key: "INACTIVE",
      total: data?.countData.countInactive || 0,
    },
  ];

  const columns: ColumnsType<ILaundryList> = [
    {
      dataIndex: "nameEn",
      title: t("name"),
      render: (___, rc) => (
        <Typography.Text ellipsis style={{ width: 300 }}>
          {rc.nameEn || rc.nameTh
            ? getKeyByLocale({ key: "name", data: rc, locale: i18n.language })
            : "-"}
        </Typography.Text>
      ),
    },
    {
      dataIndex: "dryCleaningPrice",
      title: t("dry-cleaning"),
      render: (text) => <>{text ? numeral(text).format("0,0.00") : "-"}</>,
    },
    {
      dataIndex: "laundryPrice",
      title: t("menu.laundry"),
      render: (text) => <>{text ? numeral(text).format("0,0.00") : "-"}</>,
    },
    {
      dataIndex: "pressingOnlyPrice",
      title: t("pressing-only"),
      render: (text) => <>{text ? numeral(text).format("0,0.00") : "-"}</>,
    },
    {
      dataIndex: "updatedAt",
      align: "center",
      title: t("update"),
      render: (text) => {
        return (
          <span>{text ? parse(text, i18n.language, "DD MMM YYYY") : "-"}</span>
        );
      },
    },
    {
      dataIndex: "status",
      title: t("status"),
      render: (text) => {
        return (
          <>
            {!text && "-"}
            {text === "ACTIVE" && (
              <CustomStatusBackground
                lable={t("active")}
                className={"frame-background"}
              />
            )}
            {text === "INACTIVE" && (
              <CustomStatusBackground
                lable={t("inactive")}
                className={"frame-no-background"}
              />
            )}
          </>
        );
      },
    },
    {
      dataIndex: "isComplimentary",
      title: t("complimentary"),
      render: (text) => {
        return (
          <div className="d-flex justify-center">
            {text && (
              <Popover
                content={
                  <div
                    style={{ background: "black", color: "white" }}
                    className="p-1"
                  >
                    {t("complimentary")}
                  </div>
                }
                title={null}
              >
                <ExclamationCircleOutlined style={{ color: "#AEB7FF" }} />
              </Popover>
            )}
          </div>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    if (!values.date) {
      values.date = [];
    }
    const { date } = values;

    const createdAtStartDate = findStartOrEndTime(date[0], "start");
    const createdAtEndDate = findStartOrEndTime(date[1], "end");
    values = { ...values, createdAtStartDate, createdAtEndDate };
    delete values.date;

    setParams({ ...params, ...values });
  };

  const onDelete = async (id: any) => {
    const confirm = await swalDelete(t, "comfirm-delete-laundry-list");
    if (confirm) {
      delete_list.mutate(
        { id: id },
        {
          onSuccess: () => {
            if (params.page > 1 && data?.data.length === 1) {
              setParams({ ...params, page: params.page - 1 });
            }

            swalSuccess();
            client.invalidateQueries("get-laundry-lists");
          },
          onError: (error: any) => {
            swalError(t, t(error.message));
          },
        }
      );
    }
  };

  const onFilter = (choice: IPageFilterChoice) => {
    setParams({ ...params, status: choice.key });
  };

  const onBulkDeleteSuccess = (length: number) => {
    if (length === data?.data.length && params.page > 1) {
      setParams({ ...params, page: params.page - 1 });
    }
  };

  return (
    <div>
      <Modal
        onCancel={() => setVisible(false)}
        visible={visible}
        footer={null}
        closable={false}
        centered
        width="50vw"
        style={{ height: "98vh" }}
        maskClosable={false}
      >
        <ComplimentaryModalBody onClose={() => setVisible(false)} />
      </Modal>
      <PageHeader
        menu="laundry-list"
        onSearch={onSearch}
        searchInput={searchInput}
        label="create"
        utility={["laundry", "delete"]}
        path={LAUNDRY_LIST_PATH.create}
        bulkDelete={bulk_delete_laundry_list}
        onBulkDeleteSuccess={onBulkDeleteSuccess}
        onComplimentaryButtonClick={() => setVisible(!visible)}
        textConfirmDelete="comfirm-delete-laundry-list"
      />
      <PageFilter
        choices={choices}
        onClick={onFilter}
        current={params.status}
      />
      <CustomTable
        title={t("laundry-list")}
        dataSource={data?.data || []}
        isLoadingTable={isLoading}
        columns={columns}
        pagination={{
          limit: params.limit,
          page: params.page,
          total: data?.count || 0,
        }}
        onPageChange={(page, limit) => {
          setParams({ ...params, page, limit });
        }}
        onDelete={onDelete}
      />
    </div>
  );
};

const ComplimentaryModalBody = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const client = useQueryClient();

  const get_laundry_complimentary = GET_LAUNDRY_COMPLIMENTARY();
  const patch_laundry_complimentary = PATCH_LAUNDRY_COMPLIMENTARY();
  const get_laundry_tax = GET_LAUNDRY_TAX();
  const post_laundry_tax = POST_LAUNDRY_TAX();
  const patch_laundry_tax = PATCH_LAUNDRY_TAX();

  useEffect(() => {
    initialComplimentary();
    initialTax();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_laundry_complimentary.data, get_laundry_tax.data]);

  const initialComplimentary = () => {
    if (!get_laundry_complimentary.data) return;
    get_laundry_complimentary.data.map((item: any) => {
      return form.setFieldsValue({ [item.typeOfBedroom]: item.quantity });
    });
  };

  const initialTax = () => {
    if (!get_laundry_tax.data || !get_laundry_tax.data.length) return;
    const [item] = get_laundry_tax.data;
    return form.setFieldsValue({ svg: item.svg, vat: item.vat });
  };

  const afterMutate = {
    onSuccess: () => {
      client.invalidateQueries();
      swalSuccess();
      onClose();
    },
    onError: ({ message }: any) => {
      swalError(t, message);
    },
  };

  const onFinish = (values: any) => {
    const { svg, vat } = values;
    Promise.all(
      get_laundry_complimentary.data?.map((item: any, index: number) => {
        const id = item.id;
        const quantity = values[item.typeOfBedroom];
        if (item.quantity !== quantity)
          return patch_laundry_complimentary.mutate(
            { id, quantity },
            afterMutate
          );
        return undefined;
      })
    );
    if (!get_laundry_tax.data?.length) {
      post_laundry_tax.mutate({ svg, vat }, afterMutate);
    }
    if (get_laundry_tax.data && get_laundry_tax.data.length > 0) {
      const id = get_laundry_tax.data[0].id;
      patch_laundry_tax.mutate({ svg: +svg, vat: +vat, id }, afterMutate);
    }
  };

  const getContext = () => {
    if (get_laundry_complimentary.isLoading) {
      return (
        <div
          className="d-flex justify-center align-center"
          style={{ height: "86.12px" }}
        >
          <Loader color="#B69E4F" />
        </div>
      );
    }

    return (
      <Row gutter={[12, 12]} className="px-4 pb-4">
        {get_laundry_complimentary.data.map((item: any) => {
          const name = item.typeOfBedroom.toLowerCase().split("_").join("-");

          return (
            <Col span={24} key={item.id}>
              <Form.Item
                name={item.typeOfBedroom}
                label={t(name)}
                rules={[{ required: true }]}
              >
                <InputWithText placeholder={name} />
              </Form.Item>
            </Col>
          );
        })}
        <Col span={20}>
          <Form.Item
            name="svg"
            label={t("svc")}
            rules={[{ required: true }]}
            className="custom-input-number custom-input"
          >
            <InputNumber placeholder={t("svc")} min={0} addonAfter="%" />
          </Form.Item>
        </Col>
        <Col span={20}>
          <Form.Item
            name="vat"
            label="VAT"
            rules={[{ required: true }]}
            className="custom-input-number custom-input"
          >
            <InputNumber placeholder="VAT" min={0} addonAfter="%" />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  const InputWithText = ({
    value,
    onChange,
    placeholder,
  }: {
    value?: string | undefined;
    onChange?: any;
    placeholder: string;
  }) => {
    const { t } = useTranslation();

    return (
      <Row align="middle" justify="center">
        <Col span={20}>
          <InputNumber
            value={value}
            onChange={onChange}
            placeholder={t(placeholder)}
          />
        </Col>
        <Col span={4} className="d-flex justify-end">
          {t("pcs./month")}
        </Col>
      </Row>
    );
  };

  return (
    <Form
      className="main-input-form"
      layout="vertical"
      form={form}
      onFinish={onFinish}
    >
      <Row
        justify="space-between"
        className="pb-4"
        align="middle"
        gutter={[0, 24]}
      >
        <Col>
          <div>
            <h2 className="bold mb-0"> {t("free-laundry-items")}</h2>
            <span className="color-gold"> * {t("complimentary-fixed")} </span>
          </div>
        </Col>
        <Col>
          <Row gutter={[12, 12]}>
            <Col>
              <Button
                style={{ height: 45, width: 120 }}
                className="button-cancel-setup"
                onClick={() => {
                  // form.resetFields
                  onClose();
                }}
              >
                {t("cancel")}
              </Button>
            </Col>
            <Col>
              <Button
                htmlType="submit"
                style={{ height: 45, width: 120 }}
                loading={
                  patch_laundry_complimentary.isLoading ||
                  patch_laundry_tax.isLoading ||
                  post_laundry_tax.isLoading
                }
                className="button-submit-setup"
              >
                {t("submit")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {getContext()}
    </Form>
  );
};

export default LaundryListPage;
