import { Reducer } from "react";
import { IParcel } from "../../store";

export type TParcelAction = {
  key: "edit" | "delete";
  data?: IParcel[];
  id?: number;
};

export type TParcelReducer = Reducer<IParcel[], TParcelAction>;

export const reducerFunction = (state: IParcel[], action: TParcelAction) => {
  const { key, id, data } = action;
  switch (key) {
    case "edit":
      return data || [];
    case "delete":
      return state.filter((item) => item.id !== id);
  }
};
