import { FC } from "react";
import { Layout } from "antd";
import { routes } from "../router/router";
import { Route, Switch, Redirect } from "react-router-dom";
import { GET_USER_PROFILE } from "../store/auth";
import { GET_ROLE_BY_ID } from "../store/role";
import { menu } from "../router/menu";

export interface IRoute {
  path: string;
  name: string;
  component: FC;
  exact?: boolean;
}

const Content = () => {
  const { role } = GET_USER_PROFILE();
  const { data } = GET_ROLE_BY_ID(role.id);

  const getRedirectPath = (): string => {
    const activeMenu = menu.filter((item) => {
      const find = data?.roleMenus?.find((e) => e.menu.code === item.code);
      return find?.isActive;
    });

    if (activeMenu.length > 0) {
      return activeMenu[0].to;
    }

    return "/";
  };

  const redirect = getRedirectPath();

  return (
    <Layout.Content
      style={{ backgroundColor: "#F7F7F7", overflow: "auto" }}
      className="p-5 color-white"
    >
      <Switch>
        {routes.map((item) => {
          return (
            <Route
              key={item.name}
              path={item.path}
              exact={item.exact}
              render={(props: any) => <item.component {...props} />}
            />
          );
        })}
        <Redirect from="/" to={redirect} />
      </Switch>
    </Layout.Content>
  );
};

export default Content;
