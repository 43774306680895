import React from "react";

const PARCEL = (props: React.SVGProps<SVGSVGElement> = {}) => {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141.73 141.73"
      {...props}
    >
      <g data-name="Group 20186">
        <path
          data-name="Path 20620"
          d="M5 4.94v44.17h6.58v87.68H130.2V49.11h6.58V4.94zm85.7 79.11H51.1V70.9h39.55zm33-46.15H18.11V18.1h105.5z"
          fill="#b59d4e"
        />
      </g>
    </svg>
  );
};

export default PARCEL;
