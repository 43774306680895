import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Switch, Route, Redirect } from "react-router";
import { ALLOWED_MOBILE_ROLE } from "../config/constrant";
import { IRoute } from "../layout/content";
import { CLEAR_STORAGE, GET_USER_PROFILE } from "../store/auth";
import { swalError } from "../tools/swal";
import {
  REPAIRMAN_INDEX_PATH,
  SCAN_QRCODE_PATH,
  ADMIN_PATH,
} from "./router-mobile/path";
import {
  MOBILE_JURISTIC_ROUTE,
  MOBILE_REPAIR_MAN_ROUTE,
  MOBILE_SUPER_ADMIN_ROUTE,
} from "./router-mobile/router";

// const fallback = (
//   <div className="h-100 d-flex align-center justify-center">
//     <HashLoader color="white" size={40} />
//   </div>
// );

const AppMobile = () => {
  const { t } = useTranslation();
  const { role } = GET_USER_PROFILE();

  useEffect(() => {
    redirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const redirect = () => {
    if (ALLOWED_MOBILE_ROLE.includes(role.title)) return;

    if (role.title === "MEMBER") {
      CLEAR_STORAGE(false);
      swalError(t, t("swal.unable-login-member"));
      return <Redirect to="/login" />;
    }

    //for other role than member
    CLEAR_STORAGE(false);
    swalError(t, t("swal.login-juristic"));
    return <Redirect to="/login" />;
  };

  const getRedirectPath = (): string => {
    if (role.title === "REPAIRMAN") {
      return REPAIRMAN_INDEX_PATH;
    }
    if (role.title === "JURISTIC") {
      return SCAN_QRCODE_PATH;
    }

    return ADMIN_PATH;
  };
  
  const getRoute = (): IRoute[] => {
    if (role.title === "REPAIRMAN") {
      return MOBILE_REPAIR_MAN_ROUTE;
    }
    if (role.title === "JURISTIC") {
      return MOBILE_JURISTIC_ROUTE;
    }

    return MOBILE_SUPER_ADMIN_ROUTE;
  };

  const routes = getRoute();
  const redirectPath = getRedirectPath();

  return (
    <Switch>
      {routes.map((item) => {
        return (
          <Route
            key={item.name}
            path={item.path}
            exact={item.exact}
            render={(props: any) => <item.component {...props} />}
          />
        );
      })}
      <Redirect from="/" to={redirectPath} />
    </Switch>
  );
};

export default AppMobile;
