import React from "react";

const DASHBOARD = (props: React.SVGProps<SVGSVGElement> = {}) => {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141.73 141.73"
      {...props}
    >
      <path
        data-name="Path 20618"
        d="M53.83 131.44H87.9V10.29H53.83zm-51.11 0H36.8V70.87H2.72zm102.22-83.3v83.3H139v-83.3z"
        fill="#b59d4e"
      />
    </svg>
  );
};

export default DASHBOARD;
