import { Form, Row, Col, Button, FormInstance, Input, Select } from "antd";
import { t } from "i18next";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import PageHeaderEdit from "../../components/page-header-create";
import {
  GET_PAYMENT_LIST_ID,
  PATCH_PAYMENT_LIST,
  POST_PAYMENT_LIST,
} from "../../store/payment-list";
import { swalError, swalSuccess } from "../../tools/swal";

const EditPaymentList = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [form] = Form.useForm();
  const client = useQueryClient();
  const post_payment_list = POST_PAYMENT_LIST();
  const patch_payment_list = PATCH_PAYMENT_LIST();
  const { data } = GET_PAYMENT_LIST_ID(id);

  useEffect(() => {
    form.setFieldsValue(data || {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, form]);

  const onFinish = (values: any) => {
    values.status = values.status ? values.status : "ACTIVE";
    if (values.id) {
      const dataId = { ...values, id: +values.id };
      patch_payment_list.mutate(dataId, {
        onError: (err: any) => {
          swalError(t, t("swal.please-complete-the-information"));
        },
        onSuccess: () => {
          client.invalidateQueries("get-payment-type-lists");
          client.invalidateQueries("get-payment-type-list-id");
          swalSuccess(t);
          history.goBack();
        },
      });
    } else {
      post_payment_list.mutate(values, {
        onSuccess: () => {
          client.invalidateQueries("get-payment-type-lists");
          swalSuccess(t);
          history.goBack();
        },
        onError: ({ message }: any) => {
          swalError(t, message);
        },
      });
    }
  };

  const FormEdit = () => {
    return (
      <Row gutter={[24, 24]} className="px-4 pb-4">
        <Col span={12} hidden>
          <Form.Item name="id">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="nameEn"
            label={`${t("nameEn")}`}
            className="custom-input"
            rules={[{ required: true }, { max: 255 }]}
          >
            <Input placeholder={`${t("nameEn")}`} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="nameTh"
            label={`${t("nameTh")}`}
            className="custom-input"
            rules={[{ required: true }, { max: 255 }]}
          >
            <Input placeholder={`${t("nameTh")}`} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="status"
            label={`${t("status")}`}
            className="custom-select-ant "
          >
            <Select placeholder={`${t("status")}`} defaultValue="ACTIVE">
              <Select.Option value="ACTIVE">{t("active")}</Select.Option>
              <Select.Option value="INACTIVE">{t("inactive")}</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12} className="d-flex align-end">
          <Form.Item shouldUpdate={(prev, next) => prev.id !== next.id}>
            {(form: FormInstance) => {
              const id = form.getFieldValue("id");

              if (id)
                return (
                  <Button
                    style={{ height: 45, width: 120 }}
                    className="button-cancel-setup"
                    onClick={() => {
                      form.resetFields();
                    }}
                  >
                    {t("clear")}
                  </Button>
                );
            }}
          </Form.Item>
        </Col>
      </Row>
    );
  };

  return (
    <Form
      className="main-input-form"
      layout="vertical"
      form={form}
      onFinish={onFinish}
    >
      <div className="bg-white color-black px-4 py-4 mb-4">
        <PageHeaderEdit
          label="menu.payment-list"
          labelTo="menu.payment-list"
          isLoading={
            post_payment_list.isLoading || patch_payment_list.isLoading
          }
        />
        <FormEdit />
      </div>
    </Form>
  );
};
export default EditPaymentList;
