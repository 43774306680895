import PageHeader, { ISearchInput } from "../../../components/page-header";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import CustomTable from "../../../components/custom-table";
import PageFilter, { IPageFilterChoice } from "../../../components/page-filter";
import CustomStatusBackground from "../../../components/custom-status";
import { Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import NO_PROFILE from "../../../assets/images/no_profile.png";
import { USER_MANAGEMENT_RESIDENT_PATH } from "../../../router/path";
import {
  DELETE_MULTI_USER,
  DELETE_USER,
  EXPORT_RESIDENT,
  EXPORT_RESIDENT_TEMPLATE,
  GET_USERS_MEMBER,
  IMPORT_RESIDENT,
  IUser,
  page_params,
} from "../../../store";
import { swalDelete, swalError, swalSuccess } from "../../../tools/swal";
import { useQueryClient } from "react-query";
import { GET_UNIT } from "../../../store/units";
import { useRecoilState } from "recoil";
import ModalImportExcel, {
  TModalRef,
} from "../../../components/modal-import-excel";
import { useRef } from "react";
import dayjs from "dayjs";
import { downloadFile } from "../../../tools/files";

const UserResidentPage = () => {
  const [params, setParams] = useRecoilState(page_params);
  const { isLoading, data } = GET_USERS_MEMBER(params);
  const units = GET_UNIT({ pagination: false });
  const { t } = useTranslation();
  const client = useQueryClient();
  const delete_user = DELETE_USER();
  const delete_multi_user = DELETE_MULTI_USER();
  const exportExcel = EXPORT_RESIDENT();
  const importExcel = IMPORT_RESIDENT();
  const exportTmplate = EXPORT_RESIDENT_TEMPLATE();

  const choices: IPageFilterChoice[] = [
    {
      label: t("all"),
      total: data?.countData.countAll,
    },
    {
      label: t("active"),
      key: "ACTIVE",
      total: data?.countData.countActive,
    },
    {
      label: t("inactive"),
      key: "INACTIVE",
      total: data?.countData.countInActive,
    },
  ];

  const columns: ColumnsType<IUser> = [
    {
      dataIndex: "avatar",
      title: t("profile"),
      render: (item: any, rc: any) => (
        <>
          <Avatar
            size="large"
            src={rc?.avatar?.imageUrl || NO_PROFILE}
            style={{ backgroundColor: "#F4F4F4" }}
            icon={<UserOutlined style={{ color: "#D1D1D1" }} />}
          />
        </>
      ),
    },
    {
      dataIndex: "fullName",
      title: `${t("first-name")} - ${t("last-name")}`,
      render: (text, _) => text || "-",
    },
    {
      dataIndex: "email",
      title: t("email"),
      render: (text, _) => text || "-",
    },
    {
      dataIndex: "phoneNumber",
      title: t("phone-number"),
      align: "center",
      render: (text, _) => text || "-",
    },
    {
      dataIndex: "isActive",
      title: t("status"),
      align: "center",
      width: 150,
      render: (isActive) => {
        if (isActive) {
          return (
            <CustomStatusBackground
              lable={t("active")}
              className={"frame-background"}
            />
          );
        }
        return (
          <CustomStatusBackground
            lable={t("inactive")}
            className={"frame-no-background"}
          />
        );
      },
    },
  ];
  const searchInput: ISearchInput[] = [
    {
      label: t("search"),
      key: "search",
      span: 12,
      type: "input",
      props: {
        placeholder: `${t("search")} ${t("email")} / ${t("phone-number")} / ${t(
          "id-card-number"
        )} / ${t("first-name")} - ${t("last-name")}`,
      },
    },
    {
      label: t("house-number"), //unit number to use, house number to show
      key: "unit",
      span: 7,
      type: "select",
      props: {
        placeholder: t("all"),
        optionValue: {
          values: units?.data?.data || [],
          key: "id",
          name: "unitNumber",
        },
        loading: units.isLoading,
      },
    },
  ];

  const onSearch = (e: { search?: string; unit?: number }) => {
    setParams({ ...params, ...e });
  };

  const onFilter = (e: any) => {
    const { key } = e;
    if (key === "INACTIVE") {
      setParams({ ...params, isActive: false });
    } else if (key === "ACTIVE") {
      setParams({ ...params, isActive: true });
    } else {
      setParams({ ...params, isActive: undefined });
    }
  };

  const onDelete = async (id: any) => {
    const confirm = await swalDelete();
    if (confirm) {
      delete_user.mutate(
        { id: id },
        {
          onSuccess: () => {
            swalSuccess();
            client.invalidateQueries(["get-users-member"]);
          },
          onError: (error: any) => {
            swalError(t, error.message);
          },
        }
      );
    }
  };

  const _getCurrentFilter = (): string | undefined => {
    const { isActive } = params;
    if (isActive) return "ACTIVE";
    if (isActive === false) return "INACTIVE";
    return undefined;
  };

  const transformJson = (value: any[]): any[] => {
    return value.map((item) => {
      return { ...item, dob: dayjs(item.dob).format("YYYY-MM-DD") };
    });
  };

  const importRef = useRef<TModalRef>(null);

  const onReset = () => {
    const next = { ...params, search: undefined, unit: undefined };
    setParams(next);
  };

  return (
    <div>
      <ModalImportExcel
        transformJson={transformJson}
        mutation={importExcel}
        ref={importRef}
        headerKey={[
          "email",
          "firstName",
          "lastName",
          "phoneNumber",
          "identityNumber",
          "countryCode",
          "language",
        ]}
        range={{
          rowStart: 1,
          colStart: 0,
          colEnd: 9,
        }}
        tableProps={{
          columns: [
            {
              title: "#",
              render: (_, __, index) => {
                return index + 1;
              },
            },
            {
              dataIndex: "email",
              title: t("email"),
            },
            {
              dataIndex: "firstName",
              title: t("first-name"),
            },
            {
              dataIndex: "lastName",
              title: t("last-name"),
            },
            {
              dataIndex: "phoneNumber",
              title: t("phone-number"),
            },
            {
              dataIndex: "identityNumber",
              title: t("id-card-number"),
            },
          ],
        }}
      />
      <PageHeader
        menu="user-management-resident"
        onSearch={onSearch}
        onReset={onReset}
        searchInput={searchInput}
        label={"create"}
        utility={["delete"]}
        path={USER_MANAGEMENT_RESIDENT_PATH.create}
        bulkDelete={delete_multi_user}
        invalidateQueries={["get-users"]}
        topicExport={t("menu.user-management-resident")}
        excelOptions={{
          import: () => {
            importRef?.current?.triggerVisible();
          },
          export: exportExcel,
          template: () =>
            exportTmplate.mutate(
              {},
              {
                onSuccess: (data: any) => {
                  downloadFile(data, "xlsx", "Template resident");
                },
                onError: (error: any) => {
                  swalError(t, error.message);
                },
              }
            ),
        }}
      />
      <PageFilter
        choices={choices}
        onClick={onFilter}
        current={_getCurrentFilter()}
      />
      <CustomTable
        rowKey="id"
        title={t("user-man")}
        dataSource={data?.data || []}
        columns={columns}
        isLoadingTable={isLoading}
        pagination={{
          limit: params.limit,
          page: params.page,
          total: data?.count || 0,
        }}
        onPageChange={(page, limit) => setParams({ ...params, page, limit })}
        onDelete={onDelete}
        noti="resident"
      />
    </div>
  );
};

export default UserResidentPage;
