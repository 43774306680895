import { IFoodOptional } from "./food-optional-list";
import { IUnit } from "./units";
import { IResponse, IUser } from ".";
import axios from "axios";
import { useMutation, UseMutationResult, useQuery } from "react-query";
import { UseQueryResult } from "react-query";
import { exportFile, throwResponse } from "../config/axios";
import { IFood } from "./food-list";
import { IQRImage } from "./payment";
import { POST_MEDIA_OBJECT } from "./media";
import dayjs from "dayjs";
import { getClientTimeZone } from "../tools/datetime";

export interface IFoodOrderParams {
  limit: number;
  page: number;
  status?: "ACTIVE" | "INACTIVE";
  search?: string;
  startDate?: any;
  endDate?: any;
  isPaid?: boolean;
}

export interface IFoodOrder {
  id: number;
  createdAt: string;
  totalPrice?: string;
  foodCode: string;
  setOwnPrice?: string;
  isReceived: boolean;
  isPaid: boolean;
  customer?: IUser;
  unit: IUnit;
  foodOrderList: {
    id: number;
    detail?: string;
    quantity: number;
    totalPrice: string;
    food: IFood;
    optional: IFoodOptional[];
  }[];
  phoneNumber: string;
  qrImage: IQRImage;
  qrBotImage: IQRImage;
  paymentType: string;
  receiptImage: {
    id: number;
    createdAt: string;
    updatedAt: string;
    imageUrl: string;
    filename: string;
    alternativeText: string;
    uid: string;
  }[];
  svc: number;
  vat: number;
  status: "PENDING" | "SUCCESS" | "CANCEL" | "PAID";
  paymentDate: string;
  foodRef: string;
  taxId: string;
  unitReference: string;
}

export const GET_FOOD_ORDER_BY_ID = (
  id?: string
): UseQueryResult<IFoodOrder> => {
  return useQuery(["get-food-order-id", id], async () => {
    if (!id) return;
    const res = await axios.get(`/api/food-order/${id}`);
    if (res.status === 200) {
      const { data } = res.data;
      const receiptImage = data.receiptImage
        ? [
            {
              id: data.receiptImage?.id,
              name: data.receiptImage?.filename,
              uid: data.receiptImage?.uid,
              url: data.receiptImage?.imageUrl,
            },
          ]
        : undefined;
      const setOwnPrice = data.totalPrice;
      if (data.paymentDate) data.paymentDate = dayjs(data.paymentDate);
      delete data.totalPrice;
      return { ...data, setOwnPrice, receiptImage };
    }
    throwResponse(res);
  });
};

export const GET_FOOD_ORDER = (
  params?: IFoodOrderParams
): UseQueryResult<IResponse<IFood[]>> => {
  return useQuery(
    ["get-food-order", params],
    async () => {
      const res = await axios.get(`/api/food-order`, { params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { keepPreviousData: true }
  );
};

export const POST_FOOD_ORDER = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post("/api/food-order", data);
    if (res.status === 201) {
      return res.data.data;
    }
    throwResponse(res);
  });
};

export const PATCH_FOOD_ORDER = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const id = data.id;
    delete data.id;

    const { picture } = data;
    let receiptImage = undefined;
    let imageId = undefined;
    if (picture)
      imageId = await Promise.all(
        picture.map(async (item: any) => {
          if (!item.id) {
            const idImage = await POST_MEDIA_OBJECT(item.originFileObj);
            return idImage;
          }
          return item.id;
        })
      );
    if (imageId) receiptImage = imageId[0];
    delete data.picture;
    data = { ...data, receiptImage };

    const res = await axios.patch(`/api/food-order/${id}`, data);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
    //
  });
};

export const DELETE_FOOD_ORDER = (): UseMutationResult => {
  return useMutation(async ({ id }: any) => {
    const res = await axios.delete(`/api/food-order/${id}`);
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const BULK_DELETE_FOOD_ORDER = (): UseMutationResult => {
  return useMutation(async ({ ids }: any) => {
    const res = await axios.delete("/api/food-order/bulk", { data: { ids } });
    if (res.status === 200) {
      return;
    }
    throwResponse(res);
  });
};

export const GET_GENERATE_QR_CODE_SCB_FOOD_ORDER = (): UseMutationResult => {
  return useMutation(async (foodOrderId: any) => {
    const res = await axios.get(
      `/api/scb/${foodOrderId}/food-order/generate-qrcode-qr30`
    );
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const GET_GENERATE_QR_CODE_BOT_FOOD_ORDER = (): UseMutationResult => {
  return useMutation(async (foodOrderId: any) => {
    const res = await axios.get(
      `/api/payment/${foodOrderId}/food-order/generate-qrcode`
    );
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const LINE_NOTIFICATION_FOOD_ORDER = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post(
      "/api/line-notification/send-message-food-order",
      data
    );
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const EXPORT_FOOD_ORDER = (): UseMutationResult<Blob> => {
  return useMutation(
    async (data: any) => {
      const timeZone = getClientTimeZone();
      const res = await exportFile.post("/api/export-excel/food-order", {
        ...data,
        timeZone,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { retry: false }
  );
};
