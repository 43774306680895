import { Col, Pagination, Row, Table, Image, Spin } from "antd";
import { ColumnsType, ColumnType } from "antd/lib/table";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilState } from "recoil";
import { IPageParams, page_params } from "../store";
import loadding from "../assets/images/loding.gif";
import { EditFilled } from "@ant-design/icons";
import { HOMECARE_PRIVATE_AREA_REPAIRING_PATH } from "../router/path";

type TAction = "edit";

const CustomHistoryRepairing = ({
  columns,
  dataSource,
  rowKey = "id",
  actionKey = "id",
  title,
  actions = ["edit"],
  hasIndex = true,
  pagination,
  isLoadingTable = false,
  onPageChange,
  disablePagination = false,
}: {
  columns: ColumnsType<any>;
  dataSource: any[];
  actions?: TAction[];
  rowKey?: string;
  actionKey?: string;
  title?: string;
  hasIndex?: boolean;
  pagination?: IPageParams;
  isLoadingTable?: boolean;
  onPageChange?: (page: number, limit: any) => void;
  disablePagination?: boolean;
}) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const [gParams, sParams] = useRecoilState(page_params);

  useEffect(() => {
    sParams({ limit: 10, page: 1, total: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (!dataSource.length) {
      if (gParams.page > 1 && !isLoadingTable)
        sParams({ ...gParams, page: gParams.page - 1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  const onEdit = (id: any) => {
    return push(HOMECARE_PRIVATE_AREA_REPAIRING_PATH.index + `/${id}`);
  };

  const actionProps = {
    edit: {
      icon: <EditFilled />,
      func: onEdit,
    },
  };

  const getActions = (_actions: TAction[]): ColumnType<any> => {
    return {
      title: t("manage"),
      render: (_, record) => {
        const uniqueKey = record[actionKey];
        if (!uniqueKey) return <></>;
        return (
          <div className="d-flex">
            {_actions.map((item, index) => {
              return (
                <div
                  className={`table-action-button`}
                  onClick={() => {
                    actionProps[item].func(uniqueKey);
                  }}
                  key={index}
                >
                  {actionProps[item].icon || ""}
                </div>
              );
            })}
          </div>
        );
      },
    };
  };

  const getColumns = (_columns: any): ColumnsType<any> => {
    if (hasIndex) {
      _columns = [
        {
          title: "#",
          render: (_: any, __: any, index: number) => {
            if (!pagination) return index + 1;
            return (pagination.page - 1) * pagination.limit + (index + 1);
          },
        },
        ..._columns,
      ];
    }
    if (actions.length) {
      const _actions = getActions(actions);
      _columns = [..._columns, _actions];
    }
    return _columns.map((item: any) => {
      return item;
    });
  };

  const _getHasPagination = (): boolean => {
    if (!pagination) return false;
    const { total, limit } = pagination;
    return Math.ceil(total / limit) > 1;
  };

  const _hasPagination = _getHasPagination();

  const _footer = (
    <Row justify="end">
      <Col>
        {pagination && _hasPagination && (
          <Pagination
            onChange={onPageChange}
            current={pagination.page}
            total={pagination.total}
            pageSize={pagination.limit}
            showSizeChanger
          />
        )}
      </Col>
    </Row>
  );

  const _title = (
    <Row align="middle" justify="space-between">
      <Col>
        <span className="f-16 font-bold">{title}</span>
      </Col>
      <Col className="d-flex align-center">
        <span className="mr-3 color-gold">
          <i>{t("total")}</i> : {pagination?.total} {t("item")}
        </span>

        {pagination && !disablePagination && (
          <Pagination
            onChange={onPageChange}
            current={pagination.page}
            total={pagination.total}
            pageSize={pagination.limit}
            showSizeChanger
          />
        )}
      </Col>
    </Row>
  );

  const _columns = getColumns(columns);
  const antIcon = (
    <Image src={loadding} preview={false} style={{ fontSize: "60px" }} />
  );
  const tableLoading = {
    spinning: isLoadingTable,
    indicator: <Spin indicator={antIcon} />,
  };

  return (
    <div>
      <Table
        className="p-0 custom-table"
        title={() => _title}
        footer={() => _footer}
        pagination={{
          total: pagination?.total,
          pageSize: pagination?.limit,
          position: [],
        }}
        columns={_columns}
        dataSource={dataSource}
        rowKey={rowKey}
        loading={tableLoading}
        scroll={{ x: "auto" }}
      />
    </div>
  );
};

export default CustomHistoryRepairing;
