import axios from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { throwResponse } from "../config/axios";

interface IContentAboutUs {
  id: number;
  nameTh: string;
  nameEn: string;
  createdAt: string;
  deletedAt: string;
  status: string;
  reach: number;
  updatedAt: string;
}

export const GET_CONTENT_ABOUT_US = (params?: {}): UseQueryResult<
  IContentAboutUs[]
> => {
  return useQuery(["contents-about-us", params], async () => {
    const res = await axios.get("/api/content", { params });
    const { data } = res.data;
    if (res.status === 200) {
      return data;
    }
    throwResponse(res);
  });
};

export const GET_CONTENT_ABOUT_US_ID = (
  id: string
): UseQueryResult<IContentAboutUs> => {
  return useQuery(["content-about-us", id], async () => {
    if (!id) return;
    const res = await axios.get(`/api/content/${id}`);
    if (res.status === 200) {
      const { data } = res.data;
      return data;
    }
    throwResponse(res);
  });
};

export const PATCH_CONTENT_ABOUT_US = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.patch(`/api/content/${data.id}`, data);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};
