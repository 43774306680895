import { IRoute } from "../layout/content";

import {
  DASHBOARD_PATH,
  NEWS_PATH,
  PARCEL_PATH,
  PAYMENT_PATH,
  PRIVILEGE_PATH,
  SETTING_FACILITY_CATEGORY_PATH,
  SETTING_FACILITY_PATH,
  SETTING_PATH,
  SETTING_REPAIRING_CATEGORY_PATH,
  SETTING_REPAIRING_ZONE_PATH,
  SETTING_BILL_TYPE_PATH,
  UNIT_PATH,
  SETTING_SERVICE_CATEGORY_PATH,
  SETTING_CONTENT_ABOUT_US_PATH,
  NEWS_CREATE_PATH,
  SETTING_FACILITY_CATEGORY_CREATE_PATH,
  USER_MANAGEMENT_RESIDENT_PATH,
  USER_MANAGEMENT_ADMIN_PATH,
  SETTING_REPAIRING_ZONE_CREATE_PATH,
  SETTING_FACILITY_CREATE_PATH,
  SETTING_REPAIRING_CATEGORY_CREATE_PATH,
  UNIT_CREATE_PATH,
  SETTING_SERVICE_CATEGORY_CREATE_PATH,
  ROLE_PATH,
  HOMECARE_PRIVATE_AREA_REPAIRING_PATH,
  PARCEL_CREATE_PATH,
  HOMECARE_REPAIRING_SETUP_PATH,
  NOT_AUTHORIZED,
  SETTING_REPAIRING_ITEM_PATH,
  SETTING_REPAIRING_ITEM_CREATE_PATH,
  FACILITY_LIST_BOOKING_PATH,
  FACILITY_LIST_CREATE_BOOKING_PATH,
  FACILITY_LIST_CALENDAR_PATH,
  SETTING_BILL_TYPE_CREATE_PATH,
  PAYMENT_CREATE_PATH,
  FOOD_LIST_PATH,
  FOOD_ORDER_PATH,
  SERVICE_LIST_PATH,
  SERVICE_ORDER_PATH,
  LAUNDRY_LIST_PATH,
  LAUNDRY_ORDER_PATH,
  PAYMENT_LIST_PATH,
  PAYMENT_LIST_CREATE_PATH,
} from "./path";

import NewsPage from "../views/news/news-index";
import BillTypePage from "../views/setting/bill-type/bill-type-index";
import FacilityCategoryPage from "../views/setting/facility-category/facility-category-index";
import FacilityPage from "../views/setting/facility/facility-index";
import RepainingZonePage from "../views/setting/repairing-zone/repairing-zone-index";
import RepainingCategoryPage from "../views/setting/repairing-category/repairing-category-index";
import ServiceCategoryPage from "../views/setting/service-category/service-category-index";
// import ServiceListsPage from "../views/setting/service-lists/service-lists-index";
import ContentAboutUsPage from "../views/setting/content-about-us/content-about-us-index";
import EditNews from "../views/news/news-edit";
import FacilityCategoryEdit from "../views/setting/facility-category/facility-category-edit";
import UserResidentPage from "../views/user/resident/resident-index";
import UserAdminPage from "../views/user/admin/admin-index";
import ContentAboutUsEdit from "../views/setting/content-about-us/content-about-us-edit";
import RepairingZoneEdit from "../views/setting/repairing-zone/repairing-zone-edit";
import FacilityEdit from "../views/setting/facility/facility-edit";
import RepairingCategoryEdit from "../views/setting/repairing-category/repairing-category-edit";
import UnitPage from "../views/units/units-index";
import UnitEdit from "../views/units/units-edit";
import ParcelPage from "../views/parcel/parcel-index";
import CreateParcel from "../views/parcel/parcel-create";
import EditParcel from "../views/parcel/parcel-edit";
import ServiceCategoryEdit from "../views/setting/service-category/service-category-edit";
import Privilege from "../views/privilege/privilege-index";
import PrivilegeEdit from "../views/privilege/privilege-edit";
import RolePage from "../views/setting/role/role-index";
import UserAdminEditPage from "../views/user/admin/admin-edit";
import UserResidentEditPage from "../views/user/resident/resident-edit";
import RepairingPage from "../views/home-care/repairing/repairing-index";
import RepairingEdit from "../views/home-care/repairing/repairing-edit";
import NotAuthorized from "../pages/not-authorized";
import RepairSetup from "../views/home-care/repair-setup/repair-setup-index";
import RepairingItemEdit from "../views/setting/repairing-item/repairing-item-edit";
import RepairingItemPage from "../views/setting/repairing-item/repairing-item-index";
import FacilityBookingPage from "../views/facility-list/facility-booking/facility-booking-index";
import EditFacilityBooking from "../views/facility-list/facility-booking/facility-booking-edit";
import FacilityCalendarPage from "../views/facility-list/facility-calendar/facility-calendar-index";
import BillTypeEdit from "../views/setting/bill-type/bill-type-edit";
import RepairingCreate from "../views/home-care/repairing/repairing-create";
import Payment from "../views/payment/payment-index";
import EditPayment from "../views/payment/payment-edit";
// import ServiceListsEditPage from "../views/setting/service-lists/service-list-edit";
import DashBoard from "../views/dashboard/dashboard-index";
import FoodListEditPage from "../views/food/food-list/food-list-edit";
import FoodListPage from "../views/food/food-list/food-list-index";
import FoodOrderPage from "../views/food/food-order/food-order-index";
import FoodOrderEditPage from "../views/food/food-order/food-order-edit";
import ServiceListPage from "../views/service/service-list/service-list-index";
import ServiceListEditPage from "../views/service/service-list/service-list-edit";
import ServiceOrderPage from "../views/service/service-order/service-order-index";
import ServiceOrderEditPage from "../views/service/service-order/service-order-edit";
import LaundryListPage from "../views/laundry/laundry-lists/laundry-lists-index";
import LaundryListEditPage from "../views/laundry/laundry-lists/laundry-lists-edit";
import LaundryOrderEditPage from "../views/laundry/laundry-order/laundry-order-edit";
import LaundryOrderPage from "../views/laundry/laundry-order/laundry-order-index";
import PaymentLists from "../views/payment/payment-list-index";
import EditPaymentList from "../views/payment/payment-list-edit";

const group_dashboard: IRoute[] = [
  {
    name: "dashboard",
    path: DASHBOARD_PATH,
    component: DashBoard,
  },
];

const group_not_authorized: IRoute[] = [
  {
    name: "not-authorized",
    path: NOT_AUTHORIZED,
    component: NotAuthorized,
  },
];

const group_news: IRoute[] = [
  {
    name: "news-create",
    path: NEWS_CREATE_PATH,
    component: EditNews,
  },
  {
    name: "news-edit",
    path: `${NEWS_PATH}/:id(\\d+)`,
    component: EditNews,
  },
  {
    name: "news",
    path: NEWS_PATH,
    component: NewsPage,
    exact: true,
  },
];

const group_privilege: IRoute[] = [
  {
    name: "privilege",
    path: PRIVILEGE_PATH,
    component: Privilege,
    exact: true,
  },
  {
    name: "privilege-create",
    path: `${PRIVILEGE_PATH}/create`,
    component: PrivilegeEdit,
  },
  {
    name: "privilege-edit",
    path: `${PRIVILEGE_PATH}/:id(\\d+)`,
    component: PrivilegeEdit,
  },
];

const group_homecare: IRoute[] = [
  {
    name: "homecare-repairing",
    path: HOMECARE_PRIVATE_AREA_REPAIRING_PATH.index,
    exact: true,
    component: RepairingPage,
  },
  {
    name: "homecare-repairing-create",
    path: HOMECARE_PRIVATE_AREA_REPAIRING_PATH.create,
    component: RepairingCreate,
  },
  {
    name: "homecare-repairing-edit",
    path: HOMECARE_PRIVATE_AREA_REPAIRING_PATH.edit,
    component: RepairingEdit,
  },
  {
    name: "homecare-repair_setup",
    path: HOMECARE_REPAIRING_SETUP_PATH.index,
    exact: true,
    component: RepairSetup,
  },
  // {
  //   name: "homecare-repair_setup-create",
  //   path: HOMECARE_REPAIRING_SETUP_PATH.create,
  //   component: () => (
  //     <div>
  //       <h1>hello world</h1>
  //     </div>
  //   ),
  // },
  // {
  //   name: "homecare-repair_setup-edit",
  //   path: HOMECARE_REPAIRING_SETUP_PATH.edit,
  //   component: () => (
  //     <div>
  //       <h1>hello world</h1>
  //     </div>
  //   ),
  // },
];

const group_payment: IRoute[] = [
  {
    name: "payment-edit",
    path: `${PAYMENT_PATH}/:id(\\d+)`,
    component: EditPayment,
  },
  {
    name: "payment-create",
    path: PAYMENT_CREATE_PATH,
    component: EditPayment,
  },
  {
    name: "payment",
    path: PAYMENT_PATH,
    component: Payment,
    exact: true,
  },
];

const group_payment_list: IRoute[] = [
  {
    name: "payment-list-edit",
    path: `${PAYMENT_LIST_PATH}/:id(\\d+)`,
    component: EditPaymentList,
  },
  {
    name: "payment-list-create",
    path: PAYMENT_LIST_CREATE_PATH,
    component: EditPaymentList,
  },
  {
    name: "payment-list",
    path: PAYMENT_LIST_PATH,
    component: PaymentLists,
    exact: true,
  },
];

const group_parcel: IRoute[] = [
  {
    name: "parcel-create",
    path: PARCEL_CREATE_PATH,
    component: CreateParcel,
  },
  {
    name: "parcel-edit",
    path: `${PARCEL_PATH}/:id(\\d+)`,
    component: EditParcel,
  },
  {
    name: "parcel",
    path: PARCEL_PATH,
    component: ParcelPage,
    exact: true,
  },
];

const group_facility_list: IRoute[] = [
  {
    name: "facility-list-booking",
    path: FACILITY_LIST_BOOKING_PATH,
    component: FacilityBookingPage,
    exact: true,
  },
  {
    name: "facility-list-booking-edit",
    path: `${FACILITY_LIST_BOOKING_PATH}/:id(\\d+)`,
    component: EditFacilityBooking,
  },
  {
    name: "facility-list-booking-create",
    path: FACILITY_LIST_CREATE_BOOKING_PATH,
    component: EditFacilityBooking,
  },
  {
    name: "facility-list-calendar",
    path: FACILITY_LIST_CALENDAR_PATH,
    component: FacilityCalendarPage,
    exact: true,
  },
];

const group_service: IRoute[] = [
  {
    name: "service-list",
    path: SERVICE_LIST_PATH.index,
    exact: true,
    component: ServiceListPage,
  },
  {
    name: "service-list-edit",
    path: SERVICE_LIST_PATH.edit,
    component: ServiceListEditPage,
  },
  {
    name: "service-list-create",
    path: SERVICE_LIST_PATH.create,
    component: ServiceListEditPage,
  },
  {
    name: "service-order",
    path: SERVICE_ORDER_PATH.index,
    exact: true,
    component: ServiceOrderPage,
  },
  {
    name: "service-order-edit",
    path: SERVICE_ORDER_PATH.edit,
    component: ServiceOrderEditPage,
  },
  {
    name: "service-order-create",
    path: SERVICE_ORDER_PATH.create,
    component: ServiceOrderEditPage,
  },
];

const group_user_management: IRoute[] = [
  {
    name: "user-management-resident",
    path: USER_MANAGEMENT_RESIDENT_PATH.index,
    exact: true,
    component: UserResidentPage,
  },
  {
    name: "user-management-resident-create",
    path: USER_MANAGEMENT_RESIDENT_PATH.create,
    component: UserResidentEditPage,
  },
  {
    name: "user-management-resident-edit",
    path: USER_MANAGEMENT_RESIDENT_PATH.edit,
    component: UserResidentEditPage,
  },
  {
    name: "user-management-admin",
    path: USER_MANAGEMENT_ADMIN_PATH.index,
    exact: true,
    component: UserAdminPage,
  },
  {
    name: "user-management-admin-create",
    path: USER_MANAGEMENT_ADMIN_PATH.create,
    component: UserAdminEditPage,
  },
  {
    name: "user-management-admin-edit",
    path: USER_MANAGEMENT_ADMIN_PATH.edit,
    component: UserAdminEditPage,
  },
];

const group_setting: IRoute[] = [
  {
    name: "setting-content-about-us-edit",
    path: `${SETTING_CONTENT_ABOUT_US_PATH}/:id(\\d+)`,
    component: ContentAboutUsEdit,
  },
  {
    name: "setting-content-about-us",
    path: SETTING_CONTENT_ABOUT_US_PATH,
    component: ContentAboutUsPage,
    exact: true,
  },
  // {
  //   name: "setting-service-lists",
  //   path: SETTING_SERVICE_LISTS_PATH,
  //   component: ServiceListsPage,
  //   exact: true,
  // },
  // {
  //   name: "setting-service-lists-edit",
  //   path: `${SETTING_SERVICE_LISTS_PATH}/:id(\\d+)`,
  //   component: ServiceListsEditPage,
  // },
  // {
  //   name: "setting-service-lists-create",
  //   path: SETTING_SERVICE_LISTS_CREATE_PATH,
  //   component: ServiceListsEditPage,
  // },
  {
    name: "setting-service-category",
    path: SETTING_SERVICE_CATEGORY_PATH,
    component: ServiceCategoryPage,
    exact: true,
  },
  {
    name: "setting-service-category-edit",
    path: `${SETTING_SERVICE_CATEGORY_PATH}/:id(\\d+)`,
    component: ServiceCategoryEdit,
  },
  {
    name: "setting-service-category-create",
    path: SETTING_SERVICE_CATEGORY_CREATE_PATH,
    component: ServiceCategoryEdit,
  },
  {
    name: "setting-bill-type",
    path: SETTING_BILL_TYPE_PATH,
    component: BillTypePage,
    exact: true,
  },
  {
    name: "setting-bill-type-edit",
    path: `${SETTING_BILL_TYPE_PATH}/:id(\\d+)`,
    component: BillTypeEdit,
  },
  {
    name: "setting-bill-type-create",
    path: SETTING_BILL_TYPE_CREATE_PATH,
    component: BillTypeEdit,
  },
  {
    name: "setting-facility-category",
    path: SETTING_FACILITY_CATEGORY_PATH,
    component: FacilityCategoryPage,
    exact: true,
  },
  {
    name: "setting-facility-category-create",
    path: SETTING_FACILITY_CATEGORY_CREATE_PATH,
    component: FacilityCategoryEdit,
  },
  {
    name: "setting-facility-category-edit",
    path: `${SETTING_FACILITY_CATEGORY_PATH}/:id(\\d+)`,
    component: FacilityCategoryEdit,
  },
  {
    name: "setting-facility",
    path: SETTING_FACILITY_PATH,
    component: FacilityPage,
    exact: true,
  },
  {
    name: "setting-facility-create",
    path: SETTING_FACILITY_CREATE_PATH,
    component: FacilityEdit,
  },
  {
    name: "setting-facility-edit",
    path: `${SETTING_FACILITY_PATH}/:id(\\d+)`,
    component: FacilityEdit,
  },
  {
    name: "setting-repairing-item",
    path: SETTING_REPAIRING_ITEM_PATH,
    component: RepairingItemPage,
    exact: true,
  },
  {
    name: "setting-repairing-item-create",
    path: SETTING_REPAIRING_ITEM_CREATE_PATH,
    component: RepairingItemEdit,
  },
  {
    name: "setting-repairing-item-edit",
    path: `${SETTING_REPAIRING_ITEM_PATH}/:id(\\d+)`,
    component: RepairingItemEdit,
  },
  {
    name: "setting-repairing-zone",
    path: SETTING_REPAIRING_ZONE_PATH,
    component: RepainingZonePage,
    exact: true,
  },
  {
    name: "setting-repairing-zone-edit",
    path: `${SETTING_REPAIRING_ZONE_PATH}/:id(\\d+)`,
    component: RepairingZoneEdit,
  },
  {
    name: "setting-repairing-zone-create",
    path: SETTING_REPAIRING_ZONE_CREATE_PATH,
    component: RepairingZoneEdit,
  },
  {
    name: "setting-repairing-category",
    path: SETTING_REPAIRING_CATEGORY_PATH,
    component: RepainingCategoryPage,
    exact: true,
  },
  {
    name: "setting-repairing-category-create",
    path: SETTING_REPAIRING_CATEGORY_CREATE_PATH,
    component: RepairingCategoryEdit,
  },
  {
    name: "setting-repairing-category-edit",
    path: `${SETTING_REPAIRING_CATEGORY_PATH}/:id(\\d+)`,
    component: RepairingCategoryEdit,
  },
  {
    name: "setting-roles",
    path: ROLE_PATH.index,
    component: RolePage,
  },
  {
    name: "setting",
    path: SETTING_PATH,
    component: () => <></>,
  },
];

const group_units: IRoute[] = [
  {
    name: "unit-create",
    path: UNIT_CREATE_PATH,
    component: UnitEdit,
  },
  {
    name: "unit-edit",
    path: `${UNIT_PATH}/:id(\\d+)/:tab`,
    component: UnitEdit,
  },
  {
    name: "units",
    path: UNIT_PATH,
    component: UnitPage,
    exact: true,
  },
];

const group_roles: IRoute[] = [
  {
    name: "roles",
    path: ROLE_PATH.index,
    component: RolePage,
  },
];

const group_foods: IRoute[] = [
  {
    name: "food-lists-create",
    path: FOOD_LIST_PATH.create,
    component: FoodListEditPage,
  },
  {
    name: "food-lists-edit",
    path: FOOD_LIST_PATH.edit,
    component: FoodListEditPage,
  },
  {
    name: "food-list",
    path: FOOD_LIST_PATH.index,
    component: FoodListPage,
    exact: true,
  },
  {
    name: "food-orders-create",
    path: FOOD_ORDER_PATH.create,
    component: FoodOrderEditPage,
  },
  {
    name: "food-orders-edit",
    path: FOOD_ORDER_PATH.edit,
    component: FoodOrderEditPage,
  },
  {
    name: "food-order",
    path: FOOD_ORDER_PATH.index,
    component: FoodOrderPage,
    exact: true,
  },
];

const group_laundry: IRoute[] = [
  {
    name: "laundry-orders-create",
    path: LAUNDRY_ORDER_PATH.create,
    component: LaundryOrderEditPage,
  },
  {
    name: "laundry-orders-edit",
    path: LAUNDRY_ORDER_PATH.edit,
    component: LaundryOrderEditPage,
  },
  {
    name: "laundry-orders-index",
    path: LAUNDRY_ORDER_PATH.index,
    component: LaundryOrderPage,
    exact: true,
  },
  {
    name: "laundry-lists-create",
    path: LAUNDRY_LIST_PATH.create,
    component: LaundryListEditPage,
  },
  {
    name: "laundry-lists-edit",
    path: LAUNDRY_LIST_PATH.edit,
    component: LaundryListEditPage,
  },
  {
    name: "laundry-lists-index",
    path: LAUNDRY_LIST_PATH.index,
    component: LaundryListPage,
    exact: true,
  },
];

export const routes: IRoute[] = [
  ...group_dashboard,
  ...group_news,
  ...group_privilege,
  ...group_homecare,
  ...group_payment,
  ...group_payment_list,
  ...group_parcel,
  ...group_facility_list,
  ...group_service,
  ...group_user_management,
  ...group_setting,
  ...group_units,
  ...group_roles,
  ...group_foods,
  ...group_not_authorized,
  ...group_laundry,
];
