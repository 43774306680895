import { Col, Form, Input, Row, Select } from "antd";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { useEffect, useReducer, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomDatePicker from "../../../components/custom-date-picker";
import CustomSelect from "../../../components/custom-select";
import PageHeaderEdit from "../../../components/page-header-create";
import { validateMessages } from "../../../config/validateMessage";
import { GET_REPAIRING_ZONE, GET_USERS_MEMBER } from "../../../store";
import {
  GET_REPAIRING,
  POST_REPAIRING,
  POST_REPAIRING_WARRANTY,
} from "../../../store/repairing";
import { GET_REPAIRING_CATEGORY } from "../../../store/repairing-category";
import { GET_UNIT } from "../../../store/units";
import { swalError, swalSuccess } from "../../../tools/swal";
import { repairingType } from "./repairing-index";
import { useHistory } from "react-router";
import { useQueryClient } from "react-query";
import { repairingReducer, TRepairingReducer } from "./reducer";
import th from "antd/es/date-picker/locale/th_TH";
import en from "antd/es/date-picker/locale/en_US";
import dayjs from "dayjs";

const RepairingCreate = () => {
  const { t, i18n } = useTranslation();
  const [form] = useForm();
  const history = useHistory();
  const client = useQueryClient();

  const units = GET_UNIT({ pagination: false });
  const userMember = GET_USERS_MEMBER({ pagination: false });
  const getRepairing = GET_REPAIRING({ pagination: false });
  const getRepairingZone = GET_REPAIRING_ZONE({
    pagination: false,
  });
  const getRepairingCategory = GET_REPAIRING_CATEGORY({
    pagination: false,
  });

  const [warrantyDate, setWarrantyDate] = useState<any>({});
  const [unitId, setUnitId] = useState("");

  const create = POST_REPAIRING();
  const warranty = POST_REPAIRING_WARRANTY();

  const [state, dispatch] = useReducer<TRepairingReducer>(repairingReducer, {
    repairingZone: [],
  });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const onFinish = (value: any) => {
    const {
      repairingType,
      repairingZone,
      unit,
      user,
      repairingCategory,
      repairingItem,
      detail,
      warrantyExpiredDate,
      reference,
    } = value;
    const dataValue = {
      repairingType: repairingType,
      repairingZone: repairingZone,
      repairingCategory: repairingCategory,
      repairingItem: repairingItem,
      detail: detail,
      unit: unit,
      user: user,
      status: "WAITING",
      warrantyExpiredDate: warrantyExpiredDate,
      isFree: warrantyDate?.isFree || undefined,
      isExpired: warrantyDate?.expired || undefined,
      reference: reference,
    };

    create.mutate(dataValue, {
      onError: (err: any) => {
        swalError(t, t("swal.please-complete-the-information"));
      },
      onSuccess: () => {
        client.invalidateQueries("get-repairing");
        swalSuccess(t);
        history.goBack();
      },
    });
  };

  const onValuesChange = (change: any) => {
    const { unit, user, repairingZone, repairingCategory } = change;

    if (unit) {
      setUnitId(unit);
      const findUnitUser = units.data?.data.find((e) => e.id === unit);
      dispatch({ type: "unit", data: findUnitUser?.userUnits });
      form.setFieldsValue({
        user: undefined,
        email: undefined,
        phoneNumber: undefined,
        lineName: undefined,
      });
    }

    if (user) {
      const findUserMember = userMember.data?.data.find((e) => e.id === user);
      form.setFieldsValue({
        email: findUserMember?.email,
        phoneNumber: findUserMember?.phoneNumber,
        lineName: findUserMember?.lineName,
      });
    }

    if (repairingZone) {
      const repairZone = getRepairingZone.data?.data.find(
        (e) => e.id === repairingZone
      );

      const repairType =
        repairZone?.isPrivateArea === false ? "COMMON" : "PRIVATE";

      form.setFieldsValue({
        repairingCategory: undefined,
        repairingItem: undefined,
        repairingType: repairType,
      });
    }

    if (repairingCategory) {
      form.setFieldsValue({
        repairingItem: undefined,
      });

      warranty.mutate(
        { repairingCategory: repairingCategory, unit: unitId },
        {
          onSuccess: (data: any) => {
            form.setFieldsValue({
              warrantyExpiredDate: dayjs(data.data.warrantyExpiredDate),
            });
            setWarrantyDate(data.data);
          },
          onError: (error: any) => {
            swalError(t, error.message);
          },
        }
      );
    }
  };

  const FormEdit = () => {
    return (
      <Row gutter={[24, 24]} className="px-4 mb-4 pb-2">
        <Col span={12}>
          <Form.Item
            name="unit"
            label={t("house-number")} //unit number to use, house number to show
            rules={[{ required: true }]}
            className="custom-select-ant "
          >
            <CustomSelect
              hasTranslation={true}
              placeholder={t("house-number")} //unit number to use, house number to show
              allowClear={true}
              optionValue={{
                values: units.data?.data || [],
                key: "id",
                name: "unitNumber",
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="user"
            label={t("name")}
            rules={[{ required: true }]}
            className="custom-select-ant "
          >
            <Select
              placeholder={`${t("name")}`}
              disabled={!state.unitUser}
              allowClear
              showSearch
              optionFilterProp="children"
              getPopupContainer={(value) => {
                return value;
              }}
            >
              {state?.unitUser?.map((item, index) => {
                return (
                  <Select.Option key={index} value={item.user.id}>
                    {item.user.fullName}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="email"
            label={
              <div>
                {t("email")}{" "}
                <span style={{ color: "#b69e4f" }}>
                  ({t("can't-edit-information")})
                </span>
              </div>
            }
            className="custom-input"
          >
            <Input placeholder={t("email")} disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="phoneNumber"
            label={
              <div>
                {t("phone-number")}{" "}
                <span style={{ color: "#b69e4f" }}>
                  ({t("can't-edit-information")})
                </span>
              </div>
            }
            className="custom-input"
          >
            <Input placeholder={t("phone-number")} disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="lineName"
            label={
              <div>
                {t("line-name")}{" "}
                <span style={{ color: "#b69e4f" }}>
                  ({t("can't-edit-information")})
                </span>
              </div>
            }
            className="custom-input"
          >
            <Input placeholder={t("line-name")} disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          {
            <Form.Item shouldUpdate={(prev, next) => prev.unit !== next.unit}>
              {(form: FormInstance) => {
                const unit = form.getFieldValue("unit");
                return (
                  <Form.Item
                    name="repairingZone"
                    label={t("repairing-zone")}
                    className="custom-select-ant "
                  >
                    <CustomSelect
                      allowClear={true}
                      disabled={!unit}
                      hasTranslation={true}
                      showSearch={true}
                      placeholder={t("repairing-zone")}
                      inactive={true}
                      optionValue={{
                        values: getRepairingZone.data?.data || [],
                        key: "id",
                        name: `${i18n.language === "en" ? "nameEn" : "nameTh"}`,
                      }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          }
        </Col>

        <Col span={12}>
          <Form.Item
            name="repairingType"
            label={t("repairing-type")}
            className="custom-select-ant "
          >
            <CustomSelect
              disabled
              allowClear={true}
              hasTranslation={true}
              showSearch={false}
              placeholder={t("repairing-type")}
              optionValue={{
                values: repairingType || [],
                key: "key",
                name: "name",
              }}
            />
          </Form.Item>
        </Col>

        <Col span={12}>
          {
            <Form.Item
              shouldUpdate={(prev, next) =>
                prev.repairingZone !== next.repairingZone
              }
            >
              {(form: FormInstance) => {
                const repairingZone = form.getFieldValue("repairingZone");
                const find = getRepairingZone.data?.data.find(
                  (e) => e.id === repairingZone
                );
                return (
                  <Form.Item
                    name="repairingCategory"
                    label={t("repairing-category")}
                    className="custom-select-ant "
                  >
                    <CustomSelect
                      allowClear={true}
                      disabled={!repairingZone}
                      hasTranslation={true}
                      showSearch={true}
                      inactive={true}
                      placeholder={t("repairing-category")}
                      optionValue={{
                        values: find?.repairingCategories || [],
                        key: "id",
                        name: `${i18n.language === "en" ? "nameEn" : "nameTh"}`,
                      }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          }
        </Col>
        <Col span={12}>
          <Form.Item
            name="warrantyExpiredDate"
            label={t("warranty-expire-date")}
            className="date-picker-style"
          >
            <CustomDatePicker
              locale={i18n.language === "en" ? en : th}
              format={i18n.language === "en" ? "DD-MM-YYYY" : "DD-MM-BBBB"}
              disabled
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          {
            <Form.Item
              shouldUpdate={(prev, next) =>
                prev.repairingCategory !== next.repairingCategory
              }
            >
              {(form: FormInstance) => {
                const repairingCategory =
                  form.getFieldValue("repairingCategory");
                const find = getRepairingCategory.data?.data.find(
                  (e) => e.id === repairingCategory
                );
                return (
                  <Form.Item
                    name="repairingItem"
                    label={t("repairing-item")}
                    className="custom-select-ant "
                  >
                    <CustomSelect
                      allowClear={true}
                      disabled={!repairingCategory}
                      hasTranslation={true}
                      showSearch={true}
                      inactive={true}
                      placeholder={t("repairing-item")}
                      optionValue={{
                        values: find?.repairingItems || [],
                        key: "id",
                        name: `${i18n.language === "en" ? "nameEn" : "nameTh"}`,
                      }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          }
        </Col>
        <Col span={12}>
          {
            <Form.Item shouldUpdate={(prev, next) => prev.unit !== next.unit}>
              {(form: FormInstance) => {
                const unit = form.getFieldValue("unit");
                return (
                  <Form.Item
                    name="reference"
                    label={t("reference")}
                    className="custom-select-ant "
                  >
                    <Select
                      placeholder={t("reference")}
                      disabled={!unit}
                      showSearch
                      optionFilterProp="children"
                      getPopupContainer={(value) => {
                        return value;
                      }}
                      allowClear
                    >
                      {getRepairing.data?.data.map((item, index) => {
                        return (
                          <Select.Option key={index} value={item.reference}>
                            {item.repairingCode}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                );
              }}
            </Form.Item>
          }
        </Col>
        <Col span={24}>
          {
            <Form.Item shouldUpdate={(prev, next) => prev.unit !== next.unit}>
              {(form: FormInstance) => {
                const unit = form.getFieldValue("unit");
                return (
                  <Form.Item
                    name="detail"
                    label={t("details")}
                    className="custom-input"
                  >
                    <Input.TextArea
                      placeholder={t("details")}
                      rows={4}
                      disabled={!unit}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          }
        </Col>
      </Row>
    );
  };

  return (
    <>
      <div className="bg-white color-black pb-1 w-100">
        <Form
          onValuesChange={onValuesChange}
          layout="vertical"
          form={form}
          onFinish={onFinish}
          validateMessages={validateMessages}
          className="fixed-input-size"
        >
          <PageHeaderEdit
            label="menu.homecare-repairing"
            labelTo="menu.homecare-repairing"
            isLoading={create.isLoading}
          />
          <FormEdit />
        </Form>
      </div>
    </>
  );
};

export default RepairingCreate;
