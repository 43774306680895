import { Col, Divider, Row } from "antd";
import { useTranslation } from "react-i18next";
import { GET_REPAIRING_REF, IRepairing } from "../../../store/repairing";
import { parse } from "../../../tools/datetime";

const _colors_status = [
  { key: "WAITING", color: "#EBD05F", name: "waiting" },
  { key: "PENDING", color: "#DF9C3F", name: "pending" },
  { key: "PROCESSING", color: "#A455E8", name: "processing" },
  { key: "CHECKING", color: "#5FA3FF", name: "checking" },
  { key: "COMPLETE", color: "#009681", name: "complete" },
  { key: "CANCEL", color: "#E61E1E", name: "cancel" },
];

const Reference = ({ referenceId }: { referenceId: string | undefined }) => {
  const { t } = useTranslation();
  const { data } = GET_REPAIRING_REF(referenceId);
  if (data === undefined) return <></>;
  return (
    <div className="bg-white color-black w-100 mt-4">
      <div className="p-4  w-100">
        <span className="f-16">{t("reference")}</span>
        <Row className="w-100 pt-3" gutter={[16, 16]}>
          {data?.map((item, index) => {
            return (
              <Col key={index} span={8}>
                <CardReference item={item} />
              </Col>
            );
          })}
        </Row>
      </div>
    </div>
  );
};

const CardReference = ({ item }: { item: IRepairing }) => {
  const { t, i18n } = useTranslation();
  const {
    repairingZone,
    repairingCode,
    createdAt,
    repairingAppointments,
    status,
  } = item;
  const zone =
    i18n.language === "en" ? repairingZone?.nameEn : repairingZone?.nameTh;
  const statusColor = _colors_status.find((item) => item.key === status)?.color;
  const statusText = _colors_status.find((item) => item.key === status)?.name;
  const appointment = repairingAppointments?.sort((a, b) => {
    return a.id - b.id;
  });
  return (
    <div className="reference-card">
      <p className="pl-1">{zone || "-"}</p>
      <div className="body">
        <Col className="text-left" span={12}>
          <p>{t("job-number")}</p>
          <p>{t("created-at")}</p>
          <p>{t("appointment-date")}</p>
        </Col>
        <Col className="text-right" span={12}>
          <p>{repairingCode || "-"}</p>
          <p>
            {createdAt
              ? parse(
                  createdAt,
                  i18n.language,
                  `${
                    i18n.language === "en"
                      ? "DD MMM YYYY / HH:mm a"
                      : "DD MMM BBBB / HH:mm a"
                  }`
                )
              : "-"}
          </p>
          <p>
            {repairingAppointments.length > 0
              ? parse(
                  appointment[0].appointmentFrom,
                  i18n.language,
                  `${
                    i18n.language === "en"
                      ? "DD MMM YYYY / HH:mm a"
                      : "DD MMM BBBB / HH:mm a"
                  }`
                )
              : "-"}
          </p>
        </Col>
      </div>
      <Divider />
      <div className="pl-1 font-bold" style={{ color: statusColor }}>
        {t(`${statusText}`)}
      </div>
    </div>
  );
};

export default Reference;
