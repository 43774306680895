import { Col, Form, FormInstance, Input, Row, Select } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import CustomDatePicker from "../../../../components/custom-date-picker";
import { IUnit } from "../../../../store/units";

const UnitInput = ({
  units,
  id,
  form,
}: {
  units: IUnit[];
  id: string | undefined;
  form: any;
}) => {
  const { t, i18n } = useTranslation();

  const onSelectUnit = () => {
    const unitId = form.getFieldValue("unit");
    const unit = units?.find((item) => item.id === unitId);
    form.setFieldsValue({ unitReference: unit?.unitReference });
  };

  return (
    <Row gutter={[24, 24]} className="px-4 mb-4 pb-2">
      <Col span={12}>
        <Form.Item noStyle shouldUpdate>
          {(form: FormInstance) => {
            return (
              <Form.Item
                name="unit"
                label={t("house-number")} //unit number to use, house number to show
                rules={[{ required: true }]}
                className="custom-select-ant "
                getValueFromEvent={(unit) => {
                  form.setFieldsValue({
                    user: undefined,
                    phoneNumber: undefined,
                    lineName: undefined,
                    serviceCategory: undefined,
                    serviceItem: undefined,
                    bookingHour: undefined,
                    price: undefined,
                    setPrice: undefined,
                    bookingDate: undefined,
                    requirePrivate: undefined,
                  });
                  return unit;
                }}
              >
                <Select
                  placeholder={t("house-number")}
                  //unit number to use, house number to show
                  getPopupContainer={(value) => {
                    return value;
                  }}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                  disabled={!!id}
                  onChange={onSelectUnit}
                >
                  {units.map((item, key) => {
                    return (
                      <Select.Option key={key} value={item.id}>
                        {item.unitNumber}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item shouldUpdate={(prev, next) => prev.unit !== next.unit}>
          {(form: FormInstance) => {
            const unitId: number | undefined = form.getFieldValue("unit");
            const find = units.find((e) => e.id === unitId);

            return (
              <Form.Item
                name="user"
                label={`${t("name")}`}
                rules={[{ required: true }]}
                className="custom-select-ant "
                getValueFromEvent={(user) => {
                  const userName = find?.userUnits.find(
                    (e) => e.user.id === user
                  );

                  form.setFieldsValue({
                    lineName: userName?.user.lineName,
                    phoneNumber: userName?.user.phoneNumber,
                    serviceCategory: undefined,
                    serviceItem: undefined,
                    bookingHour: undefined,
                    price: undefined,
                    setPrice: undefined,
                    bookingDate: undefined,
                    requirePrivate: undefined,
                  });
                  return user;
                }}
              >
                <Select
                  placeholder={t("name")}
                  disabled={!find || !!id}
                  getPopupContainer={(value) => {
                    return value;
                  }}
                  allowClear
                >
                  {find?.userUnits?.map((item, index) => {
                    return (
                      <Select.Option key={index} value={item.user.id}>
                        {item.user.fullName}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="lineName"
          label={t("line-name")}
          className="custom-input"
        >
          <Input placeholder={t("line-name")} disabled />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="createdAt" label={t("required-date")}>
          <CustomDatePicker
            format={
              i18n.language === "en"
                ? "DD-MM-YYYY / HH:mm a"
                : "DD-MM-BBBB / HH:mm a"
            }
            disabled
            defaultValue={dayjs()}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => prev.user !== next.user}
        >
          {(form: FormInstance) => {
            const unitId = form.getFieldValue("unit");
            const userId = form.getFieldValue("user");
            return (
              <Form.Item
                name="phoneNumber"
                label={t("contact-number")}
                rules={[{ required: true }]}
              >
                <Input
                  placeholder={t("contact-number")}
                  disabled={!userId || !unitId}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="paymentDate" label={t("payment-date")}>
          <CustomDatePicker
            format={
              i18n.language === "en"
                ? "DD-MM-YYYY / HH:mm a"
                : "DD-MM-BBBB / HH:mm a"
            }
            disabled
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default UnitInput;
