import { Dayjs } from "dayjs";
import { Reducer } from "react";
import { IUserUnit } from "../../../store/units";
import { IFacility } from "../../../store/facility";
import { IBookingAvailable } from "../../../store/facility-booking";
export interface IBookingState {
  unitUser?: IUserUnit[];
  facilities?: IFacility[];
  available: IBookingAvailable[];
  facility?: IFacility;
  date?: Dayjs;
  startTime?: string;
  endTime?: string;
  maxBookingTime: number;
  phoneNumber?: string;
}

export type TBookingAction = {
  type:
    | "unit"
    | "available"
    | "facilities"
    | "facility"
    | "date"
    | "stateTime"
    | "endTime"
    | "maxBookingTime"
    | "phoneNumber";
  data?: any;
};

export type TBookingReducer = Reducer<IBookingState, TBookingAction>;

export const bookingReducer = (
  state: IBookingState,
  action: TBookingAction
) => {
  const { type, data } = action;
  switch (type) {
    case "unit":
      return { ...state, unitUser: data };

    case "facilities":
      return {
        ...state,
        facilities: data,
        available: [],
        facility: undefined,
        startTime: undefined,
        endTime: undefined,
        date: undefined,
        maxBookingTime: 0,
      };
    case "facility":
      return {
        ...state,
        facility: data,
        available: [],
        startTime: undefined,
        endTime: undefined,
        date: undefined,
        maxBookingTime: 0,
      };

    case "available":
      return {
        ...state,
        available: data,
        startTime: undefined,
        endTime: undefined,
        date: undefined,
        maxBookingTime: 0,
      };

    case "date":
      return {
        ...state,
        date: data,
        startTime: undefined,
        endTime: undefined,
        maxBookingTime: 0,
      };
    case "stateTime":
      return {
        ...state,
        startTime: data,
        endTime: undefined,
        maxBookingTime: 0,
      };
    case "endTime":
      return { ...state, endTime: data, maxBookingTime: 0 };
    case "maxBookingTime":
      return { ...state, maxBookingTime: data };
    case "phoneNumber":
      return { ...state, phoneNumber: data };
  }
};
