import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en.json";
import th from "./th.json";

export const setLocalLanguage = (lang: "en" | "th") => {
  localStorage.setItem("lang", lang);
};

export const getLocalLanguage = (): string => {
  const lang = localStorage.getItem("lang");
  return lang || "en";
};

export const appendLangToHtmlTag = (lang?: string) => {
  if (!lang) {
    lang = localStorage.getItem("lang") || "en";
  }
  document.getElementsByTagName("html")[0].setAttribute("lang", lang);
};

i18n.use(initReactI18next).init({
  fallbackLng: getLocalLanguage(),
  debug: false,
  resources: {
    en: {
      translation: en,
    },
    th: {
      translation: th,
    },
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
