import axios from "axios";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from "react-query";
import { exportFile, throwResponse } from "../config/axios";
import { getClientTimeZone } from "../tools/datetime";
import { IResponse } from "./global";
import { POST_MEDIA_OBJECT } from "./media";

interface IRepairingItem {
  id: number;
  createdAt: string;
  updatedAt: string;
  nameEn: string;
  nameTh: string;
}

interface ICreateBy {
  id: number;
  createdAt: string;
  updatedAt: string;
  fullName: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  avatar: IAvatar;
}

interface IUnit {
  id: number;
  createdAt: string;
  updatedAt: string;
  unitNumber: string;
}

interface IImage {
  id: number;
  filename: string;
  imageUrl: string;
  originalName: string;
}

interface IRepairMans {
  id: number;
  user: ICreateBy;
}

interface IAvatar {
  filename: string;
  id: number;
  imageUrl: string;
}

interface Irepair<T> {
  data: T;
  success: boolean;
}

export interface IRepairing {
  id: number;
  createdAt: string;
  updatedAt: string;
  status: string;
  repairingCode: string;
  repairingType: string;
  phoneNumber: string;
  detail: string;
  warrantyExpiredDate: string;
  transferredDate: string;
  createdBy: ICreateBy;
  updatedBy: ICreateBy;
  unit: IUnit;
  repairingCategory: IRepairingItem;
  repairingZone: IRepairingItem;
  repairingItem: IRepairingItem;
  user: ICreateBy;
  note: string;
  repairmans: IRepairMans[];
  repairmanSignImage: IImage;
  verificationSignImage: IImage;
  beforeImage: IImage;
  afterImage: IImage;
  finishDate: string;
  comment: string;
  isExpired: boolean | any;
  price: number;
  reference: string;
  repairingAppointments: [
    {
      appointmentFrom: string;
      id: number;
      createdAt: string;
    }
  ];
}

export interface IComment {
  id: string;
  comment: string;
  createdAt: string;
  updatedAt: string;
  createdBy: ICreateBy;
  repairing: IRepairing;
}

export interface IAppointment {
  id: number;
  createdAt: string;
  updatedAt: string;
  appointmentFrom: string;
  repairing: {
    id: number;
    createdAt: string;
    updatedAt: string;
    repairingCode: string;
    repairingType: string;
  };
  createdBy: ICreateBy;
  updatedBy: ICreateBy;
}

export const GET_REPAIRING = (params?: {}): UseQueryResult<
  IResponse<IRepairing[]>
> => {
  return useQuery(
    ["get-repairing", params],
    async () => {
      const res = await axios.get("/api/repairing", { params: params });
      if (res.status === 200) {
        const { data, count, page, countData } = res.data;
        const dataSome = data.map((item: any) => {
          const createdBy = item.createdBy?.fullName;
          return { ...item, createdBy: createdBy };
        });
        return { data: dataSome, count, page, countData };
      }
      throwResponse(res);
    },
    { retry: false, refetchOnWindowFocus: false }
  );
};

export const GET_REPAIRING_ID = (id: string): UseQueryResult<IRepairing> => {
  return useQuery(["get-repairing-id", id], async () => {
    if (!id) return;
    const res = await axios.get(`/api/repairing/${id}`);
    if (res.status === 200) {
      const { data } = res.data;
      return data;
    }
    throwResponse(res);
  });
};

export const POST_REPAIRING = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post("/api/repairing", data);
    if (res.status === 201) {
      return true;
    }
    throwResponse(res);
  });
};

export const PATCH_REPAIRING = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    let idRepairManSign: number | undefined;
    let idVerificationSign: number | undefined;
    let idImageBefore: number | undefined;
    let idImageAfter: number | undefined;

    if (data.beforeImage) {
      if (data.beforeImage.name) {
        const id = await POST_MEDIA_OBJECT(data.beforeImage);
        idImageBefore = id;
      }
    }

    if (data.afterImage) {
      if (data.afterImage.name) {
        const id = await POST_MEDIA_OBJECT(data.afterImage);
        idImageAfter = id;
      }
    }

    if (data.verificationSignImage) {
      if (data.verificationSignImage.name) {
        idVerificationSign = await POST_MEDIA_OBJECT(
          data.verificationSignImage
        );
      }
    }

    if (data.repairmanSignImage) {
      if (data.repairmanSignImage.name) {
        idRepairManSign = await POST_MEDIA_OBJECT(data.repairmanSignImage);
      }
    }

    data = {
      ...data,
      beforeImage: idImageBefore,
      afterImage: idImageAfter,
      verificationSignImage: idVerificationSign,
      repairmanSignImage: idRepairManSign,
    };

    const res = await axios.patch(`/api/repairing/${data.id}`, data);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const DELETE_REPAIRING = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/api/repairing/${data.id}`);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const BULK_DELETE_REPAIRING = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete("/api/repairing/bulk", {
      data: data,
    });
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const EXPORT_REPAIRING = (): UseMutationResult<Blob> => {
  return useMutation(
    async (data: any) => {
      const timeZone = getClientTimeZone();
      const res = await exportFile.post("/api/export-excel/repairing", {
        ...data,
        timeZone,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { retry: false }
  );
};

export const GET_REPAIRING_COMMENT = (params?: {}): UseQueryResult<
  IResponse<IComment[]>
> => {
  return useQuery(
    ["get-repairing-comment", params],
    async () => {
      const res = await axios.get("/api/repairing-comment", { params: params });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { retry: false, refetchOnWindowFocus: false }
  );
};

export const POST_REPAIRING_COMMENT = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post("/api/repairing-comment", data);
    if (res.status === 201) {
      return true;
    }
    throwResponse(res);
  });
};

export const POST_REPAIRING_WARRANTY = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post(
      `/api/repairing/cal-warranty-date?repairingCategory=${data.repairingCategory}&unit=${data.unit}`
    );
    if (res.status === 201) {
      return res.data;
    }
    throwResponse(res);
  });
};

export const GET_REPAIRING_MOBILE = (
  id: number,
  params?: {}
): UseQueryResult<Irepair<IRepairing[]>> => {
  return useQuery(
    ["get-repairing-mobile", id, params],
    async () => {
      const res = await axios.get(`/api/repairing/list/${id}`, {
        params: params,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { retry: false, refetchOnWindowFocus: false }
  );
};

export const GET_REPAIRING_APPOINTMENT = (params?: {}): UseQueryResult<
  IResponse<IAppointment[]>
> => {
  return useQuery(
    ["get-repairing-appointment", params],
    async () => {
      const res = await axios.get("/api/repairing-appointment", {
        params: params,
      });
      if (res.status === 200) {
        return res.data;
      }
      throwResponse(res);
    },
    { retry: false, refetchOnWindowFocus: false }
  );
};

export const POST_REPAIRING_APPOINTMENT = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post("/api/repairing-appointment", data);
    if (res.status === 201) {
      return true;
    }
    throwResponse(res);
  });
};

export const DELETE_REPAIRING_APPOINTMENT = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/api/repairing-appointment/${data.id}`);
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const GET_REPAIRING_REF = (
  referenceId: string | undefined
): UseQueryResult<IRepairing[]> => {
  return useQuery(["get-repairing-ref", referenceId], async () => {
    if (!referenceId) return;
    const res = await axios.get(`/api/repairing/ref/${referenceId}`);
    if (res.status === 200) {
      const { data } = res.data;
      return data;
    }
    throwResponse(res);
  });
};

export const LINE_NOTIFICATION_REPAIRING = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post(
      "/api/line-notification/send-message-repairing",
      data
    );
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};

export const LINE_NOTIFICATION_REPAIRING_CHECKING = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post(
      "/api/line-notification/send-message-repairing-checking",
      data
    );
    if (res.status === 200) {
      return true;
    }
    throwResponse(res);
  });
};
