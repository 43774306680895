import { Row, Col, Breadcrumb, Button, Divider } from "antd";
import { useTranslation } from "react-i18next";
import { UseMutationResult } from "react-query";
import { useHistory, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import LINE_ICON from "../assets/icons/page-header/line";
import { routes } from "../router/router";
import { swalError, swalSuccess } from "../tools/swal";
import { fixed_hieght } from "./page-header";

type TUtility = "line";

const PageHeaderEdit = ({
  utility = [],
  label,
  labelTo,
  isLoading,
  parcelAction,
  isDivider = true,
  lineMutation,
}: {
  utility?: TUtility[];
  label: string;
  labelTo?: string;
  isLoading?: boolean;
  parcelAction?: () => void;
  isDivider?: boolean;
  lineMutation?: UseMutationResult;
}) => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const { pathname } = useLocation();
  const { id } = useParams() as { id?: string };

  const _onLine = () => {
    lineMutation?.mutate(
      { ids: [id] },
      {
        onSuccess: () => {
          swalSuccess();
        },
        onError: (err: any) => {
          swalError(t, err.message);
        },
      }
    );
  };

  const utilityProps = {
    line: {
      func: _onLine,
      className: "line-icon",
      icon: LINE_ICON(),
      loading: lineMutation?.isLoading,
    },
  };

  const getUtility = (_utility: TUtility[]) => {
    return _utility.map((item, index) => {
      const { className, icon, func, loading } = utilityProps[item];
      return (
        <Col key={index}>
          <Button
            onClick={func}
            style={{
              width: fixed_hieght,
              height: fixed_hieght,
              padding: 12,
            }}
            className={className}
            icon={icon}
            loading={loading}
          />
        </Col>
      );
    });
  };

  const getBreadcrumb = () => {
    const element = pathname.split("/").splice(-2);
    const tabElement = pathname.split("/").slice(-3)[0];
    let origin = pathname.split("/");
    origin.pop();
    const [route] = routes.filter((item) => {
      const [split] = item.path.split("/").slice(-1);
      if (split === ":tab") {
        const tabSplit = item.path.split("/").slice(-3)[0];
        return tabSplit === tabElement;
      }
      return split === element[0];
    });

    let data = element.map((item) => {
      if (element[0] !== item) {
        return tabElement;
      }
      if (item === route.path.split("/").slice(-1)[0]) {
        return `menu.${route.name}`;
      }
      if (element[1] === "create") {
        return "create";
      }
      return item;
    });

    if (tabElement) data.reverse();

    if (!isNaN(+data[data.length - 1])) {
      data.splice(1, 0, "edit");
    }
    return (
      <Breadcrumb className="font-italic">
        {data.map((item, index) => {
          if (index === 0) {
            return (
              <Breadcrumb.Item
                className="color-gold"
                key={index}
                href={`#${origin.join("/")}`}
              >
                {t(item)}
              </Breadcrumb.Item>
            );
          }
          return (
            <Breadcrumb.Item className="cursor-default" key={index}>
              {t(item)}
            </Breadcrumb.Item>
          );
        })}
      </Breadcrumb>
    );
  };

  return (
    <div>
      <Row justify="space-between" className="px-4 pt-4">
        <Col>
          <p className="f-26 mb-1 font-bold">{t(label)}</p>
          {getBreadcrumb()}
        </Col>

        <Col>
          <Row gutter={[12, 12]}>
            {getUtility(utility)}
            <Col>
              <Button
                className="button-cancel f-18"
                style={{ width: 180, height: fixed_hieght }}
                onClick={() => goBack()}
              >
                {t("cancel")}
              </Button>
            </Col>
            <Col>
              {!parcelAction && (
                <Button
                  className="button-save f-18"
                  style={{ width: 180, height: fixed_hieght }}
                  onClick={() => {}}
                  htmlType="submit"
                  loading={isLoading}
                >
                  {t("save")}
                </Button>
              )}
              {parcelAction && (
                <Button
                  className="button-save f-18"
                  style={{ width: 180, height: fixed_hieght }}
                  onClick={() => {}}
                  htmlType="submit"
                  loading={isLoading}
                >
                  {t("add")}
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      {isDivider && <Divider />}
    </div>
  );
};

export default PageHeaderEdit;
