import {
  Badge,
  Button,
  Switch,
  Col,
  Form,
  Row,
  Table,
  Select,
  Typography,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import Modal from "antd/lib/modal/Modal";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CalendarDayJS from "../../../components/calendar-dayjs";
import { useQueryClient } from "react-query";
import CustomSelect from "../../../components/custom-select";
import PageHeader from "../../../components/page-header";
import { GET_USERS_ADMIN } from "../../../store";
import { GET_HOLIDAY } from "../../../store/homecare";
import {
  DELETE_REPAIR_SETUP,
  GET_REPAIR_SETUP,
  GET_REPAIR_SETUP_ID,
  PATCH_REPAIRING_SETUP,
  POST_REPAIRING_SETUP,
} from "../../../store/repair-setup";
import { GET_REPAIRING_CATEGORY } from "../../../store/repairing-category";
import { swalDelete, swalError, swalSuccess } from "../../../tools/swal";
import weekday from "dayjs/plugin/weekday";
import isoWeekDay from "dayjs/plugin/isoWeek";
import { FormRepeat } from "./form-repeat";
import { FormNotRepeat } from "./from-not-repeat";
import { validateMessages } from "../../../config/validateMessage";
import ImportExcel from "./import-excel";
import ExportExcel from "./export-excel";
import th from "antd/es/date-picker/locale/th_TH";
import en from "antd/es/date-picker/locale/en_US";
import localeData from "dayjs/plugin/localeData";

dayjs.extend(isoWeekDay);
dayjs.extend(weekday);
dayjs.extend(localeData);

const fieldsValue = {
  repairingCategory: undefined,
  time: undefined,
  user: undefined,
  day: undefined,
  month: undefined,
  year: undefined,
  date: undefined,
};
const now = dayjs();

const RepairSetup = () => {
  const { i18n, t } = useTranslation();
  const client = useQueryClient();
  const { data } = GET_HOLIDAY({ year: `${dayjs().year()}` });
  const [form] = useForm();
  const [formDate] = useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [importExcel, setImportExcel] = useState(false);
  const [exportExcel, setExportExcel] = useState(false);
  const [switchCheck, setSwitchCheck] = useState(false);
  const [editId, setEditId] = useState<string>("create");
  const [currentRange, setCurrentRange] = useState(now);
  const [id, setId] = useState<any>(undefined);
  const [dataInDay, setDataInDay] = useState<any>([]);
  const [items, setItems] = useState<any[]>([]);

  const repairSetup = GET_REPAIR_SETUP({ pagination: false });
  const admin = GET_USERS_ADMIN({ limit: 10, page: 1, pagination: false });
  const repairingCategory = GET_REPAIRING_CATEGORY({ pagination: false });
  const create = POST_REPAIRING_SETUP();
  const update = PATCH_REPAIRING_SETUP();
  const { mutate, isLoading } = DELETE_REPAIR_SETUP();

  //Sum Year
  let defaultYear = dayjs().year();
  const year = [];
  year.push({
    id: defaultYear,
    yearEn: defaultYear,
    yearTh: defaultYear + 543,
  });
  for (let i = 1; i <= 10; i++) {
    year.push({
      id: defaultYear + i,
      yearEn: defaultYear + i,
      yearTh: defaultYear + i + 543,
    });
  }

  const technical = admin.data?.data.filter(
    (item) => item.role.title === "REPAIRMAN"
  );

  const showModal = () => {
    setEditId("create");
    setIsModalVisible(true);
    setSwitchCheck(false);
    form.setFieldsValue(fieldsValue);
  };

  const showModalImport = () => {
    setImportExcel(true);
  };

  const showModalExport = () => {
    setExportExcel(true);
  };

  const showModalEdit = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setId(undefined);
    setIsModalVisible(false);
    setImportExcel(false);
    setExportExcel(false);
    setItems([]);
    formDate.setFieldsValue({ date: [undefined, undefined] });
  };

  const dateCellRender = (date: Dayjs): React.ReactNode => {
    const day = dayjs(date).format("YYYY-MM-DD");
    const holidays = data?.filter((item) => {
      return item.Date === day;
    });

    const dataRepair = repairSetup.data?.filter((item) => {
      const repairDate = dayjs(item.repairingSetupDate).format("YYYY-MM-DD");
      return repairDate === day;
    });

    return (
      <ul className="holiday-events">
        {holidays?.map((item, index) => {
          const desc =
            i18n.language === "en"
              ? item.HolidayDescription
              : item.HolidayDescriptionThai;
          return (
            <li key={index} className="pb-1">
              <Badge color="blue" text={desc} />
            </li>
          );
        })}
        {dataRepair?.map((item, index) => {
          return (
            <li key={index} className="pb-1">
              <Badge color="gold" text={item.user ? item.user.fullName : "-"} />
              {!item.repairingCategory ? (
                <div className="f-12 pl-3">-</div>
              ) : (
                <div className="f-12 pl-3">
                  {i18n.language === "en"
                    ? item.repairingCategory.nameEn
                    : item.repairingCategory.nameTh}
                </div>
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  const onSelect = (date: Dayjs) => {
    const day = date.format("YYYY-MM-DD");
    setEditId("select");
    setSwitchCheck(false);
    form.setFieldsValue(fieldsValue);
    const inRange =
      date.month() === currentRange.month() &&
      date.year() === currentRange.year();

    const dataRepair = repairSetup.data?.filter((item) => {
      const repairDate = dayjs(item.repairingSetupDate).format("YYYY-MM-DD");
      return repairDate === day;
    });
    setDataInDay(dataRepair);
    if (inRange) {
      showModalEdit();
    } else {
      setCurrentRange(date);
    }
  };

  const onChange = (value: any) => {
    setSwitchCheck(value);
  };

  const columns = [
    {
      title: t("repairman-name"),
      dataIndex: "fullName",
      render: (__: any, record: any) => {
        const { fullName } = record?.user;
        return <div>{fullName || "-"}</div>;
      },
    },
    {
      title: t("repairing-category"),
      dataIndex: i18n.language === "en" ? "nameEn" : "nameTh",
      width: "13vw",
      render: (__: any, record: any) => {
        if (!record.repairingCategory)
          return <div className="line-clamp">-</div>;
        const { nameEn, nameTh } = record.repairingCategory;
        return (
          <div className="line-clamp">
            {i18n.language === "en"
              ? nameEn
                ? nameEn
                : "-"
              : nameTh
              ? nameTh
              : "-"}
          </div>
        );
      },
    },
    {
      title: t("date"),
      dataIndex: "repairingSetupDate",
      width: "10vw",
      render: (__: any, record: any) => {
        return (
          <>
            <div>
              {record.repairingSetupDate
                ? dayjs(record.repairingSetupDate).format("DD MMM YYYY")
                : "-"}
            </div>
            <div>
              {` ${
                record.startTime
                  ? dayjs(record.startTime, "HH:mm")
                      .utcOffset(7)
                      .format("HH:mm")
                  : "-"
              } - 
              ${
                record.endTime
                  ? dayjs(record.endTime, "HH:mm").utcOffset(7).format("HH:mm")
                  : "-"
              }`}
            </div>
          </>
        );
      },
    },
    {
      title: t("edit"),
      dataIndex: "id",
      render: (__: any, record: any) => {
        return (
          <>
            <Button onClick={() => onEdit(record)} className="button-edit">
              {t("edit")}
            </Button>
          </>
        );
      },
    },

    {
      title: t("delete"),
      dataIndex: "id",
      render: (__: any, record: any) => {
        return (
          <>
            <Button
              onClick={() => onDelete(record.id)}
              className="button-delete"
              loading={isLoading}
            >
              {t("delete")}
            </Button>
          </>
        );
      },
    },
  ];

  const time = (time: any) => {
    return dayjs(time).format("HH:mm:ssZ");
  };

  const onFinish = (value: any) => {
    if (id) {
      const dataUpdate = {
        repairingCategory: value.repairingCategory,
        user: value.user,
        id: id,
      };
      update.mutate(dataUpdate, {
        onError: (err: any) => {
          swalError(t, t("swal.please-complete-the-information"));
        },
        onSuccess: async () => {
          client.invalidateQueries("get-repairing-setup");
          client.invalidateQueries(["get-repairing-setup-id", id]);
          setDataInDay(dataInDay.filter((item: any) => item.id !== id));
          const repairSetupId = await GET_REPAIR_SETUP_ID(id);
          const data = [
            repairSetupId,
            ...dataInDay.filter((item: any) => item.id !== id),
          ];
          setDataInDay(data);
          swalSuccess(t);
          form.setFieldsValue(fieldsValue);
        },
      });
    } else {
      const startTime = time(value.time[0]);
      const endTime = time(value.time[1]);
      delete value.time;
      value = {
        ...value,
        startTime: startTime,
        endTime: endTime,
      };
      if (value.date) {
        value = {
          ...value,
          repairingSetupDate: dayjs(value.date).format(),
        };
        create.mutate(value, {
          onError: (err: any) => {
            swalError(t, t("swal.please-complete-the-information"));
          },
          onSuccess: () => {
            client.invalidateQueries("get-repairing-setup");
            setIsModalVisible(false);
            swalSuccess(t);
          },
        });
      } else if (value.day && value.month && value.year) {
        const startDate = dayjs()
          .year(value.year)
          .month(value.month - 1)
          .startOf("month");
        const endDate = dayjs()
          .year(value.year)
          .month(value.month - 1)
          .endOf("month");
        sumDay(startDate, endDate, value);
      } else if (value.day && value.year) {
        const startDate = dayjs().year(value.year).startOf("year");
        const endDate = dayjs().year(value.year).endOf("year");
        sumYear(startDate, endDate, value);
      } else if (value.day && value.month) {
        const startDate = dayjs()
          .month(value.month - 1)
          .startOf("month");
        const endDate = dayjs()
          .month(value.month - 1)
          .endOf("month");
        sumDay(startDate, endDate, value);
      } else if (value.day) {
        const startDate = dayjs().startOf("month");
        const endDate = dayjs().endOf("month");
        sumDay(startDate, endDate, value);
      }
    }
  };

  const onEdit = (value: any) => {
    setEditId("edit");
    setId(value.id);
    const {
      user,
      repairingCategory,
      repairingSetupDate,
      startTime,
      endTime,
      day,
      month,
      year,
    } = value;

    value = {
      repairingCategory: repairingCategory ? repairingCategory.id : undefined,
      time: [dayjs(startTime, "HH:mm"), dayjs(endTime, "HH:mm")],
      user: user ? user.id : undefined,
      day: day || undefined,
      month: month || undefined,
      year: year || undefined,
      date: dayjs(repairingSetupDate),
    };
    if (value.day) {
      setSwitchCheck(true);
      form.setFieldsValue(value);
    } else {
      setSwitchCheck(false);
      form.setFieldsValue(value);
    }
  };

  const onDelete = async (id: string) => {
    const confirm = await swalDelete(t);
    if (confirm) {
      mutate(
        { id },
        {
          onError: (err: any) => {
            swalError(t, err.message);
          },
          onSuccess: () => {
            setDataInDay(dataInDay.filter((item: any) => item.id !== id));
            swalSuccess(t);
            repairSetup.refetch();
          },
        }
      );
    }
  };

  const sumYear = (startDate: any, endDate: any, value: any) => {
    const dayWeek = [];
    const dateDiff = endDate.diff(startDate, "week");
    for (let i = 0; i <= dateDiff; i++) {
      const dayoffweek = startDate.add(i, "week");
      const weekday = dayjs(dayoffweek)
        .weekday(value.day - 1)
        .format();
      if (dayjs(weekday).year() === dayjs(startDate).year()) {
        dayWeek.push(weekday);
      }
    }
    dayWeek.map((item, index) => {
      value = { ...value, repairingSetupDate: item };
      return create.mutate(value, {
        onError: (err: any) => {
          swalError(t, t("swal.please-complete-the-information"));
        },
        onSuccess: () => {
          client.invalidateQueries("get-repairing-setup");
          if (dayWeek.length === index + 1) {
            setIsModalVisible(false);
            swalSuccess(t);
          }
        },
      });
    });
  };

  const sumDay = (startDate: any, endDate: any, value: any) => {
    const dayInMonth = dayjs(startDate).daysInMonth();
    const dayWeek = [];
    for (let i = 0; i < dayInMonth; i++) {
      const dayoffweek = startDate.add(i, "day");
      if (
        dayjs(dayoffweek).month() === dayjs(startDate).month() &&
        dayjs(dayoffweek).day() === value.day - 1
      ) {
        dayWeek.push(dayjs(dayoffweek).format());
      }
    }
    console.log(dayWeek);
    dayWeek.map((item, index) => {
      value = { ...value, repairingSetupDate: item };
      return create.mutate(value, {
        onError: (err: any) => {
          swalError(t, t("swal.please-complete-the-information"));
        },
        onSuccess: () => {
          client.invalidateQueries("get-repairing-setup");
          if (dayWeek.length === index + 1) {
            setIsModalVisible(false);
            swalSuccess(t);
          }
        },
      });
    });
  };

  return (
    <div>
      <PageHeader
        menu="homecare-repair-setup"
        onSearch={() => {}}
        searchInput={[]}
        label={"create"}
        utility={[]}
        hasExcelRepairItem={true}
        showModal={showModal}
        showModalImport={showModalImport}
        showModalExport={showModalExport}
      />
      <div className="p-3 bg-white custom-calendar relative">
        <CalendarDayJS
          dateCellRender={dateCellRender}
          onSelect={onSelect}
          locale={i18n.language === "en" ? en : th}
          headerRender={({ value, type, onChange, onTypeChange }) => {
            const start = 0;
            const end = 12;
            const monthOptions = [];

            const current = value.clone();
            const localeData = value.localeData();
            const months = [];

            for (let i = 0; i < 12; i++) {
              months.push(localeData.monthsShort(current.month(i)));
            }

            for (let index = start; index < end; index++) {
              monthOptions.push(
                <Select.Option
                  className="month-item"
                  key={`${index}`}
                  value={`${index}`}
                >
                  {months[index]}
                </Select.Option>
              );
            }
            const month = value.month();

            const year = value.year();
            const options = [];
            for (let i = year - 10; i < year + 10; i += 1) {
              options.push(
                <Select.Option key={i} value={i} className="year-item">
                  {i}
                </Select.Option>
              );
            }

            return (
              <div style={{ padding: 0, paddingTop: 20 }}>
                <Row gutter={8}>
                  <Col span={12}>
                    <Row gutter={8} justify="start" className="d-flex">
                      <Typography.Title level={4}>
                        {t("calendar")}
                      </Typography.Title>
                      <Col>
                        <ul
                          style={{
                            listStyle: "none",
                            marginTop: "-14px",
                            marginLeft: "-13px",
                          }}
                        >
                          <li>
                            <Badge color="blue" text={t("holiday")} />
                          </li>
                          <li>
                            <Badge color="gold" text={t("repairman-list")} />
                          </li>
                        </ul>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row gutter={8} justify="end" className="d-flex">
                      <Col>
                        <Select
                          size="small"
                          dropdownMatchSelectWidth={false}
                          className="my-year-select"
                          onChange={(newYear: any) => {
                            const now = value.clone().year(newYear);
                            onChange(now);
                          }}
                          value={String(year)}
                        >
                          {options}
                        </Select>
                      </Col>
                      <Col>
                        <Select
                          size="small"
                          dropdownMatchSelectWidth={false}
                          value={String(month)}
                          onChange={(selectedMonth) => {
                            const newValue = value.clone();
                            const newMonth = newValue.month(
                              Number(selectedMonth)
                            );
                            onChange(newMonth);
                          }}
                        >
                          {monthOptions}
                        </Select>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            );
          }}
        />
      </div>
      <div>
        <Modal
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          centered={true}
          width="67vw"
          maskClosable={false}
        >
          <Form
            form={form}
            validateMessages={validateMessages}
            layout="vertical"
            className="fixed-input-size px-4 py-3"
            onFinish={onFinish}
          >
            <Row justify="space-between" className="mt-4">
              <Col className="d-flex flex-column justify-center f-18">
                {editId === "create" ? t("add-repairman") : t("edit-repairman")}
              </Col>
              <Col>
                <Row gutter={[12, 12]}>
                  <Col>
                    <Button
                      style={{ height: 45, width: 120 }}
                      className="button-cancel-setup"
                      onClick={handleCancel}
                    >
                      {t("cancel")}
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      style={{ height: 45, width: 120 }}
                      htmlType="submit"
                      className="button-submit-setup"
                      loading={create.isLoading || update.isLoading}
                    >
                      {t("submit")}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Form.Item
                  name="user"
                  label={t("repairman")}
                  rules={[{ required: true }]}
                  className="custom-select-ant "
                >
                  <CustomSelect
                    disabled={
                      editId === "create" || editId === "edit" ? false : true
                    }
                    hasTranslation={true}
                    placeholder={t("repairman")}
                    optionValue={{
                      values: technical || [],
                      key: "id",
                      name: "fullName",
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="repairingCategory"
                  label={t("repairing-category")}
                  rules={[{ required: true }]}
                  className="custom-select-ant"
                >
                  <CustomSelect
                    disabled={
                      editId === "create" || editId === "edit" ? false : true
                    }
                    hasTranslation={true}
                    placeholder={t("repairing-category")}
                    optionValue={{
                      values: repairingCategory.data?.data || [],
                      key: "id",
                      name: i18n.language === "en" ? "nameEn" : "nameTh",
                    }}
                  />
                </Form.Item>
              </Col>
              {editId === "create" && (
                <Col span={24}>
                  <Form.Item label={t("repeat")} className="custom-ant-switch">
                    <Switch
                      disabled={editId === "create" ? false : true}
                      onChange={onChange}
                      checked={switchCheck}
                    />
                  </Form.Item>
                </Col>
              )}

              {switchCheck &&
                editId === "create" &&
                FormRepeat(form, t, i18n, year, editId)}
              {!switchCheck &&
                editId === "create" &&
                FormNotRepeat(form, t, editId)}
            </Row>
            {dataInDay && editId !== "create" && (
              <Table
                rowKey="id"
                className="pt-4 custom-table"
                dataSource={dataInDay}
                columns={columns}
                scroll={{ y: "20vh" }}
                pagination={false}
              />
            )}
          </Form>
        </Modal>
        <Modal
          visible={importExcel}
          onCancel={handleCancel}
          footer={null}
          centered={true}
          width={items.length > 1 ? "80vw" : "50vw"}
          className="modal-import-excel"
          closable={false}
          maskClosable={false}
        >
          <ImportExcel
            handleCancel={handleCancel}
            setItems={setItems}
            items={items}
            technical={technical}
            repairing_category={repairingCategory.data?.data}
          />
        </Modal>
        <Modal
          visible={exportExcel}
          onCancel={handleCancel}
          footer={null}
          centered={true}
          width="35vw"
          maskClosable={false}
        >
          <ExportExcel formDate={formDate} handleCancel={handleCancel} />
        </Modal>
      </div>
    </div>
  );
};

export default RepairSetup;
