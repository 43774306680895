import { InputNumber } from "antd";
import { useTranslation } from "react-i18next";

export const CustomInputNumber = (props: {
  placeholder: string;
  value?: any;
  onChange?: any;
  disabled?: boolean;
  readOnly?: boolean;
  loading?: boolean;
  formatter?: any;
  step?: string;
}) => {
  const { t } = useTranslation();
  const _onChange = (value: number) => {
    const { onChange } = props;
    if (isNaN(Number(value))) return;
    const decimal = findDecimal(`${value}`);
    if (decimal <= 2) {
      onChange?.(value);
    }
  };

  const findDecimal = (value: string): number => {
    const index = value.indexOf(".");
    if (index === -1) return 0;
    return value.length - index - 1;
  };

  return (
    <InputNumber
      size="large"
      {...props}
      onChange={_onChange}
      addonAfter={t("baht")}
    />
  );
};
