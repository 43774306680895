import { MinusCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
} from "antd";
import dayjs from "dayjs";
import numeral from "numeral";
import { FormInstance } from "rc-field-form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import CustomDatePicker from "../../../components/custom-date-picker";
import UploadImage from "../../../components/custom-upload-image";
import PageHeaderEdit from "../../../components/page-header-create";
import { validateMessages } from "../../../config/validateMessage";
import { FOOD_ORDER_PATH } from "../../../router/path";
import { GET_BILL_TYPE, IBillType } from "../../../store/bill";
import { GET_FOODS, IFood } from "../../../store/food-list";
import { IFoodOptional } from "../../../store/food-optional-list";
import {
  GET_FOOD_ORDER_BY_ID,
  GET_GENERATE_QR_CODE_BOT_FOOD_ORDER,
  // GET_GENERATE_QR_CODE_SCB_FOOD_ORDER,
  IFoodOrder,
  PATCH_FOOD_ORDER,
  POST_FOOD_ORDER,
} from "../../../store/food-order";
import { GET_FOOD_TAX } from "../../../store/food-tax";
import { GET_UNIT, IUnit } from "../../../store/units";
import { genUniqueId } from "../../../tools/string";
import { swalError, swalSuccess } from "../../../tools/swal";
import { getKeyByLocale } from "../../../tools/translation";
import { fix_height } from "../../news/news-edit";

const FoodOrderEditPage = () => {
  const [form] = Form.useForm();
  const client = useQueryClient();
  const history = useHistory();
  const { id } = useParams<{ id?: string }>();
  const { t } = useTranslation();

  const get_units = GET_UNIT({ pagination: false });
  const get_foods = GET_FOODS({
    pagination: false,
    limit: 10,
    page: 1,
    // status: "ACTIVE",
  });

  const { data } = GET_FOOD_ORDER_BY_ID(id);
  const post_order = POST_FOOD_ORDER();
  const patch_order = PATCH_FOOD_ORDER();
  const [unitsData, setUnitsData] = useState<IUnit | undefined>();
  const get_food_tax = GET_FOOD_TAX();
  const billType = GET_BILL_TYPE({ pagination: false, status: "ACTIVE" });
  // const generateQrSCB = GET_GENERATE_QR_CODE_SCB_FOOD_ORDER();
  const generateQrBOT = GET_GENERATE_QR_CODE_BOT_FOOD_ORDER();

  useEffect(() => {
    initial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, id, get_foods.data]);

  useEffect(() => {
    const taxId = billType.data?.data.find(
      (item) => item.slug === "food"
    )?.taxId;
    const unitReference = get_units.data?.data.find(
      (item) => item.id === data?.unit?.id
    )?.unitReference;
    if (!form.getFieldValue("taxId")) {
      if (taxId && !data?.taxId) form.setFieldsValue({ taxId });
    }
    if (!form.getFieldValue("unitReference")) {
      if (unitReference && !data?.unitReference)
        form.setFieldsValue({ unitReference });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billType, data, get_units]);

  const initial = () => {
    if (!data || !get_foods.data) return;
    const {
      foodOrderList,
      unit,
      customer,
      createdAt,
      phoneNumber,
      paymentType,
      isPaid,
      setOwnPrice,
      status,
      receiptImage,
      paymentDate,
      foodRef,
      unitReference,
      taxId,
    } = data;
    const list: _foodpk[] = foodOrderList.map((item) => {
      return {
        image: item.food.foodMediaObjects[0]?.mediaObject?.imageUrl,
        quantity: item.quantity,
        food: item.food.id,
        detail: item.detail,
        uniqueId: `${item.id}`,
        optional: item.optional,
      };
    });

    form.setFieldsValue({
      customer: customer?.fullName || undefined,
      lineName: customer?.lineName || undefined,
      unit: unit?.id || undefined,
      foodOrderList: list,
      phoneNumber,
      createdAt: dayjs(createdAt),
      paymentType: paymentType,
      isPaid: `${isPaid}`,
      setOwnPrice: Number(setOwnPrice).toFixed(2),
      status,
      picture: receiptImage,
      paymentDate: paymentDate ? dayjs(paymentDate) : undefined,
      foodRef,
      unitReference,
      taxId,
    });
  };

  const afterMutation = {
    onSuccess: (res: any) => {
      swalSuccess();
      if (!id) generateQrCode(res.id, false);
      client.invalidateQueries("get-food-order");
      client.invalidateQueries(["get-food-order-id", id]);
      history.replace(FOOD_ORDER_PATH.index);
    },
    onError: ({ message }: any) => {
      swalError(t, message);
    },
  };

  const onFinish = (values: any, goBack?: boolean) => {
    let svc = undefined;
    let vat = undefined;
    if (get_food_tax.data) {
      svc = Number(get_food_tax.data[0]?.svg);
      vat = Number(get_food_tax.data[0]?.vat);
    }
    values.setOwnPrice = +values.setOwnPrice;
    values.isPaid = values.isPaid ? JSON.parse(`${values.isPaid}`) : false;
    values.customer =
      typeof values.customer === "number" ? values.customer : undefined;
    delete values.customTotal;

    const reg = /^-?\d*(\.\d*)?$/;
    const newRef = getNewRef();

    if (values.foodRef)
      if (isNaN(values.foodRef) && !reg.test(values.foodRef))
        return swalError(
          t,
          t("food-order-reference-number") + ": " + t("validate.number")
        );

    values.foodOrderList = values.foodOrderList?.filter(
      (item: any) => item.food
    );

    if (id) {
      return patch_order.mutate(
        { ...values, ...newRef, id },
        {
          onSuccess: (res: any) => {
            if (goBack !== false) swalSuccess();
            if (!data?.qrImage || !data?.qrBotImage)
              generateQrCode(+id, goBack);
            client.invalidateQueries("get-food-order");
            client.invalidateQueries(["get-food-order-id", id]);
            if (goBack !== false) history.replace(FOOD_ORDER_PATH.index);
          },
          onError: ({ message }: any) => {
            swalError(t, message);
          },
        }
      );
    }
    return post_order.mutate({ ...values, svc, vat }, afterMutation);
  };

  const onAddFoodItem = () => {
    const formValues = form.getFieldsValue();
    const foodOrderList = formValues?.foodOrderList || [];
    const item: _foodpk = {
      quantity: 1,
      uniqueId: genUniqueId(foodOrderList.map((i: any) => i.uniqueId)),
    };

    form.setFieldsValue({
      ...formValues,
      foodOrderList: [...foodOrderList, item],
    });
  };

  const onValuesChange = (value: any) => {
    const { customer, unit } = value;

    if (unit) {
      form.setFieldsValue({
        customer: undefined,
        serviceItem: undefined,
        lineName: undefined,
      });
      const findUnit = get_units.data?.data.find(
        (item: IUnit) => item.id === unit
      );
      setUnitsData(findUnit);
    }

    if (customer) {
      const findUser = unitsData?.userUnits?.find(
        (e) => e.user.id === customer
      );
      const phoneNumber = findUser?.user?.phoneNumber;
      const lineName = findUser?.user?.lineName;
      form.setFieldsValue({ phoneNumber, lineName });
    }
  };

  const generateQrCode = (createId: number, goBack?: boolean) => {
    // generateQrSCB.mutate(createId, {
    //   onError: (err: any) => {
    //     swalError(t, err.message);
    //   },
    //   onSuccess: () => {
    //     if (goBack === false)
    //       swalSuccess(t, `${t("qr-code-scb")} ${t("success")}`);
    //   },
    // });
    generateQrBOT.mutate(createId, {
      onError: (err: any) => {
        swalError(t, err.message);
      },
      onSuccess: () => {
        if (goBack === false)
          swalSuccess(t, `${t("qr-code-bot")} ${t("success")}`);
      },
    });
  };

  const getNewRef = () => {
    const taxId = billType.data?.data?.find(
      (item) => item.slug === "food"
    )?.taxId;
    const unitId = data?.unit;
    const unitReference = get_units.data?.data?.find(
      (item) => item.id === Number(unitId)
    )?.unitReference;
    return { taxId, unitReference };
  };

  return (
    <Form
      onFinish={onFinish}
      layout="vertical"
      form={form}
      validateMessages={validateMessages}
      className="main-input-form"
      onValuesChange={onValuesChange}
    >
      <div className="bg-white color-black pb-1">
        <PageHeaderEdit
          label="menu.food-order"
          isLoading={post_order.isLoading || patch_order.isLoading}
        />
        <UnitInput
          units={get_units.data?.data || []}
          id={id}
          data={data}
          form={form}
          billType={billType.data?.data || []}
          onFinish={(e, gb) => onFinish(e, gb)}
          // generateQrSCB={generateQrSCB}
          generateQrBOT={generateQrBOT}
        />
      </div>
      <div className="bg-white color-black pb-1 mt-4">
        <FoodInput
          foods={get_foods.data?.data || []}
          onAddFoodItem={onAddFoodItem}
          form={form}
          data={data}
        />
      </div>
      {id && (
        <div className="bg-white color-black mt-4 px-4 pb-4 pt-2">
          <Col span={24}>
            <Form.Item
              name="picture"
              label={t("attached-picture")}
              className="custom-ant-upload-image"
            >
              <UploadImage onPage="food-order" isReceiptImage />
            </Form.Item>
          </Col>
        </div>
      )}
    </Form>
  );
};

const UnitInput = ({
  units,
  id,
  data,
  form,
  billType,
  onFinish,
  // generateQrSCB,
  generateQrBOT,
}: {
  units: IUnit[];
  id?: string;
  data?: IFoodOrder;
  form: any;
  billType: IBillType[];
  onFinish: (e: any, gb: boolean) => void;
  // generateQrSCB: any;
  generateQrBOT: any;
}) => {
  const { t, i18n } = useTranslation();

  const onSelectUnit = () => {
    const unitId = form.getFieldValue("unit");
    const unit = units.find((item) => item.id === unitId);
    form.setFieldsValue({ unitReference: unit?.unitReference });
  };

  return (
    <Row gutter={[24, 24]} className="px-4 mb-4 pb-2">
      <Col span={12}>
        <Form.Item
          name="unit"
          label={t("house-number")} //unit number to use, house number to show
          rules={[{ required: true }]}
          className="custom-select-ant"
        >
          <Select
            placeholder={t("house-number")} //unit number to use, house number to show
            getPopupContainer={(e) => e}
            allowClear
            showSearch
            optionFilterProp="children"
            disabled={id ? true : false}
            onChange={onSelectUnit}
          >
            {units.map((item, key) => {
              return (
                <Select.Option key={key} value={item.id}>
                  {item.unitNumber}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item shouldUpdate={(prev, next) => prev.unit !== next.unit}>
          {(form: FormInstance) => {
            const unitId: number | undefined = form.getFieldValue("unit");
            const find = units.find((e) => e.id === unitId);

            return (
              <Form.Item
                name="customer"
                label={`${t("name")}`}
                rules={[{ required: true }]}
                className="custom-select-ant"
              >
                <Select
                  placeholder={t("name")}
                  disabled={!find || id ? true : false}
                  getPopupContainer={(e) => e}
                  allowClear
                  showSearch
                  optionFilterProp="children"
                >
                  {find?.userUnits?.map((item, index) => {
                    return (
                      <Select.Option key={index} value={item.user?.id}>
                        {item.user?.fullName}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item name="createdAt" label={t("required-date")}>
          <CustomDatePicker
            format={
              i18n.language === "en"
                ? "DD-MM-YYYY / HH:mm a"
                : "DD-MM-BBBB / HH:mm a"
            }
            disabled
            defaultValue={dayjs()}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="phoneNumber"
          label={t("contact-number")}
          rules={[{ required: true }]}
        >
          <Input placeholder={t("contact-number")} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="lineName"
          label={t("line-name")}
          className="custom-input"
        >
          <Input placeholder={t("line-name")} disabled />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="unitReference"
          label={
            t("unit-reference-number") + t("this-information-comes-from-unit")
          }
          className="custom-input"
          rules={[{ required: true }]}
        >
          <Input placeholder={t("unit-reference-number")} disabled />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="foodRef"
          label={t("food-order-reference-number")}
          className="custom-input"
          rules={[{ required: true }]}
        >
          <Input placeholder={t("food-order-reference-number")} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="taxId"
          label={t("tax-id") + t("this-information-comes-from-bill-type")}
          className="custom-input"
          rules={[{ required: true }]}
        >
          <Input placeholder={t("tax-id")} disabled />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name="paymentType"
          label={t("payment-type")}
          className="custom-select-ant "
          rules={[{ required: true }]}
        >
          <Select placeholder={t("payment-type")} getPopupContainer={(e) => e}>
            <Select.Option value="QR_CODE_BOT">
              {t("qr-code-bot")}
            </Select.Option>
            {/* <Select.Option value="QR_CODE_SCB">
              {t("qr-code-scb")}
            </Select.Option> */}
            <Select.Option value="CASH">{t("cash")}</Select.Option>
            <Select.Option value="CREDIT_CARD">
              {t("credit-card")}
            </Select.Option>
          </Select>
        </Form.Item>
      </Col>
      {id && (
        <Col span={12}>
          <Form.Item
            name="paymentDate"
            label={t("payment-date")}
            className="custom-ant-picker"
          >
            <DatePicker
              disabled
              placeholder={`${t("payment-date")}`}
              style={{ width: "100%", height: `${fix_height}` }}
              format={
                i18n.language === "en"
                  ? "DD-MM-YYYY / HH:mm a"
                  : "DD-MM-BBBB / HH:mm a"
              }
            />
          </Form.Item>
        </Col>
      )}
      <Col span={12}>
        <Form.Item
          name="status"
          label={t("status")}
          className="custom-select-ant "
          rules={[{ required: true }]}
          initialValue="PENDING"
        >
          <Select
            placeholder={t("status")}
            getPopupContainer={(value) => {
              return value;
            }}
            defaultValue="PENDING"
          >
            <Select.Option value="PENDING">{t("pending")}</Select.Option>
            {/* <Select.Option value="PAID">{t("paid")}</Select.Option> */}
            <Select.Option value="SUCCESS">{t("complete")}</Select.Option>
            <Select.Option value="CANCEL">{t("cancel")}</Select.Option>
          </Select>
        </Form.Item>
      </Col>
      {id &&
        (data?.paymentType === "QR_CODE_SCB" ||
          data?.paymentType === "QR_CODE_BOT") && (
          <Col span={12}>
            <Form.Item
              shouldUpdate={(prev, next) =>
                prev.paymentType !== next.paymentType
              }
            >
              {(form: FormInstance) => {
                let generateQr: any = undefined;
                let showQr = true;
                const paymentType = form.getFieldValue("paymentType");
                const qrImage =
                  paymentType === "QR_CODE_SCB" ? "qrImage" : "qrBotImage";
                // if (paymentType === "QR_CODE_SCB") generateQr = generateQrSCB;
                if (paymentType === "QR_CODE_BOT") generateQr = generateQrBOT;
                if (paymentType?.split("_")[0] !== "QR") showQr = false;
                if (!generateQr) return <></>;

                const onClick = () => {
                  saveNewRef();
                };

                const saveNewRef = () => {
                  const taxId = billType.find(
                    (item) => item.slug === "food"
                  )?.taxId;
                  const unitId = data.unit;
                  const unitReference = units?.find(
                    (item) => item.id === Number(unitId)
                  )?.unitReference;
                  const values = form.getFieldsValue();
                  onFinish({ ...values, taxId, unitReference }, false);
                };

                const reloadSCB =
                  paymentType === "QR_CODE_SCB" && !data.qrImage;
                const reloadBOT = paymentType === "QR_CODE_BOT";

                return (
                  <Row>
                    <Col>
                      <Form.Item label={t("qr-code")} hidden={!showQr}>
                        <Image
                          src={data[qrImage]?.imageUrl}
                          preview={false}
                          height={228}
                          width={228}
                        />
                      </Form.Item>
                    </Col>
                    {(reloadSCB || reloadBOT) && showQr && (
                      <Col className="d-flex align-center pl-4 color-white">
                        <div
                          style={{
                            marginTop: 41,
                            borderRadius: 50,
                          }}
                          className="p-1 d-flex align-center bg-grey pointer"
                          onClick={onClick}
                        >
                          <ReloadOutlined
                            style={{
                              fontSize: 24,
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>
                    )}
                  </Row>
                );
              }}
            </Form.Item>
          </Col>
        )}
    </Row>
  );
};

const FoodInput = ({
  foods,
  onAddFoodItem,
  form,
  data,
}: {
  foods: IFood[];
  onAddFoodItem: () => void;
  form: FormInstance;
  data: any;
}) => {
  const { t } = useTranslation();
  const get_food_tax = GET_FOOD_TAX();

  const svc = data
    ? Number(data.svc)
    : get_food_tax.data
    ? +get_food_tax.data[0]?.svg
    : 0;
  const vat = data
    ? Number(data.vat)
    : get_food_tax.data
    ? +get_food_tax.data[0]?.vat
    : 0;

  return (
    <Row className="px-4 pt-4 pb-4" gutter={[12, 12]}>
      <Col span={4}>
        <div style={{ borderLeft: "1px solid #e4e9f2" }}>
          <span className="ml-2">{t("image")}</span>
        </div>
      </Col>
      <Col span={19}>
        <Row gutter={[12, 12]}>
          <Col span={6}>
            <div style={{ borderLeft: "1px solid #e4e9f2" }}>
              <span className="ml-2">{t("menu.food")}</span>
            </div>
          </Col>
          <Col span={6}>
            <div style={{ borderLeft: "1px solid #e4e9f2" }}>
              <span className="ml-2">{t("quantity")}</span>
            </div>
          </Col>
          <Col span={6}>
            <div style={{ borderLeft: "1px solid #e4e9f2" }}>
              <span className="ml-2">
                {t("price")} ({t("baht")})
              </span>
            </div>
          </Col>
          <Col span={6}>
            <div style={{ borderLeft: "1px solid #e4e9f2" }}>
              <span className="ml-2">{`${t("total-price")} (${t(
                "baht"
              )})`}</span>
            </div>
          </Col>
        </Row>
      </Col>
      <Col span={24}>
        <Form.Item name="foodOrderList">
          <FoodPicker foods={foods} form={form} tax={{ svg: svc, vat }} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Divider className="m-0" />
      </Col>
      <Col span={24} className="my-2">
        <Button
          onClick={onAddFoodItem}
          className="button-save d-block"
          style={{ height: 40, width: 120 }}
        >
          {t("add-list")}
        </Button>
      </Col>
      <Col span={24}>
        <Divider className="m-0" />
      </Col>
      <Col span={18} className="d-flex justify-end align-end mb-3">
        <div className="mr-4">
          <span className="color-gold mr-1">*</span> {t("if-you-edit-it")}
        </div>
        <Form.Item name="customToltal" className="custom-input-switch mr-4">
          <Switch checkedChildren="edit" unCheckedChildren="edit" />
        </Form.Item>
      </Col>
      <Col span={5} className="pb-2 d-flex justify-end">
        <Form.Item
          shouldUpdate={(prev, cur) => prev.customToltal !== cur.customToltal}
          className="w-100"
        >
          {(_form: FormInstance) => {
            const disabled = form.getFieldValue("customToltal");

            return (
              <Form.Item
                name="setOwnPrice"
                label={`${t("total")}`}
                className="custom-input-number pt-3"
              >
                <InputNumber
                  disabled={!disabled}
                  style={{ width: "100%" }}
                  placeholder={`${t("total")}`}
                  formatter={(value) => numeral(value).format("0,000 0.00")}
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={16}></Col>
      <Col span={3} className="d-flex align-center">
        {t("svc")}
      </Col>
      <Col span={1} className="d-flex align-center">
        <span className="color-gold ml-2">{svc.toFixed(0)}%</span>
      </Col>
      <Col span={3} className="d-flex justify-end">
        <Form.Item shouldUpdate={(prev, cur) => prev.svgPrice !== cur.svgPrice}>
          {(_form: FormInstance) => {
            const svgPrice = form.getFieldValue("svgPrice");

            return (
              <>
                <span className="color-gold mr-2">
                  {svgPrice ? numeral(svgPrice).format("0,000.00") : 0}
                </span>
                {t("baht")}
              </>
            );
          }}
        </Form.Item>
      </Col>
      <Col span={16}></Col>
      <Col span={3} className="d-flex align-center">
        {t("vat")}
      </Col>
      <Col span={1} className="d-flex align-center">
        <span className="color-gold ml-2">{vat.toFixed(0)}%</span>
      </Col>
      <Col span={3} className="d-flex justify-end">
        <Form.Item shouldUpdate={(prev, cur) => prev.vatPrice !== cur.vatPrice}>
          {(_form: FormInstance) => {
            const vatPrice = form.getFieldValue("vatPrice");

            return (
              <>
                <span className="color-gold mr-2">
                  {vatPrice ? numeral(vatPrice).format("0,000.00") : 0}
                </span>
                {t("baht")}
              </>
            );
          }}
        </Form.Item>
      </Col>
    </Row>
  );
};

interface _foodpk {
  image?: string;
  food?: number;
  quantity: number;
  uniqueId: string;
  detail?: string;
  optional?: IFoodOptional[];
}

const FoodPicker = ({
  foods,
  value = [],
  onChange,
  form,
  tax,
}: {
  value?: _foodpk[];
  onChange?: any;
  foods: IFood[];
  form: FormInstance;
  tax: { svg: number; vat: number };
}) => {
  const { i18n, t } = useTranslation();

  useEffect(() => {
    calculateTotal(undefined, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foods, tax]);

  const onFoodChange = (id: number, uniqueId: string) => {
    const prev: _foodpk[] = form.getFieldValue("foodOrderList") || [];

    const next = prev.map((item) => {
      if (item.uniqueId === uniqueId) {
        return { ...item, food: id };
      }
      return item;
    });

    onChange(next);
    calculateTotal();
  };

  const onQuantityChange = (quantity: number, uniqueId: string) => {
    const prev: _foodpk[] = form.getFieldValue("foodOrderList") || [];

    const next = prev.map((item) => {
      if (item.uniqueId === uniqueId) {
        return { ...item, quantity: quantity };
      }
      return item;
    });
    onChange(next);
    calculateTotal();
  };

  const onDetailChange = (detail: string, uniqueId: string) => {
    const prev: _foodpk[] = form.getFieldValue("foodOrderList") || [];

    const next = prev.map((item) => {
      if (item.uniqueId === uniqueId) {
        return { ...item, detail: detail };
      }
      return item;
    });
    onChange(next);
  };

  const calculateTotal = (orderList?: _foodpk[], initial?: boolean) => {
    const customTotal = form.getFieldValue("customToltal");
    const foodList = orderList
      ? orderList
      : form.getFieldValue("foodOrderList");
    let total = foodList
      ?.map((item: _foodpk) => {
        const food = foods?.find((food) => food.id === item.food);
        const price = food?.price ? +food.price : 0;
        const priceXquantity = price * item.quantity;
        const priceOptional =
          item.optional?.reduce((acc, cur) => {
            return acc + Number(cur.price);
          }, 0) || 0;
        return priceXquantity + priceOptional;
      })
      .reduce((a: number, b: number) => a + b, 0);
    if (tax) {
      const { svg, vat } = tax;
      const svgPrice = !svg ? 0 : (total / 100) * +svg;
      const vatPrice = !vat ? 0 : ((svgPrice + total) / 100) * +vat;
      total = total + svgPrice + vatPrice;
      form.setFieldsValue({ svgPrice, vatPrice });
    }
    total = Math.ceil(total);
    if (initial) return;
    if (!customTotal)
      return form.setFieldsValue({ setOwnPrice: Number(total).toFixed(2) });
  };

  const onDelete = (uniqueId: string) => {
    const prev: _foodpk[] = form.getFieldValue("foodOrderList") || [];
    const next = prev.filter((item) => item.uniqueId !== uniqueId);
    const itemDelete = prev.find((item) => item.uniqueId === uniqueId);
    if (itemDelete?.food) calculateTotal(next);
    onChange(next);
  };

  const onExtraChange = (value: any, uniqueId: string) => {
    // const optional = value.map((item: string) => JSON.parse(item));
    const prev: _foodpk[] = form.getFieldValue("foodOrderList") || [];

    const next = prev.map((item) => {
      const food = foods?.find((food) => food.id === item.food);
      const optional = food?.foodOptionalLists.filter((item: IFoodOptional) =>
        value.some((e: any) => e === item.id)
      );
      if (item.uniqueId === uniqueId) {
        return { ...item, optional: optional };
      }
      return item;
    });

    onChange(next);
    calculateTotal();
  };

  return (
    <div>
      {value.map((item, index) => {
        const currentFood = foods.find((e) => e.id === item.food);
        const valueExtra = item.optional?.map((item) => item.id);
        const nameCurrentFood =
          getKeyByLocale({
            key: "name",
            data: currentFood,
            locale: i18n.language,
          }) || "";
        return (
          <Row key={index} gutter={[12, 12]}>
            <Col span={24}>
              <Divider className={index === 0 ? "my-0" : "mb-0"} />
            </Col>
            <Col span={4} className="d-flex align-center justify-center">
              <Image
                src={item.image || currentFood?.foodMediaObjects?.[0]?.url}
                preview={false}
                width={150}
                height={150}
                // className="m-auto"
              />
            </Col>
            <Col span={19}>
              <Row gutter={[12, 12]}>
                <Col span={6}>
                  <Select
                    placeholder={t("menu.food")}
                    value={
                      currentFood?.status === "INACTIVE"
                        ? nameCurrentFood
                        : item.food
                    }
                    onChange={(e) => onFoodChange(e, item.uniqueId)}
                    getPopupContainer={(e) => e}
                    allowClear
                    showSearch
                    optionFilterProp="children"
                  >
                    {foods.map((item, index) => {
                      if (item.status === "INACTIVE") return <></>;
                      return (
                        <Select.Option value={item.id} key={index}>
                          {getKeyByLocale({
                            key: "name",
                            data: item,
                            locale: i18n.language,
                          })}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Col>
                <Col span={6}>
                  <InputNumber
                    onChange={(e) => onQuantityChange(e, item.uniqueId)}
                    placeholder={t("quantity")}
                    min={1}
                    value={item.quantity}
                    formatter={(value) => numeral(value).format("0,000")}
                  />
                </Col>
                <Col span={6}>
                  <InputNumber
                    placeholder={t("price")}
                    value={Number(currentFood?.price || 0)}
                    formatter={(value) => numeral(value).format("0,000.00")}
                  />
                </Col>
                <Col span={6}>
                  <InputNumber
                    placeholder={t("total-price")}
                    value={Number(currentFood?.price || 0) * item.quantity}
                    formatter={(value) => numeral(value).format("0,000.00")}
                  />
                </Col>
                {currentFood?.foodOptionalLists !== undefined &&
                  currentFood?.foodOptionalLists.length > 0 && (
                    <Col span={24} className="w-100">
                      <Checkbox.Group
                        style={{ width: "100%" }}
                        onChange={(e) => onExtraChange(e, item.uniqueId)}
                        value={valueExtra}
                      >
                        <Row gutter={[12, 12]}>
                          {currentFood?.foodOptionalLists.map((item, index) => {
                            // const value = JSON.stringify(item);
                            // console.log(value)
                            return (
                              <Col span={6} key={index}>
                                <Checkbox
                                  value={item.id}
                                  className="custom-checbox"
                                >
                                  <Row
                                    className="d-flex w-100"
                                    justify="space-between"
                                  >
                                    <span className="pr-3">
                                      {getKeyByLocale({
                                        key: "name",
                                        data: item,
                                        locale: i18n.language,
                                      })}
                                    </span>
                                    <span className="">
                                      +{Number(item.price)}
                                    </span>
                                  </Row>

                                  {/* {t("baht")} */}
                                </Checkbox>
                              </Col>
                            );
                          })}
                        </Row>
                      </Checkbox.Group>
                    </Col>
                  )}
                <Col span={24}>
                  <Input.TextArea
                    placeholder={t("details")}
                    onChange={(e) =>
                      onDetailChange(e.target.value, item.uniqueId)
                    }
                    rows={4}
                    maxLength={255}
                    value={item.detail}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={1} className="d-flex align-center justify-center">
              <MinusCircleOutlined
                style={{ fontSize: 20, color: "red" }}
                onClick={() => onDelete(item.uniqueId)}
              />
            </Col>
          </Row>
        );
      })}
    </div>
  );
};

export default FoodOrderEditPage;
