import { Button, Col, Form, InputNumber, Modal, Row } from "antd";
import { ColumnsType } from "antd/lib/table";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import Loader from "react-spinners/ClipLoader";
import CustomStatusBackground from "../../../components/custom-status";
import CustomTable from "../../../components/custom-table";
import CustomTimePicker from "../../../components/custom-time-picker";
import PageFilter, { IPageFilterChoice } from "../../../components/page-filter";
import PageHeader, { ISearchInput } from "../../../components/page-header";
import { FOOD_LIST_PATH } from "../../../router/path";
import {
  BULK_DELETE_FOOD,
  DELETE_FOOD,
  GET_FOODS,
  IFood,
  IFoodParams,
} from "../../../store/food-list";
import {
  GET_FOOD_TAX,
  PATCH_FOOD_TAX,
  POST_FOOD_TAX,
} from "../../../store/food-tax";
import {
  GET_SHOP_SETTING,
  PATCH_SHOP_SETTING,
  POST_SHOP_SETTING,
} from "../../../store/shop-setting";
import {
  dateByOnlyTime,
  findStartOrEndTime,
  parse,
} from "../../../tools/datetime";
import { swalDelete, swalError, swalSuccess } from "../../../tools/swal";

const FoodListPage = () => {
  const [params, setParams] = useState<IFoodParams>({ page: 1, limit: 10 });
  const [visible, setVisible] = useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const get_foods = GET_FOODS(params);
  const delete_food = DELETE_FOOD();
  const bulk_delete_food = BULK_DELETE_FOOD();

  const client = useQueryClient();

  const searchInput: ISearchInput[] = [
    {
      label: t("search"),
      key: "search",
      span: 10,
      type: "input",
      props: {
        placeholder: `${t("search-name-EN-TH")}`,
      },
    },
    {
      label: t("creation-date"),
      key: "date",
      span: 7,
      type: "range-picker",
      props: {
        placeholder: [t("start-date"), t("end-date")],
        getPopupContainer: (props: HTMLElement) => {
          return props;
        },
      },
    },
  ];
  const choices: IPageFilterChoice[] = [
    {
      label: t("all"),
      total: get_foods.data?.countData.countAll || 0,
    },
    {
      label: t("active"),
      key: "ACTIVE",
      total: get_foods.data?.countData.countActive || 0,
    },
    {
      label: t("inactive"),
      key: "INACTIVE",
      total: get_foods.data?.countData.countInActive || 0,
    },
  ];

  const columns: ColumnsType<IFood> = [
    {
      dataIndex: "name",
      title: t("name"),
      width: "40vw",
      render: (text, rc) => (
        <div className="line-clamp">
          {i18n.language === "en"
            ? rc.nameEn
              ? rc.nameEn
              : "-"
            : rc.nameTh
            ? rc.nameTh
            : "-"}
        </div>
      ),
    },
    {
      dataIndex: "price",
      align: "center",
      title: t("price"),
      width: 150,
      render: (text) => numeral(text || 0).format("0,0.00"),
    },
    // {
    //   dataIndex: "createdAt",
    //   align: "center",
    //   title: t("creation-date"),
    //   width: 150,
    //   render: (text) => {
    //     return <span>{parse(text, i18n.language, "DD MMM YYYY")}</span>;
    //   },
    // },
    {
      dataIndex: "updatedAt",
      align: "center",
      title: t("update"),
      width: 200,
      render: (text) => {
        return (
          <span>
            {text
              ? parse(
                  text,
                  i18n.language,
                  `${i18n.language === "en" ? "DD MMM YYYY" : "DD MMM BBBB"}`
                )
              : "-"}
          </span>
        );
      },
    },
    // {
    //   dataIndex: "createdBy",
    //   align: "center",
    //   title: t("created-by"),
    //   render: (_, record) => {
    //     return <span>{record.createdBy?.fullName}</span>;
    //   },
    // },
    {
      dataIndex: "status",
      title: t("status"),
      render: (text) => {
        return (
          <>
            {!text && "-"}
            {text === "ACTIVE" && (
              <CustomStatusBackground
                lable={t("active")}
                className={"frame-background"}
              />
            )}
            {text === "INACTIVE" && (
              <CustomStatusBackground
                lable={t("inactive")}
                className={"frame-no-background"}
              />
            )}
          </>
        );
      },
    },
  ];

  const onSearch = (values: any) => {
    if (!values.date) {
      values.date = [];
    }
    const { date } = values;

    const startDate = findStartOrEndTime(date[0], "start");
    const endDate = findStartOrEndTime(date[1], "end");
    values = { ...values, startDate, endDate };
    delete values.date;

    setParams({ ...params, ...values });
  };

  const onDelete = async (id: any) => {
    const confirm = await swalDelete(t, "comfirm-delete-food-list");
    if (confirm) {
      delete_food.mutate(
        { id: id },
        {
          onSuccess: () => {
            if (params.page > 1 && get_foods.data?.data.length === 1) {
              setParams({ ...params, page: params.page - 1 });
            }

            swalSuccess();
            client.invalidateQueries("get-foods");
          },
          onError: (error: any) => {
            swalError(t, t(error.message));
          },
        }
      );
    }
  };

  const onFilter = (choice: IPageFilterChoice) => {
    setParams({ ...params, status: choice.key });
  };

  const onBulkDeleteSuccess = (length: number) => {
    if (length === get_foods.data?.data.length && params.page > 1) {
      setParams({ ...params, page: params.page - 1 });
    }
  };

  return (
    <div>
      <Modal
        onCancel={() => setVisible(false)}
        visible={visible}
        footer={null}
        closable={false}
        centered
        width="50vw"
      >
        <ShopSettingModalBody onClose={() => setVisible(false)} />
      </Modal>
      <PageHeader
        menu="food-list"
        onSearch={onSearch}
        searchInput={searchInput}
        label="plus-btn.menu"
        utility={["delete", "food"]}
        path={FOOD_LIST_PATH.create}
        bulkDelete={bulk_delete_food}
        invalidateQueries={["get-foods"]}
        topicExport="Food Order"
        onBulkDeleteSuccess={onBulkDeleteSuccess}
        onTimeButtonClick={() => setVisible(!visible)}
        textConfirmDelete="comfirm-delete-food-list"
      />
      <PageFilter
        choices={choices}
        onClick={onFilter}
        current={params.status}
      />
      <CustomTable
        title={t("food-list")}
        dataSource={get_foods.data?.data || []}
        isLoadingTable={get_foods.isLoading}
        columns={columns}
        pagination={{
          limit: params.limit,
          page: params.page,
          total: get_foods.data?.count || 0,
        }}
        onPageChange={(page, limit) => {
          setParams({ ...params, page, limit });
        }}
        onDelete={onDelete}
        // actions={["edit"]}
      />
    </div>
  );
};

const ShopSettingModalBody = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const client = useQueryClient();

  const get_shop_setting = GET_SHOP_SETTING();
  const post_setting = POST_SHOP_SETTING();
  const patch_setting = PATCH_SHOP_SETTING();
  const get_food_tax = GET_FOOD_TAX();
  const post_tax = POST_FOOD_TAX();
  const patch_tax = PATCH_FOOD_TAX();

  useEffect(() => {
    initialShop();
    initialTax();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [get_shop_setting.data, get_food_tax.data]);

  const initialShop = () => {
    if (!get_shop_setting.data || get_shop_setting.data.length <= 0) return;
    const [item] = get_shop_setting.data;

    form.setFieldsValue({
      openTime: dateByOnlyTime(item.openTime),
      closeTime: dateByOnlyTime(item.closeTime),
    });
  };

  const initialTax = () => {
    if (!get_food_tax.data || get_food_tax.data.length <= 0) return;
    const [item] = get_food_tax.data;

    form.setFieldsValue({
      svg: item.svg,
      vat: item.vat,
    });
  };

  const afterMutate = {
    onSuccess: () => {
      client.invalidateQueries();
      swalSuccess();
      onClose();
    },
    onError: ({ message }: any) => {
      swalError(t, message);
    },
  };

  const onFinish = (values: any) => {
    const { closeTime, openTime, svg, vat } = values;
    const open = openTime.format("HH:mm:ss+0700");
    const close = closeTime.format("HH:mm:ss+0700");

    if (get_shop_setting.data && get_shop_setting.data.length > 0) {
      const id = get_shop_setting.data[0].id;
      patch_setting.mutate(
        { openTime: open, closeTime: close, title: "", id },
        afterMutate
      );
    }
    if (!get_shop_setting.data?.length) {
      post_setting.mutate(
        { openTime: open, closeTime: close, title: "" },
        afterMutate
      );
    }
    if (get_food_tax.data && get_food_tax.data.length > 0) {
      const id = get_food_tax.data[0].id;
      patch_tax.mutate({ svg: +svg, vat: +vat, id }, afterMutate);
    }
    if (!get_food_tax.data?.length) {
      post_tax.mutate({ svg, vat }, afterMutate);
    }
  };

  const getContext = () => {
    if (get_shop_setting.isLoading) {
      return (
        <div
          className="d-flex justify-center align-center"
          style={{ height: "86.12px" }}
        >
          <Loader color="#B69E4F" />
        </div>
      );
    }

    return (
      <Row gutter={[12, 12]}>
        <Col span={12}>
          <Form.Item
            name="openTime"
            label={`${t("open-time")}`}
            rules={[{ required: true }]}
          >
            <CustomTimePicker
              secondStep={60}
              minuteStep={60}
              showNow={false}
              disabled={get_shop_setting.isError}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="closeTime"
            label={`${t("close-time")}`}
            rules={[{ required: true }]}
          >
            <CustomTimePicker
              secondStep={60}
              minuteStep={60}
              showNow={false}
              disabled={get_shop_setting.isError}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="svg"
            label={t("svc")}
            rules={[{ required: true }]}
            className="custom-input-number custom-input"
          >
            <InputNumber
              placeholder={t("svc")}
              min={0}
              addonAfter="%"
              disabled={get_food_tax.isError}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="vat"
            label="VAT"
            rules={[{ required: true }]}
            className="custom-input-number custom-input"
          >
            <InputNumber
              placeholder="VAT"
              min={0}
              addonAfter="%"
              disabled={get_food_tax.isError}
            />
          </Form.Item>
        </Col>
      </Row>
    );
  };

  return (
    <Form
      className="main-input-form"
      layout="vertical"
      form={form}
      onFinish={onFinish}
    >
      <Row
        justify="space-between"
        className="pb-4"
        align="middle"
        gutter={[0, 24]}
      >
        <Col>
          <h2 className="bold mb-0"> {t("setting-open-hours")}</h2>
        </Col>
        <Col>
          <Row gutter={[12, 12]}>
            <Col>
              <Button
                style={{ height: 45, width: 120 }}
                className="button-cancel-setup"
                onClick={onClose}
              >
                {t("cancel")}
              </Button>
            </Col>
            <Col>
              <Button
                htmlType="submit"
                style={{ height: 45, width: 120 }}
                loading={post_setting.isLoading || patch_setting.isLoading}
                className="button-submit-setup"
              >
                {t("submit")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      {getContext()}
    </Form>
  );
};

export default FoodListPage;
