import { Col, Form, Input, Row } from "antd";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CustomSelect from "../../../components/custom-select";
import ImageProfileUpload from "../../../components/image-profile-upload";
import PageHeaderEdit from "../../../components/page-header-create";
import { rules, VALIDATE_MESSAGES } from "../../../config/validateMessage";
import { allCountries } from "country-telephone-data";
import { GET_ROLE } from "../../../store/role";
import { GET_USER_BY_ID, PATCH_USER, POST_USER } from "../../../store/user";
import { useEffect } from "react";
import dayjs from "dayjs";
import CustomDatePicker from "../../../components/custom-date-picker";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";
import { swalError, swalSuccess } from "../../../tools/swal";
import { USER_MANAGEMENT_ADMIN_PATH } from "../../../router/path";
import { FIXED_GENDER, FIXED_LANGUAGE, FIXED_STATUS } from "../../../store";
import { useNotification } from "../../../store/notification";

const UserAdminEditPage = () => {
  const { id } = useParams<{ id: string }>();
  const [form] = useForm();
  const { t } = useTranslation();
  const roles = GET_ROLE({ pagination: false });
  const create_user = POST_USER();
  const update_user = PATCH_USER();
  const { data } = GET_USER_BY_ID(id);
  const history = useHistory();
  const client = useQueryClient();
  const { dispatch } = useNotification();

  useEffect(() => {
    initial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const initial = () => {
    if (!data) return;
    const { avatar, role, dob } = data.data;
    form.setFieldsValue({
      ...data.data,
      avatar: avatar?.imageUrl,
      role: role.id,
      dob: dob ? dayjs(dob) : undefined,
    });
  };

  const onFinish = (value: any) => {
    if (id) {
      return update_user.mutate(
        { ...value, id },
        {
          onSuccess: () => {
            swalSuccess();
            client.invalidateQueries(["get-users-admin"]);
            client.invalidateQueries(["get-user"]);
            history.replace(USER_MANAGEMENT_ADMIN_PATH.index);
          },
          onError: (error: any) => {
            swalError(t, error.message);
          },
        }
      );
    }
    return create_user.mutate(value, {
      onSuccess: () => {
        swalSuccess();
        client.invalidateQueries(["get-users-admin"]);
        history.replace(USER_MANAGEMENT_ADMIN_PATH.index);
        dispatch!({
          type: "header",
          action: "clear",
          key: ["user-management-admin"],
        });
      },
      onError: (error: any) => {
        swalError(t, error.message);
      },
    });
  };

  return (
    <Form
      className="bg-white m-auto main-input-form"
      style={{ width: "70%" }}
      layout="vertical"
      form={form}
      onFinish={onFinish}
      validateMessages={VALIDATE_MESSAGES(t)}
      initialValues={{ isActive: false }}
    >
      <PageHeaderEdit
        label="menu.user-management-admin"
        labelTo="menu.user-management-admin"
        isLoading={create_user.isLoading || update_user.isLoading}
      />
      <Row gutter={[24, 0]} className="px-4 pb-4">
        <Col span={9}></Col>
        <Col span={6} className="py-4">
          <Form.Item noStyle name="avatar">
            <ImageProfileUpload />
          </Form.Item>
        </Col>
        <Col span={9}></Col>

        <Col span={24}>
          <Form.Item
            shouldUpdate={(prev, cur) =>
              prev.identityNumber !== cur.identityNumber
            }
            className="w-100"
          >
            {(_form: FormInstance) => {
              const inputLength = form.getFieldValue("identityNumber")?.length;

              return (
                <>
                  <Form.Item
                    label={t("id-card-number")}
                    name="identityNumber"
                    rules={[{ required: true }]}
                  >
                    <Input maxLength={255} placeholder={t("id-card-number")} />
                  </Form.Item>
                  <div className="d-flex justify-end color-text-length">
                    {inputLength || 0} / 255
                  </div>
                </>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            shouldUpdate={(prev, cur) => prev.firstName !== cur.firstName}
            className="w-100"
          >
            {(_form: FormInstance) => {
              const inputLength = form.getFieldValue("firstName")?.length;

              return (
                <>
                  <Form.Item
                    rules={[{ required: true }]}
                    label={t("first-name")}
                    name="firstName"
                  >
                    <Input placeholder={t("first-name")} maxLength={255} />
                  </Form.Item>
                  <div className="d-flex justify-end color-text-length">
                    {inputLength || 0} / 255
                  </div>
                </>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            shouldUpdate={(prev, cur) => prev.lastName !== cur.lastName}
            className="w-100"
          >
            {(_form: FormInstance) => {
              const inputLength = form.getFieldValue("lastName")?.length;

              return (
                <>
                  <Form.Item
                    rules={[{ required: true }]}
                    label={t("last-name")}
                    name="lastName"
                  >
                    <Input placeholder={t("last-name")} maxLength={255} />
                  </Form.Item>
                  <div className="d-flex justify-end color-text-length">
                    {inputLength || 0} / 255
                  </div>
                </>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            shouldUpdate={(prev, cur) => prev.email !== cur.email}
            className="w-100"
          >
            {(_form: FormInstance) => {
              const inputLength = form.getFieldValue("email")?.length;

              return (
                <>
                  <Form.Item
                    rules={[{ required: true, type: "email" }]}
                    label={t("email")}
                    name="email"
                  >
                    <Input placeholder={t("email")} maxLength={255} />
                  </Form.Item>
                  <div className="d-flex justify-end color-text-length">
                    {inputLength || 0} / 255
                  </div>
                </>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            shouldUpdate={(prev, cur) => prev.secondEmail !== cur.secondEmail}
            className="w-100"
          >
            {(_form: FormInstance) => {
              const inputLength = form.getFieldValue("secondEmail")?.length;

              return (
                <>
                  <Form.Item label={t("secondary-email")} name="secondEmail">
                    <Input placeholder={t("secondary-email")} maxLength={255} />
                  </Form.Item>
                  <div className="d-flex justify-end color-text-length">
                    {inputLength || 0} / 255
                  </div>
                </>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            rules={[{ required: true }]}
            label={t("country-code")}
            name="countryCode"
          >
            <CustomSelect
              showSearch={true}
              placeholder={t("country-code")}
              optionValue={{
                values: allCountries || [],
                key: "dialCode",
                name: "name",
                text: "dialCode",
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            shouldUpdate={(prev, cur) => prev.phoneNumber !== cur.phoneNumber}
            className="w-100"
          >
            {(_form: FormInstance) => {
              const inputLength = form.getFieldValue("phoneNumber")?.length;

              return (
                <>
                  <Form.Item
                    rules={[{ required: true }]}
                    label={t("phone-number")}
                    name="phoneNumber"
                  >
                    <Input placeholder={t("phone-number")} maxLength={255} />
                  </Form.Item>
                  <div className="d-flex justify-end color-text-length">
                    {inputLength || 0} / 255
                  </div>
                </>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={12} className="pb-4">
          <Form.Item
            label={t("language")}
            name="language"
            rules={[{ required: true }]}
          >
            <CustomSelect
              placeholder={t("language")}
              hasTranslation={true}
              optionValue={{
                values: FIXED_LANGUAGE || [],
                key: "value",
                name: "label",
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t("gender")} name="gender">
            <CustomSelect
              placeholder={t("gender")}
              hasTranslation={true}
              optionValue={{
                values: FIXED_GENDER || [],
                key: "value",
                name: "label",
              }}
            />
          </Form.Item>
        </Col>

        <Col span={12} className="pb-4">
          <Form.Item label={t("date-of-birth")} name="dob">
            <CustomDatePicker placeholder={t("date-of-birth")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label={t("role")} name="role" rules={[{ required: true }]}>
            <CustomSelect
              placeholder={t("role")}
              optionValue={{
                values:
                  roles.data?.data.filter(
                    (item) => item.isActive && item.title !== "MEMBER"
                  ) || [],
                key: "id",
                name: "title",
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12} className="pb-4">
          <Form.Item label={t("status")} name="isActive">
            <CustomSelect
              placeholder={t("status")}
              hasTranslation={true}
              optionValue={{
                values: FIXED_STATUS || [],
                key: "value",
                name: "label",
              }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            shouldUpdate={(prev, cur) => prev.address !== cur.address}
            className="w-100"
          >
            {(_form: FormInstance) => {
              const inputLength = form.getFieldValue("address")?.length;

              return (
                <>
                  <Form.Item label={t("address")} name="address">
                    <Input.TextArea rows={4} maxLength={255} />
                  </Form.Item>
                  <div className="d-flex justify-end color-text-length">
                    {inputLength || 0} / 255
                  </div>
                </>
              );
            }}
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={id ? t("reset-password") : t("password")}
            className="custom-input-password"
          >
            <PasswordInput isEdit={!!id} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const PasswordInput = ({ isEdit }: { isEdit: boolean }) => {
  const pLabel = isEdit ? "new-password" : "password";
  const pConfirmLabel = isEdit ? "confirm-new-password" : "confirm-password";
  const pKey = isEdit ? "newPassword" : "password";
  const pConfirmKey = isEdit ? "confirmNewPassword" : "confirm_password";

  const { t } = useTranslation();
  return (
    <Row
      gutter={[24, 24]}
      style={{
        border: "1px solid #e4e9f2",
        marginLeft: "0px",
        marginRight: "0px",
      }}
      className="pb-4 px-3"
    >
      <Col span={12}>
        <Form.Item
          label={t(pLabel)}
          rules={[
            { required: !isEdit },
            {
              pattern: rules.password.rule,
              message: t(rules.password.tooltip),
            },
          ]}
          name={pKey}
        >
          <Input.Password placeholder={t(pLabel)} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label={t(pConfirmLabel)}
          rules={[
            { required: !isEdit },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue(pKey) === value) {
                  return Promise.resolve();
                }
                return Promise.reject(t("passwords-do-not-match"));
              },
            }),
          ]}
          name={pConfirmKey}
        >
          <Input.Password placeholder={t(pConfirmLabel)} />
        </Form.Item>
      </Col>

      {isEdit
        ? [
            <Col span={12} key={1}>
              <Form.Item
                shouldUpdate={(prev, next) => prev.password !== next.password}
              >
                {(form: FormInstance) => {
                  const hasPwd = form.getFieldValue(pKey);
                  return (
                    <Form.Item
                      label={t("old-password")}
                      rules={[{ required: !!hasPwd }]}
                      name="oldPassword"
                    >
                      <Input.Password placeholder={t("old-password")} />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>,
            <Col span={12} key={2}></Col>,
          ]
        : null}
    </Row>
  );
};

export default UserAdminEditPage;
