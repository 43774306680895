import { ReloadOutlined } from "@ant-design/icons";
import { Col, Form, FormInstance, Row, Image } from "antd";
import { useTranslation } from "react-i18next";
import { IService } from "../../../../store/service";
import { IServiceRequest } from "../../../../store/service-request";
import { IUnit } from "../../../../store/units";

const PaymentMethode = ({
  id,
  data,
  units,
  serviceItem,
  onFinish,
  // generateQrSCB,
  generateQrBOT,
  refetch,
}: {
  id: string | undefined;
  data: IServiceRequest | undefined;
  units: IUnit[];
  serviceItem: IService[];
  onFinish: (value: any, goBack: boolean) => void;
  // generateQrSCB: any;
  generateQrBOT: any;
  refetch: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <Row className="px-4" gutter={[24, 24]}>
      {id &&
        (data?.paymentType === "QR_CODE_SCB" ||
          data?.paymentType === "QR_CODE_BOT") && (
          <Col span={12}>
            <Form.Item
              shouldUpdate={(prev, next) =>
                prev.paymentType !== next.paymentType
              }
            >
              {(form: FormInstance) => {
                let generateQr: any = undefined;
                let showQr = true;
                const paymentType = form.getFieldValue("paymentType");
                const qrImage =
                  paymentType === "QR_CODE_SCB" ? "qrImage" : "qrBotImage";
                // if (paymentType === "QR_CODE_SCB") generateQr = generateQrSCB;
                if (paymentType === "QR_CODE_BOT") generateQr = generateQrBOT;
                if (paymentType?.split("_")[0] !== "QR") showQr = false;
                if (!generateQr) return <></>;

                const onClick = () => {
                  saveNewRef();
                };

                const saveNewRef = () => {
                  const serviceId = form.getFieldValue("serviceItem");
                  const taxId = serviceItem?.find(
                    (item) => item.id === serviceId
                  )?.billType?.taxId;
                  const unitId = form.getFieldValue("unit");
                  const unitReference = units?.find(
                    (item) => item.id === unitId
                  )?.unitReference;
                  const values = form.getFieldsValue();
                  onFinish({ ...values, taxId, unitReference }, false);
                };

                const reloadSCB =
                  paymentType === "QR_CODE_SCB" && !data.qrImage;
                const reloadBOT = paymentType === "QR_CODE_BOT";

                return (
                  <Row>
                    <Col>
                      <Form.Item label={t("qr-code")} hidden={!showQr}>
                        <Image
                          src={data[qrImage]?.imageUrl}
                          preview={false}
                          height={228}
                          width={228}
                        />
                      </Form.Item>
                    </Col>
                    {(reloadSCB || reloadBOT) && showQr && (
                      <Col className="d-flex align-center pl-4 color-white">
                        <div
                          style={{
                            marginTop: 41,
                            borderRadius: 50,
                          }}
                          className="p-1 d-flex align-center bg-grey pointer"
                          onClick={onClick}
                        >
                          <ReloadOutlined
                            style={{
                              fontSize: 24,
                              fontWeight: "bold",
                            }}
                          />
                        </div>
                      </Col>
                    )}
                  </Row>
                );
              }}
            </Form.Item>
          </Col>
        )}
    </Row>
  );
};

export default PaymentMethode;
