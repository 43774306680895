import { Input, Image, Button, Form, Row, Col, Divider } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { WEB_LOGO } from "../assets/icons";
import { appendLangToHtmlTag, setLocalLanguage } from "../i18n";
import { GET_USER_PROFILE, LOGIN, SET_USER_PROFILE } from "../store/auth";
import { VALIDATE_MESSAGES } from "../config/validateMessage";
import { GET_LOCAL_TOKEN, SET_LOCAL_TOKEN } from "../config/axios";
import EMAIL from "../assets/icons/login/email";
import PASSWORD from "../assets/icons/login/password";

const _activeColor = "#54544D";
const _unActiveColor = "#A9A9A5";

interface IMsg {
  type: "error" | undefined;
  msg: string | undefined;
  input: string | undefined;
}

export const LoginPage = ({ ...props }) => {
  const history = useHistory();
  const { mutate, isLoading } = LOGIN();
  const [msg, setMsg] = useState<IMsg>({
    type: undefined,
    msg: undefined,
    input: undefined,
  });

  useEffect(() => {
    redirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findIsSmallDevice = (): boolean => {
    const { innerHeight, innerWidth } = window;
    return innerWidth <= 1024 && innerHeight <= 1366;
  };

  const redirect = () => {
    const token = GET_LOCAL_TOKEN();
    const user = GET_USER_PROFILE();

    if (!token || !user) return;
    return history.replace("/");
  };

  const onFinish = (values: any) => {
    mutate(values, {
      onSuccess: (data) => {
        SET_LOCAL_TOKEN(data.accessToken);
        SET_USER_PROFILE(data.payload);
        window.location.reload();
      },
      onError: (error: any) => {
        let input = undefined;
        if (
          error.message === "ไม่พบผู้ใช้" ||
          error.message === "user not found."
        )
          input = "usr";
        if (
          error.message === "รหัสผ่านผิด" ||
          error.message === "incorrect password."
        )
          input = "pwd";
        setMsg({ msg: error.message, type: "error", input });
      },
    });
  };

  const isSmallDevice = findIsSmallDevice();

  if (isSmallDevice) {
    return (
      <MobileLogin message={msg} isLoading={isLoading} onFinish={onFinish} />
    );
  }
  return <WebLogin message={msg} isLoading={isLoading} onFinish={onFinish} />;
};

const WebLogin = ({
  message,
  onFinish,
  isLoading,
}: {
  message: IMsg;
  onFinish: (values: any) => void;
  isLoading: boolean;
}) => {
  const { i18n, t } = useTranslation();
  const [form] = Form.useForm();
  const onLangChange = (e: "en" | "th") => {
    i18n.changeLanguage(e);
    setLocalLanguage(e);
    appendLangToHtmlTag(e);
  };

  const _changeLang = (
    <div className="absolute mt-5" style={{ right: 0, top: 0, fontSize: 20 }}>
      <span
        className="mx-1 pointer"
        style={{
          color: i18n.language === "th" ? _activeColor : _unActiveColor,
        }}
        onClick={() => onLangChange("th")}
      >
        TH
      </span>
      <span className="mx-1" style={{ color: _unActiveColor }}>
        |
      </span>
      <span
        className="mx-1 pointer"
        onClick={() => onLangChange("en")}
        style={{
          color: i18n.language === "en" ? _activeColor : _unActiveColor,
        }}
      >
        EN
      </span>
    </div>
  );

  return (
    <div className="w-100 h-100" style={{ backgroundColor: "#E1DED9" }}>
      <Row className="h-100 w-100">
        <Col span={10} className="d-flex justify-center align-center">
          <Image height={120} src={WEB_LOGO} preview={false} />
        </Col>
        <Col span={3} className="d-flex align-center">
          <Divider
            type="vertical"
            style={{ height: "50%", background: "#707070" }}
          />
        </Col>
        <Col span={8} className="h-100 d-flex align-center">
          {_changeLang}
          <Form
            className="w-100 login-form"
            onFinish={onFinish}
            validateMessages={VALIDATE_MESSAGES(t)}
            form={form}
          >
            <Form.Item
              name="usr"
              rules={[{ required: true }]}
              validateStatus={
                message.input === "usr" || !message.input
                  ? message.type
                  : undefined
              }
              help={
                message.input === "usr" || !message.input
                  ? message.msg
                  : undefined
              }
            >
              <Input prefix={EMAIL()} placeholder={t("email")} />
            </Form.Item>
            <Form.Item
              name="pwd"
              className="mt-5"
              rules={[{ required: true }]}
              validateStatus={
                message.input === "pwd" ? message.type : undefined
              }
              help={message.input === "pwd" ? message.msg : undefined}
            >
              <Input.Password prefix={PASSWORD()} placeholder={t("password")} />
            </Form.Item>
            <Form.Item className="mt-4"></Form.Item>
            <Form.Item className="mt-5">
              <Button loading={isLoading} htmlType="submit">
                {t("log-in")}
              </Button>
            </Form.Item>
            <Form.Item className="mt-2 text-center">
              <a href="#/forgot-password">
                <span style={{ color: "#2f2b27" }}>
                  {t("forgot-password")}?
                </span>
              </a>
            </Form.Item>
          </Form>
        </Col>
        <Col span={3}></Col>
      </Row>
    </div>
  );
};

const MobileLogin = ({
  isLoading,
  message,
  onFinish,
}: {
  isLoading: boolean;
  message: IMsg;
  onFinish: (values: any) => void;
}) => {
  const { t } = useTranslation();

  return (
    <div
      className="w-100 h-100 d-flex justify-center flex-column align-center"
      style={{ backgroundColor: "#E1DED9" }}
    >
      <Image width="50vw" src={WEB_LOGO} preview={false} />
      <Form className="mt-5 login-form-mobile" onFinish={onFinish}>
        <Form.Item
          rules={[{ required: true }]}
          help={message.msg}
          validateStatus={message.type}
          className="w-100 relative"
          name="usr"
        >
          <Input prefix={EMAIL()} placeholder={t("email")} />
        </Form.Item>
        <Form.Item
          name="pwd"
          className="mt-4 w-100 relative"
          rules={[{ required: true }]}
        >
          <Input.Password prefix={PASSWORD()} placeholder={t("password")} />
        </Form.Item>
        <Form.Item className="mt-5">
          <Button loading={isLoading} htmlType="submit">
            {t("log-in")}
          </Button>
        </Form.Item>
        <Form.Item className="mt-1 text-center">
          <a href="#/forgot-password">
            <span style={{ color: "#b5b5b5" }}>{t("forgot-password")}?</span>
          </a>
        </Form.Item>
      </Form>
    </div>
  );
};
