export const downloadFile = (
  data: Blob,
  extension: string,
  fileName: string = "report"
) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName}.${extension}`); //or any other extension
  document.body.appendChild(link);
  link.click();
};
