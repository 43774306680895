import React from "react";

const SERVICE = (props: React.SVGProps<SVGSVGElement> = {}) => {
  return (
    <svg
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141.73 141.73"
      {...props}
    >
      <path
        data-name="Path 20624"
        d="M131.82 71.12A61 61 0 009.9 70v1.16H3.05v40.64h20.34V72.41A48.75 48.75 0 0169.34 23a47.52 47.52 0 0149 46v49.6H64.07v13.56h67.82V111.8h6.79V71.12z"
        fill="#b59d4e"
      />
    </svg>
  );
};

export default SERVICE;
